import {connect} from "react-redux";
import {ObjectReviewFooter} from "../../../components/review-workspace/objects-side-bar/object-review-footer";
import React from "react";
import {
    activateLabel,
    updateSideBarTab
} from "../../../../../../../../store/label/action";
import {ActiveControl} from "../../../../../../../../store/label";
import {updateActiveShape} from "../../../../../../../../store/review/action";
import {MD_STTUS_CD, MD_TY_CD} from "../../../const";

interface OwnProps {
    disabled: boolean;
    clientID: number;
    sortedStatesID: number[];
    onOpenCanvasContextMenu(e): void;
}
interface StateToProps {
    labels: any[];
    objectStates: any[];
    reviewInstData: any[];
    ready: boolean;
    activeControl: ActiveControl;
    moduleType: MD_TY_CD;
    moduleStatus: MD_STTUS_CD;
}

interface DispatchToProps {
    onUpdateSideBarTab(tab: string): void;
    onActivateLabel(activatedLabelID: number | null): void;
    updateActiveShape(activatedStateID: number): void;
}

function mapStateToProps(state, own: OwnProps) :StateToProps {
    const {
        Label: {
            Cmmn: {
                annotations: {states},
                canvas: {
                    ready, activeControl
                },
                module: {
                    type: moduleType,
                    status: moduleStatus,
                    labels
                }
            }
        },
        Review: {
            Cmmn: {
                annotations: {
                    data: reviewTotalData
                }
            }
        }
    } = state;
    const reviewInstData = reviewTotalData.instance;
    return {
        labels,
        objectStates: states,
        reviewInstData,
        ready,
        activeControl,
        moduleType,
        moduleStatus
    }
}

function dispatchToProps(dispatch: any): DispatchToProps {
    return {
        onUpdateSideBarTab(tab: string): void {
            dispatch(updateSideBarTab(tab));
        },
        onActivateLabel(activatedLabelID: number | null): void {
            dispatch(activateLabel(activatedLabelID));
        },
        updateActiveShape(activatedStateID: number): void {
            dispatch(updateActiveShape(activatedStateID));
        }
    };
}

type Props = StateToProps & DispatchToProps & OwnProps;
class ObjectReviewFooterContainer extends React.PureComponent<Props> {

    public constructor(props: Props) {
        super(props);
    }



    private nextActivate = (activeStateID: number): void => {
        const { objectStates, ready, activeControl, updateActiveShape } = this.props;

        if (ready && activeControl === ActiveControl.CURSOR) {
            updateActiveShape(activeStateID);
        }
    }
    private nextObjectActivate = (e): void => {
        const { clientID, sortedStatesID, objectStates, reviewInstData, onOpenCanvasContextMenu } = this.props;
        const index = objectStates.findIndex((state) => state.clientID === clientID);
        if (index === objectStates.length - 1) {
            this.activateClassTab();
            return;
        }
        let nextObject;
        for (let i = index + 1; i < objectStates.length; i++) {
            const nClientID = objectStates[i].clientID;
            if (reviewInstData[nClientID].editable === true) {
                nextObject = objectStates[i];
                break;
            }
        }
        if (nextObject) {
            this.nextActivate(nextObject.clientID);
        } else {
            this.activateClassTab();
        }
        onOpenCanvasContextMenu(e);
    }

    private activateClassTab = (): void => {
        const { labels, onActivateLabel, onUpdateSideBarTab } = this.props;
        const nextLabel = labels[0];
        onActivateLabel(nextLabel.id);
        onUpdateSideBarTab("2");
    }

    public render(): JSX.Element {
        const {
            disabled,
            moduleType,
            moduleStatus
        } = this.props;

        return (
            <ObjectReviewFooter disabled={disabled} onClick={this.nextObjectActivate}
                                moduleType={moduleType}
                                moduleStatus={moduleStatus}
            />
        );
    }
}
export default connect(mapStateToProps, dispatchToProps)(ObjectReviewFooterContainer);
