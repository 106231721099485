import {
    CHANGE_CURRENT_MODULE
    , SET_INIT_SEARCH_PARAM
    , SET_SEARCH_PARAM
    , SET_JOIN_TEST_MODULE_PARAM
} from "./actionType";

const INIT_STATE = {
    searchParam : [],
    module: null,
    moduleTy: null,
    moduleNm: null,
    moduleId: null,
    canvasDataTy: null,
    testTryCount: 0
}

const ManagerBoard = (state = INIT_STATE, action) => {
    switch (action.type){
        case SET_SEARCH_PARAM :
            return {
                ...state,
                searchParam: [action.payload]
            }
        case SET_INIT_SEARCH_PARAM :
            return {
                ...state,
                searchParam: []
            }
        case CHANGE_CURRENT_MODULE :
            return{
                ...state,
                module: action.payload
            }
        case SET_JOIN_TEST_MODULE_PARAM:
            return{
                ...state,
                moduleTy: action.payload.moduleTy,
                moduleId: action.payload.moduleId,
                moduleNm: action.payload.moduleNm,
                canvasDataTy: action.payload.canvasDataTy,
                testTryCount: action.payload.testTryCount
            }
        default:
            return state;
    }
}

export default ManagerBoard;
