// @ts-nocheck
import {ExitBtn} from "../../../../user/label/cmmn/components/header/rightGroup/RightGroupButtons";
import {RightGroup} from "../../../../user/label/annotation/image/components/header/right-group/RightGroup";

export const RightGroupContainer = (): JSX.Element => {

    return (
        <RightGroup subKeyMap={null} handlers={null}>
            <>
                <ExitBtn/>
            </>
        </RightGroup>
    )
}
