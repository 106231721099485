import {
    BELL_MESSAGE_RECEIVE,
} from './actionType';

const INIT_STATE = {
    bellData: {count:0}
};

const Bell = (state = INIT_STATE, action) => {
    switch (action.type) {
        case BELL_MESSAGE_RECEIVE:
            return {
                ...state,
                bellData: action.payload,
            };
        default:
            return state;
    }
};

export default Bell;
