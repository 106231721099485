import React from 'react';
import {Input, Row} from "reactstrap";
import styled from "styled-components";
import {useFormContext} from "react-hook-form";
import {GetMessageIntl} from "../../../util/message";
import {useIntl} from "react-intl";

const FormBox = styled.div`
  width: 100%;
  padding: 32px 24px;
  background: #F3F6F9;
  border-radius: 7px;
`

function FormStep({title, body, subId}) {
    const intl = useIntl();
    const { register, formState: { errors } } = useFormContext();

    function convertTypeToStr(type){
        switch (type){
            case "telNo":
                return "전화번호";
            case "address":
                return "주소";
            default :
                return "";
        }
    }

    return (
        <>
            <div className="fs-24 text-coco-gray-700">
                {title}
            </div>
            <hr/>
            <Row className="fw-500 text-coco-gray-700 fs-16 mb-3">
                <div dangerouslySetInnerHTML={{__html: body.description.replaceAll("\n", "<br/>")}}></div>
            </Row>
            <Row>
                <FormBox>
                    {
                        body && body.content.length > 0 ?
                            body.content.map((form, idx) => {
                                const {type, essentialYn, example, regexp} = form;
                                console.log("regexp ->", new RegExp(regexp))
                                return (
                                    <Row key={`${subId}_${idx + 1}`} className="mb-3 text-coco-gray-700">
                                        <div className="mb-4">
                                            {convertTypeToStr(type)}
                                            {essentialYn === "Y" ? <span className="text-coco-danger-400">*</span> : null}
                                        </div>
                                        <div className={errors[subId] && errors[subId][idx+1] ? "validation-error" :""}>
                                            {
                                                essentialYn === "Y" ?
                                                    <input {...register(`${subId}.${idx+1}`, {
                                                        required: "필수 입력값입니다.",
                                                        pattern: {
                                                            value: new RegExp(regexp),
                                                            message: "올바른 형식이 아닙니다."
                                                        }
                                                    })}
                                                           type="text"
                                                           className="form-control "
                                                           placeholder={example} />
                                                    :
                                                    <input {...register(`${subId}.${idx+1}`, {
                                                        required: false,
                                                        pattern: {
                                                            value: new RegExp(regexp),
                                                            message: "올바른 형식이 아닙니다."
                                                        }
                                                    })}
                                                           type="text"
                                                           className="form-control"
                                                           placeholder={example} />
                                            }
                                            {errors[subId] && errors[subId][idx+1] && <span className={"validation-error-message"}>{errors[subId][idx+1].message}</span>}

                                        </div>
                                    </Row>
                                )
                            })
                            : null
                    }
                </FormBox>
            </Row>
        </>
    );
}

export default FormStep;