// @ts-nocheck
import React, {useState} from 'react';

import {
    ActiveControl, ObjectType, Rotation, ShapeType,
} from 'store/label';
import GlobalHotKeys, { KeyMap } from 'pages/user/label/annotation/image/utils/mousetrap-react';
import { Canvas, CanvasMode } from 'pages/user/label/annotation/image/cvat-canvas-wrapper';
import { LabelOptColor } from 'pages/user/label/annotation/image/components/labels-editor/common';

import ControlVisibilityObserver, { ExtraControlsControl } from './control-visibility-observer';
import RotateControl, { Props as RotateControlProps } from './rotate-control';
import CursorControl, { Props as CursorControlProps } from './cursor-control';
import MoveControl, { Props as MoveControlProps } from './move-control';
import FitControl, { Props as FitControlProps } from './fit-control';
import ResizeControl, { Props as ResizeControlProps } from './resize-control';
import OpenCVControl from './opencv-control';
import DrawRectangleControl, { Props as DrawRectangleControlProps } from './draw-rectangle-control';
import DrawPolygonControl, { Props as DrawPolygonControlProps } from './draw-polygon-control';
import DrawPolylineControl, { Props as DrawPolylineControlProps } from './draw-polyline-control';
import DrawPointsControl, { Props as DrawPointsControlProps } from './draw-points-control';
import DrawEllipseControl, { Props as DrawEllipseControlProps } from './draw-ellipse-control';
import DrawCuboidControl, { Props as DrawCuboidControlProps } from './draw-cuboid-control';
import DrawMaskControl, { Props as DrawMaskControlProps } from './draw-mask-control';
import DrawSkeletonControl, { Props as DrawSkeletonControlProps } from './draw-skeleton-control';
import {EditImageControl} from "pages/user/label/annotation/image/containers/control-bar/EditImageControl";
import {CONTROLS_SIDE_BAR} from "../../classNameConsts";
import {alertError} from "../../../../../../../../util/AlertUtil";
import {useIntl} from "react-intl";
import {Label} from "../../../work/core/labels";

interface Props {
    canvasInstance: Canvas;
    activeControl: ActiveControl;
    keyMap: KeyMap;
    normalizedKeyMap: Record<string, string>;
    labels: any[];
    frameData: any;

    mergeObjects(enabled: boolean): void;
    groupObjects(enabled: boolean): void;
    splitTrack(enabled: boolean): void;
    rotateFrame(rotation: Rotation): void;
    repeatDrawShape(): void;
    pasteShape(): void;
    // resetGroup(): void;
    redrawShape(): void;
    switchGrid(enabled:boolean): void;
}

// We use the observer to see if these controls are in the viewport
// They automatically put to extra if not
const ObservedCursorControl = ControlVisibilityObserver<CursorControlProps>(CursorControl);
const ObservedMoveControl = ControlVisibilityObserver<MoveControlProps>(MoveControl);
const ObservedRotateControl = ControlVisibilityObserver<RotateControlProps>(RotateControl);
const ObservedFitControl = ControlVisibilityObserver<FitControlProps>(FitControl);
const ObservedResizeControl = ControlVisibilityObserver<ResizeControlProps>(ResizeControl);
const ObservedOpenCVControl = ControlVisibilityObserver(OpenCVControl);
const ObservedDrawRectangleControl = ControlVisibilityObserver<DrawRectangleControlProps>(DrawRectangleControl);
const ObservedDrawPolygonControl = ControlVisibilityObserver<DrawPolygonControlProps>(DrawPolygonControl);
const ObservedDrawPolylineControl = ControlVisibilityObserver<DrawPolylineControlProps>(DrawPolylineControl);
const ObservedDrawPointsControl = ControlVisibilityObserver<DrawPointsControlProps>(DrawPointsControl);
const ObservedDrawEllipseControl = ControlVisibilityObserver<DrawEllipseControlProps>(DrawEllipseControl);
const ObservedDrawCuboidControl = ControlVisibilityObserver<DrawCuboidControlProps>(DrawCuboidControl);
const ObservedDrawMaskControl = ControlVisibilityObserver<DrawMaskControlProps>(DrawMaskControl);
const ObservedDrawSkeletonControl = ControlVisibilityObserver<DrawSkeletonControlProps>(DrawSkeletonControl);

export default function ControlsSideBarComponent(props: Props): JSX.Element {
    const intl = useIntl();
    const {
        grid,
        activeControl,
        canvasInstance,
        normalizedKeyMap,
        keyMap,
        labels,
        objectMax,
        shapeCount,
        activeNumOfPoints,
        activeRectDrawingMethod,
        activeCuboidDrawingMethod,
        onDrawStart,
        // mergeObjects,
        // groupObjects,
        // splitTrack,
        rotateFrame,
        repeatDrawShape,
        pasteShape,
        // resetGroup,
        redrawShape,
        switchGrid,
        frameData,
    } = props;

    const UnderBar = () => {
        return (
            <div className="menu-title">
                <i className="las la-minus fs-24 lh-1"></i>
            </div>
        )
    };

    // 라벨링 기법 유무 확인
    const controlsDisabled = !labels.length //|| frameData.deleted;
    const withUnspecifiedType = labels.some((label: any) => label.type === 'any' && !label.hasParent);  //라벨 중에 any가 있고 부모 라벨이 없을 경우
    let rectangleControlVisible = withUnspecifiedType;
    let polygonControlVisible = withUnspecifiedType;
    let polylineControlVisible = withUnspecifiedType;
    let pointsControlVisible = withUnspecifiedType;
    let ellipseControlVisible = withUnspecifiedType;
    let cuboidControlVisible = withUnspecifiedType;
    let maskControlVisible = withUnspecifiedType;
    let tagControlVisible = withUnspecifiedType;
    const skeletonControlVisible = labels.some((label: LabelOptColor) => label.type === 'skeleton');
    labels.forEach((label: LabelOptColor) => {
        rectangleControlVisible = rectangleControlVisible || label.type === ShapeType.RECTANGLE;
        polygonControlVisible = polygonControlVisible || label.type === ShapeType.POLYGON;
        polylineControlVisible = polylineControlVisible || label.type === ShapeType.POLYLINE;
        pointsControlVisible = pointsControlVisible || label.type === ShapeType.POINTS;
        ellipseControlVisible = ellipseControlVisible || label.type === ShapeType.ELLIPSE;
        cuboidControlVisible = cuboidControlVisible || label.type === ShapeType.CUBOID;
        maskControlVisible = maskControlVisible || label.type === ShapeType.BRUSH;
        tagControlVisible = tagControlVisible || label.type === ObjectType.TAG;
    });

    const preventDefault = (event: KeyboardEvent | undefined): void => {
        if (event) {
            event.preventDefault();
        }
    };

    const drawShape = (objectType: ObjectType, shapeType: ShapeType): void => {
        const drawing = [
            ActiveControl.DRAW_POINTS,
            ActiveControl.DRAW_POLYGON,
            ActiveControl.DRAW_POLYLINE,
            ActiveControl.DRAW_RECTANGLE,
            ActiveControl.DRAW_CUBOID,
            ActiveControl.DRAW_ELLIPSE,
            ActiveControl.DRAW_SKELETON,
            ActiveControl.DRAW_MASK,
            ActiveControl.AI_TOOLS,
            ActiveControl.OPENCV_TOOLS,
        ].includes(activeControl)
        const editing = canvasInstance.mode() === CanvasMode.EDIT;
        if (drawing || editing) return;

        if (objectMax !== null && !(shapeCount < objectMax)){
            const param = {cnt: objectMax};
            alertError('label.overDrawLimitAlert', 'label.overDrawLimitAlertText', null, param, null, intl);
            return;
        }

        const satisfiedLabels = labels.filter((label: Label) => {
            if (shapeType === ShapeType.SKELETON) {
                return label.type === ShapeType.SKELETON;
            }
            if ([ShapeType.MASK, ShapeType.BRUSH].includes(shapeType)) {
                // TODO 저작도구가 brush일 경우, mask로 output이 나온다.
                return ['any', ShapeType.BRUSH, shapeType].includes(label.type);
            }
            return ['any', shapeType].includes(label.type);
        });

        const defaultLabelID = satisfiedLabels.length ? satisfiedLabels[0].id as number : null;

        canvasInstance.cancel();
        const selectedLabel = labels.find((label) => label.id === defaultLabelID);
        if (selectedLabel) {
            canvasInstance.draw({
                enabled: true,
                rectDrawingMethod: activeRectDrawingMethod,
                cuboidDrawingMethod: activeCuboidDrawingMethod,
                numberOfPoints: activeNumOfPoints,
                shapeType,
                skeletonSVG: selectedLabel && selectedLabel.type === ShapeType.SKELETON ?
                    selectedLabel.structure.svg : undefined,
                crosshair: [ShapeType.RECTANGLE, ShapeType.CUBOID, ShapeType.ELLIPSE].includes(shapeType),
            });

            onDrawStart(
                shapeType, selectedLabel.id, objectType,
                activeNumOfPoints, activeRectDrawingMethod, activeCuboidDrawingMethod,
            );
        }
    }

    let subKeyMap: any = {
        CANCEL: keyMap.CANCEL,
        FIT_IMAGE: keyMap.FIT_IMAGE,
        SWITCH_GRID: keyMap.SWITCH_GRID,
        // CLOCKWISE_ROTATION: keyMap.CLOCKWISE_ROTATION,
        // ANTICLOCKWISE_ROTATION: keyMap.ANTICLOCKWISE_ROTATION,
    };

    let handlers: any = {
        CANCEL: (event: KeyboardEvent | undefined) => {
            preventDefault(event);
            if (activeControl !== ActiveControl.CURSOR) {
                canvasInstance.cancel();
            }
        },
        FIT_IMAGE: (event: KeyboardEvent | undefined) => {
            preventDefault(event);
            if (canvasInstance) {
                canvasInstance.fit();
            }
        },
        SWITCH_GRID: (event: KeyboardEvent | undefined) => {
            preventDefault(event);
            switchGrid(!!!grid);
        },
        // CLOCKWISE_ROTATION: (event: KeyboardEvent | undefined) => {
        //     preventDefault(event);
        //     rotateFrame(Rotation.CLOCKWISE90);
        // },
        // ANTICLOCKWISE_ROTATION: (event: KeyboardEvent | undefined) => {
        //     preventDefault(event);
        //     rotateFrame(Rotation.ANTICLOCKWISE90);
        // },
    };

    if (!controlsDisabled) {
        handlers = {
            ...handlers,
            PASTE_SHAPE: (event: KeyboardEvent | undefined) => {
                preventDefault(event);
                canvasInstance.cancel();

                if (objectMax !== null && !(shapeCount < objectMax)) {
                    const param = {cnt: objectMax};
                    alertError('label.overDrawLimitAlert', 'label.overDrawLimitAlertText', null, param, null, intl);
                } else {
                    pasteShape();
                }
            },
            SWITCH_DRAW_MODE: (event: KeyboardEvent | undefined) => {
                preventDefault(event);
                const drawing = [
                    ActiveControl.DRAW_POINTS,
                    ActiveControl.DRAW_POLYGON,
                    ActiveControl.DRAW_POLYLINE,
                    ActiveControl.DRAW_RECTANGLE,
                    ActiveControl.DRAW_CUBOID,
                    ActiveControl.DRAW_ELLIPSE,
                    ActiveControl.DRAW_SKELETON,
                    ActiveControl.DRAW_MASK,
                    ActiveControl.AI_TOOLS,
                    ActiveControl.OPENCV_TOOLS,
                ].includes(activeControl);
                const editing = canvasInstance.mode() === CanvasMode.EDIT;

                if (!drawing) {
                    if (editing) {
                        // users probably will press N as they are used to do when they want to finish editing
                        // in this case, if a mask is being edited we probably want to finish editing first
                        canvasInstance.edit({ enabled: false });    // 그리기 done 함수
                        return;
                    }

                    canvasInstance.cancel();
                    // repeateDrawShapes gets all the latest parameters
                    // and calls canvasInstance.draw() with them

                    // if (event && event.shiftKey) {
                    //     redrawShape();
                    // } else {
                    //     repeatDrawShape();
                    // }
                } else {
                    if ([ActiveControl.AI_TOOLS, ActiveControl.OPENCV_TOOLS].includes(activeControl)) {
                        // separated API method
                        canvasInstance.interact({ enabled: false });
                        return;
                    }

                    canvasInstance.draw({ enabled: false });
                }
            },
            DRAW_PREV_LABEL_TOOL: (event: KeyboardEvent | undefined) => {
                preventDefault(event);
                const drawing = [
                    ActiveControl.DRAW_POINTS,
                    ActiveControl.DRAW_POLYGON,
                    ActiveControl.DRAW_POLYLINE,
                    ActiveControl.DRAW_RECTANGLE,
                    ActiveControl.DRAW_CUBOID,
                    ActiveControl.DRAW_ELLIPSE,
                    ActiveControl.DRAW_SKELETON,
                    ActiveControl.DRAW_MASK,
                    ActiveControl.AI_TOOLS,
                    ActiveControl.OPENCV_TOOLS,
                ].includes(activeControl);
                const editing = canvasInstance.mode() === CanvasMode.EDIT;

                if (!drawing && !editing) {
                    if (objectMax !== null && !(shapeCount < objectMax)) {
                        const param = {cnt: objectMax};
                        alertError('label.overDrawLimitAlert', 'label.overDrawLimitAlertText', null, param, null, intl);
                    } else {
                        canvasInstance.cancel();
                        repeatDrawShape();
                    }
                }
            },
            DRAW_BOUNDINGBOX: (event: KeyboardEvent | undefined) => {
                preventDefault(event);
                drawShape(ObjectType.SHAPE, ShapeType.RECTANGLE)
            },
            DRAW_POLYGON: (event: KeyboardEvent | undefined) => {
                preventDefault(event);
                drawShape(ObjectType.SHAPE, ShapeType.POLYGON)
            },
            DRAW_POLYLINE: (event: KeyboardEvent | undefined) => {
                preventDefault(event);
                drawShape(ObjectType.SHAPE, ShapeType.POLYLINE);
            },
            DRAW_POINTS: (event: KeyboardEvent | undefined) => {
                preventDefault(event);
                drawShape(ObjectType.SHAPE, ShapeType.POINTS);
            },
            DRAW_LINE: (event: KeyboardEvent | undefined) => {
                preventDefault(event);
                drawShape(ObjectType.SHAPE, ShapeType.LINE);
            },
            DRAW_ELLIPSE: (event: KeyboardEvent | undefined) => {
                preventDefault(event);
                drawShape(ObjectType.SHAPE, ShapeType.ELLIPSE);
            },
            DRAW_POLY_ELLIPSE: (event: KeyboardEvent | undefined) => {
                preventDefault(event);
                // drawShape(ObjectType.SHAPE, ShapeType.POLY);
            },
            DRAW_CIRCLE: (event: KeyboardEvent | undefined) => {
                preventDefault(event);
                drawShape(ObjectType.SHAPE, ShapeType.CIRCLE);
            },
            DRAW_BRUSH: (event: KeyboardEvent | undefined) => {
                preventDefault(event);
                drawShape(ObjectType.SHAPE, ShapeType.MASK);
            },
        };
        subKeyMap = {
            ...subKeyMap,
            PASTE_SHAPE: keyMap.PASTE_SHAPE,
            SWITCH_DRAW_MODE: keyMap.SWITCH_DRAW_MODE,
            DRAW_PREV_LABEL_TOOL: keyMap.DRAW_PREV_LABEL_TOOL,
            DRAW_BOUNDINGBOX: keyMap.DRAW_BOUNDINGBOX,
            DRAW_POLYGON: keyMap.DRAW_POLYGON,
            DRAW_POLYLINE: keyMap.DRAW_POLYLINE,
            DRAW_POINTS: keyMap.DRAW_POINTS,
            DRAW_LINE: keyMap.DRAW_LINE,
            DRAW_ELLIPSE: keyMap.DRAW_ELLIPSE,
            DRAW_POLY_ELLIPSE: keyMap.DRAW_POLY_ELLIPSE,
            DRAW_CIRCLE: keyMap.DRAW_CIRCLE,
            DRAW_BRUSH: keyMap.DRAW_BRUSH,
        };
    }

    return (
        <div className="app-menu navbar-menu custom-b-0">
            <div id="scrollbar">
                <div className={CONTROLS_SIDE_BAR}>
                    <GlobalHotKeys keyMap={subKeyMap} handlers={handlers} />
                    {/*<ObservedToolsControl />*/}
                    {/*<ObservedOpenCVControl />*/}
                    {
                        rectangleControlVisible && (
                            <ObservedDrawRectangleControl
                                canvasInstance={canvasInstance}
                                isDrawing={activeControl === ActiveControl.DRAW_RECTANGLE}
                                disabled={controlsDisabled}
                            />
                        )
                    }
                    {
                        polygonControlVisible && (
                            <ObservedDrawPolygonControl
                                canvasInstance={canvasInstance}
                                isDrawing={activeControl === ActiveControl.DRAW_POLYGON}
                                disabled={controlsDisabled}
                            />
                        )
                    }
                    {
                        polylineControlVisible && (
                            <ObservedDrawPolylineControl
                                canvasInstance={canvasInstance}
                                isDrawing={activeControl === ActiveControl.DRAW_POLYLINE}
                                disabled={controlsDisabled}
                            />
                        )
                    }
                    {
                        pointsControlVisible && (
                            <ObservedDrawPointsControl
                                canvasInstance={canvasInstance}
                                isDrawing={activeControl === ActiveControl.DRAW_POINTS}
                                disabled={controlsDisabled}
                            />
                        )
                    }
                    {
                        cuboidControlVisible && (
                            <ObservedDrawCuboidControl
                                canvasInstance={canvasInstance}
                                isDrawing={activeControl === ActiveControl.DRAW_CUBOID}
                                disabled={controlsDisabled}
                            />
                        )
                    }
                    {
                        ellipseControlVisible && (
                            <ObservedDrawEllipseControl
                                canvasInstance={canvasInstance}
                                isDrawing={activeControl === ActiveControl.DRAW_ELLIPSE}
                                disabled={controlsDisabled}
                            />
                        )
                    }
                    {
                        maskControlVisible && (
                            <ObservedDrawMaskControl
                                canvasInstance={canvasInstance}
                                isDrawing={activeControl === ActiveControl.DRAW_MASK}
                                disabled={controlsDisabled}
                            />
                        )
                    }

                    <UnderBar />
                    {/*<ObservedCursorControl*/}
                    {/*    cursorShortkey={normalizedKeyMap.CANCEL}*/}
                    {/*    canvasInstance={canvasInstance}*/}
                    {/*    activeControl={activeControl}*/}
                    {/*/>*/}
                    {/*<ObservedMoveControl canvasInstance={canvasInstance} activeControl={activeControl} />*/}
                    <div>
                        <EditImageControl />
                    </div>
                    <ObservedRotateControl
                        anticlockwiseShortcut={normalizedKeyMap.ANTICLOCKWISE_ROTATION}
                        clockwiseShortcut={normalizedKeyMap.CLOCKWISE_ROTATION}
                        rotateFrame={rotateFrame}
                    />
                    <ObservedFitControl canvasInstance={canvasInstance} />
                    {/*<ObservedResizeControl canvasInstance={canvasInstance} activeControl={activeControl} />*/}

                    {/*<UnderBar/>*/}
                    {/*<ObservedGroupControl*/}
                    {/*    groupObjects={groupObjects}*/}
                    {/*    resetGroup={resetGroup}*/}
                    {/*    canvasInstance={canvasInstance}*/}
                    {/*    activeControl={activeControl}*/}
                    {/*    disabled={controlsDisabled}*/}
                    {/*    shortcuts={{*/}
                    {/*        SWITCH_GROUP_MODE: {*/}
                    {/*            details: keyMap.SWITCH_GROUP_MODE,*/}
                    {/*            displayValue: normalizedKeyMap.SWITCH_GROUP_MODE,*/}
                    {/*        },*/}
                    {/*        RESET_GROUP: {*/}
                    {/*            details: keyMap.RESET_GROUP,*/}
                    {/*            displayValue: normalizedKeyMap.RESET_GROUP,*/}
                    {/*        },*/}
                    {/*    }}*/}
                    {/*/>*/}
                    {/*<ObservedSplitControl*/}
                    {/*    splitTrack={splitTrack}*/}
                    {/*    canvasInstance={canvasInstance}*/}
                    {/*    activeControl={activeControl}*/}
                    {/*    disabled={controlsDisabled}*/}
                    {/*    shortcuts={{*/}
                    {/*        SWITCH_SPLIT_MODE: {*/}
                    {/*            details: keyMap.SWITCH_SPLIT_MODE,*/}
                    {/*            displayValue: normalizedKeyMap.SWITCH_SPLIT_MODE,*/}
                    {/*        },*/}
                    {/*    }}*/}
                    {/*/>*/}
                    {/*<ExtraControlsControl />*/}

                    {/*<ul className="navbar-nav" id="navbar-nav">*/}
                    {/*    {*/}
                    {/*        tmpLbPrjInfo.moduleTy === MD_TY_CD.LABEL?*/}
                    {/*            (*/}
                    {/*                <div id="labelToolArea">*/}
                    {/*                    <UnderBar />*/}
                    {/*                    <NavItem>*/}
                    {/*                        <Button color={"coco-ghost"} className="btn-icon waves-effect waves-light rounded-pill " id="trash">*/}
                    {/*                            <i className="ri-delete-bin-line fs-20"></i>*/}
                    {/*                        </Button>*/}
                    {/*                    </NavItem>*/}
                    {/*                </div>*/}
                    {/*            ): null*/}
                    {/*    }*/}
                    {/*</ul>*/}
                </div>
                {/*{!--Sidebar--}*/}
            </div>

            <div className="sidebar-background"></div>
        </div>
    )
}
