import React, { memo } from 'react';
import {Handle, useReactFlow, useStoreApi, Position, useStore} from 'reactflow';
import {GetMessage} from "../../../../../../../util/message";
import {useDispatch, useSelector} from "react-redux";
import {thisSourceValidConnection, thisTargetValidConnection} from "../nodeCommonUtil";
const connectionNodeIdSelector = (state) => {
    return state.connectionNodeId;
}
const nodesSelector = (state) => Array.from(state.nodeInternals.values());

export default function AcceptNode({ id, data, isConnectable }) {
    const dispatch = useDispatch();
    const nodes = useStore(nodesSelector);
    const connectionNodeId = useStore(connectionNodeIdSelector);
    let connectionNodeType = '';
    if(connectionNodeId){
        let connectionNode = nodes.find((item) => item.id === connectionNodeId);
        let parentNodeType = '';
        if(connectionNode.parentNode){
            parentNodeType = nodes.find((item) => item.id === connectionNode.parentNode).type+'_';
        }
        connectionNodeType = parentNodeType + connectionNode.type;
    }

    const parentId = id.substring(0, id.lastIndexOf('_'));
    const {selectionNodeId} = useSelector((state)=>({
        selectionNodeId : state.ManageWorkflowModule.selectionNodeId
    }));

    const isParent = selectionNodeId && selectionNodeId !== id && parentId ===selectionNodeId;
    const sourceHandleStyle = isParent? { visibility: 'visible', zIndex: 10 } : {visibility: 'hidden', zIndex:1};

    const isSourceValidConnection = (connection) => {
        let connectSourceType = nodes.find((item) => item.id === connection.target).type;
        return thisSourceValidConnection('accept',connectSourceType);
    }

    return (
        <>
                <Handle
                    type="source"
                    position={Position.Right}
                    id="a"
                    style={{ top: 10, background: '#555' }}
                    isConnectable={isConnectable}
                />
                <Handle
                    type="source"
                    position={Position.Right}
                    id="b"
                    style={{ bottom: 10, top: 'auto', background: '#555' }}
                    isConnectable={isConnectable}
                />

                <Handle
                    className="targetHandle"
                    style={targetHandleStyle}
                    position={Position.Left}
                    type="target"
                    isConnectable={isConnectable}
                />

                {GetMessage("workflow.accept")}
        </>
    );
}