import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {BoardService} from "../../apis/api/BoardService";
import {Col, Row} from "reactstrap";
import {
    board_faqSetModalMode, board_faqSwitchAddModalFlag, board_faqSwitchViewModalFlag,
    board_setRowData, board_switchViewModalFlag,
} from "../../store/board/action";
import CommonUtil from "../../util/CommonUtil";
import SimpleBar from "simplebar-react";
import {BoardUtil} from "../../util/BoardUtil";
import {useHistory} from "react-router-dom";

function FaqView(props) {
    const dispatch = useDispatch();
    const { rowData, modalFlag } = useSelector((state)=>({
        rowData : state.Board.rowData,
        modalFlag : state.Board.faqAddModalFlag
    }));
    const [attachFilesUrl, setAttachFilesUrl] = useState([]);
    const coconutUserInfo = JSON.parse(sessionStorage.getItem("coconutUserInfo"));
    const userRole = coconutUserInfo.userRole;
    const history = useHistory();

    useEffect(() => {
        const unlisten = history.listen((location, action) => {
            if (action === 'POP') {
                dispatch(board_faqSwitchViewModalFlag())
                if(userRole === '11'){
                    history.push('/manager/board/faq')
                }else if(userRole === '01'){
                    history.push('/platform/board/faq')
                }else if(userRole === '20'){
                    history.push('/user/board/faq')
                }
            }
        });
        return () => {
            unlisten();
        };
    }, [history]);

    useEffect(()=>{
        getAttachFilesAwsUrl(rowData.cnSeq);
        getCnInfo(rowData.cnSeq);
    }, [modalFlag])

    const getAttachFilesAwsUrl = async (cnSeq) => {
        const res =  await BoardService.getContentAttachFile({
            'cnSeq' : cnSeq
        });
        if(res.data.data){
            setAttachFilesUrl(res.data.data);
            console.log("AttachFileInfo -> ", res.data.data)
        }
    }

    const getCnInfo = async (cnSeq) => {
        const res = await BoardService.getContent({
            'cnSeq' : cnSeq
        })
        if(res.data.data){
            console.log("BoardCnInfo => ", res.data.data)
            dispatch(board_setRowData(res.data.data))
        }
    }

    function downloadFile(filePath, realFileName) {
        console.log('파일 다운로드 ', filePath)
        fetch(filePath, { method: 'GET' })
            .then((res) => {
                return res.blob() // raw 데이터를 받아온다
            })
            .then((blob) => {
                const url = window.URL.createObjectURL(blob) // 받아온 날 상태의 data를 현재 window에서만 사용하는 url로 바꾼다
                const a = document.createElement('a')
                a.href = url
                a.download = realFileName // 원하는 이름으로 파일명 지정
                document.body.appendChild(a)
                a.click() // 자동으로 눌러버리기
                setTimeout((_) => {
                    window.URL.revokeObjectURL(url) // 해당 url을 더 사용 못하게 날려버린다
                }, 60000)
                a.remove() // a를 다 사용했으니 지워준다
                return false;
            })
            .catch((err) => {
                console.error('err: ', err)
            })
    }

    return (
        <div className="d-flex justify-content-between align-items-center fs-16 fw-500">
            <div></div>
            <div style={{width:"1200px"}}>
                <Row className="tx-20 mb-2">
                    <Col>
                        <span>FAQ<span className="tx-14 text-coco-gray-200">&nbsp;| {BoardUtil.convertCnTyToLabel(rowData.cnTy)} No.{rowData.cnSeq}</span></span>
                    </Col>
                </Row>
                <Row className="mb-3" style={{borderBottom : "1px solid #cfd8dc", paddingBottom : "12px"}}>
                    <Col className="d-flex justify-content-between align-items-center">
                        <div className="tx-24 fw-600" style={{height : '40px'}}>
                            {
                                rowData.noticeYn === 'Y' ? <span className="badge badge-soft-danger">중요</span> : null
                            }{rowData.cnTitle}
                        </div>
                        <div className="d-flex justify-content-between align-items-center gap-2 tx-16 fw-500">
                            <div>
                                {rowData.regDtmText.split(" ")[0]} |
                            </div>
                            <div>
                                조회 수 {rowData.readCnt}회
                            </div>
                            <div>
                                {
                                    userRole === '01' ?
                                        <button type="button" className="btn btn-coco-xs-2" onClick={()=>{
                                            dispatch(board_faqSetModalMode("U"));
                                            dispatch(board_faqSwitchAddModalFlag());
                                        }}>
                                            수정하기
                                        </button> : null
                                }
                            </div>

                        </div>
                    </Col>
                </Row>
                <Row className="mb-2">
                    <Col>
                        {
                            attachFilesUrl.map((url, idx) => {
                                const seperateUrl = url.split(",");
                                let filePath = seperateUrl[0];
                                let realFileName = seperateUrl[1];
                                let fileSize = seperateUrl[2];
                                return(
                                    <Row className="mb-2" key={realFileName}>
                                        <div className={"d-flex align-items-center"}>
                                            <i className="ri-download-2-line text-coco-gray-400" onClick={()=>downloadFile(filePath, realFileName)}></i>
                                            <span>&nbsp;{realFileName}</span>
                                            <span className="fs-16 fw-500 text-coco-gray-400">&nbsp;({CommonUtil.bytesToSize(fileSize)})</span>
                                        </div>
                                    </Row>
                                )
                            })
                        }
                    </Col>
                </Row>
                <Row className="fs-16 pd-y-12" style={{height : '100%', borderBottom : "1px solid #cfd8dc", marginBottom : "20px"}}>
                    <SimpleBar style={{maxHeight: "100%"}} className="pe-2">
                        <Col>
                            <div dangerouslySetInnerHTML={{ __html: rowData.cn }}></div>
                        </Col>
                    </SimpleBar>
                </Row>
                <Row>
                    <div className="d-flex justify-content-between align-items-center" style={{marginBottom:"20px"}}>
                        <div></div>
                        <div className="d-flex justify-content-between align-items-center gap-2">
                            <button type="button" className="btn btn-coco-1" style={{width:"84px"}} onClick={()=>{
                                dispatch(board_faqSwitchViewModalFlag());
                                dispatch(board_setRowData([]));
                            }}>
                                목록
                            </button>
                            {
                                userRole === '01' ?
                                    <button type="button" className="btn btn-coco-2" style={{width:"84px"}} onClick={()=>{
                                        dispatch(board_faqSetModalMode("U"));
                                        dispatch(board_faqSwitchAddModalFlag());
                                    }}>
                                        수정하기
                                    </button> : undefined
                            }
                        </div>
                        <div></div>
                    </div>

                </Row>
            </div>
            <div>
            </div>
        </div>
    );
}

export default FaqView;