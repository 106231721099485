// @ts-nocheck
import {DropdownItem, DropdownMenu, DropdownToggle, Input, UncontrolledDropdown} from "reactstrap";
import React, {useEffect, useState} from "react";
import {GetMessage, GetMessageIntl} from "../../../../../util/message";
import {useIntl} from "react-intl";
import {SearchFilter, SearchOrder} from "../../../../../store/dataset/details";
import {DataStatusFilter} from "./data-status-filter";
import {DatasetFilter} from "./dataset-filter";
import {WorkerFilter} from "./worker-filter";
import {RejectFilter} from "./reject-filter";
import {SkipFilter} from "./skip-filter";
import {FolderFilter} from "./folder-filter";
import {useDispatch, useSelector} from "react-redux";
import {FilterButtonView} from "./filter-button-view";
import {
    getDataList,
    resetSearchParamType,
    updateSearchParam,
    updateSearchParamOrder,
    updateSearchParamVisible
} from "../../../../../store/dataset/details/action";
import {LABEL_TYPE} from "../../../../user/label/annotation/image/const";
import {ClsfcSmryRejectFilter} from "./ClsfcSmryRejectFilter";

export function SearchHeader(): JSX.Element {
    const dispatch = useDispatch();
    const {
        Details: {
            searchParam: {
                data:file
        }}
    } = useSelector(state => state);

    const searchCloseIcon = document.getElementById("search-close-icon");
    const searchInput = document.getElementById("search-input");

    const onChange = (target) => {
        const value = target.value;
        dispatch(updateSearchParam(SearchFilter.FILE, (value=='' ? null : value)))
        if (value.length > 0) {
            searchCloseIcon.classList.remove("d-none");
        } else {
            searchCloseIcon.classList.add("d-none");
        }
    }

    const onKeyPress = (e) => {
        if (e.key === "Enter"){
            dispatch(getDataList());
        }
    }

    useEffect(()=>{
        searchCloseIcon?.addEventListener("click", function () {
            searchInput.value = "";
            onChange(searchInput);
            searchCloseIcon.classList.add("d-none");
            dispatch(getDataList());
        });

    }, [searchCloseIcon]);

    return (
        <div id="search-input-file">
            <div className="position-relative form-search" >
                <Input type="text" className="form-control" id={"search-input"}
                       onChange={(event)=>onChange(event.target)}
                       onKeyDown={(event) => onKeyPress(event)}
                       placeholder={GetMessage("details.searchPlaceholder")} />
                <span className="ri-search-line search-widget-icon"></span>
                <span className="ri-close-line search-widget-icon search-widget-icon-close d-none"
                      id="search-close-icon"></span>
            </div>
            <FilterAndArrange />
        </div>
    )
}

function FilterAndArrange():JSX.Element {
    const intl = useIntl();
    const dispatch = useDispatch();
    const {
        Label: {
            Cmmn: {module: {labelType, labels}}
        },
        Details: {
            searchParam: {visible, view, data: {order}}
        }
    } = useSelector(state => state);
    const [dataStatusVisible, setDataStatusVisible] = useState<boolean>(false);
    const [datasetVisible, setDatasetVisible] = useState<boolean>(false);
    const [folderVisible, setFolderVisible] = useState<boolean>(false);
    const [workerVisible, setWorkerVisible] = useState<boolean>(false);
    const [lastRejectReasonsVisible, setLastRejectReasonsVisible] = useState<boolean>(false);
    const [skipReasonsVisible, setSkipReasonsVisible] = useState<boolean>(false);

    const filterList = [
        {label: GetMessageIntl(intl, "details.dataStatus"), type: SearchFilter.DATA_STATUS, page: <DataStatusFilter/>
            , visible: dataStatusVisible, setVisible: setDataStatusVisible},
        {label: GetMessageIntl(intl, "details.dataset"), type: SearchFilter.DATASET, page: <DatasetFilter/>
            , visible: datasetVisible, setVisible: setDatasetVisible},
        {label: GetMessageIntl(intl, "details.folder"), type: SearchFilter.FOLDER, page: <FolderFilter/>
            , visible: folderVisible, setVisible: setFolderVisible},
        {label: GetMessageIntl(intl, "details.worker"), type: SearchFilter.WORKER, page: <WorkerFilter/>
            , visible: workerVisible, setVisible: setWorkerVisible},
        {label: GetMessageIntl(intl, "details.lastRejectReasons"), type: SearchFilter.LAST_REJECT_REASONS, page: <RejectFilter/>, labelType: LABEL_TYPE.ANNOTATION
            , visible: lastRejectReasonsVisible, setVisible: setLastRejectReasonsVisible},
        {label: GetMessageIntl(intl, "details.lastRejectReasons"), type: SearchFilter.LAST_REJECT_REASONS_CLSFC, page: <ClsfcSmryRejectFilter/>
            , visible: lastRejectReasonsVisible, setVisible: setLastRejectReasonsVisible},
        {label: GetMessageIntl(intl, "details.skipReasons"), type: SearchFilter.SKIP_REASONS, page: <SkipFilter/>
            , visible: skipReasonsVisible, setVisible: setSkipReasonsVisible},
    ]

    useEffect(()=>{
        filterList.map(filter => {
            filter.setVisible(visible.includes(filter.type))
        })
    }, [visible])

    const orderList = [
        {label: GetMessageIntl(intl, "details.orderByFileName"), type: SearchOrder.FILE_NAME},
        {label: GetMessageIntl(intl, "details.orderByLastReg"), type: SearchOrder.LAST_REG},
        {label: GetMessageIntl(intl, "details.orderByLastCmplt"), type: SearchOrder.LAST_CMPLT}
    ]

    const updateVisible = (formType) => {
        const newVisible = [...visible];
        dispatch(updateSearchParamVisible(newVisible.includes(formType)
                                                ? newVisible.filter(f => f!==formType)
                                                : newVisible.concat(formType)))
    }

    const resetParam = (formType) => {
        dispatch(resetSearchParamType(formType))
        dispatch(getDataList())
    }

    const updateOrder = (orderType) => {
        dispatch(updateSearchParamOrder(orderType));
        dispatch(getDataList())
    }

    return (
        <>
            <div className={"search-condition"}>
                <UncontrolledDropdown>
                    <DropdownToggle tag="button" type="button" className="btn pd-0 d-flex">
                        <div className={"title"}>{GetMessage("details.filter")}</div>
                        <i className={"ri-add-fill text-coco-gray-400"}/>
                    </DropdownToggle>
                    <DropdownMenu>
                        {filterList.length > 0 &&
                            filterList.map(form => {
                                if (form.labelType === LABEL_TYPE.ANNOTATION) return;
                                return (
                                    <DropdownItem key={"form_".concat(form.type)}
                                                  onClick={() => updateVisible(form.type)}
                                    >
                                        {form.label}
                                    </DropdownItem>
                                )
                            })
                        }
                    </DropdownMenu>
                </UncontrolledDropdown>
                <UncontrolledDropdown>
                    <DropdownToggle tag="button" type="button" className="btn pd-0 d-flex">
                        <div className={"title"}>{orderList.find(i => i.type === order).label}</div>
                        <i className={"ri-arrow-drop-down-line text-coco-gray-400"}/>
                    </DropdownToggle>
                    <DropdownMenu>
                        {orderList.length > 0 &&
                            orderList.map(form => {
                                return (
                                    <DropdownItem key={"form_".concat(form.type)}
                                        onClick={() => updateOrder(form.type)}
                                    >
                                        {form.label}
                                    </DropdownItem>
                                )
                            })
                        }
                    </DropdownMenu>
                </UncontrolledDropdown>
            </div>
            <div className={"filter-button-wrapper"}>
                {filterList.length > 0 &&
                    filterList.map(filter => {
                        if (filter.labelType === LABEL_TYPE.ANNOTATION) return;
                        const makeLabelforClsfcSmry = () => {
                            if (view[filter.type].length === 0) return null;
                            let stageMap = {};
                            view[filter.type].map( v => {
                                if (stageMap[v.id]) {
                                    stageMap[v.id] = stageMap[v.id] + `, ${v.label}`;
                                } else {
                                    stageMap[v.id] = v.label;
                                }
                            })

                            let result = [];
                            for (const [id, value] of Object.entries(stageMap)) {
                                const question = labels.find(l => l.id == id).question;
                                result.push(`[${question}] ${value}`)
                            }

                            return result.join(', ');
                        }
                        const value = filter.type === SearchFilter.LAST_REJECT_REASONS_CLSFC ? makeLabelforClsfcSmry()
                            : view[filter.type].length > 0 ? view[filter.type].map(i => i.label).join(', ') : null

                        return (
                            filter.visible &&
                                <FilterButtonView key={`filter-button-${filter.type}`}
												  title={filter.label}
												  // value={view[filter.type].length > 0 ? view[filter.type].map(i => i.label).join(', ') : null}
												  value={value}
												  dropdownMenu={filter.page}
												  updateVisible={() => {
                                                  updateVisible(filter.type)
                                                  resetParam(filter.type)
                                              }}/>
                        )
                    })}
            </div>
        </>
    );
}

export function ApplySearchButton(): JSX.Element{
    const intl = useIntl();
    const dispatch = useDispatch();
    const applyFilter = () => {
        dispatch(getDataList());
    }
    return (
        <button className={"btn btn-coco-xs-1"} style={{width: "52px"}}
                onClick={applyFilter}
        >{GetMessageIntl(intl, 'ui.U00125')}</button>
    )
}
