import { takeEvery, takeLatest, call, put, all, select } from 'redux-saga/effects';
import {
    CHANGE_FRAME,
    CHANGE_GROUP_COLOR,
    CHECK_VALIDATION,
    CLOSE_MODULE,
    CLOSE_MODULE_SUCCESS,
    COLLECT_STATISTICS,
    COPY_SHAPE,
    CREATE_ANNOTATIONS,
    FETCH_ANNOTATIONS,
    GET_AUTO_LABEL_ANNOTATION,
    GET_MODULE,
    GROUP_ANNOTATIONS,
    LOAD_PREV_FILE_OBJECTS,
    MERGE_ANNOTATIONS,
    PASTE_SHAPE_ACTION,
    REDO_ACTION,
    REDRAW_SHAPE, REMOVE_ANNOTATIONS,
    REMOVE_OBJECT,
    REPEAT_DRAW_SHAPE, REPEAT_DRAW_SHAPE_ACTION,
    SAVE_LOGS,
    SET_FORCE_EXIT_ANNOTATION_PAGE_FLAG,
    SKIP_DATA,
    SKIP_DATA_SUCCESS,
    SPLIT_ANNOTATIONS,
    SUBMIT_ANNOTATIONS,
    SUBMIT_ANNOTATIONS_SUCCESS,
    UNDO_ACTION,
    UPDATE_ANNOTATIONS
} from "./actionType";
import {
    activateObject,
    changeFrame,
    changeFrameFailed,
    changeFrameSuccess,
    changeWorkspace,
    checkValidationSuccess,
    closeModule,
    closeModuleSuccess,
    collectStatistics,
    collectStatisticsFailed,
    collectStatisticsSuccess,
    createAnnotations,
    createAnnotationsFailed,
    createAnnotationsSuccess,
    fetchAnnotationsFailed,
    fetchAnnotationsSuccess,
    getAutoLabelAnnotation,
    getAutoLabelAnnotationFailed,
    getAutoLabelAnnotationSuccess,
    getModule,
    getModuleFailed,
    getModuleSuccess,
    groupAnnotationsFailed,
    groupAnnotationsSuccess,
    interactWithCanvas,
    loadPrevFileObjectsFailed,
    loadPrevFileObjectsSuccecss,
    mergeAnnotationsFailed,
    mergeAnnotationsSuccess,
    pasteShape,
    pasteShapeAction,
    redoActionFailed,
    redoActionSuccess,
    removeAnnotationsFailed,
    removeAnnotationsSuccess,
    removeObjectFailed,
    removeObjectSuccess,
    repeatDrawShape,
    saveAnnotations,
    saveAnnotationsFailed,
    saveAnnotationsSuccess,
    saveLog,
    saveLogFailed,
    saveLogSuccess, setCanvasDataTyLable,
    setForceExitAnnotationFlag,
    skipDataFailed,
    skipDataSuccess,
    splitAnnotationsFailed,
    splitAnnotationsSuccess,
    submitAnnotations,
    submitAnnotationsFailed,
    submitAnnotationsSuccess,
    undoAction,
    undoActionFailed,
    undoActionSuccess,
    updateAnnotations,
    updateAnnotationsFailed,
    updateAnnotationsSuccess
} from "store/label/action";// "./action";
import {getRootStore} from "../index";
import {
    getAnnotationResult,
    getAttr,
    getBoxPoints,
    getExFilePath,
    getFrame,
    saveOnServer,
    skipOnServer
} from "../../pages/user/label/annotation/image/work/service";
import {getCore} from "pages/user/label/annotation/image/cvat-core-wrapper";
import {Store} from "redux";
import {ObjectType, ShapeType, ActiveControl, Workspace} from ".";
import {LogType} from "pages/user/label/annotation/image/cvat-logger";
import logger from "../../pages/user/label/annotation/image/cvat-logger";
import {alertError, confirmSuccess} from "../../util/AlertUtil";
import {assignCheck} from "../user/board/saga";
import {Module} from "../../pages/user/label/annotation/image/work/core/session";
import {Canvas} from "pages/user/label/annotation/image/work/canvas/typescript/canvas";
import {DATA_TYPE, LABEL_TYPE, MD_TY_CD} from "../../pages/user/label/annotation/image/const";
import {setLoading} from "../layouts/action";
import {switchToolsBlockerState} from "../settings/action";
import {LabelCmmnService} from "../../apis/api/LabelCmmnService";
import {ReviewCmmnService} from "../../apis/api/ReviewCmmnService";


const core = getCore();
let store: null | Store = null;
function getStore(): Store {
    if (store === null) {
        store = getRootStore();
    }
    return store;
}
function receiveAnnotationsParameters(){
    if (store === null){
        store = getRootStore();
    }

    const state = getStore().getState();
    const {
        Label: {
            Cmmn: {
                annotations: {filters},
                player: {
                    frame: {number: frame},
                },
                module: {instance: moduleInstance},
            }
        },
        settings: {
            workspace: { showAllInterpolationTracks },
        },
    } = state;

    return {
        filters,
        frame,
        moduleInstance,
        showAllInterpolationTracks,
    };
}
function* resetSession(){
    // reset session storage
    sessionStorage.removeItem('module');
    sessionStorage.removeItem('frame');
    yield put(setLoading(false));
}
export function computeZRange(states: any[]): number[] {
    const filteredStates = states.filter((state: any): any => state.objectType !== ObjectType.TAG);
    let minZ = filteredStates.length ? filteredStates[0].zOrder : 0;
    let maxZ = filteredStates.length ? filteredStates[0].zOrder : 0;
    filteredStates.forEach((state: any): void => {
        minZ = Math.min(minZ, state.zOrder);
        maxZ = Math.max(maxZ, state.zOrder);
    });

    return [minZ, maxZ];
}
function* fetchAnnotationsAsync(){
    try{
        const {
            filters, frame, showAllInterpolationTracks, moduleInstance,
        } = receiveAnnotationsParameters();
        const states = yield moduleInstance.annotations.get(frame, showAllInterpolationTracks, filters);
        const history = yield moduleInstance.actions.get();
        const [minZ, maxZ] = computeZRange(states);

        yield put(fetchAnnotationsSuccess(states, history, minZ, maxZ));
    } catch (e) {
        yield put(fetchAnnotationsFailed(e))
        ;
    }
}
function* getModuleAsync(action){
    sessionStorage.setItem('module', JSON.stringify(action.payload.module));
    sessionStorage.setItem('frame', JSON.stringify(action.payload.frame));
    try {
        const { moduleId, name, type, canvasDataTy } = action.payload.module;
        const { datasetId, fileSeq, moduleId: tModuleId } = action.payload.frame;
        const Label = yield select((store) => store.Label);

        // config setting
        core.config.service = JSON.parse(sessionStorage.getItem("module")).type === MD_TY_CD.LABEL ? LabelCmmnService : ReviewCmmnService;

        let labelType, dataType;
        if (canvasDataTy) {
            [dataType, labelType] = canvasDataTy.split('');
        }

        // Todo getAttr 가져오기. (module.labels)로
        const prjInfo = yield call(getAttr, {moduleId: moduleId});
        const data = JSON.parse(prjInfo.data);
        let labels = [], countLimit = null;
        if (data) {
            labels = data.labels;
            countLimit = data.countLimit ?? {objectMax: 0, objectMin: 0};
        }

        let module = yield core.module.get({
            id: moduleId,
            name: name,
            type: type,
            dataType, labelType,
            targetModuleId: tModuleId ?? null,
            state: "in progress",
            dimension: "2d",
            workflow_id: null,
            project_id: null,
            mode: 'annotation',
            frame:{datasetId, fileSeq},
            labels,
            countLimit
        });

        // Todo dataType에 따라 frameData 가져오기
        yield put(changeFrame(module, {datasetId, fileSeq, moduleId: tModuleId}));
        yield put(setCanvasDataTyLable(canvasDataTy));
        const frameData = module.frame;
        // Todo annotations(- state 이전 라벨링 결과) 가져오기.
        // const states = yield module.annotations.get(0, datasetId, fileSeq,);
        // const [minZ, maxZ] = computeZRange(states);

        yield put(getModuleSuccess({
            module,
            dataType,
            labelType,
            labels,
            // states,
            datasetId,
            fileSeq,
            // frame,
            frameData,
            countLimit,
            // minZ,
            // maxZ
        }));

    } catch (error) {
        // 실패시 SEARCHP_USER_SUCCESS 액션을 dispatch 하고 에러를 남긴다.
        console.log('[getModuleAsync] saga error', error);
        yield put(getModuleFailed(error));
        console.log('[getModuleAsync] saga error: get module failed');
    }
}
function* changeFrameAsync(action){
    try {
        const { module: moduleParams, frame: frameParams } = action.payload;
        const {datasetId, fileSeq, moduleId} = frameParams;
        const {moduleInstance} = receiveAnnotationsParameters();
        const module = moduleParams instanceof Module ? moduleParams : moduleInstance;
        sessionStorage.setItem('frame', JSON.stringify(frameParams));

        const {dataType, labelType} = module;
        module.frame = {datasetId, fileSeq};

        yield put(setLoading(true));
        let frame;
        let states, minZ, maxZ, url;
        if (datasetId && fileSeq) {
            const tData = yield call(getFrame, {param: frameParams, dataType: module.dataType});
            const frameData = tData.data;
            const imageData = frameData.encodedString;
            const fileNm = frameData.fileNm;
            const filePath = frameData.filePath;
            const fileSize = frameData.fileSize;
            url = frameData.url;

            module.frame.encodedString = imageData;
            frame = yield module.frames.get(0, datasetId, fileSeq, fileNm, filePath, url, dataType, fileSize, imageData, false, 1);

            module.frame = frame;
            yield frame.data(imageData);
            // if (dataType === DATA_TYPE.IMAGE) {
            //     yield frame.data(imageData);
            // }

            if (labelType === LABEL_TYPE.ANNOTATION) {
                states = yield module.annotations.get(0, datasetId, fileSeq);
            } else {
                const result = yield call(getAnnotationResult, module, {datasetId, fileSeq})
                if (result.data) {
                    const json = JSON.parse(result.data.json);
                    states = json.shapes;
                }
            }

            [minZ, maxZ] = [0, 0];//computeZRange(states);

            // const result = yield module.annotations.updateFrameMeta();
        } else {
            new Error('There is no frameData');
        }

        // Todo annotations(- state 이전 라벨링 결과) 가져오기.
        // const states = yield module.annotations.get(0, datasetId, fileSeq);
        // const [minZ, maxZ] = computeZRange(states);

        yield put(changeFrameSuccess({frame, module, states, minZ, maxZ}));
        yield put(setLoading(false));
    }catch (e){
        console.log('[changeFrameAsync]', e);
        yield put(setLoading(false));   // -> TODO 간헐적으로 로딩 페이지가 안 없어지고 다음 이미지로 안 넘어간다고 함.
        yield put(changeFrameFailed(e));
    }
}

function* createAnnotationsAsync(action){
    try {
        const {sessionInstance, frame, statesToCreate} = action.payload;
        const { filters, showAllInterpolationTracks } = receiveAnnotationsParameters();
        yield sessionInstance.annotations.put(statesToCreate);
        yield sessionInstance.annotations.updateViewID();
        const states = yield sessionInstance.annotations.get(frame, showAllInterpolationTracks, filters);
        const history = yield sessionInstance.actions.get();

        yield put(createAnnotationsSuccess(sessionInstance, states, history))
    } catch (error) {
        yield put(createAnnotationsFailed(error))
    }
}
function* collectStatisticsAsync(action){
    // 통계 정보
    try {
        const sessionInstance = action.payload;
        const reviewData = getStore().getState().Review.Cmmn.annotations.data;
        const data = yield sessionInstance.annotations.statistics(reviewData);
        yield put(collectStatisticsSuccess(data));
    } catch (e) {
        yield put(collectStatisticsFailed(e));
    }
}

function* updateAnnotationsAsync(action){
    const {statesToUpdate, force} = action.payload;
    const {
        moduleInstance, filters, frame, showAllInterpolationTracks,
    } = receiveAnnotationsParameters();
    try {
        if (statesToUpdate.some((state: any): boolean => state.updateFlags.zOrder)) {
            // deactivate object to visualize changes immediately (UX)
            yield put(activateObject(null, null, null));
        }
        const promises = statesToUpdate.map((objectState: any): Promise<any> => objectState.save(force));
        const states = yield all(promises);

        const needToUpdateAll = states
            .some((state: any) => state.shapeType === ShapeType.MASK || state.parentID !== null);
        if (needToUpdateAll) {
            yield fetchAnnotationsAsync();
            // return;
        }

        const history = yield moduleInstance.actions.get();
        const [minZ, maxZ] = computeZRange(states);
        yield put(updateAnnotationsSuccess(states, history, minZ, maxZ));
    } catch (e) {
        yield put(updateAnnotationsFailed(e));
    }
}
function* mergeAnnotationsAsync(action){
    const {moduleInstance, frame, statesToMerge} = action.payload;
    try {
        const { filters, showAllInterpolationTracks } = receiveAnnotationsParameters();
        yield moduleInstance.annotations.merge(statesToMerge);
        yield moduleInstance.annotations.updateViewID();
        const states = yield moduleInstance.annotations.get(frame, showAllInterpolationTracks, filters);
        const history = yield moduleInstance.actions.get();

        yield put(mergeAnnotationsSuccess(states, history));
    } catch (error) {
        yield put(mergeAnnotationsFailed(error))
    }
}
function* groupAnnotationsAsync(action){
    const { moduleInstance, frame, statesToGroup } = action.payload;
    try {
        const { filters, showAllInterpolationTracks } = receiveAnnotationsParameters();
        const reset = getStore().getState().annotation.annotations.resetGroupFlag;

        yield moduleInstance.annotations.group(statesToGroup, reset);
        yield moduleInstance.annotations.updateViewID();
        const states = yield moduleInstance.annotations.get(frame, showAllInterpolationTracks, filters);
        const history = yield moduleInstance.actions.get();

        yield put(groupAnnotationsSuccess(states, history))
    } catch (error) {
        yield put(groupAnnotationsFailed(error))
    }
}
function* splitAnnotationAsync(action){
    const {moduleInstance, frame, stateToSplit} = action.payload;
    const { filters, showAllInterpolationTracks } = receiveAnnotationsParameters();
    try {
        yield moduleInstance.annotations.split(stateToSplit, frame);
        yield moduleInstance.annotations.updateViewID();
        const states = yield moduleInstance.annotations.get(frame, showAllInterpolationTracks, filters);
        const history = yield moduleInstance.actions.get();

        yield put(splitAnnotationsSuccess(states, history))
    } catch (error) {
        yield put(splitAnnotationsFailed(error))
    }
}
function* removeObjectAsync(action){
    const {objectState, force} = action.payload;
    const {moduleInstance: sessionInstance} = receiveAnnotationsParameters();
    try {
        yield sessionInstance.logger.log(LogType.deleteObject, { count: 1 });
        const { frame, filters, showAllInterpolationTracks } = receiveAnnotationsParameters();

        const removed = yield objectState.delete(frame, force);
        const history = yield sessionInstance.actions.get();
        yield sessionInstance.annotations.updateViewID();
        const updateStates = yield sessionInstance.annotations.get(frame, filters, showAllInterpolationTracks);

        if (removed) {
            yield put(removeObjectSuccess(objectState, history, updateStates));
        } else {
            throw new Error('Could not remove the locked object');
        }
    } catch (error) {
        yield put(removeObjectFailed(error));
    }
}

function* removeAnnotationsAsync(action) {
    const {startFrame, endFrame, delTrackKeyframesOnly} = action.payload;
    try{
        const {
            filters, frame, showAllInterpolationTracks, moduleInstance,
        } = receiveAnnotationsParameters();
        const reviewData = getStore().getState().Review.Cmmn.annotations.data;

        //  todo startframe과 endframe이 다를 때는 ... 어떻게 할지 생각해봐야함
        if (startFrame !== endFrame) return;

        yield moduleInstance.annotations.clear(false, startFrame, endFrame, reviewData, delTrackKeyframesOnly);
        // yield moduleInstance.actions.clear();

        const history = yield moduleInstance.actions.get();
        yield moduleInstance.annotations.updateViewID();
        const states = yield moduleInstance.annotations.get(frame, showAllInterpolationTracks, filters);
        yield put(removeAnnotationsSuccess(history, states))
    } catch (e) {
        yield put(removeAnnotationsFailed(e))
    }
}

function* saveLogAsync(action) {
    try {
        yield logger.save();
        yield put(saveLogSuccess());
    } catch (e) {
        yield put(saveLogFailed(e));
    }
}
function* submitAnnotationAsync(action){
    const { intl, history, dispatch} = action.hooks;
    const { moduleInstance, afterSave} = action.payload;
    const { filters, showAllInterpolationTracks } = receiveAnnotationsParameters();
    const reviewData = getStore().getState().Review.Cmmn.annotations.data;
    yield put(saveAnnotations());
    try {
        const saveJobEvent = yield moduleInstance.logger.log(LogType.saveJob, {}, true);
        yield moduleInstance.frames.save();
        const result = yield moduleInstance.annotations.save({reviewData});
        if (result.success) {
            // Todo 다음 데이터 할당
        }
        yield saveJobEvent.close();
        yield put(saveLog());

        const { frame } = receiveAnnotationsParameters();
        const states = yield moduleInstance.annotations.get(frame, showAllInterpolationTracks, filters);
        if (typeof afterSave === 'function') {
            afterSave();
        }

        yield put(submitAnnotationsSuccess(states, intl, history, dispatch, null));
    } catch (e) {
        console.log('[submitAnntoationAsync] error ',e)
        yield put(submitAnnotationsFailed(e));
    }
}

function* submitAnnotatinosSuccessSync(action) {
    const { intl, history, dispatch, form } = action.hooks;
    const labelType = getStore().getState().Label.Cmmn.module.labelType;
    confirmSuccess('label.submitSuccess'
        , 'label.submitSuccessText'
        , () => {
            if (labelType === LABEL_TYPE.CLASSIFICATION_SUMMARY) {
                form.reset();
            }
            goNextAnnotationAsync(action).next()
        } , () => {
            history.goBack();
        }, null, intl);
}
function* skipDataSuccessAsync(action){
    const { intl, history, dispatch } = action.hooks;
    confirmSuccess('label.skipSuccess'
        , 'label.submitSuccessText'
        , () => {
            goNextAnnotationAsync(action).next()
        } , () => {
            history.goBack();
        }, null, intl);
}
export function* goNextAnnotationAsync(action){
    const { intl, history, dispatch } = action.hooks;
    const { moduleInstance } = receiveAnnotationsParameters();
    const module = {
        moduleId: moduleInstance.id,
        name: moduleInstance.name,
        type: moduleInstance.type
    };
    const doAfter = {
        prePage: history.location.pathname,
        do: {
            "11": (module, frame) => {
                // 데이터 할당 (다음 데이터)
                dispatch(changeFrame(module, frame));
            },
            "12": (module, frame) => {
                // 데이터 할당 (다음 데이터)
                dispatch(changeFrame(module, frame));
            },
            "13": async (callback) => {
                const target = await callback();
                if (target != null){
                    dispatch(changeFrame(module, target));
                }
            },
            "04": () => {
                alertError('joined.noDataAlert'
                    ,'joined.noDataAlertText' ,() => {
                        history.goBack();
                    } ,null ,null
                    ,intl)
            },
            "05": () => {
                alertError('joined.noProgressAlert'
                    ,'joined.noDataAlertText' ,() => {
                        history.goBack();
                    } ,null ,null
                    ,intl)
            },
        }
    };
    yield assignCheck(module, {intl, history}, doAfter);
}
function* skipDataAsync(action){
    const { intl, history, dispatch } = action.hooks;
    const { moduleInstance, skipCn, afterSave} = action.payload;
    const frame = moduleInstance.frame;
    const skipRsn = { skipRsn: skipCn }
    const issueCn = JSON.stringify(skipRsn);
    try {
        const param = {
            moduleId: moduleInstance.id,
            datasetId: frame.datasetId,
            fileSeq: frame.fileSeq,
            issueCn
        }

        const result = yield skipOnServer(param);
        if (result.success) {
            yield put(setForceExitAnnotationFlag(true));
            yield put(skipDataSuccess(intl, history, dispatch));
            if (typeof afterSave === 'function') {
                afterSave();
            }
        }
    } catch (e) {
        console.log('[skipDataAsync] error ',e)
        yield put(skipDataFailed(e));
    }
}
function* closeModuleAction(action){
    const { moduleInstance } = receiveAnnotationsParameters();
    if (moduleInstance) {
        module = yield moduleInstance.close();
    }
    yield put(closeModuleSuccess())
}
function* undoActionAsync(action){
    try {
        const {module, frame} = action.payload;
        const state = getStore().getState();
        const {filters, showAllInterpolationTracks} = receiveAnnotationsParameters();
        // TODO: use affected IDs as an optimization
        const [undo] = state.Label.Cmmn.annotations.history.undo.slice(-1);
        const undoLog = yield module.logger.log(
            LogType.undoAction,
            {
                name: undo[0],
                frame: undo[1],
                count: 1,
            },
            true,
        );

        yield module.actions.undo();
        const history = yield module.actions.get();
        yield module.annotations.updateViewID();
        const states = yield module.annotations.get(frame, showAllInterpolationTracks, filters);
        const frameData = yield module.frames.get(frame);
        const [minZ, maxZ] = computeZRange(states);
        yield undoLog.close();

        yield put(undoActionSuccess(
            history,
            states,
            minZ,
            maxZ,
            frameData,
        ))
    } catch (error) {
        yield put(undoActionFailed(error));
    }
}
function* redoActionAsync(action){
    try {
        const {module, frame} = action.payload;
        const state = getStore().getState();
        const { filters, showAllInterpolationTracks } = receiveAnnotationsParameters();

        // TODO: use affected IDs as an optimization
        const [redo] = state.Label.Cmmn.annotations.history.redo.slice(-1);
        const redoLog = yield module.logger.log(
            LogType.redoAction,
            {
                name: redo[0],
                frame: redo[1],
                count: 1,
            },
            true,
        );

        yield module.actions.redo();
        const history = yield module.actions.get();
        yield module.annotations.updateViewID();
        const states = yield module.annotations.get(frame, showAllInterpolationTracks, filters);
        const [minZ, maxZ] = computeZRange(states);
        const frameData = yield module.frames.get(frame);
        yield redoLog.close();
        yield put(redoActionSuccess(
            history,
            states,
            minZ,
            maxZ,
            frameData,))

        // 프레임 여러개 일때 프레임 이동의 REDO
        // const redoOnFrame = redo[1];
        //
        // if (frame !== redoOnFrame || ['Removed frame', 'Restored frame'].includes(redo[0])) {
        //     yield(changeFrameAsync(redoOnFrame, undefined, undefined, true));
        // }
    } catch (error) {
        yield put(redoActionFailed(error))
    }
}
const ShapeTypeToControl: Record<ShapeType, ActiveControl> = {
    [ShapeType.RECTANGLE]: ActiveControl.DRAW_RECTANGLE,
    [ShapeType.POLYLINE]: ActiveControl.DRAW_POLYLINE,
    [ShapeType.POLYGON]: ActiveControl.DRAW_POLYGON,
    [ShapeType.POINTS]: ActiveControl.DRAW_POINTS,
    [ShapeType.CUBOID]: ActiveControl.DRAW_CUBOID,
    [ShapeType.ELLIPSE]: ActiveControl.DRAW_ELLIPSE,
    [ShapeType.SKELETON]: ActiveControl.DRAW_SKELETON,
    [ShapeType.BRUSH]: ActiveControl.DRAW_MASK,
    [ShapeType.MASK]: ActiveControl.DRAW_MASK,
};

function* repeatDrawShapeAsync(action){
    const {
        canvas: { instance: canvasInstance },
        annotations: { states },
        module: { labels, instance: moduleInstance },
        player: {
            frame: { number: frameNumber },
        },
        drawing: {
            activeInteractor,
            activeObjectType,
            activeLabelID,
            activeShapeType,
            activeNumOfPoints,
            activeRectDrawingMethod,
            activeCuboidDrawingMethod,
        },
    } = getStore().getState().Label;

    if (!activeShapeType) return;

    let activeControl = ActiveControl.CURSOR;
    if (activeInteractor && canvasInstance instanceof Canvas) {
        if (activeInteractor.kind.includes('tracker')) {
            canvasInstance.interact({
                enabled: true,
                shapeType: 'rectangle',
            });
            yield put(interactWithCanvas(activeInteractor, activeLabelID));
            yield put(switchToolsBlockerState({ buttonVisible: false }));
        } else {
            canvasInstance.interact({
                enabled: true,
                shapeType: 'points',
                ...activeInteractor.params.canvas,
            });
            yield put(interactWithCanvas(activeInteractor, activeLabelID));
        }

        return;
    }

    activeControl = ShapeTypeToControl[activeShapeType];
    yield put(repeatDrawShape(activeControl));

    if (canvasInstance instanceof Canvas) {
        canvasInstance.cancel();
    }

    const [activeLabel] = labels.filter((label: any) => label.id === activeLabelID);
    if (!activeLabel) {
        throw new Error(`Label with ID ${activeLabelID}, was not found`);
    }

    if (activeObjectType === ObjectType.TAG) {
        const tags = states.filter((objectState: any): boolean => objectState.objectType === ObjectType.TAG);
        if (tags.every((objectState: any): boolean => objectState.label.id !== activeLabelID)) {
            const objectState = new core.classes.ObjectState({
                objectType: ObjectType.TAG,
                label: labels.filter((label: any) => label.id === activeLabelID)[0],
                frame: frameNumber,
            });
            yield put(createAnnotations(moduleInstance, frameNumber, [objectState]));
        }
    } else if (canvasInstance) {
        canvasInstance.draw({
            enabled: true,
            rectDrawingMethod: activeRectDrawingMethod,
            cuboidDrawingMethod: activeCuboidDrawingMethod,
            numberOfPoints: activeNumOfPoints,
            shapeType: activeShapeType,
            crosshair: [ShapeType.RECTANGLE, ShapeType.CUBOID, ShapeType.ELLIPSE].includes(activeShapeType),
            skeletonSVG: activeShapeType === ShapeType.SKELETON ? activeLabel.structure.svg : undefined,
        });
    }
}
function* redrawShapeAsync(action){
    const {
        annotations: { activatedStateID, states },
        canvas: { instance: canvasInstance },
    } = getStore().getState().Label;

    if (activatedStateID !== null) {
        const [state] = states.filter((_state: any): boolean => _state.clientID === activatedStateID);
        if (state && state.objectType !== ObjectType.TAG) {
            const activeControl = ShapeTypeToControl[state.shapeType as ShapeType] || ActiveControl.CURSOR;
            yield put(repeatDrawShape(activeControl));

            if (canvasInstance instanceof Canvas) {
                canvasInstance.cancel();
            }

            // @ts-ignore
            canvasInstance.draw({
                skeletonSVG: state.shapeType === ShapeType.SKELETON ? state.label.structure.svg : undefined,
                enabled: true,
                redraw: activatedStateID,
                shapeType: state.shapeType,
                crosshair: [ShapeType.RECTANGLE, ShapeType.CUBOID, ShapeType.ELLIPSE].includes(state.shapeType),
            });
        }
    }
}
function* pasteShapeAsync(action) {
    const {
        canvas: { instance: canvasInstance },
        module: { instance: moduleInstance },
        player: {
            frame: { number: frameNumber },
        },
        drawing: { activeInitialState: initialState },
    } = getStore().getState().Label;

    if (initialState && canvasInstance) {
        const activeControl = ShapeTypeToControl[initialState.shapeType as ShapeType] || ActiveControl.CURSOR;

        canvasInstance.cancel();
        yield put(pasteShape(activeControl));

        if (initialState.objectType === ObjectType.TAG) {
            const objectState = new core.classes.ObjectState({
                objectType: ObjectType.TAG,
                label: initialState.label,
                attributes: initialState.attributes,
                frame: frameNumber,
            });
            yield put(createAnnotations(moduleInstance, frameNumber, [objectState]));
        } else {
            canvasInstance.draw({
                enabled: true,
                initialState,
                ...(initialState.shapeType === ShapeType.SKELETON ?
                    { skeletonSVG: initialState.label.structure.svg } : {}),
            });
        }
    }
}
function* copyShape(action){
    const module = getStore().getState().Label.Cmmn.module.instance;
    module.logger.log(LogType.copyObject, { count: 1 });
}

function* loadPrevFileObjectsAsync(action){
    const {module, frame} = action.payload;
    const param = {
        moduleId: module.id,
        datasetId: frame.datasetId,
        fileSeq: frame.fileSeq,
    }
    try {
        const result = yield getExFilePath(param);
        if (result.success) {
            const data = result.data;
            if (data.json){
                yield module.annotations.import(JSON.parse(data.json))

                yield module.annotations.updateViewID();
                const states = yield module.annotations.get(0, frame.datasetId, frame.fileSeq,);

                const history = yield module.actions.get();
                yield put(loadPrevFileObjectsSuccecss(states, history))
            }
        }
    } catch (e) {
        loadPrevFileObjectsFailed(e)
    }

}
function* getAutoLabelAnnotationAsync(action){
    const {targetClassId, frameData, module} = action.payload;
    const {moduleId, datasetId, fileSeq} = frameData;
    try {
        yield put(setLoading(true));
        const result = yield getBoxPoints({targetClassId, moduleId, datasetId, fileSeq});
        const points = result.data;
        if (points) {
            yield module.annotations.importFromModel(module.labels[0], points);

            yield module.annotations.updateViewID();
            const states = yield module.annotations.get(0, datasetId, fileSeq);

            const history = yield module.actions.get();
            yield put(getAutoLabelAnnotationSuccess(states, history))
        }

        yield put(setLoading(false));
    } catch (e) {
        yield put(getAutoLabelAnnotationFailed(e))
    }
}
function* changeGroupColorAsync(action){
    const { group, color } = action.payload;
    const state = getStore().getState();
    const groupStates = state.Label.Cmmn.annotations.states.filter(
        (_state: any): boolean => _state.group.id === group,
    );
    for (const objectState of groupStates) {
        objectState.group.color = color;
    }
    yield put(updateAnnotations(groupStates))
}

function* checkValidation(action){
    const {intl} = action.payload;
    let isError = false;
    // 1. 제출 도형 최소 조건
    const {
        filters, frame, showAllInterpolationTracks, moduleInstance,
    } = receiveAnnotationsParameters();
    const reviewData = getStore().getState().Review.Cmmn.annotations.data;
    const objectMin = moduleInstance.countLimit.objectMin;
    const statistics = yield moduleInstance.annotations.statistics(reviewData);
    const shapeCount = statistics === null ? yield put(collectStatistics(moduleInstance)) : statistics.totalData.total;
    if (objectMin !== null && (objectMin > shapeCount)) {
        isError = true;
        const param = {cnt: objectMin};
        alertError('label.underDrawLimitAlert', 'label.underDrawLimitAlertText',
            null, param, null, intl);
        return;
    }

    // 2. 속성 VALIDATION CHECK
    const instNotAttrValidCnt = statistics.instanceNotAttrValidCnt;
    const instNotEditedCnt = statistics.instanceNotEditedCnt;
    const classNotEditedCnt = statistics.classesNotEditedCnt;
    if (instNotEditedCnt + classNotEditedCnt > 0) {
        isError = true;
        const param = {instanceCount: instNotAttrValidCnt+instNotEditedCnt, classCount: classNotEditedCnt}
        alertError('label.editCntLimitAlert', 'label.editCntLimitAlertText',
            null, param, null, intl);
    // } else if (instNotAttrValidCnt > 0 && (instNotEditedCnt + classNotEditedCnt) > 0) {
    //     isError = true;
    //     alertError('label.validCntLimitAlert', 'label.validCntLimitAlertText',
    //         null, null, null, intl);
    } else if (instNotAttrValidCnt > 0) {
        isError = true;
        alertError('label.attrCntLimitAlert', 'label.attrCntLimitAlertText',
            null, null, null, intl);
    }
    yield put(checkValidationSuccess(isError, instNotAttrValidCnt, instNotEditedCnt, classNotEditedCnt));
}
/**
 * Watchers
 */
// 이제 SEARCH_USER_REQ 액션을 감지하는 함수를 userSaga에 세팅한다.
function* watchGetModule(){
    // 액션을 탐지하는 함수
    yield takeLatest(GET_MODULE, getModuleAsync);
}
function* watchChangeFrame(){
    yield takeLatest(CHANGE_FRAME, changeFrameAsync);
}
function* watchUpdateAnnotation(){
    yield takeEvery(UPDATE_ANNOTATIONS, updateAnnotationsAsync);
}
function* watchCreateAnnotations(){
    yield takeLatest(CREATE_ANNOTATIONS, createAnnotationsAsync);
}
function* watchCollectStatistics(){
    yield takeEvery(COLLECT_STATISTICS, collectStatisticsAsync);
}
function* watchFetchAnnotations(){
    yield takeLatest(FETCH_ANNOTATIONS, fetchAnnotationsAsync);
}
function* watchMergeAnnotations(){
    yield takeEvery(MERGE_ANNOTATIONS, mergeAnnotationsAsync);
}
function* watchGroupAnnotations(){
    yield takeEvery(GROUP_ANNOTATIONS, groupAnnotationsAsync);
}
function* watchSplitAnnotations(){
    yield takeEvery(SPLIT_ANNOTATIONS, splitAnnotationAsync);
}

function* watchRemoveObject(){
    yield takeLatest(REMOVE_OBJECT, removeObjectAsync);
}
function* watchRemoveAnnotations() {
    yield takeLatest(REMOVE_ANNOTATIONS, removeAnnotationsAsync)
}
function* watchSubmitAnnotations() {
    yield takeEvery(SUBMIT_ANNOTATIONS, submitAnnotationAsync);
}
function* watchSubmitAnnotationSuccess(){
    yield takeEvery(SUBMIT_ANNOTATIONS_SUCCESS, submitAnnotatinosSuccessSync);
}
function* watchSkipData() {
    yield takeEvery(SKIP_DATA, skipDataAsync);
}
function* watchSkipDataSuccess() {
    yield takeEvery(SKIP_DATA_SUCCESS, skipDataSuccessAsync);
}
function* watchCheckValidation() {
    yield takeEvery(CHECK_VALIDATION, checkValidation);
}
function* watchSaveLog() {
    yield takeLatest(SAVE_LOGS, saveLogAsync);
}
function* watchCloseModule() {
    yield takeLatest(CLOSE_MODULE, closeModuleAction);
}
function* watchCloseModuleSuccess(){
    yield takeEvery(CLOSE_MODULE_SUCCESS, resetSession);
}
function* watchUndoAction(){
    yield takeEvery(UNDO_ACTION, undoActionAsync);
}
function* watchRedoAction(){
    yield takeEvery(REDO_ACTION, redoActionAsync);
}
function* watchRedrawShapeAction(){
    yield takeLatest(REDRAW_SHAPE, redrawShapeAsync);
}
function* watchRepeatDrawShapeAction(){
    yield takeEvery(REPEAT_DRAW_SHAPE_ACTION, repeatDrawShapeAsync)
}
function* watchPasteShapeAction(){
    yield takeEvery(PASTE_SHAPE_ACTION, pasteShapeAsync)
}
function* watchCopyShape(){
    yield takeEvery(COPY_SHAPE, copyShape)
}
function* watchChanceGroupcolor(){
    yield takeEvery(CHANGE_GROUP_COLOR, changeGroupColorAsync)
}
function* watchLoadPrevFileObjects(){
    yield takeEvery(LOAD_PREV_FILE_OBJECTS, loadPrevFileObjectsAsync)
}
function* watchGetAutoLabelAnnotations(){
    yield takeLatest(GET_AUTO_LABEL_ANNOTATION, getAutoLabelAnnotationAsync)
}
// userSaga를 먼저 만든다. 그 다음 액션을 리슨하는 함수를 만든다.
// 액션을 리슨 한다는 것은 그 액션이 실행됨을 감지하는 함수이다.
function* LabelSaga(){
    // all 함수는 내부배열에 등록된 사가 함수들을 리덕스 사가 미들웨어에 등록하는 부수효과 함수.
    // 이제 세팅되었으니 루트 사가 함수에 세팅한다.
    yield all([
        watchGetModule(),
        watchChangeFrame(),
        watchUpdateAnnotation(),
        watchCreateAnnotations(),
        watchCollectStatistics(),
        watchFetchAnnotations(),
        watchMergeAnnotations(),
        watchGroupAnnotations(),
        watchSplitAnnotations(),
        watchRemoveObject(),
        watchRemoveAnnotations(),
        watchSubmitAnnotations(),
        watchSubmitAnnotationSuccess(),
        watchSkipData(),
        watchSkipDataSuccess(),
        watchCheckValidation(),
        watchSaveLog(),
        watchCloseModule(),
        watchCloseModuleSuccess(),
        watchUndoAction(),
        watchRedoAction(),
        watchRedrawShapeAction(),
        watchRepeatDrawShapeAction(),
        watchPasteShapeAction(),
        watchCopyShape(),
        watchChanceGroupcolor(),
        watchLoadPrevFileObjects(),
        watchGetAutoLabelAnnotations()]);
}

export default LabelSaga;
