import React, {useEffect, useState} from 'react';
import {GetMessage} from "../../util/message";

function PageLeftInfomation(props) {
    const startIdx = (props.currentPage - 1) * props.perPage + 1;
    const endIdx = props.currentPage * props.perPage >= props.totalRows ? props.totalRows : props.currentPage * props.perPage;
    return (
        <>
            {GetMessage("paging.left", {
                "totalRows": props.totalRows
                , "startIdx": startIdx
                , "endIdx": endIdx
            })
            }
        </>
    );
}

export default PageLeftInfomation;