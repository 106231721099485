import {ObjectReviewDetails as ObjectReviewDetailsComponents} from "../../../components/review-workspace/objects-side-bar/object-review-details";
import {connect} from "react-redux";
import ReviewInstance from "../../../work/core/review-instance";

interface OwnProps {
    clientID: number;
    sortedStatesID: number[];
    onOpenCanvasContextMenu(e): void;
}
interface StateToProps {
    rejectRsn: any;
    instData: ReviewInstance;
    choiceCdList: string[];
    reject: boolean | null;
}

interface DispatchToProps {
}

function mapStateToProps(state, own: OwnProps): StateToProps {
    const {clientID} = own;
    const {
        Review: {
            Cmmn: {
                annotations: {
                    reasons: rejectRsn,
                    data: reviewTotalData
                }
            }
        }
    } = state;

    const instData = reviewTotalData.instance ?
                            reviewTotalData.instance[clientID] ?? {reject: null, rsnCd: [], completedError: false, editable: null}
        : {reject: null, rsnCd: [], completedError: false, editable: null};
    const choiceCdList = instData.rsnCd;
    const reject = instData.reject;

    return {
        rejectRsn,
        instData,
        choiceCdList,
        reject
    };
}

function dispatchToProps(dispatch: any): DispatchToProps {
    return {};
}

export default connect(mapStateToProps, dispatchToProps)(ObjectReviewDetailsComponents);
