import React, {useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import button from "bootstrap/js/src/button";
import {
    setPrjctId,
    setUserTy,
    setPrjctOptions,
    setSearchParam,
    setUserNm, setCurrentPage
} from "../../../store/manage/manager/action";
import {
    Card,
    CardBody,
    CardHeader,
    Col,
    Container, DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Input,
    Row,
    UncontrolledDropdown
} from "reactstrap";
import BreadCrumb from "../../../components/common/BreadCrumb";
import {ManagerService} from "../../../apis/api/ManagerService.";
import ReactPaginate from "react-paginate";
import DataTable from "react-data-table-component";
import PageLeftInfomation from "../../../components/common/PageLeftInfomation";
import Select from "react-select";
import {GetMessage, GetMessageIntl} from "../../../util/message";
import {defaultSelectStyle} from "../../../components/constants/select";
import Highlight from "../../../components/features/Highlight";
import {AgGridReact} from "ag-grid-react";
import {ManagerProjectService} from "../../../apis/api/ManagerProjectService";
import {useIntl} from "react-intl";
import {UserUtil} from "../../../util/UserUtil";

function ManagerUser(props) {
    const dis = useDispatch();
    const intl = useIntl();

    const ruleRef = useRef();
    const prjctRef = useRef();
    const [highlightWords, setHighlightWords] = useState([]);

    const coconutUserInfo = JSON.parse(sessionStorage.getItem("coconutUserInfo"));

    const {
        userNm,
        userTy,
        prjctId,
        prjctOptions
    } = useSelector((state) => ({
        userNm: state.ManageManager.userNm,
        userTy: state.ManageManager.userTy,
        prjctId: state.ManageManager.prjctId,
        prjctOptions: state.ManageManager.prjctOptions
    }))

    const userTyOption = [
        { label: GetMessageIntl(intl, "managerUser.ruleAll"), value: ""},
        { label: GetMessageIntl(intl, "login.userRole10"), value: "10" },
        { label: GetMessageIntl(intl, "login.userRole11"), value: "11" },
        { label: GetMessageIntl(intl, "login.userRole12"), value: "12" },
        { label: GetMessageIntl(intl, "login.userRole13"), value: "13" }
    ]

    /*
        TITLE 오른쪽 메인 버튼 컴포넌트
        페이지마다 유동
     */
    const ButtonComponent = () => {
        return(
            <button type="button" className="btn btn-coco-1"
                    onClick={()=>{
                        // managerDis(switchModalFlag())
                    }}
            >
                {GetMessage("managerUser.addManager")}
            </button>
        )
    }

    

    useEffect(() => {
        getPrjctList();
    },[])

    const getPrjctList = async () => {
        const res = await ManagerProjectService.getPrjctListByOrgnztId({
            'orgnztId' : coconutUserInfo.orgnztId
        });
        if(res.data.data){
            let optionArr = [{label:"프로젝트 전체", value:""}]
            res.data.data?.map((item => {
                optionArr.push({label: item.prjctNm, value: item.prjctId});
            }))

            dis(setPrjctOptions(optionArr));
        }
    }
    
    // 기존 State 초기화 -> Param 재저장
    const handleSearch = () => {
        setHighlightWords([]);
        dis(setCurrentPage(1));
        let obj = {};
        if(prjctId !== undefined && prjctId !== ""){
            obj.PRJCT_ID = prjctId;
        }
        if(userNm !== undefined && userNm !== ""){
            obj.USER_NM = userNm;
        }
        if(userTy !== undefined && userTy !== ""){
            obj.USER_TY = userTy;
        }
        setHighlightWords(Object.values(obj))
        dis(setSearchParam(obj));
    }
    // Input, Select 등 State로 조정
    const handleReset = () => {
        dis(setSearchParam({}));
        dis(setCurrentPage(1));
        dis(setUserNm(""));
        dis(setPrjctId(""))
        dis(setUserTy(""))
        setHighlightWords([]);
    }

    return (
        <>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title={GetMessage('menu.MGMR0000')} pageTitle="Laflow" ButtonComponent={ButtonComponent}/>
                    <Row>
                        <Card className="shadow-none mg-b-16">
                            <CardHeader className="border-0 rounded p-0">
                                <Row className="g-3">
                                    <Col xl={4} xxl={2} >
                                        <div className="position-relative form-search">
                                            <Input type="text" className="form-control" id="search-input"
                                                   placeholder={GetMessage('managerUser.searchPlaceHolder')}
                                                   value={userNm||""}
                                                   onChange={(e)=> {
                                                       dis(setUserNm(e.target.value))
                                                   }}
                                            />
                                            <span className="ri-search-line search-widget-icon"></span>
                                            <span className="ri-close-line search-widget-icon search-widget-icon-close d-none" id="search-close-icon"></span>
                                        </div>
                                    </Col>
                                    <Col xl={2} xxl={1}>
                                        <Select
                                            ref={ruleRef}
                                            placeholder={GetMessage("managerUser.ruleAll")}
                                            name="searchParam"
                                            className="mb-0"
                                            styles={defaultSelectStyle}
                                            onChange={(e)=>{
                                                if(e && e.value) {
                                                    dis(setUserTy(e.value));
                                                }else {
                                                    dis(setUserTy(""));
                                                }
                                            }}
                                            value={userTyOption.find(item => item.value === userTy)}
                                            options={userTyOption}
                                            isSearchable={false}
                                        />
                                    </Col>
                                    <Col xl={2} xxl={2}>
                                        <Select
                                            ref={prjctRef}
                                            placeholder={GetMessage("managerUser.prjctAll")}
                                            name="searchParam"
                                            className="mb-0"
                                            styles={defaultSelectStyle}
                                            value={(prjctOptions || []).find(item => item.value === prjctId)}
                                            onChange={(e) => {
                                                dis(setPrjctId(e.value))
                                            }}
                                            defaultValue={prjctOptions[0] || ""}
                                            options={prjctOptions}
                                            isSearchable={true}
                                        />
                                    </Col>
                                    <Col xl={1} className={"align-self-center"} style={{maxWidth:"75px"}}>
                                        <div className={"d-flex"}>
                                            <button className="btn btn-coco-outline-1"
                                                    onClick={(e) => {handleSearch()}}>
                                                {GetMessage("common.apply")}
                                            </button>
                                        </div>
                                    </Col>
                                    <Col className={"pd-0 align-self-center"}>
                                        <div className={"d-flex"}>
                                            <i  className="ri-refresh-line text-coco-gray-200 fs-24 align-self-auto"
                                                onClick={()=>{handleReset()}
                                                }></i>
                                        </div>
                                    </Col>
                                </Row>
                            </CardHeader>
                        </Card>
                        {/* 기본 레이아웃이 같다면 리스트만 분기해서 들어가자 */}
                        <ManagerUserList highlightWords={highlightWords}/>
                    </Row>
                    {/*{*/}
                    {/*    modalFlag === true ? <DatasetAddModal /> : null*/}
                    {/*}*/}
                </Container>
            </div>
        </>
    );
}


function ManagerUserList(props) {
    const intl = useIntl();
    const dis = useDispatch();

    const {
        searchParam,
        currentPage
    } = useSelector((state) => ({
        searchParam : state.ManageManager.searchParam,
        currentPage : state.ManageManager.currentPage
    }))
     const [datasets, setDatasets] = useState();
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);

    function calStartIdx(page) {
        return (page - 1) * perPage;
    }

    const fetchUsers = async (page, size = perPage) => {
        setLoading(true);

        const res = await ManagerService.getManagerList({
            'start': calStartIdx(page),
            'length': size,
            // 'orderParam' : [],
            'searchParam' : searchParam
        });
        if(res.data.data){
            setDatasets(res.data.data);
            setTotalRows(res.data.recordsTotal);
        }

        setLoading(false);
    };


    useEffect(() => {
        let page = 1;
        if(currentPage > 1){
            page = currentPage
        }
        dis(setCurrentPage(page));
        fetchUsers(page);
    }, [searchParam]);


    const handlePageChange = page => {
        let pageIdx = page.selected + 1;
        fetchUsers(pageIdx);
        dis(setCurrentPage(pageIdx));
    };

    // const handlePerRowsChange = async (newPerPage, page) => {
    //     console.log("============")
    //     console.log(`newPerPage : ${newPerPage}, page : ${page}`);
    //     fetchUsers(page, newPerPage);
    //     setPerPage(newPerPage);
    // };
    const ActionCell = (props) => {
        return(
            <div>
                <UncontrolledDropdown>
                    <DropdownToggle
                        href="#"
                        className="btn-soft-secondary btn-sm dropdown"
                        tag="button"
                        style={{lineHeight: "17px"}}
                    >
                        <i className="ri-more-fill align-middle"></i>
                    </DropdownToggle>
                    <DropdownMenu container={'body'} className="dropdown-menu-end" style={{lineHeight: "17px"}}>
                        <DropdownItem className="dropdown-item" href="#"
                                      onClick={() => {}}
                        >
                            {GetMessage('ui.U00075')}
                        </DropdownItem>
                        <DropdownItem
                            className="dropdown-item edit-item-btn"
                            href="#"
                            onClick={() => {}}
                        >
                            {GetMessage('ui.U00013')}
                        </DropdownItem>
                        <DropdownItem
                            className="dropdown-item remove-item-btn"
                            href="#"
                            onClick={() => {}}
                        >
                            {GetMessage('ui.U00137')}
                        </DropdownItem>
                        <DropdownItem
                            className="dropdown-item remove-item-btn"
                            href="#"
                            onClick={() => {}}
                        >
                            {GetMessage('ui.U00135')}
                        </DropdownItem>
                    </DropdownMenu>
                </UncontrolledDropdown>
            </div>
        )
    }

    const PrjctAuthCell = ({ prjctArr }) => {
        return (
            <div>
                <UncontrolledDropdown>
                    {prjctArr[0][0][1]}&nbsp;
                    <DropdownToggle
                        href="#"
                        className="btn-soft-secondary btn-sm dropdown"
                        tag="button"
                        style={{lineHeight: "17px"}}
                    >
                        +{prjctArr[0].length}
                    </DropdownToggle>
                    <DropdownMenu container={'body'} className="dropdown-menu-end" style={{lineHeight: "17px"}}>
                        {
                            prjctArr[0].map((item, idx) => {
                                const prjctNm = item[1];
                                return(
                                    <DropdownItem key={idx} className="dropdown-item" href="#"
                                                  onClick={() => {}}>
                                        {prjctNm}
                                    </DropdownItem>
                                )
                            })
                        }
                    </DropdownMenu>
                </UncontrolledDropdown>
            </div>
        )
    }

    const [defaultColDef] = useState({
        autoHeight: true,
        resizable: true,
        cellClass: 'cell-wrap-text',
    })


    const [columnDefs] = useState([
        {
            field: 'userNm',
            headerName: GetMessageIntl(intl, "managerUser.name"),
            cellClass : "firstCell",
            flex: '10%',
            cellRendererParams: {
                highlightWords : props.highlightWords
            },
            cellRenderer: param => {
                return (
                    <>
                        <div className={"d-flex"}>
                            <i className="ri-account-circle-line" style={{color: param.data.fontCode, fontSize:"19px", marginRight:"5px"}}></i>
                            <Highlight targetText={param.data.userNm} highlightWords={param.highlightWords}/>
                        </div>
                    </>
                )
            }
        },
        {
            field: 'userId',
            headerName: GetMessageIntl(intl, "managerUser.email"),
            flex: '23%',
        },
        {
            field: 'moduleTy',
            headerName: GetMessageIntl(intl, "managerUser.role"),
            flex: '10%',
            cellRenderer: params => {
                return (
                    <div>{UserUtil.strFromUserTy(intl, params.data.userTy)}</div>
                );
            }
        },
        {
            field: 'labelSttus',
            headerName: GetMessageIntl(intl, "managerUser.sttus"),
            flex: '7%',
            tooltipField: 'labelSttus',
            cellRenderer: params => {
                return (
                    <div>
                        <span className={"badge badge-soft-info"}>{GetMessage('ui.U00134')}</span>
                    </div>
                )
            }
        },
        {
            field: 'instanceCount',
            headerName: GetMessageIntl(intl, "managerUser.orgnzt"),
            flex: '8%',
            cellRenderer: params => {
                return (
                    <div>
                        비투엔
                    </div>
                )
            }
        },
        {
            headerName: GetMessageIntl(intl, "managerUser.prjctAuth"),
            flex: '35%',
            cellRenderer: params => {
                const prjctIds = params.data.prjctId.trim().split(",");
                const prjctNms = params.data.prjctNm.trim().split(",");
                let prjctArr = [];
                if(prjctIds){
                    let tmpArr = [];
                    prjctIds.map((item, idx) => {
                        tmpArr.push([item, prjctNms[idx]])
                    })
                    prjctArr.push(tmpArr)
                }
                return (
                    <PrjctAuthCell prjctArr={prjctArr}/>
                )
            }
        },
        {
            field: 'userSeq',
            headerName: GetMessageIntl(intl, "managerUser.action"),
            cellClass: "lastCell",
            editable: false,
            flex: '7%',
            cellRenderer: params => {
                return (
                    <ActionCell params={params}/>
                )
            }
        },
    ]);

    const rowStyle = {
        fontSize :"16px",
        paddingLeft : "32px",
        paddingRight : "32px",
    }
    const gridOption = {
        suppressCellFocus : true,
    }

    let count = Math.ceil(Number((totalRows / perPage)));
    // 0인경우 console에 warning출력    
    if(count == 0){
        count = 1;
    }
    return (
        <>
            <Card className="shadow-none">
                <CardBody className="px-0 pb-0">
                    <div className="ag-theme-alpine" style={{ width: '100%' }}>
                        <AgGridReact
                            rowData={datasets}
                            rowStyle={rowStyle}
                            defaultColDef={defaultColDef}
                            columnDefs={columnDefs}
                            pagination={true}
                            paginationPageSize={perPage}
                            suppressPaginationPanel={true}
                            detailRowAutoHeight={true}
                            suppressRowTransform={true}
                            domLayout={"autoHeight"}
                            gridOptions={gridOption}
                        >
                        </AgGridReact>
                        <Row className='mx-0'>
                            <Col className='d-flex justify-content-start align-items-center fs-16 text-coco-gray-700 fw-500' sm='6'>
                                <PageLeftInfomation
                                    totalRows={totalRows}
                                    perPage ={perPage}
                                    currentPage={currentPage}
                                />
                            </Col>
                            <Col className='d-flex justify-content-end align-items-center' sm='6'>
                                <ReactPaginate
                                    previousLabel={'←'}
                                    nextLabel={'→'}
                                    onPageChange={handlePageChange}
                                    pageCount={count}
                                    forcePage={currentPage - 1}
                                    // breakLabel={'...'}
                                    // pageRangeDisplayed={2}
                                    // marginPagesDisplayed={2}
                                    activeClassName={'active'}
                                    pageClassName={'page-item'}
                                    nextLinkClassName={'page-link'}
                                    nextClassName={'page-item next'}
                                    previousClassName={'page-item prev'}
                                    previousLinkClassName={'page-link'}
                                    pageLinkClassName={'page-link'}
                                    breakClassName='page-item'
                                    breakLinkClassName='page-link'
                                    containerClassName={'pagination react-paginate pagination-custom pagination-sm justify-content-end align-items-center'}
                                />
                            </Col>
                        </Row>
                    </div>
                </CardBody>
            </Card>
        </>
    );
}

export default ManagerUser;