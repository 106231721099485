import React, {useEffect, useMemo, useRef, useState} from "react";
import {
    AccordionItem,
    Collapse, Label,
} from "reactstrap";
import {GetMessageIntl} from "../../../../../util/message";
import AnnotatorView from "../../../../user/label/clsfcSmry/components/taskSideBar/summary/AnnotatorView";
import {useIntl} from "react-intl";
import {Annotations, Relations} from "../../../../../components/common/text-annotator/types";
import {ReviewRejectView} from "../../../../user/label/clsfcSmry/components/taskSideBar/summary/ReviewRejectView";
import {useSelector} from "react-redux";

interface Props{
    labelID: number;
    viewID: number;
    question: any | null;
    label: any | null;
    review: any | null;
    openToggle: any;
    lastIndex: boolean;
}

const StageItemContainer = (props:Props) => {

    const intl = useIntl();

    const {openActiveIndex} = useSelector((state) => ({
        openActiveIndex: state['Details'].stageTabActive.tab,
    }));

    const inputType = props.question.attributes[0].inputType;

    const [uiOptions, setUiOptions] = useState({
        fontSize: 14, // default 20
        defaultSvgPadding: 1, // default 10
        spaceBetweenTextAndSvg: 4, // default 6
        svgWidth: 1.5, // default 3
        spaceBetweenSvgs: 2, // default 2
        spaceBeforeNextLine: 0, // default 10
        relationVerticalOffset: 7, // default 7
        relationHorizontalOffset: 5, // default 5
    });

    const sampleAnnotations: Annotations = [
        {
            key: 'syntax',
            name: 'syntax',
            values: [],
        },
        {
            key: 'other',
            name: 'other',
            values: [],
        }
    ];

    const sampleRelations: Relations = [
        {
            key: 'relation',
            name: 'relation',
            values: [],
            directional: true,
        },
    ];

    const [annotations, setAnnotations] = useState(sampleAnnotations)
    const [relations, setRelations] = useState(sampleRelations)

    const [workHistoryTaggingOpen, setwoprkHistoryTaggingOpen] = useState(props.viewID === openActiveIndex);

    useEffect(()=>{
        if(props.review && inputType == 'text'){
            let syntaxValues = [];

            let otherValues = [];
            props.review.q2.content.map(annotation=> {syntaxValues.push({start: annotation.start, end: annotation.end, index:annotation.id});});
            props.review.q3.content.map(annotation=> {otherValues.push({start: annotation.start, end: annotation.end, index:annotation.id});});


            let syntaxAnnotation = [
                {
                    key: 'syntax',
                    name: 'syntax',
                    values: syntaxValues
                },
                {
                    key: 'other',
                    name: 'other',
                    values: otherValues,
                }
            ];

            setAnnotations(syntaxAnnotation);

            //console.log('syntaxAnnotation', syntaxAnnotation);
        }else{
            setAnnotations(sampleAnnotations)
        }
    },[props.review])

    const onAccordionToggle = (index)=>{
        //setwoprkHistoryTaggingOpen(!workHistoryTaggingOpen)
        props.openToggle(index);
    }

    useEffect(()=>{
        setwoprkHistoryTaggingOpen(props.viewID === openActiveIndex);
    }, [openActiveIndex])

    const thisAnnotatorView = useMemo(
        () => {
            return (
                <AnnotatorView
                    text={props.label.answer[0]}
                    annotations={annotations}
                    relations={[]}
                    onChangeAnnotations={setAnnotations}
                    uiOptions={uiOptions}
                    readOnly={true}
                    showLegend={false}
                    containerId={props.labelID+""}
                />
            )
        },
        [annotations]
    );

    return (
        <AccordionItem className={"border-0"}>
            <div key={props.labelID} className={"accordion-header d-flex align-items-center border-0 pd-x-24 pd-y-14 tx-16 text-coco-gray-700 fw-500 ".concat(workHistoryTaggingOpen?"open":"")} id={`stageItem${props.labelID}`}
                           onClick={()=>onAccordionToggle(props.viewID)} style={{ cursor: "pointer" }}>
                <div className="wd-8p text-center">
                    {/*{...registerLabel(`classItem.${id}.id`, { required: true })}*/}
                    {props.viewID+1}
                </div>
                <div className="wd-76p d-flex align-items-center ps-2 text-overflow">
                    <div className={"ml-8 mr-12"}>
                        {
                            (inputType=='select' || inputType=='checkbox') &&
                                <div className="avatar-title rounded-circle pd-4"
                                     style={{backgroundColor: "#EDE0FF", color: "#9E53FF", height: "20px", width: "20px"}}>
                                    <i className="ri-node-tree tx-12"></i>
                                </div>
                        }
                        { inputType=='text' &&
                                <div className="avatar-title rounded-circle pd-4"
                                     style={{backgroundColor: "#FFF4D7", color: "#FFB900", height: "20px", width: "20px"}}>
                                    <i className="mdi mdi-text-shadow tx-12"></i>
                                </div>
                        }
                    </div>
                    <span className={"text-overflow"}>{props.label.question}</span>

                </div>
                <div className="wd-16p text-center ps-2">
                    {props.review? props.review.reject? <span className={"text-coco-danger-400"}>{GetMessageIntl(intl, "ui.U00066")}</span>: <span className={"text-coco-secondary-700"}>{GetMessageIntl(intl,"label.pass")}</span>: <></> }
                </div>
            </div>
            <Collapse isOpen={workHistoryTaggingOpen} className="accordion-collapse" id={"workHistoryTaggingOpen".concat(props.labelID+"")} >
                <div className={"accordion-body pd-16 pd-b-24 ".concat(!props.lastIndex?"border-bottom-1":"")}>
                    {
                        inputType=='text' &&
                            <div className={"text-coco-gray-700-f"}>
                                <div className={"summary-review-div read-only border-coco-gray-100 border-1 bg-coco-gray-50 border-solid border-radius-4 pd-x-18 pd-y-16"}>
                                    {thisAnnotatorView}
                                </div>
                                {
                                    props.review && props.review.reject &&
                                    <div className={"mt-20"}>
                                        <div className={"fw-semibold mb-8 d-flex align-items-center"}>
                                            <i className={"ri-search-eye-line text-coco-danger-400 mr-4"}/>
                                            {GetMessageIntl(intl,"review.reviewResult")}
                                        </div>
                                        <div className={"summary-review-result-list"}>
                                            {
                                                props.review && props.review.reject &&
                                                <>
                                                    {
                                                        props.review.q1.reject &&
                                                        props.review.q1.content.map((annotation, index)=> {
                                                            return <ReviewRejectView no={index+1} color={"skyblue"} badgeText={GetMessageIntl(intl, "review.missingKeyword")} detail={annotation.details} key={`reviewQ1${index}`}/>
                                                        })
                                                    }
                                                    {
                                                        props.review.q2.reject&&
                                                        props.review.q2.content.map((annotation, index)=> {
                                                            index = index+props.review.q1.content.length+1;
                                                            return <ReviewRejectView no={index} color={"green"} badgeText={annotation.sentence} detail={annotation.details} key={`reviewQ2${index}`}/>
                                                        })
                                                    }
                                                    {
                                                        props.review.q3.reject &&
                                                        props.review.q3.content.map((annotation, index)=> {
                                                            index = index+props.review.q1.content.length+props.review.q2.content.length+1;
                                                            return <ReviewRejectView no={index} color={"purple"} badgeText={annotation.sentence} detail={annotation.details} key={`reviewQ3${index}`}/>
                                                        })
                                                    }
                                                </>
                                            }
                                        </div>
                                    </div>
                                }
                            </div>
                    }
                    { (inputType=='select' || inputType=='checkbox') &&
                        <div className={"border-radius-4 read-only text-coco-gray-700-f"}>
                            {
                                props.review && props.review.reject &&
                                    <div className={"pd-16 bg-coco-danger-50 text-coco-gray-700 tx-16 mb-16"} style={{borderRadius: "7px"}}>
                                        <div className={"fw-semibold mb-8 d-flex align-items-center"}>
                                            <i className={"ri-search-eye-line text-coco-danger-400 mr-4"}/>
                                            {GetMessageIntl(intl,"review.rejectReason")}
                                        </div>
                                        <div className={"fw-500"}>
                                            :{props.review.q1.content[0].details}
                                        </div>
                                    </div>
                            }

                            <div className={"pd-x-16"}>
                            {
                                props.question.attributes.map((element, index) => {
                                    const values = element.values;
                                    return (
                                        values?.map((value, idx) => {
                                            return (
                                                <div className="form-check form-radio-coco mb-2"
                                                     key={`${value}-${idx}-${inputType=='checkbox'?'checkbox' :'select'}`}
                                                >
                                                    <fieldset disabled={true}>
                                                        <input
                                                            className="form-check-input"
                                                            type={inputType=='checkbox'?'checkbox' :'radio'}
                                                            id={`${props.labelID}-${value}`}
                                                            name={`shapes[${props.labelID}]`}
                                                            value={value}
                                                            defaultChecked={props.label.answer.includes(value)}
                                                        />
                                                        <Label className="form-check-label"
                                                               htmlFor={`${props.labelID}-${value}`}>
                                                            {value}
                                                        </Label>
                                                    </fieldset>
                                                </div>
                                            )
                                        })
                                    )
                                })
                            }
                            </div>
                        </div>
                    }
                    {
                        !props.lastIndex &&
                            <div className={"d-flex justify-content-end mt-16"}>
                                <button type="button" className={"btn btn-coco-ghost-1"} onClick={()=>{
                                    onAccordionToggle(props.viewID+1)
                                }}>
                                    {GetMessageIntl(intl, "ui.U00008")}
                                </button>
                            </div>
                    }


                </div>
            </Collapse>
        </AccordionItem>
    );
}

export default React.memo(StageItemContainer);