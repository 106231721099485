// @ts-nocheck
import {Card, CardBody, CardFooter} from "reactstrap";
import {MD_TY_CD, REVIEW_TASK_STTUS_CD} from "../../../annotation/image/const";
import React, {useEffect} from "react";
import "./style.scss";
import {StepWizard} from "../../containers/taskSideBar/StepWizard";
import {useForm} from "react-hook-form";
import {useDispatch, useSelector} from "react-redux";
import {NextStepFooter} from "../../containers/taskSideBar/NextStepFooter";
import {AttributeType} from "../../../annotation/image/work/core/enums";
import {alertError} from "../../../../../../util/AlertUtil";
import {useIntl} from "react-intl";
import {useHistory} from "react-router";
import {submitAnnotations as submitReview} from "../../../../../../store/review/action";
import {saveOnServer} from "../../../annotation/image/work/service";
import {submitAnnotationsSuccess, updateSideBarTab} from "../../../../../../store/label/action";

function transFormData(data, total) {
    const parsedData = data.shapes.map((shape, index) => {
        const label = total.find(label => label.id === index);

        return {
            id: label.id,
            question: label.question,
            answer: Array.isArray(shape) ? shape : [shape]
        };
    });

    return {
        shapes: parsedData.filter(n => n)
    };
}

export const TaskSideBar = (): JSX.Element => {
    const history = useHistory();
    const intl = useIntl();
    const dispatch = useDispatch();
    const {
        Label: {
            Cmmn: {
                module: {
                    moduleId,
                    labels,
                    type: moduleType,
                    status: moduleStatus,
                },
                frames: {
                    frame: {datasetId, fileSeq},
                },
                annotations:{
                    states: labelStates
                }
            }
        }
    } = useSelector(state => state);

    const clsfcSmryForm = useForm({
        criteriaMode: "all",
        defaultValues: {}
    })

    const {
        register,
        handleSubmit,
        reset,
        control,
        setError,
        watch,
        formState: {errors}
    } = clsfcSmryForm;

    // Submit Button Event
    // 추후 데이터 파싱 분기가 필요할 경우 Event 만 전달
    const handleLabelSave = async (data) => {
        const forwardData = transFormData(data, labels);
        console.log("forwardData : ", forwardData)

        // 2. Submit to server
        const param = {
            moduleId: moduleId,
            datasetId: datasetId,
            fileSeq: fileSeq,
            annotation: JSON.stringify(forwardData),
            issueCn: null   // -> todo 여기에 무얼 넣어야할까.
        }
        const result = await saveOnServer(param);
        const { success, errorMessage } = result.data;
        if (!success) {
            alertError("", "", null, null, errorMessage, intl);
            return;
        } else {
            // 작업한 데이터 저장, 다음 라벨링 페이지로 이동 로직
            dispatch(submitAnnotationsSuccess(forwardData.shapes, intl, history, dispatch, clsfcSmryForm))
            console.log('forom reset !!!!');
        }
    }

    useEffect(()=>{
        dispatch(updateSideBarTab(1));
    },[datasetId, fileSeq])

    const handleReviewSave = (data, event) => {
        // 1. make json
        console.log('handleRevieSave', data);
        let reject = false;
        let submitData = {
            classes: labels.map(label => {
                const lOrder = label.order;
                const inputType = label.attributes[0].inputType;
                const reviewData = data.review.classes[lOrder];

                if (inputType === AttributeType.TEXT) {
                    // 속성타입이 text일 경우
                    delete reviewData.q1.least;
                    delete reviewData.q2.least;
                    delete reviewData.q3.least;

                    if(reviewData.reject === false){
                        reviewData.q1.content = [];
                        reviewData.q2.content = [];
                        reviewData.q3.content = [];
                    }else{
                        if(reviewData.q1.reject === false){
                            reviewData.q1.content = [];
                        }

                        if(reviewData.q2.reject === false){
                            reviewData.q2.content = [];
                        }else{
                            // null 인 값 빼주기
                            // 비어져 있는 Array의 항목은 삭제
                            let content = reviewData.q2.content.filter(item => item != null);
                            reviewData.q2.content = content;
                        }

                        if(reviewData.q3.reject === false){
                            reviewData.q3.content = [];
                        }else{
                            // null 인 값 빼주기
                            let content = reviewData.q3.content.filter(item => item != null);
                            reviewData.q3.content = content;
                        }
                    }


                } else if ([AttributeType.SELECT, AttributeType.CHECKBOX].includes(inputType)) {
                    delete reviewData.q1.content[0].completedError;
                    delete reviewData.q1.content[0].touch;
                    if (reviewData.reject === false) {
                        reviewData.q1.content = [];
                    }
                }

                if (reviewData.reject === true) {
                    reject = true;
                }
                return reviewData;
            })
        };

        console.log('submitData', JSON.stringify(submitData))

        // 2. Submit to server
        dispatch(submitReview(submitData, reject ? REVIEW_TASK_STTUS_CD.REJECT : REVIEW_TASK_STTUS_CD.PASS,
                                    intl, history, dispatch, clsfcSmryForm))

    }

    const onHandleSubmit = (data, event) => {
        console.log('onHandleSubmit', data);
        console.log('onHandleSubmit formState', clsfcSmryForm.formState);
        if (moduleType === MD_TY_CD.LABEL) {
            handleLabelSave(data, event);
        } else if (moduleType === MD_TY_CD.REVIEW) {
            handleReviewSave(data, event);
        }
    }

    const onHandleValidationError = (errors, event) => {
        // Todo Add error modal

        // 받아온 데이터 == 수정완료 데이터 일 때 표기
        // if(moduleType === MD_TY_CD.LABEL){
        //     const matchingIds = getModifiedIds(clsfcSmryForm.getValues()?.shapes, labelStates);
        //
        //     if(matchingIds.length > 0){
        //         matchingIds.forEach(id => {
        //             setError(`modified.${id}`, {
        //                 type: 'modifideError',
        //                 message: '데이터가 수정완료 되었습니다.'
        //             })
        //         })
        //     }
        // }

        console.log('onHandleValidationError', errors)
        console.log('onHandleValidationError data', clsfcSmryForm.getValues())
        if (errors.hasOwnProperty('shapes') || errors.hasOwnProperty('modified')) {
            const param = errors.hasOwnProperty('shapes') ?
                {stages: labels.filter(v => errors.shapes[v.id]).map(l => l.order + 1).join(', ')}
                : {stages: labels.filter(v => errors.modified[v.id]).map(l => l.order + 1).join(', ')};
            alertError('label.editCntLimitAlert', 'review.clsrSmryValidCntLimitAlertText',
                null, param, null, intl);
        }  if (errors.hasOwnProperty('review')){
            const param = {stages: labels.filter(l => errors.review.classes[l.order]).map(l => l.order + 1).join(', ')};
            alertError('review.validCntLimitAlert', 'review.clsrSmryValidCntLimitAlertText',
                null, param, null, intl);
        }
    }

    // 페이지 벗어날 때 Validation 초기화
    // useEffect(() => {
    //
    //     return () => {
    //         clsfcSmryForm.reset();
    //     }
    // })

    return (
        <div className="questions-side-bar">
            <form onSubmit={handleSubmit(onHandleSubmit, onHandleValidationError)}>
                <Card className="label-object-menu-div">
                    {/*
                        Module Type 에 맞게 이벤트 분기
                        이벤트 분기 OR 함수 내부에서 분기
                    */}
                        <CardBody className="listContainer px-0" style={{height: 'calc(100vh-70px-59px)'}}>
                            {/*  질문 컴포넌트  */}
                            <StepWizard clsfcSmryForm={clsfcSmryForm}/>
                        </CardBody>

                    <CardFooter>
                        <NextStepFooter
                            type={moduleType}
                            moduleStatus={moduleStatus}
                            clsfcSmryForm={clsfcSmryForm}
                            onHandleSubmit={handleLabelSave}/>
                    </CardFooter>
                </Card>
            </form>
        </div>
    )
}