// @ts-nocheck
import ObjectsListContainer
    from "../../../user/label/annotation/image/containers/review-workspace/objects-side-bar/objects-list";
import CanvasContextMenuContainer from "../../../user/label/annotation/image/containers/canvas/canvas-context-menu";
import React, {useEffect, useState} from "react";
import CanvasHeader from "./header/CanvasHeader";
import {Workspace} from "../../../../store/label";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router";
import {closeModule} from "../../../../store/label/action";
import {
    closeWorkflow,
    getWorkflow,
    setLeftSidebarWidth,
    setRightSidebarWidth
} from "../../../../store/dataset/details/action";
import RightSideBar from "./right-side-bar/right-side-bar";
import LeftSideBar from "./left-side-bar/left-side-bar";
import {alertError} from "../../../../util/AlertUtil";
import {useIntl} from "react-intl";
import {LABEL_TYPE} from "../../../user/label/annotation/image/const";
import {ClsfcSmryRightSideBar} from "./right-side-bar/ClsfcSmryRightSideBar";
import {CanvasLayout} from "../../../user/label/cmmn/components/CanvasLayout.tsx"
import styled from "styled-components";
import {CustomHandle} from "../../../test/CustomHandle";
import {ResizableBox} from "react-resizable";
import {Simulate} from "react-dom/test-utils";
import doubleClick = Simulate.doubleClick;



const ManagerCanvasContentDiv = styled.div`
  .video-container{
    padding: 32px 32px;
  }
`

const ResizableContainer = styled.div`
  .data-list-bar{
    width: ${(props) => `${props.left}px`} !important;
  }
  
  .questions-side-bar{
    width: ${(props) => `${props.right}px`} !important;
  }
  
  .label-object-menu-div{
    min-width: 0px;
  }
  
  .video-control-bar{
    width: ${(props) => `${props.middle}px`} !important;
  }
`
function ManagerWorkPage() {
    const dispatch = useDispatch();
    const history = useHistory();
    const intl = useIntl();
    const heigth = window.innerHeight * 0.92;
    const width = window.innerWidth;

    const {
        Label: {
            Cmmn: {module: {labelType, fetching}}
        },
        Details: {
            workflow,
            sidebar
        }
    } = useSelector(state => state);

    useEffect(() => {
        // saveLogs();
        const root = window.document.getElementById('root');
        if (root) {
            root.style.minHeight = '720px';
        }

        return () => {
            // saveLogs();
            if (root) {
                root.style.minHeight = '';
            }

            if (!history.location.pathname.includes('/details')) {
                dispatch(closeModule());
                dispatch(closeWorkflow());
            }
        };
    }, []);

    useEffect(() =>{
        if (workflow.id === null || workflow.name === null) {
            const sWrkflow = JSON.parse(sessionStorage.getItem('workflow'));
            const sModule = JSON.parse(sessionStorage.getItem('module'));
            const sFrame = JSON.parse(sessionStorage.getItem('frame'));
            if (!sWrkflow || !sModule || !sFrame) {
                alertError(
                    'alert.invalidAccess',
                    'alert.invalidAccessMsg',
                    () => {
                        history.goBack();
                    },null, null, intl
                )
                // history.goBack();
                return;
            }
            dispatch(getWorkflow(sWrkflow, sModule, sFrame));
        }
    }, [workflow]);

    const [leftSidebar, setLeftSideBar] = useState(true);
    const [rightSidebar, setRightSideBar] = useState(true);
    const [lastResizeTime, setLastResizeTime] = useState(0);
    const [lastClickTime, setLastClickTime] = useState(0);
    const [isDoubleClick, setIsDoubleClick] = useState(false);
    const leftSidebarWidth = sidebar.leftSidebarWidth;
    const rightSidebarWidth = sidebar.rightSidebarWidth;

    // const [originLeftSidebarWidth, setOriginLeftSidebarWidth] = useState();
    const handleLeftResizeStart = (event) => {
        console.log("handleLeftResizeStart")
        setLastResizeTime(prev => {
            return Date.now();
        });
    }
    const handleLeftResize = (event) => {
        setLeftSideBar(true);
        const maxWidth = 500;
        const x = event.x;

        const newWidth = Math.min(maxWidth, x);
        dispatch(setLeftSidebarWidth(newWidth));
    }

    const handleLeftResized = (event) => {
        console.log("handleLeftResized")
        if (leftSidebarWidth < 298) {
            setLeftSideBar(false);
            dispatch(setLeftSidebarWidth(0));
        }
    }

    useEffect(() => {
        if(isDoubleClick){
            setLeftSideBar(false);
            dispatch(setLeftSidebarWidth(0));
            setIsDoubleClick(prev => {
                return false;
            });
        }
    }, [isDoubleClick])

    const handleClick = () => {
        // 리사이즈 이벤트가 마지막으로 발생한 시간과 현재 시간을 비교
        if (Date.now() - lastResizeTime > 200) {
            return;
        }
        setLastClickTime(prev => {
            return Date.now();
        })

        if(!isDoubleClick){
            setTimeout(() => {
                if (leftSidebarWidth < 20) {
                    setLeftSideBar(true);
                    dispatch(setLeftSidebarWidth(298));
                } else if (leftSidebarWidth === 298) {
                    dispatch(setLeftSidebarWidth(500));
                } else if (leftSidebarWidth === 500) {
                    dispatch(setLeftSidebarWidth(298));
                } else if (leftSidebarWidth < 500 && leftSidebarWidth > 298) {
                    dispatch(setLeftSidebarWidth(298));
                }
            }, 150)
        }
    }

    const handleDoubleClicked = () => {
        if(Date.now() - lastClickTime > 200){
            return ;
        }

        setTimeout(() => {
            setIsDoubleClick(prev => {
                return true;
            });
        }, 180)
    }


    const handleRightResize = (event, { size }) => {
        const targetWidth = width - event.clientX;
        const maxWidth = 580;
        const minWidth = 420;

        // width는 420 ~ 580 사이의 값
        const newWidth = Math.min(Math.max(targetWidth, minWidth), maxWidth);
        dispatch(setRightSidebarWidth(newWidth));
    };




    return (
        <>
            {!(!(workflow ? workflow.id : workflow) && !fetching) ?
                <div id={"clcl"}>
                    {labelType === LABEL_TYPE.ANNOTATION &&
                        <>
							<CanvasHeader type={Workspace.MANAGER_WORKSPACE}/>
							<div className='coco-standard-workspace'>
								<LeftSideBar/>
								<CanvasLayout className={"mn-content-container"}/>
								<RightSideBar objectsList={<ObjectsListContainer readonly/>}/>
								<CanvasContextMenuContainer readonly/>
							</div>
                        </>
                    }
                    {labelType === LABEL_TYPE.CLASSIFICATION_SUMMARY &&
						<>
							<CanvasHeader type={Workspace.MANAGER_WORKSPACE}/>
							<ResizableContainer className='d-flex coco-standard-workspace'
                                                left={leftSidebarWidth} right={rightSidebarWidth}
                                                middle={width - leftSidebarWidth - rightSidebarWidth}>
                                <ResizableBox
                                    width={leftSidebarWidth}
                                    height={heigth}
                                    axis="x"
                                    handle={(handleProps, ref) =>  <CustomHandle key={`sidebar-left`} innerRef={ref} {...handleProps} direction={"left"} position={leftSidebarWidth} />}
                                    onResize={handleLeftResize}
                                    onResizeStart={handleLeftResizeStart}
                                    onClick={handleClick}
                                    onDoubleClick={handleDoubleClicked}
                                    onResizeStop={handleLeftResized}
                                    maxConstraints={[500, Infinity]}
                                >
                                    {
                                        leftSidebar && (
                                            <LeftSideBar/>
                                        )
                                    }
                                </ResizableBox>

                                {/* MainContent */}
                                <ManagerCanvasContentDiv style={{width : `${width - leftSidebarWidth - rightSidebarWidth}px`}}>
                                    <CanvasLayout className={"mn-content-container clsfc-smry"}/>
                                </ManagerCanvasContentDiv>


                                {/* RightSideBar */}
                                <ResizableBox
                                    width={rightSidebarWidth}
                                    height={heigth}
                                    axis="x"
                                    resizeHandles={["w"]}
                                    handle={(handleProps, ref) => <CustomHandle key={`sidebar-right`} innerRef={ref} {...handleProps} direction={"right"} position={rightSidebarWidth}/>}
                                    onResize={handleRightResize}
                                    minConstraints={[420, 420]}
                                    maxConstraints={[580, Infinity]}
                                >
                                    {
                                        rightSidebar && (
                                            <ClsfcSmryRightSideBar />
                                        )
                                    }
                                </ResizableBox>
								{/*<RightSideBar objectsList={<ObjectsListContainer readonly/>}/>*/}
								{/*<CanvasContextMenuContainer readonly/>*/}
							</ResizableContainer>
						</>
                    }
                </div>
                : <></>
            }
        </>
    );
}


export default ManagerWorkPage;
