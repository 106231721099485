import React, {useEffect, useState, useCallback, useMemo, useRef} from 'react'
import {Accept, DropEvent, FileError, FileRejection, useDropzone} from 'react-dropzone'
import fileEmptyImage from '../../assets/images/file-empty.png';
import SimpleBar from 'simplebar-react';
import 'simplebar-react/dist/simplebar.min.css';
import axios from "axios";
import {NumericFormat} from "react-number-format";
import CommonUtil from "../../util/CommonUtil";
import {authAxios} from "../../apis/utils/AxiosUtil";
import {ManagerWorkflowService} from "../../apis/api/ManagerWorkflowService";

import { FilePond} from 'react-filepond'

import 'filepond/dist/filepond.min.css'

const FilefondTest = () => {
    const [files, setFiles] = useState([]);
    const [dropzoneFileCnt, setDropzoneFileCnt] = useState(0);
    const noImageDiv = useRef();
    const uploadLimit = 10000;



    const onDrop = useCallback((acceptedFiles) => {
        noImageDiv.current.className += ' d-none';
        const tList = [];
        for (let i = 0; i < acceptedFiles.length; i++) {
            const file = acceptedFiles[i];
            if (files.length > 0) {
                // 기존에 등록되어있던게 있으면
                let hasFile = false;
                for (let i = 0; i < files.length; i++) {
                    if (files[i].name === file.name
                        && files[i].size === file.size
                        && files[i].lastModified === file.lastModified) {
                        // 이름도 같고, 크기도 같고, 마지막 수정일도 같으면 중복으로
                        hasFile = true;
                        console.log('중볽!!')
                        //this.removeFile(file);
                    }
                }
                if (!hasFile) {
                    tList.push(file);
                }
            } else {
                tList.push(file);
            }
        }

        if(files.length + tList.length > uploadLimit){
            alert('업로드 제한 초과! ')
            return;
        }

        const newAccptedFiles = tList.map(file =>
            Object.assign(file, {
                preview: URL.createObjectURL(file)
            })
        )
        setFiles(files.concat(newAccptedFiles));
        setDropzoneFileCnt(files.length + newAccptedFiles.length);

    }, [files]);


    const removeFile = (file) => () => {
        const newFiles = [...files]
        newFiles.splice(newFiles.indexOf(file), 1)
        setFiles(newFiles);
        setDropzoneFileCnt(newFiles.length);
    }





    const baseStyle = {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '20px',
        borderWidth: 2,
        borderRadius: 2,
        borderColor: '#eeeeee',
        borderStyle: 'dashed',
        backgroundColor: '#fafafa',
        color: '#bdbdbd',
        outline: 'none',
        transition: 'border .24s ease-in-out'
    };

    const focusedStyle = {
        borderColor: '#2196f3'
    };

    const acceptStyle = {
        borderColor: '#00e676'
    };

    const rejectStyle = {
        borderColor: '#ff1744'
    };



    const img = {
        display: 'block',
        width: '24px',
        height: '24px'
    };

    const fileList = files.map(file => {
            return (<div className="hstack gap-3 py-2 border-bottom tx-basic" key={file.name}>
                <div className="me-3 d-flex align-items-center">
                    <div className="avatar-xxs bg-light rounded me-3">
                        <img
                            className="img-fluid rounded d-block"
                            src={file.preview}
                            style={img}
                            // Revoke data uri after image is loaded
                            onLoad={() => {
                                URL.revokeObjectURL(file.preview)
                            }}
                        />
                    </div>
                    <h5 className="fs-14 mb-0" data-dz-name>{file.name}</h5>
                    {/*<strong className="error text-danger" data-dz-errormessage>{file.name}</strong>*/}
                </div>
                <div className="ms-auto">
                    <p className="mb-0 fs-14" data-dz-size>{
                        CommonUtil.getByteConvertNumber(file.size)}</p>
                </div>
                <div className="pe-3">
                    <div data-dz-remove>
                        <i className="ri-close-line tx-18" onClick={removeFile(file)}></i>
                    </div>
                </div>
            </div>)
        }
    );

    const uploadProcess = async () => {
        // TODO Authorization의 : accessToken을 세팅해야 한다.
        let fileSeq = 1;
        let datasetId = 'DTa7974c20-180e-402f-a5bc-61ade9f76470';
        const promises = files.map(async (file) => {
            let fd = new FormData();
            fd.append('file', file);
            fd.append('fullpath', file.path);
            //  최초 요청전에 발급받아서 세팅한다.
            fd.append('datasetId', datasetId);
            fd.append('fileSeq', fileSeq++);

            return await ManagerWorkflowService.uploadLocalFiles(fd,  {contentType : 'multipart/form-data'} )
                .then((response) => {
                    //console.log(response);
                })
                .catch(error => {
                    console.log(error);
                });
        });
        await Promise.all(promises);
        const newFiles = [];
        setFiles(newFiles);
        setDropzoneFileCnt(newFiles.length);
        console.log('end')
    }

    const {
        getRootProps,
        getInputProps,
        isFocused,
        isDragAccept,
        isDragReject,
        acceptedFiles,
    } = useDropzone({
        // onDrop,
        accept: {'image/*': []}
    });

    const style = useMemo(() => ({
        ...baseStyle,
        ...(isFocused ? focusedStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {})
    }), [
        isFocused,
        isDragAccept,
        isDragReject
    ]);
    const ssssss =(aa)=>{
        console.log(aa)
    }

    return (
        <>



            <div>
                <div className="mb-2 mt-4 tx-14 pb-1 border-bottom text-coco-primary-400 d-flex align-items-center justify-content-between">
                    <div className="mb-2 tx-14 pb-1 text-coco-primary-400 fw-semibold d-flex align-items-center">
                        <i className="ri-file-upload-line me-1"></i>
                        데이터 업로드
                        <div className="text-coco-gray-500 ms-2 tx-12">
                            (업로드 제한: <span className="">{dropzoneFileCnt}</span>/<NumericFormat value={uploadLimit}  thousandSeparator="," displayType="text" />)
                        </div>
                    </div>
                </div>
                    <div>
                        <FilePond
                            files={files}
                            // onupdatefiles={setFiles}
                            chunkUploads={false}
                            allowMultiple={true}
                            allowPaste={false}
                            maxFiles={1000}
                            instant-upload={false}
                            name="files"
                            processfilestart={ssssss}
                            labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
                            credits={false}
                            itemInsertInterval={0}
                        />
                    </div>
                <div className="mb-2 mt-4 tx-14 pb-1 border-bottom text-coco-primary-400 d-flex align-items-center justify-content-between">
                    <div className="fw-semibold">
                        <i className="ri-pencil-ruler-2-line me-1"></i>
                        파일 업로드 상태
                    </div>
                    <div className="text-coco-gray-500">
                        전체 : <span>{dropzoneFileCnt}</span>
                    </div>
                </div>
                <div className="d-flex align-items-start gap-3 mt-4">
                    <button type="button" className="btn btn-coco-2 text-decoration-none ms-auto" data-previous="dataset-1step-tab">
                        이전
                    </button>
                    <button type="button" className="btn btn-coco-1 right nexttab nexttab" idx="3" onClick={uploadProcess}>
                        확인
                    </button>
                </div>
            </div>
        </>
    );
}

export default FilefondTest;