import React, {useEffect, useState} from 'react'
import './assets/scss/themes.scss';
import Route from "./routes"
import {useIntl} from "react-intl";
import Loading from '../src/components/common/Loading';
import {useSelector} from "react-redux";
import axios from "axios";

function App() {
    const loading = useSelector(states => states.Layout.loading);
    const intl = useIntl();
    axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

    return (
        <>
            {/*<React.StrictMode>*/}
            <Route/>
            {/*</React.StrictMode>*/}
            {loading ? <Loading/> : null}
        </>
    );
}

export default App;
