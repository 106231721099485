import React, { memo } from 'react';
import {Handle, useReactFlow, useStoreApi, Position, useStore} from 'reactflow';
import {useIntl} from "react-intl";
import {useDispatch, useSelector} from "react-redux";
import {thisSourceValidConnection, thisTargetValidConnection} from "./nodeCommonUtil";
import {GetMessageIntl} from "../../../../../../util/message";
const connectionNodeIdSelector = (state) => {
    return state.connectionNodeId;
}
const connectionHandleTypeSelector = (state) => {
    return state.connectionHandleType;
}
const nodesSelector = (state) => Array.from(state.nodeInternals.values());
function SdqNode({ id, data, isConnectable }) {
    const intl = useIntl();
    const dispatch = useDispatch();
    const nodes = useStore(nodesSelector);
    const connectionNodeId = useStore(connectionNodeIdSelector);
    const connectionHandleType = useStore(connectionHandleTypeSelector);
    const {selectionNodeId} = useSelector((state)=>({
        selectionNodeId : state.ManageWorkflowModule.selectionNodeId
    }));
    let connectionNodeType = '';
    if(connectionNodeId){
        let connectionNode = nodes.find((item) => item.id === connectionNodeId);
        let parentNodeType = '';
        if(connectionNode.parentNode){
            parentNodeType = nodes.find((item) => item.id === connectionNode.parentNode).type+'_';
        }
        connectionNodeType = parentNodeType + connectionNode.type;
    }
    const isTarget = connectionNodeId && connectionNodeId !== id && thisTargetValidConnection('sdq4ai', connectionNodeType) && connectionHandleType == 'source';
    const targetHandleStyle = isTarget? { visibility: 'visible', zIndex: 10 } : {visibility: 'hidden', zIndex:1};

    const isSourceValidConnection = (connection) => {
        let connectSourceType = nodes.find((item) => item.id === connection.target).type;
        return thisSourceValidConnection('sdq4ai',connectSourceType);
    }
    const isSource = (selectionNodeId && selectionNodeId == id)  || (connectionHandleType == 'target' && thisSourceValidConnection('sdq4ai', connectionNodeType));
    const sourceHandleStyle = isSource? {visibility: 'visible', zIndex: 10} : { visibility: 'hidden', zIndex:1};

    let myWindow = null;
    const handleOpenPopup = () => {
        if (myWindow == null || myWindow.closed) {
            myWindow = window.open("http://sdq-for-ai-test.ap-northeast-2.elasticbeanstalk.com/sso", "sdqforai", "noreferrer");
        } else {
            // 안되네..
            myWindow.focus()
        }
    }

    return (
        <>
            <div className="d-flex align-items-center mb-12">
                <div className="flex-shrink-0 avatar-42">
                    <div className="avatar-title fs-20 rounded-circle" style={{backgroundColor:'#F2E4FF', color:'#B66CFB'}}>
                        <i className="mdi mdi-clipboard-pulse-outline lh-19"></i>
                    </div>
                </div>
                <div className="flex-grow-1 text-overflow ml-13">
                    <h6 className="text-coco-gray-200 tx-12 lh-16 mb-2">PLUGIN</h6>
                    <span className="text-coco-gray-700 tx-16 lh-20 fw-semibold d-flex align-items-center">
                        SDQ for AI <i className="ms-1 ri-external-link-line tx-16 fw-normal text-pointer" style={{color:"#3793FF"}} onClick={handleOpenPopup}></i>
                    </span>
                </div>
            </div>
            <div className="bg-coco-gray-50 pd-10 node_info flex-shrink-0 mb-12">
                <div className="ht-20 text-overflow">
                    <label style={{width:"89px"}}>{GetMessageIntl(intl,"workflow.prjctNm")}</label>
                    <span className={"text-overflow"}>반려 동물 식별 프로젝트</span>
                </div>
                <div className="ht-20 mt-6 text-overflow">
                    <label style={{width:"89px"}}>{GetMessageIntl(intl,"workflow.datasetGroupNm")}</label>
                    <span className={"text-overflow"}>강아지 비문 라벨링 워크플로우</span>
                </div>
            </div>
            <Handle
                className="sourceHandle"
                style={sourceHandleStyle}
                position={Position.Top}
                id={'top'}
                type="source"
                isConnectable={isConnectable}
                isValidConnection={isSourceValidConnection}
            />
            <Handle
                className="sourceHandle"
                style={sourceHandleStyle}
                position={Position.Right}
                id={'right'}
                type="source"
                isConnectable={isConnectable}
                isValidConnection={isSourceValidConnection}
            />
            <Handle
                className="sourceHandle"
                style={sourceHandleStyle}
                position={Position.Bottom}
                id={'bottom'}
                type="source"
                isConnectable={isConnectable}
                isValidConnection={isSourceValidConnection}
            />
            <Handle
                className="sourceHandle"
                style={sourceHandleStyle}
                position={Position.Left}
                id={'left'}
                type="source"
                isConnectable={isConnectable}
                isValidConnection={isSourceValidConnection}
            />

            <Handle
                className="targetHandle"
                style={targetHandleStyle}
                position={Position.Top}
                id={'top'}
                type="target"
                isConnectable={isConnectable}
            />
            <Handle
                className="targetHandle"
                style={targetHandleStyle}
                position={Position.Right}
                id={'right'}
                type="target"
                isConnectable={isConnectable}
            />
            <Handle
                className="targetHandle"
                style={targetHandleStyle}
                position={Position.Bottom}
                id={'bottom'}
                type="target"
                isConnectable={isConnectable}
            />
            <Handle
                className="targetHandle"
                style={targetHandleStyle}
                position={Position.Left}
                id={'left'}
                type="target"
                isConnectable={isConnectable}
            />
        </>
    );
}

export default memo(SdqNode);