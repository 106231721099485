// @ts-nocheck
import { useIntl } from "react-intl";
import {useDispatch, useSelector} from "react-redux";
import {DropdownItem, Input, Label} from "reactstrap";
import {GetMessageIntl} from "../../../../../util/message";
import React, {useEffect, useState} from "react";
import {updateSearchParam} from "../../../../../store/dataset/details/action";
import {SearchFilter} from "../../../../../store/dataset/details";
import {ApplySearchButton} from "./search-header";
import {cloneDeep} from "lodash";

export function RejectFilter(): JSX.Element{
    const intl = useIntl();
    const dispatch = useDispatch();

    const {
        Review:{
            Cmmn: {annotations: {reasons}}
        },
        Details: {
            searchParam: {view, data}
        }
    } = useSelector(state => state);
    const [rejectRsn, setRejectRsn] = useState([]);
    const choiceCdList = data.reject;
    const choiceLabelList = view.reject;
    useEffect(() => {
        const list = reasons.filter(rsn => rsn.cmmnDetailCd !== '01').reduce((acc, rsn) => {
            acc.push({label: rsn.cmmnDetailCdNm, type: rsn.cmmnDetailCd})
            return acc;
        }, [])
        list.push({label: GetMessageIntl(intl, 'details.classReject'), type: "11"})
        list.push({label: GetMessageIntl(intl, 'details.noExistReject'), type: "12"})
        setRejectRsn(list);
    }, [reasons])

    const onCdChange = (target, label) => {
        const checked = target.checked;
        const value = target.value;
        let newValues = [...choiceCdList];
        let newLabels = cloneDeep(choiceLabelList);
        const value_ = checked ? newValues.concat(value) : newValues.filter(v => v!=value);
        const label_ = checked ? newLabels.concat({label: label, index: value}) : newLabels.filter(v => v.index != value);
        label_.sort(function (a, b) {
            return a.index - b.index
        })
        dispatch(updateSearchParam(SearchFilter.LAST_REJECT_REASONS, value_, label_))
    }

    return (
        <div className={"filter-container"}>
            <div className={"d-flex align-items-center justify-content-between"}>
                <DropdownItem header>{GetMessageIntl(intl, 'details.lastRejectReasons')}</DropdownItem>
                <ApplySearchButton />
            </div>
            <DropdownItem divider/>
            <div className={"d-flex flex-column align-items-center gap-2"}>
                {rejectRsn.map(
                    (rsn: any): JSX.Element => {
                        const { type: rsnCd} = rsn;
                        return (
                            <DropdownItem  key={`reject-filter-${rsnCd}`} toggle={false}>
                                <div className="form-check form-check-coco">
                                    <Input className="form-check-input" type="checkbox"
                                           id={rsnCd}
                                           value={rsnCd}
                                           defaultChecked={choiceCdList.includes(rsnCd)}
                                           onChange={(event) => {
                                               onCdChange(event.target, rsn.label)
                                           }}
                                    />
                                    <Label className="form-check-label" htmlFor={rsnCd}>
                                        {rsn.label}
                                    </Label>
                                </div>
                            </DropdownItem>
                        )
                    }
                )}
            </div>
        </div>
    )
}
