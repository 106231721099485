import React, {useEffect, useState} from 'react';
import {Button, ButtonGroup, Card, CardBody, CardHeader, Col, Form, Input, Label, Row, Spinner} from 'reactstrap';
import Slider from "react-rangeslider";
import "react-rangeslider/lib/index.css";
import Select from "react-select";
import {
    defaultSelectStyle,
    formatGroupLabel,
    multiSingleSelectStyle,
    noBorderSelectStyle
} from "../../components/constants/select";
import Flatpickr from "react-flatpickr";
import {OverlayTrigger, Popover} from "react-bootstrap";
import {CirclePicker} from "react-color";

const UiFormTest = () => {
    const [singleValue, setSingleValue] = useState(null);
    const [min_max, setmin_max] = useState(70);
    const [min_max_vertical, set_min_max_vertical] = useState(70);
    const [min_max_disabled, set_min_max_disabled] = useState(70);
    const [colorCode, setColorCode] = useState('#FF5A5A');
    const [dependentShape, setDependentShape] = useState('rect');
    const [checkboxBtnGroupVal, setCheckboxBtnGroupVal] = useState([]);
    const [radioBtnGroupVal, setRadioBtnGroupVal] = useState(null);

    useEffect(() => {
        var searchCloseIcon = document.getElementById("search-close-icon");
        var searchInput = document.getElementById("search-input");

        searchInput.addEventListener("focus", function () {
            var inputLength = searchInput.value.length;
            if (inputLength > 0) {
                searchCloseIcon.classList.remove("d-none");
            } else {
                searchCloseIcon.classList.add("d-none");
            }
        });

        searchInput.addEventListener("keyup", function () {
            var inputLength = searchInput.value.length;
            if (inputLength > 0) {
                searchCloseIcon.classList.remove("d-none");
            } else {
                searchCloseIcon.classList.add("d-none");
            }
        });

        searchCloseIcon.addEventListener("click", function () {
            searchInput.value = "";
            searchCloseIcon.classList.add("d-none");
        });

        var searchCloseIcon2 = document.getElementById("search-close-icon2");
        var searchInput2 = document.getElementById("search-input2");

        searchInput2.addEventListener("focus", function () {
            var inputLength = searchInput2.value.length;
            if (inputLength > 0) {
                searchCloseIcon2.classList.remove("d-none");
            } else {
                searchCloseIcon2.classList.add("d-none");
            }
        });

        searchInput2.addEventListener("keyup", function () {
            var inputLength = searchInput2.value.length;
            if (inputLength > 0) {
                searchCloseIcon2.classList.remove("d-none");
            } else {
                searchCloseIcon2.classList.add("d-none");
            }
        });

        searchCloseIcon2.addEventListener("click", function () {
            searchInput2.value = "";
            searchCloseIcon2.classList.add("d-none");
        });


        document.body.addEventListener("click", function (e) {
            if (e.target.getAttribute('id') !== "search-input") {
                searchCloseIcon.classList.add("d-none");
            }
            if (e.target.getAttribute('id') !== "search-input2") {
                searchCloseIcon2.classList.add("d-none");
            }
        });
    }, []);

    /*
    Slider Opiton & Event
     */
    const horizontalLabels = {
        0: 'Low',
        50: 'Medium',
        100: 'High'
    }


    const selectOption = [
            { label: "ajaxenhancement", value: "enhancement" },
            { label: "bug", value: "bug" },
            { label: "선택지", value: "bug2" },
            { label: "duplicate", value: "duplicate" },
            { label: "invalid", value: "invalid" },
            { label: "wontfix", value: "wontfix" }
        ];

    const arrowRenderer = () => {
        return <i className="ri-arrow-drop-down-line" />;
    };

    const colourOptions = [
        { value: 'ocean', label: 'Ocean', color: '#00B8D9', isFixed: true },
        { value: 'blue', label: 'Blue', color: '#0052CC', isDisabled: true },
        { value: 'purple', label: 'Purple', color: '#5243AA' },
        { value: 'red', label: 'Red', color: '#FF5630', isFixed: true },
        { value: 'orange', label: 'Orange', color: '#FF8B00' },
        { value: 'yellow', label: 'Yellow', color: '#FFC400' },
        { value: 'green', label: 'Green', color: '#36B37E' },
        { value: 'forest', label: 'Forest', color: '#00875A' },
        { value: 'slate', label: 'Slate', color: '#253858' },
        { value: 'silver', label: 'Silver', color: '#666666' },
    ];

    const flavourOptions = [
        { value: 'vanilla', label: 'Vanilla', rating: 'safe' },
        { value: 'chocolate', label: 'Chocolate', rating: 'good' },
        { value: 'strawberry', label: 'Strawberry', rating: 'wild' },
        { value: 'salted-caramel', label: 'Salted Caramel', rating: 'crazy' },
    ];

    const groupedOptions = [
        {
            label: 'Colours',
            options: colourOptions,
        },
        {
            label: 'Flavours',
            options: flavourOptions,
        },
    ];


    const labelShapeOption = [
        {label : 'rect',       value : 'rect',        icon : 'coco-20-bbox'}
        ,{label : 'polygon',    value : 'polygon',     icon : 'coco-20-polygon'}
        ,{label : 'line',       value : 'line',        icon : 'coco-20-line'}
        ,{label : 'polyline',   value : 'polyline',    icon : 'coco-20-polyline'}
        ,{label : 'circle',     value : 'circle',      icon : 'coco-20-circle'}
        ,{label : 'ellipse',    value : 'ellipse',     icon : 'coco-20-ellipse'}
        ,{label : 'polyEllipse',value : 'polyEllipse', icon : 'coco-20-poly-ellipse'}
        ,{label : 'points',     value : 'points',      icon : 'coco-20-point'}
        ,{label : 'cuboid',     value : 'cuboid',      icon : 'coco-20-cuboid2d'}];

    const labelShapesTypeInfo = {
        'rect': {'label': 'rect', 'value': 'rect', 'icon': 'coco-20-bbox'}
        , 'polygon': {'label': 'polygon', 'value': 'polygon', 'icon': 'coco-20-polygon'}
        , 'line': {'label': 'line', 'value': 'line', 'icon': 'coco-20-line'}
        , 'polyline': {'label': 'polyline', 'value': 'polyline', 'icon': 'coco-20-polyline'}
        , 'circle': {'label': 'circle', 'value': 'circle', 'icon': 'coco-20-circle'}
        , 'ellipse': {'label': 'ellipse', 'value': 'ellipse', 'icon': 'coco-20-ellipse'}
        , 'polyEllipse': {'label': 'polyEllipse', 'value': 'polyEllipse', 'icon': 'coco-20-poly-ellipse'}
        , 'points': {'label': 'points', 'value': 'points', 'icon': 'coco-20-point'}
        , 'cuboid': {'label': 'cuboid', 'value': 'cuboid', 'icon': 'coco-20-cuboid2d'}
    };

    // const CustomSelectOption = (props) => (
    //     <Option {...props}>
    //         <span className="d-flex align-items-center">
    //             <i className={`i-coco-20 bg-coco-gray-700 me-2 ${props.data.icon}`}></i>
    //             {props.data.label}
    //         </span>
    //     </Option>
    // )
    //
    // // react-select Custom Select Option result
    // const CustomSelectValue = (props) => (
    //     <div>
    //         <span className="d-flex">
    //             <i className={`d-inline-flex i-coco-20 bg-coco-gray-700 ${props.data.icon}`}></i>
    //         </span>
    //     </div>
    // )

    function changeDenpendentEvent(obj) {
        setColorCode(obj.value);
    }

    const colorPickerColors = ["#FF5A5A", "#FF8267", "#FFBF5F", "#FEF161", "#C5EF63", "#8CF280", "#62DDF2", "#6599FF", "#6563FF", "#A366FF"
        , "#FF2F82", "#FD5D00", "#FFD705", "#FFF85C", "#B3FF00", "#00FF9F", "#00DAFF", "#0056FF", "#0300FF", "#6600FF", "#fff"];

    function selectColorPickerEvent(obj) {
        setColorCode(obj.hex);
    }

    /*
    * ButtonGroup*/
    const onCheckboxBtnGrpClick = (selected) => {
        const index = checkboxBtnGroupVal.indexOf(selected);
        if (index < 0) {
            checkboxBtnGroupVal.push(selected);
        } else {
            checkboxBtnGroupVal.splice(index, 1);
        }
        setCheckboxBtnGroupVal([...checkboxBtnGroupVal]);
    };

    return (
        <>
            <Row>
                <Col lg={12}>
                    <Card>
                        <CardHeader className="fw-semibold">
                            Input
                        </CardHeader>
                        <CardBody className="card-body">
                            <div className="live-preview">
                                <Row className="gy-4">
                                    <Col xxl={3} md={6}>
                                        <div>
                                            <Label htmlFor="placeholderInput" className="form-label">Input</Label>
                                            <Input type="text" className="form-control" placeholder="Placeholder" />
                                        </div>
                                    </Col>
                                    <Col xxl={3} md={6}>
                                        <div>
                                            <Label htmlFor="basiInput" className="form-label">
                                                Search Input
                                                (* clear 이벤트는 소스 이벤트 참고)
                                            </Label>
                                        </div>
                                        <div>
                                            <div className="position-relative form-search">
                                                <Input type="text" className="form-control" id="search-input" placeholder="Search..." />
                                                <span className="ri-search-line search-widget-icon"></span>
                                                <span className="ri-close-line search-widget-icon search-widget-icon-close d-none"
                                                      id="search-close-icon"></span>
                                            </div>
                                        </div>
                                    </Col>

                                    <Col xxl={3} md={6}>
                                        <div>
                                            <Label htmlFor="exampleFormControlTextarea5" className="form-label">Example Textarea</Label>
                                            <textarea className="form-control" rows="3" placeholder="Placeholder"></textarea>
                                        </div>
                                    </Col>

                                    <Col xxl={3} md={6}>
                                        <div>
                                            <Label htmlFor="placeholderInput" className="form-label">editable input</Label>
                                            <Input type="text" className="form-control editable" placeholder="Placeholder" />
                                        </div>
                                    </Col>

                                    <Col xxl={3} md={6}>
                                        <div>
                                            <Label htmlFor="placeholderInput" className="form-label">Input 값 입력</Label>
                                            <Input type="text" className="form-control" placeholder="Placeholder" defaultValue="A프로젝트" />
                                        </div>
                                    </Col>
                                    <Col xxl={3} md={6}>
                                        <div>
                                            <Label htmlFor="basiInput2" className="form-label">
                                                Search Input  값 입력
                                                (* clear 이벤트는 소스 이벤트 참고)
                                            </Label>
                                        </div>
                                        <div className="position-relative form-search">
                                            <Input type="text" className="form-control" id="search-input2" placeholder="Search..." defaultValue="A프로젝트"/>
                                            <span className="ri-search-line search-widget-icon"></span>
                                            <span className="ri-close-line search-widget-icon search-widget-icon-close d-none"
                                                  id="search-close-icon2"></span>
                                        </div>
                                    </Col>

                                    <Col xxl={3} md={6}>
                                        <div>
                                            <Label htmlFor="exampleFormControlTextarea5" className="form-label">Example Textarea  값 입력</Label>
                                            <textarea className="form-control" rows="3" placeholder="Placeholder" defaultValue="A프로젝트"></textarea>
                                        </div>
                                    </Col>

                                    <Col xxl={3} md={6}>
                                        <div>
                                            <Label htmlFor="placeholderInput" className="form-label">editable input  값 입력</Label>
                                            <Input type="text" className="form-control editable" defaultValue="A프로젝트" placeholder="Placeholder" />
                                        </div>
                                    </Col>


                                    <Col xxl={3} md={6}>
                                        <div>
                                            <Label htmlFor="placeholderInput" className="form-label">Input disabled</Label>
                                            <Input type="text" className="form-control" placeholder="Placeholder" defaultValue="A프로젝트" disabled/>
                                        </div>
                                    </Col>
                                    <Col xxl={3} md={6}>
                                        <div>
                                            <Label htmlFor="basiInput2" className="form-label">
                                                Search Input disabled
                                                (* clear 이벤트는 소스 이벤트 참고)
                                            </Label>
                                        </div>
                                        <div className="position-relative form-search">
                                            <Input type="text" className="form-control" id="search-input2" placeholder="Search..." defaultValue="A프로젝트" disabled/>
                                            <span className="ri-search-line search-widget-icon"></span>
                                            <span className="ri-close-line search-widget-icon search-widget-icon-close d-none"
                                                  id="search-close-icon2"></span>
                                        </div>
                                    </Col>

                                    <Col xxl={3} md={6}>
                                        <div>
                                            <Label htmlFor="exampleFormControlTextarea5" className="form-label">Example Textarea disabled</Label>
                                            <textarea className="form-control" rows="3" placeholder="Placeholder" defaultValue="A프로젝트" disabled></textarea>
                                        </div>
                                    </Col>

                                    <Col xxl={3} md={6}>
                                    </Col>

                                    <Col xxl={3} md={6}>
                                        <div>
                                            <Label htmlFor="placeholderInput" className="form-label">Input valid</Label>
                                            <Input
                                                placeholder="Search..."
                                                type="text" defaultValue="A프로젝트"
                                                className="form-control is-invalid"
                                            />
                                            <div className="invalid-feedback">
                                                Please enter a message in the textarea.
                                            </div>
                                        </div>
                                    </Col>
                                    <Col xxl={3} md={6}>
                                        <div>
                                            <Label htmlFor="basiInput2" className="form-label">
                                                Search Input  값 입력
                                                (* clear 이벤트는 소스 이벤트 참고)
                                            </Label>
                                        </div>
                                        <div className="position-relative form-search">
                                            <Input type="text" className="form-control is-invalid" id="search-input2" placeholder="Search..." defaultValue="A프로젝트"/>
                                            <span className="ri-search-line search-widget-icon"></span>
                                            <span className="ri-close-line search-widget-icon search-widget-icon-close d-none"
                                                  id="search-close-icon2"></span>
                                        </div>
                                        <div className="invalid-feedback">
                                            Please enter a message in the textarea.
                                        </div>
                                    </Col>

                                    <Col xxl={3} md={6}>
                                        <div>
                                            <Label htmlFor="exampleFormControlTextarea5" className="form-label">Example Textarea valid</Label>
                                            <textarea className="form-control is-invalid" defaultValue="A프로젝트" placeholder="Placeholdera" required></textarea>
                                            <div className="invalid-feedback">
                                                Please enter a message in the textarea.
                                            </div>
                                        </div>
                                    </Col>

                                    <Col xxl={3} md={6}>
                                        <div>
                                            <Label htmlFor="placeholderInput" className="form-label">editable input valid</Label>
                                            <Input type="text" className="form-control editable is-invalid" defaultValue="A프로젝트" placeholder="Placeholder" />
                                            <div className="invalid-feedback">
                                                Please enter a message in the textarea.
                                            </div>
                                        </div>
                                    </Col>

                                    <Col xxl={3} md={6}>
                                        <div>
                                            <Label htmlFor="placeholderInput" className="form-label">editable input valid tooltip <code>.is-invalid</code> p태그<code>.invalid-tooltip</code></Label>
                                            <input type="text" className="form-control editable is-invalid"/>
                                            <p className="validation-error-message invalid-tooltip">부적절한 정보입니다.</p>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                            <div className="d-none code-view">
                                <pre className="language-markup" style={{ height: "352px" }}>
                                    <code>

                                    </code>
                                </pre>
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            </Row>

            <Row>
                <Col lg={12}>
                    <Card>
                        <CardHeader className="fw-semibold">
                            Picker
                        </CardHeader>
                        <CardBody className="card-body">
                            <div className="live-preview">
                                <Row className="gy-4">
                                    <Col xxl={3} md={6}>
                                        <div>
                                            <Label className="form-label">Flatpickr</Label>
                                            <div className="form-icon">
                                                <Flatpickr className="form-control form-control-icon" placeholder="YYYY.MM.DD" options={{
                                                    dateFormat: "Y.m.d"
                                                }}/>
                                                <i className="ri-calendar-event-line text-coco-gray-400"></i>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col xxl={3} md={6}>
                                        <div>
                                            <Label className="form-label">Flatpickr disabled</Label>
                                            <div className="form-icon">
                                                <Flatpickr className="form-control form-control-icon" placeholder="YYYY.MM.DD" options={{
                                                    dateFormat: "Y.m.d"
                                                }} disabled/>
                                                <i className="ri-calendar-event-line text-coco-gray-400"></i>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col xxl={3} md={6}>
                                        <div>
                                            <Label className="form-label">Flatpickr Range</Label>
                                            <div className="form-icon">
                                                <Flatpickr className="form-control form-control-icon" placeholder="YYYY.MM.DD-YYYY.MM.DD" options={{
                                                    mode: "range",
                                                    dateFormat: "Y.m.d",
                                                    locale:{rangeSeparator:'-'}
                                                }}/>
                                                <i className="ri-calendar-event-line text-coco-gray-400"></i>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col xxl={3} md={6}>
                                        <div>
                                            <Label className="form-label">Flatpickr Range disabled</Label>
                                            <div className="form-icon">
                                                <Flatpickr className="form-control form-control-icon" placeholder="YYYY.MM.DD-YYYY.MM.DD" options={{
                                                    mode: "range",
                                                    dateFormat: "Y.m.d",
                                                    locale:{rangeSeparator:'-'}
                                                }}
                                                disabled/>
                                                <i className="ri-calendar-event-line text-coco-gray-400"></i>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col xxl={3} md={6}>
                                        <div>
                                            <Label className="form-label">Flatpickr Valid</Label>
                                            <div className="form-icon">
                                                <Flatpickr className="form-control form-control-icon is-invalid" placeholder="YYYY.MM.DD" options={{
                                                    dateFormat: "Y.m.d"
                                                }}/>
                                                <i className="ri-calendar-event-line text-coco-gray-400"></i>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col xxl={3} md={6}>
                                    </Col>
                                    <Col xxl={3} md={6}>
                                        <div>
                                            <Label className="form-label">Flatpickr Range  Valid</Label>
                                            <div className="form-icon">
                                                <Flatpickr className="form-control form-control-icon is-invalid" placeholder="YYYY.MM.DD-YYYY.MM.DD" options={{
                                                    mode: "range",
                                                    dateFormat: "Y.m.d",
                                                    locale:{rangeSeparator:'-'}
                                                }}/>
                                                <i className="ri-calendar-event-line text-coco-gray-400"></i>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col xxl={3} md={6}></Col>
                                    <Col xxl={3} md={6}>
                                        <div>
                                            <Label className="form-label">Color Pickr</Label>
                                            <OverlayTrigger
                                                trigger="click"
                                                rootClose
                                                placement="bottom"
                                                overlay={
                                                    <Popover id={'colorPicker-t1'}>
                                                        <Popover.Body>
                                                            <CirclePicker color={colorCode} colors={colorPickerColors} onChange={ (event)=> selectColorPickerEvent(event) } />
                                                        </Popover.Body>
                                                    </Popover>
                                                }
                                            >
                                                <div className="rounded-circle avatar-xs border-coco-gray-50" style={{backgroundColor:`${colorCode}`}}></div>
                                            </OverlayTrigger>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                            <div className="d-none code-view">
                                <pre className="language-markup" style={{ height: "352px" }}>
                                    <code>

                                    </code>
                                </pre>
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            </Row>

            <Row>
                <Col lg={12}>
                    <Card>
                        <CardHeader className="fw-semibold">
                            Select (style을 ../../components/constants/select에서 해당 하는걸 입력하세요..)
                        </CardHeader>
                        <CardBody className="card-body">
                            <div className="live-preview">
                                <Row className="gy-4">
                                    <Col xxl={3} md={6}>
                                        <div>
                                            <h6 className="fw-semibold">Basic Select</h6>
                                            <Select
                                                options={selectOption}
                                                styles={defaultSelectStyle}
                                                className="mb-0"
                                                isSearchable={false}
                                            />
                                        </div>
                                    </Col>
                                    <Col xxl={3} md={6}>
                                        <div>
                                            <h6 className="fw-semibold">Basic Select Search</h6>
                                            <Select
                                                options={selectOption}
                                                styles={defaultSelectStyle}
                                                className="mb-0"
                                            />
                                        </div>
                                    </Col>
                                    <Col xxl={3} md={6}>
                                        <div>
                                            <h6 className="fw-semibold">
                                                Multi Single Select Search
                                                소스 참고(onChange이벤트)
                                            </h6>
                                            <Select
                                                options={selectOption}
                                                value={singleValue}
                                                styles={multiSingleSelectStyle}
                                                onChange={(value) => {
                                                        // here i dont want the user to choose more then 3 topics
                                                        setSingleValue(value[value.length-1]);
                                                    }}
                                                isMulti
                                                className="mb-0"
                                            />
                                        </div>
                                    </Col>
                                    <Col xxl={3} md={6}>
                                        <div>
                                            <h6 className="fw-semibold">Multi Select Search</h6>
                                            <Select
                                                options={selectOption}
                                                defaultValue={[selectOption[1], selectOption[2]]}
                                                styles={defaultSelectStyle}
                                                isMulti
                                                className="mb-0"
                                            />
                                        </div>
                                    </Col>
                                    <Col xxl={3} md={6}>
                                        <div>
                                            <h6 className="fw-semibold">Group Select Search</h6>
                                            <Select
                                                options={groupedOptions}
                                                formatGroupLabel={formatGroupLabel}
                                                styles={defaultSelectStyle}
                                                className="mb-0"
                                            />
                                        </div>
                                    </Col>
                                    <Col xxl={3} md={6}>
                                        <div>
                                            <h6 className="fw-semibold">No border line</h6>
                                            <Select
                                                options={selectOption}
                                                defaultValue={[selectOption[1]]}
                                                styles={noBorderSelectStyle}
                                                className="mb-0"
                                            />
                                        </div>
                                    </Col>
                                    <Col xxl={3} md={6}>
                                        <div>
                                            <h6 className="fw-semibold">disabled</h6>
                                            <Select
                                                options={selectOption}
                                                defaultValue={[selectOption[1]]}
                                                styles={defaultSelectStyle}
                                                isDisabled
                                                className="mb-0"
                                            />
                                        </div>
                                    </Col>
                                    <Col xxl={3} md={6}>
                                        <div>
                                            <h6 className="fw-semibold">multi disabled</h6>
                                            <Select
                                                options={selectOption}
                                                defaultValue={[selectOption[1], selectOption[2]]}
                                                styles={defaultSelectStyle}
                                                isMulti
                                                isDisabled
                                                className="mb-0"
                                            />
                                        </div>
                                    </Col>
                                   {/*<Col xxl={3} md={6}>*/}
                                   {/*     <div>*/}
                                   {/*         <h6 className="fw-semibold">custom select</h6>*/}
                                   {/*         <Select*/}
                                   {/*             defaultValue={labelShapesTypeInfo[dependentShape]}*/}
                                   {/*             options={labelShapeOption}*/}
                                   {/*             isSearchable={false}*/}
                                   {/*             styles={noBorderSelectStyle}*/}
                                   {/*             components={{ Option: CustomSelectOption, SingleValue: CustomSelectValue }}*/}
                                   {/*             // onChange={(value)=>changeDenpendentEvent(value)}*/}
                                   {/*         />*/}
                                   {/*     </div>*/}
                                   {/* </Col>*/}
                                </Row>
                            </div>
                            <div className="d-none code-view">
                                <pre className="language-markup" style={{ height: "352px" }}>
                                    <code>

                                    </code>
                                </pre>
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            </Row>

            <Row>
                <Col lg={12}>
                    <Card>
                        <CardHeader className="fw-semibold">
                            Slider
                        </CardHeader>
                        <CardBody>
                            <div className='slider custom-labels'>
                                <Slider
                                    min={0}
                                    max={100}
                                    value={min_max}
                                    orientation="horizontal"
                                    labels={horizontalLabels}
                                    // handleLabel={horizontal}
                                    onChange={(value) => {
                                        setmin_max(value);
                                    }}
                                />
                                <div className='value mt-3 '>{min_max}</div>
                            </div>
                        </CardBody>
                    </Card>
                    <Card>
                        <CardHeader className="fw-semibold">
                            Slider (orientation="vertical")
                        </CardHeader>
                        <CardBody>
                            <div className='slider custom-labels'>
                                <Slider
                                    min={0}
                                    max={100}
                                    value={min_max_vertical}
                                    orientation="vertical"
                                    labels={horizontalLabels}
                                    // handleLabel={horizontal}
                                    onChange={(value) => {
                                        set_min_max_vertical(value);
                                    }}
                                />
                                <div className='value mt-3 '>{min_max_vertical}</div>
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col lg={12}>
                    <Card>
                        <CardHeader className="fw-semibold">
                            Slider (class: disabled)
                        </CardHeader>
                        <CardBody>
                            <div className='slider custom-labels'>
                                <Slider
                                    min={0}
                                    max={100}
                                    value={min_max_disabled}
                                    orientation="horizontal"
                                    labels={horizontalLabels}
                                    // handleLabel={horizontal}
                                    onChange={(value) => {
                                        set_min_max_disabled(value);
                                    }}
                                    className={"disabled"}
                                />
                                <div className='value mt-3 '>{min_max_disabled}</div>
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col lg={12}>
                    <Card>
                        <CardHeader className="fw-semibold">
                            Radio & Checkbox
                        </CardHeader>
                        <CardBody className="card-body">
                            <div className="live-preview">
                                <Row className="gy-4">
                                    <Col xxl={3} md={6}>
                                        <div>
                                            <p className="text-muted fw-medium">Default Radio <code>.form-radio-coco</code></p>
                                            <div className="form-check form-radio-coco mb-3">
                                                <Input className="form-check-input" type="radio" name="formradiocolor1" id="formradioRight5" />
                                                <Label className="form-check-label" htmlFor="formradioRight5">
                                                    Radio Primary
                                                </Label>
                                            </div>
                                            <div className="form-check form-radio-coco">
                                                <Input className="form-check-input" type="radio" id="formradiocolor2" defaultChecked />
                                                <Label className="form-check-label" htmlFor="formradiocolor2">
                                                    Radio Primary Checked checkbox
                                                </Label>
                                            </div>
                                            <div className="form-check form-radio-coco">
                                                <Input className="form-check-input" type="radio" name="flexRadioDisabled" id="flexRadioDisabled" disabled />
                                                <Label className="form-check-label" htmlFor="flexRadioDisabled">
                                                    Disabled radio
                                                </Label>
                                            </div>
                                            <div>
                                                <div className="form-check form-radio-coco">
                                                    <Input className="form-check-input" type="radio" name="flexRadioDisabled" id="flexRadioCheckedDisabled" defaultChecked disabled />
                                                    <Label className="form-check-label" htmlFor="flexRadioCheckedDisabled">
                                                        Disabled checked radio
                                                    </Label>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col xxl={3} md={6}>
                                        <div>
                                            <p className="text-muted fw-medium">Default Checkbox <code>.form-check-coco</code></p>
                                            <div className="form-check form-check-coco mb-2">
                                                <Input className="form-check-input" type="checkbox" id="formCheck1" />
                                                <Label className="form-check-label" htmlFor="formCheck1">
                                                    Default checkbox
                                                </Label>
                                            </div>
                                            <div className="form-check form-check-coco">
                                                <Input className="form-check-input" type="checkbox" id="formCheck2" defaultChecked />
                                                <Label className="form-check-label" htmlFor="formCheck2">
                                                    Checked checkbox
                                                </Label>
                                            </div>
                                            <div className="form-check form-check-coco">
                                                <Input className="form-check-input" type="checkbox" value="" id="flexCheckDisabled" disabled />
                                                <Label className="form-check-label" htmlFor="flexCheckDisabled">
                                                    Disabled checkbox
                                                </Label>
                                            </div>
                                            <div className="form-check form-check-coco">
                                                <Input className="form-check-input" type="checkbox" value="" id="flexCheckCheckedDisabled" defaultChecked disabled />
                                                <Label className="form-check-label" htmlFor="flexCheckCheckedDisabled">
                                                    Disabled checked checkbox
                                                </Label>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col xxl={3} md={6}>
                                        <div>
                                            <p className="text-muted fw-medium">Default Switches <code>.form-switch-coco</code></p>
                                            <div className="form-check form-switch form-switch-coco mb-2">
                                                <Input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" />
                                                <Label className="form-check-label" htmlFor="flexSwitchCheckDefault">Default switch input</Label>
                                            </div>

                                            <div className="form-check form-switch form-switch-coco">
                                                <Input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked" defaultChecked />
                                                <Label className="form-check-label" htmlFor="flexSwitchCheckChecked">Checked switch input</Label>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col xxl={3} md={6}>
                                        <div className="mt-4 mt-md-0">
                                            <p className="text-muted fw-medium">Disabled Switches <code>.form-switch-coco</code></p>
                                            <div className="form-check form-switch-coco form-switch mb-2">
                                                <Input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDisabled" disabled />
                                                <Label className="form-check-label" htmlFor="flexSwitchCheckDisabled">Switch input</Label>
                                            </div>

                                            <div className="form-check form-switch-coco form-switch">
                                                <Input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckCheckedDisabled1" defaultChecked disabled />
                                                <Label className="form-check-label" htmlFor="flexSwitchCheckCheckedDisabled1">Disabled checked switch input</Label>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col md={12} xxl={12}>
                                        <Card>
                                            <CardBody>
                                                <div className="live-preview">
                                                    <Form className="was-validated">
                                                        <div className="form-check form-check-coco mb-3">
                                                            <Input type="checkbox" className="form-check-input" id="validationFormCheck1" required />
                                                            <Label className="form-check-label" htmlFor="validationFormCheck1">Check this checkbox</Label>
                                                            <div className="invalid-feedback">Example invalid feedback text</div>
                                                        </div>

                                                        <div className="form-check form-radio-coco">
                                                            <Input type="radio" className="form-check-input" id="validationFormCheck2" name="radio-stacked" required />
                                                            <Label className="form-check-label" htmlFor="validationFormCheck2">Toggle this radio</Label>
                                                        </div>
                                                        <div className="form-check form-radio-coco mb-3">
                                                            <Input type="radio" className="form-check-input" id="validationFormCheck3" name="radio-stacked" required />
                                                            <Label className="form-check-label" for="validationFormCheck3">Or toggle this other radio</Label>
                                                            <div className="invalid-feedback">More example invalid feedback text</div>
                                                        </div>
                                                    </Form>
                                                </div>
                                                <div className="d-none code-view">
                                                    <pre className="language-markup" style={{ "height": "352px" }}>
                                                      <code>
                                                      </code>
                                                    </pre>
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                    <Col xxl={3} md={6}>
                                        <div className="mt-4 mt-md-0">
                                            <p className="text-muted fw-medium">Chip Radio <code>.form-check-btn</code></p>
                                            <div className="hstack flex-wrap gap-2">
                                                <div className="form-check form-check-btn form-switch-coco form-switch mb-2 ps-0">
                                                    <Input type="radio" className="form-check-input btn-check" id="chipRadioButton1" name="chip-radio" />
                                                    <Label className="form-check-label btn btn-coco-outline-1" htmlFor="chipRadioButton1">chip one</Label>
                                                </div>
                                                <div className="form-check form-check-btn form-switch-coco form-switch mb-2 ps-0">
                                                    <Input type="radio" className="form-check-input btn-check" id="chipRadioButton2" name="chip-radio" />
                                                    <Label className="form-check-label btn btn-coco-outline-1" htmlFor="chipRadioButton2">chip two</Label>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col xxl={3} md={6}>
                                        <div className="mt-4 mt-md-0">
                                            <p className="text-muted fw-medium">Chip Radio - disabled</p>
                                            <div className="hstack flex-wrap gap-2">
                                                <div className="form-check form-check-btn form-switch-coco form-switch mb-2 ps-0">
                                                    <Input type="radio" className="form-check-input btn-check" id="chipRadioButton3" name="chip-radio-disabled" checked={true} disabled={true}/>
                                                    <Label className="form-check-label btn btn-coco-outline-1" htmlFor="chipRadioButton3">checked</Label>
                                                </div>
                                                <div className="form-check form-check-btn form-switch-coco form-switch mb-2 ps-0">
                                                    <Input type="radio" className="form-check-input btn-check" id="chipRadioButton4" name="chip-radio-disabled" disabled={true}/>
                                                    <Label className="form-check-label btn btn-coco-outline-1" htmlFor="chipRadioButton4">unchecked</Label>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col md={12} xxl={12}>
                                        <div className="mt-4 mt-md-0">
                                            <p className="text-muted fw-medium">Chip Checkbox <code>type="checkbox"</code></p>
                                            <div className="hstack flex-wrap gap-2">
                                                <div className="form-check form-check-btn form-switch-coco form-switch mb-2 ps-0">
                                                    <Input type="checkbox" className="form-check-input btn-check" id="chipCheckboxButton1" name="chip-checkbox" />
                                                    <Label className="form-check-label btn btn-coco-outline-1" htmlFor="chipCheckboxButton1">chip one</Label>
                                                </div>
                                                <div className="form-check form-check-btn form-switch-coco form-switch mb-2 ps-0">
                                                    <Input type="checkbox" className="form-check-input btn-check" id="chipCheckboxButton2" name="chip-checkbox" />
                                                    <Label className="form-check-label btn btn-coco-outline-1" htmlFor="chipCheckboxButton2">chip two</Label>
                                                </div>
                                                <div className="form-check form-check-btn form-switch-coco form-switch mb-2 ps-0">
                                                    <Input type="checkbox" className="form-check-input btn-check" id="chipCheckboxButton3" name="chip-checkbox"/>
                                                    <Label className="form-check-label btn btn-coco-outline-1" htmlFor="chipCheckboxButton3">chip three</Label>
                                                </div>
                                                <div className="form-check form-check-btn form-switch-coco form-switch mb-2 ps-0">
                                                    <Input type="checkbox" className="form-check-input btn-check" id="chipCheckboxButton4" name="chip-checkbox" />
                                                    <Label className="form-check-label btn btn-coco-outline-1" htmlFor="chipCheckboxButton4">chip four</Label>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                            <div className="d-none code-view">
                                <pre className="language-markup" style={{ height: "352px" }}>
                                    <code>

                                    </code>
                                </pre>
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            </Row>

            <Row>
                <Col lg={12}>
                    <Card>
                        <CardHeader className="fw-semibold">
                            Button
                        </CardHeader>
                        <CardBody className="card-body">
                            <div className="live-preview">
                                <Row className="gy-4">
                                    <Col xxl={3} md={6}>
                                        <div className="hstack flex-wrap gap-2">
                                            <button type="button" className="btn btn-coco-1">
                                                btn-coco-1
                                            </button>

                                            <button type="button" className="btn btn-coco-1" disabled>
                                                Disabled
                                            </button>

                                            <button type="button" className="btn btn-coco-1">
                                                <span className="d-flex align-items-center">
                                                    <Spinner size="sm" className="flex-shrink-0"> Loading... </Spinner>
                                                    <span className="flex-grow-1 ms-2">
                                                        Processing...
                                                    </span>
                                                </span>
                                            </button>
                                        </div>
                                    </Col>
                                    <Col xxl={3} md={6}>
                                        <div className="hstack flex-wrap gap-2">
                                            <button type="button" className="btn btn-coco-2">
                                                btn-coco-2
                                            </button>

                                            <button type="button" className="btn btn-coco-2" disabled>
                                                Disabled
                                            </button>
                                        </div>
                                    </Col>
                                    <Col xxl={3} md={6}>
                                        <div className="hstack flex-wrap gap-2">
                                            <button type="button" className="btn btn-coco-3">
                                                btn-coco-3
                                            </button>

                                            <button type="button" className="btn btn-coco-3" disabled>
                                                Disabled
                                            </button>
                                        </div>
                                    </Col>
                                    <Col xxl={3} md={6}>
                                        <div className="hstack flex-wrap gap-2">
                                            <button type="button" className="btn btn-coco-outline-1">
                                                btn-coco-outline-1
                                            </button>

                                            <button type="button" className="btn btn-coco-outline-2">
                                                btn-coco-outline-2
                                            </button>
                                        </div>
                                    </Col>
                                    <Col xxl={3} md={6}>
                                        <div className="hstack flex-wrap gap-2">
                                            <button type="button" className="btn btn-coco-ghost-1">
                                                btn-coco-ghost-1
                                            </button>
                                        </div>
                                    </Col>
                                    <Col xxl={3} md={6}>
                                        <div className="hstack flex-wrap gap-2">
                                            <button type="button" className="btn btn-coco-ghost-2">
                                                btn-coco-ghost-2
                                            </button>
                                        </div>
                                    </Col>
                                    <Col xxl={3} md={6}>
                                        <div className="hstack flex-wrap gap-2">
                                            <button type="button" className="btn btn-coco-ghost-3">
                                                btn-coco-ghost-3
                                            </button>
                                        </div>
                                    </Col>
                                    <Col xxl={3} md={6}></Col>
                                    <Col xxl={3} md={6}>
                                        <div className="hstack flex-wrap gap-2">
                                            <button type="button" className="btn btn-coco-xs-1">
                                                btn-coco-xs-1
                                            </button>
                                        </div>
                                    </Col>
                                    <Col xxl={3} md={6}>
                                        <div className="hstack flex-wrap gap-2">
                                            <button type="button" className="btn btn-coco-xs-2">
                                                btn-coco-xs-2
                                            </button>
                                        </div>
                                    </Col>
                                    <Col xxl={3} md={6}>
                                        <div className="hstack flex-wrap gap-2">
                                            <button type="button" className="btn btn-coco-icon-1">
                                                <i className="ri-delete-bin-line"></i>
                                            </button>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                            <div className="d-none code-view">
                                    <pre className="language-markup" style={{ height: "352px" }}>
                                        <code>

                                        </code>
                                    </pre>
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col lg={12}>
                    <Card>
                        <CardHeader className="fw-semibold">
                            Checkbox & Radio Buttons
                            <div className="text-muted fw-medium"> ButtonGroup<code>.coco-btn-grp</code></div>
                        </CardHeader>
                        <CardBody className="card-body">
                            <div className="live-preview">
                                <Row className="gy-4">
                                    <Col xxl={3} md={6}>
                                        <p className="text-muted fw-medium">Default Checkbox <code>type="checkbox"</code></p>
                                        <ButtonGroup className="w-100 coco-btn-grp">
                                            <Input type="checkbox" className="btn-check" id="btncheck1" defaultChecked="" />
                                            <Button type="button" id="btncheck1"
                                                    color={"coco-outline-2"}
                                                    onClick={() => onCheckboxBtnGrpClick("checkbox-1")}
                                                    active={checkboxBtnGroupVal.includes("checkbox-1")}
                                            >checkbox 1</Button>

                                            <Input type="checkbox" className="btn-check" id="btncheck2" />
                                            <Button type="button" id="btncheck2"
                                                    color={"coco-outline-2"}
                                                    onClick={() => onCheckboxBtnGrpClick("checkbox-2")}
                                                    active={checkboxBtnGroupVal.includes("checkbox-2")}
                                            >checkbox 2</Button>

                                            <Input type="checkbox" className="btn-check" id="btncheck3" />
                                            <Button type="button" id="btncheck3"
                                                    color={"coco-outline-2"}
                                                    onClick={() => onCheckboxBtnGrpClick("checkbox-3")}
                                                    active={checkboxBtnGroupVal.includes("checkbox-3")}
                                            >checkbox 3</Button>
                                        </ButtonGroup>
                                    </Col>
                                    <Col xxl={3} md={6}>
                                        <p className="text-muted fw-medium">Default Radio <code>type="radio"</code></p>
                                        <ButtonGroup className="w-100 coco-btn-grp">
                                            <Input type="radio" className="btn-check" id="btnradio1" defaultChecked="" />
                                            <Button type="button" id="btnradio1"
                                                    color={"coco-outline-2"}
                                                    onClick={() => setRadioBtnGroupVal("radio-1")}
                                                    active={radioBtnGroupVal === "radio-1"}
                                            >radio 1</Button>

                                            <Input type="radio" className="btn-check" id="btnradio2" />
                                            <Button type="button" id="btnradio2"
                                                    color={"coco-outline-2"}
                                                    onClick={() => setRadioBtnGroupVal("radio-2")}
                                                    active={radioBtnGroupVal === "radio-2"}
                                            >radio 2</Button>

                                            <Input type="radio" className="btn-check" id="btnradio3" />
                                            <Button type="button" id="btnradio3"
                                                    color={"coco-outline-2"}
                                                    onClick={() => setRadioBtnGroupVal("radio-3")}
                                                    active={radioBtnGroupVal === "radio-3"}
                                            >radio 3</Button>
                                        </ButtonGroup>
                                    </Col>
                                    <Col xxl={3} md={6}>
                                        <p className="text-muted fw-medium"><code>.is-invalid</code></p>
                                        <Form className="was-validated">
                                            <ButtonGroup className="w-100 coco-btn-grp is-invalid">
                                                <button type="button"
                                                        className={"btn btn-coco-outline-2 is-invalid"}>invalid-1</button>
                                                <button type="button"
                                                        className={"btn btn-coco-outline-2 is-invalid"}>invalid-2</button>
                                            </ButtonGroup>
                                            <div className="invalid-feedback">Example invalid feedback text</div>
                                        </Form>
                                    </Col>
                                </Row>
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col lg={12}>
                    <Card>
                        <CardHeader className="fw-semibold">
                            Badge
                        </CardHeader>
                        <CardBody>
                            <table className="table table-bordered table-striped table-nowrap align-middle mb-0">
                                <thead>
                                    <tr>
                                        <th scope="col">
                                            type
                                        </th>
                                        <th scope="col" className="text-center">
                                            green
                                        </th>
                                        <th scope="col" className="text-center">
                                            yellow
                                        </th>
                                        <th scope="col" className="text-center">
                                            red
                                        </th>
                                        <th scope="col" className="text-center">
                                            skyblue
                                        </th>
                                        <th scope="col" className="text-center">
                                            navy
                                        </th>
                                        <th scope="col" className="text-center">
                                            purple
                                        </th>
                                        <th scope="col" className="text-center">
                                            gray
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <th className="" scope="row">
                                            default
                                        </th>
                                        <td>
                                            <div className="badge badge-coco badge-coco-default-green">GREEN</div>
                                        </td>
                                        <td>
                                            <div className="badge badge-coco badge-coco-default-yellow">YELLOW</div>
                                        </td>
                                        <td>
                                            <div className="badge badge-coco badge-coco-default-red">RED</div>
                                        </td>
                                        <td>
                                            <div className="badge badge-coco badge-coco-default-skyblue">SKYBLUE</div>
                                        </td>
                                        <td>
                                            <div className="badge badge-coco badge-coco-default-navy">NAVY</div>
                                        </td>
                                        <td>
                                            <div className="badge badge-coco badge-coco-default-purple">PURPLE</div>
                                        </td>
                                        <td>
                                            <div className="badge badge-coco badge-coco-default-gray">GRAY</div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th className="" scope="row">
                                            soft
                                        </th>
                                        <td>
                                            <div className="badge badge-coco badge-coco-soft-green">GREEN</div>
                                        </td>
                                        <td>
                                            <div className="badge badge-coco badge-coco-soft-yellow">YELLOW</div>
                                        </td>
                                        <td>
                                            <div className="badge badge-coco badge-coco-soft-red">RED</div>
                                        </td>
                                        <td>
                                            <div className="badge badge-coco badge-coco-soft-skyblue">SKYBLUE</div>
                                        </td>
                                        <td>
                                            <div className="badge badge-coco badge-coco-soft-navy">NAVY</div>
                                        </td>
                                        <td>
                                            <div className="badge badge-coco badge-coco-soft-purple">PURPLE</div>
                                        </td>
                                        <td>
                                            <div className="badge badge-coco badge-coco-soft-gray">GRAY</div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th className="" scope="row">
                                            outline
                                        </th>
                                        <td>
                                            <div className="badge badge-coco badge-coco-outline-green">GREEN</div>
                                        </td>
                                        <td>
                                            <div className="badge badge-coco badge-coco-outline-yellow">YELLOW</div>
                                        </td>
                                        <td>
                                            <div className="badge badge-coco badge-coco-outline-red">RED</div>
                                        </td>
                                        <td>
                                            <div className="badge badge-coco badge-coco-outline-skyblue">SKYBLUE</div>
                                        </td>
                                        <td>
                                            <div className="badge badge-coco badge-coco-outline-navy">NAVY</div>
                                        </td>
                                        <td>
                                            <div className="badge badge-coco badge-coco-outline-purple">PURPLE</div>
                                        </td>
                                        <td>
                                            <div className="badge badge-coco badge-coco-outline-gray">GRAY</div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col lg={12}>
                    <Card>
                        <CardHeader className="fw-semibold">
                            Color
                        </CardHeader>
                        <CardBody>
                            <table className="table table-bordered table-striped table-nowrap align-middle mb-0">
                                <tbody>
                                <tr>
                                    <th className="" scope="row">
                                        bg-coco-primary <br/>text-coco-primary <br/> border-coco-primary
                                    </th>
                                    <td>
                                        <div className="bg-coco-primary-900 text-coco-default-white bg-gradient p-2">900</div>
                                    </td>
                                    <td>
                                        <div className="bg-coco-primary-800 text-coco-default-white bg-gradient p-2">800</div>
                                    </td>
                                    <td>
                                        <div className="bg-coco-primary-700 text-coco-default-white bg-gradient p-2">700</div>
                                    </td>
                                    <td>
                                        <div className="bg-coco-primary-600 bg-gradient p-2">600</div>
                                    </td>
                                    <td>
                                        <div className="bg-coco-primary-500 bg-gradient p-2">500</div>
                                    </td>
                                    <td>
                                        <div className="bg-coco-primary-400 bg-gradient p-2">400</div>
                                    </td>
                                    <td>
                                        <div className="bg-coco-primary-300 bg-gradient p-2">300</div>
                                    </td>
                                    <td>
                                        <div className="bg-coco-primary-200 bg-gradient p-2">200</div>
                                    </td>
                                    <td>
                                        <div className="bg-coco-primary-100 bg-gradient p-2">100</div>
                                    </td>
                                    <td>
                                        <div className="bg-coco-primary-50 bg-gradient p-2">50</div>
                                    </td>
                                    <td>
                                        <div className="bg-coco-primary-a-100 bg-gradient p-2">a-100</div>
                                    </td>
                                    <td>
                                        <div className="bg-coco-primary-a-200 bg-gradient p-2">a-200</div>
                                    </td>
                                    <td>
                                        <div className="bg-coco-primary-a-300 bg-gradient p-2">a-300</div>
                                    </td>
                                    <td>
                                        <div className="bg-coco-primary-a-400 bg-gradient p-2">a-400</div>
                                    </td>
                                    <td>
                                        <div className="bg-coco-primary-a-400-op-30 bg-gradient p-2">a-400-op-30</div>
                                    </td>
                                    <td>
                                        <div className="bg-coco-primary-50-op-70 bg-gradient p-2">50-op-70</div>
                                    </td>
                                </tr>
                                <tr>
                                    <th className="" scope="row">
                                        bg-coco-secondary <br/>text-coco-secondary <br/> border-coco-secondary
                                    </th>
                                    <td>
                                        <div className="bg-coco-secondary-900 text-coco-default-white bg-gradient p-2">900</div>
                                    </td>
                                    <td>
                                        <div className="bg-coco-secondary-800 text-coco-default-white bg-gradient p-2">800</div>
                                    </td>
                                    <td>
                                        <div className="bg-coco-secondary-700 text-coco-default-white bg-gradient p-2">700</div>
                                    </td>
                                    <td>
                                        <div className="bg-coco-secondary-600 bg-gradient p-2">600</div>
                                    </td>
                                    <td>
                                        <div className="bg-coco-secondary-500 bg-gradient p-2">500</div>
                                    </td>
                                    <td>
                                        <div className="bg-coco-secondary-400 bg-gradient p-2">400</div>
                                    </td>
                                    <td>
                                        <div className="bg-coco-secondary-300 bg-gradient p-2">300</div>
                                    </td>
                                    <td>
                                        <div className="bg-coco-secondary-200 bg-gradient p-2">200</div>
                                    </td>
                                    <td>
                                        <div className="bg-coco-secondary-100 bg-gradient p-2">100</div>
                                    </td>
                                    <td>
                                        <div className="bg-coco-secondary-50 bg-gradient p-2">50</div>
                                    </td>
                                    <td>
                                        <div className="bg-coco-secondary-a-100 bg-gradient p-2">a-100</div>
                                    </td>
                                    <td>
                                        <div className="bg-coco-secondary-a-200 bg-gradient p-2">a-200</div>
                                    </td>
                                    <td>
                                        <div className="bg-coco-secondary-a-300 bg-gradient p-2">a-300</div>
                                    </td>
                                    <td>
                                        <div className="bg-coco-secondary-a-400 bg-gradient p-2">a-400</div>
                                    </td>
                                    <td>
                                        <div className="bg-coco-secondary-300-op-20 bg-gradient p-2">300-op-20</div>
                                    </td>
                                    <td>
                                        <div className="bg-coco-secondary-300-op-10 bg-gradient p-2">300-op-10</div>
                                    </td>
                                </tr>
                                <tr>
                                    <th className="" scope="row">
                                        bg-coco-gray <br/>text-coco-gray <br/> border-coco-gray
                                    </th>
                                    <td>
                                        <div className="bg-coco-gray-900 text-coco-default-white bg-gradient p-2">900</div>
                                    </td>
                                    <td>
                                        <div className="bg-coco-gray-800 text-coco-default-white bg-gradient p-2">800</div>
                                    </td>
                                    <td>
                                        <div className="bg-coco-gray-700 text-coco-default-white bg-gradient p-2">700</div>
                                    </td>
                                    <td>
                                        <div className="bg-coco-gray-600 bg-gradient p-2">600</div>
                                    </td>
                                    <td>
                                        <div className="bg-coco-gray-500 bg-gradient p-2">500</div>
                                    </td>
                                    <td>
                                        <div className="bg-coco-gray-400 bg-gradient p-2">400</div>
                                    </td>
                                    <td>
                                        <div className="bg-coco-gray-300 bg-gradient p-2">300</div>
                                    </td>
                                    <td>
                                        <div className="bg-coco-gray-200 bg-gradient p-2">200</div>
                                    </td>
                                    <td>
                                        <div className="bg-coco-gray-100 bg-gradient p-2">100</div>
                                    </td>
                                    <td>
                                        <div className="bg-coco-gray-50 bg-gradient p-2">50</div>
                                    </td>
                                    <td>
                                        <div className="bg-coco-gray-a-200 bg-gradient p-2">a-200</div>
                                    </td>
                                    <td>
                                        <div className="bg-coco-gray-a-400 bg-gradient p-2">a-400</div>
                                    </td>
                                    <td>
                                        <div className="bg-coco-gray-a-600 bg-gradient p-2">a-600</div>
                                    </td>
                                    <td>
                                        <div className="bg-coco-gray-a-700 bg-gradient p-2">a-700</div>
                                    </td>
                                </tr>
                                <tr>
                                    <th className="" scope="row">
                                        bg-coco-danger <br/>text-coco-danger <br/> border-coco-danger
                                    </th>
                                    <td>
                                        <div className="bg-coco-danger-900 text-coco-default-white bg-gradient p-2">900</div>
                                    </td>
                                    <td>
                                        <div className="bg-coco-danger-800 text-coco-default-white bg-gradient p-2">800</div>
                                    </td>
                                    <td>
                                        <div className="bg-coco-danger-700 text-coco-default-white bg-gradient p-2">700</div>
                                    </td>
                                    <td>
                                        <div className="bg-coco-danger-600 bg-gradient p-2">600</div>
                                    </td>
                                    <td>
                                        <div className="bg-coco-danger-500 bg-gradient p-2">500</div>
                                    </td>
                                    <td>
                                        <div className="bg-coco-danger-400 bg-gradient p-2">400</div>
                                    </td>
                                    <td>
                                        <div className="bg-coco-danger-300 bg-gradient p-2">300</div>
                                    </td>
                                    <td>
                                        <div className="bg-coco-danger-200 bg-gradient p-2">200</div>
                                    </td>
                                    <td>
                                        <div className="bg-coco-danger-100 bg-gradient p-2">100</div>
                                    </td>
                                    <td>
                                        <div className="bg-coco-danger-50 bg-gradient p-2">50</div>
                                    </td>
                                    <td>
                                        <div className="bg-coco-danger-a-50 bg-gradient p-2">a-50</div>
                                    </td>
                                </tr>
                                <tr>
                                    <th className="" scope="row">
                                        bg-coco <br/>text-coco <br/> border-coco
                                    </th>
                                    <td>
                                        <div className="bg-coco-body-bg bg-gradient p-2">body-bg</div>
                                    </td>
                                    <td>
                                        <div className="bg-coco-default-black bg-gradient p-2 text-coco-default-white">default-black</div>
                                    </td>
                                    <td>
                                        <div className="bg-coco-default-white bg-gradient p-2">default-white</div>
                                    </td>
                                    <td>
                                        <div className="bg-coco-bg-main bg-gradient p-2">bg-main</div>
                                    </td>
                                    <td>
                                        <div className="bg-coco-vz-warning bg-gradient p-2">vz-warning</div>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </>
    )
}


export default UiFormTest;
