// @ts-nocheck
import {ReviewView} from "../../../components/taskSideBar/classification/ReviewView";

import React, {useEffect, useState} from "react";
import CommonUtil from "../../../../../../../util/CommonUtil";
import {useSelector} from "react-redux";
import {MD_STTUS_CD, MD_TY_CD} from "../../../../annotation/image/const";
import {GetMessageIntl} from "../../../../../../../util/message";
import {useIntl} from "react-intl";
interface Props {
    reviews: any;
    label: any;
    hookform: any;
}

export const Review = ({reviews, label, hookform}: Props) => {
    const {status, type: moduleType} = useSelector(state => state.Label.Cmmn.module);
    const {datasetId, fileSeq} = useSelector(state => state.Label.Cmmn.frames.frame);
    // const reviews = useSelector(state => state.Review.Cmmn.annotations.data.classes);
    const {register, setValue, getValues, clearErrors} = hookform;
    const questionName = `review.classes.${label.order}.q1`;
    const classesRejectName = `review.classes.${label.order}.reject`;
    const qRejectName = questionName+`.reject`;
    const [reject, setReject] = useState(null);
    const [modified, setModified] = useState(null);
    // console.log('isSubmitted',hookform.formState.isSubmitted)
    const updateReject = (reject: boolean | null) => {
        setValue(classesRejectName, reject);
        setValue(qRejectName, reject, {shouldValidate: reject !== null});   // 초기화일 경우, validation을 하지 않는다.
        setReject(reject);

        // 검수 결과가 '통과'일 때, 반려 사유의 validation error는 삭제해준다.
        if (reject === false) {
            clearErrors(questionName + '.content.0.details');
        }

        // 재검의 반려 사유 disabled처리를 위하여 한번이라도 검수 버튼을 눌렀으면 표시를 해준다.
        if (typeof reject === "boolean" && hookform.getValues().review.classes[label.order].q1.content[0].touch === false) {
            setValue(questionName + '.content.0.touch', true, {shouldValidate: true});
        }
    }

    const intl = useIntl();
    const touchWatch = hookform.watch(`review.classes.${label.order}.q1.content.0.touch`, false);
    useEffect(() => {
        // 데이터가 바뀔 때마다 해줘야하는 과정
        setValue(`review.classes.${label.order}.id`, label.id)
        setValue(`review.classes.${label.order}.q1.content.0.completedError`, false) // -> stage 표시 todo: validate 추가
        register(`review.classes.${label.order}.q1.content.0.touch` // 한 번이라도 수정을 했는 지에 대한 여부
            , { value: moduleType === MD_TY_CD.LABEL,   // 라벨러일 경우, 검수 결과의 수정 여부를 제한하지 않는다.
                validate: (v, formValue) => {   // 한 번이라도 수정했으면 pass, 또는 한번이라도 안 만졌더라도 이전 검수 내역이 통과였으면 pass)
                    const reject = formValue.review.classes[label.order].reject;
                    // const reject = formValue.review.classes.find(review => review.id == label.id)?.reject;
                    return  (v === true || v === false && reject === false) || GetMessageIntl(intl, 'review.reviewValidationError')
                }
        })
        setModified(false)
    }, [datasetId, fileSeq])

    useEffect(() => {
        // console.log('review 바뀜',reviews, reviews[label.order], reviews[label.order]?.reject, reject)
        // console.log('review 바뀜2',reviews[label.order]?.q1.content[0]?.details)
        if (moduleType === MD_TY_CD.REVIEW && touchWatch === true) return;    // 검수캔버스일때, 검수 결과바뀔때마다 초기화해주면 안된다.
        //console.log('[Review] useEffect의 reviews',label.order, reviews, touchWatch)

        // 이전 검수 결과가 없을 경우, default 값을 설정한다.
        const review = reviews.find(review => review.id == label.id);
        if (review === undefined) {
            updateReject(null);
        } else {
            const reject = review.reject;
            //updateReject(reject);
            setValue(classesRejectName, reject);
            setValue(qRejectName, reject);
            setReject(reject);
            // console.log('[Review] reject', reject)
            // setReject(hookform.getValues().review.classes[label.order].reject);
            if (reject){
                setValue(questionName+'.content.0.details', review.q1.content[0].details)
            }
            // console.log(getValues(questionName+'.content.0.details'), hookform, questionName+'.content.0.details')
        }
    }, [reviews])

    const onPassStage = () => {
        // 반려에서 승인으로 변경하더라도, 반려 사유는 사라지지 않고 유지됨
        updateReject(false);
    }

    const onRejectStage = () => {
        updateReject(true);
    }

    const updateModified = () => {
        setValue(`modified.${label.order}`, !modified, {shouldValidate: hookform.formState.isSubmitted});
        setModified(!modified)
    }

    return (
        <>
            <input type={"hidden"} name={"reject"}
                   {...register(qRejectName, {
                       validate: {
                           isBoolean: (value) => CommonUtil.checkType(value, 'boolean') || GetMessageIntl(intl, 'review.reviewValidationError')
                       }
                       }
                   )}
            />
            <ReviewView
                readonly={moduleType !== MD_TY_CD.REVIEW}
                label={label}
                clsfcSmryForm={hookform}
                reject={reject}
                reviewButtonVisible={!(touchWatch===false && reject===false)}   // 이전 검수 결과가 반려 또는 없을 경우에만
                modified={modified}
                questionKeyName={questionName}
                guideVisible={moduleType === MD_TY_CD.REVIEW && status === MD_STTUS_CD.RE && reject === true}
                onClickPass={onPassStage}
                onClickReject={onRejectStage}
                onClickCheck={updateModified}
            />
        </>
    );
}
