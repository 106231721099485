// @ts-nocheck
import {QuestionView} from "../../../components/taskSideBar/summary/QuestionView";
import {GetMessage, GetMessageIntl} from "../../../../../../../util/message";
import {useEffect, useState} from "react";
import { useIntl } from "react-intl";
import {useSelector} from "react-redux";
import {MD_TY_CD} from "../../../../annotation/image/const";

export const ReviewRejectReasons = ({hookform, questionKeyName}): JSX.Element => {
    const {setValue} = hookform;
    const watchTouch = hookform.watch(questionKeyName + `.content.0.touch`, true);
    const moduleType = useSelector(state => state.Label.Cmmn.module.type);
    useEffect(()=> {
        // 분류의 content는 언제나 1개이다.
        setValue(questionKeyName + `.content.0.id`, 0);
    }, [])

    const rejectReasonsKeyName = questionKeyName + `.content.0.details`;

    const intl = useIntl();
    const rules = {
        required: GetMessageIntl(intl, 'review.reviewValidationError')
    }
    return (
        <>
            {moduleType === MD_TY_CD.REVIEW
                ? <QuestionView
                    row={3}
                    placeholder={GetMessage("review.inputRejectReasonPlaceholder")}
                    hookform={hookform}
                    keyName={rejectReasonsKeyName}
                    rules={rules}
                    disabled={!watchTouch}
                />
                : <div className={"fs-16 fw-medium lh-sm"}> : {hookform.getValues(rejectReasonsKeyName)}</div>
            }
        </>
    )

}
