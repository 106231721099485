import {Input, Label} from "reactstrap";
import React from "react";

interface Props {
    clientID: number;
    index: number;
    rsn: any;
    choiceCdList: string[];
    onCdChange(event): void;
}
export function ObjectReviewContent(props: Props) {
    const {clientID, index, rsn, choiceCdList, onCdChange} = props;
    const {cmmnDetailCd: rsnCd} = rsn;
    const inputID = `content-${clientID}-${index}`;
    const checked = choiceCdList.includes(rsnCd);
    const disabled = rsnCd==="01" && (choiceCdList.length !== 0 && !choiceCdList.includes("01"))
        || rsnCd !== "01" && (choiceCdList.length !==0 && choiceCdList.includes("01"));

    return (
        <div className="form-check form-check-coco" key={rsnCd}>
            <Input className="form-check-input" type="checkbox"
                   id={inputID}
                   // defaultChecked={choiceCdList.includes(rsnCd)}
                   onChange={(event) => {
                       onCdChange(event.target)
                   }}
                   value={rsnCd}
                   checked={checked}
                   disabled={disabled}
            />
            <Label className="form-check-label" htmlFor={inputID}>
                {rsn.cmmnDetailCdNm}
            </Label>
        </div>
    )
}
