import {callApi} from '../utils/AxiosUtil';

/**
 *  이미지 라벨링 / 검수 관련 서비스
 */
export const ImageLabelService={
    // 라벨링 대상 이미지
    getTargetImage: (param) => callApi('/service/label/image/getTargetImage', param),
    // shape config 조회
    getProjectInfo: (param) => callApi('/service/label/image/getAttrList', param),
    // 라벨링 대상 이미지 목록
    getTargetImageList: (param) => callApi('/service/label/image/getTargetImageList', param),

    // 이전 라벨링 결과 조회
    getExFilePath: (param) => callApi('/service/label/image/getExFilePath', param),
    // autoLabeling 결과 조회
    getBoxPoints: (param) => callApi('/service/project/autolabel/getBoxPoints', param),
    
    /*
    /service/label/getTargetImageList
    /service/label/getTargetImage
    /service/label/export/run
    /service/label/getAttrList
    /service/label/getNextItem

    // Todo: /service/project/label -> /service/label/image
    /service/project/label/jobTargetBind
    /service/project/label/getExFilePath
    /service/project/label/checkBefore
    /service/review/save
    /service/review/getSavedItem
    /service/project/review/getNextItem
    /service/project/review/jobTargetBind
    /service/project/review/checkBefore

    /service/labeler/project/checkBeforeLabeling
    /service/labeler/project/joinProject
    /service/labeler/project/jobTargetBind
    /service/labeler/project/noticeList

    */
}

