// @ts-nocheck
import './brush-toolbox-styles.scss';

import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useDispatch, useSelector } from 'react-redux';
import Button from 'antd/lib/button';
import Icon, { VerticalAlignBottomOutlined } from '@ant-design/icons';
import InputNumber from 'antd/lib/input-number';
import Select from 'antd/lib/select';

import { filterApplicableForType } from 'pages/user/label/annotation/image/utils/filter-applicable-labels';
import { getCore, Label, LabelType } from 'pages/user/label/annotation/image/cvat-core-wrapper';
import { Canvas, CanvasMode } from 'pages/user/label/annotation/image/cvat-canvas-wrapper';
import {
    BrushIcon, EraserIcon, PolygonMinusIcon, PolygonPlusIcon,
    PlusIcon, CheckIcon, MoveIcon,
} from 'pages/user/label/annotation/image/icons';
import CVATTooltip from 'pages/user/label/annotation/image/components/common/cvat-tooltip';
import { ObjectType, ShapeType } from 'store/label';
import LabelSelector from 'pages/user/label/annotation/image/components/label-selector/label-selector';
import { rememberObject, updateCanvasBrushTools } from 'store/label/action';
import useDraggable from './draggable-hoc';
import GlobalHotKeys from "../../../../utils/mousetrap-react";
import {Input} from "reactstrap";
import {toNumber} from "lodash";
import Draggable, {DraggableData, DraggableEvent} from "react-draggable";

const DraggableArea = (
    <div className='coco-brush-tools-draggable-area'>
        <Icon component={MoveIcon} />
    </div>
);

const MIN_BRUSH_SIZE = 1;
const MAX_BRUSH_SIZE = 999;
function BrushTools(): React.ReactPortal | null {
    const dispatch = useDispatch();
    const defaultLabelID = useSelector((state) => state.Label.Cmmn.drawing.activeLabelID);
    const config = useSelector((state) => state.Label.Cmmn.canvas.brushTools);
    const canvasInstance = useSelector((state) => state.Label.Cmmn.canvas.instance);
    const labels = useSelector((state) => state.Label.Cmmn.module.labels);
    const keyMap = useSelector((state) => state.shortcuts.keyMap);
    const { visible } = config;

    const [editableState, setEditableState] = useState<any | null>(null);
    const [currentTool, setCurrentTool] = useState<'brush' | 'eraser' | 'polygon-plus' | 'polygon-minus'>('brush');
    const [brushForm, setBrushForm] = useState<'circle' | 'square'>('circle');
    const [[top, left], setTopLeft] = useState([80, 740]);
    const [brushSize, setBrushSize] = useState(10);
    const [applicableLabels, setApplicableLabels] = useState<Label[]>([]);

    const [removeUnderlyingPixels, setRemoveUnderlyingPixels] = useState(false);
    const dragBar = useDraggable(
        (): number[] => {
            const [element] = window.document.getElementsByClassName('coco-brush-tools-toolbox');
            if (element) {
                const { offsetTop, offsetLeft } = element as HTMLDivElement;
                return [offsetTop, offsetLeft];
            }

            return [0, 0];
        },
        (newTop, newLeft) => setTopLeft([newTop, newLeft]),
        DraggableArea,
    );

    useEffect(() => {
        const label = labels.find((_label: any) => _label.id === defaultLabelID);
        getCore().config.removeUnderlyingMaskPixels = removeUnderlyingPixels;
        if (visible && label && canvasInstance instanceof Canvas) {
            const onUpdateConfiguration = ({ brushTool }: any): void => {
                if (brushTool?.size) {
                    setBrushSize(Math.max(MIN_BRUSH_SIZE, brushTool.size));
                }
            };

            if (canvasInstance.mode() === CanvasMode.DRAW) {
                canvasInstance.draw({
                    enabled: true,
                    shapeType: ShapeType.MASK,
                    crosshair: false,
                    brushTool: {
                        type: currentTool,
                        size: brushSize,
                        form: brushForm,
                        color: label.color,
                    },
                    onUpdateConfiguration,
                });
            } else if (canvasInstance.mode() === CanvasMode.EDIT && editableState) {
                canvasInstance.edit({
                    enabled: true,
                    state: editableState,
                    brushTool: {
                        type: currentTool,
                        size: brushSize,
                        form: brushForm,
                        color: label.color,
                    },
                    onUpdateConfiguration,
                });
            }
        }
    }, [currentTool, brushSize, brushForm, visible, defaultLabelID, editableState]);

    useEffect(() => {
        setApplicableLabels(filterApplicableForType(LabelType.MASK, labels));
    }, [labels]);

    useEffect(() => {
        const canvasContainer = window.document.getElementsByClassName('coco-canvas-container')[0];
        if (canvasContainer) {
            const { offsetTop, offsetLeft } = canvasContainer.parentElement as HTMLElement;
            // setTopLeft([offsetTop, offsetLeft]);
        }
    }, []);

    useEffect(() => {
        const hideToolset = (): void => {
            if (visible) {
                dispatch(updateCanvasBrushTools({ visible: false }));
            }
        };

        const showToolset = (e: Event): void => {
            const evt = e as CustomEvent;
            if (evt.detail?.state?.shapeType === ShapeType.MASK ||
                (evt.detail?.drawData?.shapeType === ShapeType.MASK && !evt.detail?.drawData?.initialState)) {
                dispatch(updateCanvasBrushTools({ visible: true }));
            }
        };

        const updateEditableState = (e: Event): void => {
            const evt = e as CustomEvent;
            if (evt.type === 'canvas.editstart' && evt.detail.state) {
                setEditableState(evt.detail.state);
            } else if (editableState) {
                setEditableState(null);
            }
        };

        if (canvasInstance instanceof Canvas) {
            canvasInstance.html().addEventListener('canvas.drawn', hideToolset);
            canvasInstance.html().addEventListener('canvas.canceled', hideToolset);
            canvasInstance.html().addEventListener('canvas.canceled', updateEditableState);
            canvasInstance.html().addEventListener('canvas.drawstart', showToolset);
            canvasInstance.html().addEventListener('canvas.editstart', showToolset);
            canvasInstance.html().addEventListener('canvas.editstart', updateEditableState);
            canvasInstance.html().addEventListener('canvas.editdone', updateEditableState);
        }

        return () => {
            if (canvasInstance instanceof Canvas) {
                canvasInstance.html().removeEventListener('canvas.drawn', hideToolset);
                canvasInstance.html().removeEventListener('canvas.canceled', hideToolset);
                canvasInstance.html().removeEventListener('canvas.canceled', updateEditableState);
                canvasInstance.html().removeEventListener('canvas.drawstart', showToolset);
                canvasInstance.html().removeEventListener('canvas.editstart', showToolset);
                canvasInstance.html().removeEventListener('canvas.editstart', updateEditableState);
                canvasInstance.html().removeEventListener('canvas.editdone', updateEditableState);
            }
        };
    }, [visible, editableState]);

    if (!labels.length) {
        return null;
    }

    const preventDefault = (event: KeyboardEvent | undefined): void => {
        if (event) {
            event.preventDefault();
        }
    };

    const subKeyMap = {
        BRUSH_SIZE_UP: keyMap.BRUSH_SIZE_UP,
        BRUSH_SIZE_DOWN: keyMap.BRUSH_SIZE_DOWN,
        SWITCH_ERASER: keyMap.SWITCH_ERASER,
    }

    const handlers = {
        BRUSH_SIZE_UP: (e: KeyboardEvent | undefined) => {
            preventDefault(e);
            if (!visible) return;
            if (["brush", "eraser"].includes(currentTool)){
                setBrushSize(brushSize+1);
            }
        },
        BRUSH_SIZE_DOWN: (e: KeyboardEvent | undefined) => {
            preventDefault(e);
            if (!visible) return;
            if (["brush", "eraser"].includes(currentTool)){
                setBrushSize(Math.max(brushSize-1, MIN_BRUSH_SIZE));
            }
        },
        SWITCH_ERASER: (e: KeyboardEvent | undefined) => {
            preventDefault(e);
            if (!visible) return;
            if (["brush", "eraser"].includes(currentTool)){
                setCurrentTool(currentTool === "brush" ? "eraser" : "brush")
            }
        },
    }

    let activeDrags = false;
    const onStart = (e: DraggableEvent) => {
        e.stopPropagation;
        activeDrags = !activeDrags;
    };
    const onStop = (e: DraggableEvent, data: DraggableData) => {
        activeDrags = !activeDrags;
    }
    const dragHandlers = {
        onStart: onStart,
        onStop: onStop,
        defaultPosition: {x: left, y: top},
        cancel: '.btn-icon, .input-step'
    };

    return ReactDOM.createPortal((
        <>
            <GlobalHotKeys keyMap={subKeyMap} handlers={handlers}></GlobalHotKeys>
            <Draggable {...dragHandlers}>
                <div className='coco-brush-tools-toolbox' style={{ top: '0', left: '0', display: visible ? '' : 'none' }}>
                    {/*<Button*/}
                    {/*    type='text'*/}
                    {/*    className='coco-brush-tools-finish'*/}
                    {/*    icon={<Icon component={CheckIcon} />}*/}
                    {/*    onClick={() => {*/}
                    {/*        if (canvasInstance instanceof Canvas) {*/}
                    {/*            if (editableState) {*/}
                    {/*                canvasInstance.edit({ enabled: false });*/}
                    {/*            } else {*/}
                    {/*                canvasInstance.draw({ enabled: false });*/}
                    {/*            }*/}
                    {/*        }*/}
                    {/*    }}*/}
                    {/*/>*/}
                    {/*{!editableState && (*/}
                    {/*    <Button*/}
                    {/*        type='text'*/}
                    {/*        disabled={!!editableState}*/}
                    {/*        className='coco-brush-tools-continue'*/}
                    {/*        icon={<Icon component={PlusIcon} />}*/}
                    {/*        onClick={() => {*/}
                    {/*            if (canvasInstance instanceof Canvas) {*/}
                    {/*                canvasInstance.draw({ enabled: false, continue: true });*/}

                    {/*                dispatch(*/}
                    {/*                    rememberObject({*/}
                    {/*                        activeObjectType: ObjectType.SHAPE,*/}
                    {/*                        activeShapeType: ShapeType.MASK,*/}
                    {/*                        activeLabelID: defaultLabelID,*/}
                    {/*                    }),*/}
                    {/*                );*/}
                    {/*            }*/}
                    {/*        }}*/}
                    {/*    />*/}
                    {/*)}*/}
                    {/*<hr />*/}
                    <button className={'btn-icon'}
                    >
                        {currentTool === 'brush' && <i className={"ri-eraser-line"}
                                                       onClick={() => setCurrentTool('eraser')}/>}
                        {currentTool === 'eraser' && <i className={"ri-brush-line"}
                                                        onClick={() => setCurrentTool('brush')}/>}
                    </button>
                    {/*<Button*/}
                    {/*    type='text'*/}
                    {/*    className={['coco-brush-tools-brush', ...(currentTool === 'brush' ? ['coco-brush-tools-active-tool'] : [])].join(' ')}*/}
                    {/*    icon={<Icon component={BrushIcon} />}*/}
                    {/*    onClick={() => setCurrentTool('brush')}*/}
                    {/*/>*/}
                    {/*<Button*/}
                    {/*    type='text'*/}
                    {/*    className={['coco-brush-tools-eraser', ...(currentTool === 'eraser' ? ['coco-brush-tools-active-tool'] : [])].join(' ')}*/}
                    {/*    icon={<Icon component={EraserIcon} />}*/}
                    {/*    onClick={() => setCurrentTool('eraser')}*/}
                    {/*/>*/}
                    {/*<Button*/}
                    {/*    type='text'*/}
                    {/*    className={['coco-brush-tools-polygon-plus', ...(currentTool === 'polygon-plus' ? ['coco-brush-tools-active-tool'] : [])].join(' ')}*/}
                    {/*    icon={<Icon component={PolygonPlusIcon} />}*/}
                    {/*    onClick={() => setCurrentTool('polygon-plus')}*/}
                    {/*/>*/}
                    {/*<Button*/}
                    {/*    type='text'*/}
                    {/*    className={['coco-brush-tools-polygon-minus', ...(currentTool === 'polygon-minus' ? ['coco-brush-tools-active-tool'] : [])].join(' ')}*/}
                    {/*    icon={<Icon component={PolygonMinusIcon} />}*/}
                    {/*    onClick={() => setCurrentTool('polygon-minus')}*/}
                    {/*/>*/}
                    { ['brush', 'eraser'].includes(currentTool) ? (
                            <div className={"input-step light"} >
                                <Input
                                    type="number"
                                    className="coco-brush-tools-brush-size"
                                    value={brushSize}
                                    onChange={(e) => {
                                        const value = toNumber(e.target.value.replace(/[^0-9]/g, ""));
                                        console.log('input 값 바꾸려고 함', e.target.value, toNumber(value), Number.isInteger(toNumber(value)), value >= MIN_BRUSH_SIZE)
                                        if (Number.isInteger(value) && value >= MIN_BRUSH_SIZE && value <= MAX_BRUSH_SIZE) {
                                            setBrushSize(value);
                                        }
                                    }}
                                    min={MIN_BRUSH_SIZE}
                                    max={MAX_BRUSH_SIZE}
                                />
                                <div className={"vstack"} style={{gap: "4px"}}>
                                    <button
                                        type="button"
                                        className="coco-brush-tools-size-control plus"
                                        onClick={() => {
                                            const value = brushSize+1;
                                            if (Number.isInteger(value) && value >= MIN_BRUSH_SIZE && value <= MAX_BRUSH_SIZE) {
                                                setBrushSize(value);
                                            }
                                        }}
                                    >
                                        +
                                    </button>
                                    <button
                                        type="button"
                                        className="coco-brush-tools-size-control minus"
                                        onClick={() => {
                                            const value = brushSize-1;
                                            if (Number.isInteger(value) && value >= MIN_BRUSH_SIZE && value <= MAX_BRUSH_SIZE) {
                                                setBrushSize(value);
                                            }
                                        }}
                                    >
                                        -
                                    </button>
                                </div>
                            </div>
                        // <CVATTooltip title='Brush size [Hold Alt + Right Mouse Click + Drag Left/Right]'>
                        //     <InputNumber
                        //         className='coco-brush-tools-brush-size'
                        //         value={brushSize}
                        //         min={MIN_BRUSH_SIZE}
                        //         formatter={(val: number | undefined) => {
                        //             if (val) return `${val}px`;
                        //             return '';
                        //         }}
                        //         parser={(val: string | undefined): number => {
                        //             if (val) return +val.replace('px', '');
                        //             return 0;
                        //         }}
                        //         onChange={(value: number) => {
                        //             if (Number.isInteger(value) && value >= MIN_BRUSH_SIZE) {
                        //                 setBrushSize(value);
                        //             }
                        //         }}
                        //     />
                        // </CVATTooltip>
                    ) : null}
                    {/*{ ['brush', 'eraser'].includes(currentTool) ? (*/}
                    {/*    <Select value={brushForm} onChange={(value: 'circle' | 'square') => setBrushForm(value)}>*/}
                    {/*        <Select.Option value='circle'>Circle</Select.Option>*/}
                    {/*        <Select.Option value='square'>Square</Select.Option>*/}
                    {/*    </Select>*/}
                    {/*) : null}*/}
                    {/*<Button*/}
                    {/*    type='text'*/}
                    {/*    className={['coco-brush-tools-underlying-pixels', ...(removeUnderlyingPixels ? ['coco-brush-tools-active-tool'] : [])].join(' ')}*/}
                    {/*    icon={<VerticalAlignBottomOutlined />}*/}
                    {/*    onClick={() => setRemoveUnderlyingPixels(!removeUnderlyingPixels)}*/}
                    {/*/>*/}
                    { !editableState && !!applicableLabels.length && (
                        <LabelSelector
                            labels={applicableLabels}
                            value={defaultLabelID}
                            onChange={({ id: labelID }: { id: number }) => {
                                if (Number.isInteger(labelID)) {
                                    dispatch(
                                        rememberObject({ activeLabelID: labelID }),
                                    );
                                }
                            }}
                        />
                    )}
                    {/*{ dragBar }*/}
                </div>
            </Draggable>
        </>
    ), window.document.body);
}

export default React.memo(BrushTools);
