import {
  SET_IS_MODULE_OFFCANVAS,
  SET_IS_DATASET_OFFCANVAS,
  SET_IS_LABEL_OFFCANVAS,
  SET_IS_REVIEW_OFFCANVAS,
  SET_SELECTION_NODE_ID,
  SET_SELECTION_NODE_NM,
  SET_SELECTION_NODE,
  RESET_SELECTION_NODE,
  DELETE_NODE_ID,
  UPDATE_NODE_ID,
  SET_DATASET_DATA,
  SET_LABEL_DATA,
  SET_REVIEW_DATA
} from './actionType';

const INIT_STATE = {
  isModuleOffCanvas: false,
  isDatasetOffCanvas: false,
  isLabelOffCanvas: false,
  isReviewOffCanvas: false,
  selectionNodeId: null,
  selectionNodeNm: null,
  selectionNode: null,
  deleteNodeId: null,
  updateModuleId: null
};

const ManageWorkflowModule = (state = INIT_STATE, action) => {
  switch (action.type) {
      case SET_IS_MODULE_OFFCANVAS:
        return {
          ...state,
          isModuleOffCanvas: action.payload,
        };
     case SET_IS_DATASET_OFFCANVAS :
       return {
         ...state,
         isDatasetOffCanvas: action.payload,
       };
     case SET_IS_LABEL_OFFCANVAS :
       return {
         ...state,
         isLabelOffCanvas: action.payload,
       };

    case SET_IS_REVIEW_OFFCANVAS :
      return {
        ...state,
        isReviewOffCanvas: action.payload,
      };

    case SET_SELECTION_NODE_ID :
      return {
        ...state,
        selectionNodeId: action.payload,
      };

    case SET_SELECTION_NODE_NM :
      return {
        ...state,
        selectionNodeNm: action.payload,
      };

    case SET_SELECTION_NODE:
      return{
        ...state,
        selectionNodeId: action.payload.id,
        selectionNode: action.payload,
      };
    case RESET_SELECTION_NODE:
      return{
        ...state,
        selectionNodeId: null,
        selectionNode: null,
      };
    case DELETE_NODE_ID:
      return{
        ...state,
        deleteNodeId: action.payload
      }
    case UPDATE_NODE_ID:
      return{
        ...state,
        updateModuleId: action.payload
      }
    default:
      return state;
  }
};

export default ManageWorkflowModule;
