// @ts-nocheck
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router";
import {useIntl} from "react-intl";
import {checkValidation, submitAnnotations as submitLabel} from "../../../../../../store/label/action";
import {confirm} from "../../../../../../util/AlertUtil";
import {checkValidation as reviewCheckValidation} from "../../../../../../store/review/action";
import {GetMessageIntl} from "../../../../../../util/message";
import {useEffect, useState} from "react";
import {MD_TY_CD} from "../../../annotation/image/const";
import {useFormContext} from "react-hook-form";

interface Props {
    widthPercent: number;
    text: string;
    type: string;
    onClick(): void;
}
export const SubmitButton = (props: Props): JSX.Element => {
    const {widthPercent, text, type, onClick} = props;

    return (
        <>
            <button type={type}
                    className ={`btn btn-coco-1 waves-effect waves-light submit wd-${widthPercent}p `}
                    onClick={onClick}
            >
                {text}
            </button>
        </>
    )
}
