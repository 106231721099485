// @flow
import { all, call, put, fork, takeEvery } from "redux-saga/effects";

import {
    SET_MANAGE_WORKFLOW_REVIEW_BASE_CONFIG
    , SET_MANAGE_WORKFLOW_REVIEW_REVIEW_CONFIG
    , SET_MANAGE_WORKFLOW_REVIEW_WORKER_CONFIG
    , SET_MANAGE_WORKFLOW_REVIEW_POINT_CONFIG
    , SET_MANAGE_WORKFLOW_REVIEW_DATA_CONFIG
} from './actionType';
import {getRootStore} from "../../../../index";

let store = null;
function getStore(){
    if (store === null) {
// store = getCVATStore();
        store = getRootStore();
    }
    return store;
}

function* ManageWorkflowReviewSaga() {
    yield all([
    ]);
}

export default ManageWorkflowReviewSaga;