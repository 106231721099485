import React, { useState, useRef, useEffect, useCallback } from "react";
import {useDispatch, useSelector} from "react-redux";
import Select, {components} from "react-select";
import {CirclePicker, GithubPicker, SketchPicker} from "react-color";
import {Popover , OverlayTrigger} from "react-bootstrap";
import {noBorderBackgroundSelectStyle, noBorderSelectStyle} from "../../../../../../../components/constants/select";
import {
    setManageWorkflowLabelClassItems,
    setSelectedManageWorkflowLabelClassId
} from "../../../../../../../store/manage/project/workflow/label/action";
import {ListGroupItem, PopoverBody, UncontrolledPopover, UncontrolledTooltip} from "reactstrap";
import {Controller, useFieldArray, useFormContext} from "react-hook-form";
import {GetMessage, GetMessageIntl} from "../../../../../../../util/message";
import {useIntl} from "react-intl";
import {alertInfo} from "../../../../../../../util/AlertUtil";

const { Option } = components;

const colorPickerColors = ["#FF5A5A", "#FF8267", "#FFBF5F", "#FEF161", "#C5EF63", "#8CF280", "#62DDF2", "#6599FF", "#6563FF", "#A366FF"
, "#FF2F82", "#FD5D00", "#FFD705", "#FFF85C", "#B3FF00", "#00FF9F", "#00DAFF", "#0056FF", "#0300FF", "#6600FF", "#FFFFFF"];

/*{ controlLabel, registerLabel, errorsLabel }*/
function LabelShapeRows() {
    const dispatch = useDispatch();
    const intl = useIntl();
    const labelShapeOption = [
        {label : GetMessageIntl(intl,'workflow.shapeConfig.rectangle'),       value : 'rectangle',        icon : 'coco-20-bbox'}
        ,{label : GetMessageIntl(intl,'workflow.shapeConfig.polygon'),    value : 'polygon',     icon : 'coco-20-polygon'}
        /*,{label : GetMessageIntl(intl,'workflow.shapeConfig.line'),       value : 'line',        icon : 'coco-20-line'}*/
        ,{label : GetMessageIntl(intl,'workflow.shapeConfig.polyline'),   value : 'polyline',    icon : 'coco-20-polyline'}
        /*,{label : GetMessageIntl(intl,'workflow.shapeConfig.circle'),     value : 'circle',      icon : 'coco-20-circle'}*/
        ,{label : GetMessageIntl(intl,'workflow.shapeConfig.ellipse'),    value : 'ellipse',     icon : 'coco-20-ellipse'}
        /*,{label : GetMessageIntl(intl,'workflow.shapeConfig.polyEllipse'),value : 'polyEllipse', icon : 'coco-20-poly-ellipse'}*/
        ,{label : GetMessageIntl(intl,'workflow.shapeConfig.points'),     value : 'points',      icon : 'coco-20-point'}
        ,{label : GetMessageIntl(intl,'workflow.shapeConfig.cuboid'),     value : 'cuboid',      icon : 'coco-20-cuboid2d'}
        /*,{label : GetMessageIntl(intl,'workflow.shapeConfig.brush'),      value : 'brush',      icon : 'coco-20-brush'}*/];
    const { control, setValue, register, unregister, getValues, reset, clearErrors, formState: { errors },} = useFormContext();
    const {selectedLabelClassId,
        selectedLabelAttrId,
        selectedLabelAttrItemId,
        shapeConfig,
        labelClassItems,
        labelAttrItems,} = useSelector((state) => ({
        selectedLabelClassId: state.ManageWorkflowLabel.selectedLabelClassId,
        selectedLabelAttrId: state.ManageWorkflowLabel.selectedLabelAttrId,
        selectedLabelAttrItemId: state.ManageWorkflowLabel.selectedLabelAttrItemId,
        shapeConfig: state.ManageWorkflowLabel.shapeConfig,
        labelClassItems: state.ManageWorkflowLabel.labelClassItems,
        labelAttrItems: state.ManageWorkflowLabel.labelAttrItems,
    }));

    useEffect(()=>{
        // select2의 값이 변경되지않아서 setValue를 해줘야된다.
        labelClassItems.forEach((item)=>{
            setValue(`classItem.${item.id}.name`, item.name);
            setValue(`classItem.${item.id}.type`, item.type);
        })
    },[labelClassItems])

    const changeLabelClassItemEvent = (id, event)=>{
        const { name, value } = event.target;
        console.log('event.target', event.target);
        setValue(name, value);
        clearErrors(name);
        const getClassItemName = name.substring(name.lastIndexOf('.')+1);
        dispatch(setManageWorkflowLabelClassItems(
            labelClassItems.map((labelClassItem) => labelClassItem.id === id? {...labelClassItem, [getClassItemName]:value}: labelClassItem)
        ));
    }

    function changeDenpendentEvent(id, obj) {
        setValue(`classItem.${id}.type`, obj.value);
        dispatch(setManageWorkflowLabelClassItems(
            labelClassItems.map((labelClassItem) => labelClassItem.id === id? {...labelClassItem, 'type':obj.value}: labelClassItem)
        ));
    }

    function selectColorPickerEvent(id, obj, asisColor) {

        console.log('selectColorPickerEvent', obj.hex);

        if(asisColor.toUpperCase() != obj.hex.toUpperCase()){
            // 이전 값과 선택 값이 같지 않을 경우에는 다른 클래스에서 선택한 색상을 사용하는지 확인 필요
            let usedColor = labelClassItems.map((item) => item.color.toUpperCase());
            if(usedColor.includes(obj.hex.toUpperCase())){
                alertInfo("workflow.shapeConfig.alertDupColorTitle", "workflow.shapeConfig.alertDupColorMsg", ()=>{

                }, null, intl)
                return;
            }
        }

        setValue(`classItem.${id}.color`, obj.hex);
        dispatch(setManageWorkflowLabelClassItems(
            labelClassItems.map((labelClassItem) => labelClassItem.id === id? {...labelClassItem, 'color':obj.hex}: labelClassItem)
        ))
    }

    const setSelectedLabelClassId = (id)=>{
        dispatch(setSelectedManageWorkflowLabelClassId(id));
    }

    // react-select Custom Select Option dropdown
    const CustomSelectOption = (props) => (
        <Option {...props}>
            <span className="d-flex align-items-center">
                <i className={`i-coco-20 bg-coco-gray-700 me-2 ${props.data.icon}`}></i>
                {props.data.label}
            </span>
        </Option>
    )

    // react-select Custom Select Option result
    const CustomSelectValue = (props) => (
        <i className={`d-inline-flex i-coco-20 bg-coco-gray-700 ${props.data.icon}`}></i>
    )
    return(
        labelClassItems && labelClassItems.map((data, index)=>{
            const {id, name, color, type}= data;
            let classPropertyCnt = data.attributes.length;

            return(
                <ListGroupItem key={index} className={"d-flex align-items-center list-group-item-action border-0 pd-l-0 pd-r-24 tx-16 fw-normal ".concat(selectedLabelClassId === id ? "selected" : "").concat(errors.classItem&&errors.classItem[id]&&errors.classItem[id].attributes?" validation-error-sub":"")} id={`projectClassItem${id}`}>
                    <div className="pd-l-24 projectClassNo wd-44px mr-8" onClick={() => setSelectedLabelClassId(id)} >
                        {/*{...registerLabel(`classItem.${id}.id`, { required: true })}*/}
                        {index+1}
                        <input type="hidden" name="id" value={id} {...register(`classItem.${id}.id`)} />
                    </div>
                    <div className="mr-8">
                        <div className={errors.classItem&&errors.classItem[id]&&errors.classItem[id].name?"validation-error-bottom":""}>
                            <input type="text" className="form-control bgless"
                                   defaultValue={name}
                                   {...register(`classItem.${id}.name`, {
                                       validate: {
                                           required: value => {
                                               if(!value) return GetMessageIntl(intl,"validation.required");
                                               const classItems = getValues("classItem");
                                               if(classItems !=undefined){
                                                   let identicalLength =classItems.filter((item)=> item.name === value).length;
                                                   if (identicalLength>1) return GetMessageIntl(intl, 'validation.name');
                                               }
                                           }}
                                       })}
                                   data-parsley-notequalto-message="" onChange={(evnt)=>(changeLabelClassItemEvent(id, evnt))}/>
                        </div>
                        {errors.classItem&&errors.classItem[id]&&errors.classItem[id].name && <p className="validation-error-message mb-0">{errors.classItem[id].name.message}</p>}
                    </div>
                    <div className="mr-8 wd-65px">
                        {/*<Select value={dependentShape} options={labelShapeOption} className="form-control bg-trans border-0 select2-label-shape classDependentShape"*/}
                        {/*        name="dependentShape" onChange={(value)=>changeDenpendentEvent(id,value)}/>*/}
                        <Controller
                            name={`classItem.${id}.type`}
                            rules={{
                                required: true,
                            }}
                            defaultValue={type}
                            render={({ field: { onChange, value, ref, name } }) => (
                                <Select
                                    options={labelShapeOption}
                                    styles={noBorderBackgroundSelectStyle}
                                    components={{ Option: CustomSelectOption, SingleValue: CustomSelectValue }}
                                    onChange={(value)=>changeDenpendentEvent(id,value)}
                                    classNamePrefix="shape"
                                    value={value?labelShapeOption.find((c) => c.value === value):null}
                                    isSearchable={false}
                                    menuPortalTarget={document.body}
                                    menuPosition={'fixed'}
                                    menuPlacement={'auto'}
                                />
                                )}
                            />
                        <div className="errClassDependentShape" onClick={() => setSelectedLabelClassId(id)} ></div>
                    </div>
                    <div className="mr-8">
                        <div>
                            <div id={`colorPicker-${id}`} className="rounded-circle avatar-xxs" style={color.toUpperCase()==='#FFFFFF'?{backgroundColor:`${color}`,border: "1px solid var(--coco-gray-100)"}:{backgroundColor:`${color}`}}></div>
                            <UncontrolledPopover
                                trigger="legacy"
                                placement="bottom"
                                target={`colorPicker-${id}`}
                            >
                                <PopoverBody>
                                    <CirclePicker color={color} colors={colorPickerColors} onChange={ (event)=> selectColorPickerEvent(id, event, color) } className={color.toUpperCase()==='#FFFFFF'?"white-selected":""}/>
                                </PopoverBody>
                            </UncontrolledPopover>
                        </div>
                        <input type={"hidden"} {...register(`classItem.${id}.color`)} defaultValue={color}/>
                    </div>
                    <div className="text-right classPropertyCnt text-pointer wd-22px">
                        <span id={`classItemAttrCnt${id}`}>{classPropertyCnt}</span>
                        <UncontrolledTooltip placement="bottom" target={`classItemAttrCnt${id}`} innerClassName={"text-start"}>
                            {GetMessageIntl(intl, "workflow.shapeConfig.attrCnt")}
                        </UncontrolledTooltip>
                    </div>
                </ListGroupItem>
            )
        })
    )
}
export default LabelShapeRows;