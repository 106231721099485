import React, {useEffect, useState} from "react";
import {Redirect, useHistory} from "react-router-dom";
import {GetMenuInfo} from "../../routes/allRoutes";

const Navdata = () => {
    const history = useHistory();
    // 로그인한 사용자 권한 조회
    const coconutUserInfoJson = sessionStorage.getItem('coconutUserInfo') ?? '{}'
    const coconutUserInfo = JSON.parse(coconutUserInfoJson)
    const userRole = coconutUserInfo.userRole;

    let defaultState = '';
    if (userRole.startsWith('00')) {
        defaultState = 'SAST0000';
    } else if (userRole.startsWith('01')) {
        defaultState = 'MFUR0000';
    } else if (userRole.startsWith('1')) {
        defaultState = 'MDAT0000';
    } else if (userRole.startsWith('2')) {
        defaultState = 'LPJB0000';
    }

    const [iscurrentState, setIscurrentState] = useState(defaultState);
    //
    //
    // 시스템 관리자용 메뉴
    const [isSAST0000, setIsSAST0000] = useState(false);
    const [isSAST0001, setIsSAST0001] = useState(false);
    const [isSAST0002, setIsSAST0002] = useState(false);

    // 플랫폼 관리자용 메뉴
    const [isMFUR0000, setIsMFUR0000] = useState(false); // 회원
    const [isMFBD0000, setIsMFBD0000] = useState(false); // 게시판 관리
    const [isMFBD0001, setIsMFBD0001] = useState(false); // 게시판 관리
    const [isMFBD0002, setIsMFBD0002] = useState(false); // 게시판 관리
    const [isMFBD0003, setIsMFBD0003] = useState(false); // 게시판 관리


    // 프로젝트 관리자용 메뉴
    const [isMPJT0000, setIsMPJT0000] = useState(false); // 프로젝트

    const [isMPJT0001, setIsMPJT0001] = useState(false); // 프로젝트- 대시보드
    const [isMPJT0002, setIsMPJT0002] = useState(false); // 프로젝트 - 워크플로우
    const [isMPJT0003, setIsMPJT0003] = useState(false); // 프로젝트 - 데이터
    const [isMPJT0004, setIsMPJT0004] = useState(false); // 프로젝트 - 작업자
    const [isMPJT0005, setIsMPJT0005] = useState(false); // 프로젝트 - 설정


    const [isMGMR0000, setIsMGMR0000] = useState(false); // 매니저 관리
    const [isMBRD0000, setIsMBRD0000] = useState(false); // 게시판 관리
    const [isMGUR0000, setIsMGUR0000] = useState(false); // 회원 관리


    // 라벨러용
    const [isLPJB0000, setIsLPJB0000] = useState(false);
    const [isLJIP0000, setIsLJIP0000] = useState(false);
    const [isLTTR0000, setIsLTTR0000] = useState(false);
    const [isLBRD0000, setIsLBRD0000] = useState(false);
    const [isLBRD0001, setIsLBRD0001] = useState(false);
    const [isLBRD0002, setIsLBRD0002] = useState(false);
    const [isLBRD0003, setIsLBRD0003] = useState(false);

    const initToogleIconMenuBtn = () => {
        document.querySelector(".twocolumn-menu-hamburger i").classList.add('ri-arrow-right-s-line');
        document.querySelector(".twocolumn-menu-hamburger i").classList.remove('ri-arrow-left-s-line');
        // if(document.body.classList.contains('twocolumn-panel')){
        //     document.querySelector(".twocolumn-menu-hamburger i").classList.add('ri-arrow-right-s-line');
        //     document.querySelector(".twocolumn-menu-hamburger i").classList.remove('ri-arrow-left-s-line');
        // }else{
        //     document.querySelector(".twocolumn-menu-hamburger i").classList.remove('ri-arrow-right-s-line');
        //     document.querySelector(".twocolumn-menu-hamburger i").classList.add('ri-arrow-left-s-line');
        // }
        // document.body.classList.contains('twocolumn-panel') ? document.body.classList.add('twocolumn-panel') : document.body.classList.remove('twocolumn-panel');
    };


    useEffect(() => {
        document.body.classList.remove('twocolumn-panel');
        if (userRole === '00') {
            if (iscurrentState !== 'SAST0000') {
                setIsSAST0000(false);
            }
            // if (iscurrentState !== 'SAST0001') {
            //     setIisMFUR0000(false);
            // }
            // if (iscurrentState !== 'SAST0002') {
            //     setIsSAST0002(false);
            // }
        } else if (userRole === '01') {
            if (iscurrentState !== 'MFUR0000') {
                setIsMFUR0000(false);
            }
            if (iscurrentState !== 'MFBD0000') {
                setIsMFBD0000(false);
            }
            if (iscurrentState !== 'MFBD0001') {
                setIsMFBD0001(false);
            }
            if (iscurrentState !== 'MFBD0002') {
                setIsMFBD0002(false);
            }
            if (iscurrentState !== 'MFBD0003') {
                setIsMFBD0003(false);
            }
        } else if (userRole === '10') {

            if (iscurrentState !== 'MPJT0000') {
                setIsMPJT0000(false);
            }

            if (iscurrentState !== 'MPJT0001') {
                setIsMPJT0001(false);
            }

            if (iscurrentState !== 'MPJT0002') {
                setIsMPJT0002(false);
            }
            if (iscurrentState !== 'MPJT0003') {
                setIsMPJT0003(false);
            }

            if (iscurrentState !== 'MPJT0004') {
                setIsMPJT0004(false);
            }

            if (iscurrentState !== 'MPJT0005') {
                setIsMPJT0005(false);
            }

            if (iscurrentState !== 'MGMR0000') {
                setIsMGMR0000(false);
            }
            if (iscurrentState !== 'MBRD0000') {
                setIsMBRD0000(false);
            }
            if (iscurrentState !== 'MGUR0000') {
                setIsMGUR0000(false);
            }

        } else if (userRole === '20') {
            if (iscurrentState !== 'LPJB0000') {
                setIsLPJB0000(false);
            }
            if (iscurrentState !== 'LJIP0000') {
                setIsLJIP0000(false);
            }
            if (iscurrentState !== 'LTTR0000') {
                setIsLTTR0000(false);
            }

            if (iscurrentState !== 'LBRD0000') {
                setIsLBRD0000(false);
            }
            if (iscurrentState !== 'LBRD0001') {
                setIsLBRD0001(false);
            }
            if (iscurrentState !== 'LBRD0002') {
                setIsLBRD0002(false);
            }
            if (iscurrentState !== 'LBRD0003') {
                setIsLBRD0003(false);
            }
        }
    }, [
        history,
        iscurrentState,
        isMFUR0000,
        isMFBD0000,
        isMFBD0001,
        isMFBD0002,
        isMFBD0003,
        isSAST0000,
        isSAST0001,
        isSAST0002,
        isMPJT0000,
        isMPJT0001,
        isMPJT0002,
        isMPJT0003,
        isMPJT0004,
        isMPJT0005,
        isMGMR0000,
        isMBRD0000,
        isMGUR0000
    ]);


    function updateIconSidebar(e) {
        if (e && e.target && e.target.getAttribute("subitems")) {
            const ul = document.getElementById("two-column-menu");
            const iconItems = ul.querySelectorAll(".nav-icon.active");
            let activeIconItems = [...iconItems];
            activeIconItems.forEach((item) => {
                item.classList.remove("active");
                var id = item.getAttribute("subitems");
                if (document.getElementById(id))
                    document.getElementById(id).classList.remove("show");
            });
        }
    }

    if (userRole.startsWith('00')) {
        // 관리자 권한
        const menuItems = [
            {
                label: "Menu",
                isHeader: true,
            }, {
                id: "SAST0000",
                label: GetMenuInfo("SAST0000").name,
                icon: GetMenuInfo("SAST0000").icon,
                link: GetMenuInfo("SAST0000").url,
                stateVariables: isSAST0000,
                click: function (e) {
                    e.preventDefault();
                    setIsSAST0000(!isSAST0000);
                    setIscurrentState('SAST0000');
                    updateIconSidebar(e);
                },
                // subItems: [
                //     {
                //         id: "SAST0001",
                //         label: GetMenuInfo("SAST0001").name,
                //         link: GetMenuInfo("SAST0001").url,
                //         parentId: "SAST0000",
                //     },
                //     {
                //         id: "SAST0002",
                //         label: GetMenuInfo("SAST0002").name,
                //         link: GetMenuInfo("SAST0002").url,
                //         parentId: "SAST0000",
                //     },
                //     {
                //         id: "SAST0003",
                //         label: GetMenuInfo("SAST0003").name,
                //         link: GetMenuInfo("SAST0003").url,
                //         parentId: "SAST0000",
                //     }
                // ]
            }
        ];
        return <React.Fragment>{menuItems}</React.Fragment>;
    } else if (userRole.startsWith('01')) {

        const menuItems = [
            {
                label: "Menu",
                isHeader: true,
            }, {
                // 회원관리
                id: "MFUR0000",
                label: GetMenuInfo("MFUR0000").name,
                icon: GetMenuInfo("MFUR0000").icon,
                link: GetMenuInfo("MFUR0000").url,
                stateVariables: isMFUR0000,
                click: function (e) {
                    e.preventDefault();
                    setIsMGUR0000(!isMFUR0000);
                    setIscurrentState('MFUR0000');
                    updateIconSidebar(e);
                }
            }, {
                // 게시판 관리
                id: "MFBD0000",
                label: GetMenuInfo("MFBD0000").name,
                icon: GetMenuInfo("MFBD0000").icon,
                link: GetMenuInfo("MFBD0000").url,
                stateVariables: isMFBD0000,
                click: function (e) {
                    e.preventDefault();
                    setIsMPJT0000(!isMFBD0000);
                    setIscurrentState('MFBD0000');
                    updateIconSidebar(e);
                },
                subItems: [
                    {
                        id: "MFBD0001",
                        label: GetMenuInfo("MFBD0001").name,
                        icon: GetMenuInfo("MFBD0001").icon,
                        link: GetMenuInfo("MFBD0001").url,
                        parentId: "MFBD0000",
                        parentLink: GetMenuInfo("MFBD0001").url,
                        click: function (e) {
                            initToogleIconMenuBtn();
                        }
                    },
                    {
                        id: "MFBD0002",
                        label: GetMenuInfo("MFBD0002").name,
                        icon: GetMenuInfo("MFBD0002").icon,
                        link: GetMenuInfo("MFBD0002").url,
                        parentId: "MFBD0000",
                        parentLink: GetMenuInfo("MFBD0002").url,
                        click: function (e) {
                            initToogleIconMenuBtn();
                        }
                    },
                    {
                        id: "MFBD0003",
                        label: GetMenuInfo("MFBD0003").name,
                        icon: GetMenuInfo("MFBD0003").icon,
                        link: GetMenuInfo("MFBD0003").url,
                        parentId: "MFBD0000",
                        parentLink: GetMenuInfo("MFBD0003").url,
                        click: function (e) {
                            initToogleIconMenuBtn();
                        }
                    }
                ]
            }];
        return <React.Fragment>{menuItems}</React.Fragment>;
    } else if (userRole.startsWith('1')) {
        // 매니저 권한
        const menuItems = [
            {
                label: "Menu",
                isHeader: true,
            }, {
                // 프로젝트 관리
                id: "MPJT0000",
                label: GetMenuInfo("MPJT0000").name,
                icon: GetMenuInfo("MPJT0000").icon,
                link: GetMenuInfo("MPJT0000").url,
                stateVariables: isMPJT0000,
                click: function (e) {
                    e.preventDefault();
                    setIsMPJT0000(!isMPJT0000);
                    setIscurrentState('MPJT0000');
                    updateIconSidebar(e);
                },
                subItems: [
                    {
                        id: "MPJT0001",
                        label: GetMenuInfo("MPJT0001").name,
                        icon: GetMenuInfo("MPJT0001").icon,
                        link: GetMenuInfo("MPJT0001").url,
                        parentId: "MPJT0000",
                        parentLink: GetMenuInfo("MPJT0000").url,
                        click: function (e) {
                            initToogleIconMenuBtn();
                        }
                    },
                    {
                        id: "MPJT0002",
                        label: GetMenuInfo("MPJT0002").name,
                        icon: GetMenuInfo("MPJT0002").icon,
                        link: GetMenuInfo("MPJT0002").url,
                        parentId: "MPJT0000",
                        parentLink: GetMenuInfo("MPJT0000").url,
                        click: function (e) {
                            initToogleIconMenuBtn();
                        }
                    },
                    {
                        id: "MPJT0003",
                        label: GetMenuInfo("MPJT0003").name,
                        icon: GetMenuInfo("MPJT0003").icon,
                        link: GetMenuInfo("MPJT0003").url,
                        parentId: "MPJT0000",
                        parentLink: GetMenuInfo("MPJT0000").url,
                        click: function (e) {
                            initToogleIconMenuBtn();
                        }
                    },
                    {
                        id: "MPJT0004",
                        label: GetMenuInfo("MPJT0004").name,
                        icon: GetMenuInfo("MPJT0004").icon,
                        link: GetMenuInfo("MPJT0004").url,
                        parentId: "MPJT0000",
                        parentLink: GetMenuInfo("MPJT0000").url,
                        click: function (e) {
                            initToogleIconMenuBtn();
                        }
                    },
                    {
                        id: "MPJT0005",
                        label: GetMenuInfo("MPJT0005").name,
                        icon: GetMenuInfo("MPJT0005").icon,
                        link: GetMenuInfo("MPJT0005").url,
                        parentId: "MPJT0000",
                        parentLink: GetMenuInfo("MPJT0000").url,
                        click: function (e) {
                            initToogleIconMenuBtn();
                        }
                    }
                ]
            }, {
                //  매니저 관리
                id: "MGMR0000",
                label: GetMenuInfo("MGMR0000").name,
                icon: GetMenuInfo("MGMR0000").icon,
                link: GetMenuInfo("MGMR0000").url,
                stateVariables: isMGMR0000,
                click: function (e) {
                    e.preventDefault();
                    setIsMGMR0000(!isMGMR0000);
                    setIscurrentState('MGMR0000');
                    updateIconSidebar(e);
                }
            }, {
                // 게시판 관리
                id: "MBRD0000",
                label: GetMenuInfo("MBRD0000").name,
                icon: GetMenuInfo("MBRD0000").icon,
                link: GetMenuInfo("MBRD0000").url,
                stateVariables: isMBRD0000,
                click: function (e) {
                    e.preventDefault();
                    setIsMPJT0000(!isMBRD0000);
                    setIscurrentState('MBRD0000');
                    updateIconSidebar(e);
                },
                subItems: [
                    {
                        id: "MBRD0001",
                        label: GetMenuInfo("MBRD0001").name,
                        icon: GetMenuInfo("MBRD0001").icon,
                        link: GetMenuInfo("MBRD0001").url,
                        parentId: "MBRD0000",
                        parentLink: GetMenuInfo("MBRD0001").url,
                        click: function (e) {
                            initToogleIconMenuBtn();
                        }
                    },
                    {
                        id: "MBRD0002",
                        label: GetMenuInfo("MBRD0002").name,
                        icon: GetMenuInfo("MBRD0002").icon,
                        link: GetMenuInfo("MBRD0002").url,
                        parentId: "MBRD0000",
                        parentLink: GetMenuInfo("MBRD0002").url,
                        click: function (e) {
                            initToogleIconMenuBtn();
                        }
                    },
                    {
                        id: "MBRD0003",
                        label: GetMenuInfo("MBRD0003").name,
                        icon: GetMenuInfo("MBRD0003").icon,
                        link: GetMenuInfo("MBRD0003").url,
                        parentId: "MBRD0000",
                        parentLink: GetMenuInfo("MBRD0003").url,
                        click: function (e) {
                            initToogleIconMenuBtn();
                        }
                    }
                ]
            }
        ];
        return <React.Fragment>{menuItems}</React.Fragment>;
    } else if (userRole.startsWith('2')) {
        const menuItems = [
            {
                label: "Menu",
                isHeader: true,
            }, {
                //  태스크 공고
                id: "LPJB0000",
                label: GetMenuInfo("LPJB0000").name,
                icon: GetMenuInfo("LPJB0000").icon,
                link: GetMenuInfo("LPJB0000").url,
                stateVariables: isLPJB0000,
                click: function (e) {
                    e.preventDefault();
                    setIsLPJB0000(!isLPJB0000);
                    setIscurrentState('LPJB0000');
                    updateIconSidebar(e);
                }
            }, {
                // 참여중인 태스크
                id: "LJIP0000",
                label: GetMenuInfo("LJIP0000").name,
                icon: GetMenuInfo("LJIP0000").icon,
                link: GetMenuInfo("LJIP0000").url,
                stateVariables: isLJIP0000,
                click: function (e) {
                    e.preventDefault();
                    setIsLJIP0000(!isLJIP0000);
                    setIscurrentState('LJIP0000');
                    updateIconSidebar(e);
                }
            },
            // {
            //     // 튜토리얼
            //     id: "LTTR0000",
            //     label: GetMenuInfo("LTTR0000").name,
            //     icon: GetMenuInfo("LTTR0000").icon,
            //     link: GetMenuInfo("LTTR0000").url,
            //     stateVariables: isLTTR0000,
            //     click: function (e) {
            //         e.preventDefault();
            //         setIsLTTR0000(!isLTTR0000);
            //         setIscurrentState('LTTR0000');
            //         updateIconSidebar(e);
            //     }
            // },
            {
                // 게시판
                id: "LBRD0000",
                label: GetMenuInfo("LBRD0000").name,
                icon: GetMenuInfo("LBRD0000").icon,
                link: GetMenuInfo("LBRD0000").url,
                stateVariables: isLBRD0000,
                click: function (e) {
                    e.preventDefault();
                    setIsLBRD0000(!isLBRD0000);
                    setIscurrentState('LBRD0000');
                    updateIconSidebar(e);
                },
                subItems: [
                    {
                        id: "LBRD0001",
                        label: GetMenuInfo("LBRD0001").name,
                        icon: GetMenuInfo("LBRD0001").icon,
                        link: GetMenuInfo("LBRD0001").url,
                        parentId: "LBRD0000",
                        parentLink: GetMenuInfo("LBRD0001").url,
                        click: function (e) {
                            initToogleIconMenuBtn();
                        }
                    },
                    {
                        id: "LBRD0002",
                        label: GetMenuInfo("LBRD0002").name,
                        icon: GetMenuInfo("LBRD0002").icon,
                        link: GetMenuInfo("LBRD0002").url,
                        parentId: "LBRD0000",
                        parentLink: GetMenuInfo("LBRD0002").url,
                        click: function (e) {
                            initToogleIconMenuBtn();
                        }
                    },
                    {
                        id: "LBRD0003",
                        label: GetMenuInfo("LBRD0003").name,
                        icon: GetMenuInfo("LBRD0003").icon,
                        link: GetMenuInfo("LBRD0003").url,
                        parentId: "LBRD0000",
                        parentLink: GetMenuInfo("LBRD0003").url,
                        click: function (e) {
                            initToogleIconMenuBtn();
                        }
                    }
                ]
            }
        ];
        return <React.Fragment>{menuItems}</React.Fragment>;
    }
}
export default Navdata;