import React, { useEffect, useState } from 'react';
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import SettingHeader from './SettingHeader';


const SettingLayout = (props) => {
    // const headerBreadRegex = new RegExp('^/manager/project/.*', 'g');
    // const headerBreadYn = headerBreadRegex.test(props.location.pathname);

    return (
        <React.Fragment>
            <div id="layout-wrapper">
                <SettingHeader
                    headerBreadYn={true} />
                <div>
                    {props.children}
                    {/*<Footer />*/}
                </div>
            </div>
        </React.Fragment>

    );
};

SettingLayout.propTypes = {
    children: PropTypes.object,
};

export default withRouter(SettingLayout);
