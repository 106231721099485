
export const BoardUtil = {
    convertCnTyToLabel : (cnTy) => {
        switch (cnTy) {
            case "01" :
                cnTy = "태스크";
                break;
            case "02" :
                cnTy = "포인트";
                break;
            case "03" :
                cnTy = "플랫폼";
                break;
            case "04" :
                cnTy = "회원정보";
                break;
            default :
                cnTy = "";
                break;
        }
        return cnTy;
    },
}