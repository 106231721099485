import {callApi} from '../utils/AxiosUtil';

export const TaskService={
    getTaskList: (param) => callApi('/service/task/list', param),
    getJoinedTaskList: (param) => callApi('/service/task/joinlist', param),
    getJoinedTaskListByUserSeq: (param) => callApi('/service/task/joinedList', param),
    getTaskTest: (param) => callApi('/service/task/taskTest', param),
    getTaskByModuleId: (param) => callApi('/service/task/item', param),
    updateJoinConfig: (param) => callApi('/service/task/updateJoinData', param),
    getWrkflowWithTaskList: (param) => callApi('/service/task/getWrkflowWithTaskList', param),
}