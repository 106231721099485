export const CANVAS_ID = "svgContainer";
export const GUIDE_ID = "guideContainer";

export type ShapeName = "rect" | "polygon" | "line" | "polyline" | "circle" | "ellipse" |
    "polyEllipse" | "points" | "cuboid";
export const ShapeIcon = {
    "rectangle" : 'i-coco-20 coco-20-bbox',
    "polygon" : 'i-coco-20 coco-20-polygon',
    "polyline" :'i-coco-20 coco-20-polyline',
    "points" :'i-coco-20 coco-20-point',
    "cuboid" :'i-coco-20 coco-20-cuboid2d',
    "ellipse" :'i-coco-20 coco-20-ellipse',
    "line" : 'i-coco-20 coco-20-line',
    "circle" :'i-coco-20 coco-20-circle',
    "polyEllipse" :'i-coco-20 coco-20-poly-ellipse',
    "brush": 'i-coco-20 coco-20-brush',   // 'brush'
    "mask": 'i-coco-20 coco-20-brush'   // 'brush'
};
export const SHAPE_TYPE = {
    'RECT' : 'rect',
    'POLYGON' : 'polygon',
    'POLYLINE' : 'polyline',
    'POINT' : 'points',
    'CUBOID' : 'cuboid',
    'LINE' : 'line',
    'CIRCLE' : 'circle',
    'ELLIPSE' : 'ellipse',
    'POLY_ELLIPSE' : 'polyEllipse',
    'BRUSH' : 'brush'
};
export type SHAPE_TYPE = typeof SHAPE_TYPE[keyof typeof SHAPE_TYPE];

export const LOAD_MODE = {
    'EX_FILE_LABELING_LOAD' : 'exFileLabelingLoad', // "이전 파일"의 라벨링 결과 가져오기
    'EX_LABELING_LOAD' : 'exLabelingLoad',          // 동일한 파일의 "이전 라벨링 결과" 가져오기 (최초 캔버스가 아닌 경우)
}

// Module
export enum CANVAS_DATA_TY {
    IMAGE_ANNOTATION = "11",
    IMAGE_CLSR_SMRY = "12",
    AUDIO_CLSR_SMRY = "22",
    VIDEO_CLSR_SMRY = "32",
    TEXT_CLSR_SMRY = "42"
}
export enum DATA_TYPE {
    IMAGE = "1",
    AUDIO = "2",
    VIDEO = "3",
    TEXT = "4"
}

export enum LABEL_TYPE {
    ANNOTATION = "1",
    CLASSIFICATION_SUMMARY = "2",
}

export enum MD_TY_CD {
    LABEL = "01",
    REVIEW = "02"
}

export enum MD_STTUS_CD {
    FIRST ,
    RE
}

export const REVIEW_TASK_STTUS_CD = {
    "PASS": "03",
    "REJECT": "04",
} as const;
export type REVIEW_TASK_STTUS_CD = typeof REVIEW_TASK_STTUS_CD[keyof typeof REVIEW_TASK_STTUS_CD];
