import {MD_STTUS_CD, MD_TY_CD} from "../../../../annotation/image/const";
import React, {useEffect, useState} from "react";
import {QuestionView} from "./QuestionView";
import {ReviewView} from "./ReviewView";
import ReviewResultView from "./ReviewResultView";
import {GetMessageIntl} from "../../../../../../../util/message";
import {useIntl} from "react-intl";

interface TaskContentsProps {
    item: any;
    moduleType: MD_TY_CD;
    status: MD_STTUS_CD;
    shapes: any;
    reviews?: any;
    modifiedList: boolean[];
    clsfcSmryForm;
}
export const TaskContents = (props: TaskContentsProps): JSX.Element => {
    const intl = useIntl();
    const {item, moduleType, status, shapes, clsfcSmryForm, modifiedList } = props;
    const {setValue} = clsfcSmryForm;
    const [shape, setShape] = useState(shapes.find(shape => shape.id == item.id));

    useEffect(() => {
        if (shapes.length && item) {
            setShape(shapes.find(shape => shape.id == item.id));
        }
    }, [shapes, item])

    useEffect(() => {
        if(shape?.answer[0]){
            setValue(`shapes[${item.id}]`, shape?.answer[0]);
        }else{
            setValue(`shapes[${item.id}]`, '');
        }
    }, [shape])

    return(
        <>
            <div className="mb-12">
                {
                    // 작업 오류 메세지
                    clsfcSmryForm?.formState?.errors?.shapes && clsfcSmryForm?.formState?.errors?.shapes[item.id] ?
                        <div className="fs-12 fw-400 mb-3 text-coco-danger-400 d-flex align-items-center gap-1">
                            <i className="ri-error-warning-fill fs-16"></i>
                            {clsfcSmryForm?.formState?.errors?.shapes[item.id].message}</div>
                        :   // 수정 작업 오류 메시지
                        clsfcSmryForm?.formState?.errors?.modified && clsfcSmryForm?.formState?.errors?.modified[item.id] &&
						<div className="fs-12 fw-400 mb-3 text-coco-danger-400 d-flex align-items-center gap-1">
							<i className="ri-error-warning-fill fs-16"></i>
                            {clsfcSmryForm?.formState?.errors?.modified[item.id].message}</div>
                }
            </div>
            <div className={"mb-0"}>
                {
                    item.attributes.map((element, index) => {
                        const values = element.values;
                        const inputType = element.inputType;
                        const modified = modifiedList[element.id];
                        return (
                            <div key={`attributes-div-${item.id}-${index}`}>
                                {/*라벨링 작업일 경우*/}
                                {moduleType === MD_TY_CD.LABEL ?
                                    <>
                                        {status === MD_STTUS_CD.FIRST &&
                                            <>
                                                <div className="mb-12">
                                                    <span className="fs-16 text-coco-gray-700 fw-semibold">{item.question}
                                                        {
                                                            item.attributes[0].required === 1 &&
                                                                <span className="text-coco-danger-700">*</span>
                                                        }
                                                    </span>
                                                </div>
                                                <QuestionView placeholder={item.inputExam == ''? GetMessageIntl(intl, "common.enterInfo"): item.inputExam} rules={Boolean(element.required)?{required: GetMessageIntl(intl, 'label.summaryValidationError')}:{}} hookform={clsfcSmryForm} row={10} maxString={element.max} keyName={`shapes[${item.id}]`}/>
                                            </>
                                        }
                                        {(status === MD_STTUS_CD.RE && shape !== null) &&
											<>
                                                {/* 요약 수정 라벨링 */}
												<ReviewResultView
													shape={shape}
													review={props.reviews? props.reviews.find(review => review.id == item.id):null}
												/>
												<div className="mb-12">
													<span className="fs-16 text-coco-gray-700 fw-semibold">{item.question}
                                                        {
                                                            item.attributes[0].required === 1 &&
                                                                <span className="text-coco-danger-700">*</span>
                                                        }
                                                    </span>
												</div>
												<QuestionView placeholder={item.inputExam == ''? GetMessageIntl(intl, "common.enterInfo"): item.inputExam} rules={Boolean(element.required)?{required: GetMessageIntl(intl, 'label.summaryValidationError')}:{}}
															  shape={shape}
															  review={props.reviews? props.reviews.find(review => review.id == item.id):null}
															  modified={modified}
															  hookform={clsfcSmryForm} row={10} maxString={element.max} keyName={`shapes[${item.id}]`}/>
											</>
                                        }
                                    </>
                                    :<></>
                                }

                                {/*검수 작업일 경우*/}
                                {(moduleType === MD_TY_CD.REVIEW) &&
                                    <ReviewView shape={shape} label={item} hookform={clsfcSmryForm} review={props.reviews? props.reviews.find(review => review.id == item.id):null}/>}
                            </div>
                        )
                    })
                }
            </div>
        </>
    )
}
