import {
    SET_INIT_SEARCH_PARAM,
    SET_SEARCH_PARAM,
    SET_CANVAS_DATA_TY,
    SWITCH_MODAL_FLAG,
    SWITCH_FOLDERVIEW_FLAG,
    SET_CURRENT_PAGE,
    SET_DATASET_ID,
    SET_DATASET_OPTIONS,
    SET_WRKFLOW_OPTIONS,
    SET_FILE_OR_FOLDER_NM,
    SET_FOLDER_CURRENT_PAGE,
    SET_MOUNT_CNT, SET_WRKFLOW,
    SET_WRKFLOW_WITH_MODULES, GO_TO_DETAILS_BY_FOLDER
} from "./actionType"


export const initSearchParam = () => ({
    type : SET_INIT_SEARCH_PARAM
});

export const setSearchParam = searchObj => ({
    type : SET_SEARCH_PARAM,
    payload : searchObj
});

export const setCanvasDataTy = (canvasDataTy) => ({
    type : SET_CANVAS_DATA_TY,
    payload : canvasDataTy
});

export const switchModalFlag = () => ({
    type : SWITCH_MODAL_FLAG
})

export const switchFolderViewFlag = () => ({
    type : SWITCH_FOLDERVIEW_FLAG
})

export const setCurrentPage = (page) => ({
    type : SET_CURRENT_PAGE,
    payload : page
})

export const setDatasetId = (datasetId) => ({
    type : SET_DATASET_ID,
    payload : datasetId
})

export const setWrkflow = (wrkflowId, wrkflowNm) => ({
    type : SET_WRKFLOW,
    payload : {wrkflowId, wrkflowNm}
})

export const setWrkflowOptions = (options) => ({
    type: SET_WRKFLOW_OPTIONS,
    payload: options
})

export const setDatasetOptions = (options) => ({
    type: SET_DATASET_OPTIONS,
    payload: options
})

export const setFileOrFolderNm = (nm) => ({
    type: SET_FILE_OR_FOLDER_NM,
    payload: nm
})

export const setWrkflowWithModules = (wrkflowWithModules) => ({
    type: SET_WRKFLOW_WITH_MODULES,
    payload: wrkflowWithModules
})

export const setFolderCurrentPage = (currentPage) => ({
    type: SET_FOLDER_CURRENT_PAGE,
    payload: currentPage
})

export const dataset_setMountCnt = (mount) => ({
    type: SET_MOUNT_CNT,
    payload: mount
})

export const goToDetailsByFolder = (module, history, filePath) => ({
    type: GO_TO_DETAILS_BY_FOLDER,
    payload: {module, history, filePath}
})
