import {callApi} from '../utils/AxiosUtil';

/**
 *  비디오 라벨링 / 검수 관련 서비스
 */
export const VideoLabelService={
    // 라벨링 대상 비디오
    getTargetVideo: (param) => callApi('/service/label/video/getTargetVideo', param),
}

