import React, {useEffect, useRef, useState} from 'react';
import styled from 'styled-components';
import CommonUtil from "../../util/CommonUtil";
import {ButtonGroup, DropdownItem, DropdownMenu, DropdownToggle, Row, UncontrolledDropdown} from "reactstrap";
import {UserUtil} from "../../util/UserUtil";
import SimpleBar from "simplebar-react";
import {FilePond} from "react-filepond";
import {BoardService} from "../../apis/api/BoardService";
import {board_otoSwitchRefreshFlag, board_setModalMode, board_switchModalFlag} from "../../store/board/action";
import {useDispatch, useSelector} from "react-redux";
import {GetMessageIntl} from "../../util/message";
import {useIntl} from "react-intl";

const ReadBox = styled.div`
  
  background: ${props => {
      switch (props.userTy) {
          case '20':
              return '#DFF3EFB2'; // background for '작업자'
          default:
              return '#F3F6F9'; // default background
      }
  }};
  padding: 16px;
  border-radius: 7px;
`

function AskViewReplyRow(props) {
    const intl = useIntl();
    const dispatch  = useDispatch();
    const coconutUserInfo = JSON.parse(sessionStorage.getItem("coconutUserInfo"));
    const userRole = coconutUserInfo.userRole;
    const userNm = coconutUserInfo.userNm;
    const [updateFlag, setUpdateFlag] = useState(false);
    const {refreshFlag} = useSelector((state) => ({
        refreshFlag: state.Board.otoRefreshFlag
    }));

    const [cn, setCn] = useState("")

    const pondRef = useRef();
    const [files, setFiles] = useState([]);
    const [cpFiles, setCpFiles] = useState([]);
    const [totalFileSize, setTotalFileSize] = useState(0);
    const maxTotalSize = 50 * 1024 * 1024; // 50MB
    const [attachFilesUrl, setAttachFilesUrl] = useState([]);

    const [delFileSeq, setDelFileSeq] = useState([]);
    const [delFilePath, setDelFilePath] = useState([]);

    const addProcess = ()=>{
        const data = new FormData();
        data.append("cn", cn);
        data.append("boardSeq", 3);
        data.append("mode", "U");
        data.append("cnLvl", props.cnLvl);
        data.append("cnTitle", "");
        data.append("noticeYn", "N");
        data.append("cnSttusCd", "00");
        data.append("deletedFileSeq", delFileSeq.toString());
        data.append("deletedFilePath", delFilePath.toString());
        data.append("orginCnSeq", props.orginCnSeq);
        data.append("cnSeq", props.cnSeq);

        for (let i = 0; i < files.length; i++) {
            // append the blob file
            data.append('attachFile[]', files[i].file);
        }

        BoardService.setContent(data, {contentType : 'multipart/form-data'})
            .then((res) => {
                console.log(res.data)
                setUpdateFlag(false);
                dispatch(board_otoSwitchRefreshFlag())
            })
            .catch((err)=>{
                const data = new FormData();
            })
    }

    const deleteProcess = ()=>{
        const data = new FormData();
        data.append("boardSeq", 3);
        data.append("mode", "D");
        data.append("cnSeq", props.cnSeq);

        BoardService.setContent(data, {contentType : 'multipart/form-data'})
            .then((res) => {
                console.log(res.data)
                dispatch(board_otoSwitchRefreshFlag());
            })
            .catch((err)=>{
                const data = new FormData();
            })
            .finally(()=>{
                setUpdateFlag(false);
            })
    }

    function downloadFile(filePath, realFileName) {
        fetch(filePath, {method: 'GET'})
            .then((res) => {
                return res.blob() // raw 데이터를 받아온다
            })
            .then((blob) => {
                const url = window.URL.createObjectURL(blob) // 받아온 날 상태의 data를 현재 window에서만 사용하는 url로 바꾼다
                const a = document.createElement('a')
                a.href = url
                a.download = realFileName // 원하는 이름으로 파일명 지정
                document.body.appendChild(a)
                a.click() // 자동으로 눌러버리기
                setTimeout((_) => {
                    window.URL.revokeObjectURL(url) // 해당 url을 더 사용 못하게 날려버린다
                }, 60000)
                a.remove() // a를 다 사용했으니 지워준다
                return false;
            })
            .catch((err) => {
                console.error('err: ', err)
            })
    }

    const getAttachFilesAwsUrl = async (cnSeq) => {
        const res = await BoardService.getContentAttachFile({
            'cnSeq': cnSeq
        });
        if (res.data.data) {
            console.log("attachFiles => ", res.data.data)
            setAttachFilesUrl(res.data.data);
        }
    }

    useEffect(() => {
        setUpdateFlag(false)
    }, [])

    useEffect(()=>{
        getAttachFilesAwsUrl(props.cnSeq)
        setCn(props.cn);
    }, [refreshFlag])

    useEffect(()=>{
        let delIdx = [];
        let totalFileSize = 0;
        for (let i = cpFiles.length - 1; i >= 0; i--) {
            totalFileSize += cpFiles[i].file.size
            if(totalFileSize > maxTotalSize){
                delIdx.push(i);
            }
        }
        let tmpArr = [...cpFiles];
        let delArr = [];
        delIdx.map((idxs) => {
            let delTarget = tmpArr.shift();
            delArr.push(delTarget.file.name)
        })

        setFiles(tmpArr);
        setTotalFileSize(totalFileSize);
        if(delArr.length > 0){
            alert("업로드 안된 파일들\n" + delArr.join('\n'));
        }
    }, [cpFiles])

    function handleUpdateBtn() {
        setUpdateFlag(true)
    }

    function handleDeleteBtn(){
        setCn("");
        setFiles([]);
        setDelFileSeq([])
        setUpdateFlag(false)
        deleteProcess();
    }

    function handleRowDeleteBtn() {
        setUpdateFlag(false)
    }

    function handleRegisterButton() {
        addProcess();
    }

    function handleTextAreaChange(e){
        if (e.target.value.length <= 1000) {
            setCn(e.target.value);
        }
    }

    console.log(props.cn)

    return (
        <div className="mb-3">
            {
                updateFlag === false ?
                    <>
                        <div className="d-flex justify-content-between align-items-center mb-2 pe-2 ps-2">
                            <div>{UserUtil.strFromUserTy(props.userTy)} ({props.userNm})</div>
                            <div className="d-flex text-coco-gray-400 fs-16">
                                <div>{props.modDtm}</div>
                                {
                                    ((userRole === '11' || userRole === '01') && (props.userTy === '11' || props.userTy === '01') && props.userNm === userNm) ?
                                        <ButtonGroup className="align-self-center" style={{marginLeft: "5px"}}>
                                            <UncontrolledDropdown>
                                                <DropdownToggle tag="button" type="button" className="btn pd-0 d-flex">
                                                    <i className="ri-more-line text-pointer text-coco-gray-400 fs-20"></i>
                                                </DropdownToggle>
                                                <DropdownMenu>
                                                    <DropdownItem onClick={handleUpdateBtn}>{GetMessageIntl(intl, 'common.modify')}</DropdownItem>
                                                    <DropdownItem onClick={handleDeleteBtn}>{GetMessageIntl(intl, 'common.delete')}</DropdownItem>
                                                </DropdownMenu>
                                            </UncontrolledDropdown>
                                        </ButtonGroup>
                                        : null
                                }
                            </div>
                        </div>
                        <div>
                            <ReadBox userTy={props.userTy}>
                                <div className="fs-16 text-coco-gray-700 fw-400 mb-4">
                                    <div dangerouslySetInnerHTML={{__html: props.cn.replaceAll("\n", "<br/>")}}></div>
                                </div>
                                <div>
                                    {
                                        props.files ? props.files.map((file, idx) => {
                                            const {fileSize, realFileNm, filePath} = file
                                            return(
                                                <Row className="mb-2" key={idx}>
                                                    <div className={"d-flex align-items-center"}>
                                                        <i className="ri-download-2-line text-coco-gray-400" onClick={() => downloadFile(filePath, realFileNm)}></i>
                                                        <span className="text-coco-gray-700">&nbsp;{realFileNm}</span>
                                                        <span className="fs-16 fw-500 text-coco-gray-400">&nbsp;({CommonUtil.bytesToSize(fileSize)})</span>
                                                    </div>
                                                </Row>
                                            )
                                        }) : null
                                    }
                                </div>
                            </ReadBox>
                        </div>
                    </>
                    : // 분기점
                    <>
                        <div>
                            <div className="d-flex justify-content-between mb-4">
                                <div>
                                    {UserUtil.strFromUserTy(userRole)} ({userNm})
                                </div>
                                <div className="text-coco-gray-400">
                                    {`${cn.length}/1000자`}
                                </div>
                            </div>
                            <div className="mb-3">
                        <textarea
                            onChange={handleTextAreaChange}
                            value={cn}
                            placeholder="정보를 입력하세요."
                            className="form-control"
                            id=""
                            rows="7" />
                            </div>
                            <div>
                                <div className="d-flex justify-content-between text-coco-gray-400 mb-2">
                                    <div>
                                        {files.length}건의 파일
                                    </div>
                                    <div>
                                        {CommonUtil.bytesToSize(totalFileSize)} / 50MB
                                    </div>
                                </div>
                                <div className="askAddModalFilePondDiv">
                                    <SimpleBar style={{maxHeight: "170px"}} className="pe-2">
                                        <FilePond
                                            ref={pondRef}
                                            files={files}
                                            onupdatefiles={setCpFiles}
                                            server={{}}
                                            chunkUploads={true}
                                            allowMultiple={true}
                                            instantUpload={false}
                                            name="files"
                                            labelIdle={GetMessageIntl(intl, "workflow.dataConfig.filepondPlaceholder")}
                                            credits={false}
                                        />
                                    </SimpleBar>
                                </div>
                                <div>
                                    {
                                        attachFilesUrl.map((file, idx) => {
                                            const seperateUrl = file.split(",");
                                            let filePath = seperateUrl[0];
                                            let realFileName = seperateUrl[1];
                                            let fileSize = seperateUrl[2];
                                            let fileSeq = seperateUrl[3];
                                            let delStyle = undefined;
                                            if(delFileSeq.find(item => item === fileSeq)){
                                               delStyle = {textDecorationLine : "line-through"};
                                            }
                                            return(
                                                <Row className="mb-2" key={idx}>
                                                    <div className={"d-flex justify-content-between"}>
                                                        <div className={"d-flex gap-2"}>
                                                                                    <span className="text-coco-gray-400" onClick={(e)=>{
                                                                                        let fileSeqCopy = [...delFileSeq];
                                                                                        fileSeqCopy.push(fileSeq);
                                                                                        setDelFileSeq(fileSeqCopy);
                                                                                        let filePathCopy = [...delFilePath];
                                                                                        filePathCopy.push(filePath);
                                                                                        setDelFilePath(filePathCopy);
                                                                                    }}><i className="ri-close-circle-line"></i></span>
                                                            <span className="text-coco-gray-700" style={delStyle}>{realFileName}</span>
                                                            <span className="fs-16 fw-500 text-coco-gray-400">&nbsp;({CommonUtil.bytesToSize(fileSize)}</span>
                                                        </div>
                                                    </div>
                                                </Row>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                            <div className="d-flex justify-content-between">
                                <div>
                                </div>
                                <div className="wd-15p">
                                    <button type="button" className="btn btn-coco-ghost-2 w-50"
                                            onClick={handleRowDeleteBtn}>
                                        {GetMessageIntl(intl, 'common.cancel')}
                                    </button>
                                    <button type="button" className="btn btn-coco-2 w-50"
                                            onClick={handleRegisterButton}>
                                        {GetMessageIntl(intl, 'common.register')}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </>
            }

        </div>
    );
}

export default AskViewReplyRow;