import {useIntl} from "react-intl";
import {GetMessageIntl} from "../../../../../../../../util/message";
import React from "react";

interface Props {
    title: string;
    keyMap: any;
    normalizedKeyMap: any;
}

const OutlineBox = ({text, className}) => {
    return (
        <div className={"outline-box-white "+className}>
            {text}
        </div>
    )
}

export function ShortcutsSection(props: Props): JSX.Element {
    const intl = useIntl();
    const { title, keyMap, normalizedKeyMap } = props;

    if (Object.keys(keyMap).length === 0) return(<></>)
    return (
        <div className={"shortcuts-section"}>
            <span className={"title"}>{GetMessageIntl(intl, title)}</span>
            {
                Object.keys(keyMap).map((keyTitle, index) => {
                    const key = keyMap[keyTitle];
                    const shortcutsKey = normalizedKeyMap[keyTitle];
                    return (
                        <div className={"shortcuts-info"} key={`${title}-${index}`}>
                            <div>{GetMessageIntl(intl, key.code)}</div>
                            <div className={"shortcuts-info-keys"}>
                                {shortcutsKey.map((displayedKey: string, keyIndex: number) => {
                                    const className = ['Ctrl', 'Shift', 'Del', 'Esc', 'Wheel', 'Enter'].includes(displayedKey) ? '' : 'one-letter'
                                    return (
                                        <OutlineBox text={displayedKey} key={`${title}-${index}-${keyIndex}`} className={className}/>
                                    )
                                })}
                            </div>
                        </div>
                    )
                })
            }
        </div>
    )
}

