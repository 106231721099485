// @ts-nocheck
import './styles.scss';
import React from "react";
import {SearchHeader} from "./search-header";
import {DataList} from "./data-list";

function LeftSideBar(): JSX.Element {
    return(
        <div className="navbar-menu custom-b-0 data-list-bar">
            <SearchHeader />
            <hr />
            <DataList />
        </div>
    )
}

export default React.memo(LeftSideBar)
