// @ts-nocheck
import {ReviewResultView} from "../../../components/taskSideBar/classification/ReviewResultView";
import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {MD_TY_CD} from "../../../../annotation/image/const";
import {LabelReviewResultView} from "../../../components/taskSideBar/classification/LabelReviewResult";

interface Props {
    hookform: any;
    questionKeyName: string;
    reject: boolean | null;
}
export const ReviewResult = (props: Props): JSX.Element => {
    const {hookform, questionKeyName, reject} = props;
    const moduleType = useSelector(state => state.Label.Cmmn.module.type);

    return (
        <>
            {moduleType === MD_TY_CD.REVIEW
                ? <ReviewResultView hookform={hookform}
                               questionKeyName={questionKeyName}
                               reject={reject} />
                : <LabelReviewResultView hookform={hookform}
                                questionKeyName={questionKeyName}
                                reject={reject} />
            }
        </>
    );
}
