import axios, {AxiosError} from "axios";
import {LoginInfoModel} from "../../components/model/LoginInfoModel";
import {AxiosRequestConfig} from "axios";
import {alertError} from "../../util/AlertUtil";
import {useIntl} from "react-intl";
import Swal from "sweetalert2";
import {GetMessage, GetMessageIntl} from "../../util/message";

const axiosApi = () => {
    const instance = axios.create(
        {
            withCredentials: true
        });

    instance.interceptors.request.use(
        function (config) {
            console.debug('defaultAxios request', config)
            return config;
        },
        function (error) {
            console.debug('defaultAxios request error', error)
            return Promise.reject(error);
        }
    );
    instance.interceptors.response.use(
        function (response) {
            console.debug('defaultAxios response', response)
            return response;
        },
        function (error) {
            console.debug('defaultAxios response error', error)
            return Promise.reject(error);
        }
    );
    return instance;
}

const axiosAuthApi = () => {
    const instance = axios.create();
    instance.interceptors.request.use(
        async function (config) {
            console.debug('authAxios request', config)
            let regex = new RegExp('.*\\/service\\/.*', 'g');
            if (regex.test(config.url)) {
                // service인 경우 로그인한 경우에만 접근 가능함
                // accessToken 만료 여부를 확인하고
                // 만료된 경우 refreshToken을 이용하여 accessToken 재발급
                // refreshToken 까지 만료된 경우는 로그인 페이지로 이동함
                let sessionUserInfo = sessionStorage.getItem('coconutUserInfo') ?? '{}'
                const userInfo = JSON.parse(sessionUserInfo);
                let exp = userInfo.exp - 32400000;
                let now = new Date().getTime()
                // config.headers.set('us',userInfo.userSeq);
                if (exp < now) {
                    try {
                        // 토큰 만료
                        const reIssueResult = await defaultAxios.post(process.env.REACT_APP_API_HOST + '/service/reissue', {
                            // refreshToken: userInfo.refreshToken,
                            // accessToken: userInfo.accessToken
                        }, {
                            method: 'POST',
                            headers: {
                                'content-type': 'application/json'
                            }
                        });
                        // console.log(reIssueResult)
                        // userInfo.accessToken = reIssueResult.data.accessToken
                        userInfo.exp = reIssueResult.data.exp;
                        // userInfo.refreshToken = reIssueResult.data.refreshToken // 로그인을 다시한거나 마찬가지니까 refreshToken 새로 발급함
                        sessionStorage.setItem('coconutUserInfo', JSON.stringify(userInfo));
                        // console.debug('access token 재발급');
                        // config.headers.Authorization = 'Bearer ' + userInfo.accessToken;
                    } catch (err) {
                        console.error(err);
                        //alert('세션 재인증 실패');
                        alert(1);
                    }
                }
            }
            config = {...config, withCredentials: true}
            return config;
        },
        function (error) {
            console.debug('authAxios response error', error)
            return Promise.reject(error);
        }
    );
    instance.interceptors.response.use(
        function (response) {
            console.debug('authAxios response', response)
            return response;
        },
        function (error: AxiosError) {
            console.debug('authAxios response error', error.response)
            if (!error.response) {
                alert(2);
            } else if (error.response.status == 401) {
                alert(1);
            } else if (error.response.status ==  470) {
                alert(GetMessage("login.sessionExpired"))
                window.location.href = "/logout";
            }
            return Promise.reject(error);
        }
    );
    return instance
}

export const callApi = async (url: string, param?: any, option?: any) => {
    let sessionUserInfo: string = sessionStorage.getItem('coconutUserInfo') ?? '{}'
    if ('{}' === sessionUserInfo) {
        window.location.href = "/login";
    }
    let contentType = option ? option.contentType : 'application/json';
    const coconutUserInfo: LoginInfoModel = JSON.parse(sessionUserInfo);
    // let accessToken = coconutUserInfo.accessToken;
    const options: AxiosRequestConfig = {
        method: 'POST',
        url: url,
        headers: {
            'content-type': contentType,
            // 'Authorization': 'Bearer ' + accessToken
        }
        , withCredentials: true
    };
    return await authAxios
        .post(
            process.env.REACT_APP_API_HOST + url
            , param == null ? null : param
            , options)
}

export const callApiWithoutAuth = async (url: string, param?: any, option?: any) => {
    let contentType = option ? option.contentType : 'application/json';
    const options: AxiosRequestConfig = {
        method: 'POST',
        url: url,
        headers: {
            'content-type': contentType,
        }
        , withCredentials: true
    };

    return await defaultAxios.post(
        process.env.REACT_APP_API_HOST + url
        , param == null ? null : param
        , options);
}

function alert(type) {
    Swal.fire({
        allowEscapeKey: false,
        allowEnterKey: false,
        allowOutsideClick: false,
        buttonsStyling: false,
        html: type == 1 ? "세션이 종료되었습니다. <br/> 로그인 페이지로 이동합니다." : "데이터 조회에 실패하였습니다.",
        icon: 'error',
        confirmButtonText: "확인",
        showCancelButton: false,
        customClass: {confirmButton: 'btn btn-coco-1 wd-349px'}
    })
        .then((result) => {
            if (type == 1) {
                if (result.isConfirmed) {
                    window.location.href = "/logout";
                }
            }
        });
}

export const defaultAxios = axiosApi();
export const authAxios = axiosAuthApi();