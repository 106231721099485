// Copyright (C) 2019-2022 Intel Corporation
// Copyright (C) 2022-2023 CVAT.ai Corporation
//
// SPDX-License-Identifier: MIT
// @ts-nocheck

/**
 * External API which should be used by for development
 * @module API
 */

import PluginRegistry from './plugins';
import loggerStorage from './logger-storage';
import { EventLogger } from './log';
import ObjectState from './object-state';
import Statistics from './statistics';
import Comment from './comment';
import Issue from './issue';
import {Job, Module, Task, Workflow} from './session';
import {implementJob, implementModule, implementTask, implementWorkflow} from './session-implementation';
import Project from './project';
import implementProject from './project-implementation';
import { Attribute, Label } from './labels';
import MLModel from './ml-model';
import { FrameData } from './frames';
import CloudStorage from './cloud-storage';
import Organization from './organization';
import Webhook from './webhook';

import * as enums from './enums';

import {
    Exception, ArgumentError, DataError, ScriptingError, PluginError, ServerError,
} from './exceptions';

import User from './user';
import pjson from '../../../../../../../../package.json';
import config from './config';

import implementAPI from './api-implementation';

function build() {
    const core = {
        // server: {
        //     async about() {
        //         const result = await PluginRegistry.apiWrapper(core.server.about);
        //         return result;
        //     },
        //     async share(directory = '/') {
        //         const result = await PluginRegistry.apiWrapper(core.server.share, directory);
        //         return result;
        //     },
        //     async formats() {
        //         const result = await PluginRegistry.apiWrapper(core.server.formats);
        //         return result;
        //     },
        //     async userAgreements() {
        //         const result = await PluginRegistry.apiWrapper(core.server.userAgreements);
        //         return result;
        //     },
        //     async register(username, firstName, lastName, email, password, userConfirmations) {
        //         const result = await PluginRegistry.apiWrapper(
        //             core.server.register,
        //             username,
        //             firstName,
        //             lastName,
        //             email,
        //             password,
        //             userConfirmations,
        //         );
        //         return result;
        //     },
        //     async login(username, password) {
        //         const result = await PluginRegistry.apiWrapper(core.server.login, username, password);
        //         return result;
        //     },
        //     async logout() {
        //         const result = await PluginRegistry.apiWrapper(core.server.logout);
        //         return result;
        //     },
        //     async socialAuthentication() {
        //         const result = await PluginRegistry.apiWrapper(core.server.socialAuthentication);
        //         return result;
        //     },
        //     async changePassword(oldPassword, newPassword1, newPassword2) {
        //         const result = await PluginRegistry.apiWrapper(
        //             core.server.changePassword,
        //             oldPassword,
        //             newPassword1,
        //             newPassword2,
        //         );
        //         return result;
        //     },
        //     async requestPasswordReset(email) {
        //         const result = await PluginRegistry.apiWrapper(core.server.requestPasswordReset, email);
        //         return result;
        //     },
        //     async resetPassword(newPassword1, newPassword2, uid, token) {
        //         const result = await PluginRegistry.apiWrapper(
        //             core.server.resetPassword,
        //             newPassword1,
        //             newPassword2,
        //             uid,
        //             token,
        //         );
        //         return result;
        //     },
        //     async authorized() {
        //         const result = await PluginRegistry.apiWrapper(core.server.authorized);
        //         return result;
        //     },
        //     async healthCheck(maxRetries = 1, checkPeriod = 3000, requestTimeout = 5000, progressCallback = undefined) {
        //         const result = await PluginRegistry.apiWrapper(
        //             core.server.healthCheck,
        //             maxRetries,
        //             checkPeriod,
        //             requestTimeout,
        //             progressCallback,
        //         );
        //         return result;
        //     },
        //     async request(url, data) {
        //         const result = await PluginRegistry.apiWrapper(core.server.request, url, data);
        //         return result;
        //     },
        //     async installedApps() {
        //         const result = await PluginRegistry.apiWrapper(core.server.installedApps);
        //         return result;
        //     },
        //     async loginWithSocialAccount(
        //         provider: string,
        //         code: string,
        //         authParams?: string,
        //         process?: string,
        //         scope?: string,
        //     ) {
        //         const result = await PluginRegistry.apiWrapper(
        //             core.server.loginWithSocialAccount, provider, code, authParams, process, scope,
        //         );
        //         return result;
        //     },
        // },
        projects: {
            async get(filter = {}) {
                const result = await PluginRegistry.apiWrapper(core.projects.get, filter);
                return result;
            },
            async searchNames(search = '', limit = 10) {
                const result = await PluginRegistry.apiWrapper(core.projects.searchNames, search, limit);
                return result;
            },
        },
        tasks: {
            async get(filter = {}) {
                const result = await PluginRegistry.apiWrapper(core.tasks.get, filter);
                return result;
            },
        },
        workflows: {
            async get(filter = {}) {
                const result = await PluginRegistry.apiWrapper(core.workflows.get, filter);
                return result;
            },
        },
        Label: {
          async get(filter = {}) {
              const result = await PluginRegistry.apiWrapper(core.Label.get, filter);
              return result;
          },
        },
        module: {
            async get(filter = {}) {
                const result = await PluginRegistry.apiWrapper(core.module.get, filter);
                return result;
            },
        },
        review: {
            async get(data, reset) {
                const result = await PluginRegistry.apiWrapper(core.review.get, data, reset);
                return result;
            },
        },
        target: {
            async get(filter = {}) {
                const result = await PluginRegistry.apiWrapper(core.target.get, filter);
                return result;
            },
        },
        jobs: {
            async get(filter = {}) {
                const result = await PluginRegistry.apiWrapper(core.jobs.get, filter);
                return result;
            },
        },
        users: {
            async get(filter = {}) {
                const result = await PluginRegistry.apiWrapper(core.users.get, filter);
                return result;
            },
        },
        plugins: {
            async list() {
                const result = await PluginRegistry.apiWrapper(core.plugins.list);
                return result;
            },
            async register(plugin) {
                const result = await PluginRegistry.apiWrapper(core.plugins.register, plugin);
                return result;
            },
        },
        lambda: {
            async list() {
                const result = await PluginRegistry.apiWrapper(core.lambda.list);
                return result;
            },
            async run(task, model, args) {
                const result = await PluginRegistry.apiWrapper(core.lambda.run, task, model, args);
                return result;
            },
            async call(task, model, args) {
                const result = await PluginRegistry.apiWrapper(core.lambda.call, task, model, args);
                return result;
            },
            async cancel(requestID, functionID) {
                const result = await PluginRegistry.apiWrapper(core.lambda.cancel, requestID, functionID);
                return result;
            },
            async listen(requestID, functionID, onChange) {
                const result = await PluginRegistry.apiWrapper(core.lambda.listen, requestID, functionID, onChange);
                return result;
            },
            async requests() {
                const result = await PluginRegistry.apiWrapper(core.lambda.requests);
                return result;
            },
            async providers() {
                const result = await PluginRegistry.apiWrapper(core.lambda.providers);
                return result;
            },
        },
        logger: loggerStorage,
        config: {
            get service() {
                return config.service;
            },
            set service(value: any) {
                config.service = value;
            },
            get backendAPI() {
                return config.backendAPI;
            },
            set backendAPI(value) {
                config.backendAPI = value;
            },
            get proxy() {
                return config.proxy;
            },
            set proxy(value) {
                config.proxy = value;
            },
            get origin() {
                return config.origin;
            },
            set origin(value) {
                config.origin = value;
            },
            get uploadChunkSize() {
                return config.uploadChunkSize;
            },
            set uploadChunkSize(value) {
                config.uploadChunkSize = value;
            },
            get removeUnderlyingMaskPixels(): boolean {
                return config.removeUnderlyingMaskPixels;
            },
            set removeUnderlyingMaskPixels(value: boolean) {
                config.removeUnderlyingMaskPixels = value;
            },
        },
        client: {
            version: `${pjson.version}`,
        },
        enums,
        exceptions: {
            Exception,
            ArgumentError,
            DataError,
            ScriptingError,
            PluginError,
            ServerError,
        },
        cloudStorages: {
            async get(filter = {}) {
                const result = await PluginRegistry.apiWrapper(core.cloudStorages.get, filter);
                return result;
            },
        },
        organizations: {
            async get() {
                const result = await PluginRegistry.apiWrapper(core.organizations.get);
                return result;
            },
            async activate(organization) {
                const result = await PluginRegistry.apiWrapper(core.organizations.activate, organization);
                return result;
            },
            async deactivate() {
                const result = await PluginRegistry.apiWrapper(core.organizations.deactivate);
                return result;
            },
        },
        classes: {
            User,
            Project: implementProject(Project),
            Task: implementTask(Task),
            Workflow: implementWorkflow(Workflow),
            Module: implementModule(Module),
            Job: implementJob(Job),
            EventLogger,
            Attribute,
            Label,
            Statistics,
            ObjectState,
            MLModel,
            Comment,
            Issue,
            FrameData,
            CloudStorage,
            Organization,
            Webhook,
            Attr
        },
        webhooks: {
            async get(filter: any) {
                const result = await PluginRegistry.apiWrapper(core.webhooks.get, filter);
                return result;
            },
        },
    };

    // core.server = Object.freeze(core.server);
    core.projects = Object.freeze(core.projects);
    core.tasks = Object.freeze(core.tasks);
    core.workflows = Object.freeze(core.workflows);
    core.module = Object.freeze(core.module);
    core.review = Object.freeze(core.review);
    // core.target = Object.freeze(core.target);
    core.jobs = Object.freeze(core.jobs);
    core.users = Object.freeze(core.users);
    core.plugins = Object.freeze(core.plugins);
    core.lambda = Object.freeze(core.lambda);
    core.client = Object.freeze(core.client);
    core.enums = Object.freeze(core.enums);
    core.cloudStorages = Object.freeze(core.cloudStorages);
    core.organizations = Object.freeze(core.organizations);

    const implemented = Object.freeze(implementAPI(core));
    return implemented;
}

export default build();
