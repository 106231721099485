// @ts-nocheck
import {useEffect, useRef, useState} from "react";
import Minimap from "wavesurfer.js/dist/plugins/minimap";
import {ControlBar} from "./controlBar/ControlBar";
import {useWavesurfer} from "./useWavesurfer";
import config from "../../../annotation/image/config";
import {useSelector} from "react-redux";

const calCanvasHeight = () =>{
    const headerC = window.document.getElementsByClassName('canvas-header')[0];   //cvat-annotation-header
    const controlBarC = window.document.getElementsByClassName('audio-control-bar')[0];   //cvat-annotation-header
    let containerHeight = window.innerHeight;
    if (headerC) {
        containerHeight = window.innerHeight
            - (headerC.getBoundingClientRect().bottom + controlBarC.getBoundingClientRect().bottom -controlBarC.getBoundingClientRect().top);
        console.log('[calCanvasHeight]',
            Math.floor(containerHeight)
            , Math.floor(
            (containerHeight - config.CANVAS_WORKSPACE_MARGIN * (config.CANVAS_WORKSPACE_ROWS)) /
            config.CANVAS_WORKSPACE_ROWS,
        ))
        return Math.floor(
            containerHeight,
        );
    }
    console.log('[calCanvasHeight] 0')
    return 0;
}
export const AudioCanvas = (): JSX.Element => {
    const waveRef = useRef();
    const url = useSelector(state => state.Label.Cmmn.frames.frame.data);
    // const [waveHeight, setWaveHeight] = useState(0);
    // useEffect(() => {
    //     setWaveHeight(calCanvasHeight())
    // }, [])
    // console.log('AudioCanvas height', window.innerHeight, window.innerHeight - (70 + 58 + 74))
    const [options, setOptions] = useState({
        url: url,
        barWidth: 4,
        barRadius: 10,
        height: 'auto',
        // // waveHeight,
        //     window.innerHeight
        //     - (70 + 58 + 74 + 8*2), // header + control-bar + minimap + paddings of canvases // (70 + 58 + 74 + 8*2)
        waveColor: "#CFD8DC",   // coco-gray-100
        progressColor: "#90A4AE",   // coco-gray-300
        cursorColor: "#0BAD8F", // coco-primary-400,
        minPxPerSec : 100,
        // plugins: [
        //         Minimap.create({
        //             container: "#wave-minimap",
        //             height: 74,
        //             barWidth: 2,
        //             barRadius: 10,
        //             overlayColor: "#B1E2D7",  // coco-primary-100 selected area B1E2D7 7ED0BD
        //             waveColor: "#46BCA2",   // coco-primary-300 46BCA2 FFFFFF
        //             progressColor: "#009070",   // coco-primary-600 지나온 wave color 1. 009070 009E7D
        //             cursorColor: "#F06448", // coco-danger-400
        //             autoCenter: true,
        //             autoScroll: false,
        //             hideScrollbar: true,
        //             // the Minimap takes all the same options as the WaveSurfer itself
        //         })
        // ]
    })
    const wavesurfer = useWavesurfer(waveRef, options);

    if (wavesurfer) {
        console.log('wavesurfer.options.minPxPerSec', wavesurfer.options.minPxPerSec);
    }

    useEffect(()=> {
        console.log('[AudioCanvas 생성]')
        return () => {
            console.log('[AudioCanvas 소멸]')
        }
    }, [])

    useEffect(() => {
        setOptions({...options, url});
        if (wavesurfer) {
            // url이 변경 되었을 경우, (= 데이터가 변경되었을 경우) minimap을 재연결한다.
            // https://github.com/katspaugh/wavesurfer.js/issues/617
            // wavesurfer.options.plugins[0].initMinimap();
        }
    }, [url])
    // useEffect(() => {
    //     if (!wavesurfer)    return;
    //     console.log('[AudioCanvas] useEffect')
    // }, [wavesurfer])

    return (
        <>
            {/*<div className={"d-flex justify-content-center align-items-center"}>*/}
            <div className={'waveform'} ref={waveRef} />
            <div className={'wave-tools-wrapper'}>
                <div id={'wave-minimap'}
                     style={{backgroundColor: "#DFF3EF"// "rgba(255, 255, 255, 0.40)" 1안. "#DFF3EF" B1E2D7
                }}
                />
                <ControlBar wavesurfer={wavesurfer} waveRef={waveRef}/>
            </div>
        </>
    )
}
