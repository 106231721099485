// @ts-nocheck
import React from 'react';
import { Rotation } from 'store/label';
import {Button} from "reactstrap";

export interface Props {
    clockwiseShortcut: string;
    anticlockwiseShortcut: string;
    rotateFrame(rotation: Rotation): void;
}

function RotateControl(props: Props): JSX.Element {
    const { anticlockwiseShortcut, clockwiseShortcut, rotateFrame } = props;

    return (
        <Button color={"coco-ghost"}
                className="btn-icon waves-effect waves-light rounded-pill"
                id="clockwiseRotation"
                onClick={(): void => rotateFrame(Rotation.CLOCKWISE90)}
        >
            <i className="ri-clockwise-line fs-20"></i>
        </Button>
    );
}

export default React.memo(RotateControl);
