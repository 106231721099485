export function getLabelGroupNode(groupId, type, position, data) {
    return [{
            id: groupId,
            type: "label",
            data: data,
            position,
            expandParent: true,
            style:{height:204+48+48+8+20}
        },
        {
            id: groupId.concat("_01"),
            type:"skip",
            data: { label: "스킵" },
            position: { x: 20, y: 204 },
            parentNode: groupId,
            extent: "parent",
            draggable: false,
            selectable: false,
        },
        {
            id: groupId.concat("_02"),
            type: "submit",
            data: { label: "제출" },
            position: { x: 20, y: 204+48+8 },
            parentNode: groupId,
            extent: "parent",
            draggable: false,
            selectable: false
        }];
}

export function getReviewGroupNode(groupId, type, position, data) {
    return [{
            id: groupId,
            type: type,
            data: data,
            position,
            expandParent: true,
            style:{height:204+48+8+48+/*8+48+*/20}
        },
        {
            id: groupId.concat("_01"),
            type: "approval",
            data: { label: "승인" },
            position: { x: 20, y: 204 },
            parentNode: groupId,
            extent: "parent",
            draggable: false,
            selectable: false,
        },
        {
            id: groupId.concat("_02"),
            type:"reject",
            data: { label: "반려" },
            position: { x: 20, y: 204+48+8 },
            parentNode: groupId,
            extent: "parent",
            draggable: false,
            selectable: false
        }/*,
        {
            id: groupId.concat("_03"),
            type:"ambiguity",
            data: { label: "모호" },
            position: { x: 20, y: 204+48+8+48+8 },
            parentNode: groupId,
            extent: "parent",
            draggable: false,
            selectable: false
        }*/];
}


export function getManagerReviewGroupNode(groupId, type, position, data) {
    return [{
        id: groupId,
        type: type,
        data: data,
        position,
        expandParent: true,
        style:{height:126+48+8+48+20}
        },
        {
            id: groupId.concat("_01"),
            type: "restore",
            data: { label: "복구" },
            position: { x: 20, y: 126 },
            parentNode: groupId,
            extent: "parent",
            draggable: false,
            selectable: false,
        },
        {
            id: groupId.concat("_02"),
            type:"subUnabled",
            data: { label: "작업불가" },
            position: { x: 20, y: 126+48+8 },
            parentNode: groupId,
            extent: "parent",
            draggable: false,
            selectable: false
        }];
}


