import React, {useState} from 'react';
import styled from "styled-components";

const DetailBox = styled.div`
  display: flex;
  padding: 10px 8px;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  border-radius: 4px;
  background: var(--coco-gray-50, #F3F6F9);
`
export const HistoryRejectDetail = (props) => {
    const { data } = props;
    const [visible, setVisible] = useState(false);
    const filteredData = data?.filter(item => (item?.q1?.reject || item?.q2?.reject || item?.q3?.reject));

    return (
        <>
            {
                filteredData?.map((item, idx) => {
                    const afterFirstBox = filteredData.length >= 1 ? {marginTop: "12px", marginBottom: "8px"} : {marginTop:"4px", marginBottom: "8px"}
                    return (
                        <>
                            {
                                (item?.q1?.reject || item?.q2?.reject || item?.q3?.reject) &&
                                    <DetailBox
                                                className={idx >= 1 && !visible ? "d-none" : ""}
                                               style={afterFirstBox} key={`his-detail-${item.id}-${idx}`}>
                                        <div className="fw-500 mb-8">
                                            {item.question}
                                        </div>
                                        <ul className="mb-0 fw-400" style={{paddingLeft : "1.5rem"}}>
                                            {
                                                item.q1 && item.q1.reject &&
                                                <li>{item.q1.content[0]?.details}</li>
                                            }
                                            {
                                                item.q2 && item.q2.reject &&
                                                <li>{item.q2.content[0]?.details}</li>
                                            }
                                            {
                                                item.q3 && item.q3.reject &&
                                                <li>{item.q3.content[0]?.details}</li>
                                            }
                                        </ul>
                                    </DetailBox>
                            }
                        </>
                    )
                })
            }
            {
                filteredData?.length > 1 &&
                    <div className="d-flex align-items-center" onClick={() => {
                        setVisible((prev) => {
                            return !prev;
                        })
                    }}>
                        <span className="d-flex text-coco-secondary-700">
                            <i className={visible ? "ri-arrow-drop-up-fill" : "ri-arrow-drop-right-fill"} style={{fontSize:"19px"}}></i>
                        </span>

                            <span className="text-coco-secondary-600 fs-14 fw-400">
                            {
                                visible
                                    ? "접기"
                                    : "더보기"
                            }
                        </span>
                    </div>
            }
        </>

    );
}