import React from "react";
import {
    Card,
    Col,
} from "reactstrap";
import {ContextMenuTrigger} from 'react-contextmenu';
import thumbnail from "../../../assets/images/thumbnail.png";
import {GetMessage} from "../../../util/message";
import {ManagerProjectService} from "../../../apis/api/ManagerProjectService";
import {manage_project_setList} from "../../../store/manage/project/action";
import {useDispatch} from "react-redux";

function ProjectCardList({list, clickEvent, handleEvent, contextMenuId}) {

    const dispatch = useDispatch();
    const regDtmLabel = GetMessage("ui.U00123");
    const inquireDtmLabel = GetMessage("ui.U00124");
    const dupMsg = GetMessage("validation.duplication");

    const isProjectNmDuplication = async(event, prjctId, prjctNm)=>{
        if(prjctNm === ''){
            dispatch(manage_project_setList(
                list.map((prjctItem) => prjctItem.prjctId === prjctId? {...prjctItem, 'editMode':false}: prjctItem)
            ));
            return;
        }
        await ManagerProjectService.isProjectNmDuplication({
            'prjctId': prjctId,
            'prjctNm': prjctNm,
        }).then((result)=>{
            if(!result.data.data){
                handleEvent( prjctId, prjctNm);
            }else{
                event.target.classList.add('is-invalid');
                event.target.parentElement.children[1].classList.add('invalid-tooltip');
                event.target.parentElement.children[1].innerText= dupMsg;
            }
        });
    
    }

    const handleKeyPress = (event, prjctId) =>{
        if (event.key === 'Enter') {
            isProjectNmDuplication(event, prjctId, event.target.value.trim());
        }
        else{
            event.target.classList.remove('is-invalid');
            event.target.parentElement.children[1].classList.remove('invalid-tooltip');
            event.target.parentElement.children[1].innerText= '';
        }
    }

    const editCount = list.filter(data => data.editMode).length;

    const noImageHandler = (e) => {
        e.target.src = thumbnail;
    }


    return (

        list.length>0?
            list.map((data, index)=> {
                const {prjctId, prjctNm, prjctDc, regDtmText, inqireDtmText,editMode, thumnailUrl}= data;
                return(
                    <Col key={"prjct_".concat(prjctId)}>
                        {contextMenuId !=""&& editCount===0?
                            <ContextMenuTrigger
                                id={contextMenuId} prjctId={prjctId}
                                collect={p => {
                                    console.log("project contextMenu collect", p);
                                    return p;
                                }}
                                >
                                <Card className="card-shadow" onClick={(e)=> editMode?null:clickEvent(prjctId)}>
                                    <img className="card-img-top ht-188" src={thumnailUrl} alt={prjctDc} onError={noImageHandler} />
                                    <div className="d-flex pd-y-12 align-items-center">
                                        <div className="flex-grow-1 mx-3 text-overflow">
                                            <h5 className="tx-16 text-coco-gray-700 fw-semibold mb-10 lh-19 text-overflow">{prjctNm}</h5>
                                            <p className="text-coco-gray-400 fw-medium tx-14 mb-0">
                                                {regDtmLabel}: {regDtmText} <span className={"ml-13".concat(inqireDtmText?'':' d-none')}>({inqireDtmText} {inquireDtmLabel})</span>
                                            </p>
                                        </div>
                                    </div>
                                </Card>
                            </ContextMenuTrigger>
                            :
                            <Card className="card-shadow" onClick={(e)=>editCount>0?null:clickEvent(prjctId)}>
                                <img className="card-img-top ht-188" src={thumnailUrl} alt={prjctDc} onError={noImageHandler}/>
                                <div className="d-flex pd-y-12 align-items-center">
                                    <div className="flex-grow-1 mx-3 text-overflow">
                                        {editMode?
                                            <div className="mb-10">
                                                <input type="text" defaultValue={prjctNm} className="form-control editable tx-16 fw-semibold pd-0 lh-19"
                                                       maxLength={50}
                                                       onBlur={(e)=>isProjectNmDuplication(e, prjctId, e.target.value.trim())} onKeyUp={(e)=> handleKeyPress(e, prjctId)}
                                                       autoFocus />
                                                <p className="validation-error-message"></p>
                                            </div>
                                            :
                                            <h5 className="tx-16 text-coco-gray-700 fw-semibold mb-10 lh-19 text-overflow">{prjctNm}</h5>
                                        }
                                        <p className="text-coco-gray-400 fw-medium tx-14 mb-0">
                                            {regDtmLabel}: {regDtmText} <span className={"ml-13".concat(inqireDtmText?'':' d-none')}>({inqireDtmText} {inquireDtmLabel})</span>
                                        </p>
                                    </div>
                                </div>
                            </Card>
                        }

                    </Col>
                )
            })
            :
            <Col>{GetMessage("common.noData")}</Col>
    );
}

export default ProjectCardList;