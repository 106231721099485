import {
  SET_LIST,
  SET_SEARCH_PARAM,
  SET_INIT_SEARCH_PARAM,
  SET_ORDER_PARAM,
  SET_INIT_ORDER_PARAM,
  SET_TASK_JOIN_MODAL_FLAG,
  SET_TASK_TEST_MODAL_FLAG,
  SET_TASK_REFRESH_FLAG
} from './actionType';

const INIT_STATE = {
  taskList: [],
  searchParam: [],
  orderParam: [0],
  error: {},
  taskJoinModalFlag: false,
  taskTestModalFlag: false,
  refreshFlag: false,
};

const UserTask = (state = INIT_STATE, action) => {
  switch (action.type) {
      case SET_LIST:
        return {
          ...state,
          taskList: action.payload,
        };
     case SET_SEARCH_PARAM :
       return {
         ...state,
         searchParam: [action.payload]
       };
     case SET_INIT_SEARCH_PARAM :
       return {
         ...state,
         searchParam: []
       };

    case SET_ORDER_PARAM :
      return {
        ...state,
        orderParam: [action.payload]
      };
    case SET_INIT_ORDER_PARAM :
      return {
        ...state,
        orderParam: INIT_STATE.orderParam
      };
    case SET_TASK_JOIN_MODAL_FLAG:
      return {
        ...state,
        taskJoinModalFlag: action.payload
      }
    case SET_TASK_TEST_MODAL_FLAG:
      return {
        ...state,
        taskTestModalFlag: action.payload
      }
    case SET_TASK_REFRESH_FLAG:
      return{
        ...state,
        refreshFlag: action.payload
      }

    default:
      return state;
  }
};

export default UserTask;
