// @ts-nocheck
import React, {useEffect, useState} from 'react';
import { Row, Col } from 'antd/lib/grid';
import { MoreOutlined } from '@ant-design/icons';
import Dropdown from 'antd/lib/dropdown';
import Text from 'antd/lib/typography/Text';

import { ObjectType, ShapeType, ColorBy } from 'store/label';
import CVATTooltip from 'pages/user/label/annotation/image/components/common/cvat-tooltip';
import LabelSelector from 'pages/user/label/annotation/image/components/label-selector/label-selector';
import ItemMenu from './object-item-menu';
import {Card, CardHeader, UncontrolledCollapse} from "reactstrap";
import {MD_TY_CD, ShapeIcon} from "../../../const";
import {STATE_ITEM_LABEL_SELECTOR} from "../../classNameConsts";
import {GetMessage, GetMessageIntl} from "../../../../../../../../util/message";
import {useIntl} from "react-intl";

interface Props {
    moduleInstance: any;
    moduleType: MD_TY_CD;
    readonly: boolean;
    clientID: number;
    viewID: number;
    serverID: number | undefined;
    labelID: number;
    labels: any[];
    shapeType: ShapeType;
    objectType: ObjectType;
    color: string;
    colorBy: ColorBy;
    type: string;
    locked: boolean;
    changeColorShortcut: string;
    copyShortcut: string;
    pasteShortcut: string;
    propagateShortcut: string;
    toBackgroundShortcut: string;
    toForegroundShortcut: string;
    removeShortcut: string;
    changeColor(color: string): void;
    changeLabel(label: any): void;
    copy(): void;
    remove(): void;
    propagate(): void;
    createURL(): void;
    switchOrientation(): void;
    toBackground(): void;
    toForeground(): void;
    resetCuboidPerspective(): void;
    edit(): void;
}

function ItemTopComponent(props: Props): JSX.Element {
    const {
        reject,
        completedError,
        touch,
        readonly,
        clientID,
        viewID,
        serverID,
        labelID,
        labels,
        shapeType,
        objectType,
        color,
        colorBy,
        type,
        locked,
        changeColorShortcut,
        copyShortcut,
        pasteShortcut,
        propagateShortcut,
        toBackgroundShortcut,
        toForegroundShortcut,
        removeShortcut,
        changeColor,
        changeLabel,
        copy,
        remove,
        propagate,
        createURL,
        switchOrientation,
        toBackground,
        toForeground,
        resetCuboidPerspective,
        edit,
        moduleInstance,
        moduleType,
    } = props;

    const [menuVisible, setMenuVisible] = useState(false);
    const [colorPickerVisible, setColorPickerVisible] = useState(false);

    const [labelName, setLabelName] = useState(null);
    useEffect(() => {
        const label = labels.filter(label => {
            return label.id === labelID
        })[0]
        setLabelName(label.name);
    }, [labelID]);

    const changeMenuVisible = (visible: boolean): void => {
        if (!visible && colorPickerVisible) return;
        setMenuVisible(visible);
    };

    const changeColorPickerVisible = (visible: boolean): void => {
        if (!visible) {
            setMenuVisible(false);
        }
        setColorPickerVisible(visible);
    };

    const intl = useIntl();
    return(
        <>
            <div className="card-event wd-80p d-flex align-items-center" data-bs-toggle="collapse"
                 aria-expanded="false" id={`object-item-${viewID}`}>
                <div className="wd-10p regionId">{viewID}</div>
                <div className="wd-20p  d-flex align-items-center justify-content-center">
                    <i className={"regionType "+(ShapeIcon[shapeType])}></i>
                </div>
                <div className="regionClassName text-left ps-2 text-overflow" style={{width: "170px"}}>
                    {labelName}
                    { moduleType === MD_TY_CD.LABEL
                        ? (reject === true
                            ? (completedError
                                ? (touch
                                    ?<div className="badge badge-coco badge-coco-default-gray ml-10">{GetMessageIntl(intl, 'ui.U00013')}</div>
                                    :<div className="badge badge-coco badge-coco-soft-red ml-10">{GetMessageIntl(intl, 'ui.U00066')}</div>)
                                : <div className="badge badge-coco badge-coco-soft-yellow ml-10">{GetMessageIntl(intl,'ui.U00013')}</div>)
                            : <></>)
                        : <></>
                    }
                    { (moduleType === MD_TY_CD.LABEL && reject === false)
                        && <div className="badge badge-coco badge-coco-soft-skyblue ml-10">{GetMessageIntl(intl,'label.pass')}</div>
                    }
                </div>
                <span className="badge reviewBadge ms-2 d-none"></span>
            </div>
            {/*<div className="wd-20p mx-2 d-flex align-items-center justify-content-between fs-20">*/}
            {/*    <div className="hideBtn cursor-pointer" type="button">*/}
            {/*        <i className="ri-eye-line"></i>*/}
            {/*    </div>*/}
            {/*    <div className="deleteBtn cursor-pointer" type="button">*/}
            {/*        <i className="ri-delete-bin-line"></i>*/}
            {/*    </div>*/}
            {/*</div>*/}
        </>

    )
}

export default React.memo(ItemTopComponent);
