import React, { memo } from 'react';
import {Handle, useReactFlow, useStoreApi, Position, useStore, NodeToolbar} from 'reactflow';
import {
    manage_project_workflow_module_setIsReviewOffcanvas
} from "../../../../../../store/manage/project/workflow/module/action";
import {useDispatch} from "react-redux";
import {thisTargetValidConnection} from "./nodeCommonUtil";
import moduleReviewImg from "../../../../../../assets/images/task/module_review.png";
import {GetMessageIntl} from "../../../../../../util/message";
import {useIntl} from "react-intl";
import CommonUtil from "../../../../../../util/CommonUtil";

const connectionNodeIdSelector = (state) => {
    return state.connectionNodeId;
}
const nodesSelector = (state) => Array.from(state.nodeInternals.values());

function ReviewNode({ id, data, isConnectable }) {
    const intl = useIntl();
    const dispatch = useDispatch();
    const nodes = useStore(nodesSelector);
    const connectionNodeId = useStore(connectionNodeIdSelector);
    let connectionNodeType = '';
    if(connectionNodeId){
        let connectionNode = nodes.find((item) => item.id === connectionNodeId);
        let parentNodeType = '';
        if(connectionNode.parentNode){
            parentNodeType = nodes.find((item) => item.id === connectionNode.parentNode).type+'_';
        }
        connectionNodeType = parentNodeType + connectionNode.type;
    }

    // 하위 노드가 있는 부모 노드인 경우에는 타 부모에는 연결할수 있지만 부모 노드로는 연결 못하게 해야 하므로 connectionNodeId.indexOf(id)!=0 추가
    const isTarget = connectionNodeId && connectionNodeId !== id && thisTargetValidConnection('review', connectionNodeType) && connectionNodeId.indexOf(id)!=0;
    const targetHandleStyle = isTarget? { visibility: 'visible', zIndex: 10 } : {visibility: 'hidden', zIndex:1};

    const moduleConfig = data&&data.moduleConfig?JSON.parse(data.moduleConfig): null;
    const shapeConfig = moduleConfig?moduleConfig.shapeConfig:null;
    const workerConfig = moduleConfig?moduleConfig.workerConfig:null;
    const pointConfig = moduleConfig?moduleConfig.pointConfig:null;
    const etcConfig = moduleConfig? moduleConfig.etcConfig:null;

    return (
        <>
            <div className="d-flex align-items-center mb-12">
                <div className="flex-shrink-0 avatar-42">
                    <img src={moduleReviewImg} style={{WebkitUserDrag: 'none'}}/>
                </div>
                <div className="flex-grow-1 text-overflow ml-13">
                    <h6 className="text-coco-gray-200 tx-12 lh-16 mb-2">ACTIVITY</h6>
                    <span className="text-coco-gray-700 tx-16 lh-20 fw-semibold">{data?data.moduleNm:null}</span>
                </div>
            </div>
            <div className="bg-coco-gray-50 pd-10 node_info flex-shrink-0 mb-12">
                <div className="ht-20">
                    <label>{GetMessageIntl(intl,"common.openDt")}</label>
                    {etcConfig && etcConfig.openDate? etcConfig.openDate: null }
                </div>
                <div className="ht-20 mt-6">
                    <label>{GetMessageIntl(intl,"common.worker")}</label>
                    {workerConfig?
                        workerConfig.standardTy == '00'?
                            GetMessageIntl(intl,"workflow.noLimit")
                            : workerConfig.limitTy.length>1?
                                GetMessageIntl(intl,"workflow.directMode").concat(`, ${GetMessageIntl(intl,"workflow.conditionMode")}`)
                                : workerConfig.limitTy.includes("00")?
                                    GetMessageIntl(intl,"workflow.directMode")
                                    : GetMessageIntl(intl,"workflow.conditionMode")
                        :null
                    }
                </div>
                <div className="ht-20 mt-6">
                    <label>{GetMessageIntl(intl,"common.point")}</label>
                    {pointConfig && pointConfig.payPoint !=undefined ?CommonUtil.numberFormatComma(pointConfig.payPoint) :null}
                </div>
                <div className="ht-20 mt-6">
                    <label>검수 항목</label>
                    전체 클래스 검수
                </div>
            </div>
            <Handle
                className="targetHandle"
                /*onConnect={(params) => console.log('handle onConnect', params)}*/
                style={targetHandleStyle}
                position={Position.Top}
                id={'top'}
                type="target"
                isConnectable={isConnectable}
                isValidConnection={isTarget}
            />
            <Handle
                className="targetHandle"
                /*onConnect={(params) => console.log('handle onConnect', params)}*/
                style={targetHandleStyle}
                position={Position.Left}
                id={'left'}
                type="target"
                isConnectable={isConnectable}
                isValidConnection={isTarget}
            />
            <Handle
                className="targetHandle"
                /*onConnect={(params) => console.log('handle onConnect', params)}*/
                style={targetHandleStyle}
                position={Position.Bottom}
                id={'bottom'}
                type="target"
                isConnectable={isConnectable}
                isValidConnection={isTarget}
            />
            <Handle
                className="targetHandle"
                /*onConnect={(params) => console.log('handle onConnect', params)}*/
                style={targetHandleStyle}
                position={Position.Right}
                id={'right'}
                type="target"
                isConnectable={isConnectable}
                isValidConnection={isTarget}
            />
        </>
    );
}

export default memo(ReviewNode);