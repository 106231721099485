import {callApi} from '../../apis/utils/AxiosUtil';

export const ManagerProjectService = {
   /**
    * 프로젝트명 중복 확인
    * 이름이 존재하면 true
    * @param param{{prjctNm:string}}
    * @returns {Promise<AxiosResponse<any>>}
    */
   isProjectNmDuplication: (param) => callApi('/service/project/dupCheck', param),
   /**
    *
    * @param param
    * @returns {Promise<AxiosResponse<{prjctId:string}>}
    */
   addProjectItem: (param) => callApi('/service/project/add', param),
   /**
    *
    * @param param{{prjctId:string}}
    * @returns {Promise<AxiosResponse<any>>}
    */
   getProjectItem: (param) => callApi('/service/project/item', param),
   /**
    *
    * @param param {{prjctId:string, prjctNm:string, prjctDc:string}}
    * @returns {Promise<AxiosResponse<any>>}
    */
   updateProjectItem: (param) => callApi('/service/project/update', param),
   /**
    *
    * @param param
    * @returns {Promise<AxiosResponse<any>>}
    */
   getProjectList: (param) => callApi('/service/project/list', param),
   /**
    *
    * @param param{{prjctId:string}}
    * @returns {Promise<AxiosResponse<any>>}
    */
   deleteProjectItem: (param) => callApi('/service/project/delete', param),
   /**
    *
    * @param param{{}}
    * @returns {Promise<AxiosResponse<any>>}
    */
   getManagers: (param) => callApi('/service/project/getManagersByOrgnztId', param),

   getAllManagers: (param) => callApi('/service/project/getAllManagersByOrgnztId', param),
   /**
    *
    * @param param{{prjctId:string}}
    * @returns {Promise<AxiosResponse<any>>}
    */
   getPrjctEvents: (param) => callApi('/service/project/getPrjctEvents', param),
   /**
    *
    * @param param{{userSeq:List}}
    * @returns {Promise<axios.AxiosResponse<any>>}
    */
   updatePrjctMembers: (param) => callApi('/service/project/updateManagers', param),
   /**
    *
    * @param param{{prjctId:string}}
    * @returns {Promise<axios.AxiosResponse<any>>}
    */
   getPrjctExportLogs: (param) => callApi('/service/project/getPrjctExportLogs', param),
   /**
    *
    * @param param {prjctId:string, taskConfig: Json String}
    * @returns {Promise<axios.AxiosResponse<any>>}
    */
   ecsDataExport: (param) => callApi('/service/ecs/dataexport', param),

   /**
    *
    * @param param
    * @returns {Promise<axios.AxiosResponse<any>>}
    */
   getPrjctListByOrgnztId: (param) => callApi('/service/project/getPrjctListByOrgnztId', param),
};
