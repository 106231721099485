import {authAxios, callApi} from '../../apis/utils/AxiosUtil';

export const ManagerWorkflowService={

   saveWorkflowThumnail: (param) => callApi('/service/workflow/saveWorkFlowScreenShot', param),

   /**
    *
    * @param param{{prjctId:string}}
    * @returns {Promise<AxiosResponse<any>>}
    */
   getManageWorkflowList: (param) => callApi('/service/workflow/list', param),
   /**
    *
    * @param param{{prjctId:string, wrkflowNm:string}}
    * @returns {Promise<AxiosResponse<any>>}
    */
   isWorkflowNmDuplication: (param) => callApi('/service/workflow/dupCheck', param),

   /**
    *
    * @param param{{prjctId:string}}
    * @returns {Promise<AxiosResponse<any>>}
    */
   addWorkflowItem: (param) => callApi('/service/workflow/add', param),

   /**
    *
    * @param param{{wrkflowId:string}}
    * @returns {Promise<AxiosResponse<any>>}
    */
   getWorkflowItem: (param) => callApi('/service/workflow/item', param),

   /**
    *
    * @param param
    * @returns {Promise<AxiosResponse<any>>}
    */
   updateWorkflowItem: (param) => callApi('/service/workflow/update', param),/**
    *
    * @param param
    * @returns {Promise<AxiosResponse<any>>}
    */
   updateWorkflowNm: (param) => callApi('/service/workflow/updateNm', param),

   /**
    *
    * @param param{{wrkflowId:string}}
    * @returns {Promise<AxiosResponse<any>>}
    */
   deleteWorkflowItem: (param) => callApi('/service/workflow/delete', param),

   /**
    *
    * @param param{{wrkflowId:string}}
    * @returns {Promise<axios.AxiosResponse<any>>}
    */
   getWorkflowUseDatasetList: (param) => callApi('/service/workflow/getWorkflowUseDatasetList', param),

   /**
    *
    * @param param{{wrkflowId:string}}
    * @returns {Promise<axios.AxiosResponse<any>>}
    */
   getWorkflowUseLabelList: (param) => callApi('/service/workflow/getWorkflowUseLabelList', param),


   uploadLocalFiles: (param, option) => callApi('/service/dataset/uploadFromLocal', param, option),

   /**
    * 데이터셋 업로드(S3에 있는 파일을 플랫폼 S3로 복사 또는 파일 목록 생성 )
    * @param param
    * @returns {Promise<axios.AxiosResponse<any>>}
    */
   uploadDatasetFromS3: (param) => callApi('/service/dataset/fromS3', param),

   uploadLocalFileFinish: (param) => callApi('/service/dataset/uploadFinish', param),

   /**
    * 데이터셋 모듈과 라벨링 모듈 연결&연결 해제
    * @param param{{moduleId:string, datasetId:string, mode:string}}
    * mode : I/D
    * @returns {Promise<axios.AxiosResponse<any>>}
    */
   updateModuleUseDataset: (param) => callApi('/service/workflow/updateLabelModuleUseDataset', param),

   /**
    * 라벨링 모듈과 검수 모듈 연결&연결 해제
    * @param param{{moduleId:string, trgtModuleId:string, mode:string}}
    * @returns {Promise<axios.AxiosResponse<any>>}
    */
   updateReviewModuleUseLabelModule: (param) => callApi('/service/workflow/updateReviewModuleUseLabelModule', param),
   /**
    *
    * @param param {{prjctId: string}}
    * @returns {Promise<axios.AxiosResponse<any>>}
    */
   getWrkflowsWithModules: (param) => callApi('/service/workflow/getDataConfigFromWrkflowByPrjctId', param),


   summaryItem:(param) => callApi('/service/workflow/summaryItem', param),

}
