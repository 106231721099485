import React, {memo} from 'react';
import {Handle, useReactFlow, useStoreApi, Position, useStore} from 'reactflow';
import {GetMessage} from "../../../../../../util/message";

/**
 * Label Skip Node
 * @param id
 * @param data
 * @param isConnectable
 * @returns {JSX.Element}
 * @constructor
 */
export default function SkipNode({id, data, isConnectable}) {
    const sourceHandleStyle = {visibility: 'hidden', zIndex: 1};
    const total = data ? (data.col01 + data.col02 + data.col03 + data.col04 + data.col05) : 0;
    const per04 = total == 0 ? 0 : (data.col04 / total * 100).toFixed(1)
    return (
        <>
            <div className="d-flex align-items-center justify-content-between mb-12 tx-16 fw-500">
                <div>
                    {GetMessage("workflow.skip")}
                </div>
                <div>
                    {data ? data.col04.toLocaleString(): 0}<span className={"text-coco-primary-400"}> ({per04}%)</span>
                </div>

            </div>
            <Handle
                type="source"
                style={sourceHandleStyle}
                position={Position.Right}
                id={'right'}
            />
            <Handle
                type="source"
                style={sourceHandleStyle}
                position={Position.Left}
                id={'left'}
            />
        </>
    );
}