import {Label} from "./labels";

export function createRawCollection(session, label:Label, data){
    const result = {
        tags: [],
        shapes: [],
        tracks: [],
    };

    for (const inst of data) {
        const {x, y, width, height} = inst;
        const rectangle = createRawRectangle(session.frame.number, label, x, y, width, height);
        result.shapes.push(rectangle)
    }
    return result;
}


/**
 * x, y, width, height 값과 label ID를 주면 import 되기 직전의 구조로 만들어준다.
 * @param session
 * @param label
 * @param x
 * @param y
 * @param width
 * @param height
 */
export function createRawRectangle(frameNumber: number, label: Label, x: number, y: number, width: number, height: number) {
    const shapeMold = {
        attributes: [],
        descriptions: [],
        frame: frameNumber,
        group: 0,
        label_id: label.id,
        occluded: false,
        points: [x, y, x+width, y+height],
        rotation: 0,
        source:"manual",
        type: "rectangle",
        z_order: 0
    };

    return shapeMold;
}
