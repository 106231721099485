import React, { memo } from 'react';
import {Handle, useReactFlow, useStoreApi, Position, useStore, NodeToolbar} from 'reactflow';

const connectionNodeIdSelector = (state) => state.connectionNodeId;
const isValidConnection = (connection) => connection.target === 'B';
function GroupNode({ id, data, isConnectable }) {
    const connectionNodeId = useStore(connectionNodeIdSelector);
    const isTarget = connectionNodeId && connectionNodeId !== id;

    const targetHandleStyle = { zIndex: isTarget ? 3 : 1 };
    const label = isTarget ? 'Drop here' : 'Drag to connect';

    return (
        <>
            <div className="custom-group-node">
                <button>delete</button>
                <Handle
                    className="sourceHandle"
                    style={{ zIndex: 2 }}
                    position={Position.Right}
                    type="source"
                    isConnectable={isConnectable}
                />
                <Handle
                    className="targetHandle"
                    style={targetHandleStyle}
                    position={Position.Left}
                    type="target"
                    isConnectable={isConnectable}
                />
            </div>
        </>
    );
}

export default memo(GroupNode);