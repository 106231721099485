// @ts-nocheck
import {Card, CardBody, CardFooter, Nav, NavItem, NavLink, TabContent, TabPane} from "reactstrap";
import classnames from "classnames";
import {GetMessage} from "../../../../../util/message";
import {BasicInfo} from "./basic-info";
import {MD_TY_CD} from "../../../../user/label/annotation/image/const";
import SimpleBar from "simplebar-react";
import {FileHistory} from "./file-history";
import {SttusDisplay} from "../../../../../store/dataset/details";
import {SkipController} from "./skip-controller";
import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {updateSideBarTab} from "../../../../../store/label/action";
import StageHeader from "../../../../user/label/annotation/image/components/header/StageHeader";
import StageListComponent from "./stage-list";

export const ClsfcSmryRightSideBar = () => {
    const {
        Label: {
            Cmmn: {
                canvas: {
                    sideBarTab: sideBarTab,
                    ready
                },
                sidebarCollapsed,
                module: {instance: moduleInstance, type, status: moduleStatus},
                frames: {frame: frameData}
            }
        },
        Details: {
            fileInfo: {
                displayState:{dataSttusDisplay: displayMode}
            }
        }
    } = useSelector(state => state);

    let tab = sideBarTab;
    if (tab === null) {
        tab = "1";
    }

    const dispatch = useDispatch();
    const tabToggle = (newTab: string) => {
        if (tab !== newTab) {
            dispatch(updateSideBarTab(newTab));
        }
    };

    return (
        <div className="questions-side-bar">
            <Card className="label-object-menu-div">
                <CardBody className="listContainer px-0">
                    <Nav tabs className="nav nav-tabs nav-coco2 nav-tabs-custom fw-semibold px-4">
                        <NavItem>
                            <NavLink style={{cursor: "pointer"}}
                                     className={classnames({active: tab === "1",})} onClick={() => {
                                tabToggle("1");
                            }}>
                                {GetMessage("details.basicInfo")}
                            </NavLink>
                        </NavItem>

                        <NavItem>
                            <NavLink style={{cursor: "pointer"}}
                                     className={classnames({active: tab === "2",})} onClick={() => {
                                tabToggle("2");
                            }}>
                                {GetMessage("ui.U00148")}
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink style={{cursor: "pointer"}}
                                     className={classnames({active: tab === "3",})} onClick={() => {
                                tabToggle("3");
                            }}>
                                {GetMessage("details.history")}
                            </NavLink>
                        </NavItem>
                    </Nav>

                    <TabContent activeTab={tab} className="text-muted">
                        <TabPane tabId="1" id="basic-info"
                                 style={{height: "calc(100vh - 170px)", minHeight: "650px"}}>
                            <SimpleBar style={{maxHeight: "calc(100vh - 170px)"}} autoHide={false}>
                                <Card>
                                    <CardBody className="mb-0 rounded-0 lh-lg">
                                        <BasicInfo />
                                    </CardBody>
                                </Card>
                            </SimpleBar>
                        </TabPane>
                        <TabPane tabId="2" id="svg-instance"
                                 style={{height: "calc(100vh - 220px)", minHeight: "650px"}}>
                            <Card>
                                <CardBody className="mb-0 p-0">
                                    <StageHeader/>
                                </CardBody>
                            </Card>
                            <SimpleBar style={{maxHeight: "calc(100vh - 220px)"}} autoHide={false}>
                                <StageListComponent />
                            </SimpleBar>
                        </TabPane>
                        <TabPane tabId="3" id="file-history">
                            <SimpleBar style={{maxHeight: "calc(100vh - 180px)"}} autoHide={false}>
                                <Card>
                                    <CardBody className="mb-0 rounded-0 lh-lg">
                                        <FileHistory />
                                    </CardBody>
                                </Card>
                            </SimpleBar>
                        </TabPane>
                    </TabContent>
                </CardBody>
                <CardFooter>
                    {[SttusDisplay.SUBMIT, SttusDisplay.WORKING].includes(displayMode) && <SkipController/>}
                </CardFooter>
            </Card>
        </div>
    )
}
