import React, {memo} from 'react';
import {Handle, useReactFlow, useStoreApi, Position, useStore, useNodeId} from 'reactflow';
import {useIntl} from "react-intl";
import {GetMessageIntl} from "../../../../../util/message";

export default function FinishNode({id, data, isConnectable}) {
    const intl = useIntl();
    const targetHandleStyle = {visibility: 'hidden', zIndex: 1};
    const sourceHandleStyle = {visibility: 'hidden', zIndex: 1};

    const total = data ? data.total : 0;

    const per01 = total == 0 ? 0 : (data.col01 / total * 100).toFixed(1)
    const per02 = total == 0 ? 0 : (data.col02 / total * 100).toFixed(1)
    const perTotal = total == 0 ? 0 : ((data.col01 + data.col02) / total * 100).toFixed(1)

    return (
        <>
            <div className="d-flex align-items-center mb-12">
                <div className="flex-shrink-0 avatar-42">
                    <div className="avatar-title fs-20 rounded-circle" style={{backgroundColor: '#E4F3FF', color: '#44A9FF'}}>
                        <i className="ri-checkbox-circle-line lh-19"></i>
                    </div>
                </div>
                <div className="flex-grow-1 text-overflow ml-13">
                    <span className="text-coco-gray-700 tx-16 lh-20 mb-2 fw-semibold">{GetMessageIntl(intl, "workflow.end")}</span>
                    <h6 className="text-coco-gray-200 tx-12 lh-16 mb-0">{GetMessageIntl(intl,"common.totalUnit")} {data ? (data.col01 + data.col02) : 0} ({perTotal}%)</h6>
                </div>
            </div>
            <div className="p-0 node_info flex-shrink-0 mb-12">
                <div className="bg-coco-gray-50 ht-34 d-flex align-items-center justify-content-between pd-y-8 pd-x-10 tx-14 lh-18 rounded">
                    <div className={"fw-500"}>
                        {GetMessageIntl(intl, "workflow.finish")}
                    </div>
                    <div className={"fw-semibold"}>
                        {data ? data.col01 : 0}<span className={"text-coco-danger-400"}> ({per01}%)</span>
                    </div>
                </div>
                <div className="bg-coco-gray-50 ht-34 d-flex align-items-center justify-content-between pd-y-8 pd-x-10 tx-14 lh-18 mt-7 rounded">
                    <div className={"fw-500"}>
                        {GetMessageIntl(intl, "workflow.unabled")}
                    </div>
                    <div className={"fw-semibold"}>
                        {data ? data.col02: 0}<span className={"text-coco-danger-400"}> ({per02}%)</span>
                    </div>
                </div>
            </div>
            <Handle
                className="sourceHandle"
                /*style={sourceHandleStyle}*/
                position={Position.Top}
                id={'top'}
                type="source"
                isConnectable={isConnectable}
            />
            <Handle
                className="sourceHandle"
                /*style={sourceHandleStyle}*/
                position={Position.Right}
                id={'right'}
                type="source"
                isConnectable={isConnectable}
            />
            <Handle
                className="sourceHandle"
                /*style={sourceHandleStyle}*/
                position={Position.Bottom}
                id={'bottom'}
                type="source"
                isConnectable={isConnectable}
            />
            <Handle
                className="sourceHandle"
                /*style={sourceHandleStyle}*/
                position={Position.Left}
                id={'left'}
                type="source"
                isConnectable={isConnectable}
            />


            <Handle
                className="targetHandle"
                style={targetHandleStyle}
                position={Position.Top}
                id={'top'}
                type="target"
                isConnectable={isConnectable}
            />
            <Handle
                className="targetHandle"
                style={targetHandleStyle}
                position={Position.Right}
                id={'right'}
                type="target"
                isConnectable={isConnectable}
            />
            <Handle
                className="targetHandle"
                style={targetHandleStyle}
                position={Position.Bottom}
                id={'bottom'}
                type="target"
                isConnectable={isConnectable}
            />
            <Handle
                className="targetHandle"
                style={targetHandleStyle}
                position={Position.Left}
                id={'left'}
                type="target"
                isConnectable={isConnectable}
            />
        </>
    );
}