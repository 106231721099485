import React, {useEffect, useState} from "react";
import {Popover} from "reactstrap";

interface GuidePopoverProps{
    targetId: string;
    isOpen: boolean;
    text: string;
    setIsOpen(value: boolean): void;
}

export const GuidePopover = (props: GuidePopoverProps) => {
    const { targetId, isOpen, setIsOpen, text } = props;
    // @ts-ignore
    const targetVisibillity = targetId != undefined ? document.querySelector(`#${targetId}`)?.checkVisibility(): false;
    const labelId = targetId.replace(/[^0-9]/g,'');
    const [targetContainer, setTargetContainer] = useState(null);
    useEffect(()=>{
        if(targetVisibillity){
            setTargetContainer('#stageContent'.concat(`${labelId}`))
            setTimeout(() => {
                setIsOpen(false);
            }, 5000);
        }else{
            setTargetContainer(this);
            setIsOpen(false);
        }
    }, [targetVisibillity])

    // todo container 생성되기 전에 렌더링 되는 현상
    // Failed to execute 'querySelectorAll' on 'Document': '#.tab-content .active' is not a valid selector.
    return(
        <>
        {isOpen && <Popover
            className={"guide-popover"}
            target={targetId}
            isOpen={targetVisibillity&&isOpen}
            placement={"top"}
            container={targetContainer?targetContainer:this}
            // container={this}
        >
            <div
                dangerouslySetInnerHTML={{__html: text}}
            />
        </Popover>
        }
        </>
    )
}
