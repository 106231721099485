// label Type
export const INIT_MANAGE_WORKFLOW_REVIEW_MODULE_CONFIG                       = "INIT_MANAGE_WORKFLOW_REVIEW_MODULE_CONFIG";
export const RESET_MANAGE_WORKFLOW_REVIEW_MODULE_CONFIG                       = "RESET_MANAGE_WORKFLOW_REVIEW_MODULE_CONFIG";
export const SET_MANAGE_WORKFLOW_REVIEW_BASE_CONFIG                       = "SET_MANAGE_WORKFLOW_REVIEW_BASE_CONFIG";
export const SET_MANAGE_WORKFLOW_REVIEW_REVIEW_CONFIG                       = "SET_MANAGE_WORKFLOW_REVIEW_REVIEW_CONFIG";
export const SET_MANAGE_WORKFLOW_REVIEW_WORKER_CONFIG                       = "SET_MANAGE_WORKFLOW_REVIEW_WORKER_CONFIG";
export const SET_MANAGE_WORKFLOW_REVIEW_POINT_CONFIG                       = "SET_MANAGE_WORKFLOW_REVIEW_POINT_CONFIG";
export const SET_MANAGE_WORKFLOW_REVIEW_DATA_CONFIG                       = "SET_MANAGE_WORKFLOW_REVIEW_DATA_CONFIG";
export const SET_MANAGE_WORKFLOW_REVIEW_TEST_CONFIG                       = "SET_MANAGE_WORKFLOW_REVIEW_TEST_CONFIG";
export const UPDATE_MANAGE_WORKFLOW_REVIEW_EDITOR_CN_BY_ID = "UPDATE_MANAGE_WORKFLOW_REVIEW_EDITOR_CN_BY_ID";
export const SET_MANAGE_WORKFLOW_REVIEW_ACTIVE_DATA = "SET_MANAGE_WORKFLOW_REVIEW_ACTIVE_DATA";
export const SET_MANAGE_WORKFLOW_REVIEW_INACTIVE_DATA = "SET_MANAGE_WORKFLOW_REVIEW_INACTIVE_DATA";
export const SET_MANAGE_WORKFLOW_REVIEW_VIEW_TYPE = "SET_MANAGE_WORKFLOW_REVIEW_VIEW_TYPE";
export const SET_MANAGE_WORKFLOW_REVIEW_SELECTED_ROW_ID = "SET_MANAGE_WORKFLOW_REVIEW_SELECTED_ROW_ID";
export const SET_MANAGE_WORKFLOW_REVIEW_DATA_ERROR = "SET_MANAGE_WORKFLOW_REVIEW_DATA_ERROR";
export const SET_MANAGE_WORKFLOW_REVIEW_FILES = "SET_MANAGE_WORKFLOW_REVIEW_FILES";
export const SET_MANAGE_WORKFLOW_REVIEW_FILES_RESET = "SET_MANAGE_WORKFLOW_REVIEW_FILES_RESET";
export const SWITCH_MANAGE_WORKFLOW_REVIEW_REGIST_DATA_REFRESH_FLAG = "SWITCH_MANAGE_WORKFLOW_REVIEW_REGIST_DATA_REFRESH_FLAG";
export const SET_MANAGE_WORKFLOW_REVIEW_TARGET_LABEL_BIND_CONFIG = "SET_MANAGE_WORKFLOW_REVIEW_TARGET_LABEL_BIND_CONFIG";
export const SET_SHAPE_CONFIG = "workflow/review/testConfig/SET_SHAPE_CONFIG";
