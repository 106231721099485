import {useIntl} from "react-intl";
import {useDispatch, useSelector} from "react-redux";
import {Controller, useFormContext} from "react-hook-form";
import {GetMessageIntl} from "../../../../../../../../util/message";
import React, {useEffect, useState} from "react";
import SimpleBar from "simplebar-react";
import {Card, CardBody, Col, Input, Label, ListGroup, ListGroupItem, OffcanvasBody, Row} from "reactstrap";

const PointClsfcSmryConfigDiv = () => {
	const intl = useIntl();
	const dispatch = useDispatch();
	const prjctId = sessionStorage.getItem("prjctId");
	const { control:controlPoint, register:registerPoint, unregister:unregisterPoint, setValue:setValuePoint, getValues:getValuesPoint, reset:resetPoint, resetField:resetFieldPoint
		, watch:watchPoint, formState: { errors:errorsPoint },} = useFormContext();
	const watchAllPoint = watchPoint();
	const labelShapesTypeInfo = {
		'select': {'label': GetMessageIntl(intl,'workflow.select'), 'value': 'select', 'icon': 'ri-node-tree', 'color': 'purple', 'id': 0}
		, 'checkbox': {'label': GetMessageIntl(intl,'workflow.checkbox'), 'value': 'checkbox', 'icon': 'ri-node-tree', 'color': 'purple', 'id': 1}
		, 'text': {'label': GetMessageIntl(intl,'workflow.summary'), 'value': 'text', 'icon': 'mdi mdi-text-shadow', 'color': 'yellow', 'id': 2}
	};

	const {
		initFlag,
		shapeConfig,
		pointConfig,
		bindConfig} = useSelector((state) => ({
		initFlag: state.ManageWorkflowLabel.initFlag,
		shapeConfig: state.ManageWorkflowLabel.shapeConfig,
		pointConfig: state.ManageWorkflowLabel.pointConfig,
		bindConfig: state.ManageWorkflowLabel.bindConfig
	}));

	const [pointShapeList, setPointShapeList] = useState([]);

	useEffect(()=>{
		if(initFlag){
			resetPoint();
			setValuePoint('pointPayTy', '00');

			let shapeArr = { 'select': 0, 'checkbox': 0, 'text': 0};
			if(shapeConfig){
				for(const [key, value] of Object.entries(shapeConfig)){
					if(key==='labels'){
						let labels = value;
						labels.forEach(label=>{
							const type = label.attributes[0].inputType;
							if (type) {
								shapeArr[type]++;
							}
						});
						shapeArr = Object.entries(shapeArr).filter(([type, count]) => count > 0);
						// setPointShapeList(shapeArr.map(shape=> {return ({type: shape, id: labelShapesTypeInfo[shape].id})}));
						setPointShapeList(shapeArr.map(([type, count], index) => ({type, count, id: index})));
					}
				}
			}

			if(pointConfig){
				console.log('pointConfig', pointConfig);
				for(const [key, value] of Object.entries(pointConfig)){
					if(key === 'instance'){
						value.forEach((val)=>{
							if(shapeArr.map(s => s[0]).includes(val.type)){
								setValuePoint(`${key}.${val.id}.id`, val.id);
								setValuePoint(`${key}.${val.id}.type`, val.type);
								numberFormatter(val.value.toString(), `${key}.${val.id}.value`);
							}
						})
					}else{
						setValuePoint(key, (value ? value.toLocaleString() : ""));
					}
				}
			}

			if(bindConfig){
				if(bindConfig.dataBindType == '00'){
					// 데이터(파일) 할당
					if(pointConfig && pointConfig.pointPayTy == '01'){
						setValuePoint('pointPayTy', '00');
					}

					if(pointConfig && pointConfig.pointPayTy && pointConfig.pointPayTy != '00'){
						setValuePoint('payPoint', '');
					}

				}/*else if(bindConfig.dataBindType == '01'){
                // 데이터(파일) 할당
                setDisabledFolder(false);
            }*/

			}
		}

	}, [initFlag, pointConfig, shapeConfig, bindConfig]);

	const handleClickPointPayTy = (e) =>{
		let pointPayTy = e.target.value;

		if(pointPayTy == '00') {
			if (e.target.checked) {
				// 조건 충족을 체크 했을 경우에는 group을 추가 해줘야함.
				unregisterPoint('instance',);
			}
		}else if(pointPayTy == '03'){	// 지급 기준: 업무 유형
			if (e.target.checked) {
				unregisterPoint('payPoint',);
			}
		}

	}

	const numberFormatter = (value, key) => {
		const numberValue = value.replace(/,/g, "");
		if (!isNaN(Number(numberValue)) && Number.isFinite(+numberValue)) {
			const formattedValue = Number(numberValue).toLocaleString("ko-Kr");
			setValuePoint(key, formattedValue
				// , {shouldDirty: true, shouldValidate: true}
			);
		}
	}

	return(
		<>
			<SimpleBar style={{ maxHeight: "calc(100vh - 229px)" }} autoHide={false} className="simplebar-track-coco">
				<OffcanvasBody style={{ minHeight: "300px" }} >
					<h5 className="module-title">
						<i className="ri-money-dollar-circle-line"></i>{GetMessageIntl(intl,"workflow.pointPaySetting")}
					</h5>
					<Row>
						<Col md={12}>
							<Label htmlFor="standardTy1" className="form-label">{GetMessageIntl(intl,"workflow.pointPayStandard")}<span className="text-coco-danger-400">*</span></Label>
							<div className="ht-38 d-flex align-items-center">
								<div className="form-check form-radio-coco form-check-inline">
									<input className="form-check-input" type="radio" id="pointPayTy1" value="00" onClick={handleClickPointPayTy}{...registerPoint("pointPayTy")}/>
									<Label className="form-check-label mb-0" htmlFor="pointPayTy1">
										{GetMessageIntl(intl,"workflow.pointFile")}
									</Label>
								</div>
								<div className="form-check form-radio-coco form-check-inline">
									<input className="form-check-input" type="radio" id="pointPayTy2" onClick={handleClickPointPayTy} value="03" {...registerPoint("pointPayTy")}/>
									<Label className="form-check-label mb-0" htmlFor="pointPayTy2">
										{GetMessageIntl(intl,"workflow.canvasTy")}
									</Label>
								</div>
							</div>
						</Col>
					</Row>
					<hr className="coco-hr"/>
					<div>
						<h5 className="module-title">
							<i className="ri-coin-line"></i>{GetMessageIntl(intl,"workflow.pointPayInput")}
						</h5>
						<>
							{watchAllPoint.pointPayTy === '00' || watchAllPoint.pointPayTy === '01'?
								<Row>
									<Col md={12}>
										<Label htmlFor="payPoint" className="form-label">{GetMessageIntl(intl,"workflow.pointPayValue")}<span className="text-coco-danger-400">*</span></Label>
										<div className={errorsPoint.payPoint?"validation-error":""}>
											<input type={"text"} min="0" className="form-control" {...registerPoint(`payPoint`,{
													required: GetMessageIntl(intl,"validation.required"),
													pattern: {
														value: /^[0-9]+$/,
														message:GetMessageIntl(intl,"validation.onlyNumber")
													},
													validate: (value, values) => {
														if(parseInt(value) <= 0){
															return GetMessageIntl(intl,"validation.greaterThanZero");
														}
													},
													setValueAs: (value) => value.toString().replaceAll(",", ""),
													})}
												   onChange={(e) => {
													   const { value } = e.target;
													   if(value==''){
														   setValuePoint('payPoint',value);
													   }else{
														   numberFormatter(value, `payPoint`);
													   }
												   	}
												   }
												   maxLength={"7"}
												   placeholder={GetMessageIntl(intl,"workflow.pointPayInputPlaceholder")}/>
										</div>
										{errorsPoint.payPoint && <p className="validation-error-message">{errorsPoint.payPoint.message}</p>}
									</Col>
								</Row>
								:
								shapeConfig&&shapeConfig.labels && shapeConfig.labels.length>0?
									<>
										<ListGroup className="text-coco-gray-700 mb-0">
											{pointShapeList.map((shape, index)=>{
												return (
													<ListGroupItem key={`shape_${shape.id}`} className={"d-flex align-items-start border-0 pd-0 tx-16 mb-16 fw-normal"} id={`shapeTyValue_${shape.id}`}>
														<input type="hidden" name="id" value={shape.id} {...registerPoint(`instance.${shape.id}.id`)} />
														<input type="hidden" name="type" value={shape.type} {...registerPoint(`instance.${shape.id}.type`)} />
														<div className="mr-12 ht-38 d-flex align-items-center">
															<i className={"d-inline-flex fs-16 lh-20 badge badge-coco rounded-pill badge-coco-soft-".concat(labelShapesTypeInfo[shape.type].color).concat(' ', labelShapesTypeInfo[shape.type].icon)}/>
														</div>
														<div className={"tx-16 mn-wd-64 mr-16 ht-38 d-flex align-items-center"}>
															{labelShapesTypeInfo[shape.type].label}
														</div>
														<div className={"tx-16 mn-wd-48 mr-24 ht-38 d-flex align-items-center justify-content-end"}>
															{shape.count}{GetMessageIntl(intl,"common.piece")}
														</div>
														<div className="wd-320px">
															<div className={errorsPoint.instance&&errorsPoint.instance[shape.id]&&errorsPoint.instance[shape.id].value?"validation-error":""}>
																<input type={"text"} className="form-control" {...registerPoint(`instance.${shape.id}.value`,{
																	required: GetMessageIntl(intl,"validation.required"),
																	pattern: {
																		value: /^[0-9]+$/,
																		message:GetMessageIntl(intl,"validation.onlyNumber")
																	},
																	validate: (value, values) => {
																		if(parseInt(value) <= 0){
																			return GetMessageIntl(intl,"validation.greaterThanZero");
																		}
																	},
																	setValueAs: (value) => value.toString().replaceAll(",", ""),
																})}
																	onChange={(e) => {
																		const { value } = e.target;
																		if(value==''){
																			setValuePoint(`instance.${shape.id}.value`,value);
																		}else{
																			numberFormatter(value, `instance.${shape.id}.value`);
																		}
																	}
																	}
																	   maxLength={"7"}
																	   placeholder={GetMessageIntl(intl,"workflow.pointConfig.pointValuePlaceholder")}/>
															</div>
															{errorsPoint.instance&&errorsPoint.instance[shape.id]&&errorsPoint.instance[shape.id].value&& <p className={"mb-0 validation-error-message"}>{errorsPoint.instance[shape.id].value.message}</p>}
														</div>
													</ListGroupItem>
												)
											})
											}
										</ListGroup>
									</>
									:
									<>
										<div className={"d-flex align-items-center justify-content-center default-attribute-div"}
											 style={{height: "calc(100vh - 470px)"}}>
											<span className="text-center text-coco-gray-200 tx-16 fw-500 lh-18" dangerouslySetInnerHTML={{ __html: GetMessageIntl(intl,"workflow.pointConfig.emptyShapeConfig")}}></span>
										</div>
									</>
							}
						</>
					</div>
				</OffcanvasBody>
			</SimpleBar>
		</>
	);
}

export default PointClsfcSmryConfigDiv;
