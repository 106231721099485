import {DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown} from "reactstrap";
import {GetMessage} from "../../../../../../../util/message";
import React from "react";
import CommonUtil from "../../../../../../../util/CommonUtil";

interface SpeedControlProps{
    speed: number;
    setPlaybackRate(speed: number): void;
}
export const SpeedController = (props: SpeedControlProps): JSX.Element => {
    const {speed, setPlaybackRate} = props;
    const options = [0.25, 0.5, 0.75, 1.0, 1.25, 1.5, 1.75, 2.0]
    return (
        <>
            <UncontrolledDropdown direction={"up"}>
                <DropdownToggle tag="span" className={"cursor-pointer"}>
                    {CommonUtil.convertIntToSingleDecimalFormat(speed)}x
                </DropdownToggle>
                <DropdownMenu className={"dropdown-menu-center"} >
                    <DropdownItem header>{GetMessage("label.playbackRate")}</DropdownItem>
                    <DropdownItem divider/>
                    {options.reverse().map(speed => {
                        return (
                            <DropdownItem
                                key={`speed-control-${speed}`}
                                onClick={()=>setPlaybackRate(speed)}>
                                {speed}
                            </DropdownItem>
                        )
                    })}
                </DropdownMenu>
            </UncontrolledDropdown>
        </>
    )
}
