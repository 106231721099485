import React from 'react';
import BreadCrumb from "../../components/common/BreadCrumb";
import {Col, Container, Row} from "reactstrap";

function ManagerList() {
    return (
        <>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title="About" pageTitle="Laflow" />
                    <Row>
                        <Col lg={12}>
                            <div className={"pd-y-05"}>
                                [시스템관리자] ManagerList
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
}

export default ManagerList;
