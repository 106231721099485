import {GetMessage, GetMessageIntl} from "../../../../../../util/message";
import React from "react";

interface Props {
    reject: boolean;
}
export const ReviewTitle = (props: Props): JSX.Element => {
    const {reject} = props;
    return (
        <>
            <div className={"fs-16 result-title"}>
                <i className={"ri-search-eye-line text-coco-danger-400"} />
                <span className={"fw-semibold"}>{GetMessage('review.reviewResult')}:</span>
                {reject ? <div className="badge badge-coco badge-coco-soft-red">{GetMessage('ui.U00066')}</div>
                        : <div className="badge badge-coco badge-coco-soft-skyblue">{GetMessage('label.pass')}</div>
                }
            </div>
        </>
    )
}
