import React, {useEffect, useState} from 'react';
import {Annotator} from "../../components/common/text-annotator/components/Annotator";
function TextLabelTest() {
    const sampleText = '1. 동해 물과 백두산이 마르고 닳도록 하느님이 보우하사 우리나라 만세. 무궁화 삼천리 화려강산 대한 사람, 대한으로 길이 보전하세. ' +
        '2. 남산 위에 저 소나무, 철갑을 두른 듯 바람서리 불변함은 우리 기상일세. 무궁화 삼천리 화려강산 대한 사람, 대한으로 길이 보전하세\n' +
        '3. 가을 하늘 공활한데 높고 구름 없이 밝은 달은 우리 가슴 일편단심일세. 무궁화 삼천리 화려강산 대한 사람, 대한으로 길이 보전하세\n' +
        '4. 이 기상과 이 맘으로 충성을 다하여 괴로우나 즐거우나 나라 사랑하세. 무궁화 삼천리 화려강산 대한 사람, 대한으로 길이 보전하세'
    const [annotations, setAnnotations] = useState([
        {
            key: 'syntax',
            name: 'syntax',
            values: [],
        },
        {
            key: 'other',
            name: 'other',
            values: [],
        }
    ])

    const [relations, setRelations] = React.useState([
        {
            key: 'relation',
            name: 'relation',
            values: [],
            directional: true,
        },
    ])

    const uiOptions = {
        fontSize: 14, // default 20
        defaultSvgPadding: 1, // default 10
        spaceBetweenTextAndSvg: 4, // default 6
        svgWidth: 1.5, // default 3
        spaceBetweenSvgs: 2, // default 2
        spaceBeforeNextLine: 0, // default 10
        relationVerticalOffset: 7, // default 7
        relationHorizontalOffset: 5, // default 5
    }

    return (
        <Annotator
            text={sampleText}
            annotations={annotations}
            relations={[]}
            onChangeAnnotations={setAnnotations}
            uiOptions={uiOptions}
            readOnly={false}
            showLegend={true}
        />
    )
}

export default TextLabelTest;
