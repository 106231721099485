import React, {useEffect, useRef, useState} from "react";
import {Annotations, AnnotationsByKey, Relations} from "../../../../../../../components/common/text-annotator/types";
import {Input} from "reactstrap";
import {Annotator} from "../../../../../../../components/common/text-annotator/components/Annotator";
import {GetMessage, GetMessageIntl} from "../../../../../../../util/message";
import {GuidePopover} from "../GuidePopover";
import {useIntl} from "react-intl";
import CommonUtil from "../../../../../../../util/CommonUtil";
import AnnotatorView from "./AnnotatorView";
import {useSelector} from "react-redux";
import {MD_TY_CD} from "../../../../annotation/image/const";
import {ReviewResultView} from "../classification/ReviewResultView";

interface Props{
    shape: any;
    label: any;
    hookform: any;
    review: any;
}

export const ReviewView = ({shape, label, hookform, review}: Props): JSX.Element => {
    const {datasetId, fileSeq, moduleType} = useSelector(state => ({
        datasetId: state['Label'].Cmmn.frames.frame.datasetId,
        fileSeq: state['Label'].Cmmn.frames.frame.fileSeq,
        moduleType: state['Label'].Cmmn.module.moduleType,
    }));

    const intl = useIntl();
    //const sampleText = '고양이(학명: Felis catus 펠리스 카투스)는 식육목 고양이과에 속하는 포유류이다. 집고양이의 기원은 약 1만년 전 중동 지역에서 스스로 숲속을 나와 사람들이 모여사는 마을에 정착하여 길들여진 아프리카들고양이(학명: Felis lybica)로 추측된다.'
    const sampleText = shape? shape?.answer[0] : '';
    const questionName = `review.classes.${label.order}`;
    const qRejectName = questionName+`.reject`;
    const [reject, setReject] = useState(null);

    //console.log("ReviewView",props.shape.answer[0])
    const {register, setValue, getValues, unregister, control} = hookform;
    //console.log(hookform?.formState?.errors)
    // console.log(hookform?.getValues())

    const sampleAnnotations: Annotations = [
        {
            key: 'syntax',
            name: 'syntax',
            values: [],
        },
        {
            key: 'other',
            name: 'other',
            values: [],
        }
    ];

    const sampleRelations: Relations = [
        {
            key: 'relation',
            name: 'relation',
            values: [],
            directional: true,
        },
    ];

    const [annotations, setAnnotations] = useState(sampleAnnotations)
    const [relations, setRelations] = useState(sampleRelations)

    const [syntaxErrors, setSyntaxErrors] = useState([]);
    const [otherErrors, setOtherErrors] = useState([]);

    const [readOnly, setReadOnly]  = useState(true);
    const [selectedLabel, setSelectedLabel] = useState(null);
    const [deleteLabel, setDeleteLabel] = useState(null);
    const [question, setQuestion] = useState({"q1":null, "q2":null, "q3":null })
    const [q1, setQ1] = useState(null);
    const [q2, setQ2] = useState(null);
    const [q3, setQ3] = useState(null);
    const reviewTaggingDiv = useRef();

    const [uiOptions, setUiOptions] = useState({
        fontSize: 14, // default 20
        defaultSvgPadding: 1, // default 10
        spaceBetweenTextAndSvg: 4, // default 6
        svgWidth: 1.5, // default 3
        spaceBetweenSvgs: 2, // default 2
        spaceBeforeNextLine: 0, // default 10
        relationVerticalOffset: 7, // default 7
        relationHorizontalOffset: 5, // default 5
    });

    const [isOpen, setIsOpen] = useState<boolean>(false);

    useEffect(() => {
        const escKeyTaggingClose = (e) => {
            if (e.keyCode === 27) {
                setReadOnly(true);
                setIsOpen(false);
            }
        };
        window.addEventListener("keydown", escKeyTaggingClose);
        return () => window.removeEventListener("keydown", escKeyTaggingClose);
    }, []);

    useEffect(()=>{
        if(datasetId && datasetId !=undefined && fileSeq && fileSeq!=undefined){
            console.log('datasetId, fileSeq',datasetId, fileSeq);
            setValue(`${questionName}.id`, label.id);
            setQ1(null);
            setQ2(null);
            setQ3(null);
            setSyntaxErrors([]);
            setSyntaxErrors([]);
            setAnnotations(sampleAnnotations);

            // 한 번이라도 검수를 했는 지에 대한 여부
            register(`${questionName}.q1.content.0.touch`
                , { value: moduleType === MD_TY_CD.LABEL,
                    validate: (v, formValue) => {
                        const reject = formValue.review.classes.find(review => review.id == label.id)?.reject;
                        return (v === true || v === false && reject === false)
                    }
            })
        }
    }, [datasetId, fileSeq])

    useEffect(() => {
        if (review === undefined) {
            setReject(null);
        } else {
            setValue(`${questionName}.reject`, review.reject);
            setReject(review.reject);

            // 통과일 경우, hook form에 통과된 값을 등록
            if (review.reject === false) {
                // q1, q2, q3 입력
                changeQuestionReject('q1', false);
                changeQuestionReject('q2', false);
                changeQuestionReject('q3', false);
            }
        }
    }, [review])

    useEffect(()=>{
        annotations.forEach((annotation:AnnotationsByKey) =>{
            if (annotation.key == 'syntax'){
                setSyntaxErrors(annotation.values)
            }else{
                setOtherErrors(annotation.values);
            }
        })

        console.log(hookform?.getValues())

    }, [annotations]);

    const updateSelectedLabel = (type:string) => {
        setReadOnly(false);
        setSelectedLabel({ key: type, type: "annotation"});
        setIsOpen(true);
    }

    const changeQuestionReject = (questionId:string, rejectYn: boolean) =>{
        question[questionId] = {
            "reject": rejectYn
        }

        setQuestion(question);
        setValue(`${questionName}.${questionId}.reject`, rejectYn, {shouldValidate: rejectYn !== null});

        if(questionId == 'q1'){
            unregister(`${questionName}.${questionId}.content.0.details`);
            if(rejectYn){
                register(`${questionName}.${questionId}.content.0.details` , {required: true});
            }else{
                setValue(`${questionName}.${questionId}.content.0.details`, '');
            }
        }else{
            unregister(`${questionName}.${questionId}.content`);
            unregister(`${questionName}.${questionId}.least`);
            if(rejectYn){
                register(`${questionName}.${questionId}.content` , {required: true});
                register(`${questionName}.${questionId}.least`, {validate: (value, formValues) => {
                        if(parseInt(value) <=  0){
                            return GetMessageIntl(intl,"validation.greaterThanZero");
                        }}});
            }else{
                register(`${questionName}.${questionId}.least`);
            }
        }

        if(!rejectYn && (questionId =='q2' || questionId == 'q3')){

            if(questionId =='q2'){
                syntaxErrors.map(error => {
                    setDeleteLabel({
                        key: 'syntax',
                        type: 'annotation',
                        index: error.index,
                        start: error.start,
                        end: error.end
                    });
                });
            }else if(questionId=='q3'){
                otherErrors.map(error => {
                    setDeleteLabel({
                        key: 'other',
                        type: 'annotation',
                        index: error.index,
                        start: error.start,
                        end: error.end
                    });
                });
            }
        }

        switch (questionId) {
            case "q1":
                setQ1(rejectYn);
                break;
            case "q2":
                setQ2(rejectYn);
                break;
            case "q3":
                setQ3(rejectYn);
                break;
        }

        // 3문항 모두 선택이 되었으면 touch 값을 바꿔주어 검수자가 검수를 했는지 안했는 지에 대한 확인을 해준다.
        if (typeof getValues(`${questionName}.q1.reject`) === "boolean"
            && typeof getValues(`${questionName}.q2.reject`) === "boolean"
            && typeof getValues(`${questionName}.q3.reject`) === "boolean") {
            setValue(`${questionName}.q1.content.0.touch`, true);
        }

        // 전체에 대한 reject 여부
        //updateAllQuestionReject();
    }

    useEffect(()=>{
        if(q1 || q2 || q3){
            setValue(`${questionName}.reject`, true);
        }else{
            setValue(`${questionName}.reject`, false);
        }
    }, [q1, q2, q3]);

    const deleteTaggingItem = (type:string, start: number, end: number, index: number) =>{
        //console.log("deleteTaggingItem", type, start, end, index);
        setDeleteLabel({
            key: type,
            type: 'annotation',
            index: index,
            start: start,
            end: end
        });

        console.log(hookform?.getValues())

        if(type == 'syntax'){
            //setValue(`${questionName}.q2.content.${index}`, null);
            unregister(`${questionName}.q2.content.${index}`);
            // unregister(`${questionName}.q2.content.${index}.details`);
            // unregister(`${questionName}.q2.content.${index}.start`);
            // unregister(`${questionName}.q2.content.${index}.end`);
            // unregister(`${questionName}.q2.content.${index}.sentence`);
            // unregister(`${questionName}.q2.content.${index}.id`);
            // unregister(`${questionName}.q2.content.${index}`);
            setValue(`${questionName}.q2.least`, syntaxErrors.length-1, {shouldValidate: true});

            setSyntaxErrors(syntaxErrors.filter((error)=> error.index != index));
        }else{
            //setValue(`${questionName}.q3.content.${index}`, null);
            unregister(`${questionName}.q3.content.${index}`);
            // unregister(`${questionName}.q3.content.${index}.details`);
            // unregister(`${questionName}.q3.content.${index}.start`);
            // unregister(`${questionName}.q3.content.${index}.end`);
            // unregister(`${questionName}.q3.content.${index}.sentence`);
            // unregister(`${questionName}.q3.content.${index}.id`);
            // unregister(`${questionName}.q3.content.${index}`);
            setValue(`${questionName}.q3.least`, otherErrors.length-1, {shouldValidate: true});
            setOtherErrors(otherErrors.filter((error)=> error.index != index));
        }

        console.log(hookform?.getValues())
    }

    const afterAction = () =>{
        setReadOnly(true);
        setIsOpen(false);
        setValue(`${questionName}.q2.least`, syntaxErrors.length, {shouldValidate: true});
        setValue(`${questionName}.q3.least`, otherErrors.length, {shouldValidate: true});

        console.log(hookform?.getValues())
    }

    return (
        <>
            <div className={"mb-24 mt-8"}>
                {/* 여기는 태깅 영역 */}
                <div className={"mb-10"}>
                    <span className={"text-coco-gray-700 fw-semibold tx-16 d-flex align-items-center"}>
                        <i className={"ri-file-list-3-line text-coco-primary-400 mr-4"}/>{label?.question}
                        {label?.attributes[0].required === 1 &&
                            <span className="text-coco-danger-700">*</span>}
                    </span>
                </div>

                <div ref={reviewTaggingDiv} id={"reviewTaggingDiv".concat(shape?.id)}
                    className={"summary-review-div pd-x-18 pd-y-16 border-coco-gray-100 border-1 bg-coco-gray-50 border-solid border-radius-4".concat(readOnly?" read-only":"")}>
                    <GuidePopover isOpen={isOpen} setIsOpen={setIsOpen}
                                  text={GetMessageIntl(intl,"review.summaryCheckingGuidePopover")}
                                  targetId={"reviewTaggingDiv".concat(shape?.id)}
                    />
                    <AnnotatorView
                        text={sampleText}
                        annotations={annotations}
                        relations={relations}
                        onChangeAnnotations={setAnnotations}
                        uiOptions={uiOptions}
                        readOnly={readOnly}
                        updateLabel={selectedLabel}
                        showLegend={false}
                        afterAction={afterAction}
                        deleteLabel={deleteLabel}
                        containerId={shape?.id+""}
                    />
                </div>
            </div>

            {reject !== false?
                <div className={"position-relative"} style={{zIndex:9999}}>
                    {/* 여기는 질문 영역 */}
                    {/* 전체에 대한 reject*/}
                    <input type="hidden" {...register(`${questionName}.reject`)}/>

                    <div className={"mb-12"}>
                        <div className={"d-flex align-items-center justify-content-between"}>
                            <span className={"tx-16 fw-500 lh-20 text-coco-gray-700"}>
                                {GetMessageIntl(intl, "review.question1")}
                            </span>
                            <div>
                                {/* 버튼 영역 */}
                                <button type="button" className={"btn btn-coco-ghost-1 ".concat(q1 !=null && !q1?"selected":"")} onClick={()=>{
                                    changeQuestionReject('q1', false)
                                }}>
                                    {GetMessageIntl(intl, "ui.U00083")}
                                </button>
                                <button type="button" className={"btn btn-coco-ghost-3 ".concat(q1 !=null && q1?"selected":"")} onClick={()=>{
                                    changeQuestionReject('q1', true)
                                }}>
                                    {GetMessageIntl(intl, "ui.U00084")}
                                </button>
                                <input type="hidden" {...register(questionName+`.q1.reject`, {
                                    validate: {
                                        isBoolean: (value) => CommonUtil.checkType(value, 'boolean') || GetMessageIntl(intl, 'review.reviewValidationError')
                                    }
                                })}/>
                            </div>
                        </div>
                        {hookform?.formState?.errors?.review?.classes[label.order]?.q1?.reject
                            && <p className="validation-error-message">{GetMessageIntl(intl, 'review.reviewValidationError')}</p>}

                        <div className={"mt-12 mb-16 ".concat(q1 !=null && q1? "": "d-none")}>
                            {/*  여기에는 아니요 했을 떄의 항목이 나옴  */}
                            <input type="text" className="form-control editable" placeholder={GetMessageIntl(intl, "common.detailInfoInsert")}  {...register(questionName+`.q1.content.0.details`)}/>
                            {hookform?.formState?.errors?.review?.classes[label.order]?.q1?.content
                                && hookform?.formState?.errors?.review?.classes[label.order]?.q1?.content[0]?.details
                                && <p className="validation-error-message">{GetMessageIntl(intl, 'review.missingRequired')}</p>}
                        </div>
                    </div>
                    <div className={"mb-12"}>
                        <div className={"d-flex align-items-center justify-content-between"}>
                        <span className={"tx-16 fw-500 lh-20 text-coco-gray-700"}>
                            {GetMessageIntl(intl, "review.question2")}
                        </span>
                            <div>
                                {/* 버튼 영역 */}
                                <button type="button" className={"btn btn-coco-ghost-1 ".concat(q2 !=null && !q2?"selected":"")} onClick={()=>{
                                    changeQuestionReject('q2', false)
                                }}>
                                    {GetMessageIntl(intl, "ui.U00083")}
                                </button>
                                <button type="button" className={"btn btn-coco-ghost-3 ".concat(q2 !=null && q2?"selected":"")} onClick={()=>{
                                    changeQuestionReject('q2', true)
                                }}>
                                    {GetMessageIntl(intl, "ui.U00084")}
                                </button>
                                <input type="hidden" {...register(questionName+`.q2.reject`, {
                                    validate: {
                                        isBoolean: (value) => CommonUtil.checkType(value, 'boolean') || GetMessageIntl(intl, 'review.reviewValidationError')
                                    }
                                })}/>
                            </div>
                        </div>
                        {hookform?.formState?.errors?.review?.classes[label.order]?.q2
                            && hookform?.formState?.errors?.review?.classes[label.order]?.q2?.reject && <p className="validation-error-message">{GetMessageIntl(intl, 'review.reviewValidationError')}</p>}

                        <div className={"mt-12 mb-16 ".concat(q2 !=null && q2? "": "d-none")}>
                            {/*  여기에는 아니요 했을 떄의 항목이 나옴  */}
                            <div className={"summary-review-answer"}>
                                {
                                    syntaxErrors.map(error =>{
                                        return (
                                            <div className={"summary-review-answer-detail"} key={`${questionName}.q2.content.${error.index}.key`}>
                                                <div className={"tagging-sentence mx-wd-100p"}>
                                                    <div className="badge badge-coco badge-coco-outline-green" style={{textAlign:'start', whiteSpace:'break-spaces'}}>{sampleText.trim().substring(error.start, error.end)}</div>
                                                    <input type="hidden" {...register(questionName+`.q2.content.${error.index}.sentence`)} defaultValue={sampleText.trim().substring(error.start, error.end)} />
                                                    <input type="hidden" {...register(questionName+`.q2.content.${error.index}.id`)} defaultValue={error.index} />
                                                    <input type="hidden" {...register(questionName+`.q2.content.${error.index}.start`)} defaultValue={error.start} />
                                                    <input type="hidden" {...register(questionName+`.q2.content.${error.index}.end`)} defaultValue={error.end} />
                                                </div>
                                                <div className={"tagging-answer mn-wd-80p"}>
                                                    <div className={"d-flex align-items-center"}>
                                                        <input type="text" className="form-control editable" placeholder={GetMessageIntl(intl, "common.detailInfoInsert")} {...register(questionName+`.q2.content.${error.index}.details`, {required:true})}/>
                                                        <i className="ri-close-line text-coco-gray-400 tx-22 ps-2 deleteOtherErrorItem text-pointer" onClick={()=>{deleteTaggingItem("syntax", error.start, error.end, error.index)}}></i>
                                                    </div>
                                                    {hookform?.formState?.errors?.review?.classes[label.order]?.q2?.content?.[error.index]?.details && <p className="validation-error-message">{GetMessageIntl(intl, 'review.missingRequired')}</p>}
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                            <button type="button" className="btn btn-coco-ghost-2 pd-x-12" onClick={()=>{
                                updateSelectedLabel('syntax');
                            }}>
                                + {GetMessageIntl(intl, "ui.U00147")}
                            </button>
                        </div>
                        <input type={"hidden"} {...register(questionName+'.q2.least')} value={syntaxErrors.length} />
                        {hookform?.formState?.errors?.review?.classes[label.order]?.q2?.least && <p className="validation-error-message">{GetMessageIntl(intl, 'review.atLeast1History')}</p>}

                    </div>
                    <div className={"mb-0"}>
                        <div className={"d-flex align-items-center justify-content-between"}>
                        <span className={"tx-16 fw-500 lh-20 text-coco-gray-700"}>
                            {GetMessageIntl(intl, "review.question3")}
                        </span>
                            <div>
                                {/* 버튼 영역 */}
                                <button type="button" className={"btn btn-coco-ghost-1 ".concat(q3 !=null && !q3?"selected":"")} onClick={()=>{
                                    changeQuestionReject('q3', false)
                                }}>
                                    {GetMessageIntl(intl, "ui.U00083")}
                                </button>
                                <button type="button" className={"btn btn-coco-ghost-3 ".concat(q3 !=null && q3?"selected":"")} onClick={()=>{
                                    changeQuestionReject('q3', true)
                                }}>
                                    {GetMessageIntl(intl, "ui.U00084")}
                                </button>
                                <input type="hidden" {...register(questionName+`.q3.reject`, {
                                    validate: {
                                        isBoolean: (value) => CommonUtil.checkType(value, 'boolean') || GetMessageIntl(intl, 'review.reviewValidationError')
                                    }
                                })}/>
                            </div>
                        </div>
                        {hookform?.formState?.errors?.review?.classes[label.order]?.q3
                            && hookform?.formState?.errors?.review?.classes[label.order]?.q3?.reject && <p className="validation-error-message">{GetMessageIntl(intl, 'review.reviewValidationError')}</p>}

                        <div className={"mt-12 ".concat(q3!=null && q3? "": "d-none")}>
                            {/*  여기에는 아니요 했을 떄의 항목이 나옴  */}
                            <div className={"summary-review-answer"}>
                                {
                                    otherErrors.map(error =>{
                                        return (
                                            <div className={"summary-review-answer-detail"} key={`${questionName}.q3.content.${error.index}.key`}>
                                                <div className={"tagging-sentence mx-wd-100p"}>
                                                    <div className="badge badge-coco badge-coco-outline-purple" style={{textAlign:'start', whiteSpace:'break-spaces'}}>{sampleText.trim().substring(error.start, error.end)}</div>
                                                    <input type="hidden" {...register(questionName+`.q3.content.${error.index}.sentence`)} defaultValue={sampleText.trim().substring(error.start, error.end)} />
                                                    <input type="hidden" {...register(questionName+`.q3.content.${error.index}.id`)} defaultValue={error.index} />
                                                    <input type="hidden" {...register(questionName+`.q3.content.${error.index}.start`)} defaultValue={error.start} />
                                                    <input type="hidden" {...register(questionName+`.q3.content.${error.index}.end`)} defaultValue={error.end} />
                                                </div>
                                                <div className={"tagging-answer mn-wd-80p"}>
                                                    <div className={"d-flex align-items-center"}>
                                                        <input type="text" className="form-control editable" placeholder={GetMessageIntl(intl, "common.detailInfoInsert")} {...register(questionName+`.q3.content.${error.index}.details`, {required:true})}/>
                                                        <i className="ri-close-line text-coco-gray-400 tx-22 ps-2 deleteOtherErrorItem text-pointer" onClick={()=>{deleteTaggingItem("other", error.start, error.end, error.index)}}></i>
                                                    </div>
                                                    {hookform?.formState?.errors?.review?.classes[label.order]?.q3?.content?.[error.index]?.details && <p className="validation-error-message">{GetMessageIntl(intl, 'review.missingRequired')}</p>}
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                            <button type="button" className="btn btn-coco-ghost-2 pd-x-12" onClick={()=>{
                                updateSelectedLabel('other');
                            }}>
                                + {GetMessageIntl(intl, "ui.U00147")}
                            </button>
                            <input type={"hidden"} {...register(questionName+'.q3.least')} value={otherErrors.length}/>
                            {hookform?.formState?.errors?.review?.classes[label.order]?.q3?.least && <p className="validation-error-message">{GetMessageIntl(intl, 'review.atLeast1History')}</p>}
                        </div>
                    </div>
                </div>
                :
                <><ReviewResultView reject={reject}/></>

            }
        </>
    )
}
