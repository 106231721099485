import React, {useState} from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import Editor from 'ckeditor5-custom-build/build/ckeditor';
import {BoardService} from "../../apis/api/BoardService";
import { v4 as uuidv4 } from 'uuid';
import { FilePond} from 'react-filepond'

// Import FilePond styles
import 'filepond/dist/filepond.min.css'
import {Button} from "reactstrap";
import customUploadAdapter from "../../components/features/CustomUploadAdapter";

function EditorTestCK({setDesc, desc}) {
    const [flag, setFlag] = useState(false);
    const [files, setFiles] = useState([])
    const [content, setContent] = useState([])
    // 현재 게시물 작성 아이디
    const uuid = uuidv4();


    function uploadPlugin (editor){ // (3)
        editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
            return customUploadAdapter(loader, uuid);
        }
    }


    const ssssss =(aa)=>{
        console.log(aa)
    }

    const addProcess = ()=>{
        const data = new FormData();
        data.append("cn", content);
        data.append("boardSeq", 1);
        data.append("mode", "I");
        data.append("cnLvl", 1);
        data.append("cnTitle", "첨부파일 있는 게시글 등록");
        data.append("noticeYn", "N");
        data.append("cnSttusCd", "00");


        for (let i = 0; i < files.length; i++) {
            // append the blob file
            data.append('attachFile[]', files[i].file);
        }

        BoardService.setContent(data, {contentType : 'multipart/form-data'})
            .then((res) => {
                console.log(res.data)
            })
            .catch((err)=>{
                const data = new FormData();

            });
    }
    return (
        <>
            <div className="App">
                <h2>Using CKEditor 5 build in React</h2>
                <FilePond
                    files={files}
                    onupdatefiles={setFiles}
                    chunkUploads={true}
                    allowMultiple={true}
                    maxFiles={3}
                    instant-upload={false}
                    name="files"
                    processfilestart={ssssss}
                    labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
                    credits={false}
                />
                <CKEditor
                    editor={Editor}
                    config={{
                        extraPlugins: [uploadPlugin],
                        removePlugins : ['specialCharacters','MediaEmbedToolbar'],
                        toolbar:{
                            removeItems: [ 'specialCharacters']
                        },
                    }}
                    onReady={editor => {
                    }}
                    onChange={(event, editor) => {
                        setContent(editor.getData())
                    }}
                    onBlur={(event, editor) => {
                    }}
                    onFocus={(event, editor) => {
                    }}
                />
                <Button onClick={addProcess}>등록</Button>
            </div>
        </>
    )
}
export default EditorTestCK;