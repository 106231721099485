import {MD_STTUS_CD, MD_TY_CD} from "../../../annotation/image/const";
import {SkipContainer} from "../../../cmmn/components/taskSideBar/skip-container";
import {SubmitButtonContainer} from "./SubmitButton";
import {useDispatch} from "react-redux";
import {useIntl} from "react-intl";

export const NextStepFooter = (props): JSX.Element => {
    const dispatch = useDispatch();
    const intl = useIntl();
    const { type, moduleStatus, onHandleSubmit, clsfcSmryForm } = props;

    if (type === MD_TY_CD.LABEL) {
        return (
            <div className="hstack gap-2 justify-content-between">
                {moduleStatus === MD_STTUS_CD.FIRST &&
					<>
						<SkipContainer  dispatch={dispatch} history={history} intl={intl} />
						<SubmitButtonContainer clsfcSmryForm={clsfcSmryForm} widthPercent={50} onHandleSubmit={onHandleSubmit} />
					</>
                }
                {moduleStatus === MD_STTUS_CD.RE &&
					<>
						<SubmitButtonContainer clsfcSmryForm={clsfcSmryForm} widthPercent={100} onHandleSubmit={onHandleSubmit}/>
					</>
                }
            </div>
        )
    } else if (type === MD_TY_CD.REVIEW) {
        return (
            <>
                <SubmitButtonContainer clsfcSmryForm={clsfcSmryForm} widthPercent={100} onHandleSubmit={onHandleSubmit}/>
            </>

        )
    }
}
