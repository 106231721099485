import {useDispatch, useSelector} from "react-redux";
import {
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row,
    Col,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane,
    Label,
    Input,
    Accordion,
    AccordionItem,
    Collapse,
    AccordionHeader,
    AccordionBody,
    Card,
    CardHeader,
    CardBody,
    DropdownItem,
} from "reactstrap";
import SimpleBar from "simplebar-react";
import {setTaskRefreshFlag, setTaskTestModalFlag} from "../../../store/user/task/action";
import React, {useEffect, useRef, useState} from "react";
import {useIntl} from "react-intl";
import {GetMessage, GetMessageIntl} from "../../../util/message";
import {TaskService} from "../../../apis/api/TaskService";
import {CommonService as CmmnService} from "../../../apis/api/CmmnService";
import {FormProvider, useForm, useFormContext} from "react-hook-form";
import {alertError, alertSuccess, confirm} from "../../../util/AlertUtil";
import {useHistory} from "react-router";
import {assignCheck} from "../../../store/user/board/saga";
import {getModule} from "../../../store/label/action";
import {ModuleService} from "../../../apis/api/ModuleService";
import {
    manage_project_workflow_module_setSelectionNode,
    manage_project_workflow_module_updateModuleId
} from "../../../store/manage/project/workflow/module/action";
import laflowLogo from "../../../assets/images/laflow_logo_01.png";

const INITIAL_POSITION = { x: 0, y: 0 };
const MIN_SCALE = 0.1;
const MAX_SCALE = 10;
let img = new Image();
function TaskTestModal(props){
    const dispatch = useDispatch();
    const history = useHistory();
    const intl = useIntl();
    const {taskTestModalFlag} = useSelector((state) =>({
        taskTestModalFlag : state.UserTask.taskTestModalFlag
    }))

    const methodsTest = useForm({
        criteriaMode: "all",
        defaultValues: {}
    });
    const {
        control: controlTest, register: registerTest, setValue: setValueTest, getValues: getValuesTest, trigger: triggerTest, reset: resetTest, resetField: resetFieldTest
        , watch: watchTest, formState: {errors: errorsTest}, handleSubmit: handleSubmitTest
    } = methodsTest;
    const watchTestAll = watchTest();

    const [testConfig, setTestConfig] = useState(null);
    const [shapeConfig, setShapeConfig] = useState(null);
    const [question, setQuestion] = useState([]);
    const [activeVerticalTab, setactiveVerticalTab] = useState('');
    const [reviewRejectReasonList, setReviewRejectReasonList] = useState([]);
    // 채점 결과
    const [submitPass, setSubmitPass] = useState([]);
    const [submitPassCount, setSubmitPassCount] = useState(0);

    const [open, setOpen] = useState('');

    const zoomCanvasRef = useRef(null);
    const scaleRef = useRef(1);
    const panningRef = useRef(false);
    const viewPosRef = useRef(INITIAL_POSITION);
    const startPosRef = useRef(INITIAL_POSITION);
    const [testTryCount, setTestTryCount] = useState(0);

    useEffect(() => {
        const getReviewRejectReasonList = async () => {
            return await CmmnService.getReviewRejectReasonList();
        }
        getReviewRejectReasonList().then(res => {
            setReviewRejectReasonList(res.data.data);
        });
    }, [])

    useEffect(()=>{
        if(taskTestModalFlag && props.moduleId != null){
            setOpen('');
            resetTest();
            setactiveVerticalTab('');
            setTestConfig(null);
            setShapeConfig(null);
            setQuestion([]);
            setSubmitPass([]);
            setSubmitPassCount(0);
            setTestTryCount(props.testTryCount? props.testTryCount : 0);
            img = new Image();

            getTaskTest();
        }
    },[taskTestModalFlag])

    useEffect(()=>{
        if(question.length>0){
            toggle('1');
        }
    }, [question])

    const getTaskTest = async ()=>{
        TaskService.getTaskTest({
            moduleTy: props.moduleTy,
            moduleId: props.moduleId,
            testTryCount: testTryCount
        }).then(res => {
            if(res.data.data){
                let result = res.data.data;
                if(result.moduleConfig){
                    // testConfig만 들어있음
                    let moduleConfig = JSON.parse(result.moduleConfig);
                    if(moduleConfig.testConfig&&moduleConfig.testConfig.questions&&moduleConfig.testConfig.questions.length>0){
                        setQuestion(moduleConfig.testConfig.targetQuestions);
                        setTestTryCount(testTryCount+1);
                        console.log('testTryCount 올라갑니다', testTryCount+1);
                    }
                    setTestConfig(moduleConfig.testConfig);
                    setShapeConfig(moduleConfig.shapeConfig);
                }
            }
        })
    }

    const FAQBtn = () => {
        const goFAQ = () => {
            const url = `/user/task/info/${props.moduleId}/faq`;
            window.open(url, 'moduleInfo');
        }
        return (
            <div className="mr-12">
                <div className="badge badge-coco badge-coco-soft-green text-pointer tx-16" onClick={goFAQ}>FAQ</div>
            </div>
        )
    }
    const GuideBookBtn = () => {
        const goGuideBook = () => {
            const url = `/user/task/info/${props.moduleId}/guide`;
            window.open(url,'moduleInfo');
            // window.open(url, "_blank", "noopener, noreferrer");
        }
        return (
            <div className="badge p-0 text-pointer d-flex align-items-center mr-12" onClick={goGuideBook}>
                <i className="ri-book-open-line fs-24 text-coco-primary-400 fw-500" id="guideBook"></i>
            </div>
        )
    };


    const toggle = (id) => {
        console.log('id',id);
        if (open === id) {
            setOpen('');
        } else {
            setOpen(id);
            setactiveVerticalTab(id);
            img.src = question[id-1].image.host + question[id-1].image.url;
            img.onload = function(e){
                scaleRef.current = 1;
                viewPosRef.current = INITIAL_POSITION;
                draw();
            }
        }
    };

    const setTransform = () => {
        const zoomCanvas = zoomCanvasRef.current;
        const context = zoomCanvas.getContext('2d');
        context.setTransform(
            scaleRef.current,
            0,
            0,
            scaleRef.current,
            viewPosRef.current.x,
            viewPosRef.current.y
        );
    };

    const draw = () => {
        const zoomCanvas = zoomCanvasRef.current;
        const context = zoomCanvas.getContext('2d');
        zoomCanvas.width = zoomCanvas.width;
        setTransform();
        context.drawImage(img, 0, 0, zoomCanvas.width, zoomCanvas.height);
    };

    const handleMouseDown = (e) => {
        const { offsetX, offsetY } = e.nativeEvent;
        e.preventDefault();
        startPosRef.current = {
            x: offsetX - viewPosRef.current.x,
            y: offsetY - viewPosRef.current.y,
        };
        panningRef.current = true;
    };

    const handleMouseUp = (e) => {
        panningRef.current = false;
    };

    const handleMouseMove = (e) => {
        const { offsetX, offsetY } = e.nativeEvent;
        e.preventDefault();
        if (!panningRef.current) {
            return;
        }
        viewPosRef.current = {
            x: offsetX - startPosRef.current.x,
            y: offsetY - startPosRef.current.y,
        };
        draw();
    };

    const handleWheel = (e) => {
        const { offsetX, offsetY } = e.nativeEvent;
        const xs = (offsetX - viewPosRef.current.x) / scaleRef.current;
        const ys = (offsetY - viewPosRef.current.y) / scaleRef.current;
        const delta = -e.deltaY;
        const newScale =
            delta > 0 ? scaleRef.current * 1.2 : scaleRef.current / 1.2;

        if (newScale >= MIN_SCALE && newScale <= MAX_SCALE) {
            scaleRef.current = newScale;
            viewPosRef.current = {
                x: offsetX - xs * scaleRef.current,
                y: offsetY - ys * scaleRef.current,
            };
        }
        draw();
    };

    const resetCanvas = (e) =>{
        scaleRef.current = 1;
        viewPosRef.current = INITIAL_POSITION;
        draw();
    }

    const closeBtn = (
        <button className="close p-0 border-0 bg-transparent" onClick={()=>{
            dispatch(setTaskTestModalFlag(false))
        }
        } type="button">
            <i className={"ri-close-line tx-24"}/>
        </button>
    );

    const onSubmitQuestion = async() =>{
        let data = getValuesTest();
        const result = await triggerTest();
        if(!result){
            alertError("test.testUnsolvedQuestionTitle", 'test.testUnsolvedQuestionMsg', null, null, null, intl);
            return;
        }else{
            confirm("test.testSubmitTitle", "test.testSubmitMsg", () => {
                // 여기에 채점을 만듭시다..
                let passCnt = 0;
                let failIndex = [];
                let testResult = [];

                let resultPass = question.map((quest, index) => {
                    let pass = quest.rejectReason.sort().toString() == data.correctItem[index].rejectReason.sort().toString();
                    if(pass){
                        passCnt++;
                    }else{
                        failIndex.push(index);
                    }
                    // 채점 결과
                    let targetPass = {pass:pass, testImage:quest.image.realFileName, correct: data.correctItem[index].rejectReason.sort()};
                    testResult.push(targetPass);
                    return pass;
                });

                setSubmitPassCount(passCnt);
                setSubmitPass(resultPass);
                let testResultParam = {joinTest:{pass:passCnt >= testConfig.passCount, testResult: testResult, testTryCount: testTryCount}};
                resultSubmit(testResultParam);
                if(passCnt >= testConfig.passCount){
                    // 성공
                    alertSuccess("test.testSubmitPassTitle", 'test.testSubmitPassMsg', null, null, intl);
                }else{
                    // 실패
                    alertError("test.testSubmitFailTitle", 'test.testSubmitFailMsg', null, null, null, intl);
                }
                // console.log(resultPass);
                // console.log(passCnt);
                // console.log(failIndex);
            }, () => {
            }, null, intl)
        }
    }

    const resultSubmit =async(joinTest)=>{
        // 제출 결과를 보여주는 ui...
        // 성공을 했으면 디비에 저장해주자..
        let param = {};
        param.moduleId = props.moduleId;
        param.joinConfig = JSON.stringify(joinTest);

        await TaskService.updateJoinConfig(param).then((result) => {
        });

    }

    const onTestRetryQuestion =()=>{
        //재 시험 준비
        setOpen('');
        resetTest();
        setactiveVerticalTab('');
        setTestConfig(null);
        setShapeConfig(null);
        setQuestion([]);
        setSubmitPass([]);
        setSubmitPassCount(0);
        // 이미지를 초기화 합니다.
        img.src = '';
        img.onload();
        getTaskTest();
    }

    // const onTestWork =async ()=>{
    //     props.onTestWork();
    // }

    // 미니테스트후 작업하기 버튼시 실행
    const onTestWork =async ()=>{
        dispatch(setTaskTestModalFlag(false));
        const module = {
            moduleId: props.moduleId,
            name: props.moduleNm,
            type: props.moduleTy,
            canvasDataTy: props.canvasDataTy,
        }
        try{
            const doAfter = {
                prePage: history.location.pathname,
                do: {
                    "11": gotoCanvas,
                    "12": gotoCanvas,
                    "13": (callback) => {
                        confirm("joined.checkAssignTitleConfirm"
                            , "joined.checkAssignConfirm"
                            , async function () {
                                const target = await callback();
                                if (target != null){
                                    gotoCanvas(module, target);
                                }
                            }
                            , function () {
                                if (history) {
                                    history.goBack();
                                }
                            }
                            , null
                            , intl
                        );
                    },
                    "04": () => {
                        alertError('joined.noDataAlert'
                            ,'joined.noDataAlertText', ()=>{},null ,null
                            ,intl)
                    },
                    "05": () => {
                        alertError('joined.noProgressAlert'
                            ,'joined.noDataAlertText' ,null ,null ,null
                            ,intl)
                    },
                }
            }
            await assignCheck(module, {intl}, doAfter);
        } catch (e) {
            alertError(
                ""
                ,""
                ,null
                ,null
                ,e
                ,intl
            );
        }
    }

    const gotoCanvas = (module, frame) => {
        dispatch(getModule({module, frame}));
        history.push({
            pathname:'/label/image'
        });
    }

    return (
        <>
            <Modal
                fullscreen={true}
                isOpen={taskTestModalFlag}
                toggle={() => {
                    dispatch(setTaskTestModalFlag(false))
                    if(props.taskYn ||
                        (submitPass.length>0 && testConfig && submitPassCount>=testConfig.passCount))
                    {
                        dispatch(setTaskRefreshFlag(true));
                    }
                }}
                backdrop="static"
                keyboard={false}
                className="fw-bold"
                id="taskTestModalFullscreen"
            >
                <div className={"modal-header pd-y-20 bg-coco-default-white-f border-coco-gray-100 d-flex align-items-center"}
                             style={{borderBottomWidth: "1px"}}>
                    <div className={"modal-title d-flex align-itmes-center  justify-content-between w-100"}>
                        <div className={"d-flex align-items-center"} style={{height:"36px"}}>
                            <img src={laflowLogo} alt="" height="22" />
                        </div>
                        <div className={"d-flex align-items-center justify-content-center"}>
                            <FAQBtn />
                            <GuideBookBtn />
                        </div>
                    </div>
                    <button className="close p-0 border-0 bg-transparent" onClick={()=>{
                        dispatch(setTaskTestModalFlag(false))
                        if(props.taskYn ||
                            (submitPass.length>0 && testConfig && submitPassCount>=testConfig.passCount))
                        {
                            dispatch(setTaskRefreshFlag(true));
                        }
                    }
                    } type="button">
                        <i className={"ri-close-line tx-24"}/>
                    </button>
                </div>
                <FormProvider {...methodsTest}>
                    <form>
                        <ModalBody className="fs-16 p-0">
                            <Row className="mx-0" style={{minHeight: "calc(100vh - 138px)"}}>
                                <Col className={"col-xl-2-5 bg-coco-primary-400 text-coco-default-white pd-x-24 pd-y-42 nav-coco3 vertical-navs-step text-coco-default-white"}>
                                    <div className={"mb-16 tx-24 fw-bold"}>
                                        {testConfig?testConfig.title:null}
                                    </div>
                                    <div className={"tx-16 lh-28 fw-semibold mb-16"}>
                                        {GetMessageIntl(intl, "test.testPassCondition", {'questionCount': testConfig?testConfig.questionCount:0,'passCount': testConfig?testConfig.passCount:0})}
                                    </div>
                                    <div className={"mb-24 tx-16 fw-semibold ht-80 lh-20"} style={{whiteSpace: "pre-line"}}>
                                        <p dangerouslySetInnerHTML={{__html: testConfig?testConfig.desc.replaceAll("\n", "<br/>"):null}}>
                                        </p>
                                    </div>
                                </Col>
                                <Col className={"col-xl-7 d-flex align-items-center justify-content-center"}>
                                    <div className="px-lg-0 d-flex align-items-start">
                                        <button type={"button"} className={"icon-btn-white mr-18"} onClick={resetCanvas}><i className={"mdi mdi-fit-to-screen-outline"}/></button>
                                        <canvas
                                            ref={zoomCanvasRef}
                                            width="1000"
                                            height="620"
                                            style={{ background: '#F3F6F9' }}
                                            onMouseDown={handleMouseDown}
                                            onMouseMove={handleMouseMove}
                                            onMouseUp={handleMouseUp}
                                            onWheel={handleWheel}
                                        />
                                    </div>
                                </Col>
                                <Col className={"col-xl-2-5 pd-r-16 pd-t-24"}>
                                    <Card className={"shadow-none"}>
                                        {
                                            submitPass.length>0?
                                                <>
                                                    {
                                                        testConfig && submitPassCount>=testConfig.passCount?
                                                            <CardHeader className={"bg-coco-secondary-500 tx-20 text-coco-default-white pd-x-12 d-flex align-items-center"}>
                                                                <i className={"ri-emotion-line tx-18 mr-4 fw-normal"}/>{GetMessageIntl(intl, "test.testSubmitSuccessHeader", {'questionCount': testConfig?testConfig.questionCount:0,'passCount': submitPassCount})}
                                                            </CardHeader>
                                                            :
                                                            <CardHeader className={"bg-coco-danger-400 tx-20 text-coco-default-white pd-x-12  d-flex align-items-center"}>
                                                                <i className={"ri-emotion-sad-line tx-18 mr-4 fw-normal"}/>{GetMessageIntl(intl, "test.testSubmitFailHeader", {'failCount': testConfig?testConfig.questionCount-submitPassCount:0 })}
                                                            </CardHeader>
                                                    }
                                                    <CardBody className={"p-0"} >
                                                        <SimpleBar className="simplebar-track-coco" autoHide={false} style={{height: "calc(100vh - 250px)"}}>
                                                            <Accordion open={open} toggle={toggle} className={"coco-test-accordion"}>
                                                                {
                                                                    testConfig&&testConfig.targetQuestions?
                                                                        testConfig.targetQuestions.map((question, index) => {
                                                                            const {labels, attributes, comment, rejectReason} = question;
                                                                            const questionId = index+1+'';
                                                                            const targetLabel =shapeConfig?shapeConfig.labels.find((label)=>label.id === labels):null;
                                                                            return(
                                                                                <QuestionAccordion key={`question_${index}`} reviewRejectReasonList={reviewRejectReasonList} index={index} questionId={questionId} targetLabel={targetLabel} attributes={attributes} showComment={true} comment={comment} rejectReason={rejectReason} submitPass={submitPass}/>
                                                                            )
                                                                        })
                                                                        :null
                                                                }
                                                            </Accordion>
                                                        </SimpleBar>
                                                    </CardBody>
                                                </>
                                                :
                                                <>
                                                    <CardHeader className={"bg-coco-primary-400 tx-20 text-coco-default-white pd-x-12"}>
                                                        {GetMessageIntl(intl, "test.testHeader", {'questionCount': testConfig?testConfig.questionCount:0,'passCount': testConfig?testConfig.passCount:0})}
                                                    </CardHeader>
                                                    <CardBody className={"p-0"} >
                                                        <SimpleBar className="simplebar-track-coco" autoHide={false} style={{height: "calc(100vh - 250px)"}}>
                                                            <Accordion open={open} toggle={toggle} className={"coco-test-accordion"}>
                                                                {
                                                                    testConfig&&testConfig.targetQuestions?
                                                                        testConfig.targetQuestions.map((question, index) => {
                                                                            const {labels, attributes, comment, rejectReason} = question;
                                                                            const questionId = index+1+'';
                                                                            const targetLabel =shapeConfig?shapeConfig.labels.find((label)=>label.id === labels):null;
                                                                            return(
                                                                                <QuestionAccordion key={`question_${index}`} reviewRejectReasonList={reviewRejectReasonList} index={index} questionId={questionId} targetLabel={targetLabel} attributes={attributes} showComment={false} comment={comment} rejectReason={rejectReason} submitPass={submitPass}/>
                                                                            )
                                                                        })
                                                                        :null
                                                                }
                                                            </Accordion>
                                                        </SimpleBar>
                                                    </CardBody>
                                                </>
                                        }
                                    </Card>
                                </Col>
                            </Row>
                        </ModalBody>
                        <ModalFooter className={"pd-y-12 border-coco-gray-100"} style={{borderTopWidth: "1px"}}>
                            {
                                submitPass.length>0?
                                    testConfig && submitPassCount>=testConfig.passCount?
                                        <>
                                            <button type="button" onClick={(e)=>onTestWork(e)} className={"btn btn-coco-3"}>
                                                {GetMessageIntl(intl,"test.testWork")}
                                            </button>
                                            <button type="button" onClick={()=>{
                                                dispatch(setTaskTestModalFlag(false))
                                                dispatch(setTaskRefreshFlag(true));
                                            }} className={"btn btn-coco-1"}>
                                                {GetMessageIntl(intl,"ui.U00025")}
                                            </button>
                                        </>
                                        :
                                        <>
                                            <button type="button" onClick={onTestRetryQuestion} className={"btn btn-coco-3"}>
                                                {GetMessageIntl(intl,"test.testRetry")}
                                            </button>
                                            <button type="button" onClick={()=>{
                                                dispatch(setTaskTestModalFlag(false))
                                                if(props.taskYn){
                                                    dispatch(setTaskRefreshFlag(true));
                                                }
                                            }} className={"btn btn-coco-1"}>
                                                {GetMessageIntl(intl,"ui.U00025")}
                                            </button>
                                        </>

                                    :
                                    <button type="button" onClick={onSubmitQuestion} className={"btn btn-coco-3"}>
                                        {GetMessageIntl(intl,"ui.U00063")}
                                    </button>
                            }

                        </ModalFooter>
                    </form>
                </FormProvider>
            </Modal>
        </>
    )
}

export function QuestionAccordion(props){
    const {reviewRejectReasonList, index, questionId, targetLabel, attributes, showComment, comment, rejectReason, submitPass} = props;
    const {
        control: controlTest
        , register: registerTest
        , setValue: setValueTest
        , getValues: getValuesTest
        , clearErrors: clearErrorsTest
        , reset: resetTest
        , trigger: triggerTest
        , resetField: resetFieldTest
        , watch: watchTest
        , formState: {errors: errorsTest},
    } = useFormContext();
    const watchTestAll = watchTest();
    const intl = useIntl();

    return (
        <AccordionItem key={"question_accordion_".concat(index)} className={submitPass.length>0? submitPass[index]? "pass":"fail":""}>
            <AccordionHeader targetId={questionId} className={"coco-test-accordion-header ".concat(errorsTest.correctItem&&errorsTest.correctItem[index]&&errorsTest.correctItem[index].rejectReason? 'error':'')} >
                <span className={"d-flex align-items-center me-2 ".concat(
                    submitPass.length>0?
                        submitPass[index]? "pass":"fail"
                        :watchTestAll.correctItem&&watchTestAll.correctItem[index]&&watchTestAll.correctItem[index].rejectReason&&watchTestAll.correctItem[index].rejectReason.length>0?
                        'done':''
                )}>
                    <i className="ri-close-circle-fill step-icon me-2"></i>
                    Q{questionId}
                </span>
            </AccordionHeader>
            <AccordionBody accordionId={questionId} className={"p-0"} tag={"div"}>
                {targetLabel?
                    <div className="flex-shrink-0 mb-12 attribute-info">
                        <div className="ht-20">
                            <label className={"text-coco-gray-700 mr-14 mb-0 mn-wd-60 fw-500"}>{GetMessageIntl(intl,"workflow.labelClass")}</label>
                            <div className="badge badge-coco badge-coco-soft-green lh-14">
                                {targetLabel.name}
                            </div>
                        </div>
                        {
                            Object.entries(attributes).map(([key, value]) => {
                                const targetAttribute = targetLabel.attributes.find(target=>target.id == key);
                                return (
                                    <div className="ht-20 mt-10" key={`quest${index}_${key}`}>
                                        <label className={"text-coco-gray-700 mr-14 mb-0 mn-wd-60 fw-500"}>{targetAttribute.name}</label>
                                        {Array.isArray(value)?
                                            value.map((val, i)=>{
                                                return(
                                                    <div className="badge badge-coco badge-coco-soft-green lh-14 mr-8" key={`values_${index}_${key}_${i}`}>
                                                        {val}
                                                    </div>
                                                )
                                            }): <span className={"text-coco-gray-700 tx-16 fw-500"}>{value}</span>
                                        }

                                    </div>
                                )
                            })
                        }
                    </div>
                    :
                    null
                }
                <CommentInput questionNo={index}
                              reviewRejectReasonList={reviewRejectReasonList}
                              correct={showComment} rejectReason={rejectReason}
                />

                {
                    showComment &&comment?
                        <div className="attribute-comment">
                            <div className={"text-coco-danger-400"}>{GetMessageIntl(intl,"test.testComment")}</div>
                            <div className={"text-coco-gray-700"}>{comment }</div>
                        </div>
                        :null
                }
            </AccordionBody>
        </AccordionItem>
    )
}

export function CommentInput(props) {
    const {reviewRejectReasonList, questionNo, correct, rejectReason} = props;
    const intl = useIntl();
    const {
        control: controlTest
        , register: registerTest
        , setValue: setValueTest
        , getValues: getValuesTest
        , clearErrors: clearErrorsTest
        , reset: resetTest
        , trigger: triggerTest
        , resetField: resetFieldTest
        , watch: watchTest
        , formState: {errors: errorsTest},
    } = useFormContext();
    const [comment, setComment] = useState("");
    const [reviewRejectReason, setReviewRejectReason] = useState([]);

    useEffect(()=>{
        if(correct){
            setReviewRejectReason(rejectReason);
        }else{
            setReviewRejectReason([]);
        }
    },[correct])

    return (
        <>
            <div className="attribute-correct-info">
                <Row className="m-0">
                    <Col>
                        <Row className="p-0">
                            <div className="p-0 text-coco-gray-700 tx-16 fw-semibold mb-16">
                                {GetMessage("test.registCorrect")} <span className={"text-coco-gray-400 tx-12"}>{GetMessage("label.skipSubTitle")}</span>
                            </div>
                            {errorsTest.rejectReason && <p className="validation-error-message">{GetMessageIntl(intl, "validation.required")}</p>}
                            {
                                reviewRejectReasonList.map(item => {
                                    const checked = reviewRejectReason.includes(item.cmmnDetailCd);
                                    const rejectReasonId = 'rejectReason' + item.cmmnDetailCd;
                                    return (
                                        <Row key={item.cmmnDetailCd}>
                                            <div className={"form-check form-check-coco mb-12 ".concat(correct&&checked?'correct':'')}>
                                                <input className="form-check-input" type="checkbox" id={`${questionNo}_${rejectReasonId}`} value={item.cmmnDetailCd}
                                                       disabled={correct}
                                                       {...registerTest(`correctItem.${questionNo}.rejectReason`, {
                                                           required: true
                                                       })}
                                                       onChange={(event) => {
                                                           const newValue = event.target.value;
                                                           let newReviewRejectReason = [...reviewRejectReason];
                                                           if (newValue === '01') {
                                                               if (event.target.checked) {
                                                                   newReviewRejectReason = ['01'];
                                                               } else {
                                                                   newReviewRejectReason = [];
                                                               }
                                                           } else {
                                                               if (event.target.checked) {
                                                                   if (newReviewRejectReason.includes('01')) {
                                                                       newReviewRejectReason = newReviewRejectReason.filter(item => item !== '01');
                                                                   }
                                                                   if (!newReviewRejectReason.includes(newValue)) {
                                                                       newReviewRejectReason = [...newReviewRejectReason, newValue];
                                                                   }
                                                               } else {
                                                                   newReviewRejectReason = newReviewRejectReason.filter(item => item !== newValue);
                                                               }
                                                           }
                                                           clearErrorsTest(`correctItem.${questionNo}.rejectReason`);
                                                           setReviewRejectReason(newReviewRejectReason);
                                                           setValueTest(`correctItem.${questionNo}.rejectReason`,newReviewRejectReason);
                                                       }}
                                                       checked={checked}
                                                />
                                                <Label className="form-check-label mb-0" htmlFor={`${questionNo}_${rejectReasonId}`}>
                                                    {item.cmmnDetailCdNm}
                                                </Label>
                                            </div>
                                        </Row>
                                    )
                                })

                            }
                        </Row>
                    </Col>
                </Row>

            </div>
        </>
    );
}

export default  TaskTestModal;