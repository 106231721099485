// @flow
import { all, call, put, fork, takeEvery } from "redux-saga/effects";

import {
    SET_MANAGE_WORKFLOW_LABEL_TASK_CONFIG
    , SET_MANAGE_WORKFLOW_LABEL_SHAPE_CONFIG
    , SET_MANAGE_WORKFLOW_LABEL_WORKER_CONFIG
    , SET_MANAGE_WORKFLOW_LABEL_ORDER_CONFIG
    , SET_MANAGE_WORKFLOW_LABEL_POINT_CONFIG
    , SET_MANAGE_WORKFLOW_LABEL_DATA_CONFIG
    , SET_MANAGE_WORKFLOW_LABEL_CLASS_ITEMS
    , ADD_MANAGE_WORKFLOW_LABEL_CLASS_ITEM
    , UPDATE_MANAGE_WORKFLOW_LABEL_CLASS_ITEM
    , DELETE_MANAGE_WORKFLOW_LABEL_CLASS_ITEM
    , SET_MANAGE_WORKFLOW_LABEL_ATTR_ITEMS
    , ADD_MANAGE_WORKFLOW_LABEL_ATTR_ITEM
    , UPDATE_MANAGE_WORKFLOW_LABEL_ATTR_ITEM
    , DELETE_MANAGE_WORKFLOW_LABEL_ATTR_ITEM
    , SET_SELECTED_MANAGE_WORKFLOW_LABEL_CLASS_ID
} from './actionType';
import {setSelectedManageWorkflowLabelClassId} from "./action";

/**
 * Changes the Manage Workflow
 * @param {*} param0
 */
function* changeSelectedManageWorkflowLabelClassId({ payload: deleteClassItemId }) {
    try {
        yield put(setSelectedManageWorkflowLabelClassId(-1));
    } catch (error) {
        // console.log(error);
    }
}

export function* watchDeleteManageWorkflowLabelClassItem(){
    yield takeEvery(DELETE_MANAGE_WORKFLOW_LABEL_CLASS_ITEM, changeSelectedManageWorkflowLabelClassId);
}
function* ManageWorkflowLabelSaga() {
    yield all([
        fork(watchDeleteManageWorkflowLabelClassItem),
    ]);
}

export default ManageWorkflowLabelSaga;