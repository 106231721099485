import {callApi} from '../../apis/utils/AxiosUtil';

export const SystemService = {

    /**
     *
     * @returns {Promise<axios.AxiosResponse<any>>}
     */
    getLogList: (param) => callApi("/service/sa/log", param),


}
