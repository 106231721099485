import {
    SWITCH_MODAL_FLAG,
    SWITCH_VIEW_MODAL_FLAG,
    SET_ROW_DATA,
    SWITCH_TMP_VIEW_MODAL_FLAG,
    SET_MODAL_MODE,
    NOTICE_SET_SEARCH_PARAM,
    NOTICE_CN_TITLE,
    NOTICE_REG_DTM,
    NOTICE_CURRENT_PAGE,

    FAQ_SET_MODAL_MODE,
    FAQ_SWITCH_ADD_MODAL_FLAG,
    FAQ_SWITCH_VIEW_MODAL_FLAG,
    FAQ_SWITCH_TMP_VIEW_MODAL_FLAG,
    FAQ_SET_CN_TY,
    FAQ_SET_CN_TYPE_OPTIONS,
    FAQ_SET_SEARCH_PARAM,
    FAQ_SET_CN_TITLE,
    FAQ_SET_CURRENT_PAGE,
    SWITCH_REFRESH_FLAG,

    OTO_SWITCH_ADD_MODAL_FLAG,
    OTO_SWITCH_TMP_VIEW_MODAL_FLAG,
    OTO_SET_MODAL_MODE,
    OTO_SWITCH_VIEW_MODAL_FLAG,
    OTO_SWITCH_REFRESH_FLAG,
    OTO_SET_CN_TITLE,
    OTO_SET_REG_DTM,
    OTO_SET_REPLY_YN,
    OTO_SET_CN_TY,
    OTO_SET_SEARCH_PARAM, OTO_SET_CURRENT_PAGE
} from "./actionType"

const INIT_STATE = {
    modalFlag : false,
    viewModalFlag : false,
    refreshFlag : false,
    rowData : null,
    mode : "I",

    // 공지사항
    noticeSearchParam : [],
    noticeCnTitle : "",
    noticeRegDtm : "",
    noticeCurrentPage : 1,

    // FAQ
    faqSearchParam : [],
    faqCnTitle: "",
    faqCnTyOptoins: [],
    faqCnTy: "",
    faqModalMode : "I",
    faqCurrentPage: 1,
    tmpViewModalFlag : false,
    faqAddModalFlag : false,
    faqViewModalFlag : false,
    faqTmpViewModalFlag : false,

    // 1:1
    otoSearchParam : [],
    otoCurrentPage : 1,
    otoCnTitle: "",
    otoCnTy : "",
    otoReplyYn : "",
    otoRegDtm : "",
    otoAddModalFlag : false,
    otoViewModalFlag : false,
    otoTmpViewModalFlag : false,
    otoModalMode : "I",
    otoRefreshFlag : false
}

const Board = (state = INIT_STATE, action) => {
    switch (action.type){
        case SWITCH_MODAL_FLAG :
            return {
                ...state,
                modalFlag: !state.modalFlag
            }
        case SWITCH_VIEW_MODAL_FLAG :
            return {
                ...state,
                viewModalFlag: !state.viewModalFlag
            }
        case SWITCH_TMP_VIEW_MODAL_FLAG :
            return {
                ...state,
                tmpViewModalFlag: !state.tmpViewModalFlag
            }
        case SET_ROW_DATA :
            return {
                ...state,
                rowData: action.payload
            }
        case SET_MODAL_MODE :
            return {
                ...state,
                mode: action.payload
            }
        case NOTICE_CURRENT_PAGE :
            return {
                ...state,
                noticeCurrentPage: action.payload
            }
        case NOTICE_SET_SEARCH_PARAM :
            return {
                ...state,
                noticeSearchParam: [action.payload]
            }
        case NOTICE_CN_TITLE :
            return {
                ...state,
                noticeCnTitle : action.payload
            }
        case NOTICE_REG_DTM :
            return {
                ...state,
                noticeRegDtm: action.payload
            }
        case FAQ_SET_MODAL_MODE :
            return {
                ...state,
                faqModalMode: action.payload
            }
        case FAQ_SET_SEARCH_PARAM :
            return {
                ...state,
                faqSearchParam: [action.payload]
            }
        case FAQ_SET_CN_TY :
            return {
                ...state,
                faqCnTy: action.payload
            }
        case FAQ_SET_CN_TYPE_OPTIONS :
            return {
                ...state,
                faqCnTyOptoins: action.payload
            }
        case FAQ_SET_CN_TITLE :
            return {
                ...state,
                faqCnTitle : action.payload
            }
        case FAQ_SET_CURRENT_PAGE:
            return {
                ...state,
                faqCurrentPage: action.payload
            }
        case FAQ_SWITCH_ADD_MODAL_FLAG :
            return {
                ...state,
                faqAddModalFlag: !state.faqAddModalFlag
            }
        case FAQ_SWITCH_TMP_VIEW_MODAL_FLAG :
            return {
                ...state,
                faqTmpViewModalFlag: !state.faqTmpViewModalFlag
            }
        case FAQ_SWITCH_VIEW_MODAL_FLAG :
            return {
                ...state,
                faqViewModalFlag: !state.faqViewModalFlag
            }
        case SWITCH_REFRESH_FLAG :
            return {
                ...state,
                refreshFlag: !state.refreshFlag
            }
        case OTO_SET_MODAL_MODE :
            return {
                ...state,
                otoModalMode: action.payload
            }
        case OTO_SET_SEARCH_PARAM:
            return {
                ...state,
                otoSearchParam: [action.payload]
            }
        case OTO_SET_CURRENT_PAGE:
            return {
                ...state,
                otoCurrentPage: action.payload
            }
        case OTO_SET_CN_TITLE:
            return {
                ...state,
                otoCnTitle: action.payload
            }
        case OTO_SET_CN_TY:
            return {
                ...state,
                otoCnTy: action.payload
            }
        case OTO_SET_REPLY_YN:
            return {
                ...state,
                otoReplyYn: action.payload
            }
        case OTO_SET_REG_DTM:
            return {
                ...state,
                otoRegDtm: action.payload
            }
        case OTO_SWITCH_ADD_MODAL_FLAG :
            return {
                ...state,
                otoAddModalFlag: !state.otoAddModalFlag
            }
        case OTO_SWITCH_TMP_VIEW_MODAL_FLAG :
            return {
                ...state,
                otoTmpViewModalFlag: !state.otoTmpViewModalFlag
            }
        case OTO_SWITCH_VIEW_MODAL_FLAG :
            return {
                ...state,
                otoViewModalFlag: !state.otoViewModalFlag
            }
        case OTO_SWITCH_REFRESH_FLAG:
            return{
                ...state,
                otoRefreshFlag: !state.otoRefreshFlag
            }
        default:
            return state;
    }
}

export default Board;