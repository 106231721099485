// @ts-nocheck
import Slider from "react-rangeslider";
import "react-rangeslider/lib/index.css";
interface Props {
    min?: number;
    max?: number;
    value: number;
    orientation?: "horizontal" | "vertical";
    step?: number;
    labels?: { [key: number]: string };
    onChange;
    disabled?: boolean
    className?: string;
}
export function CustomSlider (props: Props): JSX.Element {
    const { min=0, max=100, value, orientation="horizontal", labels, onChange, disabled=false, className=""
        , step=1
        } = props;
    return (
        <Slider
            min={min}
            max={max}
            value={value}
            orientation={orientation}
            step={step}
            labels={labels}
            onChange={onChange}
            className={className.concat(" ", disabled ? "disabled" : "")}
        />
    );
}
