// @ts-nocheck
import {useIntl} from "react-intl";
import {useDispatch, useSelector} from "react-redux";
import React, {useEffect, useState} from "react";
import {
    getDataList,
    getDatasetByWorkflow,
    getModuleByWorkflow, getModuleByWorkflowSuccess,
    updateSearchParam
} from "../../../../../store/dataset/details/action";
import {DataSttusCd, SearchFilter} from "../../../../../store/dataset/details";
import {DropdownItem, Input, Label} from "reactstrap";
import {GetMessageIntl} from "../../../../../util/message";
import {cloneDeep} from "lodash";
import {ApplySearchButton} from "./search-header";
import {DatasetDetailsService} from "../../../../../apis/api/DatasetDetailsService";

export function DataStatusFilter(): JSX.Element{
    const intl = useIntl();
    const dispatch = useDispatch();
    const {
        Details: {
            workflow: {id: wrkflowId},
            searchParam: {
                view,
                data,
                // module
            }
        }
    } = useSelector(state => state);
    const [sttusList, setSttusList] = useState([]);
    const choiceCdList = data.sttus;
    const choiceLabelList = view.sttus;
    const [module, setModule] = useState([]);
    useEffect(() => {
        if (!!!module.length) {
            // dispatch(getModuleByWorkflow(wrkflowId))
            async function getModule(){
                const param = {wrkflowId};
                const result = await DatasetDetailsService.getModules(param);
                const data = result.data;
                if (data.success){
                    setModule(data.data)
                }
            }

            getModule();
        } else if (module.length > 0 && sttusList.length === 0){
            let list = module.reduce((acc, m, index) => {
                if (m.moduleTy === "01") {
                    acc.push({label: [m.moduleNm, GetMessageIntl(intl, "details.standby")].join(' ')
                        , value: {code: DataSttusCd.STANDBY, moduleId: null}, index: `${index}1`})
                    acc.push({label: [m.moduleNm, GetMessageIntl(intl, "details.working")].join(' ')
                        , value: {code: DataSttusCd.WORKING, moduleId: null}, index: `${index}2`})
                    acc.push({label: [m.moduleNm, GetMessageIntl(intl, "details.modify")].join(' ')
                        , value: {code: DataSttusCd.MODIFY, moduleId: null}, index: `${index}3`})
                } else if (m.moduleTy === "02") {
                    // let exReviewModuleId = module.filter(mItem => mItem.ord === m.ord -1 && mItem.ord !== 0).length > 0
                    //                                     ? module.filter(mItem => mItem.ord = m.ord -1)[0].moduleId
                    //                                     : null;
                    // console.log('[exReviewModuleId]', m.moduleNm, m.ord, exReviewModuleId)
                    acc.push({label: [m.moduleNm, GetMessageIntl(intl, "details.standby")].join(' ')
                        , value: {code: DataSttusCd.REVIEW_STANDBY, moduleId: m.moduleId}, index: `${index}1`})
                    acc.push({label: [m.moduleNm, GetMessageIntl(intl, "details.working")].join(' ')
                        , value: {code: DataSttusCd.REVIEW_WORKING, moduleId: m.moduleId}, index: `${index}2`})
                    acc.push({label: [m.moduleNm, GetMessageIntl(intl, "details.reworking")].join(' ')
                        , value: {code: DataSttusCd.REVIEW_MODIFY, moduleId: m.moduleId}, index: `${index}3`})
                }
                return acc;
            }, [])
            list.push({label: [GetMessageIntl(intl, "details.close"), GetMessageIntl(intl, "details.done")].join(' ')
                , value: {code:DataSttusCd.DONE, moduleId: null}, index: `9997`})
            list.push({label: [GetMessageIntl(intl, "details.close"), GetMessageIntl(intl, "details.disable")].join(' ')
                , value: {code:DataSttusCd.DISABLE, moduleId: null}, index: `9998`})
            list.push({label: [GetMessageIntl(intl, "details.hold"), GetMessageIntl(intl, "details.reviewRequired")].join(' ')
                , value: {code:DataSttusCd.REVIEW_REQUIRED, moduleId: null}, index: `9999`});

            list.sort(function(a, b){return a.index - b.index});
            setSttusList(list);
        }
    }, [module])

    const onCdChange = (target, label) => {
        const checked = target.checked;
        const value = target.value;
        let newValues = cloneDeep(choiceCdList);
        let newLabels = cloneDeep(choiceLabelList);
        const valueSttus = sttusList.filter(i => i.index === value)[0];
        const value_ = checked ? newValues.concat(valueSttus.value) : newValues.filter(v => JSON.stringify(v)!=JSON.stringify(valueSttus.value));
        const label_ = checked ? newLabels.concat({label: label, index: valueSttus.index}) : newLabels.filter(v => v.index != valueSttus.index);
        label_.sort(function (a, b) {
            return a.index - b.index
        })
        dispatch(updateSearchParam(SearchFilter.DATA_STATUS, value_, label_))
    }

    return (
        <div className={"filter-container"}>
            <div className={"d-flex align-items-center justify-content-between"}>
                <DropdownItem header>{GetMessageIntl(intl, 'details.dataStatus')}</DropdownItem>
                <ApplySearchButton />
            </div>
            <DropdownItem divider/>
            <div className={"d-flex flex-column align-items-center gap-2"}>
                {sttusList.map(
                    (item: any, idx: number): JSX.Element => {
                        const { label, value, index } = item;
                        return (
                            <DropdownItem  key={`data-sttus-filter-${idx}`} toggle={false}>
                                <div className="form-check form-check-coco">
                                    <Input className="form-check-input" type="checkbox"
                                           id={`filter-dataset-${idx}`}
                                           value={index}
                                           defaultChecked={choiceCdList.some(c => JSON.stringify(c) === JSON.stringify(value))}
                                           onChange={(event) => {
                                               onCdChange(event.target, label)
                                           }}
                                    />
                                    <Label className="form-check-label" htmlFor={`filter-dataset-${idx}`}>
                                        {label}
                                    </Label>
                                </div>
                            </DropdownItem>
                        )
                    }
                )}
            </div>
        </div>
    )
}
