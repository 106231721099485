import {callApi} from '../utils/AxiosUtil';


export const WorkerService= {
    /**
     *
     * @param param
     * @returns {Promise<axios.AxiosResponse<any>>}
     */
    getProjectWorkerList: (param) => callApi('/service/worker/list', param),

    /**
     *
     * @param param
     * @returns {Promise<axios.AxiosResponse<any>>}
     */
    getTaskNmList : (param) => callApi('/service/worker/taskNmList', param),
}