import {useIntl} from "react-intl";
import {useDispatch, useSelector} from "react-redux";
import React, {useEffect, useState} from "react";
import {useForm, useFormContext} from "react-hook-form";
import {Card, CardBody, CardHeader, Col, Input, Label, Row} from "reactstrap";
import {GetMessage, GetMessageIntl} from "../../../../../../../../../util/message";
import ItemAttribute from "./ItemAttribute";
import {
    workflow_label_testConfig_clearHasErrorFile,
    workflow_label_testConfig_setFileComment,
    workflow_label_testConfig_setFileRejectReason
} from "../../../../../../../../../store/manage/project/workflow/label/TestConfig/action";
import {CommonService as CmmnService} from "../../../../../../../../../apis/api/CmmnService";

function ClassAndAttribute(prop) {
    const intl = useIntl();
    const {changeAttribute, changeLabel} = prop;
    const [labelId, setLabelId] = useState(1);
    const [attributes, setAttributes] = useState([]);
    const [reviewRejectReasonList, setReviewRejectReasonList] = useState([]);
    const {selectedFile, shapeConfig, fileLabels, fileAttributes} = useSelector((state) => ({
        selectedFile: state.WorkflowLabelTestConfig.selectedFile,
        shapeConfig: state.ManageWorkflowLabel.shapeConfig,
        fileLabels: state.WorkflowLabelTestConfig.fileLabels,
        fileAttributes: state.WorkflowLabelTestConfig.fileAttributes
    }));

    const {
        control: controlTest
        , register: registerTest
        , setValue: setValueTest
        , getValues: getValuesTest
        , reset: resetTest
        , resetField: resetFieldTest
        , clearErrors: clearErrorsTest
        , watch: watchTest
        , formState: {errors: errorsTest}
        , trigger: triggerTest
    } = useFormContext();

    useEffect(() => {
        const getReviewRejectReasonList = async () => {
            return await CmmnService.getReviewRejectReasonList();
        }
        getReviewRejectReasonList().then(res => {
            setReviewRejectReasonList(res.data.data);
        });
    }, [])

    useEffect(() => {
        if (selectedFile.fileName != '') {
            const labelId = fileLabels[selectedFile.fileName]
            const fileAttributesItem = fileAttributes.filter(item => item.fileName === selectedFile.fileName ? item : null);
            if (fileAttributesItem.length > 0) {
                if (Object.keys(fileAttributesItem[0].values).length == 0) {
                    // 세팅이 안되어 잇는 초기 상태
                    // console.log('기본값 : ' + labelId)
                } else {
                    // 무언가 값이 세팅 되어있는 상태
                    // console.log('불러온설정 : ' + labelId)
                }
                const attributesItem = shapeConfig.labels.filter(item => {
                    if (labelId === item.id) {
                        return item.attributes;
                    }
                })
                if (attributesItem.length > 0) {
                    setAttributes(attributesItem[0].attributes);
                }
                setLabelId(labelId)
            }
        }
    }, [selectedFile]);

    useEffect(() => {
        if (shapeConfig && shapeConfig.hasOwnProperty('labels')) {
            setAttributes(shapeConfig.labels.length > 0 ? shapeConfig.labels[0].attributes : []);
        }
    }, [shapeConfig]);

    const changeLabelInner = (label) => {
        setLabelId(label.id)
        setAttributes(label.attributes);
        changeLabel(label.id)
    }

    return (
        <>
            <Card className="shadow-none mb-0 pd-x-22">
                <CardHeader className="pd-y-16 pd-x-0 border-0 d-flex align-items-start justify-content-start tx-16 lh-18 text-coco-gray-700 fw-500">
                    {GetMessageIntl(intl, "workflow.testConfig.questionConfig")} <span className={"fw-normal text-coco-gray-400 ml-8 wd-80p"}>{selectedFile.fileName ? `(${selectedFile.fileName})` : null}</span>
                    <div className="d-flex align-items-center text-coco-gray-400"></div>
                </CardHeader>
                <CardBody className="p-0">
                    <div>
                        {
                            selectedFile.isClick ?
                                <img
                                    style={img}
                                    className="img-fluid rounded d-block"
                                    src={selectedFile.imagePreView}
                                />
                                :
                                <div className={"d-flex align-items-center justify-content-center"}
                                     style={{height: "calc(100vh - 510px)"}}>
                                    <span className="text-center text-coco-gray-200 tx-16 fw-500 lh-18" dangerouslySetInnerHTML={{__html: GetMessageIntl(intl, "workflow.testConfig.fileSelectRequired")}}></span>
                                </div>
                        }
                    </div>
                    {/*<SimpleBar className="table-responsive" style={{height: "calc(100vh - 430px)"}}>*/}
                    {selectedFile.fileName !== '' ?
                        <div className={"mt-16 text-coco-gray-700"}>
                            <div className={"tx-16 fw-semibold"}>
                                {GetMessageIntl(intl, "workflow.testConfig.classAndAttr")}
                            </div>
                            <div className={"tx-16 fw-500 mt-16 mb-8"}>
                                {GetMessageIntl(intl, "ui.U00114")}
                            </div>
                            <ChipLabelSelector
                                // labels={prop.shapeConfig.labels}
                                labels={shapeConfig && shapeConfig.labels ? shapeConfig.labels : []}
                                value={labelId}
                                onChange={changeLabelInner}
                                className='cvat-objects-sidebar-state-item-label-selector'
                            />
                        </div>
                        : ""
                    }
                    {
                        selectedFile.fileName !== '' ? attributes.map((attribute) => {
                                const selectItemValue = fileAttributes.filter(item => item.fileName === selectedFile.fileName)
                                const attrValue = selectItemValue.length > 0 ? selectItemValue[0].values : attribute.inputType == 'text' ? "" : attribute.inputType == 'select' ? [] : attribute.inputType == 'checkbox' ? {} : null;

                                return (
                                    <ItemAttribute
                                        key={attribute.id}
                                        readonly={false}
                                        attrValue={attrValue[attribute.id]}
                                        attrInputType={attribute.inputType}
                                        attrName={attribute.name}
                                        attrID={attribute.id}
                                        attrValues={attribute.values ? attribute.values: []}
                                        attrRequired={attribute.required}
                                        attrRegexp={attribute.regexp}
                                        attrRegexpSample={attribute.regexpSample}
                                        changeAttribute={changeAttribute}
                                        control={controlTest}
                                        register={registerTest}
                                        errors={errorsTest}
                                    />
                                )
                            })
                            : null
                    }
                    {selectedFile.fileName !== '' ?
                        <CommentInput
                            control={controlTest}
                            register={registerTest}
                            errors={errorsTest}
                            reviewRejectReasonList={reviewRejectReasonList}
                        /> : null
                    }
                    {/*</SimpleBar>*/}
                </CardBody>
            </Card>
        </>
    )
}


export function ChipLabelSelector(props) {
    const {
        labels, value, onChange, onEnterPress, ...rest
    } = props;
    const inputChange = (newValue) => {
        const [label] = labels.filter((_label) => _label.id === +newValue);
        if (label) {
            onChange(label);
        } else {
            throw new Error(`Label with id ${newValue} was not found within the list`);
        }
    }
    return (
        <div className={"d-flex flex-wrap"}>
            {
                labels.map(element => (
                    <div className="form-check form-radio-coco form-check-btn" key={"label".concat(element.id)}>
                        <Input type="radio" className="form-check-input btn-check"
                               id={`coco-class-${element.id}`}
                               name="object-popover-class" required
                               value={element.id}
                               onChange={(e) => inputChange(parseInt(e.target.value))}
                               checked={element.id === value}
                        />
                        <Label className="form-check-label btn btn-coco-outline-1 pd-x-12 pd-y-3"
                               htmlFor={`coco-class-${element.id}`}
                        >
                            {element.name}
                        </Label>
                    </div>
                ))
            }
        </div>
    )
}


export function CommentInput(props) {
    const {reviewRejectReasonList} = props;
    const {selectedFile, fileLabels, fileAttributes, hasErrorFiles} = useSelector((state) => ({
        selectedFile: state.WorkflowLabelTestConfig.selectedFile,
        fileLabels: state.WorkflowLabelTestConfig.fileLabels,
        fileAttributes: state.WorkflowLabelTestConfig.fileAttributes,
        hasErrorFiles: state.WorkflowLabelTestConfig.hasErrorFiles
    }));
    const dispatch = useDispatch();
    const intl = useIntl();
    const {
        control: controlTest
        , register: registerTest
        , setValue: setValueTest
        , getValues: getValuesTest
        , clearErrors: clearErrorsTest
        , reset: resetTest
        , trigger: triggerTest
        , resetField: resetFieldTest
        , watch: watchTest
        , formState: {errors: errorsTest},
    } = useFormContext();
    const [comment, setComment] = useState("");
    const [reviewRejectReason, setReviewRejectReason] = useState([]);

    const findItem = fileAttributes.find(item => item.fileName === selectedFile.fileName ? item : "")
    const commentValue = findItem != undefined ? findItem.comment : "";

    useEffect(() => {
        const findItem = fileAttributes.find(item => item.fileName === selectedFile.fileName ? item : "")
        const rejectReason = findItem != undefined ? findItem.rejectReason : [];
        setReviewRejectReason(rejectReason);
        setValueTest('rejectReason', rejectReason);
    }, []);

    useEffect(() => {
        const findItem = fileAttributes.find(item => item.fileName === selectedFile.fileName ? item : "")
        const rejectReason = findItem != undefined ? findItem.rejectReason : [];
        resetFieldTest('rejectReason');
        setReviewRejectReason(rejectReason);
        setValueTest('rejectReason', rejectReason);

        let thisError = hasErrorFiles.filter((errorFile) => errorFile === selectedFile.fileName).length;
        if (thisError > 0) {
            // 에러가 있었으면 해당 항목에 에러가 있엇는지 확인을 해야 함.(border처리)
            triggerTest('rejectReason');
        }
    }, [fileAttributes, selectedFile]);

    const changeFileRejectReason = async (fileName, reason, checked) => {
        dispatch(workflow_label_testConfig_setFileRejectReason(fileName, reason, checked));

        let thisError = hasErrorFiles.filter((errorFile) => errorFile === fileName).length;
        if (thisError > 0) {
            const result = await errorCheckTrigger();
            if (result) {
                // 오류 내용 삭제.
                dispatch(workflow_label_testConfig_clearHasErrorFile(fileName));
            }
        }
    }

    const errorCheckTrigger = async () => {
        //상단에 위치한 항목 제외한 설정 값
        let arrBasicProp = ['questionOrderTy', 'title', 'fileCount', 'questionCount', 'passCount', 'desc'];
        let arrAllProp = Object.keys(getValuesTest());
        let arrChkProp = arrAllProp.filter(x => !arrBasicProp.includes(x));

        const result = await triggerTest(arrChkProp);
        return result;
    }

    return (
        <>
            <div className="coco-object-popover-attribute pd-b-24">
                <Row className="m-0 bg-coco-gray-50 rounded-7">
                    <Col>
                        <Row className="p-3">
                            <div className="p-0 text-coco-gray-700 tx-16 fw-semibold mb-16">
                                {GetMessage("workflow.testConfig.registCorrect")}<span className={"text-danger"}>*</span><span className={"text-coco-gray-400"}>({GetMessage("common.inputCheckbox")})</span>
                            </div>
                            {errorsTest.rejectReason && <p className="validation-error-message">{GetMessageIntl(intl, "validation.required")}</p>}
                            {
                                reviewRejectReasonList.map(item => {
                                    const checked = reviewRejectReason.includes(item.cmmnDetailCd);
                                    const rejectReasonId = 'rejectReason' + item.cmmnDetailCd;
                                    return (
                                        <Row key={item.cmmnDetailCd}>
                                            <div className="form-check form-check-coco mb-12">
                                                <input className="form-check-input" type="checkbox" id={rejectReasonId} value={item.cmmnDetailCd}
                                                       {...registerTest("rejectReason", {
                                                           required: true
                                                       })}
                                                       onChange={(event) => {
                                                           const newValue = event.target.value;
                                                           let newReviewRejectReason = [...reviewRejectReason];
                                                           if (newValue === '01') {
                                                               if (event.target.checked) {
                                                                   newReviewRejectReason = ['01'];
                                                               } else {
                                                                   newReviewRejectReason = [];
                                                               }
                                                           } else {
                                                               if (event.target.checked) {
                                                                   if (newReviewRejectReason.includes('01')) {
                                                                       newReviewRejectReason = newReviewRejectReason.filter(item => item !== '01');
                                                                   }
                                                                   if (!newReviewRejectReason.includes(newValue)) {
                                                                       newReviewRejectReason = [...newReviewRejectReason, newValue];
                                                                   }
                                                               } else {
                                                                   newReviewRejectReason = newReviewRejectReason.filter(item => item !== newValue);
                                                               }
                                                           }
                                                           clearErrorsTest('rejectReason');
                                                           changeFileRejectReason(selectedFile.fileName, newReviewRejectReason, event.target.checked);
                                                           setReviewRejectReason(newReviewRejectReason);
                                                       }}
                                                       checked={checked}
                                                />
                                                <Label className="form-check-label mb-0" htmlFor={rejectReasonId}>
                                                    {item.cmmnDetailCdNm}
                                                </Label>
                                            </div>
                                        </Row>
                                    )
                                })

                            }
                        </Row>
                        <Row className="p-3 pt-0 row">
                            <div className="p-0 text-coco-gray-700 tx-16 fw-semibold mb-16">
                                {GetMessage("workflow.testConfig.commentRequired")} <span className={"text-coco-gray-400"}>({GetMessage("common.options")})</span>
                            </div>
                            {/*<Input
                                defaultValue=""
                                value={commentValue ?? ''}
                                onChange={(event) => {
                                    const newValue = event.target.value;
                                    setComment(newValue)
                                    dispatch(workflow_label_testConfig_setFileComment(selectedFile.fileName, newValue))
                                }}
                                autoComplete={"off"}
                            />*/}
                            <textarea placeholder={GetMessage("common.enterInfo")} className="form-control" row={"5"}
                                      onChange={(event) => {
                                          const newValue = event.target.value;
                                          setComment(newValue)
                                          dispatch(workflow_label_testConfig_setFileComment(selectedFile.fileName, newValue))
                                      }}
                                      value={commentValue ?? ''}
                                      autoComplete={"off"}
                                      maxLength={100}/>
                        </Row>
                    </Col>
                </Row>

            </div>
        </>
    );
}

const img = {
    display: 'block',
    width: '500px',
    height: '250px'
};


const noPadding = {
    paddingLeft: "0px",
    paddingRight: "0px"
}


export default ClassAndAttribute;