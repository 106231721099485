import React, {useEffect, useState} from 'react'
import {BrowserRouter as Router, Switch, Route, Redirect} from "react-router-dom";
import {
    authProtectedRoutes,
    authProtectedTwoColumnRoutes,
    publicRoutes,
    authProtectedCustomRoutes,
    authProtectedSettingRoutes,
    testRoutes
} from "./allRoutes.js"
import Page404 from "../pages/error/page404.tsx"
import VerticalLayout from "../components/layout";
import PrivateRoute from "./PrivateRoute";
import AuthDepthLayout from "../components/layout/AuthDepthLayout";
import {bellData_bellMessageReceive} from "../store/bell/action";
import SettingLayout from "../components/layout/AuthSettingLayout";
import SockJS from 'sockjs-client';
import Stomp from 'stompjs';
import {useDispatch} from "react-redux";
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Index = () => {
    const availablePublicRoutesPaths = publicRoutes.map((r) => r.path);
    const availableAuthRoutesPath = authProtectedRoutes.map((r) => r.path);
    const availableAuthTwoColumnRoutesPaths = authProtectedTwoColumnRoutes.map((r) => r.path);
    const availableAuthCustomRoutesPath = authProtectedCustomRoutes.map((r) => r.path);
    const availableAuthSettingRoutesPath = authProtectedSettingRoutes.map((r) => r.path);
    const availableTestRoutesPath = testRoutes.map((r) => r.path);

    let dispatch = useDispatch();

    const ToastComponent = ()=>{
        return (
            <div id="notificationToast" className="toast show toast-border-success mg-t-55 wd-423px" role="alert" aria-live="assertive" aria-atomic="true">
                <div className="toast-body bg-soft-success">
                    <div className="d-flex align-items-start">
                        <div className="flex-shrink-0 me-2">
                            <i className="ri-notification-line"></i>
                        </div>
                        <div className="flex-grow-1 tx-14 text-coco-gray-700 d-flex align-items-start">
                            <div className="notificationNm fw-semibold"></div>
                            <div className="px-1">-</div>
                            <div className="notificationMsg"></div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    const toastOption = {
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: false,
        theme:"colored"
    }

    useEffect(() => {
        // 서버에서 웹소켓이 안된다.. 
        // 해결할때까진 막아놓을것

        let useWebsocket = false;
        if(useWebsocket) {
            let coconutUserInfoJson = sessionStorage.getItem('coconutUserInfo') ?? '{}'
            if ('{}' !== coconutUserInfoJson) {
                const sessionUserInfo = JSON.parse(coconutUserInfoJson)

                // let accessToken = sessionUserInfo.accessToken;
                let userSeq = sessionUserInfo.userSeq;
                // 프로젝트 관리자 계열에서만 알림 메세지를 사용
                // 대표관리자, 권한관리자, 일반관리자
                if (sessionUserInfo.userRole === '10'
                    || sessionUserInfo.userRole === '11'
                    || sessionUserInfo.userRole === '12') {
                    let socket = new SockJS('/ws');
                    const stompClient = Stomp.over(socket);
                    stompClient.heartbeat.outgoing = 20000;
                    stompClient.heartbeat.incoming = 10000;
                    //stompClient.debug = null;
                    //let headers = {Authorization: 'Bearer ' + accessToken};
                    let headers = {};
                    stompClient.connect(headers, function (cp){
                        console.debug('Connect Complete', cp);
                        //let subscribe = process.env.REACT_APP_API_HOST + '/ws/response/bellAlarm/response/' + userSeq;
                        let subscribe = '/ws/response/bellAlarm/response/' + userSeq;
                        stompClient.subscribe(subscribe, (message) => {
                            console.debug('subscribe', message)
                            let data = JSON.parse(message.body);
                            dispatch(bellData_bellMessageReceive(data))
                            if (data.message.msgCn) {
                                if (data.message.msgTy === '02') {
                                    toast.success(data.message.msgCn, toastOption)
                                } else if (data.message.msgTy === '04') {
                                    toast.error(data.message.msgCn, toastOption)
                                } else if (data.message.msgTy === 'INFO') {
                                    toast.info(data.message.msgCn, toastOption)
                                }
                            }
                        }, headers)
                        console.debug('Connect Complete');
                    }, function(error){
                        console.debug('error detail: ' + error);
                    });

                    console.debug(stompClient)
                }
            }
        }
    }, [])

    return (
        <React.Fragment>
            <Router>
                <Switch>
                    <Route path={availablePublicRoutesPaths}>
                        <Switch>
                            {publicRoutes.map((route, idx) => (
                                <Route
                                    path={route.path}
                                    component={route.component}
                                    key={route.key}
                                    exact={route.exact}
                                />
                            ))}
                        </Switch>
                    </Route>
                    <Route path={availableTestRoutesPath}>
                        <Switch>
                            {testRoutes.map((route, idx) => (
                                <Route
                                    path={route.path}
                                    component={route.component}
                                    key={route.key}
                                    exact={route.exact}
                                />
                            ))}
                        </Switch>
                    </Route>
                    <Route path={availableAuthSettingRoutesPath}>
                        <SettingLayout>
                            <Switch>
                                {authProtectedSettingRoutes.map((route, idx) => (
                                    <PrivateRoute
                                        path={route.path}
                                        component={route.component}
                                        key={route.key}
                                        exact={route.exact}
                                    />
                                ))}
                            </Switch>
                        </SettingLayout>
                    </Route>

                    <Route path={availableAuthCustomRoutesPath}>
                        <Switch>
                            {authProtectedCustomRoutes.map((route, idx) => (
                                <PrivateRoute
                                    path={route.path}
                                    component={route.component}
                                    key={route.key}
                                    exact={route.exact}
                                />
                            ))}
                        </Switch>
                    </Route>

                    <Route path={availableAuthTwoColumnRoutesPaths}>
                        <AuthDepthLayout>
                            <Switch>
                                {authProtectedTwoColumnRoutes.map((route, idx) => (
                                    <PrivateRoute
                                        path={route.path}
                                        component={route.component}
                                        key={route.key}
                                        exact={route.exact}
                                    />
                                ))}
                            </Switch>
                        </AuthDepthLayout>
                    </Route>
                    <Route path={availableAuthRoutesPath}>
                        <VerticalLayout>
                            <Switch>
                                {authProtectedRoutes.map((route, idx) => (
                                    <PrivateRoute
                                        path={route.path}
                                        component={route.component}
                                        key={route.key}
                                        exact={route.exact}
                                    />
                                ))}
                            </Switch>
                        </VerticalLayout>
                    </Route>

                    {/*<Route path={cvatRoutesPath}>*/}
                    {/*    <CvatApp/>*/}
                    {/*</Route>*/}

                    <Route path="/" exact={true} key="home" component={Home}/>
                    <Route path="/page404" exact={true} key="Page404_1" component={Page404}/>
                    <Route path="*" exact={false} key="Page404" component={Page404}/>

                </Switch>
            </Router>
            <ToastContainer/>
        </React.Fragment>
    );
}
const Home = () => {
    // const coconutUserInfoJson = sessionStorage.getItem('coconutUserInfo') ?? '{}'
    // if ('{}' === coconutUserInfoJson) {
    //     console.debug('index.js : ', coconutUserInfoJson);
    //     return <Redirect to="/login"/>
    // }
    // const userinfo = JSON.parse(coconutUserInfoJson)
    // if (userinfo.userRole === '00') {
    //     return <Redirect to="/admin/user/manager"/>
    // } else if (userinfo.userRole.startsWith("01")) {
    //     return <Redirect to="/platform/board/notice"/>
    // } else if (userinfo.userRole.startsWith("1")) {
    //     return <Redirect to="/manager/project"/>
    // } else if (userinfo.userRole.startsWith("2")) {
    //     return <Redirect to="/user/project/notice"/>
    // }
    return <Redirect to="/login"/>
}


function Container(props) {
    return (
        <>
            {
                (props.isMainAppMenu) ?
                    (<VerticalLayout>
                        <PrivateRoute
                            path={props.path}
                            component={props.component}
                            key={props.path}
                            exact={props.exact}
                        />
                    </VerticalLayout>)
                    : <PrivateRoute
                        path={props.path}
                        component={props.component}
                        key={props.path}
                        exact={props.exact}
                    />
            }
        </>
    );
}


export default Index;
