import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {
    setJoinTestModuleParam,
    userBoard_initSearchParam,
    userBoard_setSearchParam
} from "../../../store/user/board/action";
import button from "bootstrap/js/src/button";
import {Card, CardBody, CardHeader, Col, Container, Input, Row} from "reactstrap";
import BreadCrumb from "../../../components/common/BreadCrumb";
import {GetMessage} from "../../../util/message";
import ReactPaginate from "react-paginate";
import {TaskService} from "../../../apis/api/TaskService";
import Select from "react-select";
import PageLeftInfomation from 'components/common/PageLeftInfomation';
import {defaultSelectStyle} from "../../../components/constants/select";
import {useHistory} from "react-router";
import {getModule} from "../../../store/label/action";
import {AgGridReact} from "ag-grid-react";
import {AgRowStyle} from "../../../components/common/AgGridCustomStyle";
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import {alertError, confirm} from "../../../util/AlertUtil";
import {useIntl} from "react-intl";
import {assignCheck} from 'store/user/board/saga';
import TaskTestModal from "./TaskTestModal";
import {setTaskRefreshFlag, setTaskTestModalFlag} from "../../../store/user/task/action";
import {EmptyListContentView} from "../../../components/features/EmptyListContentView";

let mount = 1;
function UserProjectJoined() {
    const dispatch = useDispatch();
    const [moduleNm, setModuleNm] = useState("");
    const [regDtm, setRegDtm] = useState("");
    const [sttusCd, setSttusCd] = useState();
    const [highlightWords, setHighlightWords] = useState([]);

    const {targetModuleTy, targetModuleId, targetModuleNm, targetCanvasDataTy, targetTestTryCount} = useSelector((state) => ({
        targetModuleTy : state.UserBoard.moduleTy,
        targetModuleId : state.UserBoard.moduleId,
        targetModuleNm : state.UserBoard.moduleNm,
        targetCanvasDataTy: state.UserBoard.canvasDataTy,
        targetTestTryCount: state.UserBoard.testTryCount
    }));

    useEffect(()=>{
        mount++;
        if(mount === 2){
            mount++;
        }
        dispatch(userBoard_initSearchParam())
        dispatch(setTaskTestModalFlag(false));
        dispatch(setTaskRefreshFlag(false));
    }, [])

    // 기존 State 초기화 -> Param 재저장
    const handleSearch = () => {
        dispatch(userBoard_initSearchParam());
        mount++;
        setHighlightWords([]);
        let copy = [];
        let obj = {
            MODULE_NM : undefined,
            STTUS_CD : undefined
        };
        if (moduleNm !== "") {
            obj.MODULE_NM = moduleNm;
        }
        if(sttusCd !== ""){
            obj.MODULE_TY = sttusCd;
        }
        setHighlightWords(Object.values(obj))
        dispatch(userBoard_setSearchParam(obj));
    }

    // Input, Select 등 State로 조정
    const handleReset = () => {
        dispatch(userBoard_initSearchParam());
        setHighlightWords([]);
        mount++;
        setModuleNm("")
        setRegDtm("")
    }

    const searchOption = [
        { label: "유형 전체", value: "" },
        { label: "라벨링", value: "01" },
        { label: "검수", value: "02" }
    ]

    return (
        <>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title={GetMessage("menu.LJIP0000")} pageTitle="Laflow"/>
                    <Row>
                        <Card className="shadow-none mg-b-16">
                            <CardHeader className="border-0 rounded p-0">
                                <Row className="g-3">
                                    <Col lg={3}>
                                        <div className="position-relative form-search">
                                            <Input
                                                type="text"
                                                className="form-control"
                                                placeholder={GetMessage("task.searchPlaceHolder")}
                                                value={moduleNm}
                                                onChange={(e) => {
                                                    setModuleNm(e.target.value)
                                                }}
                                            />{" "}
                                            <span className="ri-search-line search-widget-icon"></span>
                                        </div>
                                    </Col>
                                    <Col lg={2}>
                                        <Select
                                            name="searchParam"
                                            className="mb-0"
                                            onChange={(e) => {
                                                setSttusCd(e.value);
                                            }}
                                            styles={defaultSelectStyle}
                                            defaultValue={searchOption[0]}
                                            options={searchOption}
                                        />
                                    </Col>
                                    <Col xl={1} className={"align-self-center"} style={{maxWidth:"75px"}}>
                                        <div className={"d-flex"}>
                                            <button className="btn btn-coco-outline-1"
                                                    onClick={(e) => {handleSearch()}}>
                                                {GetMessage("common.apply")}
                                            </button>
                                        </div>
                                    </Col>
                                    <Col className={"pd-0 align-self-center"}>
                                        <div className={"d-flex"}>
                                            <i  className="ri-refresh-line text-coco-gray-200 fs-24 align-self-auto"
                                                onClick={()=>{handleReset()}
                                                }></i>
                                        </div>
                                    </Col>
                                </Row>
                            </CardHeader>
                        </Card>
                        <DataList highlightWords={highlightWords}/>
                    </Row>
                </Container>
                <TaskTestModal moduleId={targetModuleId} moduleTy={targetModuleTy} canvasDataTy={targetCanvasDataTy} moduleNm={targetModuleNm} taskYn={false} testTryCount={targetTestTryCount}/>
            </div>
        </>
    );
}

function DataList(props) {
    const intl = useIntl();
    const history = useHistory();
    const dispatch = useDispatch();

    const {searchParam, refreshFlag} = useSelector((state) => ({
        searchParam : state.UserBoard.searchParam,
        refreshFlag : state.UserTask.refreshFlag
    }));

    const [datasets, setDatasets] = useState([]);
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [moduleNm, setModuleNm] = useState("");
    const [moduleId, setModuleId] = useState("");
    const [moduleTy, setModuleTy] = useState("");

    function calStartIdx(page) {
        return (page - 1) * perPage;
    }

    const fetchData = async (page, size = perPage) => {
        setLoading(true);

        const res = await TaskService.getJoinedTaskList({
            'start': calStartIdx(page),
            'length': size,
            'orderParam': [],
            'searchParam': searchParam
        })
        if (res.data.data) {
            // console.log(res.data.data.data)
            setDatasets(res.data.data.data);
            setTotalRows(res.data.data.recordsTotal);
            console.log(res.data.data)
        }
        setLoading(false);
    };

    useEffect(() => {
        if(refreshFlag){
            setCurrentPage(1);
            fetchData(1);
        }
    }, [refreshFlag]);

    useEffect(() => {
        if(mount > 2){
            setCurrentPage(1);
            fetchData(1);
            mount = 2;
        }
    }, [searchParam]);

    const handlePageChange = page => {
        let pageIdx = page.selected + 1;
        fetchData(pageIdx);
        setCurrentPage(pageIdx);
    };


    const gotoCanvas = (module, frame) => {
        dispatch(getModule({module, frame}));
        history.push({
            pathname:'/label/image'
        });
    }

    const goTaskBtn = async (props) => {
        const data = props.data;
        const taskNm = JSON.parse(props.data.moduleConfig).baseConfig.taskNm;
        // 해당 태스크에 testConfig 있는지
        // 있으면 testConfig에 joinCn.joinTest.pass에서 통과 여부 확인
        //      통과 안했으면 test 진행
        // 없으면 작업시작
        //console.log(data);
        let testPass = true;
        const moduleConfig = data.moduleConfig?JSON.parse(data.moduleConfig):null;
        const joinCn = data.joinCn?JSON.parse(data.joinCn): null;
        let testTryCount = 0;
        if(moduleConfig && moduleConfig.testConfig && moduleConfig.testConfig.questions.length>0){
            if(joinCn == null){
                testPass = false;
            }

            console.log('joinCn',joinCn);
            // 테스트 진행한게 없거나 테스트 통과안된 경우
            if(joinCn && (!joinCn.joinTest || (joinCn.joinTest && !joinCn.joinTest.pass))){
                // 미니 테스트를 합시다.
                testPass = false;
                if(joinCn.joinTest && joinCn.joinTest.testTryCount){
                    testTryCount = joinCn.joinTest.testTryCount;
                }
            }
        }

        if(!testPass){
            dispatch(setJoinTestModuleParam(data.moduleTy, data.moduleId, taskNm, data.canvasDataTy, testTryCount));
            // 미니테스트 진행
            dispatch(setTaskTestModalFlag(true));
        }else{
            // Row Hover시에 Task ID 받아옴 -> 해당 모듈로 참여
            const module = {
                moduleId: props.data.moduleId,
                name: taskNm,
                type: props.data.moduleTy,
                canvasDataTy: props.data.canvasDataTy
            }
            try{
                const doAfter = {
                    prePage: history.location.pathname,
                    do: {
                        "11": gotoCanvas,
                        "12": gotoCanvas,
                        "13": (callback) => {
                            confirm("joined.checkAssignTitleConfirm"
                                , "joined.checkAssignConfirm"
                                , async function () {
                                    const target = await callback();
                                    if (target != null){
                                        gotoCanvas(module, target);
                                    }
                                }
                                , function () {
                                    if (history) {
                                        history.goBack();
                                    }
                                }
                                , null
                                , intl
                            );
                        },
                        "04": () => {
                            alertError('joined.noDataAlert'
                                ,'joined.noDataAlertText', ()=>{},null ,null
                                ,intl)
                        },
                        "05": () => {
                            alertError('joined.noProgressAlert'
                                ,'joined.noDataAlertText' ,null ,null ,null
                                ,intl)
                        },
                    }
                }
                await assignCheck(module, {intl}, doAfter);
            } catch (e) {
                alertError(
                    ""
                    ,""
                    ,null
                    ,null
                    ,e
                    ,intl
                );
            }
        }
    }

    const [columnDefs] = useState(
        [
        {
            field: 'moduleNm',
            headerName: GetMessage("joined.task"),
            cellClass : "firstCell btnCell",
            // tooltipField: 'moduleNm',
            // tooltipComponent: CustomTestTooltipComponent,
            flex:"36%",
            cellRenderer: params => {
                const moduleConfig = JSON.parse(params.data.moduleConfig)
                let taskNm = moduleConfig.hasOwnProperty('baseConfig') ? moduleConfig.baseConfig.taskNm : "!!태스크명을 설정하세요!!";
                return (
                    <div className={"d-flex justify-content-between"}>
                        {
                            taskNm.length > 34 ?
                                // <CustomTooltip text={taskNm} width="267px">
                                    <span>{taskNm.substring(0, 34) + "..."}</span>
                                // </CustomTooltip>
                                :
                                <span>{taskNm}</span>
                        }
                        <button type="button"
                                style={{display: "none", height: "50%"}}
                                onClick={(e) => {
                                    goTaskBtn(params, e)
                                }} className="joinBtn btn btn-coco-xs-1 align-self-center">
                            {GetMessage("joined.joinTask")}
                        </button>
                    </div>
                )
            }
        },
        {
            field: 'moduleTy',
            headerName: GetMessage("joined.workTy"),
            flex:"8%",
            cellRenderer: params => {
                if(params.data.moduleTy === "01"){
                    return GetMessage("joined.labelling")
                }else if(params.data.moduleTy === "02"){
                    return GetMessage("joined.review")
                }
            }
        },
        {
            field: 'sttusCd',
            headerName: GetMessage("joined.sttus"),
            flex:"7%",
            cellRenderer: params => {
                let reStr = '';
                // TODO Badge 스타일 미결정
                switch(params.data.sttusCd){
                    case '00':
                        reStr = '최초'
                        break;
                    case '02':
                        reStr = '오픈'
                        break;
                    case '03':
                        reStr = '종료'
                        break;
                    case '04':
                        reStr = '중지'
                        break;
                }
                return(
                    <div>
                        <span className={"badge badge-soft-primary"}>{reStr}</span>
                    </div>
                )
            }
        },
        {
            field: 'reward',
            headerName: GetMessage("joined.point"),
            flex:"8%",
            cellRenderer: params=>{
                let reStr = '';
                let pointConfig = JSON.parse(params.data.moduleConfig).pointConfig;
                let pointPay = '';
                if(pointConfig){
                    if(pointConfig.pointPayTy == '00' || pointConfig.pointPayTy =='01'){
                        pointPay = pointConfig.payPoint;
                    }else {
                        if(pointConfig.instance){
                            let min = pointConfig.instance.reduce((previous, current) => {
                                return current.value < previous.value ? current : previous;
                            });

                            let max = pointConfig.instance.reduce((previous, current) => {
                                return current.value > previous.value ? current : previous;
                            });

                            if (min.value == max.value) {
                                pointPay = min.value;
                            } else {
                                pointPay = `${min.value}~${max.value}`;
                            }
                        }
                    }
                }
                return (
                    <div className={"d-flex justify-content-end"}>
                        {parseInt(pointPay).toLocaleString()}
                    </div>
                )
            }
        },
        {
            field: 'taskCount',
            headerName: GetMessage("joined.workCnt"),
            flex:"8%",
            cellRenderer: params => {
                return(
                    <div className={"d-flex justify-content-end"}>
                        {parseInt(params.data.taskCount).toLocaleString()}
                    </div>
                )
            }
        },
        {
            field: 'doneCount',
            headerName: GetMessage("joined.workedCnt"),
            flex:"8%",
            cellRenderer: params => {
                return(
                    <div className={"d-flex justify-content-end"}>
                        {parseInt(params.data.doneCount).toLocaleString()}
                    </div>
                )
            }
        },
        {
            field: 'thisMonthReward',
            headerName: GetMessage("joined.monthPoint"),
            flex:"8%",
            cellRenderer: params => {
                return(
                    <div className={"d-flex justify-content-end"}>
                        {parseInt(params.data.thisMonthReward).toLocaleString()}
                    </div>
                )
            }
        },
        {
            field: 'unPaymentsReward',
            cellClass : "lastCell",
            headerName: GetMessage("joined.unPaymentsReward"),
            flex:"9%",
            cellRenderer: params => {
                return(
                    <div className={"d-flex justify-content-end"}>
                        {parseInt(params.data.unPaymentsReward).toLocaleString()}
                    </div>
                )
            }
        },
        {
            field: 'paymentsReward',
            cellClass : "lastCell",
            headerName: GetMessage("joined.donePoint"),
            flex:"9%",
            cellRenderer: params => {
                return(
                    <div className={"d-flex justify-content-end"}>
                        {parseInt(params.data.paymentsReward).toLocaleString()}
                    </div>
                )
            }
        }
    ]
    );

    const [defaultColDef] = useState({
        autoHeight: true,
        cellClass: 'cell-wrap-text',
    })

    let count = Math.ceil(Number((totalRows / perPage)));
    // 0인경우 console에 warning출력
    if(count === 0){
        count = 1;
    }
    return (
        <>
            <Card className="shadow-none">
                <CardBody className="px-0 pb-0">
                    <div className="ag-theme-alpine" style={{ width: '100%' }}>
                        <AgGridReact
                            rowData={datasets}
                            rowStyle={AgRowStyle}
                            defaultColDef={defaultColDef}
                            columnDefs={columnDefs}
                            pagination={true}
                            paginationPageSize={perPage}
                            suppressPaginationPanel={true}
                            detailRowAutoHeight={true}
                            suppressRowTransform={true}
                            tooltipShowDelay={0}
                            tooltipHideDelay={2000}
                            gridOptions={{
                                suppressCellFocus : true
                            }}
                            domLayout={'autoHeight'}
                        >
                        </AgGridReact>
                        {
                            datasets && datasets.length > 0 ?
                                <>
                                    <Row className='mx-0'>
                                        <Col className='d-flex justify-content-start align-items-center fs-16 text-coco-gray-700 fw-500' sm='6'>
                                            <PageLeftInfomation
                                                totalRows={totalRows}
                                                perPage ={perPage}
                                                currentPage={currentPage}
                                            />
                                        </Col>
                                        <Col className='d-flex justify-content-end align-items-center' sm='6'>
                                            <ReactPaginate
                                                previousLabel={'←'}
                                                nextLabel={'→'}
                                                onPageChange={handlePageChange}
                                                pageCount={count}
                                                forcePage={currentPage - 1}
                                                // breakLabel={'...'}
                                                // pageRangeDisplayed={2}
                                                // marginPagesDisplayed={2}
                                                activeClassName={'active'}
                                                pageClassName={'page-item'}
                                                nextLinkClassName={'page-link'}
                                                nextClassName={'page-item next'}
                                                previousClassName={'page-item prev'}
                                                previousLinkClassName={'page-link'}
                                                pageLinkClassName={'page-link'}
                                                breakClassName='page-item'
                                                breakLinkClassName='page-link'
                                                containerClassName={'pagination react-paginate pagination-custom pagination-sm justify-content-end align-items-center'}
                                            />
                                        </Col>
                                    </Row>
                                </>
                                :
                                <EmptyListContentView msgCode={`joined.emptyJoinedData`} />
                        }
                    </div>
                </CardBody>
            </Card>
        </>
    );
}

export default UserProjectJoined;
