import React, {useEffect, useRef, useState} from 'react';
import {GetMessage, GetMessageIntl} from "../util/message";
import loginMain from "../assets/images/loginMain.gif";
import loginLogo from "../assets/images/login_logo.png";
import AuthProcess from "../lib/authProcess";
import {
    Container,
    Input,
} from 'reactstrap';

import Inko from 'inko';
import {useIntl} from "react-intl";
import {useForm} from "react-hook-form";
import {ErrorMessage} from "@hookform/error-message";

function Login() {
    const intl = useIntl();
    const inko = new Inko();
    const fullYear: string = new Date().getFullYear().toString();
    const {control, register, setValue, formState: {errors}, handleSubmit} = useForm({criteriaMode: "all"});
    const [asdfvbnm, setAsdfvbnm] = useState("");
    const [zxcvvbnm, setZxcvvbnm] = useState("");
    const [rememberCheck, setRememberCheck] = useState(false);
    const [loginCheck, setLoginCheck] = useState(true);

    useEffect(() => {
        async function LoginCheckProcess(){
            const result = await AuthProcess.LoginCheck();
            setLoginCheck(result);
        }
        LoginCheckProcess();

        let authInputCoco = getCookie("authInputCoco");
        setAsdfvbnm(authInputCoco);
        setValue('asdfvbnm', authInputCoco); // react-hook-form의 data도 같이 해줘야 validation 넘어감.
        if (authInputCoco != '') {
            setRememberCheck(true);
        }
    }, []);

    const idInputFocusout = (e) => {
        let convertedId = inko.ko2en(e.target.value)
        setValue('asdfvbnm', convertedId);
        setAsdfvbnm(convertedId);
    }
    const handleKeyPress = (e: React.KeyboardEvent) => {
        if (e.key === 'Enter') {
            AuthProcess.LoginProcess(asdfvbnm, zxcvvbnm, rememberCheck, intl);
        }
    }

    const onSubmit = (data) => {
        AuthProcess.LoginProcess(asdfvbnm, zxcvvbnm, rememberCheck, intl)
    };

    const getCookie = (cookieName) => {
        cookieName = cookieName + '=';
        var cookieData = document.cookie;
        var start = cookieData.indexOf(cookieName);
        var cookieValue = '';
        if (start != -1) {
            start += cookieName.length;
            var end = cookieData.indexOf(';', start);
            if (end == -1) end = cookieData.length;
            cookieValue = cookieData.substring(start, end);
        }
        return unescape(cookieValue);
    }

    return (
        loginCheck?
            <></>
            :
            <>
                <div className="auth-page-wrapper">
                    <div className="auth-page-content bg-white">
                        <Container>
                            <div className="row justify-content-center align-items-center"
                                 style={{height: "calc(100vh - 60px)"}}>
                                <div className="col-md-8 col-lg-6 col-xl-5">
                                    <div className="text-center text-coco-primary-400">
                                        <div className="tx-24 fw-bold mb-16"><img src={loginLogo}/></div>
                                        <div className="tx-20 text-bazzi">{GetMessageIntl(intl,"login.laflowDesc")}</div>
                                    </div>
                                    <div className="text-center mt-32 mb-34">
                                        <div>
                                            {/*<a href="/" className="d-inline-block auth-logo">*/}
                                            <img src={loginMain} alt="" style={{width: "347px"}}/>
                                            {/*</a>*/}
                                        </div>
                                    </div>
                                    <form onSubmit={handleSubmit(onSubmit)}>
                                        <div className="card shadow-none mb-0">
                                            <div className="card-body p-4 pt-1">
                                                <div className="mt-4">
                                                    <div className="mb-3">
                                                        <label htmlFor="asdfvbnm" className="form-label text-coco-gray-700">
                                                            {GetMessageIntl(intl,"login.id")}
                                                        </label>
                                                        <div className={errors.asdfvbnm ? "validation-error" : ""}>
                                                            <input type="text" id="asdfvbnm"
                                                                   className="form-control"
                                                                   placeholder={GetMessageIntl(intl,"login.emailRequired")}
                                                                   {...register("asdfvbnm", {
                                                                       required: GetMessageIntl(intl,"validation.required"),
                                                                       pattern: {
                                                                           value: /(^[a-zA-Z0-9+-\_.]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$|administrator)/,
                                                                           message: GetMessageIntl(intl,"validation.contentNotVaild"),
                                                                       },
                                                                       onChange: (e) => setAsdfvbnm(e.target.value),
                                                                       onBlur: (e) => idInputFocusout(e)
                                                                   })}
                                                                   value={asdfvbnm}
                                                                   tabIndex={1}
                                                            />
                                                        </div>
                                                        <ErrorMessage
                                                            errors={errors}
                                                            name="asdfvbnm"
                                                            render={({messages}) => {
                                                                return messages
                                                                    ? Object.entries(messages).map(([type, message]) => (
                                                                        <p className={"validation-error-message"} key={type}>{message}</p>
                                                                    ))
                                                                    : null;
                                                            }}/>
                                                    </div>
                                                    <div className="mb-3">
                                                        {/*<div className="float-end">
                                                            <a href="#findUserPwdModal" className="text-coco-gray-400 tx-14"
                                                               data-toggle="modal" data-backdrop="static"
                                                               data-dismiss="modal" tabIndex={4}>
                                                                {GetMessage("login.passwordFind")}
                                                            </a>
                                                        </div>*/}
                                                        <label className="form-label text-coco-gray-700" htmlFor="zxcvvbnm">
                                                            {GetMessageIntl(intl,"login.password")}
                                                        </label>
                                                        <div className={errors.zxcvvbnm?"validation-error":""}>
                                                            <div className="position-relative auth-pass-inputgroup ">
                                                                <input type="password" id="zxcvvbnm"
                                                                       className="form-control key"
                                                                       {...register("zxcvvbnm", {
                                                                           required: GetMessageIntl(intl,"validation.required"),
                                                                           onChange: (e) => setZxcvvbnm(e.target.value)
                                                                       })}
                                                                       placeholder={GetMessageIntl(intl,"login.passwordRequired")}
                                                                       onKeyUp={handleKeyPress}
                                                                       tabIndex={2}/>
                                                            </div>
                                                        </div>
                                                        <ErrorMessage
                                                            errors={errors}
                                                            name="zxcvvbnm"
                                                            render={({ messages }) => {
                                                                return messages
                                                                    ? Object.entries(messages).map(([type, message]) => (
                                                                        <p className={"validation-error-message"} key={type}>{message}</p>
                                                                    ))
                                                                    : null;
                                                            }}/>
                                                    </div>
                                                    <div className="form-check form-check-coco">
                                                        <input className="form-check-input" type="checkbox" value="" onChange={(e)=>setRememberCheck(!rememberCheck)}
                                                               name="rememberCheck" id="rememberCheck" checked={rememberCheck}/>
                                                        <label className="form-check-label text-coco-gray-400"
                                                               htmlFor="rememberCheck">
                                                            {GetMessageIntl(intl,"login.remember")}
                                                        </label>
                                                    </div>

                                                    <div className="mt-4">
                                                        <button className="btn btn-coco-1 w-100" type="submit"
                                                                tabIndex={3}>
                                                            {GetMessageIntl(intl,"login.title")}
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>

                                    {/*<div className="text-center">
                                        <p className="mb-0 text-coco-gray-700">
                                            {GetMessage("login.noneUserInfo")}
                                            <a href="/join"
                                               className="text-coco-primary-400 fw-semibold ms-2 text-decoration-underline">
                                                {GetMessage("login.joinUserInfo")}
                                            </a></p>
                                    </div>*/}

                                </div>
                            </div>
                        </Container>
                    </div>

                    <footer className="footer">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="text-center">
                                        <p className="mb-0 text-coco-gray-400">&copy;Copyright {fullYear}. (주)비투엔 all rights
                                            reserved</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </footer>
                </div>
            </>
    );
}

export default Login;