import React, {useEffect, useMemo, useState} from 'react';
import {Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import SimpleBar from "simplebar-react";
import Select from "react-select";
import {defaultSelectStyle, noBorderSelectStyle} from "../../../../../../../../../components/constants/select";
import {useDispatch, useSelector} from "react-redux";
import LabelShapeAttrRows from "../../../label/LabelShapeAttrRow";
import {ReactSortable} from "react-sortablejs";
import LabelRegistDataFormRow from "./LabelRegistDataFormRow";
import {
    setManageWorkflowLabelActiveData,
    setManageWorkflowLabelInactiveData
} from "../../../../../../../../../store/manage/project/workflow/label/action";
import {GetMessageIntl} from "../../../../../../../../../util/message";
import {useIntl} from "react-intl";

function LabelRegistDataForm(props) {
    const intl = useIntl();
    const dispatch = useDispatch();
    const {activeData, inactiveData, selectedId, errorData, dataConfig} = useSelector((state) => ({
        activeData: state.ManageWorkflowLabel.activeData,
        inactiveData: state.ManageWorkflowLabel.inactiveData,
        selectedId: state.ManageWorkflowLabel.selectedId,
        errorData: state.ManageWorkflowLabel.errorData,
        dataConfig: state.ManageWorkflowLabel.dataConfig
    }));
    const [title, setTitle] = useState("");
    const [content, setContent] = useState("");

    const updateActiveDataById = (id, title, content) => {
        const updatedData = activeData.map((item) => item.id === id ? {...item, title : title, body: {...item.body, description : content}} : item)
        dispatch(setManageWorkflowLabelActiveData(updatedData))
    };

    const updateInactiveDataById = (id, title, content) => {
        const updatedData = inactiveData.map((item) => item.id === id ? {...item, title : title, body: {...item.body, description : content}} : item)
        dispatch(setManageWorkflowLabelInactiveData(updatedData))
    };


    useMemo(()=> {
        const totalData = [...activeData, ...inactiveData];
        const findData = totalData.find((target) => target.id === selectedId);
        if(findData){
            if(findData.useYn === "Y"){
                updateActiveDataById(selectedId, title, content)
            }else{
                updateInactiveDataById(selectedId, title, content)
            }
        }
    }, [title, content, errorData])

    useEffect(()=>{
        const totalData = [...activeData, ...inactiveData];
        const findData = totalData.find((target) => target.id === selectedId);
        if(totalData.length > 0){
            setTitle(findData.title);
            setContent(findData.body.description);
        }
    }, [selectedId])

    const addActiveDataById = (id, forms) => {
        const updatedData = activeData.map((item) => item.id === id ? {...item, body: { ...item.body, content: forms }} : item)
        dispatch(setManageWorkflowLabelActiveData(updatedData))
    }

    const addInactiveDataById = (id, forms) => {
        const updatedData = inactiveData.map((item) => item.id === id ? {...item, body: { ...item.body, content: forms }} : item)
        dispatch(setManageWorkflowLabelInactiveData(updatedData))
    }

    const addFormElement = () => {
        const bfData = dataConfig.find(item => item.id === selectedId);
        let newId = bfData.body.content.length > 0 ? Math.max(...bfData.body.content.map(item => item.id)) + 1 : 1;
        bfData.body.content.push(
            {
                id : newId,
                type : "",
                essentialYn: "Y",
                example: "",
                regexp: ""
            }
        )
        if(bfData){
            if(bfData.useYn === "Y"){
                addActiveDataById(selectedId, bfData.body.content)
            }else{
                addInactiveDataById(selectedId, bfData.body.content)
            }
        }
    }

    const setFormList = (items) => {
        const findData = dataConfig.find(item => item.id === selectedId);
        if(findData){
            if(findData.useYn === "Y"){
                addActiveDataById(selectedId, items)
            }else{
                addInactiveDataById(selectedId, items)
            }
        }
    }

    return (
        <Card className="shadow-none mb-0">
            <CardHeader className="border-0 d-flex align-items-center tx-16 lh-18 pb-0 text-coco-gray-700 fw-bold">
                <i className="mdi mdi-format-list-checks mr-12"></i>{GetMessageIntl(intl, "workflow.dataConfig.form")}
            </CardHeader>
            <CardBody className="p-0">
                <SimpleBar className="table-responsive" style={{height: "calc(100vh - 250px)"}}>
                    <Col style={{padding:'1rem 1rem 0 1rem'}}>
                        <Row className="mb-8">
                            <div className={"d-flex justify-content-start align-items-center"}>
                                <div className="text-coco-gray-700 fw-500 tx-16 wd-20p">
                                    {GetMessageIntl(intl, "workflow.dataConfig.title")}
                                </div>
                                <div className={"wd-80p"}>
                                    <div className={errorData.dataItems && errorData.dataItems.find((item)=> item.id === selectedId && item.error == 'title') ? "validation-error-bottom":""}>
                                        <input type="text" style={{border: '0px', borderRadius:'0', fontSize: '16px',color:'var(--coco-gray-400)!important'}} maxLength={50} className="form-control form-control-sm  fw-500 text-coco-gray-400-f pd-x-0-f" placeholder={GetMessageIntl(intl, "workflow.dataConfig.requestTitle")}
                                               value={title}
                                               onChange={(e)=>{setTitle(e.target.value)}}
                                        />
                                        {
                                            errorData.dataItems && errorData.dataItems.map((item) => {
                                                if(item.id === selectedId && item.error == 'title'){
                                                    return (
                                                        <p key={`error_title_${item.id}`} className="validation-error-message mb-0">{GetMessageIntl(intl, "validation.required")}</p>
                                                    )
                                                }
                                            })
                                        }
                                    </div>
                                </div>
                            </div>
                        </Row>
                        <Row className="mb-16" style={{borderBottom:"1px solid #CFD8DC", paddingBottom: "10px"}}>
                            <div className={"d-flex justify-content-start align-items-start pd-x-12 pd-y-6"}>
                                <div className="text-coco-gray-700 fw-500 tx-16 wd-20p">
                                    {GetMessageIntl(intl, "workflow.dataConfig.description")}
                                </div>
                                <div className="wd-80p">
                                    <div>
                                        <div className={errorData.dataItems && errorData.dataItems.find((item)=> item.id === selectedId && item.error == 'description') ? "validation-error":""}>
                                            <textarea className="form-control" rows="3" value={content}
                                                      placeholder={GetMessageIntl(intl,"workflow.dataConfig.contentPlaceholder")}
                                                onChange={(e) => {
                                                    setContent(e.target.value)
                                                }}
                                            />
                                        </div>
                                        {
                                            errorData.dataItems && errorData.dataItems.map((item) => {
                                                if(item.id === selectedId && item.error == 'description'){
                                                    return (
                                                        <p key={`error_title_${item.id}`} className="validation-error-message mb-0">{GetMessageIntl(intl, "validation.required")}</p>
                                                    )
                                                }
                                            })
                                        }
                                    </div>
                                </div>
                            </div>
                        </Row>
                        <Row style={{marginBottom : '10px'}}>
                            <div className={"d-flex justify-content-between align-items-center"}>
                                <div className={"text-coco-gray-700 fw-500 tx-16"}>
                                    {GetMessageIntl(intl, "workflow.dataConfig.field")}
                                </div>
                                <div>
                                    <i className="ri-add-circle-line me-1 text-pointer"
                                    onClick={addFormElement} />
                                </div>
                            </div>
                        </Row>
                        <ReactSortable list={dataConfig.find(item => item.id === selectedId)?.body.content || []} setList={setFormList} handle=".handle">
                            {
                                dataConfig.find(item => item.id === selectedId)?.body.content.map((formData, index)=>{
                                    const { type, essentialYn, example, regexp } = formData;
                                    const formId = formData.id;
                                    return (
                                        <LabelRegistDataFormRow
                                            key={`form_${formData.id}_${index}`}
                                            id={selectedId}
                                            formId={formId}
                                            type={type}
                                            essentialYn={essentialYn}
                                            example={example}
                                            regexp={regexp}
                                        />
                                    );
                                })
                            }
                        </ReactSortable>

                    </Col>
                </SimpleBar>
            </CardBody>
        </Card>
    );
}

export default LabelRegistDataForm;