import {callApi, callApiSync} from '../../apis/utils/AxiosUtil';

export const ModuleService={
    /**
     * 워크플로우 하위 모듈 목록
     * @param param{{wrkflowId:string}}
     * @returns {Promise<AxiosResponse<any>>}
     */
    getManageModuleList: (param) => callApi('/service/module/list', param),

    /**
     * 워크플로우 하위 데이터셋 모듈 목록
     * @param param{{wrkflowId:string}}
     * @returns {Promise<AxiosResponse<any>>}
     */
    getManageDatasetModuleList: (param) => callApi('/service/module/datasetList', param),

    /**
     * 모듈 정보 1개 조회
     * @param param{{moduleId:string}}
     * @returns {Promise<AxiosResponse<any>>}
     */
    getManageModuleItem: (param) => callApi('/service/module/item', param),


    /**
     * 프로젝트에 속한 모듈 목록
     * @param param{{prjctId:string}}
     * @returns {Promise<axios.AxiosResponse<any>>}
     */
    getModuleNameList: (param) => callApi('/service/module/moduleNameList', param),


    /**
     * 모듈 추가
     * @param param
     * @returns {Promise<AxiosResponse<any>>}
     */
    addModuleItem: (param) => callApi('/service/module/add', param),

    /**
     * 모듈 수정
     * @param param
     * @returns {Promise<AxiosResponse<any>>}
     */
    updateModuleItem: (param) => callApi('/service/module/update', param),

    /**
     * 모듈 수정(open 오픈)
     * @param param
     * @returns {Promise<AxiosResponse<any>>}
     */
    updateOpenModuleItem: (param) => callApi('/service/module/updateWithOpen', param),

    /**
     * 모듈 수정(첨부파일이 있는 경우)
     * @param param
     * @returns {Promise<AxiosResponse<any>>}
     */
    updateModuleItemWithFile: (param, option) => callApi('/service/module/updateWithFile', param, option),

    /**
     * 모듈 삭제
     * @param param{{moduleId:string}}
     * @returns {Promise<AxiosResponse<any>>}
     */
    deleteModuleItem: (param) => callApi('/service/module/delete', param),

    /**
     * 검수대상 라벨링 모듈 추가
     * @param param
     * @returns {Promise<AxiosResponse<any>>}
     */
    addReviewTargetModule: (param) => callApi('/service/module/addReviewTargetModule', param),

    /**
     * 모듈 사용 데이터셋 추가
     * @param param
     * @returns {Promise<AxiosResponse<any>>}
     */
    addLabelTargetModule: (param) => callApi('/service/module/addLabelTargetModule', param),

    /**
     * 모듈 사용 데이터셋 삭제
     * @param param
     * @returns {Promise<AxiosResponse<any>>}
     */
    deleteLabelTargetModule: (param) => callApi('/service/module/deleteLabelTargetModule', param),

    /**
     * 모듈별 모듈명 중복 체크 필요
     * @param {{prjctId:string, wrkflowNm:string}}
     * @returns {Promise<AxiosResponse<any>>}
     */
    isModuleNmDuplication: (param) => callApi('/service/module/dupCheck', param),


    /**
     * 라벨 태스트명 중복 체크 필요
     * @param param{{prjctId:string, moduleTy:string, taskNm :string}}
     * @returns {Promise<axios.AxiosResponse<any>>}
     */
    isTaskNmDuplication: (param) => callApi('/service/module/taskNmDupCheck', param),

    getReviewTargetLabelModuleItem : (param)=>callApi('/service/module/getReviewTargetLabelModuleItem', param),

    /**
     * 자료 등록 첨부 파일들 다시 가져오기
     * @param param{{moduleId:string,dataConfigTargetId:string}}
     * @returns {Promise<axios.AxiosResponse<any>>}
     */
    getManageLabelRegistDataFiles : (param) => callApi('/service/module/getManageLabelRegistDataFiles', param),
}

