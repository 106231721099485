import React, { memo } from 'react';
import { Handle, useReactFlow, useStoreApi, Position, useStore } from 'reactflow';
import {
    manage_project_workflow_module_setIsLabelOffcanvas
} from "../../../../../../store/manage/project/workflow/module/action";
import {useDispatch} from "react-redux";
import {Progress} from "reactstrap";
import {thisTargetValidConnection} from "./nodeCommonUtil";
import {GetMessageIntl} from "../../../../../../util/message";
import {useIntl} from "react-intl";
import tool_brush from "../../../../../../assets/images/task/tool-brush.png";
import tool_circle from "../../../../../../assets/images/task/tool-circle.png";
import tool_cuboid from "../../../../../../assets/images/task/tool-cuboid.png";
import tool_ellipse from "../../../../../../assets/images/task/tool-ellipse.png";
import tool_points from "../../../../../../assets/images/task/tool-points.png";
import tool_polyEllipse from "../../../../../../assets/images/task/tool-polyEllipse.png";
import tool_polygon from "../../../../../../assets/images/task/tool-polygon.png";
import tool_polyline from "../../../../../../assets/images/task/tool-polyline.png";
import tool_rectangle from "../../../../../../assets/images/task/tool-rectangle.png";
import tool_line from "../../../../../../assets/images/task/tool-line.png";
import CommonUtil from "../../../../../../util/CommonUtil";

const connectionNodeIdSelector = (state) => {
    return state.connectionNodeId;
}
const connectionHandleTypeSelector = (state) => {
    return state.connectionHandleType;
}
const nodesSelector = (state) => Array.from(state.nodeInternals.values());

export default function LabelNode({ id, data, isConnectable }) {
    const intl = useIntl();
    const dispatch = useDispatch();
    const nodes = useStore(nodesSelector);
    const connectionNodeId = useStore(connectionNodeIdSelector);
    const connectionHandleType = useStore(connectionHandleTypeSelector);
    let connectionNodeType = '';
    if(connectionNodeId){
        let connectionNode = nodes.find((item) => item.id === connectionNodeId);
        let parentNodeType = '';
        if(connectionNode.parentNode){
            parentNodeType = nodes.find((item) => item.id === connectionNode.parentNode).type+'_';
        }
        connectionNodeType = parentNodeType + connectionNode.type;
    }
    const isTarget = connectionNodeId && connectionNodeId !== id && thisTargetValidConnection('label', connectionNodeType)&& connectionHandleType == 'source';
    const targetHandleStyle = isTarget? { visibility: 'visible', zIndex: 10 } : {visibility: 'hidden', zIndex:1};

    let dataTy = '11';
    if(data && data.canvasDataTy){
        dataTy = data.canvasDataTy;
    }

    const moduleConfig = data&&data.moduleConfig?JSON.parse(data.moduleConfig): null;
    const shapeConfig = moduleConfig?moduleConfig.shapeConfig:null;
    let shapeArr = [];

    if(shapeConfig&&shapeConfig.labels){
        if(dataTy == '11'){
            shapeConfig.labels.forEach(label=>{
                shapeArr.push(label.type);
            });
            shapeArr = shapeArr.filter((shape,index) => shapeArr.indexOf(shape) === index);
        }else{
            shapeConfig.labels.forEach(label=>{
                shapeArr.push(label.attributes[0].inputType =='text'? 'text':'select');
            });
            shapeArr = shapeArr.filter((shape,index) => shapeArr.indexOf(shape) === index);
        }
    }
    const workerConfig = moduleConfig?moduleConfig.workerConfig:null;
    const pointConfig = moduleConfig?moduleConfig.pointConfig:null;
    const etcConfig = moduleConfig? moduleConfig.etcConfig:null;

    let pointPay = '';
    if(pointConfig){
        if(pointConfig.pointPayTy == '00' || pointConfig.pointPayTy =='01'){
            if(pointConfig.payPoint != undefined){
                pointPay = CommonUtil.numberFormatComma(pointConfig.payPoint);
            }
        }else {
            if(pointConfig.instance){
                let min = pointConfig.instance.reduce((previous, current) => {
                    return current.value < previous.value ? current : previous;
                });

                let max = pointConfig.instance.reduce((previous, current) => {
                    return current.value > previous.value ? current : previous;
                });

                if (min.value == max.value) {
                    pointPay = CommonUtil.numberFormatComma(min.value);
                } else {
                    pointPay = `${CommonUtil.numberFormatComma(min.value)}~${CommonUtil.numberFormatComma(max.value)}`;
                }
            }
        }
    }

    return (
        <>
            <div className="d-flex align-items-center mb-12">
                <div className="flex-shrink-0 avatar-42">
                    <div className="avatar-title fs-20 rounded-circle" style={{backgroundColor:'#DFF3EF', color:'#0BAD8F'}}>
                        <i className="ri-pen-nib-line lh-19"></i>
                    </div>
                </div>
                <div className="flex-grow-1 text-overflow ml-13">
                    <h6 className="text-coco-gray-200 tx-12 lh-16 mb-2">ACTIVITY</h6>
                    <span className="text-coco-gray-700 tx-16 lh-20 fw-semibold">{data?data.moduleNm:null}</span>
                </div>
            </div>
            <div className="bg-coco-gray-50 pd-10 node_info flex-shrink-0 mb-12">
                <div className="ht-20">
                    <label>{GetMessageIntl(intl,"common.openDt")}</label>
                    {/*2023.01.01*/}
                    {etcConfig && etcConfig.openDate? etcConfig.openDate: null }
                </div>
                <div className="ht-20 mt-6">
                    <label>{GetMessageIntl(intl,"common.worker")}</label>
                    {workerConfig?
                        workerConfig.standardTy == '00'?
                            GetMessageIntl(intl,"workflow.noLimit")
                            : workerConfig.limitTy.length>1?
                                GetMessageIntl(intl,"workflow.directMode").concat(`, ${GetMessageIntl(intl,"workflow.conditionMode")}`)
                                : workerConfig.limitTy.includes("00")?
                                    GetMessageIntl(intl,"workflow.directMode")
                                    : GetMessageIntl(intl,"workflow.conditionMode")
                        :null
                    }
                </div>
                <div className="ht-20 mt-6">
                    <label>{GetMessageIntl(intl,"common.point")}</label>
                    {pointPay}
                </div>
                <div className="ht-20 mt-6 d-flex align-items-start">
                    <label>{GetMessageIntl(intl,"common.tool")}</label>
                    <div className="d-flex align-items-center">
                        {
                            shapeArr.map((shape, index)=>{
                                if(index <6){
                                    return (
                                        <div key={shape.concat(index)} className={"me-1"}>
                                            {
                                                shape== 'brush'?<img src={tool_brush} style={{WebkitUserDrag: 'none'}} />
                                                    :shape== 'circle'?<img src={tool_circle} style={{WebkitUserDrag: 'none'}} />
                                                        :shape== 'cuboid'?<img src={tool_cuboid} style={{WebkitUserDrag: 'none'}} />
                                                            :shape== 'ellipse'?<img src={tool_ellipse} style={{WebkitUserDrag: 'none'}} />
                                                                :shape== 'points'?<img src={tool_points} style={{WebkitUserDrag: 'none'}} />
                                                                    :shape== 'polyEllipse'?<img src={tool_polyEllipse} style={{WebkitUserDrag: 'none'}} />
                                                                        :shape== 'polygon'?<img src={tool_polygon} style={{WebkitUserDrag: 'none'}} />
                                                                            :shape== 'polyline'?<img src={tool_polyline} style={{WebkitUserDrag: 'none'}} />
                                                                                :shape== 'rectangle'?<img src={tool_rectangle} style={{WebkitUserDrag: 'none'}} />
                                                                                    :shape== 'brush'?<img src={tool_brush} style={{WebkitUserDrag: 'none'}} />
                                                                                        :shape== 'line'?<img src={tool_line} style={{WebkitUserDrag: 'none'}} />
                                                                                        :shape== 'select'?
                                                                                            <div className="flex-shrink-0">
                                                                                                <div className="avatar-title rounded-circle pd-3"
                                                                                                     style={{backgroundColor: "#EDE0FF", color: "#9E53FF", height: "18px", width: "18px"}}>
                                                                                                    <i className="ri-node-tree tx-12"></i></div>
                                                                                            </div>
                                                                                            :shape== 'text'?
                                                                                                    <div className="flex-shrink-0">
                                                                                                        <div className="avatar-title rounded-circle pd-3"
                                                                                                             style={{backgroundColor: "#FFF4D7", color: "#FFB900", height: "18px", width: "18px"}}>
                                                                                                            <i className="mdi mdi-text-shadow tx-12"></i></div>
                                                                                                    </div>
                                                                                                :<img src={tool_rectangle} style={{WebkitUserDrag: 'none'}} />

                                            }
                                        </div>
                                    );
                                }
                            })
                        }
                        {shapeArr.length >6? <span className="text-coco-gray-400 tx-12">+{shapeArr.length - 6}</span>:null}
                    </div>
                </div>
            </div>
            <Handle
                className="targetHandle"
                /*onConnect={(params) => console.log('handle onConnect', params)}*/
                style={targetHandleStyle}
                position={Position.Top}
                id={'top'}
                type="target"
                isConnectable={isConnectable}
            />
            <Handle
                className="targetHandle"
                /*onConnect={(params) => console.log('handle onConnect', params)}*/
                style={targetHandleStyle}
                position={Position.Left}
                id={'left'}
                type="target"
                isConnectable={isConnectable}
            />
            <Handle
                className="targetHandle"
                /*onConnect={(params) => console.log('handle onConnect', params)}*/
                style={targetHandleStyle}
                position={Position.Bottom}
                id={'bottom'}
                type="target"
                isConnectable={isConnectable}
            />
            <Handle
                className="targetHandle"
                /*onConnect={(params) => console.log('handle onConnect', params)}*/
                style={targetHandleStyle}
                position={Position.Right}
                id={'right'}
                type="target"
                isConnectable={isConnectable}
            />
        </>
    );
}