// Layout Type 
export const CHANGE_DEPTH_LAYOUT = "CHANGE_DEPTH_LAYOUT";
export const CHANGE_DEPTH_SIDEBAR_THEME = "CHANGE_DEPTH_SIDEBAR_THEME";
export const CHANGE_DEPTH_LAYOUT_MODE = "CHANGE_DEPTH_LAYOUT_MODE";
export const CHANGE_DEPTH_LAYOUT_WIDTH = "CHANGE_DEPTH_LAYOUT_WIDTH";
export const CHANGE_DEPTH_LAYOUT_POSITION = "CHANGE_DEPTH_LAYOUT_POSITION";
export const CHANGE_DEPTH_TOPBAR_THEME = "CHANGE_DEPTH_TOPBAR_THEME";
export const CHANGE_DEPTH_SIDEBAR_SIZE_TYPE = "CHANGE_DEPTH_SIDEBAR_SIZE_TYPE";
export const CHANGE_DEPTH_SIDEBAR_VIEW = "CHANGE_DEPTH_SIDEBAR_VIEW";
export const CHANGE_DEPTH_SIDEBAR_IMAGE_TYPE = "CHANGE_DEPTH_SIDEBAR_IMAGE_TYPE";
export const RESET_DEPTH_VALUE = "RESET_DEPTH_VALUE";