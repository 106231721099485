import React, { memo } from 'react';
import {Handle, useReactFlow, useStoreApi, Position, useStore} from 'reactflow';
import {GetMessage} from "../../../../../../util/message";
export default function SubUnabledNode({ id, data, isConnectable }) {
    const sourceHandleStyle = {visibility: 'hidden', zIndex:1};

    const total = data? data.col01  : 0;
    const managerTotal = data? (data.col04 + data.col02):0 ;
    const per02 = data ? (data.col02 / managerTotal * 100).toFixed(1) : 0.0;

    return (
        <>
            <div className="d-flex align-items-center justify-content-between mb-12 tx-16 fw-500">
                <div>
                    {GetMessage("workflow.unabled")}
                </div>
                <div>
                    {data ? data.col02.toLocaleString() : 0}<span className={"text-coco-primary-400"}> ({per02}%)</span>
                </div>
            </div>
            <Handle
                type="source"
                style={sourceHandleStyle}
                position={Position.Right}
                id={'right'}
                isConnectable={isConnectable}
            />
            <Handle
                type="source"
                style={sourceHandleStyle}
                position={Position.Left}
                id={'left'}
                isConnectable={isConnectable}
            />
        </>
    );
}