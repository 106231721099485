// @ts-nocheck
import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {CommonService} from "../../../../../../../../apis/api/CmmnService";
import {getAnnotationsReasons} from "../../../../../../../../store/review/action";
import {OBJECT_REVIEW_DETAILS} from "../../classNameConsts";
import {GetMessage} from "../../../../../../../../util/message";
import {ObjectReviewContent} from "./object-review-content";
import ObjectReviewFooterContainer from "../../../containers/standard-workspace/objects-side-bar/object-review-footer";

interface Props {
    instData: any[];
    clientID: number;
    sortedStatesID: number[];
    onOpenCanvasContextMenu(e): void;
}
export function ObjectReviewDetails (props) {
    const dispatch = useDispatch();
    const { clientID, rejectRsn, instData, choiceCdList, reject, sortedStatesID, onOpenCanvasContextMenu } = props;

    useEffect(() => {
        const getRjctRsnCd = async () => {
            const reuslt = await CommonService.getReviewRejectReasonList();
            const data = reuslt.data;
            dispatch(getAnnotationsReasons(data.data));
        }
        if (rejectRsn.length === 0) {
            getRjctRsnCd();
        }
    }, [])

    const indexPrefix = ['①', '②', '③', '④', '⑤', '⑥', '⑦', '⑧'];

    return (
        <div className={[OBJECT_REVIEW_DETAILS, "review-details"].join(" ")}>
            <div className={'title'}>
                {GetMessage('label.modifyGuide')}
            </div>
            <div className={'content'}>
                {rejectRsn.filter(rsn => choiceCdList.includes(rsn.cmmnDetailCd))
                            .map((rsn, index) => {
                                return (
                                    <ObjectReviewContent key={index}
                                                         clientID={clientID}
                                                         index={index}
                                                         indexPrefix={indexPrefix[index]}
                                                         rsn={rsn}
                                    />
                                )})
                }
            </div>
            <ObjectReviewFooterContainer
                instData={instData}
                clientID={clientID}
                disabled={reject === null}
                sortedStatesID={sortedStatesID}
                onOpenCanvasContextMenu={onOpenCanvasContextMenu}
            />
        </div>
    );
}
