// @ts-nocheck
import {
    ACTIVATE_LABEL,
    ACTIVATE_OBJECT,
    ADD_Z_LAYER,
    CHANGE_ANNOTATIONS_FILTERS,
    CHANGE_FRAME,
    CHANGE_FRAME_FAILED,
    CHANGE_FRAME_SUCCESS,
    CHANGE_GROUP_COLOR,
    CHANGE_WORKSPACE,
    CHECK_VALIDATION,
    CHECK_VALIDATION_SUCCESS,
    CLOSE_MODULE,
    CLOSE_MODULE_FAILED,
    CLOSE_MODULE_SUCCESS,
    COLLAPSE_OBJECT_ITEMS,
    COLLAPSE_SIDEBAR,
    COLLECT_STATISTICS,
    COLLECT_STATISTICS_FAILED,
    COLLECT_STATISTICS_SUCCESS,
    CONFIRM_CANVAS_READY,
    COPY_SHAPE,
    COPY_SHAPE_ACTION,
    CREATE_ANNOTATIONS,
    CREATE_ANNOTATIONS_FAILED,
    CREATE_ANNOTATIONS_SUCCESS,
    DRAG_CANVAS,
    EDIT_SHAPE,
    FETCH_ANNOTATIONS,
    FETCH_ANNOTATIONS_SUCCESS,
    GET_AUTO_LABEL_ANNOTATION,
    GET_AUTO_LABEL_ANNOTATION_FAILED,
    GET_AUTO_LABEL_ANNOTATION_SUCCESS,
    GET_DATA_FAILED,
    GET_MODULE,
    GET_MODULE_FAILED,
    GET_MODULE_SUCCESS,
    GROUP_OBJECTS,
    INTERACT_WITH_CANVAS,
    LOAD_PREV_FILE_OBJECTS,
    LOAD_PREV_FILE_OBJECTS_FAILED,
    LOAD_PREV_FILE_OBJECTS_SUCCESS,
    MERGE_ANNOTATIONS,
    MERGE_ANNOTATIONS_FAILED,
    MERGE_ANNOTATIONS_SUCCESS,
    MERGE_OBJECTS,
    PASTE_SHAPE,
    PASTE_SHAPE_ACTION,
    READY_OBJECT,
    REDO_ACTION,
    REDO_ACTION_FAILED,
    REDO_ACTION_SUCCESS,
    REDRAW_SHAPE,
    RELAX_OBJECT,
    REMEMBER_OBJECT, REMOVE_ANNOTATIONS, REMOVE_ANNOTATIONS_FAILED, REMOVE_ANNOTATIONS_SUCCESS,
    REMOVE_OBJECT,
    REMOVE_OBJECT_FAILED,
    REMOVE_OBJECT_SUCCESS,
    REPEAT_DRAW_SHAPE, REPEAT_DRAW_SHAPE_ACTION,
    RESET_CANVAS,
    ROTATE_FRAME,
    SAVE_ANNOTATIONS,
    SAVE_ANNOTATIONS_FAILED,
    SAVE_ANNOTATIONS_SUCCESS,
    SAVE_LOGS,
    SAVE_LOGS_FAILED,
    SAVE_LOGS_SUCCESS,
    SELECT_ISSUE_POSITION,
    SET_FORCE_EXIT_ANNOTATION_PAGE_FLAG,
    SHAPE_DRAWN,
    SKIP_DATA,
    SKIP_DATA_FAILED,
    SKIP_DATA_SUCCESS,
    SPLIT_ANNOTATIONS,
    SPLIT_ANNOTATIONS_FAILED,
    SPLIT_ANNOTATIONS_SUCCESS,
    SPLIT_TRACK,
    SUBMIT_ANNOTATIONS,
    SUBMIT_ANNOTATIONS_FAILED,
    SUBMIT_ANNOTATIONS_SUCCESS,
    SWITCH_NAVIGATION_BLOCKED,
    SWITCH_PROPAGATE_VISIBILITY,
    SWITCH_SHORTCUTS,
    SWITCH_Z_LAYER,
    UNDO_ACTION,
    UNDO_ACTION_FAILED,
    UNDO_ACTION_SUCCESS,
    UPDATE_ANNOTATIONS,
    UPDATE_ANNOTATIONS_FAILED,
    UPDATE_ANNOTATIONS_SUCCESS,
    UPDATE_BRUSH_TOOLS_CONFIG,
    UPDATE_CANVAS_CONTEXT_MENU,
    UPDATE_CANVAS_CONTEXT_MENU_POSITION,
    UPDATE_CANVAS_EDIT_IMAGE_POPOVER,
    UPDATE_CANVAS_SKIP_POPOVER,
    UPDATE_CANVAS_SKIP_RSN,
    UPDATE_SIDEBAR_TAB,
    ZOOM_CANVAS,
    SET_CANVAS_DATA_TY, SWITCH_PLAY, UPDATE_DURATION, UPDATE_INTERNAL_PLAYER,
} from "./actionType"
import {ContextMenuType, ObjectType, OpenCVTool, Rotation, sessionFrame, sessionModule, ShapeType, Workspace} from ".";
import {CuboidDrawingMethod, RectDrawingMethod} from "pages/user/label/annotation/image/work/canvas/typescript/canvasModel";
import {getRootStore} from "../index";
import {LogType} from "pages/user/label/annotation/image/cvat-logger";
import {receiveAnnotationsParameters} from "./saga";
import {MLModel} from "pages/user/label/annotation/image/cvat-core-wrapper";

/* MODULE */
export const getModule = ({ module, frame }: {
    module: sessionModule,
    frame: sessionFrame
}) => ({
    type: GET_MODULE,
    payload: { module, frame }
});
export const getModuleSuccess = (data) => {
    const {
        // openTime,
        module,
        labelType,
        dataType,
        // issues,
        labels,
        states,
        datasetId,
        fileSeq,
        // frame,
        // frameNumber,
        // frameFilen,
        // relatedFil,
        frameData,
        // colors,
        // filters,
        countLimit,
        minZ,
        maxZ,
    } = data;
    return {
        type: GET_MODULE_SUCCESS,
        payload: {
            // openTime,
            module,
            labelType,
            dataType,
            // issues,
            labels,
            states,
            datasetId,
            fileSeq,
            // frameNumber,
            // frameFilename,
            // relatedFiles,
            frameData,
            // colors,
            // filters,
            countLimit,
            minZ,
            maxZ
        }
    }}
export const getModuleFailed = e => ({
    type: GET_MODULE_FAILED,
    payload: e
});
export const closeModule = () => {
    return {
        type: CLOSE_MODULE,
        payload: {}
    }
}
export const closeModuleSuccess = () => ({
    type: CLOSE_MODULE_SUCCESS,
    payload: {}
})
export const closeModuleFailed = () => ({
    type: CLOSE_MODULE_FAILED,
    payload: {}
})
export const changeFrame = (module, frame: sessionFrame) => ({
    type: CHANGE_FRAME,
    payload: { module, frame }
});
export const changeFrameSuccess = ({frame, module, states, minZ, maxZ}) => ({
    type: CHANGE_FRAME_SUCCESS,
    payload: {frame, module, states, minZ, maxZ}
});
export const changeFrameFailed = frame => ({
    type: CHANGE_FRAME_FAILED,
    payload: frame
});
export const confirmCanvasReady = () => ({
    type: CONFIRM_CANVAS_READY,
    payload: {}
});
export const getDataFailed = (error: any) => ({
    type: GET_DATA_FAILED,
    payload: error
})

export const setCanvasDataTyLable = (canvasDataTy: String) => ({
    type: SET_CANVAS_DATA_TY,
    payload: canvasDataTy
})
/**
 * Canvas
 */
export const resetCanvas = () => ({
    type: RESET_CANVAS,
    payload: {},
})
export const switchShortcuts = (enabled: boolean) => ({
    type: SWITCH_SHORTCUTS,
    payload: {visible: enabled}
})
/**
 * shape
 * // used to reproduce the latest drawing (in case of tags just creating) by using N
 */
export const rememberObject = (createParams: {
    activeObjectType?: ObjectType;
    activeLabelID?: number;
    activeShapeType?: ShapeType;
    activeNumOfPoints?: number;
    activeRectDrawingMethod?: RectDrawingMethod;
    activeCuboidDrawingMethod?: CuboidDrawingMethod;
}) => ({
    type: REMEMBER_OBJECT,
    payload: createParams,
});
export const shapeDrawn = () => ({
    type: SHAPE_DRAWN,
    payload: {}
})
export const selectIssuePosition = (enabled: boolean) => ({
    type: SELECT_ISSUE_POSITION,
    payload: {
        enabled,
    },
})
export const mergeObjects = (enabled: boolean) => ({
    type: MERGE_OBJECTS,
    payload: {
        enabled,
    },
})

export const groupObjects = (enabled: boolean) => ({
    type: GROUP_OBJECTS,
    payload: {
        enabled,
    },
})

export const splitTrack = (enabled: boolean) => ({
    type: SPLIT_TRACK,
    payload: {
        enabled,
    },
})
export const readyObject = (
    readyStateID: number | null,
    readyElementID: number | null,
    readyAttributeID: number | null
) => ({
    type: READY_OBJECT,
    payload: {
        readyStateID,
        readyElementID,
        readyAttributeID,
    }
})
export const relaxObject = () => ({
    type: RELAX_OBJECT,
    payload: {}
})
export const  activateObject = (
    activatedStateID: number | null,
    activatedElementID: number | null,
    activatedAttributeID: number | null
) => ({
    type: ACTIVATE_OBJECT,
    payload: {
        activatedStateID,
        activatedElementID,
        activatedAttributeID,
    }
})
export const activateLabel = ( activatedLabelID: number | null ) => ({
    type: ACTIVATE_LABEL,
    payload: {activatedLabelID}
})
export const removeObject = (objectState: any, force: boolean) => ({
    type: REMOVE_OBJECT,
    payload: {
        objectState,
        force
    }
})
export const removeObjectSuccess = (objectState: any, history: any, updateStates: any[]) => ({
    type: REMOVE_OBJECT_SUCCESS,
    payload: {
        objectState,
        history,
        updateStates
    }
})
export const removeObjectFailed = (error) => ({
    type: REMOVE_OBJECT_FAILED,
    payload: {
        error
    }
})
export const editShape = (enabled: boolean) => ({
    type: EDIT_SHAPE,
    payload: {
        enabled,
    },
})
export const redrawShape = () => ({
    type: REDRAW_SHAPE,
    payload: {}
})
export const repeatDrawShapeAction = () => ({
    type: REPEAT_DRAW_SHAPE_ACTION,
    payload: {}
})
export const repeatDrawShape = (activeControl) => ({
    type: REPEAT_DRAW_SHAPE,
    payload: {activeControl}
})
export const copyShape = (objectState) => ({
    type: COPY_SHAPE,
    payload:{objectState}
})
export const pasteShapeAction = () => ({
    type: PASTE_SHAPE_ACTION,
    payload:{}
})
export const pasteShape = (activeControl) => ({
    type: PASTE_SHAPE,
    payload:{activeControl}
})
export const undoAction = (module: any, frame: number) => ({
    type: UNDO_ACTION,
    payload: { module, frame }
})
export const undoActionSuccess = (history: any, states: any, minZ: number, maxZ:number, frameData: any) => ({
    type: UNDO_ACTION_SUCCESS,
    payload: {
        history,
        states,
        minZ,
        maxZ,
        frameData,
    }
})
export const undoActionFailed = (error: any) => ({
    type: UNDO_ACTION_FAILED,
    payload: { error }
})
export const redoAction = (module: any, frame: number) => ({
    type: REDO_ACTION,
    payload: { module, frame }
})
export const redoActionSuccess = (history: any, states: any, minZ: number, maxZ:number, frameData: any) => ({
    type: REDO_ACTION_SUCCESS,
    payload: {
        history,
        states,
        minZ,
        maxZ,
        frameData,
    }
})
export const redoActionFailed = (error: any) => ({
    type: REDO_ACTION_FAILED,
    payload: { error }
})
export const collapseObjectItems = (states: any[], collapsed: boolean) => ({
    type: COLLAPSE_OBJECT_ITEMS,
    payload: {
        states,
        collapsed
    }
})
export const loadPrevFileObjects = (module, frame) => ({
    type: LOAD_PREV_FILE_OBJECTS,
    payload: {module, frame}
})
export const loadPrevFileObjectsSuccecss = (states, history) => ({
    type: LOAD_PREV_FILE_OBJECTS_SUCCESS,
    payload: {states, history}
})
export const loadPrevFileObjectsFailed = (e) => ({
    type: LOAD_PREV_FILE_OBJECTS_FAILED,
    payload: e
})
/**
 * statistics
 */
export const collectStatistics = (sessionInstance) => ({
    type: COLLECT_STATISTICS,
    payload: sessionInstance
});
export const collectStatisticsSuccess = (data) => ({
    type: COLLECT_STATISTICS_SUCCESS,
    payload: { data }
});
export const collectStatisticsFailed = (error) => ({
    type: COLLECT_STATISTICS_FAILED,
    payload: error
});
/**
 * annotations
 */
export const updateAnnotations = (statesToUpdate: any[], force?: boolean) => {
    return ({
            type: UPDATE_ANNOTATIONS,
            payload: {statesToUpdate, force}
        })
}
//     ({
//     type: UPDATE_ANNOTATIONS,
//     payload: statesToUpdate
// });
export const updateAnnotationsSuccess = (
    states,
    history,
    minZ,
    maxZ,
) => ({
    type: UPDATE_ANNOTATIONS_SUCCESS,
    payload: {
        states,
        history,
        minZ,
        maxZ,}
});
export const updateAnnotationsFailed = (error) => ({
    type: UPDATE_ANNOTATIONS_FAILED,
    payload: error
});
export const changeAnnotationsFilters = (filters: any[]) =>( {
    type: CHANGE_ANNOTATIONS_FILTERS,
    payload: { filters },
})

export const updateCanvasContextMenu = (
    visible: boolean,
    left: number,
    top: number,
    pointID: number | null = null,
    type?: ContextMenuType,
) => ({
    type: UPDATE_CANVAS_CONTEXT_MENU,
    payload: {
        visible,
        left,
        top,
        type,
        pointID,
    },
})
export const updateCanvasContextMenuPosition = (x: number, y: number) =>({
    type: UPDATE_CANVAS_CONTEXT_MENU_POSITION,
    payload: {x, y}
})
export const updateCanvasSkipPopover = ( visible: boolean | null, reasons: any[] | null) => ({
    type: UPDATE_CANVAS_SKIP_POPOVER,
    payload: { visible, reasons }
})
export const updateCanvasSkipRsn = ( rsnCd: string[], rsnCn: string | null ) => ({
    type: UPDATE_CANVAS_SKIP_RSN,
    payload: { rsnCd, rsnCn }
})
export const updateCanvasEditImagePopover = ( visible: boolean ) => ({
    type: UPDATE_CANVAS_EDIT_IMAGE_POPOVER,
    payload: { visible }
})
export const updateCanvasBrushTools = (config: {
    visible?: boolean, left?: number, top?: number
}) => ({
    type: UPDATE_BRUSH_TOOLS_CONFIG,
    payload: config,
})

export const createAnnotations = (sessionInstance, frame, statesToCreate) => ({
    type: CREATE_ANNOTATIONS,
    payload: { sessionInstance, frame, statesToCreate }
});
export const createAnnotationsSuccess = (module, states, history) => ({
    type: CREATE_ANNOTATIONS_SUCCESS,
    payload: { module, states, history }
});
export const createAnnotationsFailed = (error) => ({
    type: CREATE_ANNOTATIONS_FAILED,
    payload: error
})
export const removeAnnotations = (startFrame: number, endFrame: number, delTrackKeyframesOnly: boolean) => ({
    type: REMOVE_ANNOTATIONS,
    payload: {startFrame, endFrame, delTrackKeyframesOnly}
})
export const removeAnnotationsSuccess = (history, states) => ({
    type: REMOVE_ANNOTATIONS_SUCCESS,
    payload: {history, states}
})
export const removeAnnotationsFailed = (error) => ({
    type: REMOVE_ANNOTATIONS_FAILED,
    payload: {error}
})
export const mergeAnnotations = (moduleInstance: any, frame: number, statesToMerge: any[]) => ({
    type: MERGE_ANNOTATIONS,
    payload: { moduleInstance, frame, statesToMerge}
})
export const mergeAnnotationsSuccess = (states, history) => ({
    type: MERGE_ANNOTATIONS_SUCCESS,
    payload: { states, history }
})
export const mergeAnnotationsFailed = (error) => ({
    type: MERGE_ANNOTATIONS_FAILED,
    payload: { error }
})
export const groupAnnotations = () => ({
    type: MERGE_ANNOTATIONS,
    payload: {}
})
export const groupAnnotationsSuccess = (states, history) => ({
    type: MERGE_ANNOTATIONS_SUCCESS,
    payload: { states, history }
})
export const groupAnnotationsFailed = (error) => ({
    type: MERGE_ANNOTATIONS_FAILED,
    payload: { error }
})
export const splitAnnotations = (moduleInstance: any, frame: number, stateToSplit: any[]) => ({
    type: SPLIT_ANNOTATIONS,
    payload: { moduleInstance, frame, stateToSplit}
})
export const splitAnnotationsSuccess = (states, history) => ({
    type: SPLIT_ANNOTATIONS_SUCCESS,
    payload: { states, history }
})
export const splitAnnotationsFailed = (error) => ({
    type: SPLIT_ANNOTATIONS_FAILED,
    payload: { error }
})
export const getAutoLabelAnnotation = (targetClassId: string, frameData: any, module) => ({
    type: GET_AUTO_LABEL_ANNOTATION,
    payload: {targetClassId, frameData, module}
})
export const getAutoLabelAnnotationSuccess = (states, history) => ({
    type: GET_AUTO_LABEL_ANNOTATION_SUCCESS,
    payload: {states, history}
})
export const getAutoLabelAnnotationFailed = (e) => ({
    type: GET_AUTO_LABEL_ANNOTATION_FAILED,
    payload: e
})
export const fetchAnnotations = () => ({
    type: FETCH_ANNOTATIONS,
    payload: {}
})
export const fetchAnnotationsSuccess = (
    states,
    history,
    minZ,
    maxZ
) => ({
    type: FETCH_ANNOTATIONS_SUCCESS,
    payload: {
        states,
        history,
        minZ,
        maxZ,
    }
})
export const fetchAnnotationsFailed = (error) => ({
    type: FETCH_ANNOTATIONS_SUCCESS,
    payload: error
})
export const saveAnnotations = () => ({
    type: SAVE_ANNOTATIONS,
    payload: {}
})
export const saveAnnotationsSuccess = (states) => ({
    type: SAVE_ANNOTATIONS_SUCCESS,
    payload: { states }
})
export const saveAnnotationsFailed = (error) => ({
    type: SAVE_ANNOTATIONS_FAILED,
    payload: {}
})
export const setForceExitAnnotationFlag = (forceExit:boolean) => ({
    type: SET_FORCE_EXIT_ANNOTATION_PAGE_FLAG,
    payload: {
        forceExit
    }
})
export const submitAnnotations = (moduleInstance, intl, history, dispatch) => ({
    type: SUBMIT_ANNOTATIONS,
    payload: { moduleInstance: moduleInstance },
    hooks: { intl, history, dispatch}
})
export const submitAnnotationsSuccess = (states, intl, history, dispatch, form) => ({
    type: SUBMIT_ANNOTATIONS_SUCCESS,
    payload: {states},
    hooks: {intl, history, dispatch, form}
})
export const submitAnnotationsFailed = (moduleInstance) => ({
    type: SUBMIT_ANNOTATIONS_FAILED,
    payload: {}
})
export const skipData = ({moduleInstance, skipCn, intl, history, dispatch}) => ({
    type: SKIP_DATA,
    payload: { moduleInstance, skipCn },
    hooks: { intl, history, dispatch }
})
export const skipDataSuccess = (intl, history, dispatch) => ({
    type: SKIP_DATA_SUCCESS,
    payload: {},
    hooks: { intl, history, dispatch }
})
export const skipDataFailed = (e) => ({
    type: SKIP_DATA_FAILED,
    payload: {}
})
export const checkValidation = (intl) => ({
    type: CHECK_VALIDATION,
    payload: { intl }
})
export const checkValidationSuccess = (errorVisible, instanceNotAttrValidCnt, instanceNotEditedCnt, classesNotEditedCnt) => ({
    type: CHECK_VALIDATION_SUCCESS,
    payload: { errorVisible, instanceNotAttrValidCnt, instanceNotEditedCnt, classesNotEditedCnt }
})
/**
 * Frame
 */
export const rotateCurrentFrame = (rotation) => {
    const state = getRootStore().getState();
    const {
        Label: {
            Cmmn: {
                player: {
                    frame: {number: frameNumber},
                    frameAngles,
                },
                module: {
                    instance: module,
                    instance: {startFrame},
                },
            }
        },
        settings: {
            player: { rotateAll },
        },
    } = state;

    const frameAngle = (frameAngles[frameNumber - startFrame] + (rotation === Rotation.CLOCKWISE90 ? 90 : 270)) % 360;

    module.logger.log(LogType.rotateImage, { angle: frameAngle });

    return {
        type: ROTATE_FRAME,
        payload: {
            offset: frameNumber - state.Label.Cmmn.module.instance.startFrame,
            angle: frameAngle,
            rotateAll
        }
    }
}
/**
 * Log
 */
export const saveLog = () => ({
    type: SAVE_LOGS,
    payload: {}
})
export const saveLogSuccess = () => ({
    type: SAVE_LOGS_SUCCESS,
    payload: {}
})
export const saveLogFailed = (e) => ({
    type: SAVE_LOGS_FAILED,
    payload: {e}
})
/*
 * Canvas
 */
export const dragCanvas = (enabled: boolean) => ({
    type: DRAG_CANVAS,
    payload: {enabled}
})
export const zoomCanvas = (enabled: boolean) => ({
    type: ZOOM_CANVAS,
    payload: {
        enabled,
    },
})
export const switchPropagateVisibility = (visible: boolean) => ({
    type: SWITCH_PROPAGATE_VISIBILITY,
    payload: { visible }
})
export const changeGroupColor = (group: number, color: string) => ({
    type: CHANGE_GROUP_COLOR,
    payload: { group, color }
})
export const addZLayer = () => ({
    type: ADD_Z_LAYER,
    payload: {}
})
export const switchZLayer = (cur: number) => ({
    type: SWITCH_Z_LAYER,
    payload: {cur}
})
export const collapseSidebar = () => ({
    type: COLLAPSE_SIDEBAR,
    payload: {}
})
export const switchNavigationBlocked = (navigationBlocked: boolean) => ({
    type:SWITCH_NAVIGATION_BLOCKED,
    payload: { navigationBlocked }
})
export const interactWithCanvas = (activeInteractor: MLModel | OpenCVTool, activeLabelID: number | null) => ({
    type: INTERACT_WITH_CANVAS,
    payload: { activeInteractor, activeLabelID }
})
export const changeWorkspace = (workspace: Workspace) => ({
    type: CHANGE_WORKSPACE,
    payload: { workspace }
})
export const updateSideBarTab = (tab: number | string) => ({
    type: UPDATE_SIDEBAR_TAB,
    payload: { tab }
})
/* PLAYER */
export const switchPlay = (playing: boolean) => ({
    type: SWITCH_PLAY,
    payload: {playing}
})
export const updateDuration = (duration: number) => ({
    type: UPDATE_DURATION,
    payload: {duration}
})
export const updateInternalPlayer = (width: number, height: number) => ({
    type: UPDATE_INTERNAL_PLAYER,
    payload: {width, height}
})
/* PLAYER END */
