import WaveSurfer from 'wavesurfer.js'
import RegionsPlugin from 'wavesurfer.js/dist/plugins/regions.esm.js'
import Timeline from 'wavesurfer.js/dist/plugins/timeline.esm.js'
import {useEffect, useRef, useState} from "react";

function AudioPlayTest() {

    const waveformRef = useRef(null);
    const wavesurfer = useRef(null);
    const [playing, setPlay] = useState(false);
    const [volume, setVolume] = useState(0.5);

    const url = "https://coconut-v2-dev.s3.ap-northeast-2.amazonaws.com/test-files/audio/sample1.mp3";
    const formWaveSurferOptions = (ref) => ({
        container: ref,
        waveColor: "#eee",
        progressColor: "OrangeRed",
        cursorColor: "OrangeRed",
        // barWidth: 5,
        // barRadius: 3,
        responsive: true,
        height: 100,
        // If true, normalize by the maximum peak instead of 1.0.
        normalize: true,
        // Use the PeakCache to improve rendering speed of large waveforms.
        partialRender: true,
        plugins:[Timeline.create()]
        ,samplerate:22050
    });

    const random = (min, max) => Math.random() * (max - min) + min
    const randomColor = () => `rgba(${random(0, 255)}, ${random(0, 255)}, ${random(0, 255)}, 0.5)`
    let loop = true;

    useEffect(() => {
        setPlay(false);

        const options = formWaveSurferOptions(waveformRef.current);
        wavesurfer.current = WaveSurfer.create(options);
        const wsRegions = wavesurfer.current.registerPlugin(RegionsPlugin.create())

        wsRegions.enableDragSelection({
            color: 'rgba(255, 0, 0, 0.1)',
            content: '>',
        })
        let activeRegion = null

        wsRegions.on('region-in', (region) => {
            activeRegion = region
        })
        wsRegions.on('region-out', (region) => {
            if (activeRegion === region) {
                if (loop) {
                    region.play()
                } else {
                    activeRegion = null
                }
            }
        })

        wsRegions.on('region-clicked', (region, e) => {
            e.stopPropagation() // prevent triggering a click on the waveform
            activeRegion = region
            if(!playing){
                setPlay(!playing);
                region.play()
                region.setOptions({ color: randomColor() })
            }else{
                setPlay(!playing);
                wavesurfer.current.playPause();
            }



        })

        wavesurfer.current.load(url);

        wavesurfer.current.on("ready", function () {
            if (wavesurfer.current) {
                wavesurfer.current.setVolume(volume);
                setVolume(volume);
            }
        });

        return () => wavesurfer.current.destroy();
    }, [url]);

    const handlePlayPause = () => {
        setPlay(!playing);
        wavesurfer.current.playPause();
    };

    const onVolumeChange = (e) => {
        const {target} = e;
        const newVolume = +target.value;

        if (newVolume) {
            setVolume(newVolume);
            wavesurfer.current.setVolume(newVolume || 1);
        }
    };


    // 옵션
    // wavesurfer : https://www.npmjs.com/package/wavesurfer.js
    return (
        <>
            <div>
                <div id="waveform" ref={waveformRef}/>
                <div className="controls">
                    <button onClick={handlePlayPause}>{!playing ? "Play" : "Pause"}</button>
                    <input
                        type="range"
                        id="volume"
                        name="volume"
                        // waveSurfer recognize value of `0` same as `1`
                        //  so we need to set some zero-ish value for silence
                        min="0.01"
                        max="1"
                        step=".025"
                        onChange={onVolumeChange}
                        defaultValue={volume}
                    />
                    <label htmlFor="volume">Volume</label>
                </div>
            </div>
        </>
    );

}

export default AudioPlayTest;