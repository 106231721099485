import React from 'react';

// 해당 모듈에 연결할수 있는 모듈 유형 (target <- source)
// 들어올수 있는 모듈 유형
const nodeTypeIsAbleSource = {
        'dataset' : ['managerReview_stateChange','managerReview_restore'],
        'workflow' : ['managerReview_stateChange'],
        'label' : ['dataset','workflow','sample','review_reject','crossReview_reject','managerReview_stateChange','autoLabel'],
        'autoLabel' : ['dataset','workflow'],
        'review' : ['label_submit','review_approval','crossReview_approval','sample','crossReview_ambiguity','autoLabel_labeled', 'autoLabel'],
        'crossReview' : ['label','review_approval','crossReview_approval','sample','autoLabel_labeled', 'autoLabel'],
        'managerReview' : ['label_skip','review_ambiguity','crossReview_ambiguity'],
        'sdq4ai' : ['review_approval'],
        'accept' : ['managerReview_stateChange'],
        'finish' : ['review_approval','managerReview_updateSubmit', 'managerReview_subUnabled', 'sdq4ai'],
        'unabled': ['managerReview_subUnabled'],
        'submit' : [],
        'skip' : [],
        'labeled' : [],
        'approval' : [],
        'reject' : [],
        'ambiguity' : [],
        'crossAmbiguity' : [],
        'stateChange' : [],
        'updateSubmit' : [],
        'restore':[],
        'subUnabled':[],
    };

// 해당 모듈이 연결 할수 있는 유형 (source-> target)
const nodeTypesIsAbleTargetModule = {
    'dataset' : ['label','autoLabel', 'sample'],
    'workflow' : ['label','autoLabel', 'sample'],
    'label' : [],
    'autoLabel' : ['label', 'review', 'crossReview'],
    'review' : [],
    'crossReview' : [],
    'managerReview' : [],
    'sdq4ai' : ['finish'],
    'accept' : ['label','autoLabel', 'sample'],
    'finish' : [],
    'unabled': [],
    'submit' : ['review','crossReview'],
    'skip' : ['managerReview','unabled'],
    'labeled' : ['review', 'crossReview'],
    'approval' : ['review','crossReview', 'finish', 'sample', 'sdq4ai'],
    'reject' : ['label'],
    'ambiguity' : ['managerReview'],
    'crossAmbiguity' : ['review','managerReview'],
    'stateChange' : ['dataset','unabled'],
    'updateSubmit' : ['finish'],
    'restore':['dataset'],
    'subUnabled':['finish'],
};

/**
 *
 * @param thisType 현재 노드 타입
 * @param sourceType 연결을 원하는 노드 타입
 */
export const thisTargetValidConnection= (thisType, sourceType) => {
    return nodeTypeIsAbleSource[thisType].includes(sourceType);
}

/**
 *
 * @param thisType 현재 노드 타입
 * @param targetType 연결할려는 노드 타입
 */
export const thisSourceValidConnection = (thisType, targetType) => {
    return nodeTypesIsAbleTargetModule[thisType].includes(targetType);
}