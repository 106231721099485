import SimpleBar from "simplebar-react";
import {Card, CardBody, CardHeader, Col, Input, Label, ListGroup, OffcanvasBody, Row, Table} from "reactstrap";
import {GetMessageIntl} from "../../../../../../../../util/message";
import {Controller, useFormContext} from "react-hook-form";
import Select from "react-select";
import {defaultSelectStyle, formatGroupLabelNoCount} from "../../../../../../../../components/constants/select";
import React, {useEffect, useState} from "react";
import {useIntl} from "react-intl";
import {useDispatch, useSelector} from "react-redux";
import {ManagerWorkflowService} from "../../../../../../../../apis/api/ManagerWorkflowService";
import {
    addManageWorkflowLabelClassItem,
    deleteManageWorkflowLabelClassItem,
    setManageWorkflowLabelAttrItems,
    setManageWorkflowLabelBaseConfig,
    setManageWorkflowLabelBindConfig,
    setManageWorkflowLabelClassItems,
    setManageWorkflowLabelDataConfig,
    setManageWorkflowLabelOrderConfig,
    setManageWorkflowLabelPointConfig,
    setManageWorkflowLabelShapeConfig, setManageWorkflowLabelTestConfig,
    setManageWorkflowLabelWorkerConfig
} from "../../../../../../../../store/manage/project/workflow/label/action";
import {addManageLabelClassItem} from "../../../../../../../../store/manage/label/action";
import LabelShapeAttrRows from "../../label/LabelShapeAttrRow";
import {ReactSortable} from "react-sortablejs";
import LabelOrderRows from "../../label/LabelOrderRow";
import {DndProvider} from "react-dnd";
import {HTML5Backend} from "react-dnd-html5-backend";
import {ModuleService} from "../../../../../../../../apis/api/ModuleService";
import {
    manage_project_workflow_module_updateModuleId
} from "../../../../../../../../store/manage/project/workflow/module/action";

function OrderConfigDiv(props) {
    const { canvasDataTy } = props;
    const intl = useIntl();
    const dispatch = useDispatch();
    const prjctId = sessionStorage.getItem("prjctId");
    const { control:controlOrder, register:registerOrder, unregister:unregisterOrder, setValue:setValueOrder, getValues:getValuesOrder, reset:resetOrder, resetField:resetFieldOrder
        , watch:watchOrder, formState: { errors:errorsOrder }} = useFormContext();
    const watchAllOrder = watchOrder();

    const {
        orderConfig,
        bindConfig,
        selectionNode} = useSelector((state) => ({
        orderConfig: state.ManageWorkflowLabel.orderConfig,
        bindConfig: state.ManageWorkflowLabel.bindConfig,
        selectionNode: state.ManageWorkflowModule.selectionNode,
    }));
    const [orderConfigList, setOrderConfigList] = useState([]);

    useEffect(()=>{
        resetOrder();
        console.log('orderConfig',orderConfig);
        if(bindConfig){
            setValueOrder('dataBindType',bindConfig['dataBindType']?bindConfig['dataBindType']:'00')
        }else{
            setValueOrder('dataBindType','00')
        }
        if(orderConfig){
            if(orderConfig['orderConfigList']){
                setOrderConfigList(orderConfig['orderConfigList']);
            }
        }

    }, [bindConfig, orderConfig]);

    const setChangeOrderConfigList = (items) =>{
        unregisterOrder('orderConfigList');
        setOrderConfigList(items);
    }


    const refreshModuleOrderConfigItem = async ()=>{
        await ModuleService.getManageModuleItem({moduleId: selectionNode.data.moduleId}).then((result) => {
            let moduleItem = result.data.data;
            let getModuleConfig = JSON.parse(moduleItem.moduleConfig);
            dispatch(setManageWorkflowLabelOrderConfig(getModuleConfig.orderConfig))
        });
    }



    return(
        <>
            <SimpleBar style={{ maxHeight: "calc(100vh - 229px)" }} autoHide={false} className="simplebar-track-coco">
                <OffcanvasBody style={{ minHeight: "300px" }} >
                    <h5 className="module-title">
                        <i className="ri-folder-received-line"></i>{GetMessageIntl(intl,"workflow.taskJoinStandard")}
                    </h5>
                    <Row>
                        <Col md={12}>
                            <Label htmlFor="standardTy1" className="form-label">{GetMessageIntl(intl,"workflow.bindSetting")}<span className="text-coco-danger-400">*</span></Label>
                            <div className="ht-38 d-flex align-items-center">
                                <div className="form-check form-radio-coco form-check-inline">
                                    <input className="form-check-input" type="radio" id="dataBindType1" value="00" {...registerOrder("dataBindType")}/>
                                    <Label className="form-check-label mb-0" htmlFor="dataBindType1">
                                        {GetMessageIntl(intl,"workflow.dataFile")}
                                    </Label>
                                </div>
                                {
                                    canvasDataTy === '11' ?
                                        <>
                                        <div className="form-check form-radio-coco form-check-inline">
                                            <input className="form-check-input" type="radio" id="dataBindType2"  value="01" {...registerOrder("dataBindType")}/>
                                            <Label className="form-check-label mb-0" htmlFor="dataBindType2">
                                                {GetMessageIntl(intl,"workflow.dataFolder")}
                                            </Label>
                                        </div>
                                        </>
                                        : <></>
                                }
                            </div>
                        </Col>
                    </Row>
                    <hr className="coco-hr"/>
                    <div>
                        <h5 className="module-title justify-content-between">
                            <div>
                                <i className="mdi mdi-order-alphabetical-ascending"></i>{GetMessageIntl(intl,"workflow.datasetJobOrder")}
                            </div>
                            <div onClick={() => {
                                refreshModuleOrderConfigItem()
                            }}>
                                <i className="ri-refresh-line text-coco-gray-400 text-pointer"/>
                            </div>
                        </h5>
                        <Table className="table-hover table-borderless text-coco-gray-700 table-nowrap mb-0">
                            <thead className={"tx-16 lh-20 fw-500"}>
                            <tr>
                                <th scope="col" className="py-2 wd-3p"></th>
                                <th scope="col" className="py-2 wd-4p">#</th>
                                <th scope="col" className="py-2 wd-50p">
                                    {GetMessageIntl(intl, "workflow.dataset")}
                                </th>
                                <th scope="col" className="py-2 wd-20p text-center">
                                    {GetMessageIntl(intl, "common.regDate")}
                                </th>
                                <th scope="col" className="py-2 wd-12p text-center">
                                    {GetMessageIntl(intl, "workflow.bindRate")}
                                </th>
                                {/*<th scope="col" className="py-2 text-center">
                                    {GetMessageIntl(intl, "workflow.completeRate")}
                                </th>*/}
                            </tr>
                            </thead>
                            <DndProvider backend={HTML5Backend} >
                                <ReactSortable list={orderConfigList} setList={setChangeOrderConfigList} tag="tbody" handle=".handle">
                                    {orderConfigList.map((orderData, index)=>{
                                        const {datasetId, datasetNm, moduleId, useOrd, regDtmText, asgnRate}= orderData;
                                        return(
                                            <LabelOrderRows key={`order_${datasetId}`} index={index} datasetId={datasetId} datasetNm={datasetNm} moduleId={moduleId} useOrd={useOrd} regDtmText={regDtmText} asgnRate={asgnRate}/>
                                        )
                                    })
                                    }
                                </ReactSortable>
                            </DndProvider>
                        </Table>
                    </div>
                </OffcanvasBody>
            </SimpleBar>
        </>
    );
}


export default OrderConfigDiv;

