import React from 'react';
import BreadCrumb from "../../../components/common/BreadCrumb";
import {Col, Container, Row} from "reactstrap";
import {GetMessage} from "../../../util/message";

function UserProjectTutorial() {
    return(
        <>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title={GetMessage("menu.LTTR0000")} pageTitle="Laflow"/>
                </Container>
            </div>
        </>
    )
}

export default UserProjectTutorial;
