// @ts-nocheck
import React from "react";
import {ExitBtn, FAQBtn, GuideBookBtn} from "../../../cmmn/components/header/rightGroup/RightGroupButtons";
import ShortcutsButton from "../../../annotation/image/containers/standard-workspace/header/shortcuts-button";
import {RightGroup} from "../../../annotation/image/components/header/right-group/RightGroup";
import {useSelector} from "react-redux";
export const RightGroupContainer = (): JSX.Element => {
    const {moduleId} = useSelector(state => state.Label.Cmmn.module);
    return (
        <RightGroup subKeyMap={null} handlers={null}>
            <>
                <FAQBtn moduleId={moduleId}/>
                <GuideBookBtn moduleId={moduleId}/>
                <ShortcutsButton/>
                <ExitBtn/>
            </>
        </RightGroup>
    )
}
