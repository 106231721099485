import {useSelector} from "react-redux";
import {GetMessage, GetMessageIntl} from "../../../../../../../../../util/message";
import {Controller, useFormContext} from "react-hook-form";
import {Input} from "reactstrap";
import React, {useEffect, useState} from "react";
import Select from "react-select";
import {multiSingleSelectStyle} from "../../../../../../../../../components/constants/select";

export function ItemAttribute(props) {
    const {
        readonly, attrInputType, attrValues, attrValue, attrName, attrID,
        attrRequired, attrRegexp, attrRegexpSample, changeAttribute,
        control, register, errors
    } = props;

    const attrClassName = "coco-object-popover-attribute";
    const attrTitleClassName = "coco-object-popover-attribute-title";
    const attrInputPrefix = "coco-object-popover-attribute-input-"
    const attrInputName = attrInputPrefix + attrID
    const {selectedFile, fileLabels, fileAttributes, hasErrorFiles} = useSelector((state) => ({
        selectedFile: state.WorkflowLabelTestConfig.selectedFile,
        fileLabels: state.WorkflowLabelTestConfig.fileLabels,
        fileAttributes: state.WorkflowLabelTestConfig.fileAttributes,
        hasErrorFiles: state.WorkflowLabelTestConfig.hasErrorFiles
    }));

    const {
        control: controlTest
        , register: registerTest
        , setValue: setValueTest
        , getValues: getValuesTest
        , clearErrors: clearErrorsTest
        , reset: resetTest
        , resetField: resetFieldTest
        , watch: watchTest
        , formState: {errors: errorsTest}
        , trigger: triggerTest
    } = useFormContext();


    useEffect(()=>{
        resetFieldTest(attrInputName);
        setValueTest(attrInputName ,attrValue);
        let thisError = hasErrorFiles.filter((errorFile)=> errorFile === selectedFile.fileName).length;
        if(thisError > 0) {
            triggerTest(attrInputName);
        }
    },[selectedFile])

    let rules = {
        required: {
            value: attrRequired,
            message: GetMessage("validation.required")
        }
    };
    if (attrRegexp) {
        rules.pattern = {
            value: new RegExp(attrRegexp),
            message: GetMessage("validation.contentNotVaild")
        }
    }


    const [selectOption, setSelectOption] = useState([]);
    const [singleAttr, setSingleAttr] = useState(selectOption.find(option => option.value == attrValue));
    const [multiAttr, setMultiAttr] = useState([]);
    const [tValue, setTValue] = useState(null);

    useEffect(()=>{
        let optionArr = [];
        attrValues.map(attr => {
            optionArr.push({label: attr, value: attr})
        });

        setSelectOption(optionArr);
        if(attrInputType === "text"){
            setTValue(attrValue==null || attrValue && attrValue == undefined ? '': attrValue );
            setValueTest(attrInputName ,attrValue==null || attrValue && attrValue == undefined ? '': attrValue );
        }else if (attrInputType === "select") {
            setSingleAttr(optionArr.find(option => option.value == attrValue));
            setTValue(attrValue == undefined ? null : {label: attrValue, value: attrValue});
            setValueTest(attrInputName ,attrValue==null || attrValue && attrValue == undefined ? null: attrValue );
        }else if(attrInputType == 'checkbox'){

            if(Array.isArray(attrValue)){
                setMultiAttr(
                    optionArr.filter(option => {
                        return Array.isArray(attrValue) ? attrValue.includes(option.value) : false
                    })
                )
                setTValue(attrValue.map(val=>{return {label: val, value: val}}));
                setValueTest(attrInputName ,attrValue==null || attrValue.length==0 ? []: attrValue );
            }else{
                setMultiAttr([]);
                setTValue([]);
                setValueTest(attrInputName, []);
            }

        }

    }, [attrInputType, attrValue, attrValues])

    return (
            <>
            {
                attrInputType == 'text'?
                    <div className={attrClassName}>
                        <ItemAttributeTitle
                            attrInputType={attrInputType}
                            attrTitleClassName={attrTitleClassName}
                            attrName={attrName}
                            attrRequired={attrRequired}
                        />
                        <div className={Object.keys(errors).includes(attrInputName) ? "validation-error" : ""}>
                            <Controller
                                render={({field}) => {
                                    const {onChange} = field;
                                    return (<Input {...field}
                                                   autoComplete={"off"}
                                                   onChange={(event) => {
                                                       const newValue = event.target.value;
                                                       onChange(newValue);
                                                       setValueTest(attrInputName, newValue);
                                                       clearErrorsTest(attrInputName);
                                                       changeAttribute(attrID, newValue);
                                                   }}
                                                   placeholder={attrRegexpSample}
                                        />
                                    )
                                }}
                                defaultValue={tValue ? tValue: ''}
                                name={attrInputName}
                                control={controlTest}
                                rules={rules}
                                // className="form-control lh-18"
                            />
                        </div>
                    </div>
                    :
                    attrInputType === "select"?
                        <div className={attrClassName}>
                            <ItemAttributeTitle
                                attrInputType={attrInputType}
                                attrTitleClassName={attrTitleClassName}
                                attrName={attrName}
                                attrRequired={attrRequired}
                            />
                            <div className={Object.keys(errors).includes(attrInputName) ? "validation-select-error" : ""}>
                                <Controller
                                    control={controlTest}
                                    name={attrInputName}
                                    defaultValue={tValue?tValue:null}
                                    render={({ field: { onChange, value, ref, name } }) => (
                                        <Select
                                            options={selectOption}
                                            styles={canvasMultiSingleSelectStyle}
                                            className="mb-0"
                                            onChange={(newValue) => {
                                                const newAttr = newValue[newValue.length - 1];
                                                setSingleAttr(newAttr);
                                                clearErrorsTest(attrInputName);
                                                if (newAttr) {
                                                    setValueTest(attrInputName, newAttr.value);
                                                    changeAttribute(attrID, newAttr.value);
                                                } else {
                                                    setValueTest(attrInputName, null);
                                                    changeAttribute(attrID, null);
                                                }
                                            }}
                                            value={value?selectOption.find((c) => c.value === value):null}
                                            isMulti
                                        />
                                    )}
                                    rules={rules}
                                />
                            </div>
                            {/*{Object.keys(errors).includes(attrInputName) && <p className="validation-error-message">{errors[attrInputName].message}</p>}*/}
                        </div>
                        :
                        <div className={attrClassName}>
                            <ItemAttributeTitle
                                attrInputType={attrInputType}
                                attrTitleClassName={attrTitleClassName}
                                attrName={attrName}
                                attrRequired={attrRequired}
                            />
                            <div className={Object.keys(errors).includes(attrInputName) ? "validation-select-error" : ""}>
                                <Controller
                                    control={controlTest}
                                    name={attrInputName}
                                    defaultValue={tValue?tValue:null}
                                    render={({ field: { onChange, value, ref, name } }) => (
                                            <Select
                                                options={selectOption}
                                                styles={canvasMultiSingleSelectStyle}
                                                className={"mb-0"}
                                                onChange={(values) => {
                                                    let newValues = [];
                                                    for (const label of values) {
                                                        newValues.push(label.value)
                                                    }
                                                    setMultiAttr(values)
                                                    changeAttribute(attrID, newValues);
                                                }}
                                                value={value?selectOption.filter((c) => value.includes(c.value)):null}
                                                isMulti
                                            />
                                        )}
                                    rules={rules}
                                />
                            </div>
                            {/*{Object.keys(errors).includes(attrInputName) && <p className="validation-error-message">{errors[attrInputName].message}</p>}*/}
                        </div>
            }
            </>
    );
}

export function ItemAttributeTitle(props) {
    const {attrTitleClassName, attrName, attrRequired, attrInputType} = props;
    return (
        <div className={attrTitleClassName.concat(" text-coco-gray-700 tx-16 fw-normal mb-8")}>
            {attrName}
            {!!attrRequired && <span className={"text-danger"}>*</span>}
            {
                attrInputType === 'checkbox' ?
                    <span className={"px-1 text-coco-gray-400"}>({GetMessage('ui.U00047')})</span>
                    : attrInputType === 'select' ?
                        <span className={"px-1 text-coco-gray-400"}>({GetMessage('ui.U00046')})</span>
                        : <></>
            }

        </div>
    )
}




const canvasMultiSingleSelectStyle = {
    ...multiSingleSelectStyle,
    multiValue: (styles, state) => {
        return {
            ...styles,
            backgroundColor: state.isDisabled ? '#b0bec5' : '#0bad8f',
            padding: '0px 6px 0px 0px',
            borderRadius: '4px'
        };
    },
    multiValueLabel: (styles, state) => ({
        ...styles,
        color: 'white',
        borderRight: state.isDisabled ? '1px solid white' : '1px solid #7ed0bd',
        borderRadius: '0px',
        paddingRight: '6px',
        fontSize: '12px'
    })
}

export default ItemAttribute;