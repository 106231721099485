import {setForceExitAnnotationFlag} from "../../../../../store/label/action";
import {connect} from "react-redux";
import CanvasHeader from "pages/user/label/cmmn/components/header/CanvasHeader";
import {Workspace} from "../../../../../store/label";
import {RightGroupContainer as MnRightGroupContainer} from "./RightGroupContainer";

interface OwnProps {
    type: Workspace;
}
interface StateToProps {
    forceExit: boolean;
    workflowName: any;
    frame: any;
}

interface DispatchToProps {
    onSetForceExitAnnotationFlag(forceExit: boolean): void;
}
function mapStateToProps(state): StateToProps {
    const {
        Label: {
            Cmmn: {
                annotations: {saving: forceExit},
                player: {frame}
            }
        },
        Details: {
            workflow: {
                name: workflowName
            }
        }
    } = state;

    return {
        forceExit,
        workflowName,
        frame
    }
}

function mapDispatchToProps(dispatch): DispatchToProps {
    return {
        onSetForceExitAnnotationFlag(forceExit: boolean): void {
            dispatch(setForceExitAnnotationFlag(forceExit));
        }
    }
}

function CanvasHeaderContainer(props: OwnProps & StateToProps & DispatchToProps): JSX.Element{
    const {type, forceExit, workflowName, frame, onSetForceExitAnnotationFlag} = props;
    const frameName = frame?.filename;
    return (
        <CanvasHeader
            type={type}
            forceExit={forceExit}
            title={workflowName}
            subTitle={frameName}
            unblock={null}
            rightGroup={<MnRightGroupContainer/>}
        />
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(CanvasHeaderContainer);
