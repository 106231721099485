export const CREATE_ISSUE = 'review/CREATE_ISSUE';
export const START_ISSUE = 'review/START_ISSUE';
export const FINISH_ISSUE = 'review/FINISH_ISSUE';
export const FINISH_ISSUE_SUCCESS = 'review/FINISH_ISSUE_SUCCESS';
export const FINISH_ISSUE_FAILED = 'review/FINISH_ISSUE_FAILED';
export const CANCEL_ISSUE = 'review/CANCEL_ISSUE';
export const RESOLVE_ISSUE = 'review/RESOLVE_ISSUE';
export const RESOLVE_ISSUE_SUCCESS = 'review/RESOLVE_ISSUE_SUCCESS';
export const RESOLVE_ISSUE_FAILED = 'review/RESOLVE_ISSUE_FAILED';
export const REOPEN_ISSUE = 'review/REOPEN_ISSUE';
export const REOPEN_ISSUE_SUCCESS = 'review/REOPEN_ISSUE_SUCCESS';
export const REOPEN_ISSUE_FAILED = 'review/REOPEN_ISSUE_FAILED';
export const COMMENT_ISSUE = 'review/COMMENT_ISSUE';
export const COMMENT_ISSUE_SUCCESS = 'review/COMMENT_ISSUE_SUCCESS';
export const COMMENT_ISSUE_FAILED = 'review/COMMENT_ISSUE_FAILED';
export const REMOVE_ISSUE = 'reivew/REMOVE_ISSUE';
export const REMOVE_ISSUE_SUCCESS = 'review/REMOVE_ISSUE_SUCCESS';
export const REMOVE_ISSUE_FAILED = 'review/REMOVE_ISSUE_FAILED';
export const SUBMIT_REVIEW = 'review/SUBMIT_REVIEW';
export const SUBMIT_REVIEW_SUCCESS = 'review/SUBMIT_REVIEW_SUCCESS';
export const SUBMIT_REVIEW_FAILED = 'review/SUBMIT_REVIEW_FAILED';
export const SWITCH_ISSUES_HIDDEN_FLAG = 'review/SWITCH_ISSUES_HIDDEN_FLAG';
export const SWITCH_RESOLVED_ISSUES_HIDDEN_FLAG = 'review/SWITCH_RESOLVED_ISSUES_HIDDEN_FLAG';
export const GET_REVIEW_STATES_SUCCESS = 'review/GET_REVIEW_STATES_SUCCESS';
export const GET_REVIEW_STATES_FAILED = 'review/GET_REVIEW_STATES_FAILED';
export const GET_ANNOTATIONS_REASONS = 'review/GET_ANNOTATIONS_REASONS';
export const GET_ANNOTATIONS_REASONS_SUCCESS = 'review/GET_ANNOTATIONS_REASONS_SUCCESS';
export const GET_ANNOTATIONS_REASONS_FAILED = 'review/GET_ANNOTATIONS_REASONS_FAILED';
export const UPDATE_REVIEW_STATES = 'review/UPDATE_REVIEW_STATES';
export const UPDATE_REVIEW_STATES_SUCCESS = 'review/UPDATE_REVIEW_STATES_SUCCESS';
export const UPDATE_REVIEW_STATES_FAILED = 'review/UPDATE_REVIEW_STATES_FAILED';

export const UPDATE_COMPLETED_STATES = 'review/UPDATE_COMPLETED_STATES';
// export const UPDATE_REVIEW_STATES_SUCCESS = 'review/UPDATE_REVIEW_STATES_SUCCESS';
// export const UPDATE_REVIEW_STATES_FAILED = 'review/UPDATE_REVIEW_STATES_FAILED';
export const CREATE_REVIEW_DATA_SUCCESS = 'review/CREATE_REVIEW_DATA_SUCCESS';
export const CREATE_REVIEW_DATA_FAILED = 'review/CREATE_REVIEW_DATA_FAILED';
export const SUBMIT_ANNOTATIONS = 'review/SUBMIT_ANNOTATIONS';
export const SUBMIT_ANNOTATIONS_SUCCESS = 'review/SUBMIT_ANNOTATIONS_SUCCESS';
export const SUBMIT_ANNOTATIONS_FAILED = 'review/SUBMIT_ANNOTATIONS_FAILED';
export const RESET_REVIEW_STATE_SUCCESS = 'review/RESET_REVIEW_STATE_SUCCESS';
export const CHECK_VALIDATION = 'review/CHECK_VALIDATION';
export const CHECK_VALIDATION_SUCCESS = 'review/CHECK_VALIDATION_SUCCESS';
export const UPDATE_ACTIVE_SHAPE = 'review/UPDATE_ACTIVE_SHAPE'
