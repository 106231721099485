const INIT_STATE = {
	canvas: {
		/**
		 * 화면에 표시되는 state
		 */
	},
	annotations: {
		/**
		 * 라벨링 결과
		 */
		states: []
	},
}

const ClsfcSmry = (state=INIT_STATE, action) => {
	switch (action.type){
		default:
			return state;
	}
}
export default ClsfcSmry;
