// @ts-nocheck
import React from 'react';

import { Canvas } from 'pages/user/label/annotation/image/cvat-canvas-wrapper';
import {Button} from "reactstrap";

export interface Props {
    canvasInstance: Canvas;
}

function FitControl(props: Props): JSX.Element {
    const { canvasInstance } = props;
    return (
        <Button color={"coco-ghost"}
                className="btn-icon waves-effect waves-light rounded-pill"
                id="fit"
                onClick={(): void => canvasInstance.fit()}
        >
            <i className="mdi mdi-fit-to-screen-outline fs-20"></i>
        </Button>
        // <CVATTooltip title='Fit the image [Double Click]' placement='right'>
        //     <Icon className='cvat-fit-control' component={FitIcon} onClick={(): void => canvasInstance.fit()} />
        // </CVATTooltip>
    );
}

export default React.memo(FitControl);
