import React, {useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import button from "bootstrap/js/src/button";
import {user_initSearchParam, user_setSearchParam} from "../../store/user/action";
import {Card, CardBody, CardHeader, Col, Container, DropdownItem, DropdownMenu, DropdownToggle, Input, Row, UncontrolledDropdown} from "reactstrap";
import BreadCrumb from "../../components/common/BreadCrumb";
import ReactPaginate from "react-paginate";
import {ManagerService} from "../../apis/api/ManagerService.";
import PageLeftInfomation from "../../components/common/PageLeftInfomation";
import {GetMessage, GetMessageIntl} from "../../util/message";
import Select from "react-select";
import {defaultSelectStyle} from "../../components/constants/select";
import {AgGridReact} from "ag-grid-react";
import {AgRowStyle} from "../../components/common/AgGridCustomStyle";
import {useIntl} from "react-intl";


let mount = 1;
function User(props) {
    const intl = useIntl();
    let dispatch = useDispatch();
    const [managerNm, setManagerNm] = useState();
    const [userTy, setUserTy] = useState();
    const [orgnztId, setOrgnztId] = useState();
    const [orgnztOption, setOrgnztOption] = useState([]);
    const [highlightWords, setHighlightWords] = useState([]);
    const orgnztIdSelectRef = useRef();
    const userTySelectRef = useRef();




    const searchOption = [
        { label: "역할 전체", value: "" },
        { label: '대표계정', value: "10" },
        { label: '매니저', value: "11" },
        { label: '일반 매니저', value: "12" },
        { label: '게스트', value: "13" },
        { label: '작업자', value: "20" }
    ]
    // const modalFlag = useSelector((state) => { return state.Dataset.modalFlag });

    /*
        TITLE 오른쪽 메인 버튼 컴포넌트
        페이지마다 유동
     */
    const ButtonComponent = () => {
        return(
            <button type="button" className="btn btn-coco-1"
                    onClick={()=>{
                        // managerDis(switchModalFlag())
                    }}
            >
                <i className="ri-add-line align-middle me-1"></i>
                {GetMessage("platform.addUser")}
            </button>
        )
    }

    // 기존 State 초기화
    // -> Param 재저장
    const handleSearch = () => {
        dispatch(user_initSearchParam());
        mount++;
        let obj = {};
        if(managerNm !== ""){
            if(managerNm === ""){
                setManagerNm(undefined);
            }
            obj.USER_NM = managerNm
        }
        if(userTy !== ""){
            obj.USER_TY = userTy
        }
        if(orgnztId !== ""){
            obj.ORGNZT_ID = orgnztId
        }
        dispatch(user_setSearchParam(obj));
    }

    // Input, Select 등 State로 조정
    const handleReset = () => {
        dispatch(user_initSearchParam());
        mount++;
        setManagerNm("");
        if (userTySelectRef.current) {
            userTySelectRef.current.clearValue();
        }
        if (orgnztIdSelectRef.current) {
            orgnztIdSelectRef.current.clearValue();
        }
        // refComp.current.flatpickr.clear();
    }

    const getTask = async () => {
        const arr = [];
        arr.push({label:GetMessageIntl(intl,"user.allOrgnzt"), value : ""})
        ManagerService.getOrgnztList().then( res => {
            let result = res.data.data;
            result.map((task) => {
                return arr.push({value: task.orgnztId, label: task.orgnztNm});
            });
            arr.push({label:GetMessageIntl(intl,"user.worker"), value : "WORKER"})
            setOrgnztOption(arr);
        })
    };

    useEffect(()=>{
        dispatch(user_initSearchParam());
        mount++;
        getTask().then();
    }, []);

    return (
        <>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title={GetMessage("menu.MFUR0000")} pageTitle="Laflow" ButtonComponent={ButtonComponent}/>
                    <Row>
                        <Card className="shadow-none mg-b-16">
                            <CardHeader className="border-0 rounded p-0">
                                <Row className="g-3">
                                    <Col lg={3}>
                                        <div className="position-relative form-search">
                                            <Input
                                                type="text"
                                                className="form-control"
                                                placeholder={GetMessage('user.searchPlaceHolder')}
                                                value={managerNm}
                                                onChange={(e)=> {setManagerNm(e.target.value)}}
                                            />
                                            <span className="ri-search-line search-widget-icon"></span>
                                            <span className="ri-close-line search-widget-icon search-widget-icon-close d-none" id="search-close-icon"></span>
                                        </div>
                                    </Col>
                                    <Col lg={2}>
                                        <Select
                                            ref={userTySelectRef}
                                            styles={defaultSelectStyle}
                                            placeholder={GetMessage("user.allRole")}
                                            options={searchOption}
                                            defaultValue={searchOption[0]}
                                            isSearchable={true}
                                            onChange={(e) => {
                                                if(e) {
                                                    setUserTy(e.value);
                                                }
                                            }}
                                        />
                                    </Col>
                                    <Col lg={2}>
                                        <Select
                                            ref={orgnztIdSelectRef}
                                            styles={defaultSelectStyle}
                                            placeholder={GetMessage("user.allOrgnzt")}
                                            options={orgnztOption}
                                            defaultValue={orgnztOption[0]}
                                            isSearchable={true}
                                            onChange={(e) => {
                                                if(e) {
                                                    setOrgnztId(e.value);
                                                }
                                            }}
                                        />
                                    </Col>
                                    <Col xl={1} className={"align-self-center"} style={{maxWidth:"75px"}}>
                                        <div className={"d-flex"}>
                                            <button className="btn btn-coco-outline-1"
                                                    onClick={(e) => {handleSearch()}}>
                                                {GetMessage("common.apply")}
                                            </button>
                                        </div>
                                    </Col>
                                    <Col className={"pd-0 align-self-center"}>
                                        <div className={"d-flex"}>
                                            <i  className="ri-refresh-line text-coco-gray-200 fs-24 align-self-auto"
                                                onClick={()=>{handleReset()}
                                                }></i>
                                        </div>
                                    </Col>
                                </Row>
                            </CardHeader>
                        </Card>
                        {/* 기본 레이아웃이 같다면 리스트만 분기해서 들어가자 */}
                        <UserList/>
                    </Row>
                    {/*{*/}
                    {/*    modalFlag === true ? <DatasetAddModal /> : null*/}
                    {/*}*/}
                </Container>
            </div>
        </>
    );
}



function UserList() {
    const dispatch = useDispatch();
    const searchParam = useSelector((state ) => { return state.User.searchParam });
    const [datasets, setDatasets] = useState();
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);

    function calStartIdx(page) {
        return (page - 1) * perPage;
    }

    const fetchUsers = async (page, size = perPage) => {
        setLoading(true);

        const res = await ManagerService.getUserList({
            'userTy' : '20',
            'start': calStartIdx(page),
            'length': size,
            'orderParam' : [],
            'searchParam' : searchParam
        });
        if(res.data.data){
            setDatasets(res.data.data);
            setTotalRows(res.data.recordsTotal);
        };
        setLoading(false);
    };

    useEffect(() => {
        if(mount > 1){
            setCurrentPage(1);
            fetchUsers(1);
            mount = 1;
        }
    }, [searchParam]);

    const handlePageChange = page => {
        let pageIdx = page.selected + 1;
        fetchUsers(pageIdx);
        setCurrentPage(pageIdx);
    };

    const [columnDefs] = useState([
        {
            field: 'userNm',
            headerName: '성명',
            cellClass : "firstCell",
            flex : "5%",
            cellRenderer: params => {
                return (
                    <div className={"d-flex"}>
                        <i className="ri-account-circle-line" style={{color: params.data.fontCode, fontSize:"19px", marginRight:"5px"}}></i>
                        {params.data.userNm}
                    </div>
                )
            }
        },
        {
            field: 'userId',
            headerName: '이메일ID',
            flex : "15%",
        },
        {
            field: 'orgnztNm',
            headerName: '소속',
            flex : "8%",
        },
        {
            field: 'userTy',
            headerName: '역할',
            flex : "8%",
            cellRenderer: params => {
                let re = '';
                switch (params.data.userTy){
                    case "10" :
                        re = "대표계정"
                        break;
                    case "11" :
                        re = "매니저"
                        break;
                    case "12" :
                        re = "일반 매니저"
                        break;
                    case "13" :
                        re = "게스트"
                        break;
                    case "20" :
                        re = "작업자"
                        break;
                }
                return re;
            }
        },
        {
            field: 'regDtmText',
            headerName: '가입 일자',
            flex : "10%"
        },
        {
            field: 'loginHis',
            headerName: '마지막 로그인 일시',
            flex : "10%"
        },
        {
            field: '',
            cellClass : "lastCell",
            headerName: '액션',
            flex : "5%",
            cellRenderer: params => {
               return(
                   <>
                       <div className={"actionCell"}>
                           <UncontrolledDropdown>
                               <DropdownToggle
                                   href="#"
                                   className="btn-soft-secondary btn-sm dropdown"
                                   tag="button"
                                   style={{lineHeight: "17px"}}
                               >
                                   <i className="ri-more-fill align-middle"></i>
                               </DropdownToggle>
                               <DropdownMenu className="dropdown-menu-end" style={{lineHeight: "17px"}}>
                                   <DropdownItem className="dropdown-item" href="#"
                                                 onClick={() => {}}
                                   >
                                       {GetMessage('ui.U00075')}
                                   </DropdownItem>
                                   <DropdownItem
                                       className="dropdown-item edit-item-btn"
                                       href="#"
                                       onClick={() => {}}
                                   >
                                       {GetMessage('ui.U00013')}
                                   </DropdownItem>
                                   <DropdownItem
                                       className="dropdown-item remove-item-btn"
                                       href="#"
                                       onClick={() => {}}
                                   >
                                       {GetMessage('ui.U00137')}
                                   </DropdownItem>
                                   <DropdownItem
                                       className="dropdown-item remove-item-btn"
                                       href="#"
                                       onClick={() => {}}
                                   >
                                       {GetMessage('ui.U00135')}
                                   </DropdownItem>
                               </DropdownMenu>
                           </UncontrolledDropdown>
                       </div>
                   </>
               )
            }
        }
    ])

    const [defaultColDef] = useState({
        autoHeight: true,
        resizable: true,
        cellClass: 'cell-wrap-text',
    })

    const gridOption = {
        suppressCellFocus : true,
    }

    // @ts-ignore
    return (
        <>
            <Card className="shadow-none">
                <CardBody className="px-0 pb-0">
                    <div className="ag-theme-alpine" style={{ width: '100%' }}>
                        <AgGridReact
                            rowData={datasets}
                            rowStyle={AgRowStyle}
                            defaultColDef={defaultColDef}
                            columnDefs={columnDefs}
                            tooltipShowDelay={0}
                            TOOLTIP_HIDE_DELAY={2000}
                            pagination={true}
                            paginationPageSize={perPage}
                            suppressPaginationPanel={true}
                            suppressRowTransform={true}
                            detailRowAutoHeight={true}
                            gridOptions={{
                                suppressCellFocus : true
                            }}
                            domLayout={'autoHeight'}
                        >
                        </AgGridReact>
                        <Row className='mx-0'>
                            <Col className='d-flex justify-content-start align-items-center fs-16 text-coco-gray-700 fw-500' sm='6'>
                                <PageLeftInfomation
                                    totalRows={totalRows}
                                    perPage ={perPage}
                                    currentPage={currentPage}
                                />
                            </Col>
                            <Col className='d-flex justify-content-end align-items-center' sm='6'>
                                <ReactPaginate
                                    previousLabel={'←'}
                                    nextLabel={'→'}
                                    onPageChange={handlePageChange}
                                    pageCount={Math.ceil(Number((totalRows / perPage)))}
                                    forcePage={currentPage - 1}
                                    // breakLabel={'...'}
                                    // pageRangeDisplayed={2}
                                    // marginPagesDisplayed={2}
                                    activeClassName={'active'}
                                    pageClassName={'page-item'}
                                    nextLinkClassName={'page-link'}
                                    nextClassName={'page-item next'}
                                    previousClassName={'page-item prev'}
                                    previousLinkClassName={'page-link'}
                                    pageLinkClassName={'page-link'}
                                    breakClassName='page-item'
                                    breakLinkClassName='page-link'
                                    containerClassName={'pagination react-paginate pagination-custom pagination-sm justify-content-end align-items-center'}
                                />
                            </Col>
                        </Row>
                    </div>
                </CardBody>
            </Card>
        </>
    );
}


export default User;
