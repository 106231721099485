import {
    SWITCH_MODAL_FLAG,
    SWITCH_VIEW_MODAL_FLAG,
    SET_ROW_DATA,
    SWITCH_TMP_VIEW_MODAL_FLAG,
    SET_MODAL_MODE,
    // 공지사항
    NOTICE_CN_TITLE,
    NOTICE_CURRENT_PAGE,
    NOTICE_REG_DTM,
    NOTICE_SET_SEARCH_PARAM,
    // FAQ
    FAQ_SET_MODAL_MODE,
    FAQ_SWITCH_TMP_VIEW_MODAL_FLAG,
    FAQ_SWITCH_ADD_MODAL_FLAG,
    FAQ_SWITCH_VIEW_MODAL_FLAG,
    FAQ_SET_CURRENT_PAGE,
    SWITCH_REFRESH_FLAG,
    FAQ_SET_CN_TITLE,
    FAQ_SET_SEARCH_PARAM,
    FAQ_SET_CN_TY,
    FAQ_SET_CN_TYPE_OPTIONS,

    // 1:1
    OTO_SET_MODAL_MODE,
    OTO_SWITCH_VIEW_MODAL_FLAG,
    OTO_SWITCH_TMP_VIEW_MODAL_FLAG,
    OTO_SWITCH_ADD_MODAL_FLAG,
    OTO_SWITCH_REFRESH_FLAG,
    OTO_SET_CN_TITLE,
    OTO_SET_CN_TY,
    OTO_SET_REG_DTM,
    OTO_SET_REPLY_YN,
    OTO_SET_SEARCH_PARAM,
    OTO_SET_CURRENT_PAGE
} from "./actionType"

export const board_switchModalFlag = () => ({
    type : SWITCH_MODAL_FLAG,
});
export const board_switchViewModalFlag = () => ({
    type : SWITCH_VIEW_MODAL_FLAG,
});
export const board_switchTmpViewModalFlag = () => ({
    type : SWITCH_TMP_VIEW_MODAL_FLAG,
});
export const board_switchRefreshFlag = () => ({
    type : SWITCH_REFRESH_FLAG,
})
export const board_setRowData = (rowData) => ({
    type : SET_ROW_DATA,
    payload : rowData
})
export const board_setModalMode = (mode) => ({
    type : SET_MODAL_MODE,
    payload : mode
})
export const noticeCurrentPage = (currentPage) => ({
    type : NOTICE_CURRENT_PAGE,
    payload : currentPage
})
// 공지사항
export const noticeSetSearchParam = (searchParam) => ({
    type : NOTICE_SET_SEARCH_PARAM,
    payload : searchParam
})
export const noticeRegDtm = (regDtm) => ({
    type : NOTICE_REG_DTM,
    payload : regDtm
})
export const noticeCnTitle = (cnNm) => ({
    type : NOTICE_CN_TITLE,
    payload : cnNm
})

// faq
export const board_faqSetModalMode = (mode) => ({
    type : FAQ_SET_MODAL_MODE,
    payload : mode
})
export const board_faqSwitchAddModalFlag = () => ({
    type : FAQ_SWITCH_ADD_MODAL_FLAG,
});
export const board_faqSwitchViewModalFlag = () => ({
    type : FAQ_SWITCH_VIEW_MODAL_FLAG,
});
export const board_faqSwitchTmpViewModalFlag = () => ({
    type : FAQ_SWITCH_TMP_VIEW_MODAL_FLAG,
});
export const faq_setCnTitle = (cnTitle) => ({
    type: FAQ_SET_CN_TITLE,
    payload: cnTitle
})
export const faq_setSearchParam = (searchParam) => ({
    type: FAQ_SET_SEARCH_PARAM,
    payload: searchParam
})
export const faq_setCnTyOptions = (options) => ({
    type: FAQ_SET_CN_TYPE_OPTIONS,
    payload: options
})
export const faq_setCnTy = (cnTy) => ({
    type: FAQ_SET_CN_TY,
    payload: cnTy
})
export const faq_setCurrentPage = (currentPage) => ({
    type: FAQ_SET_CURRENT_PAGE,
    payload: currentPage
})

// 1:1
export const board_otoSetModalMode = (mode) => ({
    type : OTO_SET_MODAL_MODE,
    payload : mode
})
export const otoSetSearchParam = (searchParam) => ({
    type: OTO_SET_SEARCH_PARAM,
    payload: searchParam
})
export const otoSetCurrentPage = (currentPage) => ({
    type: OTO_SET_CURRENT_PAGE,
    payload: currentPage
})
export const otoSetCnTitle = (cnTitle) => ({
    type: OTO_SET_CN_TITLE,
    payload: cnTitle
});
export const otoSetCnTy = (cnTy) => ({
    type: OTO_SET_CN_TY,
    payload: cnTy
})
export const otoSetReplyYn = (replyYn) => ({
    type: OTO_SET_REPLY_YN,
    payload: replyYn
});
export const otoSetRegDtm = (regDtm) => ({
    type: OTO_SET_REG_DTM,
    payload: regDtm
})
export const board_otoSwitchAddModalFlag = () => ({
    type : OTO_SWITCH_ADD_MODAL_FLAG,
});
export const board_otoSwitchViewModalFlag = () => ({
    type : OTO_SWITCH_VIEW_MODAL_FLAG,
});
export const board_otoSwitchTmpViewModalFlag = () => ({
    type : OTO_SWITCH_TMP_VIEW_MODAL_FLAG,
});
export const board_otoSwitchRefreshFlag = () => ({
    type : OTO_SWITCH_REFRESH_FLAG
});
