// @ts-nocheck
import {GetMessageIntl} from "../../../../../util/message";
import {useIntl} from "react-intl";
import {useDispatch, useSelector} from "react-redux";
import {SttusColor} from "../../../../../store/dataset/details";
import {useEffect, useState} from "react";
import {recoveryData, checkUnableWork} from "../../../../../store/dataset/details/action";

export function SkipController(): JSX.Element {
    const {
        Details: {
            fileInfo: {
                displayState: { dataSttusColor: state}
            },
        },
        Label: {
            Cmmn: {
                frames: {
                    frame: {
                        moduleId,
                        datasetId,
                        fileSeq
                    }
                }
            }
        }
    } = useSelector(state => state)
    const intl = useIntl();
    const dispatch = useDispatch();
    const [disabled, setDisabled] = useState<boolean>(true);

    useEffect(() => {
        setDisabled(state !== SttusColor.REVIEW_REQUIRED)
    }, [state])

    const recovery = () => {
        dispatch(recoveryData({moduleId, datasetId, fileSeq}, intl));
    }

    const checkUnable = () => {
        dispatch(checkUnableWork({moduleId, datasetId, fileSeq}, intl));
    }

    return (
        <div className="hstack gap-2 justify-content-between">
            <button type="button"
                    className="btn btn-coco-1 waves-effect waves-light wd-50p submit"
                    disabled={disabled}
                    onClick={recovery}
            >
                {GetMessageIntl(intl, 'details.recovery')}
            </button>
            <button type="button"
                    className="btn btn-coco-3 waves-effect waves-light wd-50p submit"
                    disabled={disabled}
                    onClick={checkUnable}
            >
                {GetMessageIntl(intl, 'details.disable')}
            </button>
        </div>
    );
}
