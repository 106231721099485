// @ts-nocheck
import React, {useCallback, useEffect, useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';

import StageItemContainer from "./stage-item";
import {Accordion} from "reactstrap";
import {updateStageTabActive} from "../../../../../store/dataset/details/action";

function StageListComponent(props: Props): JSX.Element {
    const dispatch = useDispatch();
    const questions = useSelector((state) => state.Label.Cmmn.module.labels);
    const labels = useSelector((state) => state.Label.Cmmn.annotations.states);
    const reviews = useSelector((state) => state.Review.Cmmn.annotations.data.classes);
    const [openIndex, setOpenIndex] = useState(0);
    const openActiveIndex = useSelector((state) => state.Details.stageTabActive.tab);

    useEffect(()=>{
        if(labels && labels.length>0){
            dispatch(updateStageTabActive(0));
        }
    }, [labels, reviews])

    const openToggle = (index) => {
        if (openActiveIndex !== index) {
            dispatch(updateStageTabActive(index));
        }
    };

    return (
        <>
        {labels && labels.length>0 &&
            <Accordion className="coco-objects-sidebar-stage-list custom-accordionwithicon" open={'1'} toggle={openToggle}>
                {/*/!*<GlobalHotKeys keyMap={subKeyMap} handlers={handlers} />*!/*/}
                    {labels.map(
                        (label: any, index: number): JSX.Element => {
                            let question = questions && questions.find((question) => question.id == label.id);
                            let review = reviews && reviews.find((review) => review.id == label.id);

                            return <StageItemContainer
                                containerKey={"labels-list"}
                                question={question}
                                label={label}
                                review={review}
                                key={label.id}
                                labelID={label.id}
                                viewID={index}
                                openToggle={openToggle}
                                lastIndex={labels.length == index+1}
                            />
                        }
                    )
                    }

            </Accordion>
        }
        </>
    );
}

export default React.memo(StageListComponent);
