import ShortcutsButton from "pages/user/label/annotation/image/components/header/right-group/shortcuts-button";
import {CombinedState} from "../../../../../../../../store/label";
import {switchShortcuts} from "../../../../../../../../store/label/action";
import {connect} from "react-redux";
import GlobalHotKeys from "../../../utils/mousetrap-react";
import React from "react";

interface StateToProps{
    visible: boolean;
    keyMap: any;
    normalizedKeyMap: any;
}
interface DispatchToProps{
    switchShortcuts(enabled: boolean): void;
}

function mapStateToProps(state: CombinedState): StateToProps {
    const {
        Label: {
            Cmmn: {
                canvas: {shortcuts: {visible}}
            }
        },
        shortcuts: { keyMap, normalizedKeyMap }
    } = state;

    return {
        visible,
        keyMap,
        normalizedKeyMap
    }
}

function mapDispatchToProps(dispatch: any): DispatchToProps {
    return {
        switchShortcuts(enabled: boolean): void {
            dispatch(switchShortcuts(enabled));
        },
    };
}
type Props = StateToProps & DispatchToProps;
function ShortCutsButtonContainer(props: Props): JSX.Element {
    const {
        keyMap,
        normalizedKeyMap,
        visible,
        switchShortcuts } = props;

    const toggle = () => {
        switchShortcuts(!visible);
    }

    const preventDefault = (event: KeyboardEvent | undefined): void => {
        if (event) {
            event.preventDefault();
        }
    };

    const subKeyMap: any = {
        SWITCH_SHORTCUTS: keyMap.SWITCH_SHORTCUTS
    };

    const handlers: any = {
        SWITCH_SHORTCUTS: (event: KeyboardEvent | undefined) => {
            preventDefault(event);
            toggle();
        }
    }

    return (
        <>
            <GlobalHotKeys keyMap={subKeyMap} handlers={handlers} />
            <ShortcutsButton visible={visible} toggle={toggle}
                             keyMap={keyMap}
                             normalizedKeyMap={normalizedKeyMap}
            />
        </>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(ShortCutsButtonContainer);
