// @ts-nocheck
import {TaskSideBar} from "./components/taskSideBar/TaskSideBar";
import {RightGroupContainer} from "./containers/header/RightGroupContainer";
import CanvasHeaderContainer from "../annotation/image/containers/CanvasHeader";
import {AudioCanvas} from "./inputArea/audio/AudioCanvas";
import {TextCanvas} from "./inputArea/text/TextCanvas";
import {VideoCanvas} from "./inputArea/video/VideoCanvas";
import {DATA_TYPE, MD_TY_CD} from "../annotation/image/const";
import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {CanvasLayout} from "../cmmn/components/CanvasLayout";

export const ClsfcSmryWorkspace = (): JSX.Element => {
    return (
        <>
            <div id={"clcl"} >
                <CanvasHeaderContainer rightGroupContainer={<RightGroupContainer/>} />
                <CanvasLayout className={"content-container clsfc-smry"}/>
                <TaskSideBar/>
            </div>
        </>
    )
}
