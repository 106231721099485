import {ReviewTitle} from "../ReviewTitle";
import {GetMessage} from "../../../../../../../util/message";
import {ReviewRejectReasons} from "../../../containers/taskSideBar/classification/ReviewRejectReasons";

interface Props{
    reject: boolean;    // reject이 false라면 아래 props는 null 가능
    hookform?: any;
    questionKeyName?: string;
}
export const ReviewResultView = ({hookform, questionKeyName, reject}: Props): JSX.Element => {
    return(
        <>
            <div className={"review-result text-coco-gray-700"}>
                <ReviewTitle reject={reject}/>
                {reject &&
                    <>
						<div className={"fs-16 fw-medium"}>
                            {GetMessage("review.rejectReason")}
							<span className={"text-coco-danger-400"}>*</span>
						</div>
						<ReviewRejectReasons hookform={hookform} questionKeyName={questionKeyName}/>
                    </>
                }
            </div>
        </>
    )
}
