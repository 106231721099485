// @ts-nocheck
import React from 'react';
import Popover from 'antd/lib/popover';

import { Canvas } from 'pages/user/label/annotation/image/cvat-canvas-wrapper';
import { ShapeType } from 'store/label';

import withVisibilityHandling from './handle-popover-visibility';
import {ShapeIcon} from "../../../const";
import {Button} from "reactstrap";
import DrawShapeButtonContainer
    from "pages/user/label/annotation/image/containers/standard-workspace/controls-side-bar/draw-shape-button";

export interface Props {
    canvasInstance: Canvas;
    isDrawing: boolean;
    disabled?: boolean;
}

const CustomPopover = withVisibilityHandling(Popover, 'draw-points');
function DrawPointsControl(props: Props): JSX.Element {
    const { canvasInstance, isDrawing, disabled } = props;
    const dynamicPopoverProps = isDrawing ? {
        overlayStyle: {
            display: 'none',
        },
    } : {};

    const dynamicIconProps = isDrawing ? {
        className: ['coco-draw-points-control', 'coco-active-canvas-control'],
    } : {
        className: 'coco-draw-points-control',
    };

    return disabled ? (
        <Button className={'btn-icon rounded-pill labelShape coco-draw-shape-button coco-draw-points-control disabled'}
                color={"coco-ghost"}
        >
            <i className={ShapeIcon[ShapeType.POINTS]} />
        </Button>
        // <Icon className='cvat-draw-points-control cvat-disabled-canvas-control' component={PointIcon} />
    ) : (
        <DrawShapeButtonContainer shapeType={ShapeType.POINTS} {...dynamicIconProps} />
        // <CustomPopover
        //     {...dynamicPopoverProps}
        //     overlayClassName='cvat-draw-shape-popover'
        //     placement='right'
        //     content={<DrawShapePopoverContainer shapeType={ShapeType.POINTS} />}
        // >
        //     <Icon {...dynamicIconProps} component={PointIcon} />
        // </CustomPopover>
    );
}

export default React.memo(DrawPointsControl);
