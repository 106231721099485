import SimpleBar from "simplebar-react";
import {
    ButtonGroup,
    Card,
    CardBody,
    CardHeader,
    Col,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    ListGroup,
    OffcanvasBody,
    UncontrolledDropdown
} from "reactstrap";
import React, {useEffect, useState} from "react";
import {useIntl} from "react-intl";
import {useDispatch, useSelector} from "react-redux";
import LabelRegistDataRow from "./data/LabelRegistDataRow";
import LabelRegistDataForm from "./data/LabelRegistDataForm";
import LabelRegistDataEditorForm from "./data/LabelRegistDataEditorForm";
import LabelRegistDataCheckboxForm from "./data/LabelRegistDataCheckboxForm";
import {
    setManageWorkflowLabelActiveData,
    setManageWorkflowLabelDataConfig,
    setManageWorkflowLabelDataError,
    setManageWorkflowLabelInactiveData,
    setManageWorkflowLabelSelectedRowId,
    setManageWorkflowLabelViewType
} from "../../../../../../../../store/manage/project/workflow/label/action";
import {GetMessageIntl} from "../../../../../../../../util/message";
import CommonUtil from "../../../../../../../../util/CommonUtil";
import {ReactSortable} from "react-sortablejs";
import DuplicateSettingModal from "./data/DuplicateSettingModal";

function DataConfigDiv() {
    const intl = useIntl();
    const dispatch = useDispatch();
    const prjctId = sessionStorage.getItem("prjctId");

    const [duplicateModalFlag, setDuplicateModalFlag] = useState(false);
    const switchDuplicateModalFlag = () => {
        setDuplicateModalFlag(!duplicateModalFlag);
    }

    const {activeData, inactiveData, viewType, dataConfig, selectedId, refreshFlag} = useSelector((state) => ({
        activeData: state.ManageWorkflowLabel.activeData,
        inactiveData: state.ManageWorkflowLabel.inactiveData,
        viewType: state.ManageWorkflowLabel.viewType,
        dataConfig: state.ManageWorkflowLabel.dataConfig,
        selectedId: state.ManageWorkflowLabel.selectedId,
        refreshFlag: state.ManageWorkflowLabel.refreshFlag
    }));

    useEffect(() => {
        dispatch(setManageWorkflowLabelDataError([]));
        dispatch(setManageWorkflowLabelSelectedRowId(null));

        return (() => {
                dispatch(setManageWorkflowLabelViewType(""))
            })
    }, []);

    useEffect(() => {
        dispatch(setManageWorkflowLabelDataConfig([...activeData, ...inactiveData]))
    }, [activeData, inactiveData])


    const dropdownList = [
        {
            label : '자료 복사', type : 'duplicated'
        },
        {
            label : GetMessageIntl(intl, "workflow.dataConfig.editor"), type : 'editor'
        },
        {
            label : GetMessageIntl(intl, "workflow.dataConfig.checkbox"), type : 'checkbox'
        },
        {
            label : GetMessageIntl(intl, "workflow.dataConfig.form"), type : 'form'
        },
    ];


    const addNewData = (type) => {
        let updatedActiveData;
        let newId = 1;
        let newOrder = 1;

        switch (type) {
            case 'editor':
                let editorFormObj = {
                    "id": "",
                    "body": {
                        "title": "",
                        "content": "",
                        "useTarget": "",
                        "attachFiles": []
                    },
                    "deleteFiles": [],
                    "type": "editor",
                    "title": "",
                    "useYn": "Y"
                }

                if(dataConfig.length > 0){
                    const totalData = [...activeData, ...inactiveData];
                    newId = Math.max(...totalData.map((item) => parseInt(item.id))) + 1;
                    if(activeData.length > 0){
                        newOrder = Math.max(...activeData.map(item => item.order)) + 1;
                    }
                }

                editorFormObj.id = newId.toString();
                editorFormObj.subId = CommonUtil.generateUID();
                editorFormObj.title = `Untitled_${type}(${newId})`;
                editorFormObj.order = newOrder;

                editorFormObj.body.useTarget = "03"
                updatedActiveData = [...activeData, editorFormObj];
                dispatch(setManageWorkflowLabelActiveData(updatedActiveData));
                break;
            case 'checkbox':
                const checkboxObj = {
                    "id": "",
                    "body": {
                        "title": "",
                        "content": "",
                        "attachFiles": []
                    },
                    "deleteFiles": [],
                    "type": "checkbox",
                    "title": "",
                    "useYn": "Y"
                }

                if(dataConfig.length > 0){
                    const totalData = [...activeData, ...inactiveData];
                    newId = Math.max(...totalData.map((item) => parseInt(item.id))) + 1;
                    if(activeData.length > 0){
                        newOrder = Math.max(...activeData.map(item => item.order)) + 1;
                    }
                }

                checkboxObj.id = newId.toString();
                checkboxObj.subId = CommonUtil.generateUID();
                checkboxObj.title = `Untitled_${type}(${newId})`;
                checkboxObj.order = newOrder;

                updatedActiveData = [...activeData, checkboxObj];
                dispatch(setManageWorkflowLabelActiveData(updatedActiveData));
                break;
            case 'form':
                const formObj = {
                    "id": "",
                    "body": {
                        "title": "",
                        "content": [],
                        "description": ""
                    },
                    "type": "form",
                    "order": 2,
                    "title": "",
                    "useYn": "Y"
                }

                if(dataConfig.length > 0){
                    const totalData = [...activeData, ...inactiveData];
                    newId = Math.max(...totalData.map((item) => parseInt(item.id))) + 1;
                    if(activeData.length > 0){
                        newOrder = Math.max(...activeData.map(item => item.order)) + 1;
                    }
                }

                formObj.id = newId.toString();
                formObj.subId = CommonUtil.generateUID();
                formObj.title = `Untitled_${type}(${newId})`
                formObj.order = newOrder;

                updatedActiveData = [...activeData, formObj];
                dispatch(setManageWorkflowLabelActiveData(updatedActiveData));
                break;
        }
    }

    function handleClickDropDownItem(formType) {
        switch (formType) {
            case 'editor':
            case 'checkbox':
            case 'form':
                addNewData(formType)
                break;
            case 'duplicated':
                switchDuplicateModalFlag();
                break;
        }
    }


    function renderComponentByType(type) {
        switch (type) {
            case 'E':
                return <LabelRegistDataEditorForm/>;
            case 'C':
                return <LabelRegistDataCheckboxForm/>;
            case 'F':
                return  <LabelRegistDataForm />;
            default:
                return (
                    <div className={"d-flex align-items-center justify-content-center"}
                         style={{height: "calc(100vh - 230px)"}}>
                        <span className="text-center ht-30 text-coco-gray-400">{GetMessageIntl(intl, "workflow.dataConfig.noChoicedData")}</span>
                    </div>
                )
        }
    }

    const handleDeleteEvent = () => {
        if(selectedId){
            const findData = dataConfig.find((target) => target.id === selectedId);
            if(findData && findData.useYn === "Y"){
                // 활성화 상태인 데이터 삭제 Order
                let newOrder = 0;
                let updateData = activeData.filter((filteredData) => filteredData.id !== selectedId);
                if(updateData.length > 0){
                    updateData = updateData.map(item => {
                        newOrder += 1;
                        return {
                            ...item,
                            order : newOrder
                        }
                    })
                }
                dispatch(setManageWorkflowLabelActiveData(updateData))
            }else{
                let updateData = inactiveData.filter((filteredData) => filteredData.id !== selectedId);
                dispatch(setManageWorkflowLabelInactiveData(updateData))
            }
            dispatch(setManageWorkflowLabelViewType(""))
        }
    }

    const setActiveData = (items) =>{
        if(items.length != 0){
            let newOrder = 0;
            const processedItems = items.map(item => {
                newOrder += 1;
                return {
                    ...item,
                    order: newOrder
                }
            })
            dispatch(setManageWorkflowLabelActiveData(processedItems));
        }
    }
    const setInactiveData = (items) =>{
        if(items.length != 0){
            dispatch(setManageWorkflowLabelInactiveData(items));
        }
    }

    return(
        <>
            <SimpleBar style={{ maxHeight: "calc(100vh - 228px)" }} autoHide={false} className="simplebar-track-coco">
                <OffcanvasBody style={{ minHeight: "300px", padding : "0px 0px"}} >
                    <div className={"d-flex justify align-items-start justify-content-center"}>
                        <Col className={"col-xl-4-5 registDataDiv"}>
                            <Card className="shadow-none mb-0" style={{borderBottom:"1px solid #CFD8DC"}}>
                                <CardHeader className="border-0 d-flex align-items-center justify-content-between tx-16 lh-18 text-coco-gray-700 fw-500">
                                    {GetMessageIntl(intl, "workflow.dataConfig.activeData")}
                                    <div className="d-flex align-items-center text-coco-gray-400">
                                        <ButtonGroup>
                                            <UncontrolledDropdown>
                                                <DropdownToggle tag="button" type="button" className="btn pd-0 d-flex">
                                                    <i className="ri-add-circle-line me-1 text-pointer text-coco-gray-400"></i>
                                                </DropdownToggle>
                                                <DropdownMenu>
                                                    {
                                                        dropdownList.length > 0 ?
                                                            dropdownList.map((form, idx) => {
                                                                return (
                                                                    <DropdownItem key={"form_".concat(form.type)} onClick={() => handleClickDropDownItem(form.type)}>{form.label}</DropdownItem>
                                                                )
                                                            })
                                                            : null
                                                    }
                                                </DropdownMenu>
                                            </UncontrolledDropdown>
                                        </ButtonGroup>
                                        <i className="ri-delete-bin-line deleteProjectClass text-pointer" onClick={handleDeleteEvent}></i>
                                    </div>
                                </CardHeader>
                                <CardBody className="p-0 projectClassListDiv">
                                    <SimpleBar className="table-responsive" style={{height: "calc(100vh - 585px)"}}>
                                        {
                                            activeData.length > 0 ?
                                                <ListGroup className="text-coco-gray-700 mb-0">
                                                    <ReactSortable list={activeData} setList={setActiveData} handle=".handle">
                                                        <LabelRegistDataRow data={activeData}/>
                                                    </ReactSortable>
                                                </ListGroup>
                                                :
                                                <div className={"d-flex align-items-center justify-content-center"}
                                                     style={{height: "calc(100vh - 590px)"}}>
                                                    <span className="text-center ht-30 text-coco-gray-400">{GetMessageIntl(intl, "workflow.dataConfig.requestRegistData")}</span>
                                                </div>
                                        }
                                    </SimpleBar>
                                </CardBody>
                            </Card>
                            <Card className="shadow-none mb-0">
                                <CardHeader className="border-0 d-flex align-items-center justify-content-between tx-16 lh-18 text-coco-gray-700 fw-500">
                                    {GetMessageIntl(intl, "workflow.dataConfig.inactiveData")}
                                </CardHeader>
                                <CardBody className="p-0 projectClassListDiv">
                                    <SimpleBar className="table-responsive" style={{height: "calc(100vh - 675px)"}}>
                                        {
                                            inactiveData.length > 0 ?
                                                <ListGroup className="text-coco-gray-700 mb-0">
                                                    <ReactSortable list={inactiveData} setList={setInactiveData} handle=".handle">
                                                        <LabelRegistDataRow data={inactiveData}/>
                                                    </ReactSortable>
                                                </ListGroup>
                                                :
                                                <div className={"d-flex align-items-center justify-content-center"}
                                                     style={{height: "calc(100vh - 680px)"}}>
                                                    <span className="text-center ht-30 text-coco-gray-400">{GetMessageIntl(intl, "workflow.dataConfig.noInactiveData")}</span>
                                                </div>
                                        }
                                    </SimpleBar>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col className={"col-xl-7-5"} style={{borderLeft: "1px dashed var(--coco-gray-100)"}}>
                            {
                                renderComponentByType(viewType)
                            }
                        </Col>
                    </div>
                </OffcanvasBody>
            </SimpleBar>
            {
                duplicateModalFlag && <DuplicateSettingModal modalFlag={duplicateModalFlag} switchModalFlag={switchDuplicateModalFlag} prjctId={prjctId}/>
            }
        </>
    );
}


export default DataConfigDiv;

