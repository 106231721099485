export const SET_SELECTED_FILE = "workflow/review/testConfig/SET_SELECTED_FILE";
export const VALUE_OF_FILES = "workflow/review/testConfig/VALUE_OF_FILES";
export const SET_FILES = "workflow/review/testConfig/SET_FILES";
export const SET_FILE_LABELS = "workflow/review/testConfig/SET_FILE_LABELS";
export const SET_FILE_ATTRIBUTES = "workflow/review/testConfig/SET_FILE_ATTRIBUTES";
export const SET_FILE_COMMENT = "workflow/review/testConfig/SET_FILE_COMMENT";
export const REMOVE_FILE = "workflow/review/testConfig/REMOVE_FILE";
export const SET_FILE_REJECT_REASON = "workflow/review/testConfig/SET_FILE_REJECT_REASON";
export const SET_HAS_ERROR_FILES = "workflow/review/testConfig/SET_HAS_ERROR_FILES";
export const CLEAR_HAS_ERROR_FILES = "workflow/review/testConfig/CLEAR_HAS_ERROR_FILES";
export const CLEAR_HAS_ERROR_FILE = "workflow/review/testConfig/CLEAR_HAS_ERROR_FILE";
export const RESET_FILE_LABELS = "workflow/review/testConfig/RESET_FILE_LABELS";





