import React, { memo } from 'react';
import {Handle, useReactFlow, useStoreApi, Position, useStore, useNodeId} from 'reactflow';
import {Progress} from "reactstrap";
import {
    manage_project_workflow_module_setIsDatasetOffcanvas, manage_project_workflow_module_setIsModuleOffcanvas
} from "../../../../../../store/manage/project/workflow/module/action";
import {useDispatch, useSelector} from "react-redux";
import {thisSourceValidConnection, thisTargetValidConnection} from "./nodeCommonUtil";
import {GetMessageIntl} from "../../../../../../util/message";
import {useIntl} from "react-intl";
import CommonUtil from "../../../../../../util/CommonUtil";
import audioWaveImg from "../../../../../../assets/images/task/audio_wave.png";
const connectionNodeIdSelector = (state) => {
    return state.connectionNodeId;
}
const connectionHandleTypeSelector = (state) => {
    return state.connectionHandleType;
}
const nodesSelector = (state) => Array.from(state.nodeInternals.values());

export default function DatasetNode({ id, data, isConnectable }) {
    const intl = useIntl();
    const dispatch = useDispatch();
    const nodes = useStore(nodesSelector);
    const connectionNodeId = useStore(connectionNodeIdSelector);
    const connectionHandleType = useStore(connectionHandleTypeSelector);
    const {selectionNodeId} = useSelector((state)=>({
        selectionNodeId : state.ManageWorkflowModule.selectionNodeId
    }));
    let connectionNodeType = '';
    if(connectionNodeId){
        let connectionNode = nodes.find((item) => item.id === connectionNodeId);
        let parentNodeType = '';
        if(connectionNode.parentNode){
            parentNodeType = nodes.find((item) => item.id === connectionNode.parentNode).type+'_';
        }
        connectionNodeType = parentNodeType + connectionNode.type;
    }
    const isTarget = connectionNodeId && connectionNodeId !== id && thisTargetValidConnection('dataset', connectionNodeType) && connectionHandleType == 'source';
    const targetHandleStyle = isTarget? { visibility: 'visible', zIndex: 10 } : {visibility: 'hidden', zIndex:1};

    const isSourceValidConnection = (connection) => {
        let connectSourceType = nodes.find((item) => item.id === connection.target).type;
        return thisSourceValidConnection('dataset',connectSourceType);
    }
    const isSource = (selectionNodeId && selectionNodeId == id)  || (connectionHandleType == 'target' && thisSourceValidConnection('dataset', connectionNodeType));
    const sourceHandleStyle = isSource? {visibility: 'visible', zIndex: 10} : { visibility: 'hidden', zIndex:1};

    let dataTy = '1';
    if(data && data.canvasDataTy){
        dataTy = data.canvasDataTy.substring(0,1);
    }

    return (
        <>
            <div className="d-flex align-items-center mb-12">
                <div className="flex-shrink-0 avatar-42">
                    <div className="avatar-title fs-20 rounded-circle" style={{backgroundColor:'#FFF2D0', color:'#FFC130'}}>
                        <i className={dataTy=='1'? "ri-image-line"
                            : dataTy=='2'? "ri-voiceprint-line"
                                : dataTy == '3'? "ri-video-line"
                                : "ri-text"
                            .concat(" lh-19")}></i>
                    </div>
                </div>
                <div className="flex-grow-1 text-overflow ml-13">
                    <h6 className="text-coco-gray-200 tx-12 lh-16 mb-2">START</h6>
                    <span className="text-coco-gray-700 tx-16 lh-20 fw-semibold">{data?data.moduleNm:null}</span>
                </div>
            </div>
            <div className="bg-coco-gray-50 pd-10 node_info flex-shrink-0 mb-12">
                <div className="d-flex align-items-center">
                    <div style={{width:"123px"}} className="ht-18">
                        <label style={{width:"25px"}}>{GetMessageIntl(intl, "common.register")}</label>
                        {data&&data.datasetConfig? data.datasetConfig.regDtmText:""}
                    </div>
                    <div className="pd-l-10 ht-18" style={{borderLeft: "1px solid var(--coco-gray-100)"}}>
                        <label>{GetMessageIntl(intl, "common.order")}</label>
                        {data?data.useOrd:null}
                    </div>
                </div>
                <div className="ht-18 mt-6">
                    <label>{GetMessageIntl(intl, "common.fileCount")}</label>
                    {data&&data.datasetConfig&& data.datasetConfig.fileCount?
                        data.datasetConfig.fileCount
                        :
                        0
                    }
                </div>
                <div className="ht-18 mt-6">
                    <label>{GetMessageIntl(intl, "common.fileSize")}</label>
                    {data&&data.datasetConfig&& data.datasetConfig.totalFileSize?
                        CommonUtil.bytesToSize(data.datasetConfig.totalFileSize)
                        :
                        0
                    }
                </div>
            </div>
            <div className={"d-flex align-items-center ht-55 mb-12 ".concat(dataTy == '1' || dataTy=='2' || dataTy == '3'? "image-info justify-content-center":"")}>
                {
                    dataTy == '1' || dataTy == '3'?
                        <>
                            <div className="default-data d-flex align-items-center justify-content-center">
                                {data&&data.thumnail1?
                                    dataTy == '3' ? <div className={"position-relative"} style={{top:'2px'}}><img src={data.thumnail1}  className={"position-absolute"}/> <i className={"ri-play-circle-fill tx-24 position-absolute"}  style={{top:'10px', 'left':'28px'}}/></div> : <img src={data.thumnail1}/>
                                    :
                                    <>img</>
                                }
                            </div>
                            <div className="default-data d-flex align-items-center justify-content-center ml-2">
                                {data&&data.thumnail2?
                                    dataTy == '3' ? <div className={"position-relative"} style={{top:'2px'}}><img src={data.thumnail2}  className={"position-absolute"}/> <i className={"ri-play-circle-fill tx-24 position-absolute"}  style={{top:'10px', 'left':'28px'}}/></div> : <img src={data.thumnail2}/>
                                    :
                                    <>img</>
                                }
                            </div>
                            <div className="default-data d-flex align-items-center justify-content-center ml-2">
                                {data&&data.thumnail3?
                                    dataTy == '3' ? <div className={"position-relative"} style={{top:'2px'}}><img src={data.thumnail3}  className={"position-absolute"}/> <i className={"ri-play-circle-fill tx-24 position-absolute"}  style={{top:'10px', 'left':'28px'}}/></div> : <img src={data.thumnail3}/>
                                    :
                                    <>img</>
                                }
                            </div>
                        </>
                        :
                        dataTy == '2'?
                            data && data.datasetConfig && data.datasetConfig.fileCount && data.datasetConfig.fileCount>0?
                                <img src={audioWaveImg} style={{WebkitUserDrag: 'none'}}/>
                                : <span className={"text-coco-gray-400"}>{GetMessageIntl(intl, "dataset.noFiles")}</span>
                            :
                            data && data.datasetConfig && data.datasetConfig.fileCount && data.datasetConfig.fileCount>0?
                                <div className={"flex-row wd-100p"}>
                                    {data&&data.thumnail1?
                                        <div className={"d-flex align-items-center tx-12 text-overflow"}>
                                            <div className="flex-shrink-0 avatar-xxxs mr-10">
                                                <div className="avatar-title rounded-circle" style={{backgroundColor:'#E4F3FF', color:'#44A9FF'}}>
                                                    <i className={"ri-text"}></i>
                                                </div>
                                            </div>
                                            <div className={"text-coco-gray-700 text-overflow"}>{data.thumnail1}</div>
                                        </div>
                                        :
                                        <></>
                                    }
                                    {data&&data.thumnail2?
                                        <div className={"d-flex align-items-center tx-12 text-overflow"}>
                                            <div className="flex-shrink-0 avatar-xxxs mr-10">
                                                <div className="avatar-title rounded-circle" style={{backgroundColor:'#E4F3FF', color:'#44A9FF'}}>
                                                    <i className={"ri-text"}></i>
                                                </div>
                                            </div>
                                            <div className={"text-coco-gray-700 text-overflow"}>{data.thumnail2}</div>
                                        </div>
                                        :
                                        <></>
                                    }
                                    {data&&data.thumnail3?
                                        <div className={"d-flex align-items-center tx-12 text-overflow"}>
                                            <div className="flex-shrink-0 avatar-xxxs mr-10">
                                                <div className="avatar-title rounded-circle" style={{backgroundColor:'#E4F3FF', color:'#44A9FF'}}>
                                                    <i className={"ri-text"}></i>
                                                </div>
                                            </div>
                                            <div className={"text-coco-gray-700 text-overflow"}>{data.thumnail3}</div>
                                        </div>
                                        :
                                        <></>
                                    }
                                </div>
                                : <span className={"text-coco-gray-400"}>{GetMessageIntl(intl, "dataset.noFiles")}</span>

                }

            </div>
            <div>
                <Progress value={data&&data.datasetConfig&& data.datasetConfig.asgnRate?
                    data.datasetConfig.asgnRate:0
                } className="progress-coco-primary-400 bg-gradient ht-12">
                    {data&&data.datasetConfig&& data.datasetConfig.asgnRate?
                        data.datasetConfig.asgnRate:0
                    }%
                </Progress>
            </div>

            <Handle
                className="sourceHandle"
                style={sourceHandleStyle}
                position={Position.Top}
                id={'top'}
                type="source"
                isConnectable={isConnectable}
                isValidConnection={isSourceValidConnection}
            />
            <Handle
                className="sourceHandle"
                style={sourceHandleStyle}
                position={Position.Right}
                id={'right'}
                type="source"
                isConnectable={isConnectable}
                isValidConnection={isSourceValidConnection}
            />
            <Handle
                className="sourceHandle"
                style={sourceHandleStyle}
                position={Position.Bottom}
                id={'bottom'}
                type="source"
                isConnectable={isConnectable}
                isValidConnection={isSourceValidConnection}
            />
            <Handle
                className="sourceHandle"
                style={sourceHandleStyle}
                position={Position.Left}
                id={'left'}
                type="source"
                isConnectable={isConnectable}
                isValidConnection={isSourceValidConnection}
            />

            <Handle
                className="targetHandle"
                style={targetHandleStyle}
                position={Position.Top}
                id={'top'}
                type="target"
                isConnectable={isConnectable}
            />
            <Handle
                className="targetHandle"
                style={targetHandleStyle}
                position={Position.Right}
                id={'right'}
                type="target"
                isConnectable={isConnectable}
            />
            <Handle
                className="targetHandle"
                style={targetHandleStyle}
                position={Position.Bottom}
                id={'bottom'}
                type="target"
                isConnectable={isConnectable}
            />
            <Handle
                className="targetHandle"
                style={targetHandleStyle}
                position={Position.Left}
                id={'left'}
                type="target"
                isConnectable={isConnectable}
            />
        </>
    );
}