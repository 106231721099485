import React, {useState} from "react";
import {ResizableBox} from "react-resizable";
import "./resizableLayoutTestStyle.css";
import CanvasHeader from "../manager/dataset/details/header/CanvasHeader";
import {CustomHandle} from "./CustomHandle";
import styled from "styled-components";
import {Workspace} from "../../store/label";
import ReactPlayer from "react-player";
import {SearchHeader} from "../manager/dataset/details/left-side-bar/search-header";
import {DataList} from "../manager/dataset/details/left-side-bar/data-list";

const ResizableContainer = styled.div`
  display: flex;
  flex-direction: row;
  height: 92vh;
`

const LeftSideBarContainer = styled.div`
      height: 100%;
      color: var(--coco-gray-700);
          
    #search-input-file {
      padding: 16px 24px;
    }
    
    .search-condition {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 16px;
      margin-top: 12px;
    
      .title {
        color: var(--coco-gray-700);
      }
    }
`

export const ResizableLayoutTest = () => {
    const [leftSidebar, setLeftSideBar] = useState(true);
    const [leftSidebarWidth, setLeftSidebarWidth] = useState(290);
    const [rightSidebar, setRightSideBar] = useState(true);
    const [rightSidebarWidth, setRightSidebarWidth] = useState(290);

    const handleLeftResize = (event) => {
        setLeftSideBar(true);
        if(event.clientX > 540){
            setLeftSidebarWidth(540);
        }else{
            setLeftSidebarWidth(event.clientX);
        }
    }

    const handleLeftResized = (event) => {
        if(event.clientX < 100){
            setLeftSideBar(false);
            setLeftSidebarWidth(0);
            return;
        }
    }

    const handleRightResize = (event, { size }) => {
        setRightSideBar(true);
        if(window.innerWidth - event.clientX > 540){
            setRightSidebarWidth(540)
        }else{
            setRightSidebarWidth(window.innerWidth - event.clientX)
        }
    }

    const handleRightResized = (event) => {
        if(window.innerWidth - event.clientX < 100){
            setRightSideBar(false);
            setRightSidebarWidth(0);
            return ;
        }
    }

    const heigth = window.innerHeight * 0.92;

    return (
        <div className={"clcl"}>
            <CanvasHeader type={Workspace.MANAGER_WORKSPACE} />

            {/* LeftSideBar */}
            <ResizableContainer>
                <ResizableBox
                    width={leftSidebarWidth}
                    height={heigth}
                    axis="x"
                    handle={(handleProps, ref) =>  <CustomHandle innerRef={ref} {...handleProps} direction={"left"} position={leftSidebarWidth} />}
                    onResize={handleLeftResize}
                    onResizeStop={handleLeftResized}
                    minConstraints={[1, 100]}
                    maxConstraints={[540, Infinity]}
                >
                    {
                        leftSidebar && (
                            <LeftSideBarContainer id={`clsfcSmry-manager-canvas`} width={leftSidebarWidth}>
                                <SearchHeader />
                                <hr/>
                                <DataList />
                            </LeftSideBarContainer>
                        )
                    }
                </ResizableBox>

                {/* MainContent */}
                <div style={{flex: 1, height : "93%"}}>
                    {
                        <>
                            <ReactPlayer
                                // ref={playerRef}
                                url={"https://streamable.com/ifjh"}
                                playing={true}
                                // volume={volume}
                                // playbackRate={speed}
                                // onDuration={handleDuration}
                                // onProgress={handleCurrentTime}
                                width={"100%"}
                                height={"100%"}
                            />
                            {/*<VideoControlBar playerRef={playerRef} values={values} handleValues={handleValues}/>*/}
                        </>
                    }
                </div>


                {/* RightSideBar */}
                <ResizableBox
                    width={rightSidebarWidth}
                    height={heigth}
                    axis="x"
                    resizeHandles={["w"]}
                    handle={(handleProps, ref) => <CustomHandle innerRef={ref} {...handleProps} direction={"right"} position={rightSidebarWidth}/>}
                    onResize={handleRightResize}
                    onResizeStop={handleRightResized}
                    minConstraints={[1, 100]}
                    maxConstraints={[540, Infinity]}
                >
                    {
                        rightSidebar && (
                            <div style={{ backgroundColor: "lightgreen", height: "100%" }}>
                                세 번째 영역
                            </div>
                        )
                    }
                </ResizableBox>
            </ResizableContainer>
        </div>
    )
}