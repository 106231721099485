import {callApi} from '../utils/AxiosUtil';

/**
 *  라벨링 공통 관련 서비스
 */
export const LabelCmmnService = {
	// module config 조회
	getProjectInfo: (param) => callApi('/service/label/getAttrList', param),
	/**
	 * 라벨링 결과 저장
	 * @param param{{moduleId, datasetId, fileSeq, annotation}}
	 * @returns {Promise<axios.AxiosResponse<any>>}
	 */
	save: (param) => callApi('/service/label/save', param),
	/**
	 * 현재 작업 건너뛰기
	 * @param param{{moduleId, datasetId, fileSeq, issueCn}}
	 * @returns {Promise<axios.AxiosResponse<any>>}
	 */
	skip: (param) => callApi('/service/label/skip', param),

	//다음 라벨링 이미지
	getNextItem: (param) => callApi('/service/label/getNextItem', param),
	// 라벨링 작업 할당 요청
	jobTargetBind: (param) => callApi('/service/label/jobTargetBind', param),

	/**
	 * 이미지 라벨링 캔버스 진입전, 작업 할당 관련 세팅 확인
	 * 00 : 기본 상태
	 * 11 : 신규 사용자이며, 데이터를  할당함 : 캔버스로 이동
	 * 12 : 이전에 받았던 데이터가 있음 : 캔버스로 이동
	 * 13 : 이전에 받았던 데이터가 있으며, 할당받은 데이터가 없으므로 새로 받을건지 물어봄
	 * 04 : 할당 가능한 데이터가 남아있지 않음
	 * 05 : 프로젝트 상태가 진행중이 아님
	 * @param param
	 * @returns {Promise<axios.AxiosResponse<any>>}
	 */
	checkBefore: (param) => callApi('/service/label/checkBefore', param),

	/**
	 * 라벨링 결과 json 불러오기
	 * moduleId, datasetId, fileSeq
	 * @param param
	 * @returns {Promise<axios.AxiosResponse<any>>}
	 */
	jsonData: (param) => callApi('/service/label/jsonData', param),

	/**
	 * 라벨링 결과 미리보기
	 * @param param{{moduleId, datasetId, fileSeq}}
	 * @returns {Promise<axios.AxiosResponse<any>>}
	 */
	jsonPreview: (param) => callApi('/service/label/jsonPreview', param),

	/**
	 * 최근 검수 결과 가져오기
	 * @param param{{moduleId, datasetId, fileSeq}}
	 * @returns {Promise<axios.AxiosResponse<any>>}
	 */
	getSavedItem: (param) => callApi('/service/label/getSavedItem', param),

}

