// @ts-nocheck
import {GetMessage, GetMessageIntl} from "../../../../../util/message";
import {useDispatch, useSelector} from "react-redux";
import {SttusDisplay} from "../../../../../store/dataset/details";
import {useIntl} from "react-intl";
import React, {useEffect} from "react";
import {JobInfoContent} from "./job-info-content";
import {getAnnotationsReasons} from "../../../../../store/review/action";
import {CommonService} from "../../../../../apis/api/CmmnService";
import {updateCanvasSkipPopover} from "../../../../../store/label/action";
import {CANVAS_DATA_TY} from "../../../../user/label/annotation/image/const";

// type에 따라 로우가 달라짐
export function JobInfo(props): JSX.Element {
    const intl = useIntl();
    const dispatch = useDispatch();
    const {
        Details: {
            fileInfo: {
                displayState: {
                    dataSttusDisplay: displayMode,
                },
                rejectInstTotalRsn
            }
        },
        Label: {
            Cmmn: {
                canvas: {
                    skipPopover: {reasons: skipRsn}
                },
                statistics: {
                    data: statisticsData
                },
                module : {
                    canvasDataTy
                }
            }
        },
        Review: {
            Cmmn: {
                annotations: {
                    reasons: rejectRsn
                }
            }
        }
    } = useSelector(state => {
        return state
    });

    const rsnCdList = ['01'];
    const instCnt = statisticsData ? statisticsData.totalData.total : 0;

    useEffect(() => {
        if (rejectRsn.length === 0) {
            dispatch(getAnnotationsReasons());
        }
    }, [])

    useEffect(() => {
        const getSkipRsnCd = async () => {
            let result;
            if (canvasDataTy === CANVAS_DATA_TY.IMAGE_ANNOTATION) {
                result = await CommonService.getSkipRsnCd();
            } else if (canvasDataTy === CANVAS_DATA_TY.IMAGE_CLSR_SMRY) {
                result = await CommonService.getSkipRsnCdForImageClsfcSmry();
            } else if (canvasDataTy === CANVAS_DATA_TY.AUDIO_CLSR_SMRY) {
                result = await CommonService.getSkipRsnCdForAudioClsfcSmry();
            } else if (canvasDataTy === CANVAS_DATA_TY.VIDEO_CLSR_SMRY) {
                result = await CommonService.getSkipRsnCdForVideoClsfcSmry();
            } else if (canvasDataTy === CANVAS_DATA_TY.TEXT_CLSR_SMRY) {
                result = await CommonService.getSkipRsnCdForTextClsfcSmry();
            }

            const data = result.data;
            dispatch(updateCanvasSkipPopover(null, data.data))
        }
        if (skipRsn.length === 0) {
            getSkipRsnCd();
        }
    }, []);

    useEffect(() => {
        if (displayMode === SttusDisplay.WORKING) {

        } else if (displayMode === SttusDisplay.SUBMIT) {

        }
    }, [displayMode])

    return (
        <div className={"info-section"}>
            <div className={"title mb-12 fw-bold"}>{GetMessage('details.lastWorkInfo')}</div>
            {( displayMode === SttusDisplay.STANDBY || displayMode === null) &&
                <div className={"d-flex align-items-center justify-content-center text-coco-gray-200"} style={{height: "400px"}}>
                    {GetMessageIntl(intl, 'details.noWorkHistory')}
                </div>
            }
            {([SttusDisplay.WORKING, SttusDisplay.SUBMIT].includes(displayMode)) &&
                <>
					<JobInfoContent displayMode={displayMode} />
                </>
            }
        </div>
    );
}
