import React, {useState} from 'react';
import {ReactSortable} from "react-sortablejs";
import {Col, Row} from 'reactstrap';

const SortableTest = () => {

    const [state, setState] = useState([
        {id: 1, name: "shrek", color: "red"},
        {id: 2, name: "fiona", color: "green"},
        {id: 3, name: "3fiona", color: "red"},
        {id: 4, name: "4fiona", color: "green"},
        {id: 5, name: "5fiona", color: "red"},
        {id: 6, name: "6fiona", color: "green"},
        {id: 7, name: "7fiona", color: "red"},
    ]);

    return (
        <>
            <ReactSortable list={state} setList={setState}>
                {state.map((item) => (
                    <Row key={item.id}>
                        <Col style={{height: "40px", padding: "3px", backgroundColor: item.color}}>{item.name}</Col>
                    </Row>
                ))}
            </ReactSortable>
        </>
    );
}
export default SortableTest;