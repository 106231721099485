//@ts-nocheck
import React, {useEffect} from 'react';
import {
    ButtonGroup,
    Card,
    CardBody,
    CardHeader,
    Col,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    ListGroup,
    OffcanvasBody,
    UncontrolledDropdown
} from "reactstrap";
import SimpleBar from "simplebar-react";
import {useIntl} from "react-intl";
import {ReactSortable} from "react-sortablejs";
import {ContentView} from './ContentView';
import {useDispatch, useSelector} from "react-redux";
import {
    setManageWorkflowLabelSettingRowId,
    setManageWorkflowLabelSettingViewType
} from "../../../../../../../../store/manage/project/workflow/label/action";
import ClsfcSmrySettingContent from "./ClsfcSmrySettingContent";
import {GetMessageIntl} from "../../../../../../../../util/message";

interface Props {
    canvasDataTy: String;
    method: any;
    shapeConfig: any;
}

function PickAndContent(props: Props) {
    const intl = useIntl();
    const dispatch = useDispatch();
    const {canvasDataTy, method, shapeConfig} = props;
    const {
        clsfcSmrySelectedId,
        clsfcSmryViewType,
    } = useSelector((state) => ({
        clsfcSmrySelectedId: state.ManageWorkflowLabel.clsfcSmrySelectedId,
        clsfcSmryViewType: state.ManageWorkflowLabel.clsfcSmryViewType,
    }))


    const dropdownList = [
        // {
        //     label: '단계 복사', type: 'duplicated'
        // },
        {
            label: GetMessageIntl(intl, "workflow.shapeConfig.clsfc"), type: 'clsfc'
        },
        {
            label: GetMessageIntl(intl, "workflow.shapeConfig.summry"), type: 'smry'
        },
    ];

    useEffect(() => {
        return () => {
            dispatch(setManageWorkflowLabelSettingRowId(""));
            dispatch(setManageWorkflowLabelSettingViewType(""));
        }
    }, [])

    function handleDeleteEvent() {
        if(clsfcSmrySelectedId !== ""){
            const originData = method.getValues()?.labels;
            const filteredData = originData?.filter(item => item.id !== clsfcSmrySelectedId);

            const updatedData = filteredData?.map((item, index) => ({
                ...item,
                id: index,
                order: index
            }));

            dispatch(setManageWorkflowLabelSettingRowId(""));
            dispatch(setManageWorkflowLabelSettingViewType(""));

            method.setValue("labels", updatedData);
            method.clearErrors(`labels.${clsfcSmrySelectedId}`);
        }
    }

    const setSettingList = (items) => {
            const updateData = items.map((item, idx) => {
                return {
                    ...item,
                    id: idx,
                    order: idx
                }
            })

            method.setValue("labels", updateData);
    }

    const handleRowClick = (rowData) => {
        dispatch(setManageWorkflowLabelSettingRowId(rowData.id));
        switch (rowData.type) {
            case "clsfc" :
                dispatch(setManageWorkflowLabelSettingViewType("C"))
                break;
            case "smry" :
                dispatch(setManageWorkflowLabelSettingViewType("S"))
                break;
        }
    }

    const renderContentByType = (viewType) => {
        const values = method.getValues();
        if (values.labels && values.labels.length > 0 && (clsfcSmrySelectedId !== "" || clsfcSmrySelectedId === 0) && (clsfcSmryViewType !== "" || clsfcSmryViewType)) {
            const findData = values.labels.filter(item => item.id === clsfcSmrySelectedId)[0];

            return <ClsfcSmrySettingContent
                viewType={viewType}
                id={clsfcSmrySelectedId}
                method={method}
                data={findData}
            />
        }


        return (
            <div className={"d-flex align-items-center justify-content-center"}
                 style={{height: "calc(100vh - 231px)"}}>
                <span className="text-center ht-30 text-coco-gray-400">{GetMessageIntl(intl, "workflow.shapeConfig.selectSubtaskMsg")}</span>
            </div>
        )

    }

    const handleAddNewLabel = (formType) => {
        const labels = method.getValues()?.labels;

        let newId = 0;
        if (labels && labels.length > 0) {
            const maxId = labels.reduce((max, item) => Math.max(max, parseInt(item.id, 10)), 0);
            newId = maxId + 1;
        }

        let newTitle = `Untitle_Stage (${newId + 1})`
        const newLabel = (formType === "clsfc") ? {
            id: newId,
            type: "clsfc",
            question: newTitle,
            tip: "",
            order: newId,
            attributes : [{
                id: newId,
                values : [],
                required: undefined,
                inputType: ""
            }],
            parentId: null,
            hasParent: false,
        } : {
            id: newId,
            type: "smry",
            question: newTitle,
            inputExam: "",
            regexp: "",
            tip: "",
            order: newId,
            attributes : [{
                id: newId,
                min: 0,
                max: 1000,
                required: undefined
            }],
            parentId: null,
            hasParent: false,
        };

        let currentFormValues = method.getValues();
        let updatedClassItems = [...(currentFormValues.labels || []), newLabel];
        method.setValue('labels', updatedClassItems);

        dispatch(setManageWorkflowLabelSettingViewType(newLabel.type === "clsfc" ? "C" : "S"));
        dispatch(setManageWorkflowLabelSettingRowId(newLabel.id));
    };

    return (
        <>
            <SimpleBar style={{maxHeight: "calc(100vh - 228px)"}} autoHide={false} className="simplebar-track-coco">
                <OffcanvasBody style={{minHeight: "calc(100vh - 231px)", padding: "0px 0px"}}>
                    <div className={"d-flex justify align-items-start justify-content-center"}>
                        <Col className={"col-xl-4-5 registDataDiv"}>
                            <Card className="shadow-none mb-0">
                                <CardHeader
                                    className="border-0 d-flex align-items-center justify-content-between tx-16 lh-18 text-coco-gray-700 fw-500">
                                    {GetMessageIntl(intl, "workflow.shapeConfig.subTaskSetting")}
                                    <div className="d-flex align-items-center text-coco-gray-400">
                                        <ButtonGroup>
                                            <UncontrolledDropdown>
                                                <DropdownToggle tag="button" type="button" className="btn pd-0 d-flex">
                                                    <i className="ri-add-circle-line me-1 text-pointer text-coco-gray-400"></i>
                                                </DropdownToggle>
                                                <DropdownMenu>
                                                    {
                                                        dropdownList.length > 0 ?
                                                            dropdownList.map((form, idx) => {
                                                                return (
                                                                    <DropdownItem key={"form_".concat(form.type)}
                                                                        onClick={() => handleAddNewLabel(form.type)}
                                                                    >
                                                                        {form.label}
                                                                    </DropdownItem>
                                                                )
                                                            })
                                                            : null
                                                    }
                                                </DropdownMenu>
                                            </UncontrolledDropdown>
                                        </ButtonGroup>
                                        <i className="ri-delete-bin-line deleteProjectClass text-pointer"
                                           onClick={handleDeleteEvent}>
                                        </i>
                                    </div>
                                </CardHeader>
                                <CardBody className="p-0 projectClassListDiv">
                                    <SimpleBar className="table-responsive" style={{height: "calc(100vh - 284px)"}}>
                                        {
                                            // Data Row 표기
                                            shapeConfig && method.getValues()?.labels?.length > 0
                                                ? // True
                                                <ListGroup className="mb-0">
                                                    <ReactSortable
                                                        className={"labelsetting-sortable"}
                                                        list={method.getValues(`labels`) || []}
                                                        setList={setSettingList} handle=".handle"
                                                    >
                                                        <ContentView data={method.getValues(`labels`) || []}
                                                                     handleRowClick={handleRowClick}
                                                                     selectedId={clsfcSmrySelectedId}
                                                                     method={method}
                                                        />
                                                    </ReactSortable>
                                                </ListGroup>
                                                : // False
                                                <div className={"d-flex align-items-center justify-content-center"}
                                                     style={{height: "calc(100vh - 335px)"}}>
                                                    <span
                                                        className="text-center ht-30 text-coco-gray-400">{GetMessageIntl(intl, "workflow.shapeConfig.addSubtaskMsg")}</span>
                                                </div>
                                        }
                                    </SimpleBar>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col className={"col-xl-7-5"} style={{borderLeft: "1px dashed var(--coco-gray-100)", height: "calc(100vh - 231px)"}}>
                            {
                                (clsfcSmrySelectedId !== "" || clsfcSmrySelectedId === 0) && method.getValues(`labels`)?.length > 0
                                    ?
                                        renderContentByType(clsfcSmryViewType)
                                    :
                                    <div className={"d-flex align-items-center justify-content-center"}
                                         style={{height: "calc(100vh - 231px)"}}>
                                        <span className="text-center ht-30 text-coco-gray-400">{GetMessageIntl(intl, "workflow.shapeConfig.selectSubtaskMsg")}</span>
                                    </div>
                            }
                        </Col>
                    </div>
                </OffcanvasBody>
            </SimpleBar>
            {
                // duplicateModalFlag && <DuplicateSettingModal modalFlag={duplicateModalFlag} switchModalFlag={switchDuplicateModalFlag} prjctId={prjctId}/>
            }
        </>
    );
}

export default PickAndContent;