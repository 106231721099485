import {
  CHANGE_MANAGE_PROJECT_ID,
  CHANGE_MANAGE_PROJECT,
  CHANGE_MANAGE_WORKFLOW_ID,
  CHANGE_MANAGE_WORKFLOW,
} from './actionType';

const INIT_STATE = {
  prjctId: null,
  workflowId: null,
  datasetNm : "",
};

const Manage = (state = INIT_STATE, action) => {
  switch (action.type) {
    case CHANGE_MANAGE_PROJECT_ID:
      return {
        ...state,
        prjctId: action.payload,
      };
    case CHANGE_MANAGE_PROJECT:
      return {
        ...state,
        prjctId: action.payload.prjctId,
        prjctNm: action.payload.prjctNm,
      };

    case CHANGE_MANAGE_WORKFLOW_ID:
      return {
        ...state,
        workflowId: action.payload,
      };

    case CHANGE_MANAGE_WORKFLOW:
      return {
        ...state,
        workflowId: action.payload.workflowId,
        workflowNm: action.payload.workflowNm,
      };
    default:
      return state;
  }
};

export default Manage;
