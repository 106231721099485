// @ts-nocheck
import React, {useState} from 'react';

import {
    ActiveControl, ObjectType, Rotation, ShapeType,
} from 'store/label';
import GlobalHotKeys, { KeyMap } from 'pages/user/label/annotation/image/utils/mousetrap-react';
import { Canvas, CanvasMode } from 'pages/user/label/annotation/image/cvat-canvas-wrapper';
import { LabelOptColor } from 'pages/user/label/annotation/image/components/labels-editor/common';

import ControlVisibilityObserver, { ExtraControlsControl } from '../../standard-workspace/controls-side-bar/control-visibility-observer';
import RotateControl, { Props as RotateControlProps } from '../../standard-workspace/controls-side-bar/rotate-control';
import CursorControl, { Props as CursorControlProps } from '../../standard-workspace/controls-side-bar/cursor-control';
import MoveControl, { Props as MoveControlProps } from '../../standard-workspace/controls-side-bar/move-control';
import FitControl, { Props as FitControlProps } from '../../standard-workspace/controls-side-bar/fit-control';
import ResizeControl, { Props as ResizeControlProps } from '../../standard-workspace/controls-side-bar/resize-control';
import {EditImageControl} from "pages/user/label/annotation/image/containers/control-bar/EditImageControl";
import {CONTROLS_SIDE_BAR} from "../../classNameConsts";

interface Props {
    canvasInstance: Canvas;
    activeControl: ActiveControl;
    keyMap: KeyMap;
    normalizedKeyMap: Record<string, string>;
    labels: any[];
    frameData: any;

    mergeObjects(enabled: boolean): void;
    groupObjects(enabled: boolean): void;
    splitTrack(enabled: boolean): void;
    rotateFrame(rotation: Rotation): void;
    // repeatDrawShape(): void;
    pasteShape(): void;
    // resetGroup(): void;
    // redrawShape(): void;
}

// We use the observer to see if these controls are in the viewport
// They automatically put to extra if not
const ObservedCursorControl = ControlVisibilityObserver<CursorControlProps>(CursorControl);
const ObservedMoveControl = ControlVisibilityObserver<MoveControlProps>(MoveControl);
const ObservedRotateControl = ControlVisibilityObserver<RotateControlProps>(RotateControl);
const ObservedFitControl = ControlVisibilityObserver<FitControlProps>(FitControl);
const ObservedResizeControl = ControlVisibilityObserver<ResizeControlProps>(ResizeControl);

export default function ControlsSideBarComponent(props: Props): JSX.Element {
    const {
        activeControl,
        canvasInstance,
        normalizedKeyMap,
        keyMap,
        labels,
        // mergeObjects,
        // groupObjects,
        // splitTrack,
        rotateFrame,
        // repeatDrawShape,
        pasteShape,
        // resetGroup,
        // redrawShape,
        frameData,
    } = props;

    const UnderBar = () => {
        return (
            <div className="menu-title">
                <i className="las la-minus fs-24 lh-1"></i>
            </div>
        )
    };

    // 라벨링 기법 유무 확인
    const controlsDisabled = !labels.length //|| frameData.deleted;
    const withUnspecifiedType = labels.some((label: any) => label.type === 'any' && !label.hasParent);  //라벨 중에 any가 있고 부모 라벨이 없을 경우
    let rectangleControlVisible = withUnspecifiedType;
    let polygonControlVisible = withUnspecifiedType;
    let polylineControlVisible = withUnspecifiedType;
    let pointsControlVisible = withUnspecifiedType;
    let ellipseControlVisible = withUnspecifiedType;
    let cuboidControlVisible = withUnspecifiedType;
    let maskControlVisible = withUnspecifiedType;
    let tagControlVisible = withUnspecifiedType;
    const skeletonControlVisible = labels.some((label: LabelOptColor) => label.type === 'skeleton');
    labels.forEach((label: LabelOptColor) => {
        rectangleControlVisible = rectangleControlVisible || label.type === ShapeType.RECTANGLE;
        polygonControlVisible = polygonControlVisible || label.type === ShapeType.POLYGON;
        polylineControlVisible = polylineControlVisible || label.type === ShapeType.POLYLINE;
        pointsControlVisible = pointsControlVisible || label.type === ShapeType.POINTS;
        ellipseControlVisible = ellipseControlVisible || label.type === ShapeType.ELLIPSE;
        cuboidControlVisible = cuboidControlVisible || label.type === ShapeType.CUBOID;
        maskControlVisible = maskControlVisible || label.type === ShapeType.MASK;
        tagControlVisible = tagControlVisible || label.type === ObjectType.TAG;
    });

    const preventDefault = (event: KeyboardEvent | undefined): void => {
        if (event) {
            event.preventDefault();
        }
    };

    let subKeyMap: any = {
        CANCEL: keyMap.CANCEL,
    };

    let handlers: any = {
        CANCEL: (event: KeyboardEvent | undefined) => {
            preventDefault(event);
            if (activeControl !== ActiveControl.CURSOR) {
                canvasInstance.cancel();
            }
        }
    };

    if (!controlsDisabled) {
        handlers = {
            ...handlers,
            PASTE_SHAPE: (event: KeyboardEvent | undefined) => {
                preventDefault(event);
                canvasInstance.cancel();
                pasteShape();
            },
            SWITCH_DRAW_MODE: (event: KeyboardEvent | undefined) => {
                preventDefault(event);
                const drawing = [
                    ActiveControl.DRAW_POINTS,
                    ActiveControl.DRAW_POLYGON,
                    ActiveControl.DRAW_POLYLINE,
                    ActiveControl.DRAW_RECTANGLE,
                    ActiveControl.DRAW_CUBOID,
                    ActiveControl.DRAW_ELLIPSE,
                    ActiveControl.DRAW_SKELETON,
                    ActiveControl.DRAW_MASK,
                    ActiveControl.AI_TOOLS,
                    ActiveControl.OPENCV_TOOLS,
                ].includes(activeControl);
                const editing = canvasInstance.mode() === CanvasMode.EDIT;

                if (!drawing) {
                    if (editing) {
                        // users probably will press N as they are used to do when they want to finish editing
                        // in this case, if a mask is being edited we probably want to finish editing first
                        canvasInstance.edit({ enabled: false });
                        return;
                    }

                    canvasInstance.cancel();
                    // repeateDrawShapes gets all the latest parameters
                    // and calls canvasInstance.draw() with them

                    // if (event && event.shiftKey) {
                    //     redrawShape();
                    // } else {
                    //     repeatDrawShape();
                    // }
                } else {
                    if ([ActiveControl.AI_TOOLS, ActiveControl.OPENCV_TOOLS].includes(activeControl)) {
                        // separated API method
                        canvasInstance.interact({ enabled: false });
                        return;
                    }

                    canvasInstance.draw({ enabled: false });
                }
            },
        };
        subKeyMap = {
            ...subKeyMap,
            PASTE_SHAPE: keyMap.PASTE_SHAPE,
            SWITCH_DRAW_MODE: keyMap.SWITCH_DRAW_MODE,
        };
    }

    return (
        <div className="app-menu navbar-menu custom-b-0">
            <div id="scrollbar">
                <div className={CONTROLS_SIDE_BAR}>
                    {/*<GlobalHotKeys keyMap={subKeyMap} handlers={handlers} />*/}
                    {/*<ObservedCursorControl*/}
                    {/*    cursorShortkey={normalizedKeyMap.CANCEL}*/}
                    {/*    canvasInstance={canvasInstance}*/}
                    {/*    activeControl={activeControl}*/}
                    {/*/>*/}
                    {/*<ObservedMoveControl canvasInstance={canvasInstance} activeControl={activeControl} />*/}
                    <div>
                        <EditImageControl />
                    </div>
                    <ObservedRotateControl
                        anticlockwiseShortcut={normalizedKeyMap.ANTICLOCKWISE_ROTATION}
                        clockwiseShortcut={normalizedKeyMap.CLOCKWISE_ROTATION}
                        rotateFrame={rotateFrame}
                    />
                    <ObservedFitControl canvasInstance={canvasInstance} />
                    {/*<ObservedResizeControl canvasInstance={canvasInstance} activeControl={activeControl} />*/}

                    {/*<UnderBar/>*/}
                    {/*<ObservedGroupControl*/}
                    {/*    groupObjects={groupObjects}*/}
                    {/*    resetGroup={resetGroup}*/}
                    {/*    canvasInstance={canvasInstance}*/}
                    {/*    activeControl={activeControl}*/}
                    {/*    disabled={controlsDisabled}*/}
                    {/*    shortcuts={{*/}
                    {/*        SWITCH_GROUP_MODE: {*/}
                    {/*            details: keyMap.SWITCH_GROUP_MODE,*/}
                    {/*            displayValue: normalizedKeyMap.SWITCH_GROUP_MODE,*/}
                    {/*        },*/}
                    {/*        RESET_GROUP: {*/}
                    {/*            details: keyMap.RESET_GROUP,*/}
                    {/*            displayValue: normalizedKeyMap.RESET_GROUP,*/}
                    {/*        },*/}
                    {/*    }}*/}
                    {/*/>*/}
                    {/*<ObservedSplitControl*/}
                    {/*    splitTrack={splitTrack}*/}
                    {/*    canvasInstance={canvasInstance}*/}
                    {/*    activeControl={activeControl}*/}
                    {/*    disabled={controlsDisabled}*/}
                    {/*    shortcuts={{*/}
                    {/*        SWITCH_SPLIT_MODE: {*/}
                    {/*            details: keyMap.SWITCH_SPLIT_MODE,*/}
                    {/*            displayValue: normalizedKeyMap.SWITCH_SPLIT_MODE,*/}
                    {/*        },*/}
                    {/*    }}*/}
                    {/*/>*/}
                    {/*<ExtraControlsControl />*/}

                    {/*<ul className="navbar-nav" id="navbar-nav">*/}
                    {/*    {*/}
                    {/*        tmpLbPrjInfo.moduleTy === MD_TY_CD.LABEL?*/}
                    {/*            (*/}
                    {/*                <div id="labelToolArea">*/}
                    {/*                    <UnderBar />*/}
                    {/*                    <NavItem>*/}
                    {/*                        <Button color={"coco-ghost"} className="btn-icon waves-effect waves-light rounded-pill " id="trash">*/}
                    {/*                            <i className="ri-delete-bin-line fs-20"></i>*/}
                    {/*                        </Button>*/}
                    {/*                    </NavItem>*/}
                    {/*                </div>*/}
                    {/*            ): null*/}
                    {/*    }*/}
                    {/*</ul>*/}
                </div>
                {/*{!--Sidebar--}*/}
            </div>

            <div className="sidebar-background"></div>
        </div>
    )
}
