import React, {useEffect, useMemo, useRef, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import button from "bootstrap/js/src/button";
import {Card, CardHeader, Col, Container, Input, Row} from "reactstrap";
import BreadCrumb from "../../../components/common/BreadCrumb";
import ProjectWorkerList from "./ProjectWorkerList";
import Select from "react-select";
import {defaultSelectStyle} from "../../../components/constants/select";
import Flatpickr from "react-flatpickr";
import {GetMessageIntl} from "../../../util/message";
import {useIntl} from "react-intl";
import {TaskService} from "../../../apis/api/TaskService";
import {
    setSearchParam,
    setWorkDtm,
    setWorkerNm,
    setWrkflowId,
    setWrkflowOption,
    setTaskOption,
    setTaskId,
    setMountCnt,
    setWrkflowWithModules,
    setCanvasDataTy
} from "../../../store/manage/worker/action";

function ProjectWorker(props) {
    const dis = useDispatch();
    const intl = useIntl()

    const [wrkflowNm, setWrkflowNm] = useState();

    const prjctId = sessionStorage.getItem("prjctId");

    const [highlightWords, setHighlightWords] = useState([]);
    const {
        workerNm,
        workDtm,
        wrkflowId,
        wrkflowOption,
        wrkflowWithModules,
        taskId,
        taskOption,
        searchParam,
        mount
    } = useSelector((state) => ({
        workerNm: state.ManageWorker.workerNm,
        workDtm: state.ManageWorker.workDtm,
        wrkflowId: state.ManageWorker.wrkflowId,
        wrkflowOption: state.ManageWorker.wrkflowOption,
        wrkflowWithModules: state.ManageWorker.wrkflowWithModules,
        taskId: state.ManageWorker.taskId,
        taskOption: state.ManageWorker.taskOption,
        searchParam: state.ManageWorker.searchParam,
        mount: state.ManageWorker.mount
    }));

    const refComp = useRef();
    const wrkflowRef = useRef();
    const taskNmRef = useRef();
    // const modalFlag = useSelector((state) => { return state.Dataset.modalFlag });
    const [selectLazyLoading, setSelectLazyLoading] = useState(false);



    // 기존 State 초기화 -> Param 재저장
    const handleSearch = () => {
        dis(setMountCnt(mount + 1));
        let obj = {};
        if(wrkflowId !== ""){
            obj.WRKFLOW_ID = wrkflowId;
        }
        if(taskId !== "" && taskId !== null){
            obj.MODULE_ID = taskId;
        }
        if(workerNm !== ""){
            obj.USER_NM = workerNm
        }
        if(workDtm !== ""){
            obj.LASTEST_WORK_DTM = workDtm;
        }
        dis(setCanvasDataTy(wrkflowRef.current.getValue()[0].canvasDataTy));
        dis(setSearchParam(obj));
    }

    const getWrkflowWithTasks = (prjctId) => {
        TaskService.getWrkflowWithTaskList({
            prjctId: prjctId
        }).then(re => {
            if(re.data.data){
                const processedData = re.data.data.map(wrkflowWithTasks => {
                    const filteredModules = wrkflowWithTasks.modules.map(module => {
                        return {
                            "moduleId" : module.moduleId,
                            "moduleNm" : module.moduleNm
                        }
                    })
                    return {
                        "wrkflowNm" : wrkflowWithTasks.wrkflowNm,
                        "wrkflowId" : wrkflowWithTasks.wrkflowId,
                        "canvasDataTy": wrkflowWithTasks.canvasDataTy,
                        "modules" : filteredModules
                    }
                })
                dis(setWrkflowWithModules(processedData));
                if(processedData && processedData.length !== 0){
                    const tmpOptionArr = processedData.map(item => {
                        return {
                            value : item.wrkflowId,
                            label : item.wrkflowNm,
                            canvasDataTy: item.canvasDataTy
                        }
                    })
                    dis(setWrkflowOption(tmpOptionArr))
                    dis(setWrkflowId(tmpOptionArr[0].value))
                    dis(setSearchParam( { WRKFLOW_ID : tmpOptionArr[0].value } ))
                    dis(setCanvasDataTy(tmpOptionArr[0].canvasDataTy));
                }
            }
            dis(setMountCnt(mount + 1));
            setSelectLazyLoading(true);
        })
    }

    useEffect(() => {
        const findWrkflow = wrkflowWithModules.find(wrkflow => wrkflow.wrkflowId === wrkflowId);
        if(findWrkflow && findWrkflow.modules){
            const filteredModule = findWrkflow.modules.map(task => {
                return {
                    label : task.moduleNm,
                    value : task.moduleId
                }
            })
            const defaultArr = [{label : "태스크 전체", value : ""}];
            let taskArr = [...defaultArr, ...filteredModule];
            dis(setTaskOption(taskArr));
        }
    }, [wrkflowId])

    useEffect(() => {
        if(mount < 2){
            getWrkflowWithTasks(prjctId);
        }else{
            setSelectLazyLoading(true)
        }
    }, [])

    // useEffect(() => {
    //     mount = 1;
    // }, [prjctId])

    // Input, Select 등 State로 조정
    const handleReset = () => {
        dis(setMountCnt(mount + 1));
        dis(setWorkerNm(""));
        dis(setWrkflowId(""));
        dis(setTaskId(""))
        refComp.current.flatpickr.clear();
        setHighlightWords([]);

        let obj = {};
        if (wrkflowId !== "") {
            obj.WRKFLOW_ID = wrkflowOption[0].value;
            dis(setWrkflowId(wrkflowOption[0].value));
        }
        dis(setSearchParam(obj));
    }

    const ButtonComponent = () => {
        return(
            <button type="button" className="btn btn-coco-1"
                    onClick={()=>{
                        // datasetDis(switchModalFlag())
                    }}
            >
                엑셀 내보내기
            </button>
        )
    }

    return (
        <>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title="작업자" pageTitle="Laflow" ButtonComponent={ButtonComponent}/>
                    <Row>
                        <Card className="shadow-none mg-b-16">
                            <CardHeader className="border-0 rounded p-0">
                                <Row className="g-3">
                                    <Col lg={3}>
                                        <div className="position-relative form-search">
                                            <Input
                                                type="text"
                                                className="form-control"
                                                placeholder="성명 또는 이메일 ID를 검색하세요."
                                                value={workerNm || ""}
                                                onChange={(e)=> {
                                                    dis(setWorkerNm(e.target.value))
                                                }}
                                            />{" "}
                                            <span className="ri-search-line search-widget-icon"></span>
                                        </div>
                                    </Col>
                                    {
                                        selectLazyLoading &&
                                        <>
                                            <Col lg={2}>
                                                <Select
                                                    ref={wrkflowRef}
                                                    placeholder={"워크플로우 전체"}
                                                    options={wrkflowOption}
                                                    value={(wrkflowOption || []).find(item => item.value === wrkflowId) || []}
                                                    styles={defaultSelectStyle}
                                                    onChange={(e) => {
                                                        setWrkflowNm(e.value);
                                                        dis(setWrkflowId(e.value));
                                                    }}
                                                />
                                            </Col>
                                            <Col lg={2}>
                                                <Select
                                                    ref={taskNmRef}
                                                    placeholder={"태스크 전체"}
                                                    options={taskOption}
                                                    value={(taskOption || []).find(item => item.value === taskId) || []}
                                                    styles={defaultSelectStyle}
                                                    onChange={(e) => {
                                                        dis(setTaskId(e.value));
                                                    }}
                                                />
                                            </Col>
                                        </>
                                    }

                                    <Col lg={2}>
                                        <Flatpickr
                                            ref={refComp}
                                            className="form-control"
                                            options={{
                                                mode: "range",
                                                maxDate: 'today',
                                                dateFormat: "Y-m-d",
                                            }}
                                            placeholder="작업일자"
                                            onValueUpdate={(selectedDates, dateStr, instance) => {
                                                const tmp = dateStr.replace('to', '~');
                                                if (tmp !== workDtm) {
                                                    dis(setWorkDtm(tmp))
                                                }
                                                instance.element.value = tmp;
                                            }}
                                        />
                                    </Col>

                                    <Col xl={1} className={"align-self-center"} style={{maxWidth:"75px"}}>
                                        <div className={"d-flex"}>
                                            <button className="btn btn-coco-outline-1"
                                                    onClick={(e) => {
                                                        handleSearch()
                                                    }}>
                                                {GetMessageIntl(intl, "common.apply")}
                                            </button>
                                        </div>
                                    </Col>
                                    <Col className={"pd-0 align-self-center"}>
                                        <div className={"d-flex"}>
                                            <i  className="ri-refresh-line text-coco-gray-200 fs-24 align-self-auto"
                                                onClick={()=>{handleReset()}
                                                }></i>
                                        </div>
                                    </Col>
                                </Row>
                            </CardHeader>
                        </Card>
                        {/* 기본 레이아웃이 같다면 리스트만 분기해서 들어가자 */}
                        <ProjectWorkerList prjctId={prjctId} highlightWords={highlightWords}/>
                    </Row>
                    {/*{*/}
                    {/*    modalFlag === true ? <DatasetAddModal /> : null*/}
                    {/*}*/}
                </Container>
            </div>
        </>
    );
}

export default ProjectWorker;
