// @flow
import { all, call, put, fork, takeEvery } from "redux-saga/effects";

import {
    SET_MANAGE_LABEL_ID
    , SET_MANAGE_LABEL_SHAPE_CONFIG
    , GET_MANAGE_LABEL_SHAPE_CONFIG
    , SET_MANAGE_LABEL_DATASET
    , GET_MANAGE_LABEL_DATASET
    , UPDATE_MANAGE_LABEL_DATASET
    , SET_MANAGE_LABEL_GUIDE
    , GET_MANAGE_LABEL_GUIDE
    , SET_MANAGE_LABEL_CLASS_ITEMS
    , GET_MANAGE_LABEL_CLASS_ITEMS
    , ADD_MANAGE_LABEL_CLASS_ITEM
    , UPDATE_MANAGE_LABEL_CLASS_ITEM
    , DELETE_MANAGE_LABEL_CLASS_ITEM
    , SET_MANAGE_LABEL_ATTR_LIST
    , GET_MANAGE_LABEL_ATTR_LIST
    , SET_MANAGE_LABEL_ATTR_ITEMS
    , ADD_MANAGE_LABEL_ATTR_ITEM
    , UPDATE_MANAGE_LABEL_ATTR_ITEM
    , DELETE_MANAGE_LABEL_ATTR_ITEM
    , SET_SELECTED_MANAGE_LABEL_CLASS_ID
    , SET_SELECTED_MANAGE_LABEL_ATTR_ID
    , SET_SELECTED_MANAGE_LABEL_ATTR_ITEM_ID
} from './actionType';
import {watchChangeManageProject, watchChangeManageWorkflow} from "../saga";
import {setSelectedManageLabelClassId} from "./action";

/**
 * Changes the Manage Workflow
 * @param {*} param0
 */
function* changeSelectedManageLabelClassId({ payload: deleteClassItemId }) {
    try {
        yield put(setSelectedManageLabelClassId(-1));
    } catch (error) {
        // console.log(error);
    }
}

export function* watchDeleteManageLabelClassItem(){
    yield takeEvery(DELETE_MANAGE_LABEL_CLASS_ITEM, changeSelectedManageLabelClassId);
}
function* ManageLabelSaga() {
    yield all([
        fork(watchDeleteManageLabelClassItem),
    ]);
}

export default ManageLabelSaga;