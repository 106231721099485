import React, {useEffect, useRef, useState} from 'react';
import {BoardService} from "../../apis/api/BoardService";
import {
    board_faqSetModalMode,
    board_faqSwitchAddModalFlag,
    board_faqSwitchTmpViewModalFlag,
} from "../../store/board/action";
import {useDispatch, useSelector} from "react-redux";
import {Button, Col, Input, Label, Modal, ModalBody, ModalHeader, Row} from "reactstrap";
import NoticeTmpView from "./NoticeTmpView";
import SimpleBar from "simplebar-react";
import CommonUtil from "../../util/CommonUtil";
import {FilePond} from "react-filepond";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import Editor from 'ckeditor5-custom-build/build/ckeditor';
import Select from "react-select";
import FaqTmpView from "./FaqTmpView";
import {alertSuccess, confirm} from "../../util/AlertUtil";
import {useIntl} from "react-intl";
import customUploadAdapter from "../../components/features/CustomUploadAdapter";
import {Controller, useForm} from "react-hook-form";
import {GetMessageIntl} from "../../util/message";
import logo from "assets/images/laflow_logo_01.png";

function FaqAddModal(props) {
    const intl = useIntl();
    const dispatch = useDispatch();
    const { modalFlag, mode, rowData, tmpViewModalFlag } = useSelector((state)=>({
        modalFlag : state.Board.faqAddModalFlag,
        mode : state.Board.faqModalMode,
        rowData : state.Board.rowData,
        tmpViewModalFlag : state.Board.faqTmpViewModalFlag
    }));
    const [attachFiles, setAttachFiles] = useState([]);
    const [delFileSeq, setDelFileSeq] = useState([]);
    const [delFilePath, setDelFilePath] = useState([]);
    const [cnTy, setCnTy] = useState('00');
    const selectInputRef = useRef();
    const [selectedOption, setSelectedOption] = useState(null);
    const { reset, register, control ,formState: { errors }, handleSubmit } = useForm({criteriaMode: "all",
        defaultValues:{

        }});

    // Filepond
    const [flag, setFlag] = useState(false);
    const [files, setFiles] = useState([])
    const [cpFiles, setCpFiles] = useState([]);
    const [totalFileSize, setTotalFileSize] = useState(0);
    const pondRef = useRef(null);
    const maxTotalSize = 50 * 1024 * 1024; // 50MB

    // CKEditor
    const ckEditorRef = useRef();
    const [content, setContent] = useState([]);
    const [cnTitle, setCnTitle] = useState("");

    const getAttachFilesAwsUrl = async (cnSeq) => {
        const res =  await BoardService.getContentAttachFile({
            'cnSeq' : rowData.cnSeq
        });
        if(res.data.data){
            setAttachFiles(res.data.data);
        }
    }

    useEffect(()=>{
        reset();
        resetData();
        if(mode === "U" || mode === "T"){
            getAttachFilesAwsUrl(rowData.cnSeq);
            setContent(rowData.cn);
            setCnTitle(rowData.cnTitle);
            let filteredOption = props.cnTyOptions.filter((option) => option.value === rowData.cnTy);
            setSelectedOption(filteredOption)
            reset({
                cnTitle: rowData.cnTitle,
                cnTy: rowData.cnTy
            });
        }
    }, [modalFlag])

    useEffect(()=>{
        let delIdx = [];
        let totalFileSize = 0;
        for (let i = cpFiles.length - 1; i >= 0; i--) {
            totalFileSize += cpFiles[i].file.size
            if(totalFileSize > maxTotalSize){
                delIdx.push(i);
            }
        }
        let tmpArr = [...cpFiles];
        let delArr = [];
        delIdx.map((idxs) => {
            let delTarget = tmpArr.shift();
            delArr.push(delTarget.file.name)
        })

        setFiles(tmpArr);
        setTotalFileSize(totalFileSize);
        if(delArr.length > 0){
            alert("업로드 안된 파일들\n" + delArr.join('\n'));
        }
    }, [cpFiles])

    // CKEditor

    function uploadPlugin (editor){ // (3)
        editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
            return customUploadAdapter(loader);
        }
    }

    const addProcess = ()=>{
        const data = new FormData();
        data.append("cn", content);
        data.append("boardSeq", 2);
        data.append("mode", mode);
        data.append("cnLvl", 1);
        data.append("cnTy", cnTy);
        data.append("cnTitle", cnTitle);
        data.append("noticeYn", 'N');
        data.append("cnSttusCd", "00");
        if(mode === "U" || mode === "T"){
            data.append("deletedFileSeq", delFileSeq.toString());
            data.append("deletedFilePath", delFilePath.toString());
            data.append("orginCnSeq", rowData.orginCnSeq);
            data.append("cnSeq", rowData.cnSeq);
        }

        for (let i = 0; i < files.length; i++) {
            // append the blob file
            data.append('attachFile[]', files[i].file);
        }

        BoardService.setContent(data, {contentType : 'multipart/form-data'})
            .then((res) => {
                console.log(res.data)
            })
            .catch((err)=>{
                const data = new FormData();
            })
            .finally(()=> {
                dispatch(board_faqSwitchAddModalFlag());
                dispatch(board_faqSetModalMode("I"));
            });
    }

    const handleTmpSave = () => {
        const data = new FormData();
        data.append("cn", content);
        data.append("boardSeq", 2);
        data.append("mode", mode);
        data.append("cnLvl", 1);
        data.append("cnTy", cnTy);
        data.append("cnTitle", cnTitle);
        data.append("noticeYn", 'N');
        data.append("cnSttusCd", "01");
        if(mode === "T"){
            data.append("deletedFileSeq", delFileSeq.toString());
            data.append("deletedFilePath", delFilePath.toString());
            data.append("cnSeq", rowData.cnSeq);
        }


        for (let i = 0; i < files.length; i++) {
            // append the blob file
            data.append('attachFile[]', files[i].file);
        }

        BoardService.setContent(data, {contentType : 'multipart/form-data'})
            .then((res) => {
                console.log(res.data)
            })
            .catch((err)=>{
                const data = new FormData();
            })
            .finally(()=> {
                dispatch(board_faqSwitchAddModalFlag());
                dispatch(board_faqSetModalMode("I"));
            });
    }


    const handleTrash = () =>{
        confirm("board.addModalOut"
            , "board.addModalOutMsg"
            ,()=>{
                dispatch(board_faqSwitchAddModalFlag());
                dispatch(board_faqSetModalMode("I"));
                resetData();
            }
            ,null
            , null
            , intl);
    }

    function handleFileDelete(e) {
        e.target.parentElement.parentElement.parentElement.remove();
    }

    const resetData = () => {
        setCnTitle("");
        setContent("");
        setFiles([]);
        setCnTy("00");
        setDelFileSeq([]);
        setDelFilePath([]);
        setAttachFiles([]);
        setSelectedOption(null);
    }


    function registBtnEvent(data) {
        (mode === "I" || mode === "T") ?
            confirm("board.askFaqAdd"
                , "board.askNoticeAddMessage"
                , () => {
                    addProcess()
                    alertSuccess("board.noticeAddSuccess", "board.noticeAddSuccessMessage", () => {

                    }, null, intl)
                }
                , null
                , null
                , intl)
            :
            confirm("board.askFaqUpdate"
                , "board.askNoticeUpdateMessage"
                , () => {
                    addProcess()
                    alertSuccess("board.noticeUpdateSuccess", "board.noticeAddSuccessMessage", () => {

                    }, null, intl)
                }
                , null
                , null
                , intl)
    }

    return (
        <>
            <Modal
                fullscreen={true}
                isOpen={props.modalFlag}
                toggle={()=>{
                    dispatch(board_faqSwitchAddModalFlag())
                }}
                backdrop="static"
                keyboard={false}
                className="modal-fullscreen fw-bold"
                id="exampleModalFullscreen"
            >
                <ModalHeader toggle={() => {dispatch(board_faqSwitchAddModalFlag())}} style={{backgroundColor : "white", borderBottom : "1px solid #CFD8DC"}} cssModule={{'modal-title': 'w-100 text-center mb-0'}}>
                    <div className="d-flex justify-content-between align-items-center mb-0 tx-24 fw-bold">
                        <div>
                            <img src={logo} style={{height:"22px"}}/>
                        </div>
                        <div>FAQ { mode === "U" ? GetMessageIntl(intl, "board.modify") : GetMessageIntl(intl, "board.regist")}</div>
                        <div></div>
                    </div>
                </ModalHeader>
                { tmpViewModalFlag === true ? <FaqTmpView cnTitle={cnTitle} cn={content} cnTy={cnTy} files={files} attachFiles={attachFiles}/> :
                    <>
                        <SimpleBar
                            style={{maxHeight: "805px", width:"100%", alignSelf:"center"}}
                            className="pe-2 custom-simplebar-wrapper"
                        >
                            <div>
                                <ModalBody className="align-self-center fs-16" style={{paddingTop : '10px', width:"1200px"}}>
                                    <Col>
                                        <Row className="mb-3">
                                            <div className={"d-flex justify-content-start align-items-center"}>
                                                <div style={
                                                    errors.cnTitle ? {marginRight:"138px"} : {marginRight:"128px"}
                                                }>
                                                    {GetMessageIntl(intl, "board.title")}
                                                </div>
                                                <div style={{width:'80%'}} className={errors.cnTitle?"validation-error":""}>
                                                    <input type="text" {...register('cnTitle',
                                                        {
                                                            required: true,
                                                            onChange: (e) => setCnTitle(e.target)
                                                        })
                                                    }  value={cnTitle} style={{border:'0px', fontSize:'15px', padding:'0px !important', width:"80%"}} className="form-control form-control-sm fw-bold" placeholder="제목을 입력하세요."  onChange={(e)=>{
                                                        setCnTitle(e.target.value)
                                                    }}/>
                                                    {errors.cnTitle && <span className={"validation-error-message"}>{GetMessageIntl(intl,"validation.required")}</span>}
                                                </div>

                                            </div>
                                        </Row>
                                        <Row className="mb-3">
                                            <div className={"d-flex justify-content-start align-items-center"}>
                                                <div style={{marginRight:"138px"}}>
                                                    {GetMessageIntl(intl, "board.ty")}
                                                </div>
                                                <div className={errors.cnTy ? "validation-select-error":""} style={{width:'274px'}}>
                                                    <Controller
                                                        name="cnTy"
                                                        rules={{required: GetMessageIntl(intl,"validation.requiredSelect")}}
                                                        control={control}
                                                        render={({ field }) => (
                                                            <Select
                                                                {...field}
                                                                className="mb-0 fs-14 text-coco-gray-400"
                                                                ref={selectInputRef}
                                                                value={selectedOption}
                                                                onChange={(e) => {
                                                                    let filteredOption = props.cnTyOptions.filter((option) => option.value === e.value);
                                                                    setSelectedOption([filteredOption[0]]);
                                                                    setCnTy(e.value);
                                                                    field.onChange(e.value);
                                                                }}
                                                                placeholder={GetMessageIntl(intl, "board.faqType")}
                                                                defaultValue={props.cnTyOptions[0]}
                                                                options={props.cnTyOptions.slice(1)}
                                                                isSearchable={false}
                                                            />
                                                        )}
                                                    />
                                                    {errors.cnTy && <span className={"validation-error-message"}>{GetMessageIntl(intl,"validation.requiredSelect")}</span>}
                                                </div>
                                            </div>
                                        </Row>
                                        <Row style={{marginBottom : '10px'}}>
                                            <div className="d-flex">
                                                <div style={{marginRight:"100px", width:"7%"}}>
                                                    {GetMessageIntl(intl, "board.attachFile")}
                                                </div>
                                                <div className="w-100">
                                                    <div className="d-flex justify-content-between text-coco-gray-400">
                                                        <div>
                                                           {GetMessageIntl(intl, "filePond.totalFileCnt", {cnt: files.length})}
                                                        </div>
                                                        <div></div>
                                                        <div>
                                                            {GetMessageIntl(intl, "filePond.totalFileSize", {size: CommonUtil.bytesToSize(totalFileSize)})}
                                                        </div>
                                                    </div>
                                                    <div className="filepond-div">
                                                        <SimpleBar style={{maxHeight: "120px"}} className="pe-2">
                                                            <FilePond
                                                                ref={pondRef}
                                                                files={files}
                                                                onupdatefiles={setCpFiles}
                                                                server={{}}
                                                                chunkUploads={true}
                                                                allowMultiple={true}
                                                                instant-upload={false}
                                                                name="files"
                                                                labelIdle={GetMessageIntl(intl, "dataConfig.filepondPlaceholder")}
                                                                credits={false}
                                                            />
                                                        </SimpleBar>
                                                    </div>
                                                    <div>
                                                        {
                                                            attachFiles.map((file, idx) => {
                                                                const seperateUrl = file.split(",");
                                                                let filePath = seperateUrl[0];
                                                                let realFileName = seperateUrl[1];
                                                                let fileSize = seperateUrl[2];
                                                                let fileSeq = seperateUrl[3];
                                                                return(
                                                                    <Row className="mb-2" key={idx}>
                                                                        <div className={"d-flex justify-content-between"}>
                                                                            <div className={"d-flex gap-2"}>
                                                                                <span className="text-coco-gray-400" onClick={(e)=>{
                                                                                    let fileSeqCopy = [...delFileSeq];
                                                                                    fileSeqCopy.push(fileSeq);
                                                                                    setDelFileSeq(fileSeqCopy);
                                                                                    let filePathCopy = [...delFilePath];
                                                                                    filePathCopy.push(filePath);
                                                                                    setDelFilePath(filePathCopy);
                                                                                    handleFileDelete(e);
                                                                                }}><i className="ri-close-circle-line"></i></span>
                                                                                <span className="text-coco-gray-700">{realFileName}</span>
                                                                            </div>
                                                                            <div>
                                                                                <span className="fs-16 fw-500 text-coco-gray-400">&nbsp;({CommonUtil.bytesToSize(fileSize)})</span>
                                                                            </div>

                                                                        </div>
                                                                    </Row>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </Row>
                                        <Row className="justify-content-center">
                                            <CKEditor
                                                ref={ckEditorRef}
                                                editor={Editor}
                                                config={{
                                                    extraPlugins: [uploadPlugin],
                                                    removePlugins : ['specialCharacters','MediaEmbedToolbar'],
                                                    toolbar:{
                                                        removeItems: [ 'specialCharacters']
                                                    },
                                                }}
                                                onReady={editor => {
                                                    ckEditorRef.current = editor
                                                    editor.setData(content)
                                                }}
                                                onChange={(event, editor) => {
                                                    setContent(editor.getData())
                                                }}
                                                onBlur={(event, editor) => {
                                                }}
                                                onFocus={(event, editor) => {
                                                }}
                                            />
                                        </Row>
                                    </Col>
                                </ModalBody>
                            </div>
                        </SimpleBar>
                        <div className="modal-footer d-flex justify-content-between align-items-center" style={{borderTop : "1px solid #CFD8DC", paddingTop : "6px", paddingBottom : "6px"}}>
                            <div>
                                <button type="button" className="btn btn-coco-2" onClick={()=>{
                                    ckEditorRef.current;
                                    dispatch(board_faqSwitchTmpViewModalFlag())
                                }}>
                                    {GetMessageIntl(intl, "common.preview")}
                                </button>
                            </div>
                            <div>
                            </div>
                            <div className={"d-flex flex-wrap gap-2"}>
                                {
                                    (mode === "I" || mode === "T") ? <button type="button" className="btn btn-coco-outline-1" onClick={handleSubmit(handleTmpSave)}>
                                        {GetMessageIntl(intl, "board.tempSave")}
                                    </button> : null
                                }
                                <button type="button" className="btn btn-coco-1" onClick={handleSubmit(registBtnEvent)}>
                                    {
                                        (mode === "I" || mode === "T") ? GetMessageIntl(intl, "board.regist") : GetMessageIntl(intl, "board.modify")
                                    }
                                </button>
                                <Button color="danger" className="btn-icon" onClick={handleTrash}> <i className="ri-delete-bin-5-line" /> </Button>
                            </div>
                        </div>
                    </>
                }
            </Modal>
        </>
    );
}

export default FaqAddModal;
