import React, {useEffect, useState} from 'react';
import {
    Card,
    CardBody,
    CardHeader,
    Col,
    Container,
    DropdownItem,
    DropdownMenu,
    DropdownToggle, Form,
    Input,
    Label,
    Row, Spinner,
    UncontrolledDropdown
} from 'reactstrap';
import Slider from "react-rangeslider";
import "react-rangeslider/lib/index.css";
import Select from "react-select";
import {
    defaultSelectStyle,
    formatGroupLabel,
    multiSingleSelectStyle,
    noBorderSelectStyle
} from "../../components/constants/select";
import Flatpickr from "react-flatpickr";
import PropTypes from "prop-types";
import {OverlayTrigger, Popover} from "react-bootstrap";
import {CirclePicker} from "react-color";
import {setManageLabelClassItems} from "../../store/manage/label/action";
import {useForm} from "react-hook-form";
import CustomSelect from "../../components/common/CustomSelect";

const ReactHookFormTest = () => {
    const methods = useForm();
    const { register, setValue, getValues, handleSubmit, control } = methods;

    const [singleValue, setSingleValue] = useState(null);

    const selectOption = [
            { label: "ajaxenhancement", value: "enhancement" },
            { label: "bug", value: "bug" },
            { label: "선택지", value: "bug2" },
            { label: "duplicate", value: "duplicate" },
            { label: "invalid", value: "invalid" },
            { label: "wontfix", value: "wontfix" }
        ];

    const colourOptions = [
        { value: 'ocean', label: 'Ocean', color: '#00B8D9', isFixed: true },
        { value: 'blue', label: 'Blue', color: '#0052CC', isDisabled: true },
        { value: 'purple', label: 'Purple', color: '#5243AA' },
        { value: 'red', label: 'Red', color: '#FF5630', isFixed: true },
        { value: 'orange', label: 'Orange', color: '#FF8B00' },
        { value: 'yellow', label: 'Yellow', color: '#FFC400' },
        { value: 'green', label: 'Green', color: '#36B37E' },
        { value: 'forest', label: 'Forest', color: '#00875A' },
        { value: 'slate', label: 'Slate', color: '#253858' },
        { value: 'silver', label: 'Silver', color: '#666666' },
    ];

    const flavourOptions = [
        { value: 'vanilla', label: 'Vanilla', rating: 'safe' },
        { value: 'chocolate', label: 'Chocolate', rating: 'good' },
        { value: 'strawberry', label: 'Strawberry', rating: 'wild' },
        { value: 'salted-caramel', label: 'Salted Caramel', rating: 'crazy' },
    ];

    const groupedOptions = [
        {
            label: 'Colours',
            options: colourOptions,
        },
        {
            label: 'Flavours',
            options: flavourOptions,
        },
    ];

    const onSubmit = (data) => {
        console.log(data);
    }

    return (
        <>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
                <Row>
                    <Col lg={12}>
                        <Card>
                            <CardHeader className="fw-semibold">
                                Custom Select (React Select를 React Hook Form을 아용해서 만듬)
                            </CardHeader>
                            <CardBody className="card-body">
                                <div className="live-preview">
                                    <Row className="gy-4">
                                        <Col xxl={3} md={6}>
                                            <div>
                                                <h6 className="fw-semibold">Basic Select</h6>
                                                <CustomSelect
                                                    control={control}
                                                    name={"basicSelect"}
                                                    option={{
                                                        'options': selectOption,
                                                        'styles': defaultSelectStyle,
                                                        'isSearchable': false
                                                    }}
                                                />
                                            </div>
                                        </Col>
                                        <Col xxl={3} md={6}>
                                            <div>
                                                <h6 className="fw-semibold">Basic Select Search</h6>
                                                <CustomSelect
                                                    control={control}
                                                    name={"basicSelectSearch"}
                                                    option={{
                                                        'options': selectOption,
                                                        'styles': defaultSelectStyle,
                                                        'isSearchable': true
                                                    }}
                                                />
                                            </div>
                                        </Col>
                                        <Col xxl={3} md={6}>
                                            <div>
                                                <h6 className="fw-semibold">
                                                    Multi Single Select Search
                                                    소스 참고(onChange이벤트)
                                                </h6>
                                                <CustomSelect
                                                    control={control}
                                                    name={"multiSingleSelect"}
                                                    option={{
                                                        'options': selectOption,
                                                        'styles': multiSingleSelectStyle,
                                                        'isSearchable': false,
                                                        'isMulti': true,
                                                        'isMultiSingle':true,
                                                        'onChange': (value) => {
                                                            // here i dont want the user to choose more then 3 topics
                                                            console.log('onChange', value);
                                                            //setValue('multiSingleSelect', value[value.length-1])
                                                        }
                                                    }}
                                                />
                                                <Select
                                                    options={selectOption}
                                                    value={singleValue}
                                                    styles={multiSingleSelectStyle}
                                                    onChange={(value) => {
                                                        console.log(value);
                                                            // here i dont want the user to choose more then 3 topics
                                                            setSingleValue(value[value.length-1]);
                                                        }}
                                                    isMulti
                                                    className="mb-0"
                                                />
                                            </div>
                                        </Col>
                                        <Col xxl={3} md={6}>
                                            <div>
                                                <h6 className="fw-semibold">Multi Select Search</h6>
                                                <CustomSelect
                                                    control={control}
                                                    name={"multiSelectSearch"}
                                                    option={{
                                                        'options': selectOption,
                                                        'styles': defaultSelectStyle,
                                                        'isSearchable': true,
                                                        'isMulti': true
                                                    }}
                                                />
                                            </div>
                                        </Col>
                                        <Col xxl={3} md={6}>
                                            <div>
                                                <h6 className="fw-semibold">Group Select Search</h6>
                                                <Select
                                                    options={groupedOptions}
                                                    formatGroupLabel={formatGroupLabel}
                                                    styles={defaultSelectStyle}
                                                    className="mb-0"
                                                />
                                            </div>
                                        </Col>
                                        <Col xxl={3} md={6}>
                                            <div>
                                                <h6 className="fw-semibold">No border line</h6>
                                                <Select
                                                    options={selectOption}
                                                    defaultValue={[selectOption[1]]}
                                                    styles={noBorderSelectStyle}
                                                    className="mb-0"
                                                />
                                            </div>
                                        </Col>
                                        <Col xxl={3} md={6}>
                                            <div>
                                                <h6 className="fw-semibold">disabled</h6>
                                                <Select
                                                    options={selectOption}
                                                    defaultValue={[selectOption[1]]}
                                                    styles={defaultSelectStyle}
                                                    isDisabled
                                                    className="mb-0"
                                                />
                                            </div>
                                        </Col>
                                        <Col xxl={3} md={6}>
                                            <div>
                                                <h6 className="fw-semibold">multi disabled</h6>
                                                <Select
                                                    options={selectOption}
                                                    defaultValue={[selectOption[1], selectOption[2]]}
                                                    styles={defaultSelectStyle}
                                                    isMulti
                                                    isDisabled
                                                    className="mb-0"
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                                <div className="d-none code-view">
                                    <pre className="language-markup" style={{ height: "352px" }}>
                                        <code>

                                        </code>
                                    </pre>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </form>
        </>
    )
}


export default ReactHookFormTest;
