import SimpleBar from "simplebar-react";
import {Col, Input, Label, OffcanvasBody, Row} from "reactstrap";
import {GetMessageIntl} from "../../../../../../../../util/message";
import {Controller, useFormContext} from "react-hook-form";
import React, {useEffect} from "react";
import {useIntl} from "react-intl";
import {useDispatch} from "react-redux";

function ReviewConfigDiv() {
    const intl = useIntl();
    const dispatch = useDispatch();
    const prjctId = sessionStorage.getItem("prjctId");
    const { control:controlReview, register:registerReview, setValue:setValueReview, getValues:getValuesReview, reset:resetReview, resetField:resetFieldReview
        , watch:watchReview, formState: { errors:errorsReview },} = useFormContext();
    const watchAllReview = watchReview();

    useEffect(()=>{
        resetReview();
    }, []);


    return(
        <>
            <SimpleBar style={{ maxHeight: "calc(100vh - 229px)" }} autoHide={false} className="simplebar-track-coco">
                <OffcanvasBody style={{ minHeight: "300px" }} >
                    <h5 className="module-title">
                        <i className="ri-money-dollar-circle-line"></i>{GetMessageIntl(intl,"workflow.pointPaySetting")}
                    </h5>
                    <Row>
                        <Col md={12}>
                            <Label htmlFor="standardTy1" className="form-label">{GetMessageIntl(intl,"workflow.bindSetting")}<span className="text-coco-danger-400">*</span></Label>
                            <div className="ht-38 d-flex align-items-center">
                                <div className="form-check form-radio-coco form-check-inline">
                                    <input className="form-check-input" type="radio" id="pointPayTy1" value="00" {...registerReview("pointPayTy")} defaultChecked/>
                                    <Label className="form-check-label mb-0" htmlFor="pointPayTy1">
                                        {GetMessageIntl(intl,"workflow.pointFile")}
                                    </Label>
                                </div>
                                <div className="form-check form-radio-coco form-check-inline">
                                    <input className="form-check-input" type="radio" id="pointPayTy2"  value="01" {...registerReview("pointPayTy")}/>
                                    <Label className="form-check-label mb-0" htmlFor="pointPayTy2">
                                        {GetMessageIntl(intl,"workflow.dataFolder")}
                                    </Label>
                                </div>
                                <div className="form-check form-radio-coco form-check-inline">
                                    <input className="form-check-input" type="radio" id="pointPayTy3"  value="02" {...registerReview("pointPayTy")}/>
                                    <Label className="form-check-label mb-0" htmlFor="pointPayTy3">
                                        {GetMessageIntl(intl,"workflow.pointInstanceTool")}
                                    </Label>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <hr className="coco-hr"/>
                    <div>
                        <h5 className="module-title">
                            <i className="ri-coin-line"></i>{GetMessageIntl(intl,"workflow.pointPayInput")}
                        </h5>
                        <Row>
                            {watchAllReview.pointPayTy === '00' || watchAllReview.pointPayTy === '01'?
                                <Col md={12}>
                                    <Label htmlFor="payValue" className="form-label">{GetMessageIntl(intl,"workflow.pointPayValue")}<span className="text-coco-danger-400">*</span></Label>
                                    <div className={errorsReview.payValue?"validation-error":""}>
                                        <Controller
                                            render={({ field }) => <Input type={"number"}{...field} min="0" placeholder={GetMessageIntl(intl,"workflow.pointPayInputPlaceholder")}/>}

                                            name="payValue"
                                            control={controlReview}
                                            rules={{
                                                required: true,
                                            }}
                                            defaultValue=""
                                            className="form-control lh-18"
                                        />
                                    </div>
                                    {errorsReview.payValue && <p className="validation-error-message">{GetMessageIntl(intl,"validation.required")}</p>}
                                </Col>
                                : null
                            }
                        </Row>
                    </div>
                </OffcanvasBody>
            </SimpleBar>
        </>
    );
}

export default ReviewConfigDiv;

