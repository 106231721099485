import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Card, CardBody, Col, Row} from "reactstrap";
import ReactPaginate from "react-paginate";
import {WorkerService} from "../../../apis/api/WorkerService";
import PageLeftInfomation from "../../../components/common/PageLeftInfomation";
import {GetMessage} from "../../../util/message";
import {changeManageProjectId, goToDetailsByWorker} from "../../../store/manage/action";
import {AgGridReact} from "ag-grid-react";
import {AgRowStyle} from "../../../components/common/AgGridCustomStyle";
import button from "bootstrap/js/src/button";
import {getDataList, getWorkflow} from "../../../store/dataset/details/action";
import {useHistory} from "react-router";
import {useIntl} from "react-intl";
import {cloneDeep} from "lodash";
import {EmptyListContentView} from "../../../components/features/EmptyListContentView";

function ProjectWorkerList(props) {
    const intl = useIntl();
    const history = useHistory();
    const dispatch = useDispatch();
    const [datasets, setDatasets] = useState([]);
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [prjctId, setPrjctId] = useState(sessionStorage.getItem("prjctId"));

    const { searchParam } = useSelector((state) => ({
        searchParam : state.ManageWorker.searchParam
    }))

    useEffect(()=>{
        if(prjctId){
            dispatch(changeManageProjectId(prjctId));
        }
    },[]);

    function calStartIdx(page) {
        return (page - 1) * perPage;
    }

    const fetchUsers = async (page, size = perPage) => {
        setLoading(true);
        const res = await WorkerService.getProjectWorkerList(
            {
                'prjctId' : prjctId,
                'start': calStartIdx(page),
                'length': size,
                // 'orderParam' : [],
                'searchParam' : searchParam
            }
        );
        if(res.data.data){
            setDatasets(res.data.data.data);
            setTotalRows(res.data.data.recordsTotal);
        }

        setLoading(false);
    };


    useEffect(() => {
        setCurrentPage(1);
        fetchUsers(1);
    }, [searchParam]);

    const _workerViewList = useSelector(state => state.Details.searchParam.view.worker);
    const gotoDetails = (data, e) => {
        const workflow = {
            id: data.wrkflowId,
            name: data.wrkflowNm
        }
        let workerViewListCopy = cloneDeep(_workerViewList);
        let workerViewList = workerViewListCopy.concat({label: data.userNm, index: data.userSeq});
        workerViewList = workerViewList.filter(
            (arr, index, callback) => index === callback.findIndex(t => t.index === arr.index)
        );

        dispatch(getWorkflow(workflow))
        dispatch(getDataList());
        dispatch(goToDetailsByWorker(data.userSeq, workerViewList, intl, history));
    }

    const handlePageChange = page => {
        let pageIdx = page.selected + 1;
        fetchUsers(pageIdx);
        setCurrentPage(pageIdx);
    };

    const [columnDefs] = useState([
        {
            field: 'userNm',
            headerName: GetMessage("projectWorker.workerNm"),
            cellClass : "firstCell",
            flex: '8%',
        },
        {
            field: 'userId',
            cellClass : "btnCell",
            headerName: GetMessage("projectWorker.workerId"),
            flex: '33%',
            cellRenderer: params => {
                return(
                    <div className={"d-flex justify-content-between"}>
                        <span>{params.data.userId}</span>
                        <button type="button"
                                style={{ display : "none", height : "50%"}}
                                onClick={(e)=>{gotoDetails(params.data, e)}} className="joinBtn btn btn-coco-xs-1 align-self-center">
                            작업 보기
                        </button>
                    </div>
                )
            }
        },
        {
            field: 'moduleNm',
            flex: '24%',
            headerName: GetMessage("projectWorker.joinedTask")
        },
        {
            field: 'workPerDay',
            flex: '10%',
            headerName: GetMessage("projectWorker.doneJobDayCnt")
        },
        {
            field: 'workDoneCount',
            flex: '10%',
            headerName: GetMessage("projectWorker.workDoneCount")
        },
        {
            field: 'paymentsReward',
            flex: '10%',
            headerName: GetMessage("projectWorker.paidPoint")
        }
    ])

    const [defaultColDef] = useState({
        autoHeight: true,
        resizable: true,
        cellClass: 'cell-wrap-text',
    })

    let count = Math.ceil(Number((totalRows / perPage)));
    // 0인경우 console에 warning출력
    if(count === 0){
        count = 1;
    }
    return (
        <>
            <Card className="shadow-none">
                <CardBody className="px-0 pb-0">
                    <div className="ag-theme-alpine" style={{ width: '100%' }}>
                        <AgGridReact
                            rowData={datasets}
                            rowStyle={AgRowStyle}
                            defaultColDef={defaultColDef}
                            columnDefs={columnDefs}
                            pagination={true}
                            paginationPageSize={perPage}
                            suppressPaginationPanel={true}
                            detailRowAutoHeight={true}
                            suppressRowTransform={true}
                            gridOptions={{
                                suppressCellFocus : true
                            }}
                            domLayout={'autoHeight'}
                        >
                        </AgGridReact>
                        {
                            datasets && datasets.length > 0 ?
                                <>
                                    <Row className='mx-0'>
                                        <Col className='d-flex justify-content-start align-items-center fs-16 text-coco-gray-700 fw-500' sm='6'>
                                            <PageLeftInfomation
                                                totalRows={totalRows}
                                                perPage ={perPage}
                                                currentPage={currentPage}
                                            />
                                        </Col>
                                        <Col className='d-flex justify-content-end align-items-center' sm='6'>
                                            <ReactPaginate
                                                previousLabel={'←'}
                                                nextLabel={'→'}
                                                onPageChange={handlePageChange}
                                                pageCount={count}
                                                forcePage={currentPage - 1}
                                                activeClassName={'active'}
                                                pageClassName={'page-item'}
                                                nextLinkClassName={'page-link'}
                                                nextClassName={'page-item next'}
                                                previousClassName={'page-item prev'}
                                                previousLinkClassName={'page-link'}
                                                pageLinkClassName={'page-link'}
                                                breakClassName='page-item'
                                                breakLinkClassName='page-link'
                                                containerClassName={'pagination react-paginate pagination-custom pagination-sm justify-content-end align-items-center'}
                                            />
                                        </Col>
                                    </Row>
                                </>
                                :
                                <EmptyListContentView msgCode={'projectWorker.emptyWorker'}/>
                        }
                    </div>
                </CardBody>
            </Card>
        </>
    );
}

export default ProjectWorkerList;
