import {callApi} from '../utils/AxiosUtil';

export const DatasetService={
    /**
     * 데이터셋 아이디 생성
     * @param param
     * @returns {Promise<axios.AxiosResponse<any>>}
     */
    makeDatasetId: (param) => callApi('/service/dataset/makeDatasetId'),
    /**
     * 데이터셋 등록
     * @param param
     * @returns {Promise<axios.AxiosResponse<any>>}
     */
    addDataset: (param) => callApi('/service/dataset/add', param),
    /**
     * 데이터셋 수정
     * @param param
     * @returns {Promise<axios.AxiosResponse<any>>}
     */
    updateDataset: (param) => callApi('/service/dataset/update', param),
    /**
     * 데이터셋 아이템 조회
     * @param param
     * @returns {Promise<axios.AxiosResponse<any>>}
     */
    getDatasetItem: (param) => callApi('/service/dataset/item', param),
    /**
     * 데이터셋에 속해있는 파일 목록 조회
     * @param param
     * @returns {Promise<axios.AxiosResponse<any>>}
     */
    getFileList: (param) => callApi('/service/dataset/fileList', param),
    /**
     * 데이터셋에 속해있는 파일 항목 조회
     * @param param
     * @returns {Promise<axios.AxiosResponse<any>>}
     */
    getFileItem: (param) => callApi('/service/dataset/fileItem', param),

    /**
     * 데이터셋에 속해있는 파일 항목 조회
     * @param param
     * @returns {Promise<axios.AxiosResponse<any>>}
     */
    getDatasetListByFile: (param) => callApi('/service/dataset/listByFile', param),

    getDatasetListByFolder: (param) => callApi('/service/dataset/listByFolder', param),

    /**
     *
     * @param param
     * @returns {Promise<axios.AxiosResponse<any>>}
     */
    checkExportData: (param) => callApi('/service/dataset/checkExportData', param),
    /**
     * 데이터셋이름 목록
     * @param param
     * @returns {Promise<axios.AxiosResponse<any>>}
     */
    getDatasetNmListByPrjctIdAndWrkflowId: (param) => callApi('/service/dataset/getDatasetNmListByPrjctIdAndWrkflowId', param),
    /**
     * 워크플로우 목록
     * @param param
     * @returns {Promise<axios.AxiosResponse<any>>}
     */
    getWrkflowNmList: (param) => callApi('/service/dataset/getWrkflowNmList', param),

    setDatasetMetadata: (param, option) => callApi('/service/dataset/saveMetaInfo', param, option),


    /**
     * 데이터셋 메타 데이터 미리보기
     * @param param{{datasetId:String, moduleId:String}}
     * @returns {Promise<axios.AxiosResponse<any>>}
     */
    getPreviewMetadata: (param) => callApi('/service/dataset/previewMetadata', param),
}

