const INIT_STATE = {

};

const AnnotationReview = (state=INIT_STATE, action) => {
    switch (action.type){
        default:
            return state;
    }
}
export default AnnotationReview;
