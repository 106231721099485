import validList from "../../../../../assets/images/vaild-list.png";
import React, {useState, useRef, useEffect, useCallback, createRef} from "react";
import ReactFlow, {
    ReactFlowProvider,
    Controls,
    Background,
    useNodesState,
    useEdgesState,
    addEdge, MarkerType, useStore, Edge, updateEdge, MiniMap, useReactFlow, useKeyPress, applyNodeChanges, ControlButton
} from "reactflow";
import 'reactflow/dist/style.css';
import ConnectionLine from './edges/ConnectionLine';
import FloatingEdge from './edges/FloatingEdge';

import ModuleOffcanvas from "./offcanvas/ModuleOffcanvas";
import DatasetNode from "./nodes/DatasetNode";
import LabelNode from "./nodes/LabelNode";
import SDQNode from "./nodes/SDQNode";
import {getLabelGroupNode, getManagerReviewGroupNode, getReviewGroupNode} from './nodes/nodeGroupUtil';

import './workflow.css'
import GroupNode from "./nodes/GroupNode";
import {GetMessage} from "../../../../../util/message";
import {Link, useHistory} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import workflowNode from "./nodes/WorkFlowNode";
import {
    manage_project_workflow_module_deleteNodeId,
    manage_project_workflow_module_setIsDatasetOffcanvas,
    manage_project_workflow_module_setIsLabelOffcanvas,
    manage_project_workflow_module_setIsModuleOffcanvas,
    manage_project_workflow_module_setIsReviewOffcanvas, manage_project_workflow_module_setSelectionNode,
    manage_project_workflow_module_setSelectionNodeId, manage_project_workflow_module_updateModuleId
} from "../../../../../store/manage/project/workflow/module/action";
import DatasetOffcanvas from "./offcanvas/DatasetOffcanvas";
import ReviewNode from "./nodes/ReviewNode";
import ReviewOffcanvas from "./offcanvas/ReviewOffcanvas";
import AcceptNode from "./nodes/sub/AcceptNode";
import FinishNode from "./nodes/FinishNode";
import UnabledNode from "./nodes/UnabledNode";
import RejectNode from "./nodes/sub/RejectNode";
import LabelOffcanvas from "./offcanvas/LabelOffcanvas";
import AutoLabelNode from "./nodes/AutoLabelNode";
import CrossReviewNode from "./nodes/CrossReviewNode";
import ManagerReviewNode from "./nodes/ManagerReviewNode";
import SubmitNode from "./nodes/sub/SubmitNode";
import SkipNode from "./nodes/sub/SkipNode";
import LabeledNode from "./nodes/sub/LabeledNode";
import ApprovalNode from "./nodes/sub/ApprovalNode";
import AmbiguityNode from "./nodes/sub/AmbiguityNode";
import StateChangeNode from "./nodes/sub/StateChangeNode";
import UpdateSubmitNode from "./nodes/sub/UpdateSubmitNode";
import {v4 as uuidv4} from "uuid";
import {getEdgeParams, getMarkerEndSvg, MarkerDefinition} from "./util";
import {alertError, alertSuccess, confirm} from "../../../../../util/AlertUtil";
import {useIntl} from "react-intl";
import {ManagerWorkflowService} from "../../../../../apis/api/ManagerWorkflowService";
import {ModuleService} from "../../../../../apis/api/ModuleService";
import SubUnabledNode from "./nodes/sub/SubUnabledNode";
import RestoreNode from "./nodes/sub/RestoreNode";
import {thisSourceValidConnection, thisTargetValidConnection} from "./nodes/nodeCommonUtil";
import html2canvas from "html2canvas";
import { SmartStepEdge } from "@tisoap/react-flow-smart-edge";

let id = 0;

const getId = () => `_${id++}`;

const connectionLineStyle = {
    strokeWidth: 3,
    stroke: 'black',
};

const moduleTypes = {
    dataset: '03',
    workflow: '',
    label: '01',
    autoLabel: '',
    review: '02',
    crossReview: '',
    managerReview: '04',
    sdq4ai: '',
    finish: '11',
    unabled: '12'
};

const nodeTypes = {
    dataset: DatasetNode,
    workflow: workflowNode,
    label: LabelNode,
    autoLabel: AutoLabelNode,
    review: ReviewNode,
    crossReview: CrossReviewNode,
    managerReview: ManagerReviewNode,
    sdq4ai: SDQNode,
    module: GroupNode,
    accept: AcceptNode,
    finish: FinishNode,
    unabled: UnabledNode,
    subUnabled: SubUnabledNode,
    restore: RestoreNode,
    submit: SubmitNode,
    skip: SkipNode,
    labeled: LabeledNode,
    approval: ApprovalNode,
    reject: RejectNode,
    ambiguity: AmbiguityNode,
    stateChange: StateChangeNode,
    updateSubmit: UpdateSubmitNode
};

const edgeTypes = {
    floating: FloatingEdge,
    //smart: SmartStepEdge,
};

const defaultEdgeOptions = {
    style: {strokeWidth: 2, stroke: '#0bad8f'},
    type: 'smoothstep',
    markerEnd: {
        type: MarkerType.ArrowClosed,
        color: '#0bad8f',
    },
    /*markerStart:'edge-marker-start',*/
    zIndex: 9
};

const ref = createRef();
const AddWorkFlow = () => {
    const dispatch = useDispatch();
    const intl = useIntl();
    const prjctId = sessionStorage.getItem("prjctId");
    const wrkflowId = sessionStorage.getItem("wrkflowId");
    const reactFlowWrapper = useRef(null);
    const [nodes, setNodes, onNodesChange] = useNodesState([]);
    const [edges, setEdges, onEdgesChange] = useEdgesState([]);
    const [reactFlowInstance, setReactFlowInstance] = useState(null);
    const projectInstance = useReactFlow();
    const {selectionNodeId, selectionNodeNm, selectionNode, deleteNodeId, updateModuleId} = useSelector((state) => ({
        selectionNodeId: state.ManageWorkflowModule.selectionNodeId,
        selectionNodeNm: state.ManageWorkflowModule.selectionNodeNm,
        selectionNode: state.ManageWorkflowModule.selectionNode,
        deleteNodeId: state.ManageWorkflowModule.deleteNodeId,
        updateModuleId: state.ManageWorkflowModule.updateModuleId
    }));
    const [initial, setInitial] = useState(false);

    const typeMessage = {
        dataset: GetMessage("workflow.dataset"),
        workflow: GetMessage("workflow.workflow"),
        label: GetMessage("workflow.label"),
        autoLabel: GetMessage("workflow.autoLabel"),
        review: GetMessage("workflow.review"),
        crossReview: GetMessage("workflow.crossReview"),
        managerReview: GetMessage("workflow.managerReview"),
        sdq4ai: GetMessage("workflow.sdq4ai"),
    }
    const [canvasDataTy, setCanvasDataTy] = useState(null);

    useEffect(() => {
        ManagerWorkflowService.getWorkflowItem({wrkflowId: wrkflowId}).then((result) => {
            console.log('getWorkflowItem', result.data);
            if (result.data.data) {
                let resultData = result.data.data;
                setCanvasDataTy(resultData.canvasDataTy);
                if (resultData.nodeInfo) {
                    let nodeInfo = JSON.parse(resultData.nodeInfo);

                    if (nodeInfo.nodes.length > 0) {
                        ModuleService.getManageModuleList({wrkflowId: wrkflowId}).then((moduleResult) => {
                            if (moduleResult.data.data) {
                                let moduleList = moduleResult.data.data;
                                moduleList.forEach(function (item) {
                                    nodeInfo.nodes = nodeInfo.nodes.map((node) => {
                                        if (node.id === item.moduleId) {
                                            node = {...node, data: item};
                                        }
                                        return node;
                                    })
                                });

                                nodeInfo.nodes.map((mainNode) => {
                                    if (mainNode.data && mainNode.data.moduleTy == '01') {
                                        let moduleConfig = JSON.parse(mainNode.data.moduleConfig);
                                        let orderConfig = moduleConfig.orderConfig;
                                        if (orderConfig.orderConfigList) {
                                            // 라벨이면서 데이터셋 순서를 가지고 있으면 해당 데이터셋에 순서를 넣어준다..
                                            console.log('orderConfig.orderConfigList', orderConfig.orderConfigList);
                                            orderConfig.orderConfigList.forEach(order => {
                                                nodeInfo.nodes = nodeInfo.nodes.map((node) => {
                                                    if (node.type === 'dataset') {
                                                        console.log('node', node);
                                                        let dtModuleConfig = JSON.parse(node.data.moduleConfig);
                                                        let datasetId = dtModuleConfig.datasetId;
                                                        if (order.datasetId === datasetId) {
                                                            node.data = {...node.data, useOrd: order.useOrd};
                                                        }
                                                    }
                                                    return node;
                                                })
                                            })
                                        }
                                        //console.log('label Config nodeInfo.nodes', nodeInfo.nodes);
                                        //console.log('label Config', item.moduleConfig.orderConfig);
                                    }
                                })
                                setNodes(nodeInfo.nodes);
                                setEdges(nodeInfo.edges);
                            }
                        })
                    } else {
                        setNodes(nodeInfo.nodes);
                        setEdges(nodeInfo.edges);
                    }
                }
            }
        });
    }, []);

    const updateWorkflowItem = () => {
        let nodeInfo = {};
        nodeInfo.nodes = projectInstance.getNodes();
        nodeInfo.edges = projectInstance.getEdges();

        // 필요 없는 데이터 삭제
        nodeInfo.nodes.map(node => {
            delete node.data;
            delete node.selected;
            return {...node, selected: false};
        });

        ManagerWorkflowService.updateWorkflowItem({
            'wrkflowId': wrkflowId,
            'nodeInfo': JSON.stringify(nodeInfo),
        }).then(() => {

        })
    }

    const nodeAndEdgeSaveHandler = (e, node, nodes) => {
        console.log("onNodeDragStop")
        updateWorkflowItem();
    }

    useEffect(() => {
        if (initial) {
            updateWorkflowItem();
        }
    }, [edges]); // node는 dragEnd 때만 update 해줘도 충분해보임
    // }, [nodes, edges]);


    const setWorkflowImageSave = async () => {
        const canvas = await html2canvas(ref.current);
        const dataURL = canvas.toDataURL('image/png');
        await ManagerWorkflowService.saveWorkflowThumnail({
            wrkflowId: wrkflowId,
            screenShot: dataURL
        })
    };

    useEffect(() => {
        console.log('selectionNodeNm', selectionNodeNm);
        setNodes((nds) => {
                return nds.map((node) => {
                    if (selectionNode && node.id === selectionNode.id) {
                        // it's important that you create a new object here
                        // in order to notify react flow about the change
                        node.data = {
                            ...node.data,
                            moduleNm: selectionNodeNm,
                        };
                    }
                    return node;
                })
            }
        );
    }, [selectionNodeNm]);

    useEffect(() => {
        if (deleteNodeId) {
            let deleteNodeArr = [];
            setNodes((nodes) => nodes.filter(
                (node) => {
                    if (node.id === deleteNodeId || (node.parentNode && node.parentNode === deleteNodeId)) {
                        deleteNodeArr.push(node.id);
                    }
                    return node.id !== deleteNodeId && (node.parentNode ? node.parentNode !== deleteNodeId : true)
                }))

            setEdges((edges) => edges.filter((edge) => {
                return !deleteNodeArr.includes(edge.target) && !deleteNodeArr.includes(edge.source)
            }))

            let nodeInfo = {};
            nodeInfo.nodes = projectInstance.getNodes();
            nodeInfo.edges = projectInstance.getEdges();

            let deleteTargetNode = nodeInfo.nodes.find((node) => node.id === deleteNodeId);
            if (deleteTargetNode.type) {
                if (deleteTargetNode.type === 'dataset' || deleteTargetNode.type === 'label' || deleteTargetNode.type === 'review') {
                    // 연결된 edge가 있을 경우에는 삭제 해줘야 되는데..
                    // dataset일 경우에는 source 인것만 삭제 해주면 되고
                    // label, review 인 경우에는 다 삭제해줘야 됨.
                    //updateLabelTargetModule(targetNode.data.moduleId, JSON.parse(sourceNode.data.moduleConfig).datasetId, 'D');
                    if (deleteTargetNode.type === 'dataset') {
                        let datasetId = JSON.parse(deleteTargetNode.data.moduleConfig).datasetId;
                        nodeInfo.edges.forEach((edge) => {
                            let edgeTargetNode = nodes.find(node => node.id == edge.target.split('_')[0]);
                            if (edge.source == deleteNodeId && edgeTargetNode.type == 'label') {
                                updateLabelTargetModule(edge.target.split('_')[0], datasetId, 'D');
                            }
                        })
                    } else if (deleteTargetNode.type === 'label') {
                        nodeInfo.edges.forEach(edge => {
                            let edgeSourceNode = nodes.find(node => node.id == edge.source.split('_')[0]);
                            let edgeTargetNode = nodes.find(node => node.id == edge.target.split('_')[0]);
                            if (edge.source == deleteNodeId && edgeTargetNode.type == 'reivew') {
                                //(edge.target.split('_')[0], edge.source.split('_')[0], 'D');

                            } else if (edge.target == deleteNodeId && edgeSourceNode.type == 'dataset') {
                                let datasetId = JSON.parse(edgeSourceNode.data.moduleConfig).datasetId;
                                updateLabelTargetModule(edge.target.split('_')[0], datasetId, 'D');
                            }
                        })
                        updateReviewModuleUseLabelModule(nodeInfo);
                    } else if (deleteTargetNode.type === 'review') {
                        updateReviewModuleUseLabelModule(nodeInfo)
                        // nodeInfo.edges.forEach(edge => {
                        //     let edgeSourceNode = nodes.find(node => node.id == edge.source.split('_')[0]);
                        //     if (edge.target == deleteNodeId && edgeSourceNode.type == 'label') {
                        //         updateReviewModuleUseLabelModule(edge.target.split('_')[0], edge.source.split('_')[0], 'D');
                        //     }
                        // })
                    }
                }
            }

            nodeInfo.nodes = nodeInfo.nodes.filter(node => {
                if (node.id === deleteNodeId || (node.parentNode && node.parentNode === deleteNodeId)) {
                    deleteNodeArr.push(node.id);
                }
                return node.id !== deleteNodeId && (node.parentNode ? node.parentNode !== deleteNodeId : true)
            });
            nodeInfo.edges = nodeInfo.edges.filter((edge) => {
                return !deleteNodeArr.includes(edge.target) && !deleteNodeArr.includes(edge.source)
            })

            ManagerWorkflowService.updateWorkflowItem({
                'wrkflowId': wrkflowId,
                'nodeInfo': JSON.stringify(nodeInfo),
            }).then(() => {
                // 초기화를 해줘야 다른 워크플로우에 들어갔을때 오류가 발생하지 않음.
                dispatch(manage_project_workflow_module_deleteNodeId(null));
            })

        }

    }, [deleteNodeId]);


    useEffect(() => {
        if (updateModuleId) {
            console.log('ModuleService.getManageModuleItem');
            ModuleService.getManageModuleItem({moduleId: updateModuleId}).then((moduleResult) => {
                console.log('updateModuleId', moduleResult.data);
                console.log('updateModuleId', moduleResult.data.data);
                if (moduleResult.data.data) {
                    let moduleData = moduleResult.data.data;
                    setNodes((nodes) => nodes.map(
                        (nd) => {
                            if (nd.id === updateModuleId) {
                                nd = {...nd, data: moduleData};
                            }
                            return nd;
                        }));
                }
            })

            // 초기화를 해줘야 다음에 동일한 updateModuleId가 들어왔을때도 해당 로직을 수행함.
            dispatch(manage_project_workflow_module_updateModuleId(null));
        }
    }, [updateModuleId]);

    useEffect(() => {
        // delete 누르면 노드랑 엣지 다 삭제
        Mousetrap.bind('del', function (e) {
            if (selectionNodeId) {
                confirm("alert.deleteCheck"
                    , "workflow.deleteModuleConfirm"
                    , () => {
                        deleteModuleItem(selectionNodeId);
                    }
                    , null
                    , null
                    , intl);
            }
        });
    }, [selectionNodeId]);

    const onSelectionChange = useCallback(params => {
        let changeNodeId = null;
        if (params.nodes.length > 0) {
            changeNodeId = params.nodes[0].id;
        }
        dispatch(manage_project_workflow_module_setSelectionNodeId(changeNodeId));
    }, [])

    const onNodeContextMenu = useCallback((event, node) => {
        event.preventDefault();
        console.log('onNodeContextMenu nodeInfo=>', node);

        if (node.id != selectionNodeId) {
            // onNodeContextMenu를 하면 focus 이벤트가 발생.
            // node의 selected를 바꿔버리자~
            setNodes((nodes) => nodes.map(
                (nd) => {
                    if (nd.id === selectionNodeId) {
                        nd = {...nd, selected: false}
                    } else if (nd.id === node.id) {
                        nd = {...nd, selected: true}
                    }
                    return nd;
                }));

            //dispatch(manage_project_workflow_module_setSelectionNodeId(node.id));
        }
        dispatch(manage_project_workflow_module_setSelectionNode(node));
        // if(node.type === 'dataset'){
        //     dispatch(manage_project_workflow_module_setIsDatasetOffcanvas(true));
        // }else if(node.type === 'label'){
        //     dispatch(manage_project_workflow_module_setIsLabelOffcanvas(true));
        // }else if(node.type === 'review'){
        //     dispatch(manage_project_workflow_module_setIsReviewOffcanvas(true));
        // }
    }, [selectionNodeId]);

    const onDragOver = useCallback((event) => {
        event.preventDefault();
        event.dataTransfer.dropEffect = 'move';
    }, []);

    const onDrop = useCallback(
        (event) => {
            event.preventDefault();

            const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect();
            const type = event.dataTransfer.getData('application/reactflow');

            // check if the dropped element is valid
            if (typeof type === 'undefined' || !type) {
                return;
            }

            const position = reactFlowInstance.project({
                x: event.clientX - reactFlowBounds.left,
                y: event.clientY - reactFlowBounds.top,
            });

            // 이름 생성 중복 체크
            let basicModuleNm = `Untitled_${typeMessage[type]}`;
            let moduleNm = basicModuleNm + '(1)';
            let existNodes = projectInstance.getNodes();
            let targetModuleCnt = existNodes.filter((node) => {
                return node.type == type
            }).length;
            for (let i = 0; i < targetModuleCnt; i++) {
                let dupNode = existNodes.find((node) => node.data && node.data.moduleNm == moduleNm);
                if (dupNode != undefined) {
                    moduleNm = basicModuleNm + '(' + (i + 2) + ')';
                }
            }

            let moduleId = "MD".concat(uuidv4());
            const newNode = {
                id: moduleId,
                type,
                position,
                data: {moduleId: moduleId, moduleNm: moduleNm, canvasDataTy: canvasDataTy, moduleConfig: JSON.stringify({})},
            };

            let moduleConfig = {};
            if (type === 'review' || type === 'crossReview') {
                if(type == 'review'){
                    // 기본적으로 들어가져야 할 내용: 작업자 설정
                    moduleConfig.workerConfig = {"standardTy":"00"};
                }
                setNodes((nds) => nds.concat(getReviewGroupNode(moduleId, type, position, {moduleId: moduleId, moduleNm: moduleNm, moduleConfig: JSON.stringify({})})));
            } else if (type === 'managerReview') {
                setNodes((nds) => nds.concat(getManagerReviewGroupNode(moduleId, type, position, {moduleId: moduleId, moduleNm: moduleNm, moduleConfig: JSON.stringify({})})));
            } else if (type === 'label') {
                moduleConfig.workerConfig = {"standardTy":"00"};
                moduleConfig.bindConfig = {"dataBindType": "00", "dataBindUnitCount": 1};
                moduleConfig.pointConfig = {"pointPayTy": "00"};

                setNodes((nds) => nds.concat(getLabelGroupNode(moduleId, type, position, {moduleId: moduleId, moduleNm: moduleNm, moduleConfig: JSON.stringify({})})));
            } else {
                // 데이터셋인 경우에는 datasetId를 data에 입력해준다.
                if (type === 'dataset') {
                    newNode.data['datasetId'] = 'DT'.concat(uuidv4());
                    newNode.data.moduleConfig = {};
                    newNode.data.moduleConfig['datasetId'] = newNode.data['datasetId'];
                    newNode.data.moduleConfig = JSON.stringify(newNode.data.moduleConfig);

                    moduleConfig.datasetId = newNode.data['datasetId'];
                }
                setNodes((nds) => nds.concat(newNode));
            }

            addModuleItem(moduleId, moduleNm, moduleTypes[type], moduleConfig);
        },
        [reactFlowInstance, canvasDataTy]
    );

    const addModuleItem = async (moduleId, moduleNm, moduleTy, moduleConfig) => {
        // TODO Authorization의 : accessToken을 세팅해야 한다.
        //console.log('addModuleItem prjctId',prjctId);
        await ModuleService.addModuleItem({
            'moduleId': moduleId,
            'moduleNm': moduleNm,
            'prjctId': prjctId,
            'wrkflowId': wrkflowId,
            'moduleTy': moduleTy,
            'sttusCd': '00',
            'moduleConfig': JSON.stringify(moduleConfig)
        }).then((result) => {
            // workflowNodeInfo를 업데이르 쳐주자.
            updateWorkflowItem();
        });
    }

    const deleteModuleItem = async (moduleId) => {
        // TODO Authorization의 : accessToken을 세팅해야 한다.
        await ModuleService.deleteModuleItem({
            'moduleId': moduleId
        }).then((result) => {
            if(result.data.success) {
                alertSuccess('alert.deleteSuccess', 'alert.deleteSuccessMsg', null, null, intl);
                dispatch(manage_project_workflow_module_deleteNodeId(moduleId));
            }else{
                alertError('error.E00002', '', null, null, result.data.errorMessage, intl);
            }
        });
    }

    const updateLabelTargetModule = async (labeModuleId, datasetId, mode) => {
        await ManagerWorkflowService.updateModuleUseDataset({moduleId: labeModuleId, datasetId: datasetId, mode: mode})
    }

    // const updateReviewModuleUseLabelModule = async (reviewModuleId, labelModuleId, mode) => {
    //     await ManagerWorkflowService.updateReviewModuleUseLabelModule({moduleId: reviewModuleId, trgtModuleId: labelModuleId, mode: mode})
    // }
    const updateReviewModuleUseLabelModule = async (nodeInfo) => {
        const param = {
            'wrkflowId': wrkflowId,
            'nodeInfo': JSON.stringify(nodeInfo),
        }
        await ManagerWorkflowService.updateReviewModuleUseLabelModule(param)
    }
    const edgeUpdateSuccessful = useRef(true);
    const onConnect = useCallback((params) => {
        setInitial(true);
        let projectEdges = projectInstance.getEdges();
        // 동일한 노드끼리 연결한 경우에는 나중에 연결한 Edge로 업데이트
        let matchEdge = projectEdges.find(projectEdge => projectEdge.target === params.target && projectEdge.source === params.source);
        if (matchEdge) {
            setEdges((eds) => eds.filter((e) => e.id !== matchEdge.id));
        } else {
            let nodes = projectInstance.getNodes();
            let sourceNode = nodes.find(node => node.id == params.source.split('_')[0]);
            let targetNode = nodes.find(node => node.id == params.target.split('_')[0]);
            // console.log('onConnect targetNode type', targetNode.type, targetNode.data.moduleId);
            // console.log('onConnect sourceNode type', sourceNode.type, sourceNode.data.moduleId);
            if (sourceNode.type == 'dataset' && targetNode.type == 'label') {
                // 데이터셋을 등록해야함
                console.log('datasetId', JSON.parse(sourceNode.data.moduleConfig).datasetId);
                console.log('데이터셋을 등록해야함');
                updateLabelTargetModule(targetNode.data.moduleId, JSON.parse(sourceNode.data.moduleConfig).datasetId, 'I');
            } else if (sourceNode.type == 'label' && targetNode.type == 'review' || sourceNode.type == 'review' && targetNode.type == 'review') {
                // 검수 라벨대상을 등록해야함
                console.log('검수 라벨대상을 등록해야함');
                //updateReviewModuleUseLabelModule(targetNode.data.moduleId, sourceNode.data.moduleId, 'I');
                updateReviewModuleUseLabelModule({nodeInfo: {nodes: nodes, edges: projectEdges}})
            }
        }

        setEdges((eds) => addEdge(params, eds.map(ed => {
            return ({...ed, 'updatable': 'target'})
        })));

    }, [setEdges]);

    // Edge 삭제 Event
    const onEdgeUpdateStart = useCallback(() => {
        edgeUpdateSuccessful.current = false;
    }, []);

    const onEdgeUpdate = useCallback((oldEdge, newConnection) => {
        console.log('onEdgeUpdate oldEdge', oldEdge);
        console.log('onEdgeUpdate newConnection', newConnection);

        let nodes = projectInstance.getNodes();
        let edges = projectInstance.getEdges();
        let sourceNode = nodes.find(node => node.id == newConnection.source.split('_')[0]);
        let targetNode = nodes.find(node => node.id == newConnection.target.split('_')[0]);

        let originSourceNode = nodes.find(node => node.id == newConnection.source);
        let originTargetNode = nodes.find(node => node.id == newConnection.target);

        // onEdgeUpdate는 노드의 isValidConnection으로 유효성을 체크 하지 못하므로 따로 유효성을 체크.
        let isValid = false;
        if (sourceNode.data.moduleId === oldEdge.source) {
            let parentNodeType = '';
            if (originTargetNode.parentNode) {
                parentNodeType = nodes.find((item) => item.id === originTargetNode.parentNode).type + '_';
            }
            isValid = thisSourceValidConnection(sourceNode.type, parentNodeType + originTargetNode.type)
        } else if (targetNode.data.moduleId === oldEdge.target) {
            let parentNodeType = '';
            if (originSourceNode.parentNode) {
                parentNodeType = nodes.find((item) => item.id === originSourceNode.parentNode).type + '_';
            }
            isValid = thisTargetValidConnection(targetNode.type, parentNodeType + originSourceNode.type)
        }

        if (isValid) {
            edgeUpdateSuccessful.current = true;
            setEdges((els) => updateEdge(oldEdge, newConnection, els));

            if (sourceNode.type == 'dataset' && targetNode.type == 'label') {
                // 데이터셋을 등록해야함
                console.log('datasetId', JSON.parse(sourceNode.data.moduleConfig).datasetId);
                console.log('데이터셋을 등록해야함');
                updateLabelTargetModule(targetNode.data.moduleId, JSON.parse(sourceNode.data.moduleConfig).datasetId, 'I');
            } else if (sourceNode.type == 'label' && targetNode.type == 'review' || sourceNode.type == 'review' && targetNode.type == 'review') {
                // 검수 라벨대상을 등록해야함
                console.log('검수 라벨대상을 등록해야함');
                // updateReviewModuleUseLabelModule(targetNode.data.moduleId, sourceNode.data.moduleId, 'I');
                updateReviewModuleUseLabelModule({nodeInfo: {nodes: nodes, edges: edges}})
            }
        } else {
            edgeUpdateSuccessful.current = false;
            // edgeUpdate를 취소 해야되지 않을까숑. validation 성공 못하면 기존 edge가 삭제됨.. 근데 그렇게 되면 edge 삭제는 없애야 하지 않을까 싶음..
            // edgeUpdate를 없애야 하려나.. 이거는 좀 봐야 할듯..
        }

    }, []);

    const onEdgeUpdateEnd = useCallback((_, edge) => {
        if (!edgeUpdateSuccessful.current) {
            setEdges((eds) => eds.filter((e) => e.id !== edge.id));
        }
        let nodes = projectInstance.getNodes();
        let edges = projectInstance.getEdges();
        let sourceNode = nodes.find(node => node.id == edge.source.split('_')[0]);
        let targetNode = nodes.find(node => node.id == edge.target.split('_')[0]);
        // console.log('onEdgeUpdateEnd targetNode type', targetNode.type, targetNode.data.moduleId);
        // console.log('onEdgeUpdateEnd sourceNode type', sourceNode.type, sourceNode.data.moduleId);
        if (sourceNode.type == 'dataset' && targetNode.type == 'label') {
            // 데이터셋을 삭제해야함
            // console.log('데이터셋을 삭제해야함');
            // console.log('datasetId', JSON.parse(sourceNode.data.moduleConfig).datasetId);
            updateLabelTargetModule(targetNode.data.moduleId, JSON.parse(sourceNode.data.moduleConfig).datasetId, 'D');
        } else if (sourceNode.type == 'label' && targetNode.type == 'review' || sourceNode.type == 'review' && targetNode.type == 'review') {
            // 검수 라벨대상을 삭제해야함
            // console.log('검수 라벨대상을 삭제해야함');
            //updateReviewModuleUseLabelModule(targetNode.data.moduleId, sourceNode.data.moduleId, 'D');
            updateReviewModuleUseLabelModule({nodeInfo: {nodes: nodes, edges: edges}})
        }

        edgeUpdateSuccessful.current = true;
    }, []);

    return (
        <>
            <ModuleOffcanvas/>
            <DatasetOffcanvas  canvasDataTy={canvasDataTy}/>
            <LabelOffcanvas canvasDataTy={canvasDataTy}/>
            <ReviewOffcanvas canvasDataTy={canvasDataTy}/>
            <div className="reactflow-wrapper" ref={reactFlowWrapper} style={{height: 'calc(100vh - 72px)'}} onContextMenu={(event) => {
                event.preventDefault();
                if (!event.target.classList.contains('react-flow__pane')) {
                    return;
                }
                return dispatch(manage_project_workflow_module_setIsModuleOffcanvas(true));
            }}>
                <ReactFlow
                    id={"moduleReactFlow"}
                    ref={ref}
                    nodes={nodes}
                    edges={edges}
                    /*onNodeClick={onNodeClick}*/
                    onNodesChange={onNodesChange}
                    onEdgesChange={onEdgesChange}
                    onConnect={onConnect}
                    onConnectStart={(params) => console.log('connect start', params)}
                    onConnectEnd={(params) => console.log('connect end', params)}
                    onNodeDragStart={() => console.log("onNodeDragStart")}
                    onNodeDragStop={nodeAndEdgeSaveHandler}
                    onInit={setReactFlowInstance}
                    fitView
                    onDrop={onDrop}
                    onDragOver={onDragOver}
                    nodeTypes={nodeTypes}
                    edgeTypes={edgeTypes}
                    onEdgeUpdate={onEdgeUpdate}
                    onEdgeUpdateStart={onEdgeUpdateStart}
                    onEdgeUpdateEnd={onEdgeUpdateEnd}
                    defaultEdgeOptions={defaultEdgeOptions}
                    connectionLineComponent={ConnectionLine}
                    connectionLineStyle={connectionLineStyle}
                    onSelectionChange={onSelectionChange}
                    onNodeContextMenu={onNodeContextMenu}
                    deleteKeyCode={null}
                >
                    <Controls position={"top-left"} showInteractive={false}>
                            {/*<ControlButton onClick={()=>console.log("error 목록 뿌려주자")} title={"validList"} style={{"marginTop":"10px"}}>*/}
                            {/*    <img src={validList} style={{WebkitUserDrag: 'none'}}/>*/}
                            {/*    <span className="position-absolute topbar-badge fs-10 translate-middle badge rounded-pill bg-danger" style={{"right":"-20px", "paddingTop":"0.3em", "paddingRight":"0.35em", "paddingLeft":"0.35em"}}>*/}
                            {/*        <i className={"mdi mdi-exclamation-thick"}/>*/}
                            {/*    </span>*/}
                            {/*</ControlButton>*/}
                    </Controls>
                    <MiniMap/>
                    <Background color="#cfd8dc" gap={16} className="bg-coco-gray-50"/>
                </ReactFlow>
            </div>
        </>
    );
};

export default () => {
    const intl = useIntl();
    const history = useHistory();
    const wrkflowId= sessionStorage.getItem("wrkflowId");

    const setWorkflowImageSave = async () => {
        const canvas = await html2canvas(ref.current);
        const dataURL = canvas.toDataURL('image/png');
        await ManagerWorkflowService.saveWorkflowThumnail({
            wrkflowId: wrkflowId,
            screenShot: dataURL
        })
    };

    // 새로고침 or 닫기 막기 변수
    // const preventClose = (e) => {
    //     e.preventDefault();
    //     e.returnValue = ""; // chrome에서는 설정이 필요해서 넣은 코드
    //     setWorkflowImageSave();
    // }
    //
    // useEffect(()=>{
    //     (() => {
    //         window.addEventListener("beforeunload", preventClose);
    //     })();
    //
    //     return () => {
    //         console.log('종료?');
    //         window.removeEventListener("beforeunload", preventClose);
    //     };
    // },[])

    const workflowListLinkedClick = () => {
        setWorkflowImageSave();
        confirm("common.confirm"
            , "alert.exit"
            , () => {
                history.push({
                    pathname: "/manager/project/workflow"
                });
            }
            , null
            , null
            , intl);
    }

    return (
        <div className="dndflow">
            <div className="workflow-canvas-div">
                <Link to="#/" onClick={(e) => {
                    e.preventDefault()
                    workflowListLinkedClick()
                }}>
                    <i className="ri-arrow-left-s-line"></i>
                </Link>
                {GetMessage("workflow.workflowCanvas")}
            </div>
            <ReactFlowProvider>
                <AddWorkFlow/>
            </ReactFlowProvider>
        </div>
    );
}
