import React, {useEffect, useState} from 'react';
import {Card, CardBody, CardHeader, Row} from "reactstrap";
import Select from "react-select";
import {
    defaultSelectStyle,
    formatGroupLabelNoCount,
    noBorderBackgroundTextSelectStyle
} from "../../../../../../../../../components/constants/select";
import {GetMessageIntl} from "../../../../../../../../../util/message";
import {useIntl} from "react-intl";
import {useDispatch, useSelector} from "react-redux";
import {
    setManageWorkflowLabelActiveData,
    setManageWorkflowLabelInactiveData
} from "../../../../../../../../../store/manage/project/workflow/label/action";
import {alertError} from "../../../../../../../../../util/AlertUtil";

function LabelRegistDataFormRow({ id, formId, type, essentialYn, example, regexp }) {
    const intl = useIntl();
    const dispatch = useDispatch();
    const {activeData, inactiveData, selectedId, errorData, dataConfig} = useSelector((state) => ({
        activeData: state.ManageWorkflowLabel.activeData,
        inactiveData: state.ManageWorkflowLabel.inactiveData,
        selectedId: state.ManageWorkflowLabel.selectedId,
        errorData: state.ManageWorkflowLabel.errorData,
        dataConfig: state.ManageWorkflowLabel.dataConfig
    }));

    const formTypeOption = [
        { label : GetMessageIntl(intl, "workflow.dataConfig.choice"),  value : "" }
        ,{ label : GetMessageIntl(intl, "workflow.dataConfig.telNo"),  value : "telNo"  }
        ,{ label : GetMessageIntl(intl, "workflow.dataConfig.address"),  value : "address"  }
    ];

    const requiredTypeOption = [
        {label : GetMessageIntl(intl, "common.required"),   value : "Y"  }
        ,{label : GetMessageIntl(intl, "common.choice"),    value : "N"  }
    ];

    const [formType, setFormType] = useState({});
    const [essentialType, setEssentialType] = useState({});
    const [exam, setExam] = useState("");
    const [regexpVal, setRegexpVal] = useState("");

    useEffect(()=>{
        setFormType(formTypeOption.find(item => item.value === type));
        setEssentialType(requiredTypeOption.find(item=> item.value === essentialYn));
        setExam(example);
        setRegexpVal(regexp);
    },  [])

    const updateActiveData = (id, newData) => {
        const updateData = activeData.map((item) => item.id === id ? {
            ...item,
            body: {...item.body, content: newData}
        } : item);
        dispatch(setManageWorkflowLabelActiveData(updateData));
    }

    const updateInactiveData = (id, newData) => {
        const updateData = inactiveData.map((item) => item.id === id ? {
            ...item,
            body: {...item.body, content: newData}
        } : item);
        dispatch(setManageWorkflowLabelInactiveData(updateData));
    }

    useEffect(()=>{
        const targetForm = dataConfig.find(item => item.id === selectedId)
        const formDatas = targetForm.body.content;
        const updateData = formDatas?.map((item) => item.id === formId ? {...item, type: formType?.value, essentialYn : essentialType? essentialType.value:"Y", example : exam, regexp: regexpVal} : item);
        if(targetForm.useYn === "Y"){
            updateActiveData(selectedId, updateData);
        }else{
            updateInactiveData(selectedId, updateData);
        }
    }, [formType, essentialType, exam, regexpVal])

    const deleteFormRow = (id) => {
        const targetForm = dataConfig.find(item => item.id === selectedId)
        const formDatas = targetForm.body.content;
        const filteredDatas = formDatas.filter(item => item.id !== id);
        if(targetForm.useYn === "Y"){
            updateActiveData(selectedId, filteredDatas);
        }else{
            updateInactiveData(selectedId, filteredDatas);
        }
    }

    const handleFormTypeSelectChange = (e) => {
        if(e.value !=''){
            const targetForm = dataConfig.find(item => item.id === selectedId)
            const dupType = targetForm.body.content.filter((content)=> content.id != formId && content.type == e.value);
            if(dupType.length>0){
                alertError(
                    "workflow.dataConfig.dupFromTypeTitle"
                    ,"workflow.dataConfig.dupFromTypeMsg"
                    ,null
                    ,null
                    ,null
                    ,intl
                );

                return ;
            }
        }
        setFormType(formTypeOption.find(item=> item.value === e.value))
    }

    const handleEssentialTypeSelectChange = (e) => {
        setEssentialType(requiredTypeOption.find(item => item.value === e.value));
    }
    
    const handleExamInputChange = (e) => {
        setExam(e.target.value)
    }
    
    const handleRegrInputChange = (e) => {
        setRegexpVal(e.target.value)
    }

    return (
        <Card className="list-group-item projectAttributeItem p-0 mb-12 mr-2 border-1">
            <CardHeader className="card-header d-flex align-items-center border-0 pd-y-10 pd-x-12 bg-coco-gray-50 text-coco-gray-400">
                <div className={"mr-6 handle text-pointer"}>
                    <i className="bx bx-grid-vertical tx-22"></i>
                    <input type="hidden" name="id" value={formId}/>
                </div>
                <div className={"wd-90p mr-12"}>
                    <div className={errorData.dataItems && errorData.dataItems.find((item)=> item.contentId && item.contentId == formId && item.id === selectedId && item.error == 'type') ? "validation-select-error":""}>
                        <Select
                            className="registDataCustomSelect"
                            placeholder={GetMessageIntl(intl, "workflow.dataConfig.choice")}
                            options={formTypeOption}
                            value={formType}
                            onChange={handleFormTypeSelectChange}
                            styles={defaultSelectStyle}
                            isSearchable={false}
                            maxMenuHeight={250}
                        />
                    </div>
                    {
                        errorData.dataItems && errorData.dataItems.map((item) => {
                            if(item.contentId && item.contentId == formId && item.id === selectedId && item.error == 'type'){
                                return (
                                    <p className="validation-error-message mb-0">{GetMessageIntl(intl, "validation.required")}</p>
                                )
                            }
                        })
                    }
                </div>
                <div className="wd-25p mr-12">
                    <Select
                        name=""
                        value={essentialType}
                        isSearchable={false}
                        options={requiredTypeOption}
                        onChange={handleEssentialTypeSelectChange}
                        placeholder={GetMessageIntl(intl,"common.selectOptions")}
                        formatGroupLabel={formatGroupLabelNoCount}
                        styles={noBorderBackgroundTextSelectStyle}
                        className="mb-0"
                        maxMenuHeight={250}
                    />
                </div>
                <i className="ri-close-line tx-22 ps-2 delProjectAttribute text-pointer" onClick={()=> deleteFormRow(formId)}></i>
            </CardHeader>
            <CardBody>
                <div className="mb-3">
                    <h6 className="card-title">{GetMessageIntl(intl, "workflow.dataConfig.example")}</h6>
                    <input
                        onChange={handleExamInputChange}
                        value={exam}
                        className="form-control form-control-sm"
                        placeholder={GetMessageIntl(intl, "workflow.dataConfig.exampleNotice")}/>
                </div>
                <div>
                    <h6 className="card-title">{GetMessageIntl(intl, "workflow.dataConfig.limitTextType")}</h6>
                    <input
                        onChange={handleRegrInputChange}
                        value={regexpVal}
                        className="form-control form-control-sm"
                        placeholder={GetMessageIntl(intl, "workflow.dataConfig.regexNotice")}
                    />
                </div>
            </CardBody>
        </Card>
    );
}

export default LabelRegistDataFormRow;