import React, {useState} from 'react';
import {
    alertSuccess
} from "../../util/AlertUtil";
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    Col,
    Label,
    Row
} from "reactstrap";
import {useIntl} from "react-intl";
import {callApi} from "../../apis/utils/AxiosUtil";

const DashboardBatchTest = () => {
    const intl = useIntl();
    const clickBatch = async () => {
        console.log('배치를 실행했습니다.')
        const res = await callApi('/service/batchTest/makeDashboard');
        alert('배치가 완료되었습니다.')
    }

    return (
        <>
            <Row>
                <Col lg={12}>
                    <Card>
                        <CardHeader className="fw-semibold">
                            대시보드 배치
                        </CardHeader>
                        <CardBody className="card-body">
                            <div className="live-preview">
                                <Row className="gy-4">
                                    <Col xxl={2} md={6}>
                                        <div>
                                            <Label htmlFor="placeholderInput" className="form-label">대시보드 배치</Label>
                                            <div>
                                                <Button onClick={clickBatch} color="secondary">실행</Button>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </CardBody>
                    </Card>
                </Col>
           </Row>
        </>
    );
}

export default DashboardBatchTest;