// @ts-nocheck
import { SubmitButton } from "pages/user/label/cmmn/components/taskSideBar/SubmitButton"
import { useIntl } from "react-intl";
import {GetMessageIntl} from "../../../../../../../../util/message";
import {checkValidation, submitAnnotations as submitLabel} from "../../../../../../../../store/label/action";
import {confirm} from "../../../../../../../../util/AlertUtil";
import {checkValidation as reviewCheckValidation} from "../../../../../../../../store/review/action";
import {useEffect, useState} from "react";
import {MD_TY_CD} from "../../../const";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router";

interface Props {
    widthPercent: number;
}
export const SubmitButtonContainer = (props: Props) => {
    const {widthPercent} = props;
    const dispatch = useDispatch();
    const history = useHistory();
    const intl = useIntl();
    const {
        Label: {
            Cmmn: {
                module: {
                    instance: moduleInstance,
                    type: moduleType
                }
            }
        }
    } = useSelector((state) => state);

    const onSubmitAnnotation = () => {
        dispatch(checkValidation(intl));
        confirm("label.submitConfirm","label.submitConfirmText"
            , () => {
                dispatch(submitLabel(moduleInstance, intl, history, dispatch));
            }
            ,"","",intl)
    }
    const onSubmitReviewAnnotation = () => {
        dispatch(reviewCheckValidation(moduleInstance, intl, history, dispatch));
    }

    // 기존 핸들러
    const [clickHandler, setClickHandler] = useState<() => void>(null);
    useEffect(() => {
        if (moduleType === MD_TY_CD.LABEL) {
            setClickHandler(()=>()=>onSubmitAnnotation());
        } else if (moduleType === MD_TY_CD.REVIEW) {
            setClickHandler(()=>()=>onSubmitReviewAnnotation());
        } else {
            // Error
        }
    }, [moduleType])

    return (
        <>
            <SubmitButton type="button" widthPercent={widthPercent} onClick={clickHandler} text={GetMessageIntl(intl, 'ui.U00063')}/>
        </>
    )
}
