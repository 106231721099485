import {SelectView} from "./SelectView";
import {CheckBoxView} from "./CheckBoxView";
import {Review as ClassificationReview} from "../../../containers/taskSideBar/classification/Review";
import React, {useEffect} from "react";
import {MD_STTUS_CD, MD_TY_CD} from "../../../../annotation/image/const";
import {AttributeType} from "../../../../annotation/image/work/core/enums";
import { useIntl } from "react-intl";
import {GetMessageIntl} from "../../../../../../../util/message";

interface Props{
    label: any;
    moduleType: MD_TY_CD;
    status: MD_STTUS_CD;
    shapes: any;
    reviews: any;
    modifiedList: boolean[];
    clsfcSmryForm: any;
}

export const TaskContents = (props: Props) => {
    const {label, moduleType, status, shapes, reviews, clsfcSmryForm, modifiedList} = props;
    const intl = useIntl();
    const renderCn = (inputType, item, values, disabled, required) => {
        const requiredMessage = required ? GetMessageIntl(intl, 'label.classifyValidationError') : false;
        switch (inputType){
            case "select":
                return <SelectView item={item} values={values} disabled={disabled} clsfcSmryForm={clsfcSmryForm} required={requiredMessage}/>
            case "checkbox":
                return <CheckBoxView item={item} values ={values} disabled={disabled} clsfcSmryForm={clsfcSmryForm} required={requiredMessage}/>
            default :
                return <></>
        }
    }

    useEffect(() => {
        if (shapes.length) {   // 이전 라벨링 결과가 있다면 hookform에 value를 등록해준다.
            const answer = shapes.filter(s => s.id === label.id)[0].answer;
            const value = label.attributes[0].inputType === AttributeType.SELECT ? answer[0] : answer;
            clsfcSmryForm.setValue(`shapes[${label.id}]`, value);
        }
    }, [shapes])

    return(
        <>
            <div className="mb-12">
                {
                    // 작업 오류 메세지
                    clsfcSmryForm?.formState?.errors?.shapes && clsfcSmryForm?.formState?.errors?.shapes[label.id] ?
                        <div className="fs-12 fw-400 mb-3 text-coco-danger-400 d-flex align-items-center gap-1">
							<i className="ri-error-warning-fill fs-16"></i>
                            {clsfcSmryForm?.formState?.errors?.shapes[label.id].message}</div>
                    :   // 수정 작업 오류 메시지
                        clsfcSmryForm?.formState?.errors?.modified && clsfcSmryForm?.formState?.errors?.modified[label.id] &&
                            <div className="fs-12 fw-400 mb-3 text-coco-danger-400 d-flex align-items-center gap-1">
                                <i className="ri-error-warning-fill fs-16"></i>
                                {clsfcSmryForm?.formState?.errors?.modified[label.id].message}</div>
                }
                {
                    // 검수 오류 메세지
                    clsfcSmryForm?.formState?.errors?.review && clsfcSmryForm?.formState?.errors?.review.classes[label.order] &&
                    clsfcSmryForm?.formState?.errors?.review.classes[label.order].q1.content &&
                    <>
                        {clsfcSmryForm?.formState?.errors?.review.classes[label.order].q1.content[0].details &&
                            <div className="fs-12 fw-400 mb-3 text-coco-danger-400 d-flex align-items-center gap-1">
								<i className="ri-error-warning-fill fs-16"></i>
                                {clsfcSmryForm?.formState?.errors?.review.classes[label.order].q1.content[0].details.message}</div>}
                        {clsfcSmryForm?.formState?.errors?.review.classes[label.order].q1.content[0].touch &&
							<div className="fs-12 fw-400 mb-3 text-coco-danger-400 d-flex align-items-center gap-1">
								<i className="ri-error-warning-fill fs-16"></i>
                                {clsfcSmryForm?.formState?.errors?.review.classes[label.order].q1.content[0].touch.message}</div>}
                    </>
                }
                <span className="fs-16 text-coco-gray-700 fw-semibold">{label.question}
                    {
                        label.attributes[0].required === 1 &&
                            <span className="text-coco-danger-700">*</span>
                    }
                </span>
            </div>
            {/* 문제 렌더링 */}
            <div className={"mb-0"}>
                {
                    label.attributes.map((element, index) => {
                        const values = element.values;
                        const inputType = element.inputType;
                        const required = element.required;
                        return (
                            <div key={`attributes-${label.id}-${index}`}>
                                {renderCn(inputType, label, values, moduleType === MD_TY_CD.REVIEW || modifiedList[label.id], required)}
                                {/*검수일 경우*/}
                                {(moduleType === MD_TY_CD.LABEL && status === MD_STTUS_CD.RE) && <ClassificationReview reviews={reviews} label={label} hookform={clsfcSmryForm}/>}
                                {(moduleType === MD_TY_CD.REVIEW) && <ClassificationReview reviews={reviews} label={label} hookform={clsfcSmryForm}/>}
                            </div>
                        )
                    })
                }
            </div>
        </>
    )
}
