import { all, fork } from "redux-saga/effects";
//layout
import LayoutSaga from "./layouts/saga";
//depth layout
import depthLayoutSaga from "./depthLayouts/saga";
//manage
import ManageSaga from "./manage/saga";
import ManageLabelSaga from "./manage/label/saga";
import LabelSaga from "./label/saga";
import ManageWorkflowModuleSaga from "./manage/project/workflow/module/saga";
import ReviewSaga from "./review/saga";
import ManageWorkflowLabelSaga from "./manage/project/workflow/label/saga";
import ManageWorkflowReviewSaga from "./manage/project/workflow/review/saga";
import DetailsSaga from "./dataset/details/saga";
import DatasetSaga from "./dataset/saga";
import ReviewClsfcSmrySaga from "./review/clsfcSmry/saga";

export default function* rootSaga() {
	yield all([
		//public
		fork(LayoutSaga),
		fork(depthLayoutSaga),
		fork(ManageSaga),
		fork(ManageWorkflowModuleSaga),
		fork(ManageWorkflowLabelSaga),
		fork(ManageWorkflowReviewSaga),
		fork(ManageLabelSaga),
		fork(LabelSaga),
		fork(ReviewSaga),
		fork(ReviewClsfcSmrySaga),
		fork(DetailsSaga),
		fork(DatasetSaga)
	]);
}
