// Copyright (C) 2020-2022 Intel Corporation
//
// SPDX-License-Identifier: MIT
// @ts-nocheck
import { connect } from 'react-redux';

import { Canvas } from 'pages/user/label/annotation/image/cvat-canvas-wrapper';
import { selectIssuePosition as selectIssuePositionAction, rotateCurrentFrame } from 'store/label/action';
import ControlsSideBarComponent from 'pages/user/label/annotation/image/components/review-workspace/controls-side-bar/controls-side-bar';
import { ActiveControl, CombinedState, Rotation } from 'store/label';
import { KeyMap } from 'pages/user/label/annotation/image/utils/mousetrap-react';

interface StateToProps {
    canvasInstance: Canvas;
    rotateAll: boolean;
    activeControl: ActiveControl;
    keyMap: KeyMap;
    normalizedKeyMap: Record<string, string>;
    frameIsDeleted: boolean;
}

interface DispatchToProps {
    rotateFrame(angle: Rotation): void;
    selectIssuePosition(enabled: boolean): void;
}

function mapStateToProps(state: CombinedState): StateToProps {
    const {
        Label: {
            Cmmn: {
                canvas: {instance: canvasInstance, activeControl},
                module: {labels},
                player: {frame: {frameData}},
            }
        },
        settings: {
            player: { rotateAll },
        },
        shortcuts: { keyMap, normalizedKeyMap },
    } = state;

    return {
        rotateAll,
        canvasInstance: canvasInstance as Canvas,
        activeControl,
        labels,
        normalizedKeyMap,
        keyMap,
        frameData
    };
}

function dispatchToProps(dispatch: any): DispatchToProps {
    return {
        selectIssuePosition(enabled: boolean): void {
            dispatch(selectIssuePositionAction(enabled));
        },
        rotateFrame(rotation: Rotation): void {
            dispatch(rotateCurrentFrame(rotation));
        },
    };
}

export default connect(mapStateToProps, dispatchToProps)(ControlsSideBarComponent);
