import SimpleBar from "simplebar-react";
import {Card, CardBody, CardHeader, Col, Input, Label, ListGroup, OffcanvasBody, Row} from "reactstrap";
import {GetMessageIntl} from "../../../../../../../../util/message";
import {Controller, useFormContext} from "react-hook-form";
import React, {useCallback, useEffect, useState} from "react";
import {useIntl} from "react-intl";
import {useDispatch, useSelector} from "react-redux";
import {useDropzone} from "react-dropzone";
import {
    workflow_review_testConfig_clearHasErrorFile,
    workflow_review_testConfig_removeFile, workflow_review_testConfig_resetFileLabels,
    workflow_review_testConfig_setFileAttributes,
    workflow_review_testConfig_setFileLabels,
    workflow_review_testConfig_setFiles,
    workflow_review_testConfig_setSelectedFile
} from "../../../../../../../../store/manage/project/workflow/review/TestConfig/action"
import {ModuleService} from "../../../../../../../../apis/api/ModuleService";
import ClassAndAttribute from "./testConfigComponent/ClassAndAttribute";
import WorkflowReviewTestConfig from "../../../../../../../../store/manage/project/workflow/review/TestConfig/reducer";

function TestConfigDiv() {
    const intl = useIntl();
    const dispatch = useDispatch();
    const {
        control: controlTest
        , register: registerTest
        , setValue: setValueTest
        , getValues: getValuesTest
        , reset: resetTest
        , resetField: resetFieldTest
        , clearErrors: clearErrorsTest
        , watch: watchTest
        , trigger : triggerTest
        , formState: {errors: errorsTest}
        ,handleSubmit : handleSubmitTest
    } = useFormContext();

    const [files, setFiles] = useState([])
    const [fileCount, setFileCount] = useState(0);
    const [totalFileCountText, setTotalFileCountText] = useState(GetMessageIntl(intl, "workflow.testConfig.questionPoolCount", {cnt: 0}));
    const [selectedFileName, setSelectedFileName] = useState("")
    const {
        initFlag,
        selectionNode,
        testConfig,
        hasErrorFiles,
        shapeConfig
    } = useSelector((state) => ({
        initFlag: state.ManageWorkflowReview.initFlag,
        selectionNode: state.ManageWorkflowModule.selectionNode,
        testConfig: state.ManageWorkflowReview.testConfig,
        hasErrorFiles: state.WorkflowReviewTestConfig.hasErrorFiles,
        shapeConfig: state.ManageWorkflowReview.shapeConfig
    }));

    // useEffect(() => {
    //     console.log('hasErrorFiles', hasErrorFiles);
    // },[hasErrorFiles]);

    const oldFileProcess = (imageList) => {
        const oldFiles = [];
        let count = 0;
        imageList.forEach((item) => {
            const f = new File([""], item.realFileName)
            f.isNew = item.isNew;
            f.host = item.host;
            f.url = item.url;
            f.realFileName = item.realFileName
            f.imageUrl = item.host + item.url
            oldFiles.push(f)
            count++;
        })
        //setFiles(files.concat(oldFiles));
        //dispatch(workflow_review_testConfig_setFiles(files.concat(oldFiles)));

        setFiles(oldFiles);
        dispatch(workflow_review_testConfig_setFiles(oldFiles));
        setFileCount(count);
        setValueTest("fileCount", count);
    }
    useEffect(() => {
        if(initFlag){
            // 파일을 초기화 해준다..
            dispatch(workflow_review_testConfig_resetFileLabels());
            setFiles([]);
            setFileCount(0);
            setSelectedFileName("");
            if (testConfig) {
                setValueTest('title', testConfig.title)
                setValueTest('questionCount', testConfig.questionCount)
                setValueTest('desc', testConfig.desc)
                setValueTest('passCount', testConfig.passCount)
                setValueTest('questionOrderTy', testConfig.questionOrderTy)

                // 이미지 처리
                let imageList = testConfig.questions.map(questionItem => {
                    return questionItem.image
                });
                oldFileProcess(imageList);

                let questionsItemList = testConfig.questions.map(questionItem => {
                    return questionItem
                });
                questionsItemList.forEach((item) => {
                    dispatch(workflow_review_testConfig_setFileLabels(item.image.realFileName, item.labels, "I", item.comment, item.rejectReason))
                    for (const [key, value] of Object.entries(item.attributes)) {
                        dispatch(workflow_review_testConfig_setFileAttributes(item.image.realFileName, key, value))
                    }
                })
            } else {
                // 처음 등록할때 처리할거 있으면 요기다가
                setValueTest('questionOrderTy', '01'); // 제출순서 default : 순차
            }
        }

    }, [initFlag]);

    useEffect(() => {
        dispatch(workflow_review_testConfig_setSelectedFile({
            fileName: '',
            isClick: false,
            imagePreView: ''
        }))
        dispatch(workflow_review_testConfig_setFileLabels("", {}, "", "",[]))
        dispatch(workflow_review_testConfig_setFileAttributes("", "", ""))
    }, []);

    const changeLabel = (labelId) => {
        if (selectedFileName !== '') {
            dispatch(workflow_review_testConfig_setFileLabels(selectedFileName, labelId, 'U',"",[]));

            clearCheckErrors();
            let thisError = hasErrorFiles.filter((errorFile)=> errorFile === selectedFileName).length;
            if(thisError > 0) {
                // 오류 내용 삭제.
                dispatch(workflow_review_testConfig_clearHasErrorFile(selectedFileName));
            }

        }
    }

    const changeAttribute = async (id, val) => {
        if (selectedFileName !== '') {
            dispatch(workflow_review_testConfig_setFileAttributes(selectedFileName, id, val));

            let thisError = hasErrorFiles.filter((errorFile)=> errorFile === selectedFileName).length;
            if(thisError > 0) {
                const result = await errorCheckTrigger();
                if(result){
                    // 오류 내용 삭제.
                    dispatch(workflow_review_testConfig_clearHasErrorFile(selectedFileName));
                }
            }
        }
    }

    const errorCheckTrigger = async()=>{
        let arrBasicProp = ['questionOrderTy', 'title', 'fileCount', 'questionCount', 'passCount', 'desc'];
        let arrAllProp = Object.keys(getValuesTest());
        let arrChkProp = arrAllProp.filter(x => !arrBasicProp.includes(x));

        const result = await triggerTest(arrChkProp);
        return result;
    }

    const clearCheckErrors = async()=>{
        let arrBasicProp = ['questionOrderTy', 'title', 'fileCount', 'questionCount', 'passCount', 'desc'];
        let arrAllProp = Object.keys(getValuesTest());
        let arrChkProp = arrAllProp.filter(x => !arrBasicProp.includes(x));
        clearErrorsTest(arrChkProp);
    }

    const onDrop = useCallback((acceptedFiles) => {
        const tList = [];

        for (let i = 0; i < acceptedFiles.length; i++) {
            const file = acceptedFiles[i];
            if (files.length > 0) {
                // 기존에 등록되어있던게 있으면
                let hasFile = false;
                for (let i = 0; i < files.length; i++) {
                    if (files[i].name === file.name
                        //    && files[i].size === file.size
                        //    && files[i].lastModified === file.lastModified
                    ) {
                        // 이름도 같고, 크기도 같고, 마지막 수정일도 같으면 중복으로
                        hasFile = true;
                        break;
                        //this.removeFile(file);
                    }
                }
                if (!hasFile) {
                    tList.push(file);
                } else {

                }
            } else {
                tList.push(file);
            }
        }

        const newFileLabelId = shapeConfig && shapeConfig.labels && shapeConfig.labels.length>0? shapeConfig.labels[0].id: 0;
        const newAccptedFiles = tList.map(file => {
                file.isNew = true;
                dispatch(workflow_review_testConfig_setFileLabels(file.name, newFileLabelId, 'I', "",[]));
                return Object.assign(file, {
                    preview: URL.createObjectURL(file)
                })
            }
        )

        let list = files.concat(newAccptedFiles)
        setFiles(list);
        setFileCount(list.length);
        setValueTest("fileCount", list.length);
        clearErrorsTest('fileCount');
        dispatch(workflow_review_testConfig_setFiles(list));
    }, [files, shapeConfig]);


    useEffect(() => {
        setTotalFileCountText(GetMessageIntl(intl, "workflow.testConfig.questionPoolCount", {cnt: fileCount}));
    }, [fileCount])


    const style = {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '10px',
        borderWidth: 2,
        borderRadius: 7,
        borderColor: '#CFD8DC',
        borderStyle: 'dashed',
        backgroundColor: '#FFFFFF',
        color: '#bdbdbd',
        outline: 'none',
        transition: 'border .24s ease-in-out',
        /*height: "50px"*/
    };

    const {
        getRootProps,
        getInputProps,
    } = useDropzone({
        onDrop,
        accept: {'image/*': []},
        maxFiles: 20
    });

    const rowClick = (e, file, type) => {
        e.stopPropagation();
        if (type === '01') {
            setSelectedFileName(file.name);
            if (file.isNew) {
                dispatch(workflow_review_testConfig_setSelectedFile({
                    fileName: file.name,
                    isClick: true,
                    imagePreView: file.preview
                }))
            } else {
                dispatch(workflow_review_testConfig_setSelectedFile({
                    fileName: file.name,
                    isClick: true,
                    imagePreView: file.imageUrl
                }))
            }
        } else if (type === '02') {
            const newFiles = [...files]
            newFiles.splice(newFiles.indexOf(file), 1)
            setFiles(newFiles);

            if (file.name === selectedFileName) {
                dispatch(workflow_review_testConfig_setSelectedFile({
                    fileName: ""
                    , isClick: false
                    , imagePreView: null
                }))
                setSelectedFileName("");
            }
            setFileCount(newFiles.length)
            setValueTest("fileCount", newFiles.length);
            dispatch(workflow_review_testConfig_removeFile(file.name, file.imageUrl, file.isNew));
        }
    }

    return (
        <>
            <SimpleBar style={{maxHeight: "calc(100vh - 229px)"}} autoHide={false} className="simplebar-track-coco">
                <OffcanvasBody style={{minHeight: "300px"}} className={"pd-x-0-f pd-b-0"}>
                    <div className={"pd-x-24"}>
                        <h5 className="module-title">
                            <i className="ri-file-info-line"></i>{GetMessageIntl(intl, "workflow.testConfig.testInfo")}
                        </h5>
                        {shapeConfig && shapeConfig.labels && shapeConfig.labels.length>0?
                            <>
                                <Row className="mn-ht-38 d-flex align-items-center mb-8">
                                    <Col className={"col-xl-1-2 align-middle"}>
                                        <Label htmlFor="title" className="mb-0">{GetMessageIntl(intl, "workflow.title")}<span className="text-coco-danger-400">*</span></Label>
                                    </Col>
                                    <Col className={"col-xl-10-8"}>
                                        <div className={errorsTest.title ? "validation-error" : ""}>
                                            <Controller
                                                render={({field}) => {
                                                    const {onChange} = field;
                                                    return (<Input {...field} placeholder={GetMessageIntl(intl, "common.enterInfo")} onChange={(event)=>{
                                                        const newValue = event.target.value;
                                                        onChange(newValue);
                                                        clearErrorsTest(event.target.name);
                                                    }
                                                    }/>);
                                                }}
                                                name="title"
                                                defaultValue=""
                                                control={controlTest}
                                                rules={{
                                                    required: true,
                                                }}
                                                className="form-control lh-18"
                                            />
                                        </div>
                                        {errorsTest.title && <p className="validation-error-message mb-0">{GetMessageIntl(intl, "validation.required")}</p>}
                                    </Col>
                                </Row>
                                <Row className="mn-ht-38 d-flex align-items-center mb-8">
                                    <Col className={"col-xl-1-2 align-middle pd-r-0"}>
                                        <Label htmlFor="standardTy1" className="mb-0">{GetMessageIntl(intl, "workflow.testConfig.questionCount")}<span className="text-coco-danger-400">*</span></Label>
                                    </Col>
                                    <Col className={"col-xl-2-8"}>
                                        <div className={errorsTest.questionCount ? "validation-error" : ""}>
                                            <Controller
                                                render={({field}) => {
                                                    const {onChange} = field;
                                                    return (<Input {...field} placeholder={GetMessageIntl(intl, "workflow.testConfig.questionCountRequired")} onChange={(event)=>{
                                                        const newValue = event.target.value;
                                                        onChange(newValue);
                                                        clearErrorsTest(event.target.name);
                                                    }}/>);
                                                }}
                                                name="questionCount"
                                                defaultValue=""
                                                control={controlTest}
                                                rules={{
                                                    required: GetMessageIntl(intl,"validation.required"),
                                                    pattern: {
                                                        value: /^[0-9]+$/,
                                                        message:GetMessageIntl(intl,"validation.onlyNumber")
                                                    },
                                                    validate: (value, formValues) => {
                                                        if(parseInt(value) <=  0){
                                                            return GetMessageIntl(intl,"validation.greaterThanZero");
                                                        }
                                                        const fileCount = getValuesTest("fileCount");
                                                        if(parseInt(fileCount) !=0){
                                                            if(parseInt(value)>parseInt(fileCount)){
                                                                return GetMessageIntl(intl,"workflow.testConfig.fileCntLessCnt");
                                                            }
                                                        }
                                                    }
                                                }}
                                                className="form-control lh-18"
                                            />
                                        </div>
                                        {errorsTest.questionCount && <p className="validation-error-message mb-0">{errorsTest.questionCount.message}</p>}
                                    </Col>
                                    <Col className={"col-xl-1-2 align-middle pd-r-0"}>
                                        <Label htmlFor="standardTy1" className="mb-0">{GetMessageIntl(intl, "workflow.testConfig.passCount")}<span className="text-coco-danger-400">*</span></Label>
                                    </Col>
                                    <Col className={"col-xl-2-8"}>
                                        <div className={errorsTest.passCount ? "validation-error" : ""}>
                                            <Controller
                                                render={({field}) => {
                                                    const {onChange} = field;
                                                    return (<Input {...field} placeholder={GetMessageIntl(intl, "workflow.testConfig.passCountRequired")} onChange={(event)=>{
                                                        const newValue = event.target.value;
                                                        onChange(newValue);
                                                        clearErrorsTest(event.target.name);
                                                    }}/>);
                                                }}
                                                name="passCount"
                                                defaultValue=""
                                                control={controlTest}
                                                rules={{
                                                    required: GetMessageIntl(intl,"validation.required"),
                                                    pattern: {
                                                        value: /^[0-9]+$/,
                                                        message:GetMessageIntl(intl,"validation.onlyNumber")
                                                    },
                                                    validate: (value, values) => {
                                                        const questionCount = getValuesTest("questionCount");
                                                        if(parseInt(value) <=  0){
                                                            return GetMessageIntl(intl,"validation.greaterThanZero");
                                                        }
                                                        if(questionCount !=''){
                                                            if(parseInt(value)>questionCount){
                                                                return GetMessageIntl(intl,"workflow.testConfig.questionCntLessCnt");
                                                            }
                                                        }
                                                    }
                                                }}
                                                className="form-control lh-18"
                                            />
                                        </div>
                                        {errorsTest.passCount && <p className="validation-error-message mb-0">{errorsTest.passCount.message}</p>}
                                    </Col>
                                    <Col className={"col-xl-1-2 align-middle pd-r-0"}>
                                        <Label htmlFor="standardTy1" className="mb-0">{GetMessageIntl(intl, "workflow.testConfig.questionOrderTy")}</Label>
                                    </Col>
                                    <Col className={"col-xl-2-8"}>
                                        <div className="form-check form-radio-coco form-check-inline">
                                            <input className="form-check-input" type="radio" id="questionOrderTy1" value="01" {...registerTest("questionOrderTy")}/>
                                            <Label className="form-check-label mb-0" htmlFor="questionOrderTy1">
                                                {GetMessageIntl(intl, "workflow.testConfig.questionOrderTyOrder")}
                                            </Label>
                                        </div>
                                        <div className="form-check form-radio-coco form-check-inline">
                                            <input className="form-check-input" type="radio" id="questionOrderTy2" value="02" {...registerTest("questionOrderTy")}/>
                                            <Label className="form-check-label mb-0" htmlFor="questionOrderTy2">
                                                {GetMessageIntl(intl, "workflow.testConfig.questionOrderTyRandom")}
                                            </Label>
                                        </div>

                                    </Col>
                                </Row>
                                <Row className="d-flex align-items-start">
                                    <Col className={"col-xl-1-2 align-middle pd-r-0"}>
                                        <Label htmlFor="taskDc" className="mb-0">{GetMessageIntl(intl, "workflow.dc")}<span className="text-coco-danger-400">*</span></Label>
                                    </Col>
                                    <Col className={"col-xl-10-8"}>
                                        <div className={errorsTest.desc ? "validation-error" : ""}>
                                            <Controller
                                                render={({field}) => {
                                                    const {onChange} = field;
                                                    return (<textarea {...field} placeholder={GetMessageIntl(intl,"workflow.testConfig.descRequired")} className="form-control" row={"3"} style={{resize: 'none'}} maxLength={100} onChange={(event)=>{
                                                        const newValue = event.target.value;
                                                        onChange(newValue);
                                                        clearErrorsTest(event.target.name);
                                                    }}/>);
                                                }}
                                                name="desc"
                                                control={controlTest}
                                                rules={{
                                                    required: true,
                                                }}
                                                defaultValue=""
                                                className="form-control lh-18"
                                            />
                                        </div>
                                        {errorsTest.desc && <p className="validation-error-message mb-0">{GetMessageIntl(intl,"validation.required")}</p>}
                                    </Col>
                                </Row>
                            </>
                            :<div className={"d-flex align-items-center justify-content-center"}
                                  style={{height: "calc(100vh - 350px)"}}>
                                <span className="text-center text-coco-gray-200 tx-16 fw-500 lh-18" dangerouslySetInnerHTML={{ __html: GetMessageIntl(intl,"workflow.testConfig.emptyShapeConfig")}}></span>
                            </div>
                        }
                    </div>

                    {shapeConfig && shapeConfig.labels && shapeConfig.labels.length>0?
                        <Row className={"mt-24 border-top d-flex align-items-start justify-content-center mx-0"} style={{minHeight: "calc(100vh - 700px)"}}>
                            <Col className={"col-xl-4-5 pd-x-0"}>
                                <Card className="shadow-none mb-0">
                                    <CardHeader className="pd-x-22 pd-y-16 border-0 d-flex align-items-center justify-content-start tx-16 lh-18 text-coco-gray-700 fw-500">
                                        {GetMessageIntl(intl, "workflow.testConfig.questionPool")}<span className={"fw-normal text-coco-gray-400 ml-8"}>({totalFileCountText})</span>
                                    </CardHeader>
                                    <CardBody className="p-0">
                                        <div {...getRootProps({style})} className={"mx-22 mb-12"}>
                                            <div>
                                                <input name="file" type="file" multiple="multiple" {...getInputProps()}/>
                                            </div>
                                            <div className="dz-message needsclick text-coco-gray-100">
                                                <div className="tx-16 text-center" dangerouslySetInnerHTML={{ __html: GetMessageIntl(intl,"workflow.testConfig.fileRequired")}}>
                                                </div>
                                            </div>
                                        </div>
                                        <input type="hidden" defaultValue={0} {...registerTest("fileCount",{
                                            min: 1,
                                        })}/>
                                        {errorsTest.fileCount && <p className={"validation-error-message mb-0 mx-22"}>{GetMessageIntl(intl,"validation.required")}</p>}
                                        {/*<SimpleBar style={{maxHeight: "360px"}}>*/}
                                        <ul className="list-unstyled mb-0 testDropzoneDiv" id="dropzone-preview">
                                            {files.map((file, index) => {
                                                return (
                                                    <li className={"border-0 testDropzoneItem list-group-item list-group-item-action pd-y-13 hstack gap-3 tx-basic".concat(hasErrorFiles.find(item=>item === file.name) ? " hasError":"").concat(selectedFileName === file.name ?" selected":"" )}
                                                        id={"dropzone-preview-list".concat(index)} key={"files_".concat(index)}
                                                        onClick={(e) => {
                                                            rowClick(e, file, '01')
                                                        }}>
                                                        <div className="d-flex align-items-center wd-80p text-overflow">
                                                            <div className="me-3">
                                                            </div>
                                                            <span className="tx-16 fw-500 mb-0 text-overflow" data-dz-name>{file.name}</span>
                                                            {/*<strong className="error text-danger" data-dz-errormessage>{file.name}</strong>*/}
                                                        </div>
                                                        <div className="ms-auto">
                                                            <p className="mb-0 fs-14" data-dz-size></p>
                                                        </div>
                                                        <div className="pe-3">
                                                            <div data-dz-remove>
                                                                <i className="ri-close-line tx-18" onClick={(e) => {
                                                                    rowClick(e, file, '02')
                                                                }}></i>
                                                            </div>
                                                        </div>
                                                    </li>)
                                            })}
                                        </ul>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col className={"col-xl-7-5 pd-x-0 attribute-card-div"} style={{borderLeft: "1px dashed var(--coco-gray-100)"}}>
                                <ClassAndAttribute
                                    // shapeConfig={shapeConfig}
                                    changeAttribute={changeAttribute}
                                    changeLabel={changeLabel}
                                />
                            </Col>
                        </Row>
                        :null
                    }
                </OffcanvasBody>
            </SimpleBar>
        </>
    );
}


const selected = {
    background: "var(--coco-primary-50)",
    boxShadow: "6px 0px 0px 0px var(--coco-primary-400) inset"
}

const hasError = {
    background: "var(--coco-danger-a-50)",
    boxShadow: "6px 0px 0px 0px var(--coco-danger-400) inset"
}

const noPadding = {
    paddingLeft: "0px",
    paddingRight: "0px"
}

export default TestConfigDiv;

