// @ts-nocheck
import {GetMessageIntl} from "../../../../../../util/message";
import {updateCanvasSkipPopover} from "../../../../../../store/label/action";
import {useSelector} from "react-redux";
import {SkipPopover} from "./skip-popover";
import {useEffect} from "react";
import {IntlShape} from "react-intl/lib";

import './styles.scss'

interface Props {
    dispatch: any;
    history: any;
    intl: IntlShape;
}

export const SkipContainer = (props: Props) => {
    const {dispatch, intl} = props;
    const { visible } = useSelector(state => state.Label.Cmmn.canvas.skipPopover);

    const showPopover = () => {
        dispatch(updateCanvasSkipPopover(true))
    };
    const hidePopover = () => {
        dispatch(updateCanvasSkipPopover(false))
    };

    const onOtherAreaClick = (ev: MouseEvent) => {
        ev.stopPropagation();
        hidePopover();
    }

    useEffect(()=> {
        if (visible) {
            document.getElementById('root').addEventListener('click', onOtherAreaClick);
        }
        return () => {
            document.getElementById('root').removeEventListener('click', onOtherAreaClick);
        }
    }, [visible])

    return (
        <>
            <button type="button"
                    onClick={visible?hidePopover:showPopover}
                    className="btn btn-coco-outline-1 waves-effect waves-light wd-50p skip"
                    id={"skipButton"}
            >
                {GetMessageIntl(intl, 'ui.U00104')}
            </button>
            <SkipPopover target={"skipButton"} toggle={hidePopover} isOpen={visible}/>
        </>
        )
}
