// @ts-nocheck
import React, {useEffect, useRef} from "react";
import {useDispatch, useSelector} from "react-redux";
import SimpleBar from "simplebar-react";
import {changeFrame, getModule} from "../../../../../store/label/action";
import {DatasetDetailsService} from "../../../../../apis/api/DatasetDetailsService";
import {getDataList, setDataList} from "../../../../../store/dataset/details/action";

export function DataList(): JSX.Element {
    const dispatch = useDispatch();
    const {
        Label:{
            Cmmn: {
                frames: {
                    frame: {datasetId, fileSeq, moduleId}
                }
            }
        },
        Details: {
            workflow: { id: wrkflowId},
            dataList
        }
    } = useSelector(state => state);

    useEffect(()=>{
        if (wrkflowId !== null) {
            dispatch(getDataList())
        }
    }, [wrkflowId])

    const cardListRef = useRef();
    const selectedCard = useRef();

    useEffect(() => {
        if (!cardListRef.current || !selectedCard.current) return;
        const scrollbarEl = cardListRef.current?.getScrollElement();
        const selectedCardEl = selectedCard.current;

        // viewport 안에 없으면 scroll해준다.
        if (!(scrollbarEl.scrollTop < selectedCardEl.offsetTop
            && selectedCardEl.offsetTop + selectedCardEl.clientHeight < scrollbarEl.scrollTop + scrollbarEl.clientHeight)) {
            cardListRef.current?.getScrollElement().scrollTo(window.scrollX, selectedCard.current?.offsetTop - 48 * 3); // the height of dataCard is 48;
        }
    })

    return (
        <div className={"data-list"}>
            <SimpleBar ref={cardListRef} style={{ maxHeight: "calc(100vh - 229px)" }} autoHide={false} className="simplebar-track-coco">
            {
                !!dataList && dataList.map((data, index) => {
                    const selected = data.datasetId === datasetId && data.fileSeq === fileSeq && data.labelModuleId === moduleId
                    return(<div ref={selected?selectedCard: null} key={`data-card-${index}`} ><DataCard data={data} selected={selected} /></div>)
                })
            }
            </SimpleBar>
        </div>
    )
}

function DataCard(props): JSX.Element {
    const dispatch = useDispatch();
    const {data, selected} = props;

    const onClick = () =>{
        if (selected) return;
        const module = {
            moduleId: data.labelModuleId,
            name: data.labelModuleNm,
            type: "01"
        };
        const frame = {
            datasetId: data.datasetId,
            fileSeq: data.fileSeq,
            moduleId: data.labelModuleId
        }
        dispatch(changeFrame(module, frame))
    };

    return (
        <div className={"data-card"+(selected ? ' selected' : ' cursor-pointer')} onClick={onClick}>
            {data.fileNm}
        </div>
    )
}
