import React, { memo } from 'react';
import {Handle, useReactFlow, useStoreApi, Position, useStore} from 'reactflow';
import {Progress} from "reactstrap";
import {useDispatch} from "react-redux";

export default function workflowNode({ id, data, isConnectable }) {
    const dispatch = useDispatch();
    const targetHandleStyle = {visibility: 'hidden', zIndex:1};
    const sourceHandleStyle = { visibility: 'hidden', zIndex:1};
    return (
        <div>
            <div className="d-flex align-items-center mb-12">
                <div className="flex-shrink-0 avatar-42">
                    <div className="avatar-title fs-20 rounded-circle" style={{backgroundColor:'#FFF2D0', color:'#FFC130'}}>
                        <i className="ri-organization-chart lh-19"></i>
                    </div>
                </div>
                <div className="flex-grow-1 text-overflow ml-13">
                    <h6 className="text-coco-gray-200 tx-12 lh-16 mb-2">START</h6>
                    <span className="text-coco-gray-700 tx-16 lh-20 fw-semibold">{data.moduleNm}</span>
                </div>
            </div>
            <div className="bg-coco-gray-50 pd-10 node_info flex-shrink-0 mb-12">
                <div className="d-flex align-items-center">
                    <div style={{width:"123px"}} className="ht-18">
                        <label>등록</label>
                        2023.01.01
                    </div>
                    <div className="pd-l-10 ht-18" style={{borderLeft: "1px solid var(--coco-gray-100)"}}>
                        <label>순서</label>
                        1
                    </div>
                </div>
                <div className="ht-18 mt-6">
                    <label>파일 개수</label>
                    234,567,234
                </div>
                <div className="ht-18 mt-6">
                    <label>파일 크기</label>
                    273.93MB
                </div>
            </div>
            <div className="d-flex align-item image-info mb-12">
                <div className="default-data d-flex align-items-center justify-content-center">
                    img
                </div>
                <div className="default-data d-flex align-items-center justify-content-center ml-2">
                    img
                </div>
                <div className="default-data d-flex align-items-center justify-content-center ml-2">
                    img
                </div>
            </div>
            <div>
                <Progress value={20} className="progress-coco-primary-400 bg-gradient ht-12">
                    20%
                </Progress>
            </div>
            <Handle
                className="sourceHandle"
                /*style={sourceHandleStyle}*/
                position={Position.Right}
                type="source"
                isConnectable={isConnectable}
            />
            <Handle
                className="targetHandle"
                style={targetHandleStyle}
                position={Position.Bottom}
                type="target"
                isConnectable={isConnectable}
            />
        </div>
    );
}