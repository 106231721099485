import React, { memo } from 'react';
import {Handle, useReactFlow, useStoreApi, Position, useStore, NodeToolbar} from 'reactflow';
import {useDispatch} from "react-redux";
import {useIntl} from "react-intl";
import {GetMessageIntl} from "../../../../../util/message";


function ManagerReviewNode({ id, data, isConnectable }) {
    const intl = useIntl();

    const total = data? data.total  : 0;
    const per04 = data ? (data.col04 / total * 100).toFixed(1) : 0.0;
    const managerTotal = data? (data.col04 + data.col02):0 ;

    const targetHandleStyle = {visibility: 'hidden', zIndex:1};
    const sourceHandleStyle = { visibility: 'hidden', zIndex:1};
    return (
        <div>
            <div className="d-flex align-items-center mb-12">
                <div className="flex-shrink-0 avatar-42">
                    <div className="avatar-title fs-20 rounded-circle" style={{backgroundColor:'#DFF3EF', color:'#0BAD8F'}}>
                        <i className="ri-user-settings-line lh-19"></i>
                    </div>
                </div>
                <div className="flex-grow-1 text-overflow ml-13">
                    <span className="text-coco-gray-700 tx-16 lh-20 mb-2 fw-semibold">{GetMessageIntl(intl,"workflow.managerReview")}</span>
                    <h6 className="text-coco-gray-200 tx-12 lh-16 mb-0">{GetMessageIntl(intl,"common.totalUnit")} {managerTotal.toLocaleString()}</h6>
                </div>
            </div>
            <div className="bg-coco-gray-50 ht-34 d-flex align-items-center justify-content-between pd-y-8 pd-x-10 tx-14 lh-18 rounded mb-12">
                <div className={"fw-500"}>
                    {GetMessageIntl(intl,"workflow.requiredReview")}
                </div>
                <div className={"fw-semibold"}>
                    {data?data.col04.toLocaleString():0}<span className={"text-coco-danger-400"}> ({per04}%)</span>
                </div>
            </div>
           {/* <div className="p-0 node_info flex-shrink-0 mb-12">
                <div className="bg-coco-gray-50 ht-34 d-flex align-items-center justify-content-between pd-y-8 pd-x-10 tx-14 lh-18 rounded">
                    <div className={"fw-500"}>
                        {GetMessageIntl(intl,"workflow.requiredReview")}
                    </div>
                    <div className={"fw-semibold"}>
                        3,000,000<span className={"text-coco-danger-400"}> (0.8%)</span>
                    </div>
                </div>
            </div>*/}
            <Handle
                className="targetHandle"
                /*onConnect={(params) => console.log('handle onConnect', params)}*/
                style={targetHandleStyle}
                position={Position.Top}
                id={'top'}
                type="target"
                isConnectable={isConnectable}
            />
            <Handle
                className="targetHandle"
                /*onConnect={(params) => console.log('handle onConnect', params)}*/
                style={targetHandleStyle}
                position={Position.Left}
                id={'left'}
                type="target"
                isConnectable={isConnectable}
            />
            <Handle
                className="targetHandle"
                /*onConnect={(params) => console.log('handle onConnect', params)}*/
                style={targetHandleStyle}
                position={Position.Bottom}
                id={'bottom'}
                type="target"
                isConnectable={isConnectable}
            />
            <Handle
                className="targetHandle"
                /*onConnect={(params) => console.log('handle onConnect', params)}*/
                style={targetHandleStyle}
                position={Position.Right}
                id={'right'}
                type="target"
                isConnectable={isConnectable}
            />
        </div>
    );
}

export default memo(ManagerReviewNode);