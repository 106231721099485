import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import { GetMessageIntl} from "../../util/message";
import {useIntl} from "react-intl";

//import images

const ProfileDropdown = () => {
    const intl = useIntl();
    const [userName, setUserName] = useState("");
    const [firstName, setFirstName] = useState("");
    const [userRoleNm, setUserRoleNm] = useState("");
    const [userId, setUserId] = useState("");
    const [userColor, setUserColor] = useState("");
    const [userFontColor, setUserFontColor] = useState("");
    useEffect(() => {
        if (sessionStorage.getItem("coconutUserInfo")) {
            const obj = JSON.parse(sessionStorage.getItem("coconutUserInfo"));
            if(obj.userNm){
                setUserName(obj.userNm || obj.userRole);
                setFirstName(obj.userNm.substring(0,1));
                setUserId(obj.userId || obj.userRole);
                setUserColor(obj.colorCode);
                setUserFontColor(obj.fontCode);

                setUserRoleNm(GetMessageIntl(intl,"login.userRole"+obj.userRole))
            }
        }
    }, [userName, userId]);


    //Dropdown Toggle
    const [isProfileDropdown, setIsProfileDropdown] = useState(false);
    const toggleProfileDropdown = () => {
        setIsProfileDropdown(!isProfileDropdown);
    };
    return (
        <React.Fragment>
            <Dropdown isOpen={isProfileDropdown} toggle={toggleProfileDropdown} className="ms-sm-3 header-item">
                <DropdownToggle tag="button" type="button" className="btn">
                    <span className="d-flex align-items-center">
                        <div className="avatar-xs me-2">
                            <div className="avatar-title rounded-circle tx-14 login-user-profile" style={{color: userFontColor, backgroundColor:userColor}}>
                                {firstName}
                            </div>
                        </div>
                        <span className="text-start ms-xl-2">
                            <span className="d-none d-xl-inline-block ms-1 fw-medium user-name-text">{userName}</span>
                            <span className="d-none d-xl-block ms-1 fs-12 text-muted user-name-sub-text">{userRoleNm}</span>
                        </span>
                    </span>
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-end" style={{zIndex:9999}}>
                    <h6 className="dropdown-header">{userId}</h6>
                    <div className="dropdown-divider"></div>
                    <DropdownItem href="/logout"><i
                        className="mdi mdi-account-circle text-muted fs-16 align-middle me-1"></i> <span
                            className="align-middle" data-key="t-logout">Logout</span></DropdownItem>
                </DropdownMenu>
            </Dropdown>
        </React.Fragment>
    );
};

export default ProfileDropdown;