import {callApi} from "../utils/AxiosUtil";

export const DatasetDetailsService = {
	// 워크플로우에 대한 데이터 목록
	getDataList: (param) => callApi('/service/dataset/details/getDataList', param),
	// 파일 정보
	getFileInfo: (param) => callApi('/service/dataset/details/getFileInfo', param),
	// 최신 검수 사유 정보
	getLastRejectRsn: (param) => callApi('/service/dataset/details/lastRejectRsn', param),
	// 스킵 사유 정보
	getSkipRsn: (param) => callApi('/service/dataset/details/skipRsn', param),
	// 복구
	recovery: (param) => callApi('/service/dataset/details/recovery', param),
	// 작업불가
	checkUnable: (param) => callApi('/service/dataset/details/checkUnable', param),
	// 이력 조회
	getHistory: (param) => callApi('/service/dataset/details/history', param),
	// 워크플로우에 소속된 모듈 조회
	getModules: (param) => callApi('/service/dataset/details/module', param),
	// 워크플로우에 소속된 데이터셋 조회
	getDataset: (param) => callApi('/service/dataset/details/dataset', param),
	// 워크플로우에 참여한 작업자 조회
	getWorker: (param) => callApi('/service/dataset/details/worker', param),

}
