import ReviewInstance, {ReviewInstanceData} from "./review-instance";
import ReviewClasses, {ReviewClassesData} from "./review-classes";
import ObjectState from "./object-state";
import {Label} from "./labels";
import {REVIEW_TASK_STTUS_CD} from "../../const";

interface RawReviewData {
    instance: ReviewInstanceData[],
    classes: ReviewClassesData[]
}

interface InitialData {
    states: ObjectState[];
    labels: Label[];
}

export class Review {
    private instance: Record<number, ReviewInstance> = {};
    private classes: Record<number, ReviewClasses> = {};
    private reset: boolean;

    constructor(initialData: InitialData = null, reset: boolean = false) {
        this.reset = reset;
        if (initialData) {
            const {states, labels} = initialData;
            this.createInstanceDataToMap(states);
            this.createClassesDataToMap(labels);
        }
    }

    public checkCompletion(){    // 하나라도 error이면 error(true)!!
        let instanceNotEditedCnt = 0;
        let classesNotEditedCnt = 0;
        let completedError = false;
        Object.keys(this.instance).map( key => {
            const clientID = parseInt(key)
            const instance = this.instance[clientID];
            if (instance.getCompletedError()) {
                completedError = true;
                instanceNotEditedCnt++;
            }
        });

        Object.keys(this.classes).map( key => {
            const classID = parseInt(key)
            const classes = this.classes[classID];
            if (classes.getCompletedError()) {
                completedError = true;
                classesNotEditedCnt++;
            }
        });

        return {completedError, instanceNotEditedCnt, classesNotEditedCnt};
    }

    public getTotalReject() {   // 하나라도 reject이면 reject(true)!!
        let reject = false;
        Object.keys(this.instance).map( key => {
            const clientID = parseInt(key)
            const instance = this.instance[clientID];
            if (instance.getReject()) {
                return reject = true;
            }
        });

        Object.keys(this.classes).map( key => {
            const classID = parseInt(key)
            const classes = this.classes[classID];
            if (classes.getReject()) {
                return reject = true;
            }
        });

        return reject;
    }

    public createClassesDataToMap(labels: Label[]) {
        this.classes = {};
        labels.map(label => {
            const { id: classId } = label;
            this.classes[classId] = new ReviewClasses({classId: classId, reject: null}, this.reset);
        })
    }
    public createInstanceDataToMap(states: ObjectState[]) {
        states.map(state => {
            this.createInstanceData(state);
        })
    }

    private createInstanceData(state:ObjectState) {
        const {
            clientID,
            label: {
                id: classId
            },
        } = state;
        if (!this.instance[clientID]) {
            this.instance[clientID] = new ReviewInstance({
                objectId: clientID,
                classId,
                reject: null, rsnCd: null
            }, this.reset);
        }
    }

    /**
     *
     * @param obj 이전 검수 결과 (수정중에 삭제된 인스턴스는 삭제하고, 추가된 인스턴스는 추가해줘야함)
     * @param states 현재 인스턴스 리스트
     * @param previous 현재 검수 결과인지, 검수 이력 데이터인지 구분
     */
    public createSavedData(obj, states: ObjectState[] = [], previous: boolean = false){
        // instance
        this.instance = {};
        // 이전 결과를 업데이트하면서, 현재 인스턴스 리스트에 없는 인스턴스이면 삭제
        obj.instance.map(inst => {
            if (previous || states.find(s => s.clientID === inst.objectId)) {   // 이전 도형의 검수 내역이 있으면
                this.instance[inst.objectId] = new ReviewInstance(inst, this.reset);    // 새로운 검수 데이터를 만든다.
            }
        })
        states.map(inst => {
            if (!!! this.instance[inst.clientID]) { // 검수를 받지 않았던 인스턴스라면
                this.createInstanceData(inst);  // 새로운 검수 데이터를 만든다.
            }
        })

        // classes
        const savedClassID = obj.classes.reduce((accumulator, cls) => {
            const classID = cls.classId;
            accumulator.push(classID);
            return accumulator;
        }, []);

        if (Object.keys(this.classes).length == 0) {
            obj.classes.map(item => {
                this.classes[item.classId] = new ReviewClasses(item, false);
            })
        }

        Object.keys(this.classes).map( key => {
            const classID = parseInt(key)
            const classes = this.classes[classID];
            if (savedClassID.includes(classID)) {   // 이전 저장한 적이 있으면
                const objClass = obj.classes.filter(cls => cls.classId === classID)[0];
                this.classes[classID] = new ReviewClasses(objClass, this.reset);
            } else {
                this.classes[classID] = new ReviewClasses({classId: classID, reject: null}, this.reset)
            }
        });
        // obj.classes.map(cls => {
        //     this.classes[cls.classId] = new ReviewClasses(cls, this.reset);
        // })

        return this;
    }

    public removeInstance(clientID: number){
        this.instance[clientID]
        delete this.instance[clientID]
    }

    public getHash(){
        const instance = Object.keys(this.instance).reduce((accumulator, key) => {
            const clientID = parseInt(key)
            const instance = this.instance[clientID];
            accumulator.push(instance.getHash());
            return accumulator;
        }, []);

        const classes = Object.keys(this.classes).reduce((accumulator, key) => {
            const classID = parseInt(key)
            const classes = this.classes[classID];
            accumulator.push(classes.getHash());
            return accumulator;
        }, []);

        const hash: RawReviewData = {
            instance,
            classes
        }
        return hash;
    }

    public getInstTotalData(): string[]{
        let result = Object.keys(this.instance).reduce( (result, key) => {
            const clientID = parseInt(key);
            const instance = this.instance[clientID];
            const rsnList = instance.getRsnCd();
            return [...result, ...rsnList]
        }, [])

        result = result.filter((item, index) => item !== '01' && result.indexOf(item) === index )

        return result;
    }

    public getClassTotalData(labels): {reject: boolean, className: string[]}{
        let reject = false;
        const className = Object.keys(this.classes).reduce((acc, key) => {
            const classId = parseInt(key);
            const classes = this.classes[classId];
            const label = labels.filter(label => label.id === classId)[0]
            if (classes.getReject() && label) {
                reject = true;
                 acc.push(label.name)
            }
            return acc;
        }, [])
        return {reject, className}
    }
}
