//@ts-nocheck
import React, {useEffect, useRef, useState} from 'react';
import ReactPlayer from "react-player";
import {MathUtils} from "three";
import {useDispatch, useSelector} from "react-redux";
import styled from "styled-components";
import {updateDuration, updateInternalPlayer} from "../../../../../../store/label/action";
import clamp = MathUtils.clamp;

const VideoWrapper = styled.div`
  position: relative;
  overflow: hidden;
  background-color: black;
  display: flex;
  align-items: center;
  justify-content: center;

  ${props => props.isHorizon ? `
    width: 100%;
    padding-top: 56.25%; // 16:9 비율
  ` : `
    height: 86vh;
    width: calc(86vh * 9 / 16); // 9:16 비율 계산
    max-width: 100%;
  `}

  > .react-player {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    height: 100%;
  }
`;

export const VideoCanvas = ({className}) => {
    const dispatch = useDispatch();
    const playerRef = useRef();
    const [play, setPlay] = useState(false);
    const [currentTime, setCurrentTime] = useState(0);
    const [duration, setDuration] = useState(0);
    const [volume, setVolume] = useState(0.5);
    const [speed, setSpeed] = useState(1.0);

    const { videoUrl } = useSelector((state) => ({
        videoUrl : state.Label.Cmmn.frames.frame.data
    }));

    useEffect(() => {
        window.addEventListener('keydown', handleKeyDown);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        }
    }, []);

    const handleKeyDown = (e) => {
        // e.preventDefault();
        // switch (e.keyCode) {
        //     case 32:
        //         handlePlay();
        //         break;
        //     case 37:
        //         playBack();
        //         break;
        //     case 39:
        //         playForward();
        //         break;
        // }
    }

    const handlePlay = () => {
        setPlay(prevPlay => {
            return !prevPlay;
        });
    }

    const handleCurrentTime = (e) => {
        setCurrentTime(e.playedSeconds);
    }

    const handleDuration = (duration) => {
        setDuration(duration);
        dispatch(updateDuration(duration));

        if (playerRef.current) {
            const player = playerRef.current.getInternalPlayer();
            if (player && player.videoWidth && player.videoHeight) {
                dispatch(updateInternalPlayer(player.videoWidth, player.videoHeight))
            }
        }
    }

    const handleVolume = (_volume) => {
        _volume = clamp(_volume, 0, 1);
        setVolume(_volume);
    }

    const handleSpeed = (speed) => {
        setSpeed(speed);
    }

    const playBack = () => {
        let newTime = playerRef.current?.getCurrentTime() - 5;
        if (newTime < 0) newTime = 0;
        if(playerRef){
            playerRef.current?.seekTo(newTime);
            setCurrentTime(newTime);
        }
    }

    const playForward = () => {
        let newTime = playerRef.current?.getCurrentTime() + 5;
        let totalTime = playerRef.current.getDuration();
        if (newTime > totalTime) newTime = totalTime;
        if(playerRef){
            playerRef.current?.seekTo(newTime);
            setCurrentTime(newTime);
        }
    }

    // React-Player Values
    const values = {
        play, currentTime, duration, volume, speed
    }

    // React-Player Values Handle
    const handleValues = {
        handlePlay, handleCurrentTime, handleDuration, handleVolume, handleSpeed, playBack, playForward
    }

    const isHorizon = () => {
        if (playerRef.current) {
            const player = playerRef.current.getInternalPlayer();
            if (player && player.videoWidth && player.videoHeight) {
                console.log(player.videoWidth, player.videoHeight)
                return player.videoWidth > player.videoHeight;
            }
        }
        return true; // 기본적으로 가로 모드로 설정
    };


    return (
        <VideoWrapper isHorizon={isHorizon()}>
            {
                videoUrl &&
                <>
                    <ReactPlayer
                        className="react-player"
                        ref={playerRef}
                        url={videoUrl}
                        playing={play}
                        volume={volume}
                        playbackRate={speed}
                        onDuration={handleDuration}
                        onProgress={handleCurrentTime}
                        width={"100%"}
                        height={"100%"}
                        controls={true}
                        pip={false}
                        onKeyDown={handleKeyDown}
                        config={{ file: {
                                attributes: {
                                    controlsList: 'nodownload'
                                }
                            }}}
                    />
                    {/*<VideoControlBar playerRef={playerRef} values={values} handleValues={handleValues}/>*/}
                </>
            }

        </VideoWrapper>

    );
}