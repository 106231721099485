// @ts-nocheck
import {defaultSelectStyle, multiSingleSelectStyle} from "../../../../../../../../components/constants/select";
import Select from "react-select";
import React, {useEffect, useState} from "react";
import {Input, Label} from "reactstrap";
import {Controller, useForm} from "react-hook-form";
import {GetMessage} from "../../../../../../../../util/message";
import {AttributeType} from "../../../work/core/enums";
import {
    CANVAS_CONTEXT_MENU,
    CHIP_OPTIONS_CONTAINER,
    CHIP_OPTIONS_ICON,
    STATE_ITEM_LABEL_SELECTOR
} from "../../classNameConsts";
import ReactDOM from "react-dom";
import {useSelector} from "react-redux";

export interface Props {
    readonly: boolean;
    labelID: number;
    attributes: any[];
    values: Record<number, string>;
    changeAttribute(attrID: number, value: string): void;
}
export function ObjectPopoverAttributesWrapper(props: Props){
    const { attributes, labelID, readonly, values, changeAttribute } = props;
    const { control, register, trigger, formState: { errors }, handleSubmit, setValue } = useForm({mode: "onChange"});

    return (
        <>
            <form onSubmit={handleSubmit()}>
            {
                attributes.map( (attribute: any) : JSX.Element => {
                    return (
                        <ItemAttribute
                            key={attribute.id}
                            labelID={labelID}
                            readonly={readonly}
                            attrValue={values[attribute.id]}
                            attrInputType={attribute.inputType}
                            attrName={attribute.name}
                            attrID={attribute.id}
                            attrValues={attribute.values}
                            attrRequired={attribute.required}
                            attrRegexp={attribute.regexp}
                            attrRegexpSample={attribute.regexpSample}
                            changeAttribute={changeAttribute}
                            trigger={trigger}
                            control={control}
                            setValue={setValue}
                            register={register}
                            errors={errors}
                        />
                    )
                    }
                )
            }
            </form>
        </>
    )
}
interface attrProps{
    readonly: boolean;
    labelID: number;
    attrInputType: string;
    attrValues: string[];
    attrValue: string;
    attrName: string;
    attrID: number;
    attrRequired: boolean;
    attrRegexp: string;
    attrRegexpSample: string;
    changeAttribute(attrID: number, value: string): void;
    control: object;
    register(name: string, RegisterOptions?): ({ onChange, onBlur, name, ref });
    trigger(name?: string | string[]): Promise<boolean>;
    setValue(name: string, value: unknown, config?: Object): void;
    errors: object;
}
const canvasMultiSingleSelectStyle = {
    ...multiSingleSelectStyle,
    multiValue: (styles, state) => {
        return {
            ...styles,
            backgroundColor: state.isDisabled? '#b0bec5':'#0bad8f',
            padding:'0px 6px 0px 0px',
            borderRadius:'4px',
        };
    },
    multiValueLabel: (styles, state) => ({
        ...styles,
        color: 'white',
        borderRight:state.isDisabled? '1px solid white': '1px solid #7ed0bd',
        borderRadius: '0px',
        paddingRight:'6px',
        fontSize: '12px'
    })
}
interface TitleProps{
    readonly: boolean;
    attrTitleClassName: stirng;
    attrValues: string[];
    attrValue: string;
    attrID: number;
    attrName: string;
    attrRequired: boolean;
    attrInputType: AttributeType;

}
interface ChipOptionsProps{
    attrValues: string[];
    attrValue: string;
    attrID: number;

}
export function ItemAttributeTitle(props: TitleProps){
    const {readonly, attrTitleClassName, attrValues, attrValue, attrID, attrName, attrRequired, attrInputType} = props;
    return (
        <div className={"d-flex justify-content-between"}>
            <div className={attrTitleClassName}>
                {attrName}
                {!!attrRequired && <span className={"text-danger"}>*</span>}
                {
                    attrInputType === 'checkbox'?
                        <span className={"px-1 text-coco-gray-400"}>({GetMessage('ui.U00047')})</span>
                        : attrInputType === 'select'?
                            <span className={"px-1 text-coco-gray-400"}>({GetMessage('ui.U00046')})</span>
                        :<></>
                }
            </div>
            {
                (readonly && ["select", "checkbox"].includes(attrInputType))&&
                <ChipOptionsTooltip
                    attrValues={attrValues}
                    attrValue={attrValue}
					attrID={attrID}
                />
            }
        </div>
    )
}
function ChipOptionsTooltip(props: ChipOptionsProps) {
    const { attrValues, attrValue, attrID } = props;
    const IconID = CHIP_OPTIONS_ICON+attrID;
    const [visible, setVisible] = useState(false)
    const OptionsTooltip = ({ visible }) => {
        if (!visible) return null;
        const icon = document.getElementById(IconID);
        const {top, left, height, width} = icon.getBoundingClientRect();
        return (
            visible ?
            ReactDOM.createPortal(
                <div className={[CANVAS_CONTEXT_MENU, CHIP_OPTIONS_CONTAINER].join(' ')} style={{top: top + height, left: left, backgroundColor: "white"}}>
                    <div className={'title'}>{GetMessage('label.allOptions')}</div>
                    <div className={'content'}>
                        {attrValues.map((element, index) => {
                            return(
                            <div className="form-check form-check-btn form-radio-coco" key={index}>
                                <Input type="checkbox" className="form-check-input btn-check"
                                       name="options-tooltip" required
                                       value={element}
                                       checked={attrValue?.includes(element)}
                                       disabled={true}
                                />
                                <Label className="form-check-label btn btn-coco-outline-1"
                                >
                                    {element}
                                </Label>
                            </div>
                        )})
                        }
                    </div>
                </div>
                , window.document.body
            ) : null
        )
    }

    return (
        <>
            <span
                id={IconID} className={CHIP_OPTIONS_ICON}
                // onClick={()=>{
                //     setVisible(!visible)
                // }}
                onMouseEnter={() => setVisible(true)}
                onMouseLeave={() => setVisible(false)}
            >
                <i className={"ri-information-line"} />
            </span>
            <OptionsTooltip visible={visible}/>
        </>
    )
}

export function ItemAttribute(props: attrProps){
    const {readonly, labelID, attrInputType, attrValues, attrValue, attrName, attrID,
        attrRequired, attrRegexp, attrRegexpSample, changeAttribute,
        control, errors, trigger, setValue
    } = props;
    const errorVisible = useSelector(state => state.Label.Cmmn.canvas.badges.visible);
    const attrClassName = "coco-object-popover-attribute";
    const attrTitleClassName = "coco-object-popover-attribute-title";
    const attrInputPrefix = "coco-object-popover-attribute-input-"
    const attrInputName = attrInputPrefix+attrID
    const [prevLabelID, setPrevLabelID] = useState(labelID);

    // select, checkbox를 위한
    let selectOption = [];
    if (attrValues) {
        attrValues.map(attr => {
            selectOption.push({label: attr, value: attr})
        });
    }
    // console.log('[ItemAttribute] select selectOption', selectOption, attrValue, attrInputType
    //     , attrInputType === 'checkbox' && attrValue ? selectOption.filter(option => attrValue.includes(option.value)) : selectOption.find(option => option.value === attrValue));
    const [singleAttr, setSingleAttr] = useState(attrValue ? selectOption.find(option => option.value === attrValue): []);
    const [multiAttr, setMultiAttr] = useState(attrValue ? selectOption.filter(option => attrValue.includes(option.value)) : []);

    useEffect(() => {
        if (errorVisible) {
            setValue(attrInputName, attrValue);
            trigger(attrInputName);
        }
    }, [errorVisible])

    useEffect(() => {
        // 다른 클래스를 선택했을 시, 속성을 리셋해준다.
        if (!attrValue) {
            setSingleAttr(null);
            setMultiAttr(null);
        }
    }, [labelID])

    // text를 위한
    let rules = {
        required: {
            value: attrRequired,
            message: GetMessage("validation.required")
        }
    };

    if (attrRegexp) {
        rules.pattern = {
            value: new RegExp(attrRegexp),
            message: GetMessage("validation.contentNotVaild")
        }
    }

    if (attrInputType === "text") {
        return (
            <div className={attrClassName}>
                <ItemAttributeTitle
                    readonly={readonly}
                    attrInputType={attrInputType}
                    attrTitleClassName={attrTitleClassName}
                    attrValues={attrValues}
                    attrValue={attrValue}
                    attrID={attrID}
                    attrName={attrName}
                    attrRequired={attrRequired}
                />
                <div className={Object.keys(errors).includes(attrInputName)?"validation-error":""}>
                    <Controller
                        control={control}
                        name={attrInputName}
                        render={({ field }) => {
                            const { onChange } = field;
                            return (
                                <Input {...field}
                                       defaultValue={attrValue??''}
                                       onChange={(event) => {
                                           const newValue = event.target.value;
                                           onChange(newValue);
                                           changeAttribute(attrID, newValue);
                                       }}
                                       autoComplete={"off"}
                                       placeholder={attrRegexpSample}
                                       disabled={readonly}
                                />
                            )
                        }}
                        rules={rules}
                    />
                </div>
                {Object.keys(errors).includes(attrInputName) && <p className="validation-error-message">{errors[attrInputName].message}</p>}
            </div>
        )
    }

    if (attrInputType === "select") {
        return (
            <div className={attrClassName}>
                <ItemAttributeTitle
                    readonly={readonly}
                    attrInputType={attrInputType}
                    attrTitleClassName={attrTitleClassName}
                    attrValues={attrValues}
                    attrValue={attrValue}
                    attrID={attrID}
                    attrName={attrName}
                    attrRequired={attrRequired}
                />
                <div className={Object.keys(errors).includes(attrInputName)?"validation-select-error":""}>
                    <Controller
                        control={control}
                        name={attrInputName}
                        render={({field}) => {
                            const {onChange} = field;
                            return (
                                <Select
                                    {...field}
                                    options={selectOption}
                                    value={singleAttr}
                                    styles={canvasMultiSingleSelectStyle}
                                    className="mb-0"
                                    onChange={(newValue) => {
                                        const newAttr = newValue[newValue.length - 1];
                                        onChange(newAttr)
                                        setSingleAttr(newAttr);
                                        if (newAttr) {
                                            changeAttribute(attrID, newAttr.value);
                                        } else {
                                            changeAttribute(attrID, '');
                                        }
                                    }}
                                    isMulti
                                    isDisabled={readonly}
                                    menuPortalTarget={document.body}
                                    menuPosition={'fixed'}
                                    menuPlacement={'auto'}
                                />
                            )
                        }
                        }
                        rules={rules}
                    />
                </div>
                {Object.keys(errors).includes(attrInputName) && <p className="validation-error-message">{errors[attrInputName].message}</p>}
            </div>
        )
    }

    if (attrInputType === "checkbox") {
        return (
            <div className={attrClassName}>
                <ItemAttributeTitle
                    readonly={readonly}
                    attrInputType={attrInputType}
                    attrTitleClassName={attrTitleClassName}
                    attrValues={attrValues}
                    attrValue={attrValue}
                    attrID={attrID}
                    attrName={attrName}
                    attrRequired={attrRequired}
                />
                <div className={Object.keys(errors).includes(attrInputName) ? "validation-select-error" : ""}>
                    <Controller
                        control={control}
                        name={attrInputName}
                        render={({field}) => {
                            const {onChange} = field;
                            return (
                                <Select
                                    {...field}
                                    options={selectOption}
                                    // defaultValue={multiAttr}
                                    value={multiAttr}
                                    styles={canvasMultiSingleSelectStyle}
                                    className={"mb-0"}
                                    onChange={(values) => {
                                        onChange(values);
                                        let newValues = [];
                                        for (const label of values) {
                                            newValues.push(label.value)
                                        }
                                        setMultiAttr(values)
                                        changeAttribute(attrID, newValues);
                                    }}
                                    isMulti
                                    isDisabled={readonly}
                                    menuPortalTarget={document.body}
                                    menuPosition={'fixed'}
                                    menuPlacement={'auto'}
                                />
                            )
                        }
                        }
                        rules={rules}
                    />
                </div>
                {Object.keys(errors).includes(attrInputName) &&
					<p className="validation-error-message">{errors[attrInputName].message}</p>}
            </div>
        );
    }
    return null;
}
