import {Redirect} from "react-router-dom";
import {defaultAxios} from '../apis/utils/AxiosUtil';
import {LoginInfoModel} from "../components/model/LoginInfoModel";
import {GetMessage} from "../util/message";
import {alertError} from "../util/AlertUtil";
import {IntlShape} from "react-intl/src/types";
import axios from "axios";


function LoginProcess(id: string, pw: string, remember: any, intl: IntlShape) {
    let body = {
        "asdfvbnm": id,
        "zxcvvbnm": pw
    }

    axios.post(process.env.REACT_APP_API_HOST + "/service/authenticate", body,{
        withCredentials: true
    })
        .then(
            (res) => {
                if (res.status === 200) {
                    let data = res.data;

                    const userinfo: LoginInfoModel = {
                        // accessToken: data.accessToken,
                        // refreshToken: data.refreshToken,
                        exp: data.exp,
                        orgnztId: data.orgnztId,
                        userId: data.userId,
                        userNm: data.userNm,
                        userRole: data.userRole,
                        userSeq: data.userSeq,
                        fontCode: data.fontCode,
                        colorCode: data.colorCode,
                    }

                    if (remember) {
                        setCookie("authInputCoco", id, 7); // 7일 동안 쿠키 보관
                    } else {
                        deleteCookie("authInputCoco");
                    }

                    sessionStorage.setItem('coconutUserInfo', JSON.stringify(userinfo));

                    if(userinfo.userRole.startsWith('00')){
                        window.location.href = "/admin/log";
                    }else if(userinfo.userRole.startsWith('01')){
                        // 플랫폼 매니저용 페이지 개발 필요
                        window.location.href = "/platform/user";
                    }else if(userinfo.userRole.startsWith('1')){
                        window.location.href = "/manager/project";
                    }else if(userinfo.userRole.startsWith('2')){
                        window.location.href = "/user/project/notice";
                    }
                } else {
                    console.log(GetMessage("login.infomationError"))
                }
            }
        )
        .catch(error => {
            if (error.response) {
                if (error.response.status === 460) {
                    // 계정이 맞지 않을때
                    alertError(
                        ""
                        , ""
                        , null
                        , null
                        , error.response.data.errorMessage
                        , intl
                    );
                } else if (error.response.status === 500) {
                    alert(GetMessage("login.status500"))
                } else {
                    alert(GetMessage("error.E00003"))
                }
            } else {
                //응답 값이 없을떄
                alertError(
                    ""
                    , "login.noresponse"
                    , null
                    , null
                    , null
                    , intl
                );
            }
        });
}

function LogoutProcess() {
    let body = {

    }
    axios.post(process.env.REACT_APP_API_HOST + "/service/logoutProcess", body,{
        withCredentials: true
    })
    .then((res)=>{
            if (res.status === 200) {
                let data = res.data;
                sessionStorage.removeItem("coconutUserInfo");
                window.location.href = "/login";
            } else {
                console.log("logout error")
            }
        }
    ).catch(error => {

    });

}

async function LoginCheck() {
    let body = {

    }
    let result:boolean = false;
    await axios.post(process.env.REACT_APP_API_HOST + "/service/loginCheck", body,{
        withCredentials: true
    })
    .then((res)=>{
            if (res.status === 200) {
                let data = res.data;

                const userinfo: LoginInfoModel = {
                    // accessToken: data.accessToken,
                    // refreshToken: data.refreshToken,
                    exp: data.exp,
                    orgnztId: data.orgnztId,
                    userId: data.userId,
                    userNm: data.userNm,
                    userRole: data.userRole,
                    userSeq: data.userSeq,
                    fontCode: data.fontCode,
                    colorCode: data.colorCode,
                }
                sessionStorage.setItem('coconutUserInfo', JSON.stringify(userinfo));

                if(userinfo.userRole.startsWith('00')){
                    window.location.href = "/admin/log";
                }else if(userinfo.userRole.startsWith('01')){
                    // 플랫폼 매니저용 페이지 개발 필요
                    window.location.href = "/platform/user";
                }else if(userinfo.userRole.startsWith('1')){
                    window.location.href = "/manager/project";
                }else if(userinfo.userRole.startsWith('2')){
                    window.location.href = "/user/project/notice";
                }
            } else {
                console.log(GetMessage("login.infomationError"), res.status)
            }
            result = true;
        }
    ).catch(error => {
        if (error.response) {
            if (error.response.status === 461) {
                //console.log('오류', error.response.status);
            } else if (error.response.status === 470) {
                //console.log(error.response.status)
            } else {
                //console.log(error.response.status)
            }
        }
            result = false;
    })
    return result;
}

function getLoginUserInfo() {
    // 세션에 저장된 로그인 정보
    const user = sessionStorage.getItem("coconutUserInfo");
    if (!user) {
        return null;
    } else {
        return JSON.parse(user);
    }
}

function setCookie(cookieName, value, exdays) {
    let exdate = new Date();
    exdate.setDate(exdate.getDate() + exdays);
    let cookieValue = escape(value) + ((exdays == null) ? "" : "; expires=" + exdate.toUTCString());
    document.cookie = cookieName + "=" + cookieValue;
}

function deleteCookie(cookiName) {
    var expireDate = new Date();
    expireDate.setDate(expireDate.getDate() - 1);
    document.cookie = cookiName + "= " + "; expires=" + expireDate.toUTCString();
}

export default {LoginProcess, LogoutProcess, LoginCheck, getLoginUserInfo}
