// import { takeEvery, call, put, all } from 'redux-saga/effects';
// import {CHANGE_CURRENT_MODULE} from "./actionType";
//
import {ImageReviewService} from "../../../apis/api/ImageReviewService";
import {alertError, confirm} from "../../../util/AlertUtil";
import {MD_TY_CD} from "../../../pages/user/label/annotation/image/const";
import {LabelCmmnService} from "../../../apis/api/LabelCmmnService";
import {ReviewCmmnService} from "../../../apis/api/ReviewCmmnService";

function* changeCurrentModuel(action) {

}

export async function assignCheck(module, hooks, doAfter) {
    const {moduleId, name: moduleNm, type: moduleTy, canvasDataTy} = module;
    const {intl, history, dispatch} = hooks;
    const server = moduleTy === MD_TY_CD.LABEL ? LabelCmmnService : ReviewCmmnService;
    const params = {moduleId};
    try {
        const result = await server.checkBefore(params);
        const {success, data, errorMessage} = result.data;

        if (!success) {
            alertError(
                ""
                , ""
                , null
                , null
                , errorMessage
                , intl
            );
            return;
        }

        // 데이터 할당
        if (['11', '12'].includes(data.resultCode)) {
            let target = {};
            if (moduleTy === MD_TY_CD.LABEL) {
                target = {
                    datasetId: data.datasetId,
                    fileSeq: data.fileSeq,
                    moduleId: data.moduleId
                };
            } else {
                target = {
                    datasetId: data.datasetId,
                    fileSeq: data.fileSeq,
                    moduleId: data.labelModuleId
                };
            }

            if (doAfter.prePage.startsWith('/user/')) {
                doAfter.do["11"](module, target);
            } else if (doAfter.prePage.startsWith('/label/')) {
                doAfter.do["11"](module, target, dispatch);
            }
        } else if (['13'].includes(data.resultCode)) {
            const commnDoAfter = async () => {
                const targetData = await assignNewData(moduleId, server);
                if (!targetData.success) {
                    alertError(
                        ""
                        , ""
                        , null
                        , null
                        , targetData.errorMessage
                        , intl
                    );
                    return;
                }

                if (targetData.data.resultCode === "04") {
                    doAfter.do["04"]();
                    return null;
                }
                let target = {};
                if (moduleTy === MD_TY_CD.LABEL) {
                    target = {
                        datasetId: targetData.data.datasetId,
                        fileSeq: targetData.data.fileSeq,
                        moduleId: targetData.data.moduleId
                    };
                } else {
                    target = {
                        datasetId: targetData.data.datasetId,
                        fileSeq: targetData.data.fileSeq,
                        moduleId: targetData.data.labelModuleId
                    };
                }
                return target;
            }

            doAfter.do["13"](commnDoAfter);
        }

        // 캔버스 진입 불가
        if (data.resultCode === '04') {
            // 할당 데이터 없음
            console.log('04')
            doAfter.do["04"]();
        } else if (data.resultCode === '05') {
            // 진행중인 프로젝트가 아님
            console.log('05')
            doAfter.do["05"]();
        }
    } catch (e) {
        console.error(e);
        const errorMessage = e.response.data.errorMessage;
        alertError(
            ""
            , ""
            , null
            , null
            , errorMessage
            , intl
        );
    }
}

async function assignNewData(moduleId, server) {
    const params = {
        moduleId
    };
    const result = await server.jobTargetBind(params);
    return result.data
}

/**
 * Watcher
 * @returns {Generator<*, void, *>}
 */
function* watchChangeCurrentModule() {
    yield takeEvery(CHANGE_CURRENT_MODULE, changeCurrentModule);
}
