// label Type
export const SET_IS_MODULE_OFFCANVAS = "manage/project/workflow/module/SET_IS_MODULE_OFFCANVAS";
export const SET_IS_DATASET_OFFCANVAS = "manage/project/workflow/module/SET_IS_DATASET_OFFCANVAS";
export const SET_IS_LABEL_OFFCANVAS = "manage/project/workflow/module/SET_IS_LABEL_OFFCANVAS";
export const SET_IS_REVIEW_OFFCANVAS = "manage/project/workflow/module/SET_IS_REVIEW_OFFCANVAS";
export const SET_SELECTION_NODE_ID = "manage/project/workflow/module/SET_SELECTION_NODE_ID";
export const SET_SELECTION_NODE_NM = "manage/project/workflow/module/SET_SELECTION_NODE_NM";
export const SET_SELECTION_NODE = "manage/project/workflow/module/SET_SELECTION_NODE";
export const RESET_SELECTION_NODE = "manage/project/workflow/module/RESET_SELECTION_NODE";
export const DELETE_NODE_ID = "manage/project/workflow/module/DELETE_NODE_ID";
export const UPDATE_NODE_ID = "manage/project/workflow/module/UPDATE_NODE_ID";

export const SET_DATASET_DATA = "manage/project/workflow/module/SET_DATASET_DATA";
export const SET_LABEL_DATA = "manage/project/workflow/module/SET_LABEL_DATA";
export const SET_REVIEW_DATA = "manage/project/workflow/module/SET_REVIEW_DATA";