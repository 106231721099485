//@ts-nocheck
import React, {useEffect} from "react";
import {
    RightGroup
} from "../../../annotation/image/containers/standard-workspace/header/RightGroupContainer";
import {useIntl} from "react-intl";
import {GetMessageIntl} from "../../../../../../util/message";
import logo from "assets/images/laflow_logo_02.png";
import {Workspace} from "../../../../../../store/label";

interface Props {
    type: Workspace;
    forceExit: boolean;
    title: string | null;
    subTitle: string | null;
    unblock: any | null;
    rightGroup: JSX.Element;
}
const CanvasHeader = (props: Props) => {
    const {type, forceExit, title, subTitle, unblock, rightGroup} = props;

    useEffect(() => {
        return () => {
            // window.removeEventListener('beforeunload', this.beforeUnloadCallback);
            if (unblock && typeof unblock === 'function') {
                unblock();
            }
        }
    });

    return (
        <header className="canvas-header">
            <div className="layout-width mw-100">
                <div className="navbar-header bg-coco-primary-400">
                    <div className="d-flex">
                        {/*{!--LOGO--}*/}
                        <div className="navbar-brand-box">
                            <img src={logo} style={{height: "22px"}}/>
                        </div>
                    </div>

                    <div className="align-items-center vstack align-self-center">
                        <div className="text-white ps-2 subtitle prjctNm">{title}</div>
                        <div className="text-white ps-2 fileNm">{subTitle}</div>
                    </div>
                    <div className="d-flex align-items-center">
                        {rightGroup}
                    </div>
                </div>
            </div>
        </header>
    )
}
export default React.memo(CanvasHeader);
