import {GetMessage} from "util/message";
import React from "react";

type ClassesTitleProps = {
    endTitle: string;
}

const ClassesHeader: React.FC<ClassesTitleProps> = ({endTitle}) => {

    return (
        <React.Fragment>
            <div className="d-flex align-items-center text-coco-gray-700 tab-header">
                <div className="wd-16p regionType text-center">
                    {GetMessage("ui.U00115")}
                </div>
                <div className="wd-54p text-left ps-2">
                    {GetMessage("ui.U00114")}
                </div>
                <div className="wd-12p text-center">
                    {GetMessage("ui.U00116")}
                </div>
                <div className="wd-18p end text-center">
                    {endTitle}
                </div>
            </div>
        </React.Fragment>
    )
}
export default ClassesHeader;
