import React from 'react';
import {Input, ListGroupItem} from "reactstrap";
import {useDispatch, useSelector} from "react-redux";
import {
    setManageWorkflowLabelActiveData,
    setManageWorkflowLabelInactiveData,
    setManageWorkflowLabelSelectedRowId,
    setManageWorkflowLabelViewType
} from "../../../../../../../../../store/manage/project/workflow/label/action";
import {confirmWarning} from "../../../../../../../../../util/AlertUtil";
import {GetMessageIntl} from "../../../../../../../../../util/message";
import {useIntl} from "react-intl";

function LabelRegistDataRow(props) {
    const dispatch = useDispatch();
    const intl = useIntl();
    const {activeData, inactiveData, selectedId, errorData} = useSelector((state) => ({
        activeData: state.ManageWorkflowLabel.activeData,
        inactiveData: state.ManageWorkflowLabel.inactiveData,
        selectedId: state.ManageWorkflowLabel.selectedId,
        errorData: state.ManageWorkflowLabel.errorData
    }));

    function switchClicked(e, rowData) {
        e.stopPropagation();
        let updatedRowData = { ...rowData, useYn: rowData.useYn === "Y" ? "N" : "Y" };
        const updatedActiveData = activeData.filter(item => item.id !== rowData.id);
        const updatedInactiveData = inactiveData.filter(item => item.id !== rowData.id);

        if (updatedRowData.useYn === "Y") {
            // 비활성 -> 활성 자료로 변경
            let newOrder = 1;
            if(activeData.length > 0){
                newOrder = Math.max(...activeData.map(item => item.order)) + 1;
            }
            if(updatedRowData.type=='editor' && (updatedRowData.body.useTarget =='01' || updatedRowData.body.useTarget =='02')){
                let dupUseTarget = activeData.filter(item => item.type=='editor' && item.body.useTarget == updatedRowData.body.useTarget);
                if(dupUseTarget.length>0){
                    confirmWarning("workflow.dataConfig.dupActiveUseTargetTitle","workflow.dataConfig.dupActiveUseTargetMsg"
                        , () => {
                            updatedRowData = {...updatedRowData, order: newOrder, body:{...updatedRowData.body, useTarget:'03'}}
                            updatedActiveData.push(updatedRowData);
                            dispatch(setManageWorkflowLabelActiveData(updatedActiveData));
                            dispatch(setManageWorkflowLabelInactiveData(updatedInactiveData));
                        }
                        ,"", {'useTarget': updatedRowData.body.useTarget == '01'? GetMessageIntl(intl, "workflow.dataConfig.guide"): GetMessageIntl(intl, "workflow.dataConfig.faq")}  ,intl)
                    return;
                }
            }
            updatedRowData = {...updatedRowData, order: newOrder}
            updatedActiveData.push(updatedRowData);
        } else {
            // 활성 -> 비활성 자료로 변경
            updatedInactiveData.push(updatedRowData);
        }

        dispatch(setManageWorkflowLabelActiveData(updatedActiveData));
        dispatch(setManageWorkflowLabelInactiveData(updatedInactiveData));
    }
    const onRowClicked = (rowData) => {
        dispatch(setManageWorkflowLabelSelectedRowId(rowData.id));
        switch(rowData.type){
            case "editor" :
                dispatch(setManageWorkflowLabelViewType("E"))
                break;
            case "checkbox" :
                dispatch(setManageWorkflowLabelViewType("C"))
                break;
            case "form":
                dispatch(setManageWorkflowLabelViewType("F"))
                break;
        }
    }

    return (
        <>
            {
                props.data && props.data.map((form, index) => {
                    const { id, title } = form;

                    const isSelected = selectedId === id;
                    let hasError = errorData.dataItems?.filter((item) => item.id === id).length > 0 ;

                    const selectedClass = isSelected ? ' selected' : '';
                    const errorClass = hasError ? ' validation-error-sub' : '';
                    return(
                        <ListGroupItem key={index} onClick={()=>{onRowClicked(form)}} className={"d-flex align-items-center justify-content-between border-0 pd-y-6 ".concat(selectedClass).concat(errorClass)}>
                            <div className="d-flex gap-3 text-overflow" style={{padding: "5px 5px"}}>
                                <div className={'handle text-pointer'}>
                                    <i className="bx bx-grid-vertical" style={{fontSize : "25px"}}></i>
                                </div>
                                <div className={"fs-16 text-coco-gray-700 text-overflow"}>
                                    {form.title}
                                </div>
                            </div>
                            <div className="form-check form-switch-coco form-switch form-switch-success">
                                <Input className="form-check-input" type="checkbox" role="switch" id={`switch_${form.id}`}
                                       checked={form.useYn === "Y"}
                                       onChange={(e) => {switchClicked(e, form)}}/>
                            </div>
                        </ListGroupItem>
                    )
                })
            }
        </>
    );
}

export default LabelRegistDataRow;