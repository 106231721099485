import {
    SET_INIT_SEARCH_PARAM,
    SET_SEARCH_PARAM
} from "./actionType"

export const user_initSearchParam = searchObj => ({
    type : SET_INIT_SEARCH_PARAM,
    payload :  searchObj
});

export const user_setSearchParam = searchObj => ({
    type : SET_SEARCH_PARAM,
    payload : searchObj
});

