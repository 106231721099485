// @ts-nocheck
import React from 'react';
import {Button, NavItem} from 'reactstrap';
import Radio, { RadioChangeEvent } from 'antd/lib/radio';
import { RectDrawingMethod, CuboidDrawingMethod } from 'pages/user/label/annotation/image/cvat-canvas-wrapper';

import { ShapeType } from 'store/label';
import { Label } from 'pages/user/label/annotation/image/cvat-core-wrapper';
import {ShapeIcon} from "../../../const";
import {useIntl} from "react-intl";
import {IntlShape} from "react-intl/lib";

interface Props {
    className: string[];
    onClick(): void;
    shapeType: ShapeType;
    labels: any[];
    minimumPoints: number;
    rectDrawingMethod?: RectDrawingMethod;
    cuboidDrawingMethod?: CuboidDrawingMethod;
    numberOfPoints?: number;
    selectedLabelID: number | null;
    repeatShapeShortcut: string;
    onChangeLabel(value: Label | null): void;
    onChangePoints(value: number | undefined): void;
    onChangeRectDrawingMethod(event: RadioChangeEvent): void;
    onChangeCuboidDrawingMethod(event: RadioChangeEvent): void;
    onDrawTrack(): void;
    onDrawShape(intl: IntlShape): void;
    moduleInstance: any;
}

function DrawShapeButtonComponent(props: Props): JSX.Element {
    const {
        className,
        onClick,
        labels,
        shapeType,
        minimumPoints,
        selectedLabelID,
        numberOfPoints,
        rectDrawingMethod,
        cuboidDrawingMethod,
        repeatShapeShortcut,
        onDrawTrack,
        onDrawShape,
        onChangeLabel,
        onChangePoints,
        onChangeRectDrawingMethod,
        onChangeCuboidDrawingMethod,
        moduleInstance,
    } = props;
    const intl = useIntl();
    const classNameList = ['btn-icon', 'rounded-pill', 'labelShape', 'coco-draw-shape-button'].concat(className);
    // const is2D = moduleInstance.dimension === DimensionType.DIM_2D;
    return (
        <Button color={"coco-ghost"}
                className={classNameList.join(' ')}
                onClick={() => onDrawShape(intl)}
                // onClick={shapeType === ShapeType.MASK ? onDrawTrack : () => onDrawShape(intl)}
        >
            <i className={ShapeIcon[shapeType]} />
        </Button>
    )
    // return (
    //     <div className='cvat-draw-shape-popover-content'>
    //         <Row justify='start'>
    //             <Col>
    //                 <Text className='cvat-text-color' strong>{`Draw new ${shapeType}`}</Text>
    //             </Col>
    //         </Row>
    //         <Row justify='start'>
    //             <Col>
    //                 <Text className='cvat-text-color'>Label</Text>
    //             </Col>
    //         </Row>
    //         <Row justify='center'>
    //             <Col span={24}>
    //                 <LabelSelector
    //                     style={{ width: '100%' }}
    //                     labels={labels}
    //                     value={selectedLabelID}
    //                     onChange={onChangeLabel}
    //                 />
    //             </Col>
    //         </Row>
    //         {is2D && shapeType === ShapeType.RECTANGLE && (
    //             <>
    //                 <Row>
    //                     <Col>
    //                         <Text className='cvat-text-color'> Drawing method </Text>
    //                     </Col>
    //                 </Row>
    //                 <Row justify='space-around'>
    //                     <Col>
    //                         <Radio.Group
    //                             style={{ display: 'flex' }}
    //                             value={rectDrawingMethod}
    //                             onChange={onChangeRectDrawingMethod}
    //                         >
    //                             <Radio value={RectDrawingMethod.CLASSIC} style={{ width: 'auto' }}>
    //                                 By 2 Points
    //                             </Radio>
    //                             <Radio value={RectDrawingMethod.EXTREME_POINTS} style={{ width: 'auto' }}>
    //                                 By 4 Points
    //                             </Radio>
    //                         </Radio.Group>
    //                     </Col>
    //                 </Row>
    //             </>
    //         )}
    //         {is2D && shapeType === ShapeType.CUBOID && (
    //             <>
    //                 <Row>
    //                     <Col>
    //                         <Text className='cvat-text-color'> Drawing method </Text>
    //                     </Col>
    //                 </Row>
    //                 <Row justify='space-around'>
    //                     <Col>
    //                         <Radio.Group
    //                             style={{ display: 'flex' }}
    //                             value={cuboidDrawingMethod}
    //                             onChange={onChangeCuboidDrawingMethod}
    //                         >
    //                             <Radio value={CuboidDrawingMethod.CLASSIC} style={{ width: 'auto' }}>
    //                                 From rectangle
    //                             </Radio>
    //                             <Radio value={CuboidDrawingMethod.CORNER_POINTS} style={{ width: 'auto' }}>
    //                                 By 4 Points
    //                             </Radio>
    //                         </Radio.Group>
    //                     </Col>
    //                 </Row>
    //             </>
    //         )}
    //         {is2D && [ShapeType.POLYGON, ShapeType.POLYLINE, ShapeType.POINTS].includes(shapeType) ? (
    //             <Row justify='space-around' align='middle'>
    //                 <Col span={14}>
    //                     <Text className='cvat-text-color'> Number of points: </Text>
    //                 </Col>
    //                 <Col span={10}>
    //                     <InputNumber
    //                         onChange={(value: number | undefined | string | null) => {
    //                             if (typeof value === 'undefined' || value === null) {
    //                                 onChangePoints(undefined);
    //                             } else {
    //                                 onChangePoints(Math.floor(clamp(+value, minimumPoints, Number.MAX_SAFE_INTEGER)));
    //                             }
    //                         }}
    //                         className='cvat-draw-shape-popover-points-selector'
    //                         min={minimumPoints}
    //                         value={numberOfPoints}
    //                         step={1}
    //                     />
    //                 </Col>
    //             </Row>
    //         ) : null}
    //         <Row justify='space-around'>
    //             <Col span={12}>
    //                 <CVATTooltip title={`Press ${repeatShapeShortcut} to draw again`}>
    //                     <Button onClick={onDrawShape}>Shape</Button>
    //                 </CVATTooltip>
    //             </Col>
    //             {shapeType !== ShapeType.MASK && (
    //                 <Col span={12}>
    //                     <CVATTooltip title={`Press ${repeatShapeShortcut} to draw again`}>
    //                         <Button onClick={onDrawTrack}>Track</Button>
    //                     </CVATTooltip>
    //                 </Col>
    //             )}
    //         </Row>
    //     </div>
    // );
}

export default React.memo(DrawShapeButtonComponent);
