import {callApi} from '../../apis/utils/AxiosUtil';

export const BellArarmService = {

    /**
     *
     * @param param
     * @returns {Promise<axios.AxiosResponse<any>>}
     */
    getMessageCount: () => callApi("/service/bell/getMessageCount"),
    /**
     *
     * @param param
     * @returns {Promise<axios.AxiosResponse<any>>}
     */
    getMessageList: () => callApi("/service/bell/getMessageList"),
    /**
     *
     * @param param
     * @returns {Promise<axios.AxiosResponse<any>>}
     */
    setMessageStatus: (param) => callApi("/service/bell/setMessageStatus", param),

    /**
     * 전체 읽음 처리(삭제)
     * @param param
     * @returns {Promise<axios.AxiosResponse<any>>}
     */
    setMessageStatusAll: () => callApi("/service/bell/setMessageReadAll")
}
