import React, { memo } from 'react';
import {Handle, useReactFlow, useStoreApi, Position, useStore} from 'reactflow';
import {GetMessage} from "../../../../../../util/message";
import {useDispatch, useSelector} from "react-redux";

export default function AcceptNode({ id, data, isConnectable }) {
    const sourceHandleStyle = {visibility: 'hidden', zIndex:1};
    const targetHandleStyle = {visibility: 'hidden', zIndex:1};
    return (
        <>
            <div className="d-flex align-items-center justify-content-between mb-12 tx-16 fw-500">
                <div>
                    {GetMessage("workflow.accept")}
                </div>
                <div>
                    70,000,000<span className={"text-coco-primary-400"}> (53.8%)</span>
                </div>
            </div>
            <Handle
                type="source"
                position={Position.Right}
                id="a"
                style={{ top: 10, background: '#555' }}
                isConnectable={isConnectable}
            />
            <Handle
                type="source"
                position={Position.Right}
                id="b"
                style={{ bottom: 10, top: 'auto', background: '#555' }}
                isConnectable={isConnectable}
            />

            <Handle
                className="targetHandle"
                style={targetHandleStyle}
                position={Position.Left}
                type="target"
                isConnectable={isConnectable}
            />
        </>
    );
}