import React, { useEffect } from 'react';
import { Input } from 'reactstrap';
import {Controller} from "react-hook-form";
import {Cache} from "three";
import files = Cache.files;
import {Annotator} from "../../../../../../../components/common/text-annotator/components/Annotator";
import {Annotations, Relations} from "../../../../../../../components/common/text-annotator/types";

interface Props {
    text: string;
    annotations: Annotations;
    relations: Relations;
    onChangeAnnotations: any;
    uiOptions: any;
    readOnly?: boolean
    updateLabel?: any;
    showLegend?: boolean;
    afterAction?:any;
    deleteLabel?:any;
    containerId?:string;
}



const AnnotatorView  = ({text, annotations, relations, onChangeAnnotations, uiOptions, readOnly, updateLabel, showLegend=false, afterAction, deleteLabel, containerId}: Props) => {

    return (
        <Annotator
            text={text.trim()}
            annotations={annotations}
            relations={relations}
            onChangeAnnotations={onChangeAnnotations}
            uiOptions={uiOptions}
            readOnly={readOnly}
            updateLabel={updateLabel}
            showLegend={showLegend}
            afterAction={afterAction}
            deleteLabel={deleteLabel}
            containerId={containerId}
        />
    );
}


export default React.memo(AnnotatorView);