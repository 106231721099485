import {setForceExitAnnotationFlag} from "../../../../../../store/label/action";
import {connect} from "react-redux";
import CanvasHeader from "../../../cmmn/components/header/CanvasHeader";
import {useHistory} from "react-router";
import {GetMessageIntl} from "../../../../../../util/message";
import {useIntl} from "react-intl";
import {Workspace} from "../../../../../../store/label";

interface OwnProps {
    rightGroupContainer: JSX.Element;
}

interface StateToProps {
    type: Workspace;
    forceExit: boolean;
    moduleInstance: any;
    frame: any;
}

interface DispatchToProps {
    onSetForceExitAnnotationFlag(forceExit: boolean): void;
}
function mapStateToProps(state): StateToProps {
    const {
        Label: {
            Cmmn: {
                annotations: {saving: {forceExit}},
                module: {instance: moduleInstance},
                player: {frame},
                workspace: type
            }
        }
    } = state;

    return {
        type,
        forceExit,
        moduleInstance,
        frame
    }
}

function mapDispatchToProps(dispatch): DispatchToProps {
    return {
        onSetForceExitAnnotationFlag(forceExit: boolean): void {
            dispatch(setForceExitAnnotationFlag(forceExit));
        }
    }
}

function CanvasHeaderContainer(props: OwnProps & StateToProps & DispatchToProps): JSX.Element{
    const {type, forceExit, moduleInstance, frame, rightGroupContainer, onSetForceExitAnnotationFlag} = props;
    const moduleName = moduleInstance?.name;
    const frameName = frame?.filename;

    const intl = useIntl();
    const history = useHistory();
    const unblock = history.block((location: any) => {
        if (
            moduleInstance.annotations.hasUnsavedChanges() &&    // 변경 사항이 없을 때 경우
            !location.pathname.startsWith(`/labels/`) &&
            !forceExit
        ) {
            return GetMessageIntl(intl,"label.exitConfirm");
        }

        if (forceExit) {
            onSetForceExitAnnotationFlag(false);
        }

        return undefined;
    });

    return (
        <CanvasHeader
            type={type}
            forceExit={forceExit}
            title={moduleName}
            subTitle={frameName}
            unblock={unblock}
            rightGroup={rightGroupContainer}
        />
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(CanvasHeaderContainer);
