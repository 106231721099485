// @ts-nocheck
import '../styles.scss';
import React from 'react';

import ControlsSideBarContainer from 'pages/user/label/annotation/image/containers/standard-workspace/controls-side-bar/controls-side-bar';
import CanvasContextMenuContainer from 'pages/user/label/annotation/image/containers/canvas/canvas-context-menu';
import ObjectsListContainer from 'pages/user/label/annotation/image/containers/standard-workspace/objects-side-bar/objects-list';
import ObjectSideBarComponent from 'pages/user/label/annotation/image/components/standard-workspace/objects-side-bar/objects-side-bar';
import {CanvasLayout} from "../../../../cmmn/components/CanvasLayout";
// import CanvasPointContextMenuComponent from 'pages/user/label/components/canvas/views/canvas2d/canvas-point-context-menu';
// import IssueAggregatorComponent from 'pages/user/label/components/review/issues-aggregator';
// import RemoveConfirmComponent from 'pages/user/label/components/standard-workspace/remove-confirm';
// import PropagateConfirmComponent from 'pages/user/label/components/standard-workspace/propagate-confirm';

export function StandardWorkspaceComponent(): JSX.Element {
    return (
        <div className='coco-standard-workspace'>
            <ControlsSideBarContainer />
            <CanvasLayout className={"content-container"}/>
            <ObjectSideBarComponent objectsList={<ObjectsListContainer />} />
            {/*<PropagateConfirmComponent />*/}
            <CanvasContextMenuContainer />
            {/*<CanvasPointContextMenuComponent />*/}
            {/*<IssueAggregatorComponent />*/}
            {/*<RemoveConfirmComponent />*/}
        </div>
    );
}
