import React, {useEffect, useState} from "react";
import {
    Button,
    Col,
    NavItem,
    Nav,
    Offcanvas,
    OffcanvasBody,
    OffcanvasHeader,
    Row,
    NavLink,
    TabContent,
    TabPane,
    Label, Input
} from "reactstrap";
import {GetMessage, GetMessageIntl} from "../../../../../../util/message";
import classnames from "classnames";
import taskReviewImg from "../../../../../../assets/images/task/task_review_54.png";
import {useDispatch, useSelector} from "react-redux";
import {
    manage_project_workflow_module_deleteNodeId,
    manage_project_workflow_module_setIsReviewOffcanvas,
    manage_project_workflow_module_setSelectionNode,
    manage_project_workflow_module_setSelectionNodeNm,
    manage_project_workflow_module_updateModuleId
} from "../../../../../../store/manage/project/workflow/module/action";
import {useIntl} from "react-intl";
import {useStoreApi} from "reactflow";
import {Controller, FormProvider, useForm} from "react-hook-form";
import SimpleBar from "simplebar-react";
import {ModuleService} from "../../../../../../apis/api/ModuleService";

import BaseConfigDiv from "./tab/review/BaseConfigDiv";
import ReviewConfigDiv from "./tab/review/ReviewConfigDiv";
import WorkerConfigDiv from "./tab/review/WorkerConfigDiv";
import PointClsfcSmryConfigDiv from "./tab/review/PointClsfcSmryConfigDiv";
import PointConfigDiv from "./tab/review/PointConfigDiv";
import DataConfigDiv from "./tab/review/DataConfigDiv";
import TestConfigDiv from "./tab/review/TestConfigDiv";
import ShapeConfigDiv from "./tab/label/ShapeConfigDiv";
import {alertError, alertSuccess, alertWarning, confirm} from "../../../../../../util/AlertUtil";
import {
    initManageWorkflowReviewModuleConfig,
    resetManageWorkflowReviewModuleConfig,
    setManageWorkflowReviewActiveData,
    setManageWorkflowReviewBaseConfig,
    setManageWorkflowReviewDataConfig,
    setManageWorkflowReviewDataError,
    setManageWorkflowReviewFilesReset,
    setManageWorkflowReviewInactiveData,
    setManageWorkflowReviewPointConfig,
    setManageWorkflowReviewTargetLabelBindConfig,
    workflow_review_testConfig_setShapeConfig,
    setManageWorkflowReviewTestConfig,
    setManageWorkflowReviewWorkerConfig,
    switchManageWorkflowReviewRegistDataRefreshFlag
} from "../../../../../../store/manage/project/workflow/review/action";
import {
    workflow_review_testConfig_clearHasErrorFiles,
    workflow_review_testConfig_setHasErrorFiles
} from "../../../../../../store/manage/project/workflow/review/TestConfig/action";
import {isClsfcSmryType} from "../../../../../user/label/clsfcSmry/util/clsfcSmryUtil";

export default (props) => {
    const {canvasDataTy} = props;
    const intl = useIntl();
    const store = useStoreApi();
    const dispatch = useDispatch();
    const prjctId = sessionStorage.getItem("prjctId");
    const [customActiveTab, setcustomActiveTab] = useState("1");
    const [disabledOpenTask, setDisabledOpenTask] = useState(true);
    const {isReviewOffCanvas, selectionNode, baseConfig, workerConfig, pointConfig, dataConfig, testConfig, tabActive } = useSelector((state) => ({
        isReviewOffCanvas: state.ManageWorkflowModule.isReviewOffCanvas,
        selectionNode: state.ManageWorkflowModule.selectionNode,
        baseConfig: state.ManageWorkflowReview.baseConfig,
        workerConfig: state.ManageWorkflowReview.workerConfig,
        pointConfig: state.ManageWorkflowReview.pointConfig,
        dataConfig: state.ManageWorkflowReview.dataConfig,
        testConfig: state.ManageWorkflowReview.testConfig,
        tabActive: state.ManageWorkflowReview.tabActive
    }));

    const [nodeData, setNodeData] = useState(null);
    // 기본 정보 설정
    const methodsBase = useForm({
        criteriaMode: "all",
        defaultValues: {}
    });
    const {
        control: controlBase, register: registerBase, setValue: setValueBase, getValues: getValuesBase, reset: resetBase, resetField: resetFieldBase
        , watch: watchBase, formState: {errors: errorsBase}, handleSubmit: handleSubmitBase
    } = methodsBase;


    // 검수 설정
    const methodsReview = useForm({
        criteriaMode: "all",
        defaultValues: {}
    });
    const {
        control: controlReview, register: registerReview, setValue: setValueReview, getValues: getValuesReview, reset: resetReview, resetField: resetFieldReview
        , watch: watchReview, formState: {errors: errorsReview}, handleSubmit: handleSubmitReview
    } = methodsReview;

    // 작업자 설정
    const methodsWorker = useForm({
        criteriaMode: "all",
        defaultValues: {}
    });
    const {
        control: controlWorker, register: registerWorker, setValue: setValueWorker, getValues: getValuesWorker, reset: resetWorker, resetField: resetFieldWorker
        , watch: watchWorker, formState: {errors: errorsWorker}, handleSubmit: handleSubmitWorker
    } = methodsWorker;


    // 포인트 설정
    const methodsPoint = useForm({
        criteriaMode: "all",
        defaultValues: {}
    });
    const {
        control: controlPoint, register: registerPoint, setValue: setValuePoint, getValues: getValuesPoint, reset: resetPoint, resetField: resetFieldPoint
        , watch: watchPoint, formState: {errors: errorsPoint}, handleSubmit: handleSubmitPoint
    } = methodsPoint;

    // 미니테스트 설정
    const methodsTest = useForm({
        criteriaMode: "all",
        defaultValues: {}
    });
    const {
        control: controlTest, register: registerTest, setValue: setValueTest, getValues: getValuesTest, reset: resetTest, resetField: resetFieldTest
        , watch: watchTest, trigger:triggerTest, formState: {errors: errorsTest}, handleSubmit: handleSubmitTest
    } = methodsTest;


    useEffect(() => {
        if (isReviewOffCanvas) {
            // 캔버스 열면 일단 리셋..
            // resetBase();
            // resetReview();
            // resetWorker();
            // resetPoint();
            // resetData();
            // resetTest();
            setcustomActiveTab("1");
        }
    }, [isReviewOffCanvas]);

    useEffect(() => {
        resetBase();
        resetReview();
        resetWorker();
        resetPoint();
        resetData();
        resetTest();
        dispatch(resetManageWorkflowReviewModuleConfig());
        if (selectionNode && selectionNode.type == 'review') {
            let initModuleConfig = {};
            ModuleService.getManageModuleItem({moduleId: selectionNode.data.moduleId}).then((moduleResult) => {
                if (moduleResult.data.data) {
                    let resultData = moduleResult.data.data;
                    if(resultData.moduleConfig){
                        initModuleConfig = JSON.parse(resultData.moduleConfig);
                        dispatch(initManageWorkflowReviewModuleConfig(initModuleConfig));
                        setNodeData(resultData);

                        if(initModuleConfig.dataConfig) {
                            let tmpDataConfig = initModuleConfig.dataConfig;
                            const aData = tmpDataConfig.filter((use) => use.useYn === 'Y');
                            const iData = tmpDataConfig.filter((use) => use.useYn === 'N');
                            dispatch(setManageWorkflowReviewActiveData(aData));
                            dispatch(setManageWorkflowReviewInactiveData(iData));
                        }else{
                            initModuleConfig.dataConfig = []
                        }

                        if(resultData.sttusCd == '02' || selectionNode.data.sttusCd == '02'){
                            setDisabledOpenTask(true);
                        }else{
                            setDisabledOpenTask(false);
                        }
                    }
                }
            })

            const getBindConfig = async () => {
                const result = await ModuleService.getReviewTargetLabelModuleItem({'moduleId': selectionNode.id, 'moduleConfig':'bindConfig'});
                dispatch(setManageWorkflowReviewTargetLabelBindConfig(JSON.parse(result.data.data)))
                return result
            }
            getBindConfig().then();

            // shapeConfig 가져오기
            const getShapeConfig = async () => {
                const result = await ModuleService.getReviewTargetLabelModuleItem({'moduleId': selectionNode.id, 'moduleConfig':'shapeConfig'});
                // setShapeConfig(JSON.parse(result.data.data));
                dispatch(workflow_review_testConfig_setShapeConfig(JSON.parse(result.data.data)))
                return result
            }
            getShapeConfig().then();
        } else {
            setNodeData(null);
        }
        return () => {
            dispatch(setManageWorkflowReviewActiveData([]))
            dispatch(setManageWorkflowReviewInactiveData([]))
        }
    }, [selectionNode]);

    const getManageModuleItem = async (param)=>{
        // await ModuleService.getManageModuleItem(param).then((result) => {
        //     let moduleItem = result.data.data;
        //     let changeNode = {...selectionNode, data: {...selectionNode.data, moduleConfig: moduleItem.moduleConfig}}
        //     dispatch(manage_project_workflow_module_setSelectionNode(changeNode));
        //     dispatch(manage_project_workflow_module_updateModuleId(param.moduleId));
        // });

        await ModuleService.getManageModuleItem(param).then((result) => {
            let moduleItem = result.data.data;
            let getModuleConfig = JSON.parse(moduleItem.moduleConfig);
            let changeNode = {...selectionNode, data: {...selectionNode.data, moduleConfig: moduleItem.moduleConfig}};

            if(param.moduleConfig){
                let paramModuleConfig = JSON.parse(param.moduleConfig);
                let changeModuleConfig = JSON.parse(selectionNode.data.moduleConfig);
                for(const [key, value] of Object.entries(paramModuleConfig)) {
                    const getModuleValue = getModuleConfig[key];
                    changeModuleConfig = {...changeModuleConfig, [key]:getModuleValue};
                    if(key == 'baseConfig'){
                        dispatch(setManageWorkflowReviewBaseConfig(getModuleValue))
                    }else if(key == 'workerConfig'){
                        dispatch(setManageWorkflowReviewWorkerConfig(getModuleValue))
                    }else if(key == 'pointConfig'){
                        dispatch(setManageWorkflowReviewPointConfig(getModuleValue))
                    }else if(key == 'dataConfig'){
                        dispatch(setManageWorkflowReviewDataConfig(getModuleValue))
                    }else if(key == 'testConfig'){
                        dispatch(setManageWorkflowReviewTestConfig(getModuleValue))
                    }
                }

                changeNode = {...selectionNode, data: {...selectionNode.data, moduleConfig: JSON.stringify(changeModuleConfig)}};
            }

            //dispatch(manage_project_workflow_module_setSelectionNode(changeNode));
            dispatch(manage_project_workflow_module_updateModuleId(param.moduleId));
        });
    }

    const updateModuleItem = async (param) => {
        const result = await ModuleService.updateModuleItem(param);
        return result;
    }

    const updateModuleItemWithFile = async (param) => {
        const result = await ModuleService.updateModuleItemWithFile(param, {contentType: 'multipart/form-data'});
        return result;
    }

    const updateOpenItem = async (param) => {
        const result = await ModuleService.updateOpenModuleItem(param);
        return result;
    }

    const updateReviewModuleItem = async (param) => {
        const result = await updateModuleItem(param);
        if (result.data.data) {
            alertSuccess('alert.saveSuccess', 'alert.saveSuccessMsg', null, null, intl);
            getManageModuleItem(param);
        }
    }

    const updateReviewModuleItemWithFile = async(param) =>{
        const result = await updateModuleItemWithFile(param);
        if(result.data.data){
            alertSuccess('alert.saveSuccess','alert.saveSuccessMsg',null, null, intl);
            let itemParam ={
                moduleId:param.moduleId,
                moduleConfig:param.moduleConfig
            };
            getManageModuleItem(itemParam);
        }
        return result;
    }

    const updateOpenModuleItem = async (param) =>{
        const result = await updateOpenItem(param);
        if (result.data.data) {
            alertSuccess('workflow.openSuccess', 'workflow.openSuccessMsg', null, null, intl);
            getManageModuleItem(param);
        }
    }

    const deleteModuleItemCheck = ()=>{
        if(selectionNode){
            const selectionNodeId = selectionNode.id;
            confirm("alert.deleteCheck"
                , "workflow.deleteModuleConfirm"
                ,()=>{
                    deleteModuleItem(selectionNodeId);
                }
                ,null
                , null
                , intl);
        }
    }

    const deleteModuleItem = async (moduleId) => {
        // TODO Authorization의 : accessToken을 세팅해야 한다.
        await ModuleService.deleteModuleItem({
            'moduleId': moduleId
        }).then((result)=>{
            if(result.data.success) {
                alertSuccess('alert.deleteSuccess', 'alert.deleteSuccessMsg', null, null, intl);
                dispatch(manage_project_workflow_module_deleteNodeId(moduleId));
            }else{
                alertError('error.E00002', '', null, null, result.data.errorMessage, intl);
            }
        });
    }

    const handleClickModuleNm = (event) => {
        setNodeData({...nodeData, 'editMode': true});
    }

    const handleUpdateModuleNm = (event) => {
        const changeModuleNm = event.target.value.trim();
        if (changeModuleNm.length == 0) {
            setNodeData({...nodeData, 'editMode': false});
            return;
        }
        isModuleNmDuplication(event, changeModuleNm);
    }

    const handleUpdateModuleNmKeyPress = (event) => {
        if (event.key === 'Enter') {
            const changeModuleNm = event.target.value.trim();
            if (changeModuleNm.length == 0) {
                setNodeData({...nodeData, 'editMode': false});
                return;
            }
            isModuleNmDuplication(event, changeModuleNm);
        } else {
            event.target.classList.remove('is-invalid');
            event.target.parentElement.children[1].classList.remove('invalid-tooltip');
            event.target.parentElement.children[1].innerText = '';
        }
    }

    let isModuleNmDuplication = (event, changeModuleNm) => {
        let dupResult = false;
        const state = store.getState();
        const nodes = Array.from(state.nodeInternals.values());
        const dupNode = nodes.find((c) => c.data && c.data.moduleNm === changeModuleNm && c.id != selectionNode.id);
        if (dupNode != undefined) {
            dupResult = true;
            event.target.classList.add('is-invalid');
            event.target.parentElement.children[1].classList.add('invalid-tooltip');
            event.target.parentElement.children[1].innerText = GetMessageIntl(intl, "validation.duplication");
        } else {
            setNodeData({...nodeData, moduleNm: changeModuleNm, 'editMode': false});
            //setValueWorker('moduleNm', changeModuleNm);
            const state = store.getState();
            const changeNodes = Array.from(state.nodeInternals.values());
            state.setNodes(
                changeNodes.map((node) => {
                    if (node.id === selectionNode.id) {
                        node.data.moduleNm = changeModuleNm;
                    }
                    return node;
                })
            );
            //dispatch(manage_project_workflow_module_setSelectionNode(changeNodes.find((c) => c.id === selectionNode.id)));
            dispatch(manage_project_workflow_module_setSelectionNodeNm(changeModuleNm));
            let param = {};
            param.moduleId = selectionNode.id;
            param.moduleNm = changeModuleNm;
            param.moduleTy = '00'; // 모듈명만 바꿀때에는 00
            updateModuleItem(param);
        }

        return dupResult;
    }

    const toggleTopCanvas = () => {
        dispatch(manage_project_workflow_module_setIsReviewOffcanvas(false));
    };

    const toggleCustom = (tab) => {
        if (customActiveTab !== tab) {
            setcustomActiveTab(tab);
        }
    };

    const onSubmitBase = async (data) => {
        console.log('검수 기본정보 등록~~', data);
        let submitData = {};
        submitData.moduleId = nodeData.moduleId;
        submitData.moduleNm = nodeData.moduleNm;
        submitData.moduleTy = '02'; // 모듈 유형 검수: 02

        submitData.baseConfig = {'taskNm': data.taskNm, 'taskDc': data.taskDc};
        submitData.moduleConfig = JSON.stringify({'baseConfig': submitData.baseConfig});

        await ModuleService.isModuleNmDuplication({
            'prjctId': prjctId,
            'moduleNm': submitData.moduleNm,
            'moduleTy': '02',
            'moduleId': submitData.moduleId
        }).then((result) => {
            if (result.data.data) {
                alertError("alert.dupModuleNm", '', null, null, null, intl);
            } else {
                confirm("alert.save"
                    , "workflow.saveMsg"
                    , () => {
                        updateReviewModuleItem(submitData);
                    }
                    , null
                    , null
                    , intl);
            }
        });

        // await ModuleService.isTaskNmDuplication({
        //     'prjctId': prjctId,
        //     'taskNm': data.taskNm,
        //     'moduleTy': '02',
        //     'moduleId': submitData.moduleId
        // }).then((result) => {
        //     if (result.data.data) {
        //         alertError("alert.dupModuleNm", '', null, null, null, intl);
        //     } else {
        //         confirm("alert.save"
        //             , "workflow.saveMsg"
        //             , () => {
        //                 updateReviewModuleItem(submitData);
        //             }
        //             , null
        //             , null
        //             , intl);
        //     }
        // });
    };

    const onSubmitReview = (data) => {
        console.log('검수 설정 등록~~', data);
    };

    const onSubmitWorker = async (data) => {
        console.log('검수 작업자 설정 등록~~', data);
        let submitData = {};
        submitData.moduleId = nodeData.moduleId;
        submitData.moduleNm = nodeData.moduleNm;
        submitData.moduleTy = '02'; // 모듈 유형 검수: 02

        submitData.workerConfig = {standardTy: data.standardTy};

        if (data.standardTy === '01') {
            submitData.workerConfig = {...submitData.workerConfig, limitTy: data.limitTy}
            if (data.limitTy.includes('00')) {
                // 직접 지정
                submitData.workerConfig = {...submitData.workerConfig, conditionEmails: data.conditionEmails}
            }

            if (data.limitTy.includes('01')) {
                // 조건 설정
                if (data.groupConditions.length > 0) {
                    // 비어져 있는 Array의 항목은 삭제
                    let groupConditions = data.groupConditions.map(groupCondition => {
                        return {...groupCondition, conditions: groupCondition.conditions.filter(condition => condition != undefined)};
                    });
                    submitData.workerConfig = {...submitData.workerConfig, groupConditions: groupConditions.filter(groupCondition => groupCondition != undefined)};
                }
            }
        }

        submitData.moduleConfig = JSON.stringify({'workerConfig': submitData.workerConfig});

        await ModuleService.isModuleNmDuplication({
            'prjctId': prjctId,
            'moduleNm': submitData.moduleNm,
            'moduleTy': '02',
            'moduleId': submitData.moduleId
        }).then((result) => {
            console.log(result.data);
            if (result.data.data) {
                alertError("alert.dupModuleNm", '', null, null, null, intl);
            } else {
                confirm("alert.save"
                    , "workflow.saveMsg"
                    , () => {
                        updateReviewModuleItem(submitData);
                    }
                    , null
                    , null
                    , intl);
            }
        });
    };

    const onSubmitPoint = async (data) => {
        console.log('검수 포인트 설정 등록~~', data);
        let submitData = {};
        submitData.moduleId = nodeData.moduleId;
        submitData.moduleNm = nodeData.moduleNm;
        submitData.moduleTy = '02'; // 모듈 유형 검수: 02

        submitData.pointConfig = {pointPayTy: data.pointPayTy};

        if(data.pointPayTy === '00'||data.pointPayTy === '01'){
            submitData.pointConfig = {...submitData.pointConfig, payPoint: parseInt(data.payPoint)}
        }else{
            if(data.limitPoint){
                submitData.pointConfig = {...submitData.pointConfig, limitPoint: parseInt(data.limitPoint)};
            }
            if(data.instance.length>0){
                data.instance = data.instance.map(item=> {return {id: item.id, type: item.type, value: parseInt(item.value)}});
                // 비어져 있는 Array의 항목은 삭제
                submitData.pointConfig = {...submitData.pointConfig, instance: data.instance.filter(item=> item!=undefined && item.id !=undefined)};
            }
        }

        submitData.moduleConfig = JSON.stringify({'pointConfig': submitData.pointConfig});

        await ModuleService.isModuleNmDuplication({
            'prjctId': prjctId,
            'moduleNm': submitData.moduleNm,
            'moduleTy': '02',
            'moduleId': submitData.moduleId
        }).then((result) => {
            console.log(result.data);
            if (result.data.data) {
                alertError("alert.dupModuleNm", '', null, null, null, intl);
            } else {
                confirm("alert.save"
                    , "workflow.saveMsg"
                    , () => {
                        updateReviewModuleItem(submitData);
                    }
                    , null
                    , null
                    , intl);
            }
        });

    };

    // 자료 등록
    const methodsData = useForm({
        criteriaMode: "all",
        defaultValues: {}
    });
    const {
        control: controlData, register: registerData, setValue: setValueData, getValues: getValuesData, reset: resetData, resetField: resetFieldData
        , watch: watchData, formState: {errors: errorsData}, handleSubmit: handleSubmitData
    } = methodsData;

    const { targetFiles, refreshFlag, selectedId, activeData, inactiveData } = useSelector((state) => ({
        targetFiles: state.ManageWorkflowReview.files,
        refreshFlag: state.ManageWorkflowReview.refreshFlag,
        selectedId: state.ManageWorkflowReview.selectedId,
        activeData: state.ManageWorkflowReview.activeData,
        inactiveData: state.ManageWorkflowReview.inactiveData,
    }))

    const onSubmitData = async (data) => {
        console.log('검수 자료 등록~~', data);
        dispatch(setManageWorkflowReviewDataError([]))
        let filteredByUseYn = dataConfig.filter((item) => item.useYn !== "N");
        const submitData = new FormData();
        if(targetFiles){
            console.log("targetFiles =>", targetFiles)
            Object.keys(targetFiles).forEach(key => {
                const items = targetFiles[key];
                for(let i = 0; i < items.length; i++){
                    submitData.append("files[" + key + "].dataAttachFile[]", items[i].file)
                }
                submitData.append("files["+key+"].id", key)
            });
        }

        submitData.moduleId = nodeData.moduleId;
        submitData.moduleNm = nodeData.moduleNm;

        let errorsData = {
            dataItems: []
        };
        errorsData.dataItems = [];

        // 활성 자료
        if(filteredByUseYn){
            filteredByUseYn.forEach((config, idx) => {
                const targetId = config.id;
                if(config.title === "" || null){
                    errorsData.dataItems.push({
                        title: 'title',
                        id: targetId
                    });
                    console.log(`Empty Title Data id: ${config.id}, idx: ${idx + 1}`);
                }

                if(config.type=='form'){
                    if(config.body && (!config.body.description || config.body.description =='')){
                        errorsData.dataItems.push( {
                            error: 'description',
                            id: targetId
                        });
                    }

                    if(config.body && config.body.content){
                        config.body.content.forEach((content)=>{
                            if(content.type ===''){
                                errorsData.dataItems.push( {
                                    error: 'type',
                                    contentId: content.id,
                                    id: targetId
                                });
                            }

                        })
                    }

                    console.log(`Empty Title Data id: ${config.id}, idx: ${idx + 1}`);
                }
            })
        }

        if(errorsData.dataItems.length < 1){
            // Validation 통과
            await  ModuleService.isModuleNmDuplication({
                'prjctId': prjctId,
                'moduleNm': submitData.moduleNm,
                'moduleTy': '02',
                'moduleId':submitData.moduleId
            }).then((result)=>{
                if(result.data.data){
                    alertError("alert.dupModuleNm", '', null, null, null, intl);
                }else{
                    confirm("alert.save"
                        , "workflow.saveMsg"
                        ,()=>{
                            const moduleConfig = {
                                dataConfig
                            };

                            submitData.append("moduleId", selectionNode.id);
                            submitData.append("moduleTy", "02");
                            submitData.append("moduleConfig", JSON.stringify(moduleConfig));
                            submitData.moduleConfig = JSON.stringify(moduleConfig);

                            const result = updateReviewModuleItemWithFile(submitData);
                            result.then(res=>{
                                /*
                                    DeleteFiles 와 Body.AttachFiles 비교
                                    DeleteFiles 에 있던 AttachFIles 내용 없애기
                                 */
                                function updateData(cnData) {
                                    const result = cnData.map(data => {
                                        if (!data.deleteFiles) {
                                            const {deleteFiles, ...rest} = data;
                                            return rest;
                                        }

                                        const filteredAttachFiles = data.body.attachFiles.filter(attachFile =>
                                            !data.deleteFiles.some(deleteFile => deleteFile.realFilename === attachFile.realFilename)
                                        );

                                        const {deleteFiles, ...rest} = data;

                                        return {
                                            ...rest,
                                            body: {
                                                ...data.body,
                                                attachFiles: filteredAttachFiles
                                            }
                                        };
                                    });
                                    return result;
                                }
                                dispatch(setManageWorkflowReviewActiveData(updateData(activeData)));
                                dispatch(setManageWorkflowReviewInactiveData(updateData(inactiveData)));
                                dispatch(setManageWorkflowReviewFilesReset());
                                dispatch(switchManageWorkflowReviewRegistDataRefreshFlag());
                            })

                        }
                        ,null
                        , null
                        , intl);
                }
            });
        }

        dispatch(setManageWorkflowReviewDataError(errorsData));
        console.log("submitData => ", submitData)
    };

    const {
        files,
        fileLabels,
        fileAttributes,
        removeFiles,
        shapeConfig,
        hasErrorFiles
    } = useSelector((state) => ({
        files: state.WorkflowReviewTestConfig.files,
        fileLabels: state.WorkflowReviewTestConfig.fileLabels,
        fileAttributes: state.WorkflowReviewTestConfig.fileAttributes,
        removeFiles : state.WorkflowReviewTestConfig.removeFiles,
        shapeConfig: state.WorkflowReviewTestConfig.shapeConfig,
        hasErrorFiles: state.WorkflowReviewTestConfig.hasErrorFiles
    }));

    const onSubmitTest = async () => {
        let data = getValuesTest();
        // console.log('검수 미니테스트 등록~~1', data);
        // console.log('검수 미니테스트 등록~~2', files);
        // console.log('검수 미니테스트 등록~~3', fileLabels);
        // console.log('검수 미니테스트 등록~~4', fileAttributes);
        dispatch(workflow_review_testConfig_clearHasErrorFiles(null))
        const submitData = new FormData();
        const deleteFiles = [...removeFiles];
        let hasErrorCount = 0;
        const questions = fileAttributes.map(attrItems => {
            const labelId = fileLabels[attrItems.fileName];
            const attrArray = Array.from(attrItems.values);
            const labelSetting = shapeConfig.labels.filter(item=>item.id===labelId);
            if(Object.entries(attrItems.values).length == 0){
                if(labelSetting[0].attributes.length !== 0){
                    hasErrorCount++;
                    dispatch(workflow_review_testConfig_setHasErrorFiles(attrItems.fileName))
                }
            }else{
                if(labelSetting[0].attributes.length !== 0){
                    labelSetting[0].attributes.map(item=>{
                        if(item.required){
                            if(!attrItems.values[item.id]){
                                hasErrorCount++;
                                dispatch(workflow_review_testConfig_setHasErrorFiles(attrItems.fileName))
                            }
                        }
                        if(item.regexp){
                            if(attrItems.values[item.id]) {
                                let regexp = new RegExp(item.regexp)
                                if(!(regexp.test(attrItems.values[item.id]))){
                                    hasErrorCount++;
                                    dispatch(workflow_review_testConfig_setHasErrorFiles(attrItems.fileName))
                                }
                            }
                        }
                    })
                }
            }
            if(attrItems.rejectReason.length === 0){
                hasErrorCount++;
                dispatch(workflow_review_testConfig_setHasErrorFiles(attrItems.fileName))
            }

            return {
                "labels": labelId,
                "attributes": attrItems.values,
                "image": {
                    "realFileName": "",
                    "isNew": true
                },
                "comment": attrItems.comment,
                "rejectReason": attrItems.rejectReason
            }
        });

        const result = await triggerTest();
        //console.log('validation result == ',result , hasErrorCount);
        if(!result || hasErrorCount>0){
            return;
        }

        const moduleConfig = {
            "testConfig": {
                "title": data.title.trim(),
                "questionCount": parseInt(data.questionCount),
                "passCount": parseInt(data.passCount),
                "questionOrderTy": data.questionOrderTy,
                "desc": data.desc,
                "deleteFiles" : deleteFiles,
                "questions": questions
            }
        };

        for (let i = 0; i < files.length; i++) {
            let f = files[i];
            if (f.isNew) {
                // 신규로 추가된 파일 항목만  전송 파일 목록에 담는다.
                submitData.append("testAttachFile[]", files[i])
                moduleConfig.testConfig.questions[i].image.realFileName = f.name;
            }else{
                moduleConfig.testConfig.questions[i].image.realFileName = f.realFileName;
                moduleConfig.testConfig.questions[i].image.host = f.host;
                moduleConfig.testConfig.questions[i].image.url = f.url;
                moduleConfig.testConfig.questions[i].image.isNew = false;
            }
        }

        submitData.moduleId = nodeData.moduleId;
        submitData.moduleNm = nodeData.moduleNm;

        submitData.append("moduleId", selectionNode.id);
        submitData.append("moduleTy", "02");
        submitData.append("moduleConfig", JSON.stringify(moduleConfig));
        submitData.moduleConfig = JSON.stringify(moduleConfig);

        //console.log(JSON.stringify(moduleConfig))
        if(hasErrorCount === 0){
            await  ModuleService.isModuleNmDuplication({
                'prjctId': prjctId,
                'moduleNm': submitData.moduleNm,
                'moduleTy': '02',
                'moduleId':submitData.moduleId
            }).then((result)=> {
                if (result.data.data) {
                    alertError("alert.dupModuleNm", '', null, null, null, intl);
                } else {
                    confirm("alert.save"
                        , "workflow.saveMsg"
                        , () => {
                            updateReviewModuleItemWithFile(submitData);
                        }
                        , null
                        , null
                        , intl);
                }
            });

        }else{
            console.log('오류가 있네여')
        }
    };

    const openTask = ()=>{
        console.log('오픈 할까요?')
        let requiredConfig = [];
        if(baseConfig == null){
            requiredConfig.push(GetMessageIntl(intl, "workflow.basicInfoSetting"))
        }

        if(workerConfig == null){
            requiredConfig.push(GetMessageIntl(intl, "workflow.workerSetting"))
        }

        if(pointConfig == null || methodsPoint.getValues('data.instance.lengt')?.length ==0){
            requiredConfig.push(GetMessageIntl(intl, "workflow.pointSetting"))
        }

        let contentError = 0;
        if(dataConfig == null || dataConfig.length==0){
            requiredConfig.push(GetMessageIntl(intl, "workflow.dataSetting"))
        }else{
            // dataConfig 내부 에디터, 체크박스 내용 미존재시 내용 없습니다 경고
            dataConfig.map((item) => {
                if(item.type === "editor" || item.type === "checkbox"){
                    if(item.body.content === ""){
                        contentError += 1;
                    }
                }else if(item.type === "form"){
                    if(item.body.content.length < 1){
                        contentError += 1;
                    }
                }
            })
        }

        if(testConfig == null && (tabActive && tabActive.testConfig === true)){
            requiredConfig.push(GetMessageIntl(intl, "workflow.miniTestSetting"))
        }

        if(requiredConfig.length > 0){
            alertWarning("workflow.noOpenConfig", "workflow.noOpenConfigMsg", ()=>{
                console.log("확인 버튼");
            }, {'requiredConfig': requiredConfig.join(',')}, intl)
        }else{
            if(contentError > 0){ // dataConfig 오류 있을 때
                alertWarning("workflow.dataConfig.contentError", "workflow.dataConfig.contentErrorMsg", ()=>{
                    console.log("dataConfig body에 빈 값 있어서 오픈 불가");
                },null, intl)
            }else{
                let submitData = {};
                submitData.moduleId = nodeData.moduleId;
                confirm("workflow.open"
                    , "workflow.openMsg"
                    , () => {
                        updateOpenModuleItem(submitData);
                    }
                    , null
                    , null
                    , intl);
            }
        }
    }

    return (
        <div>
            <Offcanvas isOpen={isReviewOffCanvas} toggle={toggleTopCanvas} id="offcanvasReview" style={{top: "70px", border: "1px solid var(--vz-border-color)"}} scrollable={false} backdrop={false} keyboard={false} direction="end">
                <div className="offcanvas-header">
                    <h5 className="offcanvas-title">
                        {nodeData ? nodeData.editMode ?
                                <>
                                    <input type="text" defaultValue={nodeData.moduleNm} className="form-control editable tx-20 pd-x-0-f pd-y-3 fw-semibold lh-19"
                                           maxLength={50}
                                           style={{minWidth: 250, width: 12 * nodeData.moduleNm.length}}
                                           onBlur={handleUpdateModuleNm}
                                           onKeyUp={handleUpdateModuleNmKeyPress}
                                           autoFocus/>
                                    <p className="mb-0 validation-error-message"></p>
                                </>
                                :
                                <div className="d-flex align-items-center">
                                    {nodeData.moduleNm}
                                    <i className="ri-edit-line text-coco-primary-100 fw-normal" style={{marginLeft: '10.5px'}} onClick={handleClickModuleNm}></i>
                                </div>
                            : null
                        }
                    </h5>
                    <i className="ri-close-line text-coco-default-white tx-20 text-pointer lh-24" onClick={toggleTopCanvas}/>
                </div>
                <div className="pd-x-12 bg-coco-gray-50">
                    <Nav tabs className="nav nav-coco2 nav-tabs nav-tabs-custom">
                        <NavItem>
                            <NavLink
                                style={{cursor: "pointer"}}
                                className={classnames({
                                    active: customActiveTab === "1",
                                })}
                                onClick={() => {
                                    toggleCustom("1");
                                }}
                            >
                                {GetMessage("workflow.basicInfoSetting")}
                            </NavLink>
                        </NavItem>
                        {/*<NavItem>
                            <NavLink
                                style={{cursor: "pointer"}}
                                className={classnames({
                                    active: customActiveTab === "2",
                                })}
                                onClick={() => {
                                    toggleCustom("2");
                                }}
                            >
                                {GetMessage("workflow.reviewSetting")}
                            </NavLink>
                        </NavItem>*/}
                        <NavItem>
                            <NavLink
                                style={{cursor: "pointer"}}
                                className={classnames({
                                    active: customActiveTab === "3",
                                })}
                                onClick={() => {
                                    toggleCustom("3");
                                }}
                            >
                                {GetMessage("workflow.workerSetting")}
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                style={{cursor: "pointer"}}
                                className={classnames({
                                    active: customActiveTab === "4",
                                })}
                                onClick={() => {
                                    toggleCustom("4");
                                }}
                            >
                                {GetMessage("workflow.pointSetting")}
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                style={{cursor: "pointer"}}
                                className={classnames({
                                    active: customActiveTab === "5",
                                })}
                                onClick={() => {
                                    toggleCustom("5");
                                }}
                            >
                                {GetMessage("workflow.dataSetting")}
                            </NavLink>
                        </NavItem>
                        {
                            tabActive && tabActive.testConfig === true?
                                <NavItem>
                                    <NavLink
                                        style={{cursor: "pointer"}}
                                        className={classnames({
                                            active: customActiveTab === "6",
                                        })}
                                        onClick={() => {
                                            toggleCustom("6");
                                        }}
                                    >
                                        {GetMessage("workflow.miniTestSetting")}
                                    </NavLink>
                                </NavItem>
                            :
                                <></>
                        }
                    </Nav>
                </div>
                <TabContent
                    activeTab={customActiveTab}
                    className="text-muted"
                >
                    <TabPane tabId="1" id="datasetTab">
                        <FormProvider {...methodsBase}>
                            <form onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    e.preventDefault()
                                }
                            }}>
                                <BaseConfigDiv/>
                                <div className="offcanvas-footer justify-content-between">
                                    <div className="">
                                        <button className="btn btn-coco-3 mr-16" type="button" onClick={()=>openTask()} disabled={disabledOpenTask}>
                                            {GetMessageIntl(intl, "common.open")}
                                        </button>
                                    </div>
                                    <div className="d-flex align-items-center justify-content-end">
                                        <button className="btn btn-coco-1 mr-16" type="button" onClick={methodsBase.handleSubmit(onSubmitBase)}>
                                            {GetMessageIntl(intl, "common.save")}
                                        </button>
                                        <button type="button" className="btn btn-coco-icon-1" onClick={deleteModuleItemCheck}>
                                            <i className="ri-delete-bin-line"></i>
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </FormProvider>
                    </TabPane>
                    {/*<TabPane tabId="2" id="settingTab">
                        <FormProvider {...methodsReview}>
                            <form>
                                <ShapeConfigDiv/>
                                <div className="offcanvas-footer justify-content-between">
                                    <div className="">
                                        <button className="btn btn-coco-3 mr-16" type="button" onClick={()=>openTask()} disabled={disabledOpenTask}>
                                            {GetMessageIntl(intl, "common.open")}
                                        </button>
                                    </div>
                                    <div className="d-flex align-items-center justify-content-end">
                                        <button className="btn btn-coco-1 mr-16" type="button" onClick={methodsReview.handleSubmit(onSubmitReview)}>
                                            {GetMessageIntl(intl, "common.save")}
                                        </button>
                                        <button type="button" className="btn btn-coco-icon-1" onClick={deleteModuleItemCheck}>
                                            <i className="ri-delete-bin-line"></i>
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </FormProvider>
                    </TabPane>*/}

                    <TabPane tabId="3" id="workerTab">
                        <FormProvider {...methodsWorker}>
                            <form>
                                <WorkerConfigDiv/>
                                <div className="offcanvas-footer justify-content-between">
                                    <div className="">
                                        <button className="btn btn-coco-3 mr-16" type="button" onClick={()=>openTask()} disabled={disabledOpenTask}>
                                            {GetMessageIntl(intl, "common.open")}
                                        </button>
                                    </div>
                                    <div className="d-flex align-items-center justify-content-end">
                                        <button className="btn btn-coco-1 mr-16" type="button" onClick={methodsWorker.handleSubmit(onSubmitWorker)}>
                                            {GetMessageIntl(intl, "common.save")}
                                        </button>
                                        <button type="button" className="btn btn-coco-icon-1" onClick={deleteModuleItemCheck}>
                                            <i className="ri-delete-bin-line"></i>
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </FormProvider>
                    </TabPane>

                    <TabPane tabId="4" id="pointTab">
                        <FormProvider {...methodsPoint}>
                            <form onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    e.preventDefault()
                                }
                            }}>
                                {!isClsfcSmryType(canvasDataTy) ? <PointConfigDiv/> : <PointClsfcSmryConfigDiv />}
                                <div className="offcanvas-footer justify-content-between">
                                    <div className="">
                                        <button className="btn btn-coco-3 mr-16" type="button" onClick={()=>openTask()} disabled={disabledOpenTask}>
                                            {GetMessageIntl(intl, "common.open")}
                                        </button>
                                    </div>
                                    <div className="d-flex align-items-center justify-content-end">
                                        <button className="btn btn-coco-1 mr-16" type="button" onClick={methodsPoint.handleSubmit(onSubmitPoint)}>
                                            {GetMessageIntl(intl, "common.save")}
                                        </button>
                                        <button type="button" className="btn btn-coco-icon-1" onClick={deleteModuleItemCheck}>
                                            <i className="ri-delete-bin-line"></i>
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </FormProvider>
                    </TabPane>
                    <TabPane tabId="5" id="dataTab">
                        <FormProvider {...methodsData}>
                            <form>
                                <DataConfigDiv/>
                                <div className="offcanvas-footer justify-content-between">
                                    <div className="">
                                        <button className="btn btn-coco-3 mr-16" type="button" onClick={()=>openTask()} disabled={disabledOpenTask}>
                                            {GetMessageIntl(intl, "common.open")}
                                        </button>
                                    </div>
                                    <div className="d-flex align-items-center justify-content-end">
                                        <button className="btn btn-coco-1 mr-16" type="button" onClick={methodsData.handleSubmit(onSubmitData)}>
                                            {GetMessageIntl(intl, "common.save")}
                                        </button>
                                        <button type="button" className="btn btn-coco-icon-1" onClick={deleteModuleItemCheck}>
                                            <i className="ri-delete-bin-line"></i>
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </FormProvider>
                    </TabPane>
                    {
                        tabActive && tabActive.testConfig === true?
                            <TabPane tabId="6" id="testTab">
                                <FormProvider {...methodsTest}>
                                    {/*<form onSubmit={methodsTest.handleSubmit(onSubmitTest)} onKeyDown={(e) => {
                                        if (e.key === 'Enter') {
                                            e.preventDefault()
                                        }
                                    }}>*/}
                                    <form>
                                        <TestConfigDiv/>
                                        <div className="offcanvas-footer justify-content-between">
                                            <div className="">
                                                <button className="btn btn-coco-3 mr-16" type="button" onClick={()=>openTask()} disabled={disabledOpenTask}>
                                                    {GetMessageIntl(intl, "common.open")}
                                                </button>
                                            </div>
                                            <div className="d-flex align-items-center justify-content-end">
                                                <button className="btn btn-coco-1 mr-16" type="button" onClick={onSubmitTest}>
                                                    {GetMessageIntl(intl, "common.save")}
                                                </button>
                                                <button type="button" className="btn btn-coco-icon-1" onClick={deleteModuleItemCheck}>
                                                    <i className="ri-delete-bin-line"></i>
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                </FormProvider>
                            </TabPane>
                            :
                            <></>
                    }
                </TabContent>
            </Offcanvas>

        </div>
    );
};
