export const SET_SEARCH_PARAM = "dataset/SET_SEARCH_PARAM";
export const SET_CANVAS_DATA_TY = "dataset/SET_CANVAS_DATA_TY";
export const SET_INIT_SEARCH_PARAM = "dataset/SET_INIT_SEARCH_PARAM";
export const SWITCH_MODAL_FLAG = "dataset/SWITCH_MODAL_FLAG";
export const SWITCH_FOLDERVIEW_FLAG = "dataset/SWITCH_FOLDERVIEW_FLAG";
export const SET_CURRENT_PAGE = "dataset/SET_CURRENT_PAGE";
export const SET_DATASET_ID = "dataset/SET_DATASET_ID";
export const SET_WRKFLOW = "dataset/SET_WRKFLOW";
export const SET_WRKFLOW_OPTIONS = "dataset/SET_WRKFLOW_OPTIONS";
export const SET_WRKFLOW_WITH_MODULES = "dataset/SET_WRKFLOW_WITH_MODULES";
export const SET_DATASET_OPTIONS = "dataset/SET_DATASET_OPTIONS";
export const SET_FILE_OR_FOLDER_NM = "dataset/SET_FILE_OR_FOLDER_NM";
export const SET_FOLDER_CURRENT_PAGE = "dataset/SET_FOLDER_CURRENT_PAGE";
export const SET_MOUNT_CNT = "dataset/SET_MOUNT_CNT";
export const GO_TO_DETAILS_BY_FOLDER = "dataset/GO_TO_DETAILS_BY_FOLDER";
