import React, {useEffect, useState} from 'react';
import {Card, CardBody, CardHeader, Col, Container, Input, Row} from "reactstrap";
import Select from "react-select";
import {GetMessage, GetMessageIntl} from "../../../../util/message";
import {noBorderSelectStyle} from "../../../../components/constants/select";
import SimpleBar from "simplebar-react";
import ProgressBar from "react-bootstrap/ProgressBar";
import {useHistory} from "react-router-dom";
import ReactECharts from "echarts-for-react";
import {DashboardService} from "../../../../apis/api/DashboardService";
import {useSelector} from "react-redux";
import {useIntl} from "react-intl";

function ProjectDashboard() {
    const intl = useIntl();
    const history = useHistory();
    // const prjctId = sessionStorage.getItem("prjctId");

    const {
        prjctId,
    } = useSelector((state) => ({
        prjctId: state.Manage.prjctId,
    }));

    const [yearMonthOption, setYearMonthOption] = useState([]);

    const [projectFirstRow, setProjectFirstRow] = useState({
        count01: 0
        , count01Distinct: 0
        , count02: 0
        , count02Distinct: 0
        , count03: 0
        , count03Distinct: 0
        , count04: 0
        , count04Distinct: 0
    });

    const [column04, setColumn04] = useState([]);

    const [askList, setASkList] = useState([]);
    const [chartOption, setChartOption] = useState({});

    const [topTenWorkerList, setTopTenWorkerList] = useState([]);

    const [thisMonthAmount, setThisMonthAmount] = useState(0);
    const [unPaymentAmount, setUnPaymentAmount] = useState(0);
    const [paidAmount, setPaidAmount] = useState(0);
    const [totalPaidAmount, setTotalPaidAmount] = useState(0);

    useEffect(() => {
        // 대시보드 초기화
        setPaidAmount(0);
        setThisMonthAmount(0);
        setTotalPaidAmount(0);
        setUnPaymentAmount(0);
        setYearMonthOption({});
        setProjectFirstRow({
            count01: 0
            , count01Distinct: 0
            , count02: 0
            , count02Distinct: 0
            , count03: 0
            , count03Distinct: 0
            , count04: 0
            , count04Distinct: 0
        });
        setColumn04([]);
        setTopTenWorkerList([]);
        setChartOption({});

        DashboardService.projectDashboardColumnAll({prjctId: prjctId})
            .then(res => {
                if (res.data.data != null) {
                    if(res.data.data.column05){
                        const list = JSON.parse(res.data.data.column05)
                        setYearMonthOption(list.map(item => {
                            return {value: item, label: item}
                        }));
                    }

                    setProjectFirstRow(JSON.parse(res.data.data.column01));

                    setColumn04(JSON.parse(res.data.data.column04 ?? []));
                }
            });
        if(prjctId){
            DashboardService.projectDashboardColumn02({prjctId: prjctId})
                .then(res => {
                    console.log('res.data.data', res.data.data);
                    setPaidAmount(res.data.data.paidAmount)
                    setThisMonthAmount(res.data.data.thisMonthAmount)
                    setTotalPaidAmount(res.data.data.totalPaidAmount)
                    setUnPaymentAmount(res.data.data.unPaymentAmount)
                    setChartOption(getChartOption(res.data.data.xaxis, res.data.data.chartData));
                });
        }
        DashboardService.askBoard30DaysList({prjctId: prjctId})
            .then(res => {
                setASkList(res.data.data);
            });

    }, [prjctId]);


    const yearMonthChangeHandler = (event) => {
        console.log(event.value)
        const yyyyMm = event.value.replace('.','-');
        getTopTenList(yyyyMm)
    }

    const getTopTenList = (yyyyMm) => {
        DashboardService.projectDashboardColumn03({prjctId: prjctId, yyyyMm: yyyyMm})
            .then(res => {
                setTopTenWorkerList(res.data.data.map((item, index) => {
                    return {
                        no: index+1, name: item.userNm, point: item.total
                    }
                }));
            });
    }

    useEffect(() => {
        if (yearMonthOption.length > 0) {
            const yyyyMm = yearMonthOption[0].value.replace('.','-');
            getTopTenList(yyyyMm)
        }
    }, [yearMonthOption]);
    // useEffect(()=>{
    //     console.log(projectFirstRow)
    // },[projectFirstRow]);

    /**
     * 1:1 문의 게시판으로 이동
     */
    const goAskBoard = () => {
        history.push({
            pathname: "/manager/board/ask"
        });
    }


    const top3Style = {padding: "2px 2px 2px 1px", backgroundColor: "#0BAD8F", color: "#FFFFFF", width: "24px", height: "24px", display: "flex", borderRadius: "10px"}
    const topElseStyle = {padding: "2px 2px 2px 1px", backgroundColor: "#DFF3EF", color: "#0BAD8F", width: "24px", height: "24px", display: "flex", borderRadius: "10px"}

    return (
        <>
            <Row className="row-cols-xxl-5 row-cols-lg-3 row-cols-1">
                <Col xxl={8}>
                    <Row className="ht-150">
                        <Col xxl={4}><TopCardComponent title={GetMessageIntl(intl, "dashboard.datasetCount")} count={projectFirstRow.count01} distinctCount={projectFirstRow.count01Distinct} icon={"ri-database-2-line"}/></Col>
                        <Col xxl={4}><TopCardComponent title={GetMessageIntl(intl, "dashboard.folderCount")} count={projectFirstRow.count02} distinctCount={projectFirstRow.count02Distinct} icon={"ri-folder-line"}/></Col>
                        <Col xxl={4}><TopCardComponent title={GetMessageIntl(intl, "dashboard.fileCount")} count={projectFirstRow.count03} distinctCount={projectFirstRow.count03Distinct} icon={"ri-file-4-line"}/></Col>
                    </Row>
                    <Row className="ht-450 mt-24" style={{marginRight:"12px"}}>
                        <Col xxl={12} className="ml-10" style={{background: "#F3F6F9", borderRadius: "7px", border: "1px solid #CFD8DC"}}>
                            <Row className="ht-50">
                                <Col xxl={12} className="pd-t-20">
                                    <i className="ri-money-dollar-circle-line" style={{color: "#0BAD8F"}}></i>&nbsp;&nbsp;&nbsp;<span className="text-coco-gray-600 fw-semibold tx-16">{GetMessageIntl(intl, "dashboard.paymentPointsTransition")}</span>
                                </Col>
                            </Row>
                            <Row className="ht-400">
                                <Col xxl={9}>
                                    <ReactECharts option={chartOption} notMerge={true} style={{width: "800px", height: "400px"}}/>
                                </Col>
                                <Col xxl={3}>
                                    <Row style={{height: "84px"}}>
                                        <ChartRightItem color={false} title={GetMessageIntl(intl, "dashboard.thisMonthPoint")} point={thisMonthAmount}/>
                                    </Row>
                                    <Row style={{height: "84px"}}>
                                        <ChartRightItem color={false} title={GetMessageIntl(intl, "dashboard.readyToPayPoint")} point={unPaymentAmount}/>
                                    </Row>
                                    <Row style={{height: "84px"}}>
                                        <ChartRightItem color={false} title={GetMessageIntl(intl, "dashboard.pointsPaid")} point={paidAmount}/>
                                    </Row>
                                    <Row style={{height: "84px"}}>
                                        <ChartRightItem color={true} title={GetMessageIntl(intl, "dashboard.totalPoint")} point={thisMonthAmount+unPaymentAmount+paidAmount}/>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
                <Col xxl={4}>
                    <Card className="shadow-none h-100 mb-0" style={{borderRadius: "7px", border: "1px solid #CFD8DC"}}>
                        <CardBody>
                            <Row className="ht-40">
                                <Col xxl={12}>
                                    <i className="ri-user-4-line" style={{color: "#0BAD8F"}}></i>&nbsp;&nbsp;&nbsp;<span className="text-coco-gray-600 fw-semibold tx-16">{GetMessageIntl(intl, "dashboard.workerSttus")}</span>
                                </Col>
                            </Row>
                            <Row style={{height: "90px"}}>
                                <Col xxl={6}>
                                    <Card className="shadow-none h-100 mb-0">
                                        <CardHeader className="pd-0" style={{borderBottom: "0px"}}><span className="text-coco-gray-600 fw-semibold tx-16">중복 포함</span></CardHeader>
                                        <CardBody><span className="text-coco-gray-800 fw-semibold tx-28">{projectFirstRow.count04.toLocaleString()}</span></CardBody>
                                    </Card>
                                </Col>
                                <Col xxl={6}>
                                    <Card className="shadow-none h-100 mb-0">
                                        <CardHeader className="pd-0" style={{borderBottom: "0px"}}><span className="text-coco-gray-600 fw-semibold tx-16">중복 제외</span></CardHeader>
                                        <CardBody><span className="text-coco-gray-800 fw-semibold tx-28">{projectFirstRow.count04Distinct.toLocaleString()}</span></CardBody>
                                    </Card>
                                </Col>
                            </Row>
                            <hr/>
                            <Row>
                                <Col xxl={8}>
                                    <i className="ri-vip-crown-2-line" style={{color: "#F06448"}}></i>&nbsp;&nbsp;&nbsp;<span className="text-coco-gray-600 fw-semibold tx-16">{GetMessageIntl(intl, "dashboard.workerOfTopTen")}</span>
                                </Col>
                                <Col xxl={4} className="text-right">
                                    <Select
                                        name="orderParam"
                                        className="mb-0"
                                        styles={noBorderSelectStyle}
                                        defaultValue={yearMonthOption[0]}
                                        key={yearMonthOption[0]}
                                        options={yearMonthOption}
                                        onChange={yearMonthChangeHandler}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                {
                                    topTenWorkerList.map(item => {
                                        return (
                                            <Row key={"topTen".concat(item.no)} className="pd-y-7">
                                                <Col xxl={1}>
                                                    <div style={item.no < 4 ? top3Style : topElseStyle} className={"align-items-center justify-content-center"}>
                                                        {item.no}
                                                    </div>
                                                </Col>
                                                <Col xxl={7}>
                                                    <span className="coco-gray-700 tx-16">{item.name}</span>
                                                </Col>
                                                <Col xxl={4} className="text-right  tx-16" style={item.no < 4 ? {color: "#F06448"} : null}>￦&nbsp;{item.point.toLocaleString()}</Col>
                                            </Row>
                                        )
                                    })
                                }
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            <Row className="ht-300 mt-24 mb-24">
                <Col xxl={6}>
                    <Card className="shadow-none h-100 mb-0" style={{borderRadius: "7px", border: "1px solid #CFD8DC"}}>
                        <CardBody style={{paddingRight: "1px"}}>
                            <Row>
                                <Col xxl={12}>
                                    <i className="ri-vip-crown-2-line" style={{color: "#F06448"}}></i>&nbsp;&nbsp;&nbsp;<span className="text-coco-gray-600 fw-semibold tx-16">{GetMessageIntl(intl, "dashboard.workflowProgress")}</span>
                                </Col>
                            </Row>
                            <Row className="mt-10" style={{height: "44px"}}>
                                <Col xxl={12} style={{maxWidth: "98%", backgroundColor: "#F3F6F9"}}>
                                    <Row style={{height: "inherit"}}>
                                        <Col className="mt-10 h-100 pd-l-8" style={{backgroundColor: "#F3F6F9"}} xxl={1}>#</Col>
                                        <Col className="mt-10 h-100 pd-l-8" style={{backgroundColor: "#F3F6F9"}} xxl={4}><Header title={GetMessageIntl(intl, "dashboard.titleWorkflow")}/></Col>
                                        <Col className="mt-10 h-100 pd-l-8" style={{backgroundColor: "#F3F6F9"}} xxl={1}><Header title={GetMessageIntl(intl, "dashboard.status")}/></Col>
                                        <Col className="mt-10 h-100 pd-l-8" style={{backgroundColor: "#F3F6F9"}} xxl={2}><Header title={GetMessageIntl(intl, "dashboard.datasetCount")}/></Col>
                                        <Col className="mt-10 h-100 pd-l-8" style={{backgroundColor: "#F3F6F9"}} xxl={2}><Header title={GetMessageIntl(intl, "dashboard.fileCount")}/></Col>
                                        <Col className="mt-10 h-100 pd-l-8" style={{backgroundColor: "#F3F6F9"}} xxl={1}><Header title={GetMessageIntl(intl, "dashboard.exitRate")}/></Col>
                                        <Col className="mt-10 h-100 pd-l-8" style={{backgroundColor: "#F3F6F9"}} xxl={1}></Col>
                                    </Row>
                                </Col>
                            </Row>
                            <SimpleBar style={{maxHeight: "220px", overflowX: "hidden"}} autoHide={false} className="simplebar-track-coco">
                                <Row>
                                    {
                                        column04.map(item => {
                                            return (
                                                <Row key={"workflow".concat(item.wrkflowId)} className="pd-y-7 ht-40">
                                                    <Col xxl={1}>{item.no}</Col>
                                                    <Col xxl={4}><span className="text-coco-gray-700 tx-16">{item.wrkflowNm}</span></Col>
                                                    <Col xxl={1}>
                                                        {item.sttus === '01' ?
                                                            <div className="badge badge-coco badge-coco-soft-skyblue" style={{width: "43px"}}>{GetMessageIntl(intl, "dashboard.active")}</div>
                                                            : <div className="badge badge-coco badge-coco-soft-gray" style={{width: "43px"}}>{GetMessageIntl(intl, "dashboard.inactive")}</div>
                                                        }
                                                    </Col>
                                                    <Col xxl={2}><span className="text-coco-gray-700 tx-16">{item.datasetCnt.toLocaleString()}</span></Col>
                                                    <Col xxl={2}><span className="text-coco-gray-700 tx-16">{item.fileCnt.toLocaleString()}</span></Col>
                                                    <Col xxl={1}><span className="text-coco-gray-400 tx-16" style={{color: "#78909C"}}>{item.exitRate}%</span></Col>
                                                    <Col className="pd-t-8" xxl={1}><ProgressBar now={item.exitRate} style={{height: "5px"}}/></Col>
                                                </Row>
                                            )
                                        })
                                    }
                                </Row>
                            </SimpleBar>
                        </CardBody>
                    </Card>
                </Col>
                <Col xxl={6}>
                    <Card className="shadow-none h-100 mb-0" style={{borderRadius: "7px", border: "1px solid #CFD8DC"}}>
                        <CardBody style={{paddingRight: "1px"}}>
                            <Row style={{paddingRight: "20px"}}>
                                <Col xxl={8}>
                                    <i className="ri-questionnaire-line" style={{color: "#0BAD8F"}}></i>&nbsp;&nbsp;&nbsp;<span className="text-coco-gray-600 fw-semibold tx-16">{GetMessageIntl(intl, "dashboard.askListOf30Days")}</span>
                                </Col>
                                <Col xxl={4} className="text-right pd-r-20">
                                    <span className="text-coco-gray-600 fw-semibold tx-16" onClick={goAskBoard} style={{cursor: "pointer"}}>{GetMessageIntl(intl, "dashboard.goAskBoard")} <i className="ri-arrow-right-s-line" style={{color: "#78909C"}}></i></span>
                                </Col>
                            </Row>
                            <Row className="mt-10" style={{height: "44px"}}>
                                <Col xxl={12} style={{maxWidth: "98%", backgroundColor: "#F3F6F9"}}>
                                    <Row style={{height: "inherit"}}>
                                        <Col className="mt-10 h-100 pd-l-20" style={{backgroundColor: "#F3F6F9"}} xxl={5}><Header title={GetMessageIntl(intl, "dashboard.askTitle")}/></Col>
                                        <Col className="mt-10 h-100 pd-l-8" style={{backgroundColor: "#F3F6F9"}} xxl={5}><Header title={GetMessageIntl(intl, "dashboard.askTask")}/></Col>
                                        <Col className="mt-10 h-100 pd-l-8" style={{backgroundColor: "#F3F6F9"}} xxl={2}><Header title={GetMessageIntl(intl, "dashboard.answerStatus")}/></Col>
                                    </Row>
                                </Col>
                            </Row>
                            <SimpleBar style={{maxHeight: "220px", overflowX: "hidden"}} autoHide={false} className="simplebar-track-coco">
                                <Row>
                                    {
                                        askList.map(item => {
                                            return (
                                                <Row key={"progress".concat(item.cnSeq)} className="pd-y-7 ht-40">
                                                    <Col xxl={5} className="pd-l-20 text-overflow"><span className="text-coco-gray-700 tx-16 text-overflow" >{item.cnTitle}</span></Col>
                                                    <Col xxl={5}><span className="text-coco-gray-700 tx-16">{item.moduleNm}</span></Col>
                                                    <Col xxl={2}><span className="text-coco-gray-700 tx-16">{
                                                        item.rpCnSeq > 0 ? <span className="text-coco-gray-400 fw-semibold tx-16">{GetMessageIntl(intl, "dashboard.answer")}</span>
                                                            : <span className="text-coco-danger-400 fw-semibold tx-16">{GetMessageIntl(intl, "dashboard.noAnswer")}</span>
                                                    }</span></Col>
                                                </Row>
                                            )
                                        })
                                    }
                                </Row>
                            </SimpleBar>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            <Row className="ht-20"></Row>
        </>
    )
}

const getChartOption=(axis, data)=>{
    let seriesData = data.length > 0  ? data.map(item=>{
        return {
            name: item.name,
            type: 'bar',
            stack: 'Ad',
            emphasis: {
                disabled: true
            },
            data: item.data
        }
    }) : [];

    return {
        color: ['#2E99FF', '#44A9FF', '#93CDFF'],
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'shadow'
            }
        },
        legend: {show: false},
        grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true
        },
        xAxis: [
            {
                type: 'category',
                data: axis
            }
        ],
        yAxis: [
            {
                type: 'value'
            }
        ],
        series: seriesData
    };
}

const ChartRightItem = (props) => {
    return (
        <>
            <Col xxl={1}></Col>
            <Col xxl={10} className="pd-l-20 mb-4" style={props.color ? {background: "#465A64", borderRadius: "7px", height: "inherit"} : null}>
                <Row className="h-50 pd-t-10">
                    <Col xxl={12} className="text-right"><span className="text-coco-gray-600 fw-semibold tx-16" style={props.color ? {color: "white"} : null}>{props.title}</span></Col>
                </Row>
                <Row className="h-50">
                    <Col xxl={12} className="text-right"><span className="text-coco-gray-800 fw-semibold tx-24" style={props.color ? {color: "white"} : null}>￦{props.point.toLocaleString()}</span></Col>
                </Row>
            </Col>
            <Col xxl={1}></Col>
        </>
    )
}

const Header = (props) => {
    return <span className="text-coco-gray-700 fw-semibold tx-16">{props.title}</span>;
}

function TopCardComponent(prop) {
    const intl = useIntl();
    const {title, count, distinctCount, icon} = prop;

    return (
        <>
            <Card className="shadow-none h-100 mb-0" style={{background: "#F3F6F9", borderRadius: "7px"}}>
                <CardBody>
                    <Row className="ht-40">
                        <Col xxl={12}>
                            <i className={icon} style={{color: "#0BAD8F"}}></i>&nbsp;&nbsp;&nbsp;<span className="text-coco-gray-600 fw-semibold tx-16">{title}</span>
                        </Col>
                    </Row>
                    <Row className="ht-40">
                        <Col xxl={12}><span className="text-coco-gray-800 fw-semibold tx-28">{count.toLocaleString()}</span></Col>
                    </Row>
                    <Row className="ht-40">
                        <Col xxl={12} style={{paddingTop: "12px"}}>
                            <span className="text-coco-gray-700 tx-14">{GetMessageIntl(intl, "dashboard.distinct")}</span>
                            <span className="text-coco-primary-600 tx-14 pd-l-3">{distinctCount.toLocaleString()}</span>
                        </Col>
                    </Row>
                </CardBody>
            </Card>
        </>
    )
}

export default ProjectDashboard;