// @ts-nocheck
import {all, put, take, takeEvery, takeLatest} from "redux-saga/effects";
import {getModule} from "store/label/action";
import {
    checkUnableWorkFailed,
    checkUnableWorkSuccess,
    getSkipRsnFailed,
    getSkipRsnSuccess,
    getWorkflowSuccess,
    recoveryDataFailed,
    recoveryDataSuccess,
    setDataSttus,
    getFileInfo,
    getFileInfoFailed,
    getFileInfoSuccess,
    getRejectRsnFailed,
    getRejectRsnSuccess,
    getHistoryFailed,
    getHistorySuccess,
    updateSearchParamSuccess,
    getDatasetByWorkflowFailed,
    getDatasetByWorkflowSuccess,
    getModuleByWorkflowSuccess,
    getModuleByWorkflowFailed,
    getDataList,
    setDataList,
    getWorkerByWorkflowSuccess,
    getWorkerByWorkflowFailed,
    getJsonFailed, getJsonSuccess
} from "./action";
import {
    CHECK_UNABLE_WORK,
    GET_SKIP_RSN,
    GET_WORKFLOW,
    RECOVERY_DATA,
    GET_FILE_INFO,
    GET_FILE_INFO_SUCCESS,
    RECOVERY_DATA_SUCCESS,
    CHECK_UNABLE_WORK_SUCCESS,
    GET_REJECT_RSN,
    GET_HISTORY,
    UPDATE_SEARCH_PARAM_VISIBLE,
    UPDATE_SEARCH_PARAM,
    GET_DATASET_BY_WORKFLOW,
    GET_MODULE_BY_WORKFLOW,
    GET_DATA_LIST,
    GET_WORKER_BY_WORKFLOW, GET_JSON
} from "./actionType";
import {
    DataWorkStatus,
    DisplayState,
    HistoryColorSet, HistoryStyle, HistoryUserColor,
    LabelSttus,
    ReviewSttus, SearchFilter,
    SttusColor,
    SttusDisplay
} from "./index";
import {GetMessageIntl} from "../../../util/message";
import {Store} from "redux";
import {getRootStore} from "../../index";
import {DatasetDetailsService} from "../../../apis/api/DatasetDetailsService";
import {getCore} from "../../../pages/user/label/annotation/image/cvat-core-wrapper";
import {Review} from "../../../pages/user/label/annotation/image/work/core/review";
import {cloneDeep} from "lodash";
import {GET_MODULE_SUCCESS} from "../../label/actionType";
import {LabelCmmnService} from "../../../apis/api/LabelCmmnService";
import {CANVAS_DATA_TY, LABEL_TYPE} from "../../../pages/user/label/annotation/image/const";

const core = getCore();
let store: null | Store = null;
function getStore(): Store {
    if (store === null) {
        store = getRootStore();
    }
    return store;
}

function getRsnText(){
    if (store === null){
        store = getRootStore();
    }

    const state = getStore().getState();
    const {
        Label: {
            Cmmn: {
                canvas: {
                    skipPopover: {reasons: skipReasonsList}
                },
                module: {
                    labels, canvasDataTy
                }
            }
        },
        Review: {
            Cmmn: {
                annotations: {reasons: reviewReasonsList}
            }
        }
    } = state;

    return {
        skipReasonsList,
        reviewReasonsList,
        labels,
        canvasDataTy
    };
}

function getStatus(labelSttus: String, reviewSttus: String, rejectCount: number,
                   mngConfirmCd: String, labelModuleNm: String, reviewModuleNm: String,
                   nextTaskNm: String, lbTaskSttusCd: String, nextTaskSttusCd: String, lstTaskSttusCd: String,
                   intl
): DisplayState {
    let displayMode: SttusDisplay | null = null, text = '', color: SttusColor | null = null;
    const undecidedLabelNm = GetMessageIntl(intl, 'details.undecidedLabel');
    const undecidedReviewNm = GetMessageIntl(intl, 'details.undecidedReview');
    if (mngConfirmCd === "04") {    // 종료 작업불가
        return {
            displayMode: SttusDisplay.WORKING,
            text: [GetMessageIntl(intl, 'details.close'), GetMessageIntl(intl, 'details.disable')].join(' '),
            color: SttusColor.DISABLE
        };
    }

    if (lbTaskSttusCd === LabelSttus.WITHDRAWAL) { // 라벨링 작업전
        const moduleNm = getStore().getState().Label.Cmmn.module.name ?? undecidedLabelNm;
        return {
            displayMode: SttusDisplay.STANDBY,
            text: [moduleNm, GetMessageIntl(intl, 'details.standby')].join(' '),
            color: SttusColor.STANDBY
        }
    }

    if (reviewSttus === null) {
        if (labelSttus === null) {  // 라벨링 작업전
            const moduleNm = getStore().getState().Label.Cmmn.module.name ?? undecidedLabelNm;
            displayMode = SttusDisplay.STANDBY;
            text = [moduleNm, GetMessageIntl(intl, 'details.standby')].join(' ');
            color = SttusColor.STANDBY;
        } else if (labelSttus === LabelSttus.WORKING) { // 라벨링 작업중
            displayMode = SttusDisplay.WORKING;
            text = [labelModuleNm, GetMessageIntl(intl, 'details.working')].join(' ');
            color = SttusColor.WORKING;
        } else if (labelSttus === LabelSttus.SUBMIT) {  // 검수 작업전
            displayMode = SttusDisplay.SUBMIT;
            text = [reviewModuleNm ?? GetMessageIntl(intl, 'details.undecidedReview'), GetMessageIntl(intl, 'details.standby')].join(' ');
            color = SttusColor.STANDBY;
        } else if (labelSttus === LabelSttus.SKIP) {    // 보류 검토필요
            displayMode = SttusDisplay.WORKING;
            text = [GetMessageIntl(intl, 'details.hold'), GetMessageIntl(intl, 'details.reviewRequired')].join(' ');
            color = SttusColor.REVIEW_REQUIRED;
        }
    } else if (reviewSttus === ReviewSttus.WORKING) {   // "00"
        if (labelSttus === LabelSttus.WORKING) {    // 라벨링 작업중
            displayMode = SttusDisplay.WORKING;
            text = [labelModuleNm, GetMessageIntl(intl, 'details.working')].join(' ');
            color = SttusColor.WORKING;
        } else if (labelSttus === LabelSttus.SUBMIT) {  // "03"
            if (reviewModuleNm) {   // 검수 작업중
                displayMode = SttusDisplay.SUBMIT;
                text = [reviewModuleNm, GetMessageIntl(intl, 'details.working')].join(' ');
                color = SttusColor.WORKING;
            } else {    // 검수 작업전
                if (nextTaskNm) {   // 검수 1차
                    displayMode = SttusDisplay.SUBMIT;
                    text = [nextTaskNm, GetMessageIntl(intl, 'details.standby')].join(' ');
                    color = SttusColor.STANDBY;
                }else { // 검수(미정) 작업전
                    displayMode = SttusDisplay.SUBMIT;
                    text = [undecidedReviewNm, GetMessageIntl(intl, 'details.standby')].join(' ');
                    color = SttusColor.STANDBY;
                }
            }
        }
    } else if (reviewSttus === ReviewSttus.PASS) {
        if (nextTaskNm === null) {    // 종료 완료
            displayMode = SttusDisplay.SUBMIT;
            text = [GetMessageIntl(intl, 'details.close'), GetMessageIntl(intl, 'details.done')].join(' ');
            color = SttusColor.DONE;
        } else if (nextTaskNm){   // n차 검수 작업전
            displayMode = SttusDisplay.SUBMIT;
            text = [nextTaskNm, GetMessageIntl(intl, 'details.standby')].join(' ');
            color = SttusColor.STANDBY;
        }
    } else if (reviewSttus === ReviewSttus.REJECT) {    // 라벨링 수정중 "04"
        if (lstTaskSttusCd === ReviewSttus.WORKING) {   // 라벨러가 제출한 것이므로 검수 재검중
            displayMode = SttusDisplay.SUBMIT;
            text = [reviewModuleNm, GetMessageIntl(intl, 'details.reworking')].join(' ');
            color = SttusColor.REWORKING;
        } else {    // 검수자가 마지막으로 반려한 것이므로 라벨러 수정중
            displayMode = SttusDisplay.SUBMIT;
            text = [labelModuleNm, GetMessageIntl(intl, 'details.modify')].join(' ');
            color = SttusColor.MODIFY;
        }
    }

    if (labelSttus === LabelSttus.SKIP) {
        displayMode = SttusDisplay.WORKING;
        text = [GetMessageIntl(intl, 'details.hold'), GetMessageIntl(intl, 'details.reviewRequired')].join(' ');
        color = SttusColor.REVIEW_REQUIRED;
    }

    let result = {displayMode, text, color};
    return result;
}


function getHistoryStyle(history, intl): HistoryStyle {
    let result = null;
    const {moduleId, moduleNm, datasetNm, taskTy, bindTy, taskSttusCd} = history;
    let subTitleText = null;
    let subText = null;
    let textPointColor = null;
    if (taskTy === '01') {
        textPointColor = HistoryUserColor.LABELER
    } else if (taskTy === '02') {
        textPointColor = HistoryUserColor.REVIEWR
    } else if (taskTy === '03') {
        textPointColor = HistoryUserColor.MANAGER
    }

    if (taskTy === null) {  // 파일 업로드
        subTitleText = datasetNm;
        return {
            ...HistoryColorSet.UPLOAD
            , textPointColor: null
            , subTitleText
            , titleText: GetMessageIntl(intl, 'details.histUpload')
            , subText
        };
    }
    if (taskSttusCd === '06') { // 종료 작업 완료
        return {
            ...HistoryColorSet.DONE
            , textPointColor: null
            , subTitleText
            , titleText: GetMessageIntl(intl, 'details.histDone')
            , subText
        }
    }

    if (taskTy === '01') {  // 라벨러
        subTitleText = moduleNm;
        if (bindTy === '01') {  // 할당
            result = {
                ...HistoryColorSet.LABELER
                , textPointColor
                , subTitleText
                , titleText: GetMessageIntl(intl, 'details.histLFileAssign')
                , subText
            }
        } else if (bindTy === '02') {   // 회수
            result = {
                ...HistoryColorSet.LABELER
                , textPointColor
                , subTitleText
                , titleText: GetMessageIntl(intl, 'details.histLFileRetrive')
                , subText
            }
        } else if (bindTy === null) {
            if (taskSttusCd === '03') { // 제출
                result = {
                    ...HistoryColorSet.LABELER
                    , textPointColor
                    , subTitleText
                    , titleText: GetMessageIntl(intl, 'details.histLFileSubmit')
                    , subText
                };
            }else if (taskSttusCd === '04') {   // 스킵
                subText = skipRsn(history);
                result = {
                    ...HistoryColorSet.LABELER
                    , textPointColor
                    , subTitleText
                    , titleText: GetMessageIntl(intl, 'details.histLFileSkip')
                    , subText
                }
            }
        }
    } else if (taskTy === '02') {   // 검수자
        subTitleText = moduleNm;
        if (bindTy === '01') {  // 할당
            result = {
                ...HistoryColorSet.REVIEWER
                , textPointColor
                , subTitleText
                , titleText: GetMessageIntl(intl, 'details.histRFileAssign')
                , subText
            }
        } else if (bindTy === '02') {   // 회수
            result = {
                ...HistoryColorSet.REVIEWER
                , textPointColor
                , subTitleText
                , titleText: GetMessageIntl(intl, 'details.histRFileRetrive')
                , subText
            }
        } else if (bindTy === null) {
            if (taskSttusCd === '03') { // 통과
                result = {
                    ...HistoryColorSet.REVIEWER
                    , textPointColor
                    , subTitleText
                    , titleText: GetMessageIntl(intl, 'details.histRFilePass')
                    , subText
                };
            }else if (taskSttusCd === '04') {   // 반려
                subText = rejectRsn(history, intl);
                result = {
                    ...HistoryColorSet.REVIEWER
                    , textPointColor
                    , subTitleText
                    , titleText: GetMessageIntl(intl, 'details.histRFileReject')
                    , subText
                }
            }
        }
    } else if (taskTy === '03') {   // 관리자
        if (bindTy === '03') { // 복구
            result = {
                ...HistoryColorSet.MANAGER
                , textPointColor
                , titleText: GetMessageIntl(intl, 'details.histMRecovery')
                , subText
            }
        } else if (bindTy === '04') { // 작업불가
            result = {
                ...HistoryColorSet.MANAGER
                , textPointColor
                , titleText: GetMessageIntl(intl, 'details.histMCheckUnable')
                , subText
            }
        }
    }
    return result;
}

function skipRsn(history: any) {
    const {skipReasonsList} = getRsnText();
    const json = JSON.parse(history.issueCn);
    const skipRsnObj = json.skipRsn;
    if (!skipReasonsList) return;
    const skipRsnTextList = skipRsnObj.rsnCd.reduce((acc, rsn) => {
        const rsnInfo = skipReasonsList.filter(r => r.cmmnDetailCd === rsn)[0];
        let text = rsn === '01' ? `${rsnInfo.cmmnDetailCdNm}(${skipRsnObj.rsnCn})`: rsnInfo.cmmnDetailCdNm;
        acc.push(text);
        return acc;
    }, [])
    return skipRsnTextList;
}

function rejectRsn(history, intl) {
    const {reviewReasonsList, labels, canvasDataTy} = getRsnText();
    const json = JSON.parse(history.issueCn);
    const review = new Review(null);
    let result = [];
    if (json) {
        if(canvasDataTy === CANVAS_DATA_TY.IMAGE_ANNOTATION ){
            review.createSavedData(json, [], true);
            const lastRejectRsn = review.getInstTotalData();
            const {className: lastRejectClassName} = review.getClassTotalData(labels);

            if (lastRejectRsn) {
                const instRejectRsnTextList = lastRejectRsn.filter(r => r !== '01').reduce((acc, rsn) => {
                    const rsnInfo = reviewReasonsList.filter(r => r.cmmnDetailCd === rsn)[0];
                    const text = rsnInfo.cmmnDetailCdNm
                    acc.push(text);
                    return acc;
                }, [])

                result = [...instRejectRsnTextList];
            }
            if (lastRejectClassName.length !== 0) {
                const classRejectRsn = GetMessageIntl(intl, 'details.classReject') + '(' + lastRejectClassName.join(', ')+')'
                result.push(classRejectRsn);
            }
        }else{
            if(json.classes){
                json.classes.map((classes)=>{
                    if(classes.reject){
                        const label = labels.find(label => label.id === classes.id)
                        result.push({question: label.name, classes: classes});
                    }
                })
            }
        }
    }

    return result;
}
function* getWorkflowAsync(action){
    sessionStorage.setItem('workflow', JSON.stringify(action.payload.workflow));
    const {module, frame} = action.payload;
    if (module && frame) {
        yield put(getModule({module, frame}));
    }
    yield take(GET_MODULE_SUCCESS)
    yield put(getWorkflowSuccess());
}
function* watchGetWorkflow(){
    yield takeLatest(GET_WORKFLOW, getWorkflowAsync)
}

function* UpdateSearchParam(action){
    const {type, param, view} = action.payload;
    const data = cloneDeep(getStore().getState().Details.searchParam.data);
    const viewData = cloneDeep(getStore().getState().Details.searchParam.view);
    switch (type) {
        case SearchFilter.DATA_STATUS:
            data.sttus = param;
            viewData.sttus = view;
            break;
        case SearchFilter.DATASET:
            data.dataset = param;
            viewData.dataset = view;
            break;
        case SearchFilter.FOLDER:
            data.folder = param;
            viewData.folder = view;
            break;
        case SearchFilter.WORKER:
            data.worker = param;
            viewData.worker = view;
            break;
        case SearchFilter.LAST_REJECT_REASONS:
            data.reject = param;
            viewData.reject = view;
            break;
        case SearchFilter.LAST_REJECT_REASONS_CLSFC:
            data.rejectClsfcSmry = param;
            viewData.rejectClsfcSmry = view;
            break;
        case SearchFilter.SKIP_REASONS:
            data.skip = param;
            viewData.skip = view;
            break;
        case SearchFilter.FILE:
            data.file = param;
            viewData.file = view;
            break;
    }
    yield put(updateSearchParamSuccess(data, viewData));
}
function* watchUpdateSearchParam() {
    yield takeLatest(UPDATE_SEARCH_PARAM, UpdateSearchParam)
}

function* getModuleByWorkflow(action){
    const {wrkflowId} = action.payload;
    try {
        const param = {wrkflowId}
        const result = yield DatasetDetailsService.getModules(param);
        const data = result.data;
        if (data.success){
            yield put(getModuleByWorkflowSuccess(cloneDeep(data.data)))
        }
    }catch (e) {
        yield put(getModuleByWorkflowFailed(e))
    }
}
function* watchGetModuleByWorkflow() {
    yield takeLatest(GET_MODULE_BY_WORKFLOW, getModuleByWorkflow)
}

function* getDatasetByWorkflow(action){
    const {wrkflowId} = action.payload;
    try {
        const param = {wrkflowId}
        const result = yield DatasetDetailsService.getDataset(param);
        const data = result.data;
        if (data.success){
            yield put(getDatasetByWorkflowSuccess(data.data))
        }
    }catch (e) {
        yield put(getDatasetByWorkflowFailed(e))
    }
}
function* watchGetDatasetByWorkflow() {
    yield takeLatest(GET_DATASET_BY_WORKFLOW, getDatasetByWorkflow)
}

function* getWorkerByWorkflowAsync(action){
    const {wrkflowId} = action.payload;
    try {
        const param = {wrkflowId}
        const result = yield DatasetDetailsService.getWorker(param);

        const data = result.data;
        if (data.success){
            yield put(getWorkerByWorkflowSuccess(data.data))
        }
    }catch (e) {
        yield put(getWorkerByWorkflowFailed(e))
    }
}
function* watchGetWorkerByWorkflow() {
    yield takeLatest(GET_WORKER_BY_WORKFLOW, getWorkerByWorkflowAsync)
}

function* getDataListAsync(action){
    try {
        const param = getStore().getState().Details.searchParam.data;
        param.wrkflowId = getStore().getState().Details.workflow.id;
        const result = yield DatasetDetailsService.getDataList(param)
        const data = result.data;
        if (data.success) {
            yield put(setDataList(data.data));
        }
    } catch (e) {

    }
}
function* watchGetDataList(){
    yield takeLatest(GET_DATA_LIST, getDataListAsync)
}

function* getFileInfoAsync(action) {
    const {moduleId, datasetId, fileSeq} = action.payload;
    const {intl} = action.hooks;
    try {
        const result = yield DatasetDetailsService.getFileInfo({moduleId, datasetId, fileSeq});
        const data = result.data;
        if (data.success) {
            const fileInfo = {
                ...data.data,
                regDtmText: data.data.regDtmText.replaceAll('-', '.'),
                rejectCount: data.data.rejctCount,
                mngConfirmCd: data.data.managerConfirmCd,
            }

            yield put(getFileInfoSuccess(fileInfo, intl));
        }
    } catch (e){
        yield put(getFileInfoFailed(e))
    }
}
function* watchGetFileInfo(){
    yield takeLatest(GET_FILE_INFO, getFileInfoAsync)
}

// 파일 정보 가져오기
function* getFileInfoSuccessAsync(action){
    const {labelSttus, reviewSttus, rejectCount, mngConfirmCd, labelModuleNm, reviewModuleNm, nextTaskNm
        , lbTaskSttusCd, nextTaskSttusCd, lstTaskSttusCd} = action.payload;
    const {intl} = action.hooks;
    const module = getStore().getState().Label.Cmmn.module;
    const {labels, labelType} = module;
    const sttus = getStatus(labelSttus, reviewSttus, rejectCount, mngConfirmCd, labelModuleNm, reviewModuleNm, nextTaskNm, lbTaskSttusCd, nextTaskSttusCd, lstTaskSttusCd, intl);
    const reviewData = getStore().getState().Review.Cmmn.annotations.data;  // 최신 검수 사유
    let lastRejectRsn, reject
    if (labelType === LABEL_TYPE.ANNOTATION) {
        let lastRejectRsn = reviewData.getInstTotalData();
        ({reject} = reviewData.getClassTotalData(labels));

        if (reject) {
            lastRejectRsn.push("11");
        }
    } else if (labelType === LABEL_TYPE.CLASSIFICATION_SUMMARY) {
        lastRejectRsn = [];
        if(reviewData.classes){
            reviewData.classes.map((classes)=>{
                if(classes.reject){
                    const label = labels.find(label => label.id === classes.id)
                    lastRejectRsn.push({question: label.question, type:label.type, classes: classes});
                }
            })
        }
    }

    yield put(setDataSttus({sttus, lastRejectRsn}));
}
function* watchGetFileInfoSuccess() {
    yield takeLatest(GET_FILE_INFO_SUCCESS, getFileInfoSuccessAsync)
}

// 우측 하단 패널, 최신 반려 사유 가져오기
function* getRejectRsnAsync(action){
    const {labelModuleId, datasetId, fileSeq} = action.payload;
    try {
        const states = getStore().getState().Label.Cmmn.annotations.states;
        const canvasDataTy = getStore().getState().Label.Cmmn.module.canvasDataTy;
        const labels = getStore().getState().Label.Cmmn.module.labels;
        const result = yield DatasetDetailsService.getLastRejectRsn({moduleId: labelModuleId, datasetId, fileSeq});
        const data = result.data;
        if (data.success) {
            let lastRejectRsn = [];
            const lastReviewData = data.data ? data.data.issueCnObj : null;
            const review = new Review(null);
            if(canvasDataTy === CANVAS_DATA_TY.IMAGE_ANNOTATION) {
                if (lastReviewData) {
                    review.createSavedData(lastReviewData, [], true);
                    lastRejectRsn = review.getInstTotalData();
                    const {reject} = review.getClassTotalData(labels);

                    if (reject) {
                        lastRejectRsn.push("11");
                    }
                    // yield put(getRejectRsnSuccess({lastRejectRsn}))
                }
            }else {
                if (lastReviewData) {
                    if(lastReviewData.classes){
                        lastReviewData.classes.map((classes)=>{
                            if(classes.reject){
                                const label = labels.find(label => label.id === classes.id)
                                lastRejectRsn.push({question: label.question, type:label.type, classes: classes});
                            }
                        })
                    }
                    // yield put(getRejectRsnSuccess({lastRejectRsn}))
                }
            }
            yield put(getRejectRsnSuccess({lastRejectRsn}))
        }
    } catch (e) {
        yield put(getRejectRsnFailed(e));
    }
}
function* watchGetRejectRsn(){
    yield takeLatest(GET_REJECT_RSN, getRejectRsnAsync)
}

function* getSkipRsnAsync(action){
    try {
        const {labelModuleId, datasetId, fileSeq} = action.payload;
        const result = yield DatasetDetailsService.getSkipRsn({moduleId: labelModuleId, datasetId, fileSeq});
        const data = result.data;
        if (data.success) {
            const issueCn = data.data ? JSON.parse(data.data.issueCn) : null;
            if (issueCn && issueCn.skipRsn) {
                const skipRsn = issueCn.skipRsn;
                yield put(getSkipRsnSuccess(skipRsn));
            }
        }
    } catch (e) {
        yield put(getSkipRsnFailed(e))
    }
}
function* watchGetSkipRsn(){
    yield takeLatest(GET_SKIP_RSN, getSkipRsnAsync)
}

function* recoveryDataAsync(action) {
    try {
        const {moduleId, datasetId, fileSeq} = action.payload;
        const {intl} = action.hooks;
        yield DatasetDetailsService.recovery({moduleId, datasetId, fileSeq});
        yield put(recoveryDataSuccess({moduleId, datasetId, fileSeq}, intl))
    } catch (e) {
        yield put(recoveryDataFailed(e))
    }
}
function* watchRecoveryData(){
    yield takeLatest(RECOVERY_DATA, recoveryDataAsync)
}
function* watchRecoveryDataSuccess(){
    yield takeLatest(RECOVERY_DATA_SUCCESS, getFileInfoAsync);
}

function* checkUnableWorkAsync(action) {
    try {
        const {moduleId, datasetId, fileSeq} = action.payload;
        const {intl} = action.hooks;
        yield DatasetDetailsService.checkUnable({moduleId, datasetId, fileSeq});
        yield put(checkUnableWorkSuccess({moduleId, datasetId, fileSeq}, intl))
    } catch (e) {
        yield put(checkUnableWorkFailed(e))
    }
}
function* watchCheckUnableWork() {
    yield takeLatest(CHECK_UNABLE_WORK, checkUnableWorkAsync)
}
function* watchCheckUnableWorkSuccess(){
    yield takeLatest(CHECK_UNABLE_WORK_SUCCESS, getFileInfoAsync);
}

function* getHistoryAsync(action) {
    const param = action.payload;
    const {intl} = action.hooks;
    try {
        const result = yield DatasetDetailsService.getHistory(param);
        const data = result.data;
        if (data.success) {
            const history = data.data;
            let displayList = [];
            history.map((hItem) => {
                const style = getHistoryStyle(hItem, intl);
                hItem.style = style;
                displayList.push(hItem)
            })
            yield put(getHistorySuccess(history, displayList))
        }
    } catch (e) {
        yield put(getHistoryFailed(e))
    }
}
function* watchGetHistory() {
    yield takeLatest(GET_HISTORY, getHistoryAsync)
}

function* getJsonAsync(action){
    try {
        const {moduleId, datasetId, fileSeq} = action.payload;

        const result = yield LabelCmmnService.jsonPreview({moduleId, datasetId, fileSeq});
        const data = result.data;
        if (data.success) {
            const previewJson = JSON.stringify(JSON.parse(data.data), undefined, 4);
            yield put(getJsonSuccess(previewJson === '{}' ? null : previewJson))
        }
    } catch (e){
        yield put(getJsonFailed(e))
    }
}
function* watchGetJson(){
    yield takeEvery(GET_JSON, getJsonAsync)
}


function* DetailsSaga(){
    yield all([
        watchGetWorkflow(),
        watchUpdateSearchParam(),
        watchGetModuleByWorkflow(),
        watchGetDatasetByWorkflow(),
        watchGetWorkerByWorkflow(),
        watchGetDataList(),
        watchGetFileInfo(),
        watchGetFileInfoSuccess(),
        watchGetRejectRsn(),
        watchGetSkipRsn(),
        watchRecoveryData(),
        watchRecoveryDataSuccess(),
        watchCheckUnableWork(),
        watchCheckUnableWorkSuccess(),
        watchGetHistory(),
        watchGetJson()
    ])
}

export default DetailsSaga;
