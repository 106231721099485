// @ts-nocheck
import {Button, Popover, PopoverBody, PopoverHeader} from "reactstrap";
import React, {useEffect, useState} from "react";
import {
    EditImagePopoverComponent
} from '../../components/standard-workspace/controls-side-bar/EditImageControl';
import {useDispatch, useSelector} from "react-redux";
import {updateCanvasEditImagePopover} from "../../../../../../../store/label/action";

interface Props {

}
export function EditImageControl(props: Props) {
    const dispatch = useDispatch();
    const visible = useSelector(state => state.Label.Cmmn.canvas.editImagePopover.visible);

    const showPopover = () => {
        dispatch(updateCanvasEditImagePopover(true));
    }

    const hidePopover = () => {
        dispatch(updateCanvasEditImagePopover(false));
    }

    const onOtherAreaClick = (ev: MouseEvent) => {
        ev.stopPropagation();
        hidePopover();
    }

    useEffect(()=> {
        if (visible) {
            document.getElementById('root').addEventListener('click', onOtherAreaClick);
        }
        return () => {
            document.getElementById('root').removeEventListener('click', onOtherAreaClick);
        }
    }, [visible])

    return(
        <>
            <Button color={"coco-ghost"}
                    className="btn-icon waves-effect waves-light rounded-pill labelPopover"
                    id="editImage"
                    onClick={ visible ? hidePopover : showPopover }
            >
                <i className="ri-image-edit-line fs-20"></i>
            </Button>
            <Popover
                className={"labelToolPopover"}
                target="editImage"
                placement="right"
                isOpen={visible}
            >
                <EditImagePopoverComponent />
            </Popover>
        </>
    )
}
