import {ObjectReviewDetails as ObjectReviewDetailsComponents} from "../../../components/standard-workspace/objects-side-bar/object-review-details";
import {connect} from "react-redux";
import ReviewInstance from "../../../work/core/review-instance";

interface OwnProps {
    clientID: number;
    sortedStatesID: number[];
    onOpenCanvasContextMenu(e): void;
}
interface StateToProps {
    rejectRsn: any;
    instData: ReviewInstance;
    choiceCdList: string[];
    reject: boolean | null;
}

interface DispatchToProps {
}

function mapStateToProps(state, own: OwnProps): StateToProps {
    const {clientID} = own;
    const {
        Review: {
            Cmmn: {
                annotations: {
                    reasons: rejectRsn,
                    data: {
                        instance: instDataList
                    }
                }
            }
        }
    } = state;

    const instData = instDataList[clientID]
    const choiceCdList = instData.rsnCd;
    const reject = instData.reject;
    return {
        rejectRsn,
        instData,
        choiceCdList,
        reject
    };
}

function dispatchToProps(dispatch: any): DispatchToProps {
    return {};
}

export default connect(mapStateToProps, dispatchToProps)(ObjectReviewDetailsComponents);
