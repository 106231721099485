// Copyright (C) 2020-2022 Intel Corporation
//
// SPDX-License-Identifier: MIT
// @ts-nocheck
// import { BoundariesActions, BoundariesActionTypes } from 'cvat/actions/boundaries-actions';
// import { AuthActions, AuthActionTypes } from 'cvat/actions/auth-actions';
import { KeyMap, KeyMapItem } from 'pages/user/label/annotation/image/utils/mousetrap-react';
import { DimensionType, ShortcutsState } from '../label';
import {SWITCH_SHORTCUT_DIALOG} from "./actionType";
import {Category, ImageAnnotatingKeyMap} from "./index";
import {GET_MODULE_SUCCESS} from "../label/actionType";

function formatShortcuts(shortcuts: KeyMapItem): string {
    const list: string[] = shortcuts.displayedSequences || (shortcuts.sequences as string[]);

    return list[0].split("+")
            .map(key => {
                switch (key){
                    case "plus":
                        return '+';
                    case "left":
                        return '←';
                    case "right":
                        return '→';
                    case "up":
                        return '↑';
                    case "down":
                        return '↓';
                    default:
                        return key.replace(/^[a-z]/, char => char.toUpperCase())
                }
                // return key === "plus" ? '+' : key.replace(/^[a-z]/, char => char.toUpperCase());
            });
    // return `[${list
    //     .map((shortcut: string): string => {
    //         let keys = shortcut.split('+');
    //         keys = keys.map((key: string): string => `${key ? key[0].toUpperCase() : key}${key.slice(1)}`);
    //         keys = keys.join('+').split(/\s/g);
    //         keys = keys.map((key: string): string => `${key ? key[0].toUpperCase() : key}${key.slice(1)}`);
    //         return keys.join(' ');
    //     })
    //     .join(', ')}]`;
}

const INIT_STATE: ShortcutsState = {
    visibleShortcutsHelp: false,
    keyMap: ImageAnnotatingKeyMap,  // 실제 action keymap
    normalizedKeyMap: Object.keys(ImageAnnotatingKeyMap).reduce((acc: Record<string, string>, key: string) => { // ui에서 보여질 것
        const normalized = formatShortcuts(ImageAnnotatingKeyMap[key]);
        acc[key] = normalized;
        return acc;
    }, {}),
};

export default (state = INIT_STATE, action): ShortcutsState => {
    switch (action.type) {
        // case GET_MODULE_SUCCESS: {
        //     const {
        //         labelType,
        //         dataType
        //     } = action.payload;
        //     // todo: dataType에 따른 shortcuts keyMap 바꿔주기
        //     return {
        //         ...state,
        //         keyMap,
        //         normalizedKeyMap
        //     }
        // }
        case SWITCH_SHORTCUT_DIALOG: {
            return {
                ...state,
                visibleShortcutsHelp: !state.visibleShortcutsHelp,
            };
        }
        // case BoundariesActionTypes.RESET_AFTER_ERROR:
        // case AuthActionTypes.LOGOUT_SUCCESS: {
        //     return { ...defaultState };
        // }
        default: {
            return state;
        }
    }
};
