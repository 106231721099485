import React from 'react';
import SimpleBar from 'simplebar-react';
import styled from "styled-components";


const TipBox = styled.div`
      background-color: #FFFCDD;
      width: 540px;
      //height: 108px;
      border-radius: 7px;
      padding: 16px;
      gap: 16px;
      margin-bottom: 1rem;
    `

export const WorkTipView = (props) => {
    const htmlMessage = props.workTip.replace(/\n/g, '<br/>');
    return (
        <>
            <TipBox>
                <SimpleBar
                    className="pe-2"
                    style={{maxHeight: "100px", width: "100%", alignSelf: "center"}}
                >
                    <span className={"text-coco-danger-400 tx-16 fw-500"}>
                        *작성 TIPs
                    </span>
                    <div
                        className="fs-16"
                        dangerouslySetInnerHTML={{__html: htmlMessage}}
                    />
                </SimpleBar>
            </TipBox>
        </>
    );
}