// @ts-nocheck
import {MD_TY_CD, ShapeIcon} from "../const";
import React, {useEffect} from "react";
import {LabelType} from "pages/user/label/annotation/image/work/core/enums";
import {GetMessage} from "../../../../../../util/message";

interface Props{
    moduleType: MD_TY_CD;
    reject: boolean | null;
    completedError: boolean;
    touch: boolean;
    className: String;
    classType: LabelType;    // ShapeType;
    totalCount: number;
}

function ClassesItemComponent(props: Props): JSX.Element {
    const { moduleType, reject, completedError, touch, className, classType, totalCount, viewID } = props;

    return (
        <div className="card-event wd-80p d-flex align-items-center" data-bs-toggle="collapse" aria-expanded="false" id={`label-item-${viewID}`}>
            <div className="wd-20p d-flex align-items-center justify-content-center">
                {
                    classType===LabelType.ANY?
                        <>ANY</>
                    : (<i className={"regionType "+(ShapeIcon[classType])}></i>)
                }
            </div>
            <div className="wd-70p d-flex align-items-center">
                <div className="text-left ps-2 className text-overflow" style={{width: "170px"}}>
                    {className}
                    { moduleType === MD_TY_CD.LABEL
                        ? (reject === true
                            ? (completedError
                                ? (touch
                                    ? <div className="badge badge-coco badge-coco-default-gray ml-10">{GetMessage('ui.U00013')}</div>   // 그레이 '수정'
                                    : <div className="badge badge-coco badge-coco-soft-red ml-10">{GetMessage('ui.U00066')}</div>)      // 빨강 '반려'
                                : <div className="badge badge-coco badge-coco-soft-yellow ml-10">{GetMessage('ui.U00013')}</div>)       // 노랑 '수정'
                            : <></>)
                        : <></>
                    }
                    { moduleType === MD_TY_CD.LABEL && reject === false && <div className="badge badge-coco badge-coco-soft-skyblue ml-10">{GetMessage('label.pass')}</div> }
                </div>
                <span className="badge reviewBadge ms-2 d-none"></span>
            </div>
            <div className="wd-10p text-center objCnt">{totalCount}</div>
        </div>
    )
}
export default React.memo(ClassesItemComponent);
