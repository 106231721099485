import React from 'react';
import Highlighter from "react-highlight-words";

function Highlight(props) {
    return (
        <Highlighter
            searchWords={props.highlightWords}
            autoEscape={true}
            textToHighlight={props.targetText}
            highlightStyle={{padding : "0px", backgroundColor : "#FEFFB7"}}
        />
    );
}

export default Highlight;