// @ts-nocheck
import {MD_STTUS_CD, MD_TY_CD} from "../../../const";
import {useIntl} from "react-intl";
import {GetMessageIntl} from "../../../../../../../../util/message";

interface Props {
    disabled: boolean;
    onClick(e): void;
    moduleType: MD_TY_CD;
    moduleStatus: MD_STTUS_CD;
}
export function ObjectReviewFooter(props: Props) {
    const { disabled, onClick, moduleType, moduleStatus, editable } = props;
    const intl = useIntl();

    return (
        <>
            <div className="d-flex justify-content-end">
                <button type="button" className="btn btn-coco-ghost-1 fw-500"
                        disabled={disabled}
                        onClick={(e) => onClick(e)}
                >
                    {moduleType === MD_TY_CD.REVIEW
                        ? moduleStatus === MD_STTUS_CD.FIRST
                            ?(GetMessageIntl(intl, 'ui.U00008'))
                            : GetMessageIntl(intl, 'review.recheckCompleted')
                        : editable === true
                            ? GetMessageIntl(intl, 'label.modifyCompleted')
                            : GetMessageIntl(intl, 'ui.U00013')
                    }
                </button>
            </div>
        </>
    )
}
