import React, {useEffect} from "react";
import {layoutTypes} from "../../../components/constants/layout";
import {Workspace} from "store/label";
import {useHistory} from "react-router";
import {useDispatch} from "react-redux";
import {changeFrame, closeModule, getModule} from "../../../store/label/action";
import {alertError} from "../../../util/AlertUtil";
import {useIntl} from "react-intl";
import {LABEL_TYPE} from "./annotation/image/const";
import {AnnotationPage} from "./annotation/image/components/AnnotationPage";
import {ClsfcSmryWorkspace} from "./clsfcSmry/ClsfcSmryWorkspace";

interface Props{
    moduleId: string;
    module: any | null | undefined;
    datasetId: string;
    fileSeq: string;
    fetching: boolean;
    workspace: Workspace;
    labelType: LABEL_TYPE;
    // @ts-ignore
    layoutType: layoutTypes;
    getModule(mid: string): void;
}
export default function WorkPage(props: Props) {
    const { module, fetching, workspace, labelType, layoutType, moduleId, datasetId, fileSeq,
    } = props;
    const dispatch = useDispatch();
    const history = useHistory();
    const intl = useIntl();

    useEffect(() => {
        // saveLogs();
        const root = window.document.getElementById('root');
        if (root) {
            root.style.minHeight = '720px';
        }

        return () => {
            // saveLogs();
            if (root) {
                root.style.minHeight = '';
            }

            if (!history.location.pathname.includes('/label')) {
                dispatch(closeModule());
            }
        };
    }, []);

    useEffect(() =>{
        if (module === null && !fetching) {
            let dev = true;
            const sModule = JSON.parse(sessionStorage.getItem('module'));
            const sFrame = JSON.parse(sessionStorage.getItem('frame'));
            if (!sModule || !sFrame) {  // 브라우저 '앞으로 이동'시, 저장된 세션이 없다.
                // console.log('모듈 없음', history)
                alertError(
                    'alert.invalidAccess',
                    'alert.invalidAccessMsg',
                    () => {
                        history.goBack();
                    },null, null, intl
                )
                // history.goBack();
                return;
            }
            if(dev){
                dispatch(getModule({module: sModule, frame: sFrame}));
            } else {
                dispatch(getModule(sModule));
            }
        }
    }, [module, fetching]);

    return(
        <>
            {!(!module && !fetching) ?
                <div id={"clcl"}>
                    {labelType === LABEL_TYPE.ANNOTATION &&
                        <>
							<AnnotationPage workspace={workspace} />
                        </>
                    }
                    {labelType === LABEL_TYPE.CLASSIFICATION_SUMMARY &&
                        <>
							<ClsfcSmryWorkspace />
                        </>
                    }
                </div>
                : <></>
            }
        </>

    )
}
