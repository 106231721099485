import React, {useEffect, useRef, useState} from 'react';
import BreadCrumb from "../../components/common/BreadCrumb";
import {
    Card,
    CardHeader,
    Col,
    Container,
    Input,
    Row,
    Label,
    CardBody,
    DropdownToggle,
    DropdownMenu,
    DropdownItem, UncontrolledDropdown, ButtonGroup
} from "reactstrap";
import {useDispatch, useSelector} from "react-redux";
import {
    board_setRowData,
    board_faqSwitchViewModalFlag,
    board_faqSwitchAddModalFlag,
    board_faqSetModalMode,
    board_switchRefreshFlag,
    faq_setSearchParam,
    faq_setCnTy, faq_setCnTitle, faq_setCurrentPage
} from '../../store/board/action';
import button from "bootstrap/js/src/button";
import ReactPaginate from "react-paginate";
import {BoardService} from "../../apis/api/BoardService";
import PageLeftInfomation from "../../components/common/PageLeftInfomation";
import {GetMessage, GetMessageIntl} from "../../util/message";
import Select from "react-select";
import {defaultSelectStyle} from "../../components/constants/select";
import {AgGridReact} from "ag-grid-react";
import {AgRowStyle} from "../../components/common/AgGridCustomStyle";
import FaqAddModal from "./FaqAddModal";
import FaqView from "./FaqView";
import {BoardUtil} from "../../util/BoardUtil";
import {alertSuccess, confirm} from "../../util/AlertUtil";
import {useIntl} from "react-intl";
import {useHistory} from "react-router-dom";

function BoardFaqList() {
    const dis = useDispatch();
    const intl = useIntl();
    const selectInputRef = useRef(null);
    const coconutUserInfo = JSON.parse(sessionStorage.getItem("coconutUserInfo"));
    const userRole = coconutUserInfo.userRole;

    const cnTyOptions = [
        {
            "label": GetMessageIntl(intl, "board.allFaqTy"),
            "value": ""
        },
        {
            "label": GetMessageIntl(intl, "board.task"),
            "value": "01"
        },
        {
            "label": GetMessageIntl(intl, "board.point"),
            "value": "02"
        },
        {
            "label": GetMessageIntl(intl, "board.platform"),
            "value": "03"
        },
        {
            "label": GetMessageIntl(intl, "board.userInfo"),
            "value": "04"
        }
    ]

    const {
        modalFlag,
        viewModalFlag,
        searchParam,
        cnTitle,
        cnTy,
    } = useSelector((state) => ({
        modalFlag: state.Board.faqAddModalFlag,
        viewModalFlag : state.Board.faqViewModalFlag,
        searchParam : state.Board.faqSearchParam,
        cnTitle : state.Board.faqCnTitle,
        cnTy: state.Board.faqCnTy
    }))
    const [tmpSavedCn, setTmpSavedCn] = useState([]);

    useEffect(()=>{
        if(userRole === '01'){
            getTmpSaveData().then(r => {
                console.log("get tmp -> ", tmpSavedCn)
            });
        }
    }, [modalFlag])

    useEffect(()=>{
        if(viewModalFlag === true){
            dis(board_faqSwitchViewModalFlag())
        }
        if(modalFlag === true){
            dis(board_faqSwitchAddModalFlag())
        }
    }, [])

    const getTmpSaveData = async () => {
        BoardService.getTmpSaveData({
            'boardSeq': 2,
        }).then(re => {
            if(re.data.data){
                setTmpSavedCn(re.data.data);
            }
        })
    }

    /*
        TITLE 오른쪽 메인 버튼 컴포넌트
        페이지마다 유동
     */
    const ButtonComponent = () => {
        return (
            <button type="button" className="btn btn-coco-1"
                    onClick={() => {
                        dis(board_faqSwitchAddModalFlag())
                    }}
            >
                <i className="ri-add-line align-middle me-1"></i>
                {GetMessage("board.addFaq")}
            </button>
        )
    }

    // 기존 State 초기화 -> Param 재저장
    const handleSearch = () => {
        dis(faq_setCurrentPage(1));
        let obj = {};
        if (cnTitle !== "") {
            obj.CN_TITLE = cnTitle;
        }
        if (cnTy !== ""){
            obj.CN_TY = cnTy;
        }
        dis(faq_setSearchParam(obj));
    }

    const clickedDropdown = (e) => {
        const cnSeq = e.target.getAttribute('cnseq');
        const tmpCn = tmpSavedCn.filter((cn) => cn.cnSeq === parseInt(cnSeq)).map(cn => {
            return cn;
        })
        dis(board_setRowData(tmpCn[0]));
        dis(board_faqSetModalMode("T"));
        dis(board_faqSwitchAddModalFlag());
    }

    // Input, Select 등 State로 조정
    const handleReset = () => {
        dis(faq_setSearchParam({}));
        dis(faq_setCnTy(""));
        dis(faq_setCnTitle(""));
        dis(faq_setCurrentPage(1))
    }

    return (
        <>
            <div className="page-content">
                <Container fluid>
                    {
                        viewModalFlag === false ?
                            <>
                                <BreadCrumb title="FAQ" pageTitle="Laflow" ButtonComponent={userRole === '01' ? ButtonComponent : undefined}/>
                                <Row>
                                    <Card className="shadow-none mg-b-16">
                                        <CardHeader className="border-0 rounded p-0">
                                            <Row className="g-3">
                                                <Col xxl={3} xl={3}>
                                                    <div className="position-relative form-search">
                                                        <Input type="text"
                                                               className="form-control"
                                                               value={cnTitle}
                                                               onChange={(e) => {
                                                                   dis(faq_setCnTitle(e.target.value));
                                                               }}
                                                               id="search-input"
                                                               placeholder={GetMessage("board.searchPlaceHolder")} />
                                                        <span className="ri-search-line search-widget-icon"></span>
                                                        <span className="ri-close-line search-widget-icon search-widget-icon-close d-none" id="search-close-icon"></span>
                                                    </div>
                                                </Col>
                                                <Col  xxl={2} xl={2}>
                                                    <Select
                                                        styles={defaultSelectStyle}
                                                        className="mb-0"
                                                        ref={selectInputRef}
                                                        onChange={(e) => {
                                                            dis(faq_setCnTy(e.value));
                                                        }}
                                                        value={(cnTyOptions || []).find(item => item.value === cnTy) || ""}
                                                        options={cnTyOptions}
                                                        isSearchable={false}
                                                    />
                                                </Col>
                                                <Col xl={1} className={"align-self-center"} style={{maxWidth:"75px"}}>
                                                    <div className={"d-flex"}>
                                                        <button className="btn btn-coco-outline-1"
                                                                onClick={(e) => {handleSearch()}}>
                                                            {GetMessage("common.apply")}
                                                        </button>
                                                    </div>
                                                </Col>
                                                <Col className={"pd-0 align-self-center"}>
                                                    <div className={"d-flex"}>
                                                        <i  className="ri-refresh-line text-coco-gray-200 fs-24 align-self-auto"
                                                            onClick={()=>{handleReset()}
                                                            }></i>
                                                    </div>
                                                </Col>
                                                <Col className="pd-0 w-100 right" style={{textAlign:"right"}}>
                                                    {
                                                        userRole === '01' ?
                                                            <ButtonGroup>
                                                                <UncontrolledDropdown>
                                                                    <DropdownToggle tag="button" className="btn">
                                                                        {GetMessageIntl(intl, "board.tempSave")} (<span className="text-coco-danger-900">{tmpSavedCn.length}</span>)
                                                                    </DropdownToggle>
                                                                    <DropdownMenu container={'body'}>
                                                                        <DropdownItem>{GetMessageIntl(intl, "board.tempSave")}</DropdownItem>
                                                                        <DropdownItem divider />
                                                                        {
                                                                            tmpSavedCn.map((cn, idx) => {
                                                                                return(
                                                                                    <DropdownItem key={idx} cnseq={cn.cnSeq} onClick={clickedDropdown}>{cn.regDtmText} {cn.cnTitle}</DropdownItem>
                                                                                )
                                                                            })
                                                                        }
                                                                    </DropdownMenu>
                                                                </UncontrolledDropdown>
                                                            </ButtonGroup> : undefined
                                                    }
                                                </Col>
                                            </Row>
                                        </CardHeader>
                                    </Card>
                                    <DataList searchParam={searchParam}/>
                                </Row>
                            </>
                            : <FaqView/>
                    }
                </Container>
                <FaqAddModal modalFlag={modalFlag} cnTyOptions={cnTyOptions}/>
            </div>
        </>
    );
}

function DataList(props) {
    const intl = useIntl();
    const dis = useDispatch();
    const history = useHistory();

    const coconutUserInfo = JSON.parse(sessionStorage.getItem("coconutUserInfo"));
    const userRole = coconutUserInfo.userRole;

    const [datasets, setDatasets] = useState();
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);

    const [perPage, setPerPage] = useState(10);
    const [defaultColDef] = useState({
        autoHeight: true,
        resizable: true,
        cellClass: 'cell-wrap-text',
    })

    const {
        modalFlag,
        refreshFlag,
        searchParam,
        currentPage
    } = useSelector((state) => ({
        modalFlag : state.Board.faqAddModalFlag,
        refreshFlag : state.Board.refreshFlag,
        searchParam : state.Board.faqSearchParam,
        currentPage : state.Board.faqCurrentPage
    }));
    const [columnDefs] = useState([
        {
            field: 'idx',
            headerName: 'No',
            cellClass: "firstCell",
            flex : '5%'
        },
        {
            field: 'cnTy',
            headerName:  GetMessageIntl(intl, "board.ty"),
            cellClass: "firstCell",
            flex : '8%',
            cellRenderer : params => {
                return (
                    <>
                        {BoardUtil.convertCnTyToLabel(params.value)}
                    </>
                );
            }
        },
        {
            field: 'cnTitle',
            headerName: GetMessage("board.title"),
            flex : '50%'
        },
        {field: 'userNm', headerName:  GetMessageIntl(intl, "board.writer"), flex : '10%'},
        {field: 'regDtmText', headerName:  GetMessageIntl(intl, "common.regDate"), flex : '20%'},
        {field: 'readCnt', headerName:  GetMessageIntl(intl, "board.readCnt"), flex : '7%'},
    ]);

    function calStartIdx(page) {
        return (page - 1) * perPage;
    }

    const fetchData = async (page, size = perPage) => {
        setLoading(true);

        const res = await BoardService.getFaqList({
            'start': calStartIdx(page),
            'length': size,
            'orderParam': [],
            'searchParam': searchParam
        });
        if (res.data.data) {
            setDatasets(res.data.data.data);
            setTotalRows(res.data.data.recordsTotal);
            if(res.data.data.data.length === 0){
                setDatasets([]);
            }
        }
        setLoading(false);
    };

    useEffect(() => {
        let page = 1;
        if(currentPage > 1){
            page = currentPage
        }
        dis(faq_setCurrentPage(page));
        fetchData(page);
    }, [searchParam, modalFlag, refreshFlag]);

    const handlePageChange = page => {
        let pageIdx = page.selected + 1;
        fetchData(pageIdx);
        dis(faq_setCurrentPage(pageIdx));
    };
    const handleRowClick = (params) => {
        if(userRole === '01'){
            history.push({
                pathname: '/platform/board/faq',
            });
        }else if(userRole === '20'){
            history.push({
                pathname: '/user/board/faq',
            });
        }else{
            history.push({
                pathname: '/manager/board/faq',
            });
        }
        // View Component 보여주는 Flag State 변경
        dis(board_faqSwitchViewModalFlag());
    }

    const deleteBoard = async (rData) => {
        const data = new FormData();
        data.append("cnSeq", rData.cnSeq);
        data.append("mode", "D");
        data.append("boardSeq", 2);

        BoardService.setContent(data, {contentType : 'multipart/form-data'})
            .then((res) => {
            })
            .catch((err)=>{
                const data = new FormData();
            })
            .finally(()=> {
                dis(board_faqSetModalMode("I"));
            });
    }

    const handleCellClick = (params) => {
        dis(board_setRowData(params.data));
        if(!(params.colDef.field === "action")){
            handleRowClick(params);
        }
    }

    const handleDetailEvent = (params) => {
        handleRowClick(params)
    }
    const handleModifyEvent = (params) => {
        dis(board_faqSetModalMode("U"));
        dis(board_faqSwitchAddModalFlag());
    }

    const handleDeleteEvent = (params) => {
        confirm("board.askDeleteYn"
            , "board.warningDelete"
            ,()=>{
                deleteBoard(params.data).then(re => {
                    alertSuccess("board.successDelete", "board.successDeleteMessage", ()=>{
                        dis(board_switchRefreshFlag())
                    }, null, intl)
                })
            }
            ,null
            , null
            , intl);
    }

    const [adminColumnDefs] = useState([
        {
            field: 'idx',
            headerName: 'No',
            cellClass: "firstCell",
            flex : '10%'
        },
        {
            field: 'cnTy',
            headerName: GetMessage("board.ty"),
            cellClass: "firstCell",
            flex : '10%',
            cellRenderer : params => {
                return (
                    <>
                        {BoardUtil.convertCnTyToLabel(params.value)}
                    </>
                );
            }
        },
        {
            field: 'cnTitle', flex : "37%",
            headerName: GetMessage("board.title"),
        },
        {field: 'userNm', headerName:  GetMessageIntl(intl, "board.writer"), flex : '15%'},
        {field: 'regDtmText', headerName:  GetMessageIntl(intl, "common.regDate"), flex : "18%"},
        {field: 'readCnt', headerName:  GetMessageIntl(intl, "board.readCnt"), flex : "10%"},
        {
            field: 'action',
            headerName:  GetMessageIntl(intl, "common.action"),
            cellClass: "lastCell",
            flex : "10%",
            cellRenderer: params => {
                return(
                    <div className={"actionCell"}>
                        <UncontrolledDropdown>
                            <DropdownToggle
                                href="#"
                                className="btn-soft-secondary btn-sm dropdown"
                                tag="button"
                                style={{lineHeight: "17px"}}
                            >
                                <i className="ri-more-fill align-middle"></i>
                            </DropdownToggle>
                            <DropdownMenu className="dropdown-menu-end" style={{lineHeight: "17px"}} container={'body'}>
                                <DropdownItem className="dropdown-item"
                                              href="#"
                                              onClick={handleDetailEvent}
                                >
                                    {GetMessageIntl(intl, "board.detail")}
                                </DropdownItem>
                                <DropdownItem
                                    className="dropdown-item edit-item-btn"
                                    href="#"
                                    onClick={handleModifyEvent}
                                >
                                    {GetMessageIntl(intl, "board.modify")}
                                </DropdownItem>
                                <DropdownItem
                                    className="dropdown-item remove-item-btn"
                                    href="#"
                                    onClick={()=>{handleDeleteEvent(params)}}
                                >
                                    {GetMessageIntl(intl, "board.delete")}
                                </DropdownItem>
                            </DropdownMenu>
                        </UncontrolledDropdown>
                    </div>
                )
            }
        },
    ]);
    const gridOption = {
        suppressCellFocus : true,
    }

    let count = Math.ceil(Number((totalRows / perPage)));
    // 0인경우 console에 warning출력
    if(count === 0){
        count = 1;
    }

    return (
        <>
            <Card className="shadow-none">
                <CardBody className="px-0 pb-0">
                    <div className="ag-theme-alpine" style={{width: "100%"}}>
                        <AgGridReact
                            rowData={datasets}
                            rowStyle={AgRowStyle}
                            defaultColDef={defaultColDef}
                            columnDefs={(userRole === '01') ? adminColumnDefs : columnDefs}
                            pagination={true}
                            paginationPageSize={perPage}
                            suppressPaginationPanel={true}
                            detailRowAutoHeight={true}
                            suppressRowTransform={true}
                            domLayout={'autoHeight'}
                            onCellClicked={handleCellClick}
                            gridOptions={gridOption}
                            // onGridReady={(params)=>{
                            //     params.api.sizeColumnsToFit();
                            // }}
                        >
                        </AgGridReact>
                        <Row className='mx-0'>
                            <Col className='d-flex justify-content-start align-items-center fs-16 text-coco-gray-700 fw-500' sm='6'>
                                <PageLeftInfomation
                                    totalRows={totalRows}
                                    perPage ={perPage}
                                    currentPage={currentPage}
                                />
                            </Col>
                            <Col className='d-flex justify-content-end align-items-center' sm='6'>
                                <ReactPaginate
                                    previousLabel={'←'}
                                    nextLabel={'→'}
                                    onPageChange={handlePageChange}
                                    pageCount={count}
                                    forcePage={currentPage - 1}
                                    activeClassName={'active'}
                                    pageClassName={'page-item'}
                                    nextLinkClassName={'page-link'}
                                    nextClassName={'page-item next'}
                                    previousClassName={'page-item prev'}
                                    previousLinkClassName={'page-link'}
                                    pageLinkClassName={'page-link'}
                                    breakClassName='page-item'
                                    breakLinkClassName='page-link'
                                    containerClassName={'pagination react-paginate pagination-custom pagination-sm justify-content-end align-items-center'}
                                />
                            </Col>
                        </Row>
                    </div>
                </CardBody>
            </Card>
        </>
    );
}

export default BoardFaqList;
