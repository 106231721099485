import {DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown} from "reactstrap";
import {CustomSlider} from "../../../../../../../components/common/CustomSlider";
import React from "react";
import {GetMessage} from "../../../../../../../util/message";

interface Props{
    volume: number;
    onChangeVolume(value): void;
}
export const VolumeController = (props: Props): JSX.Element => {
    const {volume, onChangeVolume} = props;
    const verticalLabels = {
        0: 'Min',
        1: 'Max'
    };
    return (
        <>
            <UncontrolledDropdown direction={"up"}>
                <DropdownToggle tag="div" type={"button"}
                                className={"d-flex justify-content-center align-items-center "
                                    + (volume===0?"ri-volume-mute-fill": "ri-volume-up-fill") + " fs-24"}/>
                <DropdownMenu className={"volume dropdown-menu-center"}>
                    <DropdownItem header>{GetMessage("label.volumeControl")}</DropdownItem>
                    <DropdownItem divider/>
                    <div style={{paddingLeft: "7px"}}>
                        <CustomSlider
                            min={0}
                            max={1}
                            value={volume}
                            step={0.01}
                            labels={verticalLabels}
                            orientation='vertical'
                            onChange={(value: number)=>{
                                onChangeVolume(value);
                            }}
                            className={"m-0"}
                        />
                    </div>
                </DropdownMenu>
            </UncontrolledDropdown>
        </>
    )
}
