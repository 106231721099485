import {
    SET_SELECTED_FILE,
    SET_FILES,
    SET_SHAPE_CONFIG,
    SET_FILE_ATTRIBUTES,
    SET_FILE_LABELS,
    REMOVE_FILE,
    SET_FILE_COMMENT,
    SET_HAS_ERROR_FILES,
    CLEAR_HAS_ERROR_FILES,
    CLEAR_HAS_ERROR_FILE,
    SET_FILE_REJECT_REASON,
    RESET_FILE_LABELS
} from "./actionType";

export const workflow_label_testConfig_setSelectedFile = selectedFile => ({
    type: SET_SELECTED_FILE,
    payload: selectedFile
});
export const workflow_label_testConfig_setFiles = (payload) => ({
    type: SET_FILES,
    payload: payload
});
export const workflow_label_testConfig_setHasErrorFiles = (payload) => ({
    type: SET_HAS_ERROR_FILES,
    payload: payload
});

export const workflow_label_testConfig_clearHasErrorFiles = (payload) => ({
    type: CLEAR_HAS_ERROR_FILES,
    payload: []
});

export const workflow_label_testConfig_clearHasErrorFile = (fileName) => ({
    type: CLEAR_HAS_ERROR_FILE,
    payload: {fileName}
});
export const workflow_label_testConfig_setFileLabels = (fileName, data, mode, comment, rejectReason) => ({
    type: SET_FILE_LABELS,
    payload: {fileName, data, mode, comment, rejectReason}
});

export const workflow_label_testConfig_setFileComment = (fileName, comment) => ({
    type: SET_FILE_COMMENT,
    payload: {fileName, comment}
});
export const workflow_label_testConfig_setFileRejectReason = (fileName, reason, checked) => ({
    type: SET_FILE_REJECT_REASON,
    payload: {fileName, reason, checked}
});

export const workflow_label_testConfig_setFileAttributes = (fileName, id, val) => ({
    type: SET_FILE_ATTRIBUTES,
    payload: {fileName, id, val}
});

export const workflow_label_testConfig_removeFile = (fileName, imageUrl, isNew) => ({
    type: REMOVE_FILE,
    payload: {fileName, imageUrl, isNew}
});


export const workflow_label_testConfig_setShapeConfig = shapeConfig => ({
    type: SET_SHAPE_CONFIG,
    payload: shapeConfig
});

export const workflow_label_testConfig_resetFileLabels = () => ({
    type: RESET_FILE_LABELS
});

