import React, {useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {
    Card,
    CardBody,
    Col,
    Row,
} from "reactstrap";
import ReactPaginate from "react-paginate";
import PageLeftInfomation from "../../../components/common/PageLeftInfomation";
import {DatasetService} from "../../../apis/api/DatasetService";
import {AgGridReact} from "ag-grid-react";
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import {GetMessage} from "../../../util/message";
import button from "bootstrap/js/src/button";
import ProgressBar from 'react-bootstrap/ProgressBar';
import {AgRowStyle} from "../../../components/common/AgGridCustomStyle";
import {goToDetailsByFolder, setFolderCurrentPage} from "../../../store/dataset/action";
import {getWorkflow} from "../../../store/dataset/details/action";
import {useHistory} from "react-router";


function DataListByFolder(props) {
    const dis = useDispatch();
    const [datasets, setDatasets] = useState();
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);

    const { searchParam, currentPage } = useSelector((state) => ({
        searchParam: state.Dataset.searchParam,
        currentPage: state.Dataset.folderCurrentPage
    }))

    function calStartIdx(page) {
        return (page - 1) * perPage;
    }

    const fetchFolder = async (page, size = perPage) => {
        setLoading(true);

        const res = await DatasetService.getDatasetListByFolder(
            {
                'prjctId': props.prjctId,
                'start': calStartIdx(page),
                'length': size,
                'orderParam': [],
                'searchParam': [searchParam]
            }
        );

        if (res.data.data) {
            setDatasets(res.data.data);
            setTotalRows(res.data.recordsTotal);
            console.log(res.data.data)
        }
        setLoading(false);
    };

    useEffect(() => {
        let page = 1;
        if(currentPage > 1){
            page = currentPage;
        }
        dis(setFolderCurrentPage(page))
        fetchFolder(page);
    }, [searchParam]);

    const handlePageChange = page => {
        let pageIdx = page.selected + 1;
        fetchFolder(pageIdx);
        dis(setFolderCurrentPage(pageIdx));
    };

    const wrkflow = useRef(null);
    wrkflow.current = {
        id: props.wrkflowId,
        name: props.wrkflowNm
    };

    const history = useHistory();
    const gotoDetails = (wrkflow, module, filePath) => {
        dis(getWorkflow(wrkflow));
        dis(goToDetailsByFolder(module, history, filePath));
    }

    const [columnDefs] = useState([
        {
            field: 'filePath',
            headerName: GetMessage("dataset.folder"),
            cellClass: "firstCell btnCell",
            flex: '40%',
            cellRendererParams: {
                getWrkflow: () => wrkflow.current,
            },
            cellRenderer: params => {
                return (
                    <>
                        <div className={"d-flex justify-content-between"}>
                            <span>{params.value}</span>
                            <button type="button"
                                    style={{display: "none", height: "50%"}}
                                    onClick={() => {
                                        const data = params.data;
                                        const wrkflow = params.getWrkflow();
                                        const module = {
                                            moduleId: data.moduleId,
                                            name: data.moduleNm,
                                            type: data.moduleTy
                                        }
                                        gotoDetails(wrkflow, module, data.filePath)
                                    }} className="joinBtn btn btn-coco-xs-1 align-self-center">
                                {GetMessage("dataset.detailView")}
                            </button>
                        </div>
                    </>
                )
            }
        },
        {field: 'datasetNm', headerName: GetMessage("dataset.datasetNm"), flex: '26%',},
        {field: 'fileCount', headerName: GetMessage("dataset.totalFileCount"), flex: '8%',},
        {
            field: 'doneCount'
            , headerName: GetMessage("dataset.doneRate")
            , flex: '20%'
            , cellRenderer: params => {
                let rate = 0;
                if (params.data.doneCount > 0) {
                    rate = ((params.data.doneCount / params.data.fileCount) * 100).toFixed(1);
                }
                let rateText = rate + "%";
                let min = rate === 0.0 ? 0 : 10;
                return (
                    <>
                        <div style={{paddingTop: "20px"}}>
                            <ProgressBar now={rate} label={rateText} min={min} max={100}/>
                        </div>
                    </>
                )
            }
        },
        {field: 'regDtmText', headerName: GetMessage("dataset.regDtm"), flex: '10%',},
    ]);

    const [defaultColDef] = useState({
        autoHeight: true,
        resizable: true,
        cellClass: 'cell-wrap-text',
    })

    let count = Math.ceil(Number((totalRows / perPage)))
    if(count === 0){
        count = 1;
    }
    return (
        <>
            <Card className="shadow-none">
                <CardBody className="px-0 pb-0">
                    <div className="ag-theme-alpine" style={{ width: '100%'}}>
                        <AgGridReact
                            rowData={datasets}
                            rowStyle={AgRowStyle}
                            defaultColDef={defaultColDef}
                            columnDefs={columnDefs}
                            pagination={true}
                            paginationPageSize={perPage}
                            suppressPaginationPanel={true}
                            detailRowAutoHeight={true}
                            suppressRowTransform={true}
                            gridOptions={{
                                suppressCellFocus : true
                            }}
                            domLayout={'autoHeight'}
                        >
                        </AgGridReact>
                        <Row className='mx-0'>
                            <Col className='d-flex justify-content-start align-items-center fs-16 text-coco-gray-700 fw-500' sm='6'>
                                <PageLeftInfomation
                                    totalRows={totalRows}
                                    perPage={perPage}
                                    currentPage={currentPage}
                                />
                            </Col>
                            <Col className='d-flex justify-content-end align-items-center' sm='6'>
                                <ReactPaginate
                                    previousLabel={'←'}
                                    nextLabel={'→'}
                                    onPageChange={handlePageChange}
                                    pageCount={count}
                                    forcePage={currentPage - 1}
                                    activeClassName={'active'}
                                    pageClassName={'page-item'}
                                    nextLinkClassName={'page-link'}
                                    nextClassName={'page-item next'}
                                    previousClassName={'page-item prev'}
                                    previousLinkClassName={'page-link'}
                                    pageLinkClassName={'page-link'}
                                    breakClassName='page-item'
                                    breakLinkClassName='page-link'
                                    containerClassName={'pagination react-paginate pagination-custom pagination-sm justify-content-end align-items-center'}
                                />
                            </Col>
                        </Row>
                    </div>
                </CardBody>
            </Card>
        </>
    );
}

export default DataListByFolder;
