import React, {useEffect, useMemo, useState} from 'react';
import {Button, Col, Form, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row} from "reactstrap";
import {GetMessage, GetMessageIntl} from "../../../util/message";
import {defaultSelectStyle} from "../../../components/constants/select";
import Select from "react-select";
import {useIntl} from "react-intl";
import {ManagerProjectService} from "../../../apis/api/ManagerProjectService";
import {Controller, useForm} from "react-hook-form";
import {alertSuccess, confirmSuccess} from "../../../util/AlertUtil";

function MemberModifyModal(props) {
    const intl = useIntl();
    const { control, register, reset, handleSubmit } = useForm();

    const prjctId = sessionStorage.getItem("prjctId");

    const [managers, setManagers] = useState([]);
    const [defaultOption, setDefaultOption] = useState();

    useEffect(() => {
        // 초기 Select 정보 가져오기
        if(props.modalFlag === true){
            getManager(prjctId).then(r => {});
        }
    }, [props.modalFlag])

    const getManager = async (prjctId) => {
        const result = await ManagerProjectService.getAllManagers({  "prjctId": prjctId});
        if(result.data.data){
            const defaultData = result.data.data?.filter(item => (item.mainYn === 'N' && item.hasAuth ==='Y'))
                .map(manager => ({value : manager.userSeq, label: manager.userNm}))
            setDefaultOption(defaultData);

            const filteredData = result.data.data?.filter(item => item.mainYn !== 'Y' && item.hasAuth ==='N')
                .map(manager => ({value : manager.userSeq, label: manager.userNm}));
            setManagers(filteredData);
        }
    }

    const updateManagers = async (userSeqs, prjctId) => {
        console.log("userSeq ->", userSeqs);
        const res = await ManagerProjectService.updatePrjctMembers({
            "updateUserSeqs": userSeqs,
            "prjctId": prjctId
        });

    }

    const handleSaveBtn = (data) => {
        const userSeqs = data.manager.map(user => user.value);
        updateManagers(userSeqs, prjctId).then(re => {
            alertSuccess("projectSetting.modifyMemberSuccess", "projectSetting.modifyMemberSuccessMsg", () => {
                if(data.manager){
                    props.switchModalFlag();
                }
            }, null, intl)
        })
    }

    return (
        <>
            <form>
                <Modal size="lg" isOpen={props.modalFlag} centered>
                    <ModalHeader toggle={props.switchModalFlag} style={{backgroundColor: '#FFFFFF', borderBottom: '1px solid #CFD8DC', padding: '12px 20px !important'}}>
                        {GetMessageIntl(intl, "projectSetting.member")}
                    </ModalHeader>
                    <Form>
                        <ModalBody style={{paddingTop:'12px'}}>
                            <Row className="mb-8">
                                <div className="text-coco-gray-700 fs-16"
                                     style={{paddingLeft:"calc(var(--vz-gutter-x) * 0.5)", paddingRight:"calc(var(--vz-gutter-x) * 0.5)"}}>
                                    {GetMessageIntl(intl, "projectSetting.modifyMemberModalMsg")}
                                </div>
                            </Row>
                            <Row>
                                <Controller
                                    control={control}
                                    name={"manager"}
                                    render={({field}) => {
                                        const {onChange} = field;
                                        return(
                                            defaultOption &&
                                                <Select
                                                    {...field}
                                                    options={managers}
                                                    defaultValue={defaultOption || []}
                                                    styles={defaultSelectStyle}
                                                    onChange={(values) => {
                                                        onChange(values);
                                                    }}
                                                    isMulti
                                                    className="mb-0"
                                                />
                                        )
                                    }} />
                            </Row>
                        </ModalBody>
                    </Form>
                    <ModalFooter>
                        <button className="btn btn-coco-2" onClick={props.switchModalFlag}>
                            {GetMessageIntl(intl, "common.cancel")}
                        </button>
                        <button
                            type="button"
                            className="btn btn-coco-1"
                            onClick={handleSubmit(handleSaveBtn)}
                        >
                            {GetMessageIntl(intl, "common.save")}
                        </button>
                    </ModalFooter>
                </Modal>
            </form>
        </>
    );
}

export default MemberModifyModal;
