import React, {useEffect, useState} from 'react';
import { WithContext as ReactTags } from 'react-tag-input';

const KeyCodes = {
    enter: 13,
};

const delimiters = [KeyCodes.comma, KeyCodes.enter];
const regExp = new RegExp("([!#-'*+/-9=?A-Z^-~-]+(\.[!#-'*+/-9=?A-Z^-~-]+)*|\"\(\[\]!#-[^-~ \t]|(\\[\t -~]))+\")@([!#-'*+/-9=?A-Z^-~-]+(\.[!#-'*+/-9=?A-Z^-~-]+)*|\[[\t -Z^-~]*])");
const InputTagTest = () => {
    const [tags, setTags] = React.useState([
        { id: 'Thailand', text: 'Thailand' },
        { id: 'India', text: 'India' },
        { id: 'Vietnam', text: 'Vietnam' },
        { id: 'Turkey', text: 'Turkey' },
    ]);
    const [strTags, setStrTags] = useState([]);

    useEffect(() => {
        /*
        * 초기에 가져온 String Array -> Object 변환 -> tags 값 으로 삽입
        * */
        const testArr = ['안녕', '하세요'];
        let testObjArr = testArr.map((item) => {
            return { id: item, text: item };
        });
        console.log("testObjArr -> ", testObjArr)
        setTags([...tags, ...testObjArr])
    }, []);

    useEffect(() => {
        let tmpArr = [];
        tags.map((tag)=>{
            tmpArr.push(tag.text)
        })
        setStrTags(tmpArr);
        console.log("StrArr -> ", tmpArr)
    }, [tags])

    const handleDelete = (i) => {
        setTags(tags.filter((tag, index) => index !== i));
    };

    const handleAddition = (tag) => {
        let inputTagArr = [];
        if(tag.text){
            let inputTags = tag.text.split(',');
            inputTags.forEach((inputTag)=>{
                if(inputTag) {
                    if(regExp.test(inputTag)){
                        if (!itemExistCheck({id: inputTag, text: inputTag})) {
                            inputTagArr.push({id: inputTag, text: inputTag});
                        }
                    }
                }

            })
        }
        setTags([...tags, ...inputTagArr]);
    };

    const itemExistCheck = (newItem) => {
        let result = false;
        tags.flatMap((item)=>{
            if(JSON.stringify(item) == JSON.stringify(newItem)){
                result = true;
            }
        });
        return result;
    }

    const handleDrag = (tag, currPos, newPos) => {
        const newTags = tags.slice();

        newTags.splice(currPos, 1);
        newTags.splice(newPos, 0, tag);

        setTags(newTags);
    };

    const handleTagClick = (index) => {
        console.log('The tag at index ' + index + ' was clicked');
    };

    const handleInputKeyDown = (e) => {
        console.log(e)
        if (e.keyCode === 8 && !e.target.value) {
            e.preventDefault();
        }
    };

    return (
        <div className="app">
            <h1> Input Tag Test </h1>
            <div>
                <ReactTags
                    tags={tags}
                    delimiters={delimiters}
                    handleDelete={handleDelete}
                    handleAddition={handleAddition}
                    handleDrag={handleDrag}
                    handleTagClick={handleTagClick}
                    onKeyDown={handleInputKeyDown}
                    allowAdditionFromPaste={false}
                    allowUnique={true}
                    inputFieldPosition="top"
                    /*autocomplete*/
                />
            </div>
        </div>
    );
};

export default InputTagTest;
