// @ts-nocheck
import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {getAnnotationsReasons, updateReviewStates} from "../../../../../../../../store/review/action";
import {OBJECT_REVIEW_DETAILS} from "../../classNameConsts";
import './style.scss';
import {GetMessage} from "../../../../../../../../util/message";
import {ObjectReviewContent} from "./object-review-content";
import ObjectReviewFooterContainer from "../../../containers/review-workspace/objects-side-bar/object-review-footer";

interface Props {
    clientID: number;
    sortedStatesID: number[];
    onOpenCanvasContextMenu(e): void;
}
export function ObjectReviewDetails (props) {
    const dispatch = useDispatch();
    const { clientID, rejectRsn, instData, choiceCdList, reject, sortedStatesID, onOpenCanvasContextMenu } = props;

    const onCdChange = (target) => {
        const checked = target.checked;
        const value = target.value;
        let newValues = [...choiceCdList];
        if (checked) {
            newValues.push(value);
            // newValues = [...newValues, value];
        } else {
            const index = newValues.indexOf(value)
            newValues.splice(index, 1);
            // newValues.filter(v => v !== value);
        }
        instData.rsnCd = [...newValues];
        dispatch(updateReviewStates(instData))
    }

    useEffect(() => {
        if (rejectRsn.length === 0) {
            dispatch(getAnnotationsReasons());
        }
    }, [])

    return (
        <div className={[OBJECT_REVIEW_DETAILS, "review-details"].join(" ")}>
            <div className={'title'}>
                {GetMessage('review.objectReviewDetailsTitle')}
                <div className={'subtitle'}>{GetMessage('label.skipSubTitle')}</div>
            </div>
            <div className={'content'}>
                {rejectRsn.map((rsn, index) => {
                    return (
                        <ObjectReviewContent key={index}
                                             clientID={clientID}
                                             index={index}
                                             rsn={rsn}
                                             choiceCdList={choiceCdList}
                                             onCdChange={onCdChange}
                        />
                    )})
                }
            </div>
            <ObjectReviewFooterContainer
                clientID={clientID}
                disabled={reject === null}
                sortedStatesID={sortedStatesID}
                onOpenCanvasContextMenu={onOpenCanvasContextMenu}
            />
        </div>
    );
}
