import {useHistory} from "react-router";
import React from "react";

const FAQBtn = ({ moduleId }) => {
    const goFAQ = () => {
        const url = `/user/task/info/${moduleId}/faq`;
        window.open(url, 'moduleInfo');
        return;
    }
    return (
        <div className="ms-1 header-item d-flex">
            <div className="badge badge-coco badge-coco-soft-green" id={"faq"} onClick={goFAQ}>FAQ</div>
        </div>
    )
}

const GuideBookBtn = ({ moduleId }) => {
    const goGuideBook = () => {
        const url = `/user/task/info/${moduleId}/guide`;
        window.open(url,'moduleInfo');
    }
    return (
        <div className="ms-1 header-item d-flex">
            <button type="button" className="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle"  onClick={goGuideBook}>
                <i className="ri-book-open-line fs-24 text-white" id="guideBook"></i>
            </button>
        </div>
    )
};

const ExitBtn = () => {
    const history = useHistory();
    return (
        <div className="ms-1 header-item d-flex">
            <button type="button" className="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle exitButton"
                    onClick={history.goBack}>
                <i className="ri-logout-box-r-line fs-24 text-white"></i>
            </button>
        </div>
    )
}

export {FAQBtn, GuideBookBtn, ExitBtn};
