import React, {createRef, useCallback, useEffect, useState} from 'react';
import ReactFlow, {
    useNodesState,
    useEdgesState,
    useReactFlow,
    addEdge,
    MiniMap,
    Controls,
    Background,
    MarkerType, ReactFlowProvider,
} from 'reactflow';
import 'reactflow/dist/style.css';
import CustomEdge from './CustomEdge';

import 'reactflow/dist/style.css';
import {authAxios, defaultAxios} from "../../apis/utils/AxiosUtil";
import ConnectionLine from "../manager/project/workflow/components/edges/ConnectionLine";
import DatasetNode from "../manager/project/workflow/components/nodes/DatasetNode";
import workflowNode from "../manager/project/workflow/components/nodes/WorkFlowNode";
import LabelNode from "../manager/project/workflow/components/nodes/LabelNode";
import AutoLabelNode from "../manager/project/workflow/components/nodes/AutoLabelNode";
import ReviewNode from "../manager/project/workflow/components/nodes/ReviewNode";
import CrossReviewNode from "../manager/project/workflow/components/nodes/CrossReviewNode";
import ManagerReviewNode from "../manager/project/workflow/components/nodes/ManagerReviewNode";
import SDQNode from "../manager/project/workflow/components/nodes/SDQNode";
import GroupNode from "../manager/project/workflow/components/nodes/GroupNode";
import AcceptNode from "../manager/project/workflow/components/nodes/sub/AcceptNode";
import FinishNode from "../manager/project/workflow/components/nodes/FinishNode";
import UnabledNode from "../manager/project/workflow/components/nodes/UnabledNode";
import SubUnabledNode from "../manager/project/workflow/components/nodes/sub/SubUnabledNode";
import RestoreNode from "../manager/project/workflow/components/nodes/sub/RestoreNode";
import SubmitNode from "../manager/project/workflow/components/nodes/sub/SubmitNode";
import SkipNode from "../manager/project/workflow/components/nodes/sub/SkipNode";
import LabeledNode from "../manager/project/workflow/components/nodes/sub/LabeledNode";
import ApprovalNode from "../manager/project/workflow/components/nodes/sub/ApprovalNode";
import RejectNode from "../manager/project/workflow/components/nodes/sub/RejectNode";
import AmbiguityNode from "../manager/project/workflow/components/nodes/sub/AmbiguityNode";
import StateChangeNode from "../manager/project/workflow/components/nodes/sub/StateChangeNode";
import UpdateSubmitNode from "../manager/project/workflow/components/nodes/sub/UpdateSubmitNode";
import FloatingEdge from "../manager/project/workflow/components/edges/FloatingEdge";
import {manage_project_workflow_module_setSelectionNodeId} from "../../store/manage/project/workflow/module/action";
import {Button} from "reactstrap";

const initialNodes = [
    {
        "width": 290,
        "height": 328,
        "id": "MDf1b7abc9-7603-48b7-9f04-ba0e6a8e0085",
        "type": "label",
        "position": {
            "x": 650,
            "y": 89
        },
        "expandParent": true,
        "style": {
            "height": 328
        },
        "positionAbsolute": {
            "x": 650,
            "y": 89
        },
        "dragging": false,
        "data": {
            "regrSeq": 9,
            "regDtm": "2023-05-18T06:05:07.920+00:00",
            "modrSeq": 9,
            "modDtm": "2023-07-17T00:58:24.570+00:00",
            "moduleId": "MDf1b7abc9-7603-48b7-9f04-ba0e6a8e0085",
            "moduleNm": "장애물에가려진사물 라벨링",
            "prjctId": "PJ0000000001",
            "wrkflowId": "WF53d28ae1-7777-42ed-9744-965a579cb350",
            "wrkflowNm": null,
            "moduleTy": "01",
            "moduleDc": null,
            "sttusCd": "02",
            "delYn": "N",
            "reviewOrd": 0,
            "ord": 0,
            "datasetConfig": null,
            "configNm": null,
            "files": null,
            "dataConfigTargetId": null,
            "testAttachFile": null,
            "thumnail1": null,
            "thumnail2": null,
            "thumnail3": null
        }
    },
    {
        "width": 250,
        "height": 48,
        "id": "MDf1b7abc9-7603-48b7-9f04-ba0e6a8e0085_01",
        "type": "skip",
        "position": {
            "x": 20,
            "y": 204
        },
        "parentNode": "MDf1b7abc9-7603-48b7-9f04-ba0e6a8e0085",
        "extent": "parent",
        "draggable": false,
        "selectable": false,
        "positionAbsolute": {
            "x": 670,
            "y": 293
        }
    },
    {
        "width": 250,
        "height": 48,
        "id": "MDf1b7abc9-7603-48b7-9f04-ba0e6a8e0085_02",
        "type": "submit",
        "position": {
            "x": 20,
            "y": 260
        },
        "parentNode": "MDf1b7abc9-7603-48b7-9f04-ba0e6a8e0085",
        "extent": "parent",
        "draggable": false,
        "selectable": false,
        "positionAbsolute": {
            "x": 670,
            "y": 349
        }
    },
    {
        "width": 290,
        "height": 328,
        "id": "MDffc62d7e-aca5-4e4e-b596-5b63c7aafab1",
        "type": "review",
        "position": {
            "x": 1000,
            "y": 88
        },
        "expandParent": true,
        "style": {
            "height": 328
        },
        "positionAbsolute": {
            "x": 1000,
            "y": 88
        },
        "dragging": false,
        "data": {
            "regrSeq": 9,
            "regDtm": "2023-05-18T07:01:21.129+00:00",
            "modrSeq": 9,
            "modDtm": "2023-05-22T01:19:26.429+00:00",
            "moduleId": "MDffc62d7e-aca5-4e4e-b596-5b63c7aafab1",
            "moduleNm": "장애물에 가려진 사물 검수1",
            "prjctId": "PJ0000000001",
            "wrkflowId": "WF53d28ae1-7777-42ed-9744-965a579cb350",
            "wrkflowNm": null,
            "moduleConfig": "{\"etcConfig\": {\"openDate\": \"2023-05-22 01:19:26\"}, \"baseConfig\": {\"taskDc\": \"장애물에 가려진 사물 검수1\", \"taskNm\": \"장애물에 가려진 사물 검수1\"}, \"testConfig\": {\"desc\": \"1\", \"title\": \"1\", \"passCount\": 1, \"questions\": [{\"image\": {\"url\": \"module/test/MDffc62d7e-aca5-4e4e-b596-5b63c7aafab1/ec9c6f51-0b03-3b70-b295-537d6cc6590d.jpg\", \"host\": \"https://coconut-attach-dev.s3.ap-northeast-2.amazonaws.com/\", \"isNew\": false, \"realFileName\": \"210818_Bicycle_901_03_WireMedium.jpg\"}, \"labels\": 0, \"comment\": \"111\", \"attributes\": {\"0\": \"자전거\"}, \"rejectReason\": [\"01\"]}], \"questionCount\": 1, \"questionOrderTy\": \"01\"}, \"pointConfig\": {\"payPoint\": \"10\", \"pointPayTy\": \"00\"}, \"workerConfig\": {\"standardTy\": \"00\"}}",
            "moduleTy": "02",
            "moduleDc": null,
            "sttusCd": "02",
            "delYn": "N",
            "reviewOrd": 0,
            "ord": 0,
            "datasetConfig": null,
            "configNm": null,
            "files": null,
            "dataConfigTargetId": null,
            "testAttachFile": null,
            "thumnail1": null,
            "thumnail2": null,
            "thumnail3": null
        }
    },
    {
        "width": 250,
        "height": 48,
        "id": "MDffc62d7e-aca5-4e4e-b596-5b63c7aafab1_01",
        "type": "approval",
        "position": {
            "x": 20,
            "y": 204
        },
        "parentNode": "MDffc62d7e-aca5-4e4e-b596-5b63c7aafab1",
        "extent": "parent",
        "draggable": false,
        "selectable": false,
        "positionAbsolute": {
            "x": 1020,
            "y": 292
        }
    },
    {
        "width": 250,
        "height": 48,
        "id": "MDffc62d7e-aca5-4e4e-b596-5b63c7aafab1_02",
        "type": "reject",
        "position": {
            "x": 20,
            "y": 260
        },
        "parentNode": "MDffc62d7e-aca5-4e4e-b596-5b63c7aafab1",
        "extent": "parent",
        "draggable": false,
        "selectable": false,
        "positionAbsolute": {
            "x": 1020,
            "y": 348
        }
    },
    {
        "width": 290,
        "height": 273,
        "id": "MD646f5464-1ff4-40bb-b666-6e3cb690a1ed",
        "type": "dataset",
        "position": {
            "x": 177,
            "y": 87
        },
        "positionAbsolute": {
            "x": 177,
            "y": 87
        },
        "dragging": false,
        "data": {
            "regrSeq": 9,
            "regDtm": "2023-05-22T00:33:24.673+00:00",
            "modrSeq": 9,
            "modDtm": "2023-06-15T01:10:15.148+00:00",
            "moduleId": "MD646f5464-1ff4-40bb-b666-6e3cb690a1ed",
            "moduleNm": "장애물에가려진물체 데이터2",
            "prjctId": "PJ0000000001",
            "wrkflowId": "WF53d28ae1-7777-42ed-9744-965a579cb350",
            "wrkflowNm": null,
            "moduleConfig": "{\"datasetId\":\"DTfba57642-22d6-437d-8ce6-d59b19135e8a\"}",
            "moduleTy": "03",
            "moduleDc": null,
            "sttusCd": "00",
            "delYn": "N",
            "reviewOrd": 0,
            "ord": 0,
            "datasetConfig": {
                "regrSeq": 9,
                "regDtm": "2023-05-22T09:36:03.714+00:00",
                "modrSeq": 9,
                "modDtm": "2023-05-22T00:36:32.629+00:00",
                "datasetId": "DTfba57642-22d6-437d-8ce6-d59b19135e8a",
                "orgnztId": "OZ0000000001",
                "datasetNm": "장애물에가려진물체 데이터",
                "datasetDc": null,
                "dataAddTy": "01",
                "metaInfoMapngTy": "00",
                "metaInfoMapngCn": null,
                "dataConfig": "null",
                "fileCount": 163,
                "totalFileSize": 503574585,
                "sttusCd": "00",
                "delYn": "N",
                "dataConfigObj": null,
                "modDtmFromRecord": null,
                "modDtmFromRecordText": null,
                "regDtmText": "2023-05-22",
                "modDtmText": null,
                "wrkflowId": null,
                "wrkflowNm": null,
                "fileNm": null,
                "moduleId": null,
                "moduleNm": null,
                "moduleTy": null,
                "labelSttus": null,
                "reviewSttus": null,
                "rejctCount": null,
                "instanceCount": null,
                "managerConfirmCd": null,
                "reviewModuleNm": null,
                "labelModuleNm": null,
                "doneCount": 0,
                "filePath": null,
                "fileSeq": 0,
                "asgnRate": 44,
                "nextModuleId": null,
                "nextTaskNm": null,
                "nextTaskSttusCd": null,
                "lstTaskTy": null,
                "lstTaskSttusCd": null
            },
            "configNm": null,
            "files": null,
            "dataConfigTargetId": null,
            "testAttachFile": null,
            "thumnail1": "https://coconut-attach-dev.s3.ap-northeast-2.amazonaws.com/thumnail/dataset/DTfba57642-22d6-437d-8ce6-d59b19135e8a/1.png",
            "thumnail2": "https://coconut-attach-dev.s3.ap-northeast-2.amazonaws.com/thumnail/dataset/DTfba57642-22d6-437d-8ce6-d59b19135e8a/2.png",
            "thumnail3": "https://coconut-attach-dev.s3.ap-northeast-2.amazonaws.com/thumnail/dataset/DTfba57642-22d6-437d-8ce6-d59b19135e8a/3.png",
            "useOrd": 1
        }
    },
    {
        "width": 290,
        "height": 82,
        "id": "MD18dd9683-7e9f-4ac7-afcd-420f6b163431",
        "type": "finish",
        "position": {
            "x": 1173,
            "y": 607
        },
        "dragging": true,
        "positionAbsolute": {
            "x": 1173,
            "y": 607
        },
        "data": {
            "regrSeq": 9,
            "regDtm": "2023-05-26T00:03:06.579+00:00",
            "modrSeq": 9,
            "modDtm": "2023-05-26T00:03:06.579+00:00",
            "moduleId": "MD18dd9683-7e9f-4ac7-afcd-420f6b163431",
            "moduleNm": "Untitled_undefined(1)",
            "prjctId": "PJ0000000001",
            "wrkflowId": "WF53d28ae1-7777-42ed-9744-965a579cb350",
            "wrkflowNm": null,
            "moduleConfig": "{}",
            "moduleTy": "11",
            "moduleDc": null,
            "sttusCd": "00",
            "delYn": "N",
            "reviewOrd": 0,
            "ord": 0,
            "datasetConfig": null,
            "configNm": null,
            "files": null,
            "dataConfigTargetId": null,
            "testAttachFile": null,
            "thumnail1": null,
            "thumnail2": null,
            "thumnail3": null
        }
    },
    {
        "width": 290,
        "height": 250,
        "id": "MDfdacf8ff-3ced-4ebc-a10d-e1151f4c7001",
        "type": "managerReview",
        "position": {
            "x": 642,
            "y": 495
        },
        "expandParent": true,
        "style": {
            "height": 250
        },
        "positionAbsolute": {
            "x": 642,
            "y": 495
        },
        "dragging": true,
        "data": {
            "regrSeq": 7,
            "regDtm": "2023-08-22T01:38:20.958+00:00",
            "modrSeq": 7,
            "modDtm": "2023-08-22T01:38:20.958+00:00",
            "moduleId": "MDfdacf8ff-3ced-4ebc-a10d-e1151f4c7001",
            "moduleNm": "Untitled_관리자 검토(2)",
            "prjctId": "PJ0000000001",
            "wrkflowId": "WF53d28ae1-7777-42ed-9744-965a579cb350",
            "wrkflowNm": null,
            "moduleConfig": "{}",
            "moduleTy": "04",
            "moduleDc": null,
            "sttusCd": "00",
            "delYn": "N",
            "reviewOrd": 0,
            "ord": 0,
            "datasetConfig": null,
            "configNm": null,
            "files": null,
            "dataConfigTargetId": null,
            "testAttachFile": null,
            "thumnail1": null,
            "thumnail2": null,
            "thumnail3": null
        }
    },
    {
        "width": 250,
        "height": 48,
        "id": "MDfdacf8ff-3ced-4ebc-a10d-e1151f4c7001_01",
        "type": "restore",
        "position": {
            "x": 20,
            "y": 126
        },
        "parentNode": "MDfdacf8ff-3ced-4ebc-a10d-e1151f4c7001",
        "extent": "parent",
        "draggable": false,
        "selectable": false,
        "positionAbsolute": {
            "x": 662,
            "y": 621
        }
    },
    {
        "width": 250,
        "height": 48,
        "id": "MDfdacf8ff-3ced-4ebc-a10d-e1151f4c7001_02",
        "type": "subUnabled",
        "position": {
            "x": 20,
            "y": 182
        },
        "parentNode": "MDfdacf8ff-3ced-4ebc-a10d-e1151f4c7001",
        "extent": "parent",
        "draggable": false,
        "selectable": false,
        "positionAbsolute": {
            "x": 662,
            "y": 677
        }
    }
];

const initialEdges = [
    {
        "style": {
            "strokeWidth": 2,
            "stroke": "#0bad8f"
        },
        "type": "smoothstep",
        "markerEnd": {
            "type": "arrowclosed",
            "color": "#0bad8f"
        },
        "zIndex": 9,
        "source": "MDf1b7abc9-7603-48b7-9f04-ba0e6a8e0085_02",
        "sourceHandle": "right",
        "target": "MDffc62d7e-aca5-4e4e-b596-5b63c7aafab1",
        "targetHandle": "left",
        "id": "reactflow__edge-MDf1b7abc9-7603-48b7-9f04-ba0e6a8e0085_02right-MDffc62d7e-aca5-4e4e-b596-5b63c7aafab1left",
        "updatable": "target"
    },
    {
        "style": {
            "strokeWidth": 2,
            "stroke": "#0bad8f"
        },
        "type": "smoothstep",
        "markerEnd": {
            "type": "arrowclosed",
            "color": "#0bad8f"
        },
        "zIndex": 9,
        "source": "MD646f5464-1ff4-40bb-b666-6e3cb690a1ed",
        "sourceHandle": "right",
        "target": "MDf1b7abc9-7603-48b7-9f04-ba0e6a8e0085",
        "targetHandle": "left",
        "id": "reactflow__edge-MD646f5464-1ff4-40bb-b666-6e3cb690a1edright-MDf1b7abc9-7603-48b7-9f04-ba0e6a8e0085left",
        "updatable": "target"
    },
    {
        "style": {
            "strokeWidth": 2,
            "stroke": "#0bad8f"
        },
        "type": "smoothstep",
        "markerEnd": {
            "type": "arrowclosed",
            "color": "#0bad8f"
        },
        "zIndex": 9,
        "source": "MDf1b7abc9-7603-48b7-9f04-ba0e6a8e0085_01",
        "sourceHandle": "left",
        "target": "MDfdacf8ff-3ced-4ebc-a10d-e1151f4c7001",
        "targetHandle": "left",
        "id": "reactflow__edge-MDf1b7abc9-7603-48b7-9f04-ba0e6a8e0085_01left-MDfdacf8ff-3ced-4ebc-a10d-e1151f4c7001left"
    }, {
        "style": {
            "strokeWidth": 2,
            "stroke": "#0bad8f"
        },
        "type": "smoothstep",
        "markerEnd": {
            "type": "arrowclosed",
            "color": "#0bad8f"
        },
        "zIndex": 9,
        "source": "MDffc62d7e-aca5-4e4e-b596-5b63c7aafab1_01",
        "sourceHandle": "right",
        "target": "MD18dd9683-7e9f-4ac7-afcd-420f6b163431",
        "targetHandle": "top",
        "id": "reactflow__edge-MDffc62d7e-aca5-4e4e-b596-5b63c7aafab1_01left-MD18dd9683-7e9f-4ac7-afcd-420f6b163431top",
        "updatable": "target"
    },
];

const nodeTypes = {
    dataset: DatasetNode,
    workflow: workflowNode,
    label: LabelNode,
    autoLabel: AutoLabelNode,
    review: ReviewNode,
    crossReview: CrossReviewNode,
    managerReview: ManagerReviewNode,
    sdq4ai: SDQNode,
    module: GroupNode,
    accept: AcceptNode,
    finish: FinishNode,
    unabled: UnabledNode,
    subUnabled: SubUnabledNode,
    restore: RestoreNode,
    submit: SubmitNode,
    skip: SkipNode,
    labeled: LabeledNode,
    approval: ApprovalNode,
    reject: RejectNode,
    ambiguity: AmbiguityNode,
    stateChange: StateChangeNode,
    updateSubmit: UpdateSubmitNode
};


const edgeTypes = {
    floating: FloatingEdge,
    //smart: SmartStepEdge,
};

const EdgesFlow = () => {
    const ref = createRef()
    const getImage = () => {

        // if(ref123.current === null) return
        // toPng(ref123.current, {
        //     filter: node => !(
        //         node?.classList?.contains('react-flow__minimap') ||
        //         node?.classList?.contains('react-flow__controls')
        //     ),
        // }).then(async dataUrl => {
        //     console.log(dataUrl)
        //     await authAxios.post('/service/workflow/saveWorkFlowScreenShot', {
        //         wrkflowId : 'WF515ba647-a27a-4e6f-93db-5e8d3f8c6eb9',
        //         screenShot : dataUrl
        //     })
        // });
    }

    const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
    const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);
    const [reactFlowInstance, setReactFlowInstance] = useState(null);

    const onConnect = useCallback((params) => setEdges((eds) => addEdge(params, eds)), []);



    const nodeAndEdgeSaveHandler = (e, node, nodes) => {
        console.log("onNodeDragStop")
    }

    const defaultEdgeOptions = {
        style: {strokeWidth: 2, stroke: '#0bad8f'},
        type: 'smoothstep',
        markerEnd: {
            type: MarkerType.ArrowClosed,
            color: '#0bad8f',
        },
        /*markerStart:'edge-marker-start',*/
        zIndex: 9
    };


    const connectionLineStyle = {
        strokeWidth: 3,
        stroke: 'black',
    };

    const addNode= () => {
        const n1 = {
            "width": 290,
            "height": 328,
            "id": "MDcdc72a14-25f7-4f73-a64d-be3db696edcc",
            "type": "review",
            "position": {
                "x": 1350,
                "y": 88
            },
            "expandParent": true,
            "style": {
                "height": 328
            },
            "positionAbsolute": {
                "x": 1350,
                "y": 88
            },
            "dragging": false,
            "data": {
                "regrSeq": 9,
                "regDtm": "2023-05-18T07:01:22.096+00:00",
                "modrSeq": 9,
                "modDtm": "2023-05-22T01:20:44.454+00:00",
                "moduleId": "MDcdc72a14-25f7-4f73-a64d-be3db696edcc",
                "moduleNm": "장애물에가려진 사물 검수2",
                "prjctId": "PJ0000000001",
                "wrkflowId": "WF53d28ae1-7777-42ed-9744-965a579cb350",
                "wrkflowNm": null,
                "moduleConfig": "{\"etcConfig\": {\"openDate\": \"2023-05-22 01:18:31\"}, \"baseConfig\": {\"taskDc\": \"장애물에가려진 사물 검수2\", \"taskNm\": \"장애물에가려진 사물 검수2\"}, \"testConfig\": {\"desc\": \"2\", \"title\": \"2\", \"passCount\": 1, \"questions\": [{\"image\": {\"url\": \"module/test/MDcdc72a14-25f7-4f73-a64d-be3db696edcc/dfcf0513-5b7b-3766-ab69-1c1a7bbf6d93.jpg\", \"host\": \"https://coconut-attach-dev.s3.ap-northeast-2.amazonaws.com/\", \"isNew\": false, \"realFileName\": \"210818_Bicycle_901_03_None.jpg\"}, \"labels\": 0, \"comment\": \"111\", \"attributes\": {\"0\": \"자전거\"}, \"rejectReason\": [\"01\"]}], \"questionCount\": 1, \"questionOrderTy\": \"01\"}, \"pointConfig\": {\"payPoint\": \"10\", \"pointPayTy\": \"00\"}, \"workerConfig\": {\"standardTy\": \"00\"}}",
                "moduleTy": "02",
                "moduleDc": null,
                "sttusCd": "02",
                "delYn": "N",
                "reviewOrd": 0,
                "ord": 0,
                "datasetConfig": null,
                "configNm": null,
                "files": null,
                "dataConfigTargetId": null,
                "testAttachFile": null,
                "thumnail1": null,
                "thumnail2": null,
                "thumnail3": null
            }
        }
        const n2 = {
            "width": 250,
            "height": 48,
            "id": "MDcdc72a14-25f7-4f73-a64d-be3db696edcc_01",
            "type": "approval",
            "position": {
                "x": 20,
                "y": 204
            },
            "parentNode": "MDcdc72a14-25f7-4f73-a64d-be3db696edcc",
            "extent": "parent",
            "draggable": false,
            "selectable": false,
            "positionAbsolute": {
                "x": 1370,
                "y": 292
            }

        }
        const n3 = {
            "width": 250,
            "height": 48,
            "id": "MDcdc72a14-25f7-4f73-a64d-be3db696edcc_02",
            "type": "reject",
            "position": {
                "x": 20,
                "y": 260
            },
            "parentNode": "MDcdc72a14-25f7-4f73-a64d-be3db696edcc",
            "extent": "parent",
            "draggable": false,
            "selectable": false,
            "positionAbsolute": {
                "x": 1370,
                "y": 348
            }
        }

        let e1={
            "style": {
                "strokeWidth": 2,
                "stroke": "#0bad8f"
            },
            "type": "smoothstep",
            "markerEnd": {
                "type": "arrowclosed",
                "color": "#0bad8f"
            },
            "zIndex": 9,
            "source": "MDcdc72a14-25f7-4f73-a64d-be3db696edcc_01",
            "sourceHandle": "right",
            "target": "MD18dd9683-7e9f-4ac7-afcd-420f6b163431",
            "targetHandle": "right",
            "id": "reactflow__edge-MDcdc72a14-25f7-4f73-a64d-be3db696edcc_01left-MD18dd9683-7e9f-4ac7-afcd-420f6b163431top",
            "updatable": "target"
        }
        initialNodes.push(n1)
        initialNodes.push(n2)
        initialNodes.push(n3)

        setNodes(initialNodes);

        let copyArray = [...initialEdges]
        copyArray.forEach(edges=>{
            if(edges.source == 'MDffc62d7e-aca5-4e4e-b596-5b63c7aafab1_01'){
                // source, target, id를 변경
                // source : MDffc62d7e-aca5-4e4e-b596-5b63c7aafab1_01 // 변경없음
                // target : 새로 추가한 모듈 n1
                edges.target = n1.id;
                // id : 규칙에 맞게 변경
                //edges.id = edges.id.replace('MDffc62d7e-aca5-4e4e-b596-5b63c7aafab1_01',n2.id );
            }
        });
        copyArray.push(e1);
        setEdges(copyArray);

    }

    return (
        <>
            <div>
                <Button onClick={addNode}>추가</Button>
            </div>
            <div style={{height: "1000px", width: "1900px"}}>
                <ReactFlow
                    id={"moduleReactFlow"}
                    ref={ref}
                    nodes={nodes}
                    edges={edges}
                    /*onNodeClick={onNodeClick}*/
                    onNodesChange={onNodesChange}
                    onEdgesChange={onEdgesChange}
                    onConnect={onConnect}
                    onConnectStart={(params) => console.log('connect start', params)}
                    onConnectEnd={(params) => console.log('connect end', params)}
                    onNodeDragStart={() => console.log("onNodeDragStart")}
                    onNodeDragStop={nodeAndEdgeSaveHandler}
                    onInit={setReactFlowInstance}
                    nodeTypes={nodeTypes}
                    edgeTypes={edgeTypes}
                    onEdgeUpdate={() => console.log("onEdgeUpdate")}
                    onEdgeUpdateStart={() => console.log("onEdgeUpdateStart")}
                    onEdgeUpdateEnd={() => console.log("onEdgeUpdateEnd")}
                    defaultEdgeOptions={defaultEdgeOptions}
                    connectionLineComponent={ConnectionLine}
                    connectionLineStyle={connectionLineStyle}
                    deleteKeyCode={null}
                >
                    <MiniMap/>
                    <Controls/>
                    <Background/>
                </ReactFlow>
            </div>
            <div>
                <div>
                    <button style={{marginBottom: '10px'}} onClick={getImage}>
                        Take screenshot
                    </button>
                </div>
            </div>
        </>
    );
};

export default function FlowWithProvider(props) {
    return (
        <ReactFlowProvider>
            <EdgesFlow {...props} />
        </ReactFlowProvider>
    );
}

// export default EdgesFlow;
