// @ts-nocheck
import '../style.scss';
import React, {useCallback, useEffect, useState} from "react";
import WaveSurfer from "wavesurfer.js";
import CommonUtil from "../../../../../../../util/CommonUtil";
import {MathUtils} from "three";
import clamp = MathUtils.clamp;
import {VolumeController} from "./VolumeController";
import {SpeedController} from "./SpeedController";
import {useDispatch, useSelector} from "react-redux";
import {switchPlay, updateDuration} from "../../../../../../../store/label/action";

interface Props {
    wavesurfer: WaveSurfer;
    waveRef: HTMLDivElement;
}
export const ControlBar = (props: Props): JSX.Element => {
    const {wavesurfer, waveRef} = props;
    const dispatch = useDispatch();
    const [currentTime, setCurrentTime] = useState(0);
    const [duration, setDuration] = useState(0);
    const isPlaying = useSelector(state => state.Label.Cmmn.player.playing);
    const [speed, setSpeed] = useState(1.0);
    const [volume, setVolume] = useState(1);

    // Zooming on Ctrl + Scroll
    const handleScroll = (event) => {
        if (event.ctrlKey) {
            event.preventDefault();
            const delta = event.deltaY;
            const zoomLevel = wavesurfer.options.minPxPerSec;
            wavesurfer.zoom(zoomLevel - delta * 0.3);
        }
    };

    useEffect(() => {
        if (!wavesurfer) return;
        const subscriptions = [
            wavesurfer.on('play', () => dispatch(switchPlay(true))),
            wavesurfer.on('pause', () => dispatch(switchPlay(false))),
            wavesurfer.on('timeupdate', (currentTime) => setCurrentTime(currentTime)),
        ]

        setCurrentTime(wavesurfer.getCurrentTime());
        wavesurfer.once('ready', () => {
            setDuration(wavesurfer.getDuration());
            dispatch(updateDuration(wavesurfer.getDuration()));
        })
        waveRef.current.addEventListener("wheel", handleScroll);
        return () => {
            subscriptions.forEach((unsub) => unsub());
            if (waveRef.current) waveRef.current.removeEventListener("wheel", handleScroll);
        }
    }, [wavesurfer])

    // On play button click. 실제 실행 동작
    const onPlayClick = useCallback(() => {
        wavesurfer.isPlaying() ? wavesurfer.pause() : wavesurfer.play().then(_ => {})
    }, [wavesurfer])

    const onPlayRewind = useCallback((seconds: number) => {
        const _currentTime = currentTime - seconds
        wavesurfer.seekTo(_currentTime / duration);
        // wavesurfer.isPlaying() ? wavesurfer.pause() : wavesurfer.play().then(_ => {console.log('playing~')})
    })

    const onPlayFastForward = useCallback((seconds: number) => {
        const _currentTime = currentTime + seconds;
        wavesurfer.seekTo(_currentTime / duration);
    })

    // About Sound Volume
    const onChangeVolume = (_volume: number) => {
        _volume = clamp(_volume, 0, 1);
        wavesurfer.setVolume(_volume);
        setVolume(_volume);
    }

    const setPlaybackRate = (speed: number) => {
        console.log('speed', speed)
        wavesurfer.setPlaybackRate(speed, true);
        setSpeed(speed);
    }

    return (
        <>
            <ControlBarView
                isPlaying={isPlaying}
                currentTime={CommonUtil.convertToHHMMSS(currentTime)}
                duration={CommonUtil.convertToHHMMSS(duration)}
                speed={speed}
                play={onPlayClick}
                volume={volume}
                playRewind={() => onPlayRewind(5)}
                playFastForward={() => onPlayFastForward(5)}
                setPlaybackRate={(speed) => setPlaybackRate(speed)}
                onChangeVolume={onChangeVolume}
            />
        </>
    );
};

interface ViewProps {
    isPlaying: boolean;
    currentTime: number;
    duration: number;
    speed: number;
    play(): void;
    playRewind(): void;
    playFastForward(): void;

    setPlaybackRate(speed: number): void;

}
const ControlBarView = (props: ViewProps): JSX.Element => {
    const {isPlaying, currentTime, duration, speed, volume
        , play, playRewind, playFastForward, setPlaybackRate, onChangeVolume} = props;

    return(
        <>
            <div className={"audio-control-bar"}>
                <div className={"group time"}>
                    <span className={currentTime.length < 6 ?"MM-ss": "HH-MM-ss"}>{currentTime}</span>
                    <span>/</span>
                    <span>{duration}</span>
                </div>
                <div className={"group play"}>
                    <i className="mdi mdi-rewind-5 fs-24" onClick={playRewind}></i>
                    <i className={(isPlaying?"ri-pause-fill":"ri-play-fill") +" fs-24"} onClick={play}></i>
                    <i className="mdi mdi-fast-forward-5 fs-24" onClick={playFastForward}></i>
                </div>
                <div className={"group control"}>
                    <SpeedController speed={speed} setPlaybackRate={setPlaybackRate}/>
                    <VolumeController volume={volume} onChangeVolume={onChangeVolume}/>
                </div>
            </div>
        </>
    )
}
