// @ts-nocheck
import {ActiveControl, CombinedState} from "../../../../../../../../store/label";
import React from "react";
import {connect} from "react-redux";
import {LabelReviewFooter as LabelReviewFooterComponent} from "../../../components/review-workspace/objects-side-bar/label-review-footer"
import {updateReviewStates} from "../../../../../../../../store/review/action";
import {activateLabel} from "../../../../../../../../store/label/action";
import ReviewClasses from "../../../work/core/review-classes";
import {cloneDeep} from "lodash";

interface OwnProps{
    labelID: number;
}

interface StateToProps {
    labels: any[];
    reviewData: ReviewClasses;
}
interface DispatchToProps {
    onActivateLabel(activatedLabelID: number | null): void;
    onUpdateReviewLabel(RawClassesData): void;
}

function mapStateToProps(state: CombinedState, own: OwnProps): StateToProps {
    const {
        Label: {
            Cmmn: {
                module: {labels}
            }
        },
        Review: {
            Cmmn: {
                annotations: {
                    data: reviewTotalData
                }
            }
        }
    } = state;

    const reviewData = reviewTotalData.classes ? reviewTotalData.classes[own.labelID] : null;

    return {
        labels,
        reviewData
    }
}
function dispatchToProps(dispatch: any): DispatchToProps {
    return {
        onActivateLabel(activatedLabelID: number | null): void {
          dispatch(activateLabel(activatedLabelID));
        },
        onUpdateReviewLabel(classData): void {
            dispatch(updateReviewStates(null, classData));
        }
    }
}

type Props = StateToProps & DispatchToProps & OwnProps;
class LabelReviewFooterContainer extends React.PureComponent<Props> {

    public constructor(props: Props) {
        super(props);
    }

    private reviewUpdate(isReject: boolean){
        const { labelID, reviewData, onUpdateReviewLabel } = this.props;
        const newClassData = cloneDeep(reviewData);
        newClassData.reject = isReject;
        newClassData.completedError = isReject === null;
        onUpdateReviewLabel(newClassData);
    }

    private nextActivate = (): void => {
        const { labelID, labels, onActivateLabel } = this.props;
        const index = labels.findIndex((label) => label.id === labelID);
        if (index === labels.length - 1) {
            onActivateLabel(null);
            return;
        }
        const nextLabel = labels[index + 1];
        onActivateLabel(nextLabel.id);
    }

    public render(): JSX.Element {
        const { labelID } = this.props;
        return (
            <LabelReviewFooterComponent
                fstBtnClick={() => {
                    this.reviewUpdate(true);
                    this.nextActivate();
                }}
                sndBtnClick={() => {
                    this.reviewUpdate(false);
                    this.nextActivate();
                }}
            />
        );
    }
}

export default connect(mapStateToProps, dispatchToProps)(LabelReviewFooterContainer);
