export const SWITCH_ROTATE_ALL = 'setting/SWITCH_ROTATE_ALL';
export const SWITCH_GRID = 'setting/SWITCH_GRID';
export const CHANGE_GRID_SIZE = 'setting/CHANGE_GRID_SIZE';
export const CHANGE_GRID_COLOR = 'setting/CHANGE_GRID_COLOR';
export const CHANGE_GRID_OPACITY = 'setting/CHANGE_GRID_OPACITY';
export const CHANGE_SHAPES_OPACITY = 'setting/CHANGE_SHAPES_OPACITY';
export const CHANGE_SELECTED_SHAPES_OPACITY = 'setting/CHANGE_SELECTED_SHAPES_OPACITY';
export const CHANGE_SHAPES_COLOR_BY = 'setting/CHANGE_SHAPES_COLOR_BY';
export const CHANGE_SHAPES_OUTLINED_BORDERS = 'setting/CHANGE_SHAPES_OUTLINED_BORDERS';
export const CHANGE_SHAPES_SHOW_PROJECTIONS = 'setting/CHANGE_SHAPES_SHOW_PROJECTIONS';
export const CHANGE_SHOW_UNLABELED_REGIONS = 'setting/CHANGE_SHOW_UNLABELED_REGIONS';
export const CHANGE_FRAME_STEP = 'setting/CHANGE_FRAME_STEP';
export const CHANGE_FRAME_SPEED = 'setting/CHANGE_FRAME_SPEED';
export const SWITCH_RESET_ZOOM = 'setting/SWITCH_RESET_ZOOM';
export const SWITCH_SMOOTH_IMAGE = 'setting/SWITCH_SMOOTH_IMAGE';
export const SWITCH_TEXT_FONT_SIZE = 'setting/SWITCH_TEXT_FONT_SIZE';
export const SWITCH_CONTROL_POINTS_SIZE = 'setting/SWITCH_CONTROL_POINTS_SIZE';
export const SWITCH_TEXT_POSITION = 'setting/SWITCH_TEXT_POSITION';
export const SWITCH_TEXT_CONTENT = 'setting/SWITCH_TEXT_CONTENT';
export const CHANGE_BRIGHTNESS_LEVEL = 'setting/CHANGE_BRIGHTNESS_LEVEL';
export const CHANGE_CONTRAST_LEVEL = 'setting/CHANGE_CONTRAST_LEVEL';
export const CHANGE_SATURATION_LEVEL = 'setting/CHANGE_SATURATION_LEVEL';
export const SWITCH_AUTO_SAVE = 'setting/SWITCH_AUTO_SAVE';
export const CHANGE_AUTO_SAVE_INTERVAL = 'setting/CHANGE_AUTO_SAVE_INTERVAL';
export const CHANGE_AAM_ZOOM_MARGIN = 'setting/CHANGE_AAM_ZOOM_MARGIN';
export const CHANGE_DEFAULT_APPROX_POLY_THRESHOLD = 'setting/CHANGE_DEFAULT_APPROX_POLY_THRESHOLD';
export const SWITCH_AUTOMATIC_BORDERING = 'setting/SWITCH_AUTOMATIC_BORDERING';
export const SWITCH_INTELLIGENT_POLYGON_CROP = 'setting/SWITCH_INTELLIGENT_POLYGON_CROP';
export const SWITCH_SHOWNIG_INTERPOLATED_TRACKS = 'setting/SWITCH_SHOWNIG_INTERPOLATED_TRACKS';
export const SWITCH_SHOWING_OBJECTS_TEXT_ALWAYS = 'setting/SWITCH_SHOWING_OBJECTS_TEXT_ALWAYS';
export const CHANGE_CANVAS_BACKGROUND_COLOR = 'setting/CHANGE_CANVAS_BACKGROUND_COLOR';
export const SWITCH_SETTINGS_DIALOG = 'setting/SWITCH_SETTINGS_DIALOG';
export const SET_SETTINGS = 'setting/SET_SETTINGS';
export const SWITCH_TOOLS_BLOCKER_STATE = 'setting/SWITCH_TOOLS_BLOCKER_STATE';
export const SWITCH_SHOWING_DELETED_FRAMES = 'setting/SWITCH_SHOWING_DELETED_FRAMES';
export const SWITCH_SHOWING_TAGS_ON_FRAME = 'setting/SWITCH_SHOWING_TAGS_ON_FRAME';
