// @ts-nocheck
import './styles.scss';
import React, {Dispatch, TransitionEvent, useEffect, useState} from 'react';
import {AnyAction} from 'redux';
import {connect} from 'react-redux';
import {Card, CardBody, CardFooter, Nav, NavItem, NavLink, TabContent, TabPane} from 'reactstrap';
import classnames from "classnames";
import SimpleBar from "simplebar-react";

import {CombinedState, DimensionType, Workspace} from 'store/label';
import LabelsList from 'pages/user/label/annotation/image/components/standard-workspace/objects-side-bar/labels-list';
import {collapseSidebar as collapseSidebarAction, updateSideBarTab} from 'store/label/action';
import {GetMessage} from "../../../../../../../../util/message";
import InstanceHeader from "../../header/InstanceHeader";
import ClassesHeader from "../../header/ClassesHeader";
import {MD_STTUS_CD, MD_TY_CD} from "../../../const";
import {useIntl} from "react-intl";
import {alertInfo} from "../../../../../../../../util/AlertUtil";
import {NextStepFooter} from "./NextStepFooter";

interface OwnProps {
    objectsList: JSX.Element;
}

interface StateToProps {
    workspace: Workspace;
    tab: string;
    visible: boolean;
    frameName: string;
    instanceNotValidCnt: number;
    classesNotValidCnt: number;
    sidebarCollapsed: boolean;
    moduleInstance: any;
    moduleStatus: MD_STTUS_CD;
}

interface DispatchToProps {
    onUpdateSideBarTab(tab: string): void;
    collapseSidebar(): void;
}

function mapStateToProps(state: CombinedState): StateToProps {
    const {
        Label: {
            Cmmn: {
                canvas: {
                    sideBarTab,
                    badges: {
                        visible: lVisible,
                        instanceNotAttrValidCnt: lInstanceNotAttrValidCnt,
                        instanceNotEditedCnt: lInstanceNotEditedCnt,
                        classesNotEditedCnt: lClassesNotValidCnt,
                    },
                    ready
                },
                sidebarCollapsed,
                module: {instance: moduleInstance, type: moduleType, status: moduleStatus},
                frames: {frame: frameData},
                workspace
            }
        },
        Review: {
            Cmmn: {
                canvas: {
                    badges: {
                        visible: reVisible,
                        instanceNotEditedCnt: reInstanceNotValidCnt,
                        classesNotEditedCnt: reClassesNotValidCnt,
                    }
                },
            }
        }
    } = state;

    let visible = false, instanceNotValidCnt = 0, classesNotValidCnt = 0, tab = sideBarTab;
    if (moduleType === MD_TY_CD.LABEL) {
        visible = lVisible;
        instanceNotValidCnt = lInstanceNotAttrValidCnt + lInstanceNotEditedCnt;
        classesNotValidCnt = lClassesNotValidCnt;
    } else if (moduleType === MD_TY_CD.REVIEW) {
        visible = reVisible;
        instanceNotValidCnt = reInstanceNotValidCnt;
        classesNotValidCnt = reClassesNotValidCnt;
    }

    if (tab === null) {
        if (moduleType === MD_TY_CD.LABEL && moduleStatus === MD_STTUS_CD.FIRST) {
            tab = "2";
        } else {
            tab = "1";
        }
    }

    return {
        workspace,
        tab,
        visible,
        ready,
        frameName: frameData.name,
        instanceNotValidCnt,
        classesNotValidCnt,
        sidebarCollapsed,
        moduleInstance,
        moduleType,
        moduleStatus,
    };
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>): DispatchToProps {
    return {
        onUpdateSideBarTab(tab: string): void {
            dispatch(updateSideBarTab(tab));
        },
        collapseSidebar(): void {
            dispatch(collapseSidebarAction());
        },
    };
}

function ObjectsSideBar(props: StateToProps & DispatchToProps & OwnProps): JSX.Element {
    const {
        visible, ready, frameName, instanceNotValidCnt, classesNotValidCnt, workspace,
        sidebarCollapsed, collapseSidebar, objectsList, moduleType, moduleInstance, moduleStatus, tab,
        onUpdateSideBarTab
    } = props;
    const type = moduleType;

    // Border Top Nav
    const intl = useIntl();

    useEffect(() => {
        if (ready && workspace === Workspace.STANDARD && moduleStatus === MD_STTUS_CD.RE && (instanceNotValidCnt != 0 || classesNotValidCnt != 0)) {
            const param = { instanceCount: instanceNotValidCnt, classCount: classesNotValidCnt}
            alertInfo('label.retouchAlert', 'review.validCntLimitAlertText', null, param, intl);
        }
    }, [ready, frameName, moduleStatus]);

    const tabToggle = (newTab: string) => {
        if (tab !== newTab) {
            onUpdateSideBarTab(newTab);
        }
    };

    const collapse = (): void => {
        const [collapser] = window.document.getElementsByClassName('cvat-objects-sidebar');
        const listener = (event: TransitionEvent): void => {
            if (event.target && event.propertyName === 'width' && event.target === collapser) {
                window.dispatchEvent(new Event('resize'));
                (collapser as HTMLElement).removeEventListener('transitionend', listener as any);
            }
        };

        if (collapser) {
            (collapser as HTMLElement).addEventListener('transitionend', listener as any);
        }

        collapseSidebar();
    };

    const is2D = moduleInstance ? moduleInstance.dimension === DimensionType.DIM_2D : true;
    const instanceBadgeVisible = visible && instanceNotValidCnt ? '' : 'd-none';
    const classesBadgeVisible = visible && classesNotValidCnt ? '' : 'd-none';

    return (
        <div className="instance-side-bar">
            <Card className="label-object-menu-div">
                <CardBody className="listContainer px-0">
                    <Nav tabs className="nav nav-tabs nav-coco2 nav-tabs-custom fw-semibold px-4">
                        <NavItem>
                            <NavLink style={{cursor: "pointer"}}
                                     className={classnames({active: tab === "1",})} onClick={() => {
                                tabToggle("1");
                            }}>
                                {GetMessage("ui.U00113")}
                                <span className={"badge rounded-pill tx-14 "+instanceBadgeVisible}>{instanceNotValidCnt}</span>
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink style={{cursor: "pointer"}}
                                     className={classnames({active: tab === "2",})} onClick={() => {
                                tabToggle("2");
                            }}>
                                {GetMessage("ui.U00114")}
                                <span className={"badge rounded-pill tx-14 " + classesBadgeVisible}>{classesNotValidCnt}</span>
                            </NavLink>
                        </NavItem>
                    </Nav>

                    <TabContent activeTab={tab} className="text-muted">
                        <TabPane tabId="1" id="svg-instance"
                                 style={{height: "calc(100vh - 220px)", minHeight: "650px"}}>
                                <Card>
                                    <CardBody className="mb-0 rounded-0 lh-lg">
                                        <InstanceHeader endTitle={type === MD_TY_CD.LABEL?GetMessage("ui.U00117"):GetMessage("ui.U00090")}/>
                                    </CardBody>
                                </Card>
                                <SimpleBar style={{maxHeight: "calc(100vh - 220px)"}} autoHide={false}>
                                    {objectsList}
                                    {/*<InstanceHeader endTitle={userRole === "01" ? GetMessage("ui.U00117") : GetMessage("ui.U00090")}/>*/}
                                </SimpleBar>
                        </TabPane>
                        <TabPane tabId="2" id="svg-classes">
                                <Card>
                                    <CardBody className="mb-0 rounded-0 lh-lg">
                                        <ClassesHeader endTitle={type === MD_TY_CD.LABEL?GetMessage("ui.U00117"):GetMessage("ui.U00090")}/>
                                    </CardBody>
                                </Card>
                                <SimpleBar style={{maxHeight: "calc(100vh - 220px)"}} autoHide={false}>
                                    <LabelsList type={type}/>
                                </SimpleBar>
                                {/*<ClassesHeader endTitle={userRole === "01" ? GetMessage("ui.U00117") : GetMessage("ui.U00090")}/>*/}
                        </TabPane>
                    </TabContent>
                </CardBody>
                <CardFooter>
                    <NextStepFooter type={type} moduleStatus={moduleStatus}/>
                </CardFooter>
            </Card>
        </div>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(ObjectsSideBar));
