//@ts-nocheck
import {useDispatch, useSelector} from "react-redux";
import {Canvas, CanvasMode} from "../../../work/canvas/typescript/canvas";
import {ActiveControl, ColorBy, ShapeType} from "../../../../../../../../store/label";
import {shift} from "../../../utils/math";
import {ObjectPopover} from "../../../components/standard-workspace/object-popover/object-popover";
import {getColor} from "../../../components/standard-workspace/objects-side-bar/shared";
import {useEffect} from "react";
import {
    activateObject as activateObjectAction,
    changeFrame as changeFrameAsync, changeGroupColor as changeGroupColorAsync,
    copyShape as copyShapeAction,
    pasteShapeAction as pasteShapeAsync,
    removeObject as removeObjectAction, switchPropagateVisibility as switchPropagateVisibilityAction,
    updateAnnotations as updateAnnotationsAsync
} from "../../../../../../../../store/label/action";
import {filterApplicableLabels} from "../../../utils/filter-applicable-labels";

interface Props{
    readonly: boolean;
    clientID: number;
    objectStates: any[];
    onCloseCanvasContextMenu(e: MouseEvent): void;
}
export function ObjectPopoverContainer(props: Props){
    // State
    const {readonly: ownReadonly, clientID, objectStates:states, onCloseCanvasContextMenu} = props;
    const {
        Label: {
            Cmmn: {
                annotations: {
                    activatedStateID,
                    zLayer: {min: minZLayer, max: maxZLayer},
                },
                module: {attributes: jobAttributes, instance: moduleInstance, labels},
                player: {
                    frame: {number: frameNumber},
                },
                canvas: {instance: canvasInstance, ready, activeControl},
            }
        },
        settings: {
            shapes: { colorBy },
        },
        shortcuts: { normalizedKeyMap },
        Review: {
            Cmmn: {
                annotations: {
                    data: reviewTotalData
                }
            }
        }
    } = useSelector(state => state);
    const activated = activatedStateID === clientID;
    const stateIDs = states.map((_state: any): number => _state.clientID);
    const index = stateIDs.indexOf(clientID);

    const objectState = states[index];
    const attributes = jobAttributes[states[index].label.id];

    const reviewData = reviewTotalData.instance ?
                            reviewTotalData.instance[clientID] ?? {reject: null, rsnCd: [], completedError: false, editable: null}
                        : {reject: null, rsnCd: [], completedError: false, editable: null};
    const readonly = ownReadonly ? ownReadonly : !reviewData.editable;

    // Dispatch
    const dispatch = useDispatch();
    const changeFrame = (frame: number): void => {
        dispatch(changeFrameAsync(frame));
    }
    const updateState = (state: any): void => {
        dispatch(updateAnnotationsAsync([state]));
    }
    const activateObject = (activatedStateID: number | null): void => {
        dispatch(activateObjectAction(activatedStateID, null, null));
    };
    const removeObject = (objectState: any): void => {
        dispatch(removeObjectAction(objectState, false));
    }
    const copyShape = (objectState: any): void => {
        dispatch(copyShapeAction(objectState));
    dispatch(pasteShapeAsync());
    };
    const switchPropagateVisibility = (visible: boolean): void => {
        dispatch(switchPropagateVisibilityAction(visible));
    }
    const changeGroupColor = (group: number, color: string): void => {
        dispatch(changeGroupColorAsync(group, color));
    }
    // TODO Logic 나누기
    const copy = (): void => {
        const { objectState, readonly, copyShape } = this.props;
        if (!readonly) {
            copyShape(objectState);
        }
    };

    const propagate = (): void => {
        if (!readonly) {
            switchPropagateVisibility(true);
        }
    };

    const edit = (): void => {
        if (!readonly && canvasInstance instanceof Canvas) {
            if (canvasInstance.mode() !== CanvasMode.IDLE) {
                canvasInstance.cancel();
            }

            canvasInstance.edit({ enabled: true, state: objectState });
        }
    }

    const remove = (): void => {
        if (!readonly) {
            removeObject(objectState);
        }
    };

    const createURL = (): void => {
        const { origin, pathname } = window.location;

        const search = `frame=${frameNumber}&type=${objectState.objectType}&serverID=${objectState.serverID}`;
        const url = `${origin}${pathname}?${search}`;
        copy(url);
    };

    const switchOrientation = (): void => {
        if (readonly) {
            return;
        }

        if (objectState.shapeType === ShapeType.CUBOID) {
            this.switchCuboidOrientation();
            return;
        }

        const reducedPoints = objectState.points.reduce(
            (acc: number[][], _: number, index: number, array: number[]): number[][] => {
                if (index % 2) {
                    acc.push([array[index - 1], array[index]]);
                }

                return acc;
            },
            [],
        );

        if (objectState.shapeType === ShapeType.POLYGON) {
            objectState.points = reducedPoints.slice(0, 1).concat(reducedPoints.reverse().slice(0, -1)).flat();
            updateState(objectState);
        } else if (objectState.shapeType === ShapeType.POLYLINE) {
            objectState.points = reducedPoints.reverse().flat();
            updateState(objectState);
        }
    };

    const toBackground = (): void => {
        if (!readonly) {
            objectState.zOrder = minZLayer - 1;
            commit();
        }
    };

    const toForeground = (): void => {
        if (!readonly) {
            objectState.zOrder = maxZLayer + 1;
            commit();
        }
    };

    const activate = (activeElementID?: number): void => {
        if (ready && activeControl === ActiveControl.CURSOR) {
            activateObject(
                objectState.clientID,
                (Number.isInteger(activeElementID) ? activeElementID : null) as number | null,
            );
        }
    };

    const changeColor = (color: string): void => {
        if (colorBy === ColorBy.INSTANCE) {
            objectState.color = color;
            commit();
        } else if (colorBy === ColorBy.GROUP) {
            changeGroupColor(objectState.group.id, color);
        }
    };

    const changeLabel = (label: any): void => {
        if (!readonly) {
            objectState.label = label;
            commit();
        }
    };

    const switchCuboidOrientation = (): void => {
        function cuboidOrientationIsLeft(points: number[]): boolean {
            return points[12] > points[0];
        }

        const { objectState, readonly } = this.props;

        if (!readonly) {
            this.resetCuboidPerspective(false);
            objectState.points = shift(objectState.points, cuboidOrientationIsLeft(objectState.points) ? 4 : -4);

            commit();
        }
    };

    const resetCuboidPerspective = (commit = true): void => {
        function cuboidOrientationIsLeft(points: number[]): boolean {
            return points[12] > points[0];
        }
        const { objectState, readonly } = this.props;

        if (!readonly) {
            const { points } = objectState;
            const minD = {
                x: (points[6] - points[2]) * 0.001,
                y: (points[3] - points[1]) * 0.001,
            };

            if (cuboidOrientationIsLeft(points)) {
                points[14] = points[10] + points[2] - points[6] + minD.x;
                points[15] = points[11] + points[3] - points[7];
                points[8] = points[10] + points[4] - points[6];
                points[9] = points[11] + points[5] - points[7] + minD.y;
                points[12] = points[14] + points[0] - points[2];
                points[13] = points[15] + points[1] - points[3] + minD.y;
            } else {
                points[10] = points[14] + points[6] - points[2] - minD.x;
                points[11] = points[15] + points[7] - points[3];
                points[12] = points[14] + points[0] - points[2];
                points[13] = points[15] + points[1] - points[3] + minD.y;
                points[8] = points[12] + points[4] - points[0] - minD.x;
                points[9] = points[13] + points[5] - points[1];
            }

            objectState.points = points;
            if (commit) commit();
        }
    };

    const commit = () => {
        if (!readonly) {
            updateState(objectState);
        }
    }
    useEffect(() => {
        commit();
    }, [])

    const applicableLabels = filterApplicableLabels(objectState, labels);

    return (
        <ObjectPopover
            normalizedKeyMap={normalizedKeyMap}
            readonly={readonly}
            activated={activated}
            objectType={objectState.objectType}
            shapeType={objectState.shapeType}
            clientID={objectState.clientID}
            serverID={objectState.serverID}
            labelID={objectState.label.id}
            labelName={objectState.label.name}
            locked={objectState.lock}
            elements={objectState.elements}
            color={getColor(objectState, colorBy)}
            colorBy={colorBy}
            labels={applicableLabels}
            attributes={attributes}
            moduleInstance={moduleInstance}
            activate={activate}
            copy={copy}
            propagate={propagate}
            createURL={createURL}
            switchOrientation={switchOrientation}
            toBackground={toBackground}
            toForeground={toForeground}
            remove={remove}
            changeLabel={changeLabel}
            changeColor={changeColor}
            resetCuboidPerspective={resetCuboidPerspective}
            edit={edit}
            onCloseCanvasContextMenu={onCloseCanvasContextMenu}
        />
    )
}
