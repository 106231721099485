import SimpleBar from "simplebar-react";
import {Card, CardBody, CardHeader, Col, Label, ListGroup, OffcanvasBody, Row, UncontrolledTooltip} from "reactstrap";
import {GetMessageIntl} from "../../../../../../../../util/message";
import {Controller, useFormContext} from "react-hook-form";
import Select from "react-select";
import {defaultSelectStyle, formatGroupLabelNoCount} from "../../../../../../../../components/constants/select";
import LabelShapeRow from "../../label/LabelShapeRow";
import LabelShapeAttrDivs from "../../label/LabelShapeAttrDivs";
import React, {useEffect, useState} from "react";
import {useIntl} from "react-intl";
import {useDispatch, useSelector} from "react-redux";
import {ManagerWorkflowService} from "../../../../../../../../apis/api/ManagerWorkflowService";
import {
    addManageWorkflowLabelClassItem,
    deleteManageWorkflowLabelClassItem, setManageWorkflowLabelClassItems, setSelectedManageWorkflowLabelClassId
} from "../../../../../../../../store/manage/project/workflow/label/action";

let labelId = 0;
const getLabelId = () => labelId++;
const colorPickerColors = ["#FF5A5A", "#FF8267", "#FFBF5F", "#FEF161", "#C5EF63", "#8CF280", "#62DDF2", "#6599FF", "#6563FF", "#A366FF"
    , "#FF2F82", "#FD5D00", "#FFD705", "#FFF85C", "#B3FF00", "#00FF9F", "#00DAFF", "#0056FF", "#0300FF", "#6600FF", "#FFFFFF"];
function ShapeConfigDiv() {
    const intl = useIntl();
    const dispatch = useDispatch();
    const prjctId = sessionStorage.getItem("prjctId");
    const { control:controlLabel, register:registerLabel, unregister: unregisterLabel, setValue:setValueLabel, getValues:getValuesLabel, reset:resetLabel, resetField:resetFieldLabel
        , watch:watchLabel, formState: { errors:errorsLabel },} = useFormContext();
    const {
        initFlag,
        selectionNode,
        selectedLabelClassId,
        selectedLabelAttrId,
        selectedLabelAttrItemId,
        shapeConfig,
        labelClassItems,
        labelAttrItems,} = useSelector((state) => ({
        initFlag: state.ManageWorkflowLabel.initFlag,
        selectionNode: state.ManageWorkflowModule.selectionNode,
        selectedLabelClassId: state.ManageWorkflowLabel.selectedLabelClassId,
        selectedLabelAttrId: state.ManageWorkflowLabel.selectedLabelAttrId,
        selectedLabelAttrItemId: state.ManageWorkflowLabel.selectedLabelAttrItemId,
        shapeConfig: state.ManageWorkflowLabel.shapeConfig,
        labelClassItems: state.ManageWorkflowLabel.labelClassItems,
        labelAttrItems: state.ManageWorkflowLabel.labelAttrItems,
    }));

    const [LabelTyOptions, setLabelTyOptions] = useState([]);
    const [isDisabledData, setIsDisabledData] = useState(false);

    useEffect(()=>{
        if(initFlag){
            resetLabel();
            dispatch(setSelectedManageWorkflowLabelClassId(-1));
            workflowUseLabelOption();
            setValueLabel('LabelTy', 'new');
            if(shapeConfig){
                for(const [key, value] of Object.entries(shapeConfig)){
                    setValueLabel(key, (value ? value : ""));
                    if(key === 'labels'){
                        dispatch(setManageWorkflowLabelClassItems(value));
                    }else if(key === 'countLimit'){
                        for(const [aKey, aValue] of Object.entries(value)) {
                            setValueLabel(aKey, (aValue ? aValue : ""));
                        }
                    }
                }
            }
        }
    }, [initFlag]);


    const workflowPlaceholderMsg = GetMessageIntl(intl,"common.newCreate");
    const workflowUseLabelOption = async()=>{
        await ManagerWorkflowService.getWorkflowUseLabelList({
            'prjctId': prjctId,
            'configNm': 'shapeConfig'
        }).then((result)=>{
            let groupedOptions = [];
            groupedOptions.push({options: [{ value: 'new', label: workflowPlaceholderMsg}]});
            if(result.data.data){
                let resultData = result.data.data;
                resultData.map((data)=>{
                    let optionItem = new Object();
                    optionItem.label = data.wrkflowNm;
                    optionItem.value = data.wrkflowId;
                    if(data.labelList && data.labelList.length>0){
                        let labelList = data.labelList;
                        optionItem.options = labelList.map((label)=>{
                            return { value: label.moduleId, label: label.moduleNm, config: label.moduleConfig }
                        })
                    }
                    groupedOptions.push(optionItem);
                })
            }
            setLabelTyOptions(groupedOptions);
        });
    }

    const handleChangeLabelTy=(event)=>{
        console.log('handleChangeLabelTy',event);
        let targetConfig = event.config;
        if(targetConfig){
            targetConfig = JSON.parse(targetConfig);
            for(const [key, value] of Object.entries(targetConfig)){
                setValueLabel(key, (value ? value : ""));
                if(key === 'labels'){
                    dispatch(setManageWorkflowLabelClassItems(value));
                }else if(key === 'countLimit'){
                    for(const [aKey, aValue] of Object.entries(value)) {
                        setValueLabel(aKey, (aValue ? aValue : ""));
                    }
                }
            }
            setValueLabel('LabelTy', event.value);
        }else{
            dispatch(setManageWorkflowLabelClassItems([]));
        }
    }

    const addLabelClassItem = ()=>{

        // 이름 생성 중복 체크
        let basicLabelClassNm = 'Untitled_class';
        let labelClassNm = basicLabelClassNm+'(1)';
        let classItemsCnt = labelClassItems.length;
        let maxLabelId = 0;
        for(let i=0; i<classItemsCnt;i++){
            let dupNode = labelClassItems.find((item)=>item.name == labelClassNm);
            if(dupNode!=undefined){
                labelClassNm = basicLabelClassNm+'('+(i+2)+')';
            }
        }

        let usedColor = [];
        if(classItemsCnt>0){
            const maxLabelItem = labelClassItems.reduce((previous, current) => {
                return current.id > previous.id ? current : previous;
            });
            maxLabelId = Number(maxLabelItem.id)+1;
            usedColor = labelClassItems.map((item) => item.color.toUpperCase());
        }

        let possibleColor = colorPickerColors.filter(color => !usedColor.includes(color)); // 결과

        const labelClassItem = {
            id: maxLabelId,
            name: labelClassNm,
            color: possibleColor.length>0?possibleColor[0]:'#FF5A5A',
            type: 'rectangle',
            attributes: []
        };

        dispatch(addManageWorkflowLabelClassItem(labelClassItem))
    }

    const deleteLabelClassItem = ()=>{
        unregisterLabel(`classItem.${selectedLabelClassId}`);
        dispatch(deleteManageWorkflowLabelClassItem(selectedLabelClassId));
    }

    const copyLabelClassItem = ()=>{
        if(selectedLabelClassId !== -1){

            const maxLabelItem = labelClassItems.reduce((previous, current) => {
                return current.id > previous.id ? current : previous;
            });
            const maxLabelId = Number(maxLabelItem.id)+1;
            const targetLabelClassItem = labelClassItems.find((labelClassItem) => labelClassItem.id === selectedLabelClassId);

            let copyLabelClassNm = targetLabelClassItem.name+'(1)';
            let classItemsCnt = labelClassItems.length;
            for(let i=0; i<classItemsCnt;i++){
                let dupNode = labelClassItems.find((item)=>item.name == copyLabelClassNm);
                if(dupNode!=undefined){
                    copyLabelClassNm = targetLabelClassItem.name+'('+(i+2)+')';
                }
            }

            let usedColor = labelClassItems.map((item) => item.color.toUpperCase()); // 사용한 컬러
            let possibleColor = colorPickerColors.filter(color => !usedColor.includes(color)); // 결과

            const copyLabelClassItem = {...targetLabelClassItem, 'id': maxLabelId, 'name':copyLabelClassNm, color: possibleColor.length>0?possibleColor[0]:'#FF5A5A'};
            dispatch(addManageWorkflowLabelClassItem(copyLabelClassItem))
        }
    }

    return(
        <>
            <SimpleBar style={{ maxHeight: "calc(100vh - 229px)" }} autoHide={false} className="simplebar-track-coco">
                <OffcanvasBody style={{ minHeight: "300px"}} className={"pd-x-0-f pd-b-0"}>
                    <div className={"pd-x-24"}>
                        <h5 className="module-title">
                            <i className="ri-price-tag-3-line"></i>{GetMessageIntl(intl,"workflow.labelBasicInfoSetting")}
                        </h5>
                        <Row>
                            <Col md={6}>
                                <Label htmlFor="LabelTy" className="form-label">{GetMessageIntl(intl,"workflow.createOrGet")}<span className="text-coco-danger-400">*</span></Label>
                                <div className={errorsLabel.LabelTy?"validation-select-error":""}>
                                    <Controller
                                        name="LabelTy"
                                        id="LabelTy"
                                        rules={{
                                            required: true,
                                        }}
                                        defaultValue={LabelTyOptions[0]}
                                        render={({ field: { onChange, value, ref, name } }) => (
                                            <Select
                                                options={LabelTyOptions}
                                                placeholder={GetMessageIntl(intl,"common.selectOptions")}
                                                formatGroupLabel={formatGroupLabelNoCount}
                                                styles={defaultSelectStyle}
                                                className="mb-0"
                                                maxMenuHeight={250}
                                                getOptionLabel={option => {
                                                    return option.label}}
                                                onChange={val => {
                                                    onChange(val.value);
                                                    handleChangeLabelTy(val);
                                                }}
                                                value={value?LabelTyOptions.map((groupOption) => {
                                                    let options = groupOption.options.find((o) => o.value == value);
                                                    if(options !== undefined){
                                                        return options;
                                                    }
                                                }):null}
                                                isDisabled={isDisabledData}
                                            />
                                        )}
                                        control={controlLabel}
                                    />
                                </div>
                                {errorsLabel.LabelTy && <p className={"validation-error-message"}>{GetMessageIntl(intl,"validation.required")}</p>}
                            </Col>
                            <Col md={6}>
                                <div className={"d-flex align-items-center justify-content-between text-coco-gray-400"}>
                                    <Label htmlFor="ObjectMin" className="form-label text-coco-gray-400">{GetMessageIntl(intl,"workflow.labelInstanceLimit")}</Label>
                                    <i className="ri-information-line tx-18" id={"labelInstanceLimitTooltip"}/>
                                    <UncontrolledTooltip placement="bottom" target="labelInstanceLimitTooltip" innerClassName={"text-start"} dangerouslySetInnerHTML={{ __html: GetMessageIntl(intl,"workflow.labelInstanceLimitTooltip")}}>
                                    </UncontrolledTooltip>
                                </div>
                                <div>
                                    <div className="input-group">
                                        <span className="input-group-text">{GetMessageIntl(intl, "workflow.labelMin")}&{GetMessageIntl(intl, "workflow.labelMax")}</span>
                                        <input type="text" {...registerLabel("objectMin",{
                                            pattern: {
                                                value: /^[0-9]+$/,
                                                message:GetMessageIntl(intl,"validation.onlyNumber")
                                            },
                                            validate: (value, values) => {
                                                const objectMax = getValuesLabel("objectMax");
                                                if(objectMax !=''){
                                                    if(parseInt(value)>objectMax){
                                                        return GetMessageIntl(intl,"workflow.shapeConfig.instanceGreater");
                                                    }
                                                }

                                                if(parseInt(value) <= 0){
                                                    return GetMessageIntl(intl,"validation.greaterThanZero");
                                                }
                                            }
                                        })}
                                               placeholder={GetMessageIntl(intl, "workflow.labelMin")} className="form-control"
                                        />
                                        <input type="text" {...registerLabel("objectMax",{
                                            pattern: {
                                                value: /^[0-9]+$/,
                                                message:GetMessageIntl(intl,"validation.onlyNumber")
                                            },
                                            validate: (value, values) => {
                                                if(parseInt(value) <= 0){
                                                    return GetMessageIntl(intl,"validation.greaterThanZero");
                                                }
                                            }
                                        })} placeholder={GetMessageIntl(intl, "workflow.labelMax")} className="form-control" />
                                    </div>
                                    {errorsLabel.objectMin && <p className="validation-error-message mb-0">{errorsLabel.objectMin.message}</p>}
                                    {!errorsLabel.objectMin && errorsLabel.objectMax && <p className="validation-error-message mb-0">{errorsLabel.objectMax.message}</p>}
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <div className={"mt-24 border-top d-flex align-items-start justify-content-center"}>
                        <Col className={"col-xl-4-5"}>
                            <Card className="shadow-none mb-0">
                                <CardHeader className="pd-y-16 pd-x-22 border-0 d-flex align-items-center justify-content-between tx-16 lh-18 text-coco-gray-700 fw-500">
                                    {GetMessageIntl(intl, "workflow.labelClass")}
                                    <div className="d-flex align-items-center text-coco-gray-400">
                                        <i className="ri-add-circle-line me-1 addProjectClass text-pointer" onClick={addLabelClassItem} ></i>
                                        <i className="ri-file-copy-2-line me-1 copyProjectClass text-pointer" onClick={copyLabelClassItem}></i>
                                        <i className="ri-delete-bin-line deleteProjectClass text-pointer" onClick={deleteLabelClassItem}></i>
                                    </div>
                                </CardHeader>
                                <CardBody className="p-0 projectClassListDiv">
                                    <SimpleBar className="table-responsive" style={{height: "calc(100vh - 430px)"}}>
                                        {
                                            labelClassItems.length>0?
                                                <ListGroup className="text-coco-gray-700 mb-0">
                                                    <LabelShapeRow {...{ controlLabel, registerLabel, errorsLabel }}/>
                                                </ListGroup>
                                                :
                                                <div className={"d-flex align-items-center justify-content-center"}
                                                     style={{height: "calc(100vh - 430px)"}}>
                                                    <span className="text-center ht-30 text-coco-gray-400">{GetMessageIntl(intl, "workflow.labelClassAddMsg")}</span>
                                                </div>
                                        }
                                    </SimpleBar>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col className={"col-xl-7-5 attribute-card-div"} style={{borderLeft: "1px dashed var(--coco-gray-100)"}}>
                            <LabelShapeAttrDivs/>
                            {/*<LabelShapeAttrDivs rowsData={labelAttrData} rowClick={} handleChange={}/>*/}
                        </Col>
                    </div>
                </OffcanvasBody>
            </SimpleBar>
        </>
    );
}


export default ShapeConfigDiv;

