import React, {useEffect, useRef, useState} from 'react';
import BreadCrumb from "../../components/common/BreadCrumb";
import {Button, Col, Container, Input, Row} from "reactstrap";
import {GetMessage} from "../../util/message";

import {SystemService} from "../../apis/api/SystemService";
import "rc-tree/assets/index.css"
import Tree from 'rc-tree';
import JsFileDownloader from "js-file-downloader";

function ServerLog() {

    const [logData, setLogData] = useState([]);
    const [logContent, setLogContent] = useState("");
    const [fileName, setFileName] = useState("");

    useEffect(() => {

        let param = {
            operation: 'LIST',
            filePath: '',
            endPos: ''
        };

        SystemService.getLogList(param)
            .then(res => {
                setLogData(res.data.data);
            });
    }, [])

    const onSelect = (selectedKeys, info) => {
        if (info.node.TYPE !== 'D') {
            let param = {
                operation: 'CONTENT',
                filePath: selectedKeys[0],
                endPos: -1
            };

            setFileName(param.filePath);
            SystemService.getLogList(param)
                .then(res => {
                    setLogContent(res.data.data.data);
                });
        }
    };

    const onChangeTextArea = () => {
        // ignore
    };


    function downloadFile() {
        let userInfoJson = JSON.parse(sessionStorage.getItem('coconutUserInfo'));
        let accessToken = userInfoJson.accessToken;
        if (fileName) {

            let lastIdx = fileName.lastIndexOf('\\');
            let realName = fileName.substring(lastIdx+1, fileName.length);
            let param = {
                filePath: fileName,
                fileName: realName
            }
            new JsFileDownloader({
                url: process.env.REACT_APP_API_HOST + '/service/sa/logDownload',
                method: 'POST',
                contentType: 'application/json',
                filename:realName,
                includeCredentials: true,
                // headers: [
                //     {name: 'Authorization', value: 'Bearer ' + accessToken}
                // ],
                body: JSON.stringify(param)
            }).then(function () {
                // Called when download ended
            }).catch(function (error) {
                // Called when an error occurred
            });
        }
    }


    return (
        <>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title={GetMessage('menu.SAST0000')} pageTitle="Laflow"/>
                    <Row>
                        <Col xxl={2} style={{height: "780px"}}>
                            <div className={"pd-y-05"}>
                                <Tree style={{height: "750px"}}
                                    defaultExpandAll
                                    treeData={logData}
                                    onSelect={onSelect}
                                />
                            </div>
                        </Col>
                        <Col xxl={10} style={{height: "750px"}}>
                            <Row>
                                <Col className={"text-right"} style={{paddingRight:"0px"}}>
                                    <Button onClick={downloadFile}>다운로드</Button>
                                </Col>
                            </Row>
                            <Row className="pd-t-6">
                                <Input type="textarea" name="logContent" id="logContent" rows={35} value={logContent} onChange={onChangeTextArea}/>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
}

export default ServerLog;
