import React from 'react';
import {useController} from "react-hook-form";
import {useIntl} from "react-intl";
import {GetMessageIntl} from "../../util/message";
import {defaultSelectStyle, formatGroupLabelNoCount} from "../constants/select";
import Select from "react-select";

const CustomSelect = ({ control, name, option }) => {
    const intl = useIntl();
    const {
        field,
        fieldState: { invalid, isTouched, isDirty },
        formState: { touchedFields, dirtyFields }
    } = useController({
        name,
        control,
        rules: option.rules? option.rules: {},
    });

    console.log(field.value);

    return (
        <Select
            options={option.options?option.options:[]}
            placeholder={option.placeholder? option.placeholder: GetMessageIntl(intl,"common.selectOptions")}
            formatGroupLabel={option.formatGroupLagel ? option.formatGroupLagel : formatGroupLabelNoCount}
            styles={option.style? option.style: defaultSelectStyle}
            className="mb-0"
            menuPortalTarget={document.body}
            menuPosition={'fixed'}
            menuPlacement={'auto'}
            maxMenuHeight={250}
            getOptionLabel={option => {
                return option.label}}
            onChange={val => {
                if(option.isMultiSingle){
                    field.onChange(val[val.length-1])
                }else{
                    field.onChange(val.value);
                }
                if(option.onChange){
                    option.onChange(val);
                    console.log('CustomSelect Component', option.onChange);
                }
            }}
            value={field.value?
                    option.isGroup?
                        option.options.map((groupOption) => {
                            let options = groupOption.options.find((o) => o.value == field.value);
                            if(options !== undefined){
                                return options;
                            }
                        })
                        :
                        option.options.find((c) => c.value === field.value)
                    :null}
            isDisabled={option.isDisabled? option.isDisabled: false}
            isSearchable={option.isSearchable? option.isSearchable: false}
            isMulti={option.isMulti? option.isMulti: false}
        />
    );
};

export default CustomSelect;