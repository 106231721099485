import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import {BrowserRouter} from "react-router-dom";
import { Provider } from "react-redux";
import reportWebVitals from './reportWebVitals';
import {configureStore, getRootStore} from "./store";

import {flattenMessges} from "./util/message";
import {IntlProvider} from "react-intl/lib";
import koMsg from "./lang/ko.json";
import enUsMsg from "./lang/en-US.json";
import {DndProvider} from "react-dnd";
import {HTML5Backend} from "react-dnd-html5-backend";

const locale = localStorage.getItem("locale") ?? "ko";
const messages = {
    ko: koMsg,
    "en-US": enUsMsg,
};

if (process.env.NODE_ENV === 'production') {
    console.log = () => {}
    console.error = () => {}
    console.debug = () => {}
}

configureStore({});
const rootStore = getRootStore();
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <IntlProvider locale={locale} messages={flattenMessges(messages[locale])} defaultLocale={"ko"}>
        <Provider store={rootStore}>
            <DndProvider backend={HTML5Backend}>
              <React.Fragment>
                  <BrowserRouter basename={process.env.PUBLIC_URL}>
                    <App />
                  </BrowserRouter>
              </React.Fragment>
            </DndProvider>
        </Provider>
    </IntlProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
