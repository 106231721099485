// Copyright (C) 2020-2022 Intel Corporation
// Copyright (C) 2023 CVAT.ai Corporation
//
// SPDX-License-Identifier: MIT

import _core from 'pages/user/label/annotation/image/work/core/api';
import ObjectState from './work/core/object-state';
import Webhook from './work/core/webhook';
import MLModel from './work/core/ml-model';
import { ModelProvider } from './work/core/lambda-manager';
import {
    Label, Attribute, RawAttribute, RawLabel,
} from './work/core/labels';
import { Job, Task } from './work/core/session';
import {
    ShapeType, LabelType, ModelKind, ModelProviders, ModelReturnType,
} from './work/core/enums';
import { Storage, StorageData } from './work/core/storage';
import Issue from './work/core/issue';
import Comment from './work/core/comment';
import { SocialAuthMethods, SocialAuthMethod } from './work/core/auth-methods';

const core: any = _core;

core.config.backendAPI = '/api';
core.config.origin = window.location.origin;
core.config.uploadChunkSize = 100;
(globalThis as any).core = core;

function getCore(): any {
    return core;
}

export {
    getCore,
    ObjectState,
    Label,
    Job,
    Task,
    Attribute,
    ShapeType,
    LabelType,
    Storage,
    Webhook,
    SocialAuthMethod,
    Issue,
    Comment,
    MLModel,
    ModelKind,
    ModelProviders,
    ModelReturnType,
};

export type {
    RawAttribute,
    RawLabel,
    StorageData,
    SocialAuthMethods,
    ModelProvider,
};
