import {GetMessageIntl} from "./message";
import Swal from "sweetalert2";

const _defaultAlertOption = {
    allowEscapeKey: false,
    allowEnterKey: false,
    allowOutsideClick: false,
    buttonsStyling: false,
    showCancelButton: true,
    customClass: {
        confirmButton: 'btn btn-coco-1 w-sm',
        cancelButton: 'btn btn-coco-3 w-sm ml-16'
    },
}

export function confirm(titleMsg, textMsg, callback, failCallback, param, intl) {
    const option = {..._defaultAlertOption};
    option.icon = 'question';
    option.confirmButtonText = GetMessageIntl(intl, "ui.U00083");
    option.cancelButtonText = GetMessageIntl(intl, "ui.U00084");
    option.showCancelButton = true;
    swalIntl(titleMsg, textMsg, option, callback, failCallback, param, null, intl);
}

export function confirmWarning(titleMsg, textMsg, callback, failCallback, param, intl) {
    const option = {..._defaultAlertOption};
    option.icon = 'warning';
    option.confirmButtonText = GetMessageIntl(intl, "ui.U00083");
    option.cancelButtonText = GetMessageIntl(intl, "ui.U00084");
    option.showCancelButton = true;
    swalIntl(titleMsg, textMsg, option, callback, failCallback, param, null, intl);
}

export function confirmWarningClose(titleMsg, textMsg, callback, failCallback, param, intl) {
    const option = {..._defaultAlertOption};
    option.icon = 'warning';
    option.confirmButtonText = GetMessageIntl(intl, "ui.U00083");
    option.cancelButtonText = GetMessageIntl(intl, "ui.U00084");
    option.showCloseButton= true;
    option.showCancelButton = true;
    swalIntl(titleMsg, textMsg, option, callback, failCallback, param, null, intl);
}


export function confirmSuccess(titleMsg, textMsg, callback, failCallback, param, intl) {
    const option = {..._defaultAlertOption};
    option.icon = 'success';
    option.confirmButtonText = GetMessageIntl(intl, "ui.U00083");
    option.cancelButtonText = GetMessageIntl(intl, "ui.U00084");
    option.showCancelButton = true;
    swalIntl(titleMsg, textMsg, option, callback, failCallback, param, null, intl);
}

export function confirm3Button(titleMsg, textMsg, callback, callback2, callback3, param, buttonOption, intl) {
    const option = {..._defaultAlertOption};
    option.icon = 'question';
    option.confirmButtonText = buttonOption.confirmButtonText?buttonOption.confirmButtonText:GetMessageIntl(intl, "ui.U00083");
    option.denyButtonText = buttonOption.denyButtonText?buttonOption.denyButtonText: GetMessageIntl(intl, "ui.U00083");
    option.cancelButtonText = buttonOption.cancelButtonText?buttonOption.cancelButtonText: GetMessageIntl(intl, "ui.U00084");
    option.showCancelButton = true;
    option.showDenyButton = true;
    option.customClass ={
        confirmButton: 'btn btn-coco-1 wd-349px mb-8',
        denyButton: 'btn btn-coco-1 bg-coco-secondary-500-f wd-349px mb-8',
        cancelButton: 'btn btn-coco-3 wd-349px'
    };
    swalIntl3Button(titleMsg, textMsg, option, callback, callback2, callback3, param, null, intl);
}

export function alertWarning(titleMsg, textMsg, callback, param, intl) {
    const option = {..._defaultAlertOption};
    option.icon = 'warning';
    option.confirmButtonText = GetMessageIntl(intl, "ui.U00083");
    //option.cancelButtonText = GetMessageIntl(intl, "ui.U00084");
    option.showCancelButton = false;
    option.customClass ={confirmButton: 'btn btn-coco-1 wd-349px'};
    swalIntl(titleMsg, textMsg, option, callback, null, param, null, intl);
}

export function alertSuccess(titleMsg, textMsg, callback, param, intl) {
    const option = {..._defaultAlertOption};
    option.icon = 'success';
    option.confirmButtonText = GetMessageIntl(intl, "ui.U00001");
    option.showCancelButton = false;
    option.customClass ={confirmButton: 'btn btn-coco-1 wd-349px'};
    swalIntl(titleMsg, textMsg, option, callback, null, param, null, intl);
}


export function alertError(titleMsg, textMsg, callback, param, defaultMessage, intl) {
    const option = {..._defaultAlertOption};
    option.confirmButtonText = GetMessageIntl(intl, "ui.U00001");
    option.showCancelButton = false;
    option.icon = 'error';
    option.customClass ={confirmButton: 'btn btn-coco-1 wd-349px'};
    swalIntl(titleMsg, textMsg, option, callback, null, param, defaultMessage, intl);
}


export function alertInfo(titleMsg, textMsg, callback, param, intl) {
    const option = {..._defaultAlertOption};
    option.confirmButtonText = GetMessageIntl(intl, "ui.U00001");
    option.showCancelButton = false;
    option.icon = 'info';
    option.customClass ={confirmButton: 'btn btn-coco-1 wd-349px'};
    swalIntl(titleMsg, textMsg, option, callback, null, param, null, intl);
}

// function swal(titleMsg, textMsg, option, callback, failCallback, param) {
//     option.title = GetMessage(titleMsg == '' ? "ui.U00010" : titleMsg)
//     option.text = GetMessage(textMsg == '' ? "ui.U00011" : textMsg)
//
//     Swal.fire(option)
//         .then((result) => {
//             if (result.isConfirmed) {
//                 if (typeof callback === 'function') callback();
//             }
//             if (!result.isConfirmed) {
//                 if (typeof failCallback === 'function') failCallback();
//             }
//         });
// }

function swalIntl(titleMsg, textMsg, option, callback, failCallback, param, defaultMessage, intl) {
    if (defaultMessage && defaultMessage !== '') {
        option.html = defaultMessage;
    } else {
        if (titleMsg === '') {
            option.title = null;
        } else {
            option.title = GetMessageIntl(intl, titleMsg, param)
        }
        if (textMsg === '') {
            option.text = null;
        } else {
            option.html = GetMessageIntl(intl, textMsg, param)
        }
    }

    Swal.fire(option)
        .then((result) => {
            if (result.isConfirmed) {
                if (typeof callback === 'function') callback();
            }
            if (!result.isConfirmed) {
                if (typeof failCallback === 'function') failCallback();
            }
        });
}


function swalIntl3Button(titleMsg, textMsg, option, callback1, callback2, callback3, param, defaultMessage, intl) {
    console.log(titleMsg, textMsg);
    if (defaultMessage && defaultMessage !== '') {
        option.html = defaultMessage;
    } else {
        if (titleMsg === '') {
            option.title = null;
        } else {
            option.title = GetMessageIntl(intl, titleMsg, param)
        }
        if (textMsg === '') {
            option.text = null;
        } else {
            option.html = GetMessageIntl(intl, textMsg, param)
        }
    }

    console.log(option)
    Swal.fire(option)
        .then((result) => {
            if (result.isConfirmed) {
                if (typeof callback1 === 'function') callback1();
            }else if(result.isDenied){
                if (typeof callback2 === 'function') callback2();
            }else{
                if (typeof callback3 === 'function') callback3();
            }
        });
}
