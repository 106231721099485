import React, {useEffect, useRef, useState} from 'react';
import BreadCrumb from "../../components/common/BreadCrumb";
import {
    Card,
    CardHeader,
    Col,
    Container,
    Input,
    Row,
    CardBody,
} from "reactstrap";
import {useDispatch, useSelector} from "react-redux";
import Flatpickr from "react-flatpickr";
import button from "bootstrap/js/src/button";
import ReactPaginate from "react-paginate";
import PageLeftInfomation from "../../components/common/PageLeftInfomation";
import {GetMessage, GetMessageIntl} from "../../util/message";
import Select from "react-select";
import {defaultSelectStyle} from "../../components/constants/select";
import {AgGridReact} from "ag-grid-react";
import {AgRowStyle} from "../../components/common/AgGridCustomStyle";
import {BoardService} from "../../apis/api/BoardService";
import {
    board_otoSwitchAddModalFlag,
    board_otoSwitchViewModalFlag,
    board_setRowData, otoSetCnTitle, otoSetCnTy, otoSetCurrentPage, otoSetRegDtm, otoSetReplyYn, otoSetSearchParam
} from "../../store/board/action";
import AskView from "./AskView";
import AskAddModal from "./AskAddModal";
import {BoardUtil} from "../../util/BoardUtil";
import {useIntl} from "react-intl";
import {useHistory} from "react-router-dom";

function BoardAskList() {
    const dis = useDispatch();
    const intl = useIntl();

    const refComp = useRef();
    const selectInputRef = useRef(null);

    const coconutUserInfo = JSON.parse(sessionStorage.getItem("coconutUserInfo"));
    const userRole = coconutUserInfo.userRole;

    const {
        viewModalFlag,
        addModalFlag,
        cnTitle,
        cnTy,
        replyYn,
        regDtm
    } = useSelector((state) => ({
        viewModalFlag: state.Board.otoViewModalFlag,
        addModalFlag: state.Board.otoAddModalFlag,
        cnTitle: state.Board.otoCnTitle,
        cnTy: state.Board.otoCnTy,
        replyYn: state.Board.otoReplyYn,
        regDtm: state.Board.otoRegDtm
    }))

    const cnTyOption = [
        { label: GetMessage("board.allCnTy"), value: "" },
        { label: GetMessage("board.task"), value: "01" },
        { label: GetMessage("board.point"), value: "02" },
        { label: GetMessage("board.platform"), value: "03" },
        { label: GetMessage("board.userInfo"), value: "04" }
    ]

    const answerTyOption = [
        { label: GetMessage("board.allReplyYn"), value: "" },
        { label: GetMessage("board.replyY"), value: "Y" },
        { label: GetMessage("board.replyN"), value: "N" },
    ]

    /*
        TITLE 오른쪽 메인 버튼 컴포넌트
        페이지마다 유동
     */
    const ButtonComponent = () => {
        let btnStr = GetMessage("board.registOto");
        return (
            <button type="button" className="btn btn-coco-1"
                    onClick={() => {
                        dis(board_otoSwitchAddModalFlag())
                    }}
            >
                {btnStr}
            </button>
        )
    }

    useEffect(()=>{
        if(viewModalFlag === true){
            dis(board_otoSwitchViewModalFlag())
        }
        if(addModalFlag === true){
            dis(board_otoSwitchAddModalFlag())
        }
    }, [])

    // 기존 State 초기화 -> Param 재저장
    const handleSearch = () => {
        dis(otoSetCurrentPage(1));
        let obj = {};
        if (cnTitle !== "") {
            obj.CN_TITLE = cnTitle;
        }
        if (regDtm !== "") {
            obj.REG_DTM = regDtm;
        }
        if (cnTy !== ""){
            obj.CN_TY = cnTy;
        }
        if(replyYn !== ""){
            obj.REPLY_YN = replyYn;
        }
        dis(otoSetSearchParam(obj));
    }

    // Input, Select 등 State로 조정
    const handleReset = () => {
        dis(otoSetSearchParam({}));
        dis(otoSetCurrentPage(1));
        dis(otoSetReplyYn(""))
        dis(otoSetCnTitle(""));
        dis(otoSetCnTy(""));
        dis(otoSetRegDtm(""));
        refComp.current.flatpickr.clear();
    }

    return (
        <>
            <div className="page-content">
                <Container fluid>
                    {viewModalFlag === false ?
                        <>
                            <BreadCrumb title={GetMessage('menu.MBRD0003')} pageTitle="Laflow" ButtonComponent={(userRole === '20') ? ButtonComponent : undefined}/>
                            <Row>
                                <Card className="shadow-none mg-b-16">
                                    <CardHeader className="border-0 rounded p-0">
                                        <Row className="g-3">
                                            <Col lg={2}>
                                                <div className="position-relative form-search">
                                                    <Input type="text"
                                                           className="form-control"
                                                           value={cnTitle}
                                                           onChange={(e) => {
                                                               dis(otoSetCnTitle(e.target.value));
                                                           }}
                                                           id="search-input"
                                                           placeholder={GetMessage("board.searchPlaceHolder")} />
                                                    <span className="ri-search-line search-widget-icon"></span>
                                                    <span className="ri-close-line search-widget-icon search-widget-icon-close d-none" id="search-close-icon"></span>
                                                </div>
                                            </Col>
                                            <Col lg={2}>
                                                <Select
                                                    styles={defaultSelectStyle}
                                                    className="mb-0"
                                                    ref={selectInputRef}
                                                    onChange={(e) => {
                                                        dis(otoSetCnTy(e.value));
                                                    }}
                                                    defaultValue={cnTyOption[0]}
                                                    value={cnTyOption.find(item => item.value === cnTy) || cnTyOption[0]}
                                                    options={cnTyOption}
                                                    isSearchable={false}
                                                />
                                            </Col>
                                            <Col lg={2}>
                                                <Select
                                                    styles={defaultSelectStyle}
                                                    className="mb-0"
                                                    onChange={(e) => {
                                                        dis(otoSetReplyYn(e.value));
                                                    }}
                                                    value={answerTyOption.find(item => item.value === replyYn)}
                                                    defaultValue={answerTyOption[0]}
                                                    options={answerTyOption}
                                                    isSearchable={false}
                                                />
                                            </Col>
                                            <Col lg={2}>
                                                <Flatpickr
                                                    ref={refComp}
                                                    className="form-control"
                                                    options={{
                                                        mode: "range",
                                                        maxDate: 'today',
                                                        dateFormat: "Y-m-d",
                                                    }}
                                                    value={regDtm.replace('~', 'to')}
                                                    placeholder={GetMessage("common.regDate")}
                                                    onChange={(selectedDates, dateStr, instance) => {
                                                        const tmp = dateStr.replace('to', '~');
                                                        if (tmp !== regDtm) {
                                                            dis(otoSetRegDtm(tmp));
                                                        }
                                                        instance.element.value = tmp;
                                                    }}
                                                />
                                            </Col>
                                            <Col xl={1} className={"align-self-center"} style={{maxWidth:"75px"}}>
                                                <div className={"d-flex"}>
                                                    <button className="btn btn-coco-outline-1"
                                                            onClick={(e) => {handleSearch()}}>
                                                        {GetMessage("common.apply")}
                                                    </button>
                                                </div>
                                            </Col>
                                            <Col className={"pd-0 align-self-center"}>
                                                <div className={"d-flex"}>
                                                    <i  className="ri-refresh-line text-coco-gray-200 fs-24 align-self-auto"
                                                        onClick={()=>{handleReset()}
                                                        }></i>
                                                </div>
                                            </Col>
                                        </Row>
                                    </CardHeader>
                                </Card>
                                <DataList/>
                            </Row>
                        </>
                        : <AskView/>
                    }
                </Container>
                <AskAddModal modalFlag={addModalFlag}/>
            </div>
        </>
    );
}

function DataList(props) {
    const intl = useIntl();
    const dis = useDispatch();
    const history = useHistory();

    const coconutUserInfo = JSON.parse(sessionStorage.getItem("coconutUserInfo"));
    const userRole = coconutUserInfo.userRole;

    const {
        addModalFlag,
        searchParam,
        currentPage
    } = useSelector((state) => ({
        addModalFlag: state.Board.otoAddModalFlag,
        searchParam: state.Board.otoSearchParam,
        currentPage: state.Board.otoCurrentPage
    }))

    const [datasets, setDatasets] = useState();
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);

    function calStartIdx(page) {
        return (page - 1) * perPage;
    }

    const fetchData = async (page, size = perPage) => {
        setLoading(true);

        const res = await BoardService.getAskList({
            'start': calStartIdx(page),
            'length': size,
            'orderParam': [],
            'searchParam': searchParam
        });
        if (res.data.data) {
            setDatasets(res.data.data.data);
            setTotalRows(res.data.data.recordsTotal);
            console.log(res.data.data)
            if(res.data.data.data.length === 0){
                setDatasets([]);
            }
        }
        setLoading(false);
    };

    useEffect(() => {
        let page = 1;
        if(currentPage > 1){
            page = currentPage
        }
        dis(otoSetCurrentPage(page))
        fetchData(page);
    }, [searchParam, addModalFlag]);

    const handlePageChange = page => {
        let pageIdx = page.selected + 1;
        fetchData(pageIdx)
            .then(() => {
                dis(otoSetCurrentPage(pageIdx));
            });
    };

    const [columnDefs] = useState([
        {
            field: 'idx',
            headerName: 'No',
            cellClass: "firstCell",
            flex : '5%'
        },
        {
            field: 'cnTy',
            headerName: GetMessage("board.askTy"),
            flex : '10%',
            cellRenderer: params => {
                return BoardUtil.convertCnTyToLabel(params.data.cnTy);
            }
        },
        {
            field: 'cnTitle',
            headerName: GetMessage("board.title"),
            flex : '50%',
            cellRenderer: params => {
                let title = '';
                if(params.data.noticeYn === 'Y'){
                    title = GetMessage('board.important') + params.data.cnTitle;
                }else{
                    title =  params.data.cnTitle
                }
                return title;
            }
        },
        {field: 'userNm', headerName: GetMessage("board.askUserId"), flex : '10%'},
        {field: 'regDtmText', headerName: GetMessage("board.regDtm"), flex : '15%'},
        {
            field: 'replyYn', 
            headerName: GetMessage("board.answerYn"),
            flex : '10%',
            cellRenderer: params => {
                let reply = '';
                if(params.data.replyYn === 'Y'){
                    reply = <div className="badge badge-coco badge-coco-soft-skyblue">{GetMessageIntl(intl,"board.replyY")}</div>;
                }else{
                    reply =  <div className="badge badge-coco badge-coco-soft-red">{GetMessageIntl(intl,"board.replyN")}</div>
                }
                return reply;
            }
                
        },
    ]);

    const [defaultColDef] = useState({
        autoHeight: true,
        resizable: true,
        cellClass: 'cell-wrap-text',
    })

    const handleCellClick = (params) => {
        if(userRole === '01'){
            history.push({
                pathname: '/platform/board/ask',
            });
        }else if(userRole === '20'){
            history.push({
                pathname: '/user/board/ask',
            });
        }else{
            history.push({
                pathname: '/manager/board/ask',
            });
        }
        dis(board_setRowData(params.data));
        dis(board_otoSwitchViewModalFlag());

    }

    const gridOption = {
        suppressCellFocus : true,
    }


    let count = Math.ceil(Number((totalRows / perPage)));
    // 0인경우 console에 warning출력
    if(count === 0){
        count = 1;
    }

    return (
        <>
            <Card className="shadow-none">
                <CardBody className="px-0 pb-0">
                    <div className="ag-theme-alpine" style={{ width: '100%' }}>
                        <AgGridReact
                            rowData={datasets}
                            rowStyle={AgRowStyle}
                            defaultColDef={columnDefs}
                            columnDefs={columnDefs}
                            pagination={true}
                            paginationPageSize={perPage}
                            suppressPaginationPanel={true}
                            detailRowAutoHeight={true}
                            suppressRowTransform={true}
                            domLayout={'autoHeight'}
                            gridOptions={gridOption}
                            onCellClicked={handleCellClick}
                        >
                        </AgGridReact>
                        <Row className='mx-0'>
                            <Col className='d-flex justify-content-start align-items-center fs-16 text-coco-gray-700 fw-500' sm='6'>
                                <PageLeftInfomation
                                    totalRows={totalRows}
                                    perPage ={perPage}
                                    currentPage={currentPage}
                                />
                            </Col>
                            <Col className='d-flex justify-content-end align-items-center' sm='6'>
                                <ReactPaginate
                                    previousLabel={'←'}
                                    nextLabel={'→'}
                                    onPageChange={handlePageChange}
                                    pageCount={count}
                                    forcePage={currentPage - 1}
                                    activeClassName={'active'}
                                    pageClassName={'page-item'}
                                    nextLinkClassName={'page-link'}
                                    nextClassName={'page-item next'}
                                    previousClassName={'page-item prev'}
                                    previousLinkClassName={'page-link'}
                                    pageLinkClassName={'page-link'}
                                    breakClassName='page-item'
                                    breakLinkClassName='page-link'
                                    containerClassName={'pagination react-paginate pagination-custom pagination-sm justify-content-end align-items-center'}
                                />
                            </Col>
                        </Row>
                    </div>
                </CardBody>
            </Card>
        </>
    );
}

export default BoardAskList;
