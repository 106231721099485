import {
    CLOSE_WORKFLOW,
    GET_WORKFLOW,
    GET_WORKFLOW_SUCCESS,
    SET_DATA_LIST,
    SET_DATA_STTUS,
    GET_FILE_INFO,
    GET_FILE_INFO_SUCCESS,
    GET_REJECT_RSN_SUCCESS,
    GET_HISTORY_SUCCESS,
    UPDATE_SEARCH_PARAM_VISIBLE,
    UPDATE_SEARCH_PARAM_SUCCESS,
    GET_DATASET_BY_WORKFLOW_SUCCESS,
    GET_MODULE_BY_WORKFLOW_SUCCESS,
    GET_WORKER_BY_WORKFLOW_SUCCESS,
    RESET_SEARCH_PARAM_TYPE,
    UPDATE_SEARCH_PARAM_ORDER,
    UPDATE_JSON_MODAL,
    GET_JSON_SUCCESS,
    UPDATE_STAGE_TAB_ACTIVE,
    SET_LEFT_SIDEBAR_WIDTH,
    SET_RIGHT_SIDEBAR_WIDTH
} from "./actionType";
import {cloneDeep} from "lodash";

const INIT_STATE = {
    workflow: {
        id: null,
        name: null
    },
    dataList: null,
    searchParam: {
        visible: [],
        view: {
            sttus: [],
            dataset: [],
            folder: [],
            file: [],
            worker: [],
            reject: [],
            rejectClsfcSmry: [],
            skip: [],
        },
        data: {
            order: "00",
            sttus: [],
            dataset: [],
            folder: null,
            file: null,
            worker: null,
            reject: [],
            rejectClsfcSmry: [],
            skip: [],
        },
        module: [],
        dataset: [],
        worker: [],
    },
    fileInfo: {
        displayState: {
            dataSttusDisplay: null,
            dataSttusColor: null,
            dataSttusText: null,
        },
        labelModuleId: null,
        labelModuleNm: '',
        reviewModuleId: null,
        reviewModuleNm: '',
        labelSttus: null,
        reviewSttus: null,
        labelUserSeq: null,
        labelUserNm: null,
        reviewUserSeq: null,
        reviewUserNm: null,
        mngConfirmCd: null,
        rejectCount: null,
        datasetNm: null,
        regDtmText: null,
        lastRejectRsn: [],   // lastRejectRsn
        data: null,
        json: {
            visible: false,
            data: null
        }
    },
    history: {
        display: [],
        data: []
    },
    skipAction: {
        recovery: {
            fetching: false,
        },
        disable: {
            fetching: false,
        }
    },
    stageTabActive:{
        tab: 0
    },
    sidebar:{
        leftSidebarWidth: 298,
        rightSidebarWidth: 420
    }
};

const Details = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_WORKFLOW: {
            const {workflow, frame} = action.payload;
            return {
                ...state,
                workflow: {
                    ...state.workflow,
                    id: workflow.id,
                    name: workflow.name
                },
                // fileInfo: {
                //     ...state.fileInfo,
                //     labelSttus: frame.labelSttus,
                //     reviewSttus: frame.reviewSttus,
                //     rejectCount: frame.rejectCount,
                // }
            }
        }
        case UPDATE_SEARCH_PARAM_VISIBLE: {
            const {visible} = action.payload;
            return {
                ...state,
                searchParam: {
                    ...state.searchParam,
                    visible: visible
                }
            }
        }
        case UPDATE_SEARCH_PARAM_SUCCESS: {
            const {searchData, viewData} = action.payload;
            return {
                ...state,
                searchParam: {
                    ...state.searchParam,
                    view: viewData,
                    data: searchData
                }
            }
        }
        case UPDATE_SEARCH_PARAM_ORDER: {
            const {order} = action.payload;
            return {
                ...state,
                searchParam: {
                    ...state.searchParam,
                    data: {
                        ...state.searchParam.data,
                        order
                    }
                }
            }
        }
        case RESET_SEARCH_PARAM_TYPE:{
            const {type} = action.payload;
            const newParam = cloneDeep(state.searchParam.data)
            const newView = cloneDeep(state.searchParam.view)
            for (const key in newParam) {
                if (key === type) {
                    newParam[key] = INIT_STATE.searchParam.data[key]
                    newView[key] =  INIT_STATE.searchParam.view[key]
                }
            }
            return{
                ...state,
                searchParam: {
                    ...state.searchParam,
                    view: newView,
                    data: newParam
                }
            }
        }
        case GET_MODULE_BY_WORKFLOW_SUCCESS: {
            const {data} = action.payload;
            return {
                ...state,
                searchParam: {
                    ...state.searchParam,
                    module: data
                }
            }
        }
        case GET_DATASET_BY_WORKFLOW_SUCCESS:{
            const {data} = action.payload;
            return {
                ...state,
                searchParam: {
                    ...state.searchParam,
                    dataset: data
                }
            }
        }
        case GET_WORKER_BY_WORKFLOW_SUCCESS:{
            const {data} = action.payload;
            return {
                ...state,
                searchParam: {
                    ...state.searchParam,
                    worker: data
                }
            }
        }
        case SET_DATA_LIST: {
            const {dataList} = action.payload;
            return {
                ...state,
                dataList
            }
        }
        case GET_FILE_INFO: {
            const {  } = action.payload;
            return {
                ...state,
                fileInfo: {
                    ...state.fileInfo,
                    data: action.payload,
                }
            }
        }
        case GET_FILE_INFO_SUCCESS: {
            const {
                labelModuleId,
                labelModuleNm,
                reviewModuleId,
                reviewModuleNm,
                labelSttus,
                reviewSttus,
                labelUserSeq,
                labelUserNm,
                reviewUserSeq,
                reviewUserNm,
                mngConfirmCd,
                rejectCount,
                datasetNm,
                regDtmText
            } = action.payload;
            return {
                ...state,
                fileInfo: {
                    ...state.fileInfo,
                    labelModuleId,
                    labelModuleNm,
                    reviewModuleId,
                    reviewModuleNm,
                    labelSttus,
                    reviewSttus,
                    labelUserSeq,
                    labelUserNm,
                    reviewUserSeq,
                    reviewUserNm,
                    mngConfirmCd,
                    rejectCount,
                    datasetNm,
                    regDtmText
                }
            }
        }
        case SET_DATA_STTUS: {
            const {sttus, lastRejectRsn} = action.payload;
            const {displayMode, text, color} = sttus;
            return {
                ...state,
                fileInfo: {
                    ...state.fileInfo,
                    displayState: {
                        ...state.fileInfo.displayState,
                        dataSttusDisplay: displayMode,
                        dataSttusColor: color,
                        dataSttusText: text,
                    },
                    // lastRejectRsn
                }
            }
        }
        case GET_REJECT_RSN_SUCCESS: {
            const {lastRejectRsn} = action.payload;
            return {
                ...state,
                fileInfo: {
                    ...state.fileInfo,
                    lastRejectRsn: lastRejectRsn
                }
            }
        }
        case GET_HISTORY_SUCCESS:{
            const {display, historyData} = action.payload;
            return {
                ...state,
                history: {
                    ...state.history,
                    display: display,
                    data: historyData
                }
            }
        }
        case GET_JSON_SUCCESS: {
            return {
                ...state,
                fileInfo: {
                    ...state.fileInfo,
                    json: {
                        ...state.fileInfo.json,
                        data: action.payload
                    }
                }
            }
        }
        case UPDATE_JSON_MODAL: {
            return {
                ...state,
                fileInfo: {
                    ...state.fileInfo,
                    json: {
                        ...state.fileInfo.json,
                        visible: action.payload
                    }
                }
            }
        }
        case CLOSE_WORKFLOW: {
            return {
                ...INIT_STATE
            }
        }
        case UPDATE_STAGE_TAB_ACTIVE:{
            return {
                ...state,
                stageTabActive:{
                    tab: action.payload
                }
            }
        }
        case SET_LEFT_SIDEBAR_WIDTH:{
            return {
                ...state,
                sidebar: {
                    ...state.sidebar,
                    leftSidebarWidth: action.payload
                }
            }
        }
        case SET_RIGHT_SIDEBAR_WIDTH:{
            return {
                ...state,
                sidebar: {
                    ...state.sidebar,
                    rightSidebarWidth: action.payload
                }
            }
        }
        default:
            return state;
    }
}

export default Details;
