import React, {useEffect, useState} from 'react';
import BreadCrumb from "../../../../components/common/BreadCrumb";
import {Card, CardHeader, Col, Container, Input, Row} from "reactstrap";
import {DefaultRootState, RootStateOrAny, useDispatch, useSelector} from "react-redux";
import {
    manage_project_workflow_setInitSearchParam,
    manage_project_workflow_setList,
    manage_project_workflow_setOrderParam,
    manage_project_workflow_setSearchParam
} from "../../../../store/manage/project/workflow/action";
import WorkflowCardList from "./WorkflowCardList";
import Select from "react-select";
import {ManagerWorkflowService} from "../../../../apis/api/ManagerWorkflowService";
import {GetMessage} from "../../../../util/message";
import {ContextMenu, MenuItem} from "react-contextmenu";
import {changeManageWorkflowId} from "../../../../store/manage/action";
import {useHistory} from "react-router-dom";
import {alertError, confirm} from "../../../../util/AlertUtil";
import {useIntl} from "react-intl";
import {noBorderSelectStyle} from "../../../../components/constants/select";
import ManageWorkflowModal from "./ManagerWorkFlowModal";

function ProjectWorkFlow() {
    const intl = useIntl();
    const history = useHistory();
    const dispatch = useDispatch();
    const [modal, setModal] = useState(false);
    const prjctId = sessionStorage.getItem("prjctId");
    const {workflowList, searchParam, orderParam} = useSelector((state) => ({
        workflowList: state.ManageWorkflow.workflowList,
        searchParam: state.ManageWorkflow.searchParam,
        orderParam: state.ManageWorkflow.orderParam
    }));

    const orderByOption =[
        { label: GetMessage("ui.U00120"), value: "WRKFLOW_NM" },
        { label: GetMessage("ui.U00121"), value: "REG_DTM" },
        { label: GetMessage("ui.U00122"), value: "INQIRE_DTM" }
    ];

    const handleCreateWrkflow = () => {
        setModal(true);
    };

    useEffect(()=>{
        let searchCloseIcon = document.getElementById("search-close-icon");
        let searchInput = document.getElementById("search-input");

        searchInput.addEventListener("focus", function () {
            let inputLength = searchInput.value.length;
            if (inputLength > 0) {
                searchCloseIcon.classList.remove("d-none");
            } else {
                searchCloseIcon.classList.add("d-none");
            }
        });

        searchInput.addEventListener("keyup", function () {
            let inputLength = searchInput.value.length;
            if (inputLength > 0) {
                searchCloseIcon.classList.remove("d-none");
            } else {
                searchCloseIcon.classList.add("d-none");
            }
        });

        searchCloseIcon.addEventListener("click", function () {
            searchInput.value = "";
            searchCloseIcon.classList.add("d-none");
            dispatch(manage_project_workflow_setInitSearchParam());
            getWorkflows(true).then(()=>{

            })
        });

    }, []);

    useEffect(()=>{
        getWorkflows().then(()=>{

        });
    }, [orderParam])

    const getWorkflows = async (searchInit) => {

        const res = await ManagerWorkflowService.getManageWorkflowList({
            'prjctId' : prjctId,
            'orderParam' : orderParam,
            'searchParam' : searchInit?[]:searchParam
        })

        if(res.data.data){
            dispatch(manage_project_workflow_setList(res.data.data));
        }
    };

    const workflowLinkedClick = (wrkflowId) =>{
        dispatch(changeManageWorkflowId(wrkflowId));
        sessionStorage.setItem("wrkflowId",wrkflowId)
        history.push({
            pathname:"/manager/project/workflow/setting"
        });
        // 워크플로우에서 item 조회하기 떄문에 해당 로직 필요없음.
        // ManagerWorkflowService
        //     .getWorkflowItem({"wrkflowId":wrkflowId})
        //     .then(()=>{
        //         history.push({
        //             pathname:"/manager/project/workflow/setting"
        //         });
        //     });
    }

    const handleKeyPress = (event)=>{
        if (event.key === 'Enter') {
            getWorkflows();
        }
    }

    const handleInputBlur = (event)=>{
        getWorkflows();
    }

    const handleInputChange = (event)=>{
        dispatch(manage_project_workflow_setSearchParam({'WRKFLOW_NM': event.target.value}))
    }

    const handleOrderBy = (event)=>{
        dispatch(manage_project_workflow_setOrderParam(event.value));
    }

    const wrkflowContextMenu = (e, data, target) => {
        if(data.action === 'changeName'){
            editWorkflowNm(data.wrkflowId);
        }else if(data.action === 'delete'){
            deleteWorkflowId(data.wrkflowId);
        }
    }

    const switchModalFlag = (flag) =>{
        console.log('switchModalFlag', flag)
        setModal(flag);
    }


    const addWorkflowConfirm = () =>{
        confirm("common.confirm"
            , "workflow.addWorkflowConfirm"
            ,()=>{
                addWorkflow();
            }
            ,null
            , null
            , intl);
    }

    const addWorkflow = async (data) =>{
        const res = await ManagerWorkflowService.addWorkflowItem(data);
        if(res.data.success){
            setModal(false);
            workflowLinkedClick(res.data.data.wrkflowId);
        }else{
            alertError(
                "error.E00001"
                , "error.E00002"
                , null
                , {"errorMessage" : res.data.errorMessage}
                , null
                , intl);
        }
    }

    const editWorkflowNm = (wrkflowId) =>{
        dispatch(manage_project_workflow_setList(
            workflowList.map((workflowItem) => workflowItem.wrkflowId === wrkflowId? {...workflowItem, 'editMode':true}: workflowItem)
        ));
    }

    const deleteWorkflowId = (wrkflowId) =>{
        confirm("workflow.deleteWorkflowConfirm"
            , ""
            ,()=>{
                deleteWorkflow(wrkflowId);
            }
            ,null
            , null
            , intl);
    }

    const deleteWorkflow = async (wrkflowId) =>{
        const res = await ManagerWorkflowService.deleteWorkflowItem({
            'wrkflowId':wrkflowId,
            'prjctId': prjctId
        });
        if(res.data.success){
            getWorkflows();
        }else{
            alertError(
                "error.E00001"
                , "error.E00002"
                , null
                , {"errorMessage" : res.data.errorMessage}
                ,null
                , intl);
        }
    }

    const handleUpdateEvent = async(wrkflowId, wrkflowNm)=>{
        await ManagerWorkflowService.updateWorkflowNm({
            'wrkflowId': wrkflowId,
            'wrkflowNm': wrkflowNm,
            'prjctId': prjctId,
        }).then(res=>{
            if(res.data.data){
                getWorkflows();
            }else{
                dispatch(manage_project_workflow_setList(
                    workflowList.map((workflowItem) => workflowItem.wrkflowId === wrkflowId? {...workflowItem, 'editMode':false}: workflowItem)
                ));
            }
        })

    }

    return (
        <>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title={GetMessage("ui.U00118")}  pageTitle="Laflow" />
                    <Row>
                        <Card className="shadow-none">
                            <CardHeader className="border-0 rounded p-0">
                                <Row className="g-3">
                                    <Col md={3}>
                                        <div className="position-relative form-search">
                                            <Input
                                                type="text"
                                                id="search-input"
                                                className="form-control search"
                                                placeholder={GetMessage("workflow.searchPlaceHolder")}
                                                value={searchParam[0]?searchParam[0].WRKFLOW_NM:''}
                                                onKeyUp={handleKeyPress}
                                                onBlur={handleInputBlur}
                                                onChange={handleInputChange}
                                            />
                                            <span className="ri-search-line search-widget-icon"></span>
                                            <span className="ri-close-line search-widget-icon search-widget-icon-close d-none" id="search-close-icon"></span>
                                        </div>
                                    </Col>
                                    <div className="col-md-3 ms-auto">
                                        <div className="gap-2 d-flex flex-nowrap justify-content-end">
                                            {/*<div className="d-flex align-items-center gap-2">*/}
                                            <Select
                                                name="orderParam"
                                                className="mb-0"
                                                onChange={
                                                    handleOrderBy
                                                }
                                                styles={noBorderSelectStyle}
                                                value={orderByOption?.find(order => order.value === orderParam[0]) || orderByOption[1]}
                                                options={orderByOption}
                                            />
                                            {/*</div>*/}
                                            <button className="btn btn-coco-1" onClick={handleCreateWrkflow}>
                                                {GetMessage("project.createNew")}
                                            </button>
                                        </div>
                                    </div>
                                </Row>
                            </CardHeader>
                        </Card>
                    </Row>
                    <Row className="row-cols-xxl-5 row-cols-lg-3 row-cols-1">
                        <WorkflowCardList list={workflowList} clickEvent={workflowLinkedClick} handleEvent={handleUpdateEvent}  contextMenuId="wrkflowContextMenu"/>
                        <ContextMenu id="wrkflowContextMenu">
                            <MenuItem onClick={wrkflowContextMenu} data={{ action: 'changeName' }}>{GetMessage("common.changeName")}</MenuItem>
                            <MenuItem onClick={wrkflowContextMenu} data={{ action: 'delete' }}>{GetMessage("common.delete")}</MenuItem>
                        </ContextMenu>
                    </Row>
                </Container>
            </div>
            <ManageWorkflowModal modalFlag={modal} switchModalFlag={switchModalFlag} prjctId={prjctId} addWorkflow={addWorkflow}/>
        </>
    );
}

export default ProjectWorkFlow;