import {
  SET_LIST,
  SET_SEARCH_PARAM,
  SET_INIT_SEARCH_PARAM,
  SET_ORDER_PARAM,
  SET_INIT_ORDER_PARAM
} from './actionType';

export const manage_project_workflow_setList = list =>({
  type: SET_LIST,
  payload: list,
})

export const manage_project_workflow_setInitSearchParam = () => ({
  type : SET_INIT_SEARCH_PARAM,
});

export const manage_project_workflow_setSearchParam = searchObj => ({
  type : SET_SEARCH_PARAM,
  payload : searchObj
});
export const manage_project_workflow_setInitOrderParam = () => ({
  type : SET_INIT_ORDER_PARAM
});

export const manage_project_workflow_setOrderParam = orderParam => ({
  type : SET_ORDER_PARAM,
  payload : orderParam
});