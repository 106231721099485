import {Workspace} from "../../../../../../store/label";
import CanvasHeader from "../containers/CanvasHeader";
import {
    RightGroupContainer as StandardRightGroupContainer
} from "../containers/standard-workspace/header/RightGroupContainer";
import {StandardWorkspaceComponent} from "./standard-workspace/StandardWorkspace";
import {
    RightGroupContainer as ReviewRightGroupContainer
} from "../containers/review-workspace/header/RightGroupContainer";
import ReviewWorkspaceComponent from "./review-workspace/ReviewWorkspace";
import React from "react";

interface Props{
    workspace: Workspace;
}
export const AnnotationPage = (props: Props) => {
    const {workspace} = props;
    return(
        <>
            {workspace === Workspace.STANDARD &&
                <>
                    <CanvasHeader rightGroupContainer={<StandardRightGroupContainer/>}/>
                    <StandardWorkspaceComponent />
                </>
            }
            {workspace === Workspace.REVIEW_WORKSPACE &&
                <>
                    <CanvasHeader rightGroupContainer={<ReviewRightGroupContainer />}/>
                    <ReviewWorkspaceComponent />
                </>
            }
        </>

    )
}
