import {
  SET_LIST,
  SET_SEARCH_PARAM,
  SET_INIT_SEARCH_PARAM,
  SET_ORDER_PARAM,
  SET_INIT_ORDER_PARAM,
  SET_TASK_JOIN_MODAL_FLAG,
  SET_TASK_TEST_MODAL_FLAG,
  RESET_USER_TASK_PARAM,
  SET_TASK_REFRESH_FLAG
} from './actionType';

export const user_task_setList = list =>({
  type: SET_LIST,
  payload: list,
})

export const user_task_setInitSearchParam = () => ({
  type : SET_INIT_SEARCH_PARAM,
});

export const user_task_setSearchParam = searchObj => ({
  type : SET_SEARCH_PARAM,
  payload : searchObj
});
export const user_task_setInitOrderParam = () => ({
  type : SET_INIT_ORDER_PARAM
});

export const resetUserTaskParam = ()=>({
  type: RESET_USER_TASK_PARAM
})

export const user_task_setOrderParam = orderParam => ({
  type : SET_ORDER_PARAM,
  payload : orderParam
});

export const setTaskJoinModalFlag = flag =>({
  type: SET_TASK_JOIN_MODAL_FLAG,
  payload: flag
})
export const setTaskTestModalFlag = flag =>({
  type: SET_TASK_TEST_MODAL_FLAG,
  payload: flag
})
export const setTaskRefreshFlag = flag =>({
  type: SET_TASK_REFRESH_FLAG,
  payload: flag
})