import React, {useEffect, useRef, useState} from 'react';
import {board_setModalMode, board_switchModalFlag, board_switchTmpViewModalFlag} from "../../store/board/action";
import {Button, Col, Input, Label, Modal, ModalBody, ModalHeader, Row} from "reactstrap";
import {useDispatch, useSelector} from "react-redux";
import {CKEditor} from '@ckeditor/ckeditor5-react';
import Editor from 'ckeditor5-custom-build/build/ckeditor';
import {FilePond, registerPlugin} from "react-filepond";
import 'filepond/dist/filepond.min.css';
import {BoardService} from "../../apis/api/BoardService";
import SimpleBar from 'simplebar-react';
import CommonUtil from "../../util/CommonUtil";
import NoticeTmpView from "./NoticeTmpView";
import {useIntl} from "react-intl";
import {alertSuccess, confirm} from "../../util/AlertUtil";
import customUploadAdapter from "../../components/features/CustomUploadAdapter";
import {useForm} from "react-hook-form";
import {GetMessageIntl} from "../../util/message";
import logo from "assets/images/laflow_logo_01.png";

function NoticeAddModal(props) {
    const intl = useIntl();
    const dispatch = useDispatch();
    const { modalFlag, tmpViewModalFlag, rowData, mode } = useSelector((state)=>({
        tmpViewModalFlag : state.Board.tmpViewModalFlag,
        rowData : state.Board.rowData,
        modalFlag : state.Board.modalFlag,
        mode : state.Board.mode
    }));
    const [attachFiles, setAttachFiles] = useState([]);
    const [delFileSeq, setDelFileSeq] = useState([]);
    const [delFilePath, setDelFilePath] = useState([]);
    const { reset, register, formState: { errors }, handleSubmit } = useForm({criteriaMode: "all",
        defaultValues:{

        }});

    // Filepond
    const [flag, setFlag] = useState(false);
    const [files, setFiles] = useState([]);
    const [cpFiles, setCpFiles] = useState([]);
    const [totalFileSize, setTotalFileSize] = useState(0);
    const pondRef = useRef(null);
    const maxTotalSize = 50 * 1024 * 1024; // 50MB

    // CKEditor
    const ckEditorRef = useRef();
    const [content, setContent] = useState([]);
    const [noticeYn, setNoticeYn] = useState("N");
    const [cnTitle, setCnTitle] = useState("");

    // 첨부된 파일 정보 가져오기
    const getAttachFilesAwsUrl = async (cnSeq) => {
        const res =  await BoardService.getContentAttachFile({
            'cnSeq' : cnSeq
        });
        if(res.data.data){
            setAttachFiles(res.data.data);
        }
    }

    useEffect(()=>{
        reset();
        resetData();
        if(mode === "U" || mode === "T"){
            getAttachFilesAwsUrl(rowData.cnSeq);
            reset({
                cnTitle: rowData.cnTitle
            });
            setCnTitle(rowData.cnTitle);
            setContent(rowData.cn);
            setNoticeYn(rowData.noticeYn);
        }
    }, [modalFlag])


    const addProcess = ()=>{
        const data = new FormData();
        data.append("cn", content);
        data.append("boardSeq", 1);
        data.append("mode", mode);
        data.append("cnLvl", 1);
        data.append("cnTitle", cnTitle);
        data.append("noticeYn", noticeYn);
        data.append("cnSttusCd", "00");
        if(mode === "U" || mode === "T"){
            data.append("deletedFileSeq", delFileSeq.toString());
            data.append("deletedFilePath", delFilePath.toString());
            data.append("orginCnSeq", rowData.orginCnSeq);
            data.append("cnSeq", rowData.cnSeq);
        }

        for (let i = 0; i < files.length; i++) {
            // append the blob file
            data.append('attachFile[]', files[i].file);
        }

        BoardService.setContent(data, {contentType : 'multipart/form-data'})
            .then((res) => {
                console.log(res.data)
            })
            .catch((err)=>{
                const data = new FormData();
            })
            .finally(()=> {
                dispatch(board_switchModalFlag());
                dispatch(board_setModalMode("I"));
            });
    }

    function uploadPlugin (editor){ // (3)
        editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
            return customUploadAdapter(loader);
        }
    }

    const handleTrash = () =>{
        confirm("board.addModalOut"
            , "board.addModalOutMsg"
            ,()=>{
                dispatch(board_switchModalFlag());
                dispatch(board_setModalMode("I"))
            }
            ,null
            , null
            , intl);
    }

    // FilePond 업로드 => fileSize 계산
    useEffect(()=>{
        let delIdx = [];
        let totalFileSize = 0;
        for (let i = cpFiles.length - 1; i >= 0; i--) {
            totalFileSize += cpFiles[i].file.size
            if(totalFileSize > maxTotalSize){
                delIdx.push(i);
            }
        }
        let tmpArr = [...cpFiles];
        let delArr = [];
        delIdx.map((idxs) => {
            let delTarget = tmpArr.shift();
            delArr.push(delTarget.file.name)
        })

        setFiles(tmpArr);
        setTotalFileSize(totalFileSize);
        if(delArr.length > 0){
            alert("업로드 안된 파일들\n" + delArr.join('\n'));
        }
    }, [cpFiles])

    // 우상단 X 표시 클릭 이벤트
    function handleToggle() {
        dispatch(board_switchModalFlag());
        resetData();
    }

    const handleTmpSave = () => {
        const data = new FormData();
        data.append("cn", content);
        data.append("boardSeq", 1);
        data.append("mode", mode);
        data.append("cnLvl", 1);
        data.append("cnTitle", cnTitle);
        data.append("noticeYn", noticeYn);
        data.append("cnSttusCd", "01");
        if(mode === "T"){
            data.append("deletedFileSeq", delFileSeq.toString());
            data.append("deletedFilePath", delFilePath.toString());
            data.append("cnSeq", rowData.cnSeq);
        }


        for (let i = 0; i < files.length; i++) {
            // append the blob file
            data.append('attachFile[]', files[i].file);
        }

        BoardService.setContent(data, {contentType : 'multipart/form-data'})
            .then((res) => {
                console.log(res.data)
            })
            .catch((err)=>{
                const data = new FormData();
            })
            .finally(()=> {
                dispatch(board_switchModalFlag());
                dispatch(board_setModalMode("I"));
            });
    }

    const resetData = () => {
        setCnTitle("");
        setContent("");
        setNoticeYn("N");
        setFiles([]);
        setDelFileSeq([]);
        setDelFilePath([]);
        setAttachFiles([]);
    }

    function handleFileDelete(e) {
        e.target.parentElement.parentElement.parentElement.remove();
    }

    function registBtnEvent() {
        (mode === "I" || mode === "T") ?
            confirm("board.askNoticeAdd"
                , "board.askNoticeAddMessage"
                , () => {
                    addProcess()
                    alertSuccess("board.noticeAddSuccess", "board.noticeAddSuccessMessage", () => {
                    }, null, intl)
                }
                , null
                , null
                , intl) :
            confirm("board.askNoticeUpdate"
                , "board.askNoticeUpdateMessage"
                , () => {
                    addProcess()
                    alertSuccess("board.noticeUpdateSuccess", "board.noticeAddSuccessMessage", () => {
                    }, null, intl)
                }
                , null
                , null
                , intl)
    }

    return (
        <>
            <Modal
                fullscreen={true}
                isOpen={props.modalFlag}
                toggle={() => {
                    dispatch(board_switchModalFlag());
                }}
                backdrop="static"
                keyboard={false}
                className="modal-fullscreen fw-bold"
                id="exampleModalFullscreen"
            >
                <ModalHeader toggle={handleToggle} style={{backgroundColor : "white", borderBottom : "1px solid #CFD8DC"}} cssModule={{'modal-title': 'w-100 text-center mb-0'}}>
                    <div className="d-flex justify-content-between align-items-center mb-0 tx-24 fw-bold">
                        <div>
                            <img src={logo} height={22}/>
                        </div>
                        <div>
                            공지사항 { mode === "U" ? '수정' : '등록'}
                        </div>
                        <div></div>
                    </div>
                </ModalHeader>
                { tmpViewModalFlag === true ? <NoticeTmpView cnTitle={cnTitle} cn={content} noticeYn={noticeYn} files={files} attachFiles={attachFiles}/> :
                    <>
                        <form>
                            <SimpleBar
                                style={{maxHeight: "805px", width:"100%", alignSelf:"center"}}
                                className="pe-2 custom-simplebar-wrapper"
                            >
                                <div>
                                    <ModalBody className="align-self-center fs-16" style={{paddingTop : '10px', width:"1200px"}}>
                                        <Col>
                                            <Row className="mb-2">
                                                <div className={"d-flex justify-content-start align-items-center"}>
                                                    <div style={{marginRight:"50px"}}>
                                                        제목
                                                    </div>
                                                    <div style={{marginRight:"15px"}}>

                                                        <input  className="form-check-input" type="checkbox" id="formCheck1" checked={noticeYn === "Y"} onChange={(e)=> {
                                                            e.target.checked === true ? setNoticeYn('Y') : setNoticeYn('N')
                                                        }}/>

                                                        <Label className="form-check-label fs-16" for="formCheck1" style={{marginBottom:'0px'}}>
                                                            중요❗
                                                        </Label>
                                                    </div>
                                                    <div style={{width:'80%'}}>
                                                        <div className={errors.cnTitle?"validation-error":""}>
                                                            <input type="text" {...register('cnTitle', { required: true })} value={cnTitle} style={{border:'0px', fontSize:'15px', padding:'0px !important', width:"80%"}} className="form-control form-control-sm fw-bold" placeholder="제목을 입력하세요."  onChange={(e)=>{
                                                                setCnTitle(e.target.value)
                                                            }}/>
                                                        </div>
                                                        {errors.cnTitle && <span className={"validation-error-message"}>{GetMessageIntl(intl,"validation.required")}</span>}
                                                    </div>
                                                </div>
                                            </Row>
                                            <Row style={{marginBottom : '10px'}}>
                                                <div className="d-flex">
                                                    <div style={{marginRight:"100px", width:"7%"}}>
                                                        파일 첨부
                                                    </div>
                                                    <div className="w-100">
                                                        <div className="d-flex justify-content-between text-coco-gray-400">
                                                            <div>
                                                                {files.length}건의 파일
                                                            </div>
                                                            <div></div>
                                                            <div>
                                                                총 {CommonUtil.bytesToSize(totalFileSize)} / 50MB
                                                            </div>
                                                        </div>
                                                        <div className="filepond-div">
                                                            <SimpleBar style={{maxHeight: "120px"}} className="pe-2">
                                                                <FilePond
                                                                    ref={pondRef}
                                                                    files={files}
                                                                    onupdatefiles={setCpFiles}
                                                                    server={{}}
                                                                    chunkUploads={true}
                                                                    allowMultiple={true}
                                                                    instantUpload={false}
                                                                    name="files"
                                                                    labelIdle={'폴더를 올리거나, 클릭하여 파일을 선택하세요.'}
                                                                    credits={false}
                                                                />
                                                            </SimpleBar>
                                                        </div>
                                                        <div>
                                                            {
                                                                attachFiles.map((file, idx) => {
                                                                    const seperateUrl = file.split(",");
                                                                    let filePath = seperateUrl[0];
                                                                    let realFileName = seperateUrl[1];
                                                                    let fileSize = seperateUrl[2];
                                                                    let fileSeq = seperateUrl[3];
                                                                    return(
                                                                        <Row className="mb-2" key={idx}>
                                                                            <div className={"d-flex justify-content-between"}>
                                                                                <div className={"d-flex gap-2"}>
                                                                                    <span className="text-coco-gray-400" onClick={(e)=>{
                                                                                        let fileSeqCopy = [...delFileSeq];
                                                                                        fileSeqCopy.push(fileSeq);
                                                                                        setDelFileSeq(fileSeqCopy);
                                                                                        let filePathCopy = [...delFilePath];
                                                                                        filePathCopy.push(filePath);
                                                                                        setDelFilePath(filePathCopy);
                                                                                        handleFileDelete(e);
                                                                                    }}><i className="ri-close-circle-line"></i></span>
                                                                                    <span className="text-coco-gray-700">{realFileName}</span>
                                                                                </div>
                                                                                <div>
                                                                                    <span className="fs-16 fw-500 text-coco-gray-400">&nbsp;({CommonUtil.bytesToSize(fileSize)})</span>
                                                                                </div>
                                                                            </div>
                                                                        </Row>
                                                                    )
                                                                })
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </Row>
                                            <Row className="justify-content-center">
                                                <CKEditor
                                                    ref={ckEditorRef}
                                                    editor={Editor}
                                                    config={{
                                                        extraPlugins: [uploadPlugin],
                                                        removePlugins : ['specialCharacters','MediaEmbedToolbar'],
                                                        toolbar:{
                                                            removeItems: [ 'specialCharacters']
                                                        },
                                                    }}
                                                    onReady={editor => {
                                                        ckEditorRef.current = editor
                                                        editor.setData(content)
                                                    }}
                                                    onChange={(event, editor) => {
                                                        setContent(editor.getData())
                                                    }}
                                                    onBlur={(event, editor) => {
                                                    }}
                                                    onFocus={(event, editor) => {
                                                    }}
                                                />
                                            </Row>
                                        </Col>
                                    </ModalBody>
                                </div>
                            </SimpleBar>
                            <div className="modal-footer d-flex justify-content-between align-items-center" style={{borderTop : "1px solid #CFD8DC", paddingTop : "6px", paddingBottom : "6px"}}>
                                <div>
                                    <button type="button" className="btn btn-coco-2" onClick={()=>{
                                        ckEditorRef.current;
                                        dispatch(board_switchTmpViewModalFlag())
                                    }}>
                                        미리보기
                                    </button>
                                </div>
                                <div>
                                </div>
                                <div className={"d-flex flex-wrap gap-2"}>
                                    {
                                        (mode === "I" || mode === "T") ? <button type="button" className="btn btn-coco-outline-1" onClick={handleSubmit(handleTmpSave)}>
                                            임시 저장
                                        </button> : null
                                    }
                                    <button type="button" className="btn btn-coco-1" onClick={handleSubmit(registBtnEvent)}>
                                        {
                                            (mode === "I" || mode === "T") ?  "등록" : "수정"
                                        }
                                    </button>
                                    <Button color="danger" className="btn-icon" onClick={handleTrash}> <i className="ri-delete-bin-5-line"/> </Button>
                                </div>
                            </div>
                        </form>
                    </>
                }
            </Modal>
        </>
    );
}

export default NoticeAddModal;