import React, {useEffect, useRef, useState} from 'react';
import {Card, CardHeader, Col, Container, Row} from "reactstrap";
import Select from "react-select";
import {GetMessage} from "../../../util/message";
import {useHistory} from "react-router-dom";
import ProjectDashboard from "./dashboard/ProjectDashboard";
import WorkflowDashboard from "./dashboard/WorkflowDashboard";
import {DashboardService} from "../../../apis/api/DashboardService";
import {useIntl} from "react-intl";
import {useDispatch, useSelector} from "react-redux";
import {changeManageProjectId} from "../../../store/manage/action";

function Dashboard() {
    const dispatch = useDispatch();
    const [prjctId, setPrjctId] = useState(sessionStorage.getItem("prjctId"));
    useEffect(()=>{
        if(prjctId){
            dispatch(changeManageProjectId(prjctId));
        }
    },[]);

    const {
        reduxPrjctId,
    } = useSelector((state) => ({
        reduxPrjctId: state.Manage.prjctId,
    }));

    const intl = useIntl();
    const selectRef = useRef();

    const projectText = GetMessage("dashboard.titleProject");

    const defaultSelectOption = {label: projectText, value: "PROJECT"};
    const [dashBoardSelectOption, setDashBoardSelectOption] = useState([defaultSelectOption]);
    const [showTarget, setShowTarget] = useState("PROJECT");
    const titleProject = GetMessage("dashboard.titleProject");
    const titleWorkflow = GetMessage("dashboard.titleWorkflow");
    const [title, setTitle] = useState(showTarget === 'PROJECT' ? titleProject : titleWorkflow);
    const [canvasDataTy, setCanvasDataTy] = useState("11");

    const handleChangeDashboard = (e) => {
        let value = e.value;
        value === 'PROJECT' ? setTitle(titleProject) : setTitle(titleWorkflow)
        setShowTarget(value);
        setCanvasDataTy(e.canvasDataTy);
    }

    const iconStyle = {
        paddingLeft: "3px"
        , color: "#0BAD8F"
        , background: "#DFF3EF"
        , borderRadius: "100px"
        , display: "inline-block"
        , width: "20px"
        , height: "20px"
    }
    const [lastWorkDtm, setLastWorkDtm] = useState(GetMessage("dashboard.updatePeriod", {lastDtm: '-'}));
    useEffect(() => {
        if (prjctId) {

            // 프로젝트 아이디가 변경되면 화면에 프로젝트를 표시하도록 하기 위해서
            setShowTarget("PROJECT")
            setTitle(titleProject);
            selectRef.current.setValue(defaultSelectOption);

            DashboardService.getProjectWorkflowList({prjctId: prjctId})
                .then(res => {
                    const list = res.data.data.map(item => {
                        return {label: item.wrkflowNm, value: item.wrkflowId, canvasDataTy : item.canvasDataTy}
                    })
                    setDashBoardSelectOption([defaultSelectOption, ...list])
                });

            DashboardService.lastBatchDtmText({prjctId: prjctId})
                .then(res => {
                    setLastWorkDtm(intl.formatMessage({id: "dashboard.updatePeriod", defaultMessage: ''}, {lastDtm: res.data.data}))
                });
        }
    }, [prjctId]);

    useEffect(() => {
        // 대시보드 진입시에는 session에서 prjctId를 가져온다.
        // 대시보드 사용중 상단의 프로젝트를 변경할 경우 prjctId가 dispatch 되고
        // , 강제로 prjctId의 상태를 변경시켜 화면이 변경되도록 한다.
        setPrjctId(reduxPrjctId)
    }, [reduxPrjctId]);

    return (
        <>
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Card className="shadow-none">
                            <CardHeader className="border-0 rounded p-0">
                                <Row className="g-3">
                                    <Col md={10}>
                                        <span className="align-items-center justify-content-between text-coco-gray-700 fw-semibold mb-sm-0 tx-20">{title} {GetMessage("menu.MPJT0001")}</span>
                                        <span className="m-2">{lastWorkDtm}</span>
                                    </Col>
                                    <Col md={2}>
                                        <Select
                                            ref={selectRef}
                                            className="mb-0"
                                            onChange={(e) => {
                                                handleChangeDashboard(e)
                                            }}
                                            defaultValue={dashBoardSelectOption[0]}
                                            options={dashBoardSelectOption}
                                        />
                                    </Col>
                                </Row>
                            </CardHeader>
                        </Card>
                    </Row>
                    {showTarget === 'PROJECT' ? <ProjectDashboard iconStyle={iconStyle}/> : <WorkflowDashboard wrkflowId={showTarget} iconStyle={iconStyle} canvasDataTy={canvasDataTy} />}
                </Container>
            </div>
        </>
    )
}

export default Dashboard;