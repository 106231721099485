import React, { useState, useRef, useEffect, useCallback } from "react";
import {useDispatch, useSelector} from "react-redux";
import {Card, CardBody, CardHeader} from "reactstrap";
import {ReactSortable} from "react-sortablejs";
import {
    deleteManageWorkflowLabelAttrItem,
    setManageWorkflowLabelAttrItems
} from "../../../../../../../store/manage/project/workflow/label/action";
import {GetMessageIntl} from "../../../../../../../util/message";
import {
    defaultSelectStyle,
    formatGroupLabelNoCount,
    noBorderBackgroundSelectStyle, noBorderBackgroundTextSelectStyle
} from "../../../../../../../components/constants/select";
import Select from "react-select";
import {useIntl} from "react-intl";
import {Controller, useFieldArray, useFormContext} from "react-hook-form";
import {WithContext as ReactTags} from "react-tag-input";


const KeyCodes = {
    enter: 13,
};

const delimiters = [KeyCodes.comma, KeyCodes.enter];
function LabelShapeAttrRows({id, attrId, name, attrKey, required, inputType, values, regexp, regexpSample}) {
    const intl = useIntl();
    const dispatch = useDispatch();
    const { control:controlLabel, register:registerLabel, unregister: unregisterLabel, setValue:setValueLabel, clearErrors:clearErrorsLabel, getValues:getValuesLabel, reset:resetLabel, resetField:resetFieldLabel
        , watch:watchLabel, formState: { errors:errorsLabel },} = useFormContext();
    const {selectedLabelClassId,
        selectedLabelAttrId,
        labelClassItems,
        labelAttrItems,} = useSelector((state) => ({
        selectedLabelClassId: state.ManageWorkflowLabel.selectedLabelClassId,
        selectedLabelAttrId: state.ManageWorkflowLabel.selectedLabelAttrId,
        labelClassItems: state.ManageWorkflowLabel.labelClassItems,
        labelAttrItems: state.ManageWorkflowLabel.labelAttrItems,
    }));
    const valuesLength = values? values.length:0;
    const valuesObjArr = values? values.map((valueObj) => {
        return { id: valueObj, text: valueObj };
    }) : [];

    const errorsAttr = errorsLabel.classItem && errorsLabel.classItem[id] && errorsLabel.classItem[id].attributes&&errorsLabel.classItem[id].attributes[attrId];

    const inputTypeOption = [
        {label : GetMessageIntl(intl, "common.inputSelect"),   value : 'select'   }
        ,{label : GetMessageIntl(intl, "common.inputCheckbox"),    value : 'checkbox'     }
        ,{label : GetMessageIntl(intl, "common.inputText"),       value : 'text'        }];

    const requiredTypeOption = [
        {label : GetMessageIntl(intl, "common.required"),   value : 1  }
        ,{label : GetMessageIntl(intl, "common.choice"),    value : 0  }];

    const deleteLabelAttrItem = (attrId)=>{
        unregisterLabel(`classItem.${selectedLabelClassId}.attributes.${attrId}`);
        dispatch(deleteManageWorkflowLabelAttrItem(selectedLabelClassId, attrId));
    }

    const changeLabelAttrItemEvent = (id, event)=>{
        const {name, value} = event.target;
        clearErrorsLabel(name);
        setValueLabel(name, value);
        console.log(name, value);
        const getAttrItemName = name.substring(name.lastIndexOf('.')+1);
        console.log(getAttrItemName, value);
        dispatch(setManageWorkflowLabelAttrItems(selectedLabelClassId,
            labelAttrItems.map((labelAttrItem) => labelAttrItem.id === id? {...labelAttrItem, [getAttrItemName]:value}: labelAttrItem)
        ));
    }

    const changeLabelAttrItemSelectEvent = (id, name, event)=>{
        console.log('changeLabelAttrItemSelectEvent', event);
        const value = event.value;
        setValueLabel(name, value);
        const getAttrItemName = name.substring(name.lastIndexOf('.')+1);
        dispatch(setManageWorkflowLabelAttrItems(selectedLabelClassId,
            labelAttrItems.map((labelAttrItem) => labelAttrItem.id === attrId? {...labelAttrItem, [getAttrItemName]:value}: labelAttrItem)
        ));
    }

    const handleValuesDelete = (delIndex, id, attrId) => {
        let name = `classItem.${id}.attributes.${attrId}.values`;
        let value = getValuesLabel(`classItem.${id}.attributes.${attrId}.values`);
        value.splice(delIndex, 1);

        setValueLabel(name, value);
        const getAttrItemName = name.substring(name.lastIndexOf('.')+1);
        dispatch(setManageWorkflowLabelAttrItems(selectedLabelClassId,
            labelAttrItems.map((labelAttrItem) => labelAttrItem.id === attrId? {...labelAttrItem, [getAttrItemName]:value}: labelAttrItem)
        ));
    };

    const handleValuesAddition = (values, id, attrId) => {
        let name = `classItem.${id}.attributes.${attrId}.values`;
        let inputValuesArr = [];
        let getItems = getValuesLabel(`classItem.${id}.attributes.${attrId}.values`);

        if(values.text){
            let inputValues = values.text.split(',');
            inputValues = inputValues.length>0?[...new Set(inputValues)]:inputValues;
            inputValues.forEach((inputValue)=>{
                if(inputValue) {
                    inputValue = inputValue.trim(); // 앞, 뒤 공백 없애기
                    if (!itemExistCheck(id, attrId, inputValue)) {
                        inputValuesArr.push(inputValue);
                    }
                }
            })
        }

        console.log('handleValuesAddition',values, id, attrId);
        console.log('handleValuesAddition getItems',getItems);

        let value = getItems?[...getItems, ...inputValuesArr]:inputValuesArr;
        setValueLabel(name, value);
        clearErrorsLabel(name);
        const getAttrItemName = name.substring(name.lastIndexOf('.')+1);
        dispatch(setManageWorkflowLabelAttrItems(selectedLabelClassId,
            labelAttrItems.map((labelAttrItem) => labelAttrItem.id === attrId? {...labelAttrItem, [getAttrItemName]:value}: labelAttrItem)
        ));
    };

    const handleValuesDrag = (tag, currPos, newPos, id, attrId) => {
        const newValues = getValuesLabel(`classItem.${id}.attributes.${attrId}.values`);

        newValues.splice(currPos, 1);
        newValues.splice(newPos, 0, tag.text);

        let name = `classItem.${id}.attributes.${attrId}.values`;
        let value = newValues;

        setValueLabel(name, value);
        const getAttrItemName = name.substring(name.lastIndexOf('.')+1);
        dispatch(setManageWorkflowLabelAttrItems(selectedLabelClassId,
            labelAttrItems.map((labelAttrItem) => labelAttrItem.id === attrId? {...labelAttrItem, [getAttrItemName]:value}: labelAttrItem)
        ));
    }

    const itemExistCheck = (id, attrId, newItem) => {
        let result = false;
        let getItems = getValuesLabel(`classItem.${id}.attributes.${attrId}.values`);
        if(getItems){
            getItems.map((item)=>{
                if(item == newItem){
                    result = true;
                }
            });
        }

        return result;
    }

    return(
        <Card className="list-group-item projectAttributeItem p-0 mb-12 mr-2 border-1">
            <CardHeader className="card-header d-flex align-items-center border-0 pd-y-10 pd-x-12 bg-coco-gray-50 text-coco-gray-400">
                <div className={"mr-6 handle text-pointer"}>
                    <i className="bx bx-grid-vertical tx-22"></i>
                    <input type="hidden" name="id" value={attrId} {...registerLabel(`classItem.${id}.attributes.${attrId}.id`)}/>
                </div>
                <div className={"d-flex align-items-start wd-100p"}>
                    <div className="wd-35p me-1">
                        <div className={errorsAttr&&errorsLabel.classItem[id].attributes[attrId].name?"validation-error":""}>
                            <input type="text" className="form-control" placeholder={GetMessageIntl(intl, "workflow.attrName")} defaultValue={name}
                                   {...registerLabel(`classItem.${id}.attributes.${attrId}.name`, {
                                       validate: {
                                           required: value => {
                                               if(!value) return GetMessageIntl(intl,"validation.required");
                                               const attrItems = getValuesLabel(`classItem.${id}.attributes`);
                                               console.log(attrItems);
                                               if(attrItems !=undefined){
                                                   let identicalLength =attrItems.filter((item)=> item.name === value).length;
                                                   if (identicalLength>1) return GetMessageIntl(intl, 'validation.name');
                                               }
                                           }}
                                   })}
                                   onChange={(event)=>changeLabelAttrItemEvent(attrId,event)}/>
                        </div>
                        {errorsAttr&&errorsLabel.classItem[id].attributes[attrId].name && <p className="validation-error-message mb-0">{errorsLabel.classItem[id].attributes[attrId].name.message}</p>}
                    </div>
                    <div className="wd-30p me-1">
                        <div className={errorsAttr&&errorsLabel.classItem[id].attributes[attrId].key?"validation-error":""}>
                            {/*attrData-parsley-notequalto-message="동일한 속성키가 있습니다."
                                                                    attrData-parsley-type="alphanum"*/}
                            <input type="text" className="form-control" placeholder={GetMessageIntl(intl, "workflow.attrKey")} defaultValue={attrKey}
                                   {...registerLabel(`classItem.${id}.attributes.${attrId}.key`, {
                                       pattern: {
                                           value: /^[a-zA-Z]+/,
                                           message: GetMessageIntl(intl, "workflow.shapeConfig.onlyEngEnter")
                                       },
                                       validate: {
                                           required: value => {
                                               if(!value) return GetMessageIntl(intl,"validation.required");
                                               const attrItems = getValuesLabel(`classItem.${id}.attributes`);
                                               if(attrItems !=undefined){
                                                   let identicalLength =attrItems.filter((item)=> item.key === value).length;
                                                   if (identicalLength>1) return GetMessageIntl(intl, 'validation.name');
                                               }
                                           }}
                                   })}
                                   onChange={(event)=>changeLabelAttrItemEvent(attrId,event)}/>
                        </div>
                        {errorsAttr&&errorsLabel.classItem[id].attributes[attrId].key && <p className="validation-error-message mb-0">{errorsLabel.classItem[id].attributes[attrId].key.message}</p>}
                    </div>
                    <div className="wd-20p">
                        <Controller
                            name={`classItem.${id}.attributes.${attrId}.inputType`}
                            rules={{
                                required: true,
                            }}
                            defaultValue={inputType}
                            render={({ field: { onChange, value, ref, name } }) => (
                                <Select
                                    name=""
                                    isSearchable={false}
                                    options={inputTypeOption}
                                    placeholder={GetMessageIntl(intl,"common.selectOptions")}
                                    formatGroupLabel={formatGroupLabelNoCount}
                                    styles={noBorderBackgroundTextSelectStyle}
                                    onChange={(val) => {
                                        onChange(val.value);
                                        changeLabelAttrItemSelectEvent(attrId,'inputType', val);
                                    }}
                                    value={value?inputTypeOption.find((c) => c.value === value):null}
                                    className="mb-0"
                                    maxMenuHeight={250}
                                />
                            )}
                        />
                    </div>
                    <div className="wd-15p">
                        <Controller
                            name={`classItem.${id}.attributes.${attrId}.required`}
                            rules={{
                                required: true,
                            }}
                            defaultValue={required}
                            render={({ field: { onChange, value, ref, name } }) => (
                                <Select
                                    name=""
                                    isSearchable={false}
                                    options={requiredTypeOption}
                                    placeholder={GetMessageIntl(intl,"common.selectOptions")}
                                    formatGroupLabel={formatGroupLabelNoCount}
                                    styles={noBorderBackgroundTextSelectStyle}
                                    onChange={(val) => {
                                        onChange(val.value);
                                        changeLabelAttrItemSelectEvent(attrId, 'required', val);
                                    }}
                                    value={String(value) !=''?requiredTypeOption.find((c) => c.value === value):null}
                                    className="mb-0"
                                    maxMenuHeight={250}
                                />
                            )}
                        />
                    </div>
                </div>
                <i className="ri-close-line tx-22 ps-2 delProjectAttribute text-pointer" onClick={()=>deleteLabelAttrItem(attrId)}></i>
            </CardHeader>
            <CardBody className={"pd-x-12 pd-y-10"}>
                {
                    inputType === 'text'?
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="mb-16">
                                    <label className="form-label tx-14 fw-semibold text-coco-gray-700">
                                        {GetMessageIntl(intl, "workflow.regexpSample")}
                                    </label>
                                    <input type="text" className="form-control" name="regexpSample" defaultValue={regexpSample}
                                           {...registerLabel(`classItem.${id}.attributes.${attrId}.regexpSample`)}
                                           placeholder={GetMessageIntl(intl, "workflow.regexpSamplePlaceholder")} onChange={(event)=>changeLabelAttrItemEvent(id,event)}/>
                                </div>
                            </div>
                            <div className="col-lg-12">
                                <div className="mb-0">
                                    <label className="form-label tx-14 fw-semibold text-coco-gray-700">
                                        {GetMessageIntl(intl, "workflow.regexp")}
                                    </label>
                                    <input type="text" className="form-control" defaultValue={regexp}
                                           {...registerLabel(`classItem.${id}.attributes.${attrId}.regexp`)}
                                           placeholder={GetMessageIntl(intl, "workflow.regexpPlaceholder")} onChange={(event)=>changeLabelAttrItemEvent(id,event)}/>
                                </div>
                            </div>
                        </div>
                        :
                        <div className={errorsAttr&&errorsLabel.classItem[id].attributes[attrId].values?"validation-error":""}>
                            <Controller
                                name={`classItem.${id}.attributes.${attrId}.values`}
                                rules={
                                    {
                                        validate: {
                                            required: value => {
                                                console.log('values', value);
                                                if(value.length<2){
                                                    return GetMessageIntl(intl,"workflow.shapeConfig.attrMinSize");
                                                }
                                            }
                                        }
                                    }
                                }
                                defaultValue={values}
                                render={({ field: { onChange, value, ref, name } }) => (
                                    <ReactTags
                                        tags={valuesObjArr}
                                        delimiters={delimiters}
                                        className={"form-control"}
                                        handleDelete={(evnt)=>{handleValuesDelete(evnt, id, attrId)}}
                                        handleAddition={(evnt)=>{handleValuesAddition(evnt, id, attrId)}}
                                        handleDrag={(tag, currPos, newPos)=>{handleValuesDrag(tag, currPos, newPos, id, attrId)}}
                                        allowAdditionFromPaste={false}
                                        allowUnique={true}
                                        inputFieldPosition="top"
                                        placeholder={GetMessageIntl(intl,"workflow.attrValuesPlaceholder")}
                                        /*autocomplete*/
                                    /> )}
                            />
                            {errorsAttr&&errorsLabel.classItem[id].attributes[attrId].values && <p className="validation-error-message mb-0">{errorsLabel.classItem[id].attributes[attrId].values.message}</p>}
                            <div className="shownAttrInfo mt-18 text-right text-coco-gray-400 tx-14">
                                <div>
                                    {GetMessageIntl(intl, "workflow.attrValuesLength")}:<span className="shownAttrCount">{valuesLength}</span>{GetMessageIntl(intl, "common.piece")}
                                </div>
                            </div>
                        </div>
                }
            </CardBody>
        </Card>
    )
}
export default LabelShapeAttrRows;