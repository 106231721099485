import React, { memo } from 'react';
import {Handle, useReactFlow, useStoreApi, Position, useStore} from 'reactflow';
import {GetMessage} from "../../../../../../../util/message";
import {useDispatch, useSelector} from "react-redux";
import {thisSourceValidConnection, thisTargetValidConnection} from "../nodeCommonUtil";
const connectionNodeIdSelector = (state) => {
    return state.connectionNodeId;
}
const connectionHandleTypeSelector = (state) => {
    return state.connectionHandleType;
}
const nodesSelector = (state) => Array.from(state.nodeInternals.values());

export default function SubUnabledNode({ id, data, isConnectable }) {
    const dispatch = useDispatch();
    const nodes = useStore(nodesSelector);
    const connectionNodeId = useStore(connectionNodeIdSelector);
    const connectionHandleType = useStore(connectionHandleTypeSelector);
    let connectionNodeType = '';
    if(connectionNodeId){
        let connectionNode = nodes.find((item) => item.id === connectionNodeId);
        let parentNodeType = '';
        if(connectionNode.parentNode){
            parentNodeType = nodes.find((item) => item.id === connectionNode.parentNode).type+'_';
        }
        connectionNodeType = parentNodeType + connectionNode.type;
    }

    const parentId = id.substring(0, id.lastIndexOf('_'));
    const {selectionNodeId} = useSelector((state)=>({
        selectionNodeId : state.ManageWorkflowModule.selectionNodeId
    }));

    const isSourceValidConnection = (connection) => {
        let connectSourceType = nodes.find((item) => item.id === connection.target).type;
        return thisSourceValidConnection('subUnabled',connectSourceType)&& connection.target != parentId;
    }

    const isParent = selectionNodeId && selectionNodeId !== id && parentId ===selectionNodeId;
    const isSource = connectionHandleType == 'target' && thisSourceValidConnection('subUnabled', connectionNodeType);
    const sourceHandleStyle = (isParent||isSource) && connectionNodeId != parentId? { visibility: 'visible', zIndex: 10 } : {visibility: 'hidden', zIndex:1};


    return (
        <>
            <Handle
                type="source"
                style={sourceHandleStyle}
                position={Position.Right}
                id={'right'}
                isConnectable={isConnectable}
                isValidConnection={isSourceValidConnection}
            />
            <Handle
                type="source"
                style={sourceHandleStyle}
                position={Position.Left}
                id={'left'}
                isConnectable={isConnectable}
                isValidConnection={isSourceValidConnection}
            />
            {GetMessage("workflow.unabled")}
        </>
    );
}