import {
  SET_MANAGE_LABEL_ID
  , SET_MANAGE_LABEL_SHAPE_CONFIG
  , GET_MANAGE_LABEL_SHAPE_CONFIG
  , SET_MANAGE_LABEL_DATASET
  , GET_MANAGE_LABEL_DATASET
  , UPDATE_MANAGE_LABEL_DATASET
  , SET_MANAGE_LABEL_GUIDE
  , GET_MANAGE_LABEL_GUIDE
  , SET_MANAGE_LABEL_CLASS_ITEMS
  , GET_MANAGE_LABEL_CLASS_ITEMS
  , ADD_MANAGE_LABEL_CLASS_ITEM
  , UPDATE_MANAGE_LABEL_CLASS_ITEM
  , DELETE_MANAGE_LABEL_CLASS_ITEM
  , SET_MANAGE_LABEL_ATTR_LIST
  , GET_MANAGE_LABEL_ATTR_LIST
  , SET_MANAGE_LABEL_ATTR_ITEMS
  , ADD_MANAGE_LABEL_ATTR_ITEM
  , UPDATE_MANAGE_LABEL_ATTR_ITEM
  , DELETE_MANAGE_LABEL_ATTR_ITEM
  , SET_SELECTED_MANAGE_LABEL_CLASS_ID
  , SET_SELECTED_MANAGE_LABEL_ATTR_ID
  , SET_SELECTED_MANAGE_LABEL_ATTR_ITEM_ID
} from './actionType';

const INIT_STATE = {
  selectedLabelClassId: -1,
  selectedLabelAttrId: -1,
  selectedLabelAttrItemId: -1,
  labelShapeConfig: [],
  labelDataset:[],
  labelGuide:null,
  labelClassItems:[],
  labelAttrList:[],
  labelAttrItems:[],
  error: {},
};

const ManageLabel = (state = INIT_STATE, action) => {
  switch (action.type) {
      // case SET_MANAGE_LABEL_SHAPE_LIST:
      //   return {
      //     ...state,
      //     labelShapeList: action.payload,
      //   };
      // case GET_MANAGE_LABEL_SHAPE_LIST:
      //   return {
      //     ...state,
      //   };
      //
      // case UPDATE_MANAGE_LABEL_SHAPE:
      //   return {
      //     ...state,
      //     labelShapeList: state.labelShapeList.map(labelShape =>
      //         labelShape._id.toString() === action.payload.data._id.toString()
      //             ? { ...labelShape, ...action.payload.data }
      //             : labelShape
      //     ),
      //   };
      //
      // case ADD_MANAGE_LABEL_SHAPE:
      //   return {
      //     ...state,
      //     labelShapeList: [...state.labelShapeList, action.payload.data],
      //   };
      //
      // case DELETE_MANAGE_LABEL_SHAPE:
      //   return {
      //     ...state,
      //     labelShapeList: state.labelShapeList.filter(
      //         labelShape => labelShape._id.toString() !== action.payload.labelShape.toString()
      //     )
      //   };
    case SET_MANAGE_LABEL_CLASS_ITEMS:
      return {
        ...state,
        labelClassItems:action.payload,
      };

    case ADD_MANAGE_LABEL_CLASS_ITEM:
      return {
        ...state,
        labelClassItems:[...state.labelClassItems, action.payload]
      };

    case UPDATE_MANAGE_LABEL_CLASS_ITEM:
      return {
        ...state,
        labelClassItems: state.labelClassItems.map(labelClassItem =>
            labelClassItem.id === action.payload.id?{...labelClassItem, ...action.payload}
                :labelClassItem
        ),
      };

    case DELETE_MANAGE_LABEL_CLASS_ITEM:
      return{
        ...state,
        labelClassItems: state.labelClassItems.filter(
            labelClassItem => labelClassItem.id !== action.payload
        )
      };

    case SET_MANAGE_LABEL_ATTR_ITEMS:
      return{
        ...state,
        labelAttrItems: action.payload
      };

    case ADD_MANAGE_LABEL_ATTR_ITEM:
      return{
        ...state,
        labelAttrItems: [...state.labelAttrItems, action.payload]
      };

    case DELETE_MANAGE_LABEL_ATTR_ITEM:
      return{
        ...state,
        labelAttrItems: state.labelAttrItems.filter(
            labelAttrItem => labelAttrItem.id !== action.payload
        )
      }

    case SET_SELECTED_MANAGE_LABEL_CLASS_ID:
      return {
        ...state,
        selectedLabelClassId: action.payload
      };

    case SET_SELECTED_MANAGE_LABEL_ATTR_ID:
      return {
        ...state,
        selectedLabelAttrId: action.payload
      };

    case SET_SELECTED_MANAGE_LABEL_ATTR_ITEM_ID:
      return {
        ...state,
        selectedLabelAttrItemId: action.payload
      };
    default:
      return state;
  }
};

export default ManageLabel;
