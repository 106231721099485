// @ts-nocheck
import React, {useRef} from 'react';
import {Row} from 'react-bootstrap';
import {ButtonGroup, Card, CardBody, Col, Input, Label} from "reactstrap";
import {GetMessageIntl} from "../../../../../../../../util/message";
import {WithContext as ReactTags} from "react-tag-input";
import {useIntl} from "react-intl";
import styled from "styled-components";
import {Controller} from "react-hook-form";
import {toNumber} from "lodash";
import CustomTooltip from "../../../../../../../../components/features/CustomTooltip";


const CustomButtonContainer = styled.div`
  
  button{
    border: 1px solid var(--coco-gray-200);
  }
  .checked{
    background-color: var(--coco-primary-50);
    color: var(--coco-primary-300);
  }
  .is-invalid{
    border: 1px solid var(--coco-danger-400);
  }
  button:hover{
    border: 1px solid var(--coco-gray-200);
    background-color: var(--coco-primary-200);
    color: var(--coco-default-white);
  }
`

const InvalidContainer = styled.div`
    color: var(--coco-danger-400);
    font-size: 0.875em;
`

const NumberButtonContainer = styled.div`
  height: 36px;
  
  .number{
    width: 35%;
  }
  .input-step{
    background-color: white;
  }
  .coco-brush-tools-size-control{
    border: none;
    color: var(--coco-gray-400);
    background-color: #F3F6F9;
  }
`

interface Props {
    viewType: string;
    id: number;
    method: any;
    data: any;
}
function ClsfcSmrySettingContent(props: Props) {
    const intl = useIntl();
    const questionRef = useRef(null);
    const { viewType , id, method, data} = props;
    const KeyCodes = {
        enter: 13,
    };

    const handleValuesAddition = (values, id) => {
        let name = `labels.${id}.attributes[0].values`;
        let inputValuesArr = [];
        let getItems = method.getValues(name) || [];

        const maxId = getItems.reduce((max, item) => {
            const parsedId = parseInt(item.id, 10);
            return isNaN(parsedId) ? max : Math.max(max, parsedId);
        }, 0);

        if (values.text) {
            let inputValues = values.text.split(',');
            inputValues = inputValues.length > 0 ? [...new Set(inputValues)] : inputValues;
            inputValues.forEach((inputValue, idx) => {
                if (inputValue) {
                    inputValue = inputValue.trim(); // 앞, 뒤 공백 없애기
                    if (!itemExistCheck(id, inputValue)) {
                        // 기존 항목 수를 기반으로 ID를 생성
                        inputValuesArr.push({ id: String(maxId + idx + 1), text: inputValue });
                    }
                }
            });
        }

        let value = getItems ? [...getItems, ...inputValuesArr] : inputValuesArr;
        method.setValue(name, value);
        method.clearErrors(name);
    };

    const handleValuesDelete = (delIndex, id, event) => {
        // backspace 입력시 안지워지도록
        if(event.type ==="keydown" && event.code === "Backspace"){
            return
        }
        let name = `labels.${id}.attributes.values`;
        let value = method.getValues(`labels.${id}.attributes[0].values`);
        value.splice(delIndex, 1);
        method.setValue(name, value);
    };

    const itemExistCheck = (id, newItem) => {
        let result = false;
        let getItems = method.getValues(`labels.${id}.attributes[0].values`);
        if(getItems){
            getItems.map((item)=>{
                if(item.text == newItem){
                    result = true;
                }
            });
        }

        return result;
    }

    const handleTipChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        const inputValue = e.target.value;
        if (inputValue.length > 100) {
            const truncatedValue = inputValue.slice(0, 100);
            method.setValue(`labels.${id}.tip`, truncatedValue); // 값을 잘라서 저장합니다.
        } else {
            method.setValue(`labels.${id}.tip`, inputValue); // 그대로 값을 저장합니다.
        }
    };

    const handleTipKeyDown = (e) => {
        e.stopPropagation();
    }

    const isRequiredOption = (option) => {
        method.setValue(`labels.${id}.attributes[0].required`, option);
    };

    const isInputTypeOption = (option) => {
        method.setValue(`labels.${id}.attributes[0].inputType`, option);
    }

    return (
        <Card className="shadow-none">
            <CardBody>
                <Col className="fs-16 fw-500 text-coco-gray-700">
                    <Row className="d-flex pd-b-16">
                        <div className="d-flex">
                            {
                                viewType === "C"
                                    ? <>
                                        <div className="flex-shrink-0 avatar-16" style={{marginRight: "8px"}}>
                                            <div className="avatar-title rounded-circle pd-4"
                                                 style={{backgroundColor: "#EDE0FF", color: "#9E53FF", height: "24px", width: "24px"}}>
                                                <i className="ri-node-tree"></i></div>
                                        </div>
                                        <span className="fw-bold">{GetMessageIntl(intl, "workflow.shapeConfig.clsfc")}</span>
                                    </>
                                    : <>
                                        <div className="flex-shrink-0 avatar-16" style={{marginRight: "8px"}}>
                                            <div className="avatar-title rounded-circle pd-4"
                                                 style={{backgroundColor: "rgb(255, 242, 208)", color: "#FFB900", height: "24px", width: "24px"}}>
                                                <i className="mdi mdi-text-shadow"></i></div>
                                        </div>
                                        <span className="fw-bold">{GetMessageIntl(intl, "workflow.shapeConfig.summry")}</span>
                                    </>
                            }

                        </div>
                    </Row>
                    <Row className="d-flex mb-12">
                        <div className="d-flex">
                            <i className="ri-pages-line mr-8"></i>
                            <span className="fw-bold">{GetMessageIntl(intl, "workflow.shapeConfig.essentialInfo")}</span>
                        </div>
                    </Row>
                    <Row className="mb-12">
                        <span>{GetMessageIntl(intl, "workflow.shapeConfig.label")}<span className="text-coco-danger-400">*</span></span>
                        <div>
                            <input type="text"
                                   key={`labels.${id}.question`}
                                   ref={questionRef}
                                   {
                                        ...method.register(`labels.${id}.question`, {
                                            required: false
                                        })
                                    }
                                   className={"form-control ".concat(method.formState.errors?.labels?.[id]?.question ? "is-invalid" : "")}
                                   placeholder={GetMessageIntl(intl, "common.enterInfo")}
                                   maxLength={50}
                            />
                            {method.formState.errors?.labels?.[id]?.question?.message && (<div className="invalid-feedback">{method.formState.errors.labels[id].question.message}</div>)}
                        </div>
                    </Row>
                    <Row className="mb-12">
                        <Col>
                            <div>
                                {
                                    viewType === "C"?
                                        <>{GetMessageIntl(intl, "workflow.shapeConfig.multipleSelectYn")}<span className="text-coco-danger-400">*</span></>
                                        :
                                        <>{GetMessageIntl(intl, "workflow.shapeConfig.requireSelectYn")}<span className="text-coco-danger-400">*</span></>
                                }
                            </div>
                            <CustomButtonContainer>
                                {
                                    viewType === "C" ?
                                        <ButtonGroup className="w-100 custombuttongrp">
                                            <button type="button"
                                                    className={"btn btn-coco-outline-2 "
                                                        .concat(method.getValues(`labels.${id}.attributes[0].inputType`) === "select" ? " checked" : "")
                                                        .concat(method.formState.errors?.labels?.[id]?.inputType ? " is-invalid" : "")}
                                                    onClick={() => isInputTypeOption("select")}>{GetMessageIntl(intl, "workflow.shapeConfig.isMultiN")}</button>
                                            <button type="button"
                                                    className={"btn btn-coco-outline-2 "
                                                        .concat(method.getValues(`labels.${id}.attributes[0].inputType`) === "checkbox" ? " checked" : "")
                                                        .concat(method.formState.errors?.labels?.[id]?.inputType ? " is-invalid" : "")}
                                                    onClick={() => isInputTypeOption("checkbox")}>{GetMessageIntl(intl, "workflow.shapeConfig.isMultiY")}</button>
                                        </ButtonGroup>
                                        :
                                        <>
                                            <ButtonGroup className="w-100">
                                                <button type="button"
                                                        className={"btn btn-coco-outline-2"
                                                            .concat(method.getValues(`labels.${id}.attributes[0].required`) === 1 ? " checked" : "")
                                                            .concat(method.formState.errors?.labels?.[id]?.required ? " is-invalid" : "")}
                                                        onClick={() => isRequiredOption(1)}>{GetMessageIntl(intl, "workflow.shapeConfig.isRequireY")}</button>
                                                <button type="button"
                                                        className={"btn btn-coco-outline-2"
                                                            .concat(method.getValues(`labels.${id}.attributes[0].required`) === 0 ? " checked" : "")
                                                            .concat(method.formState.errors?.labels?.[id]?.required ? " is-invalid" : "")}
                                                        onClick={() => isRequiredOption(0)}>{GetMessageIntl(intl, "workflow.shapeConfig.isRequireN")}</button>
                                            </ButtonGroup>
                                            <div className={"d-flex justify-content-between"}>
                                                {method.formState.errors?.labels?.[id]?.required && (<div><div className="invalid-feedback">{method.formState.errors.labels[id].required.message}</div><div></div></div>)}
                                            </div>
                                        </>

                                }
                            </CustomButtonContainer>
                        </Col>
                        <Col>
                            <div>
                                {
                                    viewType === "C" ?
                                    <div>
                                        {GetMessageIntl(intl, "workflow.shapeConfig.requireSelectYn")}<span className="text-coco-danger-400">*</span>
                                    </div>
                                    :
                                    <div style={{height:"24px"}} className="d-flex">
                                        <span className="w-50">
                                            글자 수 제한
                                        </span>
                                        <CustomTooltip width={"145px"} text={"최대 1,000자까지 글자 수를 제한할 수 있습니다."}>
                                            <i className="ri-information-line pd-l-3"></i>
                                        </CustomTooltip>
                                    </div>
                                }

                            </div>
                            <CustomButtonContainer>
                                {
                                    viewType === "C" ?
                                        <>
                                            <ButtonGroup className="w-100">
                                                <button type="button"
                                                        className={"btn btn-coco-outline-2"
                                                            .concat(method.getValues(`labels.${id}.attributes[0].required`) === 1 ? " checked" : "")
                                                            .concat(method.formState.errors?.labels?.[id]?.required ? " is-invalid" : "")}
                                                        onClick={() => isRequiredOption(1)}>{GetMessageIntl(intl, "workflow.shapeConfig.isRequireY")}</button>
                                                <button type="button"
                                                        className={"btn btn-coco-outline-2"
                                                            .concat(method.getValues(`labels.${id}.attributes[0].required`) === 0 ? " checked" : "")
                                                            .concat(method.formState.errors?.labels?.[id]?.required ? " is-invalid" : "")}
                                                        onClick={() => isRequiredOption(0)}>{GetMessageIntl(intl, "workflow.shapeConfig.isRequireN")}</button>
                                            </ButtonGroup>
                                            <div className={"d-flex justify-content-between"}>
                                                {method.formState.errors?.labels?.[id]?.required && (<div><div className="invalid-feedback">{method.formState.errors.labels[id].required.message}</div><div></div></div>)}
                                            </div>
                                        </>
                                        :
                                        <NumberButtonContainer className={"input-step"}>
                                            <Input
                                                type="number"
                                                className="coco-brush-tools-brush-size number"
                                                value={method.getValues(`labels.${id}.attributes[0].min`) || 0}
                                                onChange={(e) => {
                                                    const value = toNumber(e.target.value.replace(/[^0-9]/g, ""));
                                                    if(value >= 0){
                                                        method.setValue(`labels.${id}.attributes[0].min`, value);
                                                    }
                                                }}
                                            />
                                            <div className={"vstack"} style={{gap: "4px", borderRight: "1px solid var(--vz-input-border)"}}>
                                                <button
                                                    type="button"
                                                    className="coco-brush-tools-size-control plus"
                                                    onClick={() => {
                                                        const value = method.getValues(`labels.${id}.attributes[0].min`) + 1;
                                                        method.setValue(`labels.${id}.attributes[0].min`, value);
                                                    }}
                                                >
                                                    +
                                                </button>
                                                <button
                                                    type="button"
                                                    className="coco-brush-tools-size-control minus"
                                                    onClick={() => {
                                                        const value = method.getValues(`labels.${id}.attributes[0].min`) - 1;
                                                        if(value >= 0){
                                                            method.setValue(`labels.${id}.attributes[0].min`, value);
                                                        }
                                                    }}
                                                >
                                                    -
                                                </button>
                                            </div>

                                            <Input
                                                type="string"
                                                className="coco-brush-tools-brush-size number"
                                                value={method.getValues(`labels.${id}.attributes[0].max`) === 1000 ? "MAX" : method.getValues(`labels.${id}.attributes[0].max`)}
                                                onChange={(e) => {
                                                    if(toNumber(e.target.value.replace(/[^0-9]/g, "")) >= 1000) {
                                                        method.setValue(`labels.${id}.attributes[0].max`, "MAX");
                                                    }else {
                                                        const value = toNumber(e.target.value.replace(/[^0-9]/g, ""));
                                                        method.setValue(`labels.${id}.attributes[0].max`, value);
                                                    }
                                                }}
                                            />
                                            <div className={"vstack"} style={{gap: "4px"}}>
                                                <button
                                                    type="button"
                                                    className="coco-brush-tools-size-control plus"
                                                    onClick={() => {
                                                        if(method.getValues(`labels.${id}.attributes[0].max`) === "MAX") {
                                                            method.setValue(`labels.${id}.attributes[0].max`, "MAX");
                                                        }else {
                                                            const value = method.getValues(`labels.${id}.attributes[0].max`) + 1;
                                                            if(value > 1000) {
                                                                method.setValue(`labels.${id}.attributes[0].max`, "MAX");
                                                            }else {
                                                                method.setValue(`labels.${id}.attributes[0].max`, value);
                                                            }
                                                        }
                                                    }}
                                                >
                                                    +
                                                </button>
                                                <button
                                                    type="button"
                                                    className="coco-brush-tools-size-control minus"
                                                    onClick={() => {
                                                        if(method.getValues(`labels.${id}.attributes[0].max`) === "MAX"){
                                                            method.setValue(`labels.${id}.attributes[0].max`, 999);
                                                        }else {
                                                            const value = method.getValues(`labels.${id}.attributes[0].max`) - 1;
                                                            if(value < 0) {
                                                                method.setValue(`labels.${id}.attributes[0].max`, 0);
                                                            }else{
                                                                method.setValue(`labels.${id}.attributes[0].max`, value);
                                                            }
                                                        }

                                                    }}
                                                >
                                                    -
                                                </button>
                                            </div>
                                        </NumberButtonContainer>
                                }
                            </CustomButtonContainer>
                        </Col>
                        <div className={"d-flex"}>
                            {
                                viewType === "C" ?
                                    <>
                                        <Col>
                                            <InvalidContainer>
                                                {method.formState.errors?.labels?.[id]?.inputType && (<div className="is-invalid">{method.formState.errors.labels[id].inputType.message}</div>)}
                                            </InvalidContainer>
                                        </Col>
                                        <Col>
                                            <InvalidContainer>
                                                {method.formState.errors?.labels?.[id]?.required && (<div className="is-invalid" style={{paddingLeft:"12px"}}>{method.formState.errors.labels[id].required.message}</div>)}
                                            </InvalidContainer>
                                        </Col>
                                    </>
                                    :
                                    <>
                                        <Col>
                                            <InvalidContainer>
                                                {method.formState.errors?.labels?.[id]?.required && (<div className="is-invalid" style={{paddingLeft:"12px"}}>{method.formState.errors.labels[id].required.message}</div>)}
                                            </InvalidContainer>
                                        </Col>
                                        <Col>
                                        </Col>
                                    </>
                            }
                        </div>
                    </Row>
                    <Row>
                        {
                            viewType === "C" ?
                                <div className="mb-12">
                                    <span>{GetMessageIntl(intl, "workflow.shapeConfig.select")}<span className="text-coco-danger-400">*</span></span>
                                    <div className={method.formState.errors?.labels?.[id]?.attributes ? "validation-error " : ""}>
                                        <Controller
                                            name={`labels.${id}.attributes[0].values`}
                                            defaultValue={method.getValues(`labels.${id}.attributes[0].values`) || []}
                                            rules={{
                                                validate: value => {
                                                    return true;
                                                }
                                            }}
                                            render={({ field: { onChange, value, ref, name } }) => (
                                                <ReactTags
                                                    tags={method.getValues(`labels.${id}.attributes[0].values`) || []}
                                                    delimiters={[KeyCodes.enter]}
                                                    className={"form-control"}
                                                    handleDelete={(index,e)=>{handleValuesDelete(index, id, e)}}
                                                    handleAddition={(e)=>{handleValuesAddition(e, id)}}
                                                    // handleDrag={(tag, currPos, newPos)=>{handleValuesDrag(tag, currPos, newPos, id, attrId)}}
                                                    allowAdditionFromPaste={false}
                                                    // handleDelete={(e)=>{handleValueDelete(e,id)}}
                                                    allowUnique={false}
                                                    inputFieldPosition="top"
                                                    placeholder={GetMessageIntl(intl,"workflow.attrValuesPlaceholder")}
                                                    autofocus={false}
                                                    /*autocomplete*/
                                                />
                                            )}
                                        />
                                    </div>
                                    {method.formState.errors?.labels?.[id]?.attributes && (
                                        <InvalidContainer>
                                            {method.formState.errors.labels[id].attributes.message}
                                        </InvalidContainer>
                                    )}
                                    <div className="d-flex justify-content-between">
                                        <div></div>
                                        <div className="text-coco-gray-400 fs-12 fw-400">{GetMessageIntl(intl, "workflow.shapeConfig.selectAmount")}{method.getValues(`labels.${id}.attributes[0].values`)?.length || 0}개</div>
                                    </div>
                                </div>

                                :
                                <>
                                    <div className="mb-12">
                                        <span className="mb-8">{GetMessageIntl(intl, "workflow.shapeConfig.inputExam")}</span>
                                        <div>
                                            <input
                                                value={method.getValues(`labels.${id}.inputExam`) || ""}
                                                {...method.register(`labels.${id}.inputExam`, {
                                                    required: false
                                                })}
                                                type="text"
                                                className="form-control"
                                                placeholder={GetMessageIntl(intl, "workflow.shapeConfig.inputExamPlaceholder")}/>
                                        </div>
                                    </div>
                                    <div className="mb-12">
                                        <span className="mb-8">{GetMessageIntl(intl, "workflow.shapeConfig.textRegexLimit")}</span>
                                        <input
                                            value={method.getValues(`labels.${id}.regexp`) || ""}
                                            {...method.register(`labels.${id}.regexp`, {
                                                required: false
                                            })}
                                            type="text"
                                            className="form-control"
                                            placeholder={GetMessageIntl(intl, "workflow.shapeConfig.regexLimitPlaceholder")}/>
                                        <div>
                                        </div>
                                    </div>
                                </>
                        }
                    </Row>
                    <hr/>
                    <Row>
                        <div className="d-flex mb-12">
                            <i className="ri-palette-line mr-8"></i>
                            <span className="fw-bold">{GetMessageIntl(intl, "workflow.shapeConfig.etcInfo")}</span>
                        </div>
                    </Row>
                    <Row>
                        <div>
                            <Label htmlFor="exampleFormControlTextarea5" className="form-label">{GetMessageIntl(intl, "workflow.shapeConfig.tipInfo")}</Label>
                            <textarea
                                value={method.getValues(`labels.${id}.tip`) || ""}
                                {...method.register(`labels.${id}.tip`, {
                                    required: false,
                                    onChange: handleTipChange
                                })}
                                onKeyDown={handleTipKeyDown}
                                className="form-control"
                                placeholder={GetMessageIntl(intl, "workflow.shapeConfig.tipInfoPlaceholder")}
                                style={{minHeight:"87px"}}
                            />
                            <div className="d-flex justify-content-between">
                                <div></div>
                                <div className="text-coco-gray-400 fs-12 fw-400">{method.getValues(`labels.${id}.tip`)?.length || 0}/100자</div>
                            </div>
                        </div>
                    </Row>
                </Col>
            </CardBody>
        </Card>
    );
}

export default ClsfcSmrySettingContent;
