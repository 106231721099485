import React, {createRef, useEffect, useState} from 'react';
import {Link, useHistory} from 'react-router-dom';
import {
    Col,
    ButtonGroup,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Row,
    UncontrolledDropdown
} from 'reactstrap';
import {ManagerProjectService} from "../../apis/api/ManagerProjectService";
import {useUserInfo} from "../../hooks/UserHooks";
import {GetMessage} from "../../util/message";
import {ManagerWorkflowService} from "../../apis/api/ManagerWorkflowService";
import NotificationDropdown from "../common/NotificationDropdown";
import ProfileDropdown from "../common/ProfileDropdown";
import html2canvas from "html2canvas";
import {confirm} from "../../util/AlertUtil";
import {useIntl} from "react-intl";

const SettingHeader = ({ headerBreadYn, headerClass }) => {
    const history = useHistory();
    const intl = useIntl();
    const ref = createRef();
    const targetPrjctId = sessionStorage.getItem("prjctId");
    const targetWrkflowId = sessionStorage.getItem("wrkflowId");
    const [projectList,setProjectList] = useState([]);
    const [workflowItem,setWorkflowItem] = useState(null);
    const { coconutUserInfo, loading } = useUserInfo();
    const dupMsg = GetMessage("validation.duplication");

    useEffect(()=>{
        getProjects();
        getWorkflowItem();
    },[]);


    const setWorkflowImageSave = async () => {
        const canvas = await html2canvas(document.getElementById("moduleReactFlow"));
        const dataURL = canvas.toDataURL('image/png');
        await ManagerWorkflowService.saveWorkflowThumnail({
            wrkflowId: targetWrkflowId,
            screenShot: dataURL
        })
    };

    const getProjects = async () => {
        const res = await ManagerProjectService.getProjectList({
            'orgnztId' : coconutUserInfo.orgnztId
        });
        if(res.data.data){
            setProjectList(res.data.data);
        }
    };

    const getWorkflowItem = async () => {
        const res = await ManagerWorkflowService.summaryItem({
            'wrkflowId' : targetWrkflowId
        });
        if(res.data.data){
            setWorkflowItem(res.data.data);
            console.log("wrkflowItem => ", res.data.data)
        }
    };

    const hadleClickDropDownItem = (prjctId)=>{
        setWorkflowImageSave();
        // confirm("common.confirm"
        //     , "alert.exit"
        //     , () => {
        //         sessionStorage.setItem("prjctId",prjctId);
        //         ManagerProjectService
        //             .getProjectItem({"prjctId":prjctId})
        //             .then((result)=>{
        //                 if(result.data){
        //                     history.push({
        //                         pathname:"/manager/project/dashboard"
        //                     });
        //                 }
        //
        //             });
        //     }
        //     , null
        //     , null
        //     , intl);
        sessionStorage.setItem("prjctId",prjctId);
        ManagerProjectService
            .getProjectItem({"prjctId":prjctId})
            .then((result)=>{
                if(result.data){
                    history.push({
                        pathname:"/manager/project/dashboard"
                    });
                }

            });
    }

    const hadleClickWorkflowNm = (event) => {
        setWorkflowItem({...workflowItem, 'editMode':true, 'nmLength': workflowItem.wrkflowNm.length});
    }

    const isWorkflowNmDuplication = async(event, wrkflowId, wrkflowNm)=>{
        if(wrkflowNm === ''){
            setWorkflowItem({...workflowItem, 'editMode':false});
            return;
        }
        await ManagerWorkflowService.isWorkflowNmDuplication({
            'prjctId': targetPrjctId,
            'wrkflowId': wrkflowId,
            'wrkflowNm': wrkflowNm,
        }).then((result)=>{
            if(!result.data.data){
                handleUpdateEvent( wrkflowId, wrkflowNm);
            }else{
                event.target.classList.add('is-invalid');
                event.target.parentElement.children[1].classList.add('invalid-tooltip');
                event.target.parentElement.children[1].innerText= dupMsg;
            }
        });

    }

    const handleKeyPress = (event, wrkflowId) =>{
        if (event.key === 'Enter') {
            isWorkflowNmDuplication(event, wrkflowId, event.target.value.trim());
        }
        else{
            event.target.classList.remove('is-invalid');
            event.target.parentElement.children[1].classList.remove('invalid-tooltip');
            event.target.parentElement.children[1].innerText= '';
        }
    }

    const handleUpdateEvent = async(wrkflowId, wrkflowNm)=>{
        await ManagerWorkflowService.updateWorkflowNm({
            'wrkflowId': wrkflowId,
            'wrkflowNm': wrkflowNm,
            'prjctId': targetPrjctId,
        }).then(res=>{
            if(res.data.data){
                setWorkflowItem({...workflowItem, 'wrkflowNm':wrkflowNm, 'editMode':false});
            }else{
                setWorkflowItem({...workflowItem, 'editMode':false});
            }
        })
    }
    const userRole = coconutUserInfo.userRole;

    return (
        <React.Fragment>
            <header>
                <div className="layout-width">
                    <div className="navbar-header pd-x-32">
                        <div className="d-flex">
                            <Row>
                                <Col xs={12}>
                                    <div className="d-sm-flex align-items-center justify-content-between">
                                        <div className="page-title-right d-flex align-items-center">
                                            <ButtonGroup>
                                                <UncontrolledDropdown>
                                                    <DropdownToggle tag="button" className="btn btn-light pd-x-12 pd-y-5 mr-12">
                                                        <i className="bx bx-dots-horizontal-rounded text-coco-gray-200"></i>
                                                    </DropdownToggle>
                                                    <DropdownMenu className={"default-scroll-bar"} style={{ maxHeight: "500px", overflowY:"scroll", zIndex:99999}}>
                                                        {projectList.length>0?
                                                            projectList.map((data, index)=> {
                                                                const {prjctId, prjctNm}= data;
                                                                return(
                                                                    <DropdownItem key={"menu_project_".concat(prjctId)} onClick={()=>hadleClickDropDownItem(prjctId)}>{prjctNm}</DropdownItem>
                                                                )
                                                            })
                                                            :GetMessage("common.noData")
                                                        }
                                                    </DropdownMenu>
                                                </UncontrolledDropdown>
                                            </ButtonGroup>
                                            <ol className="breadcrumb m-0">
                                                {workflowItem?
                                                    <>
                                                        <li className="breadcrumb-item text-coco-gray-700 tx-16 fw-semibold">
                                                            <div className="text-pointer" onClick={()=>hadleClickDropDownItem(workflowItem.prjctId)}>
                                                                {workflowItem.prjctNm}
                                                            </div>
                                                        </li>
                                                        <li className="breadcrumb-item text-coco-gray-700 tx-16 fw-semibold d-flex align-items-center">
                                                            <span onDoubleClick={hadleClickWorkflowNm}>
                                                                {workflowItem.editMode?
                                                                        <>
                                                                            <input type="text" defaultValue={workflowItem.wrkflowNm} className="form-control editable tx-16 pd-x-0-f pd-y-3 fw-semibold lh-19"
                                                                                   maxLength={50}
                                                                                   onBlur={(e)=>isWorkflowNmDuplication(e, workflowItem.wrkflowId, e.target.value.trim())}
                                                                                   onKeyUp={(e)=> handleKeyPress(e, workflowItem.wrkflowId)}
                                                                                   style={{minWidth: 250, width: 12 * workflowItem.nmLength}}
                                                                                   autoFocus />
                                                                            <p className="mb-0 validation-error-message"></p>
                                                                        </>
                                                                        : workflowItem.wrkflowNm
                                                                }
                                                            </span>
                                                        </li>
                                                    </>
                                                    : null
                                                }
                                            </ol>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>

                        <div className="d-flex align-items-center">
                            {/* NotificationDropdown */}
                            {userRole !== '20' ? <NotificationDropdown /> : null}

                            {/* ProfileDropdown */}
                            <ProfileDropdown />
                        </div>
                    </div>
                </div>
            </header>
        </React.Fragment>
    );
};

export default SettingHeader;