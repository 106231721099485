// @ts-nocheck
import {RightGroup} from "../../../components/header/right-group/RightGroup";
import {ExitBtn, FAQBtn, GuideBookBtn} from "../../../../../cmmn/components/header/rightGroup/RightGroupButtons";
import {useSelector} from "react-redux";

export const RightGroupContainer = (): JSX.Element => {
    const {
        Label: {
            Cmmn: {
                module: {
                    moduleId
                },
            }
        }
    } = useSelector(state => state);
    return (
        <RightGroup subKeyMap={null} handlers={null}>
            <>
                <FAQBtn moduleId={moduleId}/>
                <GuideBookBtn moduleId={moduleId}/>
                <ExitBtn/>
            </>
        </RightGroup>
    )
}
