// @ts-nocheck
import {
    CANCEL_ISSUE,
    COMMENT_ISSUE,
    COMMENT_ISSUE_SUCCESS,
    CREATE_ISSUE,
    CREATE_REVIEW_DATA_FAILED,
    CREATE_REVIEW_DATA_SUCCESS,
    FINISH_ISSUE,
    FINISH_ISSUE_FAILED,
    FINISH_ISSUE_SUCCESS,
    GET_REVIEW_STATES_FAILED,
    GET_REVIEW_STATES_SUCCESS,
    REMOVE_ISSUE,
    REMOVE_ISSUE_FAILED,
    REMOVE_ISSUE_SUCCESS,
    REOPEN_ISSUE,
    REOPEN_ISSUE_FAILED,
    REOPEN_ISSUE_SUCCESS,
    RESET_REVIEW_STATE_SUCCESS,
    RESOLVE_ISSUE,
    RESOLVE_ISSUE_FAILED,
    RESOLVE_ISSUE_SUCCESS,
    START_ISSUE,
    SUBMIT_ANNOTATIONS,
    SUBMIT_ANNOTATIONS_FAILED,
    SUBMIT_ANNOTATIONS_SUCCESS,
    SWITCH_ISSUES_HIDDEN_FLAG,
    SWITCH_RESOLVED_ISSUES_HIDDEN_FLAG,
    GET_ANNOTATIONS_REASONS,
    UPDATE_REVIEW_STATES,
    UPDATE_REVIEW_STATES_FAILED,
    UPDATE_REVIEW_STATES_SUCCESS,
    CHECK_VALIDATION,
    CHECK_VALIDATION_SUCCESS,
    UPDATE_COMPLETED_STATES,
    GET_ANNOTATIONS_REASONS_SUCCESS,
    GET_ANNOTATIONS_REASONS_FAILED, UPDATE_ACTIVE_SHAPE
} from "./actionType";
import {getCore} from "../../pages/user/label/annotation/image/cvat-core-wrapper";
import {Store} from "redux";
import {getRootStore} from "../index";
import ReviewInstance from "../../pages/user/label/annotation/image/work/core/review-instance";
import ReviewClasses from "../../pages/user/label/annotation/image/work/core/review-classes";
import {REVIEW_TASK_STTUS_CD} from "../../pages/user/label/annotation/image/const";

const core = getCore();
let store: null | Store = null;
function getStore(): Store {
    if (store === null) {
        store = getRootStore();
    }
    return store;
}
export const createIssue = () => ({
    type: CREATE_ISSUE,
    payload: {}
})
export const startIssue = (position: number[]) => ({
    type:  START_ISSUE,
    payload: { position: core.classes.Issue.hull(position) }
})
export const finishIssue = (message: string) => ({
    type: FINISH_ISSUE,
    payload: {
        message
    }
})
export const finishIssueSuccess = (frame: number, issue: any) => ({
    type: FINISH_ISSUE_SUCCESS,
    payload: { frame, issue }
})
export const finishIssueFailed = (error: any) => ({
    type: FINISH_ISSUE_FAILED,
    payload: { error }
})
export const cancelIssue = () => ({
    type: CANCEL_ISSUE,
    payload: {}
})
export const commentIssue = (issueId: number) => ({
    type: COMMENT_ISSUE,
    payload: {issueId}
})
export const commentIssueSuccess = () => ({
    type: COMMENT_ISSUE_SUCCESS,
    payload: {}
})
export const commentIssueFailed = (error: any) => ({
    type: CANCEL_ISSUE,
    payload: {error}
})
export const resolveIssue = (issueId: number) => ({
    type: RESOLVE_ISSUE,
    payload: {issueId}
})
export const resolveIssueSuccess = () => ({
    type: RESOLVE_ISSUE_SUCCESS,
    payload: {}
})
export const resolveIssueFailed = (error: any) => ({
    type: RESOLVE_ISSUE_FAILED,
    payload: {error}
})
export const reopenIssue = (issueId: number) => ({
    type: REOPEN_ISSUE,
    payload: {issueId}
})
export const reopenIssueSuccess = () => ({
    type: REOPEN_ISSUE_SUCCESS,
    payload: {}
})
export const reopenIssueFailed = (error: any) => ({
    type: REOPEN_ISSUE_FAILED,
    payload: {error}
})
export const removeIssue = (issueId: number) => ({
    type:REMOVE_ISSUE,
    payload: {issueId}
})
export const removeIssueSuccess = (issueId: number, frame: number) => ({
    type: REMOVE_ISSUE_SUCCESS,
    payload: { issueId, frame }
})
export const removeIssueFailed = (error: any) => ({
    type: REMOVE_ISSUE_FAILED,
    payload: {error}
})
export const switchIssuesHiddenFlag = (hidden: boolean) => ({
    type: SWITCH_ISSUES_HIDDEN_FLAG,
    payload: {hidden}
})
export const switchIssuesHiddenResolvedFlag = (hidden: boolean) => ({
    type: SWITCH_RESOLVED_ISSUES_HIDDEN_FLAG,
    payload: {hidden}
})
export const getReviewStatesSuccess = (reviewData) => ({
    type: GET_REVIEW_STATES_SUCCESS,
    payload: reviewData
})
export const getReviewStatesFailed = (error) => ({
    type: GET_REVIEW_STATES_FAILED,
    payload: error
})
export const getAnnotationsReasons = () => ({
    type: GET_ANNOTATIONS_REASONS,
    payload: {}
})
export const getAnnotationsReasonsSuccess = (reasons) => ({
    type: GET_ANNOTATIONS_REASONS_SUCCESS,
    payload: reasons
})
export const getAnnotationsReasonsFailed = (e) => ({
    type: GET_ANNOTATIONS_REASONS_FAILED,
    payload: e
})


export const updateCompletedStates = (reviewData: ReviewInstance | ReviewClasses, completedError: boolean) => {
    return ({
        type: UPDATE_COMPLETED_STATES,
        payload: { reviewData, completedError }
    });
}
export const updateReviewStates = (
    instance: ReviewInstance,
    classes: ReviewClasses
) => {
    let reviewData = getStore().getState().Review.Cmmn.annotations.data;

    if (instance) {
        reviewData.instance[instance.objectId] = instance;
    }

    if (classes) {
        reviewData.classes[classes.classId] = classes;
    }
    return {
        type: UPDATE_REVIEW_STATES,
        payload: reviewData
    };
}
export const updateReviewStatesSuccess = (instance, classes) => ({
    type: UPDATE_REVIEW_STATES_SUCCESS,
    payload: {instance, classes}
})
export const updateReviewStatesFailed = (error) => ({
    type: UPDATE_REVIEW_STATES_FAILED,
    payload: error
})
export const createReviewDataSuccess = (data) => ({
    type: CREATE_REVIEW_DATA_SUCCESS,
    payload: data
})
export const createReviewDataFailed = (error) => ({
    type: CREATE_REVIEW_DATA_FAILED,
    payload: error
})
export const submitAnnotations = (json, taskSttusCd: REVIEW_TASK_STTUS_CD, intl, history, dispatch, form) => ({
    type: SUBMIT_ANNOTATIONS,
    payload: {json, taskSttusCd},
    hooks: {intl, history, dispatch, form}
})
export const submitAnnotationsSuccess = (data, intl, history, dispatch) => ({
    type: SUBMIT_ANNOTATIONS_SUCCESS,
    hooks: {intl, history, dispatch}
})
export const submitAnnotationsFailed = (e) => ({
    type: SUBMIT_ANNOTATIONS_FAILED,
    payload: e
})
export const resetReviewStatesSuccess = (reviewData, moduleStatus) => ({
    type: RESET_REVIEW_STATE_SUCCESS,
    payload: {reviewData, moduleStatus}
})
export const checkValidation = (moduleInstance, intl, history, dispatch) => ({
    type: CHECK_VALIDATION,
    payload: { moduleInstance },
    hooks: { intl, history, dispatch }
})
export const checkValidationSuccess = (errorVisible, instanceNotEditedCnt, classesNotEditedCnt) => ({
    type: CHECK_VALIDATION_SUCCESS,
    payload: { errorVisible, instanceNotEditedCnt, classesNotEditedCnt }
})
export const updateActiveShape = (activatedStateID: number) => ({
    type: UPDATE_ACTIVE_SHAPE,
    payload: {activatedStateID}
})
