import {Button, CardHeader} from "reactstrap";

import React from "react";
import {ShapeIcon} from "../../../const";
import {OBJECT_POPOVER_HEADER} from "../../classNameConsts";
interface Props{
    shapeType: string;
    labelName: string;
    handleClose(e: MouseEvent): void;
}
export function ObjectPopoverHeader(props) {
    const {shapeType, labelName, handleClose} = props;
    return(
        <CardHeader className={OBJECT_POPOVER_HEADER +
            " coco-gray-700 d-flex align-items-center justify-content-between py-3 cursor"}
        >
            <div className="d-flex align-items-center coco-object-popover-header text-overflow">
                <i className={"me-2 i-coco-20 " + (ShapeIcon[shapeType])}></i>
                <span className={"text-overflow"} style={{width: "200px"}}>{labelName}</span>
            </div>
            <div className={"d-flex align-items-center fs-20 btn"} onClick={handleClose}>
                <i className="ri-close-fill"></i>
            </div>
        </CardHeader>
    )
}
