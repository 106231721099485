import React from "react";
import {GetMessage} from "../../../../../../../util/message";

{/*instanceTitle*/}
const StageHeader = ({endTitle}) => {

    return (
        <React.Fragment>
            <div className="d-flex align-items-center text-coco-gray-700 tab-header pd-x-24-f pd-t-24 pd-b-12 tx-16 fw-semibold">
                <div className="wd-8p text-center">
                    #
                </div>
                <div className="wd-76p ps-2">
                    {GetMessage("ui.U00148")}
                </div>
                <div className="wd-16p text-center ps-2">
                    {GetMessage("ui.U00090")}
                </div>
            </div>
        </React.Fragment>
    )
}
export default StageHeader;
