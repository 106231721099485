// @ts-nocheck
import {useIntl} from "react-intl";
import {useDispatch, useSelector} from "react-redux";
import React, {useEffect, useState} from "react";
import {GetMessageIntl} from "../../../../../util/message";
import {getDataList, getDatasetByWorkflow, updateSearchParam} from "../../../../../store/dataset/details/action";
import {SearchFilter} from "../../../../../store/dataset/details";
import {DropdownItem, Input, Label} from "reactstrap";
import {ApplySearchButton} from "./search-header";
import {cloneDeep} from "lodash";

export function DatasetFilter(): JSX.Element{
    const intl = useIntl();
    const dispatch = useDispatch();
    const {
        Details: {
            workflow: {id: wrkflowId},
            searchParam: {
                view,
                data,
                dataset
            }
        }
    } = useSelector(state => state);
    const choiceCdList = data.dataset;
    const choiceLabelList = view.dataset;
    useEffect(() => {
        if (!!!dataset.length) {
            dispatch(getDatasetByWorkflow(wrkflowId))
        }
    }, [dataset])
    const onCdChange = (target, label) => {
        const checked = target.checked;
        const value = target.value;
        let newValues = cloneDeep(choiceCdList);
        let newLabels = cloneDeep(choiceLabelList);
        const label_ = checked ? newLabels.concat({label: label, index: dataset.indexOf(label)}) : newLabels.filter(v => v.index != valueSttus.index);
        label_.sort(function (a, b) {
            return a.index - b.index
        })
        dispatch(updateSearchParam(SearchFilter.DATASET, checked ? newValues.concat(value) : newValues.filter(v => v!=value), label_))
    }

    return (
        <div className={"filter-container"}>
            <div className={"d-flex align-items-center justify-content-between"}>
                <DropdownItem header>{GetMessageIntl(intl, 'details.dataset')}</DropdownItem>
                <ApplySearchButton />
            </div>
            <DropdownItem divider/>
            <div className={"d-flex flex-column align-items-center gap-2"}>
                {dataset.map(
                    (item: any, index: number): JSX.Element => {
                        const { datasetNm: label, datasetId: value} = item;
                        return (
                            <DropdownItem  key={`dataset-filter-${index}`} toggle={false}>
                                <div className="form-check form-check-coco">
                                    <Input className="form-check-input" type="checkbox"
                                           id={`filter-dataset-${index}`}
                                           value={value}
                                           defaultChecked={choiceCdList.includes(value)}
                                           onChange={(event) => {
                                               onCdChange(event.target, label)
                                           }}
                                    />
                                    <Label className="form-check-label" htmlFor={`filter-dataset-${index}`}>
                                        {label}
                                    </Label>
                                </div>
                            </DropdownItem>
                        )
                    }
                )}
            </div>
        </div>
    )
}
