import React, {useEffect, useState} from 'react';
import {Row} from "reactstrap";
import CommonUtil from "../../../util/CommonUtil";

function EditorStep(props) {
    const [attachFiles, setAttachFiles] = useState([]);

    useEffect(() => {
        setAttachFiles(props.body.attachFiles)
    }, [])

    function downloadFile(filePath, realFileName) {
        fetch(filePath, {method: 'GET'})
            .then((res) => {
                return res.blob() // raw 데이터를 받아온다
            })
            .then((blob) => {
                const url = window.URL.createObjectURL(blob) // 받아온 날 상태의 data를 현재 window에서만 사용하는 url로 바꾼다
                const a = document.createElement('a')
                a.href = url
                a.download = realFileName // 원하는 이름으로 파일명 지정
                document.body.appendChild(a)
                a.click() // 자동으로 눌러버리기
                setTimeout((_) => {
                    window.URL.revokeObjectURL(url) // 해당 url을 더 사용 못하게 날려버린다
                }, 60000)
                a.remove() // a를 다 사용했으니 지워준다
                return false;
            })
            .catch((err) => {
                console.error('err: ', err)
            })
    }

    return (
        <>
            <div className="fs-24 text-coco-gray-700">
                {props.title}
            </div>
            <hr/>
            <div className="text-coco-gray-700">
                <Row className="mb-12">
                    {
                        attachFiles.map((fileInfo, idx) => {
                            const {downloadUrl, host, realFilename} = fileInfo;
                            return (
                                <Row className="mb-3" key={`editor_${props.title}_${idx}`}>
                                    <div className={"d-flex align-items-center"}>
                                        <i className="ri-download-2-line text-coco-gray-400" onClick={() => {
                                            const filePath = host + downloadUrl;
                                            console.log(filePath)
                                            downloadFile(filePath, realFilename);
                                        }}></i>
                                        <span>&nbsp;{realFilename}</span>
                                    </div>
                                </Row>
                            )
                        })
                    }
                </Row>
                <Row className="fw-500">
                    <div className="stepCnDiv" dangerouslySetInnerHTML={{__html: props.body.content}}></div>
                </Row>
            </div>
        </>
    );
}

export default EditorStep;