const prefix = "coco-"
// control-side-bar
export const CONTROLS_SIDE_BAR = prefix + 'controls-side-bar';
// objects-side-bar
export const OBJECT_REVIEW_DETAILS = 'object-review-details';
export const LABEL_REVIEW_DETAILS = 'label-review-details';
// context-menu
export const CANVAS_CONTEXT_MENU = prefix + 'canvas-context-menu';
export const OBJECT_POPOVER_HEADER = prefix + "object-popover-header";
export const CHIP_OPTIONS_ICON = prefix + 'chip-options-icon';
export const CHIP_OPTIONS_CONTAINER = prefix + 'chip-options-container';
export const SKIP_POPOVER_INPUT_TEXT_CONTAINER = 'skip-popover-input-text-container';
export const SKIP_POPOVER_INPUT_TEXT = 'skip-popover-input-text';
export const STATE_ITEM_LABEL_SELECTOR = prefix + 'objects-sidebar-state-item-label-selector';
