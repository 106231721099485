// @ts-nocheck
import {DropdownItem, Input, Label} from "reactstrap";
import {GetMessageIntl} from "../../../../../util/message";
import {ApplySearchButton} from "./search-header";
import React, {useEffect, useState} from "react";
import {useIntl} from "react-intl";
import {useDispatch, useSelector} from "react-redux";
import {cloneDeep} from "lodash";
import {updateSearchParam} from "../../../../../store/dataset/details/action";
import {SearchFilter} from "../../../../../store/dataset/details";
import {AttributeType} from "../../../../user/label/annotation/image/work/core/enums";

export const ClsfcSmryRejectFilter = (): JSX.Element => {
    const intl = useIntl();
    const dispatch = useDispatch();

    const {
        Label:{
            Cmmn: {module: {labels}}
        },
        Details: {
            searchParam: {view, data}
        }
    } = useSelector(state => state);

    const [questions, setQuestions] = useState([]);
    const choiceCdList = data.rejectClsfcSmry;
    const choiceLabelList = view.rejectClsfcSmry;

    useEffect(() => {
        if (labels.length === 0) return;
    }, labels)

    const [rejectRsn, setRejectRsn] = useState([]);
    const onUpdateCd = (target, label, id, type, name) => {
        const checked = target.checked;
        const value = target.value;

        let newValues = [...choiceCdList];
        let questionObject = newValues.filter(v => v.id === id)[0];
        let isFirst = questionObject === undefined;
        // console.log('onCdChange', checked, value, label, id, type, questionObject)

        // object 만들기
        let object = {id, type, q1: false, q2: false, q3: false};
        if (questionObject === undefined) { // 처음 체크 되는 거라면
            if (value !== "pass") object[value] = checked;
            questionObject = {...object};
        } else {    // 이전 object가 있으면
            if (value !== "pass") {
                questionObject[value] = checked;
            } else {
                questionObject = {...object};
            }
        }
        // console.log('onCdChange 2', questionObject)

        // 체크가 다 해제 되었는 지 확인
        let isUnAllChecked = !checked;
        if (checked === false) {
            const nodeList = document.getElementsByName(name);
            for (const node of nodeList) {
                if (node.checked) {
                    isUnAllChecked = false;
                    break;
                }
            }
        }

        let newLabels = cloneDeep(choiceLabelList);
        const value_ = !isUnAllChecked ?
                            (isFirst ? newValues.concat(questionObject)
                                : newValues.map(o => o.id === id ? questionObject : o))
                        : newValues.filter(v => v.id!=id);
        const label_ = checked ? newLabels.concat({label: label, index: value, id: id})
                        : newLabels.filter(v => !(v.id === id && v.index === value))
        // console.log('label_', label_)
        label_.sort(function (a, b) {
            return a.id - b.id || a.index.localeCompare(b.index)
        })
        dispatch(updateSearchParam(SearchFilter.LAST_REJECT_REASONS_CLSFC, value_, label_))
    }

    const onToggleChange = (target) => {
        const checked = target.checked;
        const value = target.value;
        if (value === "pass") {

        }
    }

    const classificationRejectList = [
        {value: "q1", label: GetMessageIntl(intl, "details.classificationError")},
        {value: "pass", label: GetMessageIntl(intl, "details.noExistReject")},
    ]
    const summaryRejectList = [
        {value: "q1", label: GetMessageIntl(intl, "details.missingKeywordError")},
        {value: "q2", label: GetMessageIntl(intl, "details.spellingError")},
        {value: "q3", label: GetMessageIntl(intl, "details.otherErrors")},
        {value: "pass", label: GetMessageIntl(intl, "details.noExistReject")},
    ]

    return (
        <>
            <div className={"filter-container"}>
                <div className={"d-flex align-items-center justify-content-between"}>
                    <DropdownItem header>{GetMessageIntl(intl, 'details.lastRejectReasons')}</DropdownItem>
                    <ApplySearchButton />
                </div>
                <DropdownItem divider/>
                <div className={"d-flex flex-column filter-contents default-scroll-bar"} style={{gap: "20px", maxHeight: "500px", overflowY:"scroll", paddingRight: "6px"}}>
                    {labels.map(
                        (label: any, index): JSX.Element => {
                            const id = label.id;
                            const title = label.question;
                            const type = [AttributeType.SELECT, AttributeType.CHECKBOX].includes(label.attributes[0].inputType) ? 'clsfc' : 'smry';
                            const rejectList = type === 'clsfc' ? classificationRejectList : summaryRejectList;
                            return (
                                <div key={`filter-group-${index}`} className={"filter-group"}>
                                    <DropdownItem header> {title} </DropdownItem>
                                    <DropdownItem divider/>
                                    <div className={"d-flex flex-column gap-2"}>
                                        {rejectList.map((rsn, idx) => {
                                                const rsnCd = rsn.value;
                                                const inputId = `${id}-${rsnCd}`
                                                const disabled = () => {
                                                    const checkedObject = choiceCdList.find(o => o.id === id);
                                                    let result = false;
                                                    if (checkedObject) {
                                                        const isPass = checkedObject['q1'] === false && checkedObject['q2'] === false && checkedObject['q3'] === false
                                                        result = rsnCd === 'pass' ? !isPass : isPass;
                                                    }
                                                    return result;
                                                }
                                                const defaultChecked = () => {
                                                    const checkedObject = choiceCdList.find(o => o.id === id);
                                                    let result = false;
                                                    if (checkedObject) {
                                                        if (rsnCd === 'pass') {
                                                            result = checkedObject['q1'] === false && checkedObject['q2'] === false && checkedObject['q3'] === false;
                                                        } else {
                                                            result = checkedObject[rsnCd];
                                                        }
                                                    }
                                                    return result;
                                                }

                                                return (
                                                    <DropdownItem  key={`reject-filter-${id}-${idx}`} toggle={false}>
                                                        <div className="form-check form-check-coco">
                                                            <Input className="form-check-input" type="checkbox"
                                                                   id={inputId}
                                                                   name={`reject-filter-${id}`}
                                                                   value={rsnCd}
                                                                   defaultChecked={defaultChecked()}
                                                                   disabled={disabled()}
                                                                   onChange={(event) => {
                                                                       onUpdateCd(event.target, rsn.label, id, type, event.target.name)
                                                                   }}
                                                            />
                                                            <Label className="form-check-label" htmlFor={inputId}>
                                                                {rsn.label}
                                                            </Label>
                                                        </div>
                                                    </DropdownItem>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            )
                        }
                    )}
                </div>
            </div>
        </>
    )
}
