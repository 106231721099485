import { combineReducers } from "redux";

// Front
import Layout from "./layouts/reducer";
import depthLayout from "./depthLayouts/reducer";
import Dataset from "./dataset/reducer";
import Details from "./dataset/details/reducer";
import ManagerBoard from "./manage/board/reducer";
import UserBoard from "./user/board/reducer";
import Manage from "./manage/reducer";
import User from "./user/reducer";
import Cmmn from "./label/reducer"
import ManageLabel from "./manage/label/reducer";
import Board from "./board/reducer";

// import authReducer from "../cvat/reducers/auth-reducer";
// import projectsReducer from "../cvat/reducers/projects-reducer";
// import tasksReducer from "../cvat/reducers/tasks-reducer";
// import jobsReducer from "../cvat/reducers/jobs-reducer";
// import aboutReducer from "../cvat/reducers/about-reducer";
// import shareReducer from "../cvat/reducers/share-reducer";
// import formatsReducer from "../cvat/reducers/formats-reducer";
// import pluginsReducer from "../cvat/reducers/plugins-reducer";
// import modelsReducer from "../cvat/reducers/models-reducer";
// import notificationsReducer from "../cvat/reducers/notifications-reducer";
// import annotationReducer from "../cvat/reducers/annotation-reducer";
import settingsReducer from "./settings/reducer";
import shortcutsReducer from "./shortcuts/reducer";
import reviewCmmnReducer from "./review/reducer";
// import exportReducer from "../cvat/reducers/export-reducer";
// import importReducer from "../cvat/reducers/import-reducer";
// import cloudStoragesReducer from "../cvat/reducers/cloud-storages-reducer";
// import organizationsReducer from "../cvat/reducers/organizations-reducer";
// import webhooksReducer from "../cvat/reducers/webhooks-reducer";
// import userAgreementsReducer from '../cvat/reducers/useragreements-reducer';

import ManageProject from "./manage/project/reducer";
import ManageWorkflow from "./manage/project/workflow/reducer";
import ManageWorker from "./manage/worker/reducer";
import UserTask from "./user/task/reducer";
import ManageManager from "./manage/manager/reducer";
import Bell from "./bell/reducer";
import ManageWorkflowModule from "./manage/project/workflow/module/reducer";
import ManageWorkflowLabel from "./manage/project/workflow/label/reducer";
import ManageWorkflowReview from "./manage/project/workflow/review/reducer";
import WorkflowReviewTestConfig from "./manage/project/workflow/review/TestConfig/reducer";
import WorkflowLabelTestConfig from "./manage/project/workflow/label/TestConfig/reducer";
import Dashboard from './dashboard/reducer'
import ClsfcSmry from "./label/clsfcSmry/reducer";
import Annotation from "./label/annotation/reducer";
import ClsfcSmryReview from "./review/clsfcSmry/reducer";
import AnnotationReview from "./review/annotation/reducer";

export default function rootReducer() {
    return combineReducers({
        // pages
        Layout,
        depthLayout,
        ManageWorker,
        ManageLabel,
        ManageProject,
        ManageWorkflow,
        ManageWorkflowModule,
        ManageWorkflowLabel,
        ManageWorkflowReview,
        Manage,
        Dataset,
        ManagerBoard,
        UserBoard,
        UserTask,
        User,
        ManageManager,
        Bell,
        Board,
        Label: combineReducers({
            Cmmn,
            Annotation,
            ClsfcSmry
        }),
        Review: combineReducers({
            Cmmn: reviewCmmnReducer,
            Annotation: AnnotationReview,
            ClsfcSmry: ClsfcSmryReview
        }),
        WorkflowReviewTestConfig,
        WorkflowLabelTestConfig,
        Dashboard,
        Details,
        // Cmm Store
        settings: settingsReducer,
        shortcuts: shortcutsReducer,

    });
}

