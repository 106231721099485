import {
    CHECK_UNABLE_WORK,
    CHECK_UNABLE_WORK_FAILED,
    CHECK_UNABLE_WORK_SUCCESS,
    CLOSE_WORKFLOW,
    GET_SKIP_RSN,
    GET_SKIP_RSN_FAILED,
    GET_SKIP_RSN_SUCCESS,
    GET_WORKFLOW,
    GET_WORKFLOW_SUCCESS,
    RECOVERY_DATA,
    RECOVERY_DATA_FAILED,
    RECOVERY_DATA_SUCCESS,
    SET_DATA_LIST,
    SET_DATA_STTUS,
    GET_FILE_INFO,
    GET_FILE_INFO_FAILED,
    GET_FILE_INFO_SUCCESS,
    GET_REJECT_RSN,
    GET_REJECT_RSN_SUCCESS,
    GET_REJECT_RSN_FAILED,
    GET_HISTORY,
    GET_HISTORY_SUCCESS,
    GET_HISTORY_FAILED,
    UPDATE_SEARCH_PARAM_VISIBLE,
    UPDATE_SEARCH_PARAM,
    UPDATE_SEARCH_PARAM_FAILED,
    UPDATE_SEARCH_PARAM_SUCCESS,
    GET_DATASET_BY_WORKFLOW,
    GET_DATASET_BY_WORKFLOW_SUCCESS,
    GET_DATASET_BY_WORKFLOW_FAILED,
    GET_MODULE_BY_WORKFLOW,
    GET_MODULE_BY_WORKFLOW_SUCCESS,
    GET_MODULE_BY_WORKFLOW_FAILED,
    GET_DATA_LIST,
    GET_WORKER_BY_WORKFLOW,
    GET_WORKER_BY_WORKFLOW_SUCCESS,
    GET_WORKER_BY_WORKFLOW_FAILED,
    RESET_SEARCH_PARAM_TYPE,
    UPDATE_SEARCH_PARAM_ORDER,
    GET_JSON,
    GET_JSON_SUCCESS,
    GET_JSON_FAILED,
    UPDATE_JSON_MODAL,
    UPDATE_STAGE_TAB_ACTIVE,
    SET_LEFT_SIDEBAR_WIDTH,
    SET_RIGHT_SIDEBAR_WIDTH
} from "./actionType";
import {DisplayState, SearchFilter} from "./index";

export const getWorkflow = (workflow, module, frame) => ({
    type: GET_WORKFLOW,
    payload: {workflow, module, frame}
})

export const getWorkflowSuccess = () => ({
    type: GET_WORKFLOW_SUCCESS,
})

export const closeWorkflow = () => ({
    type: CLOSE_WORKFLOW,
    payload: {}
})

export const updateSearchParamVisible = (
    visible: SearchFilter[]
) => ({
    type: UPDATE_SEARCH_PARAM_VISIBLE,
    payload: {visible}
})

export const updateSearchParam = (
    type: SearchFilter,
    param: any,
    view: any
) => ({
    type: UPDATE_SEARCH_PARAM,
    payload: {type, param, view}
})

export const updateSearchParamSuccess = (searchData, viewData) => ({
    type: UPDATE_SEARCH_PARAM_SUCCESS,
    payload: {searchData, viewData}
})

export const updateSearchParamFailed = (e) => ({
    type: UPDATE_SEARCH_PARAM_FAILED,
    payload: e
})

export const updateSearchParamOrder = (order) => ({
    type: UPDATE_SEARCH_PARAM_ORDER,
    payload: {order}
})

export const resetSearchParamType = (type: SearchFilter) => ({
    type: RESET_SEARCH_PARAM_TYPE,
    payload: {type}
})

export const getDatasetByWorkflow = (wrkflowId) => ({
    type: GET_DATASET_BY_WORKFLOW,
    payload: {wrkflowId}
})
export const getDatasetByWorkflowSuccess = (data) => ({
    type: GET_DATASET_BY_WORKFLOW_SUCCESS,
    payload: {data}
})
export const getDatasetByWorkflowFailed = (e) => ({
    type: GET_DATASET_BY_WORKFLOW_FAILED,
    payload: e
})
export const getModuleByWorkflow = (wrkflowId) => ({
    type: GET_MODULE_BY_WORKFLOW,
    payload: {wrkflowId}
})
export const getModuleByWorkflowSuccess = (data) => ({
    type: GET_MODULE_BY_WORKFLOW_SUCCESS,
    payload: {data}
})
export const getModuleByWorkflowFailed = (e) => ({
    type: GET_MODULE_BY_WORKFLOW_FAILED,
    payload: e
})

export const getWorkerByWorkflow = (wrkflowId) => ({
    type: GET_WORKER_BY_WORKFLOW,
    payload: {wrkflowId}
})
export const getWorkerByWorkflowSuccess = (data) => ({
    type: GET_WORKER_BY_WORKFLOW_SUCCESS,
    payload: {data}
})
export const getWorkerByWorkflowFailed = (e) => ({
    type: GET_WORKER_BY_WORKFLOW_FAILED,
    payload: e
})

export const getDataList = () => ({
    type: GET_DATA_LIST,
    payload: {}
})
export const setDataList = (dataList) => ({
    type: SET_DATA_LIST,
    payload: {dataList}
})

export const getFileInfo = ({moduleId, datasetId, fileSeq}, intl) => ({
    type: GET_FILE_INFO,
    payload: {moduleId, datasetId, fileSeq},  // fileInfo
    hooks: {intl}
})
export const getFileInfoSuccess = ({
        labelModuleId,
        labelModuleNm,
        reviewModuleId,
        reviewModuleNm,
        labelSttus,
        reviewSttus,
        labelUserSeq,
        labelUserNm,
        reviewUserSeq,
        reviewUserNm,
        mngConfirmCd,
        nextTaskNm,
        lbTaskSttusCd,
        nextTaskSttusCd,
        lstTaskSttusCd,
        rejectCount,
        datasetNm,
        regDtmText
}, intl) => ({
    type: GET_FILE_INFO_SUCCESS,
    payload: {
        labelModuleId,
        labelModuleNm,
        reviewModuleId,
        reviewModuleNm,
        labelSttus,
        reviewSttus,
        labelUserSeq,
        labelUserNm,
        reviewUserSeq,
        reviewUserNm,
        mngConfirmCd,
        nextTaskNm,
        lbTaskSttusCd,
        nextTaskSttusCd,
        lstTaskSttusCd,
        rejectCount,
        datasetNm,
        regDtmText
    },
    hooks: {intl}
})
export const getFileInfoFailed = (e) => ({
    type: GET_FILE_INFO_FAILED,
    payload: e
})

export const setDataSttus = ({sttus, lastRejectRsn}
     : {sttus: DisplayState, lastRejectRsn: string[]}
) => ({
    type: SET_DATA_STTUS,
    payload: {sttus, lastRejectRsn}
})

export const getRejectRsn = ({labelModuleId, datasetId, fileSeq}) => ({
    type: GET_REJECT_RSN,
    payload: {labelModuleId, datasetId, fileSeq}
})
export const getRejectRsnSuccess = ({lastRejectRsn}) => ({
    type: GET_REJECT_RSN_SUCCESS,
    payload: {lastRejectRsn}
})
export const getRejectRsnFailed = (e) => ({
    type: GET_REJECT_RSN_FAILED,
    payload: e
})

export const getSkipRsn = (labelModuleId, datasetId, fileSeq) => ({
    type: GET_SKIP_RSN,
    payload: {labelModuleId, datasetId, fileSeq}
})
export const getSkipRsnSuccess = ({rsnCd, rsnCn}) => ({
    type: GET_SKIP_RSN_SUCCESS,
    payload: {rsnCd, rsnCn}
})
export const getSkipRsnFailed = (e) => ({
    type: GET_SKIP_RSN_FAILED,
    payload: e
})

export const recoveryData = ({moduleId, datasetId, fileSeq}, intl) => ({
    type: RECOVERY_DATA,
    payload: {moduleId, datasetId, fileSeq},
    hooks: {intl}
})
export const recoveryDataSuccess = ({moduleId, datasetId, fileSeq}, intl) => ({
    type: RECOVERY_DATA_SUCCESS,
    payload: {moduleId, datasetId, fileSeq},
    hooks: {intl}
})
export const recoveryDataFailed = (e) => ({
    type: RECOVERY_DATA_FAILED,
    payload: e
})

export const checkUnableWork = ({moduleId, datasetId, fileSeq}, intl) => ({
    type: CHECK_UNABLE_WORK,
    payload: {moduleId, datasetId, fileSeq},
    hooks: {intl}
})
export const checkUnableWorkSuccess = ({moduleId, datasetId, fileSeq}, intl) => ({
    type: CHECK_UNABLE_WORK_SUCCESS,
    payload: {moduleId, datasetId, fileSeq},
    hooks: {intl}
})
export const checkUnableWorkFailed = (e) => ({
    type: CHECK_UNABLE_WORK_FAILED,
    payload: e
})

export const getHistory = (wrkflowId, datasetId, fileSeq, {intl}) => ({
    type: GET_HISTORY,
    payload: {wrkflowId, datasetId, fileSeq},
    hooks: {intl}
})
export const getHistorySuccess = (historyData, display) => ({
    type: GET_HISTORY_SUCCESS,
    payload: {historyData, display}
})
export const getHistoryFailed = (e) => ({
    type: GET_HISTORY_FAILED,
    payload: e
})

export const getJson = (moduleId, datasetId, fileSeq) => ({
    type: GET_JSON,
    payload: {moduleId, datasetId, fileSeq}
})
export const getJsonSuccess = (json: string) => ({
    type: GET_JSON_SUCCESS,
    payload: json
})
export const getJsonFailed = (e) => ({
    type: GET_JSON_FAILED,
    payload: e
})

export const updateJsonModal = (visible: boolean) => ({
    type: UPDATE_JSON_MODAL,
    payload: visible
})

export const updateStageTabActive = (tabIndx: number) => ({
    type: UPDATE_STAGE_TAB_ACTIVE,
    payload: tabIndx
})

export const setLeftSidebarWidth = (width: number) => ({
    type: SET_LEFT_SIDEBAR_WIDTH,
    payload: width
})

export const setRightSidebarWidth = (width: number) => ({
    type: SET_RIGHT_SIDEBAR_WIDTH,
    payload: width
})