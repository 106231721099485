import {
  SET_LIST,
  SET_SEARCH_PARAM,
  SET_INIT_SEARCH_PARAM,
  SET_ORDER_PARAM,
  SET_INIT_ORDER_PARAM,
} from './actionType';

const INIT_STATE = {
  workflowList: [],
  searchParam: [],
  orderParam: ["REG_DTM"],
  error: {},
};

const ManageWorkflow = (state = INIT_STATE, action) => {
  switch (action.type) {
      case SET_LIST:
        return {
          ...state,
          workflowList: action.payload,
        };
     case SET_SEARCH_PARAM :
       return {
         ...state,
         searchParam: [action.payload]
       };
     case SET_INIT_SEARCH_PARAM :
       return {
         ...state,
         searchParam: []
       };

    case SET_ORDER_PARAM :
      return {
        ...state,
        orderParam: [action.payload]
      };
    case SET_INIT_ORDER_PARAM :
      return {
        ...state,
        orderParam: INIT_STATE.orderParam
      };

    default:
      return state;
  }
};

export default ManageWorkflow;
