import React, { memo } from 'react';
import {Handle, useReactFlow, useStoreApi, Position, useStore} from 'reactflow';
import {GetMessage} from "../../../../../../util/message";
export default function LabeledNode({ id, data, isConnectable }) {
    const sourceHandleStyle = {visibility: 'hidden', zIndex:1};
    return (
        <>
            <Handle
                type="source"
                style={sourceHandleStyle}
                position={Position.Right}
                id={'right'}
                isConnectable={isConnectable}
            />
            <Handle
                type="source"
                style={sourceHandleStyle}
                position={Position.Left}
                id={'left'}
                isConnectable={isConnectable}
            />
            {GetMessage("workflow.skip")}
        </>
    );
}