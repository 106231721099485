import React, { memo } from 'react';
import {Handle, useReactFlow, useStoreApi, Position, useStore, useNodeId} from 'reactflow';
import {Progress} from "reactstrap";
import {
    manage_project_workflow_module_setIsDatasetOffcanvas, manage_project_workflow_module_setIsModuleOffcanvas
} from "../../../../../../store/manage/project/workflow/module/action";
import {useDispatch} from "react-redux";
import {thisSourceValidConnection, thisTargetValidConnection} from "./nodeCommonUtil";
import {useIntl} from "react-intl";
import {GetMessageIntl} from "../../../../../../util/message";
const connectionNodeIdSelector = (state) => {
    return state.connectionNodeId;
}
const nodesSelector = (state) => Array.from(state.nodeInternals.values());

export default function UnabledNode({ id, data, isConnectable }) {
    const dispatch = useDispatch();
    const intl = useIntl();
    const nodes = useStore(nodesSelector);
    const connectionNodeId = useStore(connectionNodeIdSelector);
    let connectionNodeType = '';
    if(connectionNodeId){
        let connectionNode = nodes.find((item) => item.id === connectionNodeId);
        let parentNodeType = '';
        if(connectionNode.parentNode){
            parentNodeType = nodes.find((item) => item.id === connectionNode.parentNode).type+'_';
        }
        connectionNodeType = parentNodeType + connectionNode.type;
    }
    const isTarget = connectionNodeId && connectionNodeId !== id && thisTargetValidConnection('unabled', connectionNodeType);
    const targetHandleStyle = isTarget? { visibility: 'visible', zIndex: 10 } : {visibility: 'hidden', zIndex:1};

    const isSourceValidConnection = (connection) => {
        let connectSourceType = nodes.find((item) => item.id === connection.target).type;
        return thisSourceValidConnection('unabled',connectSourceType);
    }

    return (
        <>
            <div className="d-flex align-items-center mb-12">
                <div className="flex-shrink-0 avatar-42">
                    <div className="avatar-title fs-20 rounded-circle" style={{backgroundColor:'#E4F3FF', color:'#44A9FF'}}>
                        <i className="ri-close-circle-line lh-19"></i>
                    </div>
                </div>
                <div className="flex-grow-1 text-overflow ml-13">
                    <h6 className="text-coco-gray-200 tx-12 lh-16 mb-2">STATUS MARK</h6>
                    <span className="text-coco-gray-700 tx-16 lh-20 fw-semibold">{GetMessageIntl(intl,"workflow.unabled")}</span>
                </div>
            </div>
            <Handle
                className="sourceHandle"
                /*style={sourceHandleStyle}*/
                position={Position.Top}
                id={'top'}
                type="source"
                isConnectable={isConnectable}
                isValidConnection={isSourceValidConnection}
            />
            <Handle
                className="sourceHandle"
                /*style={sourceHandleStyle}*/
                position={Position.Right}
                id={'right'}
                type="source"
                isConnectable={isConnectable}
                isValidConnection={isSourceValidConnection}
            />
            <Handle
                className="sourceHandle"
                /*style={sourceHandleStyle}*/
                position={Position.Bottom}
                id={'bottom'}
                type="source"
                isConnectable={isConnectable}
                isValidConnection={isSourceValidConnection}
            />
            <Handle
                className="sourceHandle"
                /*style={sourceHandleStyle}*/
                position={Position.Left}
                id={'left'}
                type="source"
                isConnectable={isConnectable}
                isValidConnection={isSourceValidConnection}
            />


            <Handle
                className="targetHandle"
                style={targetHandleStyle}
                position={Position.Top}
                id={'top'}
                type="target"
                isConnectable={isConnectable}
            />
            <Handle
                className="targetHandle"
                style={targetHandleStyle}
                position={Position.Right}
                id={'right'}
                type="target"
                isConnectable={isConnectable}
            />
            <Handle
                className="targetHandle"
                style={targetHandleStyle}
                position={Position.Bottom}
                id={'bottom'}
                type="target"
                isConnectable={isConnectable}
            />
            <Handle
                className="targetHandle"
                style={targetHandleStyle}
                position={Position.Left}
                id={'left'}
                type="target"
                isConnectable={isConnectable}
            />
        </>
    );
}