import React, {useEffect, useState} from 'react';
import {Col, Dropdown, DropdownMenu, DropdownToggle, Nav, NavItem, NavLink, Row, TabContent, TabPane} from 'reactstrap';
import {Link} from 'react-router-dom';
import classnames from 'classnames';

//import images

//SimpleBar
import SimpleBar from "simplebar-react";
import {useSelector} from "react-redux";
import {CommonService} from '../../apis/api/CmmnService'
import {BellArarmService} from "../../apis/api/BellAlarmService";
import {GetMessage} from "../../util/message";

const NotificationDropdown = (props) => {

    //Dropdown Toggle
    const [isNotificationDropdown, setIsNotificationDropdown] = useState(false);
    const {bellData} = useSelector((state) => ({
        bellData: state.Bell.bellData
    }));

    const [bellCount, setBellCount] = useState(0);

    useEffect(() => {
        setBellCount(bellData.count);
    }, [bellData])

    useEffect(() => {
        BellArarmService.getMessageCount()
            .then((data) => {
                console.debug('NotificationDropdown=>', data);
                if(data.data.data) {
                    setBellCount(data.data.data.count);
                }
            })
    }, []);


    const toggleNotificationDropdown = (e) => {
        e.stopPropagation();
        setIsNotificationDropdown(!isNotificationDropdown);
        if (isNotificationDropdown) {
        } else {
            getMessageList();
        }
    };
    //Tab
    const [activeTab, setActiveTab] = useState('1');
    const toggleTab = (tab) => {
        if (activeTab !== tab) {
            setActiveTab(tab);
        }
    };
    const [messageList, setMessageList] = useState([]);

    const getMessageList = () => {
        BellArarmService.getMessageList()
            .then((data) => {
                setMessageList(data.data.data);
            })
    }

    const readHandler = (item) => {
        if (item.readYn === 'N') {
            BellArarmService.setMessageStatus(item)
                .then((data) => {
                    setBellCount(data.data.data.count);
                    setMessageList(data.data.data.data);
                });
        }
    }

    /**
     * 읽음처리(삭제)
     */
    const readAllHandler =()=>{
        if(messageList.length > 0) {
            BellArarmService.setMessageStatusAll()
                .then((data) => {
                    setBellCount(0);
                    setMessageList([]);
                });
        }
    }

    return (
        <React.Fragment>
            <Dropdown isOpen={isNotificationDropdown} toggle={toggleNotificationDropdown} className="topbar-head-dropdown ms-1 header-item">
                <DropdownToggle type="button" tag="button" className="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle">
                    <i className='bx bx-bell fs-22'></i>
                    <span className="position-absolute topbar-badge fs-10 translate-middle badge rounded-pill bg-danger">{bellCount}<span className="visually-hidden">unread messages</span></span>
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-lg dropdown-menu-end p-0" style={{zIndex:'1100'}}>
                    <div className="dropdown-head bg-transparent rounded-top border-bottom">
                        <div className="p-3">
                            <div className="row align-items-center">
                                <div className="col">
                                    <h6 className="m-0 fw-semibold text-coco-gray-700 tx-18 d-flex align-items-center">
                                        {GetMessage("bell.title")}
                                        <span className="ms-1 tx-14 text-coco-danger-400 fw-medium">(<span className="px-1">{bellCount}</span>)</span>
                                    </h6>
                                </div>
                                <div className="col-auto dropdown-tabs">
                                    <button type="button" className="btn btn-coco-1 text-coco-primary-400 bg-coco-primary-50 btn-sm" onClick={readAllHandler}>
                                        <i className="ri-check-double-line align-middle me-1 "></i>
                                        {GetMessage("bell.readAll")}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <TabContent activeTab={activeTab}>
                        <TabPane tabId="1" className="py-2 ps-2">
                            <SimpleBar style={{maxHeight: "300px"}} className="pe-2">
                                <div className="tab-pane fade show active py-2 ps-2" id="all-noti-tab" role="tabpanel">
                                    <div style={{"maxHeight": "300px", "minHeight": "60px"}} className="pe-2" id="notiItemDiv">
                                        {messageList.map((item) => (
                                            <div className="d-flex align-items-start notification-item d-block dropdown-item position-relative px-2" onClick={() => {
                                                readHandler(item)
                                            }} key={item.msgId}>
                                                <div className="avatar-xs me-2">
                                                    <div className="avatar-title rounded-circle tx-14" style={{"color": item.fontColor, "backgroundColor": item.bgColor}}>{item.regrNm.substring(0, 1)}</div>
                                                </div>
                                                <div className="flex-grow-1 wd-80p">
                                                    <div className="d-flex align-items-center justify-content-between">
                                                        <span className="userNm tx-14 fw-semibold text-coco-gray-700">{item.regrNm}</span>
                                                        <span className="tx-10 text-coco-gray-400"><i className="mdi mdi-clock-outline me-1"></i><span>{item.regDtmText}</span></span>
                                                    </div>
                                                    {/*//TODO 안읽은 애는 bold!!!!!*/}
                                                    <div className="text-coco-gray-400 tx-12 msgCn">{item.msgCn}</div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </SimpleBar>
                        </TabPane>
                    </TabContent>
                </DropdownMenu>
            </Dropdown>
        </React.Fragment>
    );
};

export default NotificationDropdown;