// Copyright (C) 2020-2022 Intel Corporation
//
// SPDX-License-Identifier: MIT
// @ts-nocheck
import config from 'pages/user/label/annotation/image/config';
import {
    CANCEL_ISSUE,
    CHECK_VALIDATION,
    CHECK_VALIDATION_SUCCESS as REVIEW_CHECK_VALIDATION_SUCCESS,
    CHECK_VALIDATION_SUCCESS,
    COMMENT_ISSUE,
    COMMENT_ISSUE_FAILED,
    COMMENT_ISSUE_SUCCESS,
    CREATE_REVIEW_DATA_SUCCESS,
    FINISH_ISSUE_SUCCESS,
    GET_REVIEW_STATES_SUCCESS,
    REMOVE_ISSUE_SUCCESS,
    REOPEN_ISSUE,
    REOPEN_ISSUE_FAILED,
    REOPEN_ISSUE_SUCCESS,
    RESET_REVIEW_STATE_SUCCESS,
    RESOLVE_ISSUE,
    RESOLVE_ISSUE_FAILED,
    RESOLVE_ISSUE_SUCCESS,
    START_ISSUE,
    SUBMIT_ANNOTATIONS_SUCCESS,
    SUBMIT_REVIEW,
    SUBMIT_REVIEW_FAILED,
    SUBMIT_REVIEW_SUCCESS,
    SWITCH_ISSUES_HIDDEN_FLAG,
    SWITCH_RESOLVED_ISSUES_HIDDEN_FLAG,
    GET_ANNOTATIONS_REASONS,
    UPDATE_REVIEW_STATES, GET_ANNOTATIONS_REASONS_SUCCESS,
} from "./actionType";
import {
    GET_MODULE_SUCCESS,
    CHANGE_FRAME,
    DELETE_FRAME_SUCCESS,
    CHANGE_FRAME_SUCCESS, CLOSE_MODULE_SUCCESS,
} from '../label/actionType'
// import { AuthActionTypes } from 'cvat/actions/auth-actions';
import { ReviewState } from '../label';
import {CLOSE_WORKFLOW} from "../dataset/details/actionType";

const INIT_STATE: ReviewState = {
    canvas: {
        badges: {
            visible: false,
            instanceNotEditedCnt: 0,
            classesNotEditedCnt: 0,
        }
    },
    issues: [],
    latestComments: [],
    frameIssues: [], // saved on the server and not saved on the server
    newIssuePosition: null,
    issuesHidden: false,
    issuesResolvedHidden: false,
    fetching: {
        jobId: null,
        issueId: null,
    },
    annotations: {
        reasons: [],
        data: {
            instance: null,
            classes: null
        },
        states: []
    },
};

export default function (state: ReviewState = INIT_STATE, action: any): ReviewState {
    switch (action.type) {
        // issue 가져오는 로직
        // case GET_MODULE_SUCCESS: {
        //     const {
        //         issues,
        //         frameData: { number: frame },
        //     } = action.payload;
        //     const frameIssues = issues.filter((issue: any): boolean => issue.frame === frame);
        //
        //     return {
        //         ...state,
        //         issues,
        //         frameIssues,
        //     };
        // }
        case RESET_REVIEW_STATE_SUCCESS: {
            const {reviewData} = action.payload;
            return {
                ...state,
                annotations: {
                    ...state.annotations,
                    data: reviewData ?? INIT_STATE.annotations.data
                }
            }
        }
        case GET_REVIEW_STATES_SUCCESS:{
            return {
                ...state,
                annotations:{
                    ...state.annotations,
                    data: action.payload
                }
            }
        }
        case GET_ANNOTATIONS_REASONS_SUCCESS: {
            return {
                ...state,
                annotations: {
                    ...state.annotations,
                    reasons: action.payload
                }
            }
        }
        case UPDATE_REVIEW_STATES: {
            const review = action.payload;
            // const {instance, classes} = action.payload;
            // const state.annotations.data
            return {
                ...state,
                annotations: {
                    ...state.annotations,
                    // data: { instance, classes }
                    data: review
                }
            }
        }
        case CREATE_REVIEW_DATA_SUCCESS: {
            return {
                ...state,
                annotations:{
                    ...state.annotations,
                    data: action.payload
                }
            }
        }
        case CHANGE_FRAME: {
            return {
                ...state,
                newIssuePosition: null,
            };
        }
        case DELETE_FRAME_SUCCESS: {
            return {
                ...state,
                newIssuePosition: null,
            };
        }
        case SUBMIT_REVIEW: {
            const { jobId } = action.payload;
            return {
                ...state,
                fetching: {
                    ...state.fetching,
                    jobId,
                },
            };
        }
        case CHECK_VALIDATION_SUCCESS: {
            const { errorVisible, instanceNotEditedCnt, classesNotEditedCnt } = action.payload;
            return {
                ...state,
                canvas: {
                    ...state.canvas,
                    badges: {
                        ...state.canvas.badges,
                        visible: errorVisible ?? state.canvas.badges.visible,
                        instanceNotEditedCnt,
                        classesNotEditedCnt,
                    }
                }
            }
        }
        case SUBMIT_REVIEW_SUCCESS: {
            return {
                ...state,
                fetching: {
                    ...state.fetching,
                    jobId: null,
                },
            };
        }
        case SUBMIT_REVIEW_FAILED: {
            return {
                ...state,
                fetching: {
                    ...state.fetching,
                    jobId: null,
                },
            };
        }
        case CHANGE_FRAME_SUCCESS: {
            const { number: frame } = action.payload;
            return {
                ...state,
                frameIssues: state.issues.filter((issue: any): boolean => issue.frame === frame),
            };
        }
        // issue
        case START_ISSUE: {
            const { position } = action.payload;
            return {
                ...state,
                newIssuePosition: position,
            };
        }
        case FINISH_ISSUE_SUCCESS: {
            const { frame, issue } = action.payload;
            const issues = [...state.issues, issue];
            const frameIssues = issues.filter((_issue: any): boolean => _issue.frame === frame);

            return {
                ...state,
                latestComments: state.latestComments.includes(issue.comments[0].message) ?
                    state.latestComments :
                    Array.from(
                        new Set(
                            [...state.latestComments, issue.comments[0].message].filter(
                                (message: string): boolean => ![
                                    config.QUICK_ISSUE_INCORRECT_POSITION_TEXT,
                                    config.QUICK_ISSUE_INCORRECT_ATTRIBUTE_TEXT,
                                ].includes(message),
                            ),
                        ),
                    ).slice(-config.LATEST_COMMENTS_SHOWN_QUICK_ISSUE),
                frameIssues,
                issues,
                newIssuePosition: null,
            };
        }
        case CANCEL_ISSUE: {
            return {
                ...state,
                newIssuePosition: null,
            };
        }
        case COMMENT_ISSUE:
        case RESOLVE_ISSUE:
        case REOPEN_ISSUE: {
            const { issueId } = action.payload;
            return {
                ...state,
                fetching: {
                    ...state.fetching,
                    issueId,
                },
            };
        }
        case COMMENT_ISSUE_FAILED:
        case RESOLVE_ISSUE_FAILED:
        case REOPEN_ISSUE_FAILED: {
            return {
                ...state,
                fetching: {
                    ...state.fetching,
                    issueId: null,
                },
            };
        }
        case RESOLVE_ISSUE_SUCCESS:
        case REOPEN_ISSUE_SUCCESS:
        case COMMENT_ISSUE_SUCCESS: {
            const { issues, frameIssues } = state;

            return {
                ...state,
                issues: [...issues],
                frameIssues: [...frameIssues],
                fetching: {
                    ...state.fetching,
                    issueId: null,
                },
            };
        }
        case SWITCH_ISSUES_HIDDEN_FLAG: {
            const { hidden } = action.payload;
            return {
                ...state,
                issuesHidden: hidden,
            };
        }
        case SWITCH_RESOLVED_ISSUES_HIDDEN_FLAG: {
            const { hidden } = action.payload;
            return {
                ...state,
                issuesResolvedHidden: hidden,
            };
        }
        case REMOVE_ISSUE_SUCCESS: {
            const { issueId, frame } = action.payload;
            const issues = state.issues.filter((issue: any) => issue.id !== issueId);
            const frameIssues = issues.filter((issue: any): boolean => issue.frame === frame);
            return {
                ...state,
                issues,
                frameIssues,
            };
        }
        // case CLOSE_JOB:
        // case AuthActionTypes.LOGOUT_SUCCESS: {
        //     return { ...defaultState };
        // }
        case CLOSE_WORKFLOW:
        case CLOSE_MODULE_SUCCESS: {
            return {...INIT_STATE};
        }
        default:
            return state;
    }

    return state;
}
