import {GET_REVIEW_STATE_SUCCESS} from "./actionType";

const INIT_STATE = {

};

const ClsfcSmryReview = (state=INIT_STATE, action) => {
	switch (action.type){
		default:
			return state;
	}
}
export default ClsfcSmryReview;
