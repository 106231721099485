import React, {useEffect, useRef, useState} from 'react'
import SockJS from "sockjs-client";
import {Button} from "reactstrap";

function socketTest() {
    const stompClient = useRef(null);

    console.log('socketTest start');
    useEffect(() => {
        let socket = new SockJS('/ws');
        let stompClient = Stomp.over(socket);
        stompClient.connect({}, onConnected, onError);

        function onConnected() {
            let subscribe = '/ws/response/bellAlarm/response/9';
            stompClient.subscribe(subscribe, (message) => {
                console.log('subscribe', message)
            });
        }

        function onError() {
        }
    }, []);


    const sendMessage = () => {
        stompClient.current.send("/hello", {}, JSON.stringify({'a': 'b'}));
    }

    return (
        <>
            <div className={"container"}>
                <Button onClick={sendMessage}>send</Button>
            </div>
        </>
    );
};

export default socketTest;