// label Type
export const INIT_MANAGE_WORKFLOW_LABEL_MODULE_CONFIG                       = "INIT_MANAGE_WORKFLOW_LABEL_MODULE_CONFIG";
export const RESET_MANAGE_WORKFLOW_LABEL_MODULE_CONFIG                       = "RESET_MANAGE_WORKFLOW_LABEL_MODULE_CONFIG";
export const SET_MANAGE_WORKFLOW_LABEL_BASE_CONFIG                       = "SET_MANAGE_WORKFLOW_LABEL_BASE_CONFIG";
export const SET_MANAGE_WORKFLOW_LABEL_SHAPE_CONFIG                       = "SET_MANAGE_WORKFLOW_LABEL_SHAPE_CONFIG";
export const SET_MANAGE_WORKFLOW_LABEL_WORKER_CONFIG                       = "SET_MANAGE_WORKFLOW_LABEL_WORKER_CONFIG";
export const SET_MANAGE_WORKFLOW_LABEL_BIND_CONFIG                       = "SET_MANAGE_WORKFLOW_LABEL_BIND_CONFIG";
export const SET_MANAGE_WORKFLOW_LABEL_ORDER_CONFIG                       = "SET_MANAGE_WORKFLOW_LABEL_ORDER_CONFIG";
export const SET_MANAGE_WORKFLOW_LABEL_POINT_CONFIG                       = "SET_MANAGE_WORKFLOW_LABEL_POINT_CONFIG";
export const SET_MANAGE_WORKFLOW_LABEL_DATA_CONFIG                       = "SET_MANAGE_WORKFLOW_LABEL_DATA_CONFIG";
export const SET_MANAGE_WORKFLOW_LABEL_TEST_CONFIG                       = "SET_MANAGE_WORKFLOW_LABEL_TEST_CONFIG";
export const SET_MANAGE_WORKFLOW_LABEL_CLASS_ITEMS              = "SET_MANAGE_WORKFLOW_LABEL_CLASS_ITEMS";
export const ADD_MANAGE_WORKFLOW_LABEL_CLASS_ITEM            = "ADD_MANAGE_WORKFLOW_LABEL_CLASS_ITEM";
export const UPDATE_MANAGE_WORKFLOW_LABEL_CLASS_ITEM            = "UPDATE_MANAGE_WORKFLOW_LABEL_CLASS_ITEM";
export const DELETE_MANAGE_WORKFLOW_LABEL_CLASS_ITEM            = "DELETE_MANAGE_WORKFLOW_LABEL_CLASS_ITEM";
export const SET_MANAGE_WORKFLOW_LABEL_ATTR_ITEMS                = "SET_MANAGE_WORKFLOW_LABEL_ATTR_ITEMS";
export const ADD_MANAGE_WORKFLOW_LABEL_ATTR_ITEM                = "ADD_MANAGE_WORKFLOW_LABEL_ATTR_ITEM";
export const UPDATE_MANAGE_WORKFLOW_LABEL_ATTR_ITEM             = "UPDATE_MANAGE_WORKFLOW_LABEL_ATTR_ITEM";
export const UPDATE_MANAGE_WORKFLOW_LABEL_EDITOR_CN_BY_ID = "UPDATE_MANAGE_WORKFLOW_LABEL_EDITOR_CN_BY_ID";
export const DELETE_MANAGE_WORKFLOW_LABEL_ATTR_ITEM             = "DELETE_MANAGE_WORKFLOW_LABEL_ATTR_ITEM";
export const SET_SELECTED_MANAGE_WORKFLOW_LABEL_CLASS_ID        = "SET_SELECTED_MANAGE_WORKFLOW_LABEL_CLASS_ID";
export const SET_MANAGE_WORKFLOW_LABEL_ACTIVE_DATA = "SET_MANAGE_WORKFLOW_LABEL_ACTIVE_DATA";
export const SET_MANAGE_WORKFLOW_LABEL_INACTIVE_DATA = "SET_MANAGE_WORKFLOW_LABEL_INACTIVE_DATA";
export const SET_MANAGE_WORKFLOW_LABEL_VIEW_TYPE = "SET_MANAGE_WORKFLOW_LABEL_VIEW_TYPE";
export const SET_MANAGE_WORKFLOW_LABEL_SELECTED_ROW_ID = "SET_MANAGE_WORKFLOW_LABEL_SELECTED_ROW_ID";
export const SET_MANAGE_WORKFLOW_LABEL_SETTING_ROW_ID = "SET_MANAGE_WORKFLOW_LABEL_SETTING_ROW_ID";
export const SET_MANAGE_WORKFLOW_LABEL_SETTING_VIEW_TYPE = "SET_MANAGE_WORKFLOW_LABEL_SETTING_VIEW_TYPE";
export const SET_MANAGE_WORKFLOW_LABEL_DATA_ERROR = "SET_MANAGE_WORKFLOW_LABEL_DATA_ERROR";
export const SET_MANAGE_WORKFLOW_LABEL_FILES = "SET_MANAGE_WORKFLOW_LABEL_FILES";
export const SET_MANAGE_WORKFLOW_LABEL_FILES_RESET = "SET_MANAGE_WORKFLOW_LABEL_FILES_RESET";
export const SWITCH_MANAGE_WORKFLOW_LABEL_REGIST_DATA_REFRESH_FLAG = "SWITCH_MANAGE_WORKFLOW_LABEL_REGIST_DATA_REFRESH_FLAG";
