const defaultSelectStyle = {
  option: (provided, state) => ({
    ...provided,
    color: '#465a64',
    fontWeight: state.isSelected ? '500' : '400',
    fontSize: '14px',
    backgroundColor: state.isDisabled
        ? null
        : state.isSelected
            ? null
            : state.isFocused
                ? '#f3f6f9'
                : null,
    padding: '8px 14px',
    '&:hover': {
      backgroundColor: '#f3f6f9'
    },
    ':active': {
      backgroundColor: '#f3f6f9'
    },
  }),
  group:(provided, state)=>{
    return {
      padding: state.data.label?'0 0 8px 0':'0px',
    }
  },
  groupHeading:(provided, state)=>{
    return {
      padding: state.data.label?'0px 14px':'0px'
    }
  },
  indicatorSeparator: ()=>({
    border: 0,
  }),
  indicatorsContainer: () => ({
    color: '#78909c',
    display:'flex'
  }),
  indicatorContainer: () => ({
    padding:'0'
  }),
  menu: (provided, state) => ({
    ...provided,
    margin: 0,
  }),
  menuPortal: (provided, state) => ({
    ...provided,
    zIndex: 999999
  }),
  menuList:(provided, state)=>({
    ...provided,
    padding: '0',
    "::-webkit-scrollbar": {
      width: "8px",
      height: "0px",
    },
    "::-webkit-scrollbar-track": {
      background: "transparent"
    },
    "::-webkit-scrollbar-thumb": {
      background: "var(--coco-gray-100)",
      opacity: "1!important",
      borderRadius:"5px"
    },
    "::-webkit-scrollbar-thumb:hover": {
      background: "var(--coco-gray-100)"
    }
  }),
  control: (base, state) => ({
    // none of react-select's styles are passed to <Control />
    ...base,
    backgroundColor: state.isDisabled
        ? '#f3f6f9': '#ffffff',
    border: '1px solid #b0bec5',
    // This line disable the blue border
    boxShadow: state.isFocused ? 0 : 0,
    /*padding: '2.7px 2px'*/
    padding: '0'
  }),
  placeholder:(styles)=> ({
    ...styles,
    color: '#78909c'
  }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = 'opacity 300ms';
    return { ...provided, opacity, transition };
  },
  multiValue: (styles, state) => {
    return {
      ...styles,
      backgroundColor: state.isDisabled? '#b0bec5':'#0bad8f',
      padding:'0px 6px 0px 0px',
      borderRadius:'4px'
    };
  },
  multiValueLabel: (styles, state) => ({
    ...styles,
    color: 'white',
    borderRight:state.isDisabled? '1px solid white': '1px solid #7ed0bd',
    borderRadius: '0px',
    paddingRight:'6px'
  }),
  multiValueRemove: (styles) => ({
    ...styles,
    color: 'white',
    paddingRight: '0',
    // borderLeft:'1px solid white',
    ':hover': {
      fontWeight: '800',
    },
  }),
  valueContainer:(styles)=>({
    ...styles,
    padding: '0px 0px 0px 12px'
  })
}
const noBorderSelectStyle = {
  option: (provided, state) => ({
    ...provided,
    color: '#465a64',
    fontWeight: state.isSelected ? '500' : '400',
    fontSize: '14px',
    backgroundColor: state.isDisabled
        ? null
        : state.isSelected
            ? null
            : state.isFocused
                ? '#f3f6f9'
                : null,
    padding: '4px 10px',
    '&:hover': {
      backgroundColor: '#f3f6f9'
    },
    ':active': {
      backgroundColor: '#f3f6f9'
    },
  }),
  indicatorSeparator: ()=>({
    border: 0,
  }),
  indicatorsContainer: () => ({
    color: '#78909c',
    display:'flex'
  }),
  indicatorContainer: () => ({
    padding:'0'
  }),
  menu: (provided, state) => ({
    ...provided,
    margin: 0,
  }),
  control: (base, state) => ({
    // none of react-select's styles are passed to <Control />
    ...base,
    border: 0,
    // This line disable the blue border
    boxShadow: state.isFocused ? 0 : 0,
    padding: '2.7px 2px'
  }),
  placeholder:(styles)=> ({
    ...styles,
    color: '#78909c'
  }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = 'opacity 300ms';

    return { ...provided, opacity, transition };
  },
  multiValue: (styles) => {
    return {
      ...styles,
      backgroundColor: '#0bad8f',
      padding:'0px 6px 0px 0px',
      borderRadius:'4px'
    };
  },
  multiValueLabel: (styles) => ({
    ...styles,
    color: 'white',
    borderRight:'1px solid #7ed0bd',
    borderRadius: '0px',
    paddingRight:'6px'
  }),
  multiValueRemove: (styles) => ({
    ...styles,
    color: 'white',
    paddingRight: '0',
    // borderLeft:'1px solid white',
    ':hover': {
      fontWeight: '800',
    },
  })
}

const noBorderBackgroundSelectStyle = {
  option: (provided, state) => ({
    ...provided,
    color: '#465a64',
    fontWeight: state.isSelected ? '500' : '400',
    fontSize: '14px',
    backgroundColor: state.isDisabled
        ? null
        : state.isSelected
            ? null
            : state.isFocused
                ? '#f3f6f9'
                : null,
    padding: '4px 10px',
    '&:hover': {
      backgroundColor: '#f3f6f9'
    },
    ':active': {
      backgroundColor: '#f3f6f9'
    },
  }),
  indicatorSeparator: () => ({
    border: 0,
  }),
  indicatorsContainer: () => ({
    color: '#78909c',
    padding: '0px!important'
  }),
  indicatorContainer: () => ({
    padding: '0px!important'
  }),
  dropdownIndicator: (provided, state)=>({
    ...provided,
    padding: '0px!important'
  }),
  menu: (provided, state) => ({
    ...provided,
    width: '130px',
    margin: 0,
  }),
  menuList:(provided, state)=>({
    ...provided,
    "::-webkit-scrollbar": {
      width: "8px",
      height: "0px",
    },
    "::-webkit-scrollbar-track": {
      background: "transparent"
    },
    "::-webkit-scrollbar-thumb": {
      background: "var(--coco-gray-100)",
      opacity: "1!important",
      borderRadius:"5px"
    },
    "::-webkit-scrollbar-thumb:hover": {
      background: "var(--coco-gray-100)"
    }
  }),
  menuPortal: (provided, state) => ({
    ...provided,
    zIndex: 999999
  }),
  control: (base, state) => ({
    // none of react-select's styles are passed to <Control />
    ...base,
    border: 0,
    backgroundColor:'transparent',
    // This line disable the blue border
    boxShadow: state.isFocused ? 0 : 0,
    padding: '2.7px 2px'
  }),
  placeholder: (styles) => ({
    ...styles,
    color: '#78909c'
  }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = 'opacity 300ms';

    return {...provided, opacity, transition};
  },
  multiValue: (styles) => {
    return {
      ...styles,
      backgroundColor: '#0bad8f',
      padding: '0px 6px 0px 0px',
      borderRadius: '4px'
    };
  },
  multiValueLabel: (styles) => ({
    ...styles,
    color: 'white',
    borderRight: '1px solid #7ed0bd',
    borderRadius: '0px',
    paddingRight: '6px'
  }),
  multiValueRemove: (styles) => ({
    ...styles,
    color: 'white',
    paddingRight: '0',
    // borderLeft:'1px solid white',
    ':hover': {
      fontWeight: '800',
    },
  }),
  valueContainer:(styles)=>({
    ...styles,
    padding: '0px 8px 0px 0px'
  })
};


const noBorderBackgroundTextSelectStyle = {
  option: (provided, state) => ({
    ...provided,
    color: '#465a64',
    fontWeight: state.isSelected ? '500' : '400',
    fontSize: '14px',
    backgroundColor: state.isDisabled
        ? null
        : state.isSelected
            ? null
            : state.isFocused
                ? '#f3f6f9'
                : null,
    padding: '4px 10px',
    '&:hover': {
      backgroundColor: '#f3f6f9'
    },
    ':active': {
      backgroundColor: '#f3f6f9'
    },
  }),
  indicatorSeparator: () => ({
    border: 0,
  }),
  indicatorsContainer: () => ({
    color: '#78909c',
    padding: '0px!important'
  }),
  indicatorContainer: () => ({
    padding: '0px!important'
  }),
  dropdownIndicator: (provided, state)=>({
    ...provided,
    padding: '0px!important'
  }),
  menu: (provided, state) => ({
    ...provided,
    width: '120px',
    margin: 0,
  }),
  menuPortal: (provided, state) => ({
    ...provided,
    zIndex: 999999
  }),
  control: (base, state) => ({
    // none of react-select's styles are passed to <Control />
    ...base,
    border: 0,
    backgroundColor:'transparent',
    // This line disable the blue border
    boxShadow: state.isFocused ? 0 : 0,
    padding: '2.7px 2px'
  }),
  placeholder: (styles) => ({
    ...styles,
    color: '#78909c'
  }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = 'opacity 300ms';

    return {...provided, opacity, transition};
  },
  multiValue: (styles) => {
    return {
      ...styles,
      backgroundColor: '#0bad8f',
      padding: '0px 6px 0px 0px',
      borderRadius: '4px'
    };
  },
  multiValueLabel: (styles) => ({
    ...styles,
    color: 'white',
    borderRight: '1px solid #7ed0bd',
    borderRadius: '0px',
    paddingRight: '6px'
  }),
  multiValueRemove: (styles) => ({
    ...styles,
    color: 'white',
    paddingRight: '0',
    // borderLeft:'1px solid white',
    ':hover': {
      fontWeight: '800',
    },
  }),
  valueContainer:(styles)=>({
    ...styles,
    padding: '0'
  })
};

const multiSingleSelectStyle = {
  option: (provided, state) => ({
    ...provided,
    color: '#465a64',
    fontWeight: state.isSelected ? '500' : '400',
    fontSize: '14px',
    backgroundColor: state.isDisabled
        ? null
        : state.isSelected
            ? null
            : state.isFocused
                ? '#f3f6f9'
                : null,
    padding: '4px 10px',
    '&:hover': {
      backgroundColor: '#f3f6f9'
    },
    ':active': {
      backgroundColor: '#f3f6f9'
    },
  }),
  indicatorSeparator: ()=>({
    border: 0,
  }),
  clearIndicator: ()=>({
    display: 'none'
  }),
  indicatorsContainer: () => ({
    color: '#78909c',
    display:'flex'
  }),
  indicatorContainer: () => ({
    padding:'0'
  }),
  menu: (provided, state) => ({
    ...provided,
    margin: 0,
  }),
  menuPortal: (provided, state) => ({
    ...provided,
    zIndex: 999999
  }),
  control: (base, state) => ({
    // none of react-select's styles are passed to <Control />
    ...base,
    backgroundColor: state.isDisabled
        ? '#f3f6f9': null,
    border: '1px solid #b0bec5',
    // This line disable the blue border
    boxShadow: state.isFocused ? 0 : 0,
    padding: '2.7px 2px'
  }),
  placeholder:(styles)=> ({
    ...styles,
    color: '#78909c'
  }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = 'opacity 300ms';

    return { ...provided, opacity, transition };
  },
  multiValue: (styles, state) => {
    return {
      ...styles,
      backgroundColor: state.isDisabled? '#b0bec5':'#0bad8f',
      padding:'0px 6px 0px 0px',
      borderRadius:'4px'
    };
  },
  multiValueLabel: (styles, state) => ({
    ...styles,
    color: 'white',
    borderRight:state.isDisabled? '1px solid white': '1px solid #7ed0bd',
    borderRadius: '0px',
    paddingRight:'6px'
  }),
  multiValueRemove: (styles) => ({
    ...styles,
    color: 'white',
    paddingRight: '0',
    // borderLeft:'1px solid white',
    ':hover': {
      fontWeight: '800',
    },
  })
}
const groupStyles = {
  // display: 'flex',
  color: '#465a64',
  fontSize: '14px',
  lineHeight: '18px',
  fontWeight: '500',
  alignItems: 'center',
  borderBottom: '1px solid #CFD8DC',
  padding: '12px 0px 10px 0px'
  // justifyContent: 'space-between',
};

const groupBadgeStyles = {
  backgroundColor: '#EBECF0',
  borderRadius: '2em',
  color: '#172B4D',
  display: 'inline-block',
  fontSize: 12,
  fontWeight: 'normal',
  lineHeight: '1',
  minWidth: 1,
  padding: '0.16666666666667em 0.5em',
  textAlign: 'center',
};

const formatGroupLabel = (data) => (
    data.label?
          <div style={groupStyles}>
            <span>{data.label}({data.options.length})</span>
            {/*<span style={groupBadgeStyles}>({data.options.length})</span>*/}
          </div>:<></>
);

const formatGroupLabelNoCount = (data) => (
    data.label?
        <div style={groupStyles}>
          <span>{data.label}</span>
          {/*<span style={groupBadgeStyles}>({data.options.length})</span>*/}
        </div>:<></>
);

export {
  defaultSelectStyle,
  noBorderSelectStyle,
  noBorderBackgroundSelectStyle,
  noBorderBackgroundTextSelectStyle,
  multiSingleSelectStyle,
  groupStyles,
  groupBadgeStyles,
  formatGroupLabel,
  formatGroupLabelNoCount
};
