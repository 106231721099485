import {BoardService} from "../../apis/api/BoardService";

const customUploadAdapter = (loader, uuid) => { // (2)
    return {
        upload(){
            return new Promise((resolve, reject) => {
                const data = new FormData();
                loader.file.then( (file) => {
                    // 필수
                    data.append("contentId", uuid);
                    data.append("file", file);

                    BoardService.uploadContentImage(data, {contentType : 'multipart/form-data'})
                        .then((res) => {
                            resolve({
                                default : res.data.data
                            });
                        })
                        .catch((err)=>reject(err));
                })
            })
        }
    }
}

export default customUploadAdapter;