import React from 'react';
import styled from 'styled-components';
import Spinner from './loading-gif.gif';
import {BounceLoader} from "react-spinners";

export default () => {
    return (
        <Background>
            <BounceLoader
                    color={"#36d7b7"}
            />
        </Background>
    );
};

export const Background = styled.div`
  position: absolute;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background: #FFFFFF;
  opacity: 0.8;
  z-index: 999999;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const LoadingText = styled.div`
  font: 1rem 'Noto Sans KR';
  text-align: center;
`;
