// @ts-nocheck
import React from 'react';
import ReactDOM from 'react-dom';
import Menu from 'antd/lib/menu';
import { MenuInfo } from 'rc-menu/lib/interface';
import Draggable, {DraggableData, DraggableEvent} from 'react-draggable';
import ObjectItemElementComponent from 'pages/user/label/annotation/image/components/standard-workspace/objects-side-bar/object-item-element';
import ObjectItemContainer from 'pages/user/label/annotation/image/containers/standard-workspace/objects-side-bar/object-item';
import { ShapeType, Workspace } from 'store/label';
import { rotatePoint } from 'pages/user/label/annotation/image/utils/math';
import config from 'pages/user/label/annotation/image/config';
import {ObjectPopoverContainer} from "../../../../containers/standard-workspace/object-popover/object-popover";
import {CANVAS_CONTEXT_MENU, OBJECT_POPOVER_HEADER} from "../../../classNameConsts";

interface Props {
    readonly: boolean;
    workspace: Workspace;
    contextMenuParentID: number | null;
    contextMenuClientID: number | null;
    objectStates: any[];
    visible: boolean;
    left: number;
    top: number;
    posX: number;
    posY: number;
    latestComments: string[];
    onStartIssue(position: number[]): void;
    openIssue(position: number[], message: string): void;
    onUpdateContextMenuPosition(x: number, y: number): void;
    onCloseCanvasContextMenu(e: MouseEvent): void;
}

interface ReviewContextMenuProps {
    top: number;
    left: number;
    latestComments: string[];
    onClick: (param: MenuInfo) => void;
}

enum ReviewContextMenuKeys {
    OPEN_ISSUE = 'open_issue',
    QUICK_ISSUE_POSITION = 'quick_issue_position',
    QUICK_ISSUE_ATTRIBUTE = 'quick_issue_attribute',
    QUICK_ISSUE_FROM_LATEST = 'quick_issue_from_latest',
}

function ReviewContextMenu({
    top, left, latestComments, onClick,
}: ReviewContextMenuProps): JSX.Element {
    return (
        <Menu onClick={onClick} selectable={false} className={CANVAS_CONTEXT_MENU} style={{ top, left }}>
            <Menu.Item className='cvat-context-menu-item' key={ReviewContextMenuKeys.OPEN_ISSUE}>
                Open an issue ...
            </Menu.Item>
            <Menu.Item className='cvat-context-menu-item' key={ReviewContextMenuKeys.QUICK_ISSUE_POSITION}>
                Quick issue: incorrect position
            </Menu.Item>
            <Menu.Item className='cvat-context-menu-item' key={ReviewContextMenuKeys.QUICK_ISSUE_ATTRIBUTE}>
                Quick issue: incorrect attribute
            </Menu.Item>
            {latestComments.length ? (
                <Menu.SubMenu
                    title='Quick issue ...'
                    className='cvat-context-menu-item'
                    key={ReviewContextMenuKeys.QUICK_ISSUE_FROM_LATEST}
                >
                    {latestComments.map(
                        (comment: string, id: number): JSX.Element => (
                            <Menu.Item
                                className='cvat-context-menu-item cvat-quick-issue-from-latest-item'
                                key={`${id}`}
                            >
                                {comment}
                            </Menu.Item>
                        ),
                    )}
                </Menu.SubMenu>
            ) : null}
        </Menu>
    );
}

export default function CanvasContextMenu(props: Props): JSX.Element | null {
    const {
        contextMenuClientID,
        contextMenuParentID,
        objectStates,
        visible,
        left,
        top,
        posX,
        posY,
        readonly,
        workspace,
        latestComments,
        onStartIssue,
        openIssue,
        onUpdateContextMenuPosition,
        onCloseCanvasContextMenu
    } = props;

    if (!visible || contextMenuClientID === null) {
        return null;
    }

    let activeDrags = false;
    const onStart = () => {
        activeDrags = !activeDrags;
    };
    const onStop = (e: DraggableEvent, data: DraggableData) => {
        onUpdateContextMenuPosition(data.lastX, data.lastY);
        activeDrags = !activeDrags;
    }

    const dragHandlers = {
        onStart: onStart,
        onStop: onStop,
        position: {x: posX, y: posY},
        handle: "."+OBJECT_POPOVER_HEADER
    };

    if (workspace !== Workspace.STANDARD) {
        ReactDOM.createPortal(
            <Draggable
                {...dragHandlers}
            >
                <div className={CANVAS_CONTEXT_MENU} style={{ top, left }}>
                    <ObjectPopoverContainer
                        readonly={readonly}
                        key={contextMenuClientID}
                        clientID={contextMenuClientID}
                        objectStates={objectStates}
                        onCloseCanvasContextMenu={onCloseCanvasContextMenu}
                    />
                </div>
            </Draggable>,
            window.document.body,
        );
        // return ReactDOM.createPortal(
        //     <ReviewContextMenu
        //         key={contextMenuClientID}
        //         top={top}
        //         left={left}
        //         latestComments={latestComments}
        //         onClick={(param: MenuInfo) => {
        //             const state = objectStates.find((_state: any): boolean => _state.clientID === contextMenuClientID);
        //             if (state) {
        //                 let { points } = state;
        //                 if ([ShapeType.ELLIPSE, ShapeType.RECTANGLE].includes(state.shapeType)) {
        //                     const [cx, cy] = state.shapeType === 'ellipse' ? state.points : [
        //                         (state.points[0] + state.points[2]) / 2,
        //                         (state.points[1] + state.points[3]) / 2,
        //                     ];
        //                     const [rx, ry] = [state.points[2] - cx, cy - state.points[3]];
        //                     points = state.shapeType === 'ellipse' ? [
        //                         state.points[0] - rx,
        //                         state.points[1] - ry,
        //                         state.points[0] + rx,
        //                         state.points[1] + ry,
        //                     ] : state.points;
        //
        //                     points = [
        //                         [points[0], points[1]],
        //                         [points[2], points[1]],
        //                         [points[2], points[3]],
        //                         [points[0], points[3]],
        //                     ].map(([x, y]: number[]) => rotatePoint(x, y, state.rotation, cx, cy)).flat();
        //                 } else if (state.shapeType === ShapeType.MASK) {
        //                     points = state.points.slice(-4);
        //                     points = [
        //                         points[0], points[1],
        //                         points[2], points[1],
        //                         points[2], points[3],
        //                         points[0], points[3],
        //                     ];
        //                 }
        //
        //                 if (param.key === ReviewContextMenuKeys.OPEN_ISSUE) {
        //                     onStartIssue(points);
        //                 } else if (param.key === ReviewContextMenuKeys.QUICK_ISSUE_POSITION) {
        //                     openIssue(points, config.QUICK_ISSUE_INCORRECT_POSITION_TEXT);
        //                 } else if (param.key === ReviewContextMenuKeys.QUICK_ISSUE_ATTRIBUTE) {
        //                     openIssue(points, config.QUICK_ISSUE_INCORRECT_ATTRIBUTE_TEXT);
        //                 } else if (
        //                     param.keyPath.length === 2 &&
        //                     param.keyPath[1] === ReviewContextMenuKeys.QUICK_ISSUE_FROM_LATEST
        //                 ) {
        //                     openIssue(points, latestComments[+param.keyPath[0]]);
        //                 }
        //             }
        //         }}
        //     />,
        //     window.document.body,
        // );
    }

    if (Number.isInteger(contextMenuParentID)) {
        return ReactDOM.createPortal(
            <div className={CANVAS_CONTEXT_MENU} style={{ top, left }}>
                <ObjectPopoverContainer
                    readonly={readonly}
                    key={contextMenuClientID}
                    clientID={contextMenuClientID}
                    parentID={contextMenuParentID as number}
                />
            </div>,
            window.document.body,
        );
    }

    return ReactDOM.createPortal(
        <Draggable
            {...dragHandlers}
        >
            <div className={CANVAS_CONTEXT_MENU} style={{ top, left }}>
                <ObjectPopoverContainer
                    readonly={readonly}
                    key={contextMenuClientID}
                    clientID={contextMenuClientID}
                    objectStates={objectStates}
                    onCloseCanvasContextMenu={onCloseCanvasContextMenu}
                />
            </div>
        </Draggable>,
        window.document.body,
    );
    // return ReactDOM.createPortal(
    //     <div className={CANVAS_CONTEXT_MENU} style={{ top, left }}>
    //         <ObjectItemContainer
    //             readonly={readonly}
    //             key={contextMenuClientID}
    //             clientID={contextMenuClientID}
    //             objectStates={objectStates}
    //         />
    //     </div>,
    //     window.document.body,
    // );
}
