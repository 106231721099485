import React from "react";
import Shortcuts from "./shortcuts-popover";

interface Props {
    keyMap: any;
    normalizedKeyMap: any;
    visible: boolean;
    toggle(): void;
}
function ShortcutsButton(props: Props): JSX.Element {
    const {
        keyMap,
        normalizedKeyMap,
        visible,
        toggle } = props;
    return (
        <div className="ms-1 header-item d-flex">
            <button type="button" className="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle"
                    id={"shortcuts"} onClick={toggle}>
                <i className="ri-keyboard-box-line fs-24 text-white" ></i>
            </button>
            <Shortcuts target={"shortcuts"} visible={visible} toggle={toggle}
                       keyMap={keyMap}
                       normalizedKeyMap={normalizedKeyMap}
            />
        </div>
    )
}

export default React.memo(ShortcutsButton);
