import React from 'react';
import {Card, CardBody, Nav, NavItem, NavLink, Progress, TabContent, TabPane} from "reactstrap";
import {WorkTipView} from "./WorkTipView";
import classnames from "classnames";
import SimpleBar from "simplebar-react";
import styled from "styled-components";
import {TaskContents as ClassificationTaskContents} from "./classification/TaskContents";
import {TaskContents as SummaryTaskContents} from "./summary/TaskContents";
import {AttributeType} from "../../../annotation/image/work/core/enums";
import {MD_TY_CD} from "../../../annotation/image/const";

const NavContainer = styled.div`
  .progress-nav .nav {
    justify-content: start;
    gap: 16px;
  }

  .progress {
    //  length * 9% => Total Progress Bar Width
    width: ${props => props.length === 1 ? '0' : props.length <= 4 ? props.length * 7 : props.length * 9}%;
    background-color: #0BAD8F;
  }

  .nav-item-custom {
    border-radius: 50rem;
    border: 2px solid #0BAD8F;
    
    .nav-link {
      background-color: white;
      color: #0BAD8F;
    }
  }
  
  .nav-item-error{
    border-radius: 50rem;
    border: 2px solid var(--coco-danger-400);
    z-index: 1;
    .nav-link {
      background-color: var(--coco-default-white) !important;
      color: var(--coco-danger-400) !important;
    }
    .active{
      background-color: var(--coco-danger-400) !important;
      color: var(--coco-default-white) !important;
    }
  }
  
  .nav-item-unmodified {
    border-radius: 50rem;
    border: 2px solid #FFB900;
    z-index: 1;
    .nav-link {
      background-color: var(--coco-default-white) !important;
      color: #FFB900 !important;
    }
    .active{
      background-color: #FFB900 !important;
      color: var(--coco-default-white) !important;
    }
  }
`

export const StepWizardView = ({ labels, activeTab, passedSteps, progressbarvalue, length,
                                   toggleTab, moduleType, status, shapes, reviews, clsfcSmryForm, modifiedList }) => {

    function getCustomNavItemClass(item) {
        if (moduleType === MD_TY_CD.REVIEW && reviews.length && reviews[item.id].reject) {
            if (clsfcSmryForm.getValues(`review.classes.${item.order}.q1.content.0.touch`) == false // 검수 진행을 하지 않았거나
                || clsfcSmryForm?.formState?.errors?.review && clsfcSmryForm?.formState?.errors?.review.classes[item.id]) { // 검수에 대한 오류가 있으면(제출 이후)
                return "nav-item-error";
            }
            return "nav-item-unmodified";
        }

        if (reviews.length && reviews[item.id].reject && modifiedList && modifiedList[item.id]){
            return "nav-item-unmodified";
        }

        if( moduleType === MD_TY_CD.LABEL && reviews.length && reviews[item.id].reject
            || (clsfcSmryForm?.formState?.errors?.shapes && clsfcSmryForm?.formState?.errors?.shapes[item.id] || clsfcSmryForm?.formState?.errors?.review && clsfcSmryForm?.formState?.errors?.review.classes[item.id])
        ){
            return "nav-item-error"
        }

        return "nav-item-custom"
    }

    return (
        <Card className="mb-0 shadow-none">
            {/*<CardHeader>STAGE</CardHeader>*/}
            <CardBody className="form-steps p-0">
                <div className={"pd-t-24 pd-x-24"}>
                    <div
                        style={{ color : "#0BAD8F", fontSize : "12px"}}
                        className="fw-400 mb-1"
                    >
                        STAGE
                    </div>
                    <NavContainer length={length}>
                        <div className="progress-nav mb-4">
                            {/* Progress Value 설정 */}
                            <Progress style={{height: "3px"}}/>

                            <Nav className="nav-pills progress-bar-tab custom-nav"
                                 role="tablist"
                            >
                                {/* 가져온 데이터 수 별로 세팅 */}
                                {
                                    labels.map((item, index) => (
                                        <NavItem className={getCustomNavItemClass(item)}
                                                 key={`${item.id}-nav`} >
                                            <NavLink
                                                to="#"
                                                id={`pills-tab-${item.id}`}
                                                className={classnames(
                                                    {
                                                        active: activeTab === index + 1,
                                                        done: passedSteps.includes(index + 1),
                                                    },
                                                    "rounded-pill"
                                                )}
                                                onClick={() => {
                                                    toggleTab(index + 1, (index / (length - 1)) * 100);
                                                }}
                                                tag="button"
                                                type="button"
                                            >
                                                {index < 9 ? `0${index + 1}` : index + 1 }
                                            </NavLink>
                                        </NavItem>
                                    ))
                                }
                            </Nav>
                        </div>
                    </NavContainer>
                </div>

                {/* 컨텐츠 영역 */}
                <TabContent activeTab={activeTab}>
                    {
                        labels.map((label, index) => {
                            const inputType = label.attributes[0].inputType;

                            return (
                                <TabPane key={label.id} tabId={index + 1}>
                                    <SimpleBar
                                        style={{maxHeight: "calc(100vh - 250px)", width: "100%", alignSelf: "center"}}
                                    >
                                        <div className={"pd-x-24 pd-y-24"} id={"stageContent".concat(label.id)}>
                                        {/* 작업 팁 */}
                                        {
                                            label.tip && <WorkTipView className="mb-24" workTip={label.tip}/>
                                        }

                                        {/* 컨텐츠 내용 렌더링 */}
                                        {/*라벨링 작업 컴포넌트*/}
                                        {inputType === AttributeType.TEXT
                                            && <SummaryTaskContents
                                                    item={label} moduleType={moduleType} status={status}
                                                    shapes={shapes} reviews={reviews} clsfcSmryForm={clsfcSmryForm}
													modifiedList={modifiedList}
                                            />}
                                        {[AttributeType.SELECT, AttributeType.CHECKBOX].includes(inputType)
                                            && <ClassificationTaskContents label={label}  moduleType={moduleType} status={status}
																		   shapes={shapes} reviews={reviews} clsfcSmryForm={clsfcSmryForm}
																		   modifiedList={modifiedList}
                                            />
                                        }
                                        </div>
                                    </SimpleBar>
                                </TabPane>
                            )
                        })
                    }
                </TabContent>
            </CardBody>
        </Card>
    );
}
