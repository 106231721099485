import React, {useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import {
    Card,
    Col,
} from "reactstrap";
import {ContextMenuTrigger} from 'react-contextmenu';
import thumbnail from "../../../../assets/images/thumbnail.png";
import {GetMessage, GetMessageIntl} from "../../../../util/message";
import {useDispatch} from "react-redux";
import {manage_project_setList} from "../../../../store/manage/project/action";
import {ManagerWorkflowService} from "../../../../apis/api/ManagerWorkflowService";
import {manage_project_workflow_setList} from "../../../../store/manage/project/workflow/action";
import {useIntl} from "react-intl";

function WorkflowCardList({list, clickEvent, handleEvent, contextMenuId}) {

    const dispatch = useDispatch();
    const intl = useIntl();
    const prjctId = sessionStorage.getItem("prjctId");
    const regDtmLabel = GetMessage("ui.U00123");
    const inquireDtmLabel = GetMessage("ui.U00124");
    const dupMsg = GetMessage("validation.duplication");

    const isWorkflowNmDuplication = async (event, wrkflowId, wrkflowNm) => {
        if (wrkflowNm === '') {
            dispatch(manage_project_workflow_setList(
                list.map((workflowItem) => workflowItem.wrkflowId === wrkflowId ? {...workflowItem, 'editMode': false} : workflowItem)
            ));
            return;
        }
        await ManagerWorkflowService.isWorkflowNmDuplication({
            'prjctId': prjctId,
            'wrkflowId': wrkflowId,
            'wrkflowNm': wrkflowNm,
        }).then((result) => {
            if (!result.data.data) {
                handleEvent(wrkflowId, wrkflowNm);
            } else {
                event.target.classList.add('is-invalid');
                event.target.parentElement.children[1].classList.add('invalid-tooltip');
                event.target.parentElement.children[1].innerText = dupMsg;
            }
        });

    }

    const handleKeyPress = (event, wrkflowId) => {
        if (event.key === 'Enter') {
            isWorkflowNmDuplication(event, wrkflowId, event.target.value.trim());
        } else {
            event.target.classList.remove('is-invalid');
            event.target.parentElement.children[1].classList.remove('invalid-tooltip');
            event.target.parentElement.children[1].innerText = '';
        }
    }

    const editCount = list.filter(data => data.editMode).length;
    const noImageHandler = (e) => {
        e.target.src = thumbnail;
    }

    return (
        list.length > 0 ?
            list.map((data, index) => {
                const {wrkflowId, wrkflowNm, wrkflowDc, regDtmText, inqireDtmText, editMode, thumnailUrl} = data;
                return (
                    <Col key={"wrkflow_".concat(wrkflowId)}>
                        {contextMenuId != "" && editCount === 0 ?
                            <ContextMenuTrigger id={contextMenuId} wrkflowId={wrkflowId}
                                                collect={p => {
                                                    console.log("workFlow contextMenu collect", p);
                                                    return p;
                                                }}
                            >
                                <Card className="card-shadow" onClick={(e) => editMode ? null : clickEvent(wrkflowId)}>
                                    <img className="card-img-top ht-188" src={thumnailUrl} alt={wrkflowDc} onError={noImageHandler}/>
                                    <div className="d-flex pd-y-12 align-items-center">
                                        <div className="flex-grow-1 mx-3 text-overflow">
                                            <h5 className="tx-16 text-coco-gray-700 fw-semibold mb-10 text-overflow">{wrkflowNm}</h5>
                                            <p className="text-coco-gray-400 fw-medium tx-14 mb-0">
                                                {regDtmLabel}: {regDtmText} <span className={"ml-13".concat(inqireDtmText ? '' : ' d-none')}>({inqireDtmText} {inquireDtmLabel})</span>
                                            </p>
                                        </div>
                                    </div>
                                </Card>
                            </ContextMenuTrigger>
                            :
                            <Card className="card-shadow" onClick={(e) => editCount > 0 ? null : clickEvent(wrkflowId)}>
                                <img className="card-img-top ht-188" src={thumnailUrl} alt={wrkflowDc} onError={noImageHandler}/>
                                <div className="d-flex pd-y-12 align-items-center">
                                    <div className="flex-grow-1 mx-3 text-overflow">
                                        {editMode ?
                                            <div className="mb-10">
                                                <input type="text" defaultValue={wrkflowNm} className="form-control editable tx-16 fw-semibold pd-0 lh-19"
                                                       maxLength={50}
                                                       onBlur={(e) => isWorkflowNmDuplication(e, wrkflowId, e.target.value.trim())} onKeyUp={(e) => handleKeyPress(e, wrkflowId)}
                                                       autoFocus/>
                                                <p className="validation-error-message"></p>
                                            </div>
                                            :
                                            <h5 className="tx-16 text-coco-gray-700 fw-semibold mb-10 lh-19 text-overflow">{wrkflowNm}</h5>
                                        }
                                        <p className="text-coco-gray-400 fw-medium tx-14 mb-0">
                                            {regDtmLabel}: {regDtmText} <span className={"ml-13".concat(inqireDtmText ? '' : ' d-none')}>({inqireDtmText} {inquireDtmLabel})</span>
                                        </p>
                                    </div>
                                </div>
                            </Card>
                        }
                    </Col>
                )
            })
            :
            <Col>{GetMessageIntl(intl,"common.noData")}</Col>
    );
}

export default WorkflowCardList;