const newLine = "NEWLINE";
const space = "SPACE";
const dash = "DASH";

export const formatText = (text: string) => {
  let formattedText = text;
  formattedText = formattedText.replace("\t", " "); // Make tab line break behaviors similar to space behaviors
  return formattedText;
};

export const getTextLineBreaks = (
  text: string,
  textContainerWidth: number,
  charWidth: number,
  fontSize: number
) => {

  const lineBreaks = []; // 각 라인의 마지막 문자 위치 배열

  let lineCharIndex = 0; // 해당 라인의 현재 문자열 위치
  let lastBreakingCharIndex = 0;
  let lastBreakingChar: string;
  let startIndex = 0;

  for (let i = 0; i < text.length; i++) {
    const char = text[i];

    if(lineBreaks.length > 0 ){
      startIndex = lineBreaks[lineBreaks.length-1];
    }

    const shouldGoToNewLine =
      char == "\n" ||
      (getTextWidth(text.substring(lineBreaks.length>0?startIndex+1 : startIndex, i+1), fontSize) > textContainerWidth);

    // console.log('getTextWidth > textContainerWidth', getTextWidth(text.substring(lineBreaks.length>0?startIndex+1 : startIndex, i+1), fontSize) > textContainerWidth);
    // console.log('bf i lineBreaks.length char lineCharWidth', i,  lineBreaks.length, char, getTextWidth(text.substring(lineBreaks.length>0?startIndex+1 : startIndex, i+1), fontSize), textContainerWidth);
    // console.log('text startIndex endIndex', text.substring(lineBreaks.length>0?startIndex+1 : startIndex, i+1), lineBreaks.length>0?startIndex+1:startIndex, i+1)

    if (shouldGoToNewLine) {

      //console.log('lineCharWidth, textContainerWidth char', char, lineCharWidth, textContainerWidth);
      lineCharIndex = 0;
      if (char == "\n") {
        lineBreaks.push(i);
      } else {
        lineBreaks.push(i - 1);
      }
    }

    // console.log('af i lineBreaks.length char lineCharWidth', i,  lineBreaks.length, char, getTextWidth(text.substring(lineBreaks.length>0?startIndex+1 : startIndex, i+1), fontSize),  textContainerWidth);

    if (char == "\n") {
      lastBreakingChar = newLine;
      lastBreakingCharIndex = lineCharIndex;
    } else if (char == " ") {
      lastBreakingChar = space;
      lastBreakingCharIndex = lineCharIndex;
    } else if (char == "-") {
      lastBreakingChar = dash;
      lastBreakingCharIndex = lineCharIndex;
    }

    if (!shouldGoToNewLine) {
      lineCharIndex += 1;
    }

    // End of text
    if (i === text.length - 1 && lineBreaks[lineBreaks.length] !== i) {
      lineBreaks.push(i);
    }
  }

  // console.log(lineBreaks);

  return lineBreaks;
};

export const getTextWidth = (charText:string, fontSize:number) => {

  let inputText = charText;
  let font = fontSize+"px Pretendard";

  let canvas = document.createElement("canvas");
  let context = canvas.getContext("2d");
  context.font = font;
  let width = context.measureText(inputText).width;
  let formattedWidth = Math.ceil(width)

  //console.log('getTextWidth',formattedWidth);
  return formattedWidth;
}

export const getSelectionCharOffsets = () => {
  const selection: Selection | any = window.getSelection();

  const text = selection.toString();
  const range = selection.getRangeAt(0);

  const startTrim = text.length - text.trimStart().length; // 시작 trim 갯수만큼 뒤로
  const endTrim = text.length - text.trimEnd().length; // 종료 trim 갯수만큼 앞으로

  const baseOffset = Number(
    selection.baseNode.parentElement.getAttribute("data-offset")
  );
  const basePosition = selection.baseOffset;
  const extentOffset = Number(
    selection.extentNode.parentElement.getAttribute("data-offset")
  );
  const extentPosition = selection.extentOffset;

  let from = Number(baseOffset) + Number(basePosition);
  let to = Number(extentOffset) + Number(extentPosition);

  [from, to] = [Math.min(from, to)+startTrim, Math.max(from, to)-endTrim];

  return [from, to];
};
