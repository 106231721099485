import React, {useEffect, useState} from 'react'
import Select from 'react-select'
import {CommonService} from "../../apis/api/CmmnService";
import {callApi} from "../../apis/utils/AxiosUtil";

function CmmnCodeTest() {

    const [option1, setOption1] = useState({});
    const [option2, setOption2] = useState({});
    const [option3, setOption3] = useState({});
    const [option4, setOption4] = useState({});
    const [option5, setOption5] = useState({});
    const [option6, setOption6] = useState({});
    const [option7, setOption7] = useState({});
    const [option8, setOption8] = useState({});
    const [option9, setOption9] = useState({});
    const [option10, setOption10] = useState({});
    const [option11, setOption11] = useState({});
    const [option12, setOption12] = useState({});
    const [option13, setOption13] = useState({});
    const [option14, setOption14] = useState({});
    const [option15, setOption15] = useState({});


    useEffect(() => {
        CommonService.getStorageVendorList()
            .then(result => {
                setOption1(result.data.data.map(item => {
                    return {value: item.cmmnDetailCd, label: item.cmmnDetailCdNm}
                }));
            });

        CommonService.getFaqCnTyList()
            .then(result => {
                setOption2(result.data.data.map(item => {
                    return {value: item.cmmnDetailCd, label: item.cmmnDetailCdNm}
                }));
            });

        CommonService.getAskCnTyList()
            .then(result => {
                setOption3(result.data.data.map(item => {
                    return {value: item.cmmnDetailCd, label: item.cmmnDetailCdNm}
                }));
            });

        CommonService.getDataDomainCdList()
            .then(result => {
                setOption4(result.data.data.map(item => {
                    return {value: item.cmmnDetailCd, label: item.cmmnDetailCdNm}
                }));
            });

        CommonService.getJobCodeList()
            .then(result => {
                setOption5(result.data.data.map(item => {
                    return {value: item.cmmnDetailCd, label: item.cmmnDetailCdNm}
                }));
            });

        CommonService.getReviewRejectReasonList()
            .then(result => {
                setOption6(result.data.data.map(item => {
                    return {value: item.cmmnDetailCd, label: item.cmmnDetailCdNm}
                }));
            });


        CommonService.getWorkerJoinCtrlTyCd()
            .then(result => {
                setOption7(result.data.data.map(item => {
                    return {value: item.cmmnDetailCd, label: item.cmmnDetailCdNm}
                }));
            });


        CommonService.getWorkerJoinCondition()
            .then(result => {
                setOption8(result.data.data.map(item => {
                    return {value: item.cmmnDetailCd, label: item.cmmnDetailCdNm}
                }));
            });


        CommonService.getWorkerJoinConditionProfile()
            .then(result => {
                setOption9(result.data.data.map(item => {
                    return {value: item.cmmnDetailCd, label: item.cmmnDetailCdNm}
                }));
            });

        CommonService.getGenderCd()
            .then(result => {
                setOption10(result.data.data.map(item => {
                    return {value: item.cmmnDetailCd, label: item.cmmnDetailCdNm}
                }));
            });

        CommonService.getEducationCd()
            .then(result => {
                setOption11(result.data.data.map(item => {
                    return {value: item.cmmnDetailCd, label: item.cmmnDetailCdNm}
                }));
            });


        CommonService.getSkipRsnCdForImageClsfcSmry()
            .then(result => {
                setOption12(result.data.data.map(item => {
                    return {value: item.cmmnDetailCd, label: item.cmmnDetailCdNm}
                }));
            });


        CommonService.getSkipRsnCdForAudioClsfcSmry()
            .then(result => {
                setOption13(result.data.data.map(item => {
                    return {value: item.cmmnDetailCd, label: item.cmmnDetailCdNm}
                }));
            });


        CommonService.getSkipRsnCdForVideoClsfcSmry()
            .then(result => {
                setOption14(result.data.data.map(item => {
                    return {value: item.cmmnDetailCd, label: item.cmmnDetailCdNm}
                }));
            });


        CommonService.getSkipRsnCdForTextClsfcSmry()
            .then(result => {
                setOption15(result.data.data.map(item => {
                    return {value: item.cmmnDetailCd, label: item.cmmnDetailCdNm}
                }));
            });
    }, []);


    const MyComponent1 = () => (
        <Select options={option1}/>
    )
    const MyComponent2= () => (
        <Select options={option2}/>
    )
    const MyComponent3= () => (
        <Select options={option3}/>
    )
    const MyComponent4= () => (
        <Select options={option4}/>
    )
    const MyComponent5= () => (
        <Select options={option5}/>
    )
    const MyComponent6= () => (
        <Select options={option6}/>
    )
    const MyComponent7= () => (
        <Select options={option7}/>
    )
    const MyComponent8= () => (
        <Select options={option8}/>
    )
    const MyComponent9= () => (
        <Select options={option9}/>
    )
    const MyComponent10= () => (
        <Select options={option10}/>
    )
    const MyComponent11= () => (
        <Select options={option11}/>
    )

    const MyComponent12= () => (
        <Select options={option12}/>
    )

    const MyComponent13= () => (
        <Select options={option13}/>
    )

    const MyComponent14= () => (
        <Select options={option14}/>
    )

    const MyComponent15= () => (
        <Select options={option15}/>
    )

    return (
        <>
            <MyComponent1/>
            <MyComponent2/>
            <MyComponent3/>
            <MyComponent4/>
            <MyComponent5/>
            <MyComponent6/>
            <MyComponent7/>
            <MyComponent8/>
            <MyComponent9/>
            <MyComponent10/>
            <MyComponent11/>
            <MyComponent12/>
            <MyComponent13/>
            <MyComponent14/>
            <MyComponent15/>
        </>
    )
}


export default CmmnCodeTest;