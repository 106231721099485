// Copyright (C) 2019-2022 Intel Corporation
// Copyright (C) 2022-2023 CVAT.ai Corporation
//
// SPDX-License-Identifier: MIT
// @ts-nocheck

import { Storage } from './storage';
import serverProxy from './server-proxy';
import Collection from './annotations-collection';
import AnnotationsSaver from './annotations-saver';
import AnnotationsHistory from './annotations-history';
import { checkObjectType } from './common';
import Project from './project';
import { Task, Module } from './session';
import { ScriptingError, DataError, ArgumentError } from './exceptions';
import {getAnnotationResult} from "../service";
import {getDeletedFrames} from "./frames";
import {createRawCollection} from "./annotations-ml";

const moduleCache = new WeakMap();
const taskCache = new WeakMap();

function getCache(sessionType) {
    if (sessionType === 'task') {
        return taskCache;
    }

    if (sessionType === 'module') {
        return moduleCache;
    }

    throw new ScriptingError(`Unknown session type was received ${sessionType}`);
}

async function getAnnotationsFromServer(session) {
    const sessionType = session instanceof Task ? 'task' : 'module';
    const cache = getCache(sessionType);

    const datasetId = session.frame.datasetId;
    const fileSeq = session.frame.fileSeq;
    if (!cache.has(session) || (cache.get(session).saver.datasetId != datasetId || cache.get(session).saver.fileSeq != fileSeq)) {
        // const rawAnnotations = await serverProxy.annotations.getAnnotations(sessionType, session.id);
        let rawAnnotations = {
            tags: [],
            shapes: [],
            tracks: [],
            version: 0,
        };
        const moduleId = session.id;
        const result = await getAnnotationResult(session, {datasetId, fileSeq});
        const data = result.data;
        if (data && data.hasOwnProperty('json') && data.json && JSON.parse(data.json).hasOwnProperty('shapes')) {
            rawAnnotations = JSON.parse(data.json);
            rawAnnotations.version = 0;
        }

        // Get meta information about frames
        const startFrame = sessionType === 'module' ? session.startFrame : 0;
        const stopFrame = sessionType === 'module' ? session.stopFrame : session.size - 1;
        const frameMeta = {};
        for (let i = startFrame; i <= stopFrame; i++) {
            frameMeta[i] = await session.frames.get(i, datasetId, fileSeq);
        }
        frameMeta.deleted_frames = await getDeletedFrames(sessionType, session.targetModuleId); // id
        const history = new AnnotationsHistory();
        const collection = new Collection({ // todo labelType에 따라서 Collection을 다르게 바꾸자
            labels: session.labels || session.task.labels,
            history,
            stopFrame,
            frameMeta: frameMeta,
            dimension: session.dimension,
        });

        collection.import(rawAnnotations);
        const saver = new AnnotationsSaver(rawAnnotations.version, collection, session);
        cache.set(session, { collection, saver, history });
    }
}

export async function updateViewID(session){
    const sessionType = session instanceof Task ? 'task' : 'module';
    const cache = getCache(sessionType);

    const collection = cache.get(session).collection;
    const states = collection.updateShapeCount(0);
    return states;
}
export async function updateFrameMeta(session){
    const sessionType = session instanceof Task ? 'task' : 'module';
    const cache = getCache(sessionType);
    const datasetId = session.frame.datasetId;
    const fileSeq = session.frame.fileSeq;

    const startFrame = sessionType === 'module' ? session.startFrame : 0;
    const stopFrame = sessionType === 'module' ? session.stopFrame : session.size - 1;
    const frameMeta = {};
    for (let i = startFrame; i <= stopFrame; i++) {
        frameMeta[i] = await session.frames.get(i, datasetId, fileSeq);
    }
    const collection = cache.get(session).collection;
    collection.updateFrameMeta({frameMeta});

    return true;
}

export async function clearCache(session) {
    const sessionType = session instanceof Task ? 'task' : 'module';
    const cache = getCache(sessionType);

    if (cache.has(session)) {
        cache.delete(session);
    }
}

export async function getAnnotations(session, frame, datasetId, fileSeq, allTracks, filters) {
    const sessionType = session instanceof Task ? 'task' : 'module';
    const cache = getCache(sessionType);

    if (cache.has(session) && (cache.get(session).saver.datasetId === datasetId && cache.get(session).saver.fileSeq === fileSeq)) {
        return cache.get(session).collection.get(frame, allTracks, filters);
    }

    await getAnnotationsFromServer(session);
    return cache.get(session).collection.get(frame, allTracks, filters);
}

export async function saveAnnotations(session, onUpdate) {
    const sessionType = session instanceof Task ? 'task' : 'module';
    const cache = getCache(sessionType);

    if (cache.has(session)) {
        return await cache.get(session).saver.save(onUpdate);
    }

    // If a collection wasn't uploaded, than it wasn't changed, finally we shouldn't save it
}

export function searchAnnotations(session, filters, frameFrom, frameTo) {
    const sessionType = session instanceof Task ? 'task' : 'module';
    const cache = getCache(sessionType);

    if (cache.has(session)) {
        return cache.get(session).collection.search(filters, frameFrom, frameTo);
    }

    // throw new DataError(
    //     'Collection has not been initialized yet. Call annotations.get() or annotations.clear(true) before',
    // );
}

export function searchEmptyFrame(session, frameFrom, frameTo) {
    const sessionType = session instanceof Task ? 'task' : 'module';
    const cache = getCache(sessionType);

    if (cache.has(session)) {
        return cache.get(session).collection.searchEmpty(frameFrom, frameTo);
    }

    throw new DataError(
        'Collection has not been initialized yet. Call annotations.get() or annotations.clear(true) before',
    );
}

export function mergeAnnotations(session, objectStates) {
    const sessionType = session instanceof Task ? 'task' : 'module';
    const cache = getCache(sessionType);

    if (cache.has(session)) {
        return cache.get(session).collection.merge(objectStates);
    }

    throw new DataError(
        'Collection has not been initialized yet. Call annotations.get() or annotations.clear(true) before',
    );
}

export function splitAnnotations(session, objectState, frame) {
    const sessionType = session instanceof Task ? 'task' : 'module';
    const cache = getCache(sessionType);

    if (cache.has(session)) {
        return cache.get(session).collection.split(objectState, frame);
    }

    throw new DataError(
        'Collection has not been initialized yet. Call annotations.get() or annotations.clear(true) before',
    );
}

export function groupAnnotations(session, objectStates, reset) {
    const sessionType = session instanceof Task ? 'task' : 'module';
    const cache = getCache(sessionType);

    if (cache.has(session)) {
        return cache.get(session).collection.group(objectStates, reset);
    }

    throw new DataError(
        'Collection has not been initialized yet. Call annotations.get() or annotations.clear(true) before',
    );
}

export function hasUnsavedChanges(session) {
    const sessionType = session instanceof Task ? 'task' : 'module';
    const cache = getCache(sessionType);

    if (cache.has(session)) {
        return cache.get(session).saver.hasUnsavedChanges();
    }

    return false;
}

export async function clearAnnotations(session, reload, startFrame, endframe, reviewData, delTrackKeyframesOnly) {
    checkObjectType('reload', reload, 'boolean', null);
    const sessionType = session instanceof Task ? 'task' : 'module';
    const cache = getCache(sessionType);

    if (cache.has(session)) {
        const collection = cache.get(session).collection;
        collection.clearAnnotations(startFrame, endframe, reviewData, delTrackKeyframesOnly);
    }

    if (reload) {
        cache.delete(session);
        await getAnnotationsFromServer(session);
    }
}

export function annotationsStatistics(session, review) {
    const sessionType = session instanceof Task ? 'task' : 'module';
    const cache = getCache(sessionType);

    if (cache.has(session)) {
        return cache.get(session).collection.statistics(review);
    }

    throw new DataError(
        'Collection has not been initialized yet. Call annotations.get() or annotations.clear(true) before',
    );
}

export function putAnnotations(session, objectStates) {
    const sessionType = session instanceof Task ? 'task' : 'module';
    const cache = getCache(sessionType);

    if (cache.has(session)) {
        return cache.get(session).collection.put(objectStates);
    }

    throw new DataError(
        'Collection has not been initialized yet. Call annotations.get() or annotations.clear(true) before',
    );
}

export function selectObject(session, objectStates, x, y) {
    const sessionType = session instanceof Task ? 'task' : 'module';
    const cache = getCache(sessionType);

    if (cache.has(session)) {
        return cache.get(session).collection.select(objectStates, x, y);
    }

    // throw new DataError(
    //     'Collection has not been initialized yet. Call annotations.get() or annotations.clear(true) before',
    // );
}

export function importFromModel(session, label, data) {
    const sessionType = session instanceof Task ? 'task' : 'module';
    const cache = getCache(sessionType);

    const rawCollection = createRawCollection(session, label, data)

    if (cache.has(session)) {
        return cache.get(session).collection.addCollection(rawCollection);
    }

    throw new DataError(
        'Collection has not been initialized yet. Call annotations.get() or annotations.clear(true) before',
    );
}

export function importCollection(session, data) {
    const sessionType = session instanceof Task ? 'task' : 'module';
    const cache = getCache(sessionType);

    if (cache.has(session)) {
        return cache.get(session).collection.addCollection(data);
    }

    throw new DataError(
        'Collection has not been initialized yet. Call annotations.get() or annotations.clear(true) before',
    );
}

export function exportCollection(session) {
    const sessionType = session instanceof Task ? 'task' : 'module';
    const cache = getCache(sessionType);

    if (cache.has(session)) {
        return cache.get(session).collection.export();
    }

    throw new DataError(
        'Collection has not been initialized yet. Call annotations.get() or annotations.clear(true) before',
    );
}

export async function exportDataset(
    instance,
    format: string,
    saveImages: boolean,
    useDefaultSettings: boolean,
    frameStorage: Storage,
    name?: string,
) {
    if (!(instance instanceof Task || instance instanceof Project || instance instanceof module)) {
        throw new ArgumentError('A dataset can only be created from a module, task or project');
    }

    let result = null;
    if (instance instanceof Task) {
        result = await serverProxy.tasks
            .exportDataset(instance.id, format, saveImages, useDefaultSettings, frameStorage, name);
    } else if (instance instanceof module) {
        result = await serverProxy.modules
            .exportDataset(instance.id, format, saveImages, useDefaultSettings, frameStorage, name);
    } else {
        result = await serverProxy.projects
            .exportDataset(instance.id, format, saveImages, useDefaultSettings, frameStorage, name);
    }

    return result;
}

export function importDataset(
    instance: any,
    format: string,
    useDefaultSettings: boolean,
    sourceStorage: Storage,
    file: File | string,
    options: {
        convMaskToPoly?: boolean,
        updateStatusCallback?: (s: string, n: number) => void,
    } = {},
): Promise<void> {
    const updateStatusCallback = options.updateStatusCallback || (() => {});
    const convMaskToPoly = 'convMaskToPoly' in options ? options.convMaskToPoly : true;
    const adjustedOptions = {
        updateStatusCallback,
        convMaskToPoly,
    };

    if (!(instance instanceof Project || instance instanceof Task || instance instanceof module)) {
        throw new ArgumentError('Instance must be a Project || Task || module instance');
    }
    if (!(typeof updateStatusCallback === 'function')) {
        throw new ArgumentError('Callback must be a function');
    }
    if (!(typeof convMaskToPoly === 'boolean')) {
        throw new ArgumentError('Option "convMaskToPoly" must be a boolean');
    }
    const allowedFileExtensions = [
        '.zip', '.xml', '.json',
    ];
    const allowedFileExtensionsList = allowedFileExtensions.join(', ');
    if (typeof file === 'string' && !(allowedFileExtensions.some((ext) => file.toLowerCase().endsWith(ext)))) {
        throw new ArgumentError(
            `File must be file instance with one of the following extensions: ${allowedFileExtensionsList}`,
        );
    }
    const allowedMimeTypes = [
        'application/zip', 'application/x-zip-compressed',
        'application/xml', 'text/xml',
        'application/json',
    ];
    if (file instanceof File && !(allowedMimeTypes.includes(file.type))) {
        throw new ArgumentError(
            `File must be file instance with one of the following extensions: ${allowedFileExtensionsList}`,
        );
    }

    if (instance instanceof Project) {
        return serverProxy.projects
            .importDataset(
                instance.id,
                format,
                useDefaultSettings,
                sourceStorage,
                file,
                adjustedOptions,
            );
    }

    const instanceType = instance instanceof Task ? 'task' : 'module';
    return serverProxy.annotations
        .uploadAnnotations(
            instanceType,
            instance.id,
            format,
            useDefaultSettings,
            sourceStorage,
            file,
            adjustedOptions,
        );
}

export function getHistory(session) {
    const sessionType = session instanceof Task ? 'task' : 'module';
    const cache = getCache(sessionType);

    if (cache.has(session)) {
        return cache.get(session).history;
    }

    throw new DataError(
        'Collection has not been initialized yet. Call annotations.get() or annotations.clear(true) before',
    );
}

export async function undoActions(session, count) {
    const sessionType = session instanceof Task ? 'task' : 'module';
    const cache = getCache(sessionType);

    if (cache.has(session)) {
        return cache.get(session).history.undo(count);
    }

    throw new DataError(
        'Collection has not been initialized yet. Call annotations.get() or annotations.clear(true) before',
    );
}

export async function redoActions(session, count) {
    const sessionType = session instanceof Task ? 'task' : 'module';
    const cache = getCache(sessionType);

    if (cache.has(session)) {
        return cache.get(session).history.redo(count);
    }

    throw new DataError(
        'Collection has not been initialized yet. Call annotations.get() or annotations.clear(true) before',
    );
}

export function freezeHistory(session, frozen) {
    const sessionType = session instanceof Task ? 'task' : 'module';
    const cache = getCache(sessionType);

    if (cache.has(session)) {
        return cache.get(session).history.freeze(frozen);
    }

    throw new DataError(
        'Collection has not been initialized yet. Call annotations.get() or annotations.clear(true) before',
    );
}

export function clearActions(session) {
    const sessionType = session instanceof Task ? 'task' : 'module';
    const cache = getCache(sessionType);

    if (cache.has(session)) {
        return cache.get(session).history.clear();
    }

    throw new DataError(
        'Collection has not been initialized yet. Call annotations.get() or annotations.clear(true) before',
    );
}

export function getActions(session) {
    const sessionType = session instanceof Task ? 'task' : 'module';
    const cache = getCache(sessionType);

    if (cache.has(session)) {
        return cache.get(session).history.get();
    }

    throw new DataError(
        'Collection has not been initialized yet. Call annotations.get() or annotations.clear(true) before',
    );
}
