import TestConfigDiv from "../manager/project/workflow/components/offcanvas/tab/review/TestConfigDiv";
import {GetMessageIntl} from "../../util/message";
import React from "react";
import {Controller, FormProvider, useFieldArray, useForm, useFormContext} from "react-hook-form";
import {Input} from "reactstrap";

const ValidationGroupTest = () => {

    const methodsTest = useForm({
        criteriaMode: "all",
        defaultValues: {}
    });
    const {
        control: controlTest
        , register: registerTest
        , setValue: setValueTest
        , getValues: getValuesTest
        , reset: resetTest
        , resetField: resetFieldTest
        , watch: watchTest
        , formState: {errors: errorsTest}
        , handleSubmit: handleSubmitTest
    } = methodsTest;


    const onSubmitTest = (data) => {
        console.log(data)
    }
    const files = ['a.jpg', 'b.jpg', 'c.jpg', 'd.jpg']

    return (
        <>
            <FormProvider {...methodsTest}>
                <form onSubmit={methodsTest.handleSubmit(onSubmitTest)} onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                        e.preventDefault()
                    }
                }}>
                    {files.map((item, index) => {
                        return (<Test name={item} index={index}/>)
                    })
                    }
                    <div className="offcanvas-footer justify-content-end">
                        <div className="">
                            <button className="btn btn-coco-1 mr-16" type="submit">
                                저장
                            </button>
                            <button type="button" className="btn btn-coco-icon-1">
                                <i className="ri-delete-bin-line"></i>
                            </button>
                        </div>
                    </div>
                </form>
            </FormProvider>
        </>
    )
}


function Test(props) {
    const {
        control: controlTest
        , register: registerTest
        , setValue: setValueTest
        , getValues: getValuesTest
        , reset: resetTest
        , resetField: resetFieldTest
        , watch: watchTest
        , formState: {errors: errorsTest},
    } = useFormContext();

    const { fields, append, prepend, remove, swap, move, insert } = useFieldArray({
        name: "title", // 배열 필드의 이름을 입력
        // keyName: "id", 기본값은 "id" 이고 변경 가능하다
    });


    const {fileName,index} = props;
    return (
        <>
            <div className={`errorsTest.[index].title` ? "validation-error-bottom" : ""}>
                <input
                    type="text"
                    {...registerTest('${fileName}.title', {
                        required : true
                    })}
                />
            </div>
        </>
    )

}


export default ValidationGroupTest;