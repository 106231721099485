import React from 'react';
import styled from "styled-components";

const TooltipContainer = styled.div`
    width: 100%;
    position: relative;
    display: inline-block;

    &:hover .tooltipText {
        visibility: visible;
        opacity: 1;
    }
`;

// Styled Component for Tooltip Text
const TooltipText = styled.span`
      visibility: hidden;
      width: ${props => props.width ? props.width : '146px'};

      background-color: #555;
      color: #fff;
      text-align: left;
      padding: 5px 5px 5px 5px;
      border-radius: 6px;
      position: absolute;
      z-index: 1;
      top: 125%;
      left: 0;
      opacity: 0;
      transition: opacity 0.3s;
      font-size: 12px;
    
      &:after {
        content: "";
        position: absolute;
        bottom: 100%;
        left: 10%;
        margin-left: -5px;
        border-width: 5px;
        border-style: solid;
        border-color: transparent transparent #555 transparent;
      }
`;

function CustomTooltip({children, text, width}) {
    return (
        <TooltipContainer>
            {children}
            <TooltipText className="tooltipText" width={width}>{text}</TooltipText>
        </TooltipContainer>
    );
}

export default CustomTooltip;