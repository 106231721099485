export const SET_CURRENT_PAGE = "manage/worker/SET_CURRENT_PAGE";
export const SET_WORKER_NM = "manage/worker/SET_WORKER_NM";
export const SET_WORK_DTM = "manage/worker/SET_WORK_DTM";
export const SET_SEARCH_PARAM = "manage/worker/SET_SEARCH_PARAM";
export const SET_WRKFLOW_ID = "manage/worker/SET_WRKFLOW_ID";
export const SET_WRKFLOW_OPTION = "manage/worker/SET_WRKFLOW_OPTION";
export const SET_TASK_ID = "manage/worker/SET_TASK_ID";
export const SET_TASK_OPTION = "manage/worker/SET_TASK_OPTION";
export const SET_MOUNT_CNT = "manage/worker/SET_MOUNT_CN";
export const SET_WRKFLOW_WITH_MODULES = "manage/worker/SET_WRKFLOW_WITH_MODULES";
export const SET_CANVAS_DATA_TY = "manage/worker/SET_CANVAS_DATA_TY";