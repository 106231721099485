import {Redirect, Route} from "react-router-dom";

import React, {useEffect} from "react";
import Page404 from "../pages/error/page404";

function PrivateRoute({component: Component, ...rest}) {
    let coconutUserInfoJson = sessionStorage.getItem('coconutUserInfo') ?? '{}'
    if ('{}' === coconutUserInfoJson) {
        window.location.href = "/login";
    }
    const sessionUserInfo = JSON.parse(coconutUserInfoJson)
    let userRole = sessionUserInfo.userRole;
    if (!userRole) {
        userRole = "99";
    }
    return (
        <Route {...rest}
               render={
                   props => {
                       if (!sessionUserInfo) {
                           return <Redirect to="/login"/>
                       }
                       if (userRole.startsWith("00") && !props.location.pathname.startsWith("/admin/")) {
                           return  <Redirect to="/page404"/>
                       } else if (userRole.startsWith("01") && !props.location.pathname.startsWith("/platform/")) {
                           return  <Redirect to="/page404"/>
                       } else if (userRole.startsWith("1") && !props.location.pathname.startsWith("/manager/")) {
                           return  <Redirect to="/page404"/>
                       } else if (userRole.startsWith("2") && (!props.location.pathname.startsWith("/user/")
                                                            && !props.location.pathname.startsWith("/label/"))
                       ) {
                           return  <Redirect to="/page404"/>
                       }
                       return <Component {...props} />;
                   }
               }
        />
    )
}

export default PrivateRoute;
