import React, {useCallback, useRef, useState} from 'react'
import {Button, Col, Row} from "reactstrap";
import {FilePond, registerPlugin} from 'react-filepond'
import 'filepond/dist/filepond.min.css'
import {useDropzone} from "react-dropzone";
import SimpleBar from "simplebar-react";
import CommonUtil from "../../util/CommonUtil";
import {ModuleService} from "../../apis/api/ModuleService";
import TestConfigDiv from "../manager/project/workflow/components/offcanvas/tab/review/TestConfigDiv";

const ModuleTestConfigUpdateTest = () => {
    const [files, setFiles] = useState([])
    const [isClick, setIsClick] = useState(false)
    const [imagePreView, setImagePreView] = useState(null);

    const onDrop = useCallback((acceptedFiles) => {
        const tList = [];

        for (let i = 0; i < acceptedFiles.length; i++) {
            const file = acceptedFiles[i];
            if (files.length > 0) {
                // 기존에 등록되어있던게 있으면
                let hasFile = false;
                for (let i = 0; i < files.length; i++) {
                    if (files[i].name === file.name
                        && files[i].size === file.size
                        && files[i].lastModified === file.lastModified) {
                        // 이름도 같고, 크기도 같고, 마지막 수정일도 같으면 중복으로
                        hasFile = true;
                        console.log('중볽!!')
                        //this.removeFile(file);
                    }
                }
                if (!hasFile) {
                    tList.push(file);
                }
            } else {
                tList.push(file);
            }
        }

        const newAccptedFiles = tList.map(file => {
                console.log(file)
                file.isNew = true;
                return Object.assign(file, {
                    preview: URL.createObjectURL(file)
                })
            }
        )
        setFiles(files.concat(newAccptedFiles));

    }, [files]);

    const removeFile = (file) => () => {
        const newFiles = [...files]
        newFiles.splice(newFiles.indexOf(file), 1)
        setFiles(newFiles);
    }

    const img = {
        display: 'block',
        width: '500px',
    };

    const {
        getRootProps,
        getInputProps,
    } = useDropzone({
        onDrop,
        accept: {'image/*': []}
    });

    const style = {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '20px',
        borderWidth: 2,
        borderRadius: 2,
        borderColor: '#eeeeee',
        borderStyle: 'dashed',
        backgroundColor: '#fafafa',
        color: '#bdbdbd',
        outline: 'none',
        transition: 'border .24s ease-in-out'
    };
    const showPreView = (file) => {
        if (file.isNew) {
            setIsClick(true)
            setImagePreView(file.preview)
        } else {
            setIsClick(true)
            setImagePreView('module/test/MD5c3bc558-3747-4ca9-9154-0d49600dca27/f4d6281b-5cbe-32d3-8aac-84ebb3624183.jpg')
        }
    }

    const go = () => {
        const submitData = new FormData();
        const moduleConfig = {
            "testConfig": {
                "title": "",
                "questionCount": 10,
                "passCount": 8,
                "questionOrderTy": "01",
                "desc": "설명",
                "deleteFiles" : ["module/test/MD5c3bc558-3747-4ca9-9154-0d49600dca27/f0e514d4-4954-3f5a-998b-752d30a83235.jpg"],
                "questions": [
                    {
                        "labels": 1,
                        "attributes": [
                            {
                                "id": 1,
                                "values": [
                                    "",
                                    ""
                                ]
                            }
                        ],
                        "image": {
                            "realFileName": "Dinocore_S03_07_4P_0028 (93).jpg",
                            "url": "module/test/MD5c3bc558-3747-4ca9-9154-0d49600dca27/b50af762-457c-30f4-b1a8-0f7d4a53a001.jpg",
                            "isNew": false
                        },
                        "answer": [
                            "01",
                            "02"
                        ],
                        "comment": "산천어의 줄무늬 개수는 꼬리 줄무늬 개수는 제외합니다. 머리부터 몸통만 개수를 카운트하므로 답은 '3'입니다."
                    },
                    {
                        "labels": 1,
                        "attributes": [
                            {
                                "id": 1,
                                "values": [
                                    "",
                                    ""
                                ]
                            }
                        ],
                        "image": {
                            "realFileName": "Dinocore_S03_07_4P_0028 (94).jpg",
                            "url": "module/test/MD5c3bc558-3747-4ca9-9154-0d49600dca27/df5a2634-715a-3ae9-838a-5d596829ed82.jpg",
                            "isNew": false
                        },
                        "answer": [
                            "01",
                            "02"
                        ],
                        "comment": "산천어의 줄무늬 개수는 꼬리 줄무늬 개수는 제외합니다. 머리부터 몸통만 개수를 카운트하므로 답은 '3'입니다."
                    },
                    {
                        "labels": 1,
                        "attributes": [
                            {
                                "id": 1,
                                "values": [
                                    "",
                                    ""
                                ]
                            }
                        ],
                        "image": {
                            "realFileName": "Dinocore_S03_07_4P_0028 (95).jpg",
                            "url": "module/test/MD5c3bc558-3747-4ca9-9154-0d49600dca27/ef21bb27-4ad6-3882-8b8e-ba85cbf517db.jpg",
                            "isNew": false
                        },
                        "answer": [
                            "01",
                            "02"
                        ],
                        "comment": "산천어의 줄무늬 개수는 꼬리 줄무늬 개수는 제외합니다. 머리부터 몸통만 개수를 카운트하므로 답은 '3'입니다."
                    },
                    {
                        "labels": 1,
                        "attributes": [
                            {
                                "id": 1,
                                "values": [
                                    "",
                                    ""
                                ]
                            }
                        ],
                        "image": {
                            "realFileName": "Dinocore_S03_07_4P_0028 (96).jpg",
                            "url": "module/test/MD5c3bc558-3747-4ca9-9154-0d49600dca27/f4d6281b-5cbe-32d3-8aac-84ebb3624183.jpg",
                            "isNew": false
                        },
                        "answer": [
                            "01",
                            "02"
                        ],
                        "comment": "산천어의 줄무늬 개수는 꼬리 줄무늬 개수는 제외합니다. 머리부터 몸통만 개수를 카운트하므로 답은 '3'입니다."
                    }
                ]
            }
        }

        for (let i = 0; i < files.length; i++) {
            let f = files[i];
            if (f.isNew) {
                // 신규로 추가된 파일 항목만  전송 파일 목록에 담는다.
                submitData.append("testAttachFile[]", files[i])
                moduleConfig.testConfig.questions[i].image.realFileName = f.name;
            }
        }

        submitData.append("moduleId", "MD5c3bc558-3747-4ca9-9154-0d49600dca27");
        submitData.append("moduleTy", "01");
        submitData.append("moduleConfig", JSON.stringify(moduleConfig));

        // let param = {};
        // param.moduleId = "MD5c3bc558-3747-4ca9-9154-0d49600dca27";
        // param.moduleTy = "01";
        // param.moduleConfig = JSON.stringify(moduleConfig);

        ModuleService
            .updateModuleItemWithFile(submitData, {contentType: 'multipart/form-data'})
            .then(result => {
                console.log(result)
            }).catch((error) => {
            }
        );
    }

    const add = () => {
        const oldFiles = [];
        const f = new File([""], "test.jpg")
        f.isNew = false;
        f.imageUrl = "http://asdfas.qwer/tes.jpg"
        oldFiles.push(f)
        setFiles(files.concat(oldFiles));
    }


    return (
        <>
            <Row>
                <Col>
                    <Button onClick={go}>전송</Button>
                    <Button className="m-1" onClick={add}>파일 목록에 추가</Button>
                </Col>
            </Row>

            <Row style={{height: "200px"}}>
                <Col xxl={3}>
                    <Row>
                        <Col xxl={12}>
                            <div {...getRootProps({style})}>
                                <div>
                                    <input name="file" type="file" multiple="multiple" webkitdirectory="true" {...getInputProps()}/>
                                </div>
                                <div className="dz-message needsclick text-coco-gray-100 my-4">
                                    <div className="mb-2">
                                        <i className="display-4 ri-upload-cloud-2-fill"></i>
                                    </div>
                                    <div className="tx-16">
                                        Drop files here or click to upload.
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col xxl={12}>
                            <SimpleBar style={{maxHeight: 250}}>
                                <ul className="list-unstyled mb-0" id="dropzone-preview">
                                    {files.map((file, index) => {
                                        return (
                                            <li className="mt-2" id="dropzone-preview-list" key={index} onClick={() => {
                                                showPreView(file)
                                            }}>
                                                <div className="hstack gap-3 py-2 border-bottom tx-basic" key={file.name}>
                                                    <div className="me-3 d-flex align-items-center">
                                                        <div className="me-3">
                                                        </div>
                                                        <h5 className="fs-14 mb-0" data-dz-name>{file.name}</h5>
                                                        {/*<strong className="error text-danger" data-dz-errormessage>{file.name}</strong>*/}
                                                    </div>
                                                    <div className="ms-auto">
                                                        <p className="mb-0 fs-14" data-dz-size>{file.isNew ? "신규" : "기존"}</p>
                                                    </div>
                                                    <div className="pe-3">
                                                        <div data-dz-remove>
                                                            <i className="ri-close-line tx-18" onClick={removeFile(file)}></i>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>)
                                    })}
                                </ul>
                            </SimpleBar>
                        </Col>
                    </Row>
                </Col>
                <Col xxl={9}>
                    {
                        isClick ?
                            <img
                                style={img}
                                className="img-fluid rounded d-block"
                                src={imagePreView}
                            />
                            :
                            <img
                                className="img-fluid rounded d-block"
                            />
                    }
                </Col>
            </Row>
        </>
    )
}

export default ModuleTestConfigUpdateTest;