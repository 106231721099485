// @ts-nocheck
import React, {useState} from 'react';

import { StatesOrdering } from 'store/label';
import ObjectItemContainer from 'pages/user/label/annotation/image/containers/standard-workspace/objects-side-bar/object-item';
import { ObjectState } from 'pages/user/label/annotation/image/cvat-core-wrapper';
import ObjectItemReviewContainer from "../../../containers/review-workspace/objects-side-bar/object-item";
import {MD_TY_CD} from "../../../const";

interface Props {
    type: MD_TY_CD;
    readonly: boolean;
    statesHidden: boolean;
    statesLocked: boolean;
    statesCollapsedAll: boolean;
    statesOrdering: StatesOrdering;
    sortedStatesID: number[];
    objectStates: any[];
    switchLockAllShortcut: string;
    switchHiddenAllShortcut: string;
    changeStatesOrdering(value: StatesOrdering): void;
    lockAllStates(): void;
    unlockAllStates(): void;
    collapseAllStates(): void;
    expandAllStates(): void;
    hideAllStates(): void;
    showAllStates(): void;
    className: string;
    onClick(e:MouseEvent): void;
}

function ObjectListComponent(props: Props): JSX.Element {
    const {
        readonly,
        statesHidden,
        statesLocked,
        statesCollapsedAll,
        statesOrdering,
        sortedStatesID,
        objectStates,
        firstReviewObjectID,
        switchLockAllShortcut,
        switchHiddenAllShortcut,
        changeStatesOrdering,
        lockAllStates,
        unlockAllStates,
        collapseAllStates,
        expandAllStates,
        hideAllStates,
        showAllStates,
        className,
        type,
        item,
        onUpdateStates
    } = props;

    let latestZOrder: number | null = null;
    return (
        <>
            <div className={className + " label-event-list-container"}>
                {sortedStatesID.map(
                    (id: number, index: number): JSX.Element => {
                        const object = objectStates.find((state: ObjectState) => state.clientID === id);
                        const zOrder = object?.zOrder || latestZOrder;

                        const renderZLayer = latestZOrder !== zOrder && statesOrdering === StatesOrdering.Z_ORDER;
                        if (renderZLayer) {
                            latestZOrder = zOrder;
                        }

                        return (
                            <ObjectItemContainer key={'objectItemContainer'+id}
                                                 containerKey={"objects-list"}
                                                 firstReviewObjectID={firstReviewObjectID}
                                                 readonly={readonly}
                                                 objectStates={objectStates}
                                                 sortedStatesID={sortedStatesID}
                                                 clientID={id}
                                                 viewID={index+1}
                                                 item={item}
                                                 onUpdateStates={onUpdateStates}
                            />
                        );
                    },
                )}
            </div>
        </>
    );
}

export default React.memo(ObjectListComponent);
