import {SET_INIT_SEARCH_PARAM, SET_SEARCH_PARAM} from "./actionType";

const INIT_STATE = {
    searchParam : []
}

const User = (state = INIT_STATE, action) => {
    switch (action.type){
        case SET_SEARCH_PARAM :
            return {
                ...state,
                searchParam: [action.payload]
            }
        case SET_INIT_SEARCH_PARAM :
            console.log(action)
            return {
                ...state,
                searchParam: []
            }
        default:
            return state;
    }
}

export default User;