import React, {useEffect, useRef, useState} from "react";
import {Annotations, AnnotationsByKey, Relations} from "../../../../../../../components/common/text-annotator/types";
import {AccordionItem, Collapse, Input} from "reactstrap";
import {Annotator} from "../../../../../../../components/common/text-annotator/components/Annotator";
import {GetMessage, GetMessageIntl} from "../../../../../../../util/message";
import {GuidePopover} from "../GuidePopover";
import {useIntl} from "react-intl";
import {DashedBar} from "../DashedBar";
import { Accordion } from "react-bootstrap";
import classnames from "classnames";
import {ReviewTitle} from "../ReviewTitle";

interface Props{
    no: number;
    color: string;
    badgeText: string;
    detail : string;
}

export const ReviewRejectView = (props: Props): JSX.Element => {

    return (
        <div className={"summary-review-answer-item tx-14 text-coco-gray-700"}>
            <span className={"ht-18 vertical-center fw-500 wd-5p"}>{props.no}</span>
            <div className={"summary-review-answer-result wd-95p"}>
                <div className={"tagging-sentence mx-wd-100p d-flex align-items-center"}>
                    <div className={"pd-r-12 "}>
                        <div className={"badge badge-coco badge-coco-outline-".concat(props.color)}>{props.badgeText}</div>
                    </div>
                </div>
                <div className={"tagging-detail mn-wd-65p d-flex align-items-center"}>
                    {props.detail}
                </div>
            </div>
        </div>
    )
}