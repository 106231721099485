import React, {useEffect, useMemo, useRef, useState} from 'react';
import {Card, CardBody, CardHeader, Col, Input, Label, Row} from "reactstrap";
import SimpleBar from "simplebar-react";
import {FilePond} from "react-filepond";
import {CKEditor} from "@ckeditor/ckeditor5-react";
import Editor from "ckeditor5-custom-build";
import customUploadAdapter from "../../../../../../../../../components/features/CustomUploadAdapter";
import {useDispatch, useSelector} from "react-redux";
import {
    setManageWorkflowLabelActiveData, setManageWorkflowLabelFiles,
    setManageWorkflowLabelInactiveData, switchManageWorkflowLabelRegistDataRefreshFlag
} from "../../../../../../../../../store/manage/project/workflow/label/action";
import {ModuleService} from "../../../../../../../../../apis/api/ModuleService";
import {useIntl} from "react-intl";
import {GetMessageIntl} from "../../../../../../../../../util/message";
import {alertError, confirm} from "../../../../../../../../../util/AlertUtil";


function LabelRegistDataEditorForm(props) {
    const intl = useIntl();
    const dispatch = useDispatch();
    const {activeData, inactiveData, selectedId, dataConfig, errorData, allFiles, refreshFlag, selectionNode} = useSelector((state) => ({
        activeData: state.ManageWorkflowLabel.activeData,
        inactiveData: state.ManageWorkflowLabel.inactiveData,
        selectedId: state.ManageWorkflowLabel.selectedId,
        dataConfig: state.ManageWorkflowLabel.dataConfig,
        errorData: state.ManageWorkflowLabel.errorData,
        allFiles: state.ManageWorkflowLabel.files,
        refreshFlag: state.ManageWorkflowLabel.refreshFlag,
        selectionNode: state.ManageWorkflowModule.selectionNode,
    }));


    const [title, setTitle] = useState("");
    const [useTarget, setUseTarget] = useState("01");
    const [attachFiles, setAttachFiles] = useState([]);
    const [targetData, setTargetData] = useState({});

    // FilePond
    const [files, setFiles] = useState([]);
    const [cpFiles, setCpFiles] = useState([]);
    const pondRef = useRef();

    // CKEditor
    const ckEditorRef = useRef();
    const [content, setContent] = useState("");
    const contentRef = useRef(content);

    function uploadPlugin (editor){ // (3)
        const findData = dataConfig?.find(item => item.id === selectedId);
        editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
            return customUploadAdapter(loader, findData.subId);
        }
    }

    const updateActiveDataById = (id, newTitle, newContent, newUseTarget) => {
        const updatedData = activeData.map((item) => item.id === id ? {...item, title: newTitle, body: { ...item.body, content: newContent, useTarget: newUseTarget }} : item )
        dispatch(setManageWorkflowLabelActiveData(updatedData))
    };

    const updateInactiveDataById = (id, newTitle, newContent, newUseTarget) => {
        const updatedData = inactiveData.map((item) => item.id === id ? {...item, title: newTitle, body: { ...item.body, content: newContent, useTarget: newUseTarget }} : item )
        dispatch(setManageWorkflowLabelInactiveData(updatedData))
    };

    useEffect(() => {
        const checkDuplicates = async (files) => {
            let updatedFiles = [...files];

            for (let i = updatedFiles.length - 1; i >= 0; i--) {
                const newFile = updatedFiles[i];
                const sameFileIndex = updatedFiles.slice(0, i).findIndex(f => f.file.name === newFile.file.name);

                if (sameFileIndex !== -1) {
                    const replace = await new Promise(resolve => { // 동기처리
                        confirm("workflow.dataConfig.duplicatedFileReplace"
                            , "workflow.dataConfig.duplicatedFileReplaceMsg"
                            , () => { // 기존 파일 대체
                                resolve(true);
                            }
                            , () => { // 기존 파일 유지
                                resolve(false);
                            }
                            ,
                            {
                                filename : newFile.filename
                            }
                            , intl);
                    });

                    if (replace) {
                        updatedFiles = [...updatedFiles.slice(0, sameFileIndex), ...updatedFiles.slice(sameFileIndex + 1)];
                    } else {
                        updatedFiles = [...updatedFiles.slice(0, i), ...updatedFiles.slice(i + 1)];
                    }
                }
            }

            return updatedFiles;
        }

        checkDuplicates(cpFiles).then(updatedFiles => {
            setFiles(updatedFiles);
            dispatch(setManageWorkflowLabelFiles(selectedId, updatedFiles));
        });
    }, [cpFiles]);

    useEffect(()=> {
        const totalData = [...activeData, ...inactiveData];
        const findData = totalData.find((target) => target.id === selectedId);
        if(findData){
            if(findData.useYn === "Y"){
                updateActiveDataById(selectedId, title, content, useTarget)
            }else{
                updateInactiveDataById(selectedId, title, content, useTarget)
            }
        }
    }, [title, content, useTarget, errorData])

    useMemo(()=>{
        const totalData = [...activeData, ...inactiveData];
        const findData = totalData.find((target) => target.id === selectedId);
        setTargetData(findData);
        if(findData){
            setTitle(findData.title);
            setUseTarget(findData.body.useTarget);
            setContent(findData.body.content);

            ModuleService.getManageLabelRegistDataFiles({
                "moduleId": selectionNode.id,
                "dataConfigTargetId": selectedId
            }).then(res => {
                if(res.data.data.length > 0) {
                    if(findData.useYn === "Y"){
                        const updatedData = activeData.map((item) => item.id === selectedId ? {...item, body : { ...item.body, attachFiles : res.data.data}} : item);
                        dispatch(setManageWorkflowLabelActiveData(updatedData))
                    }else{
                        const updatedData = inactiveData.map((item) => item.id === selectedId ? {...item, body : { ...item.body, attachFiles : res.data.data}} : item);
                        dispatch(setManageWorkflowLabelInactiveData(updatedData))
                    }
                }

                if(res.data.data.length > 0){
                    setAttachFiles(res.data.data)
                }else{
                    setAttachFiles(findData.body.attachFiles)
                }
            })
        }

        if(allFiles[selectedId]){
            setCpFiles(allFiles[selectedId])
        }else{
            setCpFiles([])
        }
        return () => {
            setContent("");
        }
    }, [selectedId, refreshFlag])

    const handleRadioChange = (event) => {
        if(event.target.value == '01' || event.target.value =='02'){
            // 가이드 또는 FAQ는 하나씩만 입력 되어야 한다.
            const findData = dataConfig.find((target) => target.id === selectedId);
            let dupType = [];
            if(findData.useYn === "Y"){
                dupType = activeData.filter((data) => data.type == 'editor' && data.body.useTarget == event.target.value);
            }else{
                dupType = inactiveData.filter((data) => data.type == 'editor' && data.body.useTarget == event.target.value);
            }

            if(dupType.length>0){
                alertError(
                    "workflow.dataConfig.dupEditorUseTargetTitle"
                    ,"workflow.dataConfig.dupEditorUseTargetMsg"
                    ,null
                    , {'useTarget': event.target.value == '01'? GetMessageIntl(intl, "workflow.dataConfig.guide"): GetMessageIntl(intl, "workflow.dataConfig.faq")}
                    ,null
                    ,intl
                );

                return ;
            }
        }

        setUseTarget(event.target.value);
    };

    const addDeleteFilesToActiveData = (id, data) => {
        const updatedData = activeData.map((item) => item.id === id ? data : item)
        dispatch(setManageWorkflowLabelActiveData(updatedData))
    }

    const addDeleteFilesToInactiveData = (id, data) => {
        const updatedData = inactiveData.map((item) => item.id === id ? data : item)
        dispatch(setManageWorkflowLabelInactiveData(updatedData))
    }

    const handleFileDelete = (e, downloadUrl, realFilename) => {
        let deleteFile = {
            "downloadUrl" : downloadUrl,
            "realFilename" : realFilename
        }

        const findData = dataConfig.find((target) => target.id === selectedId);
        const attachFiles = findData.body.attachFiles;
        const filteredFiles = attachFiles.filter(item => item.realFilename !== realFilename && item.downloadUrl !== downloadUrl);
        findData.body.attachFiles = filteredFiles
        if(findData.deleteFiles){
            findData.deleteFiles.push(deleteFile);
        }else{
            findData.deleteFiles = [];
            findData.deleteFiles.push(deleteFile);
        }

        if(findData.useYn === "Y"){
            addDeleteFilesToActiveData(findData.id, findData)
        }else{
            addDeleteFilesToInactiveData(findData.id, findData)
        }

        dispatch(switchManageWorkflowLabelRegistDataRefreshFlag())
    }

    // const handleSameFile = (item) => {
    //     for (let i = 0; i < files.length; i++) {
    //         if (files[i].file.name === item.file.name &&
    //             files[i].file.size === item.file.size &&
    //             files[i].file.type === item.file.type) {
    //             // 중복되는 파일이 존재할 경우
    //             return false;
    //         }
    //     }
    //     // 중복되는 파일이 없을 경우
    //     return true;
    // }

    // const handleAddFile = (error, file) => {
    //     if (error) {
    //         console.log('Oh no', error);
    //         return;
    //     }
    //
    //     console.log(`file : ${file}, pondRef : ${pondRef}`)
    //     const sameFile = files.find(f => f.file.name === file.filename);
    //
    //     if (sameFile) {
    //         const confirmReplace = window.confirm('This file already exists. Do you want to replace it?');
    //         if (confirmReplace) {
    //             // Remove the existing file from the files array
    //             setFiles(files.filter(f => f.file.name !== file.filename));
    //         } else {
    //             // Remove the new file from the FilePond instance
    //             pondRef.current.removeFile(file.id);
    //         }
    //     }
    // };


    return (
        <Card className="shadow-none mb-0">
            <CardHeader className="border-0 d-flex align-items-center tx-16 lh-18 pb-0 text-coco-gray-700 fw-bold">
                <i className="ri-book-read-line mr-12"></i>{GetMessageIntl(intl, "workflow.dataConfig.editor")}
            </CardHeader>
            <CardBody className="p-0">
                <SimpleBar className="table-responsive" style={{height: "calc(100vh - 250px)"}}>
                    <Col style={{padding:'1rem 1rem 0 1rem'}}>
                        <Row className="mb-8">
                            <div className={"d-flex justify-content-start align-items-center"}>
                                <div className="text-coco-gray-700 fw-500 tx-16 wd-20p">
                                    {GetMessageIntl(intl, "workflow.dataConfig.title")}
                                </div>
                                <div className={"wd-80p"}>
                                    <div className={errorData.dataItems && errorData.dataItems.find((item)=> item.id === selectedId && item.error == 'title') ? "validation-error-bottom":""}>
                                        <input type="text" value={title} style={{border:'0px', borderRadius:'0', fontSize:'16px'}} maxLength={50} className="form-control form-control-sm fw-500 text-coco-gray-400-f pd-x-0-f" placeholder="제목을 입력하세요."
                                               onKeyDown={(e) => {
                                                   if (e.key === 'Enter') {
                                                       e.preventDefault()
                                                   }
                                               }}
                                               onChange={(e)=>{
                                                    setTitle(e.target.value)
                                               }
                                        }/>
                                        {
                                            errorData.dataItems && errorData.dataItems.map((item) => {
                                                if(item.id === selectedId && item.error == 'title'){
                                                    return (
                                                        <p className="validation-error-message mb-0">{GetMessageIntl(intl, "validation.required")}</p>
                                                    )
                                                }
                                            })
                                        }
                                    </div>
                                </div>
                            </div>
                        </Row>
                        <Row className="mb-8">
                            <div className={"d-flex justify-content-start align-items-center"}>
                                <div className="text-coco-gray-700 fw-500 tx-16 wd-20p">
                                    캔버스
                                </div>
                                <div className="wd-80p">
                                    <div className="d-flex align-items-center fs-16 gap-2 mb-0">
                                        <div className="form-check form-radio-coco">
                                            <Input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" onChange={handleRadioChange}
                                                value="01" checked={useTarget === '01'}/>
                                            <Label className="form-check-label mb-0" for="flexRadioDefault1">
                                                {GetMessageIntl(intl, "workflow.dataConfig.guide")}
                                            </Label>
                                        </div>
                                        <div className="form-check form-radio-coco">
                                            <Input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" onChange={handleRadioChange}
                                                value="02" checked={useTarget === '02'}/>
                                            <Label className="form-check-label mb-0" for="flexRadioDefault2">
                                                {GetMessageIntl(intl, "workflow.dataConfig.faq")}
                                            </Label>
                                        </div>
                                        <div className="form-check form-radio-coco">
                                            <Input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault3" onChange={handleRadioChange}
                                                value="03" checked={useTarget === '03'}/>
                                            <Label className="form-check-label mb-0" for="flexRadioDefault3">
                                                {GetMessageIntl(intl, "workflow.dataConfig.etc")}
                                            </Label>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </Row>
                        <Row>
                            <div className="d-flex">
                                <div className="text-coco-gray-700 fw-500 tx-16 wd-20p">
                                    {GetMessageIntl(intl, "workflow.dataConfig.attachFile")}
                                </div>
                                <div className="wd-80p">
                                    <div className="d-flex justify-content-between text-coco-gray-400 mb-8">
                                        <div></div>
                                        <div>
                                        </div>
                                        <div>
                                            {files?.length || 0}건의 파일
                                        </div>
                                    </div>
                                    <div className="filepond-div">
                                        <SimpleBar style={{maxHeight: "120px"}}>
                                            <FilePond
                                                ref={pondRef}
                                                files={files}
                                                onupdatefiles={setCpFiles}
                                                server={{}}
                                                chunkUploads={true}
                                                allowMultiple={true}
                                                instantUpload={false}
                                                name="files"
                                                labelIdle={GetMessageIntl(intl, "workflow.dataConfig.filepondPlaceholder")}
                                                credits={false}
                                            />
                                        </SimpleBar>
                                    </div>
                                    <div>
                                        {
                                            attachFiles.map((file, idx) => {
                                                const {downloadUrl, realFilename} = file
                                                const findData = dataConfig.find(item => item.id === selectedId)
                                                let delStyle = undefined;
                                                if(findData){
                                                    if(findData.deleteFiles){
                                                        findData.deleteFiles.map((item) => {
                                                            if(downloadUrl === item.downloadUrl && realFilename === item.realFilename){
                                                                delStyle = {textDecorationLine : "line-through"};
                                                            }
                                                        })
                                                    }
                                                }
                                                return(
                                                    <Row className="mb-2" key={idx}>
                                                        <div className={"d-flex justify-content-between"}>
                                                            <div className={"d-flex gap-2"} style={delStyle}>
                                                                <span className="text-coco-gray-400" onClick={(e) =>
                                                                    handleFileDelete(e, downloadUrl, realFilename)}>
                                                                    <i className="ri-close-circle-line"></i>
                                                                </span>
                                                                <span className="text-coco-gray-700" >{file.realFilename}</span>
                                                            </div>
                                                            <div>
                                                            </div>
                                                        </div>
                                                    </Row>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            </div>
                        </Row>
                        <Row className="justify-content-center">
                            <div className="dataConfigDiv-editor registDataEditor">
                                <CKEditor
                                    key={dataConfig.find(item => item.id === selectedId)?.subId}
                                    ref={ckEditorRef}
                                    editor={Editor}
                                    data={content}
                                    config={{
                                        width:"99%",
                                        // plugins: [ Preview ],
                                        extraPlugins: [uploadPlugin],
                                        removePlugins : ['specialCharacters','MediaEmbedToolbar'],
                                        toolbar:{
                                            removeItems: [ 'specialCharacters'],
                                        },
                                    }}
                                    onReady={editor => {
                                        // You can store the "editor" and use when it is needed.
                                        //console.log('Editor is ready to use!', editor);
                                        ckEditorRef.current = editor
                                        editor.setData(content)
                                    }}
                                    onChange={(event, editor) => {
                                        setContent(editor.getData())
                                        //console.log({event, editor, data});
                                    }}
                                    onBlur={(event, editor) => {
                                        //console.log('Blur.', editor);
                                    }}
                                    onFocus={(event, editor) => {
                                        //console.log('Focus.', editor);
                                    }}
                                />
                            </div>
                        </Row>
                    </Col>
                </SimpleBar>
            </CardBody>
        </Card>
    );



}

export default LabelRegistDataEditorForm;