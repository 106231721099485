import {callApi} from '../utils/AxiosUtil';

/**
 *  검수 공통 관련 서비스
 */
export const ReviewCmmnService = {
	/**
	 * 검수 결과 저장
	 * @param param {moduleId, labelModuleId, issueCnObj, taskSttusCd, datasetId, fileSeq}
	 * @returns {Promise<axios.AxiosResponse<any>>}
	 */
	save: (param) => callApi('/service/review/save', param),

	/**
	 *  저장된 검수 결과 조회
	 * @param param {moduleId, datasetId, fileSeq}
	 * @returns {Promise<axios.AxiosResponse<any>>}
	 */
	getSavedItem: (param) => callApi('/service/review/getSavedItem', param),

	/**
	 * 다음 검수 대상 조회
	 * @param param {moduleId}
	 * @returns {Promise<axios.AxiosResponse<any>>}
	 */
	getNextItem: (param) => callApi('/service/review/getNextItem', param),

	/**
	 * 검수 대상 할당
	 * @param param {moduleId}
	 * @returns {Promise<axios.AxiosResponse<any>>}
	 */
	// 03: 새로운 데이터 할당
	// 04: 할당 가능한 데이터가 남아있지 않음
	jobTargetBind: (param) => callApi('/service/review/jobTargetBind', param),

	/**
	 * 이미지 검수 캔버스 진입 전,  작업 할당 관련 세팅 확인
	 * @param param {moduleId}
	 * @returns {Promise<axios.AxiosResponse<any>>}
	 */
	// 00 : 기본 상태
	// 11 : 신규 사용자이며, 데이터를  할당함 : 캔버스로 이동
	// 12 : 이전에 받았던 데이터가 있음 : 캔버스로 이동
	// 13 : 이전에 받았던 데이터가 있으며, 할당받은 데이터가 없으므로 새로 받을건지 물어봄
	// 04 : 할당 가능한 데이터가 남아있지 않음
	// 05 : 프로젝트 상태가 진행중이 아님
	checkBefore: (param) => callApi('/service/review/checkBefore', param),

	/**
	 * 이미지 라벨링 모듈의 shape config 조회
	 * @param param {labelModuleId} : url 가져오면서 같이 return 한다.
	 * @returns {Promise<axios.AxiosResponse<any>>}
	 */
	getProjectInfo: (param) => callApi('/service/review/getLabelAttrList', param),
}

