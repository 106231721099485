import React, {useEffect, useRef, useState} from 'react';
import {Button, Col, Label, Modal, ModalBody, ModalHeader, Row} from "reactstrap";
import {
    board_faqSetModalMode,
    board_faqSwitchAddModalFlag,
    board_otoSwitchAddModalFlag,
    board_switchModalFlag,
    board_switchTmpViewModalFlag
} from "../../store/board/action";
import NoticeTmpView from "./NoticeTmpView";
import SimpleBar from "simplebar-react";
import {GetMessage, GetMessageIntl} from "../../util/message";
import CommonUtil from "../../util/CommonUtil";
import {FilePond} from "react-filepond";
import {CKEditor} from "@ckeditor/ckeditor5-react";
import Editor from "ckeditor5-custom-build";
import {useDispatch, useSelector} from "react-redux";
import {CommonService} from "../../apis/api/CmmnService";
import {useIntl} from "react-intl";
import Select from "react-select";
import {TaskService} from "../../apis/api/TaskService";
import {BoardService} from "../../apis/api/BoardService";
import {alertSuccess, confirm} from "../../util/AlertUtil";
import {Controller, useForm} from "react-hook-form";

function AskAddModal(props) {
    const dispatch = useDispatch();
    const intl = useIntl();
    const coconutUserInfo = JSON.parse(sessionStorage.getItem("coconutUserInfo"));
    const userSeq = coconutUserInfo.userSeq;
    const {
        register,
        handleSubmit,
        reset,
        control,
        formState: {errors}
    } = useForm();

    const { addModalFlag } = useSelector((state) => ({
        addModalFlag: state.Board.otoAddModalFlag
    }))
    const [cnTyOption, setCnTyOption] = useState([]);
    const [taskOption, setTaskOption] = useState([]);

    // FilePond
    const [files, setFiles] = useState([]);
    const pondRef = useRef(null);
    const [totalFileSize, setTotalFileSize] = useState(0);
    const [cpFiles, setCpFiles] = useState([]);
    const maxTotalSize = 50 * 1024 * 1024; // 50MB

    // 본문
    const [cnTitle, setCnTitle] = useState("");
    const [cn, setCn] = useState("");
    const [cnTy, setCnTy] = useState();
    const [task, setTask] = useState("");
    const cnTySelectRef = useRef();
    const [selectedCnTyOption, setSelectedCnTyOption] = useState();
    const taskSelectRef = useRef();
    const [selectedTaskOption, setSelectedTaskOption] = useState();
    const [taskFlag, setTaskFlag] = useState(false);

    useEffect(() => {
        getCnTyList();
    }, [])

    useEffect(() => {
        getJoinedTask();
        resetData();
    }, [addModalFlag])

    useEffect(()=>{
        let delIdx = [];
        let totalFileSize = 0;
        for (let i = cpFiles.length - 1; i >= 0; i--) {
            totalFileSize += cpFiles[i].file.size
            if(totalFileSize > maxTotalSize){
                delIdx.push(i);
            }
        }
        let tmpArr = [...cpFiles];
        let delArr = [];
        delIdx.map((idxs) => {
            let delTarget = tmpArr.shift();
            delArr.push(delTarget.file.name)
        })

        setFiles(tmpArr);
        setTotalFileSize(totalFileSize);
        if(delArr.length > 0){
            alert("업로드 안된 파일들\n" + delArr.join('\n'));
        }
    }, [cpFiles])


    const getCnTyList = () => {
        CommonService.getAskCnTyList().then(res => {
            console.log("getCnTyList -> ", res.data.data)
            const options = res.data.data.map((opt) => (
                {
                    label : opt.cmmnDetailCdNm,
                    value : opt.cmmnDetailCd
                }
            ));
            const reOption = [...options];
            setCnTyOption(reOption)
        })
    }

    const getJoinedTask = () => {
        TaskService.getJoinedTaskListByUserSeq({
            userSeq: userSeq
        }).then(res => {
            console.log("getJoinedTask -> ", res.data.data)
            const defaultOption = {
                label : GetMessageIntl(intl, "board.choiceTask"),
                value : ""
            }
            if(res.data.data < 1){
              // 참여태스크가 없을 때
              const option = {
                  label : GetMessageIntl(intl, "board.notJoinedTaskMsg"),
                  value : ""
              }
              const reOption = [defaultOption, option]
              setTaskOption(reOption)
            }else{
                const options = res.data.data.map((opt) => ({
                    label : opt.moduleNm,
                    value : opt.moduleId
                }))
                const reOption = [defaultOption, ...options]
                setTaskOption(reOption)
            }

        })
    }

    const handleToggle = () => {
        dispatch(board_otoSwitchAddModalFlag());
    }

    const resetData = () => {
        reset();
        setCnTitle("")
        setCn("")
        setCnTy("")
        setFiles([])
        setTaskFlag(false)
        setSelectedCnTyOption(null);
        setSelectedTaskOption(null);
        // TODO Select 초기화
    }

    const addProcess = () => {
        const data = new FormData();
        data.append("cn", cn);
        data.append("boardSeq", 3);
        data.append("mode", "I");
        data.append("cnLvl", 1);
        data.append("cnTy", cnTy);
        data.append("replyYn", "N");
        if(cnTy === '01'){
            data.append("task", task)
        }else{
            data.append("task", "")
        }
        data.append("cnTitle", cnTitle);
        data.append("noticeYn", 'N');
        data.append("cnSttusCd", "00");

        for (let i = 0; i < files.length; i++) {
            // append the blob file
            data.append('attachFile[]', files[i].file);
        }

        console.log("Add Data =>", data)
        BoardService.setContent(data, {contentType : 'multipart/form-data'})
            .then((res) => {
                console.log(res.data)
            })
            .catch((err)=>{
                const data = new FormData();
            })
            .finally(()=> {
                dispatch(board_otoSwitchAddModalFlag());
            });
    }


    const registBtnEvent = () => {
        confirm("board.askOtoAdd"
            , "board.askOtoAddMessage"
            , () => {
                addProcess()
                alertSuccess("board.noticeAddSuccess", "board.noticeAddSuccessMessage", () => {

                }, null, intl)
            }
            , null
            , null
            , intl)
    }


    const handleTextAreaChange = (e) => {
        if (e.target.value.length <= 1000) {
            setCn(e.target.value);
        }
    }

    return (
        <>
            <Modal
                fullscreen={true}
                isOpen={props.modalFlag}
                toggle={() => {
                    dispatch(board_otoSwitchAddModalFlag());
                }}
                backdrop="static"
                keyboard={false}
                className="modal-fullscreen fw-bold"
                id="exampleModalFullscreen"
            >
                <ModalHeader
                    className="askModalHeader"
                    toggle={handleToggle} style={{borderBottom: "1px solid #CFD8DC"}}
                             cssModule={{'modal-title': 'w-100 text-center mb-0', 'btn-close' : 'btn-close btn-close-white askModalHeaderBtn'}}>
                    <div className="text-coco-default-white mb-0 tx-24 fw-semibold">
                        <div className="fs-48 mb-3">
                            {GetMessageIntl(intl, "board.oto")}
                        </div>
                        <div className="fs-24">
                            {GetMessageIntl(intl, "board.addModalTitleMsg")}
                        </div>
                    </div>
                </ModalHeader>
                <SimpleBar
                    style={{maxHeight: "657px", width: "100%", alignSelf: "center"}}
                    className="pe-2 ask-custom-simplebar-wrapper"
                >
                    <div style={{width:"63%"}}>
                        <ModalBody className="align-self-center fs-16" style={{paddingTop: '4%', width: "100%", minHeight:"657px"}}>
                            <Col>
                                <Row className="mb-3">
                                    <div className={"d-flex justify-content-start align-items-center"}>
                                        <div style={{marginRight:"25px"}}>
                                            {GetMessageIntl(intl, "board.askTy")}<span className="text-coco-danger-400">*</span>
                                        </div>
                                        <div className={errors.cnTy ? "validation-select-error":""} style={{width:'274px'}}>
                                            <Controller
                                                control={control}
                                                name="cnTy"
                                                rules={{ required: GetMessage('validation.requiredSelect') }}
                                                defaultValue=""
                                                render={({ field }) => (
                                                    <Select
                                                        {...field}
                                                        className="mb-0 fs-14 text-coco-gray-400"
                                                        value={cnTyOption.find(option => option.value === field.value)}
                                                        onChange={e => {
                                                            e.value === '01' ? setTaskFlag(true) : setTaskFlag(false)
                                                            setCnTy(e.value)
                                                            field.onChange(e.value);
                                                        }}
                                                        placeholder={GetMessageIntl(intl, "board.askTy")}
                                                        options={cnTyOption}
                                                        isSearchable={false}
                                                    />
                                                )}
                                            />
                                            {errors.cnTy && <span className={"validation-error-message"}>{errors.cnTy.message}</span>}
                                        </div>
                                        {
                                            taskFlag ?
                                                <div className={errors.task ? "validation-select-error":""} style={{width:'274px', marginLeft: '20px'}}>
                                                    <Controller
                                                        control={control}
                                                        name="task"
                                                        rules={{ required: taskFlag ? GetMessage('validation.requiredSelect') : false }}
                                                        defaultValue=""
                                                        render={({ field }) => (
                                                            <Select
                                                                {...field}
                                                                className="mb-0 fs-14 text-coco-gray-400"
                                                                value={taskOption.find(option => option.value === field.value)}
                                                                onChange={e => {
                                                                    setTask(e.value)
                                                                    field.onChange(e.value)
                                                                }}
                                                                placeholder={GetMessage('validation.choiceTask')}
                                                                options={taskOption}
                                                                isSearchable={false}
                                                            />
                                                        )}
                                                    />
                                                    {errors.task && <span className={"validation-error-message"}>{errors.task.message}</span>}
                                                </div>
                                                : null
                                        }
                                    </div>
                                </Row>
                                <Row className="mb-3">
                                    <div className={"d-flex justify-content-start align-items-center"}>
                                        <div style={{marginRight : "15px"}}>
                                            {GetMessage("board.title")}<span className="text-coco-danger-400">*</span>
                                        </div>
                                        <div className={errors.title?"validation-error":""}>
                                            <input type="text"
                                                   value={cnTitle} style={{
                                                border: '0px',
                                                fontSize: '15px',
                                                padding: '0px !important'
                                            }}
                                                   {...register("title", {
                                                       required : true,
                                                       onChange: (e) => setCnTitle(e.target.value)
                                                   })}
                                                   className="form-control form-control-sm fw-bold" placeholder="제목을 입력하세요."
                                            />
                                            {errors.title && <span className={"validation-error-message"}>{GetMessageIntl(intl,"validation.required")}</span>}
                                        </div>
                                    </div>
                                </Row>
                                <Row className="d-flex mb-3">
                                    <div className="d-flex justify-content-between align-items-center mb-3">
                                        <div>
                                            {GetMessageIntl(intl, 'board.askCn')}
                                        </div>
                                        <div className="text-coco-gray-400">
                                            {`${cn.length}/1000자`}
                                        </div>
                                    </div>
                                    <div>
                                        <textarea
                                            {...register("cn", {
                                                required : GetMessageIntl(intl, "validation.required"),
                                                onChange: (e) => handleTextAreaChange(e)
                                            })}
                                            value={cn}
                                            className={"form-control ".concat(errors.cn && "is-invalid")}
                                            id=""
                                            rows="10">
                                        </textarea>
                                        {errors.cn && <span className={"validation-error-message"}>{errors.cn.message}</span>}
                                    </div>
                                </Row>
                                <Row className="d-flex mb-2">
                                        <div className="w-100">
                                            <div className="d-flex justify-content-between text-coco-gray-400 mb-2">
                                                <div>
                                                    {files.length}건의 파일
                                                </div>
                                                <div>
                                                    {CommonUtil.bytesToSize(totalFileSize)} / 50MB
                                                </div>
                                            </div>
                                            <div className="askAddModalFilePondDiv">
                                                <SimpleBar style={{maxHeight: "170px"}} className="pe-2">
                                                    <FilePond
                                                        ref={pondRef}
                                                        files={files}
                                                        onupdatefiles={setCpFiles}
                                                        server={{}}
                                                        chunkUploads={true}
                                                        allowMultiple={true}
                                                        instantUpload={false}
                                                        name="files"
                                                        labelIdle={GetMessageIntl(intl, "workflow.dataConfig.filepondPlaceholder")}
                                                        credits={false}
                                                        maxFiles={5}
                                                    />
                                                </SimpleBar>
                                            </div>
                                    </div>
                                    <div className="text-coco-gray-400">
                                        <span>●</span> {GetMessage("board.limitFileExt")}
                                    </div>
                                </Row>
                                <Row className="justify-content-center">

                                </Row>
                            </Col>
                        </ModalBody>
                    </div>
                </SimpleBar>
                <div className="modal-footer d-flex justify-content-between align-items-center"
                     style={{borderTop: "1px solid #CFD8DC", paddingTop: "6px", paddingBottom: "6px"}}>

                    <div>
                    </div>
                    <div className={"d-flex flex-wrap gap-2"}>
                        <button type="submit" className="btn btn-coco-1" onClick={handleSubmit(registBtnEvent)}>
                            {GetMessage('board.regist')}
                        </button>
                        {/*<Button color="danger" className="btn-icon" onClick={handleTrash}> <i*/}
                        {/*    className="ri-delete-bin-5-line"/> </Button>*/}
                    </div>
                </div>
            </Modal>
        </>
    );
}

export default AskAddModal;
