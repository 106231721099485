import React, { useState, useRef, useEffect, useCallback } from "react";
import {useDispatch, useSelector} from "react-redux";
import {Card, CardBody, CardHeader, Input} from "reactstrap";
import SimpleBar from "simplebar-react";
import {GetMessageIntl} from "../../../../../../../util/message";
import {useIntl} from "react-intl";
import Select from "react-select";
import {
    defaultSelectStyle,
    formatGroupLabelNoCount, noBorderBackgroundSelectStyle,
    noBorderBackgroundTextSelectStyle
} from "../../../../../../../components/constants/select";
import {ReactSortable} from "react-sortablejs";
import {Controller, useFieldArray, useFormContext} from "react-hook-form";
import {WithContext as ReactTags} from "react-tag-input";
import {CommonService} from "../../../../../../../apis/api/CmmnService";
import {ErrorMessage} from "@hookform/error-message";
import {ModuleService} from "../../../../../../../apis/api/ModuleService";

function WorkerConditionRow({index, groupId, condition, joinConditionTyOptions, profileConditionTyOptions, historyConditionTyOptions, deleteConditionItemEvent, updateConditionItemEvent}) {

    const intl = useIntl();
    const prjctId = sessionStorage.getItem("prjctId");
    const { control, setValue, register, unregister, getValues, resetField, clearErrors, watch, formState: { errors },} = useFormContext();
    const watchAllWorker = watch();

    const [profileConditionSubTyOptions, setProfileConditionSubTyOptions] = useState([]);
    const [conditionEqualTyOptions, setConditionEqualTyOptions] = useState([]);
    const {id, operator, joinConditionTy, profile, sub, equals, history} = condition;
    const errorsCondition = errors&&errors.groupConditions && errors.groupConditions[groupId] && errors.groupConditions[groupId].conditions&&errors.groupConditions[groupId].conditions[id];

    const equalsOptions = [
        {label : GetMessageIntl(intl, "common.equals"),   value : 'equal'   }
        ,{label : GetMessageIntl(intl, "common.not"),    value : 'not'     }
    ];

    const historyEqualsOptions = [
        {label : GetMessageIntl(intl, "common.joinEquals"),   value : 'equal'   }
        ,{label : GetMessageIntl(intl, "common.joinNot"),    value : 'not'     }
    ];

    const operatorOptions = [
        {label : 'AND',   value : 'AND'  }
        ,{label : 'OR',    value : 'OR'  }];

    useEffect(()=>{
        if(joinConditionTy === '2010'){
            //  '2010' : 프로필
            if(profile){
                getProfileConditionSubTyOptions({'value':profile});
                setValue(`groupConditions.${groupId}.conditions.${id}.sub`,sub);
            }
            setConditionEqualTyOptions(equalsOptions);
        }else{
            setConditionEqualTyOptions(historyEqualsOptions);
        }
    }, []);

    useEffect(()=>{
        if(profile) {
            setValue(`groupConditions.${groupId}.conditions.${id}.profile`, profile);
        }
    },[profileConditionTyOptions]);



    const getProfileConditionSubTyOptions = async(event)=>{
        const value = event.value;
        const label = event.label;

        unregister(`groupConditions.${groupId}.conditions.${id}.sub`); // rule이 달라지므로
        register(`groupConditions.${groupId}.conditions.${id}.sub`);
        resetField(`groupConditions.${groupId}.conditions.${id}.sub`)
        setValue(`groupConditions.${groupId}.conditions.${id}.sub`, null);

        if(value==='201020'|| label === '나이'){

        }else{
            if(value === '201010' || label==='성별'){
                // 성별
                await CommonService.getGenderCd().then((result)=>{
                    if(result.data.data){
                        let resultData = result.data.data;
                        resultData = resultData.map((data)=>{
                            return { value: data.cmmnDetailCd, label: data.cmmnDetailCdNm }
                        })
                        setProfileConditionSubTyOptions(resultData);
                    }else{
                        setProfileConditionSubTyOptions([]);
                    }
                });
            }else if(value === '201040' || label==='학력'){
                // 학력
                await CommonService.getEducationCd().then((result)=>{
                    if(result.data.data){
                        let resultData = result.data.data;
                        resultData = resultData.map((data)=>{
                            return { value: data.cmmnDetailCd, label: data.cmmnDetailCdNm }
                        })
                        setProfileConditionSubTyOptions(resultData);
                    }else{
                        setProfileConditionSubTyOptions([]);
                    }
                });
            }else if(value === '201030' || label==='직업'){
                // 직업
                await CommonService.getWorkerJobCd().then((result)=>{
                    if(result.data.data){
                        let resultData = result.data.data;
                        resultData = resultData.map((data)=>{
                            return { value: data.cmmnDetailCd, label: data.cmmnDetailCdNm }
                        })
                        setProfileConditionSubTyOptions(resultData);
                    }else{
                        setProfileConditionSubTyOptions([]);
                    }
                });
            }else{
                setProfileConditionSubTyOptions([]);
            }
        }
    }

    const changeConditionAttrItemSelectEvent = (groupId, conditionId, name, event)=>{
        const value = event.value?event.value: event.target? event.target.value:'';
        clearErrors(name); // error가 되어있었다면 error는 지워준다..
        setValue(name, value);


        if(name.indexOf('joinConditionTy')>0){
            resetField(`groupConditions.${groupId}.conditions.${conditionId}.profile`);
            resetField(`groupConditions.${groupId}.conditions.${conditionId}.sub`);
            resetField(`groupConditions.${groupId}.conditions.${conditionId}.history`);
            resetField(`groupConditions.${groupId}.conditions.${conditionId}.equals`);

            setValue(`groupConditions.${groupId}.conditions.${conditionId}.profile`, null);
            setValue(`groupConditions.${groupId}.conditions.${conditionId}.sub`, null);
            setValue(`groupConditions.${groupId}.conditions.${conditionId}.history`, null);
            setValue(`groupConditions.${groupId}.conditions.${conditionId}.equals`, null);
            if(value === '2010'){
                //  '2010' : 프로필
                setConditionEqualTyOptions(equalsOptions);
            }else{
                setConditionEqualTyOptions(historyEqualsOptions);
            }
        }else if(name.indexOf('profile')>0){
            resetField(`groupConditions.${groupId}.conditions.${conditionId}.sub`);
            setValue(`groupConditions.${groupId}.conditions.${conditionId}.sub`, null);
            updateConditionItemEvent(groupId, conditionId, `groupConditions.${groupId}.conditions.${conditionId}.sub`, {value:null});

            getProfileConditionSubTyOptions(event);
        }

        updateConditionItemEvent(groupId, conditionId, name, event);
    }

    return(
        <>
            <h3>{JSON.stringify(getValues(`condition_${groupId}_${id}`))}</h3>
            <div key={`condition_${groupId}_${id}`} className="list-group-item d-flex align-items-start border-0 pd-y-10 pd-x-12 bg-coco-gray-50 text-coco-gray-400 mb-16">
                <div className={"d-flex align-items-start w-100"}>
                    <input type="hidden" {...register(`groupConditions.${groupId}.conditions.${id}.id`)} defaultValue={condition.id} />
                    <div className="wd-11p pe-1">
                            <Controller
                                name={`groupConditions.${groupId}.conditions.${id}.operator`}
                                rules={{
                                    required: true,
                                }}
                                defaultValue={operator}
                                render={({ field: { onChange, value, ref, name } }) => (
                                    <Select
                                        name=""
                                        isSearchable={false}
                                        options={operatorOptions}
                                        placeholder={GetMessageIntl(intl,"common.choice")}
                                        formatGroupLabel={formatGroupLabelNoCount}
                                        styles={noBorderBackgroundTextSelectStyle}
                                        onChange={(event)=>changeConditionAttrItemSelectEvent(groupId, id,`groupConditions.${groupId}.conditions.${id}.operator`, event)}
                                        value={value?operatorOptions.find((c) => c.value === value):null}
                                        className={"mb-0 ".concat(index>0?"":"d-none")}
                                        maxMenuHeight={250}
                                    />
                                )}
                            />
                    </div>
                    <div className="wd-16p pe-1">
                        <div className={errorsCondition&&errorsCondition.joinConditionTy?"validation-select-error":""}>
                            <Controller
                                name={`groupConditions.${groupId}.conditions.${id}.joinConditionTy`}
                                rules={{
                                    required: true,
                                }}
                                defaultValue={joinConditionTy?joinConditionTy:joinConditionTyOptions[0].value}
                                render={({ field: { onChange, value, ref, name } }) => (
                                    <Select
                                        name=""
                                        isSearchable={false}
                                        options={joinConditionTyOptions}
                                        placeholder={GetMessageIntl(intl,"common.choice")}
                                        formatGroupLabel={formatGroupLabelNoCount}
                                        styles={defaultSelectStyle}
                                        onChange={(event)=>changeConditionAttrItemSelectEvent(groupId, id,`groupConditions.${groupId}.conditions.${id}.joinConditionTy`, event)}
                                        value={value?joinConditionTyOptions.find((c) => c.value === value):null}
                                        className="mb-0"
                                        maxMenuHeight={250}
                                    />
                                )}
                            />
                        </div>
                        {errorsCondition&&errorsCondition.joinConditionTy && <p className="validation-error-message mb-0">{GetMessageIntl(intl,"validation.requiredSelect")}</p>}
                    </div>
                    {
                        watchAllWorker&&watchAllWorker.groupConditions&&watchAllWorker.groupConditions[groupId]
                        && watchAllWorker.groupConditions[groupId].conditions&&watchAllWorker.groupConditions[groupId].conditions[id]
                        && watchAllWorker.groupConditions[groupId].conditions[id].joinConditionTy&&watchAllWorker.groupConditions[groupId].conditions[id].joinConditionTy === '2010'
                        ?
                            <>
                                <div className="wd-18p pe-1">
                                    <div className={errorsCondition&&errorsCondition.profile?"validation-select-error":""}>
                                        <Controller
                                            name={`groupConditions.${groupId}.conditions.${id}.profile`}
                                            rules={{
                                                required: true,
                                            }}
                                            defaultValue={profile?profile:null}
                                            render={({ field: { onChange, value, ref, name } }) => (
                                                <Select
                                                    name=""
                                                    isSearchable={false}
                                                    options={profileConditionTyOptions}
                                                    placeholder={GetMessageIntl(intl,"common.choice")}
                                                    formatGroupLabel={formatGroupLabelNoCount}
                                                    styles={defaultSelectStyle}
                                                    onChange={(event)=>changeConditionAttrItemSelectEvent(groupId,id,`groupConditions.${groupId}.conditions.${id}.profile`, event)}
                                                    value={value?profileConditionTyOptions.find((c) => c.value === value):null}
                                                    className="mb-0"
                                                    maxMenuHeight={250}
                                                    menuPortalTarget={document.body}
                                                    menuPosition={'fixed'}
                                                    menuPlacement={'auto'}
                                                />
                                            )}
                                        />
                                    </div>
                                    {errorsCondition&&errorsCondition.profile && <p className="validation-error-message mb-0">{GetMessageIntl(intl,"validation.requiredSelect")}</p>}
                                </div>
                                <div className={"wd-9p"}>
                                    <div className={"ht-38 d-flex align-items-center"}>
                                        {GetMessageIntl(intl,"common.is")}
                                    </div>
                                </div>
                                <div className="wd-27p pe-2">
                                    {
                                        watchAllWorker&&watchAllWorker.groupConditions&&watchAllWorker.groupConditions[groupId]
                                        && watchAllWorker.groupConditions[groupId].conditions&&watchAllWorker.groupConditions[groupId].conditions[id]
                                        && watchAllWorker.groupConditions[groupId].conditions[id].profile&&watchAllWorker.groupConditions[groupId].conditions[id].profile === '201020'
                                            ?
                                            <>
                                                <div className={errorsCondition&&errorsCondition.sub?"validation-error":""}>
                                                    <Controller
                                                        render={({ field }) => <Input {...field} onChange={(event)=>changeConditionAttrItemSelectEvent(groupId,id,`groupConditions.${groupId}.conditions.${id}.sub`, event)} placeholder={GetMessageIntl(intl, "workflow.agePlaceholder")}/>}
                                                        name={`groupConditions.${groupId}.conditions.${id}.sub`}
                                                        defaultValue={sub?sub||'':''}
                                                        control={control}
                                                        rules={{
                                                            required: GetMessageIntl(intl,"validation.required"),
                                                            pattern: {
                                                                value: /^[0-9][0-9]*[0-9]*(~[1-9][0-9]*[0-9]*)?$/g,
                                                                message: GetMessageIntl(intl,"workflow.ageErrorMessage")
                                                            },
                                                            validate: (value, formValues) => {
                                                                if(value && value.indexOf('-')>-1){
                                                                    // 마이너스는 있으면 안됨
                                                                    return GetMessageIntl(intl, 'validation.updateField');
                                                                }
                                                                if(value && value.indexOf('~')>-1){
                                                                    const ageArr = value.split('~');
                                                                    console.log('ageArr', ageArr);
                                                                    if(ageArr.length===2){
                                                                        const frontNum = parseInt(ageArr[0]);
                                                                        const endNum = parseInt(ageArr[1]);
                                                                        if (frontNum>endNum || frontNum == endNum) return GetMessageIntl(intl, 'validation.updateField');
                                                                    }else{
                                                                        return GetMessageIntl(intl, 'validation.updateField');
                                                                    }
                                                                }
                                                            }
                                                        }}
                                                        className="form-control"
                                                        menuPortalTarget={document.body}
                                                        menuPosition={'fixed'}
                                                        menuPlacement={'auto'}
                                                    />
                                                </div>
                                                {errorsCondition&&errorsCondition.sub && <p className="validation-error-message mb-0">{errorsCondition.sub.message}</p>}
                                            </>
                                            :
                                            <>
                                                <div className={errorsCondition&&errorsCondition.sub?"validation-select-error":""}>
                                                    <Controller
                                                        name={`groupConditions.${groupId}.conditions.${id}.sub`}
                                                        rules={{
                                                            required: true,
                                                        }}
                                                        defaultValue={sub?sub:null}
                                                        render={({ field: { onChange, value, ref, name } }) => (
                                                            <Select
                                                                name=""
                                                                isSearchable={false}
                                                                options={profileConditionSubTyOptions}
                                                                placeholder={GetMessageIntl(intl,"common.choice")}
                                                                formatGroupLabel={formatGroupLabelNoCount}
                                                                styles={defaultSelectStyle}
                                                                onChange={(event)=>changeConditionAttrItemSelectEvent(groupId,id,`groupConditions.${groupId}.conditions.${id}.sub`, event)}
                                                                value={value?profileConditionSubTyOptions.find((c) => c.value === value):null}
                                                                className="mb-0"
                                                                maxMenuHeight={250}
                                                                menuPortalTarget={document.body}
                                                                menuPosition={'fixed'}
                                                                menuPlacement={'auto'}
                                                            />
                                                        )}
                                                    />
                                                </div>
                                                {errorsCondition&&errorsCondition.sub && <p className="validation-error-message mb-0">{GetMessageIntl(intl,"validation.requiredSelect")}</p>}
                                            </>
                                    }
                                </div>
                            </>
                            :
                            <>
                                <div className="wd-45p pe-1">
                                    <div className={errorsCondition&&errorsCondition.history?"validation-select-error":""}>
                                        <Controller
                                            name={`groupConditions.${groupId}.conditions.${id}.history`}
                                            rules={{
                                                required: true,
                                            }}
                                            defaultValue={history?history:null}
                                            render={({ field: { onChange, value, ref, name } }) => (
                                                <Select
                                                    name=""
                                                    isSearchable={false}
                                                    options={historyConditionTyOptions}
                                                    placeholder={GetMessageIntl(intl,"common.choice")}
                                                    formatGroupLabel={formatGroupLabelNoCount}
                                                    styles={defaultSelectStyle}
                                                    onChange={(event)=>changeConditionAttrItemSelectEvent(groupId,id,`groupConditions.${groupId}.conditions.${id}.history`, event)}
                                                    value={value?historyConditionTyOptions.map((groupOption) => {
                                                        let options = groupOption.options.find((o) => o.value == value);
                                                        if(options !== undefined){
                                                            return options;
                                                        }
                                                        }):null
                                                    }
                                                    className="mb-0"
                                                    maxMenuHeight={250}
                                                    menuPortalTarget={document.body}
                                                    menuPosition={'fixed'}
                                                    menuPlacement={'auto'}
                                                />
                                            )}
                                        />
                                    </div>
                                    {errorsCondition&&errorsCondition.history && <p className="validation-error-message mb-0">{GetMessageIntl(intl,"validation.requiredSelect")}</p>}
                                </div>
                                <div className={"wd-5p"}>
                                    <div className={"ht-38 d-flex align-items-center"}>
                                        {GetMessageIntl(intl,"common.to")}
                                    </div>
                                </div>
                            </>
                    }
                    <div className={watchAllWorker&&watchAllWorker.groupConditions&&watchAllWorker.groupConditions[groupId]
                                        && watchAllWorker.groupConditions[groupId].conditions&&watchAllWorker.groupConditions[groupId].conditions[id]
                                        && watchAllWorker.groupConditions[groupId].conditions[id].joinConditionTy&&watchAllWorker.groupConditions[groupId].conditions[id].joinConditionTy === '2010'? "wd-19p":"wd-23p"}>
                        <div className={errorsCondition&&errorsCondition.equals?"validation-select-error":""}>
                            <Controller
                                name={`groupConditions.${groupId}.conditions.${id}.equals`}
                                rules={{
                                    required: true,
                                }}
                                defaultValue={equals?equals:null}
                                render={({ field: { onChange, value, ref, name } }) => (
                                    <Select
                                        name=""
                                        isSearchable={false}
                                        options={conditionEqualTyOptions}
                                        placeholder={GetMessageIntl(intl,"common.choice")}
                                        formatGroupLabel={formatGroupLabelNoCount}
                                        styles={defaultSelectStyle}
                                        onChange={(event)=>changeConditionAttrItemSelectEvent(groupId, id,`groupConditions.${groupId}.conditions.${id}.equals`, event)}
                                        value={value?conditionEqualTyOptions.find((c) => c.value === value):null}
                                        className="mb-0"
                                        maxMenuHeight={250}
                                    />
                                )}
                            />
                        </div>
                        {errorsCondition&&errorsCondition.equals && <p className="validation-error-message mb-0">{GetMessageIntl(intl,"validation.requiredSelect")}</p>}
                    </div>
                </div>
                <div className={"ht-38 d-flex align-items-center"}>
                    <i className="ri-close-line tx-22 ps-2 delProjectAttribute text-pointer" onClick={()=>deleteConditionItemEvent(groupId, id)}></i>
                </div>
            </div>
        </>
    )
}
export default WorkerConditionRow;