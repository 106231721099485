// @ts-nocheck
import {useDispatch, useSelector} from "react-redux";
import {DropdownItem, Input, Label} from "reactstrap";
import React, {useEffect, useState} from "react";
import {useIntl} from "react-intl";
import {GetMessageIntl} from "../../../../../util/message";
import {updateSearchParam, updateSearchParamVisible} from "../../../../../store/dataset/details/action";
import {SearchFilter} from "../../../../../store/dataset/details";
import {ApplySearchButton} from "./search-header";
import {cloneDeep} from "lodash";

export function SkipFilter(): JSX.Element{
    const intl = useIntl();
    const dispatch = useDispatch();
    const {
        Label: {
            Cmmn: {
                canvas: {skipPopover: {reasons}}
            }
        },
        Details: {
            searchParam: {view, data}
        }
    } = useSelector(state => state);

    const [skipRsn, setSkipRsn] = useState([]);
    const choiceCdList = data.skip;
    const choiceLabelList = view.skip;

    useEffect(()=>{
        const list = reasons.reduce((acc, rsn) => {
            acc.push({label: rsn.cmmnDetailCdNm, type: rsn.cmmnDetailCd})
            return acc;
        }, [])
        list.push({label: GetMessageIntl(intl, 'details.noExistSkip'), type: "12"})
        setSkipRsn(list);
    }, [reasons])

    const onCdChange = (target, label) => {
        const checked = target.checked;
        const value = target.value;
        const newValues = cloneDeep(choiceCdList)
        let newLabels = cloneDeep(choiceLabelList);
        const value_ = checked ? newValues.concat(value) : newValues.filter(v => v!=value);
        const label_ = checked ? newLabels.concat({label: label, index: value}) : newLabels.filter(v => v.index != value);
        label_.sort(function (a, b) {
            return a.index - b.index
        })
        dispatch(updateSearchParam(SearchFilter.SKIP_REASONS, value_, label_))

        // dispatch(updateSearchParam(SearchFilter.SKIP_REASONS, checked ? newValues.filter(v => v!=value) : newValues.concat(value)))
    }

    return (
        <div className={"filter-container"}>
            <div className={"d-flex align-items-center justify-content-between"}>
                <DropdownItem header>{GetMessageIntl(intl, 'details.skipReasons')}</DropdownItem>
                <ApplySearchButton />
            </div>
            <DropdownItem divider/>
            <div className={"d-flex flex-column align-items-center gap-2"}>
                {skipRsn.map(
                    (rsn: any): JSX.Element => {
                        const { type: rsnCd} = rsn;
                        return (
                            <DropdownItem  key={`skip-filter-${rsnCd}`} toggle={false}>
                                <div className="form-check form-check-coco">
                                    <Input className="form-check-input" type="checkbox"
                                           id={rsnCd}
                                           value={rsnCd}
                                           defaultChecked={choiceCdList.includes(rsnCd)}
                                           onChange={(event) => {
                                               onCdChange(event.target, rsn.label)
                                           }}
                                    />
                                    <Label className="form-check-label" htmlFor={rsnCd}>
                                        {rsn.label}
                                    </Label>
                                </div>
                            </DropdownItem>
                        )
                    }
                )}
            </div>
        </div>
    )
}
