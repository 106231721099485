import React, {useMemo} from 'react';
import CustomTooltip from "../../../components/features/CustomTooltip";
import styled from "styled-components";

const Tooltip = styled.div`
  background-color: #555;
  color: #fff;
  text-align: center;
  padding: 5px 5px;
  border-radius: 6px;
  transition: opacity 0.3s;
  font-size: 13px;
`
function DatasetGridTooltip(props) {
    const display = props.data.fileNm.length < 51 ? {display: 'none'} : null;
    return (
        <Tooltip style={display}>
            <span>{props.data.fileNm}</span>
        </Tooltip>
    );
}

export default DatasetGridTooltip;
