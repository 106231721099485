import React, {useEffect, useRef, useState, useCallback} from 'react';
import BreadCrumb from "../../../../components/common/BreadCrumb";
import {Card, CardHeader, Col, Container, Input, Row} from "reactstrap";
import {DefaultRootState, RootStateOrAny, useDispatch, useSelector} from "react-redux";
import ReactFlow, {
    ReactFlowProvider,
    addEdge,
    useNodesState,
    useEdgesState,
    Controls,
} from 'reactflow';
import 'reactflow/dist/style.css';
import WorkFlow from "./components/ProjectWorkflow";
import {
    manage_project_workflow_module_setIsDatasetOffcanvas, manage_project_workflow_module_setIsLabelOffcanvas,
    manage_project_workflow_module_setIsModuleOffcanvas, manage_project_workflow_module_setIsReviewOffcanvas
} from "../../../../store/manage/project/workflow/module/action";

function ProjectWorkFlowSetting() {

    const dispatch = useDispatch();
    useEffect(()=>{
        // 다 false로 해버리자!
        dispatch(manage_project_workflow_module_setIsModuleOffcanvas(false));
        dispatch(manage_project_workflow_module_setIsDatasetOffcanvas(false));
        dispatch(manage_project_workflow_module_setIsLabelOffcanvas(false));
        dispatch(manage_project_workflow_module_setIsReviewOffcanvas(false));
    },[])


    return (
        <>
            <Container fluid style={{paddingLeft:'12px', paddingRight:'12px'}}>
                {/*<BreadCrumb title="워크플로우 셋팅" pageTitle="Laflow" />*/}
                <Row className="px-0">
                    <WorkFlow />
                </Row>
            </Container>
        </>
    );
}

export default ProjectWorkFlowSetting;