import React, {useState} from 'react'
import Select from 'react-select'
import {CommonService} from "../../apis/api/CmmnService";

function ApiTest() {

    const [apiCallResult, setApiCallResult] = useState("");

    const options = [
        {value: 'chocolate', label: 'Chocolate'},
        {value: 'strawberry', label: 'Strawberry'},
        {value: 'vanilla', label: 'Vanilla'}
    ]

    const [inputs, setInputs] = useState({
        name: '이훈희',
        nickname: '훈군'
    });

    console.log(inputs)


    // console.log(inputs)

    const { name, nickname } = inputs; // 비구조화 할당을 통해 값 추출

    const MyComponent = () => (
        <Select options={options} onChange={GetData}/>
    )

    const GetData = async (prop) => {
        const data = await CommonService.getFaqCnTyList();
        console.log(data.data.data);
        setApiCallResult(
            <ul>
                {data.data.data.map(item => {
                    console.log(item)
                    return <li key={item.cmmnDetailCd}>{item.cmmnDetailCdNm}</li>
                })}
            </ul>);
        console.log(name)
        setInputs({
            ...inputs,
            ['name']: '김선희'
        });

    }

    return (
        <>
            <MyComponent/>
            <div>{apiCallResult}</div>
        </>
    )
}


export default ApiTest;