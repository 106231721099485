import {DimensionType} from "../label";
import {KeyMap} from "../../pages/user/label/annotation/image/utils/mousetrap-react";

export enum Category {
    INSTANCE='instance',    // 인스턴스
    JOB='job',  // 작업
    ZOOM_MOVE='zoomMove',    // 줌 및 이동
    TOOL='tool',    // 도구
    BRUSH='brush',   // 브러시
    VIEW='view'    // 보기
}
export const ImageAnnotatingKeyMap = ({
    // 인스턴스
    DELETE_OBJECT: {    // O 인스턴스 삭제
        name: 'Delete object',
        description: 'Delete an active object. Use shift to force delete of locked objects',
        category: Category.INSTANCE,
        code: 'shortcuts.deleteInstance',
        sequences: ['del'],
        action: 'keydown',
        applicable: [DimensionType.DIM_2D, DimensionType.DIM_3D],
    },
    DELETE_ALL_OBJECTS: {   // 전체 인스턴스 삭제
        name: 'Delete all objects',
        description: 'Delete all objects. Use shift to force delete of locked objects',
        category: Category.INSTANCE,
        code: 'shortcuts.deleteAllInstances',
        sequences: ['ctrl+r'],
        action: 'keydown',
        applicable: [DimensionType.DIM_2D, DimensionType.DIM_3D],
    },
    COPY_SHAPE: {   // 인스턴스 복사
        name: 'Copy shape',
        description: 'Copy shape to CVAT internal clipboard',
        category: Category.INSTANCE,
        code: 'shortcuts.copyInstance',
        sequences: ['ctrl+c'],
        action: 'keydown',
        applicable: [DimensionType.DIM_2D, DimensionType.DIM_3D],
    },
    PASTE_SHAPE: {  // 인스턴스 붙여넣기
        name: 'Paste shape',
        description: 'Paste a shape from internal CVAT clipboard',
        category: Category.INSTANCE,
        code: 'shortcuts.pasteInstance',
        sequences: ['ctrl+v'],
        action: 'keydown',
        applicable: [DimensionType.DIM_2D, DimensionType.DIM_3D],
    },
    LOAD_PREV_FILE_OBJECTS: {
        name: 'Load the objects in the previous file',
        description: 'Load the objects in the file you worked on last time',
        category: Category.INSTANCE,
        code: 'shortcuts.loadPrevFileInstance',
        sequences: ['ctrl+h'],
        action: 'keydown',
        applicable: [DimensionType.DIM_2D, DimensionType.DIM_3D],
    },
    // 줌 및 이동
    ZOOM_IN_OUT: {  // Mousetrap 등록 안함
        name: 'Zoom in and out',
        description: 'Zoom in and out',
        category: Category.ZOOM_MOVE,
        code: 'shortcuts.zoomInOut',
        sequences: ['Wheel'],
        action: 'keydown',
        applicable: [DimensionType.DIM_2D],
    },
    MOVE_LEFT_OBJECT: {
        name: 'Move left object',
        description: 'Move 1px left object',
        category: Category.ZOOM_MOVE,
        code: 'shortcuts.moveLeftObject',
        sequences: ['ctrl+left'],
        action: 'keydown',
        applicable: [DimensionType.DIM_2D]
    },
    MOVE_RIGHT_OBJECT: {
        name: 'Move right object',
        description: 'Move 1px right object',
        category: Category.ZOOM_MOVE,
        code: 'shortcuts.moveRightObject',
        sequences: ['ctrl+right'],
        action: 'keydown',
        applicable: [DimensionType.DIM_2D]
    },
    MOVE_UP_OBJECT: {
        name: 'Move up object',
        description: 'Move 1px up object',
        category: Category.ZOOM_MOVE,
        code: 'shortcuts.moveUpObject',
        sequences: ['ctrl+up'],
        action: 'keydown',
        applicable: [DimensionType.DIM_2D]
    },
    MOVE_DOWN_OBJECT: {
        name: 'Move down object',
        description: 'Move 1px down object',
        category: Category.ZOOM_MOVE,
        code: 'shortcuts.moveDownObject',
        sequences: ['ctrl+down'],
        action: 'keydown',
        applicable: [DimensionType.DIM_2D]
    },
    JUMP_LEFT_OBJECT: {
        name: 'Jump left object',
        description: 'Jump 10px left object',
        category: Category.ZOOM_MOVE,
        code: 'shortcuts.jumpLeftObject',
        sequences: ['ctrl+shift+left'],
        action: 'keydown',
        applicable: [DimensionType.DIM_2D]
    },
    JUMP_RIGHT_OBJECT: {
        name: 'Jump right object',
        description: 'Jump 10px right object',
        category: Category.ZOOM_MOVE,
        code: 'shortcuts.jumpRightObject',
        sequences: ['ctrl+shift+right'],
        action: 'keydown',
        applicable: [DimensionType.DIM_2D]
    },
    JUMP_UP_OBJECT: {
        name: 'Jump up object',
        description: 'Jump 10px up object',
        category: Category.ZOOM_MOVE,
        code: 'shortcuts.jumpUpObject',
        sequences: ['ctrl+shift+up'],
        action: 'keydown',
        applicable: [DimensionType.DIM_2D]
    },
    JUMP_DOWN_OBJECT: {
        name: 'Jump down object',
        description: 'Jump 10px down object',
        category: Category.ZOOM_MOVE,
        code: 'shortcuts.jumpDownObject',
        sequences: ['ctrl+shift+down'],
        action: 'keydown',
        applicable: [DimensionType.DIM_2D]
    },
    // 작업
    SWITCH_DRAW_MODE: { // 필요 O 작업 끝내기
        name: 'Draw mode',
        description:
            'Repeat the latest procedure of drawing with the same parameters (shift to redraw an existing shape)',
        category: Category.JOB,
        code: 'shortcuts.drawDone',
        sequences: ['d'],
        action: 'keydown',
        applicable: [DimensionType.DIM_2D, DimensionType.DIM_3D],
    },
    CANCEL: { // O 그리기 툴 취소
        name: 'draw cancel',
        description: 'draw cancel',
        category: Category.JOB,
        code: 'shortcuts.cancel',
        sequences: ['esc'],
        action: 'keydown',
        applicable: [DimensionType.DIM_2D, DimensionType.DIM_3D],
    },
    DRAW_PREV_LABEL_TOOL: { // O 이전 라벨링 도구 유지
        name: 'Draw previous labeling tool',
        description: 'Draw previous labeling tool',
        category: Category.JOB,
        code: 'shortcuts.drawPrevLabelTool',
        sequences: ['c'],
        action: 'keydown',
        applicable: [DimensionType.DIM_2D, DimensionType.DIM_3D],
    },
    UNDO: { // O 작업 실행 취소
        name: 'Undo action',
        description: 'Cancel the latest action related with objects',
        category: Category.JOB,
        code: 'shortcuts.undo',
        sequences: ['ctrl+z'],
        action: 'keydown',
        applicable: [DimensionType.DIM_2D, DimensionType.DIM_3D],
    },
    REDO: { // O 작업 실행 취소 번복
        name: 'Redo action',
        description: 'Cancel undo action',
        category: Category.JOB,
        code: 'shortcuts.redo',
        sequences: ['ctrl+shift+z'],
        action: 'keydown',
        applicable: [DimensionType.DIM_2D, DimensionType.DIM_3D],
    },
    // 도구
    DRAW_BOUNDINGBOX: {
        name: 'Draw bounding box',
        description: 'draw bounding box',
        category: Category.TOOL,
        code: 'shortcuts.boundingBox',
        sequences: ['f1'],
        action: 'keydown',
        applicable: [DimensionType.DIM_2D, DimensionType.DIM_3D],
    },
    DRAW_POLYGON: {
        name: 'Draw polygon',
        description: 'draw polygon',
        category: Category.TOOL,
        code: 'shortcuts.polygon',
        sequences: ['f2'],
        action: 'keydown',
        applicable: [DimensionType.DIM_2D, DimensionType.DIM_3D],
    },
    DRAW_POLYLINE: {
        name: 'Draw polyline',
        description: 'draw polyline',
        category: Category.TOOL,
        code: 'shortcuts.polyline',
        sequences: ['f3'],
        action: 'keydown',
        applicable: [DimensionType.DIM_2D, DimensionType.DIM_3D],
    },
    DRAW_POINTS: {
        name: 'Draw points',
        description: 'draw points',
        category: Category.TOOL,
        code: 'shortcuts.points',
        sequences: ['f4'],
        action: 'keydown',
        applicable: [DimensionType.DIM_2D, DimensionType.DIM_3D],
    },
    DRAW_LINE: {
        name: 'Draw line',
        description: 'draw line',
        category: Category.TOOL,
        code: 'shortcuts.line',
        sequences: ['f5'],
        action: 'keydown',
        applicable: [DimensionType.DIM_2D, DimensionType.DIM_3D],
    },
    DRAW_ELLIPSE: {
        name: 'Draw ellipse',
        description: 'draw ellipse',
        category: Category.TOOL,
        code: 'shortcuts.ellipse',
        sequences: ['f6'],
        action: 'keydown',
        applicable: [DimensionType.DIM_2D, DimensionType.DIM_3D],
    },
    DRAW_POLY_ELLIPSE: {
        name: 'Draw poly ellipse',
        description: 'draw poly ellipse',
        category: Category.TOOL,
        code: 'shortcuts.polyEllipse',
        sequences: ['f7'],
        action: 'keydown',
        applicable: [DimensionType.DIM_2D, DimensionType.DIM_3D],
    },
    DRAW_CIRCLE: {
        name: 'Draw circle',
        description: 'draw circle',
        category: Category.TOOL,
        code: 'shortcuts.circle',
        sequences: ['f8'],
        action: 'keydown',
        applicable: [DimensionType.DIM_2D, DimensionType.DIM_3D],
    },
    DRAW_BRUSH: {
        name: 'Draw brush',
        description: 'draw brush',
        category: Category.TOOL,
        code: 'shortcuts.brush',
        sequences: ['f9'],
        action: 'keydown',
        applicable: [DimensionType.DIM_2D, DimensionType.DIM_3D],
    },
    // 브러시
    BRUSH_SIZE_UP: {
        name: 'brush size up',
        description: 'brush point size up',
        category: Category.BRUSH,
        code: 'shortcuts.brushSizeUP',
        sequences: ['ctrl+plus'],
        action: 'keydown',
        applicable: [DimensionType.DIM_2D, DimensionType.DIM_3D],
    },
    BRUSH_SIZE_DOWN: {
        name: 'brush size down',
        description: 'brush point size down',
        category: Category.BRUSH,
        code: 'shortcuts.brushSizeDOWN',
        sequences: ['ctrl+-'],
        action: 'keydown',
        applicable: [DimensionType.DIM_2D, DimensionType.DIM_3D],
    },
    SWITCH_ERASER: {
        name: 'switch eraser/brush',
        description: 'switch eraser/brush',
        category: Category.BRUSH,
        code: 'shortcuts.switchEraser',
        sequences: ['e'],
        action: 'keydown',
        applicable: [DimensionType.DIM_2D, DimensionType.DIM_3D],
    },
    // 보기
    FIT_IMAGE:{
        name: 'Fit image',
        description: 'Fit image',
        category: Category.VIEW,
        code: 'shortcuts.fitImage',
        sequences: ['f'],
        action: 'keydown',
        applicable: [DimensionType.DIM_2D],
    },
    SWITCH_GRID: {
        name: 'Switch grid',
        description: 'On/off the grid',
        category: Category.VIEW,
        code: 'shortcuts.switchGrid',
        sequences: ['g'],
        action: 'keydown',
        applicable: [DimensionType.DIM_2D],
    },
    SWITCH_SHORTCUTS: { // O 단축키 보기
        name: 'Show shortcuts',
        description: 'Open/hide the list of available shortcuts',
        category: Category.VIEW,
        code: 'shortcuts.showShortcuts',
        sequences: ['ctrl+?'],
        action: 'keydown',
        applicable: [DimensionType.DIM_2D, DimensionType.DIM_3D],
    },
    SWITCH_SETTINGS: {  // 필요 X
        name: 'Show settings',
        description: 'Open/hide settings dialog',
        sequences: ['f2'],
        action: 'keydown',
        applicable: [DimensionType.DIM_2D, DimensionType.DIM_3D],
    },
    SWITCH_ALL_LOCK: {  // 필요 X
        name: 'Lock/unlock all objects',
        description: 'Change locked state for all objects in the side bar',
        sequences: ['t l'],
        action: 'keydown',
        applicable: [DimensionType.DIM_2D, DimensionType.DIM_3D],
    },
    SWITCH_LOCK: {  // 필요 X
        name: 'Lock/unlock an object',
        description: 'Change locked state for an active object',
        sequences: ['l'],
        action: 'keydown',
        applicable: [DimensionType.DIM_2D, DimensionType.DIM_3D],
    },
    SWITCH_ALL_HIDDEN: {    // 필요 X
        name: 'Hide/show all objects',
        description: 'Change hidden state for objects in the side bar',
        sequences: ['t h'],
        action: 'keydown',
        applicable: [DimensionType.DIM_2D, DimensionType.DIM_3D],
    },
    SWITCH_HIDDEN: {    // 필요 X
        name: 'Hide/show an object',
        description: 'Change hidden state for an active object',
        sequences: ['h'],
        action: 'keydown',
        applicable: [DimensionType.DIM_2D, DimensionType.DIM_3D],
    },
    SWITCH_OCCLUDED: {  // 필요 X
        name: 'Switch occluded',
        description: 'Change occluded property for an active object',
        sequences: ['q', '/'],
        action: 'keydown',
        applicable: [DimensionType.DIM_2D, DimensionType.DIM_3D],
    },
    SWITCH_KEYFRAME: {  // 필요 X
        name: 'Switch keyframe',
        description: 'Change keyframe property for an active track',
        sequences: ['k'],
        action: 'keydown',
        applicable: [DimensionType.DIM_2D],
    },
    SWITCH_OUTSIDE: {   // 필요 X
        name: 'Switch outside',
        description: 'Change outside property for an active track',
        sequences: ['o'],
        action: 'keydown',
        applicable: [DimensionType.DIM_2D],
    },
    TO_BACKGROUND: {    // 필요 X
        name: 'To background',
        description: 'Put an active object "farther" from the user (decrease z axis value)',
        sequences: ['-', '_'],
        action: 'keydown',
        applicable: [DimensionType.DIM_2D],
    },
    TO_FOREGROUND: {    // 필요 X
        name: 'To foreground',
        description: 'Put an active object "closer" to the user (increase z axis value)',
        sequences: ['+', '='],
        action: 'keydown',
        applicable: [DimensionType.DIM_2D],
    },
    PROPAGATE_OBJECT: { // 필요 X
        name: 'Propagate object',
        description: 'Make a copy of the object on the following frames',
        sequences: ['ctrl+b'],
        action: 'keydown',
        applicable: [DimensionType.DIM_2D, DimensionType.DIM_3D],
    },
    NEXT_KEY_FRAME: {   // 필요 X
        name: 'Next keyframe',
        description: 'Go to the next keyframe of an active track',
        sequences: ['r'],
        action: 'keydown',
        applicable: [DimensionType.DIM_2D],
    },
    PREV_KEY_FRAME: {   // 필요 X
        name: 'Previous keyframe',
        description: 'Go to the previous keyframe of an active track',
        sequences: ['e'],
        action: 'keydown',
        applicable: [DimensionType.DIM_2D],
    },
    NEXT_ATTRIBUTE: {   // 필요 X
        name: 'Next attribute',
        description: 'Go to the next attribute',
        sequences: ['down'],
        action: 'keydown',
        applicable: [DimensionType.DIM_2D, DimensionType.DIM_3D],
    },
    PREVIOUS_ATTRIBUTE: {   // 필요 X
        name: 'Previous attribute',
        description: 'Go to the previous attribute',
        sequences: ['up'],
        action: 'keydown',
        applicable: [DimensionType.DIM_2D, DimensionType.DIM_3D],
    },
    NEXT_OBJECT: {  // 필요 X
        name: 'Next object',
        description: 'Go to the next object',
        sequences: ['tab'],
        action: 'keydown',
        applicable: [DimensionType.DIM_2D, DimensionType.DIM_3D],
    },
    PREVIOUS_OBJECT: {  // 필요 X
        name: 'Previous object',
        description: 'Go to the previous object',
        sequences: ['shift+tab'],
        action: 'keydown',
        applicable: [DimensionType.DIM_2D, DimensionType.DIM_3D],
    },
    OPEN_REVIEW_ISSUE: {    // 필요 X
        name: 'Open an issue',
        description: 'Create a new issues in the review workspace',
        sequences: ['n'],
        action: 'keydown',
        applicable: [DimensionType.DIM_2D],
    },
    SWITCH_MERGE_MODE: {    // 필요 X
        name: 'Merge mode',
        description: 'Activate or deactivate mode to merging shapes',
        sequences: ['m'],
        action: 'keydown',
        applicable: [DimensionType.DIM_2D],
    },
    SWITCH_SPLIT_MODE: {    // 필요 X
        name: 'Split mode',
        description: 'Activate or deactivate mode to splitting shapes',
        sequences: ['alt+m'],
        action: 'keydown',
        applicable: [DimensionType.DIM_2D],
    },
    SWITCH_GROUP_MODE: {    // 필요 X
        name: 'Group mode',
        description: 'Activate or deactivate mode to grouping shapes',
        sequences: ['g'],
        action: 'keydown',
        applicable: [DimensionType.DIM_2D, DimensionType.DIM_3D],
    },
    RESET_GROUP: {  // 필요 X
        name: 'Reset group',
        description: 'Reset group for selected shapes (in group mode)',
        sequences: ['shift+g'],
        action: 'keyup',
        applicable: [DimensionType.DIM_2D, DimensionType.DIM_3D],
    },
    // CLOCKWISE_ROTATION: {   // 필요 X
    //     name: 'Rotate clockwise',
    //     description: 'Change image angle (add  90 degrees)',
    //     sequences: ['ctrl+r'],
    //     action: 'keydown',
    //     applicable: [DimensionType.DIM_2D],
    // },
    // ANTICLOCKWISE_ROTATION: {   // 필요 X
    //     name: 'Rotate anticlockwise',
    //     description: 'Change image angle (subtract 90 degrees)',
    //     sequences: ['ctrl+shift+r'],
    //     action: 'keydown',
    //     applicable: [DimensionType.DIM_2D],
    // },
    SAVE_JOB: { // 필요 X
        name: 'Save the job',
        description: 'Send all changes of annotations to the server',
        sequences: ['ctrl+s'],
        action: 'keydown',
        applicable: [DimensionType.DIM_2D, DimensionType.DIM_3D],
    },
    DELETE_FRAME: { // 필요 X
        name: 'Delete frame',
        description: 'Delete frame',
        sequences: ['alt+del'],
        action: 'keydown',
        applicable: [DimensionType.DIM_2D, DimensionType.DIM_3D],
    },
    NEXT_FRAME: {   // 필요 X
        name: 'Next frame',
        description: 'Go to the next frame',
        sequences: ['f'],
        action: 'keydown',
        applicable: [DimensionType.DIM_2D, DimensionType.DIM_3D],
    },
    PREV_FRAME: {   // 필요 X
        name: 'Previous frame',
        description: 'Go to the previous frame',
        sequences: ['d'],
        action: 'keydown',
        applicable: [DimensionType.DIM_2D, DimensionType.DIM_3D],
    },
    FORWARD_FRAME: {    // 필요 X
        name: 'Forward frame',
        description: 'Go forward with a step',
        sequences: ['v'],
        action: 'keydown',
        applicable: [DimensionType.DIM_2D, DimensionType.DIM_3D],
    },
    BACKWARD_FRAME: {   // 필요 X
        name: 'Backward frame',
        description: 'Go backward with a step',
        sequences: ['c'],
        action: 'keydown',
        applicable: [DimensionType.DIM_2D, DimensionType.DIM_3D],
    },
    SEARCH_FORWARD: {   // 필요 X
        name: 'Search forward',
        description: 'Search the next frame that satisfies to the filters',
        sequences: ['right'],
        action: 'keydown',
        applicable: [DimensionType.DIM_2D, DimensionType.DIM_3D],
    },
    SEARCH_BACKWARD: {  // 필요 X
        name: 'Search backward',
        description: 'Search the previous frame that satisfies to the filters',
        sequences: ['left'],
        action: 'keydown',
        applicable: [DimensionType.DIM_2D, DimensionType.DIM_3D],
    },
    PLAY_PAUSE: {   // 필요 X
        name: 'Play/pause',
        description: 'Start/stop automatic changing frames',
        sequences: ['space'],
        action: 'keydown',
        applicable: [DimensionType.DIM_2D, DimensionType.DIM_3D],
    },
    FOCUS_INPUT_FRAME: {    // 필요 X
        name: 'Focus input frame',
        description: 'Focus on the element to change the current frame',
        sequences: ['`'],
        displayedSequences: ['~'],
        action: 'keydown',
        applicable: [DimensionType.DIM_2D, DimensionType.DIM_3D],
    },
    SWITCH_AUTOMATIC_BORDERING: {   // 필요 X
        name: 'Switch automatic bordering',
        description: 'Switch automatic bordering for polygons and polylines during drawing/editing',
        sequences: ['ctrl'],
        action: 'keydown',
        applicable: [DimensionType.DIM_2D, DimensionType.DIM_3D],
    },
    SWITCH_TOOLS_BLOCKER_STATE: {   // 필요 X
        name: 'Switch algorithm blocker',
        description: 'Postpone running the algorithm for interaction tools',
        sequences: ['ctrl'],
        action: 'keydown',
        applicable: [DimensionType.DIM_2D],
    },
    CHANGE_OBJECT_COLOR: {  // 필요 X
        name: 'Change color',
        description: 'Set the next color for an activated shape',
        sequences: ['enter'],
        action: 'keydown',
        applicable: [DimensionType.DIM_2D, DimensionType.DIM_3D],
    },
    SWITCH_LABEL: { // 필요 X
        name: 'Switch label',
        description: 'Changes a label for an activated object or for the next drawn object if no objects are activated',
        sequences: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0'].map((val: string): string => `ctrl+${val}`),
        action: 'keydown',
        applicable: [DimensionType.DIM_2D, DimensionType.DIM_3D],
    },
    TILT_UP: {  // 필요 X
        name: 'Camera Roll Angle Up',
        description: 'Increases camera roll angle',
        sequences: ['shift+arrowup'],
        action: 'keydown',
        applicable: [DimensionType.DIM_3D],
    },
    TILT_DOWN: {    // 필요 X
        name: 'Camera Roll Angle Down',
        description: 'Decreases camera roll angle',
        sequences: ['shift+arrowdown'],
        action: 'keydown',
        applicable: [DimensionType.DIM_3D],
    },
    ROTATE_LEFT: {  // 필요 X
        name: 'Camera Pitch Angle Left',
        description: 'Decreases camera pitch angle',
        sequences: ['shift+arrowleft'],
        action: 'keydown',
        applicable: [DimensionType.DIM_3D],
    },
    ROTATE_RIGHT: { // 필요 X
        name: 'Camera Pitch Angle Right',
        description: 'Increases camera pitch angle',
        sequences: ['shift+arrowright'],
        action: 'keydown',
        applicable: [DimensionType.DIM_3D],
    },
    MOVE_UP: {  // 필요 X
        name: 'Camera Move Up',
        description: 'Move the camera up',
        sequences: ['alt+u'],
        action: 'keydown',
        applicable: [DimensionType.DIM_3D],
    },
    MOVE_DOWN: {    // 필요 X
        name: 'Camera Move Down',
        description: 'Move the camera down',
        sequences: ['alt+o'],
        action: 'keydown',
        applicable: [DimensionType.DIM_3D],
    },
    MOVE_LEFT: {    // 필요 X
        name: 'Camera Move Left',
        description: 'Move the camera left',
        sequences: ['alt+j'],
        action: 'keydown',
        applicable: [DimensionType.DIM_3D],
    },
    MOVE_RIGHT: {   // 필요 X
        name: 'Camera Move Right',
        description: 'Move the camera right',
        sequences: ['alt+l'],
        action: 'keydown',
        applicable: [DimensionType.DIM_3D],
    },
    ZOOM_IN: {  // 필요 X
        name: 'Camera Zoom In',
        description: 'Performs zoom in',
        sequences: ['alt+i'],
        action: 'keydown',
        applicable: [DimensionType.DIM_3D],
    },
    ZOOM_OUT: { // 필요 X
        name: 'Camera Zoom Out',
        description: 'Performs zoom out',
        sequences: ['alt+k'],
        action: 'keydown',
        applicable: [DimensionType.DIM_3D],
    },
    CANCEL_SKELETON_EDGE: { // 필요 X
        name: 'Cancel skeleton drawing',
        description: 'Interrupts drawing a new skeleton edge',
        sequences: ['esc'],
        action: 'keydown',
        applicable: [DimensionType.DIM_3D],
    },
} as any) as KeyMap;
export const ImageClsfcSmryKeyMap = ({} as any) as KeyMap;
export const AudioClsfcSmryKeyMap = ({} as any) as KeyMap;
export const VideoClsfcSmryKeyMap = ({} as any) as KeyMap;
export const TextClsfcSmryKeyMap = ({} as any) as KeyMap;
