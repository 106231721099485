// @ts-nocheck
import {useDispatch, useSelector} from "react-redux";
import {useEffect, useState} from "react";
import {GetMessageIntl} from "../../../../../../util/message";
import {updateSideBarTab} from "../../../../../../store/label/action";
import {SubmitButton} from "../../../cmmn/components/taskSideBar/SubmitButton";
import {useIntl} from "react-intl";

interface Props {
    widthPercent: number;
    onHandleSubmit(): void;
    clsfcSmryForm: any;
}
export const SubmitButtonContainer = (props: Props) => {
    const {widthPercent, onHandleSubmit, clsfcSmryForm} = props;
    const {
        Label: {
            Cmmn: {
                canvas: {sideBarTab: activeTab},
                module: {
                    instance: moduleInstance,
                    type: moduleType,
                    labels
                }
            }
        },
        Review: {
            Cmmn: {
                annotations:{
                    data: {
                        classes: reviewStates
                    }
                }
            }
        }
    } = useSelector((state) => state);
    const intl = useIntl();
    const [text, setText] = useState('');
    const [type, setType] = useState('button');
    const [onClick, setOnClick] = useState();
    useEffect(() => {
        if (activeTab === labels.length) {
            setText(GetMessageIntl(intl, 'ui.U00063'));   // 제출
            setType('submit');
            setOnClick(() => {
                // 제출 버튼 클릭 전, modified Error 초기화
                if(clsfcSmryForm.formState?.errors && clsfcSmryForm.formState?.errors?.modified){
                    delete clsfcSmryForm.formState.errors.modified
                }
            })
        } else {
            setText(GetMessageIntl(intl, 'ui.U00008'));   // 다음
            setType('button');
            setOnClick(() => () => goNextStage())
        }
    }, [activeTab, reviewStates])


    const dispatch = useDispatch();
    const goNextStage = () => {
        // 다음에 오류가 있는 곳이 존재하면, 그 탭으로 이동.
        let targetTabIdx = activeTab + 1;
        const modified = clsfcSmryForm.getValues()?.modified;
        if(modified){
            for (let i = activeTab; i < modified.length; i++) {
                if (!modified[i]) {
                    dispatch(updateSideBarTab(i + 1));
                    return;
                }
            }
        }
        // 리뷰일 때.
        if(reviewStates){
            for (let i = activeTab; i < reviewStates.length; i++) {
                if (reviewStates[i].reject) {
                    dispatch(updateSideBarTab(i + 1));
                    return;
                }
            }
        }

        dispatch(updateSideBarTab(targetTabIdx));
    }

    return(
        <>
            <SubmitButton type={type} widthPercent={widthPercent} text={text} onClick={onClick}/>
        </>
    )
}
