import {PopoverBody} from "reactstrap";
import React from "react";
import {GetMessage} from "../../../../../../../../util/message";
import Select from "react-select";
import {defaultSelectStyle} from "../../../../../../../../components/constants/select";
import {CustomSlider} from 'components/common/CustomSlider';
import {useDispatch, useSelector} from "react-redux";

import {
    switchGrid,
    changeGridColor,
    changeGridOpacity,
    changeBrightnessLevel,
    changeContrastLevel,
    changeSaturationLevel,
    changeGridSize,
} from 'store/settings/action';
import {CombinedState, GridColor, PlayerSettingsState} from "store/label";
import {clamp} from "../../../utils/math";

export function EditImagePopoverComponent() {
    const {
        brightnessLevel,
        contrastLevel,
        saturationLevel,
        gridOpacity,
        gridColor,
        gridSize,
        grid: gridEnabled,
    } = useSelector((state: CombinedState): PlayerSettingsState => state.settings.player);

    return (
        <div className={"wd-200px"}>
            <EditGridContent
                gridEnabled={gridEnabled}
                gridColor={gridColor}
                gridSize={gridSize}
            />
            <EditImageContent
                brightnessLevel={brightnessLevel}
                contrastLevel={contrastLevel}
            />
        </div>
    )
}


function ChangeGridColor({disabled, color}){
    const dispatch = useDispatch();
    const selectOption = [
        { label: "white", value: "White" },
        { label: "black", value: "Black" },
        { label: "red", value: "Red" },
        { label: "blue", value: "Blue" },
        { label: "green", value: "Green" },
    ];
    const defaultValue = selectOption.find(o => o.label === color);

    return(
        <>
            <label className="fw-semibold">{GetMessage("ui.U00139")}</label>
            <Select
                options={selectOption}
                defaultValue={defaultValue}
                styles={defaultSelectStyle}
                className="mb-0"
                isSearchable={false}
                isDisabled={disabled}
                onChange={(newValue: { label: string; value: string; }) => {
                    const color = newValue.label as GridColor;
                    dispatch(changeGridColor(color));
                }}
            />
        </>
    )
}

function ChangeGridSize({disabled, size}){
    const dispatch = useDispatch();
    const minGridSize = 5;
    const maxGridSize = 500;
    return(
        <>
            <label className="mt-3 mb-3">
                {GetMessage("ui.U00140")}
            </label>
            <div id="gridSizeRange">
                <CustomSlider
                    min={0}
                    max={500}
                    value={size}
                    onChange={(value: number | undefined | null | string): void => {
                        if (typeof value !== 'undefined' && value !== null) {
                            const converted = Math.floor(clamp(+value, minGridSize, maxGridSize));
                            dispatch(changeGridSize(converted));
                        }
                    }}
                    disabled={disabled}
                    className={"m-0"}
                />
            </div>
            <div className="d-flex align-items-center justify-content-end tx-12 text-coco-gray-200 mt-2">
                <div>Size : {size}</div>
            </div>
        </>
    )
}

interface EditGridContentProps{
    gridEnabled: boolean
    gridColor: string
    gridSize: number
}
function EditGridContent(props: EditGridContentProps){
    const dispatch = useDispatch();
    const { gridEnabled, gridColor, gridSize } = props;
    return(
        <>
            <PopoverBody>
                <div className="d-flex align-items-center justify-content-between text-white">
                    <div className="fw-semibold tx-16">
                        {GetMessage("ui.U00138")}
                    </div>
                    <div className="form-check form-switch form-switch-coco"
                         style={{paddingRight: "0px"}}>
                        <input className="form-check-input" type="checkbox" role="switch" id="gridSwitch"
                               style={{marginRight: "0px"}}
                               checked={gridEnabled}
                               onChange={(event) => {
                                   dispatch(switchGrid(event.target.checked));
                               }}
                        >
                        </input>
                        <label className="form-check-label" htmlFor="gridSwitch"></label>
                    </div>
                </div>
                <hr className="my-2 text-white" />
                <div className="text-white tx-14">
                    <ChangeGridColor disabled={!gridEnabled} color={gridColor}/>
                    <ChangeGridSize disabled={!gridEnabled} size={gridSize}/>
                </div>
            </PopoverBody>
        </>
    )
}


function ChangeBrightnessLevel(props: { min:number, max:number, value:number }){
    const dispatch = useDispatch();
    const { min, max, value } = props;
    return(
        <div className="mb-3">
            <label className="mb-2">
                {GetMessage("ui.U00142")}
            </label>
            <CustomSlider
                min={min}
                max={max}
                value={value}
                onChange={(value: number | [number, number]): void => {
                    dispatch(changeBrightnessLevel(value as number));
                }}
                className={"m-0"}
            />
            <div
                className="d-flex align-items-center justify-content-between tx-12 text-coco-gray-200 mt-2">
                <div>Dark</div>
                <div>Bright</div>
            </div>
        </div>
    )
}
function ChangeContrastLevel(props: { min:number, max:number, value: number }){
    const dispatch = useDispatch();
    const { min, max, value } = props;
    return(
        <div className="mb-2">
            <label className="mb-2">
                {GetMessage("ui.U00143")}
            </label>
            <div id="contrastRange">
                <CustomSlider
                    min={min}
                    max={max}
                    value={value}
                    onChange={(value: number | [number, number]): void => {
                        dispatch(changeContrastLevel(value as number));
                    }}
                    className={"m-0"}
                />
            </div>
            <div className="d-flex align-items-center justify-content-between tx-12 text-coco-gray-200 mt-2">
                <div>Low</div>
                <div>High</div>
            </div>
        </div>
    )
}

interface EditIamgeContentProps{
    brightnessLevel:number,
    contrastLevel:number
}
function EditImageContent(props: EditIamgeContentProps){
    const { brightnessLevel, contrastLevel } = props;
    const [min, max] = [5, 200]
    return(
        <>
            <PopoverBody className="text-white tx-14">
                <div className="fw-semibold tx-16">
                    {GetMessage("ui.U00141")}
                </div>
                <hr className="my-2 text-white" />
                <ChangeBrightnessLevel min={min} max={max} value={brightnessLevel}/>
                <ChangeContrastLevel min={min} max={max} value={contrastLevel}/>
            </PopoverBody>
        </>
    )
}
