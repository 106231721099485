import React, {useEffect, useMemo, useRef, useState} from 'react';
import {Card, CardBody, CardHeader, Col, Input, Label, Row} from "reactstrap";
import SimpleBar from "simplebar-react";
import {FilePond} from "react-filepond";
import {CKEditor} from "@ckeditor/ckeditor5-react";
import Editor from "ckeditor5-custom-build";
import customUploadAdapter from "../../../../../../../../../components/features/CustomUploadAdapter";
import {useDispatch, useSelector} from "react-redux";
import {
    setManageWorkflowLabelActiveData, setManageWorkflowLabelFiles,
    setManageWorkflowLabelInactiveData, switchManageWorkflowLabelRegistDataRefreshFlag
} from "../../../../../../../../../store/manage/project/workflow/label/action";
import {ModuleService} from "../../../../../../../../../apis/api/ModuleService";
import {useIntl} from "react-intl";
import {GetMessageIntl} from "../../../../../../../../../util/message";
import {confirm} from "../../../../../../../../../util/AlertUtil";

function LabelRegistDataCheckboxForm(props) {
    const intl = useIntl();
    const dispatch = useDispatch();
    const {activeData, inactiveData, selectedId, dataConfig, errorData, allFiles, refreshFlag, selectionNode} = useSelector((state) => ({
        activeData: state.ManageWorkflowLabel.activeData,
        inactiveData: state.ManageWorkflowLabel.inactiveData,
        selectedId: state.ManageWorkflowLabel.selectedId,
        dataConfig: state.ManageWorkflowLabel.dataConfig,
        errorData: state.ManageWorkflowLabel.errorData,
        allFiles: state.ManageWorkflowLabel.files,
        refreshFlag: state.ManageWorkflowLabel.refreshFlag,
        selectionNode: state.ManageWorkflowModule.selectionNode,
    }));
    const [title, setTitle] = useState("");
    const [attachFiles, setAttachFiles] = useState([]);

    // FilePond
    const [files, setFiles] = useState([]);
    const [cpFiles, setCpFiles] = useState([]);
    const pondRef = useRef();

    // CKEditor
    const ckEditorRef = useRef();
    const [content, setContent] = useState("");

    function uploadPlugin (editor){ // (3)
        const findData = dataConfig?.find(item => item.id === selectedId);
        editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
            return customUploadAdapter(loader, findData.subId);
        }
    }

    const updateActiveDataById = (id, newTitle, newContent) => {
        const updatedData = activeData.map((item) => item.id === id ? {...item, title: newTitle, body: { ...item.body, content: newContent }} : item )
        dispatch(setManageWorkflowLabelActiveData(updatedData))
    };

    const updateInactiveDataById = (id, newTitle, newContent) => {
        const updatedData = inactiveData.map((item) => item.id === id ? {...item, title: newTitle, body: { ...item.body, content: newContent }} : item )
        dispatch(setManageWorkflowLabelInactiveData(updatedData))
    };

    useEffect(() => {
        const checkDuplicates = async (files) => {
            let updatedFiles = [...files];

            for (let i = updatedFiles.length - 1; i >= 0; i--) {
                const newFile = updatedFiles[i];
                const sameFileIndex = updatedFiles.slice(0, i).findIndex(f => f.file.name === newFile.file.name);

                if (sameFileIndex !== -1) {
                    const replace = await new Promise(resolve => { // 동기처리
                        confirm("workflow.dataConfig.duplicatedFileReplace"
                            , "workflow.dataConfig.duplicatedFileReplaceMsg"
                            , () => { // 기존 파일 대체
                                resolve(true);
                            }
                            , () => { // 기존 파일 유지
                                resolve(false);
                            }
                            ,
                            {
                                filename : newFile.filename
                            }
                            , intl);
                    });

                    if (replace) {
                        updatedFiles = [...updatedFiles.slice(0, sameFileIndex), ...updatedFiles.slice(sameFileIndex + 1)];
                    } else {
                        updatedFiles = [...updatedFiles.slice(0, i), ...updatedFiles.slice(i + 1)];
                    }
                }
            }

            return updatedFiles;
        }

        checkDuplicates(cpFiles).then(updatedFiles => {
            setFiles(updatedFiles);
            dispatch(setManageWorkflowLabelFiles(selectedId, updatedFiles));
        });
    }, [cpFiles]);

    useEffect(()=> {
        const totalData = [...activeData, ...inactiveData];
        const findData = totalData.find((target) => target.id === selectedId);
        if(findData){
            if(findData.useYn === "Y"){
                updateActiveDataById(selectedId, title, content)
            }else{
                updateInactiveDataById(selectedId, title, content)
            }
        }
    }, [title, content])

    useMemo(()=>{
        // DB 저장된 정보 세팅
        const totalData = [...activeData, ...inactiveData];
        const findData = totalData.find((target) => target.id === selectedId);
        if(findData){
            setTitle(findData.title);
            setContent(findData.body.content);

            ModuleService.getManageLabelRegistDataFiles({
                "moduleId": selectionNode.id,
                "dataConfigTargetId": selectedId
            }).then(res => {
                if(findData.useYn === "Y"){
                    const updatedData = activeData.map((item) => item.id === selectedId ? {...item, body : { ...item.body, attachFiles : res.data.data}} : item);
                    dispatch(setManageWorkflowLabelActiveData(updatedData))
                }else{
                    const updatedData = inactiveData.map((item) => item.id === selectedId ? {...item, body : { ...item.body, attachFiles : res.data.data}} : item);
                    dispatch(setManageWorkflowLabelInactiveData(updatedData))
                }

                if(res.data.data.length > 0){
                    setAttachFiles(res.data.data)
                }else{
                    setAttachFiles(findData.body.attachFiles)
                }
            })
        }

        if(allFiles[selectedId]){
            setCpFiles(allFiles[selectedId])
        }else{
            setFiles([])
        }
    }, [selectedId, refreshFlag])

    const addDeleteFilesToActiveData = (id, data) => {
        const updatedData = activeData.map((item) => item.id === id ? data : item)
        dispatch(setManageWorkflowLabelActiveData(updatedData))
    }

    const addDeleteFilesToInactiveData = (id, data) => {
        const updatedData = inactiveData.map((item) => item.id === id ? data : item)
        dispatch(setManageWorkflowLabelInactiveData(updatedData))
    }

    const handleFileDelete = (e, downloadUrl, realFilename) => {
        console.log("handleFileDelete ->", e)
        let deleteFile = {
            "downloadUrl" : downloadUrl,
            "realFilename" : realFilename
        }
        const findData = dataConfig.find((target) => target.id === selectedId);
        const attachFiles = findData.body.attachFiles;
        const filteredFiles = attachFiles.filter(item => item.realFilename !== realFilename && item.downloadUrl !== downloadUrl);
        findData.body.attachFiles = filteredFiles
        if(findData.deleteFiles){
            findData.deleteFiles.push(deleteFile);
        }else{
            findData.deleteFiles = [];
            findData.deleteFiles.push(deleteFile);
        }

        if(findData.useYn === "Y"){
            addDeleteFilesToActiveData(findData.id, findData)
        }else{
            addDeleteFilesToInactiveData(findData.id, findData)
        }
        dispatch(switchManageWorkflowLabelRegistDataRefreshFlag())
    }

    return (
        <Card className="shadow-none mb-0">
            <CardHeader className="border-0 d-flex align-items-center tx-16 lh-18 pb-0 text-coco-gray-700 fw-bold">
                <i className="mdi mdi-file-sign mr-12"></i>{GetMessageIntl(intl, "workflow.dataConfig.checkbox")}
            </CardHeader>
            <CardBody className="p-0">
                <SimpleBar className="table-responsive" style={{height: "calc(100vh - 250px)"}}>
                    <Col style={{padding:'1rem 1rem 0 1rem'}}>
                        <Row className="mb-8">
                            <div className={"d-flex justify-content-start align-items-center"}>
                                <div className="text-coco-gray-700 fw-500 tx-16 wd-20p">
                                    {GetMessageIntl(intl, "workflow.dataConfig.title")}
                                </div>
                                <div className={"wd-80p"}>
                                    <div className={errorData.dataItems && errorData.dataItems.find((item)=> item.id === selectedId && item.error == 'title') ? "validation-error-bottom":""}>
                                        <input type="text" value={title} style={{border: '0px', borderRadius:'0', fontSize: '16px',color:'var(--coco-gray-400)!important'}} maxLength={50} className="form-control form-control-sm fw-500 text-coco-gray-400-f pd-x-0-f" placeholder="제목을 입력하세요."
                                               onKeyDown={(e) => {
                                                   if (e.key === 'Enter') {
                                                       e.preventDefault()
                                                   }
                                               }}
                                               onChange={(e)=>{
                                                    setTitle(e.target.value)
                                               }}
                                        />
                                        {
                                            errorData.dataItems && errorData.dataItems.map((item) => {
                                                if(item.id === selectedId && item.error == 'title'){
                                                    return (
                                                        <p className="validation-error-message mb-0">{GetMessageIntl(intl, "validation.required")}</p>
                                                    )
                                                }
                                            })
                                        }
                                    </div>
                                </div>
                            </div>
                        </Row>
                        <Row>
                            <div className="d-flex">
                                <div className="text-coco-gray-700 fw-500 tx-16 wd-20p">
                                    {GetMessageIntl(intl, "workflow.dataConfig.attachFile")}
                                </div>
                                <div className="wd-80p">
                                    <div className="d-flex justify-content-between text-coco-gray-400 mb-8">
                                        <div>

                                        </div>
                                        <div>
                                        </div>
                                        <div>
                                            {files.length}건의 파일
                                        </div>
                                    </div>
                                    <div className="filepond-div">
                                        <SimpleBar style={{maxHeight: "120px"}}>
                                            <FilePond
                                                ref={pondRef}
                                                files={files}
                                                onupdatefiles={setCpFiles}
                                                server={{}}
                                                chunkUploads={true}
                                                allowMultiple={true}
                                                instantUpload={false}
                                                name="files"
                                                labelIdle={GetMessageIntl(intl, "workflow.dataConfig.filepondPlaceholder")}
                                                credits={false}
                                            />
                                        </SimpleBar>
                                    </div>
                                    <div>
                                        {
                                            attachFiles.map((file, idx) => {
                                                const {downloadUrl, realFilename} = file
                                                const findData = dataConfig.find(item => item.id === selectedId)
                                                let delStyle = undefined;
                                                if(findData){
                                                    if(findData.deleteFiles){
                                                        findData.deleteFiles.map((item) => {
                                                            if(downloadUrl === item.downloadUrl && realFilename === item.realFilename){
                                                                delStyle = {textDecorationLine : "line-through"};
                                                            }
                                                        })
                                                    }
                                                }
                                                return(
                                                    <Row className="mb-2" key={idx}>
                                                        <div className={"d-flex justify-content-between"}>
                                                            <div className={"d-flex gap-2"} style={delStyle}>
                                                                <span className="text-coco-gray-400" onClick={(e)=>{
                                                                    handleFileDelete(e, downloadUrl, realFilename)
                                                                }}><i className="ri-close-circle-line"></i></span>
                                                                <span className="text-coco-gray-700">{file.realFilename}</span>
                                                            </div>
                                                            <div>
                                                            </div>
                                                        </div>
                                                    </Row>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            </div>
                        </Row>
                        <Row className="justify-content-center">
                            <div className="dataConfigDiv-editor registDataEditor">
                                <CKEditor
                                    ref={ckEditorRef}
                                    editor={Editor}
                                    data={content}
                                    config={{
                                        width:"99%",
                                        // plugins: [ Preview ],
                                        extraPlugins: [uploadPlugin],
                                        removePlugins : ['specialCharacters','MediaEmbedToolbar'],
                                        toolbar:{
                                            removeItems: [ 'specialCharacters']
                                        },
                                    }}
                                    onReady={editor => {
                                        // You can store the "editor" and use when it is needed.
                                        //console.log('Editor is ready to use!', editor);
                                        ckEditorRef.current = editor
                                        editor.setData(content)

                                    }}
                                    onChange={(event, editor) => {
                                        setContent(editor.getData())
                                        //console.log({event, editor, data});
                                    }}
                                    onBlur={(event, editor) => {
                                        //console.log('Blur.', editor);
                                    }}
                                    onFocus={(event, editor) => {
                                        //console.log('Focus.', editor);
                                    }}
                                />
                            </div>
                        </Row>
                    </Col>
                </SimpleBar>
            </CardBody>
        </Card>
    );
}

export default LabelRegistDataCheckboxForm;