import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {
    Button,
    Col,
    Input,
    Label,
    ListGroup,
    ListGroupItem,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Nav,
    NavItem,
    NavLink,
    Offcanvas,
    OffcanvasBody,
    Row,
    Spinner,
    TabContent,
    TabPane
} from "reactstrap";
import {useDropzone} from 'react-dropzone'
import SimpleBar from 'simplebar-react';
import {GetMessageIntl} from "../../../../../../util/message";
import classnames from "classnames";
import {useDispatch, useSelector} from "react-redux";
import {
    manage_project_workflow_module_deleteNodeId,
    manage_project_workflow_module_setIsDatasetOffcanvas,
    manage_project_workflow_module_setSelectionNode,
    manage_project_workflow_module_setSelectionNodeNm,
    manage_project_workflow_module_updateModuleId
} from "../../../../../../store/manage/project/workflow/module/action";
import {Controller, useForm} from "react-hook-form";
import {NumericFormat} from "react-number-format";
import {defaultSelectStyle, formatGroupLabelNoCount} from "../../../../../../components/constants/select";
import Select from "react-select";
import {ManagerWorkflowService} from "../../../../../../apis/api/ManagerWorkflowService";
import {CommonService} from "../../../../../../apis/api/CmmnService";
import CommonUtil from "../../../../../../util/CommonUtil";
import {useIntl} from "react-intl";
import {useStoreApi} from "reactflow";
import {ModuleService} from "../../../../../../apis/api/ModuleService";
import {DatasetService} from "../../../../../../apis/api/DatasetService";
import {alertError, alertInfo, alertSuccess, confirm} from "../../../../../../util/AlertUtil";
import JsFileDownloader from "js-file-downloader";
import {ImageLabelService} from "../../../../../../apis/api/ImageLabelService";
import {TextLabelService} from "../../../../../../apis/api/TextLabelService";
import {AudioLabelService} from "../../../../../../apis/api/AudioLabelService";
import {DATA_TYPE} from "../../../../../user/label/annotation/image/const";
import {VideoLabelService} from "../../../../../../apis/api/VideoLabelService";
import ReactPlayer from "react-player";

export default (prop) => {
    const intl = useIntl();
    const store = useStoreApi();
    const dispatch = useDispatch();
    const prjctId = sessionStorage.getItem("prjctId");
    const wrkflowId = sessionStorage.getItem("wrkflowId");
    const [files, setFiles] = useState([]);
    const [fileRejections, setFileRejections] = useState([]);
    const [dropzoneFileCnt, setDropzoneFileCnt] = useState(0);
    const [customActiveTab, setcustomActiveTab] = useState("1");
    const [uploadButtonStatus, setUploadButtonStatus] = useState("0"); // 0이면 신규또는 데이터셋 마스터가 없는 경우, 1이면 데이터셋 마스터가 있지만 upload하지 않은 경우 , 2이면 upload까지 완료한 경우
    const {isDatasetOffCanvas, selectionNode} = useSelector((state)=>({
        isDatasetOffCanvas: state.ManageWorkflowModule.isDatasetOffCanvas,
        selectionNode: state.ManageWorkflowModule.selectionNode
    }));
    const [initDatasetModuleConfig, setInitDatasetModuleConfig] = useState(null);
    const [initDatasetConfig, setInitDatasetConfig] = useState(null);
    const [initMetaConfig, setInitMetaConfig] = useState(null);
    const [changeMetaInfo, setChangeMetaInfo] = useState(false);
    const [nodeData, setNodeData] = useState(null);
    const [isDisabledData, setIsDisabledData] = useState(false);
    const [initDataSettingComplete, setInitDataSettingComplete] = useState(false);
    const { control, register, setValue, getValues, trigger, reset, resetField, watch, formState, handleSubmit } = useForm({criteriaMode: "all",
        defaultValues:{
            storageKeyId:'',
            getMethodTy:'00',
            dataAddTy:'00',
            localDataUnitTy:'00',
            copyObject:'00',
            uploadUnit:'00',
            accessKey:'',
            bucketName:'',
            datasetTy:'',
            fileNamePttrn:'',
            filePathPttrn:'',
            fileSizeLimit:'',
            folderName:'',
            secretKey:'',
            uploadFileCnt: 0,
    }});
    const { control:controlMeta, register:registerMeta, setValue:setValueMeta, getValues:getValuesMeta, reset:resetMeta, resetField:resetFieldMeta, watch:watchMeta, formState: { errors:errorsMeta }, handleSubmit: handleSubmitMeta } = useForm({criteriaMode: "all",
        defaultValues:{

        }});

    const [datasetTyOptions, setDatasetTyOptions] = useState([]);
    const [storageKeyOptions, setStorageKeyOptions] = useState([]);
    const [targetFileList, setTargetFileList] = useState([]);
    const [targetFileImage, setTargetFileImage] = useState(null);
    const [targetFileAudio, setTargetFileAudio] = useState(null);
    const [targetFileText, setTargetFileText] = useState(null);
    const [targetFileVideo, setTargetFileVideo] = useState(null);
    const [targetFileNm, setTargetFileNm] = useState(null);
    const [targetFileModal, setTargetFileModal] = useState(false);
    const [targetFileAudioModal, setTargetFileAudioModal] = useState(false);
    const [targetFileTextModal, setTargetFileTextModal] = useState(false);
    const [targetFileVideoModal, setTargetVideoModal] = useState(false);
    const [alertLocalFilesModal, setAlertLocalFilesModal] = useState(false);
    const [alertFileRejectionModal, setAlertFileRejectionModal] = useState(false);
    const [metaPreviewModal, setMetaPreviewModal] = useState(false);
    const [metaPreviewData, setMetaPreviewData] = useState(null);
    const [targetConfig, setTargetConfig] = useState(null);
    const [selectedDatasetOption, setSelectedDatasetOption] = useState(null);
    const [copyDatasetDisabled, setCopyDatasetDisabled]= useState(false);
    const [copyConfigDisabled, setCopyConfigDisabled]= useState(false);
    const [dataAddTyDisabled, setDataAddTyDisabled]= useState(false);
    const watchAllFields = watch();
    const watchAllMeta = watchMeta();

    const videoRef = useRef();
    const [horizon, setHorizon] = useState(null);

    const compareDatasetFields = ['accessKey'
        , 'bucketName'
        , 'copyObject'
        , 'datasetTy'
        , 'fileNamePttrn'
        , 'filePathPttrn'
        , 'fileSizeLimit'
        , 'folderName'
        , 'secretKey'
        , 'storageKeyId'
    ];

    useEffect(()=>{
        /*if(selectionNode&&selectionNode.type=='dataset'&&initDataSettingComplete) {
            if(initDatasetModuleConfig){
                //

            }else{
                // 없었으면 이거는 오류 인것인가..

            }
            if(initDatasetConfig){
                //기존 설정 된 값이 있었을 경우에는 비교 해준다.
                for(const fields of compareFields){
                    if(initDatasetConfig[fields] != getValues(fields)){
                        console.log('오홍 뭔가 바뀜.. 나가거나 offcanvas를 닫거나 다른 노드 클릭할 떄 알려줘야함..', fields, initDatasetConfig[fields], watchAllFields[fields]);
                        return ;
                    }
                }
            }else{
                // 기존 설정된 값이 없을 경우에는 watchAllFields에 값이 들어가 있으면 변경된 값이 있다고 알려줘야됨!!
                for(const fields of compareDatasetFields){
                    if(watchAllFields[fields] !='' && getValues(fields)!='00' ){
                        console.log('오홍 뭔가 새로운게 들어온듯?', fields, watchAllFields[fields]);
                        return;
                    }
                }

            }
        }*/
    },[watchAllFields]);

    useEffect(()=>{
        if(isDatasetOffCanvas){
            workflowUseDatasetOption();
            getStorageVendorOption();
            // 캔버스 열면 일단 리셋..
            reset();
            resetMeta();
            setInitDatasetConfig(null);
            setInitMetaConfig(null);
            setTargetFileList([]); // 로컬 업로드인 경우의 파일 리스트
            setChangeMetaInfo(false);
            setInitDataSettingComplete(false);
            setcustomActiveTab("1");
            setFiles([]);
            setDropzoneFileCnt(0);
        }
    }, [isDatasetOffCanvas]);

    useEffect(()=>{
        if(selectionNode&&selectionNode.type == 'dataset'){
            reset();
            setcustomActiveTab("1");
            setIsDisabledData(false);
            setInitDataSettingComplete(false);
            setFiles([]);
            setDropzoneFileCnt(0);
            setCopyDatasetDisabled(false);
            setCopyConfigDisabled(false);
            setDataAddTyDisabled(false);
            setTargetFileList([]);

            let initModuleConfig = null;
            ModuleService.getManageModuleItem({moduleId: selectionNode.data.moduleId}).then((moduleResult) => {
                if (moduleResult.data.data) {
                    let resultData = moduleResult.data.data;
                    console.log('resultData', resultData);
                    if(resultData.moduleConfig){
                        initModuleConfig = JSON.parse(resultData.moduleConfig);
                        if(resultData.datasetConfig){
                            initModuleConfig = {...initModuleConfig, datasetConfig: resultData.datasetConfig};
                        }

                        setValue('datasetId', initModuleConfig.datasetId);
                        setValue('moduleNm', resultData.moduleNm);
                    }

                    let targetNode = resultData.moduleConfig?{...resultData, moduleConfig: JSON.parse(resultData.moduleConfig)}: resultData;
                    setNodeData(targetNode);

                    if(targetNode.datasetConfig){
                        if(targetNode.datasetConfig.sttusCd=='01' || targetNode.datasetConfig.sttusCd=='00'){
                            setIsDisabledData(true);
                        }
                        let datasetConfig = targetNode.datasetConfig;
                        let moduleConfig = targetNode.moduleConfig;
                        if(moduleConfig && moduleConfig.wrkflowId){
                            setValue('datasetTy', `${moduleConfig.wrkflowId}_${moduleConfig.datasetId}`);
                        }else{
                            setValue('datasetTy', 'new');
                        }
                        setValue('dataAddTy', datasetConfig.dataAddTy);

                        setValueMeta('datasetId', datasetConfig.datasetId);
                        let dataConfig = JSON.parse(datasetConfig.dataConfig);
                        setInitDatasetConfig(dataConfig);
                        if(datasetConfig.dataAddTy == '00'){
                            for(const [key, value] of Object.entries(dataConfig)){
                                resetField(key);
                                if(key === 'copyObject'){
                                    setValue(key, (value?"00":"01"));
                                }else {
                                    setValue(key, (value ? value : ""));
                                }
                            };
                            console.log('초기  InitDatsetConfig', dataConfig);
                        }else{
                            //파일 업로드된 리스트 조회
                            if(targetNode.datasetConfig.sttusCd=='00'){
                                DatasetService.getFileList({datasetId: datasetConfig.datasetId}).then((result)=>{
                                    setTargetFileList(result.data.data);
                                });
                            }
                        }

                        if(datasetConfig.metaInfoMapngCn){
                            let metaInfoMapngCn = JSON.parse(datasetConfig.metaInfoMapngCn);
                            let changeNodeData = targetNode;

                            setNodeData({...changeNodeData, datasetConfig: {
                                    ...changeNodeData.datasetConfig,
                                    metaInfoMapngCn: metaInfoMapngCn
                                }
                            })
                        }

                    }
                }else{
                    // 실패
                }
                console.log('initModuleConfig', initModuleConfig);
                setInitDatasetModuleConfig(initModuleConfig);
                setInitDataSettingComplete(true);
            })
        }else{
            setNodeData(null);
            setInitDataSettingComplete(true);
        }
    }, [selectionNode]);

    /**
     * 데이터셋 등록중 동일 데이터셋인 경우
     * @param param
     * @returns {Promise<void>}
     */
    const updataCopyDatasetItem = async(param) =>{
        //console.log('updataCopyDatasetItem param', param);
        const result = await updateModuleItem(param);
        if(result.data.data){
            alertSuccess('alert.saveSuccess','alert.saveSuccessMsg',null, null, intl);
            param.datasetId = JSON.parse(param.moduleConfig).datasetId;
            //setNodeData({...nodeData, moduleNm:param.moduleNm, moduleConfig: JSON.parse(param.moduleConfig)});

            // 동일 데이터셋인 경우에는 데이터셋아이디가 변경 되므로 0202T에서 변경을 해줘야 한다.
            const state = store.getState();
            let updateTargetEdges = state.edges.filter((edge)=> edge.source === param.moduleId);

            updateTargetEdges.forEach((edge)=>{
                updateLabelTargetModule(edge.target, param.datasetId, param.beforeDatasetId, 'U');
            })

            getDatasetItem(param, true);
        }
    }

    const updateLabelTargetModule = async (labeModuleId, datasetId, beforeDatasetId, mode) => {
        await ManagerWorkflowService.updateModuleUseDataset({moduleId: labeModuleId, datasetId: datasetId, beforeDatasetId: beforeDatasetId ,mode: mode})
    }

    const updateModuleItem = async (param) => {
        const result = await ModuleService.updateModuleItem(param);
        return result;
    }

    const deleteModuleItemCheck = ()=>{
        if(selectionNode){
            const selectionNodeId = selectionNode.id;
            confirm("alert.deleteCheck"
                , "workflow.deleteModuleConfirm"
                ,()=>{
                    deleteModuleItem(selectionNodeId);
                }
                ,null
                , null
                , intl);
        }
    }

    const deleteModuleItem = async (moduleId) => {
        // TODO Authorization의 : accessToken을 세팅해야 한다.
        await ModuleService.deleteModuleItem({
            'moduleId': moduleId
        }).then((result)=>{
            alertSuccess('alert.deleteSuccess','alert.deleteSuccessMsg',null, null, intl);
            dispatch(manage_project_workflow_module_deleteNodeId(moduleId));
        });
    }

    const addDatasetMaster = async (param) => {
        await  DatasetService.addDataset(param).then((result)=>{
            if(result.data.data){
                alertSuccess('alert.saveSuccess','alert.saveSuccessMsg',null, null, intl);
                getDatasetItem(param);
            }
        });
    }
    const updateDatasetMaster = async (param) => {
        await  DatasetService.updateDataset(param).then((result)=>{
            if(result.data.data){
                getDatasetItem(param);
                alertSuccess('alert.saveSuccess','alert.saveSuccessMsg',null, null, intl);
            }
        });
    }

    const getDatasetItem = async (param, changeSelectionNodeNotify) =>{
        console.log('getDatasetItem param', param);
        await  DatasetService.getDatasetItem(param).then((result)=>{
            console.log('getDatasetItem result.data.data', result.data.data);
            let datasetConfig = result.data.data;
            let changeNode = {...selectionNode, data: {...selectionNode.data, moduleNm: param.moduleNm, datasetConfig:datasetConfig}}
            if(param.moduleConfig){
                changeNode = {...changeNode, data:{...changeNode.data, moduleConfig: param.moduleConfig}};
            }

            setNodeData({...nodeData, datasetConfig:datasetConfig});
            if(changeSelectionNodeNotify){
                dispatch(manage_project_workflow_module_setSelectionNode(changeNode));
            }
            dispatch(manage_project_workflow_module_updateModuleId(changeNode.data.moduleId));

        });
    }

    const handleClickModuleNm = (event) => {
        setNodeData({...nodeData, 'editMode':true});
    }

    const handleUpdateModuleNm = (event)=>{
        const changeModuleNm = event.target.value.trim();
        if(changeModuleNm.length ==0){
            setNodeData({...nodeData, 'editMode':false});
            return;
        }
        isModuleNmDuplication(event, changeModuleNm);
    }

    const handleUpdateModuleNmKeyPress = (event) =>{
        if (event.key === 'Enter') {
            const changeModuleNm = event.target.value.trim();
            if(changeModuleNm.length ==0){
                setNodeData({...nodeData, 'editMode':false});
                return;
            }
            isModuleNmDuplication(event, changeModuleNm);
        }else{
            event.target.classList.remove('is-invalid');
            event.target.parentElement.children[1].classList.remove('invalid-tooltip');
            event.target.parentElement.children[1].innerText= '';
        }
    }

    let isModuleNmDuplication =async(event, changeModuleNm)=>{
        let dupResult = false;
        const state = store.getState();
        const nodes = Array.from(state.nodeInternals.values());
        const dupNode = nodes.find((c) => c.data && c.data.moduleNm === changeModuleNm && c.id != selectionNode.id);
        if(dupNode!=undefined){
            dupResult = true;
            event.target.classList.add('is-invalid');
            event.target.parentElement.children[1].classList.add('invalid-tooltip');
            event.target.parentElement.children[1].innerText= GetMessageIntl(intl, "validation.duplication");
        }else{
            await ModuleService.isModuleNmDuplication({
                'prjctId': prjctId,
                'moduleNm': changeModuleNm,
                'moduleTy': '03',
                'moduleId':selectionNode.id
            }).then((result)=>{
                if(result.data.data){
                    alertError("alert.dupModuleNm", '', null, null, null, intl);
                }else{
                    setNodeData({...nodeData, moduleNm: changeModuleNm, 'editMode':false});
                    setValue('moduleNm', changeModuleNm);
                    const state = store.getState();
                    const changeNodes = Array.from(state.nodeInternals.values());
                    state.setNodes(
                        changeNodes.map((node)=>{
                            if(node.id === selectionNode.id){
                                node.data.moduleNm = changeModuleNm;
                            }
                            return node;
                        })
                    );
                    //dispatch(manage_project_workflow_module_setSelectionNode(changeNodes.find((c) => c.id === selectionNode.id)));
                    dispatch(manage_project_workflow_module_setSelectionNodeNm(changeModuleNm));
                    let param = {};
                    param.moduleId = selectionNode.id;
                    param.moduleNm = changeModuleNm;
                    param.moduleTy = '00';
                    updateModuleItem(param);
                }
            });
        }

        return dupResult;
    }

    const workflowPlaceholderMsg = GetMessageIntl(intl,"common.newCreate");
    const workflowUseDatasetOption = async()=>{
        await ManagerWorkflowService.getWorkflowUseDatasetList({
            'prjctId': prjctId, 'canvasDataTy': prop.canvasDataTy
        }).then((result)=>{
            let groupedOptions = [];
            groupedOptions.push({options: [{ value: 'new', label: workflowPlaceholderMsg}]});
            if(result.data.data){
                let resultData = result.data.data;
                resultData.map((data)=>{
                    let optionItem = new Object();
                    optionItem.label = data.wrkflowNm;
                    optionItem.value = data.wrkflowId;
                    if(data.datasetList && data.datasetList.length>0){
                        let datasetList = data.datasetList;
                        if(wrkflowId ==data.wrkflowId){
                            datasetList = datasetList.filter(dataset => {return dataset.dataAddTy == '00'});
                        }
                        optionItem.options = datasetList.map((dataset)=>{
                            //return { value: (data.wrkflowId).concat(`_${dataset.datasetId}`), label: dataset.datasetNm, config: dataset.dataConfig, wrkflowId: data.wrkflowId, dataAddTy:dataset.dataAddTy }
                            return { value: (data.wrkflowId).concat(`_${dataset.datasetId}`), label: dataset.moduleNm, config: dataset.dataConfig, wrkflowId: data.wrkflowId, dataAddTy:dataset.dataAddTy }
                        })
                    }
                    if(optionItem.options.length>0){
                        //console.log(optionItem);
                        groupedOptions.push(optionItem);
                    }
                })
            }
            setDatasetTyOptions(groupedOptions);
        });
    }

    const getStorageVendorOption = async()=>{
        await CommonService.getStorageVendorList().then((result)=>{
            if(result.data.data){
                let resultData = result.data.data;
                resultData = resultData.map((data)=>{
                    return { value: data.cmmnDetailCd, label: data.cmmnDetailCdNm }
                })
                setStorageKeyOptions(resultData);
            }else{
                setStorageKeyOptions([]);
            }
        });
    }

    const handleChangeDatasetTy = (result) =>{
        console.log('handleChangeDatasetTy result', result);
        reset();
        let selectedDatasetTy = result.value;
        let selectedDatasetId = result.value.split('_')[1];
        setValue('datasetTy', selectedDatasetTy);
        setCopyDatasetDisabled(false);
        setCopyConfigDisabled(false);
        setDataAddTyDisabled(false)
        setTargetFileList([]);

        let selectedDatasetOption = null;
        datasetTyOptions.map((groupOption) => {
            let findDatasetTypOption = groupOption.options.find((o)=> o.value == selectedDatasetTy);
            if(findDatasetTypOption != undefined){
                selectedDatasetOption = findDatasetTypOption;
            }
        })

        setSelectedDatasetOption(selectedDatasetOption);

        if(result.value !== 'new'){
            setValue('dataAddTy', result.dataAddTy);
            setDataAddTyDisabled(true)
            if(result.dataAddTy == '00' ){
                setValue('getMethodTy', '00');
                if(result.wrkflowId === wrkflowId){
                    // 동일 워크프로우내 클라우드는 설정복사만 가능
                    setCopyDatasetDisabled(true);
                }

                setTargetConfig(result.config);
                let resultConfig = JSON.parse(result.config);
                for(const [key, value] of Object.entries(resultConfig)){
                    resetField(key);
                    if(key === 'copyObject'){
                        setValue(key, (value?"00":"01"));
                    }else {
                        setValue(key, (value ? value : ""));
                    }
                };

            }else if(result.dataAddTy == '01'){
                // 파일 업로드는 재사용만 가능
                setCopyConfigDisabled(true);
                setValue('getMethodTy', '01');

                DatasetService.getFileList({datasetId: selectedDatasetId}).then((result)=>{
                    setTargetFileList(result.data.data);
                });
            }
            setFiles([]);
        }else{
            // new 인 경우에는 설정가져 오기 복사 이므로..
            setValue('getMethodTy', '00');
        }
    }

    // 설정 복사 인지 데이터셋 가져오기인지
    const handleChangeMethodTy = (e) =>{
        let methodTyVal = e.target.value;
        setValue('getMethodTy', methodTyVal);

        if(methodTyVal == '01'){

            if(targetConfig){
                let resultConfig = JSON.parse(targetConfig);
                for(const [key, value] of Object.entries(resultConfig)){
                    if(key === 'copyObject'){
                        setValue(key, (value?"00":"01"));
                    }else{
                        setValue(key, (value?value:""));
                    }
                };
            }

        }
    }

    const noImageDiv = useRef();
    const uploadLimit = 10000;
    const onDrop = useCallback((acceptedFiles) => {
        //noImageDiv.current.className += ' d-none';
        const tList = [];
        for (let i = 0; i < acceptedFiles.length; i++) {
            const file = acceptedFiles[i];
            if (files.length > 0) {
                // 기존에 등록되어있던게 있으면
                let hasFile = false;
                for (let i = 0; i < files.length; i++) {
                    if (files[i].name === file.name
                        && files[i].size === file.size
                        && files[i].lastModified === file.lastModified) {
                        // 이름도 같고, 크기도 같고, 마지막 수정일도 같으면 중복으로
                        hasFile = true;
                        console.log('중복!!')
                        //this.removeFile(file);
                    }
                }
                if (!hasFile) {
                    tList.push(file);
                }
            } else {
                tList.push(file);
            }
        }

        tList.sort((a, b) =>{
            let am =a.name.match(/(\d+)/g)
            let bm =b.name.match(/(\d+)/g)
            if(am && bm){
                return Number(am[0]) > Number(bm[0]) ? 1 : -1
            }else{
                return a.name > b.name ? 1: -1
            }
        });
        if(files.length + tList.length > uploadLimit){
            alert('업로드 제한 초과! ')
            return;
        }

        const newAccptedFiles = tList.map(file =>
            Object.assign(file, {
                preview: URL.createObjectURL(file)
            })
        )
        setFiles(files.concat(newAccptedFiles).sort((a, b) =>{
            let am =a.name.match(/(\d+)/g)
            let bm =b.name.match(/(\d+)/g)
            if(am && bm){
                return Number(am[0]) > Number(bm[0]) ? 1 : -1
            }else{
                return a.name > b.name ? 1: -1
            }
        }));
        setDropzoneFileCnt(files.length + newAccptedFiles.length);
        setValue('uploadFileCnt', files.length + newAccptedFiles.length);

        setFileRejections([]);
    }, [files]);

    const onDropRejected = useCallback((fileRejections) => {
        setFileRejections(fileRejections);
    }, [files]);

    useEffect(()=>{
        if(fileRejections.length>0){
            setAlertFileRejectionModal(true);
        }
    },[fileRejections])

    const removeFile = (file) => () => {
        const newFiles = [...files]
        newFiles.splice(newFiles.indexOf(file), 1)
        setFiles(newFiles);
        setDropzoneFileCnt(newFiles.length);
        setValue('uploadFileCnt', newFiles.length);
    }
    const {
        getRootProps,
        getInputProps,
        isFocused,
        isDragAccept,
        isDragReject,
        acceptedFiles,
    } = useDropzone({
        onDrop,
        onDropRejected,
        // accept: !getValues('dataTy') || getValues('dataTy') && getValues('dataTy') == '01'? {'image/gif':[], 'image/jpeg':[], 'image/png':[]}
        //     : getValues('dataTy') == '02'? {'audio/*':['.wav']}
        //         : getValues('dataTy') == '03' ? {'video/mp4': ['.mp4', '.MP4']}
        //             : {'text/plain':[]}
        accept: !prop.canvasDataTy || prop.canvasDataTy && prop.canvasDataTy.startsWith('1')? {'image/gif':[], 'image/jpeg':[], 'image/png':[]}
            : prop.canvasDataTy.startsWith('2')? {'audio/wav':[],'audio/mp3':[]}
                : prop.canvasDataTy.startsWith('3') ? {'video/*': ['.mp4', '.MP4' ,'.mov' ,'.MOV']}
                    : {'text/plain':[]}
    });

    const baseStyle = {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '20px',
        borderWidth: 2,
        borderRadius: 2,
        borderColor: '#eeeeee',
        borderStyle: 'dashed',
        backgroundColor: '#fafafa',
        color: '#bdbdbd',
        outline: 'none',
        transition: 'border .24s ease-in-out'
    };

    const focusedStyle = {
        borderColor: '#2196f3'
    };

    const acceptStyle = {
        borderColor: '#00e676'
    };

    const rejectStyle = {
        borderColor: '#ff1744'
    };

    const style = useMemo(() => ({
        ...baseStyle,
        ...(isFocused ? focusedStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {})
    }), [
        isFocused,
        isDragAccept,
        isDragReject
    ]);

    const img = {
        display: 'block',
        width: '50px',
        height: '28px'
    };

    const fileList = files.map(file => {
        //console.log('fileList', file);
            return (<div className="hstack gap-3 py-2 border-0 tx-16 text-coco-gray-700 fw-500" key={file.name}>
                <div className="me-3 d-flex align-items-center">
                    {
                        prop.canvasDataTy.substring(0,1) == '1'?
                            <div className="avatar-xxs bg-light rounded me-3">
                                <img
                                    className="img-fluid rounded d-block"
                                    src={file.preview}
                                    style={img}
                                    // Revoke data uri after image is loaded
                                    onLoad={() => {
                                        URL.revokeObjectURL(file.preview)
                                    }}
                                />
                            </div>
                            : <></>
                    }
                    <div className="wd-570px text-overflow">
                        <span className="mb-0 fw-500 tx-16 text-overflow" data-dz-name>{file.name}</span>
                    </div>
                    {/*<strong className="error text-danger" data-dz-errormessage>{file.name}</strong>*/}
                </div>
                <div className="ms-auto">
                    <p className="mb-0" data-dz-size>{
                        CommonUtil.getByteConvertNumber(file.size)}</p>
                </div>
                <div className="pe-3">
                    <div data-dz-remove>
                        <i className="ri-close-line tx-20" onClick={removeFile(file)}></i>
                    </div>
                </div>
            </div>)
        }
    );

    const uploadConfirm =async()=>{
        let dataAddTy = nodeData.datasetConfig.dataAddTy;
        if(dataAddTy === '01'&&trigger()){
            const result = await trigger();
            console.log(result);
            if(!result){
                return;
            }

        }

        confirm("workflow.uploadDatasetTitle"
            , "workflow.uploadDatasetMsg"
            ,()=>{
                uploadProcess();
            }
            ,null
            , null
            , intl);
    }

    const uploadProcess = async () => {
        let datasetId = nodeData.datasetConfig.datasetId;
        let dataAddTy = nodeData.datasetConfig.dataAddTy;
        // console.log('uploadProcess datasetId', datasetId);
        // console.log('uploadProcess dataAddTy', dataAddTy);
        if(dataAddTy === '00'){
            // cloud
            await ManagerWorkflowService.uploadDatasetFromS3({'wrkflowId': wrkflowId,'datasetId': datasetId, 'datasetNm':nodeData.datasetConfig.datasetNm, 'dataAddTy': dataAddTy})
                .then((response) => {
                    alertInfo("workflow.uploadDatasetIngTitle", "workflow.uploadDatasetIngCloudMsg", null, null, intl)
                    // 상태를 변경해줘야 업로드를 다시 못함..
                    setNodeData({...nodeData, datasetConfig:{...nodeData.datasetConfig, sttusCd: '01'}});
                    setIsDisabledData(true); // 수정도 못하게 변경해야 함.
                })
                .catch(error => {
                    console.log(error);
                });

        }else if(dataAddTy === '01'){
            // 파일 업로드
            // TODO Authorization의 : accessToken을 세팅해야 한다.
            setAlertLocalFilesModal(true);
            uploadLocalFiles()
        }
    }

    const uploadLocalFiles = async ()=>{
        let fileSeq = 1;
        let datasetId = nodeData.datasetConfig.datasetId;
        const promises = files.map(async (file) => {
            let fd = new FormData();
            fd.append('file', file);
            fd.append('fullpath', file.path);
            //  최초 요청전에 발급받아서 세팅한다.
            fd.append('datasetId', datasetId);
            fd.append('wrkflowId', wrkflowId);
            fd.append('fileSeq', fileSeq++);

            return await ManagerWorkflowService.uploadLocalFiles(fd,  {contentType : 'multipart/form-data'} )
                .then((response) => {
                })
                .catch(error => {
                    console.log(error);
                });
        });
        await Promise.all(promises);
        const newFiles = [];
        setFiles(newFiles);
        setDropzoneFileCnt(newFiles.length);
        setValue('uploadFileCnt', newFiles.length);

        await ManagerWorkflowService.uploadLocalFileFinish({'wrkflowId': wrkflowId,'datasetId': datasetId}).then((result)=>{

        });
        setAlertLocalFilesModal(false);
        alertSuccess('workflow.uploadDatasetFinishLocalTitle','workflow.uploadDatasetFinishLocalMsg',null, null, intl);

        // 데이터셋 조회해서 selectionNode에다가 알려주자..
        getDatasetItem({datasetId:datasetId, moduleNm: nodeData.moduleNm}, true);
    }

    const targetFileView = async(fileSeq, fileNm) =>{
        let datasetTy = getValues('datasetTy');
        let datasetId = datasetTy=='new'?nodeData.datasetConfig.datasetId:datasetTy.split('_').length>1?datasetTy.split('_')[1]:selectedDatasetOption.value.split('_')[1];

        let result = null;
        if(prop.canvasDataTy.substring(0,1) == '1'){
            result = await ImageLabelService.getTargetImage({datasetId: datasetId, fileSeq: fileSeq});
            if(result.data.data){
                setTargetFileModal(true);
                setTargetFileImage(result.data.data.encodedString);
            }
        }else if(prop.canvasDataTy.substring(0,1) == DATA_TYPE.AUDIO){
            result = await AudioLabelService.getTargetAudio({datasetId: datasetId, fileSeq: fileSeq});
            if(result.data.data){
                setTargetFileNm(fileNm);
                setTargetFileAudioModal(true);
                setTargetFileAudio(result.data.data.encodedString);
            }
        } else if(prop.canvasDataTy.substring(0,1) === DATA_TYPE.VIDEO){
            result = await VideoLabelService.getTargetVideo({datasetId: datasetId, fileSeq: fileSeq});
            if(result.data.data){
                setTargetFileNm(fileNm);
                setTargetVideoModal(true);
                setTargetFileVideo(result.data.data.encodedString);
            }
        } else if(prop.canvasDataTy.substring(0,1) == '4'){
            result = await TextLabelService.getTargetText({datasetId: datasetId, fileSeq: fileSeq});
            if(result.data.data){
                setTargetFileNm(fileNm);
                setTargetFileTextModal(true);
                setTargetFileText(result.data.data.encodedString);
            }
        }
    }

    const toggleTopCanvas = () => {
        dispatch(manage_project_workflow_module_setIsDatasetOffcanvas(false));
    };

    const toggleCustom = (tab) => {
        if (customActiveTab !== tab) {
            setcustomActiveTab(tab);
        }
    };

    const onSubmit = async (data) => {
        let submitData = {};
        submitData.moduleNm = data.moduleNm;
        submitData.moduleTy ='03';
        submitData.datasetId = data.datasetId;
        submitData.datasetNm = data.moduleNm;
        submitData.metaInfoMapngTy = '00';

        if(data.datasetTy == 'new' || data.getMethodTy == '00'){
            // 설정 값을 저장해야 할 경우에는 Master에서 저장한다.
            if(data.dataAddTy == '00'){
                submitData.dataAddTy = data.dataAddTy;
                submitData.dataConfigObj = {
                    accessKey: data.accessKey,
                    bucketName: data.bucketName,
                    copyObject: (data.copyObject == '00'? true: false),
                    fileNamePttrn: data.fileNamePttrn,
                    filePathPttrn: data.filePathPttrn,
                    fileSizeLimit: data.fileSizeLimit? parseInt(data.fileSizeLimit):'',
                    folderName: data.folderName,
                    secretKey: data.secretKey,
                    storageKeyId: data.storageKeyId
                };

                if(data.getMethodTy == '00' && selectedDatasetOption && selectedDatasetOption.config){

                    let compareData = {};
                    let selectedDatasetConfig = JSON.parse(selectedDatasetOption.config);
                    for(const fields of compareDatasetFields){
                        compareData[fields]= selectedDatasetConfig[fields];
                    }
                    if(JSON.stringify(submitData.dataConfigObj) == JSON.stringify(compareData) ){
                        //console.log('설정 복사는 하나라도 변경된게 있어야 함..');
                        alertError("alert.sameConfig", 'alert.sameConfigMsg', null, null, null, intl);
                        return;
                    }
                }

            }else{
                submitData.dataAddTy = data.dataAddTy;
            }

            console.log('submitData', submitData);
            await  ModuleService.isModuleNmDuplication({
                'prjctId': prjctId,
                'moduleNm': submitData.moduleNm,
                'moduleTy': submitData.moduleTy,
                'moduleId':selectionNode.id
            }).then((result)=>{
                console.log(result.data);
                if(result.data.data){
                    alertError("alert.dupModuleNm", '', null, null, null, intl);
                }else{
                    if(!nodeData.datasetConfig){
                        //console.log('마스터 등록을 해야함')
                        confirm("alert.save"
                            , "workflow.saveMsg"
                            ,()=>{
                                addDatasetMaster(submitData);
                            }
                            ,null
                            , null
                            , intl);


                    }else if(nodeData.datasetConfig&&(nodeData.datasetConfig.sttusCd=='02'||nodeData.datasetConfig.sttusCd=='03')){
                        // 정보 등록 완료(02) 된 상태와 파일 처리중 오류 (03)
                        // 근데 파일 처리중 오류 난것도 업데이트 해주게 해야함
                        confirm("alert.modifySave"
                            , "workflow.modifyMsg"
                            ,()=>{
                                updateDatasetMaster(submitData);
                            }
                            ,null
                            , null
                            , intl);
                    }else{
                        console.log('저장하면 안됨.. 팅겨져라!!');
                    }


                }
            });
        }else{
            // 동일한 데이터셋을 사용할 경우에는 module만 업데이트!
            let param = {};
            let selectedWrkflowId = selectedDatasetOption.value.split('_')[0];
            let selectedDatasetId = selectedDatasetOption.value.split('_')[1];
            console.log('selectedWrkflowId',selectedWrkflowId);
            console.log('selectedDatasetId',selectedDatasetId);
            param.moduleTy = '03';
            param.moduleId = selectionNode.id;
            param.moduleNm = selectedDatasetOption.label;
            param.moduleConfig = JSON.stringify({'wrkflowId':selectedDatasetOption.wrkflowId, 'datasetId':selectedDatasetId});
            param.beforeDatasetId = selectionNode.data.datasetId? selectionNode.data.datasetId:JSON.parse(selectionNode.data.moduleConfig).datasetId;

            const state = store.getState();
            const nodes = Array.from(state.nodeInternals.values());


            const dupNode = nodes.find((c) => c.type=='dataset' &&  ((c.data.moduleConfig &&JSON.parse(c.data.moduleConfig) !=undefined &&JSON.parse(c.data.moduleConfig).datasetId === selectedDatasetId)
                || (c.data.datasetId &&  c.data.datasetId === selectedDatasetId))
            );
            if(dupNode === undefined){
                confirm("alert.save"
                    , "workflow.saveMsg"
                    ,()=>{
                        updataCopyDatasetItem(param);
                    }
                , null
                , null
                , intl);
            }else{
                alertError('workflow.datasetIdDupTitle', 'workflow.datasetIdDupMsg', null, null, null, intl);
            }
        }
    };

    const onMetaSubmit = (data) => {
        confirm("alert.metaDataTitle"
            , "alert.metaDataMsg"
            ,()=>{
                const submitData = new FormData();
                submitData.append("file", document.getElementById("metaFile").files[0]);
                submitData.append("wrkflowId", wrkflowId);
                submitData.append("datasetId", data.datasetId);
                submitData.append("metaInfoMapngTy", data.metaInfoMapngTy);

                setDatasetMetadata(submitData);
            }
            ,null
            , null
            , intl);
    };

    const setDatasetMetadata= async (submitData) =>{
        DatasetService.setDatasetMetadata(submitData, {contentType : 'multipart/form-data'}).then((result)=>{
            if(result.data.success){
                alertSuccess('alert.metaDataCompleteTitle','alert.metaDataCompleteMsg',null, null, intl);
            }else{
                alertError('error.E00001', 'error.E00002', null, null, null, intl);
            }
        });
    }

    const changeMetaInfoMode = () =>{
        setChangeMetaInfo(!changeMetaInfo);
    }

    const metaCsvDownload = () =>{
        const coconutUserInfo = JSON.parse(sessionStorage.getItem("coconutUserInfo"));
        let accessToken = coconutUserInfo.accessToken;


        // DatasetService.getDatasetListForMetadata('파일명.csv', {"datasetId": nodeData.datasetConfig.datasetId})
        // 다운로드 받을 데이터가 있는지 확인
        // 이때 다운받을 파일명 데이터 수신
        new JsFileDownloader({
            url: '/service/dataset/fileListDownload',
            method: 'POST',
            contentType: 'application/json',
            filename: `${nodeData.datasetConfig.datasetNm}.csv`,
            headers: [
                {name: 'Authorization', value: 'Bearer '+accessToken}
            ],
            body: `{"datasetId": "${nodeData.datasetConfig.datasetId}"}`
        })
            .then(function () {
                // Called when download ended
            })
            .catch(function (error) {
                // Called when an error occurred
            });
        console.log('----end----')
    }

    const metaPreviewDownload = ()=>{
        const moduleId = nodeData.moduleId;
        const datasetId = nodeData.moduleConfig.datasetId;

        DatasetService.getPreviewMetadata({datasetId:datasetId, moduleId:moduleId}).then((result)=>{
           if(result.data.data){
               setMetaPreviewModal(true);
               setMetaPreviewData(result.data.data);
           }
        });
    }

    const handlePlayerReady = (player) => {
        console.log("여기 확인해보셈 : ", player)
        const videoWidth = player.getInternalPlayer().videoWidth;
        const videoHeight = player.getInternalPlayer().videoHeight;

        setHorizon(isHorizon(videoHeight, videoWidth));
    }

    const isHorizon = (height, width) => {
        return height < width;
    }

    // useEffect(() => {
    //     const audioPlayEvent = (ev) => {
    //         const audioElement = document.getElementById('targetFileAudio');
    //         console.log('ev space 눌림', )
    //         if (ev.code === 'Space' && document.getElementById('targetFileAudioModal') && ev.target !== audioElement) {
    //             // audio 태그 안에서 space를 누르면 default로 play, pause가 실행된다.
    //             // ev.preventDefault();
    //             audioElement.paused ? audioElement.play() : audioElement.pause();
    //         }
    //     }
    //     window.addEventListener('keydown', audioPlayEvent);
    //
    //     return () => window.removeEventListener('keydown', audioPlayEvent);
    // }, [])

    return (
        <div>
            <Offcanvas isOpen={isDatasetOffCanvas} toggle={toggleTopCanvas} id="offcanvasDatset" style={{top:"70px",border:"1px solid var(--vz-border-color)"}} scrollable={false} backdrop={false} keyboard={false} direction="end">
                <div className="offcanvas-header">
                    <h5 className="offcanvas-title">
                        {nodeData? nodeData.editMode?
                                <>
                                    <input type="text" defaultValue={nodeData.moduleNm|| ''} className="form-control editable tx-20 pd-x-0-f pd-y-3 fw-semibold lh-19"
                                           maxLength={50}
                                           style={{minWidth: 250, width: 12 * nodeData.moduleNm.length}}
                                           onBlur={handleUpdateModuleNm}
                                           onKeyUp={handleUpdateModuleNmKeyPress}
                                           autoFocus />
                                    <p className="mb-0 validation-error-message"></p>
                                </>
                                :
                                <div className="d-flex align-items-center">
                                    {nodeData.moduleNm}
                                    <i className={"ri-edit-line text-coco-primary-100 fw-normal ".concat(nodeData&&nodeData.moduleConfig&&nodeData.moduleConfig.wrkflowId?'d-none':'')} style={{marginLeft:'10.5px'}} onClick={handleClickModuleNm}></i>
                                </div>
                            : null
                        }
                    </h5>
                    <i className="ri-close-line text-coco-default-white tx-20 text-pointer lh-24" onClick={toggleTopCanvas}/>
                </div>
                <div className="pd-x-12 bg-coco-gray-50">
                    <Nav tabs className="nav nav-coco2 nav-tabs nav-tabs-custom">
                        <NavItem>
                            <NavLink
                                style={{ cursor: "pointer" }}
                                className={classnames({
                                    active: customActiveTab === "1",
                                })}
                                onClick={() => {
                                    toggleCustom("1");
                                }}
                            >
                                {GetMessageIntl(intl,"workflow.datasetInsert")}
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                style={{ cursor: "pointer" }}
                                className={classnames({
                                    active: customActiveTab === "2",
                                })}
                                onClick={() => {
                                    toggleCustom("2");
                                }}
                            >
                                {GetMessageIntl(intl,"workflow.metadataInsert")}
                            </NavLink>
                        </NavItem>
                    </Nav>
                </div>
                <TabContent
                    activeTab={customActiveTab}
                    className="text-muted"
                >
                    <TabPane tabId="1" id="datasetTab">
                        <form onSubmit={handleSubmit(onSubmit)} name={"datasetInfoForm"}
                              /*onBlur={(e)=> {
                                  let elm = e.target;
                                  if(elm.name != '' && elm.name != undefined){
                                      console.log('elm', elm.name, elm.value);
                                      trigger(elm.name);
                                      //console.log(trigger());
                                      console.log('formState.isValid', formState.isValid);
                                  }
                              }}*/
                        >
                            <input type="hidden" value={nodeData?nodeData.moduleNm|| '':""} {...register("moduleNm")}/>
                            <input type="hidden" value={nodeData&&nodeData.moduleConfig?nodeData.moduleConfig.datasetId|| '':""} {...register("datasetId")}/>
                            <SimpleBar style={{ maxHeight: "calc(100vh - 229px)" }} autoHide={false} className="simplebar-track-coco">
                                <OffcanvasBody style={{ minHeight: "300px" }} >
                                    <h5 className="module-title">
                                        <i className="ri-database-2-line"></i>{GetMessageIntl(intl,"workflow.dataset")}
                                    </h5>
                                    <Row>
                                        <Col md={6}>
                                            <Label htmlFor="datasetTy">{GetMessageIntl(intl,"workflow.createOrGet")}<span className="text-coco-danger-400">*</span></Label>
                                            <div className={formState.errors.datasetTy?"validation-select-error":""}>
                                                <Controller
                                                    name="datasetTy"
                                                    id="datasetTy"
                                                    rules={{
                                                        required: true,
                                                    }}
                                                    render={({ field: { onChange, value, ref, name } }) => (
                                                        <Select
                                                            options={datasetTyOptions}
                                                            placeholder={GetMessageIntl(intl,"common.selectOptions")}
                                                            formatGroupLabel={formatGroupLabelNoCount}
                                                            styles={defaultSelectStyle}
                                                            className="mb-0"
                                                            menuPortalTarget={document.body}
                                                            menuPosition={'fixed'}
                                                            menuPlacement={'auto'}
                                                            maxMenuHeight={250}
                                                            getOptionLabel={option => {
                                                                return option.label}}
                                                            onChange={val => {
                                                                onChange(val.value);
                                                                handleChangeDatasetTy(val);
                                                            }}
                                                            value={value?datasetTyOptions.map((groupOption) => {
                                                                    let options = groupOption.options.find((o) => o.value == value);
                                                                    if(options !== undefined){
                                                                        return options;
                                                                    }
                                                            }):null}
                                                            isDisabled={isDisabledData}
                                                        />
                                                    )}
                                                    control={control}
                                                />
                                            </div>
                                            {formState.errors.datasetTy && <p className={"validation-error-message"}>{GetMessageIntl(intl,"validation.required")}</p>}
                                        </Col>
                                        <Col md={6} className={(!watchAllFields.datasetTy || watchAllFields.datasetTy === 'new' || isDisabledData? 'd-none':'none')}>
                                            <Label className="form-label">{GetMessageIntl(intl,"workflow.getMethod")}<span className="text-coco-danger-400">*</span></Label>
                                            <div className="ht-38 d-flex align-items-center">
                                                <div className="form-check form-radio-coco form-check-inline">
                                                    <input className="form-check-input" type="radio" id="getMethodTy1" value="00" {...register("getMethodTy")} onChange={handleChangeMethodTy} defaultChecked={!copyConfigDisabled} disabled={copyConfigDisabled}/>
                                                    <Label className="form-check-label mb-0" htmlFor="getMethodTy1">
                                                        {GetMessageIntl(intl,"workflow.copyConfig")}
                                                    </Label>
                                                </div>
                                                <div className="form-check form-radio-coco form-check-inline">
                                                    <input className="form-check-input" type="radio" id="getMethodTy2"  value="01" {...register("getMethodTy")} onChange={handleChangeMethodTy} defaultChecked={copyConfigDisabled} disabled={copyDatasetDisabled}/>
                                                    <Label className="form-check-label mb-0" htmlFor="getMethodTy2">
                                                        {GetMessageIntl(intl,"workflow.copyDataset")}
                                                    </Label>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>

                                    <div className={(!watchAllFields.datasetTy || watchAllFields.datasetTy === ''? 'd-none':'none')}>
                                        <Row>
                                            <Col md={12}>
                                                <hr className={"coco-hr ".concat((!watchAllFields.datasetTy || watchAllFields.datasetTy === ''? 'd-none':'none'))}/>
                                                <h5 className="module-title">
                                                    <i className="ri-archive-drawer-line"></i>{GetMessageIntl(intl,"workflow.uploadMethod")}
                                                </h5>
                                                <div>
                                                    <Label htmlFor="dataAddTy1" className="form-label">{GetMessageIntl(intl,"workflow.uploadType")}<span className="text-coco-danger-400">*</span></Label>
                                                    <div className="d-flex align-items-center">
                                                        <div className="form-check form-radio-coco form-check-inline">
                                                            <input className="form-check-input" type="radio" name="dataAddTy" id="dataAddTy1" value="00" {...register("dataAddTy")}  disabled={watchAllFields.getMethodTy == '01'|| dataAddTyDisabled || isDisabledData}/>
                                                            <Label className="form-check-label mb-0" htmlFor="dataAddTy1">
                                                                {GetMessageIntl(intl,"workflow.cloud")}
                                                            </Label>
                                                        </div>
                                                        <div className="form-check form-radio-coco form-check-inline">
                                                            <input className="form-check-input" type="radio" id="dataAddTy2" name="dataAddTy" value="01" {...register("dataAddTy")} disabled={watchAllFields.getMethodTy == '01'|| dataAddTyDisabled || isDisabledData}/>
                                                            <Label className="form-check-label mb-0" htmlFor="dataAddTy2">
                                                                {GetMessageIntl(intl,"workflow.local")}
                                                            </Label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>

                                        <hr className="coco-hr"/>
                                        {watchAllFields.dataAddTy ==='00'?
                                            <>
                                                {/* 업로드 방식이 클라우드인 경우에만 보여지도록 */}
                                                <div className={(watchAllFields.dataAddTy ==='00'?'none':'d-none')}>
                                                    <h5 className="module-title">
                                                        <i className="ri-cloudy-line"></i>{GetMessageIntl(intl,"workflow.cloudInfo")}
                                                    </h5>
                                                    <Row>
                                                        <Col md={6}>
                                                            <Label htmlFor="storageKeyId" className="form-label">{GetMessageIntl(intl,"workflow.storageKeyId")}<span className="text-coco-danger-400">*</span></Label>
                                                            <div className={formState.errors.storageKeyId?"validation-select-error":""}>
                                                                <Controller
                                                                    name="storageKeyId"
                                                                    id="storageKeyId"
                                                                    rules={{
                                                                        required: true,
                                                                    }}
                                                                    render={({ field: { onChange, value, ref, name } }) => (
                                                                        <Select
                                                                            options={storageKeyOptions}
                                                                            placeholder={GetMessageIntl(intl,"common.selectOptions")}
                                                                            formatGroupLabel={formatGroupLabelNoCount}
                                                                            styles={defaultSelectStyle}
                                                                            className="mb-0"
                                                                            onChange={val => {
                                                                                onChange(val.value);
                                                                            }}
                                                                            value={value?storageKeyOptions.find((c) => c.value === value):null}
                                                                            isDisabled={watchAllFields.getMethodTy == '01' || isDisabledData}
                                                                        />
                                                                    )}
                                                                    control={control}
                                                                />
                                                            </div>
                                                            {formState.errors.storageKeyId && <p className={"validation-error-message"}>{GetMessageIntl(intl,"validation.required")}</p>}
                                                        </Col>
                                                        <Col md={6}>
                                                            <Label htmlFor="bucketName" className="form-label">{GetMessageIntl(intl,"workflow.bucketName")}<span className="text-coco-danger-400">*</span></Label>
                                                            <div className={formState.errors.bucketName?"validation-error":""}>
                                                                <Controller
                                                                    render={({ field }) => <Input {...field} placeholder={GetMessageIntl(intl,"common.enterInfo")} disabled={watchAllFields.getMethodTy == '01' || isDisabledData}/>}
                                                                    name="bucketName"
                                                                    control={control}
                                                                    rules={{
                                                                        required: true,
                                                                    }}
                                                                    defaultValue=""
                                                                    className="form-control lh-18"
                                                                />
                                                            </div>
                                                            {formState.errors.bucketName && <p className="validation-error-message">{GetMessageIntl(intl,"validation.required")}</p>}
                                                        </Col>
                                                        <Col md={6} className="mt-16">
                                                            <Label htmlFor="accessKey" className="form-label">{GetMessageIntl(intl,"workflow.accessKey")}<span className="text-coco-danger-400">*</span></Label>
                                                            <div className={formState.errors.accessKey?"validation-error":""}>
                                                                <Controller
                                                                    render={({ field }) => <Input {...field} placeholder={GetMessageIntl(intl,"common.enterInfo")} maxLength="20" disabled={watchAllFields.getMethodTy == '01' || isDisabledData}/>}
                                                                    name="accessKey"
                                                                    control={control}
                                                                    rules={{
                                                                        required: true,
                                                                    }}
                                                                    defaultValue=""
                                                                    className="form-control lh-18"
                                                                />
                                                            </div>
                                                            {formState.errors.accessKey && <p className="validation-error-message">{GetMessageIntl(intl,"validation.required")}</p>}
                                                        </Col>
                                                        <Col md={6} className="mt-16">
                                                            <Label htmlFor="secretKey" className="form-label">{GetMessageIntl(intl,"workflow.secretKey")}<span className="text-coco-danger-400">*</span></Label>
                                                            <div className={formState.errors.secretKey?"validation-error":""}>
                                                                <Controller
                                                                    render={({ field }) => <Input {...field} type="password" placeholder={GetMessageIntl(intl,"common.enterInfo")} maxLength="40" disabled={watchAllFields.getMethodTy == '01' || isDisabledData}/>}
                                                                    name="secretKey"
                                                                    control={control}
                                                                    rules={{
                                                                        required: true,
                                                                    }}
                                                                    defaultValue=""
                                                                    className="form-control lh-18"
                                                                />
                                                            </div>
                                                            {formState.errors.secretKey && <p className="validation-error-message">{GetMessageIntl(intl,"validation.required")}</p>}
                                                        </Col>
                                                        <Col md={12} className="mt-16">
                                                            <Label htmlFor="folderName" className="form-label">{GetMessageIntl(intl,"workflow.filePath")}<span className="text-coco-danger-400">*</span></Label>
                                                            <div className={formState.errors.folderName?"validation-error":""}>
                                                                <Controller
                                                                    render={({ field }) => <Input {...field} placeholder={GetMessageIntl(intl,"common.enterInfo")} disabled={watchAllFields.getMethodTy == '01' || isDisabledData}/>}
                                                                    name="folderName"
                                                                    control={control}
                                                                    rules={{
                                                                        required: true,
                                                                    }}
                                                                    defaultValue=""
                                                                    className="form-control lh-18"
                                                                />
                                                            </div>
                                                            {formState.errors.folderName && <p className="validation-error-message">{GetMessageIntl(intl,"validation.required")}</p>}
                                                        </Col>
                                                    </Row>

                                                    <hr className="coco-hr"/>

                                                    <h5 className="module-title">
                                                        <i className="ri-folder-received-line"></i>{GetMessageIntl(intl,"workflow.dataAccessAndFilterRule")}
                                                    </h5>
                                                    <Row>
                                                        <Col md={12}>
                                                            <Label htmlFor="copyObject1" className="form-label">{GetMessageIntl(intl,"workflow.accessRange")}<span className="text-coco-danger-400">*</span></Label>
                                                            <div className="d-flex align-items-center">
                                                                <div className="form-check form-radio-coco form-check-inline">
                                                                    <input className="form-check-input" type="radio" name="copyObject" id="copyObject1" value="00" {...register("copyObject")} defaultChecked disabled={watchAllFields.getMethodTy == '01' || isDisabledData}/>
                                                                    <Label className="form-check-label mb-0" htmlFor="copyObject1">
                                                                        {GetMessageIntl(intl,"workflow.copyObject")}
                                                                    </Label>
                                                                </div>
                                                                <div className="form-check form-radio-coco form-check-inline">
                                                                    <input className="form-check-input" type="radio" id="copyObject2" name="copyObject" value="01" {...register("copyObject")} disabled={watchAllFields.getMethodTy == '01' || isDisabledData}/>
                                                                    <Label className="form-check-label mb-0" htmlFor="copyObject2">
                                                                        {GetMessageIntl(intl,"workflow.readObject")}
                                                                    </Label>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                        {/*<Col md={12} className="mt-16">
                                                    <Label htmlFor="uploadUnit" className="form-label">{GetMessageIntl(intl,"workflow.uploadUnit")}<span className="text-coco-danger-400">*</span></Label>
                                                    <div className="d-flex align-items-center">
                                                        <div className="form-check form-radio-coco form-check-inline">
                                                            <input className="form-check-input" type="radio" name="uploadUnit" id="uploadUnit1" value="00" {...register("uploadUnit")} defaultChecked disabled={watchAllFields.getMethodTy == '01'}/>
                                                            <Label className="form-check-label mb-0" htmlFor="uploadUnit1">
                                                                {GetMessageIntl(intl,"workflow.data")}
                                                            </Label>
                                                        </div>
                                                        <div className="form-check form-radio-coco form-check-inline">
                                                            <input className="form-check-input" type="radio" id="uploadUnit2" name="uploadUnit" value="01" {...register("uploadUnit")} disabled={watchAllFields.getMethodTy == '01'}/>
                                                            <Label className="form-check-label mb-0" htmlFor="uploadUnit2">
                                                                {GetMessageIntl(intl,"workflow.folder")}
                                                            </Label>
                                                        </div>
                                                    </div>
                                                </Col>*/}
                                                        <Col md={12} className="mt-16">
                                                            <Label htmlFor="filePathPttrn" className="form-label">{GetMessageIntl(intl,"workflow.filePathPttrn")}</Label>
                                                            <Controller
                                                                render={({ field }) => <Input {...field} placeholder={GetMessageIntl(intl,"common.enterInfo")} disabled={watchAllFields.getMethodTy == '01' || isDisabledData}/>}
                                                                name="filePathPttrn"
                                                                control={control}
                                                                defaultValue=""
                                                                className="form-control lh-18"
                                                            />
                                                        </Col>
                                                        <Col md={6} className="mt-16">
                                                            <Label htmlFor="fileNamePttrn" className="form-label">{GetMessageIntl(intl,"workflow.fileNamePttrn")}</Label>
                                                            <Controller
                                                                render={({ field }) => <Input {...field} placeholder={GetMessageIntl(intl,"common.enterInfo")} disabled={watchAllFields.getMethodTy == '01' || isDisabledData}/>}
                                                                name="fileNamePttrn"
                                                                control={control}
                                                                defaultValue=""
                                                                className="form-control lh-18"
                                                            />
                                                        </Col>
                                                        <Col md={6} className="mt-16">
                                                            <Label htmlFor="fileSizeLimit" className="form-label">{GetMessageIntl(intl,"workflow.fileSizeLimit")}</Label>
                                                            <div className={formState.errors.fileSizeLimit?"validation-error":""}>
                                                                <Controller
                                                                    render={({ field }) => <Input {...field} placeholder={GetMessageIntl(intl,"common.enterInfo")} disabled={watchAllFields.getMethodTy == '01' || isDisabledData}/>}
                                                                    name="fileSizeLimit"
                                                                    control={control}
                                                                    rules={{
                                                                        pattern: {
                                                                            value: /^[0-9]+$/,
                                                                            message:GetMessageIntl(intl,"validation.onlyNumber")
                                                                        },
                                                                        validate: (value, formValues) => {
                                                                            if(parseInt(value) <=  0){
                                                                                return GetMessageIntl(intl,"validation.greaterThanZero");
                                                                            }
                                                                        }
                                                                    }}
                                                                    defaultValue=""
                                                                    className="form-control lh-18"
                                                                />
                                                            </div>
                                                            {formState.errors.fileSizeLimit && <p className="validation-error-message">{formState.errors.fileSizeLimit.message}</p>}
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </>
                                            :
                                            <>
                                                {/* 업로드 방식이 로컬 업로드인 경우에만 보여지도록 */}
                                                <div className={(watchAllFields.dataAddTy ==='01'?'none':'d-none')}>
                                                    <div className="module-title justify-content-between">
                                                        <div className="d-flex align-items-center">
                                                            <i className="ri-folder-upload-line"></i>{GetMessageIntl(intl,"workflow.local")}
                                                        </div>
                                                        <div className="">
                                                            {GetMessageIntl(intl,"common.total")}:
                                                            {
                                                                (selectionNode.data.datasetConfig && selectionNode.data.datasetConfig.sttusCd == '00') || watchAllFields.getMethodTy =='01'?
                                                                    <>
                                                                        {targetFileList.length}{GetMessageIntl(intl,"common.number")} /<NumericFormat value={uploadLimit}  thousandSeparator="," displayType="text" />
                                                                    </>
                                                                :
                                                                    <>
                                                                        {dropzoneFileCnt}{GetMessageIntl(intl,"common.number")} /<NumericFormat value={uploadLimit}  thousandSeparator="," displayType="text" />
                                                                        <span className=""></span>
                                                                    </>
                                                            }
                                                        </div>
                                                    </div>
                                                    {(selectionNode.data.datasetConfig && selectionNode.data.datasetConfig.sttusCd == '00') || watchAllFields.getMethodTy =='01'?
                                                        <Row>
                                                            <Col md={12}>
                                                                <div data-simplebar-auto-hide="false" className="ht-315">
                                                                    <SimpleBar style={{maxHeight: 315}}>
                                                                        <ListGroup className={"list-unstyled mb-0".concat(targetFileList.length>0?"":" d-none")} id="local-upload-fileList" >
                                                                            {
                                                                                targetFileList.map( (file) =>{
                                                                                    return(<ListGroupItem key={"localFile_".concat(file.fileSeq)} className="list-group-item-action border-0 d-flex align-items-center justify-content-between text-coco-gray-700 tx-16 lh-20 fw-semibold pd-r-20-f">
                                                                                        <div>
                                                                                            {file.fileNm}
                                                                                            {
                                                                                                prop.canvasDataTy.substring(0,1) == '1' || prop.canvasDataTy.substring(0,1) == '4' || prop.canvasDataTy.substring(0,1) == DATA_TYPE.AUDIO || prop.canvasDataTy.substring(0,1) === DATA_TYPE.VIDEO ?
                                                                                                <button type="button" className={"btn-coco-xs-1 ml-16"} onClick={()=>targetFileView(file.fileSeq, file.fileNm)}>{GetMessageIntl(intl,"common.detail")}</button>
                                                                                                :<></>
                                                                                            }
                                                                                        </div>
                                                                                        <span>{CommonUtil.getByteConvertNumber(file.fileSize)}</span>
                                                                                    </ListGroupItem>)
                                                                                })
                                                                            }
                                                                        </ListGroup>
                                                                    </SimpleBar>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                        :
                                                        <Row>
                                                            <Col md={12}>
                                                                <div {...getRootProps()} style={{outline: "2px dashed #CFD8DC", borderRadius: "7px", padding:'10px'}}>
                                                                    <div>
                                                                        <input name="file" type="file" multiple="multiple" {...getInputProps()}/>
                                                                    </div>
                                                                    <div className="dz-message needsclick text-coco-gray-100">
                                                                        <div className="tx-16 text-coco-gray-100 fw-normal text-center">
                                                                            {GetMessageIntl(intl,"workflow.dropzonePlaceholder")}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                            <Col md={12} className="mt-28">
                                                                <div data-simplebar-auto-hide="false" className="dropzone-preview-div ht-250">
                                                                    <div ref={noImageDiv} id="dropzone-default" className={"card card-body ht-250 shadow-none mb-0 border-0 d-flex align-items-center justify-content-center".concat(dropzoneFileCnt>0?" d-none":"")}>
                                                                        <span className="tx-16 text-coco-gray-200 fw-normal">{GetMessageIntl(intl, "workflow.noUploadFile")}</span>
                                                                        {/*<div id="errDropzoneCnt"></div>*/}
                                                                        <input type="hidden" defaultValue={0} {...register("uploadFileCnt",{
                                                                            min: 1,
                                                                        })}/>
                                                                        {formState.errors.uploadFileCnt && <p className={"validation-error-message"}>{GetMessageIntl(intl,"validation.required")}</p>}
                                                                    </div>
                                                                    <SimpleBar style={{maxHeight: 250}}>
                                                                        <ul className={"list-unstyled mb-0".concat(dropzoneFileCnt>0?"":" d-none")} id="dropzone-preview" >
                                                                            <li className="mt-2" id="dropzone-preview-list">
                                                                                {fileList}
                                                                            </li>
                                                                        </ul>
                                                                    </SimpleBar>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    }
                                                </div>
                                            </>
                                        }
                                    </div>
                                </OffcanvasBody>
                            </SimpleBar>
                            <div className="offcanvas-footer justify-content-between">
                                <div>
                                    <button type="button" onClick={uploadConfirm} className={"btn btn-coco-3 ".concat(nodeData && nodeData.datasetConfig && (nodeData.datasetConfig.sttusCd=='01' || nodeData.datasetConfig.sttusCd=='00')?"d-none":"")} disabled={nodeData && nodeData.datasetConfig && nodeData.datasetConfig.sttusCd=='02'?false:true}>
                                        {GetMessageIntl(intl,"common.upload")}
                                    </button>
                                </div>
                                <div className="">
                                    <button className={"btn btn-coco-1 mr-16 ".concat(nodeData && nodeData.datasetConfig && (nodeData.datasetConfig.sttusCd=='01' || nodeData.datasetConfig.sttusCd=='00')?"d-none":"")} type="submit">
                                        {GetMessageIntl(intl,"common.save")}
                                    </button>
                                    <button type="button" className="btn btn-coco-icon-1" onClick={deleteModuleItemCheck}>
                                        <i className="ri-delete-bin-line"></i>
                                    </button>
                                </div>
                            </div>
                        </form>
                    </TabPane>
                    <TabPane tabId="2" id="metaTab">
                        <form onSubmit={handleSubmitMeta(onMetaSubmit)} name={"datasetMetaForm"}>
                            <input type="hidden" value={nodeData&&nodeData.datasetConfig?nodeData.datasetConfig.datasetId|| '':""} {...registerMeta("datasetId")}/>
                            <OffcanvasBody>
                                {
                                    nodeData && nodeData.datasetConfig && (nodeData.datasetConfig.sttusCd=='01' || nodeData.datasetConfig.sttusCd=='00')?
                                        <>
                                            <h5 className="module-title mb-0">
                                                <i className="ri-file-info-line"></i>{GetMessageIntl(intl,"workflow.metaFileInsertAndRuleSetting")}
                                            </h5>
                                            {
                                                nodeData.datasetConfig.metaInfoMapngCn?
                                                    <>
                                                        <div className={"d-flex align-items-center justify-content-between mt-16"}>
                                                            <span className={"tx-16 lh-20 fw-semibold "}>* {GetMessageIntl(intl,"workflow.metaDataInsertHistory")}</span>
                                                            <button type="button" className={"btn-coco-xs-2"} onClick={changeMetaInfoMode}>
                                                                {changeMetaInfo?
                                                                    GetMessageIntl(intl,"common.modifyCancle")
                                                                    :GetMessageIntl(intl,"common.modify")
                                                                }
                                                            </button>
                                                        </div>
                                                        <div className="metaInfoDiv">
                                                            <div className="ht-20">
                                                                <label>{GetMessageIntl(intl, "workflow.metaUploadFile")}</label>
                                                                {nodeData.datasetConfig.metaInfoMapngCn.uploadFileInfo.realFileName}
                                                            </div>
                                                            <div className="ht-20 mt-16">
                                                                <label>{GetMessageIntl(intl, "workflow.metaInfoMapngTy")}</label>
                                                                {
                                                                    nodeData.datasetConfig.metaInfoMapngTy=='01'?
                                                                        GetMessageIntl(intl, "workflow.batchInsert") : GetMessageIntl(intl, "workflow.keyInsert")
                                                                }
                                                            </div>
                                                        </div>
                                                    </>
                                                    :
                                                    null
                                            }
                                            {
                                                !nodeData.datasetConfig.metaInfoMapngCn || changeMetaInfo?
                                                    <Row>
                                                        <Col className="col-12 mt-16">
                                                            <Label htmlFor="metaFile" className="form-label">{GetMessageIntl(intl,"workflow.metaUpload")}<span className="text-coco-danger-400">*</span></Label>
                                                            <div className={errorsMeta.metaFile?"validation-error":""}>
                                                                <div className="input-group">
                                                                    <Controller
                                                                        render={({ field }) => <Input {...field} type="file" id="metaFile" accept="text/csv" />}
                                                                        name="metaFile"
                                                                        control={controlMeta}
                                                                        rules={{
                                                                            required: true,
                                                                        }}
                                                                        defaultValue=""
                                                                        className="form-control lh-18"
                                                                    />
                                                                </div>
                                                            </div>
                                                            {errorsMeta.metaFile && <p className="validation-error-message">{GetMessageIntl(intl,"validation.required")}</p>}
                                                        </Col>
                                                        <div className="col-12 mt-16">
                                                            <Label htmlFor="metaInfoMapngTy1" className="form-label">{GetMessageIntl(intl,"workflow.metaInfoMapngTy")}<span className="text-coco-danger-400">*</span></Label>
                                                            <div className="d-flex align-items-center">
                                                                <div className="form-check form-radio-coco form-check-inline">
                                                                    <input className="form-check-input" type="radio" id="metaInfoMapngTy1" value="01" {...registerMeta("metaInfoMapngTy")} defaultChecked/>
                                                                    <Label className="form-check-label mb-0" htmlFor="metaInfoMapngTy1">
                                                                        {GetMessageIntl(intl, "workflow.batchInsert")}
                                                                    </Label>
                                                                </div>
                                                                <div className="form-check form-radio-coco form-check-inline">
                                                                    <input className="form-check-input" type="radio" id="metaInfoMapngTy2" value="02" {...registerMeta("metaInfoMapngTy")}/>
                                                                    <Label className="form-check-label mb-0" htmlFor="metaInfoMapngTy2">
                                                                        {GetMessageIntl(intl, "workflow.keyInsert")}
                                                                    </Label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Row>
                                                    : null
                                            }
                                        </>
                                        :
                                        <div className={"d-flex align-items-center justify-content-center text-center tx-16 lh-18 fw-normal text-coco-gray-200"} style={{height:"650px"}}>
                                            {GetMessageIntl(intl, "workflow.uploadAfterMetaInsert1")}<br/>
                                            {GetMessageIntl(intl, "workflow.uploadAfterMetaInsert2")}
                                        </div>
                                }

                            </OffcanvasBody>
                            <div className="offcanvas-footer">
                                <div className={"w-100 d-flex align-items-center ".concat(nodeData && nodeData.datasetConfig && nodeData.datasetConfig.metaInfoMapngCn?"justify-content-between":"justify-content-end")}>
                                    {nodeData && nodeData.datasetConfig && nodeData.datasetConfig.metaInfoMapngCn?
                                        <div className={"d-flex align-items-center justify-content-start"}>
                                            <button className="btn btn-coco-1 mr-16" type="button" onClick={metaCsvDownload}>
                                                {GetMessageIntl(intl,"common.csvDownload")}
                                            </button>
                                            <button className="btn btn-coco-1 mr-16" type="button" onClick={metaPreviewDownload}>
                                                {GetMessageIntl(intl,"common.preview")}
                                            </button>
                                        </div>
                                        :
                                        null

                                    }
                                    <div>
                                        {
                                            nodeData && nodeData.datasetConfig&& (!nodeData.datasetConfig.metaInfoMapngCn || changeMetaInfo)?
                                                <button className="btn btn-coco-1 mr-16" type="submit">
                                                    {GetMessageIntl(intl,"common.save")}
                                                </button>
                                                :null
                                        }
                                        <button type="button" className="btn btn-coco-icon-1">
                                            <i className="ri-delete-bin-line"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </TabPane>
                </TabContent>
            </Offcanvas>

            <Modal
                id={"targetFileModal"}
                isOpen={targetFileModal}
                toggle={() => {
                    setTargetFileModal(false);
                }}
                centered={true}
                backdrop={true}
                keyboard={true}
                className={"border-0"}
            >
                <ModalBody className="pd-0">
                    {
                        prop.canvasDataTy && prop.canvasDataTy.substring(0,1) =='1'?
                            <img src={targetFileImage} width={804} height={538}/>
                            :
                            <div>{targetFileText}</div>
                    }

                </ModalBody>
            </Modal>

            <Modal
                id={"targetFileAudioModal"}
                isOpen={targetFileAudioModal}
                toggle={() => {
                    setTargetFileAudioModal(false);
                }}
                centered={true}
                backdrop={true}
                keyboard={true}
                size={"xl"}
            >
                <audio controls id={"targetFileAudio"} src={targetFileAudio} style={{width: "636px"}} controlsList={"nodownload"}/>
            </Modal>


            <Modal
                id={"targetFileVideoModal"}
                isOpen={targetFileVideoModal}
                toggle={() => {
                    setTargetVideoModal(false);
                }}
                backdrop={true}
                keyboard={true}
                className={"targetFileVideoModal".concat(horizon ? " horizon" : " vertical")}
            >
                {
                    targetFileVideo &&
                        <ReactPlayer
                            ref={videoRef}
                            url={targetFileVideo}
                            playing
                            width={"100%"}
                            height={"100%"}
                            controls={true}
                            pip={false}
                            config={{ file: {
                                    attributes: {
                                        controlsList: 'nodownload'
                                    }
                                }}}
                            onReady={handlePlayerReady}
                        />
                }

            </Modal>


            <Modal
                id={"targetFileTextModal"}
                isOpen={targetFileTextModal}
                toggle={() => {
                    setTargetFileTextModal(false);
                }}
                centered={true}
                backdrop={true}
                keyboard={true}
                size={"xl"}
            >
                <ModalHeader className="bg-coco-default-white-f border-bottom border-coco-gray-100" toggle={()=>setTargetFileTextModal(false)}>
                    {GetMessageIntl(intl, "common.detail")} <span className={"border-1 border-coco-gray-100 pd-x-8"}></span> <span className={"text-coco-gray-400 tx-16 fw-500"}>{targetFileNm}</span>
                </ModalHeader>
                <ModalBody>
                    <textarea className="form-control tx-16 fw-500" readOnly rows="30" placeholder="Placeholder" defaultValue={targetFileText} style={{resize: 'none'}}/>
                </ModalBody>
                <ModalFooter>
                    <Button
                        type="button"
                        className={"btn-coco-1"}
                        onClick={()=>setTargetFileTextModal(false)}
                    >
                        {GetMessageIntl(intl,"common.confirm")}
                    </Button>
                </ModalFooter>
            </Modal>

            <Modal id={"metaPreviewModal"}
                   isOpen={metaPreviewModal}
                   toggle={() => {
                       setMetaPreviewModal(false);
                   }}
                   centered={true}
                   backdrop={true}
                   keyboard={false}
                   size={"md"}>
                <ModalHeader className="bg-coco-default-white-f border-bottom border-coco-gray-100" toggle={()=>setMetaPreviewModal(false)}>
                    {GetMessageIntl(intl, "workflow.metaDataPreview")}
                </ModalHeader>
                <ModalBody>
                    <div className={"text-coco-gray-400 tx-16 fw-500 mb-8"}>{GetMessageIntl(intl,"workflow.metaDataInfo",{"fileNm": metaPreviewData?metaPreviewData.fileNm:null})}</div>
                    <textarea className="form-control" rows="10" placeholder="Placeholder" defaultValue={metaPreviewData?metaPreviewData.metaInfoCn:null}/>
                </ModalBody>
                <ModalFooter>
                    <Button
                        type="button"
                        className={"btn-coco-1"}
                        onClick={()=>setMetaPreviewModal(false)}
                    >
                        {GetMessageIntl(intl,"common.confirm")}
                    </Button>
                </ModalFooter>
            </Modal>


            <Modal id={"alertLocalFilesModal"}
                   isOpen={alertLocalFilesModal}
                   toggle={() => {
                       setAlertLocalFilesModal(false);
                   }}
                   centered={true}
                   backdrop={true}
                   keyboard={false}>
                <ModalBody className="pd-x-24 pd-t-32 pd-b-0"  style={{paddingTop:"32px", paddingLeft : "24px", paddingRight : "24px"}}>
                    <div className="swal2-icon swal2-info swal2-icon-show" style={{display: 'flex'}}>
                        <div className="swal2-icon-content">i</div>
                    </div>
                    <h2 className="swal2-title tx-20 pd-0" style={{display: 'block', textAlign:"center"}}>{GetMessageIntl(intl,"workflow.uploadDatasetIngTitle")}</h2>
                    <div className="swal2-html-container" style={{display: 'block', textAlign:"center"}} dangerouslySetInnerHTML={{ __html: GetMessageIntl(intl,"workflow.uploadDatasetIngLocalMsg")}}></div>
                </ModalBody>
                <ModalFooter className={"justify-content-center mt-20"} style={{paddingBottom:"32px", paddingLeft : "24px", paddingRight : "24px"}}>
                    <button type="button" className={"btn btn-coco-1 wd-349px bg-coco-primary-400-f ".concat("disabled")}>
                        <span className="d-flex align-items-center justify-content-center">
                            <Spinner size="sm" className="flex-shrink-0"> Loading... </Spinner>
                            <span className="ms-2">
                                업로드 진행중
                            </span>
                        </span>
                    </button>
                </ModalFooter>
            </Modal>

            <Modal id={"alertFileRejectionModal"}
                   isOpen={alertFileRejectionModal}
                   toggle={() => {
                       setAlertFileRejectionModal(false);
                   }}
                   centered={true}
                   backdrop={true}
                   keyboard={false}>
                <ModalBody className="pd-x-24 pd-t-32 pd-b-0"  style={{paddingTop:"32px", paddingLeft : "24px", paddingRight : "24px"}}>
                    <div className="swal2-icon swal2-info swal2-icon-show" style={{display: 'flex'}}>
                        <div className="swal2-icon-content">i</div>
                    </div>
                    <h2 className="swal2-title tx-20 pd-0 text-coco-gray-700" style={{display: 'block', textAlign:"center"}}>{GetMessageIntl(intl,"alert.fileRejectionMsg")}</h2>
                    <div className="swal2-html-container  text-coco-gray-700" style={{display: 'block', textAlign:"center"}}>
                        {GetMessageIntl(intl, "alert.fileRejectionDetail", {cnt: fileRejections.length})}
                    </div>
                </ModalBody>
                <ModalFooter className={"justify-content-center mt-20"} style={{paddingBottom:"32px", paddingLeft : "24px", paddingRight : "24px"}}>
                    <button type="button" className={"btn btn-coco-1 wd-349px bg-coco-primary-400-f"} onClick={()=>setAlertFileRejectionModal(false)}>
                        {GetMessageIntl(intl,"common.confirm")}
                    </button>
                </ModalFooter>
            </Modal>

        </div>
    );
};
