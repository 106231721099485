import React, {useEffect, useState} from 'react';
import {Container, Form} from "reactstrap";
import {GetMessageIntl} from "../../util/message";
import {useIntl} from "react-intl";
import Select from "react-select";
import {CommonService} from "../../apis/api/CmmnService";
import {confirm} from "../../util/AlertUtil";

function MemberJoin() {
    const intl = useIntl();
    const [jobOptions, setJobOptions] = useState([]);
    useEffect(() => {
        async function makeJobSelectBox() {
            let resData = await CommonService.getJobCodeList();
            // GetMessageIntl(intl, "memberJoin.chooseJob")
            setJobOptions(resData.data.data.map(item => {
                return {value: item.cmmnDetailCd, label: item.cmmnDetailCdNm}
            }));
        }
        makeJobSelectBox().then(() => {
        });
    }, [])

    let mode = "I"

    const handleSubmit = (event) => {
        event.preventDefault();
    }

    const [formValue, setFormValue] = useState({
        email: "",
        password: "",
        passwordConfirm:"",
        name: "",
        gender: "01",
        birthDay: "",
        job: "",
        phone: ""
    });

    const beforeSubmit = (event) => {
        event.preventDefault();
        console.log(formValue)
    }

    return (
        <>
            <Container fluid>
                <div className="row justify-content-center align-items-center" style={{height: "calc(100vh - 150px)"}}>
                    <div className="col-md-8 col-lg-6 col-xl-5">
                        <div className="card shadow-none">
                            <div className="card-body p-4 pb-0">
                                <div className="mt-2 text-coco-primary-400 border-bottom border-coco-primary-400-f pb-3">
                                    <div className="tx-24 fw-bold">
                                        {GetMessageIntl(intl, "memberJoin.createAccount")}
                                    </div>
                                </div>
                                <div className="mt-4">
                                    <Form onSubmit={handleSubmit}>
                                        <input type="hidden" name="mode" value={mode}/>
                                        <input type="hidden" name="userRole" value="20"/>
                                        <input type="hidden" name="userTimezone" value="Asia/Seoul"/>
                                        <div className="mb-3">
                                            <label htmlFor="userId" className="form-label text-coco-gray-700 fw-semibold">
                                                {GetMessageIntl(intl, "memberJoin.email")}
                                                <span className="text-coco-danger-400">*</span></label>
                                            <div className="input-group">
                                                <input type="email" className="form-control" id="email" name="email" autoComplete="off"
                                                       placeholder={GetMessageIntl(intl, "memberJoin.emailRequired")} required="required"
                                                       data-parsley-errors-container="#email-error-container"
                                                       onChange={(value) => {
                                                           setFormValue((state) => ({
                                                               ...state,
                                                               email: value.target.value
                                                           }));
                                                       }}

                                                />
                                                <button type="button" className="btn btn-success checkExistUserId"> {GetMessageIntl(intl, "memberJoin.dupCheck")}</button>
                                            </div>
                                            <div id="email-error-container"></div>
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="userPwd" className="form-label text-coco-gray-700 fw-semibold">
                                                {GetMessageIntl(intl, "memberJoin.password")}
                                                <span className="text-coco-danger-400">*</span></label>
                                            <input type="password"
                                                   className="form-control"
                                                   id="userPwd"
                                                   name="userPwd"
                                                   autoComplete="off"
                                                   placeholder={GetMessageIntl(intl, "memberJoin.passwordRequired")}
                                                   required="required"
                                                   minLength="9"
                                                   data-parsley-pattern="^.*(?=.*\d)(?=.*[a-zA-Z])(?=.*[!@#$%^&+=]).*$"
                                                   data-parsley-pattern-message={GetMessageIntl(intl, "memberJoin.passwordValidationFail")}
                                                   onChange={(value) => {
                                                       setFormValue((state) => ({
                                                           ...state,
                                                           password: value.target.value
                                                       }));
                                                   }}
                                            />
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="userPwdConfirm" className="form-label text-coco-gray-700 fw-semibold">
                                                {GetMessageIntl(intl, "memberJoin.passwordAgain")}
                                                <span className="text-coco-danger-400">*</span></label>
                                            <input type="password"
                                                   autoComplete="off"
                                                   className="form-control"
                                                   id="userPwdConfirm"
                                                   name="userPwdConfirm"
                                                   placeholder={GetMessageIntl(intl, "memberJoin.passwordConfirm")}
                                                   required="required"
                                                   data-parsley-equalto="#userPwd"
                                                   data-parsley-equalto-message={GetMessageIntl(intl, "memberJoin.passwordNotMatch")}
                                                   onChange={(value) => {
                                                       setFormValue((state) => ({
                                                           ...state,
                                                           passwordConfirm: value.target.value
                                                       }));
                                                   }}

                                            />
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="userNm" className="form-label text-coco-gray-700 fw-semibold">
                                                {GetMessageIntl(intl, "memberJoin.name")}
                                                <span className="text-coco-danger-400">*</span></label>
                                            <input type="text"
                                                   className="form-control"
                                                   id="userNm"
                                                   name="userNm"
                                                   placeholder={GetMessageIntl(intl, "memberJoin.nameRequired")}
                                                   required="required"
                                                   onChange={(value) => {
                                                       setFormValue((state) => ({
                                                           ...state,
                                                           name: value.target.value
                                                       }));
                                                   }}
                                            />
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="sexBtn1" className="form-label text-coco-gray-700 fw-semibold">
                                                {GetMessageIntl(intl, "memberJoin.gender")}
                                                <span className="text-coco-danger-400">*</span></label>
                                            <div>
                                                <div className="form-check form-radio-success form-check-inline">
                                                    <input id="sexBtn1" name="btnradio" type="radio" className="form-check-input" value="01" required="" defaultChecked={true}
                                                           onChange={(value) => {
                                                               console.log(value.target.value)
                                                               setFormValue((state) => ({
                                                                   ...state,
                                                                   gender: value.target.value
                                                               }));
                                                           }}
                                                    />
                                                    <label className="form-check-label" htmlFor="sexBtn1">
                                                        {GetMessageIntl(intl, "memberJoin.genderMale")}
                                                    </label>
                                                </div>
                                                <div className="form-check form-radio-success form-check-inline">
                                                    <input id="sexBtn2" name="btnradio" type="radio" className="form-check-input" value="02" required=""
                                                           onChange={(value) => {
                                                               console.log(value.target.value)
                                                               setFormValue((state) => ({
                                                                   ...state,
                                                                   gender: value.target.value
                                                               }));
                                                           }}
                                                    />
                                                    <label className="form-check-label" htmlFor="sexBtn2">
                                                        {GetMessageIntl(intl, "memberJoin.genderFemale")}
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="birthDay" className="form-label text-coco-gray-700 fw-semibold">
                                                {GetMessageIntl(intl, "memberJoin.yyyyMMdd")}
                                                <span className="text-coco-danger-400">*</span></label>
                                            <input type="text" id="birthDay" name="birthDay" className="form-control" placeholder="YYYY-MM-DD" required="required" maxLength="10"
                                                   onChange={(value) => {
                                                       setFormValue((state) => ({
                                                           ...state,
                                                           birthDay: value.target.value
                                                       }));
                                                   }}
                                            />
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="jobSelect" className="form-label text-coco-gray-700 fw-semibold">
                                                {GetMessageIntl(intl, "memberJoin.job")}
                                            </label>
                                            <Select id="jobSelect"
                                                    options={jobOptions}
                                                    onChange={(value) => {
                                                        setFormValue((state) => ({
                                                            ...state,
                                                            job: value.value
                                                        }));
                                                    }}
                                                    placeholder={GetMessageIntl(intl, "memberJoin.chooseJob")}/>
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="telNo" className="form-label text-coco-gray-700 fw-semibold">
                                                {GetMessageIntl(intl, "memberJoin.phone")}
                                            </label>
                                            <input type="text"
                                                   id="telNo"
                                                   name="telNo"
                                                   className="form-control"
                                                   placeholder={GetMessageIntl(intl, "memberJoin.phone")}
                                                   required="required"
                                                   maxLength="20"
                                                   data-parsley-pattern="^([+][0-9]{1,4})?[-.\s]?[0-9]{2,3}[-.\s]?[0-9]{3,4}[-.\s]?[0-9]{4}"
                                                   onChange={(value) => {
                                                       setFormValue((state) => ({
                                                           ...state,
                                                           phone: value.target.value
                                                       }));
                                                   }}
                                            />
                                        </div>
                                        <div className="mt-4">
                                            <button onClick={beforeSubmit} className="btn btn-success w-100" type="button">{GetMessageIntl(intl, "memberJoin.join")}</button>
                                        </div>
                                    </Form>
                                </div>
                            </div>
                        </div>
                        <div className="mt-4 text-center">
                            <p className="mb-0">
                                {GetMessageIntl(intl, "memberJoin.alreadyHave")}
                                <a href="/login" className="ms-2 fw-semibold text-coco-primary-400 text-decoration-underline">
                                    {GetMessageIntl(intl, "memberJoin.goLogin")}
                                </a></p>
                        </div>
                    </div>
                </div>
            </Container>
        </>
    )
}

export default MemberJoin;