import React, {useState} from 'react';
import {
    alertError,
    alertInfo,
    alertSuccess,
    alertWarning,
    confirm, confirm3Button,
    confirmSuccess,
    confirmWarning, confirmWarningClose
} from "../../util/AlertUtil";
import {
    Accordion,
    Button,
    Card,
    CardBody,
    CardHeader,
    Col,
    Input,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    Row, Spinner
} from "reactstrap";
import {useIntl} from "react-intl";
import {GetMessage, GetMessageIntl} from "../../util/message";
import {board_switchRefreshFlag} from "../../store/board/action";
import {setTaskRefreshFlag, setTaskTestModalFlag} from "../../store/user/task/action";
import laflowLogo from "../../assets/images/laflow_logo_01.png";
import {FormProvider} from "react-hook-form";
import SimpleBar from "simplebar-react";
import {QuestionAccordion} from "../user/project/TaskTestModal";
import {Link} from "react-router-dom";

const SweetAlertTest = () => {
    const intl = useIntl();

    const [smModalFlag, setSmModalFlag] = useState(false);
    const [lgModalFlag, setLgModalFlag] = useState(false);
    const [xlModalFlag, setXlModalFlag] = useState(false);
    const [fullModalFlag, setFullModalFlag] = useState(false);
    const [testModalFlag, setTestModalFlag] = useState(false);

    const clickQuestion = () => {
        confirm("user.allTasks", "user.allTasks", () => {
            console.log('예 버튼 눌렀지')
            confirm("user.allTasks", "user.allTasks", () => {
                console.log('예 버튼 눌렀지')
            }, () => {
                console.log('아니오 버튼 눌렀지')
            }, null, intl)
        }, () => {
            console.log('아니오 버튼 눌렀지')
        }, null, intl)
    }



    const clickWarning = () => {
        confirmWarning("user.allTasks", "user.allTasks", () => {
            console.log('예 버튼 눌렀지')
            confirm("user.allTasks", "user.allTasks", () => {
                console.log('예 버튼 눌렀지')
            }, () => {
                console.log('아니오 버튼 눌렀지')
            }, null, intl)
        }, () => {
            console.log('아니오 버튼 눌렀지')
        }, null, intl)
    }

    const clickWarningWithClose = () => {
        confirmWarningClose("user.allTasks", "user.allTasks", () => {
            console.log('예 버튼 눌렀지')
            confirm("user.allTasks", "user.allTasks", () => {
                console.log('예 버튼 눌렀지')
            }, () => {
                console.log('아니오 버튼 눌렀지')
            }, null, intl)
        }, () => {
            console.log('아니오 버튼 눌렀지')
        }, null, intl)
    }

    const clickSuccess=()=>{
        confirmSuccess("user.allTasks", "user.allTasks", () => {
            console.log('예 버튼 눌렀지')
            confirm("user.allTasks", "user.allTasks", () => {
                console.log('예 버튼 눌렀지')
            }, () => {
                console.log('아니오 버튼 눌렀지')
            }, null, intl)
        }, () => {
            console.log('아니오 버튼 눌렀지')
        }, null, intl)
    }

    const clickAlertWarning=()=>{
        alertWarning("경고창에 대한 강조 문구를 작성합니다.", "경고창에 대한 설명 문구를 작성합니다.", ()=>{
            console.log("확인 버튼");
        }, null, null, intl)
    }

    const clickAlertInfo=()=>{
        alertInfo("경고창에 대한 강조 문구를 작성합니다.", "경고창에 대한 설명 문구를 작성합니다.", ()=>{
            console.log("확인 버튼");
        }, null, intl)
    }

    const clickAlertSuccess=()=>{
        alertSuccess("경고창에 대한 강조 문구를 작성합니다.", "경고창에 대한 설명 문구를 작성합니다.", ()=>{
            console.log("확인 버튼");
        }, null, intl)
    }

    const clickAlertError=()=>{
        alertError("경고창에 대한 강조 문구를 작성합니다.", "경고창에 대한 설명 문구를 작성합니다.", ()=>{
            console.log("확인 버튼");
        }, null, null, intl)
    }


    const click3ButtonConfirm=()=>{
        confirm3Button("경고창에 대한 강조 문구를 작성합니다.", "경고창에 대한 설명 문구를 작성합니다.", ()=>{
            console.log("1번째 버튼");
        }, ()=>{
            console.log("2번째 버튼");
        }, ()=>{
            console.log("3번째 버튼");
        }, null, {
            confirmButtonText : '예',
            denyButtonText: '예',
            cancelButton: '예'
        }, intl)
    }


    return (
        <>
            <Row>
                <Col lg={12}>
                    <Card>
                        <CardHeader className="fw-semibold">
                            경고창 버튼
                        </CardHeader>
                        <CardBody className="card-body">
                            <div className="live-preview">
                                <Row className="gy-4">
                                    <Col xxl={2} md={6}>
                                        <div>
                                            <Label htmlFor="placeholderInput" className="form-label">question</Label>
                                            <div>
                                                <Button onClick={clickQuestion} color="info">confirm</Button>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col xxl={2} md={6}>
                                        <div>
                                            <Label htmlFor="placeholderInput" className="form-label">info</Label>
                                            <div>
                                                <Button onClick={clickAlertInfo} color="secondary">alert</Button>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col xxl={2} md={6}>
                                        <div>
                                            <Label htmlFor="placeholderInput" className="form-label">success</Label>
                                            <div>
                                                <Button onClick={clickSuccess} color="info" className={"me-1"}>confirm</Button>
                                                <Button onClick={clickAlertSuccess} color="secondary">alert</Button>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col xxl={2} md={6}>
                                        <div>
                                            <Label htmlFor="placeholderInput" className="form-label">warning</Label>
                                            <div>
                                                <Button onClick={clickWarning} color="info" className={"me-1"}>confirm</Button>
                                                <Button onClick={clickWarningWithClose} color="warning">confirm with close</Button>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col xxl={2} md={6}>
                                        <div>
                                            <Label htmlFor="placeholderInput" className="form-label">error</Label>
                                            <div>
                                                <Button onClick={clickAlertError} color="secondary">alert</Button>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col xxl={2} md={6}>
                                        <div>
                                            <Label htmlFor="placeholderInput" className="form-label">3 Button</Label>
                                            <div>
                                                <Button onClick={click3ButtonConfirm} color="success">confirm</Button>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </CardBody>
                    </Card>
                </Col>
                <Col lg={12}>
                    <Card>
                        <CardHeader className="fw-semibold">
                            모달 버튼 <span className="text-pointer text-secondary" onClick={()=> window.open("https://reactstrap.github.io/?path=/docs/components-modal--modal")}>해당 참고</span>
                        </CardHeader>
                        <CardBody className="card-body">
                            <div className="live-preview">
                                <Row className="gy-4">
                                    <Col xxl={2} md={6}>
                                        <div>
                                            <Label htmlFor="placeholderInput" className="form-label">sm</Label>
                                            <div>
                                                <Button onClick={()=>{setSmModalFlag(true)}} color="secondary">sm 524</Button>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col xxl={2} md={6}>
                                        <div>
                                            <Label htmlFor="placeholderInput" className="form-label">md lg</Label>
                                            <div>
                                                <Button onClick={()=>{setLgModalFlag(true)}} color="secondary">md lg 864 </Button>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col xxl={2} md={6}>
                                        <div>
                                            <Label htmlFor="placeholderInput" className="form-label">xl</Label>
                                            <div>
                                                <Button onClick={()=>{setXlModalFlag(true)}} color="secondary">xl 1198</Button>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col xxl={2} md={6}>
                                        <div>
                                            <Label htmlFor="placeholderInput" className="form-label">full</Label>
                                            <div>
                                                <Button onClick={()=>{setFullModalFlag(true)}} color="secondary" className={"me-1"}>full 1920</Button>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col xxl={2} md={6}>
                                        <div>
                                            <Label htmlFor="placeholderInput" className="form-label">test</Label>
                                            <div>
                                                <Button onClick={()=>{setTestModalFlag(true)}} color="secondary" className={"me-1"}>Test</Button>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </CardBody>
                    </Card>
                </Col>
           </Row>
            <Modal
                isOpen={smModalFlag}
                toggle={() => {
                    setSmModalFlag(false)
                }}
                size={"sm"}
                backdrop="static"
                keyboard={false}
                className="fw-bold"
            >
                <div className={"modal-header pd-y-20 bg-coco-default-white-f border-coco-gray-100 d-flex align-items-center"}
                     style={{borderBottomWidth: "1px"}}>
                    <div className={"modal-title d-flex align-itmes-center  justify-content-between w-100"}>
                        타이틀
                    </div>
                    <button className="close p-0 border-0 bg-transparent" onClick={()=>{
                        setSmModalFlag(false)
                    }} type="button">
                        <i className={"ri-close-line tx-24"}/>
                    </button>
                </div>
                <ModalBody className="fs-16 fw-500">
                    안녕하세요 작은 사이즈 모달입니다.
                    혹시나 Modal footer에 border을 없애시려면 border-coco-gray-100 className을 지우고 style을 지우세요
                </ModalBody>
                <ModalFooter className={"pd-y-12 border-coco-gray-100"} style={{borderTopWidth: "1px"}}>
                    <button type="button" className={"btn btn-coco-3"} onClick={()=>{setSmModalFlag(false )}}>
                        닫기
                    </button>
                </ModalFooter>
            </Modal>

            <Modal
                isOpen={lgModalFlag}
                toggle={() => {
                    setLgModalFlag(false)
                }}
                size={"lg"}
                backdrop="static"
                keyboard={false}
                className="fw-bold"
            >
                <div className={"modal-header pd-y-20 bg-coco-default-white-f border-coco-gray-100 d-flex align-items-center"}
                     style={{borderBottomWidth: "1px"}}>
                    <div className={"modal-title d-flex align-itmes-center  justify-content-between w-100"}>
                        타이틀
                    </div>
                    <button className="close p-0 border-0 bg-transparent" onClick={()=>{
                        setLgModalFlag(false)
                    }} type="button">
                        <i className={"ri-close-line tx-24"}/>
                    </button>
                </div>
                <ModalBody className="fs-16 fw-500">
                    안녕하세요 lg 사이즈 모달입니다.
                    혹시나 Modal footer에 border을 없애시려면 border-coco-gray-100 className을 지우고 style을 지우세요
                </ModalBody>
                <ModalFooter className={"pd-y-12 border-coco-gray-100"} style={{borderTopWidth: "1px"}}>
                    <button type="button" className={"btn btn-coco-3"} onClick={()=>{setLgModalFlag(false )}}>
                        닫기
                    </button>
                </ModalFooter>
            </Modal>

            <Modal
                isOpen={xlModalFlag}
                toggle={() => {
                    setXlModalFlag(false)
                }}
                size={"xl"}
                backdrop="static"
                keyboard={false}
                className="fw-bold"
            >
                <div className={"modal-header pd-y-20 bg-coco-default-white-f border-coco-gray-100 d-flex align-items-center"}
                     style={{borderBottomWidth: "1px"}}>
                    <div className={"modal-title d-flex align-itmes-center  justify-content-between w-100"}>
                        타이틀
                    </div>
                    <button className="close p-0 border-0 bg-transparent" onClick={()=>{
                        setXlModalFlag(false)
                    }} type="button">
                        <i className={"ri-close-line tx-24"}/>
                    </button>
                </div>
                <ModalBody className="fs-16 fw-500">
                    안녕하세요 xl 사이즈 모달입니다.
                    혹시나 Modal footer에 border을 없애시려면 border-coco-gray-100 className을 지우고 style을 지우세요
                </ModalBody>
                <ModalFooter className={"pd-y-12 border-coco-gray-100"} style={{borderTopWidth: "1px"}}>
                    <button type="button" className={"btn btn-coco-3"} onClick={()=>{setXlModalFlag(false )}}>
                        닫기
                    </button>
                </ModalFooter>
            </Modal>

            <Modal
                fullscreen={true}
                isOpen={fullModalFlag}
                toggle={() => {
                    setFullModalFlag(false)
                }}
                backdrop="static"
                keyboard={false}
                className="fw-bold"
                id="fullModalFlag"
            >
                <div className={"modal-header bg-coco-default-white-f border-coco-gray-100 d-flex align-items-center"}
                     style={{borderBottomWidth: "1px"}}>
                    <div className={"modal-title d-flex align-itmes-center  justify-content-between w-100"}>
                        타이틀
                    </div>
                    <button className="close p-0 border-0 bg-transparent" onClick={()=>{
                        setFullModalFlag(false)
                    }} type="button">
                        <i className={"ri-close-line tx-24"}/>
                    </button>
                </div>
                <ModalBody className="fs-16 fw-500">
                    안녕하세요 full 사이즈 모달입니다.
                    혹시나 Modal footer에 border을 없애시려면 border-coco-gray-100 className을 지우고 style을 지우세요
                </ModalBody>
                <ModalFooter className={"pd-y-12 border-coco-gray-100"} style={{borderTopWidth: "1px"}}>
                    <button type="button" className={"btn btn-coco-3"} onClick={()=>{setFullModalFlag(false )}}>
                        닫기
                    </button>
                </ModalFooter>
            </Modal>

            <Modal id={"alertLocalFilesModal"}
                   isOpen={testModalFlag}
                   toggle={() => {
                       setTestModalFlag(false);
                   }}
                   centered={true}
                   backdrop={true}
                   keyboard={false}>
                <ModalBody className="pd-x-24 pd-t-32 pd-b-0" style={{paddingTop:"32px", paddingLeft : "24px", paddingRight : "24px"}}>
                    <div className="swal2-icon swal2-info swal2-icon-show" style={{display: 'flex'}}>
                        <div className="swal2-icon-content">i</div>
                    </div>
                    <h2 className="swal2-title tx-20 pd-0" style={{display: 'block', textAlign:"center"}}>{GetMessageIntl(intl,"workflow.uploadDatasetIngTitle")}</h2>
                    <div className="swal2-html-container" style={{display: 'block', textAlign:"center"}} dangerouslySetInnerHTML={{ __html: GetMessageIntl(intl,"workflow.uploadDatasetIngLocalMsg")}}></div>
                </ModalBody>
                <ModalFooter className={"justify-content-center mt-20"} style={{paddingBottom:"32px", paddingLeft : "24px", paddingRight : "24px"}}>
                    <button type="button" className={"btn btn-coco-1 wd-349px bg-coco-primary-400-f ".concat("disabled")}>
                        <span className="d-flex align-items-center justify-content-center">
                            <Spinner size="sm" className="flex-shrink-0"> Loading... </Spinner>
                            <span className="ms-2">
                                업로드 진행중
                            </span>
                        </span>
                    </button>
                </ModalFooter>
            </Modal>

        </>
    );
}

export default SweetAlertTest;