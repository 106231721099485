import React, {useCallback, useEffect, useRef, useState} from 'react'
import {Button, Col, Row} from "reactstrap";
import {FilePond, registerPlugin} from 'react-filepond'
import 'filepond/dist/filepond.min.css'
import CommonUtil from "../../util/CommonUtil";
import {ModuleService} from "../../apis/api/ModuleService";

const ModuleDataConfigUpdateTest = () => {

    const [fileForm, setFileForm] = useState([]);
    const [files, setFiles] = useState({});


    const style = {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '20px',
        borderWidth: 2,
        borderRadius: 2,
        borderColor: '#eeeeee',
        borderStyle: 'dashed',
        backgroundColor: '#fafafa',
        color: '#bdbdbd',
        outline: 'none',
        transition: 'border .24s ease-in-out'
    };

    const setFilesByComponent = (id, file) => {

        const key = id + "";
        const value = {
            [key]: file
        }
        setFiles({...files, ...value})
    }


    const go = () => {
        const submitData = new FormData();
        for (let i = 0; i < fileForm.length; i++) {
            const fileData = new FormData();
            const key = fileForm[i];
            const thisItem = files[key];
            for (let j = 0; j < thisItem.length; j++) {
                // appendFiles.push({realFilename : thisItem[j].file.name})
                submitData.append("files[" + i + "].dataAttachFile[]", thisItem[j].file)
            }
            submitData.append("files["+i+"].id", key)

        }

        const moduleConfig = {
           "dataConfig": [
                {
                    "id": "cvt1xd",
                    "body": {
                        "title": "",
                        "content": "게시판 글 내용 ",
                        "useTarget": "03",
                        // 자동으로 추가되는 구문이므로 데이터 전송시에는 추가하지 않아도 됨
                        // 단 attachFiles : [] 는 존재해야 함
                        "attachFiles": [
                        ]
                    },
                    // 삭제할 파일이 있을 경우에 deleteFiles 추가
                    "deleteFiles": [
                        {
                            "downloadUrl": "module/data/MD5c3bc558-3747-4ca9-9154-0d49600dca27/cvt1xd/d53962b4-055e-3f4a-b165-0ce1ba2bcde8.jpg",
                            "realFilename": "1000124_592.jpg"
                        }],
                    "type": "editor",
                    "order": 1,
                    "title": "editor 형식",
                    "useYn": "Y"
                },
                {
                    "id": "7w9vxh",
                    "body": {
                        "title": "",
                        "content": [
                            {
                                "정의필요": ""
                            }
                        ],
                        "description": ""
                    },
                    "type": "form",
                    "order": 2,
                    "title": "form 형식",
                    "useYn": "Y"
                },
                {
                    "id": "f5rwqu",
                    "body": {
                        "title": "",
                        "content": "게시판 글 내용 ",
                        "attachFiles": [
                            {
                                "downloadUrl": "module/data/MD5c3bc558-3747-4ca9-9154-0d49600dca27/f5rwqu/96f4f349-43e9-33af-b6ef-97ee11e93b2e.jpg",
                                "realFilename": "1000132_592.jpg"
                            },
                            {
                                "downloadUrl": "module/data/MD5c3bc558-3747-4ca9-9154-0d49600dca27/f5rwqu/96f4f349-43e9-33af-b6ef-97ee11e93b2e.jpg",
                                "realFilename": "1000132_592.jpg"
                            }
                        ]
                    },
                    "type": "checkbox",
                    "order": 3,
                    "title": "checkbox 형식",
                    "useYn": "Y"
                },
                {
                    "id": "938475",
                    "body": {
                        "title": "",
                        "content": "게시판 글 내용 ",
                        "attachFiles": []
                    },
                    "type": "checkbox",
                    "order": 0,
                    "title": "checkbox 형식",
                    "useYn": "N"
                }
            ]
        }
        submitData.append("moduleId", "MD5c3bc558-3747-4ca9-9154-0d49600dca27");
        submitData.append("moduleTy", "01");
        submitData.append("moduleConfig", JSON.stringify(moduleConfig));

        // let param = {};
        // param.moduleId = "MD5c3bc558-3747-4ca9-9154-0d49600dca27";
        // param.moduleTy = "01";
        // param.moduleConfig = JSON.stringify(moduleConfig);


        console.log("submitData ->", submitData)
        // ModuleService
        //     .updateModuleItemWithFile(submitData, {contentType: 'multipart/form-data'})
        //     .then(result => {
        //         console.log(result)
        //     }).catch((error) => {
        //     }
        // );
    }

    const addForm = () => {
        const tList = [];
        tList.push(CommonUtil.generateUID())
        setFileForm(fileForm.concat(tList))
    }
    return (
        <>
            <Row>
                <Col>
                    <Button onClick={go}>전송</Button>
                    <Button onClick={addForm}>파일 폼 추가</Button>
                </Col>
            </Row>
            <Row style={{height: "200px"}}>
                <Col xxl={3}>
                    <Row>
                        <Col xxl={12}>
                            {fileForm.map((item) => {
                                const name = "files_" + item;
                                return (<FileForm item={item} name={name} key={item} fileHandle={setFilesByComponent}/>)
                            })}
                        </Col>
                    </Row>
                </Col>
            </Row>
        </>
    )
}

const FileForm = (prop) => {
    const [files, setFiles] = useState([]);

    useEffect(() => {
        prop.fileHandle(prop.item, files)
    }, [files])

    return (
        <>
            <FilePond
                key={prop.item}
                files={files}
                onupdatefiles={setFiles}
                chunkUploads={true}
                allowMultiple={true}
                maxFiles={3}
                instant-upload={false}
                name={prop.name}
                labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
                credits={false}
            />
        </>
    )
}


export default ModuleDataConfigUpdateTest;