import {
    SET_CURRENT_PAGE,
    SET_WRKFLOW_ID,
    SET_WRKFLOW_OPTION,
    SET_TASK_ID,
    SET_TASK_OPTION,
    SET_WORK_DTM,
    SET_WORKER_NM,
    SET_SEARCH_PARAM,
    SET_MOUNT_CNT,
    SET_WRKFLOW_WITH_MODULES,
    SET_CANVAS_DATA_TY
} from "./actionType"

const INIT_STATE = {
    currentPage: 1,
    wrkflowId: null,
    wrkflowOption: [],
    wrkflowWithModules: [],
    taskOption: [],
    taskId: null,
    workDtm: "",
    workerNm: "",
    searchParam: [],
    mount: 1,
    canvasDataTy: null
}

const ManageWorker = (state = INIT_STATE, action) => {
    switch (action.type) {
        case SET_CURRENT_PAGE:
            return{
                ...state,
                currentPage: action.payload
            }
        case SET_WRKFLOW_ID:
            return {
                ...state,
                wrkflowId: action.payload
            }
        case SET_WRKFLOW_OPTION:
            return {
                ...state,
                wrkflowOption: action.payload
            }
        case SET_WRKFLOW_WITH_MODULES:
            return {
                ...state,
                wrkflowWithModules: action.payload
            }
        case SET_TASK_ID:
            return {
                ...state,
                taskId: action.payload
            }
        case SET_TASK_OPTION:
            return {
                ...state,
                taskOption: action.payload
            }
        case SET_WORK_DTM:
            return {
                ...state,
                workDtm: action.payload
            }
        case SET_WORKER_NM:
            return {
                ...state,
                workerNm: action.payload
            }
        case SET_SEARCH_PARAM:
            return {
                ...state,
                searchParam: [action.payload]
            }
        case SET_MOUNT_CNT:
            return {
                ...state,
                mount: action.payload
            }
        case SET_CANVAS_DATA_TY :
            return {
                ...state,
                canvasDataTy: action.payload
            }
        default:
            return state;
    }
}

export default ManageWorker;