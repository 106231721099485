import React from 'react';
import dateFormat from 'dateformat';
import _ from 'lodash';
import Swal from "sweetalert2";
import {GetMessage} from "./message";

class CommonUtil {
    // constructor() {
    // }

    // 이메일 주소 검증 정규식 (RFC 5322 형식)
    static emailValidationRegex(){
       return new RegExp("([!#-'*+/-9=?A-Z^-~-]+(\.[!#-'*+/-9=?A-Z^-~-]+)*|\"\(\[\]!#-[^-~ \t]|(\\[\t -~]))+\")@([!#-'*+/-9=?A-Z^-~-]+(\.[!#-'*+/-9=?A-Z^-~-]+)*|\[[\t -Z^-~]*])");
    }

    static generateUID() {
        // I generate the UID from two parts here
        // to ensure the random number provide enough bits.
        var firstPart = (Math.random() * 46656) | 0;
        var secondPart = (Math.random() * 46656) | 0;
        firstPart = ("000" + firstPart.toString(36)).slice(-3);
        secondPart = ("000" + secondPart.toString(36)).slice(-3);
        return firstPart + secondPart;
    }

    static getByteConvertNumber(byteNum) {
        let l = 0
        let n = parseInt(byteNum, 10) || 0;
        let units = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

        while (n >= 1024 && ++l) {
            n = n / 1024;
        }
        return (n.toFixed(2) + ' ' + units[l]);
    }

    static bytesToSize(bytes) {
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB']
        if (bytes === 0) return '0'
        const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10)
        if (i === 0) return `${bytes} ${sizes[i]})`
        return `${(bytes / (1024 ** i)).toFixed(1)} ${sizes[i]}`
    }

    static getCamelToSnakeStr(targetStr, upperYn) {
        let returnStr = '';

        if (targetStr !== undefined && targetStr !== null) {
            let splitTargetStr = targetStr.split(/(?=[A-Z])/);
            if (upperYn !== undefined && upperYn !== null && upperYn) {
                returnStr = splitTargetStr.join('_').toUpperCase();
            } else {
                returnStr = splitTargetStr.join('_').toLowerCase();
            }
        }
        return returnStr;
    }

    //금일 날짜를 yyyy-mm-dd형태로 가져옴
    static getToDay() {
        return dateFormat(new Date(), "yyyy-mm-dd");
    }

    //금일 날짜를 yyyy-mm-dd HH:MM:ss형태로 가져옴
    static getToDayTime() {
        return dateFormat(new Date(), "yyyy-mm-dd HH:MM:ss");
    }

    //날짜 포멧을 변경함.
    static changeFormat(date, format) {
        return dateFormat(new Date(date), format);
    }

    //시간을 HH:MM -> HH시MM분으로 변경함.
    static changeTimeToKor(date) {
        const hour = date.split(":")[0];
        const minute = date.split(":")[1];
        return hour + '시' + minute + '분';
    }

    // seconds를 HH:MM:ss 형태로 가져옴
    static convertToHHMMSS (seconds) {
        const date = new Date(null);
        date.setSeconds(Math.floor(seconds)); // specify value for SECONDS here
        const result = date.toISOString().slice(11, 19);
        if (result.startsWith("00")) {  // less than 1 hour
            return result.slice(3); // return MM:ss
        } else {
            return result;
        }
    }

    // 정수일 경우, x.0으로 표기하고 아닐 경우, 그대로 표기
    static convertIntToSingleDecimalFormat (number) {
        if (number % 1 === 0) {
            return number.toFixed(1);
        }
        return number;
    }

    static isTooShort(password, minLength) {
        if (!minLength) {
            return true;
        }
        return password.length < minLength;
    }

    static isMatchingRules(password, ruleNames) {
        if (!ruleNames) {
            return true;
        }

        const rules = _.chain(ruleNames)
            .split('|')
            .filter(rule => !!rule)
            .map(rule => rule.trim())
            .value();

        for (const rule of rules) {
            if (!CommonUtil.isMatchingRule(password, rule)) {
                return false;
            }
        }
        return true;
    }

    static isMatchingRule(password, rule) {
        switch (rule) {
            case 'symbols':
                return regex.symbolsPattern.test(password);
            case 'words':
                return regex.wordsPattern.test(password);
            case 'digits':
                return regex.digitsPattern.test(password);
            case 'letters':
                return regex.lettersPattern.test(password);
            case 'lowerCase':
                return regex.lowerCasePattern.test(password);
            case 'upperCase':
                return regex.upperCasePattern.test(password);
            default:
                return true;
        }
    }
    static calculateScoreForPassword(text, minLength, rules) {
        if (!text) {
            return -1;
        }

        if (CommonUtil.isTooShort(text, minLength)) {
            return -1;
        }

        if (!CommonUtil.isMatchingRules(text, rules)) {
            return 0;
        }
        return 1;
    }

    static checkType(data, type) {
        return typeof data === type
    }

    static numberFormatComma(num){
        return Number(num).toLocaleString("ko-Kr");
    }
}





const regex = {
    digitsPattern: /\d/,
    lettersPattern: /[a-zA-Z]/,
    lowerCasePattern: /[a-z]/,
    upperCasePattern: /[A-Z]/,
    wordsPattern: /\w/,
    symbolsPattern: /\W/
};
export default CommonUtil;
