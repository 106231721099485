import {GetMessage, GetMessageIntl} from "./message";

export const UserUtil = {
    strFromUserTy : (intl, userTy) => {
        let res = '';
        switch (userTy) {
            case '01' :
                res = GetMessageIntl(intl,"login.userRole01")
                break;
            case '10':
                res =  GetMessageIntl(intl,"login.userRole10")
                break;
            case '11':
                res =  GetMessageIntl(intl,"login.userRole11")
                break;
            case '12':
                res = GetMessageIntl(intl,"login.userRole12")
                break
            case '13':
                res =  GetMessageIntl(intl,"login.userRole13")
                break;
            case '20' :
                res =  GetMessageIntl(intl,"login.userRole20")
                break;
            default :
                res = ''
                break;
        }
        return res;
    }
}