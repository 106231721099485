import {
  SET_IS_MODULE_OFFCANVAS,
  SET_IS_DATASET_OFFCANVAS,
  SET_IS_LABEL_OFFCANVAS,
  SET_IS_REVIEW_OFFCANVAS,
  SET_SELECTION_NODE_ID,
  SET_SELECTION_NODE_NM,
  SET_SELECTION_NODE,
  RESET_SELECTION_NODE,
  DELETE_NODE_ID,
  UPDATE_NODE_ID,
  SET_DATASET_DATA,
  SET_LABEL_DATA,
  SET_REVIEW_DATA
} from './actionType';

export const manage_project_workflow_module_setIsModuleOffcanvas = isYn =>({
  type: SET_IS_MODULE_OFFCANVAS,
  payload: isYn,
})

export const manage_project_workflow_module_setIsDatasetOffcanvas = isYn => ({
  type : SET_IS_DATASET_OFFCANVAS,
  payload: isYn,
});

export const manage_project_workflow_module_setIsLabelOffcanvas = isYn => ({
  type : SET_IS_LABEL_OFFCANVAS,
  payload : isYn
});
export const manage_project_workflow_module_setIsReviewOffcanvas  = isYn => ({
  type : SET_IS_REVIEW_OFFCANVAS,
  payload : isYn
});

export const manage_project_workflow_module_setSelectionNodeId  = selectionNodeId => ({
  type : SET_SELECTION_NODE_ID,
  payload : selectionNodeId
});

export const manage_project_workflow_module_setSelectionNodeNm  = selectionNodeNm => ({
  type : SET_SELECTION_NODE_NM,
  payload : selectionNodeNm
});

export const manage_project_workflow_module_setSelectionNode  = selectionNode => ({
  type : SET_SELECTION_NODE,
  payload : selectionNode
});

export const manage_project_workflow_module_resetSelectionNode  = () => ({
  type : RESET_SELECTION_NODE
});
export const manage_project_workflow_module_deleteNodeId  = deleteNodeId => ({
  type : DELETE_NODE_ID,
  payload : deleteNodeId
});
export const manage_project_workflow_module_updateModuleId  = updateModuleId => ({
  type : UPDATE_NODE_ID,
  payload : updateModuleId
});

export const manage_project_workflow_module_setDatasetData  = data => ({
  type : SET_DATASET_DATA,
  payload : data
});
export const manage_project_workflow_module_setLabelData  = data => ({
  type : SET_LABEL_DATA,
  payload : data
});
export const manage_project_workflow_module_setReviewData  = data => ({
  type : SET_REVIEW_DATA,
  payload : data
});
