/* CANVAS */
export const RESET_CANVAS = 'label/annotation/RESET_CANVAS';
/* SHAPE */
export const MERGE_OBJECTS = 'label/annotation/MERGE_OBJECTS';
export const GROUP_OBJECTS = 'label/annotation/GROUP_OBJECTS';
export const SPLIT_TRACK = 'label/annotation/SPLIT_TRACK';
export const COPY_SHAPE = 'label/annotation/COPY_SHAPE';
export const PASTE_SHAPE_ACTION = 'label/annotation/PASTE_SHAPE_ACTION';
export const PASTE_SHAPE = 'label/annotation/PASTE_SHAPE';
export const EDIT_SHAPE = 'label/annotation/EDIT_SHAPE';
export const REDRAW_SHAPE = 'label/annotation/REDRAW_SHAPE';
export const REPEAT_DRAW_SHAPE_ACTION = 'label/annotation/REPEAT_DRAW_SHAPE_ACTION';
export const REPEAT_DRAW_SHAPE = 'label/annotation/REPEAT_DRAW_SHAPE';
export const SHAPE_DRAWN = 'label/annotation/SHAPE_DRAWN';
export const REMEMBER_OBJECT = 'label/annotation/REMEMBER_OBJECT';
export const LOAD_PREV_FILE_OBJECTS = 'label/annotation/LOAD_PREV_FILE_OBJECTS';
export const LOAD_PREV_FILE_OBJECTS_SUCCESS = 'label/annotation/LOAD_PREV_FILE_OBJECTS_SUCCESS';
export const LOAD_PREV_FILE_OBJECTS_FAILED = 'label/annotation/LOAD_PREV_FILE_OBJECTS_FAILED';
/* SHAPE END*/
/* ANNOTATIONS */
export const UPDATE_ANNOTATIONS = 'label/annotation/UPDATE_ANNOTATIONS';
export const UPDATE_ANNOTATIONS_SUCCESS = 'label/annotation/UPDATE_ANNOTATIONS_SUCCESS';
export const UPDATE_ANNOTATIONS_FAILED = 'label/annotation/UPDATE_ANNOTATIONS_FAILED';
export const CREATE_ANNOTATIONS = 'label/annotation/CREATE_ANNOTATIONS';
export const CREATE_ANNOTATIONS_SUCCESS = 'label/annotation/CREATE_ANNOTATIONS_SUCCESS';
export const CREATE_ANNOTATIONS_FAILED = 'label/annotation/CREATE_ANNOTATIONS_FAILED';
export const REMOVE_ANNOTATIONS = 'label/annotation/REMOVE_ANNOTATIONS';
export const REMOVE_ANNOTATIONS_SUCCESS = 'label/annotation/REMOVE_ANNOTATIONS_SUCCESS';
export const REMOVE_ANNOTATIONS_FAILED = 'label/annotation/REMOVE_ANNOTATIONS_FAILED';
export const MERGE_ANNOTATIONS = 'label/annotation/MERGE_ANNOTATIONS';
export const MERGE_ANNOTATIONS_SUCCESS = 'label/annotation/MERGE_ANNOTATIONS_SUCCESS';
export const MERGE_ANNOTATIONS_FAILED = 'label/annotation/MERGE_ANNOTATIONS_FAILED';
export const RESET_ANNOTATIONS_GROUP = 'label/annotation/RESET_ANNOTATIONS_GROUP';
export const GROUP_ANNOTATIONS = 'label/annotation/GROUP_ANNOTATIONS';
export const GROUP_ANNOTATIONS_SUCCESS = 'label/annotation/GROUP_ANNOTATIONS_SUCCESS';
export const GROUP_ANNOTATIONS_FAILED = 'label/annotation/GROUP_ANNOTATIONS_FAILED';
export const SPLIT_ANNOTATIONS = 'label/annotation/SPLIT_ANNOTATIONS';
export const SPLIT_ANNOTATIONS_SUCCESS = 'label/annotation/SPLIT_ANNOTATIONS_SUCCESS';
export const SPLIT_ANNOTATIONS_FAILED = 'label/annotation/SPLIT_ANNOTATIONS_FAILED';
export const GET_AUTO_LABEL_ANNOTATION = 'label/annotation/GET_AUTO_LABEL_ANNOTATION'
export const GET_AUTO_LABEL_ANNOTATION_SUCCESS = 'label/annotation/GET_AUTO_LABEL_ANNOTATION_SUCCESS'
export const GET_AUTO_LABEL_ANNOTATION_FAILED = 'label/annotation/GET_AUTO_LABEL_ANNOTATION_FAILED'
export const FETCH_ANNOTATIONS = 'label/annotation/FETCH_ANNOTATIONS';
export const FETCH_ANNOTATIONS_SUCCESS = 'label/annotation/FETCH_ANNOTATIONS_SUCCESS';
export const FETCH_ANNOTATIONS_FAILED = 'label/annotation/FETCH_ANNOTATIONS_FAILED';

/* ANNOTATIONS END */
