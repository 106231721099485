// @ts-nocheck
import {Button, Col, Form, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row} from "reactstrap";
import {GetMessage, GetMessageIntl} from "../../../../../util/message";
import { useIntl } from "react-intl";
import {useRef} from "react";

interface Props{
    visible: boolean;
    data: string;
    wrkflowNm: string;
    filename: string;
    switchModal(boolean): void;
}

export function JsonPreviewModal(props: Props): JSX.Element{
    const {visible, data, wrkflowNm, filename, switchModal} = props;
    const intl = useIntl();
    const jsonRef = useRef();

    function download() {
        const content = jsonRef ? jsonRef.current.value : '';

        const fileNameList = filename.split('.');
        fileNameList.splice(fileNameList.length - 1)
        const downloadFileName = fileNameList.join('.')

        const blob = new Blob([content], { type: 'plain/text' });
        const url = window.URL.createObjectURL(blob)
        const a = document.createElement('a')
        a.href = url
        a.download = `${wrkflowNm}_${downloadFileName}.json` // 원하는 이름으로 파일명 지정
        document.body.appendChild(a)
        a.click()
        setTimeout((_) => {
            window.URL.revokeObjectURL(url)
        }, 60000)
        a.remove() // a를 다 사용했으니 지워준다
    }

    return (
        <Modal id={"jsonPreviewModal"}
               size={"md"}
               isOpen={visible}
               toggle={() => {
                   switchModal(false);
               }}
               centered={true}
               backdrop={true}
               keyboard={false}>
            <ModalHeader className="bg-coco-default-white-f border-bottom border-coco-gray-100 pd-y-16 pd-x-24" toggle={()=>switchModal(false)}>
                {GetMessageIntl(intl, "details.previewJson")}
            </ModalHeader>
            <ModalBody>
                <textarea ref={jsonRef} className="form-control" readOnly rows={20} placeholder={GetMessageIntl(intl, 'details.noWorkHistory')} defaultValue={data ?? null}/>
            </ModalBody>
            <ModalFooter>
                <Button
                    type="button"
                    color="light"
                    disabled={!data}
                    onClick={download}
                >
                    {GetMessageIntl(intl, 'common.download')}
                </Button>
                <Button
                    type="button"
                    className={"btn-coco-1"}
                    onClick={() => switchModal(false)}
                >
                    {GetMessageIntl(intl,"common.confirm")}
                </Button>
            </ModalFooter>
        </Modal>
    )
}
