import {Col, Input, Label, OffcanvasBody, Row} from "reactstrap";
import {GetMessageIntl} from "../../../../../../../../util/message";
import {Controller, useFormContext} from "react-hook-form";
import SimpleBar from "simplebar-react";
import React, {useEffect} from "react";
import {useIntl} from "react-intl";
import {useDispatch, useSelector} from "react-redux";

function BaseConfigDiv() {
    const intl = useIntl();
    const dispatch = useDispatch();

    const { control:controlBase, register:registerBase, unregister: unregisterBase, setValue:setValueBase, getValues:getValuesBase, reset:resetBase, resetField:resetFieldBase
        , watch:watchBase, formState: { errors:errorsBase },} = useFormContext();
    const {baseConfig} = useSelector((state)=>({
        baseConfig: state.ManageWorkflowLabel.baseConfig
    }));

    useEffect(()=>{
        resetBase();
        if(baseConfig){
            for(const [key, value] of Object.entries(baseConfig)){
                setValueBase(key, (value?value:""));
            };
        }

    }, [baseConfig]);

    const watchAllBase = watchBase();
    return(
        <>
            <SimpleBar style={{ maxHeight: "calc(100vh - 229px)" }} autoHide={false} className="simplebar-track-coco">
                <OffcanvasBody style={{ minHeight: "300px" }} >
                    <h5 className="module-title">
                        <i className="ri-pages-line"></i>{GetMessageIntl(intl,"workflow.taskInfo")}
                    </h5>
                    <Row>
                        <Col md={12}>
                            <Label htmlFor="taskNm" className="form-label">{GetMessageIntl(intl,"workflow.title")}<span className="text-coco-danger-400">*</span></Label>
                            <div className={errorsBase.taskNm?"validation-error":""}>
                                <Controller
                                    render={({ field }) => <Input {...field} maxLength={50} placeholder={GetMessageIntl(intl,"common.enterInfo")}/>}
                                    name="taskNm"
                                    control={controlBase}
                                    rules={{
                                        validate: (value) => {
                                            return value.trim().length>0
                                        }
                                    }}
                                    defaultValue=""
                                    className="form-control lh-18"
                                />
                            </div>
                            {errorsBase.taskNm && <p className="validation-error-message">{GetMessageIntl(intl,"validation.required")}</p>}
                        </Col>
                        <Col md={12} className="mt-16">
                            <Label htmlFor="taskDc" className="form-label">{GetMessageIntl(intl,"workflow.dc")}<span className="text-coco-danger-400">*</span></Label>
                            <div className={errorsBase.taskDc?"validation-error":""}>
                                <Controller
                                    render={({ field }) =>{
                                        const {onChange} = field;
                                        return (
                                            <textarea {...field} placeholder={GetMessageIntl(intl,"common.enterInfo")}
                                                      className="form-control" row={"5"}
                                                                 onChange={(event)=>{
                                                                     const newValue = event.target.value;
                                                                     if(newValue.length>100){
                                                                         return;
                                                                     }
                                                                     onChange(newValue);
                                                                 }}
                                                      style={{resize: 'none'}} maxLength={100}/>
                                        )
                                    }}
                                    name="taskDc"
                                    control={controlBase}
                                    rules={{
                                        validate: (value) => {
                                            return value.trim().length>0
                                        }
                                    }}
                                    defaultValue=""
                                    className="form-control lh-18"
                                />
                            </div>
                            <div className="d-flex align-items-center justify-content-between">
                                <div>{errorsBase.taskDc && <p className="validation-error-message">{GetMessageIntl(intl,"validation.required")}</p>}</div>
                                <div className={"tx-12 lh-16 text-coco-gray-400 fw-normal"}>{watchAllBase.taskDc?watchAllBase.taskDc.length:0}/100자</div>
                            </div>

                        </Col>
                    </Row>
                </OffcanvasBody>
            </SimpleBar>
        </>
    );
}


export default BaseConfigDiv;