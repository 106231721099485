// @ts-nocheck
import React, {useCallback, useEffect, useState} from 'react';
import Text from 'antd/lib/typography/Text';
import Collapse from 'antd/lib/collapse';

import ObjectButtonsContainer from 'pages/user/label/annotation/image/containers/standard-workspace/objects-side-bar/object-buttons';
import ItemDetailsContainer from 'pages/user/label/annotation/image/containers/standard-workspace/objects-side-bar/object-item-details';
import { ObjectType, ShapeType, ColorBy } from 'store/label';
import { ObjectState } from 'pages/user/label/annotation/image/cvat-core-wrapper';
import ObjectItemElementComponent from './object-item-element';
import ItemBasics from './object-item-basics';
import {Card, CardHeader, UncontrolledCollapse} from "reactstrap";
import ObjectReviewDetails from "../../../containers/standard-workspace/objects-side-bar/object-review-details";
import {MD_STTUS_CD, MD_TY_CD} from "../../../const";
import {GetMessageIntl} from "../../../../../../../../util/message";
import {useIntl} from "react-intl";

interface Props {
    moduleType: MD_TY_CD;
    moduleStatus: MD_STTUS_CD;
    containerKey: string;
    sortedStatesID: number[];
    normalizedKeyMap: Record<string, string>;
    errorVisible: boolean;
    readonly: boolean;
    prepared: boolean;
    activated: boolean;
    attributeError: boolean;
    reject: boolean | null;
    rsnCd: string[];
    hidden: boolean;
    objectType: ObjectType;
    shapeType: ShapeType;
    clientID: number;
    viewID: number;
    serverID: number | undefined;
    labelID: number;
    locked: boolean;
    elements: any[];
    color: string;
    colorBy: ColorBy;
    labels: any[];
    attributes: any[];
    moduleInstance: any;
    prepare(readyElementID?: number): void;
    activate(activeElementID?: number): void;
    copy(): void;
    propagate(): void;
    createURL(): void;
    switchOrientation(): void;
    toBackground(): void;
    toForeground(): void;
    remove(): void;
    changeLabel(label: any): void;
    changeColor(color: string): void;
    resetCuboidPerspective(): void;
    edit(): void;
    onOpenCanvasContextMenu(): void;
}

function ObjectItemComponent(props: Props): JSX.Element {
    const {
        prepared,
        activated,
        attributeError,
        readonly,
        hidden,
        objectType,
        shapeType,
        clientID,
        viewID,
        serverID,
        locked,
        labelID,
        color,
        colorBy,
        elements,
        attributes,
        labels,
        moduleType,
        moduleStatus,
        normalizedKeyMap,
        errorVisible,
        prepare,
        activate,
        copy,
        propagate,
        createURL,
        switchOrientation,
        toBackground,
        toForeground,
        remove,
        changeLabel,
        changeColor,
        resetCuboidPerspective,
        edit,
        moduleInstance,
        sortedStatesID,
        item,
        containerKey,
        ready,
        firstReviewObjectID,
        reject,
        rsnCd,
        completedError,
        touch,
        onUpdateStates,
        onOpenCanvasContextMenu,
    } = props;

    const [result, setResult] = useState(0);
    const intl = useIntl();
    const resultMap = [
        { color: "", text: ''},
        { color: "text-coco-danger-400", text: GetMessageIntl(intl, 'ui.U00066')},
        { color: "text-coco-secondary-700", text: GetMessageIntl(intl, 'label.pass')},
    ]
    useEffect(() => {
        if (reject === null) {
            setResult(0);
        } else if (reject === true) {
            setResult(1);
        } else if (reject === false) {
            setResult(2);
        }
    }, [reject])

    useEffect(() => {
        if (ready && firstReviewObjectID === clientID && moduleType === MD_TY_CD.REVIEW) { // 검수 일때
            activate();
            onOpenCanvasContextMenu();
        }
    }, [ready])

    const type =
        objectType === ObjectType.TAG ?
            ObjectType.TAG.toUpperCase() :
            `${shapeType.toUpperCase()} ${objectType.toUpperCase()}`;

    const className = activated ?
        'instanceItem selected' :
        'instanceItem';
    const errorClassName = errorVisible && (attributeError || (completedError && rsnCd.length)) ?
        'error':
        '';
    const vLineClassName = !activated ?
        'd-none':
        ''
    const readyState = useCallback(() => {
        if (!hidden){
            prepare();
        }
    }, []);
    const activateState = useCallback((e) => {
        activate();
        onOpenCanvasContextMenu();
    }, []);

    return (
        <>
            <Card className={"list-item-event-container "+className.concat(' ', errorClassName)} id={containerKey}
                  onClick={activateState}
                  onMouseEnter={readyState}
            >
                <CardHeader>
                    <div className={"v-line "+vLineClassName}/>
                    <div className={'detailShapeItem'}>
                        <ItemBasics
                            reject={reject}
                            completedError={completedError}
                            touch={touch}
                            moduleInstance={moduleInstance}
                            moduleType={moduleType}
                            readonly={readonly}
                            serverID={serverID}
                            clientID={clientID}
                            viewID={viewID}
                            labelID={labelID}
                            labels={labels}
                            shapeType={shapeType}
                            objectType={objectType}
                            color={color}
                            colorBy={colorBy}
                            type={type}
                            locked={locked}
                            copyShortcut={normalizedKeyMap.COPY_SHAPE}
                            pasteShortcut={normalizedKeyMap.PASTE_SHAPE}
                            propagateShortcut={normalizedKeyMap.PROPAGATE_OBJECT}
                            toBackgroundShortcut={normalizedKeyMap.TO_BACKGROUND}
                            toForegroundShortcut={normalizedKeyMap.TO_FOREGROUND}
                            removeShortcut={normalizedKeyMap.DELETE_OBJECT}
                            changeColorShortcut={normalizedKeyMap.CHANGE_OBJECT_COLOR}
                            changeLabel={changeLabel}
                            changeColor={changeColor}
                            copy={copy}
                            remove={remove}
                            propagate={propagate}
                            createURL={createURL}
                            switchOrientation={switchOrientation}
                            toBackground={toBackground}
                            toForeground={toForeground}
                            resetCuboidPerspective={resetCuboidPerspective}
                            edit={edit}
                        />
                        <div className={"wd-20p mx-2"}>
                            <ObjectButtonsContainer readonly={readonly}
                                                    clientID={clientID}
                                                    remove={remove}
                            />
                        </div>
                    </div>
                </CardHeader>
            </Card>

            {
                moduleStatus === MD_STTUS_CD.RE && reject &&
                <UncontrolledCollapse toggler={`object-item-${viewID}`} isOpen={activated}>
                    <ObjectReviewDetails
                        clientID={clientID}
                        sortedStatesID={sortedStatesID}
                        onOpenCanvasContextMenu={onOpenCanvasContextMenu}
                    />
                </UncontrolledCollapse>
            }
        </>
    )
}

export default React.memo(ObjectItemComponent);
