// @ts-nocheck
import { isBrowser, isNode } from 'browser-or-node';

import * as cvatData from 'pages/user/label/annotation/image/work/data/ts/cvat-data';
import { DimensionType } from 'enums';
import PluginRegistry from './plugins';
import serverProxy, { FramesMetaData } from './server-proxy';
import {
    Exception, ArgumentError, DataError, ServerError,
} from './exceptions';
import {getMeta} from "../service";
import {DATA_TYPE} from "../../const";

// frame storage by module id
const frameDataCache: Record<string, {
    meta: FramesMetaData;
    chunkSize: number;
    mode: 'annotation' | 'interpolation';
    startFrame: number;
    stopFrame: number;
    provider: cvatData.FrameProvider;
    frameBuffer: FrameBuffer;
    decodedBlocksCacheSize: number;
    activeChunkRequest: null;
    nextChunkRequest: null;
}> = {};

export class FrameData {
    constructor({
        width,
        height,
        name,
        path,
        url,
        dataType,
        fileSize,
        moduleId,
        datasetId,
        fileSeq,
        frameNumber,
        startFrame,
        stopFrame,
        decodeForward,
        deleted,
        related_files: relatedFiles,
    }) {
        Object.defineProperties(
            this,
            Object.freeze({
                datasetId: {
                    value: datasetId,
                    writable: false,
                },
                fileSeq: {
                    value: fileSeq,
                    writable: false,
                },
                name: {
                    value: name,
                    writable: false,
                },
                path: {
                    value: path,
                    writable: false,
                },
                url: {
                    value: url,
                    writable: false,
                },
                dataType: {
                    value: dataType,
                    writable: false,
                },
                fileSize: {
                    value: fileSize,
                    writable: false,
                },
                moduleId: {
                    value: moduleId,
                    writable: false,
                },
                number: {
                    value: frameNumber,
                    writable: false,
                },
                width: {
                    value: width,
                    writable: true,
                },
                height: {
                    value: height,
                    writable: true,
                },
                relatedFiles: {
                    value: relatedFiles,
                    writable: false,
                },
                startFrame: {
                    value: startFrame,
                    writable: false,
                },
                stopFrame: {
                    value: stopFrame,
                    writable: false,
                },
                decodeForward: {
                    value: decodeForward,
                    writable: false,
                },
                deleted: {
                    value: deleted,
                    writable: false,
                },
            }),
        );
    }

    async data(onServerRequest = () => {}) {
        const result = await PluginRegistry.apiWrapper.call(this, FrameData.prototype.data, onServerRequest);
        return result;
    }

    get imageData() {
        console.log('frames imageData', this._data);
        return this._data.imageData;
    }

    set imageData(imageData) {
        this._data.imageData = imageData;
    }
}

FrameData.prototype.data.implementation = async function (onServerRequest) {
    return new Promise((resolve, reject) => {
        const resolveWrapper = () => {

            if (this.dataType === DATA_TYPE.IMAGE) {
                const image = new Image();
                image.src = onServerRequest;
                image.onload = () => {
                    this._data = {
                        renderWidth: image.width,
                        renderHeight: image.height,
                        imageData: image
                    }
                    this.width = image.width;
                    this.height = image.height;
                };
            } else
                // if (this.dataType === DATA_TYPE.TEXT)
                {
                this._data = onServerRequest;
            }
            resolve(this._data);
        };

        if (this._data) {
            resolve(this._data);
            return;
        }

        resolveWrapper(this._data);
    })
};

function getFrameMeta(moduleId, frame): FramesMetaData['frames'][0] {
    const { meta, mode, startFrame } = frameDataCache[moduleId];
    let size = null;
    if (mode === 'interpolation') {
        [size] = meta.frames;
    } else if (mode === 'annotation') {
        if (frame >= meta.size) {
            throw new ArgumentError(`Meta information about frame ${frame} can't be received from the server`);
        } else {
            // Todo 여기서 이미지 사이즈 return
            size = meta.frames[frame - startFrame];
        }
    } else {
        throw new DataError(`Invalid mode is specified ${mode}`);
    }
    return size;
}

class FrameBuffer {
    constructor(size, chunkSize, stopFrame, moduleId) {
        this._size = size;
        this._buffer = {};
        this._contextImage = {};
        this._requestedChunks = {};
        this._chunkSize = chunkSize;
        this._stopFrame = stopFrame;
        this._activeFillBufferRequest = false;
        this._moduleId = moduleId;
    }

    addContextImage(frame, data): void {
        const promise = new Promise<void>((resolve, reject) => {
            data.then((resolvedData) => {
                const meta = getFrameMeta(this._moduleId, frame);
                return cvatData
                    .decodeZip(resolvedData, 0, meta.related_files, cvatData.DimensionType.DIMENSION_2D);
            }).then((decodedData) => {
                this._contextImage[frame] = decodedData;
                resolve();
            }).catch((error: Error) => {
                if (error instanceof ServerError && (error as any).code === 404) {
                    this._contextImage[frame] = {};
                    resolve();
                } else {
                    reject(error);
                }
            });
        });

        this._contextImage[frame] = promise;
    }

    isContextImageAvailable(frame): boolean {
        return frame in this._contextImage;
    }

    getContextImage(frame): Promise<ImageBitmap[]> {
        return new Promise((resolve) => {
            if (frame in this._contextImage) {
                if (this._contextImage[frame] instanceof Promise) {
                    this._contextImage[frame].then(() => {
                        resolve(this.getContextImage(frame));
                    });
                } else {
                    resolve({ ...this._contextImage[frame] });
                }
            } else {
                resolve([]);
            }
        });
    }

    getFreeBufferSize() {
        let requestedFrameCount = 0;
        for (const chunk of Object.values(this._requestedChunks)) {
            requestedFrameCount += chunk.requestedFrames.size;
        }

        return this._size - Object.keys(this._buffer).length - requestedFrameCount;
    }

    requestOneChunkFrames(chunkIdx) {
        return new Promise((resolve, reject) => {
            this._requestedChunks[chunkIdx] = {
                ...this._requestedChunks[chunkIdx],
                resolve,
                reject,
            };
            for (const frame of this._requestedChunks[chunkIdx].requestedFrames.entries()) {
                const requestedFrame = frame[1];
                const frameMeta = getFrameMeta(this._moduleId, requestedFrame);
                const frameData = new FrameData({
                    ...frameMeta,
                    moduleId: this._moduleId,
                    frameNumber: requestedFrame,
                    startFrame: frameDataCache[this._moduleId].startFrame,
                    stopFrame: frameDataCache[this._moduleId].stopFrame,
                    decodeForward: false,
                    deleted: requestedFrame in frameDataCache[this._moduleId].meta,
                });

                frameData
                    .data()
                    .then(() => {
                        if (
                            !(chunkIdx in this._requestedChunks) ||
                            !this._requestedChunks[chunkIdx].requestedFrames.has(requestedFrame)
                        ) {
                            reject(chunkIdx);
                        } else {
                            this._requestedChunks[chunkIdx].requestedFrames.delete(requestedFrame);
                            this._requestedChunks[chunkIdx].buffer[requestedFrame] = frameData;
                            if (this._requestedChunks[chunkIdx].requestedFrames.size === 0) {
                                const bufferedframes = Object.keys(this._requestedChunks[chunkIdx].buffer).map(
                                    (f) => +f,
                                );
                                this._requestedChunks[chunkIdx].resolve(new Set(bufferedframes));
                            }
                        }
                    })
                    .catch(() => {
                        reject(chunkIdx);
                    });
            }
        });
    }

    fillBuffer(startFrame, frameStep = 1, count = null) {
        const freeSize = this.getFreeBufferSize();
        const requestedFrameCount = count ? count * frameStep : freeSize * frameStep;
        const stopFrame = Math.min(startFrame + requestedFrameCount, this._stopFrame + 1);

        for (let i = startFrame; i < stopFrame; i += frameStep) {
            const chunkIdx = Math.floor(i / this._chunkSize);
            if (!(chunkIdx in this._requestedChunks)) {
                this._requestedChunks[chunkIdx] = {
                    requestedFrames: new Set(),
                    resolve: null,
                    reject: null,
                    buffer: {},
                };
            }
            this._requestedChunks[chunkIdx].requestedFrames.add(i);
        }

        let bufferedFrames = new Set();

        // if we send one request to get frame 1 with filling the buffer
        // then quicky send one more request to get frame 1
        // frame 1 will be already decoded and written to buffer
        // the second request gets frame 1 from the buffer, removes it from there and returns
        // after the first request finishes decoding it tries to get frame 1, but failed
        // because frame 1 was already removed from the buffer by the second request
        // to prevent this behavior we do not write decoded frames to buffer till the end of decoding all chunks
        const buffersToBeCommited = [];
        const commitBuffers = () => {
            for (const buffer of buffersToBeCommited) {
                this._buffer = {
                    ...this._buffer,
                    ...buffer,
                };
            }
        };

        // Need to decode chunks in sequence
        return new Promise(async (resolve, reject) => {
            for (const chunkIdx of Object.keys(this._requestedChunks)) {
                try {
                    const chunkFrames = await this.requestOneChunkFrames(chunkIdx);
                    if (chunkIdx in this._requestedChunks) {
                        bufferedFrames = new Set([...bufferedFrames, ...chunkFrames]);

                        buffersToBeCommited.push(this._requestedChunks[chunkIdx].buffer);
                        delete this._requestedChunks[chunkIdx];
                        if (Object.keys(this._requestedChunks).length === 0) {
                            commitBuffers();
                            resolve(bufferedFrames);
                        }
                    } else {
                        commitBuffers();
                        reject(chunkIdx);
                        break;
                    }
                } catch (error) {
                    commitBuffers();
                    reject(error);
                    break;
                }
            }
        });
    }

    async makeFillRequest(start, step, count = null) {
        if (!this._activeFillBufferRequest) {
            this._activeFillBufferRequest = true;
            try {
                await this.fillBuffer(start, step, count);
                this._activeFillBufferRequest = false;
            } catch (error) {
                if (typeof error === 'number' && error in this._requestedChunks) {
                    this._activeFillBufferRequest = false;
                }
                throw error;
            }
        }
    }

    async require(frameNumber: number, moduleId: number, datasetId: string, fileSeq: number, name: string, path: string, url: string, dataType: DATA_TYPE, fileSize: number, fillBuffer: boolean, frameStep: number): FrameData {
        for (const frame in this._buffer) {
            if (+frame < frameNumber || +frame >= frameNumber + this._size * frameStep) {
                delete this._buffer[frame];
            }
        }

        this._required = frameNumber;
        const frameMeta = getFrameMeta(moduleId, frameNumber);
        let frame = new FrameData({
            ...frameMeta,
            moduleId: moduleId,
            datasetId,
            fileSeq,
            name,
            path,
            url,
            dataType,
            fileSize,
            frameNumber,
            startFrame: frameDataCache[moduleId].startFrame,
            stopFrame: frameDataCache[moduleId].stopFrame,
            decodeForward: !fillBuffer,
            deleted: frameNumber in frameDataCache[moduleId].meta.deleted_frames,
        });

        if (frameNumber in this._buffer) {
            frame = this._buffer[frameNumber];
            delete this._buffer[frameNumber];
            const cachedFrames = this.cachedFrames();
            if (
                fillBuffer &&
                !this._activeFillBufferRequest &&
                this._size > this._chunkSize &&
                cachedFrames.length < (this._size * 3) / 4
            ) {
                const maxFrame = cachedFrames ? Math.max(...cachedFrames) : frameNumber;
                if (maxFrame < this._stopFrame) {
                    this.makeFillRequest(maxFrame + 1, frameStep).catch((e) => {
                        if (e !== 'not needed') {
                            throw e;
                        }
                    });
                }
            }
        } else if (fillBuffer) {
            this.clear();
            await this.makeFillRequest(frameNumber, frameStep, fillBuffer ? null : 1);
            frame = this._buffer[frameNumber];
        } else {
            this.clear();
        }

        return frame;
    }

    clear() {
        for (const chunkIdx in this._requestedChunks) {
            if (
                Object.prototype.hasOwnProperty.call(this._requestedChunks, chunkIdx) &&
                this._requestedChunks[chunkIdx].reject
            ) {
                this._requestedChunks[chunkIdx].reject('not needed');
            }
        }
        this._activeFillBufferRequest = false;
        this._requestedChunks = {};
        this._buffer = {};
    }

    cachedFrames() {
        return Object.keys(this._buffer).map((f) => +f);
    }
}

async function getImageContext(jobID, frame) {
    return new Promise((resolve, reject) => {
        serverProxy.frames
            .getImageContext(jobID, frame)
            .then((result) => {
                if (isNode) {
                    resolve(global.Buffer.from(result, 'binary').toString('base64'));
                } else if (isBrowser) {
                    resolve(result);
                }
            })
            .catch((error) => {
                reject(error);
            });
    });
}

export async function getContextImage(moduleId, frame) {
    if (frameDataCache[moduleId].frameBuffer.isContextImageAvailable(frame)) {
        return frameDataCache[moduleId].frameBuffer.getContextImage(frame);
    }
    const response = getImageContext(moduleId, frame);
    await frameDataCache[moduleId].frameBuffer.addContextImage(frame, response);
    return frameDataCache[moduleId].frameBuffer.getContextImage(frame);
}

export function decodePreview(preview: Blob): Promise<string> {
    return new Promise((resolve, reject) => {
        if (isNode) {
            resolve(global.Buffer.from(preview, 'binary').toString('base64'));
        } else if (isBrowser) {
            const reader = new FileReader();
            reader.onload = () => {
                resolve(reader.result as string);
            };
            reader.onerror = (error) => {
                reject(error);
            };
            reader.readAsDataURL(preview);
        }
    });
}

export async function getFrame(
    moduleId: number,
    datasetId: string,
    fileSeq: number, name: string, path: string, url: string, dataType: DATA_TYPE, fileSize: number,
    chunkSize: number,
    chunkType: 'video' | 'imageset',
    mode: 'interpolation' | 'annotation', // todo: obsolete, need to remove
    frame: number,
    startFrame: number,
    stopFrame: number,
    isPlaying: boolean,
    step: number,
    dimension: DimensionType,
) {
    if (!(moduleId in frameDataCache)) {
        const blockType = chunkType === 'video' ? cvatData.BlockType.MP4VIDEO : cvatData.BlockType.ARCHIVE;
        const meta = await getMeta();
        const meta_ = {
            ...meta,
            frames: [{
                ...meta.frames[0],
                width: 1280,
                height: 720
            }]
        };
        //console.log('[points meta_]', meta_);
        meta_.deleted_frames = Object.fromEntries(meta_.deleted_frames.map((_frame) => [_frame, true]));
        const mean = meta_.frames.reduce((a, b) => a + b.width * b.height, 0) / meta_.frames.length;
        const stdDev = Math.sqrt(
            meta_.frames.map((x) => (x.width * x.height - mean) ** 2).reduce((a, b) => a + b) /
            meta_.frames.length,
        );
        // meta.deleted_frames = Object.fromEntries(meta.deleted_frames.map((_frame) => [_frame, true]));
        // const mean = meta.frames.reduce((a, b) => a + b.width * b.height, 0) / meta.frames.length;
        // const stdDev = Math.sqrt(
        //     meta.frames.map((x) => (x.width * x.height - mean) ** 2).reduce((a, b) => a + b) /
        //         meta.frames.length,
        // );

        // limit of decoded frames cache by 2GB
        const decodedBlocksCacheSize = Math.floor(2147483648 / (mean + stdDev) / 4 / chunkSize) || 1;

        frameDataCache[moduleId] = {
            meta: meta_,
            chunkSize,
            mode,
            startFrame,
            stopFrame,
            // provider: new cvatData.FrameProvider(
            //     blockType,
            //     chunkSize,
            //     Math.max(decodedBlocksCacheSize, 9),
            //     decodedBlocksCacheSize,
            //     1,
            //     dimension,
            // ),
            frameBuffer: new FrameBuffer(
                Math.min(180, decodedBlocksCacheSize * chunkSize),
                chunkSize,
                stopFrame,
                moduleId,
            ),
            decodedBlocksCacheSize,
            activeChunkRequest: null,
            nextChunkRequest: null,
        };

        // relevant only for video chunks
        const frameMeta = getFrameMeta(moduleId, frame);
        // frameDataCache[moduleId].provider.setRenderSize(frameMeta.width, frameMeta.height);
    }

    return frameDataCache[moduleId].frameBuffer.require(frame, moduleId, datasetId, fileSeq, name, path, url, dataType, fileSize, isPlaying, step);
}

export async function getDeletedFrames(instanceType, id) {
    if (instanceType === 'module') {
        const { meta } = frameDataCache[id];
        return meta.deleted_frames;
    }

    if (instanceType === 'job') {
        const { meta } = frameDataCache[id];
        return meta.deleted_frames;
    }

    if (instanceType === 'task') {
        const meta = await serverProxy.frames.getMeta('job', id);
        meta.deleted_frames = Object.fromEntries(meta.deleted_frames.map((_frame) => [_frame, true]));
        return meta;
    }

    throw new Exception(`getDeletedFrames is not implemented for ${instanceType}`);
}

export function deleteFrame(moduleId, frame) {
    const { meta } = frameDataCache[moduleId];
    meta.deleted_frames[frame] = true;
}

export function restoreFrame(moduleId, frame) {
    const { meta } = frameDataCache[moduleId];
    if (frame in meta.deleted_frames) {
        delete meta.deleted_frames[frame];
    }
}

export async function patchMeta(moduleId) {
    const { meta } = frameDataCache[moduleId];
    const newMeta = meta;
    newMeta.deleted_frames = [];
    const prevDeletedFrames = meta.deleted_frames;

    // it is important do not overwrite the object, it is why we working on keys in two loops below
    for (const frame of Object.keys(prevDeletedFrames)) {
        delete prevDeletedFrames[frame];
    }
    for (const frame of newMeta.deleted_frames) {
        prevDeletedFrames[frame] = true;
    }

    frameDataCache[moduleId].meta = newMeta;
    frameDataCache[moduleId].meta.deleted_frames = prevDeletedFrames;
}

export async function findNotDeletedFrame(moduleId, frameFrom, frameTo, offset) {
    let meta;
    if (!frameDataCache[moduleId]) {
        meta = await serverProxy.frames.getMeta('job', moduleId);
    } else {
        meta = frameDataCache[moduleId].meta;
    }
    const sign = Math.sign(frameTo - frameFrom);
    const predicate = sign > 0 ? (frame) => frame <= frameTo : (frame) => frame >= frameTo;
    const update = sign > 0 ? (frame) => frame + 1 : (frame) => frame - 1;
    let framesCounter = 0;
    let lastUndeletedFrame = null;
    for (let frame = frameFrom; predicate(frame); frame = update(frame)) {
        if (!(frame in meta.deleted_frames)) {
            lastUndeletedFrame = frame;
            framesCounter++;
            if (framesCounter === offset) {
                return lastUndeletedFrame;
            }
        }
    }

    return lastUndeletedFrame;
}

export function getRanges(moduleId) {
    if (!(moduleId in frameDataCache)) {
        return {
            decoded: [],
            buffered: [],
        };
    }

    return {
        decoded: frameDataCache[moduleId].provider.cachedFrames,
        buffered: frameDataCache[moduleId].frameBuffer.cachedFrames(),
    };
}

export function clear(moduleId) {
    if (moduleId in frameDataCache) {
        frameDataCache[moduleId].frameBuffer.clear();
        delete frameDataCache[moduleId];
    }
}
