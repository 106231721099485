import {
  INIT_MANAGE_WORKFLOW_LABEL_MODULE_CONFIG
  , RESET_MANAGE_WORKFLOW_LABEL_MODULE_CONFIG
  , SET_MANAGE_WORKFLOW_LABEL_BASE_CONFIG
  , SET_MANAGE_WORKFLOW_LABEL_SHAPE_CONFIG
  , SET_MANAGE_WORKFLOW_LABEL_WORKER_CONFIG
  , SET_MANAGE_WORKFLOW_LABEL_BIND_CONFIG
  , SET_MANAGE_WORKFLOW_LABEL_ORDER_CONFIG
  , SET_MANAGE_WORKFLOW_LABEL_POINT_CONFIG
  , SET_MANAGE_WORKFLOW_LABEL_DATA_CONFIG
  , SET_MANAGE_WORKFLOW_LABEL_TEST_CONFIG
  , SET_MANAGE_WORKFLOW_LABEL_CLASS_ITEMS
  , ADD_MANAGE_WORKFLOW_LABEL_CLASS_ITEM
  , UPDATE_MANAGE_WORKFLOW_LABEL_CLASS_ITEM
  , DELETE_MANAGE_WORKFLOW_LABEL_CLASS_ITEM
  , SET_MANAGE_WORKFLOW_LABEL_ATTR_ITEMS
  , ADD_MANAGE_WORKFLOW_LABEL_ATTR_ITEM
  , UPDATE_MANAGE_WORKFLOW_LABEL_ATTR_ITEM
  , DELETE_MANAGE_WORKFLOW_LABEL_ATTR_ITEM
  , SET_SELECTED_MANAGE_WORKFLOW_LABEL_CLASS_ID
  , SET_MANAGE_WORKFLOW_LABEL_ACTIVE_DATA
  , SET_MANAGE_WORKFLOW_LABEL_INACTIVE_DATA
  , SET_MANAGE_WORKFLOW_LABEL_SELECTED_ROW_ID
  , SET_MANAGE_WORKFLOW_LABEL_VIEW_TYPE
  , UPDATE_MANAGE_WORKFLOW_LABEL_EDITOR_CN_BY_ID
  , SET_MANAGE_WORKFLOW_LABEL_DATA_ERROR
  , SET_MANAGE_WORKFLOW_LABEL_FILES
  , SWITCH_MANAGE_WORKFLOW_LABEL_REGIST_DATA_REFRESH_FLAG
  , SET_MANAGE_WORKFLOW_LABEL_FILES_RESET
  , SET_MANAGE_WORKFLOW_LABEL_SETTING_ROW_ID
  , SET_MANAGE_WORKFLOW_LABEL_SETTING_VIEW_TYPE
} from './actionType';

export const initManageWorkflowLabelModuleConfig=(config) =>({
  type: INIT_MANAGE_WORKFLOW_LABEL_MODULE_CONFIG,
  payload: config
})
export const resetManageWorkflowLabelModuleConfig=() =>({
  type: RESET_MANAGE_WORKFLOW_LABEL_MODULE_CONFIG
})
export const setManageWorkflowLabelBaseConfig =(config)=>({
  type:SET_MANAGE_WORKFLOW_LABEL_BASE_CONFIG,
  payload: config,
})
export const setManageWorkflowLabelShapeConfig =(config)=>({
  type:SET_MANAGE_WORKFLOW_LABEL_SHAPE_CONFIG,
  payload: config,
})
export const setManageWorkflowLabelWorkerConfig =(config)=>({
  type:SET_MANAGE_WORKFLOW_LABEL_WORKER_CONFIG,
  payload: config,
})
export const setManageWorkflowLabelBindConfig = (config) =>({
  type: SET_MANAGE_WORKFLOW_LABEL_BIND_CONFIG,
  payload: config,
})
export const setManageWorkflowLabelOrderConfig =(config)=>({
  type:SET_MANAGE_WORKFLOW_LABEL_ORDER_CONFIG,
  payload: config,
})
export const setManageWorkflowLabelPointConfig =(config)=>({
  type:SET_MANAGE_WORKFLOW_LABEL_POINT_CONFIG,
  payload: config,
})
export const setManageWorkflowLabelDataConfig =(config)=>({
  type:SET_MANAGE_WORKFLOW_LABEL_DATA_CONFIG,
  payload: config,
})
export const setManageWorkflowLabelTestConfig =(config)=>({
  type:SET_MANAGE_WORKFLOW_LABEL_TEST_CONFIG,
  payload: config,
})
export const setManageWorkflowLabelClassItems = items =>({
  type: SET_MANAGE_WORKFLOW_LABEL_CLASS_ITEMS,
  payload: items,
})
export const addManageWorkflowLabelClassItem = item =>({
  type: ADD_MANAGE_WORKFLOW_LABEL_CLASS_ITEM,
  payload: item,
})
export const updateManageWorkflowLabelClassItem = (classId, item) =>({
  type: UPDATE_MANAGE_WORKFLOW_LABEL_CLASS_ITEM,
  payload: {classId, item},
})
export const deleteManageWorkflowLabelClassItem = deleteItemId =>({
  type: DELETE_MANAGE_WORKFLOW_LABEL_CLASS_ITEM,
  payload: deleteItemId,
})
export const setManageWorkflowLabelAttrItems = (classId, items) =>({
  type: SET_MANAGE_WORKFLOW_LABEL_ATTR_ITEMS,
  payload: {classId, items},
})
export const addManageWorkflowLabelAttrItem = (classId, item) =>({
  type: ADD_MANAGE_WORKFLOW_LABEL_ATTR_ITEM,
  payload: {classId, item},
})
export const updateManageWorkflowLabelAttrItem = (classId, attrId, item) =>({
  type: UPDATE_MANAGE_WORKFLOW_LABEL_ATTR_ITEM,
  payload: {classId, item},
})
export const deleteManageWorkflowLabelAttrItem = (classId, deleteAttrId) =>({
  type: DELETE_MANAGE_WORKFLOW_LABEL_ATTR_ITEM,
  payload: {classId,deleteAttrId},
})
export const setSelectedManageWorkflowLabelClassId  = id =>({
  type: SET_SELECTED_MANAGE_WORKFLOW_LABEL_CLASS_ID,
  payload: id,
})
export const setManageWorkflowLabelActiveData = (aData) => ({
  type: SET_MANAGE_WORKFLOW_LABEL_ACTIVE_DATA,
  payload: aData
})
export const setManageWorkflowLabelInactiveData = (iData) => ({
  type: SET_MANAGE_WORKFLOW_LABEL_INACTIVE_DATA,
  payload: iData
})
export const setManageWorkflowLabelViewType = (viewType) => ({
  type: SET_MANAGE_WORKFLOW_LABEL_VIEW_TYPE,
  payload: viewType
})
export const setManageWorkflowLabelSelectedRowId = (id) => ({
  type: SET_MANAGE_WORKFLOW_LABEL_SELECTED_ROW_ID,
  payload: id
})
export const updateManageWorkflowLabelEditorCnById = (id, attr, newData) => ({
  type: UPDATE_MANAGE_WORKFLOW_LABEL_EDITOR_CN_BY_ID,
  payload: { id, attr, newData }
})
export const setManageWorkflowLabelDataError = (errorData) => ({
  type: SET_MANAGE_WORKFLOW_LABEL_DATA_ERROR,
  payload: errorData
})
export const setManageWorkflowLabelFiles = (id, files) => ({
  type: SET_MANAGE_WORKFLOW_LABEL_FILES,
  payload: { id, files }
})
export const setManageWorkflowLabelFilesReset = () => ({
  type: SET_MANAGE_WORKFLOW_LABEL_FILES_RESET,
})

export const switchManageWorkflowLabelRegistDataRefreshFlag = () => ({
  type: SWITCH_MANAGE_WORKFLOW_LABEL_REGIST_DATA_REFRESH_FLAG
})

export const setManageWorkflowLabelSettingViewType = (viewType) => ({
  type: SET_MANAGE_WORKFLOW_LABEL_SETTING_VIEW_TYPE,
  payload: viewType
})

export const setManageWorkflowLabelSettingRowId = (rowId) => ({
  type: SET_MANAGE_WORKFLOW_LABEL_SETTING_ROW_ID,
  payload: rowId
})
