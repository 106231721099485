// @ts-nocheck
import {DropdownItem} from "reactstrap";
import {GetMessageIntl} from "../../../../../util/message";
import React, {useEffect, useState} from "react";
import {useIntl} from "react-intl";
import {useDispatch, useSelector} from "react-redux";
import {getWorkerByWorkflow, updateSearchParam} from "../../../../../store/dataset/details/action";
import {defaultSelectStyle} from "../../../../../components/constants/select";
import Select from "react-select";
import {SearchFilter} from "../../../../../store/dataset/details";
import {ApplySearchButton} from "./search-header";

export function WorkerFilter(): JSX.Element{
    const intl = useIntl();
    const dispatch = useDispatch();
    const {
        Details: {
            workflow: {id: wrkflowId},
            searchParam: {
                view,
                data,
                worker
            }
        }
    } = useSelector(state => state);
    const [workerList, setWorkerList] = useState([]);
    const choice = data.worker; // value
    const choiceLabelList = view.worker;
    useEffect(() => {
        if(!!!worker.length){
            dispatch(getWorkerByWorkflow(wrkflowId))
        } else {
            let list = worker.reduce((acc, w) => {
                acc.push({label: w.userNm, value: w.userSeq})
                return acc;
            }, [])
            setWorkerList(list)
        }
    }, [wrkflowId])

    const onChange = (value) =>{
        dispatch(updateSearchParam(SearchFilter.WORKER, value.value, [{label: value.label, index: value.value}]));
    }

    return (
        <>
            <div className={"filter-container text-container"}>
                <div className={"d-flex align-items-center justify-content-between"}>
                    <DropdownItem header>{GetMessageIntl(intl, 'details.worker')}</DropdownItem>
                    <ApplySearchButton/>
                </div>
                <DropdownItem divider/>
                <div className={"d-flex flex-column align-items-center gap-2"}>
                    <DropdownItem toggle={false}>
                        <Select
                            options={workerList}
                            styles={defaultSelectStyle}
                            value={workerList.find(w => w.value === choice)}
                            className="mb-0"
                            onChange={(value) => onChange(value)}
                        />
                    </DropdownItem>
                </div>
            </div>
        </>
    )
}
