// @ts-nocheck
import {MD_STTUS_CD, MD_TY_CD} from "../../../const";
import {CombinedState} from "../../../../../../../../store/label";
import React, {Dispatch} from "react";
import {AnyAction} from "redux";
import {connect} from "react-redux";
import {Popover, UncontrolledPopover} from "reactstrap";
import './style.scss';
import {useIntl} from "react-intl";
import {GetMessageIntl} from "../../../../../../../../util/message";
import {ShortcutsSection} from "./shortcuts-section";
import {Category} from "../../../../../../../../store/shortcuts";

interface OwnProps {
    target: string;
    visible: boolean;
    toggle(): void;
    keyMap: any;
    normalizedKeyMap: any;
}

export function Shortcuts(props: OwnProps): JSX.Element {
    const {
        target,
        visible,
        toggle,
        keyMap,
        normalizedKeyMap,
    } = props;

    if (!document.getElementById(target)) return (<></>)

    return (
        <Popover
            className={"shortcutsPopover"}
            target={target}
            placement={"bottom-end"}
            isOpen={visible}
            toggle={toggle}
        >
            <ShortcutsBody
                keyMap={keyMap}
                normalizedKeyMap={normalizedKeyMap}
            />
        </Popover>
    );
}
export default React.memo(Shortcuts);

interface Props {
    keyMap: any;
    normalizedKeyMap: any;
}
function ShortcutsBody(props: Props): JSX.Element {
    const {keyMap, normalizedKeyMap} = props;
    const categories = [Category.INSTANCE, Category.JOB, Category.ZOOM_MOVE, Category.TOOL, Category.BRUSH, Category.VIEW,];
    const titles = categories.map(title => 'shortcuts'+'.'+title);

    return (
        <div className={"shortcutsBody"}>
            <div className={"shortcutCol"}>
                <ShortcutsSection
                    title={titles[0]}
                    keyMap={Object.keys(keyMap).reduce((accumulator, key) => {
                        if (keyMap[key].category === categories[0]) {
                            accumulator[key] = {...keyMap[key]};
                        }
                        return accumulator;
                    }, {})}
                    normalizedKeyMap={normalizedKeyMap}
                />
                <ShortcutsSection
                    title={titles[1]}
                    keyMap={Object.keys(keyMap).reduce((accumulator, key) => {
                        if (keyMap[key].category === categories[1]) {
                            accumulator[key] = {...keyMap[key]};
                        }
                        return accumulator;
                    }, {})}
                    normalizedKeyMap={normalizedKeyMap}
                />
            </div>
            <div className={"col-broken-line-white"} />
            <div className={"shortcutCol"}>
                <ShortcutsSection
                    title={titles[2]}
                    keyMap={Object.keys(keyMap).reduce((accumulator, key) => {
                        if (keyMap[key].category === categories[2]) {
                            accumulator[key] = {...keyMap[key]};
                        }
                        return accumulator;
                    }, {})}
                    normalizedKeyMap={normalizedKeyMap}
                />
            </div>
            <div className={"col-broken-line-white"} />
            <div className={"shortcutCol"}>
                <ShortcutsSection
                    title={titles[3]}
                    keyMap={Object.keys(keyMap).reduce((accumulator, key) => {
                        if (keyMap[key].category === categories[3]) {
                            accumulator[key] = {...keyMap[key]};
                        }
                        return accumulator;
                    }, {})}
                    normalizedKeyMap={normalizedKeyMap}
                />
            </div>
            <div className={"col-broken-line-white"} />
            <div className={"shortcutCol"}>
                <ShortcutsSection
                    title={titles[4]}
                    keyMap={Object.keys(keyMap).reduce((accumulator, key) => {
                        if (keyMap[key].category === categories[4]) {
                            accumulator[key] = {...keyMap[key]};
                        }
                        return accumulator;
                    }, {})}
                    normalizedKeyMap={normalizedKeyMap}
                />
                <ShortcutsSection
                    title={titles[5]}
                    keyMap={Object.keys(keyMap).reduce((accumulator, key) => {
                        if (keyMap[key].category === categories[5]) {
                            accumulator[key] = {...keyMap[key]};
                        }
                        return accumulator;
                    }, {})}
                    normalizedKeyMap={normalizedKeyMap}
                />
            </div>
        </div>
    );
}


