import {ArgumentError} from "./exceptions";

export interface ReviewInstanceData {
    objectId: number;
    classId: number;
    reject: boolean | null;
    rsnCd: string[];
}
export default class ReviewInstance {
   private objectId: number;
   private classId: number;
   private reject: boolean | null;
   private rsnCd: string[];
   private completedError: boolean; // 수정 완료 여부
   private editable: boolean;   // 수정 가능한지 여부
   private touch: boolean;  // 한 번이라도 touch 했는 지 여부

    // constructor({objectId, classId, reject, rsnCd}) {
    constructor(initialData: ReviewInstanceData, reset: boolean) {
        const data = {
            objectId: undefined,
            classId: undefined,
            reject: null,
            rsnCd: [],
            completedError: true,
            editable: true, // 검수자, 라벨러가 인스턴스를 편집할 수 있는가. 즉, readonly 여부
            touch: false    // 한번이라도 수정했는 지 여부
        };

        for (const property in data) {
            if (Object.prototype.hasOwnProperty.call(data, property)) {
                if (property in initialData) {
                    data[property] = initialData[property];
                }

                if (data[property] === undefined) {
                    throw new ArgumentError(`Review field "${property}" was not initialized`);
                }
            }
        }

        if (reset) {    // 검수자일 경우, 이전 인스턴스 검수 결과가 있을때, 재검할 수 있게 이전 검수 결과를 보여주지 않아야한다.
            if (data.reject == false) { // 단, 이전에 통과된 인스턴스는 건들이지 않았기에 재검할 필요가 없다.
                data.editable = false;
                data.completedError = false;
            } else {    // 반려된 인스턴스는 '결과 내역'과 '반려 사유' 모두 리셋된다.
                data.reject = null;
                data.rsnCd = [];
            }
        } else if (data.reject === false) {
            data.editable = false;
            data.completedError = false;
        }

        Object.defineProperties(this,
            Object.freeze({
                objectId: {
                    get: () => data.objectId,
                },
                classId: {
                    get: () => data.classId,
                },
                reject: {
                    get: () => data.reject,
                    set: (reject) => {
                        data.reject = reject;
                    },
                    // writable: reject == false,
                },
                rsnCd: {
                    get: () => data.rsnCd ?? [],
                    set: (rsnCd) => {
                        data.rsnCd = [...rsnCd];
                        if (rsnCd.length === 0) {
                            data.reject = null;
                            data.completedError = true;
                        } else {
                            data.completedError = false;
                            if (JSON.stringify(rsnCd) === JSON.stringify(["01"])) {
                                data.reject = false;
                            } else {
                                data.reject = true;
                            }
                        }
                    },
                    // value: rsnCd ?? [],
                    // writable: reject == false,
                },
                completedError: {
                    get: () => data.completedError,
                    set: (isCompleted: boolean) => {
                        data.completedError = isCompleted;
                        data.editable = isCompleted;
                        if (data.touch === false) {
                            data.touch = true;
                        }
                    }
                },
                editable: {
                    get: () => data.editable,
                    set: (editable) => {
                        data.editable = editable;
                    }
                },
                touch: {
                    get: () => data.touch,
                    set: (touch) => {
                        data.touch = touch;
                    }
                }
            })
        );
    }

    public getHash() {
        const hash: ReviewInstanceData = {
            objectId: this.objectId,
            classId: this.classId,
            reject: this.reject,
            rsnCd: this.rsnCd
        }
        return hash;
    }

    public getReject(){
        return this.reject;
    }

    public getRsnCd(){
        return this.rsnCd;
    }

    public getCompletedError(){
        return this.completedError;
    }
};
