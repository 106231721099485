import {
  INIT_MANAGE_WORKFLOW_REVIEW_MODULE_CONFIG
  , RESET_MANAGE_WORKFLOW_REVIEW_MODULE_CONFIG
  , SET_MANAGE_WORKFLOW_REVIEW_BASE_CONFIG
  , SET_MANAGE_WORKFLOW_REVIEW_REVIEW_CONFIG
  , SET_MANAGE_WORKFLOW_REVIEW_WORKER_CONFIG
  , SET_MANAGE_WORKFLOW_REVIEW_POINT_CONFIG
  , SET_MANAGE_WORKFLOW_REVIEW_DATA_CONFIG
  , SET_MANAGE_WORKFLOW_REVIEW_TEST_CONFIG
  , SET_MANAGE_WORKFLOW_REVIEW_ACTIVE_DATA
  , SET_MANAGE_WORKFLOW_REVIEW_SELECTED_ROW_ID
  , UPDATE_MANAGE_WORKFLOW_REVIEW_EDITOR_CN_BY_ID
  , SET_MANAGE_WORKFLOW_REVIEW_DATA_ERROR
  , SET_MANAGE_WORKFLOW_REVIEW_FILES
  , SET_MANAGE_WORKFLOW_REVIEW_FILES_RESET
  , SET_MANAGE_WORKFLOW_REVIEW_INACTIVE_DATA
  , SET_MANAGE_WORKFLOW_REVIEW_VIEW_TYPE
  , SWITCH_MANAGE_WORKFLOW_REVIEW_REGIST_DATA_REFRESH_FLAG
  , SET_MANAGE_WORKFLOW_REVIEW_TARGET_LABEL_BIND_CONFIG
  , SET_SHAPE_CONFIG
} from './actionType';

export const initManageWorkflowReviewModuleConfig = (config) =>({
  type: INIT_MANAGE_WORKFLOW_REVIEW_MODULE_CONFIG,
  payload: config
});
export const resetManageWorkflowReviewModuleConfig = () =>({
  type: RESET_MANAGE_WORKFLOW_REVIEW_MODULE_CONFIG
});
export const setManageWorkflowReviewBaseConfig =(config)=>({
  type:SET_MANAGE_WORKFLOW_REVIEW_BASE_CONFIG,
  payload: config,
})
export const setManageWorkflowReviewReviewConfig =(config)=>({
  type:SET_MANAGE_WORKFLOW_REVIEW_REVIEW_CONFIG,
  payload: config,
})
export const setManageWorkflowReviewWorkerConfig =(config)=>({
  type:SET_MANAGE_WORKFLOW_REVIEW_WORKER_CONFIG,
  payload: config,
})
export const setManageWorkflowReviewPointConfig =(config)=>({
  type:SET_MANAGE_WORKFLOW_REVIEW_POINT_CONFIG,
  payload: config,
})
export const setManageWorkflowReviewDataConfig =(config)=>({
  type:SET_MANAGE_WORKFLOW_REVIEW_DATA_CONFIG,
  payload: config,
})
export const setManageWorkflowReviewTestConfig =(config)=>({
  type:SET_MANAGE_WORKFLOW_REVIEW_TEST_CONFIG,
  payload: config,
})
export const setManageWorkflowReviewActiveData = (aData) => ({
  type: SET_MANAGE_WORKFLOW_REVIEW_ACTIVE_DATA,
  payload: aData
})
export const setManageWorkflowReviewInactiveData = (iData) => ({
  type: SET_MANAGE_WORKFLOW_REVIEW_INACTIVE_DATA,
  payload: iData
})
export const setManageWorkflowReviewViewType = (viewType) => ({
  type: SET_MANAGE_WORKFLOW_REVIEW_VIEW_TYPE,
  payload: viewType
})
export const setManageWorkflowReviewSelectedRowId = (id) => ({
  type: SET_MANAGE_WORKFLOW_REVIEW_SELECTED_ROW_ID,
  payload: id
})
export const updateManageWorkflowReviewEditorCnById = (id, attr, newData) => ({
  type: UPDATE_MANAGE_WORKFLOW_REVIEW_EDITOR_CN_BY_ID,
  payload: { id, attr, newData }
})
export const setManageWorkflowReviewDataError = (errorData) => ({
  type: SET_MANAGE_WORKFLOW_REVIEW_DATA_ERROR,
  payload: errorData
})
export const setManageWorkflowReviewFiles = (id, files) => ({
  type: SET_MANAGE_WORKFLOW_REVIEW_FILES,
  payload: { id, files }
})
export const setManageWorkflowReviewFilesReset = () => ({
  type: SET_MANAGE_WORKFLOW_REVIEW_FILES_RESET,
})
export const switchManageWorkflowReviewRegistDataRefreshFlag = () => ({
  type: SWITCH_MANAGE_WORKFLOW_REVIEW_REGIST_DATA_REFRESH_FLAG
})

export const setManageWorkflowReviewTargetLabelBindConfig =(config)=>({
  type: SET_MANAGE_WORKFLOW_REVIEW_TARGET_LABEL_BIND_CONFIG,
  payload: {config}
})

export const workflow_review_testConfig_setShapeConfig = shapeConfig => ({
  type: SET_SHAPE_CONFIG,
  payload: shapeConfig
});
