import {
    SET_SEARCH_PARAM,
    SET_USER_TY,
    SET_PRJCT_ID,
    SET_USER_NM,
    SET_PRJCT_OPTIONS, SET_CURRENT_PAGE,
} from "./actionType";
export const setSearchParam = (searchParam) => ({
    type : SET_SEARCH_PARAM,
    payload : searchParam
});
export const setUserTy = (userTy) => ({
    type: SET_USER_TY,
    payload: userTy
})
export const setUserNm = (userNm) => ({
    type: SET_USER_NM,
    payload: userNm
})
export const setPrjctId = (prjctId) => ({
    type: SET_PRJCT_ID,
    payload: prjctId
})
export const setPrjctOptions = (options) => ({
    type: SET_PRJCT_OPTIONS,
    payload: options
})
export const setCurrentPage = (currentPage) => ({
    type: SET_CURRENT_PAGE,
    payload: currentPage
})