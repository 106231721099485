import {
    SET_DASHBOARD_WORKFLOW_COLUMN04,
} from "./actionType"
import Board from "../board/reducer";

const INIT_STATE = {
    workflowTaskStatusData : {}
}

const Dashboard = (state = INIT_STATE, action) => {
    switch (action.type) {
        case SET_DASHBOARD_WORKFLOW_COLUMN04 :
            return {
                ...state,
                workflowTaskStatusData: state.workflowTaskStatusData,
            }
        default:
            return state;
    }
}

export default Dashboard;