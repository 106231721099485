import {
    SET_CURRENT_PAGE,
    SET_WRKFLOW_ID,
    SET_WRKFLOW_OPTION,
    SET_TASK_ID,
    SET_TASK_OPTION,
    SET_SEARCH_PARAM,
    SET_WORKER_NM,
    SET_WORK_DTM,
    SET_MOUNT_CNT,
    SET_WRKFLOW_WITH_MODULES,
    SET_CANVAS_DATA_TY
} from "./actionType"

export const setCurrentPage = (currentPage) => ({
    type: SET_CURRENT_PAGE,
    payload: currentPage
})
export const setWrkflowId = (wrkflowId) => ({
    type: SET_WRKFLOW_ID,
    payload: wrkflowId
})
export const setWrkflowOption = (options) => ({
    type: SET_WRKFLOW_OPTION,
    payload: options
})
export const setTaskId = (taskId) => ({
    type: SET_TASK_ID,
    payload: taskId
})
export const setTaskOption = (options) => ({
    type: SET_TASK_OPTION,
    payload: options
})
export const setSearchParam = (searchObj) => ({
    type: SET_SEARCH_PARAM,
    payload: searchObj
})
export const setWorkerNm = (workerNm) => ({
    type: SET_WORKER_NM,
    payload: workerNm
})
export const setWorkDtm = (workDtm) => ({
    type: SET_WORK_DTM,
    payload: workDtm
})
export const setMountCnt = (mount) => ({
    type: SET_MOUNT_CNT,
    payload: mount
})
export const setWrkflowWithModules = (wrkflowWithModules) => ({
    type: SET_WRKFLOW_WITH_MODULES,
    payload: wrkflowWithModules
})

export const setCanvasDataTy = (canvasDataTy) => ({
    type : SET_CANVAS_DATA_TY,
    payload : canvasDataTy
});