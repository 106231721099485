import {all, put, take, takeEvery, takeLatest} from "redux-saga/effects";
import {
    CHECK_VALIDATION,
    COMMENT_ISSUE,
    FINISH_ISSUE,
    GET_ANNOTATIONS_REASONS,
    REMOVE_ISSUE,
    REOPEN_ISSUE,
    RESOLVE_ISSUE,
    SUBMIT_ANNOTATIONS,
    SUBMIT_ANNOTATIONS_SUCCESS,
    UPDATE_ACTIVE_SHAPE,
    UPDATE_COMPLETED_STATES,
    UPDATE_REVIEW_STATES
} from "./actionType";
import {getCore} from "../../pages/user/label/annotation/image/cvat-core-wrapper";
import {Store} from "redux";
import {getRootStore} from "../index";
import {
    checkValidationSuccess,
    commentIssueFailed,
    commentIssueSuccess,
    createReviewDataFailed,
    createReviewDataSuccess,
    finishIssueFailed,
    finishIssueSuccess,
    getAnnotationsReasonsFailed,
    getAnnotationsReasonsSuccess,
    getReviewStatesFailed,
    getReviewStatesSuccess,
    removeIssueFailed,
    removeIssueSuccess,
    reopenIssueFailed,
    reopenIssueSuccess,
    resetReviewStatesSuccess,
    resolveIssueFailed,
    resolveIssueSuccess,
    submitAnnotations as submitReview,
    submitAnnotationsFailed,
    submitAnnotationsSuccess,
    updateReviewStates
} from "./action";
import {goNextAnnotationAsync} from "../label/saga";
import {
    CHANGE_FRAME_SUCCESS,
    CREATE_ANNOTATIONS_SUCCESS,
    GET_AUTO_LABEL_ANNOTATION_SUCCESS,
    GET_MODULE_SUCCESS,
    LOAD_PREV_FILE_OBJECTS_SUCCESS,
    REMOVE_OBJECT_SUCCESS,
    UPDATE_ANNOTATIONS_SUCCESS
} from "../label/actionType";
import {LABEL_TYPE, MD_STTUS_CD, MD_TY_CD, REVIEW_TASK_STTUS_CD} from "../../pages/user/label/annotation/image/const";
import {getSavedReviewResult, saveReviewOnserver} from "../../pages/user/label/annotation/image/work/service";
import {alertError, confirm, confirmSuccess} from "../../util/AlertUtil";
import ReviewInstance from "../../pages/user/label/annotation/image/work/core/review-instance";
import ReviewClasses from "../../pages/user/label/annotation/image/work/core/review-classes";
import {cloneDeep} from "lodash";
import {activateObject, collectStatistics, updateAnnotations, updateCanvasContextMenu} from "../label/action";
import {CommonService} from "../../apis/api/CmmnService";
import {ContextMenuType} from "../label";

const core = getCore();
let store: null | Store = null;
function getStore(): Store {
    if (store === null) {
        // store = getCVATStore();
        store = getRootStore();
    }
    return store;
}
function* fisnishIssueAsync(action){
    const state = getStore().getState();
    const {message} = action.payload;
    const {
        Label: {
            Cmmn: {
                player: {
                    frame: {number: frameNumber},
                },
                module: {
                    instance: moduleInstance,
                },
            }
        },
        review: {
            Cmmn: { newIssuePosition }
        }
    } = state;
    try {
        const issue = new core.classes.Issue({
            module: moduleInstance.id,
            frame: frameNumber,
            position: newIssuePosition,
        });
        const savedIssue = yield moduleInstance.openIssue(issue, message);
        yield put(finishIssueSuccess(frameNumber, savedIssue));
    } catch (error) {
        yield put(finishIssueFailed(error));
    }
}
function* commentIssueAsync(action) {
    const { id, message } = action.payload;
    const state = getStore().getState();
    const {
        auth: { user },
        review: {
            Cmmn: {frameIssues}
        },
    } = state;

    try {
        const [issue] = frameIssues.filter((_issue: any): boolean => _issue.id === id);
        yield issue.comment({
            message,
            owner: user,
        });

        yield put(commentIssueSuccess());
    } catch (error) {
        yield put (commentIssueFailed(error));
    }
}
function* resolveIssueAsync(action) {
    const {id} = action.payload;
    const state = getStore().getState();
    const {
        auth: { user },
        review: {
            Cmmn: {frameIssues}
        },
    } = state;

    try {
        const [issue] = frameIssues.filter((_issue: any): boolean => _issue.id === id);
        yield issue.resolve(user);
        yield put(resolveIssueSuccess());
    } catch (error) {
        yield put(resolveIssueFailed(error));
    }
}
function* reopenIssueAsync(action){
    const {id} = action.payload;
    const state = getStore().getState();
    const {
        auth: { user },
        review: {
            Cmmn: {frameIssues}
        },
    } = state;

    try {
        const [issue] = frameIssues.filter((_issue: any): boolean => _issue.id === id);
        yield issue.reopen(user);
        yield put(reopenIssueSuccess());
    } catch (error) {
        yield put(reopenIssueFailed(error));
    }
}
function* deleteIssueAsync(action){
    const {id} = action.payload;
    const state = getStore().getState();
    const {
        review: { Cmmn: {frameIssues} },
        Label: {
            Cmmn: {
                player: {
                    frame: {number: frameNumber},
                },
            }
        },
    } = state;

    try {
        const [issue] = frameIssues.filter((_issue: any): boolean => _issue.id === id);
        yield issue.delete();
        yield put(removeIssueSuccess(id, frameNumber));
    } catch (error) {
        yield put(removeIssueFailed(error));
    }
}
function* watchFinishIssue() {
    yield takeLatest(FINISH_ISSUE, fisnishIssueAsync)
}
function* watchCommentIssue() {
    yield takeLatest(COMMENT_ISSUE, commentIssueAsync)
}
function* watchResolveIssue() {
    yield takeLatest(RESOLVE_ISSUE, resolveIssueAsync)
}
function* watchReopenIssue() {
    yield takeLatest(REOPEN_ISSUE, reopenIssueAsync)
}
function* watchDeleteIssue() {
    yield takeLatest(REMOVE_ISSUE, deleteIssueAsync)
}
/********** 여기서부터 라플로에서 사용되는 saga ********/

/**
 * GET_MODULE시(캔버스 진입), review class 생성한다.
 *
 * + 현재는 사용되지 않고 RESET_REVIEW_STATE시, review class를 생성함
 * @param action
 */
function* getReviewStates(action) {
    const {module, states, labels} = action.payload;
    try {
        const reviewData = yield core.review.get({states, labels}, module.type === MD_TY_CD.REVIEW);
        yield put(getReviewStatesSuccess(reviewData))
    } catch (e) {
        yield put(getReviewStatesFailed(e))
    }
}
function* watchGetModuleSuccess(){
    yield takeLatest(GET_MODULE_SUCCESS, getReviewStates)
}

/**
 * 가장 처음으로 Review 객체를 만드는 함수
 * 이전 결과와 현재 annotation 결과를 비교하여
 * 수정 중에 삭제된 인스턴스는 삭제하고, 추가된 인스턴스는 추가를 해준다.
 * @param action
 */
function* resetReviewStates(action){
    try {
        const {module, frame, states} = action.payload;

        if (module.labelType !== LABEL_TYPE.ANNOTATION) return;

        const reviewData = getStore().getState().Review.Cmmn.annotations.data;
        const labels = module.labels;

        const result = yield getSavedReviewResult({moduleId: module.id, datasetId:frame.datasetId, fileSeq:frame.fileSeq});

        if (result.errorMessage) {
            // 다른 에러 처리해주기
            //throw new Error(result.errorMessage);
        } else {
            const savedReviewData = result.data ? result.data.issueCnObj : null; // 이전 검수 결과
            const moduleStatus = savedReviewData ? MD_STTUS_CD.RE : MD_STTUS_CD.FIRST;
            // let newReviewData = yield core.review.get({states, labels}, module.type === MD_TY_CD.REVIEW);
            let newReviewData = yield core.review.get({states, labels}, module.type === MD_TY_CD.REVIEW);

            if (savedReviewData) {
                // newReviewData = yield reviewData.createSavedData(savedReviewData, states)
                newReviewData = yield newReviewData.createSavedData(savedReviewData, states)
            }
            yield put(resetReviewStatesSuccess(newReviewData, moduleStatus));
        }

        yield put(collectStatistics(module));
    } catch (e) {
        console.log('resetReviewStates failed', e)
        // alertError(
        //     ""
        //     ,""
        //     ,null
        //     ,null
        //     ,e
        //     , intl
        // );
    }
}
function* watchChangeFrameSuccess(){
    yield takeLatest(CHANGE_FRAME_SUCCESS, resetReviewStates)
}

/**
 * 새로운 Review 객체 생성이 필요한 경우 사용한다.
 * 생성된 Review 객체는 초기값으로 세팅된다.
 *
 * case1. 새로운 도형이 생성되었을 경우
 * case2. '이전 라벨링 결과 불러오기'를 하여 새로운 도형이 생성되었을 경우
 * case3. AUTO 라벨링을 하여 새로운 도형이 생성되었을 경우
 * @param action
 */
function* createReviewData(action){
    try {
        const {states} = action.payload;
        const reviewData = getStore().getState().Review.Cmmn.annotations.data;
        reviewData.createInstanceDataToMap(states);

        yield put(createReviewDataSuccess(reviewData))
    } catch (e){
        yield put(createReviewDataFailed(e))
    }
}
function* watchCreateAnnotationsSuccess(){
    yield takeLatest(CREATE_ANNOTATIONS_SUCCESS, createReviewData)
}
function* watchLoadPrevFileObjectsSuccess(){
    yield takeLatest(LOAD_PREV_FILE_OBJECTS_SUCCESS, createReviewData)
}
function* watchGetAutoLabelAnnotationSuccess(){
    yield takeLatest(GET_AUTO_LABEL_ANNOTATION_SUCCESS, createReviewData)
}

/**
 * 인스턴스 탭에서 선택된 Shape만 보여지고 나머지 도형은 hide 된다.
 * 또 선택된 Shape만 활성상태가 되며, 속성 popover가 보여진다.
 * @param action
 */
function* updateActiveShapeAsync(action){
    const {activatedStateID} = action.payload;
    const states = getStore().getState().Label.Cmmn.annotations.states;
    const ownState = states.filter(state => state.clientID === activatedStateID).map((state)=> ((state.hidden = false), state));
    const theirStates = states.filter(state => state.clientID !== activatedStateID).map((state)=> ((state.hidden = true), state));

    yield put(updateAnnotations(ownState));
    yield take(UPDATE_ANNOTATIONS_SUCCESS);
    yield put(updateAnnotations(theirStates));
    yield take(UPDATE_ANNOTATIONS_SUCCESS);

    yield put(activateObject(activatedStateID, null, null));

    yield put(updateCanvasContextMenu(true, null, null, null, ContextMenuType.CANVAS_SHAPE));
}
function* watchUpdateActiveShape(){
    yield takeLatest(UPDATE_ACTIVE_SHAPE, updateActiveShapeAsync)
}

/**
 * 도형이 삭제 되었을 때, 해당 Review Instance를 삭제한다.
 *
 * + 현재는 사용되지 않고, Review 객체의 createSavedData될 때,
 * 현재 있는 도형과 이전 검수 결과를 비교하여 존재하지 않으면 삭제한다.
 * @param action
 */
function* removeReviewData(action){
    const { objectState } = action.payload;
    const reviewData = getStore().getState().Review.Cmmn.annotations.data;
    reviewData.removeInstance(objectState.clientID);
}
function* watchRemoveObjectSuccess(){
    yield takeLatest(REMOVE_OBJECT_SUCCESS, removeReviewData)
}

/**
 * validation 부터 체크한다.
 *
 * case1. submit 버튼을 눌렀을 때
 * @param action
 */
function* checkValidation(action){
    const {intl, history, dispatch} = action.hooks;
    const reviewData = getStore().getState().Review.Cmmn.annotations.data;
    const {completedError, instanceNotEditedCnt, classesNotEditedCnt} = yield reviewData.checkCompletion();
    // console.log('[checkValidation]',completedError, instanceNotEditedCnt, classesNotEditedCnt)
    if (completedError) {
        // console.log('completedError is ', completedError);
        const param = {instanceCount: instanceNotEditedCnt, classCount: classesNotEditedCnt};
        alertError('review.validCntLimitAlert', 'review.validCntLimitAlertText',
            null, param, null, intl);
        yield put(checkValidationSuccess(completedError, instanceNotEditedCnt, classesNotEditedCnt));
    } else {
        const {moduleInstance} = action.payload;
        const reviewData = getStore().getState().Review.Cmmn.annotations.data;
        const json = reviewData.getHash();
        const taskSttusCd: REVIEW_TASK_STTUS_CD = reviewData.getTotalReject() ? REVIEW_TASK_STTUS_CD.REJECT : REVIEW_TASK_STTUS_CD.PASS;
        yield put(submitReview(json, taskSttusCd, intl, history, dispatch, null));
    }

}
function* watchCheckValidation() {
    yield takeEvery(CHECK_VALIDATION, checkValidation)
}

/**
 * 검수 결과를 제출한다.
 * @param action
 */
function* submitAnnotationsAsync(action){
    const { intl, history, dispatch, form } = action.hooks;
    const { json: issueCnObj,  taskSttusCd} = action.payload;
    const state = getStore().getState();
    try {
        confirm("label.submitConfirm","label.submitConfirmText"
            , async () => {
                const moduleId = getStore().getState().Label.Cmmn.module.moduleId;
                const frameData = getStore().getState().Label.Cmmn.frames.frame;

                const param = {
                    moduleId: moduleId,
                    labelModuleId: frameData.moduleId,
                    datasetId: frameData.datasetId,
                    fileSeq: frameData.fileSeq,
                    issueCnObj: issueCnObj,
                    taskSttusCd,
                };

                const response = await saveReviewOnserver(param);

                const { success, errorMessage } = response.data;
                if( !success ){
                    alertError(
                        ""
                        ,""
                        ,null
                        ,null
                        , errorMessage
                        ,intl
                    );
                    return;
                }

                if(state.Label.Cmmn.module.labelType == LABEL_TYPE.CLASSIFICATION_SUMMARY){
                    form.reset();
                }

                dispatch(submitAnnotationsSuccess(response, intl, history, dispatch));
            }
            ,"","",intl)
    } catch (e) {
        yield put(submitAnnotationsFailed(e))
    }
}
function* watchSubmitAnnotation(){
    yield takeLatest(SUBMIT_ANNOTATIONS, submitAnnotationsAsync)
}

/**
 * 검수 결과 제출의 성공에 대한 alert을 띄워준다.
 * @param action
 */
function* submitAnnotationsSuccessAsync(action){
    const { intl, history, dispatch } = action.hooks;
    // console.log('[submitAnnotationsSuccessAsync] action', action)
    confirmSuccess('label.submitSuccess'
        , 'label.submitSuccessText'
        , () => {
            goNextAnnotationAsync(action).next()
        } , () => {
            history.goBack();
        }, null, intl);
}
function* watchSubmitAnnotationSuccess(){
    yield takeLatest(SUBMIT_ANNOTATIONS_SUCCESS, submitAnnotationsSuccessAsync)
}

/**
 * Review 데이터를 update한다.
 * @param action
 */
function* updateCompletedStates (action) {
    const {reviewData, completedError} = action.payload;
    const newData = cloneDeep(reviewData);
    newData.completedError = completedError;
    newData.editable = completedError;
    newData.touch = true;

    if (newData instanceof ReviewInstance) {
        yield put(updateReviewStates(newData, null));
    } else if (newData instanceof ReviewClasses) {
        yield put(updateReviewStates(null, newData));
    }

    const moduleInstance = getStore().getState().Label.Cmmn.module.instance;
    yield put(collectStatistics(moduleInstance));
}
function* watchUpdateCompletedStates (){
    yield takeLatest(UPDATE_COMPLETED_STATES, updateCompletedStates);
}

/**
 * 인스턴스 반려 사유 checkbox 클릭시, ReviewInstance 객체 반려 사유 update한다.
 * @param action
 */
function* updateCompletion(action){
    const reviewData = getStore().getState().Review.Cmmn.annotations.data;
    const {completedError, instanceNotEditedCnt, classesNotEditedCnt} = yield reviewData.checkCompletion();
    yield put(checkValidationSuccess(null, instanceNotEditedCnt, classesNotEditedCnt));
}
function* watchUpdateReviewStates(){
    yield takeLatest(UPDATE_REVIEW_STATES, updateCompletion)
}

/**
 * 검수 사유를 불러온다.
 * @param action
 */
function* getAnnotationsReasonsAsync(action){
    try {
        const reuslt = yield CommonService.getReviewRejectReasonList();
        const data = reuslt.data;
        if (data.success) {
            yield put(getAnnotationsReasonsSuccess(data.data));
        }
    } catch (e) {
        yield put(getAnnotationsReasonsFailed(e));
    }
}
function* watchGetAnnotationsReasons(){
    yield takeLatest(GET_ANNOTATIONS_REASONS, getAnnotationsReasonsAsync)
}

function* ReviewSaga() {
    yield all([
        watchFinishIssue(),
        watchCommentIssue(),
        watchResolveIssue(),
        watchReopenIssue(),
        watchDeleteIssue(),
        // watchGetModuleSuccess(),
        watchChangeFrameSuccess(),
        watchCreateAnnotationsSuccess(),
        watchLoadPrevFileObjectsSuccess(),
        watchGetAutoLabelAnnotationSuccess(),
        // watchRemoveObjectSuccess(),
        watchCheckValidation(),
        watchSubmitAnnotation(),
        watchSubmitAnnotationSuccess(),
        watchUpdateCompletedStates(),
        watchUpdateReviewStates(),
        watchGetAnnotationsReasons(),
        watchUpdateActiveShape()
    ])
}
export default ReviewSaga;
