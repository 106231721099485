// @ts-nocheck
import {CloseButton, Dropdown, DropdownMenu, DropdownToggle, UncontrolledDropdown} from "reactstrap";
import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";

interface Props{
    title: string;
    value: string | null;
    dropdownMenu: JSX.Element;
    updateVisible(): void;
}
export function FilterButtonView(props: Props):JSX.Element {
    const {title, value, dropdownMenu, updateVisible} = props;
    const sidebarWidth = useSelector(state => state.Details.sidebar.leftSidebarWidth);
    const [maxWidth, setMaxWidth] = useState(264);
    const [isOpen, setIsOpen] = useState(false);
    const toggle = () => {
        setIsOpen(!isOpen)
    }

    useEffect(() => {
        setMaxWidth(sidebarWidth - 34);
    }, [sidebarWidth])

    return (
        <Dropdown isOpen={isOpen} toggle={toggle} className={"filter-dropdown"}>
            <DropdownToggle tag="button" type="button" className="btn btn-coco-1 d-flex align-items-center filter-button" style={{maxWidth: `${maxWidth}px`}}>
                <div className={"filter-button-title"}>{title} {value && " : " + value}</div>
                <div className={"vl"} />
                <i className={"ri-close-line"} onClick={updateVisible}/>
            </DropdownToggle>
            <DropdownMenu>
                {dropdownMenu}
            </DropdownMenu>
        </Dropdown>
    )
}
