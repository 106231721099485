import React, { memo } from 'react';
import {Handle, useReactFlow, useStoreApi, Position, useStore, NodeToolbar} from 'reactflow';
import {
    manage_project_workflow_module_setIsReviewOffcanvas
} from "../../../../../../store/manage/project/workflow/module/action";
import {useDispatch} from "react-redux";
import {thisTargetValidConnection} from "./nodeCommonUtil";
import {useIntl} from "react-intl";
import {GetMessageIntl} from "../../../../../../util/message";

const connectionNodeIdSelector = (state) => {
    return state.connectionNodeId;
}
const nodesSelector = (state) => Array.from(state.nodeInternals.values());

function ManagerReviewNode({ id, data, isConnectable }) {
    const intl = useIntl();
    const dispatch = useDispatch();
    const nodes = useStore(nodesSelector);
    const connectionNodeId = useStore(connectionNodeIdSelector);
    let connectionNodeType = '';
    if(connectionNodeId){
        let connectionNode = nodes.find((item) => item.id === connectionNodeId);
        let parentNodeType = '';
        if(connectionNode.parentNode){
            parentNodeType = nodes.find((item) => item.id === connectionNode.parentNode).type+'_';
        }
        connectionNodeType = parentNodeType + connectionNode.type;
    }

    // 하위 노드가 있는 부모 노드인 경우에는 타 부모에는 연결할수 있지만 부모 노드로는 연결 못하게 해야 하므로 connectionNodeId.indexOf(id)!=0 추가
    const isTarget = connectionNodeId && connectionNodeId !== id && thisTargetValidConnection('managerReview', connectionNodeType) && connectionNodeId.indexOf(id)!=0;
    const targetHandleStyle = isTarget? { visibility: 'visible', zIndex: 10 } : {visibility: 'hidden', zIndex:1};

    return (
        <div onContextMenu={(event)=>{
            event.preventDefault();
            dispatch(manage_project_workflow_module_setIsReviewOffcanvas(true));
        }}>
            <div className="d-flex align-items-center mb-12">
                <div className="flex-shrink-0 avatar-42">
                    <div className="avatar-title fs-20 rounded-circle" style={{backgroundColor:'#DFF3EF', color:'#0BAD8F'}}>
                        <i className="ri-user-settings-line lh-19"></i>
                    </div>
                </div>
                <div className="flex-grow-1 text-overflow ml-13">
                    <h6 className="text-coco-gray-200 tx-12 lh-16 mb-2">ACTIVITY</h6>
                    <span className="text-coco-gray-700 tx-16 lh-20 fw-semibold">{GetMessageIntl(intl,"workflow.managerReview")}</span>
                </div>
            </div>
            <div className="bg-coco-gray-50 pd-10 node_info flex-shrink-0 mb-12">
                <div className="ht-20">
                    <label style={{width:"80px"}}>{GetMessageIntl(intl,"workflow.reviewAuthTarget")}</label>
                    {GetMessageIntl(intl,"managerUser.admin")},{GetMessageIntl(intl,"managerUser.manager")}
                </div>
            </div>
            <Handle
                className="targetHandle"
                /*onConnect={(params) => console.log('handle onConnect', params)}*/
                style={targetHandleStyle}
                position={Position.Top}
                id={'top'}
                type="target"
                isConnectable={isConnectable}
            />
            <Handle
                className="targetHandle"
                /*onConnect={(params) => console.log('handle onConnect', params)}*/
                style={targetHandleStyle}
                position={Position.Left}
                id={'left'}
                type="target"
                isConnectable={isConnectable}
            />
            <Handle
                className="targetHandle"
                /*onConnect={(params) => console.log('handle onConnect', params)}*/
                style={targetHandleStyle}
                position={Position.Bottom}
                id={'bottom'}
                type="target"
                isConnectable={isConnectable}
            />
            <Handle
                className="targetHandle"
                /*onConnect={(params) => console.log('handle onConnect', params)}*/
                style={targetHandleStyle}
                position={Position.Right}
                id={'right'}
                type="target"
                isConnectable={isConnectable}
            />
        </div>
    );
}

export default memo(ManagerReviewNode);