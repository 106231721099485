import {ArgumentError} from "./exceptions";

export interface ReviewClassesData {
    classId: number;
    reject: boolean | null;
}

export default class ReviewClasses{
    private classId: number;
    private reject: boolean | null;
    private editable: boolean;
    private completedError: boolean;

    constructor(initialData: ReviewClassesData, reset: boolean) {
        const data = {
            classId: undefined,
            reject: undefined,
            editable: true,
            completedError: true
        }

        for (const property in data) {
            if (Object.prototype.hasOwnProperty.call(data, property)) {
                if (property in initialData) {
                    data[property] = initialData[property];
                }

                if (data[property] === undefined) {
                    throw new ArgumentError(`Review field "${property}" was not initialized`);
                }
            }
        }

        if (reset) {
            data.reject = null; // 클래스 검수 결과는 다시 처음부터 진행한다.
            // if (data.reject === false) {
            //     data.editable = false;
            //     data.completedError = false;
            // } else {
            //     data.reject = null;
            // }
        } else {
            if (data.reject === false) {
                data.editable = false;
                data.completedError = false;
            }
        }

        Object.defineProperties(this,
            Object.freeze({
                classId: {
                    get: () => data.classId
                },
                reject: {
                    get: () => data.reject,
                    set: (reject) => {
                        data.reject = reject;
                        data.completedError = reject === null;
                    },
                },
                editable: {
                    get: () => data.editable,
                    set: (editable) => {
                        data.editable = editable;
                    }
                },
                completedError: {
                    get: () => data.completedError,
                    set: (isCompleted) => {
                        data.completedError = isCompleted;
                    }
                }
            })
        );
    }

    public getReject() {
        return this.reject;
    }

    public getHash() {
        const hash: ReviewClassesData = {
            classId: this.classId,
            reject: this.reject
        };
        return hash;
    }

    public getCompletedError(){
        return this.completedError;
    }
}
