import React, { useEffect, useState } from 'react';
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";


//import Components
import Header from './Header';
import Sidebar from './Sidebar';
import Footer from './Footer';

//import actions
import {
    changeDepthLeftsidebarViewType, changeDepthLeftsidebarSizeType,
    changeDepthSidebarTheme, changeDepthLayoutMode,
    changeDepthLayoutWidth, changeDepthLayoutPosition, changeDepthTopbarTheme,
    changeDepthLayout, changeDepthSidebarImageType
} from "../../store/actions";

//redux
import { useSelector, useDispatch } from "react-redux";


const DepthLayout = (props) => {
    const dispatch = useDispatch();
    const {
        layoutType,
        leftSidebarType,
        layoutModeType,
        layoutWidthType,
        layoutPositionType,
        topbarThemeType,
        leftsidbarSizeType,
        leftSidebarViewType,
        leftSidebarImageType
    } = useSelector(state => ({
        layoutType: state.depthLayout.layoutType,
        leftSidebarType: state.depthLayout.leftSidebarType,
        layoutModeType: state.depthLayout.layoutModeType,
        layoutWidthType: state.depthLayout.layoutWidthType,
        layoutPositionType: state.depthLayout.layoutPositionType,
        topbarThemeType: state.depthLayout.topbarThemeType,
        leftsidbarSizeType: state.depthLayout.leftsidbarSizeType,
        leftSidebarViewType: state.depthLayout.leftSidebarViewType,
        leftSidebarImageType: state.depthLayout.leftSidebarImageType,
    }));

    const headerBreadRegex = new RegExp('^/manager/project/.*', 'g');
    const headerBreadYn = headerBreadRegex.test(props.location.pathname);

    /*
    layout settings
    */
    useEffect(() => {
        if (
            layoutType ||
            leftSidebarType ||
            layoutModeType ||
            layoutWidthType ||
            layoutPositionType ||
            topbarThemeType ||
            leftsidbarSizeType ||
            leftSidebarViewType ||
            leftSidebarImageType
        ) {
            dispatch(changeDepthLeftsidebarViewType(leftSidebarViewType));
            dispatch(changeDepthSidebarTheme(leftSidebarType));
            dispatch(changeDepthLayoutMode(layoutModeType));
            // dispatch(changeDepthLayoutWidth(layoutWidthType));
            // dispatch(changeDepthLayoutPosition(layoutPositionType));
            // dispatch(changeDepthTopbarTheme(topbarThemeType));
            dispatch(changeDepthLayout(layoutType));
            // dispatch(changeDepthSidebarImageType(leftSidebarImageType))
        }
    }, [layoutType,
        leftSidebarType,
        layoutModeType,
        layoutWidthType,
        layoutPositionType,
        topbarThemeType,
        leftsidbarSizeType,
        leftSidebarViewType,
        leftSidebarImageType,
        dispatch]);

    const [headerClass, setHeaderClass] = useState("");
    // class add remove in header
    useEffect(() => {
        window.addEventListener("scroll", scrollNavigation, true);
    });
    function scrollNavigation() {
        let scrollup = document.documentElement.scrollTop;
        if (scrollup > 50) {
            setHeaderClass("topbar-shadow");
        } else {
            setHeaderClass("");
        }
    }

    return (
        <React.Fragment>
            <div id="layout-wrapper">
                <Header
                    layoutType={layoutType}
                    headerBreadYn={headerBreadYn}
                    headerClass={headerClass}
                    layoutModeType={layoutModeType} />
                <Sidebar layoutType={layoutType} />
                <div className="main-content">{props.children}
                    {/*<Footer />*/}
                </div>
            </div>
        </React.Fragment>

    );
};

DepthLayout.propTypes = {
    children: PropTypes.object,
};

export default withRouter(DepthLayout);
