import React from 'react';
import {useDispatch} from "react-redux";
import {Col, ModalBody, Row} from "reactstrap";
import CommonUtil from "../../util/CommonUtil";
import SimpleBar from "simplebar-react";
import {board_faqSwitchTmpViewModalFlag, board_switchTmpViewModalFlag} from "../../store/board/action";
import {BoardUtil} from "../../util/BoardUtil";


function FaqTmpView(props) {
    const dispatch = useDispatch();
    return (
        <SimpleBar style={{maxHeight: "865px"}} className="pe-2">
            <ModalBody className="w-70 align-self-center fs-16" style={{paddingTop : '10px'}}>
                <div className="d-flex justify-content-between align-items-center fs-16 fw-500">
                    <div></div>
                    <div style={{width:"1200px"}}>
                        <Row className="tx-20 mb-2">
                            <Col>
                                <span>FAQ<span className="tx-14 text-coco-gray-200">&nbsp;| {BoardUtil.convertCnTyToLabel(props.cnTy)} No.111</span></span>
                            </Col>
                        </Row>
                        <Row className="mb-3" style={{borderBottom : "1px solid #cfd8dc", paddingBottom : "12px"}}>
                            <Col className="d-flex justify-content-between align-items-center">
                                <div className="tx-24 fw-600" style={{height : '40px'}}>
                                    {
                                        props.noticeYn === 'Y' ? <span className="badge badge-soft-danger">중요</span> : null
                                    }{props.cnTitle}
                                </div>
                                <div className="d-flex justify-content-between align-items-center gap-2 tx-16 fw-500">
                                    <div>
                                        {CommonUtil.getToDay()} |
                                    </div>
                                    <div>
                                        조회 수 0회
                                    </div>
                                    <div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row className="mb-2">
                            <Col>
                                {props.files.length !== 0 ?
                                    props.files.map((file)=>{
                                        console.log(file)
                                        return(
                                            <Row className="mb-2">
                                                <div className={"d-flex align-items-center"}>
                                                    <i className="ri-download-2-line text-coco-gray-400"></i>
                                                    <span>&nbsp;{file.file.name}</span>
                                                    <span className="fs-16 fw-500 text-coco-gray-400">&nbsp;({CommonUtil.bytesToSize(file.file.size)})</span>
                                                </div>
                                            </Row>
                                        )
                                    })
                                    : null}
                                {
                                    props.attachFiles.length !== 0 ?
                                        props.attachFiles.map((file)=>{
                                            return(
                                                <Row className="mb-2">
                                                    <div className={"d-flex align-items-center"}>
                                                        <i className="ri-download-2-line text-coco-gray-400"></i>
                                                        <span>&nbsp;{file.split(",")[1]}</span>
                                                        <span className="fs-16 fw-500 text-coco-gray-400">&nbsp;({CommonUtil.bytesToSize(file.split(",")[2])})</span>
                                                    </div>
                                                </Row>
                                            )
                                        })
                                        : null
                                }
                            </Col>
                        </Row>
                        <Row className="fs-16 pd-y-12" style={{height : '100%', borderBottom : "1px solid #cfd8dc", marginBottom : "20px"}}>
                            <SimpleBar style={{maxHeight: "100%"}} className="pe-2">
                                <Col>
                                    <div dangerouslySetInnerHTML={{ __html: props.cn }}></div>
                                </Col>
                            </SimpleBar>
                        </Row>
                        <Row>
                            <div className="d-flex justify-content-between align-items-center" style={{marginBottom:"20px"}}>
                                <div></div>
                                <div className="d-flex justify-content-between align-items-center gap-2">
                                    <button type="button" className="btn btn-coco-1" style={{width:"100px"}} onClick={()=>{dispatch(board_faqSwitchTmpViewModalFlag())}}>
                                        되돌아가기
                                    </button>
                                </div>
                                <div></div>
                            </div>

                        </Row>
                    </div>
                    <div>

                    </div>
                </div>
            </ModalBody>
        </SimpleBar>
    );
}

export default FaqTmpView;