import React, {useEffect, useState} from 'react';
import {Input, Label, Row} from "reactstrap";
import {useForm, useFormContext} from "react-hook-form";
import {GetMessageIntl} from "../../../util/message";
import {useIntl} from "react-intl";

function CheckBoxStep({title, body, subId}) {
    const intl = useIntl();
    const [attachFiles, setAttachFiles] = useState([]);
    const [agreeYn, setAgreeYn] = useState(false);
    const { register, formState: { errors } } = useFormContext();

    useEffect(() => {
        setAttachFiles(body.attachFiles);
    }, [])

    function downloadFile(filePath, realFileName) {
        fetch(filePath, {method: 'GET'})
            .then((res) => {
                return res.blob() // raw 데이터를 받아온다
            })
            .then((blob) => {
                const url = window.URL.createObjectURL(blob) // 받아온 날 상태의 data를 현재 window에서만 사용하는 url로 바꾼다
                const a = document.createElement('a')
                a.href = url
                a.download = realFileName // 원하는 이름으로 파일명 지정
                document.body.appendChild(a)
                a.click() // 자동으로 눌러버리기
                setTimeout((_) => {
                    window.URL.revokeObjectURL(url) // 해당 url을 더 사용 못하게 날려버린다
                }, 60000)
                a.remove() // a를 다 사용했으니 지워준다
                return false;
            })
            .catch((err) => {
                console.error('err: ', err)
            })
    }

    return (
        <>
            <div className="fs-24 text-coco-gray-700">
                {title}
            </div>
            <hr/>
            <div className="text-coco-gray-700">
                <Row className="mb-12">
                    {
                        attachFiles.map((fileInfo, idx) => {
                            const {downloadUrl, host, realFilename} = fileInfo;
                            return (
                                <Row className="mb-3" key={`editor_${title}_${idx}`}>
                                    <div className={"d-flex align-items-center"}>
                                        <i className="ri-download-2-line text-coco-gray-400" onClick={() => {
                                            const filePath = host + downloadUrl;
                                            console.log(filePath)
                                            downloadFile(filePath, realFilename);
                                        }}></i>
                                        <span>&nbsp;{realFilename}</span>
                                        {/*<span className="fs-16 fw-500 text-coco-gray-400">&nbsp;({CommonUtil.bytesToSize(fileSize)})</span>*/}
                                    </div>
                                </Row>
                            )
                        })
                    }
                </Row>
                <Row className="fw-500">
                    <div className="stepCnDiv" dangerouslySetInnerHTML={{__html: body.content}}></div>
                </Row>
                <hr/>
                <Row>
                    <div className="form-check form-check-coco mb-3 d-flex justify-content-center align-items-center">
                        <input {...register(`${subId}.checkbox`, {
                            required: true
                        })}
                               type="checkbox"
                               className={"form-check-input mg-r-10 ".concat(errors[subId] && errors[subId].checkbox ? "is-invalid" : "")}
                               id={`${subId}_agreeYn`}/>
                        <Label className={"form-check-label mb-0 "} htmlFor={`${subId}_agreeYn`}>{GetMessageIntl(intl, "project.taskJoin.agreeYnMsg")}</Label>
                    </div>
                    <div className="d-flex justify-content-center">
                        {errors[subId] && errors[subId].checkbox && <p className={"validation-error-message"}>{GetMessageIntl(intl, "project.taskJoin.requireMsg")}</p>}
                    </div>
                </Row>
            </div>
        </>
    );
}

export default CheckBoxStep;