import {
    SET_SELECTED_FILE,
    SET_FILES,
    SET_FILE_LABELS,
    SET_FILE_ATTRIBUTES,
    REMOVE_FILE,
    SET_FILE_COMMENT,
    SET_HAS_ERROR_FILES,
    CLEAR_HAS_ERROR_FILES,
    CLEAR_HAS_ERROR_FILE,
    SET_FILE_REJECT_REASON,
    RESET_FILE_LABELS
} from './actionType';

const INIT_STATE = {
    selectedFile: {
        fileName: "",
        isClick: false,
        imagePreView: null
    },
    files: [],
    removeFiles: [],
    fileLabels: {},
    fileAttributes: [],
    hasErrorFiles: [],
    shapeConfig: {}
};

const WorkflowReviewTestConfig = (state = INIT_STATE, action) => {
    switch (action.type) {
        case SET_SELECTED_FILE :
            return {
                ...state,
                selectedFile: action.payload
            };
        case SET_FILE_COMMENT :
            const copyList1 = [...state.fileAttributes]
            const newList1 = copyList1.map(item=> {
                if (action.payload.fileName == item.fileName) {
                    item.comment=action.payload.comment
                    return item
                } else {
                    return item
                }
            });
            return {
                ...state,
                fileAttributes: [...newList1]
            };

        case SET_FILE_LABELS :
            if('I' === action.payload.mode){
                const fileLabels = {...state.fileLabels, [action.payload.fileName] : action.payload.data};
                const fileAttributes =
                    [...state.fileAttributes, {fileName : action.payload.fileName, values : {}, comment :action.payload.comment, rejectReason: action.payload.rejectReason}];

                return {
                    ...state,
                    fileLabels: fileLabels,
                    fileAttributes :fileAttributes
                };
            }else if('U' === action.payload.mode){
                const fileLabels2 = {...state.fileLabels,  [action.payload.fileName] : action.payload.data}
                const tmpFileAttributes = [...state.fileAttributes]
                const newtmpFileAttributes =  tmpFileAttributes.map(item=>{
                    if(item.fileName === action.payload.fileName){
                        item['values'] = {}
                    }
                    return item
                });

                return {
                    ...state,
                    fileLabels: fileLabels2,
                    fileAttributes :newtmpFileAttributes
                };
            }

        case SET_FILE_ATTRIBUTES :
            const copyList = [...state.fileAttributes]
            const newList = copyList.map(item=> {
                if (action.payload.fileName == item.fileName) {
                    item.values[action.payload.id] = action.payload.val
                    return item
                } else {
                    return item
                }
            });
            return {
                ...state,
                fileAttributes: [...newList]
            };
        case REMOVE_FILE:
            const tmpFileLabels = {...state.fileLabels}
            let tmpRemoveFiles = [...state.removeFiles ]
            delete tmpFileLabels[action.payload.fileName]

            const newFiles = state.files.filter(item=>{
                return item.path != action.payload.fileName && item.name != action.payload.fileName;
            })

            if(!action.payload.isNew){
                tmpRemoveFiles = [...state.removeFiles,  action.payload.imageUrl]
            }else{
                tmpRemoveFiles = [...state.removeFiles]
            }

            const tmpFileAttributes = [...state.fileAttributes]
            const newtmpFileAttributes =  tmpFileAttributes.filter(item=>item.fileName !== action.payload.fileName)

            return {
                ...state,
                fileLabels: tmpFileLabels,
                fileAttributes :newtmpFileAttributes,
                removeFiles : tmpRemoveFiles,
                files : newFiles
            };

        case SET_FILES:
            return {
                ...state,
                files: action.payload
            };
        case SET_HAS_ERROR_FILES:
            return {
                ...state,
                hasErrorFiles: [...state.hasErrorFiles,  action.payload]
            };

        case CLEAR_HAS_ERROR_FILES :
            return {
                ...state,
                hasErrorFiles: []
            };
        case CLEAR_HAS_ERROR_FILE :
            return {
                ...state,
                hasErrorFiles: state.hasErrorFiles.filter(file => file != action.payload.fileName)
            };

        case SET_FILE_REJECT_REASON :
            const copyList2 = [...state.fileAttributes]
            const newList2 = copyList2.map(item => {
                if (action.payload.fileName == item.fileName) {
                    item.rejectReason = action.payload.reason
                    return item
                } else {
                    return item
                }
            });
            return {
                ...state,
                fileAttributes: [...newList2]
            };

        case RESET_FILE_LABELS:
            return {
                ...state,
                selectedFile: {
                    fileName: "",
                    isClick: false,
                    imagePreView: null
                },
                fileLabels: {},
                fileAttributes: [],
                hasErrorFiles: []
            };

        default:
            return state;
    }
};

export default WorkflowReviewTestConfig;
