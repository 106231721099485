import React, { memo } from 'react';
import {Handle, useReactFlow, useStoreApi, Position, useStore} from 'reactflow';
import {GetMessage} from "../../../../../../../util/message";
import {useDispatch, useSelector} from "react-redux";
import {thisSourceValidConnection} from "../nodeCommonUtil";
const connectionNodeIdSelector = (state) => state.connectionNodeId;
const nodesSelector = (state) => Array.from(state.nodeInternals.values());

/**
 *
 * @param id
 * @param data
 * @param isConnectable
 * @returns {JSX.Element}
 * @constructor
 */
export default function UpdateSubmitNode({ id, data, isConnectable }) {
    const dispatch = useDispatch();
    const nodes = useStore(nodesSelector);
    const connectionNodeId = useStore(connectionNodeIdSelector);
    let connectionNodeType = '';
    if(connectionNodeId){
        let connectionNode = nodes.find((item) => item.id === connectionNodeId);
        let parentNodeType = '';
        if(connectionNode.parentNode){
            parentNodeType = nodes.find((item) => item.id === connectionNode.parentNode).type+'_';
        }
        connectionNodeType = parentNodeType + connectionNode.type;
    }

    const parentId = id.substring(0, id.lastIndexOf('_'));
    const {selectionNodeId} = useSelector((state)=>({
        selectionNodeId : state.ManageWorkflowModule.selectionNodeId
    }));

    const isParent = selectionNodeId && selectionNodeId !== id && parentId ===selectionNodeId;
    const sourceHandleStyle = isParent? { visibility: 'visible', zIndex: 10 } : {visibility: 'hidden', zIndex:1};

    const isSourceValidConnection = (connection) => {
        let connectSourceType = nodes.find((item) => item.id === connection.target).type;
        return thisSourceValidConnection('updateSubmit',connectSourceType);
    }

    return (
        <>
            <Handle
                type="source"
                style={sourceHandleStyle}
                position={Position.Right}
                id={'right'}
                isConnectable={isConnectable}
                isValidConnection={isSourceValidConnection}
            />
            <Handle
                type="source"
                style={sourceHandleStyle}
                position={Position.Left}
                id={'left'}
                isConnectable={isConnectable}
                isValidConnection={isSourceValidConnection}
            />
            {GetMessage("workflow.updateSubmit")}
        </>
    );
}