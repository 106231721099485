import React from 'react';
import {Label} from "reactstrap";

export const SelectView = ({item, values, disabled=false, clsfcSmryForm, required}) => {
    return (
        <>
            {
                values?.map((value, idx) => {
                    return(
                        <div className="form-check form-check-coco mb-2"
                            key={`${value}-${idx}-checkbox`}
                        >
                            <fieldset disabled={disabled}>
                                <input
                                    {...clsfcSmryForm.register(`shapes[${item.id}]`, {
                                        required: required,
                                    })}
                                    className="form-check-input"
                                    type="radio"
                                    id={`${item.id}-${value}`}
                                    name={`shapes[${item.id}]`}
                                    value={value}
                                />
                                <Label className="form-check-label"  htmlFor={`${item.id}-${value}`}>
                                    {value}
                                </Label>
                            </fieldset>
                        </div>
                    )
                })
            }
        </>
    )

}
