export const SWITCH_MODAL_FLAG = "board/SWITCH_MODAL_FLAG";
export const SWITCH_VIEW_MODAL_FLAG = "board/SWITCH_VIEW_MODAL_FLAG";
export const SWITCH_TMP_VIEW_MODAL_FLAG = "board/SWITCH_TMP_VIEW_MODAL_FLAG";
export const SET_ROW_DATA = "board/SET_ROW_DATA";
export const SET_MODAL_MODE = "board/SET_MODAL_MODE";
export const SWITCH_REFRESH_FLAG = "board/SWITCH_REFRESH_FLAG";

// 공지사항
export const NOTICE_SET_SEARCH_PARAM = "board/NOTICE_SET_SEARCH_PARAM";
export const NOTICE_CURRENT_PAGE = "board/NOTICE_CURRENT_PAGE";
export const NOTICE_CN_TITLE = "board/NOTICE_CN_TITLE";
export const NOTICE_REG_DTM = "board/NOTICE_REG_DTM";

// FAQ
// Modal Mode => [L, I, U, V] ( list, insert, update, tmpView )
export const FAQ_SET_MODAL_MODE = "board/FAQ_SET_MODAL_MODE";
export const FAQ_SWITCH_ADD_MODAL_FLAG = "board/FAQ_SET_ADD_MODAL_FLAG"
export const FAQ_SWITCH_VIEW_MODAL_FLAG = "board/FAQ_SWITCH_VIEW_MODAL_FLAG";
export const FAQ_SWITCH_TMP_VIEW_MODAL_FLAG = "board/FAQ_SWITCH_TMP_VIEW_MODAL_FLAG";
export const FAQ_SET_SEARCH_PARAM = "board/FAQ_SET_SEARCH_PARAM";
export const FAQ_SET_CN_TITLE = "board/FAQ_SET_CN_TITLE";
export const FAQ_SET_CN_TYPE_OPTIONS = "board/FAQ_SET_CN_TYPE_OPTIONS";
export const FAQ_SET_CN_TY = "board/FAQ_SET_CN_TY";
export const FAQ_SET_CURRENT_PAGE = "board/FAQ_SET_CURRENT_PAGE";

// 1:1
export const OTO_SET_MODAL_MODE = "board/OTO_SET_MODAL_MODE";
export const OTO_SET_SEARCH_PARAM = "board/OTO_SET_SEARCH_PARAM";
export const OTO_SET_CURRENT_PAGE = "board/OTO_SET_CURRENT_PAGE";
export const OTO_SET_CN_TY = "board/OTO_SET_CN_TY";
export const OTO_SET_CN_TITLE = "board/OTO_SET_CN_TITLE";
export const OTO_SET_REPLY_YN = "board/OTO_SET_REPLY_YN";
export const OTO_SET_REG_DTM = "board/OTO_SET_REG_DTM";
export const OTO_SWITCH_ADD_MODAL_FLAG = "board/OTO_SET_ADD_MODAL_FLAG"
export const OTO_SWITCH_VIEW_MODAL_FLAG = "board/OTO_SWITCH_VIEW_MODAL_FLAG";
export const OTO_SWITCH_TMP_VIEW_MODAL_FLAG = "board/OTO_SWITCH_TMP_VIEW_MODAL_FLAG";
export const OTO_SWITCH_REFRESH_FLAG = "board/OTO_SWITCH_REFRESH_FLAG";