import {CardBody} from "reactstrap";
import {ObjectPopoverClass} from "./object-popover-class";
import {Props as ClassProps} from "./object-popover-class";
import React from "react";
import {ObjectPopoverAttributesContainer, Props as AttributesProps} from "../../../containers/standard-workspace/object-popover/object-popover-attributes";
interface Props extends ClassProps, AttributesProps{
    attributes: any[]
}
export function ObjectPopoverBody(props: Props){
    const {attributes, labels, readonly, shapeType, labelID, changeLabel,
        clientID, parentID
    } = props;
    return (
        <CardBody>
            <ObjectPopoverClass
                labels={labels}
                readonly={readonly}
                shapeType={shapeType}
                labelID={labelID}
                changeLabel={changeLabel}
            />
            {!!attributes.length && (
                <ObjectPopoverAttributesContainer
                    readonly={readonly}
                    clientID={clientID}
                    parentID={parentID}
                    labelID={labelID}
                />
            )}
        </CardBody>
    );
}
