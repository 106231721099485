import SimpleBar from "simplebar-react";
import {Card, CardBody, CardHeader, Col, Input, Label, ListGroup, OffcanvasBody, Row} from "reactstrap";
import {GetMessageIntl} from "../../../../../../../../util/message";
import {Controller, useFormContext} from "react-hook-form";
import React, {useEffect, useState} from "react";
import {useIntl} from "react-intl";
import {useDispatch, useSelector} from "react-redux";
import {CommonService} from "../../../../../../../../apis/api/CmmnService";
import {WithContext as ReactTags} from "react-tag-input";
import WorkerConditionRow from "../../common/WorkerConditionRow";
import Select from "react-select";
import {
    formatGroupLabelNoCount,
    noBorderBackgroundTextSelectStyle
} from "../../../../../../../../components/constants/select";
import {ModuleService} from "../../../../../../../../apis/api/ModuleService";
import Dataset from "../../../../../../../../store/dataset/reducer";
import CommonUtil from "../../../../../../../../util/CommonUtil";

const KeyCodes = {
    enter: 13,
};

const delimiters = [KeyCodes.comma, KeyCodes.enter];
let groupId = 0;
const getGroupId = () => groupId++;

function WorkerConfigDiv(props) {

    const {canvasDataTy} = props;
    const intl = useIntl();
    const dispatch = useDispatch();
    const prjctId = sessionStorage.getItem("prjctId");
    const {
        control: controlWorker,
        register: registerWorker,
        unregister: unregisterWorker,
        setValue: setValueWorker,
        getValues: getValuesWorker,
        reset: resetWorker,
        clearErrors: clearErrorsWorker,
        resetField: resetFieldWorker
        ,
        watch: watchWorker,
        formState: {errors: errorsWorker},
    } = useFormContext();
    const watchAllWorker = watchWorker();
    const watchStandardTy = watchWorker('standardTy');
    const [tags, setTags] = useState([]);
    const [valuesObjArr, setValuesObjArr] = useState([]);
    const [joinConditionTyOptions, setJoinConditionTyOptions] = useState([]);
    const [profileConditionTyOptions, setProfileConditionTyOptions] = useState([]);
    const [historyConditionTyOptions, setHistoryConditionTyOptions] = useState([]);
    const [subConditionTyOptions, setSubConditionTyOptions] = useState([]);
    const {initFlag, workerConfig, selectionNode} = useSelector((state) => ({
        initFlag: state.ManageWorkflowLabel.initFlag,
        workerConfig: state.ManageWorkflowLabel.workerConfig,
        selectionNode: state.ManageWorkflowModule.selectionNode
    }));


    const [workerGroupItems, setWorkerGroupItems] = useState([]);

    const equalsOptions = [
        {label: GetMessageIntl(intl, "common.equals"), value: 'equal'}
        , {label: GetMessageIntl(intl, "common.not"), value: 'not'}
    ];

    const operatorOptions = [
        {label: 'AND', value: 'AND'}
        , {label: 'OR', value: 'OR'}];

    useEffect(() => {
        if (initFlag) {
            setValueWorker('limitTy', []);
            setWorkerGroupItems([]);
            setValuesObjArr([]);
            getWorkerJoinConditionOptions();
            getProfileConditionTyOptions();
            getHistoryConditionTyOptions();
            if (workerConfig) {
                for (const [key, value] of Object.entries(workerConfig)) {
                    if (key === 'conditionEmails') {
                        setValueWorker(key, (value ? value : ""));
                        setValuesObjArr(value ? value.map((valueObj) => {
                            return {id: valueObj, text: valueObj};
                        }) : [])
                    } else if (key === 'groupConditions') {
                        //console.log('groupConditions',value);
                        setWorkerGroupItems(value);
                    } else if (key === 'standardTy') {
                        setValueWorker(key, (value ? value : ""));
                    } else if (key === 'limitTy') {
                        // limitTy
                        setValueWorker(key, (value ? value : ""));
                    } else {
                        setValueWorker(key, (value ? value : ""));
                    }
                }
                ;
            } else {
                setValueWorker('standardTy', '00'); // 제한 없음 으로 기본 셋팅
            }
        }
    }, [initFlag]);

    useEffect(() => {
        if (watchStandardTy == '00') {
            if (getValuesWorker("limitTy")) {
                unregisterWorker("limitTy");
            }
            setWorkerGroupItems([]);
            setValuesObjArr([]);
        } else if (watchStandardTy == '01') {
            if (!getValuesWorker("limitTy")) {
                registerWorker("limitTy");
            }
            let limitArr = [];
            if (valuesObjArr.length > 0) {
                limitArr.push('00')
            }
            if (workerGroupItems.length > 0) {
                limitArr.push('01')
            }
            setValueWorker('limitTy', limitArr);
        }
    }, [watchStandardTy])

    const getWorkerJoinConditionOptions = async () => {
        await CommonService.getWorkerJoinCondition().then((result) => {
            if (result.data.data) {
                let resultData = result.data.data;
                resultData = resultData.map((data) => {
                    return {value: data.cmmnDetailCd, label: data.cmmnDetailCdNm}
                })
                setJoinConditionTyOptions(resultData);
            } else {
                setJoinConditionTyOptions([]);
            }
        });
    }

    const getHistoryConditionTyOptions = async () => {
        await ModuleService.getModuleNameList({
            prjctId: prjctId,
            moduleId: selectionNode.data.moduleId
        }).then((result) => {
            let groupedOptions = [];
            if (result.data.data) {
                let resultData = result.data.data;
                resultData = resultData.map((data) => {
                    let optionItem = new Object();
                    optionItem.label = data.wrkflowNm;
                    optionItem.value = data.wrkflowId;
                    if (data.labelList && data.labelList.length > 0) {
                        let labelList = data.labelList;
                        optionItem.options = labelList.map((label) => {
                            return {value: label.moduleId, label: label.moduleNm}
                        })
                    }
                    groupedOptions.push(optionItem);
                })
                setHistoryConditionTyOptions(groupedOptions);
            } else {
                setHistoryConditionTyOptions([]);
            }
        });
    }

    const getProfileConditionTyOptions = async () => {
        await CommonService.getWorkerJoinConditionProfile().then((result) => {
            if (result.data.data) {
                let resultData = result.data.data;
                resultData = resultData.map((data) => {
                    return {value: data.cmmnDetailCd, label: data.cmmnDetailCdNm}
                })
                setProfileConditionTyOptions(resultData);
            } else {
                setProfileConditionTyOptions([]);
            }
        });
    }

    const handleClickLimitTy = (e) => {
        let limitTyVal = e.target.value;
        let getLimitTy = getValuesWorker('limitTy');

        if (limitTyVal == '01') {
            if (e.target.checked) {
                // 조건 충족을 체크 했을 경우에는 group을 추가 해줘야함.
                clearErrorsWorker('limitTy');
                addGroupCondition();
            } else {
                // 설정된 condition group 내용이 삭제 되어야 됨.
                setWorkerGroupItems([]);
            }
        } else if (limitTyVal == '00') {
            if (e.target.checked) {
                clearErrorsWorker('limitTy');
            } else {
                //초기화..
                setValueWorker('conditionEmails', []);
                setValuesObjArr([]);
            }
        }

    }

    const addGroupCondition = () => {

        if (workerGroupItems.length > 0) {
            const maxGroupItem = workerGroupItems.reduce((previous, current) => {
                console.log(current.id, previous.id);
                return current.id > previous.id ? current : previous;
            });

            groupId = Number(maxGroupItem.id) + 1;
        } else {
            groupId = 0;
        }

        const groupConditionItem = {
            id: getGroupId(),
            operator: 'AND',
            conditions: [{
                id: 0,
                operator: 'AND',
                joinConditionTy: '2010',// 프로필
                profile: null,
                sub: null,
                equals: null,
            }]
        };

        setWorkerGroupItems([...workerGroupItems, groupConditionItem]);
    }

    const deleteGroupConditionEvent = (groupId) => {
        setWorkerGroupItems(workerGroupItems.filter(
            item => item.id !== groupId
        ));

        if (workerGroupItems.length == 1) {
            let getLimitTy = getValuesWorker('limitTy');
            setValueWorker('limitTy', getLimitTy.filter(limitTy => limitTy != '01'));
        }

        unregisterWorker(`groupConditions.${groupId}`)
    }

    const addConditionItemEvent = (groupId) => {
        let conditionId = 0;
        if (workerGroupItems.length > 0) {
            let targetGroupCondition = workerGroupItems.find(group => group.id == groupId)
            if (targetGroupCondition != undefined) {
                const targetConditions = targetGroupCondition.conditions;
                if (targetConditions) {
                    const maxConditionItem = targetConditions.reduce((previous, current) => {
                        return current.id > previous.id ? current : previous;
                    });

                    conditionId = Number(maxConditionItem.id) + 1;
                }
            }
        }

        const groupConditionItem = {
            id: conditionId,
            operator: 'AND',
            joinConditionTy: '2010',// 프로필
            profile: null,
            sub: null,
            equals: null,
        };

        setWorkerGroupItems(workerGroupItems.map(item =>
                item.id === groupId ? {...item, conditions: [...item.conditions, groupConditionItem]}
                    : item
            )
        );
    }

    const changeGroupItemSelectEvent = (groupId, name, event) => {
        const getGroupItemName = name.substring(name.lastIndexOf('.') + 1);
        const value = event.value;
        setValueWorker(name, value);

        setWorkerGroupItems(workerGroupItems.map(item =>
                item.id === groupId ?
                    {...item, [getGroupItemName]: value}
                    : item
            )
        );
    }

    const deleteConditionItemEvent = (groupId, conditionId) => {
        setWorkerGroupItems(workerGroupItems.map(item =>
                item.id === groupId ?
                    {
                        ...item, conditions: item.conditions.filter(
                            attr => attr.id != conditionId)
                    }
                    : item
            )
        );

        unregisterWorker(`groupConditions.${groupId}.conditions.${conditionId}`)
        const targetGroupConditions = workerGroupItems.find(item => item.id === groupId);
        if (targetGroupConditions.conditions.length == 1) {
            // 하나라도 없을 경우에는 group도 삭제 해야됨.
            deleteGroupConditionEvent(groupId);
        }
    }

    const updateConditionItemEvent = (groupId, conditionId, name, event) => {
        const getConditionItemName = name.substring(name.lastIndexOf('.') + 1);
        const value = event.value ? event.value : event.target ? event.target.value : '';
        let targetConditionItem = null;

        workerGroupItems.forEach((item) => {
            if (item.id === groupId) {
                item.conditions.forEach((condition) => {
                    if (condition.id === conditionId) {
                        targetConditionItem = condition;
                        return;
                    }
                });
            }
        });

        setWorkerGroupItems(workerGroupItems.map(item =>
                item.id === groupId ?
                    {
                        ...item, conditions: item.conditions.map(
                            condition => condition.id === conditionId ? {
                                ...targetConditionItem,
                                [getConditionItemName]: value
                            } : condition
                        )
                    }
                    : item
            )
        );

    }

    const handleValuesDelete = (delIndex, event) => {
        // backspace 입력시 안지워지도록
        if (event.type === "keydown" && event.code === "Backspace") {
            return
        }
        let name = 'conditionEmails';
        let value = getValuesWorker(name);
        value.splice(delIndex, 1);

        setValuesObjArr(value ? value.map((valueObj) => {
            return {id: valueObj, text: valueObj};
        }) : [])

        setValueWorker(name, value);
    };

    const handleValuesAddition = (values) => {
        let name = 'conditionEmails';
        let inputValuesArr = [];
        let getItems = getValuesWorker(name);
        let errorValues = [];
        if (values.text) {
            // 이메일 주소 형식 검사 통과
            let inputValues = values.text.split(',');
            inputValues = inputValues.length > 0 ? [...new Set(inputValues)] : inputValues;
            inputValues.forEach((inputValue) => {
                if (inputValue) {
                    if (CommonUtil.emailValidationRegex().test(inputValue)) {
                        if (!itemExistCheck(inputValue)) {
                            inputValuesArr.push(inputValue);
                        }
                    }else{
                        errorValues.push(inputValue)
                    }
                }
            });

            let value = getItems ? [...getItems, ...inputValuesArr] : inputValuesArr;
            setValueWorker(name, value);
            clearErrorsWorker(name);

            setValuesObjArr(value ? value.map((valueObj) => {
                return {id: valueObj, text: valueObj};
            }) : [])
        }
    };

    const handleValuesDrag = (tag, currPos, newPos) => {
        const name = 'conditionEmails';
        const newValues = getValuesWorker(name);
        newValues.splice(currPos, 1);
        newValues.splice(newPos, 0, tag.text);

        let value = newValues;

        setValuesObjArr(value ? value.map((valueObj) => {
            return {id: valueObj, text: valueObj};
        }) : [])

        setValueWorker(name, value);
    }

    const itemExistCheck = (newItem) => {
        let result = false;
        const name = 'conditionEmails';
        let getItems = getValuesWorker(name);
        if (getItems) {
            getItems.map((item) => {
                if (item == newItem) {
                    result = true;
                }
            });
        }
        return result;
    }
    return (
        <>
            <SimpleBar style={{maxHeight: "calc(100vh - 229px)"}} autoHide={false} className="simplebar-track-coco">
                <OffcanvasBody style={{minHeight: "300px"}}>
                    <h5 className="module-title">
                        <i className="mdi mdi-account-search"></i>{GetMessageIntl(intl, "workflow.taskJoinStandard")}
                    </h5>
                    <Row>
                        <Col md={12}>
                            <Label htmlFor="standardTy1"
                                   className="form-label">{GetMessageIntl(intl, "workflow.joinStandard")}<span
                                className="text-coco-danger-400">*</span></Label>
                            <div className="ht-38 d-flex align-items-center">
                                <div className="form-check form-radio-coco form-check-inline">
                                    <input className="form-check-input" type="radio" id="standardTy1"
                                           value="00" {...registerWorker("standardTy")}/>
                                    <Label className="form-check-label mb-0" htmlFor="standardTy1">
                                        {GetMessageIntl(intl, "workflow.noLimit")}
                                    </Label>
                                </div>
                                <div className="form-check form-radio-coco form-check-inline">
                                    <input className="form-check-input" type="radio" id="standardTy2"
                                           value="01" {...registerWorker("standardTy")}/>
                                    <Label className="form-check-label mb-0" htmlFor="standardTy2">
                                        {GetMessageIntl(intl, "workflow.limit")}
                                    </Label>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    {
                        watchStandardTy == '01' ?
                            <div>
                                {/*제한있음*/}
                                <hr className="coco-hr"/>
                                <div>
                                    <h5 className="module-title mb-0">
                                        <i className="ri-spam-3-line"></i>{GetMessageIntl(intl, "workflow.limitSetting")}
                                    </h5>
                                    <div className="mt-12">
                                        <div>
                                            {errorsWorker && errorsWorker.limitTy &&
                                                <p className="validation-error-message mb-0">{GetMessageIntl(intl, "workflow.limitTyErrorMessage")}</p>}
                                            <div className="form-check form-check-coco">
                                                <input className="form-check-input" type="checkbox" id="limitTy1"
                                                       value="00" {...registerWorker("limitTy", {
                                                    required: true,
                                                })} onClick={handleClickLimitTy}
                                                       checked={getValuesWorker('limitTy') && getValuesWorker('limitTy').includes('00') || valuesObjArr && valuesObjArr.length > 0 ? true : false}/>
                                                <Label className="form-check-label mb-0" htmlFor="limitTy1">
                                                    {GetMessageIntl(intl, "workflow.directMode")}
                                                </Label>
                                            </div>
                                            {
                                                (watchAllWorker.limitTy && watchAllWorker.limitTy.includes('00')) || (valuesObjArr && valuesObjArr.length > 0) ?
                                                    <div className={"mt-10 pd-x-22"}>
                                                        <div
                                                            className={errorsWorker && errorsWorker.conditionEmails ? "validation-error" : ""}>
                                                            <Controller
                                                                name={'conditionEmails'}
                                                                rules={{
                                                                    required: true,
                                                                }}
                                                                defaultValue={[]}
                                                                render={({field: {onChange, value, ref, name}}) => (
                                                                    <ReactTags
                                                                        tags={valuesObjArr}
                                                                        delimiters={delimiters}
                                                                        className={"form-control"}
                                                                        handleDelete={(index, e) => handleValuesDelete(index, e)}
                                                                        handleAddition={handleValuesAddition}
                                                                        handleDrag={(tag, currPos, newPos) => {
                                                                            handleValuesDrag(tag, currPos, newPos)
                                                                        }}
                                                                        allowAdditionFromPaste={false}
                                                                        allowUnique={true}
                                                                        inputFieldPosition="top"
                                                                        placeholder={GetMessageIntl(intl, "workflow.emailEnterPlaseholder")}
                                                                        /*autocomplete*/
                                                                    />)}
                                                            />
                                                        </div>
                                                        {errorsWorker && errorsWorker.conditionEmails &&
                                                            <p className="validation-error-message mb-0">{GetMessageIntl(intl, "workflow.emailConditionErrorMessage")}</p>}
                                                        <div className="mt-18 text-right text-coco-gray-400 tx-14">
                                                            <div>
                                                                {GetMessageIntl(intl, "common.totalPiece")}:<span
                                                                className="shownAttrCount">{valuesObjArr.length}</span>{GetMessageIntl(intl, "common.piece")}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    : null
                                            }
                                        </div>
                                        <div className={"mt-8"}>
                                            <div className="form-check form-check-coco">
                                                <input className="form-check-input" type="checkbox" id="limitTy2"
                                                       value="01" {...registerWorker("limitTy", {
                                                    required: true,
                                                })} onClick={handleClickLimitTy}
                                                       checked={getValuesWorker('limitTy') && getValuesWorker('limitTy').includes('01') || workerGroupItems && workerGroupItems.length > 0 ? true : false}/>
                                                <Label className="form-check-label mb-0" htmlFor="limitTy2">
                                                    {GetMessageIntl(intl, "workflow.conditionMode")}
                                                </Label>
                                            </div>
                                            {
                                                (watchAllWorker.limitTy && watchAllWorker.limitTy.includes('01')) || (workerGroupItems && workerGroupItems.length > 0) ?
                                                    <div className={"mt-8  groupConditionsList"}>
                                                        {
                                                            (workerGroupItems).map((data, index) => {
                                                                const {id, operator, conditions} = data;
                                                                return (
                                                                    <Card key={"groupCondition".concat(id)}
                                                                          className={"mb-0 shadow-none border-1 border-coco-gray-100 mb-16"}>
                                                                        <CardBody className="p-0">
                                                                            <div
                                                                                className={"mb-1 groupConditionsTargetDiv pd-x-12 pd-y-16 d-flex align-items-start"}>
                                                                                {/*Group 조건의 논리연산자*/}
                                                                                <input type="hidden"
                                                                                       defaultValue={id} {...registerWorker(`groupConditions.${id}.id`)}/>
                                                                                <div className="wd-84px mr-10">
                                                                                    <Controller
                                                                                        name={`groupConditions.${id}.operator`}
                                                                                        rules={{
                                                                                            required: true,
                                                                                        }}
                                                                                        defaultValue={operator}
                                                                                        render={({
                                                                                                     field: {
                                                                                                         onChange,
                                                                                                         value,
                                                                                                         ref,
                                                                                                         name
                                                                                                     }
                                                                                                 }) => (
                                                                                            <Select
                                                                                                name=""
                                                                                                isSearchable={false}
                                                                                                options={operatorOptions}
                                                                                                placeholder={GetMessageIntl(intl, "common.choice")}
                                                                                                formatGroupLabel={formatGroupLabelNoCount}
                                                                                                styles={noBorderBackgroundTextSelectStyle}
                                                                                                onChange={(event) => changeGroupItemSelectEvent(id, `groupConditions.${id}.operator`, event)}
                                                                                                value={value ? operatorOptions.find((c) => c.value === value) : null}
                                                                                                className={"mb-0 ".concat(index > 0 ? "" : "d-none")}
                                                                                                maxMenuHeight={250}
                                                                                            />
                                                                                        )}
                                                                                    />
                                                                                </div>
                                                                                {/* 내부 조건  영영 시작 */}
                                                                                <div
                                                                                    className={"groupConditions-div w-100"}>
                                                                                    <div className={"list-group"}>
                                                                                        {
                                                                                            conditions.map((condition, index) => {
                                                                                                return (condition ?
                                                                                                    <WorkerConditionRow
                                                                                                        key={`condition_${id}_${condition.id}`}
                                                                                                        index={index}
                                                                                                        groupId={id}
                                                                                                        condition={condition}
                                                                                                        joinConditionTyOptions={joinConditionTyOptions}
                                                                                                        profileConditionTyOptions={profileConditionTyOptions}
                                                                                                        historyConditionTyOptions={historyConditionTyOptions}
                                                                                                        deleteConditionItemEvent={deleteConditionItemEvent}
                                                                                                        updateConditionItemEvent={updateConditionItemEvent}/> : null)
                                                                                            })
                                                                                        }
                                                                                    </div>
                                                                                    <div
                                                                                        className={"d-flex align-items-center justify-content-between"}>
                                                                                        <button type="button"
                                                                                                className={"btn-coco-xs-2"}
                                                                                                onClick={() => addConditionItemEvent(id)}>+ {GetMessageIntl(intl, "workflow.addInnerCondition")}</button>
                                                                                        <span
                                                                                            className="text-center tx-18 text-coco-gray-400"
                                                                                            onClick={() => deleteGroupConditionEvent(id)}><i
                                                                                            className={"ri-delete-bin-line"}/></span>
                                                                                    </div>
                                                                                </div>
                                                                                {/* 내부 조건  영영 끝 */}
                                                                            </div>
                                                                        </CardBody>
                                                                    </Card>
                                                                )
                                                            })
                                                        }

                                                        <div className={""}>
                                                            <button type="button" className={"btn btn-coco-ghost-2"}
                                                                    onClick={addGroupCondition}>+ {GetMessageIntl(intl, "workflow.addGroupCondition")}</button>
                                                        </div>
                                                    </div>
                                                    : null
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            : null
                    }
                </OffcanvasBody>
            </SimpleBar>
        </>
    );
}

export default WorkerConfigDiv;


