import {ImageLabelService} from "../../../../../../apis/api/ImageLabelService";
import {DATA_TYPE, MD_TY_CD, REVIEW_TASK_STTUS_CD} from "../const";
import {getCore} from "../cvat-core-wrapper";
import {LabelCmmnService} from "../../../../../../apis/api/LabelCmmnService";
import {ReviewCmmnService} from "../../../../../../apis/api/ReviewCmmnService";
import {TextLabelService} from "../../../../../../apis/api/TextLabelService";
import {VideoLabelService} from "../../../../../../apis/api/VideoLabelService";
import {AudioLabelService} from "../../../../../../apis/api/AudioLabelService";

function getService(){
    const core = getCore();
    return core.config.service;
}
interface module{
    moduleId: string,
}
interface target{
    datasetId: string,
    fileSeq: number
}
interface annotation{
    annotation: string
}
interface issueContent{
    issueCn: string
}
interface autoLabel{
    targetClassId: string
}

interface reviewAnnotation{
    issueCnObj: string;
    taskSttusCd: REVIEW_TASK_STTUS_CD;
}

// 공통
export async function getFrame({param, dataType}: {param: target, dataType: string}){
    let result = {data: null};
    console.log("getFrame Param", param, dataType);
    switch (dataType) {
        case undefined:
        case DATA_TYPE.IMAGE:
            result = await ImageLabelService.getTargetImage(param);
            break;
        case DATA_TYPE.AUDIO:
            result = await AudioLabelService.getTargetAudio(param);
            break;
        case DATA_TYPE.VIDEO:
            result = await VideoLabelService.getTargetVideo(param);
            break;
        case DATA_TYPE.TEXT:
            result = await TextLabelService.getTargetText(param);
            break;
    }
    return result.data;
}
export async function getAttr(param: module) {
    // api 호출을 위해 axios 사용
    const result = await getService().getProjectInfo(param);
    return result.data;
}
export async function getAnnotationResult(module, target) {
    // 라벨링 결과 가져오기
    const moduleId = module.type === MD_TY_CD.LABEL ? module.id : module.targetModuleId;
    const param: module & target = {
        moduleId,
        ...target
    }
    const result = await LabelCmmnService.jsonData(param);
    return result.data;
}

export async function getSavedReviewResult(param: module & target){
    const result = await getService().getSavedItem(param);
    return result.data;
}
export async function getMeta(){
    // Todo 할당받은 targetData가 list일때의 array의 Meta 값. 추후에 필요한지 확인 필요
    // const result = '{"chunk_size":72,"size":1,"image_quality":70,"start_frame":0,"stop_frame":0,"frame_filter":"","frames":[{"width":749,"height":750,"name":"dog4.jpg","related_files":0,"has_related_context":false}],"deleted_frames":[]}';
    const result = '{"chunk_size":72,"size":3,"image_quality":70,"start_frame":0,"stop_frame":2,"frame_filter":"","frames":[{"width":800,"height":534,"name":"도로1.jpg","related_files":0,"has_related_context":false},{"width":620,"height":359,"name":"도로2.jpg","related_files":0,"has_related_context":false},{"width":768,"height":512,"name":"도로3jpg.jpg","related_files":0,"has_related_context":false}],"deleted_frames":[]}';
    return JSON.parse(result);
}

// LB
export async function skipOnServer(param: module & target & issueContent) {
    const result = await LabelCmmnService.skip(param);
    return result.data;
}

export async function saveOnServer(param: module & target & annotation & issueContent | module & target & annotation) {
    const result = await LabelCmmnService.save(param);
    return result;
}
// LB - Image
export async function getExFilePath(param: module & target) {
    const result = await ImageLabelService.getExFilePath(param);
    return result.data;
}

export async function getBoxPoints(param: module & target & autoLabel) {
    const result = await ImageLabelService.getBoxPoints(param);
    return result.data;
}
// RV
export async function saveReviewOnserver(param: module & target & reviewAnnotation){
    const result = await ReviewCmmnService.save(param);
    return result;
}
