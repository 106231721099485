// @ts-nocheck

interface ObjectStatistics {
    track: number;
    shape: number;
}

interface StatisticsBody {
    rectangle: ObjectStatistics;
    polygon: ObjectStatistics;
    polyline: ObjectStatistics;
    points: ObjectStatistics;
    ellipse: ObjectStatistics;
    cuboid: ObjectStatistics;
    skeleton: ObjectStatistics;
    mask: {
        shape: number;
    };
    tag: number;
    manually: number;
    interpolated: number;
    total: number;
}

export interface ClassTotalDataBody {
    classId: number;
    className: string;
    total: number;
}

export default class Statistics {
    private labelData: Record<string, StatisticsBody>;
    private totalData: StatisticsBody;
    private classTotalData: ClassTotalDataBody[];
    private instanceNotAttrValidCnt: number;
    private instanceNotEditedCnt: number;
    private classesNotEditedCnt: number;

    constructor(label: Statistics['labelData'], total: Statistics['totalData'], classTotal: Statistics['classTotalData'],
                instanceNotAttrValidCnt, instanceNotEditedCnt, classesNotEditedCnt) {
        this.labelData = label;
        this.totalData = total;
        this.classTotalData = classTotal;
        this.instanceNotAttrValidCnt = instanceNotAttrValidCnt;
        this.instanceNotEditedCnt = instanceNotEditedCnt;
        this.classesNotEditedCnt = classesNotEditedCnt;
    }

    public get label(): Record<string, StatisticsBody> {
        return JSON.parse(JSON.stringify(this.labelData));
    }

    public get total(): StatisticsBody {
        return JSON.parse(JSON.stringify(this.totalData));
    }
}
