import {GetMessage} from "../../../../../../../../util/message";

interface Props {
    fstBtnClick() : void;
    sndBtnClick() : void;
}
export function LabelReviewFooter(props: Props){
    const { fstBtnClick, sndBtnClick } = props;
    return (
        <div className="d-flex justify-content-end" style={{padding: "0 0 0 50px", gap: "10px"}}>
            <button type="button" className="btn btn-coco-ghost-1 fw-500"
                    // disabled={disabled}
                    onClick={fstBtnClick}
            >
                {GetMessage('ui.U00083')}
            </button>
            <button type="button" className="btn btn-coco-ghost-3 fw-500"
                    // disabled={disabled}
                    onClick={sndBtnClick}
            >
                {GetMessage('ui.U00084')}
            </button>
        </div>
    )
}
