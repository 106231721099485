import WaveSurfer from "wavesurfer.js";
import {useEffect, useState} from "react";

export const useWavesurfer = (containerRef: any, options: any) => {
    const [wavesurfer, setWavesurfer] = useState(null)
    // Initialize wavesurfer when the container mounts
    // or any of the props change
    useEffect(() => {
        if (!containerRef.current) return
        // console.log('useWavesurfer options', options)
        const ws = WaveSurfer.create({
            ...options,
            container: containerRef.current,
        })
        setWavesurfer(ws)

        return () => {
            ws.destroy()
        }
    }, [options, containerRef])

    return wavesurfer
}
