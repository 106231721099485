import {
    CHANGE_CURRENT_MODULE,
    SET_INIT_SEARCH_PARAM,
    SET_SEARCH_PARAM,
    SET_JOIN_TEST_MODULE_PARAM
} from "./actionType"


export const userBoard_initSearchParam = searchObj => ({
    type : SET_INIT_SEARCH_PARAM,
    payload :  searchObj
});

export const userBoard_setSearchParam = searchObj => ({
    type : SET_SEARCH_PARAM,
    payload : searchObj
});

export const changeCurrentModule = module => ({
    type: CHANGE_CURRENT_MODULE,
    payload: module
});

export const setJoinTestModuleParam = (moduleTy, moduleId, moduleNm, canvasDataTy, testTryCount) =>({
    type: SET_JOIN_TEST_MODULE_PARAM,
    payload: {moduleTy, moduleId, moduleNm, canvasDataTy, testTryCount}
})
