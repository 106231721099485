import React, {useEffect, useState} from 'react';
import {Form, Modal, ModalBody, ModalFooter, ModalHeader, Row} from "reactstrap";
import {GetMessageIntl} from "../../../../../../../../../util/message";
import Select from "react-select";
import {defaultSelectStyle, formatGroupLabelNoCount} from "../../../../../../../../../components/constants/select";
import {useIntl} from "react-intl";
import {ManagerWorkflowService} from "../../../../../../../../../apis/api/ManagerWorkflowService";
import {useDispatch, useSelector} from "react-redux";
import CommonUtil from "../../../../../../../../../util/CommonUtil";
import {setManageWorkflowLabelActiveData} from "../../../../../../../../../store/manage/project/workflow/label/action";

function DuplicateSettingModal(props) {
    const intl = useIntl();
    const dis = useDispatch();

    const { dataConfig, activeData, inactiveData, selectionNodeId } = useSelector((state) => ({
        dataConfig: state.ManageWorkflowLabel.dataConfig,
        activeData: state.ManageWorkflowLabel.activeData,
        inactiveData: state.ManageWorkflowLabel.inactiveData,
        selectionNodeId: state.ManageWorkflowModule.selectionNodeId
    }))

    const wrkflowId = sessionStorage.getItem("wrkflowId");
    const [wrkflowGroupOptions, setWrkflowGroupOptions] = useState([]);
    const [targetData, setTargetData] = useState([]);
    const [targetDataOptions, setTargetDataOptions] = useState([]);
    const [copyTargetSubId, setCopyTargetSubId] = useState("");

    const getOtherDatas = async (prjctId) => {
        const res = await ManagerWorkflowService.getWrkflowsWithModules({
            "prjctId" : prjctId
        });
        if(res.data.data) {
            const re = res.data.data;
            const processedData = re.map(item => {
                const options = item.module
                    .filter(module => module.moduleId !== selectionNodeId && (module.moduleTy === "01" || module.moduleTy === "02"))
                    .map(module => ({
                        label: module.moduleNm,
                        value: module.moduleId,
                        data: module.moduleConfig
                    }));

                return {
                    label: item.wrkflowNm,
                    options: options
                };
            });
            setWrkflowGroupOptions(processedData);
        }
    }

    useEffect(() => {
        getOtherDatas(props.prjctId);
    }, [])

    const handleSaveBtn = () => {
        if(copyTargetSubId !== ""){
            const copyData = targetData?.filter(item => item.subId === copyTargetSubId);
            let newId = 0;
            let newOrder = 1;
            if (dataConfig.length > 0) {
                newId = Math.max.apply(Math, dataConfig.map(function(o) { return o.id; })) || 0;
                newOrder = Math.max(...dataConfig.map((item) => item.order)) + 1;
            }
            const newData = copyData?.map(item => {
                return {
                    ...item,
                    id: (newId += 1).toString(),
                    subId: CommonUtil.generateUID(),
                    useYn: 'Y',
                    order: newOrder
                }
            });
            const updateData = [...activeData, ...newData]
            dis(setManageWorkflowLabelActiveData(updateData))
        }else{
            const copyDatas = targetData;
            let newId = 0;
            let newOrder = 1;
            if (dataConfig.length > 0) {
                newId = Math.max.apply(Math, dataConfig.map(function(o) { return o.id; })) || 0;
                newOrder = Math.max(...dataConfig.map((item) => item.order)) + 1;
            }
            const newData = copyDatas?.map(item => {
                return {
                    ...item,
                    id: (newId += 1).toString(),
                    subId: CommonUtil.generateUID(),
                    useYn: 'Y',
                    order: newOrder
                }
            });
            const updateData = [...activeData, ...newData]
            dis(setManageWorkflowLabelActiveData(updateData))
        }
        props.switchModalFlag();
    }

    return (
        <Modal style={{maxWidth : "525px"}} isOpen={props.modalFlag} centered>
            <ModalHeader toggle={props.switchModalFlag} style={{backgroundColor: '#FFFFFF', borderBottom: '1px solid #CFD8DC', padding: '12px 20px !important'}}>
                {GetMessageIntl(intl, "workflow.dataConfig.duplicateData")}
            </ModalHeader>
            <Form>
                <ModalBody style={{paddingTop:'12px'}}>
                    <div className="mb-3">
                        <Row className="mb-8">
                            <div className="text-coco-gray-700 fs-16 fw-semibold">
                                {GetMessageIntl(intl, "workflow.dataConfig.selectWorkflowAndModule")}
                            </div>
                        </Row>
                        <Row className="pd-x-12">
                            <Select
                                style={{paddingRight : '12px', paddingLeft: '12px'}}
                                placeholder={GetMessageIntl(intl, "workflow.dataConfig.choice")}
                                options={wrkflowGroupOptions}
                                formatGroupLabel={formatGroupLabelNoCount}
                                styles={defaultSelectStyle}
                                onChange={(values) => {
                                    const dataConfig = JSON.parse(values.data).dataConfig;
                                    const config = dataConfig
                                        ? dataConfig.map(({title, subId}) => ({ label: title, value: subId }))
                                        : [];
                                    const configData = [{ value: "", label: GetMessageIntl(intl, "workflow.dataConfig.allDataDuplicate") }, ...config];
                                    setTargetDataOptions(configData);
                                    setTargetData(dataConfig);
                                }}
                                className="mb-0 pd-0"
                            />
                        </Row>
                    </div>
                    {
                        targetData?.length > 0 ?
                        <div>
                            <Row className="mb-8">
                                <div className="text-coco-gray-700 fs-16 fw-semibold"
                                     style={{paddingLeft:"calc(var(--vz-gutter-x) * 0.5)", paddingRight:"calc(var(--vz-gutter-x) * 0.5)"}}>
                                    {GetMessageIntl(intl, "workflow.dataConfig.selectData")}
                                </div>
                            </Row>
                            <Row className="pd-x-12">
                                <Select
                                    placeholder={GetMessageIntl(intl, "workflow.dataConfig.choice")}
                                    options={targetDataOptions}
                                    styles={defaultSelectStyle}
                                    onChange={(values) => {
                                        setCopyTargetSubId(values.value);
                                    }}
                                    className="mb-0 pd-0"
                                />
                            </Row>
                        </div> :
                            <div>
                                {GetMessageIntl(intl, "workflow.dataConfig.noSelectableData")}
                            </div>
                    }
                </ModalBody>
            </Form>
            <ModalFooter>
                <button className="btn btn-coco-2" onClick={props.switchModalFlag}>
                    {GetMessageIntl(intl, "common.cancel")}
                </button>
                <button
                    type="button"
                    className={"btn btn-coco-1 ".concat(!targetData?.length > 0 && "disabled")}
                    onClick={handleSaveBtn}
                >
                    {GetMessageIntl(intl, "workflow.dataConfig.duplicate")}
                </button>
            </ModalFooter>
        </Modal>
    );
}

export default DuplicateSettingModal;
