import React from 'react';
import {ListGroupItem} from "reactstrap";

interface Props {
    data: any;
    handleRowClick: any;
    selectedId: number;
    method: any;
}
export const ContentView = (props : Props) => {
    const { data, handleRowClick, selectedId, method} = props;

    return (
        <>
            {
                data && data?.map((rowData, index) => {
                    const isSelected = selectedId === rowData.id;

                    let hasError = false;
                    if(method?.formState?.errors?.labels && method?.formState?.errors?.labels[rowData.id]){
                        hasError = true
                    }

                    const selectedClass = isSelected ? ' selected' : '';
                    const errorClass = hasError ? ' validation-error-sub' : '';

                    return (
                        <ListGroupItem key={index}
                                       onClick={()=>{handleRowClick(rowData)}}
                                       className={"d-flex align-items-center justify-content-between border-0 pd-y-6 "?.concat(selectedClass)?.concat(errorClass)}
                        >
                            <div className="d-flex gap-3 text-overflow" style={{padding: "5px 5px"}}>
                                <div className={'handle text-pointer'}>
                                    <i className="bx bx-grid-vertical" style={{fontSize : "25px"}}></i>
                                </div>
                                <div className={"d-flex fs-16 text-coco-gray-700 text-overflow"}>
                                    {
                                        rowData.type === "clsfc"
                                            ? <div className="flex-shrink-0 avatar-16" style={{marginRight: "8px"}}>
                                                <div className="avatar-title rounded-circle pd-4"
                                                     style={{backgroundColor: "#EDE0FF", color: "#9E53FF", height: "24px", width: "24px"}}>
                                                    <i className="ri-node-tree"></i></div>
                                            </div>
                                            : <div className="flex-shrink-0 avatar-16" style={{marginRight: "8px"}}>
                                                <div className="avatar-title rounded-circle pd-4"
                                                     style={{backgroundColor: "rgb(255, 242, 208)", color: "#FFB900", height: "24px", width: "24px"}}>
                                                    <i className="mdi mdi-text-shadow"></i></div>
                                            </div>
                                    }
                                    {
                                        <>
                                    <span style={{textOverflow: "ellipsis",overflow: "hidden",whiteSpace: "nowrap"}}>
                                        {rowData.question}
                                    </span>
                                            {rowData.attributes[0].required === 1 && <span className="text-coco-danger-400" style={{marginLeft:"5px"}}>*</span>}
                                        </>
                                    }
                                </div>
                            </div>
                        </ListGroupItem>
                    )
                })
            }
        </>
    );
}



