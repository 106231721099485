import React from "react";
import Login from "../pages/Login.tsx";

import AuthProcess from "../lib/authProcess";
import {GetMessage} from "../util/message";
import ManagerList from "../pages/admin/ManagerList";

// 플랫폼 관리자
import User from "../pages/platform/User";

// 프로젝트 관리자
import DatasetListByFile from "../pages/manager/dataset/DatasetListByFile";
import ManagerProject from "../pages/manager/project/ManagerProject";
import ProjectWorker from "../pages/manager/project/ProjectWorker";
import ManagerWorkFlow from "../pages/manager/project/workflow/ManagerWorkFlow";
import ManagerUser from "../pages/manager/user/ManagerUser";
import ProjectWorkFlowSetting from "../pages/manager/project/workflow/ManagerWorkFlowSetting";

// 게시판
import BoardNoticeList from "../pages/board/Notice";
import BoardFaqList from "../pages/board/Faq";
import BoardAskList from "../pages/board/Ask";

// 사용자
import UserProjectNotice from "../pages/user/project/Notice.jsx"
import UserProjectJoined from "../pages/user/project/Joined.jsx"
import UserProjectTutorial from "../pages/user/project/Tutorial";
import ProjectSetting from "../pages/manager/project/ProjectSetting";
import Dashboard from "../pages/manager/project/Dashboard";
import SweetAlertTest from "../pages/test/SweetAlertTest";
import UiFormTest from "../pages/test/UiFormTest";
import ValidationTest from "../pages/test/ValidationTest";
import WorkPage from "../pages/user/label/WorkPageContainer";
import socketTest from "../pages/test/websocketTest";

// test page
import Test from "../pages/test/Test.js";
import FileUploadTest from "../pages/test/FileuploadTest.js";
import FileExportTest from "../pages/test/FileExportTest.js";
import ApiTest from "../pages/test/ApiTest.js";
import EditorTestCK from "../pages/test/EditorTestCK";
import AgGridTest from "../pages/test/AgGridTest";
import DatasetFileListTest from "../pages/test/DatasetFIleList";
import SortableTest from "../pages/test/SortableTest";
import CmmnCodeTest from "../pages/test/CmmnCodeTest";
import MemberJoin from "../pages/noauth/MemberJoin";
import InputTagTest from "../pages/test/InputTagTest"
import ModuleTestConfigUpdateTest from "../pages/test/ModuleTestConfigUpdateTest";
import DndTest from "../pages/test/DndTest";
import ModuleDataConfigUpdateTest from "../pages/test/ModuleDataConfigUpdateTest";
import ModuleNormalConfigUpdateTest from "../pages/test/ModuleNormalConfigUpdateTest";
import ModuleTestConfigClassTest from "../pages/test/ModuleTestConfigClassTest";
import ValidationGroupTest from "../pages/test/ValidationGroupTest";
import TaskInfoDocPage from "../pages/user/project/TaskInfoDocPage";
import ToastTest from "../pages/test/ToastTest";
import FilefondTest from "../pages/test/FilefondTest";
import Loading from "../components/common/Loading";
import TextareaDownloadTest from "../pages/test/TextareaDownloadTest";
import ServerLog from "../pages/admin/ServerLog";
import SessionTest from "../pages/test/SessionTest";
import AudioPlayTest from "../pages/test/AudioPlayTest";
import TextLabelTest from "../pages/test/TextLabelTest";
import ReactHookFormTest from "../pages/test/ReactHookFormTest";
import DashboardBatchTest from "../pages/test/DashboardBatchTest";
import {ClsfcSmryWorkspace} from "../pages/user/label/clsfcSmry/ClsfcSmryWorkspace";
import {ResizableLayoutTest} from "../pages/test/ResizableLayoutTest";
import ManagerWorkPage from "../pages/manager/dataset/details/ManagerWorkPage";

export function GetMenuInfo(menuId) {
    let url = '/#';
    let icon = '';
    let name = '';
    let find = false;
    authProtectedRoutes.forEach(item => {
        if (item.menuId === menuId) {
            url = item.path;
            icon = item.menuIcon;
            name = GetMessage("menu." + menuId);
            find = true;
        }
    });

    if (!find) {
        authProtectedCustomRoutes.forEach(item => {
            if (item.menuId === menuId) {
                url = item.path;
                icon = item.menuIcon;
                name = GetMessage("menu." + menuId);
                find = true;
            }
        });
    }
    if (!find) {
        authProtectedTwoColumnRoutes.forEach(item => {
            if (item.menuId === menuId) {
                url = item.path;
                icon = item.menuIcon;
                name = GetMessage("menu." + menuId);
                find = true;
            }
        });
    }
    return {url, icon, name}
}

const authProtectedRoutes = [

    // 플랫폼 관리자
    {path: "/platform/user", component: User, exact: true, key: "MFUR0000", isMainAppMenu: true, menuId: "MFUR0000", menuIcon: "ri-pages-line"}, // 플랫폼 회원 목록
    {path: "/platform/board/notice", component: BoardNoticeList, exact: true, key: "MFBD0000", isMainAppMenu: true, menuId: "MFBD0000", menuIcon: "ri-artboard-line"},  // 게시판 관리

    // 매니저 페이지
    {path: "/manager/project", component: ManagerProject, exact: true, key: "MPJT0000", isMainAppMenu: true, menuId: "MPJT0000", menuIcon: "ri-pages-line"}, // 프로젝트 관리
    {path: "/manager/member", component: ManagerUser, exact: true, key: "MGMR0000", isMainAppMenu: true, menuId: "MGMR0000", menuIcon: "ri-user-settings-line"},  // 매니저 관리
    {path: "/manager/board/notice", component: BoardNoticeList, exact: true, key: "MBRD0000", isMainAppMenu: true, menuId: "MBRD0000", menuIcon: "ri-artboard-line"},  // 게시판 관리
    // {path: "/manager/userList", component: User, exact: true, key: "MGUR0000", isMainAppMenu: true, menuId: "MGUR0000", menuIcon: "ri-user-5-line"},  // 회원 관리
    // {path: "/manager/project/worker", component: User, exact: true, key: "MGUR0000", isMainAppMenu: true, menuId: "MGUR0000", menuIcon: "ri-settings-3-line"},  // 작업자 관리
    // {path: "/#", component: DataFolder, exact: true, key: "MSMR0000", isMainAppMenu: true, menuId: "MSMR0000", menuIcon: "ri-settings-3-line"}, // 환경설정 대표

    // 사용자 페이지
    {path: "/user/project/notice", component: UserProjectNotice, exact: true, key: "LPJB0000", isMainAppMenu: true, menuId: "LPJB0000", menuIcon: "ri-survey-line"},
    {path: "/user/project/joined", component: UserProjectJoined, exact: true, key: "LJIP0000", isMainAppMenu: true, menuId: "LJIP0000", menuIcon: "ri-file-mark-line"},
    {path: "/user/project/tutorial", component: UserProjectTutorial, exact: true, key: "LTTR0000", isMainAppMenu: true, menuId: "LTTR0000", menuIcon: "ri-movie-line"},
    {path: "/user/board/notice", component: BoardNoticeList, exact: true, key: "LBRD0000", isMainAppMenu: true, menuId: "LBRD0000", menuIcon: "ri-artboard-line"},  // 게시판 관리


    // 관리자 페이지
    // {path: "/#", component: ServerLog, exact: true, key: "SAST0000", isMainAppMenu: true, menuId: "SAST0000", menuIcon: "ri-todo-line"},
    {path: "/admin/log", component: ServerLog, exact: true, key: "SAST0000", isMainAppMenu: true, menuId: "SAST0000", menuIcon: "ri-todo-line"},
];

const authProtectedTwoColumnRoutes = [

    {path: "/manager/board/notice", component: BoardNoticeList, exact: true, key: "MFUT0001", isMainAppMenu: true, menuId: "MFUT0001", menuIcon: "mdi mdi-bullhorn-variant-outline"},  // 게시판 관리
    {path: "/manager/board/faq", component: BoardFaqList, exact: true, key: "MFUT0002", isMainAppMenu: true, menuId: "MFUT0002", menuIcon: "ri-discuss-line"},  // FAQ
    {path: "/manager/board/ask", component: BoardAskList, exact: true, key: "MFUT0003", isMainAppMenu: true, menuId: "MFUT0003", menuIcon: "ri-questionnaire-line"},  // 1:1문의사항


    // 플랫폼 관리자
    {path: "/platform/board/notice", component: BoardNoticeList, exact: true, key: "MFBD0001", isMainAppMenu: true, menuId: "MFBD0001", menuIcon: "mdi mdi-bullhorn-variant-outline"},  // 게시판 관리
    {path: "/platform/board/faq", component: BoardFaqList, exact: true, key: "MFBD0002", isMainAppMenu: true, menuId: "MFBD0002", menuIcon: "ri-discuss-line"},  // FAQ
    {path: "/platform/board/ask", component: BoardAskList, exact: true, key: "MFBD0003", isMainAppMenu: true, menuId: "MFBD0003", menuIcon: "ri-questionnaire-line"},  // 1:1문의사항


    // 프로젝트 관리 하위 메뉴
    {path: "/manager/project/dashboard", component: Dashboard, exact: true, key: "MPJT0001", isMainAppMenu: true, menuId: "MPJT0001", menuIcon: "ri-pie-chart-2-line"}, // 프로젝트 관리/대시보드
    {path: "/manager/project/workflow", component: ManagerWorkFlow, exact: true, key: "MPJT0002", isMainAppMenu: true, menuId: "MPJT0002", menuIcon: "ri-route-line"}, // 프로젝트 관리/워크플로우
    {path: "/manager/project/dataset", component: DatasetListByFile, exact: true, key: "MPJT0003", isMainAppMenu: true, menuId: "MPJT0003", menuIcon: "ri-database-2-line"}, // 프로젝트 관리/데이터셋
    {path: "/manager/project/worker", component: ProjectWorker, exact: true, key: "MPJT0004", isMainAppMenu: true, menuId: "MPJT0004", menuIcon: "ri-user-received-line"}, // 프로젝트 관리/작업자
    {path: "/manager/project/setting", component: ProjectSetting, exact: true, key: "MPJT0005", isMainAppMenu: true, menuId: "MPJT0005", menuIcon: "ri-settings-5-line"}, // 프로젝트 관리/워크플로우


    // 매니저 권한 게시판 관리 하위 메뉴
    {path: "/manager/board/notice", component: BoardNoticeList, exact: true, key: "MBRD0001", isMainAppMenu: true, menuId: "MBRD0001", menuIcon: "mdi mdi-bullhorn-variant-outline"},  // 게시판 관리
    {path: "/manager/board/faq", component: BoardFaqList, exact: true, key: "MBRD0002", isMainAppMenu: true, menuId: "MBRD0002", menuIcon: "ri-discuss-line"},  // FAQ
    {path: "/manager/board/ask", component: BoardAskList, exact: true, key: "MBRD0003", isMainAppMenu: true, menuId: "MBRD0003", menuIcon: "ri-questionnaire-line"},  // 1:1문의사항

    // 사용자 권한 게시판 하위 메뉴
    {path: "/user/board/notice", component: BoardNoticeList, exact: true, key: "LBRD0001", isMainAppMenu: true, menuId: "LBRD0001", menuIcon: "mdi mdi-bullhorn-variant-outline"},  // 게시판 관리
    {path: "/user/board/faq", component: BoardFaqList, exact: true, key: "LBRD0002", isMainAppMenu: true, menuId: "LBRD0002", menuIcon: "ri-discuss-line"},  // FAQ
    {path: "/user/board/ask", component: BoardAskList, exact: true, key: "LBRD0003", isMainAppMenu: true, menuId: "LBRD0003", menuIcon: "ri-questionnaire-line"},  // 1:1문의사항

    //{path: "/manager/project/workflow", component: ManagerWorkFlow, exact:  true, key: "MPJT0001", isMainAppMenu: true, menuId: "MPJT0001", menuIcon: "ri-article-line"}, // 프로젝트 관리/워크플로우
    //{path: "/manager/project/workflow/setting", component: ProjectWorkFlowSetting, exact: true, key: "MPJT0011", isMainAppMenu: true, menuId: "MPJT0011", menuIcon: "ri-article-line"}, // 프로젝트 관리/워크플로우 셋팅
    //{path: "/manager/project/label", component: ManagerLabel, exact: true, key: "MPJT0001", isMainAppMenu: true, menuId: "MPJT0001", menuIcon: "ri-article-line"}, // 프로젝트 관리/라벨
    //{path: "/manager/project/label/setting", component: ProjectLabelSetting, exact: true, key: "MPJT0001", isMainAppMenu: true, menuId: "MPJT0001", menuIcon: "ri-article-line"}, // 프로젝트 관리/라벨 셋팅
]

const authProtectedSettingRoutes = [
    // Canvas
    {path: "/manager/project/workflow/setting", component: ProjectWorkFlowSetting, exact: true, key: "MPJT0011", isMainAppMenu: true, menuId: "MPJT0011", menuIcon: "ri-article-line"}, // 프로젝트 관리/워크플로우 셋팅
];

const authProtectedCustomRoutes = [
    // Manager
    // 프로젝트 관리 데이터셋 하위 페이지
    {path: "/manager/project/dataset/details", component: ManagerWorkPage, exact: true, key: "details", isMainAppMenu: false, menuId: ""},   // 상세 페이지

    // User
    {path: "/label/image", component: WorkPage, exact: true, key: "label", isMainAppMenu: false, menuId: ""}, // 작업 페이지
    {path: "/manager/label/image", component: WorkPage, exact: true, key: "label", isMainAppMenu: false, menuId: ""}, // 작업 페이지
    {path: "/user/task/info/:moduleId/:type", component: TaskInfoDocPage, exact: true, key: "info", isMainAppMenu: false, menuId: ""},
];

const publicRoutes = [
    {path: "/login", component: Login, exact: true, key: "login"},
    {path: "/logout", component: AuthProcess.LogoutProcess, exact: true, key: "logout"},
    {path: "/join", component: MemberJoin, exact: true, key: "join"},

];

const testRoutes = [
    {path: "/apiTest", component: ApiTest, exact: true, key: "apiTest"},
    {path: "/test", component: Test, exact: true, key: "test"},
    {path: "/fileuploadTest", component: FileUploadTest, exact: true, key: "fileuploadTest"},
    {path: "/fileExportTest", component: FileExportTest, exact: true, key: "fileExportTest"},
    {path: "/alertTest", component: SweetAlertTest, exact: true, key: "alertTest"},
    {path: "/uiFormTest", component: UiFormTest, exact: true, key: "uiFormTest"},
    {path: "/validationTest", component: ValidationTest, exact: true, key: "validationTest"},
    {path: "/socketTest", component: socketTest, exact: true, key: "socketTest"},
    {path: "/ckEditorTest", component: EditorTestCK, exact: true, key: "ckEditorTest"},
    {path: "/agGridTest", component: AgGridTest, exact: true, key:"agGridTest"},
    {path: "/datasetFileListTest", component: DatasetFileListTest, exact: true, key:"datasetFileListTest"},
    {path: "/sortableTest", component: SortableTest, exact: true, key:"sortableTest"},
    {path: "/cmmnCodeTest", component: CmmnCodeTest, exact: true, key:"cmmnCodeTest"},
    {path: "/inputTagTest", component: InputTagTest, exact: true, key:"inputTagTest"},
    {path: "/dndTest", component: DndTest, exact: true, key:"dndTest"},
    {path: "/moduleDataConfigUpdateTest", component: ModuleDataConfigUpdateTest, exact: true, key:"moduleDataConfigUpdateTest"},
    {path: "/moduleTestConfigUpdateTest", component: ModuleTestConfigUpdateTest, exact: true, key:"moduleTestConfigUpdateTest"},
    {path: "/moduleTestConfigClassTest", component: ModuleTestConfigClassTest, exact: true, key:"moduleTestConfigClassTest"},
    {path: "/moduleNormalConfigUpdateTest", component: ModuleNormalConfigUpdateTest, exact: true, key:"moduleNormalConfigUpdateTest"},
    {path: "/validationGroupTest", component: ValidationGroupTest, exact: true, key:"validationGroupTest"},
    {path: "/toastTest", component: ToastTest, exact: true, key:"toastTest"},
    {path: "/filefondTest", component: FilefondTest, exact: true, key:"filefondTest"},
    {path: "/loading", component: Loading, exact: true, key:"loadingPageTest"},
    {path: "/downloadTest", component: TextareaDownloadTest, exact: true, key:"downloadTest"},
    {path: "/sessionTest", component: SessionTest, exact: true, key:"sessionTest"},
    {path: "/audioPlayTest", component: AudioPlayTest, exact: true, key:"audioPlayTest"},
    {path: "/textLabelTest", component: TextLabelTest, exact: true, key:"textLabelTest"},
    {path: "/reactHookFormTest", component: ReactHookFormTest, exact: true, key:"reactHookFormTest"},
    {path: "/dashboardBatchTest", component: DashboardBatchTest, exact: true, key:"reactHookFormTest"},
    {path: "/clsfcSmryLayout", component: ClsfcSmryWorkspace, exact: true, key:"clsfcSmryLayout"},
    {path: "/resizableLayoutTest", component: ResizableLayoutTest, exact: true, key:"resizableLayoutTest"},
];


export {authProtectedRoutes, authProtectedTwoColumnRoutes, publicRoutes, authProtectedCustomRoutes, authProtectedSettingRoutes, testRoutes};
