import React, {useEffect, useState} from 'react';
import BreadCrumb from "../../../components/common/BreadCrumb";
import {Card, CardHeader, Col, Container, Input, Row} from "reactstrap";
import {useDispatch, useSelector} from "react-redux";
import {GetMessage} from "../../../util/message";
import Select from "react-select";
import {TaskService} from "../../../apis/api/TaskService";
import TaskCardList from "./TaskCardList";
import {
    user_task_setInitSearchParam,
    user_task_setSearchParam,
    user_task_setList,
    user_task_setOrderParam,
    setTaskJoinModalFlag, resetUserTaskParam, setTaskTestModalFlag, getModule, setTaskRefreshFlag
} from 'store/actions';
import TaskJoinModal from "./TaskJoinModal";
import TaskTestModal from "./TaskTestModal";
import {alertError, confirm} from "../../../util/AlertUtil";
import {assignCheck} from "../../../store/user/board/saga";
import {useIntl} from "react-intl";
import {noBorderBackgroundSelectStyle} from "../../../components/constants/select";

function UserProjectNotice() {
    const intl = useIntl();
    const dispatch = useDispatch();
    const {
        searchParam,
        orderParam,
        refreshFlag,
    } = useSelector((state) => ({
        searchParam: state.UserTask.searchParam,
        orderParam: state.UserTask.orderParam,
        refreshFlag : state.UserTask.refreshFlag
    }));

    const [taskList, setTaskList] = useState([]);
    const [targetModuleTy, setTargetModuleTy] = useState(null);
    const [targetModuleId, setTargetModuleId] = useState(null);
    const [targetModuleNm, setTargetModuleNm] = useState(null);
    const [targetCanvasDataTy, setTargetCanvasDataTy] = useState(null);

    const searchOption =[
        { label: GetMessage("ui.U00126"), value: "" },
        { label: GetMessage("ui.U00038"), value: "01" },
        { label: GetMessage("ui.U00042"), value: "02" }
    ];
    const orderByOption =[
        { label: GetMessage("ui.U00127"), value: 0 },
        { label: GetMessage("ui.U00128"), value: 1 },
    ];

    useEffect(() => {
        dispatch(resetUserTaskParam());
        dispatch(setTaskRefreshFlag(false));
    }, []);

    useEffect(() => {
        getTasks();
    }, [searchParam, orderParam]);

    useEffect(()=>{
        if(refreshFlag){
            getTasks();
        }
    }, [refreshFlag])


    const moduleLinkedClick = (moduleTy, moduleId, moduleNm, canvasDataTy) =>{
        dispatch(setTaskJoinModalFlag(true));
        //dispatch(setTaskTestModalFlag(true));
        setTargetModuleTy(moduleTy);
        setTargetModuleId(moduleId);
        setTargetModuleNm(moduleNm);
        setTargetCanvasDataTy(canvasDataTy);
    }

    const handleSearch = (event)=>{
        if(event.value === ""){
            dispatch(user_task_setInitSearchParam());
        }else{
            dispatch(user_task_setSearchParam({'MODULE_TY': event.value}));
        }
    }

    const handleOrderBy = (event)=>{
        dispatch(user_task_setOrderParam(event.value));
    }


    const getTasks = async () => {
        const res = await TaskService.getTaskList({
            'orderFlag' : orderParam[0],
            'searchParam' : searchParam
        })

        if(res.data.data){
            setTaskList(res.data.data);
        }
    };

    return (
        <>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title={GetMessage("menu.LPJB0000")} pageTitle="Laflow" />
                    {/*<Row className="px-0 border rounded-3 mb-26">
                        <Col md={12} className="pd-16 text-coco-gray-700 tx-18 fw-semibold"><i className="ri-thumb-up-line"></i> {GetMessage("ui.U00129")}</Col>
                        <Row className="row-cols-xxl-5 px-16">
                            <TaskCardList list={taskList} clickEvent={moduleLinkedClick} handleEvent/>
                            <Swiper
                                slidesPerView={1}
                                spaceBetween={10}
                                pagination={{
                                    el: '.swiper-pagination',
                                    clickable: true,
                                }}
                                breakpoints={{
                                    640: {
                                        slidesPerView: 2,
                                        spaceBetween: 20,
                                    },
                                    768: {
                                        slidesPerView: 3,
                                        spaceBetween: 40,
                                    },
                                    1024: {
                                        slidesPerView: 4,
                                        spaceBetween: 50,
                                    },
                                }}
                                loop={true}
                                modules={[Navigation]}
                                navigation={true}
                                className="mySwiper swiper responsive-swiper rounded gallery-light pb-4"
                            >
                                <div className="swiper-wrapper">
                                    <SwiperSlide>

                                    </SwiperSlide>
                                </div>
                            </Swiper>
                        </Row>
                    </Row>*/}
                    <Row>
                        <Card className="shadow-none">
                            <CardHeader className="border-0 rounded p-0">
                                <Row className="g-3">
                                    <Col md={2}>
                                        <Select
                                            name="searchParam"
                                            className="mb-0"
                                            onChange={
                                                handleSearch
                                            }
                                            defaultValue={searchOption[0]}
                                            options={searchOption}
                                        />
                                    </Col>
                                    <div className="col-md-1 ms-auto" style={{width: '10%'}}>
                                        <div className="gap-1">
                                            <Select
                                                name="orderParam"
                                                className="mb-0"
                                                onChange={
                                                    handleOrderBy
                                                }
                                                defaultValue={orderByOption[0]}
                                                options={orderByOption}
                                            />
                                        </div>
                                    </div>
                                </Row>
                            </CardHeader>
                        </Card>
                    </Row>
                    <Row className="row-cols-xxl-5 row-cols-lg-3 row-cols-1">
                        <TaskCardList list={taskList} clickEvent={moduleLinkedClick} handleEvent/>
                    </Row>
                </Container>
                <TaskJoinModal moduleId={targetModuleId} moduleTy={targetModuleTy} canvasDataTy={targetCanvasDataTy}/>
                <TaskTestModal moduleId={targetModuleId} moduleTy={targetModuleTy} canvasDataTy={targetCanvasDataTy} moduleNm={targetModuleNm} taskYn={true} testTryCount={0}/>
            </div>
        </>
    );
}

export default UserProjectNotice;
