// Copyright (C) 2020-2022 Intel Corporation
//
// SPDX-License-Identifier: MIT
// @ts-nocheck
import { AnyAction } from 'redux';

// import { BoundariesActionTypes } from 'cvat/actions/boundaries-actions';
// import { AuthActionTypes } from 'cvat/actions/auth-actions';
// import { SettingsActionTypes } from 'cvat/actions/settings-actions';
// import { AnnotationActionTypes } from 'cvat/actions/annotation-actions';
import {
    CREATE_ANNOTATIONS_SUCCESS,
    CHANGE_FRAME_SUCCESS,
    UPLOAD_MODULE_ANNOTATIONS_SUCCESS,
    GET_MODULE_SUCCESS,
    INTERACT_WITH_CANVAS,
} from 'store/label/actionType'
import {
    SettingsState, GridColor, FrameSpeed, ColorBy, DimensionType,
} from '../label';
import { ObjectState, ShapeType } from 'pages/user/label/annotation/image/cvat-core-wrapper';
import {
    CHANGE_AAM_ZOOM_MARGIN, CHANGE_AUTO_SAVE_INTERVAL, CHANGE_BRIGHTNESS_LEVEL,
    CHANGE_CANVAS_BACKGROUND_COLOR, CHANGE_CONTRAST_LEVEL,
    CHANGE_DEFAULT_APPROX_POLY_THRESHOLD,
    CHANGE_FRAME_SPEED,
    CHANGE_FRAME_STEP,
    CHANGE_GRID_COLOR,
    CHANGE_GRID_OPACITY, CHANGE_GRID_SIZE, CHANGE_SATURATION_LEVEL, CHANGE_SELECTED_SHAPES_OPACITY,
    CHANGE_SHAPES_COLOR_BY, CHANGE_SHAPES_OPACITY,
    CHANGE_SHAPES_OUTLINED_BORDERS, CHANGE_SHAPES_SHOW_PROJECTIONS, CHANGE_SHOW_UNLABELED_REGIONS, SET_SETTINGS,
    SWITCH_AUTO_SAVE,
    SWITCH_AUTOMATIC_BORDERING,
    SWITCH_CONTROL_POINTS_SIZE,
    SWITCH_GRID,
    SWITCH_INTELLIGENT_POLYGON_CROP,
    SWITCH_RESET_ZOOM, SWITCH_ROTATE_ALL,
    SWITCH_SETTINGS_DIALOG, SWITCH_SHOWING_DELETED_FRAMES, SWITCH_SHOWING_OBJECTS_TEXT_ALWAYS,
    SWITCH_SHOWING_TAGS_ON_FRAME, SWITCH_SHOWNIG_INTERPOLATED_TRACKS,
    SWITCH_SMOOTH_IMAGE,
    SWITCH_TEXT_CONTENT, SWITCH_TEXT_FONT_SIZE, SWITCH_TEXT_POSITION,
    SWITCH_TOOLS_BLOCKER_STATE
} from "./actionType";
import {LABEL_TYPE} from "../../pages/user/label/annotation/image/const";

const INIT_STATE: SettingsState = {
    shapes: {
        colorBy: ColorBy.LABEL,
        opacity: 20,
        selectedOpacity: 60,
        outlined: false,
        outlineColor: '#000000',
        showBitmap: false,
        showProjections: false,
    },
    workspace: {
        autoSave: false,
        autoSaveInterval: 15 * 60 * 1000,
        aamZoomMargin: 100,
        automaticBordering: false,
        showObjectsTextAlways: false,
        showAllInterpolationTracks: false,
        intelligentPolygonCrop: true,
        defaultApproxPolyAccuracy: 9,
        textFontSize: 14,
        controlPointsSize: 5,
        textPosition: 'auto',
        textContent: 'id,source,label,attributes,descriptions',
        toolsBlockerState: {
            algorithmsLocked: false,
            buttonVisible: false,
        },
        showTagsOnFrame: true,
    },
    player: {
        canvasBackgroundColor: '#ffffff',
        frameStep: 10,
        frameSpeed: FrameSpeed.Usual,
        resetZoom: false,
        rotateAll: false,
        smoothImage: true,
        showDeletedFrames: false,
        grid: false,
        gridSize: 100,
        gridColor: GridColor.White,
        gridOpacity: 100,
        brightnessLevel: 100,
        contrastLevel: 100,
        saturationLevel: 100,
    },
    showDialog: false,
};

export default (state = INIT_STATE, action: AnyAction): SettingsState => {
    switch (action.type) {
        case SWITCH_ROTATE_ALL: {
            return {
                ...state,
                player: {
                    ...state.player,
                    rotateAll: action.payload.rotateAll,
                },
            };
        }
        case SWITCH_GRID: {
            return {
                ...state,
                player: {
                    ...state.player,
                    grid: action.payload.grid,
                },
            };
        }
        case CHANGE_GRID_SIZE: {
            return {
                ...state,
                player: {
                    ...state.player,
                    gridSize: action.payload.gridSize,
                },
            };
        }
        case CHANGE_GRID_COLOR: {
            return {
                ...state,
                player: {
                    ...state.player,
                    gridColor: action.payload.gridColor,
                },
            };
        }
        case CHANGE_GRID_OPACITY: {
            return {
                ...state,
                player: {
                    ...state.player,
                    gridOpacity: action.payload.gridOpacity,
                },
            };
        }
        case CHANGE_SHAPES_COLOR_BY: {
            return {
                ...state,
                shapes: {
                    ...state.shapes,
                    colorBy: action.payload.colorBy,
                },
            };
        }
        case CHANGE_SHAPES_OPACITY: {
            return {
                ...state,
                shapes: {
                    ...state.shapes,
                    opacity: action.payload.opacity,
                },
            };
        }
        case CHANGE_SELECTED_SHAPES_OPACITY: {
            return {
                ...state,
                shapes: {
                    ...state.shapes,
                    selectedOpacity: action.payload.selectedOpacity,
                },
            };
        }
        case CHANGE_SHAPES_OUTLINED_BORDERS: {
            return {
                ...state,
                shapes: {
                    ...state.shapes,
                    outlined: action.payload.outlined,
                    outlineColor: action.payload.color,
                },
            };
        }
        case CHANGE_SHAPES_SHOW_PROJECTIONS: {
            return {
                ...state,
                shapes: {
                    ...state.shapes,
                    showProjections: action.payload.showProjections,
                },
            };
        }
        case CHANGE_SHOW_UNLABELED_REGIONS: {
            return {
                ...state,
                shapes: {
                    ...state.shapes,
                    showBitmap: action.payload.showBitmap,
                },
            };
        }
        case CHANGE_FRAME_STEP: {
            return {
                ...state,
                player: {
                    ...state.player,
                    frameStep: action.payload.frameStep,
                },
            };
        }
        case CHANGE_FRAME_SPEED: {
            return {
                ...state,
                player: {
                    ...state.player,
                    frameSpeed: action.payload.frameSpeed,
                },
            };
        }
        case SWITCH_RESET_ZOOM: {
            return {
                ...state,
                player: {
                    ...state.player,
                    resetZoom: action.payload.resetZoom,
                },
            };
        }
        case SWITCH_SMOOTH_IMAGE: {
            return {
                ...state,
                player: {
                    ...state.player,
                    smoothImage: action.payload.smoothImage,
                },
            };
        }
        case SWITCH_TEXT_FONT_SIZE: {
            return {
                ...state,
                workspace: {
                    ...state.workspace,
                    textFontSize: action.payload.fontSize,
                },
            };
        }
        case SWITCH_CONTROL_POINTS_SIZE: {
            return {
                ...state,
                workspace: {
                    ...state.workspace,
                    controlPointsSize: action.payload.controlPointsSize,
                },
            };
        }
        case SWITCH_TEXT_POSITION: {
            return {
                ...state,
                workspace: {
                    ...state.workspace,
                    textPosition: action.payload.position,
                },
            };
        }
        case SWITCH_TEXT_CONTENT: {
            const { textContent } = action.payload;
            return {
                ...state,
                workspace: {
                    ...state.workspace,
                    textContent,
                },
            };
        }
        case CHANGE_BRIGHTNESS_LEVEL: {
            return {
                ...state,
                player: {
                    ...state.player,
                    brightnessLevel: action.payload.level,
                },
            };
        }
        case CHANGE_CONTRAST_LEVEL: {
            return {
                ...state,
                player: {
                    ...state.player,
                    contrastLevel: action.payload.level,
                },
            };
        }
        case CHANGE_SATURATION_LEVEL: {
            return {
                ...state,
                player: {
                    ...state.player,
                    saturationLevel: action.payload.level,
                },
            };
        }
        case SWITCH_AUTO_SAVE: {
            return {
                ...state,
                workspace: {
                    ...state.workspace,
                    autoSave: action.payload.autoSave,
                },
            };
        }
        case CHANGE_AUTO_SAVE_INTERVAL: {
            return {
                ...state,
                workspace: {
                    ...state.workspace,
                    autoSaveInterval: action.payload.autoSaveInterval,
                },
            };
        }
        case CHANGE_AAM_ZOOM_MARGIN: {
            return {
                ...state,
                workspace: {
                    ...state.workspace,
                    aamZoomMargin: action.payload.aamZoomMargin,
                },
            };
        }
        case SWITCH_SHOWNIG_INTERPOLATED_TRACKS: {
            return {
                ...state,
                workspace: {
                    ...state.workspace,
                    showAllInterpolationTracks: action.payload.showAllInterpolationTracks,
                },
            };
        }
        case SWITCH_SHOWING_OBJECTS_TEXT_ALWAYS: {
            return {
                ...state,
                workspace: {
                    ...state.workspace,
                    showObjectsTextAlways: action.payload.showObjectsTextAlways,
                },
            };
        }
        case SWITCH_AUTOMATIC_BORDERING: {
            return {
                ...state,
                workspace: {
                    ...state.workspace,
                    automaticBordering: action.payload.automaticBordering,
                },
            };
        }
        case SWITCH_INTELLIGENT_POLYGON_CROP: {
            return {
                ...state,
                workspace: {
                    ...state.workspace,
                    intelligentPolygonCrop: action.payload.intelligentPolygonCrop,
                },
            };
        }
        case CHANGE_CANVAS_BACKGROUND_COLOR: {
            return {
                ...state,
                player: {
                    ...state.player,
                    canvasBackgroundColor: action.payload.color,
                },
            };
        }
        case CHANGE_DEFAULT_APPROX_POLY_THRESHOLD: {
            return {
                ...state,
                workspace: {
                    ...state.workspace,
                    defaultApproxPolyAccuracy: action.payload.approxPolyAccuracy,
                },
            };
        }
        case SWITCH_TOOLS_BLOCKER_STATE: {
            return {
                ...state,
                workspace: {
                    ...state.workspace,
                    toolsBlockerState: { ...state.workspace.toolsBlockerState, ...action.payload.toolsBlockerState },
                },
            };
        }
        case SWITCH_SETTINGS_DIALOG: {
            return {
                ...state,
                showDialog: typeof action.payload.show === 'undefined' ? !state.showDialog : action.payload.show,
            };
        }
        case SET_SETTINGS: {
            return {
                ...state,
                ...action.payload.settings,
            };
        }
        case SWITCH_SHOWING_DELETED_FRAMES: {
            return {
                ...state,
                player: {
                    ...state.player,
                    showDeletedFrames: action.payload.showDeletedFrames,
                },
            };
        }
        case SWITCH_SHOWING_TAGS_ON_FRAME: {
            return {
                ...state,
                workspace: {
                    ...state.workspace,
                    showTagsOnFrame: action.payload.showTagsOnFrame,
                },
            };
        }
        case UPLOAD_MODULE_ANNOTATIONS_SUCCESS:
        case CREATE_ANNOTATIONS_SUCCESS:
        case CHANGE_FRAME_SUCCESS: {
            const { module, states } = action.payload;
            const labelType = module.labelType;

            if (labelType === LABEL_TYPE.ANNOTATION && states.some((_state: ObjectState): boolean => _state.shapeType === ShapeType.MASK)) {
                const MIN_OPACITY = 30;
                const { shapes: { opacity } } = state;
                if (opacity < MIN_OPACITY) {
                    return {
                        ...state,
                        shapes: {
                            ...state.shapes,
                            opacity: MIN_OPACITY,
                            selectedOpacity: MIN_OPACITY * 2,
                        },
                    };
                }
            }

            return state;
        }
        // case BoundariesActionTypes.RESET_AFTER_ERROR:
        case GET_MODULE_SUCCESS: {
            const { module } = action.payload;

            return {
                ...state,
                player: {
                    ...state.player,
                    resetZoom: module && module.mode === 'annotation',
                },
                shapes: {
                    ...INIT_STATE.shapes,
                    ...(module.dimension === DimensionType.DIM_3D ?
                        {
                            opacity: 40,
                            selectedOpacity: 60,
                        } :
                        {}),
                },
            };
        }
        case INTERACT_WITH_CANVAS: {
            return {
                ...state,
                workspace: {
                    ...state.workspace,
                    toolsBlockerState: {
                        buttonVisible: true,
                        algorithmsLocked: false,
                    },
                },
            };
        }
        // case AuthActionTypes.LOGOUT_SUCCESS: {
        //     return { ...defaultState };
        // }
        default: {
            return state;
        }
    }
};
