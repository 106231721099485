//@ts-nocheck
import React, {useEffect} from 'react';
import styled from "styled-components";
import {useSelector} from "react-redux";

const seperateCssBydirection = (direction, position) => {
    const isLeft = direction === "left";
    if(isLeft){
        return `
            border-radius: 0 15px 15px 0;
            left: ${position}px;
            top: 50%;
            width: 20px;
            height: 56px;
        `
    }
    return `
        border-radius: 15px 0 0 15px;
        right: ${position}px;
        top: 0%;
        width: 5px;
        height: 100%;
        opacity: 0
      `;
};

const HandleContainer = styled.div`
  cursor: col-resize;

  
  .custom-handle {
    z-index: 1001;
    position: absolute;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid lightgray;
    // 방향별 분기
    ${(props) => seperateCssBydirection(props.direction, props.position)}
  }

  .custom-handle::before {
    content: "||";
    font-size: 12px;
    color: gray;
  }
`

export const CustomHandleView = (props) => {
    const { position, direction } = props;
    const {
        Details: {
            sidebar:{
                leftSidebarWidth,
                rightSidebarWidth
            }
        }
    } = useSelector(state => state);

    useEffect(() => {

    }, [leftSidebarWidth, rightSidebarWidth])

    return (
        <HandleContainer position={position} direction={direction}>
            <div className="custom-handle" />
        </HandleContainer>
    );
}