import React, { memo } from 'react';
import { Handle, useReactFlow, useStoreApi, Position, useStore } from 'reactflow';
import {useDispatch} from "react-redux";
import {Progress} from "reactstrap";
import {GetMessageIntl} from "../../../../../util/message";
import {useIntl} from "react-intl";

export default function AutoLabelNode({ id, data, isConnectable }) {
    const intl = useIntl();
    const targetHandleStyle = {visibility: 'hidden', zIndex:1};
    const sourceHandleStyle = { visibility: 'hidden', zIndex:1};

    return (
        <div >
            <div className="d-flex align-items-center mb-12">
                <div className="flex-shrink-0 avatar-42">
                    <div className="avatar-title fs-20 rounded-circle" style={{backgroundColor:'#F2E4FF', color:'#B66CFB'}}>
                        <i className="mdi mdi-robot-happy-outline lh-19"></i>
                    </div>
                </div>
                <div className="flex-grow-1 text-overflow ml-13">
                    <h6 className="text-coco-gray-200 tx-12 lh-16 mb-2">PLUGIN</h6>
                    <span className="text-coco-gray-700 tx-16 lh-20 fw-semibold d-flex align-items-center">
                        {GetMessageIntl(intl, "workflow.autoLabel")}
                    </span>
                </div>
            </div>
            <div className="bg-coco-gray-50 pd-10 node_info flex-shrink-0 mb-12">
                <div className="ht-20">
                    <label style={{width:"94px"}}>{GetMessageIntl(intl,"workflow.autoLabelTy")}</label>
                    객체 탐지
                </div>
                <div className="ht-20 mt-6">
                    <label style={{width:"94px"}}>{GetMessageIntl(intl,"workflow.autoLabelTarget")}</label>
                    개
                </div>
            </div>
            <Handle
                className="sourceHandle"
                style={sourceHandleStyle}
                position={Position.Top}
                id={'top'}
                type="source"
                isConnectable={isConnectable}
            />
            <Handle
                className="sourceHandle"
                style={sourceHandleStyle}
                position={Position.Right}
                id={'right'}
                type="source"
                isConnectable={isConnectable}
            />
            <Handle
                className="sourceHandle"
                style={sourceHandleStyle}
                position={Position.Bottom}
                id={'bottom'}
                type="source"
                isConnectable={isConnectable}
            />
            <Handle
                className="sourceHandle"
                style={sourceHandleStyle}
                position={Position.Left}
                id={'left'}
                type="source"
                isConnectable={isConnectable}
            />

            <Handle
                className="targetHandle"
                style={targetHandleStyle}
                position={Position.Top}
                id={'top'}
                type="target"
                isConnectable={isConnectable}
            />
            <Handle
                className="targetHandle"
                style={targetHandleStyle}
                position={Position.Right}
                id={'right'}
                type="target"
                isConnectable={isConnectable}
            />
            <Handle
                className="targetHandle"
                style={targetHandleStyle}
                position={Position.Bottom}
                id={'bottom'}
                type="target"
                isConnectable={isConnectable}
            />
            <Handle
                className="targetHandle"
                style={targetHandleStyle}
                position={Position.Left}
                id={'left'}
                type="target"
                isConnectable={isConnectable}
            />
        </div>
    );
}