import {
  CHANGE_DEPTH_LAYOUT,
  CHANGE_DEPTH_SIDEBAR_THEME,
  CHANGE_DEPTH_LAYOUT_MODE,
  CHANGE_DEPTH_LAYOUT_WIDTH,
  CHANGE_DEPTH_LAYOUT_POSITION,
  CHANGE_DEPTH_TOPBAR_THEME,
  CHANGE_DEPTH_SIDEBAR_SIZE_TYPE,
  CHANGE_DEPTH_SIDEBAR_VIEW,
  CHANGE_DEPTH_SIDEBAR_IMAGE_TYPE,
  RESET_DEPTH_VALUE
} from './actionType';

export const changeDepthLayout = layout => ({
  type: CHANGE_DEPTH_LAYOUT,
  payload: layout,
});

export const changeDepthLayoutMode = mode => ({
  type: CHANGE_DEPTH_LAYOUT_MODE,
  payload: mode,
});

export const changeDepthSidebarTheme = theme => ({
  type: CHANGE_DEPTH_SIDEBAR_THEME,
  payload: theme,
});

export const changeDepthLayoutWidth = layoutWidth => ({
  type: CHANGE_DEPTH_LAYOUT_WIDTH,
  payload: layoutWidth,
});

export const changeDepthLayoutPosition = layoutposition => ({
  type: CHANGE_DEPTH_LAYOUT_POSITION,
  payload: layoutposition,
});

export const changeDepthTopbarTheme = topbarTheme => ({
  type: CHANGE_DEPTH_TOPBAR_THEME,
  payload: topbarTheme,
});

export const changeDepthLeftsidebarSizeType = leftsidebarSizetype => ({
  type: CHANGE_DEPTH_SIDEBAR_SIZE_TYPE,
  payload: leftsidebarSizetype,
});

export const changeDepthLeftsidebarViewType = leftsidebarViewtype => ({
  type: CHANGE_DEPTH_SIDEBAR_VIEW,
  payload: leftsidebarViewtype,
});

export const changeDepthSidebarImageType = leftsidebarImagetype => ({
  type: CHANGE_DEPTH_SIDEBAR_IMAGE_TYPE,
  payload: leftsidebarImagetype,
});

export const resetDepthValue = value => ({
  type: RESET_DEPTH_VALUE,
  payload: value,
});