import React, {useState} from 'react'
import {BellArarmService} from "../../apis/api/BellAlarmService";
import {Button} from "reactstrap";

function SessionTest() {
    const [id ,setId] = useState(null);

    const handleCAllApi = () => {
        BellArarmService.getMessageList().then(res=>{
            console.log(res)
        });
    };

    const start = ()=>{
        let id = setInterval(handleCAllApi, 1000)
        setId(id);
    }

    const stop = ()=>{
        console.log(id)
        clearInterval(id);
    }

    return (
        <>
            <Button onClick={start}>시작</Button><Button onClick={stop}>중지</Button>
        </>
    )
}


export default SessionTest;