export interface DisplayState{
    displayMode: SttusDisplay | null,
    text: String,
    color: SttusColor | null
}
export enum DataWorkStatus {
    LABEL,
    REVIEW,
    DONE,
    DISABLE,
    CLOSE,
}

export enum LabelSttus {
    STANDBY,
    WORKING="00",
    SUBMIT="03",
    SKIP="04",
    WITHDRAWAL="05"
}
export enum ReviewSttus {
    STANDBY,
    WORKING="00",
    PASS="03",
    REJECT="04"
}

export enum SttusDisplay {
    STANDBY,    // 작업전
    WORKING,    // 라벨링 작업중
    SUBMIT  // 제출한 이후
}

export enum SttusColor {
    STANDBY = 'badge-coco-soft-yellow',
    WORKING = 'badge-coco-soft-green',
    MODIFY = 'badge-coco-soft-purple',
    REWORKING = 'badge-coco-soft-purple',
    REVIEW_REQUIRED = 'badge-coco-soft-navy',
    DISABLE = 'badge-coco-soft-red',
    DONE = 'badge-coco-soft-skyblue'
}

export interface HistoryStyle {
    backgroundColor: string;
    iconColor: string;
    icon: string;
    textPointColor: string | null;
    subTitleText: string | null;
    titleText: string;
    subText: string | null;
}
export enum HistoryUserColor {
    LABELER = '#0BAD8F',
    REVIEWR = '#3577F1',
    MANAGER = '#FF5F9F'
}
export const HistoryColorSet = {
    UPLOAD: {
        backgroundColor: '#FFF2D0',
        iconColor: '#FFC130',
        icon: 'ri-file-upload-line'
    },
    LABELER: {
        backgroundColor: '#DFF3EF',
        iconColor: '#0BAD8F',
        icon: 'ri-pen-nib-line',
    },
    REVIEWER: {
        backgroundColor: '#D0F8FF',
        iconColor: '#37CBE4',
        icon: 'coco-review',
    },
    MANAGER: {
        backgroundColor: '#FFD8CC',
        iconColor: '#FF6230',
        icon: 'ri-arrow-left-right-fill',
    },
    DONE: {
        backgroundColor: '#F6DBFF',
        iconColor: '#C548FF',
        icon: 'ri-checkbox-circle-line',
    }
}

export enum SearchFilter {
    DATA_STATUS="sttus",
    DATASET="dataset",
    FOLDER="folder",
    WORKER="worker",
    LAST_REJECT_REASONS="reject",
    LAST_REJECT_REASONS_CLSFC="rejectClsfcSmry",
    SKIP_REASONS="skip",
    FILE="file"
}

export enum SearchOrder{
    FILE_NAME="00",
    LAST_REG= "01",
    LAST_CMPLT="02"
}

export enum DataSttusCd{
    STANDBY="00",
    WORKING="01",
    MODIFY="02",
    DONE="93",
    DISABLE="94",
    REVIEW_REQUIRED="90",
    REVIEW_STANDBY="10",
    REVIEW_WORKING="11",
    REVIEW_MODIFY="12"
}
