// @ts-nocheck
import {MD_STTUS_CD, MD_TY_CD} from "../../../const";
import {useDispatch} from "react-redux";
import {useHistory} from "react-router";
import {useIntl} from "react-intl";
import {SkipContainer} from "../../../../../cmmn/components/taskSideBar/skip-container";
import {SubmitButtonContainer} from "../../../containers/standard-workspace/objects-side-bar/SubmitButton";

interface Props{
    type: String    // project type
    moduleStatus: MD_STTUS_CD
}
export const NextStepFooter = (props: Props) => {
    const { type, moduleStatus } = props;
    const dispatch = useDispatch();
    const history = useHistory();
    const intl = useIntl();

    if(type === MD_TY_CD.LABEL){
        return (
            <div className="hstack gap-2 justify-content-between">
                {moduleStatus === MD_STTUS_CD.FIRST &&
                    <>
						<SkipContainer  dispatch={dispatch} history={history} intl={intl} />
                        <SubmitButtonContainer widthPercent={50}/>
                    </>
                }
                {moduleStatus === MD_STTUS_CD.RE &&
					<>
						<SubmitButtonContainer widthPercent={100}/>
					</>
                }
            </div>
        )
    } else if (type === MD_TY_CD.REVIEW) {
        return (
            <>
                <SubmitButtonContainer widthPercent={100}/>
            </>

        )
    }
}
