// @ts-nocheck
import {useDispatch, useSelector} from "react-redux";
import React, {useEffect} from "react";
import {getHistory} from "../../../../../store/dataset/details/action";
import {History} from "./history";
import {useIntl} from "react-intl";
import SimpleBar from "simplebar-react";

export function FileHistory(props): JSX.Element{
    const intl = useIntl();
    const dispatch = useDispatch();
    const {
        Label:{
            Cmmn: {
                canvas: {
                    skipPopover: {reasons: skipReasonsList}
                },
                frames: {
                    frame: {datasetId, fileSeq}
                }
            }
        },
        Review: {
            Cmmn: {
                annotations: {reasons: reviewReasonsList}
            }
        },
        Details: {
            workflow: {id: wrkflowId},
            history: {
                display: displayHistory
            },
        }
    } = useSelector(state => state);

    useEffect(() => {
        // if (wrkflowId && datasetId && fileSeq && displayHistory.length == 0 && skipReasonsList.length != 0 && reviewReasonsList.length != 0) {
        if (wrkflowId && datasetId && fileSeq ) {
            dispatch(getHistory(wrkflowId, datasetId, fileSeq, {intl}))
        }
    }, [wrkflowId, datasetId, fileSeq, skipReasonsList, reviewReasonsList])

    const firstMatchLabelData = (total, target, idx) => {
        let targetLabelData = null;
        if(target.issueCn && target.taskTy === "02"){
            for(let i = idx + 1; i < total.length; i++){
                if(total[i].moduleConfig){
                    try {
                        targetLabelData = JSON.parse(total[i].moduleConfig).shapeConfig;
                    } catch (error) {
                        console.error("Failed to parse moduleConfig:", error);
                    }
                    break; // 루프를 종료합니다.
                }
            }
        }
        return targetLabelData;
    }

    return(
        <>
            <div className="acitivity-timeline acitivity-main acitivity-manager-setting">
                 {
                     displayHistory.map((h, index) => {
                         // 가장 최근 라벨링 제출의 shapeConfig , Props 로 넘겨줘서 표기
                         let labelData = null;
                         if(h.issueCn && h.taskTy === "02"){
                             const slicedData = displayHistory.slice(index + 1);
                             for (let i = 0; i < slicedData.length; i++) {
                                 if(slicedData[i].moduleConfig){
                                     labelData = JSON.parse(slicedData[i].moduleConfig).shapeConfig;

                                     if(labelData){
                                         break;
                                     }
                                 }
                             }
                         }

                         return (
                            <History
                                key={`history-${index}`}
                                history={h}
                                shapeConfig={labelData}
                            />
                        )
                    })
                }
            </div>
        </>
    )
}
