// @ts-nocheck
import {GetMessageIntl} from "../../../../../../../util/message";
import {DashedBar} from "../DashedBar";
import React, {useEffect, useRef, useState} from "react";
import {GuidePopover} from "../GuidePopover";
import {useSelector} from "react-redux";
import {ReviewResult} from "../../../containers/taskSideBar/classification/ReviewResult";
import {useIntl} from "react-intl";

// classification-review-details
interface Props {
    readonly: boolean;
    label: any;
    clsfcSmryForm: any;
    reject: boolean | null;
    reviewButtonVisible: boolean;
    modified: boolean | null;
    questionKeyName: string;
    guideVisible: boolean;
    onClickPass(): void;
    onClickReject(): void;
    onClickCheck(): void;
}
export const ReviewView = (props: Props): JSX.Element => {
    const intl = useIntl();
    const {readonly, label, clsfcSmryForm, reject, reviewButtonVisible, modified, questionKeyName, guideVisible
        , onClickPass, onClickReject, onClickCheck} = props;

    const {
        Label: {
            Cmmn: {
                canvas: {sideBarTab}
            }
        },

    } = useSelector(state => state);
    const order = label.order + 1;
    const labelId = label.id;

    const reviewButtons = useRef();
    const [isOpen, setIsOpen] = useState<boolean>(false);

    useEffect(() => {
        /**
         * 현재 스테이지에 왔고, 검수 상태 & 검수가 되어 있지 않았을 때, guidePopover가 보여진다.
         */
        // if (order !== sideBarTab) {
        //     setIsOpen(false);
        // }
    }, [sideBarTab])
    useEffect(() => {
        if (order === sideBarTab && Object.hasOwn(reviewButtons, 'current')) {
            console.log('reviewButtons', reviewButtons);
            setIsOpen(true);
        } else {
            setIsOpen(false);
        }
    }, [reviewButtons, sideBarTab])

    const guidePopoverTargetID = `reviewButton${labelId}`;
    return (
        <>
            <div className="d-flex justify-content-end mb-12" style={{padding: "0 0 0 50px", gap: "10px"}} >
                {readonly ?
                    reject === true &&
                        <div>
                            <button type="button" className="btn btn-coco-ghost-1 fw-500"
                                    onClick={onClickCheck} >
                                {modified ? GetMessageIntl(intl, 'ui.U00013') : GetMessageIntl(intl, 'label.modifyCompleted')}
                            </button>
                        </div>
                    : reviewButtonVisible &&
                    <>
                        <div>
                            <button type="button" id={guidePopoverTargetID} className="btn btn-coco-ghost-1 fw-500"
                                    onClick={onClickPass} ref={reviewButtons}
                            >
                                {GetMessageIntl(intl, 'label.pass')}
                            </button>
                            <button type="button" className="btn btn-coco-ghost-3 fw-500"
                                    onClick={onClickReject}
                            >
                                {GetMessageIntl(intl, 'ui.U00066')}
                            </button>
                        </div>
                        {guideVisible && <GuidePopover isOpen={isOpen} setIsOpen={setIsOpen}
													   text={GetMessageIntl(intl, "review.classificationRecheckingGuidePopover")}
													   targetId={reviewButtons?.current.id} />
                        }
                    </>
                }

            </div>
            <div className={"mb-24"}>
                <DashedBar />
            </div>
            {reject !== null && <ReviewResult hookform={clsfcSmryForm} questionKeyName={questionKeyName} reject={reject}/>}
        </>
    )
}
