import {
    SET_INIT_SEARCH_PARAM
    , SET_SEARCH_PARAM
    , SWITCH_MODAL_FLAG
} from "./actionType";

const INIT_STATE = {
    searchParam : []
}

const ManagerBoard = (state = INIT_STATE, action) => {
    switch (action.type){
        case SET_SEARCH_PARAM :
            return {
                ...state,
                searchParam: [action.payload]
            }
        case SET_INIT_SEARCH_PARAM :
            return {
                ...state,
                searchParam: []
            }
        case SWITCH_MODAL_FLAG :
            return {
                ...state,
                modalFlag: !state.modalFlag
            }
        default:
            return state;
    }
}

export default ManagerBoard;