import React, { useState, useRef, useEffect, useCallback } from "react";
import {useDispatch, useSelector} from "react-redux";
import {Card, CardBody, CardHeader} from "reactstrap";
import SimpleBar from "simplebar-react";
import LabelShapeAttrRows from "./LabelShapeAttrRow";
import {GetMessageIntl} from "../../../../../../../util/message";
import {useIntl} from "react-intl";
import {
    addManageWorkflowLabelAttrItem, deleteManageWorkflowLabelAttrItem, setManageWorkflowLabelAttrItems,
} from "../../../../../../../store/manage/project/workflow/label/action";
import {ReactSortable} from "react-sortablejs";
import {Controller, useFieldArray, useFormContext} from "react-hook-form";

let attrId = 0;
const getAttrId = () => attrId++;
function LabelShapeAttrDivs() {

    const intl = useIntl();
    const dispatch = useDispatch();
    const { control, setValue, getValues, register, unregister, watch,formState: { errors },} = useFormContext();
    const {selectedLabelClassId,
        selectedLabelAttrId,
        selectedLabelAttrItemId,
        shapeConfig,
        labelClassItems,
        labelAttrItems} = useSelector((state) => ({
        selectedLabelClassId: state.ManageWorkflowLabel.selectedLabelClassId,
        selectedLabelAttrId: state.ManageWorkflowLabel.selectedLabelAttrId,
        selectedLabelAttrItemId: state.ManageWorkflowLabel.selectedLabelAttrItemId,
        shapeConfig: state.ManageWorkflowLabel.shapeConfig,
        labelClassItems: state.ManageWorkflowLabel.labelClassItems,
        labelAttrItems: state.ManageWorkflowLabel.labelAttrItems,
    }));

    const addLabelAttrItem = ()=>{

        if(labelAttrItems.length >0 ){
            const maxLabelAttrItem = labelAttrItems.reduce((previous, current) => {
                return current.id > previous.id ? current : previous;
            });

            attrId = Number(maxLabelAttrItem.id) + 1;
        }else{
            attrId = 0;
        }

        console.log('attrId',attrId);

        const labelAttrItem = {
            id: getAttrId(),
            name: '',
            key: '',
            required: 1,
            inputType: "select",
            values: [],
            regexp: '',
            regexpSample: ''
        };

        dispatch(addManageWorkflowLabelAttrItem(selectedLabelClassId, labelAttrItem));
    }

    const setLabelAttrItems = (items) =>{
        if(items.length != 0){
            dispatch(setManageWorkflowLabelAttrItems(selectedLabelClassId, items));
        }
    }

    return(
        <>
            <Card className={"mb-0 shadow-none attribute-card-item-div attribute-card-item-div-default ".concat(selectedLabelClassId>-1 ? "d-none" : "")} >
                <CardHeader className="pd-y-16 pd-x-22 border-0 d-flex align-items-center tx-16 lh-18 text-coco-gray-700 fw-500">
                    {GetMessageIntl(intl, "workflow.labelAttribute")}<span className="text-coco-gray-400 ml-6 tx-12">({GetMessageIntl(intl,"common.options")})</span>
                </CardHeader>
                <CardBody className="p-0" style={{height: "calc(100vh - 430px)"}}>
                    <div className="d-flex align-items-center justify-content-center" style={{height: "100%"}}>
                        <div className="d-flex flex-column">
                            <span className="text-center ht-30 text-coco-gray-400">{GetMessageIntl(intl, "workflow.labelAttributeAddMsg")}</span>
                            {/*<img src={attributeImg}/>*/}
                        </div>
                    </div>
                </CardBody>
            </Card>
            {
                (labelClassItems).map((data, index)=> {
                    const {id, value, colorCode, dependentShape, attributes}= data;
                    return(
                        <Card key={"attrDiv".concat(id)}  className={"mb-0 shadow-none attribute-card-item-div ".concat(selectedLabelClassId !== id?"d-none":"")}>
                            <CardHeader className="pd-y-16 pd-x-22 border-0 d-flex align-items-center justify-content-between tx-16 lh-18 text-coco-gray-700 fw-500">
                                <div className={"d-flex align-items-center"}>
                                    {GetMessageIntl(intl, "workflow.labelAttribute")}
                                    <span className="text-coco-gray-400 ml-6 tx-12">({GetMessageIntl(intl,"common.options")})</span>
                                </div>
                                <div className="text-coco-gray-400 ht-18">
                                    <i className="ri-add-circle-line me-1 addProjectAttribute text-pointer" onClick={addLabelAttrItem}></i>
                                </div>
                            </CardHeader>
                            <SimpleBar className="card-body p-0" style={{height: "calc(100vh - 430px)"}}>
                                <div className={"d-flex align-items-center justify-content-center default-attribute-div ".concat(labelAttrItems.length===0?"":"d-none")}
                                     style={{height: "calc(100vh - 430px)"}}>
                                    <span className="text-center ht-30 text-coco-gray-400">{GetMessageIntl(intl, "workflow.labelAttributeAddMsg")}</span>
                                </div>
                                <div className={"list-group mb-1 projectAttributeTargetDiv pd-x-22 ".concat(labelAttrItems.length>0?"":"d-none")}>
                                    {/*<LabelShapeAttrRows itemId={id}/>*/}
                                    <ReactSortable list={attributes} setList={setLabelAttrItems} handle=".handle">
                                        {attributes.map((attrData, index)=>{
                                            const {name, key, required, inputType, values, regexp, regexpSample}= attrData;
                                            const attrId = attrData.id;
                                            return(
                                                <LabelShapeAttrRows key={`attr_${id}_${attrId}`} id={id} attrId={attrId} name={name} attrKey={key} required={required}
                                                                    inputType={inputType} values={values} regexp={regexp} regexpSample={regexpSample}/>
                                            )
                                        })
                                        }
                                    </ReactSortable>
                                </div>
                            </SimpleBar>
                        </Card>
                    )
                })
            }
        </>
    )
}
export default LabelShapeAttrDivs;