import {callApi} from '../utils/AxiosUtil';

/**
 *  텍스트 라벨링 / 검수 관련 서비스
 */
export const TextLabelService={
    // 라벨링 대상 텍스트
    getTargetText: (param) => callApi('/service/label/text/getTargetText', param),
}

