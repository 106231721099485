import WorkPage from './WorkPage';
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {changeFrame, getModule} from "store/label/action";
import 'pages/user/label/Label.scss';

function mapStateToProps(state) {
    const {
        Layout: {
            layoutType,
            leftSidebarType,
            topbarThemeType
        },
        Label:{
            Cmmn: {
                module: {id, fetching, instance: module, labelType},
                frames: {frame: {datasetId, fileSeq}},
                workspace,
            }
        },
    } = state;

    return {
        layoutType,
        leftSidebarType,
        topbarThemeType,
        moduleId: id,
        fetching,
        module,
        labelType,
        datasetId,
        fileSeq,
        workspace
    }
}

function mapDispatchToProps(dispatch:any){
    return {
        getModule(moduleId): void {
            if(!moduleId)
                moduleId = sessionStorage.getItem('moduleId');
            dispatch(getModule(moduleId));
        }
    }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(WorkPage))
