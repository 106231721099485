import {
    Card,
    CardBody,
    CardHeader,
    Col,
    Form,
    Input,
    Label,
    Row
} from 'reactstrap';
import { ErrorMessage } from "@hookform/error-message";
import {Controller, useForm} from "react-hook-form";
import Select from "react-select";
import React, {useCallback, useEffect, useState} from "react";
import {defaultSelectStyle} from "../../components/constants/select";

const ValidationTest = () => {
    const { control
        , register
        ,setValue
        ,getValues
        , formState: { errors }
        , handleSubmit } = useForm({criteriaMode: "all"});

    const onSubmit = (data) => {
        alert(JSON.stringify(data));
    };
    useEffect(()=>{
        setValue('title', 'lee');
    },[]);
    console.log(getValues())
    return (
        <>
            <Card>
                <CardBody>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <label>First Name</label>
                        <div className={errors.firstName?"validation-error":""}>
                            <Controller
                                render={({ field }) => <Input {...field} />}
                                name="title"
                                control={control}
                                rules={{
                                    required: true,
                                }}
                                defaultValue=""
                                className="form-control"
                            />
                        </div>
                        {errors.firstName && <p className="validation-error-message">This is required.</p>}
                        <label>First Name2</label>
                        <div className={errors.multipleErrorInput?"validation-error":""}>
                            <input className="form-control"
                                {...register("multipleErrorInput", {
                                    required: "This input is required.",
                                    pattern: {
                                        value: /\d+/,
                                        message: "This input is number only."
                                    },
                                    minLength: {
                                        value: 11,
                                        message: "This input must exceed 10 characters"
                                    }
                                })}
                            />
                        </div>
                        <ErrorMessage
                            errors={errors}
                            name="multipleErrorInput"
                            render={({ messages }) => {
                                console.log("messages", messages);
                                return messages
                                    ? Object.entries(messages).map(([type, message]) => (
                                        <p className={"validation-error-message"} key={type}>{message}</p>
                                    ))
                                    : null;
                            }}/>
                        <label>Last Name</label>
                        <div className={errors.lastName?"validation-error":""}>
                            <Controller
                                render={({ field }) => <Input {...field} />}
                                name="lastName"
                                control={control}
                                defaultValue=""
                            />
                        </div>
                        <label>Ice Cream Preference</label>

                        <div className={errors.iceCreamType?"validation-select-error":""}>
                            <Controller
                                name="iceCreamType"
                                rules={{
                                    required: true,
                                }}
                                render={({ field }) => (
                                    <Select
                                        {...field}
                                        styles={defaultSelectStyle}
                                        options={[
                                            { value: "chocolate", label: "Chocolate" },
                                            { value: "strawberry", label: "Strawberry" },
                                            { value: "vanilla", label: "Vanilla" }
                                        ]}
                                    />
                                )}
                                control={control}
                                defaultValue=""
                            />
                        </div>
                        {errors.iceCreamType && <p className={"validation-error-message"}>This is required.</p>}

                        <Controller
                            name="Checkbox"
                            control={control}
                            render={({ field }) =>
                                <div className="form-check form-check-coco">
                                    <Input className="form-check-input" type="checkbox" {...field} defaultChecked />
                                    <Label className="form-check-label" htmlFor="formradiocolor2">
                                        Checked checkbox
                                    </Label>
                                </div>
                        }
                        />

                        <button className="btn btn-coco-1 w-100" type="submit">
                            제출
                        </button>
                    </form>
                </CardBody>
            </Card>
        </>
    )
}


export default ValidationTest;
