// @flow
import { all, call, fork, takeEvery } from "redux-saga/effects";

import {
    CHANGE_DEPTH_LAYOUT,
    CHANGE_DEPTH_SIDEBAR_THEME,
    CHANGE_DEPTH_LAYOUT_MODE,
    CHANGE_DEPTH_LAYOUT_WIDTH,
    CHANGE_DEPTH_LAYOUT_POSITION,
    CHANGE_DEPTH_TOPBAR_THEME,
    CHANGE_DEPTH_SIDEBAR_SIZE_TYPE,
    CHANGE_DEPTH_SIDEBAR_VIEW,
    CHANGE_DEPTH_SIDEBAR_IMAGE_TYPE
} from './actionType';

/**
 * Changes the body attribute
 */
function changeDepthHTMLAttribute(attribute, value) {
    if (document.documentElement) document.documentElement.setAttribute(attribute, value);
    return true;
}

/**
 * Changes the layout type
 * @param {*} param0
 */
function* changeDepthLayoutTheme({ payload: layout }) {
    try {
        if (layout === "twocolumn") {
            document.documentElement.removeAttribute("data-layout-width");
        } else if (layout === "horizontal") {
            document.documentElement.removeAttribute("data-sidebar-size");
        }
        yield call(changeDepthHTMLAttribute, "data-layout", layout);
    } catch (error) {
        // console.log(error);
    }
}


/**
 * Changes the layout mode
 * @param {*} param0
 */
function* changeDepthLayoutMode({ payload: mode }) {
    try {
        yield call(changeDepthHTMLAttribute, "data-layout-mode", mode);
    } catch (error) {
        // console.log(error);
    }
}

/**
 * Changes the left sidebar theme
 * @param {*} param0
 */
function* changeDepthLeftSidebarTheme({ payload: theme }) {
    try {
        yield call(changeDepthHTMLAttribute, "data-sidebar", theme);
    } catch (error) {
        // console.log(error);
    }
}

/**
 * Changes the layout width
 * @param {*} param0
 */
function* changeDepthLayoutWidth({ payload: layoutWidth }) {
    try {
        if (layoutWidth === 'lg') {
            yield call(changeDepthHTMLAttribute, "data-layout-width", "fluid");
        } else {
            yield call(changeDepthHTMLAttribute, "data-layout-width", "boxed");
        }
        // yield call(changeHTMLAttribute, "data-sidebar-size", layoutWidth);
    } catch (error) {
        // console.log(error);
    }
}

/**
 * Changes the layout position
 * @param {*} param0
 */
function* changeDepthLayoutPosition({ payload: layoutposition }) {
    try {
        yield call(changeDepthHTMLAttribute, "data-layout-position", layoutposition);
    } catch (error) {
        // console.log(error);
    }
}

/**
 * Changes the topbar themes
 * @param {*} param0
 */
function* changeDepthTopbarTheme({ payload: topbarTheme }) {
    try {
        yield call(changeDepthHTMLAttribute, "data-topbar", topbarTheme);
    } catch (error) {
        // console.log(error);
    }
}

/**
 * Changes the topbar themes
 * @param {*} param0
 */
 function* changeDepthSidebarImageType({ payload: leftsidebarImagetype }) {
    try {
        yield call(changeDepthHTMLAttribute, "data-sidebar-image", leftsidebarImagetype);
    } catch (error) {
        // console.log(error);
    }
}

/**
 * Changes the topbar themes
 * @param {*} param0
 */
function* changeDepthLeftsidebarSizeType({ payload: leftsidebarSizetype }) {
    try {
        switch (leftsidebarSizetype) {
            case 'lg':
                yield call(changeDepthHTMLAttribute, "data-sidebar-size", "lg");
                break;
            case 'md':
                yield call(changeDepthHTMLAttribute, "data-sidebar-size", "md");
                break;
            case "sm":
                yield call(changeDepthHTMLAttribute, "data-sidebar-size", "sm");
                break;
            case "sm-hover":
                yield call(changeDepthHTMLAttribute, "data-sidebar-size", "sm-hover");
                break;
            default:
                yield call(changeDepthHTMLAttribute, "data-sidebar-size", "lg");
        }
    } catch (error) {
        // console.log(error);
    }
}

/**
 * Changes the topbar themes
 * @param {*} param0
 */
function* changeDepthLeftSidebarViewType({ payload: leftsidebarViewtype }) {
    try {
        yield call(changeDepthHTMLAttribute, "data-layout-style", leftsidebarViewtype);
    } catch (error) {
        // console.log(error);
    }
}

/**
 * Watchers
 */
export function* watchChangeDepthLayoutType() {
    yield takeEvery(CHANGE_DEPTH_LAYOUT, changeDepthLayoutTheme);
}
export function* watchChangeDepthLayoutMode() {
    yield takeEvery(CHANGE_DEPTH_LAYOUT_MODE, changeDepthLayoutMode);
}
export function* watchChangeDepthLeftSidebarTheme() {
    yield takeEvery(CHANGE_DEPTH_SIDEBAR_THEME, changeDepthLeftSidebarTheme);
}
export function* watchChangeDepthLayoutWidth() {
    yield takeEvery(CHANGE_DEPTH_LAYOUT_WIDTH, changeDepthLayoutWidth);
}
export function* watchChangeDepthLayoutPosition() {
    yield takeEvery(CHANGE_DEPTH_LAYOUT_POSITION, changeDepthLayoutPosition);
}
export function* watchChangeDepthTopbarTheme() {
    yield takeEvery(CHANGE_DEPTH_TOPBAR_THEME, changeDepthTopbarTheme);
}
export function* watchChangeDepthLeftsidebarSizeType() {
    yield takeEvery(CHANGE_DEPTH_SIDEBAR_SIZE_TYPE, changeDepthLeftsidebarSizeType);
}
export function* watchChangeDepthLeftSidebarViewType() {
    yield takeEvery(CHANGE_DEPTH_SIDEBAR_VIEW, changeDepthLeftSidebarViewType);
}

export function* watchChangeDepthSidebarImageType() {
    yield takeEvery(CHANGE_DEPTH_SIDEBAR_IMAGE_TYPE, changeDepthSidebarImageType);
}

function* depthLayoutSaga() {
    yield all([
        fork(watchChangeDepthLayoutType),
        fork(watchChangeDepthLeftSidebarTheme),
        fork(watchChangeDepthLayoutMode),
        fork(watchChangeDepthLayoutWidth),
        fork(watchChangeDepthLayoutPosition),
        fork(watchChangeDepthTopbarTheme),
        fork(watchChangeDepthLeftsidebarSizeType),
        fork(watchChangeDepthLeftSidebarViewType),
        fork(watchChangeDepthSidebarImageType),
    ]);
}

export default depthLayoutSaga;