// Copyright (C) 2020-2022 Intel Corporation
//
// SPDX-License-Identifier: MIT
// @ts-nocheck
import React from 'react';
import { connect } from 'react-redux';
import {
    activateLabel as activateLabelAction,
    updateAnnotations,
    updateCanvasContextMenu
} from "../../../../../../../../store/label/action";

import LabelItemComponent from 'pages/user/label/annotation/image/components/standard-workspace/objects-side-bar/label-item';
import {ActiveControl, CombinedState, ObjectType} from 'store/label';
import {MD_STTUS_CD, MD_TY_CD} from "../../../const";

interface OwnProps {
    containerKey: string;
    type: MD_TY_CD;
    labelID: number;
    keyToLabelMapping: Record<string, number>;
    item: string | null;
    viewID: number;
    workspace: Worksapce;
    updateLabelShortcutKey(updatedKey: string, labelID: number): void;
}

interface StateToProps {
    activated: boolean;
    activatedLabelID: number | null;
    errorVisible: boolean;
    completedError: boolean;
    labels: any[];
    label: any;
    labelType: string;
    labelName: string;
    labelColor: string;
    objectStates: any[];
    moduleInstance: any;
    moduleStatus: MD_STTUS_CD;
    frameNumber: number;
    statisticsData: any;
    workspace: Workspace;
}

interface DispatchToProps {
    updateAnnotations(states: any[]): void;
    activateLabel(activatedLabelID: number | null): void;
}

function mapStateToProps(state: CombinedState, own: OwnProps): StateToProps {
    const {
        Label: {
            Cmmn: {
                canvas: {
                    ready, activeControl,
                    badges: {
                        visible: lErrorVisible
                    }
                },
                annotations: {activatedLabelID, states: objectStates},
                module: {labels, instance: moduleInstance, status: moduleStatus, type: moduleType},
                player: {
                    frame: {number: frameNumber},
                },
                statistics: {
                    data: statisticsData
                },
                workspace
            }
        },
        Review: {
            Cmmn: {
                canvas: {
                    badges: {
                        visible: reErrorVisible,
                    }
                },
                annotations: {
                    data: reviewTotalData
                }
            }
        }
    } = state;

    const [label] = labels.filter((_label: any) => _label.id === own.labelID);
    let totalCount;
    if(statisticsData){
        totalCount = statisticsData.labelData[label.name].total;
    }
    let errorVisible = null;
    if (moduleType === MD_TY_CD.LABEL) {
        errorVisible = lErrorVisible;
    } else if (moduleType === MD_TY_CD.REVIEW) {
        errorVisible = reErrorVisible;
    }
    const reviewData = reviewTotalData.classes ? reviewTotalData.classes[own.labelID]
                                                : {reject: null, rsnCd: [], completedError: null, editable: null};

    return {
        ready,
        labels,
        activeControl,
        activated: activatedLabelID === own.labelID,
        activatedLabelID,
        labelType: label.type,
        labelColor: label.color,
        labelName: label.name,
        objectStates,
        moduleInstance,
        moduleStatus,
        frameNumber,
        totalCount,
        reviewData,
        errorVisible,
        reject: reviewData.reject,
        completedError: (moduleType === MD_TY_CD.LABEL && reviewData.reject === null) ? false : reviewData.completedError,
        editable: reviewData.editable,
        touch: reviewData.touch,
        workspace
    };
}

function mapDispatchToProps(dispatch: any): DispatchToProps {
    return {
        updateAnnotations(states: any[]): void {
            dispatch(updateAnnotations(states));
        },
        activateLabel(activatedLabelID: number | null): void {
            dispatch(updateCanvasContextMenu(false, 0, 0));
            dispatch(activateLabelAction(activatedLabelID));
        },
    };
}

type Props = StateToProps & DispatchToProps & OwnProps;
interface State {
    objectStates: any[];
    ownObjectStates: any[];
    visible: boolean;
    statesHidden: boolean;
    statesLocked: boolean;
}

class LabelItemContainer extends React.PureComponent<Props, State> {
    public constructor(props: Props) {
        super(props);
        this.state = {
            objectStates: [],
            ownObjectStates: [],
            theirObjectStates: [],
            visible: true,
            statesHidden: false,
            statesLocked: false,
        };
    }

    static getDerivedStateFromProps(props: Props, state: State): State | null {
        if (props.objectStates === state.objectStates) {
            return null;
        }

        const ownObjectStates = props.objectStates.filter(
            (ownObjectState: any): boolean => ownObjectState.label.id === props.labelID,
        );

        const theirObjectStates = props.objectStates.filter(
            (ownObjectState: any): boolean => ownObjectState.label.id !== props.labelID,
        );

        const visible = !!ownObjectStates.length;
        let statesHidden = true;
        let statesLocked = true;

        ownObjectStates.forEach((objectState: any) => {
            const { lock, objectType } = objectState;
            if (!lock && objectType !== ObjectType.TAG) {
                statesHidden = statesHidden && objectState.hidden;
                statesLocked = statesLocked && objectState.lock;
            }
        });

        return {
            ...state,
            objectStates: props.objectStates,
            ownObjectStates,
            theirObjectStates,
            statesHidden,
            statesLocked,
            visible,
        };
    }

    private activate = (): void => {
        const {
            activated, labelID, ready, activeControl, activateLabel
            // objectState, ready, activeControl, activateObject,
        } = this.props;

        if ( ready && activeControl === ActiveControl.CURSOR) {
            if (! activated) {
                activateLabel(labelID);
            } else {
                activateLabel(null);
            }
        }
    }

    private hideStates = (): void => {
        this.switchHidden(true);
    };

    private showStates = (): void => {
        this.switchHidden(false);
    };

    private lockStates = (): void => {
        this.switchLock(true);
    };

    private unlockStates = (): void => {
        this.switchLock(false);
    };

    private switchHiddenOnlyLabel = (value: boolean): void => {   // show: (value: true)
        const { updateAnnotations, labelID, activated } = this.props;
        const { ownObjectStates, theirObjectStates } = this.state;
        if (ownObjectStates.length) {
            // false alarm
            updateAnnotations(ownObjectStates.map((state: any) => ((state.hidden = value), state)));
        }

        if (theirObjectStates.length) {
            updateAnnotations(theirObjectStates.map((state: any) => ((state.hidden = !value), state)));
        }
    }

    private switchHidden(value: boolean): void {
        const { updateAnnotations, labels } = this.props;
        const { ownObjectStates } = this.state;

        if (ownObjectStates.length) {
            // false alarm
            updateAnnotations(ownObjectStates.map((state: any) => ((state.hidden = value), state)));
        }
    }

    private switchLock(value: boolean): void {
        const { updateAnnotations } = this.props;
        const { ownObjectStates } = this.state;

        if (ownObjectStates.length) {
            // false alarm
            updateAnnotations(ownObjectStates.map((state: any) => ((state.lock = value), state)));
        }
    }

    public render(): JSX.Element {
        const {
            activated, activatedLabelID, activatedStateID, errorVisible, completedError, editable, containerKey, type, moduleStatus, item, moduleInstance,
            labelType, labelName, labelColor, keyToLabelMapping, labelID, viewID, totalCount, workspace,
            reject, touch,
            updateLabelShortcutKey
        } = this.props;
        const { visible, statesHidden, statesLocked } = this.state;

        return (
            <LabelItemComponent
                workspace={workspace}
                errorVisible={errorVisible}
                reject={reject}
                completedError={completedError}
                editable={editable}
                touch={touch}
                containerKey={containerKey}
                item={item}
                type={type}
                moduleStatus={moduleStatus}
                moduleInstance={moduleInstance}
                labelType={labelType}
                labelName={labelName}
                labelColor={labelColor}
                labelID={labelID}
                viewID={viewID}
                totalCount={totalCount}
                activated={activated}
                activatedLabelID={activatedLabelID}
                keyToLabelMapping={keyToLabelMapping}
                visible={visible}
                statesHidden={statesHidden}
                statesLocked={statesLocked}
                activate={this.activate}
                hideStates={this.hideStates}
                showStates={this.showStates}
                lockStates={this.lockStates}
                unlockStates={this.unlockStates}
                switchHiddenOnlyLabel={this.switchHiddenOnlyLabel}
                updateLabelShortcutKey={updateLabelShortcutKey}
            />
        );
    }
}

export default connect<StateToProps, DispatchToProps, OwnProps, CombinedState>(
    mapStateToProps,
    mapDispatchToProps,
)(LabelItemContainer);
