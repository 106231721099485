// @ts-nocheck
import React, {useCallback, useEffect, useState} from 'react';
import {Card, CardHeader, UncontrolledCollapse} from "reactstrap";
import ClassesItemComponent from 'pages/user/label/annotation/image/components/ClassesItem';
import ClassesItemButtons from 'pages/user/label/annotation/image/components/ClassesItemButtons';
import {MD_STTUS_CD, MD_TY_CD} from "../../../const";
import LabelReviewDetailsContainer from "../../../containers/standard-workspace/objects-side-bar/label-review-details";
import ReviewDetailsContainer from "../../../containers/review-workspace/objects-side-bar/label-review-details";
import {useIntl} from "react-intl";
import {GetMessageIntl} from "../../../../../../../../util/message";
import {Workspace} from "../../../../../../../../store/label";

interface Props {
    reject: boolean | null;
    completedError: boolean;
    containerKey: string;
    type: MD_TY_CD;
    workspace: Workspace;
    moduleStatus: MD_STTUS_CD;
    jobIsntance: any;
    labelType: string;
    labelName: string;
    labelColor: string;
    labelID: number;
    viewID: number;
    totalCount: number;
    visible: boolean;
    statesHidden: boolean;
    statesLocked: boolean;
    keyToLabelMapping: Record<string, number>;
    hideStates(): void;
    showStates(): void;
    lockStates(): void;
    unlockStates(): void;
    updateLabelShortcutKey(updatedKey: string, labelID: number): void;
    activate(): void;
    switchHiddenOnlyLabel(boolean): void
}

function LabelItemComponent(props: Props): JSX.Element {
    const {
        activated,
        activatedLabelID,
        errorVisible,
        completedError,
        touch,
        containerKey,
        type,
        workspace,
        moduleStatus,
        moduleInstance,
        labelType,
        labelName,
        labelColor,
        labelID,
        viewID,
        totalCount,
        keyToLabelMapping,
        visible,
        statesHidden,
        statesLocked,
        hideStates,
        showStates,
        lockStates,
        unlockStates,
        switchHiddenOnlyLabel,
        updateLabelShortcutKey,
        activate,
        reject,
        editable,
        item
    } = props;

    // create reversed mapping just to receive key easily
    const labelToKeyMapping: Record<string, string> = Object.fromEntries(
        Object.entries(keyToLabelMapping).map(([key, _labelID]) => [_labelID, key]),
    );
    const labelShortcutKey = labelToKeyMapping[labelID] || '?';
    const classes = {
        lock: {
            enabled: { className: 'cvat-label-item-button-lock cvat-label-item-button-lock-enabled' },
            disabled: { className: 'cvat-label-item-button-lock' },
        },
        hidden: {
            enabled: { className: 'cvat-label-item-button-hidden cvat-label-item-button-hidden-enabled' },
            disabled: { className: 'cvat-label-item-button-hidden' },
        },
    };

    const [result, setResult] = useState(0);
    const intl = useIntl();
    const resultMap = [
        { color: "", text: ''},
        { color: "text-coco-danger-400", text: GetMessageIntl(intl, 'ui.U00066')},
        { color: "text-coco-secondary-700", text: GetMessageIntl(intl, 'label.pass')},
    ]
    useEffect(() => {
        if (reject === null) {
            setResult(0);
        } else if (reject === true) {
            setResult(1);
        } else if (reject === false) {
            setResult(2);
        }
    }, [reject])

    const className = activated ?
        'instanceItem selected' :
        'instanceItem';
    const errorClassName = errorVisible && completedError ?
        'error':
        '';
    const vLineClassName = activated ?
        '':
        'd-none'
    const readyState = useCallback(() => {
        // if (!hidden){
        //     prepare();
        // }
    }, []);
    const activateState = useCallback((e) => {
        activate();
    }, []);

    useEffect(() => {
        if (type === MD_TY_CD.REVIEW) {
            if (activated) {
                switchHiddenOnlyLabel(false);
            }
        }
    }, [activated])

    return (
        <Card className={'classesItem'}>
            <CardHeader>
                <Card className={"list-item-event-container "+className.concat(' ', errorClassName)} id={containerKey}
                      onClick={activateState}
                      onMouseEnter={readyState}
                >
                    <CardHeader>
                        <div className={"v-line "+vLineClassName}/>
                        <div className={'detailShapeItem'}>
                            <ClassesItemComponent
                                moduleType={type}
                                reject={reject}
                                completedError={completedError}
                                touch={touch}
                                className={labelName}
                                classType={labelType}
                                totalCount={totalCount}
                                viewID={viewID}
                            />
                            <div className={"wd-20p mx-2"}>
                                {
                                    type === MD_TY_CD.LABEL ?
                                        (
                                            <ClassesItemButtons
                                                type={type}
                                                statesHidden={statesHidden}
                                                showStates={showStates}
                                                hideStates={hideStates}
                                            />
                                        )
                                        :   (
                                            <div className={resultMap[result].color+" fs-16 d-flex align-items-center justify-content-center"}>
                                                {resultMap[result].text}
                                            </div>
                                        )
                                }
                            </div>
                        </div>
                    </CardHeader>
                </Card>
                {
                    workspace === Workspace.STANDARD && moduleStatus === MD_STTUS_CD.RE  && reject &&
                    <UncontrolledCollapse toggler={`label-item-${viewID}`}
                                          isOpen={activated}
                    >
                        <LabelReviewDetailsContainer
                            activated={activated}
                            labelID={labelID}
                            labelName={labelName}
                            showStates={showStates}
                            hideStates={hideStates}
                        />
                    </UncontrolledCollapse>
                }
                {
                    (workspace === Workspace.REVIEW_WORKSPACE && editable)
                    &&
					<UncontrolledCollapse toggler={`label-item-${viewID}`}
										  isOpen={activated}
					>
						<ReviewDetailsContainer
							activated={activated}
							labelID={labelID}
							labelName={labelName}
							showStates={showStates}
							hideStates={hideStates}
						/>
					</UncontrolledCollapse>
                }
            </CardHeader>
        </Card>
    );
}

export default React.memo(LabelItemComponent);
