export class CountLimit {
    public readonly objectMin: number | null;
    public readonly objectMax: number | null;

    constructor(initialData) {
        const data = {
            objectMin: undefined,
            objectMax: undefined
        }

        for (const key of Object.keys(data)) {
            if (Object.prototype.hasOwnProperty.call(initialData, key)) {
                data[key] = initialData[key];
            }
        }

        if ([null, "0", 0].includes(initialData.objectMin)) {
            data.objectMin = 1;
        }

        Object.defineProperties(
            this,
            Object.freeze({
                objectMin: {
                    get: () => data.objectMin,
                },
                objectMax: {
                    get: () => data.objectMax,
                }
            })
        );
    }
}
