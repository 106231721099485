import {
  CHANGE_MANAGE_PROJECT_ID,
  CHANGE_MANAGE_PROJECT,
  CHANGE_MANAGE_WORKFLOW_ID,
  CHANGE_MANAGE_WORKFLOW,
  SET_DATASET_NM, GO_TO_DETAILS_BY_WORKER,
} from './actionType';

export const changeManageProjectId = prjctId => ({
  type: CHANGE_MANAGE_PROJECT_ID,
  payload: prjctId,
});

export const changeManageProject = (projectId, projectNm) => ({
  type: CHANGE_MANAGE_PROJECT,
  payload: (projectId, projectNm),
});

export const changeManageWorkflowId = workflowId => ({
  type: CHANGE_MANAGE_WORKFLOW_ID,
  payload: workflowId,
});

export const changeManageWorkflow = (workflowId, workflowNm) => ({
  type: CHANGE_MANAGE_WORKFLOW,
  payload: (workflowId, workflowNm),
});

export const setDatasetNm = datasetNm => ({
  type: SET_DATASET_NM,
  payload : datasetNm,
});

export const goToDetailsByWorker = (userSeq, workerViewList, intl, history) => ({
  type: GO_TO_DETAILS_BY_WORKER,
  payload: {userSeq, workerViewList},
  hooks: {intl, history}
})
