// @ts-nocheck
import {GetMessage} from "../../../../../util/message";
import {useDispatch, useSelector} from "react-redux";
import React, {useEffect, useState} from "react";
import {getFileInfo, getJson, updateJsonModal} from "../../../../../store/dataset/details/action";
import {useIntl} from "react-intl";
import button from "bootstrap/js/src/button";
import {JsonPreviewModal} from "./json-preview-modal";
import {DATA_TYPE} from "../../../../user/label/annotation/image/const";
import CommonUtil from "../../../../../util/CommonUtil";

export function FileInfo(): JSX.Element {
    const {
        Label: {
            Cmmn: {
                module: {type: lastModuleType, status: lastModuleStatus, dataType: dataType},
                frames: {
                    frame: {
                        moduleId,
                        datasetId,
                        fileSeq,
                        fileSize,
                        name,
                        width,
                        height
                    }
                },
                player: {
                    duration
                }
            }
        },
        Details: {
            workflow: {name:wrkflowNm},
            fileInfo: {
                displayState: {
                    dataSttusColor,
                    dataSttusText,
                 },
                datasetNm,
                regDtmText,
                json: {
                    visible: jsonVisible,
                    data: jsonData
                }
            }
        }
    } = useSelector(state => state);

    const intl = useIntl();
    const dispatch = useDispatch();
    useEffect(() => {
        if (moduleId && datasetId && fileSeq) {
            dispatch(getFileInfo({moduleId, datasetId, fileSeq}, intl))
        }
    }, [datasetId, fileSeq]);

    const [sttusBadge, setSttusBadge] = useState(<></>)
    useEffect(() => {
        setSttusBadge(<div className={`badge badge-coco ${dataSttusColor}`}>{dataSttusText}</div>)
    }, [dataSttusText, dataSttusColor]);

    const viewJson = () => {
        dispatch(getJson(moduleId, datasetId, fileSeq))
        dispatch(updateJsonModal(true))
    }

    const switchJsonModal = (visible) => {
        dispatch(updateJsonModal(visible))
    }

    return (
        <div className={"info-section"}>
            <div className={"title d-flex justify-content-between fw-bold"}>
                {GetMessage('details.fileInfo')}
                <div className="badge badge-coco badge-coco-soft-green title" type={"button"} onClick={viewJson}>
                    <i className={"mdi mdi-code-json"} />
                </div>
            </div>
            <Line name={GetMessage('details.fileName')} value={name} />
            <Line name={GetMessage('details.status')} value={sttusBadge} />

            {/* todo frame에 fileSize를 넣어서 fileSize를 넣으면 될거같은데.. */}
            <Line name={GetMessage('common.fileSize')} value={CommonUtil.getByteConvertNumber(fileSize)} />

            {dataType === DATA_TYPE.IMAGE &&
                <Line name={GetMessage('common.imageSize')} value={width === null || height === null? '': width+'*'+height} />
            }

            {dataType === DATA_TYPE.AUDIO
                && <Line name={GetMessage('common.audioLength')} value={CommonUtil.convertToHHMMSS(duration)} />
            }
            {dataType === DATA_TYPE.VIDEO
                &&
                <>
                    <Line name={GetMessage('common.videoSize')} value={width === null || height === null? '': width+'*'+height} />
                    <Line name={GetMessage('common.videoLength')} value={CommonUtil.convertToHHMMSS(duration)} />
                </>
            }

            <Line name={GetMessage('details.regDtm')} value={regDtmText} />
            <Line name={GetMessage('details.datasetName')} value={datasetNm} />
            { jsonVisible &&
                <JsonPreviewModal visible={jsonVisible}
                                  data={jsonData}
                                  filename={name}
								  wrkflowNm={wrkflowNm}
                                  switchModal={(visible) => switchJsonModal(visible)} /> }
        </div>
    );
}

export function Line ({name, value=''}): JSX.Component {
    return (
        <div className={"line"}>
            <div style={{width: "110px"}}>{name}:</div>
            <div>{value}</div>
        </div>
    )
}
