import {useDispatch, useSelector} from "react-redux";
import {
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row,
    Col,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane,
    Label, Input,
} from "reactstrap";
import {setTaskJoinModalFlag, setTaskRefreshFlag, setTaskTestModalFlag} from "../../../store/user/task/action";
import React, {useEffect, useState} from "react";
import UserTask from "../../../store/user/task/reducer";
import {useIntl} from "react-intl";
import {GetMessageIntl} from "../../../util/message";
import classnames from "classnames";
import {TaskService} from "../../../apis/api/TaskService";
import EditorStep from "./EditorStep";
import CheckBoxStep from "./CheckBoxStep";
import FormStep from "./FormStep";
import SimpleBar from "simplebar-react";
import {FormProvider, useForm} from "react-hook-form";
import {alertError, alertSuccess, confirm} from "../../../util/AlertUtil";
import {getModule} from "../../../store/label/action";
import {assignCheck} from "../../../store/user/board/saga";
import {useHistory} from "react-router";
import laflowLogo from "../../../assets/images/laflow_logo_01.png";

function TaskJoinModal(props){
    const dispatch = useDispatch();
    const intl = useIntl();
    const history = useHistory();
    const {taskJoinModalFlag} = useSelector((state) =>({
        taskJoinModalFlag : state.UserTask.taskJoinModalFlag
    }))
    const [dataConfig, setDataConfig] = useState([]);
    const [testConfig, setTestConfig] = useState(null);
    const [module, setModule] = useState();
    const [title, setTitle] = useState("");
    const [dc, setDc] = useState("");
    const methods = useForm();
    const { reset, register, getValues, handleSubmit, formState: { errors } } = methods;

    const [activeVerticalTab, setactiveVerticalTab] = useState(1);
    const [passedverticalSteps, setPassedverticalSteps] = useState([1]);

    useEffect(() => {
        setactiveVerticalTab(1);
        return (() => {
            reset();
        })
    }, [taskJoinModalFlag])

    const getDataConfig = (moduleId) => {
        TaskService.getTaskByModuleId({
            'moduleId' : moduleId
        }).then(re => {
            console.log("getTaskByModuleId -> ", re.data.data)
            if(re.data.data){
                // 라벨링 페이지 가기 위함
                setModule({
                    moduleId: re.data.data.moduleId,
                    name: re.data.data.moduleNm,
                    type: re.data.data.moduleTy,
                    canvasDataTy: re.data.data.canvasDataTy
                })
                if(re.data.data.moduleConfig){
                    const resultData = JSON.parse(re.data.data.moduleConfig);
                    const filteredData = resultData.dataConfig
                        .filter((item => item.useYn !== 'N'))
                        .sort((a, b) => a.id - b.id);
                    const newData = filteredData.map((item, idx) => {
                        return {
                            ...item,
                            id: String(idx + 1)
                        }
                    })
                    console.log("dataConfig ->", newData);
                    setDataConfig(newData);

                    if(resultData.baseConfig){
                        const baseConfig = resultData.baseConfig;
                        setTitle(baseConfig.taskNm);
                        setDc(baseConfig.taskDc);
                    }
                    if(resultData.testConfig){
                        setTestConfig(resultData.testConfig);
                    }
                }
            }
        })
    }

    useEffect(()=>{
        reset();
        if(taskJoinModalFlag && props.moduleId !== null){
            getDataConfig(props.moduleId);
        }
    }, [taskJoinModalFlag])



    function toggleVerticalTab(tab) {
        if (activeVerticalTab !== tab) {
            var modifiedSteps = [...passedverticalSteps, tab];

            if (tab >= 1 && tab <= dataConfig.length) {
                setactiveVerticalTab(tab);
                setPassedverticalSteps(modifiedSteps);
            }
        }
    }

    const renderingByFormTy = (formTy, title, body, subId) => {
        switch (formTy){
            case "editor" :
                return <EditorStep title={title} body={body} subId={subId}/>;
            case "checkbox" :
                return <CheckBoxStep title={title} body={body} subId={subId}/>;
            case "form":
                return <FormStep title={title} body={body} subId={subId}/>;
            default:
                return null;
        }
    }

    const gotoCanvas = (module, frame) => {
        dispatch(getModule({module, frame}));
        history.push({
            pathname:'/label/image'
        });
    }

    const goTaskBtn = async () => {
        try{
            const doAfter = {
                prePage: history.location.pathname,
                do: {
                    "11": gotoCanvas,
                    "12": gotoCanvas,
                    "13": (callback) => {
                        confirm("joined.checkAssignTitleConfirm"
                            , "joined.checkAssignConfirm"
                            , async function () {
                                const target = await callback();
                                if (target != null){
                                    gotoCanvas(module, target);
                                }
                            }
                            , function () {
                                if (history) {
                                    history.goBack();
                                }
                            }
                            , null
                            , intl
                        );
                    },
                    "04": () => {
                        alertError('joined.noDataAlert'
                            ,'joined.noDataAlertText', ()=>{},null ,null
                            ,intl)
                    },
                    "05": () => {
                        alertError('joined.noProgressAlert'
                            ,'joined.noDataAlertText' ,null ,null ,null
                            ,intl)
                    },
                }
            }
            await assignCheck(module, {intl}, doAfter);
        } catch (e) {
            alertError(
                ""
                ,""
                ,null
                ,null
                ,e
                ,intl
            );
        }

    }

    function handleJoinRequest(data){
        console.log("data ->", data)
        const subIds = Object.keys(data);
        let reArr = [];
        subIds.map((subId) => {
            const findData = dataConfig.find(item => item.subId === subId);
            console.log("findData ->", findData);
            if(findData){
                let realId = findData.id;
                const submitData = data[subId];
                let obj = {};
                switch (findData.type){
                    case "checkbox":
                        obj.type = findData.type
                        obj.id = realId;
                        obj.subId = subId;
                        submitData.checkbox ? obj.agreeYn = 'Y' : obj.agreeYn = 'N';
                        break;
                    case "form":
                        obj.type = findData.type
                        obj.id = realId;
                        obj.subId = subId;
                        obj.data = []
                        submitData.map((item, idx) => {
                            obj.data.push({
                                id : idx,
                                data : item
                            })
                        })
                        break;
                    default:
                        break;
                }
                reArr.push(obj);
            }
        })

        const joinConfig = {
            joinData: reArr
        }

        const submitData = new FormData();
        submitData.append("joinConfig", JSON.stringify(joinConfig))
        submitData.append("moduleId", props.moduleId)
        console.log("submitData -> ", submitData)

        TaskService.updateJoinConfig(submitData, {contentType: 'multipart/form-data'}).then(re => {
            alertSuccess("joined.joinRequestSuccess", "joined.joinRequestSuccessMsg", () => {
                // 미니 테스트는 선택 ( moduleConfig에 testConfig 내용이 존재할 때만 미니테스트 입장 띄우기 )
                if(testConfig !== null){
                    confirm("joined.miniTestRequest"
                        , "joined.miniTestRequestMsg"
                        , () => {
                            dispatch(setTaskJoinModalFlag(false))
                            dispatch(setTaskTestModalFlag(true))
                        }
                        , () => {
                            //goTaskBtn()
                            dispatch(setTaskJoinModalFlag(false))
                            dispatch(setTaskRefreshFlag(true));
                        }
                        , null
                        , intl)
                }else {
                    goTaskBtn()
                    dispatch(setTaskJoinModalFlag(false))
                }
            }, null, intl);
        }).catch((e) => {
            console.log("error", e)
        })

    }

    const navContentstyle={
        display: "-webkit-box",
        height:"80px",
        overflow:"hidden",
        verticalAlign:"top",
        textOverflow: "ellipsis",
        WebkitBoxOrient: "vertical",
        WebkitLineClamp: 4,
        whiteSpace: "pre-line"
    }

    const closeBtn = (
        <button className="close p-0 border-0 bg-transparent" onClick={()=>{
            dispatch(setTaskJoinModalFlag(false))
        }
        } type="button">
            <i className={"ri-close-line tx-24"}/>
        </button>
    );

    return (
        <>
        <FormProvider {...methods}>
            <form>
                    <Modal
                        fullscreen={true}
                        isOpen={taskJoinModalFlag}
                        toggle={() => {
                            dispatch(setTaskJoinModalFlag(false))
                        }}
                        backdrop="static"
                        keyboard={false}
                        className="fw-bold"
                        id="taskJoinModalFullscreen"
                    >
                        <ModalHeader className={"pd-y-20 bg-coco-default-white-f border-coco-gray-100"}
                                     style={{borderBottomWidth: "1px"}} close={closeBtn} toggle={() => {
                            dispatch(setTaskJoinModalFlag(false))
                        }}>
                            <div className={"d-flex align-items-center"} style={{height:"36px"}}>
                                <img src={laflowLogo} alt="" height="22" />
                            </div>
                        </ModalHeader>
                        <ModalBody className="fs-16 p-0">
                            <Row className="mx-0" style={{minHeight: "calc(100vh - 138px)"}}>
                                <Col className={"col-xl-2-5 bg-coco-primary-400 text-coco-default-white pd-x-24 pd-y-42 nav-coco3 vertical-navs-step text-coco-default-white"}>
                                    <div className={"mb-16 tx-24 fw-bold text-overflow"}>
                                        <span className={"text-overflow"}>{title}</span>
                                    </div>
                                    <div className={"mb-24 tx-16 fw-semibold ht-80 lh-20"} style={navContentstyle}>
                                        {dc}
                                    </div>
                                    <Nav
                                        className="flex-column custom-nav nav-pills taskJoinNav"
                                    >
                                        {
                                            dataConfig.length > 0 ?
                                                dataConfig?.sort((a, b) => a.order - b.order).map((item, idx) => {
                                                    const { subId } = item;
                                                    return (
                                                        <NavLink
                                                            key={`dataConfig_${item.order}_${idx}`}
                                                            href="#"
                                                            className={
                                                                (classnames({
                                                                    active: activeVerticalTab === item.order,
                                                                    done: (activeVerticalTab <= dataConfig.length && activeVerticalTab > item.order),
                                                                    invalid : errors[subId]
                                                                }))
                                                            }
                                                            onClick={() => {
                                                                toggleVerticalTab(item.order);
                                                            }}
                                                        >
                                                      <span className="step-title me-2">
                                                        <i className="ri-close-circle-fill step-icon me-2"></i>
                                                          STEP {item.order < 10 ? `0${item.order}` : item.order}.
                                                      </span>
                                                        {item.title}
                                                    </NavLink>
                                                )
                                            })
                                            : null
                                    }
                                </Nav>
                            </Col>
                            <SimpleBar style={{maxHeight:'calc(100vh - 140px)', width: '79%'}} className="taskJoinModalSimpleBar">
                                <Col className={"col-xl-9-5 pd-y-42"}>
                                    <div className="px-lg-4">
                                        <TabContent activeTab={activeVerticalTab}>
                                            {
                                                dataConfig.length > 0 ?
                                                    dataConfig.sort((a, b) => a.order - b.order).map((config, idx) => {
                                                        const {body, title, subId} = config;
                                                        return (
                                                            <TabPane key={`notice_tab_${subId}`}
                                                                tabId={config.order}>
                                                                {
                                                                    renderingByFormTy(config.type, title, body, subId)
                                                                }
                                                            </TabPane>
                                                        )
                                                    })
                                                    : null
                                            }
                                        </TabContent>
                                    </div>
                                </Col>
                            </SimpleBar>
                        </Row>


                        </ModalBody>
                        <ModalFooter className={"pd-y-12 border-coco-gray-100"} style={{borderTopWidth: "1px"}}>
                            {
                                activeVerticalTab !== 1 &&
                                    <button type={"button"} className={"btn btn-coco-outline-1 wd-82 "}
                                            onClick={() => {
                                                toggleVerticalTab(activeVerticalTab - 1)
                                            }}>
                                        {GetMessageIntl(intl, "ui.U00007")}
                                    </button>
                            }
                            {
                                dataConfig.length > activeVerticalTab ?
                                    <button type={"button"} className={"btn btn-coco-1  wd-82"}
                                            onClick={() => {
                                                toggleVerticalTab(activeVerticalTab + 1)
                                            }}>
                                        {GetMessageIntl(intl, "ui.U00008")}
                                    </button>
                                    :
                                    <button type={"button"} className={"btn btn-coco-1 wd-82"}
                                    onClick={methods.handleSubmit(handleJoinRequest)}>
                                        {GetMessageIntl(intl, "project.taskJoin.requestJoin")}
                                    </button>
                            }
                        </ModalFooter>
                    </Modal>
            </form>

        </FormProvider>
        </>
    );
}

export default  TaskJoinModal;