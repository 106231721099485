// @ts-nocheck
import React, {useEffect, useState} from 'react';
import {StepWizardView} from "../../components/taskSideBar/StepWizardView";
import {MD_STTUS_CD, MD_TY_CD} from "../../../annotation/image/const";
import {useDispatch, useSelector} from "react-redux";
import {updateSideBarTab} from "../../../../../../store/label/action";
import {GetMessageIntl} from "../../../../../../util/message";
import {useIntl} from "react-intl";

export const StepWizard = ({ clsfcSmryForm }) => {
    const {
        Label: {
            Cmmn: {
                canvas: {sideBarTab: activeTab},
                module: {
                    type: moduleType,
                    status,
                    labels
                },
                annotations: {
                    states: labelStates
                },
            },
        },
        Review: {
            Cmmn: {
                annotations:{
                    data: {
                        classes: reviewStates
                    }
                }
            }
        }
    } = useSelector(state => state);
    const dispatch = useDispatch();
    const intl = useIntl();


    const [passedSteps, setPassedSteps] = useState([1]);
    const [progressbarvalue, setprogressbarvalue] = useState(0);

    useEffect(() => {
        dispatch(updateSideBarTab(1))
    }, [])

    useEffect(() => {
        updatePassedStepView(activeTab);
    }, [activeTab])

    useEffect(() => {
        const {register, setValue, getValues} = clsfcSmryForm;
        if (moduleType === MD_TY_CD.LABEL && status === MD_STTUS_CD.RE) {
            labels.map((label) => {
                const id = label.id;
                register(`modified.${id}`,
                    { //value: !reviewStates[label.id].reject  // reject이 true이면 true로 해야함.
                         validate: (v) => v || GetMessageIntl(intl, 'label.clsrSmryModifyGuide')});
                setValue(`modified.${id}`, !reviewStates[label.id].reject); // watch를 위해 setValue를 해줌
            });
        }
        // 최초 modified 안된 곳으로 초기화
        const modified = getValues(`modified`);
        // 라벨링일 때
        if(modified){
            for (let i = activeTab - 1; i < modified.length; i++) {
                if (!modified[i]) {
                    dispatch(updateSideBarTab(i + 1));
                    return;
                }
            }
        }
        // 리뷰일 때.
        if(reviewStates){
            for (let i = activeTab - 1; i < reviewStates.length; i++) {
                if (reviewStates[i].reject) {
                    dispatch(updateSideBarTab(i + 1));
                    return;
                }
            }
        }

    }, [labels, reviewStates])

    /**
     * NextStepFooter에서 stage를 바꿀 경우, 관련 상태값의 변경을 위해 분리해놓음
     * @param tab
     */
    function updatePassedStepView(tab) {
        // 현재 탭 이전의 모든 탭들만 포함
        // let modifiedSteps = Array.from({ length: tab }, (_, i) => i + 1);
        const value = (tab / (labels.length - 1)) * 100;
        setPassedSteps([tab]);  // 선택한 stage만 배경색이 표시됨
        setprogressbarvalue(value);
    }

    function toggleTab(tab, value) {
        if (activeTab !== tab) {
            dispatch(updateSideBarTab(tab))
        }
    }

    const props = {
        labels,
        activeTab : activeTab,
        passedSteps : passedSteps,
        progressbarvalue : progressbarvalue,
        length : labels.length,
        toggleTab : toggleTab,
        status: MD_STTUS_CD.FIRST,
        moduleType,
        status,
        shapes: labelStates,
        reviews: reviewStates ?? [],
        modifiedList: labels.map(l => clsfcSmryForm.watch(`modified.${l.id}`, false)),
        clsfcSmryForm: clsfcSmryForm
    }

    return (
        <>
            <StepWizardView {...props} />
        </>
    );
}
