import React, {useEffect, useState} from "react";
import {Button, Col, NavItem, Nav, Offcanvas, OffcanvasBody, OffcanvasHeader, Row, NavLink, TabContent, TabPane} from "reactstrap";
import {GetMessage} from "../../../../../../util/message";
import classnames from "classnames";
import taskReviewImg from "../../../../../../assets/images/task/task_review_54.png";
import {useDispatch, useSelector} from "react-redux";
import {
    manage_project_workflow_module_setIsModuleOffcanvas
} from "../../../../../../store/manage/project/workflow/module/action";

export default (prop) => {
    const onDragStart = (event, nodeType) => {
        if(nodeType == 'sdq4ai' ||  nodeType == 'autoLabel'){
            let elem = document.createElement("div");
            elem.classList="module-item ribbon-box right";
            elem.id = "drag-ghost";
            elem.style.position = "absolute";
            elem.style.top = "-1000px";
            elem.innerHTML = event.target.innerHTML;
            document.body.appendChild(elem);
            event.dataTransfer.setDragImage(elem, 40, 40);
        }
        event.dataTransfer.setData("application/reactflow", nodeType);
        event.dataTransfer.effectAllowed = "move";
    };

    const dispatch = useDispatch();
    const [customActiveTab, setcustomActiveTab] = useState("1");
    const {isModuleOffCanvas} = useSelector((state)=>({
        isModuleOffCanvas: state.ManageWorkflowModule.isModuleOffCanvas
    }));

    const toggleTopCanvas = () => {
        dispatch(manage_project_workflow_module_setIsModuleOffcanvas(false));
    };

    const toggleCustom = (tab) => {
        if (customActiveTab !== tab) {
            setcustomActiveTab(tab);
        }
    };

    return (
        <div>
            <Offcanvas isOpen={isModuleOffCanvas} toggle={toggleTopCanvas} id="offcanvasModuleLib" style={{top:"70px",border:"1px solid var(--vz-border-color)", width: "700px"}} scrollable={false} backdrop={false} keyboard={false} direction="end">
                {/*<OffcanvasHeader toggle={toggleTopCanvas} className="bg-coco-primary-400 text-coco-">*/}
                {/*    {GetMessage("workflow.moduleLib")}*/}
                {/*</OffcanvasHeader>*/}
                <div className="offcanvas-header">
                    <h5 className="offcanvas-title">{GetMessage("workflow.moduleLib")}</h5>
                    <i className="ri-close-line text-coco-default-white tx-20 text-pointer lh-24" onClick={toggleTopCanvas}/>
                </div>
                <div className="pd-x-12 bg-coco-gray-50">
                    <Nav tabs className="nav nav-coco2 nav-tabs nav-tabs-custom">
                        <NavItem>
                            <NavLink
                                style={{ cursor: "pointer" }}
                                className={classnames({
                                    active: customActiveTab === "1",
                                })}
                                onClick={() => {
                                    toggleCustom("1");
                                }}
                            >
                                {GetMessage("workflow.module")}
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                style={{ cursor: "pointer" }}
                                className={classnames({
                                    active: customActiveTab === "2",
                                })}
                                onClick={() => {
                                    toggleCustom("2");
                                }}
                            >
                                {GetMessage("workflow.preset")}
                            </NavLink>
                        </NavItem>
                    </Nav>
                </div>
                <OffcanvasBody>
                    <TabContent
                        activeTab={customActiveTab}
                        className="text-muted"
                    >
                        <TabPane tabId="1" id="moduleTab">
                            {/* 시작 데이터셋 워크플로우 */}
                            <h5 className="module-type"><i className="ri-arrow-right-circle-line fw-500"></i>{GetMessage("workflow.start")}</h5>
                            <div className="module-div">
                                <div className="module-item"
                                     onDragStart={(event) => onDragStart(event, "dataset")}
                                     draggable>
                                    <div className="module-icon">
                                        <div className="avatar">
                                            <div className="avatar-title rounded-circle" style={{backgroundColor:'#FFF2D0', color:'#FFC130'}}>
                                                <i className="ri-database-2-line"></i>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="module-label">
                                        {GetMessage("workflow.dataset")}
                                    </div>
                                    <div className="module-info">
                                        {GetMessage("workflow.datasetInfo")}
                                    </div>
                                </div>
                                {/*<div className="module-item"
                                     onDragStart={(event) => onDragStart(event, "workflow")}
                                     draggable>
                                    <div className="module-icon">
                                        <div className="avatar">
                                            <div className="avatar-title rounded-circle" style={{backgroundColor:'#FFF2D0', color:'#FFC130'}}>
                                                <i className="ri-organization-chart"></i>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="module-label">
                                        {GetMessage("workflow.workflow")}
                                    </div>
                                    <div className="module-info">
                                        {GetMessage("workflow.workflowInfo")}
                                    </div>
                                </div>*/}
                            </div>

                            {/* 활동 라벨링 검수 교차 검수 관리자 검토 */}
                            <h5 className="module-type"><i className="ri-hand-coin-line fw-500"></i>{GetMessage("workflow.active")}</h5>
                            <div className="module-div">
                                <div className="module-item"
                                     onDragStart={(event) => onDragStart(event, "label")}
                                     draggable>
                                    <div className="module-icon">
                                        <div className="avatar">
                                            <div className="avatar-title rounded-circle" style={{backgroundColor:'#DFF3EF', color:'#0BAD8F'}}>
                                                <i className="ri-pen-nib-line"></i>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="module-label">
                                        {GetMessage("workflow.label")}
                                    </div>
                                    <div className="module-info">
                                        {GetMessage("workflow.labelInfo")}
                                    </div>
                                </div>
                                <div className="module-item"
                                     onDragStart={(event) => onDragStart(event, "review")}
                                     draggable>
                                    <div className="module-icon">
                                        <img src={taskReviewImg} style={{WebkitUserDrag: 'none'}}/>
                                    </div>
                                    <div className="module-label">
                                        {GetMessage("workflow.review")}
                                    </div>
                                    <div className="module-info">
                                        {GetMessage("workflow.reviewInfo")}
                                    </div>
                                </div>
                                {/*<div className="module-item"
                                     onDragStart={(event) => onDragStart(event, "crossReview")}
                                     draggable>
                                    <div className="module-icon">
                                        <div className="avatar">
                                            <div className="avatar-title rounded-circle" style={{backgroundColor:'#DFF3EF', color:'#0BAD8F'}}>
                                                <i className="ri-team-line"></i>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="module-label">
                                        {GetMessage("workflow.crossReview")}
                                    </div>
                                    <div className="module-info">
                                        {GetMessage("workflow.crossReviewInfo")}
                                    </div>
                                </div>*/}
                                <div className="module-item"
                                     onDragStart={(event) => onDragStart(event, "managerReview")}
                                     draggable>
                                    <div className="module-icon">
                                        <div className="avatar">
                                            <div className="avatar-title rounded-circle" style={{backgroundColor:'#DFF3EF', color:'#0BAD8F'}}>
                                                <i className="ri-user-settings-line"></i>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="module-label">
                                        {GetMessage("workflow.managerReview")}
                                    </div>
                                    <div className="module-info">
                                        {GetMessage("workflow.managerReviewInfo")}
                                    </div>
                                </div>
                            </div>

                            {/* 상태표기 완료 작업불가 */}
                            <h5 className="module-type"><i className="ri-price-tag-2-line fw-500"></i>{GetMessage("workflow.status")}</h5>
                            <div className="module-div">
                                <div className="module-item"
                                     onDragStart={(event) => onDragStart(event, "finish")}
                                     draggable>
                                    <div className="module-icon">
                                        <div className="avatar">
                                            <div className="avatar-title rounded-circle" style={{backgroundColor:'#E4F3FF', color:'#44A9FF'}}>
                                                <i className="ri-checkbox-circle-line"></i>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="module-label">
                                        {GetMessage("workflow.end")}
                                    </div>
                                    <div className="module-info">
                                        {GetMessage("workflow.finishInfo")}
                                    </div>
                                </div>
                            </div>

                            {/* 기타 샘플링 SDQforAI 오토라벨 오토리뷰 */}
                            <h5 className="module-type"><i className="ri-apps-line fw-500"></i>{GetMessage("workflow.other")}</h5>
                            <div className="module-div">
                                {/*<div className="module-item"
                                     onDragStart={(event) => onDragStart(event, "sample")}
                                     draggable>
                                    <div className="module-icon">
                                        <div className="avatar">
                                            <div className="avatar-title rounded-circle" style={{backgroundColor:'#F2E4FF', color:'#B66CFB'}}>
                                                <i className="ri-filter-2-line"></i>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="module-label">
                                        {GetMessage("workflow.sample")}
                                    </div>
                                    <div className="module-info">
                                        {GetMessage("workflow.sampleInfo")}
                                    </div>
                                </div>*/}
                                <div className="module-item ribbon-box right"
                                     onDragStart={(event) => onDragStart(event, "sdq4ai")}
                                     draggable>
                                    <div className="ribbon-two ribbon-two-coco"><span>PRO</span></div>
                                    <div className="module-icon">
                                        <div className="avatar">
                                            <div className="avatar-title rounded-circle" style={{backgroundColor:'#F2E4FF', color:'#B66CFB'}}>
                                                <i className="mdi mdi-clipboard-pulse-outline"></i>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="module-label">
                                        {GetMessage("workflow.sdq4ai")}
                                    </div>
                                    <div className="module-info">
                                        {GetMessage("workflow.sdq4aiInfo")}
                                    </div>
                                </div>
                                <div className="module-item ribbon-box right"
                                     onDragStart={(event) => onDragStart(event, "autoLabel")}
                                     draggable>
                                    <div className="ribbon-two ribbon-two-coco"><span>PRO</span></div>
                                    <div className="module-icon">
                                        <div className="avatar">
                                            <div className="avatar-title rounded-circle" style={{backgroundColor:'#F2E4FF', color:'#B66CFB'}}>
                                                <i className="mdi mdi-robot-happy-outline"></i>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="module-label">
                                        {GetMessage("workflow.autoLabel")}
                                    </div>
                                    <div className="module-info">
                                        {GetMessage("workflow.autoLabelInfo")}
                                    </div>
                                </div>
                                {/*<div className="module-item ribbon-box right"
                                     onDragStart={(event) => onDragStart(event, "autoReview")}
                                     draggable>
                                    <div className="ribbon-two ribbon-two-coco"><span>PRO</span></div>
                                    <div className="module-icon">
                                        <div className="avatar">
                                            <div className="avatar-title rounded-circle" style={{backgroundColor:'#F3F6F9', color:'#78909C'}}>
                                                <i className="mdi mdi-robot-confused-outline"></i>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="module-label">
                                        {GetMessage("workflow.autoReview")}
                                    </div>
                                    <div className="module-info">
                                        {GetMessage("workflow.autoReviewInfo")}
                                    </div>
                                </div>*/}
                            </div>
                        </TabPane>
                        <TabPane tabId="2" id="presetTab">
                            <div className="d-flex">
                                <div className="flex-shrink-0">
                                    <i className="ri-checkbox-multiple-blank-fill text-success"></i>
                                </div>
                                <div className="flex-grow-1 ms-2">
                                    When, while the lovely valley teems with vapour around me, and the meridian sun strikes the upper surface of the impenetrable foliage of my trees, and but a few stray gleams steal into the inner sanctuary, I throw myself down among the tall grass by the trickling stream; and, as I lie close to the earth, a thousand unknown.
                                    <div className="mt-2">
                                    </div>
                                </div>
                            </div>

                        </TabPane>
                    </TabContent>
                </OffcanvasBody>
            </Offcanvas>

        </div>
    );
};
