import React from "react";
import {Button} from "reactstrap";
import {toast} from "react-toastify";

const toastOption = {
    autoClose: 30000,
    hideProgressBar: true,
    closeOnClick: false,
    pauseOnHover: true,
    draggable: false,
    theme: "colored",
    style : {
        minHeight: "140px"
    }
}


function ToastTest() {
    const msg = "export프로젝트1_프로젝트_202300900855.zip 내보내기가 완료되었습니다.";
    const show = () => {
        toast.success(msg, toastOption)
        toast.error(msg, toastOption)
        toast.info(msg, toastOption)
    }


    return (

        <>
            <Button onClick={show}>11111111111</Button>
        </>
    )
}


export default ToastTest;