import React, {useEffect, useRef, useState} from 'react';
import {Col, Row} from "reactstrap";
import {
    board_otoSwitchAddModalFlag, board_otoSwitchRefreshFlag,
    board_otoSwitchViewModalFlag,
    board_setModalMode,
    board_setRowData,
    board_switchModalFlag,
    board_switchViewModalFlag
} from "../../store/board/action";
import CommonUtil from "../../util/CommonUtil";
import SimpleBar from "simplebar-react";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import {BoardService} from "../../apis/api/BoardService";
import {FilePond} from "react-filepond";
import AskViewReplyRow from "./AskViewReplyRow";
import {UserUtil} from "../../util/UserUtil";
import {BoardUtil} from "../../util/BoardUtil";
import {useForm} from "react-hook-form";
import {GetMessage, GetMessageIntl} from "../../util/message";
import {useIntl} from "react-intl";

function AskView(props) {
    const intl = useIntl();
    const dispatch = useDispatch();
    const history = useHistory();
    const {rowData, refreshFlag} = useSelector((state) => ({
        rowData: state.Board.rowData,
        refreshFlag: state.Board.otoRefreshFlag
    }));
    const {register, handleSubmit, reset, formState : {errors}} = useForm();
    const [attachFilesUrl, setAttachFilesUrl] = useState([]);
    const coconutUserInfo = JSON.parse(sessionStorage.getItem("coconutUserInfo"));
    const userRole = coconutUserInfo.userRole;
    const userNm = coconutUserInfo.userNm;
    const [replyList, setReplyList] = useState([]);
    const [additionalQueryFlag, setAdditionalQueryFlag] = useState(true);
    const [targetTaskNm, setTargetTaskNm] = useState("");

    const pondRef = useRef();
    const [files, setFiles] = useState([]);
    const [cpFiles, setCpFiles] = useState([]);
    const [cn, setCn] = useState("");
    const [totalFileSize, setTotalFileSize] = useState(0);
    const maxTotalSize = 50 * 1024 * 1024; // 50MB

    const [targetCn, setTargetCn] = useState({});

    // 첨부됐던 파일 가져오기
    const getAttachFilesAwsUrl = async (cnSeq) => {
        const res = await BoardService.getContentAttachFile({
            'cnSeq': cnSeq
        });
        if (res.data.data) {
            setAttachFilesUrl(res.data.data);
        }
    }
    function downloadFile(filePath, realFileName) {
        fetch(filePath, {method: 'GET'})
            .then((res) => {
                return res.blob() // raw 데이터를 받아온다
            })
            .then((blob) => {
                const url = window.URL.createObjectURL(blob) // 받아온 날 상태의 data를 현재 window에서만 사용하는 url로 바꾼다
                const a = document.createElement('a')
                a.href = url
                a.download = realFileName // 원하는 이름으로 파일명 지정
                document.body.appendChild(a)
                a.click() // 자동으로 눌러버리기
                setTimeout((_) => {
                    window.URL.revokeObjectURL(url) // 해당 url을 더 사용 못하게 날려버린다
                }, 60000)
                a.remove() // a를 다 사용했으니 지워준다
                return false;
            })
            .catch((err) => {
                console.error('err: ', err)
            })
    }

    const getCnInfo = async (cnSeq) => {
        const res = await BoardService.getContent({
            'cnSeq': cnSeq
        })
        if (res.data.data) {
            console.log("getCnInfo ->", res.data.data);
            setTargetCn(res.data.data)
            if(res.data.data.cnTy === "01"){
                BoardService.getCnTargetTask({
                    'cnSeq': res.data.data.cnSeq
                }).then(re => {
                    console.log("targetTaskNm => ", re.data.data)
                    setTargetTaskNm(re.data.data)
                })

            }
        }
    }

    const getReply = async (cnSeq) => {
        const res = await BoardService.getReply({
            'cnSeq': cnSeq
        })
        if (res.data.data) {
            if(res.data.data){
                setReplyList(res.data.data.sort((a, b) => a.cnLvl - b.cnLvl))
            }
        }
    }

    useEffect(() => {
        reset();
        getAttachFilesAwsUrl(rowData.cnSeq);
        getCnInfo(rowData.cnSeq);
        getReply(rowData.cnSeq);
    }, [refreshFlag])

    useEffect(()=>{
        let delIdx = [];
        let totalFileSize = 0;
        for (let i = cpFiles.length - 1; i >= 0; i--) {
            totalFileSize += cpFiles[i].file.size
            if(totalFileSize > maxTotalSize){
                delIdx.push(i);
            }
        }
        let tmpArr = [...cpFiles];
        let delArr = [];
        delIdx.map((idxs) => {
            let delTarget = tmpArr.shift();
            delArr.push(delTarget.file.name)
        })

        setFiles(tmpArr);
        setTotalFileSize(totalFileSize);
        if(delArr.length > 0){
            alert("업로드 안된 파일들\n" + delArr.join('\n'));
        }
    }, [cpFiles])

    const handleTextAreaChange = (e) => {
        if (e.target.value.length <= 1000) {
            setCn(e.target.value);
        }
    };

    useEffect(() => {
        const unlisten = history.listen((location, action) => {
            if (action === 'POP') {
                dispatch(board_otoSwitchViewModalFlag())
                if(userRole === '11'){
                    history.push('/manager/board/ask')
                }else if(userRole === '01'){
                    history.push('/platform/board/ask')
                }else if(userRole === '20'){
                    history.push('/user/board/ask')
                }
            }
        });
        return () => {
            unlisten();
        };
    }, [history]);

    const addProcess = () => {
        const data = new FormData();
        data.append("cn", cn);
        data.append("boardSeq", 3);
        data.append("mode", "I");
        data.append("cnTitle", "");
        data.append("orginCnSeq", rowData.cnSeq)
        // replyList에서 가장 높은 레벨보다 1높게
        const highestCnLvl = replyList.length > 0 ? Math.max(...replyList.map(item => Number(item.cnLvl))) + 1 : 2;
        data.append("cnLvl", highestCnLvl);
        data.append("cnTy", "00");
        data.append("replyYn", "N");
        data.append("cnTitle", "");
        data.append("noticeYn", 'N');
        data.append("cnSttusCd", "00");

        for (let i = 0; i < files.length; i++) {
            // append the blob file
            data.append('attachFile[]', files[i].file);
        }

        BoardService.setContent(data, {contentType : 'multipart/form-data'})
            .then((res) => {
                console.log(res.data)
                dispatch(board_otoSwitchRefreshFlag())
            })
            .catch((err)=>{
                const data = new FormData();
            })
            .finally(()=> {
                setFiles([]);
                setCn("")
            });
    }

    const handleRegisterButton = () => {
        addProcess();
    }

    function handleDeleteButton (){
        setCn("");
        setFiles([]);
    }

    function handleCancelBtn () {
        setAdditionalQueryFlag(!additionalQueryFlag)
    }

    return (
        <div className="d-flex justify-content-between align-items-center fs-16 fw-500">
            <div></div>
            <div style={{width: "1200px"}}>
                <Row className="tx-20 mb-2">
                    <Col>
                        {
                            targetTaskNm !== "" ?
                                <span>{GetMessage('board.oto')}<span className="tx-14 text-coco-gray-200"><span className="text-coco-gray-400 fs-20">&nbsp;|&nbsp;참여태스크({targetTaskNm})</span>&nbsp;No.{rowData.cnSeq}</span></span>
                                :
                                <span>{GetMessage('board.oto')}<span className="tx-14 text-coco-gray-200"><span className="text-coco-gray-400 fs-20">&nbsp;|&nbsp;{BoardUtil.convertCnTyToLabel(rowData.cnTy)}</span>&nbsp;No.{rowData.cnSeq}</span></span>
                        }

                    </Col>
                </Row>
                <Row className="mb-3" style={{borderBottom: "1px solid #cfd8dc", paddingBottom: "12px"}}>
                    <Col className="d-flex justify-content-between align-items-center">
                        <div className="tx-24 fw-600 d-flex align-items-center" style={{height: '40px'}}>
                            {
                                replyList.length > 0 ? <span className="badge badge-coco-soft-skyblue">{GetMessage('board.replyYMsg')}</span> : <span className="badge badge-soft-danger">답변 대기</span>
                            }
                            <span style={{marginLeft:"5px"}}>
                                {rowData.cnTitle}
                            </span>
                        </div>
                        <div className="d-flex justify-content-between align-items-center gap-2 tx-16 fw-500">
                            <div>
                                {rowData.regDtmText ? rowData.regDtmText.split(" ")[0] : null} |
                            </div>
                            <div>
                                조회 수 {rowData.readCnt}회
                            </div>
                            <div>
                                {
                                    userRole === '01' ?
                                        <button type="button" className="btn btn-coco-xs-2" onClick={() => {
                                            dispatch(board_setModalMode("U"));
                                            dispatch(board_switchModalFlag());
                                        }}>
                                            {GetMessage('board.modifyMsg')}
                                        </button> : null
                                }
                            </div>

                        </div>
                    </Col>
                </Row>
                <Row className="mb-2">
                    <Col>
                        {
                            attachFilesUrl.map((url, idx) => {
                                const seperateUrl = url.split(",");
                                let filePath = seperateUrl[0];
                                let realFileName = seperateUrl[1];
                                let fileSize = seperateUrl[2];
                                return (
                                    <Row className="mb-2" key={realFileName}>
                                        <div className={"d-flex align-items-center"}>
                                            <i className="ri-download-2-line text-coco-gray-400"
                                               onClick={() => downloadFile(filePath, realFileName)}></i>
                                            <span>&nbsp;{realFileName}</span>
                                            <span
                                                className="fs-16 fw-500 text-coco-gray-400">&nbsp;({CommonUtil.bytesToSize(fileSize)}</span>
                                        </div>
                                    </Row>
                                )
                            })
                        }
                    </Col>
                </Row>
                <Row className="fs-16 pd-y-12" style={{height: '100%', marginBottom: "20px"}}>
                    <SimpleBar style={{maxHeight: "100%"}} className="pe-2">
                        <Col>
                            <div dangerouslySetInnerHTML={{__html: rowData.cn.replaceAll("\n", "<br/>")}}></div>
                        </Col>
                    </SimpleBar>
                </Row>
                <Row style={{borderBottom: "1px solid #cfd8dc", paddingBottom: "1%"}}>
                    <div className="d-flex text-coco-primary-400 fs-20" style={{marginRight: "1%", borderBottom: "1px solid #cfd8dc", paddingBottom:"1%", marginBottom:"1%"}}>
                        <i className="ri-message-3-line" /> <span>{GetMessageIntl(intl, 'board.replyCheck')}</span>
                    </div>
                    {
                        replyList.map((reply) => {
                            const {cn, cnSeq, orginCnSeq, cnOrd, cnLvl, files, userNm, userTy, regDtm, modDtm, regDtmText, modDtmText} = reply
                            return (
                                <AskViewReplyRow key={cnSeq} orginCnSeq={orginCnSeq} cnOrd={cnOrd} cnLvl={cnLvl} cnSeq={cnSeq} cn={cn} files={files} userNm={userNm} userTy={userTy} modDtm={modDtmText}/>
                            )
                        })
                    }


                    {
                        userRole === '20' && replyList.length > 0 && additionalQueryFlag ?
                            <div className="d-flex justify-content-between align-items-center">
                                <div></div>
                                <div>
                                    <button type="button" className="btn btn-coco-outline-1"
                                        onClick={() => {
                                            setAdditionalQueryFlag(!additionalQueryFlag)
                                        }}>
                                        {GetMessageIntl(intl, 'board.additionalQuery')}
                                    </button>
                                </div>
                            </div>

                            :
                            userRole === '20' && replyList.length === 0 ? null
                                :
                            <>
                                <div className="d-flex justify-content-between mb-4">
                                    <div>
                                        {
                                            replyList.length > 0 ?
                                                <i className="mdi mdi-subdirectory-arrow-right" style={{marginLeft:"5px", marginRight:"5px"}}></i>
                                                : null
                                        }
                                        {UserUtil.strFromUserTy(userRole)} ({userNm})
                                    </div>
                                    <div className="text-coco-gray-400">
                                        {`${cn.length}/1000자`}
                                    </div>
                                </div>
                                <div className="mb-3">
                                    <textarea
                                        {...register("replyCn", {
                                            required: GetMessageIntl(intl, 'validation.required'),
                                            onChange: (e) => {handleTextAreaChange(e)}
                                        })}
                                        value={cn}
                                        placeholder={GetMessageIntl(intl, 'board.insertInfoMsg')}
                                        className={"form-control ".concat(errors.replyCn && "is-invalid")}
                                        id=""
                                        rows="7"
                                    />
                                    {errors.replyCn && <span className={"validation-error-message"}>{errors.replyCn.message}</span>}
                                </div>
                                <div>
                                    <div className="d-flex justify-content-between text-coco-gray-400 mb-2">
                                        <div>
                                            {files.length}건의 파일
                                        </div>
                                        <div>
                                            {CommonUtil.bytesToSize(totalFileSize)} / 50MB
                                        </div>
                                    </div>
                                    <div className="askAddModalFilePondDiv">
                                        <SimpleBar style={{maxHeight: "170px"}} className="pe-2">
                                            <FilePond
                                                ref={pondRef}
                                                files={files}
                                                onupdatefiles={setCpFiles}
                                                server={{}}
                                                chunkUploads={true}
                                                allowMultiple={true}
                                                instantUpload={false}
                                                name="files"
                                                labelIdle={GetMessageIntl(intl, "workflow.dataConfig.filepondPlaceholder")}
                                                credits={false}
                                            />
                                        </SimpleBar>
                                    </div>
                                </div>
                                <div className="d-flex justify-content-between">
                                    <div>
                                    </div>
                                    <div className="wd-15p">

                                            {
                                                userRole === '20' ?
                                                    <button type="button" className="btn btn-coco-ghost-2 w-50"
                                                            onClick={handleCancelBtn}>{GetMessageIntl(intl, 'common.cancel')}
                                                    </button>
                                                    :
                                                    <button type="button" className="btn btn-coco-ghost-2 w-50"
                                                            onClick={handleDeleteButton}>{GetMessageIntl(intl, 'common.delete')}
                                                    </button>
                                            }


                                        <button type="button" className="btn btn-coco-2 w-50"
                                                onClick={handleSubmit(handleRegisterButton)}>
                                            {GetMessageIntl(intl, 'common.register')}
                                        </button>
                                    </div>
                                </div>
                            </>
                    }
                </Row>
                <Row>
                    <div className="d-flex justify-content-between align-items-center" style={{marginTop: "20px"}}>
                        <div></div>
                        <div className="d-flex justify-content-between align-items-center gap-2">
                            <button type="button" className="btn btn-coco-1" style={{width: "84px"}} onClick={() => {
                                dispatch(board_setRowData([]));
                                dispatch(board_otoSwitchViewModalFlag());
                            }}>
                                {GetMessageIntl(intl, 'common.list')}
                            </button>
                        </div>
                        <div></div>
                    </div>

                </Row>
            </div>
            <div>
            </div>
        </div>
    );
}

export default AskView;
