import React, {useEffect, useState} from 'react';
import BreadCrumb from "../../../components/common/BreadCrumb";
import {Card, CardHeader, Col, Container, Input, Row} from "reactstrap";
import {useDispatch, useSelector} from "react-redux";
import ProjectCardList from "./ProjectCardList";
import {useUserInfo} from "../../../hooks/UserHooks";
import {
    manage_project_setInitSearchParam,
    manage_project_setList,
    manage_project_setOrderParam,
    manage_project_setSearchParam
} from "../../../store/manage/project/action";
import Select from "react-select";
import {ManagerProjectService} from "../../../apis/api/ManagerProjectService";
import {GetMessage} from "../../../util/message";
import {changeManageProjectId} from "../../../store/manage/action";
import {useHistory} from "react-router-dom";
import {ContextMenu, MenuItem} from "react-contextmenu";
import {useIntl} from "react-intl";

import {alertError, confirm} from "../../../util/AlertUtil";
import {defaultSelectStyle, noBorderSelectStyle} from "../../../components/constants/select";
import {dataset_setMountCnt, setDatasetOptions, setWrkflowOptions} from "../../../store/dataset/action";
import {setMountCnt} from "../../../store/manage/worker/action";

function ProjectGroupList() {

    const intl = useIntl();
    const history = useHistory();
    const dispatch = useDispatch();
    const { coconutUserInfo, loading } = useUserInfo();
    const {
        projectList,
        searchParam,
        orderParam,
    } = useSelector((state) => ({
        projectList: state.ManageProject.projectList,
        searchParam: state.ManageProject.searchParam,
        orderParam: state.ManageProject.orderParam,
    }));
    const [modal, setModal] = useState(false);

    const orderByOption =[
        { label: GetMessage("ui.U00120"), value: "PRJCT_NM" },
        { label: GetMessage("ui.U00121"), value: "REG_DTM" },
        { label: GetMessage("ui.U00122"), value: "INQIRE_DTM" }
    ];

    useEffect(()=>{
        let searchCloseIcon = document.getElementById("search-close-icon");
        let searchInput = document.getElementById("search-input");

        searchInput.addEventListener("focus", function () {
            let inputLength = searchInput.value.length;
            if (inputLength > 0) {
                searchCloseIcon.classList.remove("d-none");
            } else {
                searchCloseIcon.classList.add("d-none");
            }
        });

        searchInput.addEventListener("keyup", function () {
            let inputLength = searchInput.value.length;
            if (inputLength > 0) {
                searchCloseIcon.classList.remove("d-none");
            } else {
                searchCloseIcon.classList.add("d-none");
            }
        });

        searchCloseIcon.addEventListener("click", function () {
            searchInput.value = "";
            searchCloseIcon.classList.add("d-none");
            dispatch(manage_project_setInitSearchParam());
            getProjects(true).then(()=>{

            })
        });

    },[]);

    useEffect(() => {
        getProjects().then(()=>{

        })
    }, [orderParam]);

    const toggle = () => {
        if (modal) {
            setModal(false);
        } else {
            setModal(true);
        }
    };

    const prjctLinkedClick = (prjctId) =>{
        //console.log('카드 목록에서 클릭했습니다. : ', prjctId)
        dispatch(changeManageProjectId(prjctId));

        // 데이터쪽 초기화
        dispatch(dataset_setMountCnt(1));
        dispatch(setWrkflowOptions(null))
        dispatch(setDatasetOptions([]))
        // 작업자쪽 초기화
        dispatch(setMountCnt(1));

        sessionStorage.setItem("prjctId",prjctId)
        ManagerProjectService
            .getProjectItem({"prjctId":prjctId})
            .then(()=>{
                history.push({
                    pathname:"/manager/project/dashboard"
                });
            });
    }

    const handleKeyPress = (event)=>{
        if (event.key === 'Enter') {
            getProjects();
        }
    }

    const handleInputBlur = (event)=>{
        getProjects();
    }

    const handleInputChange = (event)=>{
        dispatch(manage_project_setSearchParam({'PRJCT_NM': event.target.value}))
    }

    const handleOrderBy = (event)=>{
        // console.log("orderParam : ", orderParam);
        // console.log("event.value :", event.value);
        // console.log("orderByOption : ", orderByOption);
        dispatch(manage_project_setOrderParam(event.value));
    }

    const getProjects = async (searchInit) => {
        const res = await ManagerProjectService.getProjectList({
            'orgnztId' : coconutUserInfo.orgnztId,
            'orderParam' : orderParam,
            'searchParam' : searchInit?[]:searchParam
        })

        if(res.data.data){
            dispatch(manage_project_setList(res.data.data));
        }
    };

    const projectContextMenu = (e, data, target) => {
        if(data.action === 'changeName'){
            editPrjctNm(data.prjctId);
        }else if(data.action === 'delete'){
            deletePrjctId(data.prjctId);
        }
    }

    const editPrjctNm = (prjctId) =>{
        dispatch(manage_project_setList(
            projectList.map((prjctItem) => prjctItem.prjctId === prjctId? {...prjctItem, 'editMode':true}: prjctItem)
        ));
    }

    const deletePrjctId = (prjctId) =>{
        confirm("project.deleteProjectConfirm"
            , ""
            ,()=>{
                deleteProject(prjctId);
            }
            ,null
            , null
            , intl);
    }

    const addProjectConfirm = () =>{
        confirm("project.addProjectConfirm"
            , ""
            ,()=>{
                addProject();
            }
            ,null
            , null
            , intl);
    }

    const addProject = async () =>{
        const res = await ManagerProjectService.addProjectItem({
            'orgnztId': coconutUserInfo.orgnztId
        });
        if(res.data.success){
            prjctLinkedClick(res.data.data.prjctId)
        }else{
            alertError(
                "error.E00001"
                , "error.E00002"
                , null
                , {"errorMessage" : res.data.errorMessage}
                ,null
                , intl);
        }
    }

    const deleteProject = async (prjctId) =>{
        const res = await ManagerProjectService.deleteProjectItem({
            'prjctId':prjctId
        });
        if(res.data.success){
            getProjects();
        }else{
            alertError(
                "error.E00001"
                , "error.E00002"
                , null
                , {"errorMessage" : res.data.errorMessage}
                ,null
                , intl);
        }
    }

    const handleUpdateEvent = async(prjctId, prjctNm)=>{
        await ManagerProjectService.updateProjectItem({
            'prjctId': prjctId,
            'prjctNm': prjctNm,
        }).then(res=>{
            if(res.data.data){
                getProjects();
            }else{
                dispatch(manage_project_setList(
                    projectList.map((prjctItem) => prjctItem.prjctId === prjctId? {...prjctItem, 'editMode':false}: prjctItem)
                ));
            }
        })

    }

    return (
        <>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title={GetMessage("menu.MPJT0000")} pageTitle="Laflow" />
                    <Row>
                        <Card className="shadow-none">
                            <CardHeader className="border-0 rounded p-0">
                                <Row className="g-3">
                                    <Col md={3} sm={5}>
                                        <div className="position-relative form-search">
                                            <Input type="text" className="form-control" id="search-input"
                                                   placeholder={GetMessage("project.searchPlaceHolder")}
                                                   value={searchParam[0]?searchParam[0].PRJCT_NM:''}
                                                   onKeyUp={handleKeyPress}
                                                   onBlur={handleInputBlur}
                                                   onChange={handleInputChange} />
                                            <span className="ri-search-line search-widget-icon"></span>
                                            <span className="ri-close-line search-widget-icon search-widget-icon-close d-none" id="search-close-icon"></span>
                                        </div>
                                    </Col>
                                    <Col md={9} sm={7}>
                                        <div className="gap-2 d-flex flex-nowrap justify-content-end">
                                            {/*<div className="d-flex align-items-center gap-2">*/}
                                                <Select
                                                    name="orderParam"
                                                    className="mb-0"
                                                    onChange={
                                                        handleOrderBy
                                                    }
                                                    styles={noBorderSelectStyle}
                                                    value={orderByOption?.find(order => order.value === orderParam[0]) || orderByOption[1]}
                                                    options={orderByOption}
                                                />
                                            {/*</div>*/}
                                            <button className="btn btn-coco-1" onClick={addProjectConfirm}>
                                                {GetMessage("project.createNew")}
                                            </button>
                                        </div>
                                    </Col>
                                </Row>
                            </CardHeader>
                        </Card>
                    </Row>
                    <Row className="row-cols-xxl-5 row-cols-lg-3 row-cols-1">
                        <ProjectCardList list={projectList} clickEvent={prjctLinkedClick} handleEvent={handleUpdateEvent} contextMenuId="projectContextMenu"/>
                        <ContextMenu id="projectContextMenu">
                            <MenuItem onClick={projectContextMenu} data={{ action: 'changeName' }}>{GetMessage("common.changeName")}</MenuItem>
                            <MenuItem onClick={projectContextMenu} data={{ action: 'delete' }}>{GetMessage("common.delete")}</MenuItem>
                        </ContextMenu>
                    </Row>
                </Container>
            </div>
        </>
    );
}

export default ProjectGroupList;