// Copyright (C) 2021-2022 Intel Corporation
// Copyright (C) 2022 CVAT.ai Corporation
//
// SPDX-License-Identifier: MIT
// @ts-nocheck
import React from 'react';
import copy from 'copy-to-clipboard';
import { connect } from 'react-redux';

import {
    activateObject as activateObjectAction,
    changeFrame as changeFrameAsync,
    changeGroupColor as changeGroupColorAsync,
    copyShape as copyShapeAction,
    pasteShapeAction as pasteShapeAsync,
    readyObject,
    removeObject as removeObjectAction,
    switchPropagateVisibility as switchPropagateVisibilityAction,
    updateAnnotations as updateAnnotationsAsync, updateCanvasContextMenu
} from "store/label/action";
import {ActiveControl, ColorBy, CombinedState, ContextMenuType, ShapeType, Workspace,} from 'store/label';
import ReviewObjectItemComponent from "../../../components/review-workspace/objects-side-bar/object-item";
// import ObjectItemComponent from "../../../components/review-workspace/objects-side-bar/object-item";
import { getColor } from 'pages/user/label/annotation/image/components/standard-workspace/objects-side-bar/shared';
import { shift } from 'pages/user/label/annotation/image/utils/math';
import { Canvas, CanvasMode } from 'pages/user/label/annotation/image/cvat-canvas-wrapper';
import { Canvas3d } from 'pages/user/label/annotation/image/cvat-canvas3d-wrapper';
import { filterApplicableLabels } from 'pages/user/label/annotation/image/utils/filter-applicable-labels';
import {MD_TY_CD} from '../../../const';
import ReviewInstance from "../../../work/core/review-instance";
import {updateActiveShape} from "../../../../../../../../store/review/action";

interface OwnProps {
    readonly: boolean;
    clientID: number;
    viewID: number;
    objectStates: any[];
    sortedStatesID: number[];
    item: string | null;
    containerKey: string;

    // firstReviewObjectID: number;

    onUpdateStates(e):void;
}

interface StateToProps {
    objectState: any;
    labels: any[];
    moduleType: MD_TY_CD;
    attributes: any[];
    jobInstance: any;
    frameNumber: number;
    prepared: boolean;
    activated: boolean;
    colorBy: ColorBy;
    ready: boolean;
    errorVisible: boolean;
    activeControl: ActiveControl;
    activatedStateID: number | null;
    activatedElementID: number | null;
    minZLayer: number;
    maxZLayer: number;
    normalizedKeyMap: Record<string, string>;
    canvasInstance: Canvas | Canvas3d;
    reviewInstData: ReviewInstance;
    workspace: Workspace
}

interface DispatchToProps {
    changeFrame(frame: number): void;
    updateState(objectState: any): void;
    readyObject(readyStateID: number | null): void;
    activateObject: (activatedStateID: number | null, activatedElementID: number | null) => void;
    removeObject: (objectState: any) => void;
    copyShape: (objectState: any) => void;
    switchPropagateVisibility: (visible: boolean) => void;
    changeGroupColor(group: number, color: string): void;
}

function mapStateToProps(state: CombinedState, own: OwnProps): StateToProps {
    const {
        Label: {
            Cmmn: {
                annotations: {
                    readyStateID,
                    activatedStateID,
                    zLayer: {min: minZLayer, max: maxZLayer},
                },
                module: {attributes: jobAttributes, instance: jobInstance, labels, type},
                player: {
                    frame: {number: frameNumber},
                },
                canvas: {
                    instance: canvasInstance, ready, activeControl,
                },
                workspace
            }
        },
        settings: {
            shapes: { colorBy },
        },
        shortcuts: { normalizedKeyMap },
        Review: {
            Cmmn: {
                canvas: {
                    badges: {
                        visible: errorVisible,
                    }
                },
                annotations: {
                    data: reviewTotalData
                }
            }
        }
    } = state;

    const { objectStates: states, clientID } = own;
    const stateIDs = states.map((_state: any): number => _state.clientID);
    const index = stateIDs.indexOf(clientID);

    const reviewData = reviewTotalData.instance ?
                            reviewTotalData.instance[clientID] ?? {reject: null, rsnCd: [], completedError: false, editable: null}
                        : {reject: null, rsnCd: [], completedError: false, editable: null};

    return {
        objectState: states[index],
        attributes: jobAttributes[states[index].label.id],
        labels,
        moduleType: type,
        ready,
        errorVisible,
        activeControl,
        colorBy,
        jobInstance,
        frameNumber,
        prepared: readyStateID === clientID,
        activated: activatedStateID === clientID,
        activatedStateID,
        minZLayer,
        maxZLayer,
        normalizedKeyMap,
        canvasInstance,
        reviewData,
        reject: reviewData.reject,
        rsnCd: reviewData.rsnCd,
        completedError: reviewData.completedError,
        editable: reviewData.editable,
        workspace
    };
}

function mapDispatchToProps(dispatch: any): DispatchToProps {
    return {
        changeFrame(frame: number): void {
            dispatch(changeFrameAsync(frame));
        },
        updateActiveShape(activatedStateID: number): void {
            dispatch(updateActiveShape(activatedStateID));
        },
        updateState(state: any): void {
            dispatch(updateAnnotationsAsync([state]));
        },
        updateAnnotations(states: any): void {
            dispatch(updateAnnotationsAsync(states));
        },
        readyObject(readyStateID: number | null): void {
            dispatch(readyObject(readyStateID, null, null));
        },
        activateObject(activatedStateID: number | null): void {
            dispatch(activateObjectAction(activatedStateID, null, null));
        },
        removeObject(objectState: any): void {
            dispatch(removeObjectAction(objectState, false));
        },
        copyShape(objectState: any): void {
            dispatch(copyShapeAction(objectState));
            dispatch(pasteShapeAsync());
        },
        switchPropagateVisibility(visible: boolean): void {
            dispatch(switchPropagateVisibilityAction(visible));
        },
        changeGroupColor(group: number, color: string): void {
            dispatch(changeGroupColorAsync(group, color));
        },
        onUpdateContextMenu(
            visible: boolean, left: number, top: number,
            pointID: number | null, type?: ContextMenuType
        ): void{
            dispatch(updateCanvasContextMenu(visible, left, top, pointID, type));
        }
    };
}

type Props = StateToProps & DispatchToProps & OwnProps;
class ObjectItemReviewContainer extends React.PureComponent<Props> {
    private copy = (): void => {
        const { objectState, readonly, copyShape } = this.props;
        if (!readonly) {
            copyShape(objectState);
        }
    };

    private propagate = (): void => {
        const { switchPropagateVisibility, readonly } = this.props;
        if (!readonly) {
            switchPropagateVisibility(true);
        }
    };

    private edit = (): void => {
        const { objectState, readonly, canvasInstance } = this.props;

        if (!readonly && canvasInstance instanceof Canvas) {
            if (canvasInstance.mode() !== CanvasMode.IDLE) {
                canvasInstance.cancel();
            }

            canvasInstance.edit({ enabled: true, state: objectState });
        }
    }

    private remove = (): void => {
        const {
            objectState, readonly, removeObject,
        } = this.props;

        if (!readonly) {
            removeObject(objectState);
        }
    };

    private createURL = (): void => {
        const { objectState, frameNumber } = this.props;
        const { origin, pathname } = window.location;

        const search = `frame=${frameNumber}&type=${objectState.objectType}&serverID=${objectState.serverID}`;
        const url = `${origin}${pathname}?${search}`;
        copy(url);
    };

    private switchOrientation = (): void => {
        const { objectState, readonly, updateState } = this.props;
        if (readonly) {
            return;
        }

        if (objectState.shapeType === ShapeType.CUBOID) {
            this.switchCuboidOrientation();
            return;
        }

        const reducedPoints = objectState.points.reduce(
            (acc: number[][], _: number, index: number, array: number[]): number[][] => {
                if (index % 2) {
                    acc.push([array[index - 1], array[index]]);
                }

                return acc;
            },
            [],
        );

        if (objectState.shapeType === ShapeType.POLYGON) {
            objectState.points = reducedPoints.slice(0, 1).concat(reducedPoints.reverse().slice(0, -1)).flat();
            updateState(objectState);
        } else if (objectState.shapeType === ShapeType.POLYLINE) {
            objectState.points = reducedPoints.reverse().flat();
            updateState(objectState);
        }
    };

    private toBackground = (): void => {
        const { objectState, readonly, minZLayer } = this.props;

        if (!readonly) {
            objectState.zOrder = minZLayer - 1;
            this.commit();
        }
    };

    private toForeground = (): void => {
        const { objectState, readonly, maxZLayer } = this.props;

        if (!readonly) {
            objectState.zOrder = maxZLayer + 1;
            this.commit();
        }
    };

    private prepare = (readyElementID?: number): void => {
        const {
            objectState, ready, activeControl, readyObject,
        } = this.props;

        if (ready && activeControl === ActiveControl.CURSOR && !objectState.hidden) {
            readyObject(
                objectState.clientID,
                (Number.isInteger(readyElementID) ? readyElementID : null) as number | null,
            )
        }
    }



    private activate = (activeElementID?: number): void => {
        const {
            objectState, objectStates: states, ready, activeControl, activateObject
            , updateState, updateAnnotations, updateActiveShape
        } = this.props;

        if (ready && activeControl === ActiveControl.CURSOR) {
            // objectState.hidden = false;
            // updateState(objectState);
            // updateAnnotations(states.filter(s => s.clientID!==objectState.clientID).map((state) => ((state.hidden = true), state)));
            updateActiveShape(objectState.clientID);
            // activateObject(
            //     objectState.clientID,
            //     (Number.isInteger(activeElementID) ? activeElementID : null) as number | null,
            // );
        }
    };

    private changeColor = (color: string): void => {
        const { objectState, colorBy, changeGroupColor } = this.props;

        if (colorBy === ColorBy.INSTANCE) {
            objectState.color = color;
            this.commit();
        } else if (colorBy === ColorBy.GROUP) {
            changeGroupColor(objectState.group.id, color);
        }
    };

    private changeLabel = (label: any): void => {
        const { objectState, readonly } = this.props;
        if (!readonly) {
            objectState.label = label;
            this.commit();
        }
    };

    private switchCuboidOrientation = (): void => {
        function cuboidOrientationIsLeft(points: number[]): boolean {
            return points[12] > points[0];
        }

        const { objectState, readonly } = this.props;

        if (!readonly) {
            this.resetCuboidPerspective(false);
            objectState.points = shift(objectState.points, cuboidOrientationIsLeft(objectState.points) ? 4 : -4);

            this.commit();
        }
    };

    private resetCuboidPerspective = (commit = true): void => {
        function cuboidOrientationIsLeft(points: number[]): boolean {
            return points[12] > points[0];
        }
        const { objectState, readonly } = this.props;

        if (!readonly) {
            const { points } = objectState;
            const minD = {
                x: (points[6] - points[2]) * 0.001,
                y: (points[3] - points[1]) * 0.001,
            };

            if (cuboidOrientationIsLeft(points)) {
                points[14] = points[10] + points[2] - points[6] + minD.x;
                points[15] = points[11] + points[3] - points[7];
                points[8] = points[10] + points[4] - points[6];
                points[9] = points[11] + points[5] - points[7] + minD.y;
                points[12] = points[14] + points[0] - points[2];
                points[13] = points[15] + points[1] - points[3] + minD.y;
            } else {
                points[10] = points[14] + points[6] - points[2] - minD.x;
                points[11] = points[15] + points[7] - points[3];
                points[12] = points[14] + points[0] - points[2];
                points[13] = points[15] + points[1] - points[3] + minD.y;
                points[8] = points[12] + points[4] - points[0] - minD.x;
                points[9] = points[13] + points[5] - points[1];
            }

            objectState.points = points;
            if (commit) this.commit();
        }
    };

    private commit(): void {
        const { objectState, readonly, updateState } = this.props;
        if (!readonly) {
            updateState(objectState);
        }
    }

    private onOpenCanvasContextMenu = (): void => {
        const { objectState, activatedStateID, onUpdateContextMenu } = this.props;
        onUpdateContextMenu(
            true, null, null, null, ContextMenuType.CANVAS_SHAPE,
        );
        // onUpdateContextMenu(
        //     activatedStateID !== null, null, null, null, ContextMenuType.CANVAS_SHAPE,
        // );
    };

    public render(): JSX.Element {
        const {
            sortedStatesID,
            objectState,
            viewID,
            labels,
            moduleType,
            attributes,
            prepared,
            activated,
            colorBy,
            normalizedKeyMap,
            errorVisible,
            readonly,
            jobInstance,
            item,
            containerKey,
            ready,
            firstReviewObjectID,
            reviewData,
            reject,
            rsnCd,
            completedError,
            editable,
            workspace,
            onUpdateStates
        } = this.props;
        // let reject = null, rsnCd = [], completedError = false, editable = null;
        // if (reviewData) {
        //     reject = reviewData.reject;
        //     rsnCd = reviewData.rsnCd;
        //     completedError = reviewData.completedError;
        //     editable = reviewData.editable;
        // }

        const applicableLabels = filterApplicableLabels(objectState, labels);

        return (
            <ReviewObjectItemComponent
                workspace={workspace}
                moduleInstance={jobInstance}
                moduleType={moduleType}
                sortedStatesID={sortedStatesID}
                readonly={readonly}
                prepared={prepared}
                activated={activated}
                attributeError={completedError}
                hidden={objectState.hidden}
                objectType={objectState.objectType}
                shapeType={objectState.shapeType}
                clientID={objectState.clientID}
                viewID={objectState.viewID}
                serverID={objectState.serverID}
                locked={objectState.lock}
                labelID={objectState.label.id}
                color={getColor(objectState, colorBy)}
                attributes={attributes}
                elements={objectState.elements}
                normalizedKeyMap={normalizedKeyMap}
                errorVisible={errorVisible}
                labels={applicableLabels}
                colorBy={colorBy}
                ready={ready}
                firstReviewObjectID={firstReviewObjectID}
                reject={reject}
                rsnCd={rsnCd}
                editable={editable}
                prepare={this.prepare}
                activate={this.activate}
                remove={this.remove}
                copy={this.copy}
                propagate={this.propagate}
                createURL={this.createURL}
                switchOrientation={this.switchOrientation}
                toBackground={this.toBackground}
                toForeground={this.toForeground}
                changeColor={this.changeColor}
                changeLabel={this.changeLabel}
                edit={this.edit}
                resetCuboidPerspective={() => this.resetCuboidPerspective()}
                item={item}
                containerKey={containerKey}
                onUpdateStates={onUpdateStates}
                onOpenCanvasContextMenu={this.onOpenCanvasContextMenu}
            />
        );
    }
}

export default connect<StateToProps, DispatchToProps, OwnProps, CombinedState>(
    mapStateToProps,
    mapDispatchToProps,
)(ObjectItemReviewContainer);
