// @ts-nocheck
import React from 'react';

import ControlsSideBarContainer from 'pages/user/label/annotation/image/containers/review-workspace/controls-side-bar/controls-side-bar';
import CanvasContextMenuContainer from 'pages/user/label/annotation/image/containers/canvas/canvas-context-menu';
import ObjectsListContainer from 'pages/user/label/annotation/image/containers/review-workspace/objects-side-bar/objects-list';
import ObjectSideBarComponent from 'pages/user/label/annotation/image/components/standard-workspace/objects-side-bar/objects-side-bar';
import {CanvasLayout} from "../../../../cmmn/components/CanvasLayout";

export default function ReviewWorkspaceComponent(): JSX.Element {
    return (
        <div className='coco-standard-workspace'>
            <ControlsSideBarContainer />
            <CanvasLayout className={"content-container"}/>
            <ObjectSideBarComponent objectsList={<ObjectsListContainer readonly />} />
            <CanvasContextMenuContainer readonly />
        </div>
    );
}
