import {GetMessage} from "../../../../../../../../util/message";
import {ShapeType} from "../../../../../../../../store/label";
import React, {useEffect, useState} from "react";
import {Input, Label} from "reactstrap";
import {STATE_ITEM_LABEL_SELECTOR} from "../../classNameConsts";

export interface Props{
    labels: any[];
    readonly: boolean;
    shapeType: ShapeType;
    labelID: number;
    changeLabel(label: any): void;
}
export function ObjectPopoverClass(props: Props){
    const { labels, readonly, shapeType, labelID, changeLabel
    }=props;
    return(
        <div>
            <h6>
                {GetMessage("ui.U00114")}
            </h6>
            <ChipLabelSelector
                disabled={readonly || shapeType === ShapeType.SKELETON}
                labels={labels}
                value={labelID}
                onChange={changeLabel}
                className={STATE_ITEM_LABEL_SELECTOR}
            />
        </div>
    )
}

interface selectorProps{
    labels: any[];
    value: any | number | null;
    onChange: (label: any) => void;
    onEnterPress?: (labelID: number) => void;
    disabled: boolean;
    className: string;
}

export function ChipLabelSelector(props: selectorProps): JSX.Element {
    const {
        labels, value, disabled, onChange, onEnterPress, ...rest
    } = props;

    const inputChange = (newValue: number) => {
        const [label] = labels.filter((_label: any): boolean => _label.id === +newValue);
        if (label) {
            onChange(label);
        } else {
            throw new Error(`Label with id ${newValue} was not found within the list`);
        }
    }
    return (
        <div className={"d-flex"}>
            {
                labels.map(element => (
                    <div className="form-check form-radio-coco form-check-btn" key={element.id}>
                        <Input type="radio" className="form-check-input btn-check"
                               id={`coco-class-${element.id}`}
                               name="object-popover-class" required
                               value={element.id}
                               onChange={ (e) => inputChange(parseInt(e.target.value)) }
                               checked={element.id === value}
                               disabled={disabled}
                        />
                        <Label className="form-check-label btn btn-coco-outline-1 pd-x-12 pd-y-3"
                               htmlFor={`coco-class-${element.id}`}
                        >
                            {element.name}
                        </Label>
                    </div>
                ))
            }
        </div>
    )
}
