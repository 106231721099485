export const GET_WORKFLOW = 'dataset/details/GET_WORKFLOW';
export const GET_WORKFLOW_SUCCESS = 'dataset/details/GET_WORKFLOW_SUCCESS';
export const CLOSE_WORKFLOW = 'dataset/details/CLOSE_WORKFLOW';
export const UPDATE_SEARCH_PARAM_VISIBLE = 'dataset/detailis/UPDATE_SEARCH_PARAM_VISIBLE';
export const UPDATE_SEARCH_PARAM = 'dataset/details/UPDATE_SEARCH_PARAM';
export const UPDATE_SEARCH_PARAM_SUCCESS = 'dataset/details/UPDATE_SEARCH_PARAM_SUCCESS';
export const UPDATE_SEARCH_PARAM_FAILED = 'dataset/details/UPDATE_SEARCH_PARAM_FAILED';
export const UPDATE_SEARCH_PARAM_ORDER = 'dataset/details/UPDATE_SEARCH_PARAM_ORDER';
export const RESET_SEARCH_PARAM_TYPE = 'dataset/details/RESET_SEARCH_PARAM_TYPE';
export const GET_DATASET_BY_WORKFLOW = 'dataset/details/GET_DATASET_BY_WORKFLOW';
export const GET_DATASET_BY_WORKFLOW_SUCCESS = 'dataset/details/GET_DATASET_BY_WORKFLOW_SUCCESS';
export const GET_DATASET_BY_WORKFLOW_FAILED = 'dataset/details/GET_DATASET_BY_WORKFLOW_FAILED';
export const GET_MODULE_BY_WORKFLOW = 'dataset/details/GET_MODULE_BY_WORKFLOW';
export const GET_MODULE_BY_WORKFLOW_SUCCESS = 'dataset/details/GET_MODULE_BY_WORKFLOW_SUCCESS';
export const GET_MODULE_BY_WORKFLOW_FAILED = 'dataset/details/GET_MODULE_BY_WORKFLOW_FAILED';
export const GET_WORKER_BY_WORKFLOW = 'dataset/details/GET_WORKER_BY_WORKFLOW';
export const GET_WORKER_BY_WORKFLOW_SUCCESS = 'dataset/details/GET_WORKER_BY_WORKFLOW_SUCCESS';
export const GET_WORKER_BY_WORKFLOW_FAILED = 'dataset/details/GET_WORKER_BY_WORKFLOW_FAILED';
export const GET_DATA_LIST = 'dataset/details/GET_DATA_LIST'
export const SET_DATA_LIST = 'dataset/details/SET_DATA_LIST';
export const SET_DATA_STTUS = 'dataset/details/SET_DATA_STTUS';
export const GET_FILE_INFO = 'dataset/details/GET_FILE_INFO';
export const GET_FILE_INFO_SUCCESS = 'dataset/details/GET_FILE_INFO_SUCCESS';
export const GET_FILE_INFO_FAILED = 'dataset/details/GET_FILE_INFO_FAILED';
export const GET_REJECT_RSN = 'dataset/details/GET_REJECT_RSN';
export const GET_REJECT_RSN_SUCCESS = 'dataset/details/GET_REJECT_RSN_SUCCESS';
export const GET_REJECT_RSN_FAILED = 'dataset/details/GET_REJECT_RSN_FAILED';
export const GET_SKIP_RSN = 'dataset/details/GET_SKIP_RSN';
export const GET_SKIP_RSN_SUCCESS = 'dataset/details/GET_SKIP_RSN_SUCCESS';
export const GET_SKIP_RSN_FAILED = 'dataset/details/GET_SKIP_RSN_FAILED';
export const RECOVERY_DATA = 'dataset/details/RECOVERY_DATA';
export const RECOVERY_DATA_SUCCESS = 'dataset/details/RECOVERY_DATA_SUCCESS';
export const RECOVERY_DATA_FAILED = 'dataset/details/RECOVERY_DATA_FAILED';
export const CHECK_UNABLE_WORK = 'dataset/details/CHECK_UNABLE_WORK';
export const CHECK_UNABLE_WORK_SUCCESS = 'dataset/details/CHECK_UNABLE_WORK_SUCCESS';
export const CHECK_UNABLE_WORK_FAILED = 'dataset/details/CHECK_UNABLE_WORK_FAILED';
export const GET_HISTORY = 'dataset/details/GET_HISTORY';
export const GET_HISTORY_SUCCESS = 'dataset/details/GET_HISTORY_SUCCESS';
export const GET_HISTORY_FAILED = 'dataset/details/GET_HISTORY_FAILED';
export const GET_JSON = 'dataset/details/GET_JSON';
export const GET_JSON_SUCCESS = 'dataset/details/GET_JSON_SUCCESS';
export const GET_JSON_FAILED = 'dataset/details/GET_JSON_FAILED';
export const UPDATE_JSON_MODAL = 'dataset/details/UPDATE_JSON_MODAL';
export const UPDATE_STAGE_TAB_ACTIVE = 'dataset/details/UPDATE_STAGE_TAB_ACTIVE';

// .Resizable sidebar
export const SET_LEFT_SIDEBAR_WIDTH = 'dataset/details/SET_LEFT_SIDEBAR_WIDTH';
export const SET_RIGHT_SIDEBAR_WIDTH = 'dataset/details/SET_RIGHT_SIDEBAR_WIDTH';

