// @ts-nocheck

import { ArgumentError, DataError } from './exceptions';
import { HistoryActions } from './enums';
import loggerStorage from './logger-storage';
import serverProxy from './server-proxy';
import {
    getFrame,
    deleteFrame,
    restoreFrame,
    getRanges,
    clear as clearFrames,
    findNotDeletedFrame,
    getContextImage,
    patchMeta,
    getDeletedFrames,
    decodePreview, FrameData,
} from './frames';
import Issue from './issue';
import { checkObjectType } from './common';
import {
    getAnnotations, putAnnotations, saveAnnotations,
    hasUnsavedChanges, searchAnnotations, searchEmptyFrame,
    mergeAnnotations, splitAnnotations, groupAnnotations,
    clearAnnotations, selectObject, annotationsStatistics,
    importCollection, exportCollection, importDataset,
    exportDataset, undoActions, redoActions,
    freezeHistory, clearActions, getActions,
    clearCache, getHistory, updateFrameMeta, updateViewID, importFromModel,
} from './annotations';

// must be called with task/job context
async function deleteFrameWrapper(moduleId, frame) {
    const history = getHistory(this);
    const redo = async () => {
        deleteFrame(moduleId, frame);
    };

    await redo();
    history.do(HistoryActions.REMOVED_frame, async () => {
        restoreframe(moduleId, frame);
    }, redo, [], frame);
}

async function restoreFrameWrapper(moduleId, frame) {
    const history = getHistory(this);
    const redo = async () => {
        restoreFrame(moduleId, frame);
    };

    await redo();
    history.do(HistoryActions.RESTORED_frame, async () => {
        deleteFrame(moduleId, frame);
    }, redo, [], frame);
}

export function implementModule(Module) {
    Module.prototype.save.implementation = async function () {
        if (this.id) {
            const jobData = this._updateTrigger.getUpdated(this);
            if (jobData.assignee) {
                jobData.assignee = jobData.assignee.id;
            }

            const data = await serverProxy.jobs.save(this.id, jobData);
            this._updateTrigger.reset();
            return new Job(data);
        }

        throw new ArgumentError('Could not save job without id');
    };

    Module.prototype.issues.implementation = async function () {
        const result = await serverProxy.issues.get(this.id);
        return result.map((issue) => new Issue(issue));
    };

    Module.prototype.openIssue.implementation = async function (issue, message) {
        checkObjectType('issue', issue, null, Issue);
        checkObjectType('message', message, 'string');
        const result = await serverProxy.issues.create({
            ...issue.serialize(),
            message,
        });
        return new Issue(result);
    };

    Module.prototype.frames.get.implementation = async function (frame, datasetId, fileSeq, fileNm, filePath, url, dataType, fileSize, encodedString, isPlaying, step) {
        if (!Number.isInteger(frame) || frame < 0) {
            throw new ArgumentError(`frame must be a positive integer. Got: "${frame}"`);
        }

        if (frame < this.startFrame || frame > this.stopFrame) {
            throw new ArgumentError(`The frame with number ${frame} is out of the job`);
        }

        if (this.frame instanceof FrameData){
            return this.frame;
        }

        const frameData = await getFrame(
            this.targetModuleId,    // id -> targetModuleId
            datasetId,
            fileSeq, fileNm, filePath, url, dataType, fileSize,
            // encodedString = this.frame.encodedString,
            this.dataChunkSize,
            this.dataChunkType,
            this.mode,
            frame,
            this.startFrame,
            this.stopFrame,
            isPlaying,
            step,
            this.dimension,
        );
        if(frameData._data){
            await frameData.data(this.frame.encodedString);
        }
        return frameData;
    };

    Module.prototype.frames.delete.implementation = async function (frame) {
        if (!Number.isInteger(frame)) {
            throw new Error(`frame must be an integer. Got: "${frame}"`);
        }

        if (frame < this.startframe || frame > this.stopframe) {
            throw new Error('The frame is out of the job');
        }

        await deleteFrameWrapper.call(this, this.targetModuleId, frame);    // id -> targetModuleId
    };

    Module.prototype.frames.restore.implementation = async function (frame) {
        if (!Number.isInteger(frame)) {
            throw new Error(`frame must be an integer. Got: "${frame}"`);
        }

        if (frame < this.startframe || frame > this.stopframe) {
            throw new Error('The frame is out of the job');
        }

        await restoreFrameWrapper.call(this, this.targetModuleId, frame);   // id -> targetModuleId
    };

    Module.prototype.frames.save.implementation = async function () {
        const result = await patchMeta(this.targetModuleId);  // id -> targetModuleId
        return result;
    };

    Module.prototype.frames.ranges.implementation = async function () {
        const rangesData = await getRanges(this.targetModuleId);    // id -> targetModuleId
        return rangesData;
    };

    Module.prototype.frames.preview.implementation = async function () {
        if (this.id === null || this.taskId === null) {
            return '';
        }

        const preview = await serverProxy.jobs.getPreview(this.targetModuleId); // id -> targetModuleId
        const decoded = await decodePreview(preview);
        return decoded;
    };

    Module.prototype.frames.contextImage.implementation = async function (frameId) {
        const result = await getContextImage(this.targetModuleId, frameId); // id -> targetModuleId
        return result;
    };

    Module.prototype.frames.search.implementation = async function (filters, frameFrom, frameTo) {
        if (typeof filters !== 'object') {
            throw new ArgumentError('Filters should be an object');
        }

        if (!Number.isInteger(frameFrom) || !Number.isInteger(frameTo)) {
            throw new ArgumentError('The start and end frames both must be an integer');
        }

        if (frameFrom < this.startframe || frameFrom > this.stopframe) {
            throw new ArgumentError('The start frame is out of the job');
        }

        if (frameTo < this.startframe || frameTo > this.stopframe) {
            throw new ArgumentError('The stop frame is out of the job');
        }
        if (filters.notDeleted) {
            return getDeletedFrames(this.targetModuleId, frameFrom, frameTo, filters.offset || 1);  // id -> targetModuleId
        }
        return null;
    };

    Module.prototype.annotations.updateViewID.implementation = async function (){
        return updateViewID(this);
    };

    Module.prototype.annotations.updateFrameMeta.implementation = async function (){
        return updateFrameMeta(this);
    };

    // TODO: Check filter for annotations
    Module.prototype.annotations.get.implementation = async function (frame, datasetId, fileSeq, allTracks, filters) {
        // if (!Array.isArray(filters)) {
        //     throw new ArgumentError('Filters must be an array');
        // }
        //
        // if (!Number.isInteger(frame)) {
        //     throw new ArgumentError('The frame argument must be an integer');
        // }
        //
        // if (frame < this.startframe || frame > this.stopframe) {
        //     throw new ArgumentError(`frame ${frame} does not exist in the job`);
        // }

        const annotationsData = await getAnnotations(this, frame, datasetId, fileSeq, allTracks, filters);
        const deletedFrames = await getDeletedFrames('module', this.targetModuleId);    // id -> targetModuleId
        if (frame in deletedFrames) {
            return [];
        }

        return annotationsData;
    };

    Module.prototype.annotations.search.implementation = function (filters, frameFrom, frameTo) {
        if (!Array.isArray(filters)) {
            throw new ArgumentError('Filters must be an array');
        }

        if (!Number.isInteger(frameFrom) || !Number.isInteger(frameTo)) {
            throw new ArgumentError('The start and end frames both must be an integer');
        }

        if (frameFrom < this.startframe || frameFrom > this.stopframe) {
            throw new ArgumentError('The start frame is out of the job');
        }

        if (frameTo < this.startframe || frameTo > this.stopframe) {
            throw new ArgumentError('The stop frame is out of the job');
        }

        const result = searchAnnotations(this, filters, frameFrom, frameTo);
        return result;
    };

    Module.prototype.annotations.searchEmpty.implementation = function (frameFrom, frameTo) {
        if (!Number.isInteger(frameFrom) || !Number.isInteger(frameTo)) {
            throw new ArgumentError('The start and end frames both must be an integer');
        }

        if (frameFrom < this.startframe || frameFrom > this.stopframe) {
            throw new ArgumentError('The start frame is out of the job');
        }

        if (frameTo < this.startframe || frameTo > this.stopframe) {
            throw new ArgumentError('The stop frame is out of the job');
        }

        const result = searchEmptyFrame(this, frameFrom, frameTo);
        return result;
    };

    Module.prototype.annotations.save.implementation = async function (onUpdate) {
        const result = await saveAnnotations(this, onUpdate);
        return result;
    };

    Module.prototype.annotations.merge.implementation = async function (objectStates) {
        const result = await mergeAnnotations(this, objectStates);
        return result;
    };

    Module.prototype.annotations.split.implementation = async function (objectState, frame) {
        const result = await splitAnnotations(this, objectState, frame);
        return result;
    };

    Module.prototype.annotations.group.implementation = async function (objectStates, reset) {
        const result = await groupAnnotations(this, objectStates, reset);
        return result;
    };

    Module.prototype.annotations.hasUnsavedChanges.implementation = function () {
        const result = hasUnsavedChanges(this);
        return result;
    };

    Module.prototype.annotations.clear.implementation = async function (
        reload, startframe, endframe, reviewData, delTrackKeyframesOnly,
    ) {
        const result = await clearAnnotations(this, reload, startframe, endframe, reviewData, delTrackKeyframesOnly);
        return result;
    };

    Module.prototype.annotations.select.implementation = function (frame, x, y) {
        const result = selectObject(this, frame, x, y);
        return result;
    };

    Module.prototype.annotations.statistics.implementation = function (review) {
        const result = annotationsStatistics(this, review);
        return result;
    };

    Module.prototype.annotations.put.implementation = function (objectStates) {
        const result = putAnnotations(this, objectStates);
        return result;
    };

    Module.prototype.annotations.upload.implementation = async function (
        format: string,
        useDefaultLocation: boolean,
        sourceStorage: Storage,
        file: File | string,
        options?: { convMaskToPoly?: boolean },
    ) {
        const result = await importDataset(this, format, useDefaultLocation, sourceStorage, file, options);
        return result;
    };

    Module.prototype.annotations.importFromModel.implementation = function (label, data){
        const result = importFromModel(this, label, data);
        return result;
    }

    Module.prototype.annotations.import.implementation = function (data) {
        const result = importCollection(this, data);
        return result;
    };

    Module.prototype.annotations.export.implementation = function () {
        const result = exportCollection(this);
        return result;
    };

    Module.prototype.annotations.exportDataset.implementation = async function (
        format: string,
        saveImages: boolean,
        useDefaultSettings: boolean,
        frameStorage: Storage,
        customName?: string,
    ) {
        const result = await exportDataset(this, format, saveImages, useDefaultSettings, frameStorage, customName);
        return result;
    };

    Module.prototype.actions.undo.implementation = async function (count) {
        const result = await undoActions(this, count);
        return result;
    };

    Module.prototype.actions.redo.implementation = async function (count) {
        const result = await redoActions(this, count);
        return result;
    };

    Module.prototype.actions.freeze.implementation = function (frozen) {
        const result = freezeHistory(this, frozen);
        return result;
    };

    Module.prototype.actions.clear.implementation = function () {
        const result = clearActions(this);
        return result;
    };

    Module.prototype.actions.get.implementation = function () {
        const result = getActions(this);
        return result;
    };

    Module.prototype.logger.log.implementation = async function (logType, payload, wait) {
        const result = await loggerStorage.log(
            logType,
            {
                ...payload,
                project_id: this.projectId,
                task_id: this.taskId,
                job_id: this.id,
            },
            wait,
        );
        return result;
    };

    Module.prototype.predictor.status.implementation = async function () {
        if (!Number.isInteger(this.projectId)) {
            throw new DataError('The job must belong to a project to use the feature');
        }

        const result = await serverProxy.predictor.status(this.projectId);
        return {
            message: result.message,
            progress: result.progress,
            projectScore: result.score,
            timeRemaining: result.time_remaining,
            mediaAmount: result.media_amount,
            annotationAmount: result.annotation_amount,
        };
    };

    Module.prototype.predictor.predict.implementation = async function (frame) {
        if (!Number.isInteger(frame) || frame < 0) {
            throw new ArgumentError(`frame must be a positive integer. Got: "${frame}"`);
        }

        if (frame < this.startframe || frame > this.stopframe) {
            throw new ArgumentError(`The frame with number ${frame} is out of the job`);
        }

        if (!Number.isInteger(this.projectId)) {
            throw new DataError('The job must belong to a project to use the feature');
        }

        const result = await serverProxy.predictor.predict(this.taskId, frame);
        return result;
    };

    Module.prototype.close.implementation = function closeTask() {
        clearFrames(this.id);
        clearCache(this);
        return this;
    };

    return Module;
}

export function implementJob(Job) {
    Job.prototype.save.implementation = async function () {
        if (this.id) {
            const jobData = this._updateTrigger.getUpdated(this);
            if (jobData.assignee) {
                jobData.assignee = jobData.assignee.id;
            }

            const data = await serverProxy.jobs.save(this.id, jobData);
            this._updateTrigger.reset();
            return new Job(data);
        }

        throw new ArgumentError('Could not save job without id');
    };

    Job.prototype.issues.implementation = async function () {
        const result = await serverProxy.issues.get(this.id);
        return result.map((issue) => new Issue(issue));
    };

    Job.prototype.openIssue.implementation = async function (issue, message) {
        checkObjectType('issue', issue, null, Issue);
        checkObjectType('message', message, 'string');
        const result = await serverProxy.issues.create({
            ...issue.serialize(),
            message,
        });
        return new Issue(result);
    };

    Job.prototype.frames.get.implementation = async function (frame, isPlaying, step) {
        if (!Number.isInteger(frame) || frame < 0) {
            throw new ArgumentError(`frame must be a positive integer. Got: "${frame}"`);
        }

        if (frame < this.startframe || frame > this.stopframe) {
            throw new ArgumentError(`The frame with number ${frame} is out of the job`);
        }

        const frameData = await getFrame(
            this.id,
            this.dataChunkSize,
            this.dataChunkType,
            this.mode,
            frame,
            this.startframe,
            this.stopframe,
            isPlaying,
            step,
            this.dimension,
        );
        return frameData;
    };

    Job.prototype.frames.delete.implementation = async function (frame) {
        if (!Number.isInteger(frame)) {
            throw new Error(`frame must be an integer. Got: "${frame}"`);
        }

        if (frame < this.startframe || frame > this.stopframe) {
            throw new Error('The frame is out of the job');
        }

        await deleteFrameWrapper.call(this, this.id, frame);
    };

    Job.prototype.frames.restore.implementation = async function (frame) {
        if (!Number.isInteger(frame)) {
            throw new Error(`frame must be an integer. Got: "${frame}"`);
        }

        if (frame < this.startframe || frame > this.stopframe) {
            throw new Error('The frame is out of the job');
        }

        await restoreFrameWrapper.call(this, this.id, frame);
    };

    Job.prototype.frames.save.implementation = async function () {
        const result = await patchMeta(this.id);
        return result;
    };

    Job.prototype.frames.ranges.implementation = async function () {
        const rangesData = await getRanges(this.id);
        return rangesData;
    };

    Job.prototype.frames.preview.implementation = async function () {
        if (this.id === null || this.taskId === null) {
            return '';
        }

        const preview = await serverProxy.jobs.getPreview(this.id);
        const decoded = await decodePreview(preview);
        return decoded;
    };

    Job.prototype.frames.contextImage.implementation = async function (frameId) {
        const result = await getContextImage(this.id, frameId);
        return result;
    };

    Job.prototype.frames.search.implementation = async function (filters, frameFrom, frameTo) {
        if (typeof filters !== 'object') {
            throw new ArgumentError('Filters should be an object');
        }

        if (!Number.isInteger(frameFrom) || !Number.isInteger(frameTo)) {
            throw new ArgumentError('The start and end frames both must be an integer');
        }

        if (frameFrom < this.startframe || frameFrom > this.stopframe) {
            throw new ArgumentError('The start frame is out of the job');
        }

        if (frameTo < this.startframe || frameTo > this.stopframe) {
            throw new ArgumentError('The stop frame is out of the job');
        }
        if (filters.notDeleted) {
            return findNotDeletedFrame(this.id, frameFrom, frameTo, filters.offset || 1);
        }
        return null;
    };

    // TODO: Check filter for annotations
    Job.prototype.annotations.get.implementation = async function (frame, allTracks, filters) {
        if (!Array.isArray(filters)) {
            throw new ArgumentError('Filters must be an array');
        }

        if (!Number.isInteger(frame)) {
            throw new ArgumentError('The frame argument must be an integer');
        }

        if (frame < this.startframe || frame > this.stopframe) {
            throw new ArgumentError(`frame ${frame} does not exist in the job`);
        }

        const annotationsData = await getAnnotations(this, frame, allTracks, filters);
        const deletedframes = await getDeletedframes('job', this.id);
        if (frame in deletedframes) {
            return [];
        }

        return annotationsData;
    };

    Job.prototype.annotations.search.implementation = function (filters, frameFrom, frameTo) {
        if (!Array.isArray(filters)) {
            throw new ArgumentError('Filters must be an array');
        }

        if (!Number.isInteger(frameFrom) || !Number.isInteger(frameTo)) {
            throw new ArgumentError('The start and end frames both must be an integer');
        }

        if (frameFrom < this.startframe || frameFrom > this.stopframe) {
            throw new ArgumentError('The start frame is out of the job');
        }

        if (frameTo < this.startframe || frameTo > this.stopframe) {
            throw new ArgumentError('The stop frame is out of the job');
        }

        const result = searchAnnotations(this, filters, frameFrom, frameTo);
        return result;
    };

    Job.prototype.annotations.searchEmpty.implementation = function (frameFrom, frameTo) {
        if (!Number.isInteger(frameFrom) || !Number.isInteger(frameTo)) {
            throw new ArgumentError('The start and end frames both must be an integer');
        }

        if (frameFrom < this.startframe || frameFrom > this.stopframe) {
            throw new ArgumentError('The start frame is out of the job');
        }

        if (frameTo < this.startframe || frameTo > this.stopframe) {
            throw new ArgumentError('The stop frame is out of the job');
        }

        const result = searchEmptyFrame(this, frameFrom, frameTo);
        return result;
    };

    Job.prototype.annotations.save.implementation = async function (onUpdate) {
        const result = await saveAnnotations(this, onUpdate);
        return result;
    };

    Job.prototype.annotations.merge.implementation = async function (objectStates) {
        const result = await mergeAnnotations(this, objectStates);
        return result;
    };

    Job.prototype.annotations.split.implementation = async function (objectState, frame) {
        const result = await splitAnnotations(this, objectState, frame);
        return result;
    };

    Job.prototype.annotations.group.implementation = async function (objectStates, reset) {
        const result = await groupAnnotations(this, objectStates, reset);
        return result;
    };

    Job.prototype.annotations.hasUnsavedChanges.implementation = function () {
        const result = hasUnsavedChanges(this);
        return result;
    };

    Job.prototype.annotations.clear.implementation = async function (
        reload, startframe, endframe, delTrackKeyframesOnly,
    ) {
        const result = await clearAnnotations(this, reload, startframe, endframe, delTrackKeyframesOnly);
        return result;
    };

    Job.prototype.annotations.select.implementation = function (frame, x, y) {
        const result = selectObject(this, frame, x, y);
        return result;
    };

    Job.prototype.annotations.statistics.implementation = function () {
        const result = annotationsStatistics(this);
        return result;
    };

    Job.prototype.annotations.put.implementation = function (objectStates) {
        const result = putAnnotations(this, objectStates);
        return result;
    };

    Job.prototype.annotations.upload.implementation = async function (
        format: string,
        useDefaultLocation: boolean,
        sourceStorage: Storage,
        file: File | string,
        options?: { convMaskToPoly?: boolean },
    ) {
        const result = await importDataset(this, format, useDefaultLocation, sourceStorage, file, options);
        return result;
    };

    Job.prototype.annotations.import.implementation = function (data) {
        const result = importCollection(this, data);
        return result;
    };

    Job.prototype.annotations.export.implementation = function () {
        const result = exportCollection(this);
        return result;
    };

    Job.prototype.annotations.exportDataset.implementation = async function (
        format: string,
        saveImages: boolean,
        useDefaultSettings: boolean,
        frameStorage: Storage,
        customName?: string,
    ) {
        const result = await exportDataset(this, format, saveImages, useDefaultSettings, frameStorage, customName);
        return result;
    };

    Job.prototype.actions.undo.implementation = async function (count) {
        const result = await undoActions(this, count);
        return result;
    };

    Job.prototype.actions.redo.implementation = async function (count) {
        const result = await redoActions(this, count);
        return result;
    };

    Job.prototype.actions.freeze.implementation = function (frozen) {
        const result = freezeHistory(this, frozen);
        return result;
    };

    Job.prototype.actions.clear.implementation = function () {
        const result = clearActions(this);
        return result;
    };

    Job.prototype.actions.get.implementation = function () {
        const result = getActions(this);
        return result;
    };

    Job.prototype.logger.log.implementation = async function (logType, payload, wait) {
        const result = await loggerStorage.log(
            logType,
            {
                ...payload,
                project_id: this.projectId,
                task_id: this.taskId,
                job_id: this.id,
            },
            wait,
        );
        return result;
    };

    Job.prototype.predictor.status.implementation = async function () {
        if (!Number.isInteger(this.projectId)) {
            throw new DataError('The job must belong to a project to use the feature');
        }

        const result = await serverProxy.predictor.status(this.projectId);
        return {
            message: result.message,
            progress: result.progress,
            projectScore: result.score,
            timeRemaining: result.time_remaining,
            mediaAmount: result.media_amount,
            annotationAmount: result.annotation_amount,
        };
    };

    Job.prototype.predictor.predict.implementation = async function (frame) {
        if (!Number.isInteger(frame) || frame < 0) {
            throw new ArgumentError(`frame must be a positive integer. Got: "${frame}"`);
        }

        if (frame < this.startframe || frame > this.stopframe) {
            throw new ArgumentError(`The frame with number ${frame} is out of the job`);
        }

        if (!Number.isInteger(this.projectId)) {
            throw new DataError('The job must belong to a project to use the feature');
        }

        const result = await serverProxy.predictor.predict(this.taskId, frame);
        return result;
    };

    Job.prototype.close.implementation = function closeTask() {
        clearFrames(this.id);
        clearCache(this);
        return this;
    };

    return Job;
}

export function implementTask(Task) {
    Task.prototype.close.implementation = function closeTask() {
        for (const job of this.jobs) {
            clearFrames(job.id);
            clearCache(job);
        }

        clearCache(this);
        return this;
    };

    Task.prototype.save.implementation = async function (onUpdate) {
        // TODO: Add ability to change an owner and an assignee
        if (typeof this.id !== 'undefined') {
            // If the task has been already created, we update it
            const taskData = this._updateTrigger.getUpdated(this, {
                bugTracker: 'bug_tracker',
                projectId: 'project_id',
                assignee: 'assignee_id',
            });
            if (taskData.assignee_id) {
                taskData.assignee_id = taskData.assignee_id.id;
            }
            if (taskData.labels) {
                taskData.labels = this._internalData.labels;
                taskData.labels = taskData.labels.map((el) => el.toJSON());
            }

            const data = await serverProxy.tasks.save(this.id, taskData);
            // Temporary workaround for UI
            const jobs = await serverProxy.jobs.get({
                filter: JSON.stringify({ and: [{ '==': [{ var: 'task_id' }, data.id] }] }),
            }, true);
            this._updateTrigger.reset();
            return new Task({ ...data, jobs: jobs.results });
        }

        const taskSpec: any = {
            name: this.name,
            labels: this.labels.map((el) => el.toJSON()),
        };

        if (typeof this.bugTracker !== 'undefined') {
            taskSpec.bug_tracker = this.bugTracker;
        }
        if (typeof this.segmentSize !== 'undefined') {
            taskSpec.segment_size = this.segmentSize;
        }
        if (typeof this.overlap !== 'undefined') {
            taskSpec.overlap = this.overlap;
        }
        if (typeof this.projectId !== 'undefined') {
            taskSpec.project_id = this.projectId;
        }
        if (typeof this.subset !== 'undefined') {
            taskSpec.subset = this.subset;
        }

        if (this.frameStorage) {
            taskSpec.frame_storage = this.frameStorage.toJSON();
        }

        if (this.sourceStorage) {
            taskSpec.source_storage = this.sourceStorage.toJSON();
        }

        const taskDataSpec = {
            client_files: this.clientFiles,
            server_files: this.serverFiles,
            remote_files: this.remoteFiles,
            image_quality: this.imageQuality,
            use_zip_chunks: this.useZipChunks,
            use_cache: this.useCache,
            sorting_method: this.sortingMethod,
        };

        if (typeof this.startframe !== 'undefined') {
            taskDataSpec.start_frame = this.startframe;
        }
        if (typeof this.stopframe !== 'undefined') {
            taskDataSpec.stop_frame = this.stopframe;
        }
        if (typeof this.frameFilter !== 'undefined') {
            taskDataSpec.frame_filter = this.frameFilter;
        }
        if (typeof this.dataChunkSize !== 'undefined') {
            taskDataSpec.chunk_size = this.dataChunkSize;
        }
        if (typeof this.copyData !== 'undefined') {
            taskDataSpec.copy_data = this.copyData;
        }
        if (typeof this.cloudStorageId !== 'undefined') {
            taskDataSpec.cloud_storage_id = this.cloudStorageId;
        }

        const task = await serverProxy.tasks.create(taskSpec, taskDataSpec, onUpdate);
        // Temporary workaround for UI
        const jobs = await serverProxy.jobs.get({
            filter: JSON.stringify({ and: [{ '==': [{ var: 'task_id' }, task.id] }] }),
        }, true);
        return new Task({ ...task, jobs: jobs.results });
    };

    Task.prototype.delete.implementation = async function () {
        const result = await serverProxy.tasks.delete(this.id);
        return result;
    };

    Task.prototype.backup.implementation = async function (
        frameStorage: Storage,
        useDefaultSettings: boolean,
        fileName?: string,
    ) {
        const result = await serverProxy.tasks.backup(this.id, frameStorage, useDefaultSettings, fileName);
        return result;
    };

    Task.restore.implementation = async function (storage: Storage, file: File | string) {
        const result = await serverProxy.tasks.restore(storage, file);
        return result;
    };

    Task.prototype.frames.get.implementation = async function (frame, isPlaying, step) {
        if (!Number.isInteger(frame) || frame < 0) {
            throw new ArgumentError(`frame must be a positive integer. Got: "${frame}"`);
        }

        if (frame >= this.size) {
            throw new ArgumentError(`The frame with number ${frame} is out of the task`);
        }

        const job = this.jobs.filter((_job) => _job.startframe <= frame && _job.stopframe >= frame)[0];

        const result = await getframe(
            job.id,
            this.dataChunkSize,
            this.dataChunkType,
            this.mode,
            frame,
            job.startframe,
            job.stopframe,
            isPlaying,
            step,
        );
        return result;
    };

    Task.prototype.frames.ranges.implementation = async function () {
        const rangesData = {
            decoded: [],
            buffered: [],
        };
        for (const job of this.jobs) {
            const { decoded, buffered } = await getRanges(job.id);
            rangesData.decoded.push(decoded);
            rangesData.buffered.push(buffered);
        }
        return rangesData;
    };

    Task.prototype.frames.preview.implementation = async function () {
        if (this.id === null) {
            return '';
        }

        const preview = await serverProxy.tasks.getPreview(this.id);
        const decoded = await decodePreview(preview);
        return decoded;
    };

    Task.prototype.frames.delete.implementation = async function (frame) {
        if (!Number.isInteger(frame)) {
            throw new Error(`frame must be an integer. Got: "${frame}"`);
        }

        if (frame < 0 || frame >= this.size) {
            throw new Error('The frame is out of the task');
        }

        const job = this.jobs.filter((_job) => _job.startframe <= frame && _job.stopframe >= frame)[0];
        if (job) {
            await deleteFrameWrapper.call(this, job.id, frame);
        }
    };

    Task.prototype.frames.restore.implementation = async function (frame) {
        if (!Number.isInteger(frame)) {
            throw new Error(`frame must be an integer. Got: "${frame}"`);
        }

        if (frame < 0 || frame >= this.size) {
            throw new Error('The frame is out of the task');
        }

        const job = this.jobs.filter((_job) => _job.startframe <= frame && _job.stopframe >= frame)[0];
        if (job) {
            await restoreFrameWrapper.call(this, job.id, frame);
        }
    };

    Task.prototype.frames.save.implementation = async function () {
        return Promise.all(this.jobs.map((job) => patchMeta(job.id)));
    };

    Task.prototype.frames.search.implementation = async function (filters, frameFrom, frameTo) {
        if (typeof filters !== 'object') {
            throw new ArgumentError('Filters should be an object');
        }

        if (!Number.isInteger(frameFrom) || !Number.isInteger(frameTo)) {
            throw new ArgumentError('The start and end frames both must be an integer');
        }

        if (frameFrom < 0 || frameFrom > this.size) {
            throw new ArgumentError('The start frame is out of the task');
        }

        if (frameTo < 0 || frameTo > this.size) {
            throw new ArgumentError('The stop frame is out of the task');
        }

        const jobs = this.jobs.filter((_job) => (
            (frameFrom >= _job.startframe && frameFrom <= _job.stopframe) ||
            (frameTo >= _job.startframe && frameTo <= _job.stopframe) ||
            (frameFrom < _job.startframe && frameTo > _job.stopframe)
        ));

        if (filters.notDeleted) {
            for (const job of jobs) {
                const result = await findNotDeletedFrame(
                    job.id, Math.max(frameFrom, job.startframe), Math.min(frameTo, job.stopframe), 1,
                );

                if (result !== null) return result;
            }
        }

        return null;
    };

    // TODO: Check filter for annotations
    Task.prototype.annotations.get.implementation = async function (frame, allTracks, filters) {
        if (!Array.isArray(filters) || filters.some((filter) => typeof filter !== 'string')) {
            throw new ArgumentError('The filters argument must be an array of strings');
        }

        if (!Number.isInteger(frame) || frame < 0) {
            throw new ArgumentError(`frame must be a positive integer. Got: "${frame}"`);
        }

        if (frame >= this.size) {
            throw new ArgumentError(`frame ${frame} does not exist in the task`);
        }

        const result = await getAnnotations(this, frame, allTracks, filters);
        const deletedframes = await getDeletedframes('task', this.id);
        if (frame in deletedframes) {
            return [];
        }

        return result;
    };

    Task.prototype.annotations.search.implementation = function (filters, frameFrom, frameTo) {
        if (!Array.isArray(filters) || filters.some((filter) => typeof filter !== 'string')) {
            throw new ArgumentError('The filters argument must be an array of strings');
        }

        if (!Number.isInteger(frameFrom) || !Number.isInteger(frameTo)) {
            throw new ArgumentError('The start and end frames both must be an integer');
        }

        if (frameFrom < 0 || frameFrom >= this.size) {
            throw new ArgumentError('The start frame is out of the task');
        }

        if (frameTo < 0 || frameTo >= this.size) {
            throw new ArgumentError('The stop frame is out of the task');
        }

        const result = searchAnnotations(this, filters, frameFrom, frameTo);
        return result;
    };

    Task.prototype.annotations.searchEmpty.implementation = function (frameFrom, frameTo) {
        if (!Number.isInteger(frameFrom) || !Number.isInteger(frameTo)) {
            throw new ArgumentError('The start and end frames both must be an integer');
        }

        if (frameFrom < 0 || frameFrom >= this.size) {
            throw new ArgumentError('The start frame is out of the task');
        }

        if (frameTo < 0 || frameTo >= this.size) {
            throw new ArgumentError('The stop frame is out of the task');
        }

        const result = searchEmptyFrame(this, frameFrom, frameTo);
        return result;
    };

    Task.prototype.annotations.save.implementation = async function (onUpdate) {
        const result = await saveAnnotations(this, onUpdate);
        return result;
    };

    Task.prototype.annotations.merge.implementation = async function (objectStates) {
        const result = await mergeAnnotations(this, objectStates);
        return result;
    };

    Task.prototype.annotations.split.implementation = async function (objectState, frame) {
        const result = await splitAnnotations(this, objectState, frame);
        return result;
    };

    Task.prototype.annotations.group.implementation = async function (objectStates, reset) {
        const result = await groupAnnotations(this, objectStates, reset);
        return result;
    };

    Task.prototype.annotations.hasUnsavedChanges.implementation = function () {
        const result = hasUnsavedChanges(this);
        return result;
    };

    Task.prototype.annotations.clear.implementation = async function (reload) {
        const result = await clearAnnotations(this, reload);
        return result;
    };

    Task.prototype.annotations.select.implementation = function (frame, x, y) {
        const result = selectObject(this, frame, x, y);
        return result;
    };

    Task.prototype.annotations.statistics.implementation = function () {
        const result = annotationsStatistics(this);
        return result;
    };

    Task.prototype.annotations.put.implementation = function (objectStates) {
        const result = putAnnotations(this, objectStates);
        return result;
    };

    Task.prototype.annotations.upload.implementation = async function (
        format: string,
        useDefaultLocation: boolean,
        sourceStorage: Storage,
        file: File | string,
        options?: { convMaskToPoly?: boolean },
    ) {
        const result = await importDataset(this, format, useDefaultLocation, sourceStorage, file, options);
        return result;
    };

    Task.prototype.annotations.import.implementation = function (data) {
        const result = importCollection(this, data);
        return result;
    };

    Task.prototype.annotations.export.implementation = function () {
        const result = exportCollection(this);
        return result;
    };

    Task.prototype.annotations.exportDataset.implementation = async function (
        format: string,
        saveImages: boolean,
        useDefaultSettings: boolean,
        frameStorage: Storage,
        customName?: string,
    ) {
        const result = await exportDataset(this, format, saveImages, useDefaultSettings, frameStorage, customName);
        return result;
    };

    Task.prototype.actions.undo.implementation = function (count) {
        const result = undoActions(this, count);
        return result;
    };

    Task.prototype.actions.redo.implementation = function (count) {
        const result = redoActions(this, count);
        return result;
    };

    Task.prototype.actions.freeze.implementation = function (frozen) {
        const result = freezeHistory(this, frozen);
        return result;
    };

    Task.prototype.actions.clear.implementation = function () {
        const result = clearActions(this);
        return result;
    };

    Task.prototype.actions.get.implementation = function () {
        const result = getActions(this);
        return result;
    };

    Task.prototype.logger.log.implementation = async function (logType, payload, wait) {
        const result = await loggerStorage.log(
            logType,
            {
                ...payload,
                project_id: this.projectId,
                task_id: this.id,
            },
            wait,
        );
        return result;
    };

    Task.prototype.predictor.status.implementation = async function () {
        if (!Number.isInteger(this.projectId)) {
            throw new DataError('The task must belong to a project to use the feature');
        }

        const result = await serverProxy.predictor.status(this.projectId);
        return {
            message: result.message,
            progress: result.progress,
            projectScore: result.score,
            timeRemaining: result.time_remaining,
            mediaAmount: result.media_amount,
            annotationAmount: result.annotation_amount,
        };
    };

    Task.prototype.predictor.predict.implementation = async function (frame) {
        if (!Number.isInteger(frame) || frame < 0) {
            throw new ArgumentError(`frame must be a positive integer. Got: "${frame}"`);
        }

        if (frame >= this.size) {
            throw new ArgumentError(`The frame with number ${frame} is out of the task`);
        }

        if (!Number.isInteger(this.projectId)) {
            throw new DataError('The task must belong to a project to use the feature');
        }

        const result = await serverProxy.predictor.predict(this.id, frame);
        return result;
    };

    return Task;
}

export function implementWorkflow(Workflow) {
    Workflow.prototype.close.implementation = function closeWorkflow() {
        for (const job of this.jobs) {
            clearFrames(job.id);
            clearCache(job);
        }

        clearCache(this);
        return this;
    };

    Workflow.prototype.save.implementation = async function (onUpdate) {
        // TODO: Add ability to change an owner and an assignee
        if (typeof this.id !== 'undefined') {
            // If the Workflow has been already created, we update it
            const WorkflowData = this._updateTrigger.getUpdated(this, {
                bugTracker: 'bug_tracker',
                projectId: 'project_id',
                assignee: 'assignee_id',
            });
            if (WorkflowData.assignee_id) {
                WorkflowData.assignee_id = WorkflowData.assignee_id.id;
            }
            if (WorkflowData.labels) {
                WorkflowData.labels = this._internalData.labels;
                WorkflowData.labels = WorkflowData.labels.map((el) => el.toJSON());
            }

            const data = await serverProxy.Workflows.save(this.id, WorkflowData);
            // Temporary workaround for UI
            const jobs = await serverProxy.jobs.get({
                filter: JSON.stringify({ and: [{ '==': [{ var: 'Workflow_id' }, data.id] }] }),
            }, true);
            this._updateTrigger.reset();
            return new Workflow({ ...data, jobs: jobs.results });
        }

        const WorkflowSpec: any = {
            name: this.name,
            labels: this.labels.map((el) => el.toJSON()),
        };

        if (typeof this.bugTracker !== 'undefined') {
            WorkflowSpec.bug_tracker = this.bugTracker;
        }
        if (typeof this.segmentSize !== 'undefined') {
            WorkflowSpec.segment_size = this.segmentSize;
        }
        if (typeof this.overlap !== 'undefined') {
            WorkflowSpec.overlap = this.overlap;
        }
        if (typeof this.projectId !== 'undefined') {
            WorkflowSpec.project_id = this.projectId;
        }
        if (typeof this.subset !== 'undefined') {
            WorkflowSpec.subset = this.subset;
        }

        if (this.frameStorage) {
            WorkflowSpec.frame_storage = this.frameStorage.toJSON();
        }

        if (this.sourceStorage) {
            WorkflowSpec.source_storage = this.sourceStorage.toJSON();
        }

        const WorkflowDataSpec = {
            client_files: this.clientFiles,
            server_files: this.serverFiles,
            remote_files: this.remoteFiles,
            image_quality: this.imageQuality,
            use_zip_chunks: this.useZipChunks,
            use_cache: this.useCache,
            sorting_method: this.sortingMethod,
        };

        if (typeof this.startframe !== 'undefined') {
            WorkflowDataSpec.start_frame = this.startframe;
        }
        if (typeof this.stopframe !== 'undefined') {
            WorkflowDataSpec.stop_frame = this.stopframe;
        }
        if (typeof this.frameFilter !== 'undefined') {
            WorkflowDataSpec.frame_filter = this.frameFilter;
        }
        if (typeof this.dataChunkSize !== 'undefined') {
            WorkflowDataSpec.chunk_size = this.dataChunkSize;
        }
        if (typeof this.copyData !== 'undefined') {
            WorkflowDataSpec.copy_data = this.copyData;
        }
        if (typeof this.cloudStorageId !== 'undefined') {
            WorkflowDataSpec.cloud_storage_id = this.cloudStorageId;
        }

        const Workflow = await serverProxy.Workflows.create(WorkflowSpec, WorkflowDataSpec, onUpdate);
        // Temporary workaround for UI
        const jobs = await serverProxy.jobs.get({
            filter: JSON.stringify({ and: [{ '==': [{ var: 'Workflow_id' }, Workflow.id] }] }),
        }, true);
        return new Workflow({ ...Workflow, jobs: jobs.results });
    };

    Workflow.prototype.delete.implementation = async function () {
        const result = await serverProxy.Workflows.delete(this.id);
        return result;
    };

    Workflow.prototype.backup.implementation = async function (
        frameStorage: Storage,
        useDefaultSettings: boolean,
        fileName?: string,
    ) {
        const result = await serverProxy.Workflows.backup(this.id, frameStorage, useDefaultSettings, fileName);
        return result;
    };

    Workflow.restore.implementation = async function (storage: Storage, file: File | string) {
        const result = await serverProxy.Workflows.restore(storage, file);
        return result;
    };

    Workflow.prototype.frames.get.implementation = async function (frame, isPlaying, step) {
        if (!Number.isInteger(frame) || frame < 0) {
            throw new ArgumentError(`frame must be a positive integer. Got: "${frame}"`);
        }

        if (frame >= this.size) {
            throw new ArgumentError(`The frame with number ${frame} is out of the Workflow`);
        }

        const job = this.jobs.filter((_job) => _job.startframe <= frame && _job.stopframe >= frame)[0];

        const result = await getframe(
            job.id,
            this.dataChunkSize,
            this.dataChunkType,
            this.mode,
            frame,
            job.startframe,
            job.stopframe,
            isPlaying,
            step,
        );
        return result;
    };

    Workflow.prototype.frames.ranges.implementation = async function () {
        const rangesData = {
            decoded: [],
            buffered: [],
        };
        for (const job of this.jobs) {
            const { decoded, buffered } = await getRanges(job.id);
            rangesData.decoded.push(decoded);
            rangesData.buffered.push(buffered);
        }
        return rangesData;
    };

    Workflow.prototype.frames.preview.implementation = async function () {
        if (this.id === null) {
            return '';
        }

        const preview = await serverProxy.Workflows.getPreview(this.id);
        const decoded = await decodePreview(preview);
        return decoded;
    };

    Workflow.prototype.frames.delete.implementation = async function (frame) {
        if (!Number.isInteger(frame)) {
            throw new Error(`frame must be an integer. Got: "${frame}"`);
        }

        if (frame < 0 || frame >= this.size) {
            throw new Error('The frame is out of the Workflow');
        }

        const job = this.jobs.filter((_job) => _job.startframe <= frame && _job.stopframe >= frame)[0];
        if (job) {
            await deleteFrameWrapper.call(this, job.id, frame);
        }
    };

    Workflow.prototype.frames.restore.implementation = async function (frame) {
        if (!Number.isInteger(frame)) {
            throw new Error(`frame must be an integer. Got: "${frame}"`);
        }

        if (frame < 0 || frame >= this.size) {
            throw new Error('The frame is out of the Workflow');
        }

        const job = this.jobs.filter((_job) => _job.startframe <= frame && _job.stopframe >= frame)[0];
        if (job) {
            await restoreFrameWrapper.call(this, job.id, frame);
        }
    };

    Workflow.prototype.frames.save.implementation = async function () {
        return Promise.all(this.jobs.map((job) => patchMeta(job.id)));
    };

    Workflow.prototype.frames.search.implementation = async function (filters, frameFrom, frameTo) {
        if (typeof filters !== 'object') {
            throw new ArgumentError('Filters should be an object');
        }

        if (!Number.isInteger(frameFrom) || !Number.isInteger(frameTo)) {
            throw new ArgumentError('The start and end frames both must be an integer');
        }

        if (frameFrom < 0 || frameFrom > this.size) {
            throw new ArgumentError('The start frame is out of the Workflow');
        }

        if (frameTo < 0 || frameTo > this.size) {
            throw new ArgumentError('The stop frame is out of the Workflow');
        }

        const jobs = this.jobs.filter((_job) => (
            (frameFrom >= _job.startframe && frameFrom <= _job.stopframe) ||
            (frameTo >= _job.startframe && frameTo <= _job.stopframe) ||
            (frameFrom < _job.startframe && frameTo > _job.stopframe)
        ));

        if (filters.notDeleted) {
            for (const job of jobs) {
                const result = await findNotDeletedFrame(
                    job.id, Math.max(frameFrom, job.startframe), Math.min(frameTo, job.stopframe), 1,
                );

                if (result !== null) return result;
            }
        }

        return null;
    };

    // TODO: Check filter for annotations
    Workflow.prototype.annotations.get.implementation = async function (frame, allTracks, filters) {
        if (!Array.isArray(filters) || filters.some((filter) => typeof filter !== 'string')) {
            throw new ArgumentError('The filters argument must be an array of strings');
        }

        if (!Number.isInteger(frame) || frame < 0) {
            throw new ArgumentError(`frame must be a positive integer. Got: "${frame}"`);
        }

        if (frame >= this.size) {
            throw new ArgumentError(`frame ${frame} does not exist in the Workflow`);
        }

        const result = await getAnnotations(this, frame, allTracks, filters);
        const deletedframes = await getDeletedframes('Workflow', this.id);
        if (frame in deletedframes) {
            return [];
        }

        return result;
    };

    Workflow.prototype.annotations.search.implementation = function (filters, frameFrom, frameTo) {
        if (!Array.isArray(filters) || filters.some((filter) => typeof filter !== 'string')) {
            throw new ArgumentError('The filters argument must be an array of strings');
        }

        if (!Number.isInteger(frameFrom) || !Number.isInteger(frameTo)) {
            throw new ArgumentError('The start and end frames both must be an integer');
        }

        if (frameFrom < 0 || frameFrom >= this.size) {
            throw new ArgumentError('The start frame is out of the Workflow');
        }

        if (frameTo < 0 || frameTo >= this.size) {
            throw new ArgumentError('The stop frame is out of the Workflow');
        }

        const result = searchAnnotations(this, filters, frameFrom, frameTo);
        return result;
    };

    Workflow.prototype.annotations.searchEmpty.implementation = function (frameFrom, frameTo) {
        if (!Number.isInteger(frameFrom) || !Number.isInteger(frameTo)) {
            throw new ArgumentError('The start and end frames both must be an integer');
        }

        if (frameFrom < 0 || frameFrom >= this.size) {
            throw new ArgumentError('The start frame is out of the Workflow');
        }

        if (frameTo < 0 || frameTo >= this.size) {
            throw new ArgumentError('The stop frame is out of the Workflow');
        }

        const result = searchEmptyFrame(this, frameFrom, frameTo);
        return result;
    };

    Workflow.prototype.annotations.save.implementation = async function (onUpdate) {
        const result = await saveAnnotations(this, onUpdate);
        return result;
    };

    Workflow.prototype.annotations.merge.implementation = async function (objectStates) {
        const result = await mergeAnnotations(this, objectStates);
        return result;
    };

    Workflow.prototype.annotations.split.implementation = async function (objectState, frame) {
        const result = await splitAnnotations(this, objectState, frame);
        return result;
    };

    Workflow.prototype.annotations.group.implementation = async function (objectStates, reset) {
        const result = await groupAnnotations(this, objectStates, reset);
        return result;
    };

    Workflow.prototype.annotations.hasUnsavedChanges.implementation = function () {
        const result = hasUnsavedChanges(this);
        return result;
    };

    Workflow.prototype.annotations.clear.implementation = async function (reload) {
        const result = await clearAnnotations(this, reload);
        return result;
    };

    Workflow.prototype.annotations.select.implementation = function (frame, x, y) {
        const result = selectObject(this, frame, x, y);
        return result;
    };

    Workflow.prototype.annotations.statistics.implementation = function () {
        const result = annotationsStatistics(this);
        return result;
    };

    Workflow.prototype.annotations.put.implementation = function (objectStates) {
        const result = putAnnotations(this, objectStates);
        return result;
    };

    Workflow.prototype.annotations.upload.implementation = async function (
        format: string,
        useDefaultLocation: boolean,
        sourceStorage: Storage,
        file: File | string,
        options?: { convMaskToPoly?: boolean },
    ) {
        const result = await importDataset(this, format, useDefaultLocation, sourceStorage, file, options);
        return result;
    };

    Workflow.prototype.annotations.import.implementation = function (data) {
        const result = importCollection(this, data);
        return result;
    };

    Workflow.prototype.annotations.export.implementation = function () {
        const result = exportCollection(this);
        return result;
    };

    Workflow.prototype.annotations.exportDataset.implementation = async function (
        format: string,
        saveImages: boolean,
        useDefaultSettings: boolean,
        frameStorage: Storage,
        customName?: string,
    ) {
        const result = await exportDataset(this, format, saveImages, useDefaultSettings, frameStorage, customName);
        return result;
    };

    Workflow.prototype.actions.undo.implementation = function (count) {
        const result = undoActions(this, count);
        return result;
    };

    Workflow.prototype.actions.redo.implementation = function (count) {
        const result = redoActions(this, count);
        return result;
    };

    Workflow.prototype.actions.freeze.implementation = function (frozen) {
        const result = freezeHistory(this, frozen);
        return result;
    };

    Workflow.prototype.actions.clear.implementation = function () {
        const result = clearActions(this);
        return result;
    };

    Workflow.prototype.actions.get.implementation = function () {
        const result = getActions(this);
        return result;
    };

    Workflow.prototype.logger.log.implementation = async function (logType, payload, wait) {
        const result = await loggerStorage.log(
            logType,
            {
                ...payload,
                project_id: this.projectId,
                Workflow_id: this.id,
            },
            wait,
        );
        return result;
    };

    Workflow.prototype.predictor.status.implementation = async function () {
        if (!Number.isInteger(this.projectId)) {
            throw new DataError('The Workflow must belong to a project to use the feature');
        }

        const result = await serverProxy.predictor.status(this.projectId);
        return {
            message: result.message,
            progress: result.progress,
            projectScore: result.score,
            timeRemaining: result.time_remaining,
            mediaAmount: result.media_amount,
            annotationAmount: result.annotation_amount,
        };
    };

    Workflow.prototype.predictor.predict.implementation = async function (frame) {
        if (!Number.isInteger(frame) || frame < 0) {
            throw new ArgumentError(`frame must be a positive integer. Got: "${frame}"`);
        }

        if (frame >= this.size) {
            throw new ArgumentError(`The frame with number ${frame} is out of the Workflow`);
        }

        if (!Number.isInteger(this.projectId)) {
            throw new DataError('The Workflow must belong to a project to use the feature');
        }

        const result = await serverProxy.predictor.predict(this.id, frame);
        return result;
    };

    return Workflow;
}
