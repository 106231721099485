// @ts-nocheck
import React from 'react';

import {ActiveControl} from 'store/label';
import { Canvas } from 'pages/user/label/annotation/image/cvat-canvas-wrapper';
import {Button} from "reactstrap";

export interface Props {
    canvasInstance: Canvas;
    activeControl: ActiveControl;
}

function ResizeControl(props: Props): JSX.Element {
    const { activeControl, canvasInstance } = props;

    return (
        <Button color={"coco-ghost"}
                className="btn-icon waves-effect waves-light rounded-pill"
                id="clockwiseRotation"
                onClick={(): void => {
                    if (activeControl === ActiveControl.ZOOM_CANVAS) {
                        canvasInstance.zoomCanvas(false);
                    } else {
                        canvasInstance.cancel();
                        canvasInstance.zoomCanvas(true);
                    }
                }}
        >
            <i className="ri-zoom-in-line fs-20"></i>
        </Button>
    );
}

export default React.memo(ResizeControl);
