import React, { memo } from 'react';
import {Handle, useReactFlow, useStoreApi, Position, useStore, NodeToolbar} from 'reactflow';

function CrossReviewNode({ id, data, isConnectable }) {
    const targetHandleStyle = {visibility: 'hidden', zIndex:1};

    return (
        <div>
            <div className="d-flex align-items-center mb-12">
                <div className="flex-shrink-0 avatar-42">
                    <div className="avatar-title fs-20 rounded-circle" style={{backgroundColor:'#DFF3EF', color:'#0BAD8F'}}>
                        <i className="ri-pen-nib-line lh-19"></i>
                    </div>
                </div>
                <div className="flex-grow-1 text-overflow ml-13">
                    <h6 className="text-coco-gray-200 tx-12 lh-16 mb-2">TASK</h6>
                    <span className="text-coco-gray-700 tx-16 lh-20 fw-semibold">{data.moduleNm}</span>
                </div>
            </div>
            <div className="bg-coco-gray-50 pd-10 node_info flex-shrink-0 mb-12">
                <div className="ht-20">
                    <label>오픈 일자</label>
                    2023.01.01
                </div>
                <div className="ht-20 mt-6">
                    <label>작업자</label>
                    20대 여성 (+2 조건)
                </div>
                <div className="ht-20 mt-6 d-flex align-items-center">
                    <label>도구</label>
                    <div className="d-flex align-items-center">
                        <div className="avatar-xxxs rounded-circle" style={{backgroundColor:'#82B1FF', color:'#ffffff'}}>
                            <i className="ri-pen-nib-line"></i>
                        </div>
                        <div className="avatar-xxxs rounded-circle" style={{backgroundColor:'#F28067', color:'#ffffff'}}>
                            <i className="ri-pen-nib-line"></i>
                        </div>
                    </div>
                </div>
            </div>
            <Handle
                className="targetHandle"
                onConnect={(params) => console.log('handle onConnect', params)}
                style={targetHandleStyle}
                position={Position.Left}
                id={id.concat('_Left')}
                type="target"
                isConnectable={isConnectable}
            />
            <Handle
                className="targetHandle"
                onConnect={(params) => console.log('handle onConnect', params)}
                style={targetHandleStyle}
                position={Position.Bottom}
                id={id.concat('_bottom')}
                type="target"
                isConnectable={isConnectable}
            />
        </div>
    );
}

export default memo(CrossReviewNode);