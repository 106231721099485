import React, {useEffect, useState} from 'react';
import {Cache} from "three";
import {GetMessageIntl} from "../../../../../../../util/message";
import {useIntl} from "react-intl";

interface Props {
    row: number;
    placeholder: string;
    rules?: any;
    maxString?: number;
    disabled?: boolean
    hookform: any;
    keyName: string;
    shape?:any;
    review?:any;
    modified?: boolean;
}
export const QuestionView  = ({row, placeholder, maxString, disabled=false, rules, hookform, keyName, shape=null, review=null, modified}: Props) => {

    const {register, setValue, getValues, watch} = hookform;
    const [count, setCount] = useState(0);
    const reject = review !=null? review?.reject : null;
    const [update, setUpdate] = useState(!!modified);
    const intl = useIntl();

    disabled = reject != null? reject == false? true: update : false;
    const valueWatch = watch(keyName, '');

    useEffect(() => {
        if (typeof modified === "boolean") {
            setUpdate(modified);
        }
    }, [modified])

    useEffect(() => {
        setCount(valueWatch.length)
    }, [valueWatch]);

    useEffect(()=>{
        setUpdate(!!modified);
    },[modified]);

    const onInputHandler = (e, maxString) => {
        const inputValue = e.target.value.trimStart();
        if (inputValue.length > maxString) {
            const truncatedValue = inputValue.slice(0, maxString);
            setValue(keyName, truncatedValue, {shouldValidate: true});
        }else{
            setValue(keyName, inputValue, {shouldValidate: true});
        }
        setCount(inputValue.length);
    };

    const updateText = ()=>{
        setValue(`modified.${shape.id}`, !update, {shouldValidate: hookform.formState.isSubmitted});
        setUpdate(!update);
    }

    return (
        <div>
            {/*<Controller
                    name={keyName}
                    control={hookform?.control}
                    rules={rules}
                    render={({ field }) => {
                        return (
                            <Input
                                {...field}
                                type="textarea"
                                placeholder={placeholder}
                                rows={row}
                                disabled={disabled}
                                maxLength={maxString}
                                onChange={(event)=>onInputHandler(event)}
                                onKeyDown={(event) => {
                                    if (event.keyCode === 32) {
                                        event.stopPropagation();
                                    }
                                }}
                            />
                        )
                    }}
            />*/}
            <textarea
                className={"form-control"}
                {...register(keyName, rules)}
                placeholder={placeholder}
                rows={row}
                disabled={disabled}
                maxLength={maxString}
                onChange={(event)=>{
                    const newValue = event.target.value.trimStart();
                    if(newValue.length>maxString){
                        setValue(keyName, newValue.substring(0, maxString));
                        return;
                    }

                    onInputHandler(event, maxString)
                }}
                onKeyDown={(event) => {
                    // 영상 실행, 중단 이벤트와 텍스트 입력 이벤트 중복 막기
                    // if(event.keyCode === 32){
                    //     event.stopPropagation();
                    // }
                }}
            />
            <div className="d-flex justify-content-between">
                <div></div>
                {maxString && <div className="text-coco-gray-400">{count}/{maxString}자</div>}
            </div>
            {
                reject !=null && reject ?
                    <div className="d-flex justify-content-end mt-24">
                        <button type="button" className="btn btn-coco-ghost-1 fw-500" onClick={()=>{
                            updateText();
                        }}>
                            {update === true? GetMessageIntl(intl, "ui.U00013"): GetMessageIntl(intl, "label.modifyCompleted")}
                        </button>
                        {/*<input type={"hidden"} {...register(keyName+`.update`, {*/}
                        {/*    validate: {*/}
                        {/*        isBoolean: (value) => CommonUtil.checkType(value, 'boolean') || GetMessageIntl(intl, 'review.reviewValidationError')*/}
                        {/*    }*/}
                        {/*})}  defaultValue={update}/>*/}
                    </div>
                    :
                    <></>
            }
        </div>
    );
}
