import React, {createRef, useEffect, useState} from 'react';
import {
    Card,
    CardHeader,
    Col,
    Container,
    Form,
    Input,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row
} from "reactstrap";
import Select from "react-select";
import {defaultSelectStyle, formatGroupLabelNoCount} from "../../../../components/constants/select";
import {GetMessage, GetMessageIntl} from "../../../../util/message";
import {useIntl} from "react-intl";
import laflowLogo from "../../../../assets/images/laflow_logo_01.png";
import {setTaskRefreshFlag, setTaskTestModalFlag} from "../../../../store/user/task/action";
import ConnectionLine from "./components/edges/ConnectionLine";
import ReactFlow, {
    Background,
    ControlButton,
    Controls,
    MarkerType,
    MiniMap,
    useEdgesState,
    useNodesState, useReactFlow
} from "reactflow";
import validList from "../../../../assets/images/vaild-list.png";
import DatasetNode from "./components/nodes/DatasetNode";
import workflowNode from "./components/nodes/WorkFlowNode";
import LabelNode from "./components/nodes/LabelNode";
import AutoLabelNode from "./components/nodes/AutoLabelNode";
import ReviewNode from "./components/nodes/ReviewNode";
import CrossReviewNode from "./components/nodes/CrossReviewNode";
import ManagerReviewNode from "./components/nodes/ManagerReviewNode";
import SDQNode from "./components/nodes/SDQNode";
import GroupNode from "./components/nodes/GroupNode";
import AcceptNode from "./components/nodes/sub/AcceptNode";
import FinishNode from "./components/nodes/FinishNode";
import UnabledNode from "./components/nodes/UnabledNode";
import SubUnabledNode from "./components/nodes/sub/SubUnabledNode";
import RestoreNode from "./components/nodes/sub/RestoreNode";
import SubmitNode from "./components/nodes/sub/SubmitNode";
import SkipNode from "./components/nodes/sub/SkipNode";
import LabeledNode from "./components/nodes/sub/LabeledNode";
import ApprovalNode from "./components/nodes/sub/ApprovalNode";
import RejectNode from "./components/nodes/sub/RejectNode";
import AmbiguityNode from "./components/nodes/sub/AmbiguityNode";
import StateChangeNode from "./components/nodes/sub/StateChangeNode";
import UpdateSubmitNode from "./components/nodes/sub/UpdateSubmitNode";
import FloatingEdge from "./components/edges/FloatingEdge";
import ConnetionLine from './components/edges/ConnectionLine';
import {Controller, useForm} from "react-hook-form";
import {v4 as uuidv4} from "uuid";
import {getLabelGroupNode, getManagerReviewGroupNode, getReviewGroupNode} from "./components/nodes/nodeGroupUtil";
import {confirm} from "../../../../util/AlertUtil";
import {ManagerWorkflowService} from "../../../../apis/api/ManagerWorkflowService";

const nodeTypes = {
    dataset: DatasetNode,
    workflow: workflowNode,
    label: LabelNode,
    autoLabel: AutoLabelNode,
    review: ReviewNode,
    crossReview: CrossReviewNode,
    managerReview: ManagerReviewNode,
    sdq4ai: SDQNode,
    module: GroupNode,
    accept: AcceptNode,
    finish: FinishNode,
    unabled: UnabledNode,
    subUnabled: SubUnabledNode,
    restore: RestoreNode,
    submit: SubmitNode,
    skip: SkipNode,
    labeled: LabeledNode,
    approval: ApprovalNode,
    reject: RejectNode,
    ambiguity: AmbiguityNode,
    stateChange: StateChangeNode,
    updateSubmit: UpdateSubmitNode
};

const moduleTypes = {
    dataset: '03',
    workflow: '',
    label: '01',
    autoLabel: '',
    review: '02',
    crossReview: '',
    managerReview: '04',
    sdq4ai: '',
    finish: '11',
    unabled: '12'
};

const edgeTypes = {
    floating: FloatingEdge,
    //smart: SmartStepEdge,
};

const connectionLineStyle = {
    strokeWidth: 3,
    stroke: 'black',
};

const defaultEdgeOptions = {
    style: {strokeWidth: 2, stroke: '#0bad8f'},
    type: 'smoothstep',
    markerEnd: {
        type: MarkerType.ArrowClosed,
        color: '#0bad8f',
    },
    /*markerStart:'edge-marker-start',*/
    zIndex: 9
};

function ManageWorkflowModal(props) {
    const intl = useIntl();
    const [modal, setModal] = useState(props.modalFlag);
    const [nodes, setNodes, onNodesChange] = useNodesState([]);
    const [edges, setEdges, onEdgesChange] = useEdgesState([]);
    // DB에 저장되어야할 module들

    const [moduleItems, setModuleItems] = useState([]);
    const [labelTargetModule, setLabelTargetModule] = useState({});
    const [reactFlowInstance, setReactFlowInstance] = useState(null);
    const [testDisabled, setTestDisabled] = useState(true);

    const [reviewOptions, setReviewOptions] = useState([
        { value: '1', label: GetMessageIntl(intl,"workflow.reviewStep1") }
        , { value: '2', label: GetMessageIntl(intl,"workflow.reviewStep2") }
        , { value: '3', label: GetMessageIntl(intl,"workflow.reviewStep3") }
        , { value: '4', label: GetMessageIntl(intl,"workflow.reviewStep4") }]);

    const typeMessage = {
        dataset: GetMessage("workflow.dataset"),
        workflow: GetMessage("workflow.workflow"),
        label: GetMessage("workflow.label"),
        autoLabel: GetMessage("workflow.autoLabel"),
        review: GetMessage("workflow.review"),
        crossReview: GetMessage("workflow.crossReview"),
        managerReview: GetMessage("workflow.managerReview"),
        sdq4ai: GetMessage("workflow.sdq4ai"),
    }

    const methods = useForm({
        criteriaMode: "all",
        defaultValues: {}
    });

    const { control, register, setValue, getValues, trigger, reset, resetField, watch, setError, formState: { errors }, handleSubmit } = methods;
    const watchDataTy= watch('dataTy');
    const watchCanvasTy= watch('canvasTy');
    const watchReviewNTy= watch('reviewNTy');
    const watchTestRequired= watch('testRequired');

    const setSwitchModalFlag = ()=>{
        props.switchModalFlag(false)
        reset();
    }

    useEffect(()=>{
        if(watchDataTy&&watchDataTy != '1'){
            setValue('canvasTy', '2');
            // if(watchCanvasTy && watchCanvasTy == '1'){
            //     setValue('canvasTy', '2');
            // }
            setValue('testRequired',false);
            setTestDisabled(true);
        }else if(watchDataTy&&watchDataTy == '1'){
            if(watchCanvasTy&& watchCanvasTy == '1'){
                setTestDisabled(false);
            }else{
                setValue('testRequired',false);
                setTestDisabled(true);
            }

        }
    },[watchDataTy, watchCanvasTy])

    useEffect(()=>{
        // 핏 해주기
        if(reactFlowInstance){
            reactFlowInstance.fitView()
        }
    }, [nodes]);

    useEffect(()=>{
        setNodes([]); // 초기화
        setEdges([]); // 초기화

        // 만들자 node와 edge!
        let arrayNodes = [];
        let modules = [];

        let positionX = 100;
        let positionY = 300;

        if(watchDataTy && watchCanvasTy && watchReviewNTy){
            modules.push(makeModuleItem('dataset', 1, positionX, positionY));

            positionX = positionX+290+70;
            modules.push(makeModuleItem('label', 1, positionX, positionY));

            // datasetId, label edge  && db updateModuleUseDataset
            let datasetModuleId = modules[0].moduleId;
            let datasetId = JSON.parse(modules[0].moduleConfig).datasetId;
            let labelModuleId = modules[1].moduleId;
            setEdges((edge) =>  edge.concat(makeEdgeConnect(datasetModuleId , labelModuleId, 'right', 'left')));

            // updateLabelTargetModule(targetNode.data.moduleId, JSON.parse(sourceNode.data.moduleConfig).datasetId, 'I');
            setLabelTargetModule({datasetId: datasetId, moduleId: labelModuleId});

            let managerReviewModule = makeModuleItem('managerReview', 1, positionX, positionY-400);
            // label-skip, managerReview edge
            setEdges((edge) =>  edge.concat(makeEdgeConnect(labelModuleId.concat("_01") , managerReviewModule.moduleId, 'right', 'bottom')));
            // managerReview-restore, dataset edge
            setEdges((edge) =>  edge.concat(makeEdgeConnect(managerReviewModule.moduleId.concat("_01") , datasetModuleId, 'left', 'top')));

            if(watchReviewNTy){
                for(let i=1; i<=watchReviewNTy;i++){
                    positionX = positionX+290+70;
                    modules.push(makeModuleItem('review', i, positionX, positionY));
                }
            }

            let reviewItem = modules.filter((module)=>
            {
                return module.moduleTy == '02';
            });

            reviewItem.forEach((reivew, index)=>{
                if(index==0){
                    // label submit, review edge
                    setEdges((edge) =>  edge.concat(makeEdgeConnect(labelModuleId.concat("_02") , reviewItem[index].moduleId, 'right', 'left')));
                }else{
                    // review-approval, review edge
                    setEdges((edge) =>  edge.concat(makeEdgeConnect(reviewItem[index-1].moduleId.concat("_01") , reviewItem[index].moduleId, 'right', 'left')));
                }

                // review reject - label
                setEdges((edge) =>  edge.concat(makeEdgeConnect(reviewItem[index].moduleId.concat("_02") , labelModuleId, 'left', 'bottom')));
            })

            positionX = positionX+290+70;

            let finishModule = makeModuleItem('finish', 1, positionX, positionY);

            // last review, finish edge
            setEdges((edge) =>  edge.concat(makeEdgeConnect(reviewItem[reviewItem.length-1].moduleId.concat("_01") , finishModule.moduleId, 'right', 'left')));

            // managerReview-subUnabled, finish edge
            setEdges((edge) =>  edge.concat(makeEdgeConnect(managerReviewModule.moduleId.concat("_02") , finishModule.moduleId, 'right', 'top')));
        }

        if(modules.length>0){
            setModuleItems(modules);
        }
    },[watchDataTy, watchCanvasTy, watchReviewNTy, watchTestRequired]);

    const makeModuleItem = (type, index, positionX, positionY) =>{
        let moduleNm = '';
        let moduleId = "MD".concat(uuidv4());
        let newNode = {};

        let moduleConfig = {};
        moduleNm = `Untitled_${typeMessage[type]}(${index})`;
        newNode.id = moduleId;
        newNode.type= type;
        newNode.position = { x: positionX, y: positionY };
        newNode.data = {};
        newNode.data.moduleNm = moduleNm;
        newNode.data.canvasDataTy = getValues('dataTy').concat(getValues('canvasTy'));

        if(type == 'dataset'){

            newNode.data.datasetId = 'DT'.concat(uuidv4());
            newNode.data.moduleConfig = {};
            newNode.data.moduleConfig['datasetId'] = newNode.data['datasetId'];
            newNode.data.moduleConfig = JSON.stringify(newNode.data.moduleConfig);

            moduleConfig.datasetId = newNode.data['datasetId'];
            setNodes((nds)=> nds.concat(newNode));
        }else if(type == 'review' || type== 'crossReview'){
            if(type == 'review'){
                // 기본적으로 들어가져야 할 내용: 작업자 설정
                moduleConfig.workerConfig = {"standardTy":"00"};
                moduleConfig.tabActive = {testConfig: watchTestRequired};
            }
            setNodes((nds)=> nds.concat(getReviewGroupNode(moduleId, type, newNode.position, newNode.data)));
        }else if (type === 'managerReview') {
            setNodes((nds) => nds.concat(getManagerReviewGroupNode(moduleId, type, newNode.position, newNode.data)));
        }else if (type === 'label') {
            // 기본적으로 들어가져야 할 내용: 작업자 설정, 작업할당기준, 포인트 지급 기준
            moduleConfig.workerConfig = {"standardTy":"00"};
            moduleConfig.bindConfig = {"dataBindType": "00", "dataBindUnitCount": 1};
            moduleConfig.pointConfig = {"pointPayTy": "00"};
            moduleConfig.tabActive = {testConfig: watchTestRequired};
            setNodes((nds) => nds.concat(getLabelGroupNode(moduleId, type, newNode.position, newNode.data)));
        }else{
            setNodes((nds)=> nds.concat(newNode));
        }

        return {'moduleId': moduleId, 'moduleNm': moduleNm, 'moduleTy' : moduleTypes[type], 'moduleConfig' : JSON.stringify(moduleConfig)};
    }


    const makeEdgeConnect = (sourceModule, targetModule, sourcePosition, targetPosition) =>{
        //console.log('makeEdgeConnect', sourceModule, targetModule, sourcePosition, targetPosition)
        return {
            id: `reactflow__edge-${sourceModule}${sourcePosition}-${targetModule}${targetPosition}`,
            source: sourceModule,
            sourceHandle: sourcePosition,
            target: targetModule,
            targetHandle: targetPosition
        }
    }

    const toggle = () => {
        if (modal) {
            setModal(false);
        } else {
            setModal(true);
        }
    };

    const onSubmit = async (data) => {
        console.log(data);
        let submitData = {};
        submitData.prjctId = props.prjctId;
        submitData.wrkflowNm = data.wrkflowNm;
        submitData.canvasDataTy = data.dataTy + data.canvasTy;
        submitData.nodeInfo = JSON.stringify({nodes: nodes, edges: edges});
        submitData.modules = moduleItems;
        submitData.labelTargetModule = labelTargetModule;

        let dupYn = await isWorkflowNmDuplication(data.wrkflowNm);
        if(dupYn.data.data){
            setError('wrkflowNm', { type: 'custom', message: GetMessageIntl(intl, "validation.duplication") } )
            return;
        }

        confirm("common.confirm"
            , "workflow.addWorkflowConfirm"
            ,()=>{
               props.addWorkflow(submitData);
            }
            ,null
            , null
            , intl);
    }

    const isWorkflowNmDuplication = async(wrkflowNm)=>{
        let rtnResult = await ManagerWorkflowService.isWorkflowNmDuplication({
            'prjctId': props.prjctId,
            'wrkflowNm': wrkflowNm,
        });

        return rtnResult;
    }

    const ref = createRef();

    return (
        <>
            <form id={"managerWorkflowModalForm"}>
                <Modal size="xxl" isOpen={props.modalFlag} centered>
                    <div className={"modal-header pd-y-20 bg-coco-default-white-f border-coco-gray-100 d-flex align-items-center"}
                         style={{borderBottomWidth: "1px"}}>
                        <div className={"modal-title d-flex align-itmes-center  justify-content-between w-100"}>
                            {GetMessageIntl(intl,"workflow.create")}
                        </div>
                        <button className="close p-0 border-0 bg-transparent ht-30" onClick={setSwitchModalFlag} type="button">
                            <i className={"ri-close-line tx-24"}/>
                        </button>
                    </div>
                    <Form onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                            e.preventDefault()
                        }
                    }}>
                        <ModalBody className="fs-16 p-0">
                            <div className={"d-flex wd-100p"}>
                                <div className={"wd-35p"} style={{padding: "16px 24px 16px 24px"}}>
                                    <h5 className="module-title mb-12">
                                        <i className="ri-pages-line"></i>{GetMessageIntl(intl,"workflow.basicSetting")}
                                    </h5>
                                    <div className={"mb-16"}>
                                        <Label className={"mb-8"}>{GetMessageIntl(intl,"workflow.name")}<span className="text-coco-danger-400">*</span></Label>
                                        <div className={errors.wrkflowNm?"validation-error":""}>
                                            <Controller
                                                render={({ field }) => <Input {...field} maxLength={50} placeholder={GetMessageIntl(intl,"common.enterInfo")} />}
                                                name="wrkflowNm"
                                                control={control}
                                                rules={{
                                                    required: GetMessageIntl(intl,"validation.required"),
                                                    validate: (value) => {
                                                        if(value.trim().length==0){
                                                            return GetMessageIntl(intl,"validation.required");
                                                        }
                                                    }
                                                }}
                                                defaultValue=""
                                                className="form-control lh-18"
                                            />
                                        </div>
                                        {errors.wrkflowNm && <p className="validation-error-message">{errors.wrkflowNm.message}</p>}
                                    </div>
                                    <div className={"mb-16"}>
                                        <Label className={"mb-8"}>{GetMessageIntl(intl,"workflow.dataType")}<span className="text-coco-danger-400">*</span></Label>
                                        <div className="d-flex align-items-center">
                                            <Controller
                                                name="dataTy"
                                                control={control}
                                                rules={{
                                                    required: true,
                                                }}
                                                render={({ field }) =>
                                                    <Label className="card-label wd-25p text-center">
                                                        <input className="card-radio" type="radio" {...field} value="1" />
                                                        <div className="card-details text-coco-gray-200">
                                                            <div
                                                                className="ht-30 d-flex align-items-center justify-content-center mb-8">
                                                                <i className="ri-image-2-line tx-30"></i>
                                                            </div>
                                                            <div className="fw-500 tx-14">
                                                                {GetMessageIntl(intl,"workflow.image")}
                                                            </div>
                                                            <div className="fw-500 tx-12">
                                                                jpg, png
                                                            </div>
                                                        </div>
                                                    </Label>
                                                }
                                            />
                                            <Controller
                                                name="dataTy"
                                                control={control}
                                                rules={{
                                                    required: true,
                                                }}
                                                render={({ field }) =>
                                                    <Label className="card-label wd-25p ml-10 text-center">
                                                        <input className="card-radio" type="radio" {...field} value="4" />
                                                        <div className="card-details text-coco-gray-200">
                                                            <div
                                                                className="ht-30 d-flex align-items-center justify-content-center mb-8">
                                                                <i className="ri-text tx-30"></i>
                                                            </div>
                                                            <div className="fw-500 tx-14">
                                                                {GetMessageIntl(intl,"ui.U00146")}
                                                            </div>
                                                            <div className="fw-500 tx-12">
                                                                txt
                                                            </div>
                                                        </div>
                                                    </Label>
                                                }
                                            />
                                            <Controller
                                                name="dataTy"
                                                control={control}
                                                rules={{
                                                    required: true,
                                                }}
                                                render={({ field }) =>
                                                    <Label className="card-label wd-25p ml-10 text-center">
                                                        <input className="card-radio" type="radio" {...field} value="2" />
                                                        <div className="card-details text-coco-gray-200">
                                                            <div
                                                                className="ht-30 d-flex align-items-center justify-content-center mb-8">
                                                                <i className="ri-voiceprint-line tx-30"></i>
                                                            </div>
                                                            <div className="fw-500 tx-14">
                                                                {GetMessageIntl(intl,"workflow.audio")}
                                                            </div>
                                                            <div className="fw-500 tx-12">
                                                                mp3
                                                            </div>
                                                        </div>
                                                    </Label>
                                                }
                                            />

                                            <Controller
                                                name="dataTy"
                                                control={control}
                                                rules={{
                                                    required: true,
                                                }}
                                                render={({ field }) =>
                                                    <Label className="card-label wd-25p ml-10 text-center">
                                                        <input className="card-radio" type="radio" {...field} value="3" />
                                                        <div className="card-details text-coco-gray-200">
                                                            <div
                                                                className="ht-30 d-flex align-items-center justify-content-center mb-8">
                                                                <i className="ri-video-line tx-30"></i>
                                                            </div>
                                                            <div className="fw-500 tx-14">
                                                                {GetMessageIntl(intl,"workflow.video")}
                                                            </div>
                                                            <div className="fw-500 tx-12">
                                                                mp4
                                                            </div>
                                                        </div>
                                                    </Label>
                                                }
                                            />
                                        </div>
                                        {errors.dataTy && <p className="validation-error-message">{GetMessageIntl(intl,"validation.requiredSelect")}</p>}
                                    </div>
                                    <div className={"mb-16"}>
                                        <div className={"mb-8 tx-16 text-coco-gray-700 fw-500"}>{GetMessageIntl(intl,"workflow.canvasType")}<span className="text-coco-danger-400">*</span></div>
                                        <div>
                                            <div className="form-check form-radio-coco mb-8">
                                                <input className="form-check-input" type="radio" id="canvasTy1" value="1" {...register("canvasTy", {required: true})}
                                                       disabled={getValues('dataTy') && getValues('dataTy') == '1'? false: true}
                                                />
                                                <Label className="form-check-label mb-0" htmlFor="canvasTy1">
                                                    {GetMessageIntl(intl,"workflow.annotation")}
                                                </Label>
                                            </div>
                                            <div className="form-check form-radio-coco">
                                                <input className="form-check-input" type="radio" id="canvasTy2"  value="2" {...register("canvasTy", {required: true})} disabled={getValues('dataTy')? false: true}/>
                                                <Label className="form-check-label mb-0" htmlFor="canvasTy2" >
                                                    {GetMessageIntl(intl,"workflow.clsfcSmry")}
                                                </Label>
                                            </div>
                                        </div>
                                        {errors.canvasTy && <p className="validation-error-message">{GetMessageIntl(intl,"validation.requiredSelect")}</p>}
                                    </div>

                                    <div>
                                        <div className={"mb-8 tx-16 text-coco-gray-700 fw-500"}>{GetMessageIntl(intl,"workflow.reviewStep")}<span className="text-coco-danger-400">*</span></div>
                                        <div className={errors.reviewNTy?"validation-select-error":""}>
                                            <Controller
                                                name="reviewNTy"
                                                id="reviewNTy"
                                                rules={{
                                                    required: true,
                                                }}
                                                defaultValue={"1"}
                                                render={({ field: { onChange, value, ref, name } }) => (
                                                    <Select
                                                        options={reviewOptions}
                                                        placeholder={GetMessageIntl(intl,"common.selectOptions")}
                                                        formatGroupLabel={formatGroupLabelNoCount}
                                                        styles={defaultSelectStyle}
                                                        className="mb-8"
                                                        menuPortalTarget={document.body}
                                                        menuPosition={'fixed'}
                                                        menuPlacement={'auto'}
                                                        maxMenuHeight={250}
                                                        onChange={val => {
                                                            onChange(val.value);
                                                            /*handleChangeDataTy(val);*/
                                                        }}
                                                        value={value?reviewOptions.find((c) => c.value === value):null}
                                                    />
                                                )}
                                                control={control}
                                            />
                                        </div>
                                        {errors.reviewNTy && <p className="validation-error-message mb-0">{GetMessageIntl(intl,"validation.requiredSelect")}</p>}
                                    </div>

                                    <hr className="coco-hr my-28"/>

                                    <h5 className="module-title mb-12">
                                        <i className="mdi mdi-check-network-outline"></i>{GetMessageIntl(intl,"workflow.otherOption")}
                                    </h5>
                                    <div className={"mb-16"}>
                                        <div className={"mb-8 d-flex align-items-center text-coco-gray-700 fw-500"}>{GetMessageIntl(intl,"workflow.presetModuleActive")}<i className={"ml-6  ri-information-line"}/></div>
                                        <div>
                                            <div className="form-check form-check form-check-coco">
                                                <input className="form-check-input" type="checkbox" name="moduleTy" id="moduleTy1" value="sdq4ai" {...register("moduleTy")} disabled={true}/>
                                                <Label className="form-check-label mb-0 d-flex align-items-center" htmlFor="moduleTy1">
                                                    {GetMessageIntl(intl,"workflow.sdqforaiModule")}<div className="badge badge-coco badge-coco-soft-red ml-8">PRO</div>
                                                </Label>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div className={"mb-8 text-coco-gray-700 fw-500"}>{GetMessageIntl(intl,"workflow.tabActive")}</div>
                                        <div>
                                            <div className="form-check form-check-coco">
                                                <input className="form-check-input" type="checkbox" id="testRequired" {...register("testRequired")} disabled={testDisabled}/>
                                                <Label className="form-check-label mb-0" htmlFor="testRequired">
                                                    {GetMessageIntl(intl,"workflow.minitest")}
                                                </Label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className={"wd-65p"}>
                                    <ReactFlow
                                        id={"moduleReactFlow"}
                                        ref={ref}
                                        nodes={nodes}
                                        edges={edges}
                                        /*onNodeClick={onNodeClick}*/
                                        onNodesChange={onNodesChange}
                                        onEdgesChange={onEdgesChange}
                                        onInit={setReactFlowInstance}
                                        fitView
                                        nodesDraggable={false}
                                        nodeTypes={nodeTypes}
                                        edgeTypes={edgeTypes}
                                        defaultEdgeOptions={defaultEdgeOptions}
                                        connectionLineComponent={ConnectionLine}
                                        connectionLineStyle={connectionLineStyle}
                                    >
                                        <Controls position={"top-right"} showInteractive={false}/>
                                        <Background color="#cfd8dc" gap={16} className="bg-coco-gray-50"/>
                                    </ReactFlow>
                                </div>
                            </div>
                        </ModalBody>
                    </Form>
                    <ModalFooter style={{padding:"12px 20px 16px 32px"}}>
                        <button className="btn btn-coco-2" onClick={setSwitchModalFlag}>
                            {GetMessageIntl(intl, "common.cancel")}
                        </button>
                        <button
                            type="button"
                            className="btn btn-coco-1"
                            onClick={methods.handleSubmit(onSubmit)}
                        >
                            {GetMessageIntl(intl, "common.confirm")}
                        </button>
                    </ModalFooter>
                </Modal>
            </form>
        </>
    );
}

export default ManageWorkflowModal;