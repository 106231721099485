import {useIntl} from "react-intl";
import {IntlShape} from "react-intl/src/types";

export function flattenMessges(nestedMessages: any, prefix = ''): any {
    return Object.keys(nestedMessages).reduce((messages: any, key) => {
        let value = nestedMessages[key]
        let prefixedKey: string = prefix ? `${prefix}.${key}` : key

        if (typeof value === 'string') {
            messages[prefixedKey] = value
        } else {
            Object.assign(messages, flattenMessges(value, prefixedKey))
        }

        return messages
    }, {})
}

export function GetMessage(id: string, values?: any, defaultText?: string) {
    defaultText = defaultText ?? "Message Not Found";
    const intl = useIntl();
    const translatedMessage = intl.formatMessage({id: id, defaultMessage: defaultText}, values);
    return translatedMessage;
}

export function GetMessageIntl(intl: IntlShape, id: string, values?: any, defaultText?: string) {
    defaultText = defaultText ?? "Message Not Found";
    const translatedMessage = intl.formatMessage({id: id, defaultMessage: defaultText}, values);
    return translatedMessage;
}