import React, {useState} from 'react';
import {Button, Col, Form, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row} from "reactstrap";
import {useDispatch, useSelector} from "react-redux";
import {switchModalFlag} from "../../../store/dataset/action";
import {GetMessage} from "../../../util/message";
import {DatasetService} from "../../../apis/api/DatasetService";
import JsFileDownloader from "js-file-downloader";

function ExcelDownloadModal(prop) {
    const dis = useDispatch();
    const modalFlag = useSelector((state) => {return state.Dataset.modalFlag});
    const switchModal = () => {
        return dis(switchModalFlag());
    }

    const [inputs, setInputs] = useState({
        // COL1: "on", // 데이터셋아이디
        COL2: "on", // 폴더명
        COL3: "on", // 파일명
        COL4: "off", // 데이터셋명
        COL5: "off", // 작업
        COL6: "off", // 상태
        COL7: "off", // 반려횟수
        COL8: "off", // 총 인스턴스
    });

    function onChange(e) {
        const { value, name } = e.target;
        setInputs({
            ...inputs,
            [name]: (e.target.checked ? 'on':'off')
        });
    }

    const exceldownloadRequest = async () => {
        // 다운로드 받을 데이터가 있는지 확인
        // 이때 다운받을 파일명 데이터 수신

        const param = {
            'prjctId' : prop.prjctId,
            'wrkflowId' : prop.wrkflowId,
            'column' : inputs
        }

        let userInfoJson = JSON.parse(sessionStorage.getItem('coconutUserInfo'));
        let accessToken = userInfoJson.accessToken;

        const response = await DatasetService.checkExportData(param);
        const result = response.data.data;
        if(result.fileCount > 0){
            console.log(result);
            new JsFileDownloader({
                url: process.env.REACT_APP_API_HOST+'/service/dataset/exportDatasetList',
                method: 'POST',
                contentType: 'application/json',
                filename : result.fileName,
                // headers: [
                //     { name: 'Authorization', value: 'Bearer '+accessToken}
                // ],
                body: JSON.stringify(param),
                withCredentials: true
            })
            .then(function () {
                // Called when download ended
            })
            .catch(function (error) {
                // Called when an error occurred
            });
        }else{
            alert('다운받을 데이터가 없습니다.')
        }
    }

    const columnList = [
        // {
        //     name : "COL1"
        //     ,title : GetMessage("dataset.datasetId")
        //     ,disable : true
        //     ,checked : true
        // },
        {
            name : "COL2"
            ,title : GetMessage("dataset.folder")
            ,disable : true
            ,checked : true
        },
        {
            name : "COL3"
            ,title : GetMessage("dataset.fileNm")
            ,disable : true
            ,checked : true
        },
        {
            name : "COL4"
            ,title : GetMessage("dataset.datasetNm")
            ,disable : false
            ,checked : false
        },
        {
            name : "COL5"
            ,title : GetMessage("dataset.taskType")
            ,disable : false
            ,checked : false
        },
        {
            name : "COL6"
            ,title : GetMessage("dataset.sttus")
            ,disable : false
            ,checked : false
        },
        {
            name : "COL7"
            ,title : GetMessage("dataset.rejectCnt")
            ,disable : false
            ,checked : false
        },
        {
            name : "COL8"
            ,title : GetMessage("dataset.allInstance")
            ,disable : false
            ,checked : false
        }
    ]


    return (
       <>
           <Modal size="lg" isOpen={modalFlag} centered>
               <ModalHeader className="bg-light p-3" toggle={switchModal}>
                   {GetMessage("dataset.excelExportDatasetList")}
               </ModalHeader>
               <Form>
                   <ModalBody>
                       <Row className="gy-4">
                           {columnList.map(item=> (
                               <Col xxl={3} md={6} key={item.name}>
                                   <div>
                                       <div className="form-check form-check-coco mb-2">
                                           <Input className="form-check-input" type="checkbox" name={item.name} id={item.name} onClick={onChange} disabled = {item.disable} defaultChecked={item.checked} />
                                           <Label className="form-check-label" htmlFor={item.name}>
                                               {item.title}
                                           </Label>
                                       </div>
                                   </div>
                               </Col>
                           ))}
                       </Row>
                   </ModalBody>
               </Form>
               <ModalFooter>
                   <Button
                       type="button"
                       color="light"
                       onClick={switchModal}
                   >
                       닫기
                   </Button>
                   <Button
                       type="submit"
                       className="btn btn-coco-1"
                       onClick={exceldownloadRequest}
                   >
                       <i className="ri-save-line align-bottom me-1"></i>
                       {GetMessage("dataset.download")}
                   </Button>
               </ModalFooter>
           </Modal>
       </>
    );
}

export default ExcelDownloadModal;