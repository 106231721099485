import React, {useEffect, useRef, useState} from "react";
import {Annotations, AnnotationsByKey, Relations} from "../../../../../../../components/common/text-annotator/types";
import {AccordionItem, Collapse, Input} from "reactstrap";
import {Annotator} from "../../../../../../../components/common/text-annotator/components/Annotator";
import {GetMessage, GetMessageIntl} from "../../../../../../../util/message";
import {GuidePopover} from "../GuidePopover";
import {useIntl} from "react-intl";
import {DashedBar} from "../DashedBar";
import { Accordion } from "react-bootstrap";
import classnames from "classnames";
import {ReviewTitle} from "../ReviewTitle";
import {ReviewRejectView} from "./ReviewRejectView";
import AnnotatorView from "./AnnotatorView";

interface Props{
    shape: any;
    review: any;
}

const ReviewResultView = (props: Props): JSX.Element => {
    const intl = useIntl();
    console.log('summary ReviewResultView', props.shape)
    //const sampleText = '고양이(학명: Felis catus 펠리스 카투스)는 식육목 고양이과에 속하는 포유류이다. 집고양이의 기원은 약 1만년 전 중동 지역에서 스스로 숲속을 나와 사람들이 모여사는 마을에 정착하여 길들여진 아프리카들고양이(학명: Felis lybica)로 추측된다.'
    const answerText = props.shape?.answer[0];

    let reviewAnnotations: Annotations =  [
        {
        key: 'syntax',
        name: 'syntax',
        values: []
        },
        {
            key: 'other',
            name: 'other',
            values: [],
        }
    ];

    const sampleRelations: Relations = [
        {
            key: 'relation',
            name: 'relation',
            values: [],
            directional: true,
        },
    ];

    const [annotations, setAnnotations] = useState(reviewAnnotations)
    const [relations, setRelations] = useState(sampleRelations)

    const [syntaxErrors, setSyntaxErrors] = useState([]);
    const [otherErrors, setOtherErrors] = useState([]);

    const [workHistoryTaggingCollapse, setwoprkHistoryTaggingCollapse] = useState(true);
    const [workHistoryResultCollapse, setworkHistoryResultCollapse] = useState(true);

    const [uiOptions, setUiOptions] = useState({
        fontSize: 14, // default 20
        defaultSvgPadding: 1, // default 10
        spaceBetweenTextAndSvg: 4, // default 6
        svgWidth: 1.5, // default 3
        spaceBetweenSvgs: 2, // default 2
        spaceBeforeNextLine: 0, // default 10
        relationVerticalOffset: 7, // default 7
        relationHorizontalOffset: 5, // default 5
    });

    const [isOpen, setIsOpen] = useState<boolean>(false);

    useEffect(()=>{
        annotations.forEach((annotation:AnnotationsByKey) =>{
            if (annotation.key == 'syntax'){
                setSyntaxErrors(annotation.values)
            }else{
                setOtherErrors(annotation.values);
            }
        })
    }, [annotations]);

    useEffect(()=>{
        if(props.review){
            let syntaxValues = [];

            let otherValues = [];
            props.review.q2.content.map(annotation=> {syntaxValues.push({start: annotation.start, end: annotation.end, index:annotation.id});});
            props.review.q3.content.map(annotation=> {otherValues.push({start: annotation.start, end: annotation.end, index:annotation.id});});


            let syntaxAnnotation = [
                {
                    key: 'syntax',
                    name: 'syntax',
                    values: syntaxValues
                },
                {
                    key: 'other',
                    name: 'other',
                    values: otherValues,
                }
            ];

            setAnnotations(syntaxAnnotation);

            //console.log('syntaxAnnotation', syntaxAnnotation);
        }
    },[props.review])

    return (
        <>
            <div className={"mb-24 mt-8"}>
                {/* 여기는 태깅 영역 */}
                <Accordion className="custom-accordionwithicon" id={"accordionWorkHistoryTagging".concat(props.shape.id)} flush>
                    <AccordionItem className={"border-0"}>
                        <h2 className="accordion-header">
                            <button className={classnames("accordion-button","bg-transparent", "shadow-none", "text-coco-gray-700-f", "tx-16", "fw-semibold ", "pd-l-2", "pd-r-10", "pd-y-0", { collapsed: !workHistoryTaggingCollapse })} type="button" onClick={()=>setwoprkHistoryTaggingCollapse(!workHistoryTaggingCollapse)} style={{ cursor: "pointer" }} >
                                <i className={"ri-arrow-left-circle-line text-coco-danger-400 mr-4"}/>{GetMessageIntl(intl,"label.beforeWorkHistory")}
                            </button>
                        </h2>

                        <Collapse isOpen={workHistoryTaggingCollapse} className="accordion-collapse" id={"workHistoryTaggingCollapse".concat(props.shape.id)} >
                            <div className={"accordion-body pd-0 pd-t-24"}>
                                <div className={"accordion-body summary-review-div pd-x-18 pd-y-16 border-coco-gray-100 border-1 bg-coco-gray-50 border-solid border-radius-4 read-only text-coco-gray-700-f"}>
                                    <AnnotatorView
                                        text={answerText}
                                        annotations={annotations}
                                        relations={[]}
                                        onChangeAnnotations={setAnnotations}
                                        uiOptions={uiOptions}
                                        readOnly={true}
                                        showLegend={false}
                                        containerId={props.shape.id+""}
                                    />
                                </div>
                            </div>
                        </Collapse>
                    </AccordionItem>
                </Accordion>
            </div>

            <div className={"mb-24"}>
                 {/*여기는 검수 결과 영역*/}
                <Accordion className="custom-accordionwithicon" id={"accordionWorkHistoryResult".concat(props.shape.id)} flush>
                    <AccordionItem className={"border-0"}>
                        <h2 className="accordion-header">
                            <button className={classnames("accordion-button","bg-transparent", "shadow-none", "text-coco-gray-700-f", "tx-16", "fw-semibold ", "pd-l-2", "pd-r-10", "pd-y-0", { collapsed: !workHistoryResultCollapse })} type="button" onClick={()=>setworkHistoryResultCollapse(!workHistoryResultCollapse)} style={{ cursor: "pointer" }} >
                                <i className={"ri-search-eye-line text-coco-danger-400 mr-4"}/>{GetMessageIntl(intl,"review.reviewResult")}
                                {
                                    props.review.reject?
                                        <div className="badge badge-coco badge-coco-soft-red ml-8">{GetMessage('ui.U00066')}</div>
                                        :
                                        <div className="badge badge-coco badge-coco-soft-skyblue ml-8">{GetMessage('label.pass')}</div>
                                }

                            </button>
                        </h2>

                        <Collapse isOpen={workHistoryResultCollapse} className="accordion-collapse" id={"workHistoryResultCollapse".concat(props.shape.id)} >
                            <div className={"accordion-body pd-x-16 pd-t-24 pd-b-0 summary-review-result-list"}>
                                {
                                    props.review.q1.reject?
                                        props.review.q1.content.map((annotation, index)=> {
                                            return <ReviewRejectView no={index+1} color={"skyblue"} badgeText={GetMessageIntl(intl, "review.missingKeyword")} detail={annotation.details} key={`reviewQ1${index}`}/>
                                            }):<></>
                                }
                                {
                                    props.review.q2.reject?
                                        props.review.q2.content.map((annotation, index)=> {
                                            index = index+props.review.q1.content.length+1;
                                            return <ReviewRejectView no={index} color={"green"} badgeText={annotation.sentence} detail={annotation.details} key={`reviewQ2${index}`}/>
                                        }):<></>
                                }
                                {
                                    props.review.q3.reject?
                                        props.review.q3.content.map((annotation, index)=> {
                                            index = index+props.review.q1.content.length+props.review.q2.content.length+1;
                                            return <ReviewRejectView no={index} color={"purple"} badgeText={annotation.sentence} detail={annotation.details} key={`reviewQ3${index}`}/>
                                        }):<></>
                                }
                            </div>
                        </Collapse>
                    </AccordionItem>
                </Accordion>
            </div>
            <div className={"mb-24"}>
                <DashedBar />
            </div>
        </>
    )
}


export default React.memo(ReviewResultView);
