// @flow
import { all, call, put, fork, takeEvery } from "redux-saga/effects";

import {
    SET_IS_MODULE_OFFCANVAS,
    SET_IS_DATASET_OFFCANVAS,
    SET_IS_LABEL_OFFCANVAS,
    SET_IS_REVIEW_OFFCANVAS, SET_SELECTION_NODE, RESET_SELECTION_NODE,
    DELETE_NODE_ID
} from './actionType';
import {
    manage_project_workflow_module_setIsDatasetOffcanvas,
    manage_project_workflow_module_setIsLabelOffcanvas,
    manage_project_workflow_module_setIsModuleOffcanvas,
    manage_project_workflow_module_setIsReviewOffcanvas,
    manage_project_workflow_module_setSelectionNodeNm
} from "./action";
import {getRootStore} from "../../../../index";

let store = null;
function getStore(){
    if (store === null) {
// store = getCVATStore();
        store = getRootStore();
    }
    return store;
}

function* setIsModuleOffcanvas({ payload: isYn }) {
    try {
        if(isYn) {
            const state = getStore().getState();
            if(state.ManageWorkflowModule.isDatasetOffCanvas){
                yield put(manage_project_workflow_module_setIsDatasetOffcanvas(false));
            }
            if(state.ManageWorkflowModule.isLabelOffCanvas) {
                yield put(manage_project_workflow_module_setIsLabelOffcanvas(false));
            }
            if(state.ManageWorkflowModule.isReviewOffCanvas) {
                yield put(manage_project_workflow_module_setIsReviewOffcanvas(false));
            }
        }
    } catch (error) {
        // console.log(error);
    }
}

function* setIsDatasetOffcanvas({ payload: isYn }) {
    try {
        if(isYn) {
            const state = getStore().getState();
            if(state.ManageWorkflowModule.isModuleOffCanvas){
                yield put(manage_project_workflow_module_setIsModuleOffcanvas(false));
            }
            if(state.ManageWorkflowModule.isLabelOffCanvas) {
                yield put(manage_project_workflow_module_setIsLabelOffcanvas(false));
            }
            if(state.ManageWorkflowModule.isReviewOffCanvas) {
                yield put(manage_project_workflow_module_setIsReviewOffcanvas(false));
            }
        }
    } catch (error) {
        // console.log(error);
    }
}

function* setIsLabelOffcanvas({ payload: isYn }) {
    try {
        if(isYn) {
            const state = getStore().getState();
            if(state.ManageWorkflowModule.isModuleOffCanvas){
                yield put(manage_project_workflow_module_setIsModuleOffcanvas(false));
            }
            if(state.ManageWorkflowModule.isDatasetOffCanvas) {
                yield put(manage_project_workflow_module_setIsDatasetOffcanvas(false));
            }
            if(state.ManageWorkflowModule.isReviewOffCanvas) {
                yield put(manage_project_workflow_module_setIsReviewOffcanvas(false));
            }
        }
    } catch (error) {
        // console.log(error);
    }
}

function* setIsReviewOffcanvas({ payload: isYn }) {
    try {
        if(isYn) {
            const state = getStore().getState();
            if(state.ManageWorkflowModule.isModuleOffCanvas){
                yield put(manage_project_workflow_module_setIsModuleOffcanvas(false));
            }
            if(state.ManageWorkflowModule.isDatasetOffCanvas) {
                yield put(manage_project_workflow_module_setIsDatasetOffcanvas(false));
            }
            if(state.ManageWorkflowModule.isLabelOffCanvas) {
                yield put(manage_project_workflow_module_setIsLabelOffcanvas(false));
            }
        }
    } catch (error) {
        // console.log(error);
    }
}

function* setSelectionNode({payload: nodeInfo}){
    try{
        if(nodeInfo.type === 'dataset'){
            yield put(manage_project_workflow_module_setIsDatasetOffcanvas(true));
        }else if(nodeInfo.type === 'label'){
            yield put(manage_project_workflow_module_setIsLabelOffcanvas(true));
        }else if(nodeInfo.type === 'review'){
            yield put(manage_project_workflow_module_setIsReviewOffcanvas(true));
        }else{
            const state = getStore().getState();
            if(state.ManageWorkflowModule.isModuleOffCanvas){
                yield put(manage_project_workflow_module_setIsModuleOffcanvas(false));
            }
            if(state.ManageWorkflowModule.isDatasetOffCanvas) {
                yield put(manage_project_workflow_module_setIsDatasetOffcanvas(false));
            }
            if(state.ManageWorkflowModule.isLabelOffCanvas) {
                yield put(manage_project_workflow_module_setIsLabelOffcanvas(false));
            }
            if(state.ManageWorkflowModule.isReviewOffCanvas) {
                yield put(manage_project_workflow_module_setIsReviewOffcanvas(false));
            }
        }

        if(nodeInfo.data && nodeInfo.data.moduleNm){
            yield put(manage_project_workflow_module_setSelectionNodeNm(nodeInfo.data.moduleNm));
        }
    }catch (error){

    }
}

function* resetSelectionNode(){
    const state = getStore().getState();
    if(state.ManageWorkflowModule.isDatasetOffCanvas){
        yield put(manage_project_workflow_module_setIsDatasetOffcanvas(false));
    }
    if(state.ManageWorkflowModule.isLabelOffCanvas) {
        yield put(manage_project_workflow_module_setIsLabelOffcanvas(false));
    }
    if(state.ManageWorkflowModule.isReviewOffCanvas) {
        yield put(manage_project_workflow_module_setIsReviewOffcanvas(false));
    }

    yield put(manage_project_workflow_module_setSelectionNodeNm(null));
}

function* deleteNodeId(){
    const state = getStore().getState();
    const offcanvasModuleId = state.ManageWorkflowModule.selectionNode?state.ManageWorkflowModule.selectionNode.id:null;
    const clickModuleId = state.ManageWorkflowModule.selectionNodeId;
    if(offcanvasModuleId == clickModuleId){
        if(state.ManageWorkflowModule.isDatasetOffCanvas){
            yield put(manage_project_workflow_module_setIsDatasetOffcanvas(false));
        }
        if(state.ManageWorkflowModule.isLabelOffCanvas) {
            yield put(manage_project_workflow_module_setIsLabelOffcanvas(false));
        }
        if(state.ManageWorkflowModule.isReviewOffCanvas) {
            yield put(manage_project_workflow_module_setIsReviewOffcanvas(false));
        }
    }

}

/**
 * Watchers
 */
export function* watchSetIsModuleOffcanvas() {
    yield takeEvery(SET_IS_MODULE_OFFCANVAS, setIsModuleOffcanvas);
}

export function* watchSetIsDatasetOffcanvas() {
    yield takeEvery(SET_IS_DATASET_OFFCANVAS, setIsDatasetOffcanvas);
}

export function* watchSetIsLabelOffcanvas() {
    yield takeEvery(SET_IS_LABEL_OFFCANVAS, setIsLabelOffcanvas);
}
export function* watchSetIsReviewOffcanvas() {
    yield takeEvery(SET_IS_REVIEW_OFFCANVAS, setIsReviewOffcanvas);
}
export function* watchSetSelectionNode() {
    yield takeEvery(SET_SELECTION_NODE, setSelectionNode);
}
export function* watchResetSelectionNode() {
    yield takeEvery(RESET_SELECTION_NODE, resetSelectionNode);
}

export function* watchDeleteNodeId() {
    yield takeEvery(DELETE_NODE_ID, deleteNodeId);
}


function* ManageWorkflowModuleSaga() {
    yield all([
        fork(watchSetIsModuleOffcanvas),
        fork(watchSetIsDatasetOffcanvas),
        fork(watchSetIsLabelOffcanvas),
        fork(watchSetIsReviewOffcanvas),
        fork(watchSetSelectionNode),
        fork(watchResetSelectionNode),
        fork(watchDeleteNodeId),
    ]);
}

export default ManageWorkflowModuleSaga;