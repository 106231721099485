import React, {useEffect, useState} from 'react';
import {Form, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row} from "reactstrap";
import {GetMessageIntl} from "../../../util/message";
import Select from "react-select";
import {defaultSelectStyle} from "../../../components/constants/select";
import {useIntl} from "react-intl";
import {ManagerProjectService} from "../../../apis/api/ManagerProjectService";
import {ManagerWorkflowService} from "../../../apis/api/ManagerWorkflowService";
import Flatpickr from "react-flatpickr";
import {alertInfo} from "../../../util/AlertUtil";

function ExportModal(props) {
    const intl = useIntl();

    const [selectedTarget, setSelectedTarget] = useState("01");
    const [selectedRange, setSelectedRange] = useState("01");
    const [targetWorkflow, setTargetWorkflow] = useState([]);
    const [targetDate, setTargetDate] = useState("");

    const [selectWorkflowOption, setSelectWorkflowOption] = useState([]);

    const resetData = () => {
        setSelectedTarget("01");
        setSelectedRange("01");
        setTargetWorkflow([]);
        setTargetDate("");
    }

    useEffect(() => {
        if(props.modalFlag === true){
            resetData();
            //워크플로우 리스트 가져오기 (프로젝트ID 기준)
            getWorkflowListByPrjctId(props.prjctId).then(r => {});
        }
    }, [props.modalFlag])

    const getWorkflowListByPrjctId = async (prjctId) => {
        const res = await ManagerWorkflowService.getManageWorkflowList({
            "prjctId" : prjctId
        });
        if(res.data.data){
            console.log("WorkFlowList -> ", res.data.data);
            const options = res.data.data
                ?.map(workflow => ({ label: workflow.wrkflowNm, value: workflow.wrkflowId}));
            setSelectWorkflowOption(options);
        }
    }

    const handleTargetChange = (e) => {
        setSelectedTarget(e.target.value);
    }
    const handleRangeChange = (e) => {
        setSelectedRange(e.target.value);
    }

    const handleWorkFlowChange = (values) => {
        console.log("워크플로우 변경 이벤트", values);
        const filteredData = values.map(data => data.value);
        setTargetWorkflow(filteredData);
    }

    const handleExportBtn = () => {
        const taskConfig = {
            "exportType" : selectedTarget,
            "exportTarget" : selectedRange,
            "originInclude" : false
        }

        if(selectedTarget === "02"){
            taskConfig.exportTargetId = targetWorkflow;
        }else if(selectedTarget === "01"){
            taskConfig.exportTargetId = targetWorkflow;
        }

        if(selectedRange === "03" && targetDate !== ""){
            const dates = targetDate.split("~");
            if(dates.length === 1){
                taskConfig.exportFrom = dates[0];
                taskConfig.exportTo = "";
            }else{
                taskConfig.exportFrom = dates[0];
                taskConfig.exportTo = dates[1];
            }
        }else{
            taskConfig.exportFrom = "";
            taskConfig.exportTo = "";
        }

        console.log("taskConfig ->", taskConfig)
        alertInfo("projectSetting.exportFile", "projectSetting.exportFileMsg", () => {
            ManagerProjectService.ecsDataExport({
                "prjctId" : props.prjctId,
                "taskConfig" : JSON.stringify(taskConfig)
            }).then(re => {
                props.switchModalFlag();
            })
        }, null, intl)

    }

    return (
        <>
            <form>
                <Modal size="lg" isOpen={props.modalFlag} centered>
                    <ModalHeader toggle={props.switchModalFlag} style={{backgroundColor: '#FFFFFF', borderBottom: '1px solid #CFD8DC', padding: '12px 20px !important'}}>
                        내보내기
                    </ModalHeader>
                    <Form>
                        <ModalBody className="fs-16" style={{paddingTop:'12px'}}>
                            <div className="mb-3">
                                <Row className="mb-8">
                                    <span className="text-coco-gray-700 fw-500">내보내기 단위<span className="text-coco-danger-400">*</span></span>
                                </Row>
                                <Row className="fw-500 d-flex pd-l-10">
                                    <div className="form-check form-radio-coco wd-30p">
                                        <Input className="form-check-input" type="radio" name="prjctOrWrkflow" value="01" checked={selectedTarget === "01"} onChange={handleTargetChange}/>
                                        <Label className="form-check-label" htmlFor="formradioRight5">
                                            프로젝트(전체 워크플로우)
                                        </Label>
                                    </div>
                                    <div className="form-check form-radio-coco wd-50p">
                                        <Input className="form-check-input" type="radio" name="prjctOrWrkflow" value="02" checked={selectedTarget === "02"} onChange={handleTargetChange}/>
                                        <Label className="form-check-label" htmlFor="formradiocolor2">
                                            개별 워크플로우
                                        </Label>
                                    </div>
                                </Row>
                                {
                                    selectedTarget === "02" &&
                                        <>
                                            <Select
                                                placeholder="워크플로우를 선택하세요"
                                                options={selectWorkflowOption}
                                                styles={defaultSelectStyle}
                                                onChange={(values) => {
                                                    handleWorkFlowChange(values)
                                                }}
                                                isMulti
                                                className="mb-0"
                                            />
                                        </>
                                }
                            </div>
                            <div>
                                <Row className="mb-8">
                                    <span className="text-coco-gray-700 fw-500">내보내기 대상 파일<span className="text-coco-danger-400">*</span></span>
                                </Row>
                                <Row className="fw-500 pd-l-10">
                                    <div className="form-check form-radio-coco">
                                        <Input className="form-check-input" type="radio" name="targetFileRange" value="01" checked={selectedRange === "01"} onChange={handleRangeChange}/>
                                        <Label className="form-check-label" htmlFor="formradioRight5">
                                            전체 완료된 파일
                                        </Label>
                                    </div>
                                    <div className="form-check form-radio-coco">
                                        <Input className="form-check-input" type="radio" name="targetFileRange"  value="02" checked={selectedRange === "02"} onChange={handleRangeChange}/>
                                        <Label className="form-check-label" htmlFor="formradioRight5">
                                            마지막 내보내기 시점 이후 완료된 파일
                                        </Label>
                                    </div>
                                    <div className="form-check form-radio-coco">
                                        <Input className="form-check-input" type="radio" id="targetFileRange"  value="03" checked={selectedRange === "03"} onChange={handleRangeChange}/>
                                        <Label className="form-check-label" htmlFor="formradiocolor2">
                                            특정 기간동안 완료된 파일
                                        </Label>
                                    </div>
                                    {
                                        selectedRange === "03" &&
                                            <>
                                                <div className="form-icon">
                                                    <Flatpickr className="form-control form-control-icon w-50" placeholder="YYYY.MM.DD-YYYY.MM.DD"
                                                               options={{
                                                                   mode: "range",
                                                                   maxDate: 'today',
                                                                   dateFormat: "Y-m-d",
                                                                   locale:{rangeSeparator:'~'}
                                                               }}
                                                               onValueUpdate={(selectedDates, dateStr, instance) => {
                                                                   setTargetDate(dateStr)
                                                                   instance.element.value = dateStr;
                                                                   console.log("date ->", dateStr)
                                                               }}
                                                    />
                                                    <i className="ri-calendar-event-line text-coco-gray-400"></i>
                                                </div>
                                            </>
                                    }
                                </Row>
                            </div>
                        </ModalBody>
                    </Form>
                    <ModalFooter style={{padding:"0px 20px 10px 32px"}}>
                        <button className="btn btn-coco-2" onClick={props.switchModalFlag}>
                            {GetMessageIntl(intl, "common.cancel")}
                        </button>
                        <button
                            type="button"
                            className="btn btn-coco-1"
                            onClick={handleExportBtn}
                        >
                            {GetMessageIntl(intl, "common.confirm")}
                        </button>
                    </ModalFooter>
                </Modal>
            </form>
        </>
    );
}

export default ExportModal;