import React, {useEffect, useRef, useState} from 'react';
import {
    Card,
    CardHeader,
    Col,
    Container,
    Input,
    Row,
    CardBody,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu, DropdownItem, ModalHeader, ModalBody, Nav, NavLink, TabContent, TabPane, ModalFooter, Modal
} from "reactstrap";
import {useDispatch, useSelector} from "react-redux";
import {useIntl} from "react-intl";
import {setTaskJoinModalFlag} from "../../../store/user/task/action";
import classnames from "classnames";
import SimpleBar from "simplebar-react";
import {GetMessageIntl} from "../../../util/message";
import {useParams} from "react-router-dom";
import {TaskService} from "../../../apis/api/TaskService";
import laflowLogo from "../../../assets/images/laflow_logo_01.png";

function TaskInfoDocPage() {
    const intl = useIntl();
    const dispatch = useDispatch();
    const {moduleId, type} = useParams();

    const [title, setTitle] = useState("");
    const [dc, setDc] = useState("");
    const [guideContent, setGuideContent] = useState(null);
    const [faqContent, setFaqContent] = useState(null);

    const [activeVerticalTab, setactiveVerticalTab] = useState('guide');

    const navContentstyle={
        display: "-webkit-box",
        height:"80px",
        overflow:"hidden",
        verticalAlign:"top",
        textOverflow: "ellipsis",
        WebkitBoxOrient: "vertical",
        WebkitLineClamp: 4,
    }

    useEffect(()=>{
        getDataConfig();
        setactiveVerticalTab(type);
    },[])

    const getDataConfig = () => {
        TaskService.getTaskByModuleId({
            'moduleId' : moduleId
        }).then(result => {
            if(result.data.data){
                if(result.data.data.moduleConfig){
                    const resultData = JSON.parse(result.data.data.moduleConfig);
                    if(resultData.dataConfig){
                        const guideData = resultData.dataConfig.find((item => item.useYn == 'Y' && item.type=='editor' && item.body.useTarget == '01'));
                        const faqData = resultData.dataConfig.find((item => item.useYn == 'Y' && item.type=='editor' && item.body.useTarget == '02'));
                        setGuideContent(guideData);
                        setFaqContent(faqData);
                    }else{
                        setGuideContent(null);
                        setFaqContent(null);
                    }

                    if(resultData.baseConfig){
                        const baseConfig = resultData.baseConfig;
                        console.log(baseConfig);
                        setTitle(baseConfig.taskNm);
                        setDc(baseConfig.taskDc);
                    }
                }
            }
        })
    }

    function toggleVerticalTab(tab) {
        setactiveVerticalTab(tab);
    }

    return (
        <>
            <Card className={"mb-0"}>
                <CardHeader className={"pd-y-16 pd-x-32 bg-coco-default-white-f border-coco-gray-100 d-flex align-items-center justify-content-start"}
                             style={{borderBottomWidth: "1px"}}>
                    <div className={"d-flex align-items-center"} style={{height:"36px"}}>
                        <img src={laflowLogo} alt="" height="22" />
                    </div>
                </CardHeader>
                <CardBody className="fs-16 p-0">
                    <Row className="mx-0" style={{minHeight: "calc(100vh - 70px)"}}>
                        <Col className={"col-xl-2-5 bg-coco-primary-400 text-coco-default-white pd-x-24 pd-y-42 nav-coco3 vertical-navs-step text-coco-default-white"}>
                            <div className={"mb-16 tx-24 fw-bold text-overflow"}>
                                <span className={"text-overflow"}>{title}</span>
                            </div>
                            <div className={"mb-24 tx-16 fw-semibold ht-80 lh-20"} style={navContentstyle}>
                                {dc}
                            </div>
                            <Nav
                                className="flex-column custom-nav nav-pills taskJoinNav"
                            >
                                <NavLink
                                    href="#"
                                    className={
                                    "d-flex align-items-center ".concat(
                                        (classnames({
                                            active: activeVerticalTab === 'guide'
                                        }))
                                    )
                                    }
                                    onClick={() => {
                                        toggleVerticalTab('guide');
                                    }}
                                >
                                  <span className="step-title me-2">
                                    <i className="ri-book-open-line me-2"></i>
                                  </span>
                                    {GetMessageIntl(intl, "task.guide")}
                                </NavLink>
                                <NavLink
                                    href="#"
                                    className={
                                        "d-flex align-items-center ".concat(
                                            (classnames({
                                                active: activeVerticalTab === 'faq'
                                            }))
                                        )
                                    }
                                    onClick={() => {
                                        toggleVerticalTab('faq');
                                    }}
                                >
                                  <span className="step-title me-2">
                                    <i className="ri-book-open-line me-2"></i>
                                  </span>
                                    {GetMessageIntl(intl, "task.faq")}
                                </NavLink>
                            </Nav>
                        </Col>
                        <SimpleBar style={{maxHeight:'calc(100vh - 70px)', width: '79%'}} className="taskJoinModalSimpleBar">
                            <Col className={"col-xl-9-5 pd-y-42"}>
                                <div className="px-lg-4">
                                    <TabContent activeTab={activeVerticalTab}>
                                        <TabPane tabId={'guide'}>
                                            {
                                                guideContent?
                                                    <EditorStep title={guideContent.title} body={guideContent.body}/>
                                                    :GetMessageIntl(intl, "common.noData")
                                            }
                                        </TabPane>
                                        <TabPane tabId={'faq'}>
                                            {
                                                faqContent?
                                                    <EditorStep title={faqContent.title} body={faqContent.body}/>
                                                    :GetMessageIntl(intl, "common.noData")
                                            }
                                        </TabPane>
                                    </TabContent>
                                </div>
                            </Col>
                        </SimpleBar>
                    </Row>
                </CardBody>
            </Card>
        </>
    );
}

function EditorStep(props) {
    const [attachFiles, setAttachFiles] = useState([]);

    useEffect(() => {
        setAttachFiles(props.body.attachFiles)
    }, [])

    function downloadFile(filePath, realFileName) {
        fetch(filePath, {method: 'GET'})
            .then((res) => {
                return res.blob() // raw 데이터를 받아온다
            })
            .then((blob) => {
                const url = window.URL.createObjectURL(blob) // 받아온 날 상태의 data를 현재 window에서만 사용하는 url로 바꾼다
                const a = document.createElement('a')
                a.href = url
                a.download = realFileName // 원하는 이름으로 파일명 지정
                document.body.appendChild(a)
                a.click() // 자동으로 눌러버리기
                setTimeout((_) => {
                    window.URL.revokeObjectURL(url) // 해당 url을 더 사용 못하게 날려버린다
                }, 60000)
                a.remove() // a를 다 사용했으니 지워준다
                return false;
            })
            .catch((err) => {
                console.error('err: ', err)
            })
    }

    return (
        <>
            <div className="fs-24 text-coco-gray-700 fw-bold">
                {props.title}
            </div>
            <hr/>
            <div className="text-coco-gray-700">
                <Row className="mb-12">
                    {
                        attachFiles.map((fileInfo, idx) => {
                            const {downloadUrl, host, realFilename} = fileInfo;
                            return (
                                <Row className="mb-3" key={`editor_${props.title}_${idx}`}>
                                    <div className={"d-flex align-items-center text-pointer"}>
                                        <i className="ri-download-2-line text-coco-gray-400" onClick={() => {
                                            const filePath = host + downloadUrl;
                                            downloadFile(filePath, realFilename);
                                        }}></i>
                                        <span>&nbsp;{realFilename}</span>
                                        {/*<span className="fs-16 fw-500 text-coco-gray-400">&nbsp;({CommonUtil.bytesToSize(fileSize)})</span>*/}
                                    </div>
                                </Row>
                            )
                        })
                    }
                </Row>
                <Row>
                    <div className="pd-x-24 pd-y-24" dangerouslySetInnerHTML={{__html: props.body.content}}></div>
                </Row>
            </div>
        </>
    );
}

export default TaskInfoDocPage;
