import React, {useEffect, useState} from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import * as PropTypes from "prop-types";
import {Card, CardBody, Col, DropdownItem, DropdownMenu, DropdownToggle, Row, UncontrolledDropdown} from "reactstrap";
import PageLeftInfomation from "../../components/common/PageLeftInfomation";
import ReactPaginate from "react-paginate";
import {useDispatch, useSelector} from "react-redux";
import {ManagerService} from "../../apis/api/ManagerService.";
import {DatasetService} from "../../apis/api/DatasetService";
import {GetMessage} from "../../util/message";
import button from "bootstrap/js/src/button";

function AgGridTest(props) {
    const dispatch = useDispatch();
    const searchParam = [];
    const [datasets, setDatasets] = useState();
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);


    function calStartIdx(page) {
        return (page - 1) * perPage;
    }
    const fetchUsers = async (page, size = perPage) => {
        setLoading(true);
        console.log('=========================')
        console.log(searchParam)

        const res = await DatasetService.getDatasetList(
            {
                'prjctId' : 'PJ0000000001',
                'start': calStartIdx(page),
                'length': size,
                'orderParam' : [],
                'searchParam' : searchParam
            }
        );

        if (res.data.data){
            setDatasets(res.data.data);
            setTotalRows(res.data.recordsTotal);
            console.log(res.data.data)
        }
        setLoading(false);
    };

    useEffect(() => {
        setCurrentPage(1);
        fetchUsers(1);
    }, []);

    const handlePageChange = page => {
        let pageIdx = page.selected + 1;
        fetchUsers(pageIdx);
        setCurrentPage(pageIdx);
    };

    const CellRenterTest = (props) => {
        return(
            <div className={"actionCell"}>
                <UncontrolledDropdown>
                    <DropdownToggle
                        href="#"
                        className="btn-soft-secondary btn-sm dropdown"
                        tag="button"
                        style={{lineHeight: "17px"}}
                    >
                        <i className="ri-more-fill align-middle"></i>
                    </DropdownToggle>
                    <DropdownMenu className="dropdown-menu-end" style={{lineHeight: "17px"}}>
                        <DropdownItem className="dropdown-item" href="#"
                                      onClick={() => {
                                          console.log(props.params)
                                      }}
                        >
                            {GetMessage('ui.U00075')}
                        </DropdownItem>
                        <DropdownItem
                            className="dropdown-item edit-item-btn"
                            href="#"
                            onClick={() => {}}
                        >
                            {GetMessage('ui.U00013')}
                        </DropdownItem>
                        <DropdownItem
                            className="dropdown-item remove-item-btn"
                            href="#"
                            onClick={() => {}}
                        >
                            {GetMessage('ui.U00137')}
                        </DropdownItem>
                        <DropdownItem
                            className="dropdown-item remove-item-btn"
                            href="#"
                            onClick={() => {}}
                        >
                            {GetMessage('ui.U00135')}
                        </DropdownItem>
                    </DropdownMenu>
                </UncontrolledDropdown>
            </div>
        )
    }

    const [columnDefs] = useState([
        {
            field: 'fileNm',
            headerName: '파일명',
            cellClass : "firstCell",
            width:700,
            cellRenderer: params => {
                return (
                    <>
                        <div className={"d-flex justify-content-between"}>
                            <span>{params.value}</span>
                            <button type="button"
                                    style={{ display : "none", height : "50%"}}
                                    onClick={()=>{
                                        console.log(params);
                                    }} className="joinBtn btn btn-coco-xs-1 align-self-center">
                                {GetMessage("joined.joinTask")}
                            </button>
                        </div>
                    </>
                )
            }
        },
        {field: 'datasetNm', headerName: '데이터셋명', width: 400},
        {
            field: '',
            headerName: '액션',
            cellRenderer: params => {
                return <CellRenterTest params={params}/>;
            },
            editable: false,
            cellClass : "lastCell",
            width : 200
        },
    ]);


    const [defaultColDef] = useState({
        autoHeight: true,
        resizable: true,
        cellClass: 'cell-wrap-text',
    })

    const rowStyle = {
        fontSize :"16px",
        paddingLeft : "32px",
        paddingRight : "32px",
    }

    const count = Math.ceil(Number((totalRows / perPage)))
    return (
        <>
            <Card className="shadow-none">
                <CardBody className="px-0 pb-0">
                    <div className="ag-theme-alpine" style={{ width: '100%' }}>
                        <AgGridReact
                            rowData={datasets}
                            rowStyle={rowStyle}
                            defaultColDef={defaultColDef}
                            columnDefs={columnDefs}
                            pagination={true}
                            paginationPageSize={perPage}
                            suppressPaginationPanel={true}
                            detailRowAutoHeight={true}
                            suppressRowTransform={true}
                            onGridReady={(params)=>{
                                params.api.sizeColumnsToFit();
                            }}
                            domLayout={'autoHeight'}
                            onCellMouseOver={(params)=>{

                            }}
                        >
                        </AgGridReact>
                        <Row className='mx-0'>
                            <Col className='d-flex justify-content-start align-items-center fs-16 text-coco-gray-700 fw-500' sm='6'>
                                <PageLeftInfomation
                                    totalRows={totalRows}
                                    perPage ={perPage}
                                    currentPage={currentPage}
                                />
                            </Col>
                            <Col className='d-flex justify-content-end align-items-center' sm='6'>
                                <ReactPaginate
                                    previousLabel={'←'}
                                    nextLabel={'→'}
                                    onPageChange={handlePageChange}
                                    pageCount={count}
                                    forcePage={currentPage - 1}
                                    // breakLabel={'...'}
                                    // pageRangeDisplayed={2}
                                    // marginPagesDisplayed={2}
                                    activeClassName={'active'}
                                    pageClassName={'page-item'}
                                    nextLinkClassName={'page-link'}
                                    nextClassName={'page-item next'}
                                    previousClassName={'page-item prev'}
                                    previousLinkClassName={'page-link'}
                                    pageLinkClassName={'page-link'}
                                    breakClassName='page-item'
                                    breakLinkClassName='page-link'
                                    containerClassName={'pagination react-paginate pagination-custom pagination-sm justify-content-end align-items-center'}
                                />
                            </Col>
                        </Row>
                    </div>
                </CardBody>
            </Card>
        </>
    );
}

export default AgGridTest;
