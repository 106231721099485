// @ts-nocheck
import { FileInfo } from "./file-info";
import {JobInfo} from "./job-info";
import React from "react";

export function BasicInfo(props): JSX.Element{
    return (
        <div className={"basic-info"}>
            <FileInfo />
            <hr/>
            <JobInfo />
        </div>
    )
}
