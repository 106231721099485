import React, {useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import {
    Card,
    Col,
} from "reactstrap";
import taskReviewImg from "../../../assets/images/task/task_review.png";
import taskLabelImg from "../../../assets/images/task/task_label.png";
import taskStopReviewImg from "../../../assets/images/task/task_stop_review.png";
import taskStopLabelImg from "../../../assets/images/task/task_stop_label.png";
import {GetMessage, GetMessageIntl} from "../../../util/message";
import {useIntl} from "react-intl";

function TaskCardList({list, clickEvent, handleEvent}:{list:any; clickEvent:any, handleEvent:any;}) {

    const intl = useIntl();
    const taskCardstyle:any={
        display: "-webkit-box",
        height:"38px",
        overflow:"hidden",
        verticalAlign:"top",
        textOverflow: "ellipsis",
        WebkitBoxOrient: "vertical",
        WebkitLineClamp: 2,
    }

    const labelText = GetMessage("task.labeling");
    const reviewText = GetMessage("task.review");

    return (
        list.length>0?
            list.map((data:any, index:any)=> {
                const {moduleId, moduleNm, moduleTy, wrkflowNm, canvasDataTy, moduleDc, moduleConfig, modDtmText}= data;
                const taskConfig = moduleConfig?JSON.parse(moduleConfig): null;
                let baseConfig = null;
                let shapeConfig = null;
                let pointConfig = null;
                let workerConfig = null;
                if(taskConfig){
                    if(taskConfig.baseConfig){
                        baseConfig = taskConfig.baseConfig;
                    }
                    if(taskConfig.shapeConfig){
                        shapeConfig = taskConfig.shapeConfig;
                    }
                    if(taskConfig.pointConfig){
                        pointConfig = taskConfig.pointConfig;
                    }
                    if(taskConfig.workerConfig){
                        workerConfig = taskConfig.workerConfig;
                    }
                }

                let pointPay = '';
                if(pointConfig){
                    if(pointConfig.pointPayTy == '00' || pointConfig.pointPayTy =='01'){
                        pointPay = pointConfig.payPoint;
                    }else {
                        if(pointConfig.instance){
                            let min = pointConfig.instance.reduce((previous, current) => {
                                return current.value < previous.value ? current : previous;
                            });

                            let max = pointConfig.instance.reduce((previous, current) => {
                                return current.value > previous.value ? current : previous;
                            });

                            if (min.value == max.value) {
                                pointPay = min.value;
                            } else {
                                pointPay = `${min.value}~${max.value}`;
                            }
                        }
                    }
                }

                return(
                    <Col key={"task_".concat(moduleId)}>
                        <Card className="card-shadow card-body border-1 pd-16 cursor-pointer" onClick={()=>clickEvent(moduleTy, moduleId, baseConfig?baseConfig.taskNm:null, canvasDataTy)}>
                            <div className="align-items-center justify-content-start ht-60 mb-16">
                                <img src={moduleTy==='01'?taskLabelImg:taskReviewImg} alt={baseConfig?baseConfig.taskDc: null}/>
                            </div>
                            <div className="text-coco-gray-a-700 tx-16 fw-bold lh-19" style={taskCardstyle}>
                                {baseConfig?baseConfig.taskNm: null}
                            </div>
                            <div className="border-coco-gray-200 wd-20px bd-2 my-12"></div>
                            <div className="mb-12">
                                <span className="badge bg-coco-primary-400 tx-12 lh-14 fw-normal mg-r-10 pd-x-4 pd-y-6">
                                {moduleTy==='01'?labelText:reviewText}
                                </span>

                                {pointPay?
                                    <span className="badge bg-coco-primary-400 tx-12 lh-14 fw-normal pd-x-4 pd-y-6">
                                        {GetMessageIntl(intl,"common.pointPay").concat(` ${pointPay} ${GetMessageIntl(intl,"common.pointUnit")}`)}
                                    </span>
                                    :null
                                }
                            </div>
                            <p className="text-coco-gray-400 fw-semibold tx-16 lh-19 fw-medium mb-0" style={taskCardstyle}>{baseConfig?baseConfig.taskDc: null}</p>
                        </Card>
                    </Col>
                )
            })
            :
            <Col>{GetMessage("common.noData")}</Col>
    );
}

export default TaskCardList;