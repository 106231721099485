import {LABEL_REVIEW_DETAILS, OBJECT_REVIEW_DETAILS} from "../../classNameConsts";
import {GetMessage} from "../../../../../../../../util/message";
import LabelReviewFooterContainer from "../../../containers/standard-workspace/objects-side-bar/label-review-footer";

interface Props {
    activated: boolean;
    labelID: number;
    labelName: string;
    hideStates(): void;
    showStates(): void;
}
export function LabelReviewDetails (props: Props){
    const {activated, labelID, labelName, showStates, hideStates} = props;

    return (
        <div className={[LABEL_REVIEW_DETAILS, "review-details"].join(" ")}>
            <div className={'title'}>
                {GetMessage('label.classesModifyGuide', {labelName: labelName})}
            </div>
            <LabelReviewFooterContainer
                labelID={labelID}
            />
        </div>
    )
}
