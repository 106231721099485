import {
    CHANGE_BRIGHTNESS_LEVEL,
    CHANGE_CONTRAST_LEVEL,
    CHANGE_GRID_COLOR,
    CHANGE_GRID_OPACITY, CHANGE_GRID_SIZE, CHANGE_SATURATION_LEVEL, SWITCH_AUTOMATIC_BORDERING,
    SWITCH_GRID, SWITCH_TOOLS_BLOCKER_STATE
} from "./actionType";
import {GridColor} from "../label";
import {ToolsBlockerState} from "store/label";

export const switchGrid = (grid: boolean) => ({
    type: SWITCH_GRID,
    payload: {
        grid,
    }
})
export const changeGridSize = (gridSize: number) => ({
    type: CHANGE_GRID_SIZE,
    payload: {
        gridSize
    }
})

export const changeGridColor = (gridColor: GridColor) => ({
    type: CHANGE_GRID_COLOR,
    payload: {
        gridColor
    }
})
export const changeGridOpacity = (gridOpacity: number) => ({
    type: CHANGE_GRID_OPACITY,
    payload: {
        gridOpacity
    }
})
export const changeBrightnessLevel = (level: number) => ({
    type: CHANGE_BRIGHTNESS_LEVEL,
    payload: {
        level
    }
})
export const changeContrastLevel = (level: number) => ({
    type: CHANGE_CONTRAST_LEVEL,
    payload: {
        level
    }
})
export const changeSaturationLevel = (level: number) => ({
    type: CHANGE_SATURATION_LEVEL,
    payload: {
        level
    }
})
export const switchAutomaticBordering = (automaticBordering: boolean) => ({
    type: SWITCH_AUTOMATIC_BORDERING,
    payload: {
        automaticBordering
    }
})
export const  switchToolsBlockerState = (toolsBlockerState: ToolsBlockerState) => ({
    type: SWITCH_TOOLS_BLOCKER_STATE,
    payload: {
        toolsBlockerState,
    },
})
