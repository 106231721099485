import React, {lazy, useEffect, useState} from "react";
import {Nav, NavItem, NavLink, Offcanvas, TabContent, TabPane} from "reactstrap";
import {GetMessage, GetMessageIntl} from "../../../../../../util/message";
import {useDispatch, useSelector} from "react-redux";
import {
    manage_project_workflow_module_deleteNodeId,
    manage_project_workflow_module_setIsLabelOffcanvas,
    manage_project_workflow_module_setSelectionNodeNm,
    manage_project_workflow_module_updateModuleId,
} from "../../../../../../store/manage/project/workflow/module/action";
import {FormProvider, useForm} from "react-hook-form";
import classnames from "classnames";
import {useIntl} from "react-intl";
import {useStoreApi} from "reactflow";
import {ModuleService} from "../../../../../../apis/api/ModuleService";
import {
    initManageWorkflowLabelModuleConfig,
    resetManageWorkflowLabelModuleConfig,
    setManageWorkflowLabelActiveData,
    setManageWorkflowLabelBaseConfig,
    setManageWorkflowLabelBindConfig,
    setManageWorkflowLabelDataConfig,
    setManageWorkflowLabelDataError,
    setManageWorkflowLabelFilesReset,
    setManageWorkflowLabelInactiveData,
    setManageWorkflowLabelOrderConfig,
    setManageWorkflowLabelPointConfig, setManageWorkflowLabelSettingRowId, setManageWorkflowLabelSettingViewType,
    setManageWorkflowLabelShapeConfig,
    setManageWorkflowLabelTestConfig,
    setManageWorkflowLabelWorkerConfig,
    switchManageWorkflowLabelRegistDataRefreshFlag
} from "../../../../../../store/manage/project/workflow/label/action";
import BaseConfigDiv from "./tab/label/BaseConfigDiv";
import ShapeConfigDiv from "./tab/label/ShapeConfigDiv";
import WorkerConfigDiv from "./tab/label/WorkerConfigDiv";
import OrderConfigDiv from "./tab/label/OrderConfigDiv";
import PointConfigDiv from "./tab/label/PointConfigDiv";
import DataConfigDiv from "./tab/label/DataConfigDiv";
import TestConfigDiv from "./tab/label/TestConfigDiv";
import PickAndContent from "./common/offcanvas/PickAndContent";
import {alertError, alertSuccess, alertWarning, confirm} from "../../../../../../util/AlertUtil";
import {
    workflow_label_testConfig_clearHasErrorFiles,
    workflow_label_testConfig_setHasErrorFiles
} from "../../../../../../store/manage/project/workflow/label/TestConfig/action";
import {CANVAS_DATA_TY, DATA_TYPE, LABEL_TYPE} from "../../../../../user/label/annotation/image/const";
import PointClsfcSmryConfigDiv from "./tab/label/PointClsfcSmryConfigDiv";
import {isClsfcSmryType, parseLabels} from "../../../../../user/label/clsfcSmry/util/clsfcSmryUtil";




export default (props) => {
    const { canvasDataTy } = props;
    const intl = useIntl();
    const store = useStoreApi();
    const dispatch = useDispatch();
    const prjctId = sessionStorage.getItem("prjctId");
    const [customActiveTab, setcustomActiveTab] = useState("1");
    const [disabledOpenTask, setDisabledOpenTask] = useState(true);

    const {
        isLabelOffCanvas,
        selectionNode,
        shapeConfig,
        baseConfig, workerConfig, orderConfig, pointConfig, dataConfig, testConfig, bindConfig, tabActive
    } = useSelector((state) => ({
        isLabelOffCanvas: state.ManageWorkflowModule.isLabelOffCanvas,
        selectionNode: state.ManageWorkflowModule.selectionNode,
        shapeConfig: state.ManageWorkflowLabel.shapeConfig,
        baseConfig: state.ManageWorkflowLabel.baseConfig,
        workerConfig: state.ManageWorkflowLabel.workerConfig,
        orderConfig: state.ManageWorkflowLabel.orderConfig,
        pointConfig: state.ManageWorkflowLabel.pointConfig,
        dataConfig: state.ManageWorkflowLabel.dataConfig,
        testConfig: state.ManageWorkflowLabel.testConfig,
        bindConfig: state.ManageWorkflowLabel.bindConfig,
        tabActive: state.ManageWorkflowLabel.tabActive,
    }));

    const [nodeData, setNodeData] = useState(null);
    const [labelTy, setLabelTy] = useState(null);

    // 기본 정보 설정
    const methodsBase = useForm({
        criteriaMode: "all",
        defaultValues: {}
    });
    const {
        control: controlBase, register: registerBase, setValue: setValueBase, getValues: getValuesBase, reset: resetBase, resetField: resetFieldBase
        , watch: watchBase, formState: {errors: errorsBase}, handleSubmit: handleSubmitBase
    } = methodsBase;

    // 라벨링 설정
    const methodsLabel = useForm({
        defaultValues: { }
    });
    const {
        control: controlLabel, register: registerLabel, unregister: unregisterLabel, setValue: setValueLabel, getValues: getValuesLabel, reset: resetLabel, resetField: resetFieldLabel
        , watch: watchLabel, formState: {errors: errorsLabel}, handleSubmit: handleSubmitLabel
    } = methodsLabel;

    // 작업자 설정
    const methodsWorker = useForm({
        criteriaMode: "all",
        defaultValues: {}
    });
    const {
        control: controlWorker, register: registerWorker, setValue: setValueWorker, getValues: getValuesWorker, reset: resetWorker, resetField: resetFieldWorker
        , watch: watchWorker, formState: {errors: errorsWorker}, handleSubmit: handleSubmitWorker
    } = methodsWorker;

    // 작업 순서
    const methodsOrder = useForm({
        criteriaMode: "all",
        defaultValues: {}
    });
    const {
        control: controlOrder, register: registerOrder, setValue: setValueOrder, getValues: getValuesOrder, reset: resetOrder, resetField: resetFieldOrder
        , watch: watchOrder, formState: {errors: errorsOrder}, handleSubmit: handleSubmitOrder
    } = methodsOrder;

    // 포인트 설정
    const methodsPoint = useForm({
        criteriaMode: "all",
        defaultValues: {}
    });
    const {
        control: controlPoint, register: registerPoint, setValue: setValuePoint, getValues: getValuesPoint, reset: resetPoint, resetField: resetFieldPoint
        , watch: watchPoint, formState: {errors: errorsPoint}, handleSubmit: handleSubmitPoint
    } = methodsPoint;

    // 미니테스트 설정
    const methodsTest = useForm({
        criteriaMode: "all",
        defaultValues: {}
    });
    const {
        control: controlTest, register: registerTest, setValue: setValueTest, getValues: getValuesTest, trigger: triggerTest, reset: resetTest, resetField: resetFieldTest
        , watch: watchTest, formState: {errors: errorsTest}, handleSubmit: handleSubmitTest
    } = methodsTest;

    const watchAllBase = watchBase();
    const watchAllLabel = watchLabel();
    const watchAllWorker = watchWorker();
    const watchAllPoint = watchPoint();

    // console.log('watchAllLabel', watchAllLabel);

    useEffect(() => {
        if (isLabelOffCanvas) {
            // 캔버스 열면 일단 리셋..
            // resetBase();
            // resetLabel();
            // resetWorker();
            // resetOrder();
            // resetPoint();
            // resetData();
            // resetTest();
            setcustomActiveTab("1");
        }
    }, [isLabelOffCanvas]);

    useEffect(()=>{
        resetBase();
        resetLabel();
        resetWorker();
        resetOrder();
        resetPoint();
        resetData();
        resetTest();
        dispatch(resetManageWorkflowLabelModuleConfig());
        if(selectionNode&&selectionNode.type == 'label'){
            let initModuleConfig = {};
            ModuleService.getManageModuleItem({moduleId: selectionNode.data.moduleId}).then((moduleResult) => {
                if (moduleResult.data.data) {
                    let resultData = moduleResult.data.data;

                    if(resultData.moduleConfig){
                        initModuleConfig = JSON.parse(resultData.moduleConfig);
                        dispatch(initManageWorkflowLabelModuleConfig(initModuleConfig));
                        setNodeData(resultData);

                        if(initModuleConfig.dataConfig) {
                            let tmpDataConfig = initModuleConfig.dataConfig;
                            const aData = tmpDataConfig.filter((use) => use.useYn === 'Y');
                            const iData = tmpDataConfig.filter((use) => use.useYn === 'N');
                            dispatch(setManageWorkflowLabelActiveData(aData));
                            dispatch(setManageWorkflowLabelInactiveData(iData));
                        }

                        // 분류, 요약 라벨링 설정 초기값 지정
                        if(isClsfcSmryType(canvasDataTy) && initModuleConfig.shapeConfig){
                            console.log("labels -> ", initModuleConfig.shapeConfig)
                            initModuleConfig.shapeConfig?.labels?.forEach(label => {
                                methodsLabel.setValue(`labels.${label.id}.id`, label.id);
                                methodsLabel.setValue(`labels.${label.id}.type`, label.type);
                                methodsLabel.setValue(`labels.${label.id}.tip`, label.tip);
                                methodsLabel.setValue(`labels.${label.id}.question`, label.question);
                                methodsLabel.setValue(`labels.${label.id}.order`, label.order);

                                methodsLabel.setValue(`labels.${label.id}.attributes[0].required`, label.attributes[0].required);
                                if(label.type === "clsfc"){
                                    methodsLabel.setValue(`labels.${label.id}.attributes[0].inputType`, label.attributes[0].inputType);
                                    const values = label?.attributes[0]?.values?.map((value, idx) => {
                                        return { id: value, text: value};
                                    }) || [];
                                    methodsLabel.setValue(`labels.${label.id}.attributes[0].values`, values);
                                }
                                if(label.type === "smry"){
                                    methodsLabel.setValue(`labels.${label.id}.inputExam`, label.inputExam);
                                    methodsLabel.setValue(`labels.${label.id}.regexp`, label.regexp);
                                    methodsLabel.setValue(`labels.${label.id}.attributes[0].min`, label.attributes[0].min);
                                    methodsLabel.setValue(`labels.${label.id}.attributes[0].max`, label.attributes[0].max);

                                }
                            });
                        }

                        if(resultData.sttusCd == '02' || selectionNode.data.sttusCd == '02'){
                            setDisabledOpenTask(true);
                        }else{
                            setDisabledOpenTask(false);
                        }
                    }
                }else{
                    // 실패
                }
            })
        }else{
            setNodeData(null);
        }
        return () => {
            methodsLabel.reset();
            dispatch(setManageWorkflowLabelSettingViewType(""));
            dispatch(setManageWorkflowLabelSettingRowId(null));
            dispatch(setManageWorkflowLabelActiveData([]));
            dispatch(setManageWorkflowLabelInactiveData([]));
        }
    }, [selectionNode]);

    useEffect(() => {
        if (canvasDataTy) {
            setLabelTy(canvasDataTy[1]);
        }
    }, [canvasDataTy])

    const getManageModuleItem = async (param)=>{
        // await ModuleService.getManageModuleItem(param).then((result) => {
        //     let moduleItem = result.data.data;
        //     let changeNode = {...selectionNode, data: {...selectionNode.data, moduleConfig: moduleItem.moduleConfig}}
        //     dispatch(manage_project_workflow_module_setSelectionNode(changeNode));
        //     dispatch(manage_project_workflow_module_updateModuleId(param.moduleId));
        // });

        await ModuleService.getManageModuleItem(param).then((result) => {
            let moduleItem = result.data.data;
            let getModuleConfig = JSON.parse(moduleItem.moduleConfig);
            let changeNode = {...selectionNode, data: {...selectionNode.data, moduleConfig: moduleItem.moduleConfig}};
            if(param.moduleConfig){
                let paramModuleConfig = JSON.parse(param.moduleConfig);
                let changeModuleConfig = JSON.parse(selectionNode.data.moduleConfig);
                for(const [key, value] of Object.entries(paramModuleConfig)) {
                    const getModuleValue = getModuleConfig[key];
                    changeModuleConfig = {...changeModuleConfig, [key]:getModuleValue};
                    if(key == 'shapeConfig'){
                        dispatch(setManageWorkflowLabelShapeConfig(getModuleValue));
                    }else if(key == 'baseConfig'){
                        dispatch(setManageWorkflowLabelBaseConfig(getModuleValue))
                    }else if(key == 'workerConfig'){
                        dispatch(setManageWorkflowLabelWorkerConfig(getModuleValue))
                    }else if(key == 'bindConfig'){
                        dispatch(setManageWorkflowLabelBindConfig(getModuleValue))
                    }else if(key == 'orderConfig'){
                        dispatch(setManageWorkflowLabelOrderConfig(getModuleValue))
                    }else if(key == 'pointConfig'){
                        dispatch(setManageWorkflowLabelPointConfig(getModuleValue))
                    }else if(key == 'dataConfig'){
                        dispatch(setManageWorkflowLabelDataConfig(getModuleValue))
                    }else if(key == 'testConfig'){
                        dispatch(setManageWorkflowLabelTestConfig(getModuleValue))
                    }
                }

                changeNode = {...selectionNode, data: {...selectionNode.data, moduleConfig: JSON.stringify(changeModuleConfig)}};
            }

            console.log('changeNode',changeNode);

            //dispatch(manage_project_workflow_module_setSelectionNode(changeNode));
            dispatch(manage_project_workflow_module_updateModuleId(param.moduleId));
        });
    }

    const updateModuleItem = async (param) => {
        const result = await ModuleService.updateModuleItem(param);
        return result;
    }

    const updateModuleItemWithFile = async (param) => {
        const result = await ModuleService.updateModuleItemWithFile(param, {contentType: 'multipart/form-data'});
        return result;
    }

    const updateOpenItem = async (param) => {
        const result = await ModuleService.updateOpenModuleItem(param);
        return result;
    }

    const updateLabelModuleItem = async(param) =>{
        console.log('updateLabelModuleItem param', param);
        const result = await updateModuleItem(param);
        if(result.data.data){
            alertSuccess('alert.saveSuccess','alert.saveSuccessMsg',null, null, intl);
            getManageModuleItem(param);
        }
    }

    const updateLabelModuleItemWithFile = async(param) =>{
        const result = await updateModuleItemWithFile(param);
        if(result.data.data){
            alertSuccess('alert.saveSuccess','alert.saveSuccessMsg',null, null, intl);
            let itemParam ={
                moduleId:param.moduleId,
                moduleConfig:param.moduleConfig
            };
            getManageModuleItem(itemParam);
        }
        return result;
    }

    const updateOpenModuleItem = async (param) =>{
        const result = await updateOpenItem(param);
        if (result.data.data) {
            alertSuccess('workflow.openSuccess', 'workflow.openSuccessMsg', null, null, intl);
            getManageModuleItem(param);
        }
    }

    const deleteModuleItemCheck = ()=>{
        if(selectionNode){
            const selectionNodeId = selectionNode.id;
            confirm("alert.deleteCheck"
                , "workflow.deleteModuleConfirm"
                ,()=>{
                    deleteModuleItem(selectionNodeId);
                }
                ,null
                , null
                , intl);
        }
    }

    const deleteModuleItem = async (moduleId) => {
        // TODO Authorization의 : accessToken을 세팅해야 한다.
        await ModuleService.deleteModuleItem({
            'moduleId': moduleId
        }).then((result)=>{
            if(result.data.success) {
                alertSuccess('alert.deleteSuccess','alert.deleteSuccessMsg',null, null, intl);
                dispatch(manage_project_workflow_module_deleteNodeId(moduleId));
            }else{
                alertError('error.E00002', '', null, null, result.data.errorMessage, intl);
            }
        });
    }

    const handleClickModuleNm = (event) => {
        setNodeData({...nodeData, 'editMode':true});
    }

    const handleUpdateModuleNm = (event)=>{
        const changeModuleNm = event.target.value.trim();
        if(changeModuleNm.length ==0){
            setNodeData({...nodeData, 'editMode':false});
            return;
        }
        isModuleNmDuplication(event, changeModuleNm);
    }

    const handleUpdateModuleNmKeyPress = (event) =>{
        if (event.key === 'Enter') {
            const changeModuleNm = event.target.value.trim();
            if(changeModuleNm.length ==0){
                setNodeData({...nodeData, 'editMode':false});
                return;
            }
            isModuleNmDuplication(event, changeModuleNm);
        }else{
            event.target.classList.remove('is-invalid');
            event.target.parentElement.children[1].classList.remove('invalid-tooltip');
            event.target.parentElement.children[1].innerText= '';
        }
    }

    // const handleChangeStandardTy = (event) =>{
    //     let standardTyVal = e.target.value;
    //     setValue('standardTyVal', standardTyVal);
    // }

    let isModuleNmDuplication =(event, changeModuleNm)=>{
        let dupResult = false;
        const state = store.getState();
        const nodes = Array.from(state.nodeInternals.values());
        const dupNode = nodes.find((c) => c.data&&c.data.moduleNm === changeModuleNm && c.id != selectionNode.id);
        if(dupNode!=undefined){
            dupResult = true;
            event.target.classList.add('is-invalid');
            event.target.parentElement.children[1].classList.add('invalid-tooltip');
            event.target.parentElement.children[1].innerText= GetMessageIntl(intl, "validation.duplication");
        }else{
            setNodeData({...nodeData, moduleNm: changeModuleNm, 'editMode':false});
            //setValue('moduleNm', changeModuleNm);
            const state = store.getState();
            const changeNodes = Array.from(state.nodeInternals.values());
            state.setNodes(
                changeNodes.map((node)=>{
                    if(node.id === selectionNode.id){
                        node.data.moduleNm = changeModuleNm;
                    }
                    return node;
                })
            );
            //dispatch(manage_project_workflow_module_setSelectionNode(changeNodes.find((c) => c.id === selectionNode.id)));
            dispatch(manage_project_workflow_module_setSelectionNodeNm(changeModuleNm));
            let param = {};
            param.moduleId = selectionNode.id;
            param.moduleNm = changeModuleNm;
            param.moduleTy = '00'; // 이름만 수정인 경우에는 moduleTy : 00
            updateModuleItem(param);
        }

        return dupResult;
    }

    const toggleTopCanvas = () => {
        dispatch(manage_project_workflow_module_setIsLabelOffcanvas(false));
    };

    const toggleCustom = (tab) => {
        if (customActiveTab !== tab) {
            setcustomActiveTab(tab);
        }
    };


    const onSubmitBase = async(data) => {
        console.log('태스크 기본정보 등록~~', data);
        let submitData = {};
        submitData.moduleId = nodeData.moduleId;
        submitData.moduleNm = nodeData.moduleNm;
        submitData.moduleTy = '01'; // 모듈 유형 라벨: 01 검수: 02

        submitData.baseConfig = {'taskNm': data.taskNm, 'taskDc': data.taskDc};
        submitData.moduleConfig = JSON.stringify({'baseConfig' :submitData.baseConfig});

        await  ModuleService.isModuleNmDuplication({
            'prjctId': prjctId,
            'moduleNm': submitData.moduleNm,
            'moduleTy': '01',
            'moduleId':submitData.moduleId
        }).then((result)=>{
            if(result.data.data){
                alertError("alert.dupModuleNm", '', null, null, null, intl);
            }else{
                confirm("alert.save"
                    , "workflow.saveMsg"
                    ,()=>{
                        updateLabelModuleItem(submitData);
                    }
                    ,null
                    , null
                    , intl);
            }
        });

        // await  ModuleService.isTaskNmDuplication({
        //     'prjctId': prjctId,
        //     'taskNm': data.taskNm,
        //     'moduleTy': '01',
        //     'moduleId':submitData.moduleId
        // }).then((result)=>{
        //     if(result.data.data){
        //         alertError("alert.dupTaskNm", '', null, null, null, intl);
        //     }else{
        //         confirm("alert.save"
        //             , "workflow.saveMsg"
        //             ,()=>{
        //                 updateLabelModuleItem(submitData);
        //             }
        //             ,null
        //             , null
        //             , intl);
        //     }
        // });
    };

    const onSubmitLabel = async (data) => {
        console.log('라벨링 설정 등록~~', data);
        let submitData = {};
        submitData.moduleId = nodeData.moduleId;
        submitData.moduleNm = nodeData.moduleNm;
        submitData.moduleTy = '01'; // 모듈 유형 라벨: 01 검수: 02

        let errorExist = false;
        // 분류, 요약일 때 분기
        if(isClsfcSmryType(canvasDataTy)) {

            // 분류, 요약 수동 Validation
            for (const labels of data?.labels) {
                delete labels.editable;
                delete labels.chosen;

                if (!labels.question || labels.question.length === 0 || data.labels?.filter(item => item.question === labels.question)?.length > 1) {
                    const isDuplicateName = data.labels?.filter(item => item.question === labels.question)?.length > 1;
                    methodsLabel.setError(`labels.${labels.id}.question`, {message : isDuplicateName ? "중복된 이름입니다." : "필수 입력 값입니다."});
                    errorExist = true;
                }

                if(labels.attributes[0].required !== 0 && labels.attributes[0].required !== 1){
                    methodsLabel.setError(`labels.${labels.id}.required`, {message: "필수 선택입니다."});
                    errorExist = true;
                }

                if(labels.type === "clsfc"){
                    if(labels.attributes[0]?.values?.length < 2){
                        methodsLabel.setError(`labels.${labels.id}.attributes`, {message: "속성을 최소 2개 이상 입력해주세요."});
                        errorExist = true;
                    }
                    if(!labels.attributes[0].inputType || labels.attributes[0].inputType.length === 0){
                        methodsLabel.setError(`labels.${labels.id}.inputType`, {message : "필수 선택입니다."})
                        errorExist = true;
                    }
                }else if(labels.type === "smry"){
                    if(labels.attributes[0].min >= labels.attributes[0].max){
                        methodsLabel.setError(`labels.${labels.id}.minMax`, {message: "최소 글자가 최대 글자보다 클 수 없습니다."});
                        errorExist = true;
                    }
                }
            }

            if(errorExist) {
                console.log("오류 발생해서 모두 정지")
                return;
            }

            submitData.shapeConfig = parseLabels(methodsLabel.getValues());
            submitData.moduleConfig = JSON.stringify({'shapeConfig' :submitData.shapeConfig})
            await  ModuleService.isModuleNmDuplication({
                'prjctId': prjctId,
                'moduleNm': submitData.moduleNm,
                'moduleTy': '01',
                'moduleId':submitData.moduleId
            }).then((result)=>{
                if(result.data.data){
                    alertError("alert.dupModuleNm", '', null, null, null, intl);
                }else{
                    confirm("alert.save"
                        , "workflow.saveMsg"
                        ,()=>{
                            updateLabelModuleItem(submitData);
                        }
                        ,null
                        , null
                        , intl);
                }
            });

            console.log("submitData : ", submitData);
        }else{
            submitData.shapeConfig = shapeConfig;

            let countLimit = {objectMin: data.objectMin !=''?data.objectMin:null, objectMax:data.objectMax!=''?data.objectMax:null};

            // 비어져 있는 Array의 항목은 삭제
            let labels = data.classItem?data.classItem.map(item => {
                return {...item, attributes: item.attributes?item.attributes.filter(attribute=> attribute!=undefined):[]};
            }):[];

            // cvat에서 설정해줘야 하는 부분이라 넣어줌. 추후에 필요 없으면 해당 부분 삭제.
            labels = labels.map(item => {
                return {...item, id: Number(item.id), projectId: 1, parentId: null, hasParent: false,
                    attributes: item.attributes?item.attributes.map(attribute=> {return {...attribute, id:Number(attribute.id)}}):[]
                };
            });


            submitData.shapeConfig = {...submitData.shapeConfig, countLimit: countLimit, labels: labels.filter(label=> label!=undefined)};
            submitData.moduleConfig = JSON.stringify({'shapeConfig' :submitData.shapeConfig});

            await  ModuleService.isModuleNmDuplication({
                'prjctId': prjctId,
                'moduleNm': submitData.moduleNm,
                'moduleTy': '01',
                'moduleId':submitData.moduleId
            }).then((result)=>{
                if(result.data.data){
                    alertError("alert.dupModuleNm", '', null, null, null, intl);
                }else{
                    confirm("alert.save"
                        , "workflow.saveMsg"
                        ,()=>{
                            updateLabelModuleItem(submitData);
                        }
                        ,null
                        , null
                        , intl);
                }
            });
        }
    };

    const onHandleErrorLabel = (error) => {
        console.log("error 발생 ! ", methodsLabel.formState.errors)
    }


    const onSubmitWorker = async(data) => {
        console.log('라벨링 작업자 설정 등록~~', data);
        let submitData = {};
        submitData.moduleId = nodeData.moduleId;
        submitData.moduleNm = nodeData.moduleNm;
        submitData.moduleTy = '01'; // 모듈 유형 라벨: 01, 검수: 02

        submitData.workerConfig = {standardTy : data.standardTy};
        if(data.standardTy === '01'){
            submitData.workerConfig = {...submitData.workerConfig, limitTy: data.limitTy}
            if(data.limitTy.includes('00')) {
                // 직접 지정
                submitData.workerConfig = {...submitData.workerConfig, conditionEmails: data.conditionEmails}
            }

            if(data.limitTy.includes('01')){
                // 조건 설정
                if(data.groupConditions.length>0){
                    // 비어져 있는 Array의 항목은 삭제
                    let groupConditions = data.groupConditions.map(groupCondition => {
                        return {...groupCondition, conditions: groupCondition.conditions.filter(condition=> condition!=undefined)};
                    });
                    submitData.workerConfig = {...submitData.workerConfig, groupConditions: groupConditions.filter(groupCondition=> groupCondition!=undefined)};
                }
            }
        }

        submitData.moduleConfig = JSON.stringify({'workerConfig' :submitData.workerConfig});

        await  ModuleService.isModuleNmDuplication({
            'prjctId': prjctId,
            'moduleNm': submitData.moduleNm,
            'moduleTy': '01',
            'moduleId':submitData.moduleId
        }).then((result)=>{
            console.log(result.data);
            if(result.data.data){
                alertError("alert.dupModuleNm", '', null, null, null, intl);
            }else{
                confirm("alert.save"
                    , "workflow.saveMsg"
                    ,()=>{
                        updateLabelModuleItem(submitData);
                    }
                    ,null
                    , null
                    , intl);
            }
        });
    };

    const onSubmitOrder = async(data) => {
        console.log('라벨링 작업 순서 등록~~', data);
        let submitData = {};
        submitData.moduleId = nodeData.moduleId;
        submitData.moduleNm = nodeData.moduleNm;
        submitData.moduleTy = '01'; // 모듈 유형 라벨: 01 검수: 02

        console.log(data.orderConfigList);

        submitData.bindConfig = {'dataBindType': data.dataBindType, 'dataBindUnitCount': 1};
        submitData.orderConfig = data.orderConfigList?{orderConfigList: data.orderConfigList}:null;

        submitData.moduleConfig = JSON.stringify({'bindConfig' :submitData.bindConfig, 'orderConfig' :submitData.orderConfig});

        await  ModuleService.isModuleNmDuplication({
            'prjctId': prjctId,
            'moduleNm': submitData.moduleNm,
            'moduleTy': '01',
            'moduleId':submitData.moduleId
        }).then((result)=>{
            if(result.data.data){
                alertError("alert.dupModuleNm", '', null, null, null, intl);
            }else{
                confirm("alert.save"
                    , "workflow.saveMsg"
                    ,()=>{
                        updateLabelModuleItem(submitData);
                    }
                    ,null
                    , null
                    , intl);
            }
        });
    };

    const onSubmitPoint = async(data) => {
        console.log('라벨링 포인트 설정 등록~~', data);
        let submitData = {};
        submitData.moduleId = nodeData.moduleId;
        submitData.moduleNm = nodeData.moduleNm;
        submitData.moduleTy = '01'; // 모듈 유형 라벨: 01

        submitData.pointConfig = {pointPayTy : data.pointPayTy};

        if(data.pointPayTy === '00'||data.pointPayTy === '01'){
            submitData.pointConfig = {...submitData.pointConfig, payPoint: parseInt(data.payPoint)}
        }else{
            if(data.limitPoint){
                submitData.pointConfig = {...submitData.pointConfig, limitPoint: parseInt(data.limitPoint)};
            }
            if(data.instance.length>0){
                data.instance = data.instance.map(item=> {return {id: item.id, type: item.type, value: parseInt(item.value)}});
                // 비어져 있는 Array의 항목은 삭제
                submitData.pointConfig = {...submitData.pointConfig, instance: data.instance.filter(item=> item!=undefined && item.id !=undefined)};
            }
        }

        submitData.moduleConfig = JSON.stringify({'pointConfig' :submitData.pointConfig});
        await  ModuleService.isModuleNmDuplication({
            'prjctId': prjctId,
            'moduleNm': submitData.moduleNm,
            'moduleTy': '01',
            'moduleId':submitData.moduleId
        }).then((result)=>{
            console.log(result.data);
            if(result.data.data){
                alertError("alert.dupModuleNm", '', null, null, null, intl);
            }else{
                confirm("alert.save"
                    , "workflow.saveMsg"
                    ,()=>{
                        updateLabelModuleItem(submitData);
                    }
                    ,null
                    , null
                    , intl);
            }
        });

    };


    // 자료 등록
    const methodsData = useForm({
        criteriaMode: "all",
        defaultValues: {}
    });
    let {
        control: controlData, register: registerData, setValue: setValueData, getValues: getValuesData, reset: resetData, resetField: resetFieldData
        , watch: watchData, formState: {errors: errorsData}, handleSubmit: handleSubmitData
    } = methodsData;

    const { targetFiles, refreshFlag, selectedId, activeData, inactiveData } = useSelector((state) => ({
        targetFiles: state.ManageWorkflowLabel.files,
        refreshFlag: state.ManageWorkflowLabel.refreshFlag,
        selectedId: state.ManageWorkflowLabel.selectedId,
        activeData: state.ManageWorkflowLabel.activeData,
        inactiveData: state.ManageWorkflowLabel.inactiveData,
    }))

    const onSubmitData = async (data) => {
        console.log('라벨링 자료 등록~~', data);
        dispatch(setManageWorkflowLabelDataError([]))
        let filteredByUseYn = dataConfig.filter((item) => item.useYn !== "N");
        const submitData = new FormData();
        if(targetFiles){
            console.log("targetFiles =>", targetFiles)
            Object.keys(targetFiles).forEach(key => {
                const items = targetFiles[key];
                for(let i = 0; i < items.length; i++){
                    submitData.append("files[" + key + "].dataAttachFile[]", items[i].file)
                }
                submitData.append("files["+key+"].id", key)
            });
        }

        submitData.moduleId = nodeData.moduleId;
        submitData.moduleNm = nodeData.moduleNm;

        let errorsData = {
            dataItems: []
        };
        errorsData.dataItems = [];

        // 활성 자료
        if(filteredByUseYn){
            filteredByUseYn.forEach((config, idx) => {
                const targetId = config.id;
                if(config.title === "" || null){
                    errorsData.dataItems.push({
                        error: 'title',
                        id: targetId
                    });
                    console.log(`Empty Title Data id: ${config.id}, idx: ${idx + 1}`);
                }

                if(config.type=='form'){
                    if(config.body && (!config.body.description || config.body.description =='')){
                        errorsData.dataItems.push( {
                            error: 'description',
                            id: targetId
                        });
                    }

                    if(config.body && config.body.content){
                        config.body.content.forEach((content)=>{
                            if(content.type ===''){
                                errorsData.dataItems.push( {
                                    error: 'type',
                                    contentId: content.id,
                                    id: targetId
                                });
                            }

                        })
                    }

                    console.log(`Empty Title Data id: ${config.id}, idx: ${idx + 1}`);
                }
            })
        }

        if(errorsData.dataItems.length < 1){
            // Validation 통과
            await  ModuleService.isModuleNmDuplication({
                'prjctId': prjctId,
                'moduleNm': submitData.moduleNm,
                'moduleTy': '01',
                'moduleId':submitData.moduleId
            }).then((result)=>{
                if(result.data.data){
                    alertError("alert.dupModuleNm", '', null, null, null, intl);
                }else{
                    confirm("alert.save"
                        , "workflow.saveMsg"
                        ,()=>{
                            const moduleConfig = {
                                dataConfig
                            };

                            submitData.append("moduleId", selectionNode.id);
                            submitData.append("moduleTy", "01");
                            submitData.append("moduleConfig", JSON.stringify(moduleConfig));
                            submitData.moduleConfig = JSON.stringify(moduleConfig);

                            const result = updateLabelModuleItemWithFile(submitData);
                            result.then(res=>{
                                /*
                                    DeleteFiles 와 Body.AttachFiles 비교
                                    DeleteFiles 에 있던 AttachFIles 내용 없애기
                                 */
                                function updateData(cnData) {
                                    const result = cnData.map(data => {
                                        if (!data.deleteFiles) {
                                            const {deleteFiles, ...rest} = data;
                                            return rest;
                                        }

                                        const filteredAttachFiles = data.body.attachFiles.filter(attachFile =>
                                            !data.deleteFiles.some(deleteFile => deleteFile.realFilename === attachFile.realFilename)
                                        );

                                        const {deleteFiles, ...rest} = data;

                                        return {
                                            ...rest,
                                            body: {
                                                ...data.body,
                                                attachFiles: filteredAttachFiles
                                            }
                                        };
                                    });
                                    return result;
                                }
                                dispatch(setManageWorkflowLabelActiveData(updateData(activeData)));
                                dispatch(setManageWorkflowLabelInactiveData(updateData(inactiveData)));

                                dispatch(setManageWorkflowLabelFilesReset());
                                dispatch(switchManageWorkflowLabelRegistDataRefreshFlag());
                            });

                        }
                        ,null
                        , null
                        , intl);
                }
            });
        }



        dispatch(setManageWorkflowLabelDataError(errorsData));
        console.log("submitData => ", submitData)
    };

    const {
        files,
        fileLabels,
        fileAttributes,
        removeFiles,
        hasErrorFiles
    } = useSelector((state) => ({
        files: state.WorkflowLabelTestConfig.files,
        fileLabels: state.WorkflowLabelTestConfig.fileLabels,
        fileAttributes: state.WorkflowLabelTestConfig.fileAttributes,
        removeFiles: state.WorkflowLabelTestConfig.removeFiles,
        hasErrorFiles: state.WorkflowLabelTestConfig.hasErrorFiles
    }));


    const onSubmitTest = async() => {

        let data = getValuesTest();
        // console.log('검수 미니테스트 등록~~1', data);
        // console.log('검수 미니테스트 등록~~2', files);
        // console.log('검수 미니테스트 등록~~3', fileLabels);
        // console.log('검수 미니테스트 등록~~4', fileAttributes);
        dispatch(workflow_label_testConfig_clearHasErrorFiles(null))
        const submitData = new FormData();
        const deleteFiles = [...removeFiles];
        let hasErrorCount = 0;
        const questions = fileAttributes.map(attrItems => {
            const labelId = fileLabels[attrItems.fileName];
            const attrArray = Array.from(attrItems.values);
            const labelSetting = shapeConfig.labels.filter(item => item.id === labelId);
            if (Object.entries(attrItems.values).length == 0) {
                if (labelSetting[0].attributes.length !== 0) {
                    hasErrorCount++;
                    dispatch(workflow_label_testConfig_setHasErrorFiles(attrItems.fileName))
                }
            } else {
                if (labelSetting[0].attributes.length !== 0) {
                    labelSetting[0].attributes.map(item => {
                        if (item.required) {
                            if (!attrItems.values[item.id]) {
                                hasErrorCount++;
                                dispatch(workflow_label_testConfig_setHasErrorFiles(attrItems.fileName))
                            }
                        }
                        if (item.regexp) {
                            if (attrItems.values[item.id]) {
                                let regexp = new RegExp(item.regexp)
                                if (!(regexp.test(attrItems.values[item.id]))) {
                                    hasErrorCount++;
                                    dispatch(workflow_label_testConfig_setHasErrorFiles(attrItems.fileName))
                                }
                            }
                        }
                    })
                }
            }
            if (attrItems.rejectReason.length === 0) {
                hasErrorCount++;
                dispatch(workflow_label_testConfig_setHasErrorFiles(attrItems.fileName))
            }

            return {
                "labels": labelId,
                "attributes": attrItems.values,
                "image": {
                    "realFileName": "",
                    "isNew": true
                },
                "comment": attrItems.comment,
                "rejectReason": attrItems.rejectReason
            }
        });

        const result = await triggerTest();
        //console.log('validation result == ',result , hasErrorCount);
        if(!result || hasErrorCount>0){
            return;
        }

        const moduleConfig = {
            "testConfig": {
                "title": data.title.trim(),
                "questionCount": parseInt(data.questionCount),
                "passCount": parseInt(data.passCount),
                "questionOrderTy": data.questionOrderTy,
                "desc": data.desc,
                "deleteFiles": deleteFiles,
                "questions": questions
            }
        };

        for (let i = 0; i < files.length; i++) {
            let f = files[i];
            if (f.isNew) {
                // 신규로 추가된 파일 항목만  전송 파일 목록에 담는다.
                submitData.append("testAttachFile[]", files[i])
                moduleConfig.testConfig.questions[i].image.realFileName = f.name;
            } else {
                moduleConfig.testConfig.questions[i].image.realFileName = f.realFileName;
                moduleConfig.testConfig.questions[i].image.host = f.host;
                moduleConfig.testConfig.questions[i].image.url = f.url;
                moduleConfig.testConfig.questions[i].image.isNew = false;
            }
        }

        submitData.moduleId = nodeData.moduleId;
        submitData.moduleNm = nodeData.moduleNm;
        submitData.moduleConfig = JSON.stringify(moduleConfig);

        submitData.append("moduleId", selectionNode.id);
        submitData.append("moduleTy", "01");
        submitData.append("moduleConfig", JSON.stringify(moduleConfig));
        //console.log(JSON.stringify(moduleConfig))
        if (hasErrorCount === 0) {
            await  ModuleService.isModuleNmDuplication({
                'prjctId': prjctId,
                'moduleNm': submitData.moduleNm,
                'moduleTy': '01',
                'moduleId':submitData.moduleId
            }).then((result)=>{
                if(result.data.data){
                    alertError("alert.dupModuleNm", '', null, null, null, intl);
                }else{
                    confirm("alert.save"
                        , "workflow.saveMsg"
                        , () => {
                            updateLabelModuleItemWithFile(submitData);
                        }
                        , null
                        , null
                        , intl);
                }
            });

        } else {
            console.log('오류가 있네여')
        }
    };

    const openTask = ()=>{
        console.log('오픈 할까요?')
        let requiredConfig = [];
        if(baseConfig == null){
            requiredConfig.push(GetMessageIntl(intl, "workflow.basicInfoSetting"))
        }

        if(shapeConfig == null || shapeConfig.labels?.length==0){
            requiredConfig.push(GetMessageIntl(intl, "workflow.labelSetting"))
        }

        if(workerConfig == null){
            requiredConfig.push(GetMessageIntl(intl, "workflow.workerSetting"))
        }

        if(bindConfig == null){
            if(!requiredConfig.includes(GetMessageIntl(intl, "workflow.workSeqSetting"))){
                requiredConfig.push(GetMessageIntl(intl, "workflow.workSeqSetting"))
            }
        }

        if(orderConfig == null){
            requiredConfig.push(GetMessageIntl(intl, "workflow.workSeqSetting"))
        }

        if(pointConfig == null
            || ((methodsPoint.getValues('pointPayTy') === '00'||methodsPoint.getValues('pointPayTy') === '01' ) &&
                    methodsPoint.getValues('payPoint') === '')
            || ((methodsPoint.getValues('pointPayTy') != '00'&&methodsPoint.getValues('pointPayTy') != '01' ) &&
                methodsPoint.getValues('data.instance.lengt')?.length ==0)
        ){
            requiredConfig.push(GetMessageIntl(intl, "workflow.pointSetting"))
        }

        let contentError = 0;
        if(dataConfig == null || dataConfig.length==0){
            requiredConfig.push(GetMessageIntl(intl, "workflow.dataSetting"))
        }else{
            // dataConfig 내부 에디터, 체크박스 내용 미존재시 내용 없습니다 경고
            dataConfig.map((item) => {
                if(item.type === "editor" || item.type === "checkbox"){
                    if(item.body.content === ""){
                        contentError += 1;
                    }
                }else if(item.type === "form"){
                    if(item.body.content.length < 1){
                        contentError += 1;
                    }
                }
            })
        }

        if(testConfig == null && (tabActive && tabActive.testConfig === true)){
            requiredConfig.push(GetMessageIntl(intl, "workflow.miniTestSetting"))
        }

        if(requiredConfig.length > 0){
            alertWarning("workflow.noOpenConfig", "workflow.noOpenConfigMsg", ()=>{
                console.log("확인 버튼");
            }, {'requiredConfig': requiredConfig.join(',')}, intl)
        }else{
            if(contentError > 0){ // dataConfig 오류 있을 때
                alertWarning("workflow.dataConfig.contentError", "workflow.dataConfig.contentErrorMsg", ()=>{
                    console.log("dataConfig body에 빈 값 있어서 오픈 불가");
                },null, intl)
            }else{
                let submitData = {};
                submitData.moduleId = nodeData.moduleId;
                confirm("workflow.open"
                    , "workflow.openMsg"
                    , () => {
                        updateOpenModuleItem(submitData);
                    }
                    , null
                    , null
                    , intl);
            }
        }
    }

    return (
        <div>
            <Offcanvas isOpen={isLabelOffCanvas} toggle={toggleTopCanvas} id="offcanvasLabel" style={{top:"70px",border:"1px solid var(--vz-border-color)"}} scrollable={false} backdrop={false} keyboard={false} direction="end">
                <div className="offcanvas-header">
                    <h5 className="offcanvas-title">
                        {nodeData? nodeData.editMode?
                                <>
                                    <input type="text" defaultValue={nodeData.moduleNm} className="form-control editable tx-20 pd-x-0-f pd-y-3 fw-semibold lh-19"
                                           maxLength={50}
                                           style={{minWidth: 250, width: 12 * nodeData.moduleNm.length}}
                                           onBlur={handleUpdateModuleNm}
                                           onKeyUp={handleUpdateModuleNmKeyPress}
                                           autoFocus />
                                    <p className="mb-0 validation-error-message"></p>
                                </>
                                :
                                <div className="d-flex align-items-center">
                                    {nodeData.moduleNm}
                                    <i className="ri-edit-line text-coco-primary-100 fw-normal" style={{marginLeft:'10.5px'}} onClick={handleClickModuleNm}></i>
                                </div>
                            : null
                        }
                    </h5>
                    <i className="ri-close-line text-coco-default-white tx-20 text-pointer lh-24" onClick={toggleTopCanvas}/>
                </div>
                <div className="pd-x-12 bg-coco-gray-50">
                    <Nav tabs className="nav nav-coco2 nav-tabs nav-tabs-custom">
                        <NavItem>
                            <NavLink
                                style={{ cursor: "pointer" }}
                                className={classnames({
                                    active: customActiveTab === "1",
                                })}
                                onClick={() => {
                                    toggleCustom("1");
                                }}
                            >
                                {GetMessage("workflow.basicInfoSetting")}
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                style={{ cursor: "pointer" }}
                                className={classnames({
                                    active: customActiveTab === "2",
                                })}
                                onClick={() => {
                                    toggleCustom("2");
                                }}
                            >
                                {GetMessage("workflow.labelSetting")}
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                style={{ cursor: "pointer" }}
                                className={classnames({
                                    active: customActiveTab === "3",
                                })}
                                onClick={() => {
                                    toggleCustom("3");
                                }}
                            >
                                {GetMessage("workflow.workerSetting")}
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                style={{ cursor: "pointer" }}
                                className={classnames({
                                    active: customActiveTab === "4",
                                })}
                                onClick={() => {
                                    toggleCustom("4");
                                }}
                            >
                                {GetMessage("workflow.workSeqSetting")}
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                style={{ cursor: "pointer" }}
                                className={classnames({
                                    active: customActiveTab === "5",
                                })}
                                onClick={() => {
                                    toggleCustom("5");
                                }}
                            >
                                {GetMessage("workflow.pointSetting")}
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                style={{ cursor: "pointer" }}
                                className={classnames({
                                    active: customActiveTab === "6",
                                })}
                                onClick={() => {
                                    toggleCustom("6");
                                }}
                            >
                                {GetMessage("workflow.dataSetting")}
                            </NavLink>
                        </NavItem>
                        {
                            tabActive && tabActive.testConfig === true?
                                <NavItem>
                                    <NavLink
                                        style={{ cursor: "pointer" }}
                                        className={classnames({
                                            active: customActiveTab === "7",
                                        })}
                                        onClick={() => {
                                            toggleCustom("7");
                                        }}
                                    >
                                        {GetMessage("workflow.miniTestSetting")}
                                    </NavLink>
                                </NavItem>
                            :
                                <></>
                        }
                    </Nav>
                </div>
                <TabContent
                    activeTab={customActiveTab}
                    className="text-muted"
                >
                    <TabPane tabId="1" id="taskTab">
                        <FormProvider {...methodsBase}>
                            <form onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    e.preventDefault()
                                }
                            }}>
                                <BaseConfigDiv/>
                                {/*<OffCanvasFooter deleteModuleItemCheck={deleteModuleItemCheck} handleSubmit={onSubmitBase} openTask={openTask} disabledOpenTask={disabledOpenTask} hookMethod={methodsBase} />*/}
                                <div className="offcanvas-footer justify-content-between">
                                    <div className="">
                                        <button className="btn btn-coco-3 mr-16" type="button" onClick={()=>openTask()} disabled={disabledOpenTask}>
                                            {GetMessageIntl(intl, "common.open")}
                                        </button>
                                    </div>
                                    <div className="d-flex align-items-center justify-content-end">
                                        <button className="btn btn-coco-1 mr-16" type="button" onClick={methodsBase.handleSubmit(onSubmitBase)}>
                                            {GetMessageIntl(intl,"common.save")}
                                        </button>
                                        <button type="button" className="btn btn-coco-icon-1" onClick={deleteModuleItemCheck}>
                                            <i className="ri-delete-bin-line"></i>
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </FormProvider>
                    </TabPane>
                    <TabPane tabId="2" id="settingTab">
                        <FormProvider {...methodsLabel}>
                            <form
                                onSubmit={(e) => {
                                    e.preventDefault();
                                    if(isClsfcSmryType(canvasDataTy)){
                                        methodsLabel.clearErrors();
                                    }
                                    methodsLabel.handleSubmit(onSubmitLabel, onHandleErrorLabel)(e);
                                }}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        e.preventDefault()
                                    }
                            }}>
                                {
                                    isClsfcSmryType(canvasDataTy) && <PickAndContent canvasDataTy={canvasDataTy} method={methodsLabel} shapeConfig={shapeConfig}/>
                                }
                                {
                                    !isClsfcSmryType(canvasDataTy) && <ShapeConfigDiv/>
                                }
                                <div className="offcanvas-footer justify-content-between">
                                    <div className="">
                                        <button className="btn btn-coco-3 mr-16" type="button" onClick={()=>openTask()} disabled={disabledOpenTask}>
                                            {GetMessageIntl(intl, "common.open")}
                                        </button>
                                    </div>
                                    <div className="d-flex align-items-center justify-content-end">
                                        <button className="btn btn-coco-1 mr-16" type="submit">
                                            {GetMessageIntl(intl, "common.save")}
                                        </button>
                                        <button type="button" className="btn btn-coco-icon-1" onClick={deleteModuleItemCheck}>
                                            <i className="ri-delete-bin-line"></i>
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </FormProvider>
                    </TabPane>

                    <TabPane tabId="3" id="workerTab">
                        <FormProvider {...methodsWorker}>
                            <form>
                                <WorkerConfigDiv canvasDataTy={canvasDataTy} />
                                <div className="offcanvas-footer justify-content-between">
                                    <div className="">
                                        <button className="btn btn-coco-3 mr-16" type="button" onClick={()=>openTask()} disabled={disabledOpenTask}>
                                            {GetMessageIntl(intl, "common.open")}
                                        </button>
                                    </div>
                                    <div className="d-flex align-items-center justify-content-end">
                                        <button className="btn btn-coco-1 mr-16" type="button" onClick={methodsWorker.handleSubmit(onSubmitWorker)}>
                                            {GetMessageIntl(intl, "common.save")}
                                        </button>
                                        <button type="button" className="btn btn-coco-icon-1" onClick={deleteModuleItemCheck}>
                                            <i className="ri-delete-bin-line"></i>
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </FormProvider>
                    </TabPane>
                    <TabPane tabId="4" id="orderTab">
                        <FormProvider {...methodsOrder}>
                            <form>
                                <OrderConfigDiv canvasDataTy={canvasDataTy}/>
                                <div className="offcanvas-footer justify-content-between">
                                    <div className="">
                                        <button className="btn btn-coco-3 mr-16" type="button" onClick={()=>openTask()} disabled={disabledOpenTask}>
                                            {GetMessageIntl(intl, "common.open")}
                                        </button>
                                    </div>
                                    <div className="d-flex align-items-center justify-content-end">
                                        <button className="btn btn-coco-1 mr-16" type="button" onClick={methodsOrder.handleSubmit(onSubmitOrder)}>
                                            {GetMessageIntl(intl, "common.save")}
                                        </button>
                                        <button type="button" className="btn btn-coco-icon-1" onClick={deleteModuleItemCheck}>
                                            <i className="ri-delete-bin-line"></i>
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </FormProvider>
                    </TabPane>
                    <TabPane tabId="5" id="pointTab">
                        <FormProvider {...methodsPoint}>
                            <form onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    e.preventDefault()
                                }
                            }}>
                                {!isClsfcSmryType(canvasDataTy) ? <PointConfigDiv/> : <PointClsfcSmryConfigDiv />}
                                <div className="offcanvas-footer justify-content-between">
                                    <div className="">
                                        <button className="btn btn-coco-3 mr-16" type="button" onClick={()=>openTask()} disabled={disabledOpenTask}>
                                            {GetMessageIntl(intl, "common.open")}
                                        </button>
                                    </div>
                                    <div className="d-flex align-items-center justify-content-end">
                                        <button className="btn btn-coco-1 mr-16" type="button" onClick={methodsPoint.handleSubmit(onSubmitPoint)}>
                                            {GetMessageIntl(intl, "common.save")}
                                        </button>
                                        <button type="button" className="btn btn-coco-icon-1" onClick={deleteModuleItemCheck}>
                                            <i className="ri-delete-bin-line"></i>
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </FormProvider>
                    </TabPane>
                    <TabPane tabId="6" id="dataTab">
                        <FormProvider {...methodsData}>
                            <form>
                                <DataConfigDiv/>
                                <div className="offcanvas-footer justify-content-between">
                                    <div className="">
                                        <button className="btn btn-coco-3 mr-16" type="button" onClick={()=>openTask()} disabled={disabledOpenTask}>
                                            {GetMessageIntl(intl, "common.open")}
                                        </button>
                                    </div>
                                    <div className="d-flex align-items-center justify-content-end">
                                        <button className="btn btn-coco-1 mr-16" type="button" onClick={methodsData.handleSubmit(onSubmitData)} >
                                            {GetMessageIntl(intl, "common.save")}
                                        </button>
                                        <button type="button" className="btn btn-coco-icon-1" onClick={deleteModuleItemCheck}>
                                            <i className="ri-delete-bin-line"></i>
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </FormProvider>
                    </TabPane>
                    {
                        tabActive && tabActive.testConfig === true?
                            <TabPane tabId="7" id="testTab">
                                <FormProvider {...methodsTest}>
                                    {/*<form onSubmit={methodsTest.handleSubmit(onSubmitTest)} onKeyDown={(e) => {*/}
                                    <form>
                                        <TestConfigDiv/>
                                        <div className="offcanvas-footer justify-content-between">
                                            <div className="">
                                                <button className="btn btn-coco-3 mr-16" type="button" onClick={()=>openTask()} disabled={disabledOpenTask}>
                                                    {GetMessageIntl(intl, "common.open")}
                                                </button>
                                            </div>
                                            <div className="d-flex align-items-center justify-content-end">
                                                <button className="btn btn-coco-1 mr-16" type="button" onClick={onSubmitTest}>
                                                    {GetMessageIntl(intl, "common.save")}
                                                </button>
                                                <button type="button" className="btn btn-coco-icon-1" onClick={deleteModuleItemCheck}>
                                                    <i className="ri-delete-bin-line"></i>
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                </FormProvider>
                            </TabPane>
                            :
                            <></>
                    }

                </TabContent>
            </Offcanvas>

        </div>
    );
};
