import SimpleBar from "simplebar-react";
import {Card, CardBody, CardHeader, Col, Input, Label, ListGroup, OffcanvasBody, Row} from "reactstrap";
import {GetMessageIntl} from "../../../../../../../../util/message";
import {Controller, useFormContext} from "react-hook-form";
import React, {useEffect, useState} from "react";
import {useIntl} from "react-intl";
import {useDispatch, useSelector} from "react-redux";

function PointConfigDiv() {
    const intl = useIntl();
    const dispatch = useDispatch();
    const prjctId = sessionStorage.getItem("prjctId");
    const { control:controlPoint, register:registerPoint, unregister:unregisterPoint, setValue:setValuePoint, getValues:getValuesPoint, reset:resetPoint, resetField:resetFieldPoint
        , watch:watchPoint, formState: { errors:errorsPoint },} = useFormContext();
    const watchAllPoint = watchPoint();
    const [disabledFolder, setDisabledFolder] = useState(false);

    const {
        initFlag,
        selectionNode,
        pointConfig,
        bindConfig} = useSelector((state) => ({
        initFlag: state.ManageWorkflowReview.initFlag,
        selectionNode: state.ManageWorkflowModule.selectionNode,
        pointConfig: state.ManageWorkflowReview.pointConfig,
        bindConfig: state.ManageWorkflowReview.bindConfig
    }));

    useEffect(()=>{
        if(initFlag) {
            resetPoint();
            setDisabledFolder(false);
            setValuePoint('pointPayTy', '00');
            if (pointConfig) {
                for (const [key, value] of Object.entries(pointConfig)) {
                    //setValuePoint(key, (value ? value : ""));
                    setValuePoint(key, (value ? value.toLocaleString() : ""));
                }
            }

            if (bindConfig) {
                if (bindConfig.dataBindType == '00') {
                    // 데이터(파일) 할당
                    // 폴더 포인트 설정으로 못하게
                    setDisabledFolder(true);
                    setValuePoint('pointPayTy', '00');
                    if (pointConfig && pointConfig.pointPayTy && pointConfig.pointPayTy != '00') {
                        setValuePoint('payPoint', '');
                    }

                }/*else if(bindConfig.dataBindType == '01'){
                    // 데이터(파일) 할당
                    setDisabledFolder(false);
                }*/

            }
        }
    }, [initFlag, pointConfig, bindConfig]);

    const numberFormatter = (value, key) => {
        const numberValue = value.replace(/,/g, "");
        if (!isNaN(Number(numberValue)) && Number.isFinite(+numberValue)) {
            const formattedValue = Number(numberValue).toLocaleString("ko-Kr");
            setValuePoint(key, formattedValue
                // , {shouldDirty: true, shouldValidate: true}
            );
        }
    }


    return(
        <>
            <SimpleBar style={{ maxHeight: "calc(100vh - 229px)" }} autoHide={false} className="simplebar-track-coco">
                <OffcanvasBody style={{ minHeight: "300px" }} >
                    <h5 className="module-title">
                        <i className="ri-money-dollar-circle-line"></i>{GetMessageIntl(intl,"workflow.pointPaySetting")}
                    </h5>
                    {
                        bindConfig?
                            <>
                                <Row>
                                    <Col md={12}>
                                        <Label htmlFor="standardTy1" className="form-label">{GetMessageIntl(intl,"workflow.pointPayStandard")}<span className="text-coco-danger-400">*</span></Label>
                                        <div className="ht-38 d-flex align-items-center">
                                            <div className="form-check form-radio-coco form-check-inline">
                                                <input className="form-check-input" type="radio" id="pointPayTy1" value="00" {...registerPoint("pointPayTy")}/>
                                                <Label className="form-check-label mb-0" htmlFor="pointPayTy1">
                                                    {GetMessageIntl(intl,"workflow.pointFile")}
                                                </Label>
                                            </div>
                                            <div className="form-check form-radio-coco form-check-inline">
                                                <input className="form-check-input" type="radio" id="pointPayTy2"  value="01" {...registerPoint("pointPayTy")} disabled={disabledFolder}/>
                                                <Label className="form-check-label mb-0" htmlFor="pointPayTy2">
                                                    {GetMessageIntl(intl,"workflow.dataFolder")}
                                                </Label>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                                <hr className="coco-hr"/>
                                <div>
                                    <h5 className="module-title">
                                        <i className="ri-coin-line"></i>{GetMessageIntl(intl,"workflow.pointPayInput")}
                                    </h5>
                                    <Row>
                                        {watchAllPoint.pointPayTy === '00' || watchAllPoint.pointPayTy === '01'?
                                            <Col md={12}>
                                            <Label htmlFor="payPoint" className="form-label">{GetMessageIntl(intl,"workflow.pointPayValue")}<span className="text-coco-danger-400">*</span></Label>
                                            <div className={errorsPoint.payPoint?"validation-error":""}>
                                                <input type={"text"} min="0" className="form-control" {...registerPoint(`payPoint`,{
                                                    required: GetMessageIntl(intl,"validation.required"),
                                                    pattern: {
                                                        value: /^[0-9]+$/,
                                                        message:GetMessageIntl(intl,"validation.onlyNumber")
                                                    },
                                                    validate: (value, values) => {
                                                        if(parseInt(value) <= 0){
                                                            return GetMessageIntl(intl,"validation.greaterThanZero");
                                                        }
                                                    },
                                                    setValueAs: (value) => value.toString().replaceAll(",", ""),
                                                    })}
                                                       onChange={(e) => {
                                                           const { value } = e.target;
                                                           if(value==''){
                                                               setValuePoint(`payPoint`,value);
                                                           }else {
                                                               numberFormatter(value, `payPoint`);
                                                           }
                                                       }
                                                       }
                                                       maxLength={"7"}
                                                       placeholder={GetMessageIntl(intl,"workflow.pointPayInputPlaceholder")}/>
                                            </div>
                                            {errorsPoint.payPoint && <p className="validation-error-message">{errorsPoint.payPoint.message}</p>}
                                            </Col>
                                            : null
                                        }
                                    </Row>
                                </div>
                            </>
                            :
                            <div className={"d-flex align-items-center justify-content-center"}
                                 style={{height: "calc(100vh - 350px)"}}>
                                <span className="text-center text-coco-gray-200 tx-16 fw-500 lh-18" dangerouslySetInnerHTML={{ __html: GetMessageIntl(intl,"workflow.pointConfig.emptyBindConfig")}}></span>
                            </div>
                    }
                </OffcanvasBody>
            </SimpleBar>
        </>
    );
}


export default PointConfigDiv;

