import {useEffect, useState} from "react";
import authProcess from "../lib/authProcess";
const useUserInfo = () => {
    const userInfoSession = authProcess.getLoginUserInfo();

    const [loading, setLoading] = useState(userInfoSession ? false : true);
    const [coconutUserInfo, setCoconutUserInfo] = useState( userInfoSession? userInfoSession : null);

    useEffect(()=> {
        const userInfoSession = authProcess.getLoginUserInfo();
        setCoconutUserInfo(userInfoSession?userInfoSession:null);
        setLoading(userInfoSession?false:true);
    }, []);

    return {coconutUserInfo, loading};
};

export { useUserInfo };