import React, {useEffect, useState} from 'react';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';

const ItemTypes = {
    ITEM: 'item'
};

const Column = styled.div`
  height: 400px;
  width: 200px;
  margin: 20px;
  border-radius: 10px;
  box-shadow: 1px 1px 6px rgba(0,0,0,0.5);
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
`;

const Item = styled.div`
  border-radius: 10px;
  background-color: #fff3f3;
  height: 100px;
  width: 170px;
  margin: 10px auto;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 0px 3px rgba(0,0,0,0.5);
`


const MovableItem = () => {
    const [{isDragging}, drag] = useDrag({
        item: {name: 'Item', type: 'item'},
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        })
    })

    const opacity = isDragging ? 0.4 : 1;

    return(
        <Item ref={drag} style={{opacity}}>
            We will move this Item
        </Item>
    )
}

function DndTest() {
    const [items, setItems] = useState([
        { id: uuidv4(), name: 'Item 1' },
        { id: uuidv4(), name: 'Item 2' },
        { id: uuidv4(), name: 'Item 3' },
        { id: uuidv4(), name: 'Item 4' },
        { id: uuidv4(), name: 'Item 5' },
        { id: uuidv4(), name: 'Item 6' },
    ]);

    const moveItem = (dragIndex, hoverIndex) => {
        const dragCard = items[dragIndex];
        setItems(prevState => {
            const newItems = [...prevState];
            newItems.splice(dragIndex, 1);
            newItems.splice(hoverIndex, 0, dragCard);
            return newItems;
        });
    };

    return (
        <>
            <DndProvider backend={HTML5Backend}>
                <Column>
                    <MovableItem></MovableItem>
                </Column>
                <Column/>
            </DndProvider>
        </>
    );
}

export default DndTest;