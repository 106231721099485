import {all, put, take, takeLatest} from "redux-saga/effects";
import {GO_TO_DETAILS_BY_FOLDER} from "./actionType";
import {getDataList, updateSearchParam, updateSearchParamVisible} from "./details/action";
import {getRootStore} from "../index";
import {SET_DATA_LIST} from "./details/actionType";
import {alertError} from "../../util/AlertUtil";
import {getModule} from "../label/action";
import {SearchFilter} from "./details";

let store = null;
function getStore() {
	if (store === null) {
		store = getRootStore();
	}
	return store;
}

function* gotoDetailsByFolderAsync(action){
	const {module, history, filePath} = action.payload;
	try {
		yield put(updateSearchParamVisible([SearchFilter.FOLDER]));
		yield put(updateSearchParam(SearchFilter.FOLDER, filePath, [{label:filePath}]))

		yield put(getDataList());
		yield take(SET_DATA_LIST);
		const dataList = getStore().getState().Details.dataList;
		if (dataList.length > 0) {
			const data = dataList[0];
			const frame = {
				datasetId: data.datasetId,
				fileSeq: data.fileSeq,
				moduleId: data.labelModuleId
			}
			yield put(getModule({module, frame}));
			history.push({
				pathname: '/manager/project/dataset/details',
			});
		} else {
			// 빈 폴더일 경우는 존재하지 않음
		}
	} catch (error) {
		console.log(error)
	}
}

function* watchGotoDetailsByFolder(){
	yield takeLatest(GO_TO_DETAILS_BY_FOLDER, gotoDetailsByFolderAsync)
}
function* DatasetSaga(){
	yield all([
		watchGotoDetailsByFolder()
	])
}
export default DatasetSaga;
