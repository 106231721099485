// @ts-nocheck
import {useSelector} from "react-redux";
import {CANVAS_DATA_TY, DATA_TYPE} from "../../annotation/image/const";
import React from "react";
import {TextCanvas} from "../../clsfcSmry/inputArea/text/TextCanvas";
import {AudioCanvas} from "../../clsfcSmry/inputArea/audio/AudioCanvas";
import {VideoCanvas} from "../../clsfcSmry/inputArea/video/VideoCanvas";
import ImageCanvasLayout from "pages/user/label/annotation/image/components/canvas/grid-layout/image-canvas-layout";

export const CanvasLayout = ({className}): JSX.Element => {
    const {
        Label: {
            Cmmn:{
                module:{
                    dataType,
                    canvasDataTy
                }
            }
        }
    } = useSelector(state => state);
    return(
        <div className={(className ?? "content-container")
            .concat(dataType === DATA_TYPE.VIDEO
                ?
                    canvasDataTy === CANVAS_DATA_TY.VIDEO_CLSR_SMRY ? ' video-label-container'
                        : ' video-manager-container'
                : "")
        }>
            {dataType === DATA_TYPE.IMAGE && <ImageCanvasLayout/>}
            {dataType === DATA_TYPE.TEXT && <TextCanvas/>}
            {dataType === DATA_TYPE.AUDIO && <AudioCanvas/>}
            {dataType === DATA_TYPE.VIDEO && <VideoCanvas/>}
        </div>
    )

}
