import {
    SET_INIT_SEARCH_PARAM
    , SET_SEARCH_PARAM
    , SET_CANVAS_DATA_TY
    , SWITCH_MODAL_FLAG
    , SWITCH_FOLDERVIEW_FLAG
    , SET_CURRENT_PAGE
    , SET_DATASET_ID
    , SET_DATASET_OPTIONS
    , SET_WRKFLOW_OPTIONS
    , SET_FILE_OR_FOLDER_NM
    , SET_FOLDER_CURRENT_PAGE
    , SET_MOUNT_CNT
    , SET_WRKFLOW
    , SET_WRKFLOW_WITH_MODULES
} from "./actionType";

const INIT_STATE = {
    searchParam: {},
    modalFlag: false,
    folderViewFlag: true,
    currentPage: 1,
    folderCurrentPage: 1,
    datasetId: null,
    wrkflowId: null,
    wrkflowNm: null,
    wrkflowOptions: null,
    datasetOptions: [],
    wrkflowWithModulesArr: [],
    fileOrFolderNm: "",
    mount: 1,
    canvasDataTy: null
}

const Dataset = (state = INIT_STATE, action) => {
    switch (action.type) {
        case SET_SEARCH_PARAM :
            return {
                ...state,
                searchParam: action.payload
            }
        case SET_INIT_SEARCH_PARAM :
            return {
                ...state,
                searchParam: {}
            }
        case SWITCH_MODAL_FLAG :
            return {
                ...state,
                modalFlag: !state.modalFlag
            }
        case SWITCH_FOLDERVIEW_FLAG :
            return {
                ...state,
                folderViewFlag: !state.folderViewFlag,
                searchParam: []
            }
        case SET_CURRENT_PAGE :
            return {
                ...state,
                currentPage: action.payload
            }
        case SET_FOLDER_CURRENT_PAGE:
            return {
                ...state,
                folderCurrentPage: action.payload
            }
        case SET_DATASET_ID :
            return {
                ...state,
                datasetId: action.payload
            }
        case SET_WRKFLOW_WITH_MODULES:
            return {
                ...state,
                wrkflowWithModulesArr: action.payload
            }
        case SET_WRKFLOW :
            return {
                ...state,
                wrkflowId: action.payload.wrkflowId,
                wrkflowNm: action.payload.wrkflowNm
            }
        case SET_WRKFLOW_OPTIONS :
            return {
                ...state,
                wrkflowOptions: action.payload
            }
        case SET_DATASET_OPTIONS:
            return {
                ...state,
                datasetOptions: action.payload
            }
        case SET_FILE_OR_FOLDER_NM:
            return {
                ...state,
                fileOrFolderNm: action.payload
            }
        case SET_MOUNT_CNT:
            return {
                ...state,
                mount: action.payload
            }
        case SET_CANVAS_DATA_TY :
            return {
                ...state,
                canvasDataTy: action.payload
            }
        default:
            return state;
    }
}
export default Dataset;
