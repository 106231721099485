// @ts-nocheck
import React from 'react';
import Popover from 'antd/lib/popover';

import { Canvas } from 'pages/user/label/annotation/image/cvat-canvas-wrapper';
import {ObjectType, ShapeType} from 'store/label';

import DrawShapeButtonContainer
    from "pages/user/label/annotation/image/containers/standard-workspace/controls-side-bar/draw-shape-button";
import withVisibilityHandling from './handle-popover-visibility';
import {ShapeIcon} from "../../../const";
import {Button} from "reactstrap";

export interface Props {
    canvasInstance: Canvas;
    isDrawing: boolean;
    disabled?: boolean;
}

const CustomPopover = withVisibilityHandling(Popover, 'draw-rectangle');
function DrawRectangleControl(props: Props): JSX.Element {
    const { canvasInstance, isDrawing, disabled } = props;

    const dynamicPopoverProps = isDrawing ? {
        overlayStyle: {
            display: 'none',
        },
    } : {};

    const dynamicIconProps = isDrawing ? {
        className: ['coco-draw-rectangle-control', 'coco-active-canvas-control'],//'cvat-draw-rectangle-control cvat-active-canvas-control',
        // onClick: (): void => {
        //     canvasInstance.draw({ enabled: false });
        // },
    } : {
        className: 'coco-draw-rectangle-control',
    };

    return disabled ? (
        <Button className={'btn-icon rounded-pill labelShape coco-draw-shape-button coco-draw-rectangle-control disabled'}
                color={"coco-ghost"}
        >
            <i className={ShapeIcon[ShapeType.RECTANGLE]} />
        </Button>
    ) : (
        <DrawShapeButtonContainer shapeType={ShapeType.RECTANGLE} {...dynamicIconProps} />
        // <CustomPDrawShapePopoverContaineropover
        //     {...dynamicPopoverProps}
        //     overlayClassName='cvat-draw-shape-popover'
        //     placement='right'
        //     content={<DrawShapePopoverContainer shapeType={ShapeType.RECTANGLE} />}
        // >
        //     <Icon {...dynamicIconProps} component={RectangleIcon} />
        // </CustomPDrawShapePopoverContaineropover>
    );
}

export default React.memo(DrawRectangleControl);
