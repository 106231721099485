//@ts-nocheck
import './styles.scss'
import {ColorBy, ObjectType, ShapeType} from "../../../../../../../../store/label";
import SimpleBar from "simplebar-react";
import {
    ObjectPopoverHeader
} from "./object-popover-header";
import {ObjectPopoverBody} from "./object-popover-body";
import React from "react";
import {OBJECT_POPOVER_HEADER} from "../../classNameConsts";

interface Props {
    normalizedKeyMap: Record<string, string>;
    readonly: boolean;
    activated: boolean;
    objectType: ObjectType;
    shapeType: ShapeType;
    clientID: number;
    serverID: number | undefined;
    labelID: number;
    labelName: string;
    locked: boolean;
    elements: any[];
    color: string;
    colorBy: ColorBy;
    labels: any[];
    attributes: any[];
    moduleInstance: any;
    activate(activeElementID?: number): void;
    copy(): void;
    propagate(): void;
    createURL(): void;
    switchOrientation(): void;
    toBackground(): void;
    toForeground(): void;
    remove(): void;
    changeLabel(label: any): void;
    changeColor(color: string): void;
    resetCuboidPerspective(): void;
    edit(): void;
    onCloseCanvasContextMenu(e:MouseEvent): void;
}
export function ObjectPopover(props: Props) {
    const {shapeType, labelName} = props;
    const {attributes, labels, readonly, labelID, clientID, parentID, changeLabel, onCloseCanvasContextMenu} = props;
    // TODO props

    const calculateBodyHeight = (): number => {
        const titleContainer = window.document.getElementsByClassName(OBJECT_POPOVER_HEADER)[0];
        if (titleContainer) {
            return titleContainer.getBoundingClientRect().height;
        }
    }
    const bodyMaxHeight = calculateBodyHeight();
    return (
        <div style={{width:"306px"}}>
            <ObjectPopoverHeader
                shapeType={shapeType}
                labelName={labelName}
                handleClose={onCloseCanvasContextMenu}
            />
            <SimpleBar style={{maxHeight: `calc(600px - ${bodyMaxHeight}px)`}} autoHide={false}>
                <ObjectPopoverBody
                    className={"no-cursor"}
                    attributes={attributes}
                    labels={labels}
                    readonly={readonly}
                    shapeType={shapeType}
                    labelID={labelID}
                    changeLabel={changeLabel}
                    clientID={clientID}
                    parentID={parentID}
                />
            </SimpleBar>
        </div>
    );
}
