import {ReviewTitle} from "../ReviewTitle";
import {ReviewRejectReasons} from "../../../containers/taskSideBar/classification/ReviewRejectReasons";

export const LabelReviewResultView = ({hookform, questionKeyName, reject}) => {
    return (
        <div className={"review-result"}>
            <ReviewTitle reject={reject}/>
            {reject &&
				<>
					<ReviewRejectReasons hookform={hookform} questionKeyName={questionKeyName}/>
				</>
            }
        </div>
    )
}
