// Copyright (C) 2020-2022 Intel Corporation
//
// SPDX-License-Identifier: MIT
// @ts-nocheck
import React from 'react';
import { connect } from 'react-redux';
import { updateAnnotations} from "../../../../../../../../store/label/action";
import {LabelReviewDetails as LabelReviewDetailsComponent} from "../../../components/review-workspace/objects-side-bar/label-review-details";
import { CombinedState, ObjectType } from 'store/label';

interface OwnProps {
    activated: boolean;
    labelID: number;
    // keyToLabelMapping: Record<string, number>;
    // item: string | null;
    // updateLabelShortcutKey(updatedKey: string, labelID: number): void;
}

interface StateToProps {
    label: any;
    labelType: string;
    labelName: string;
    labelColor: string;
    objectStates: any[];
    moduleInstance: any;
    frameNumber: number;
    statisticsData: any;
}

interface DispatchToProps {
    updateAnnotations(states: any[]): void;
}

function mapStateToProps(state: CombinedState, own: OwnProps): StateToProps {
    const {
        Label: {
            Cmmn: {
                annotations: {states: objectStates},
                module: {labels, instance: moduleInstance},
                player: {
                    frame: {number: frameNumber},
                },
                statistics: {
                    data: statisticsData
                }
            }
        },
    } = state;

    const [label] = labels.filter((_label: any) => _label.id === own.labelID);
    let totalCount ;
    if(statisticsData){
        totalCount = statisticsData.labelData[label.name].total;
    }

    return {
        labelType: label.type,
        labelColor: label.color,
        labelName: label.name,
        objectStates,
        moduleInstance,
        frameNumber,
        totalCount
    };
}

function mapDispatchToProps(dispatch: any): DispatchToProps {
    return {
        updateAnnotations(states: any[]): void {
            dispatch(updateAnnotations(states));
        },
    };
}

type Props = StateToProps & DispatchToProps & OwnProps;
interface State {
    objectStates: any[];
    ownObjectStates: any[];
    visible: boolean;
    statesHidden: boolean;
    statesLocked: boolean;
}

class LabelReviewDetailsContainer extends React.PureComponent<Props, State> {
    public constructor(props: Props) {
        super(props);
        this.state = {
            objectStates: [],
            ownObjectStates: [],
            visible: true,
            statesHidden: false,
            statesLocked: false,
        };
    }

    static getDerivedStateFromProps(props: Props, state: State): State | null {
        if (props.objectStates === state.objectStates) {
            return null;
        }

        const ownObjectStates = props.objectStates.filter(
            (ownObjectState: any): boolean => ownObjectState.label.id === props.labelID,
        );
        const visible = !!ownObjectStates.length;
        let statesHidden = true;
        let statesLocked = true;

        ownObjectStates.forEach((objectState: any) => {
            const { lock, objectType } = objectState;
            if (!lock && objectType !== ObjectType.TAG) {
                statesHidden = statesHidden && objectState.hidden;
                statesLocked = statesLocked && objectState.lock;
            }
        });

        return {
            ...state,
            objectStates: props.objectStates,
            ownObjectStates,
            statesHidden,
            statesLocked,
            visible,
        };
    }

    private hideStates = (): void => {
        this.switchHidden(true);
    };

    private showStates = (): void => {
        this.switchHidden(false);
    };

    private lockStates = (): void => {
        this.switchLock(true);
    };

    private unlockStates = (): void => {
        this.switchLock(false);
    };

    private switchHidden(value: boolean): void {
        const { updateAnnotations } = this.props;
        const { ownObjectStates } = this.state;

        if (ownObjectStates.length) {
            // false alarm
            console.log('switchHidden', ownObjectStates)
            updateAnnotations(ownObjectStates.map((state: any) => ((state.hidden = value), state)));
        }
    }

    private switchLock(value: boolean): void {
        const { updateAnnotations } = this.props;
        const { ownObjectStates } = this.state;

        if (ownObjectStates.length) {
            // false alarm
            updateAnnotations(ownObjectStates.map((state: any) => ((state.lock = value), state)));
        }
    }

    public render(): JSX.Element {
        const {
            activated, item, moduleInstance, labelType, labelName, labelColor, keyToLabelMapping, labelID, totalCount,
            updateLabelShortcutKey
        } = this.props;
        const { visible, statesHidden, statesLocked } = this.state;

        return (
            <LabelReviewDetailsComponent
                activated={activated}
                item={item}
                moduleInstance={moduleInstance}
                labelType={labelType}
                labelName={labelName}
                labelColor={labelColor}
                labelID={labelID}
                totalCount={totalCount}
                keyToLabelMapping={keyToLabelMapping}
                visible={visible}
                statesHidden={statesHidden}
                statesLocked={statesLocked}
                hideStates={this.hideStates}
                showStates={this.showStates}
                lockStates={this.lockStates}
                unlockStates={this.unlockStates}
                updateLabelShortcutKey={updateLabelShortcutKey}
            />
        );
    }
}

export default connect<StateToProps, DispatchToProps, OwnProps, CombinedState>(
    mapStateToProps,
    mapDispatchToProps,
)(LabelReviewDetailsContainer);
