//@ts-nocheck
import {
    ObjectPopoverAttributesWrapper
} from "../../../components/standard-workspace/object-popover/object-popover-attributes";
import {ObjectState} from "../../../cvat-core-wrapper";
import {useDispatch, useSelector} from "react-redux";
import {collapseObjectItems, updateAnnotations as updateAnnotationsAsync} from "../../../../../../../../store/label/action";
import {LogType} from "../../../cvat-logger";
export interface Props {
    readonly: boolean;
    clientID: number;
    parentID: number | null;
    labelID: number;
}
export function ObjectPopoverAttributesContainer(props: Props){
    // State
    const { readonly, clientID, parentID, labelID } = props;
    const { states } = useSelector(state => state.Label.Cmmn.annotations);
    let objectState: ObjectState | null = null;
    if (parentID) {
        const parentState = (states as ObjectState[])
            .find((_objectState: ObjectState) => _objectState.clientID === parentID);
        if (parentState) {
            objectState = parentState.elements.find((el: ObjectState) => el.clientID === clientID) || null;
        }
    } else {
        objectState = (states as ObjectState[]).find((el: ObjectState) => el.clientID === clientID) || null;
    }

    const {
        Label: {
            Cmmn: {
                annotations: {
                    collapsedAll,
                    collapsed: statesCollapsed,
                },
                module: {
                    instance: moduleInstance,
                },
            }
        },
    } = useSelector(state => state);
    const collapsed = typeof statesCollapsed[clientID as number] === 'undefined' ? collapsedAll : statesCollapsed[clientID];
    const state = objectState;

    // Dispatch
    const dispatch = useDispatch();
    const updateState = (state: ObjectState): void => {
        dispatch(updateAnnotationsAsync([state]));
    };
    const collapseOrExpand = (objectState: ObjectState, collapsed: boolean): void => {
        dispatch(collapseObjectItems([objectState], collapsed));
    };

    // Logic
    const changeAttribute = (id: number, value: string): void => {
        // const {
        //     state, readonly, jobInstance, updateState,
        // } = this.props;
        if (!readonly && state) {
            moduleInstance.logger.log(LogType.changeAttribute, {
                id,
                value,
                object_id: state.clientID,
            });
            const attr: Record<number, string> = {};
            attr[id] = value;
            state.attributes = attr;
            updateState(state);
        }
    };

    if (state) {
        return(
            <ObjectPopoverAttributesWrapper
                labelID={labelID}
                readonly={readonly}
                attributes={[...state.label.attributes]}
                values={{ ...state.attributes }}
                changeAttribute={changeAttribute}
            />
        );
    }
    return null;
}
