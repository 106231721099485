import React, {useEffect, useState} from 'react';
import {GetMessage, GetMessageIntl} from "../../../util/message";
import BreadCrumb from "../../../components/common/BreadCrumb";
import {Card, CardBody, CardHeader, Col, Container, Row} from "reactstrap";
import SimpleBar from "simplebar-react";
import styled from "styled-components";
import {UserUtil} from "../../../util/UserUtil";
import MemberModifyModal from "./MemberModifyModal";
import {ManagerProjectService} from "../../../apis/api/ManagerProjectService";
import {useIntl} from "react-intl";
import ExportModal from "./ExportModal";
import CustomTooltip from "../../../components/features/CustomTooltip";


const NameIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  z-index: 1;
  position: relative;
  
  font-weight: 600;
  font-size: 14px;
  border-radius: 50%;
  background-color: ${props => {
      if(props.mainYn === 'Y'){
          return '#FCE4DF';
      }else{
          if(props.userTy === '10' || props.userTy === '11'){
              return '#E4F3FF'
          }else{
              return '#CEE0BB'
          }
      }
  }};
  color: ${props => {
    if(props.mainYn === 'Y'){
      return '#FF5A5A';
    }else{
      if(props.userTy === '10' || props.userTy === '11'){
        return '#3577F1'
      }else{
        return '#6E9D41'
      }
    }
  }};
  text-align: center;
  width: 32px;
  height: 32px;
`

const EventIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  z-index: 1;
  position: relative;
  
  font-weight: 600;
  font-size: 14px;
  border-radius: 50%;
  background-color: ${props => {
    if(props.mainYn === 'Y'){
      return '#FCE4DF';
    }else{
      if(props.userTy === '10' || props.userTy === '11'){
        return '#E4F3FF'
      }else{
        return '#CEE0BB'
      }
    }
}};
  color: ${props => {
    if(props.mainYn === 'Y'){
      return '#FF5A5A';
    }else{
      if(props.userTy === '10' || props.userTy === '11'){
        return '#3577F1'
      }else{
        return '#6E9D41'
      }
    }
}};
  text-align: center;
  width: 32px;
  height: 32px;
`

function ProjectSetting() {
    const intl = useIntl();
    const prjctId = sessionStorage.getItem("prjctId");

    const [memberModifyModal, setMemberModifyModal] = useState(false);
    const [exportModalFlag, setExportModalFlag] = useState(false);
    const [memebers, setMembers] = useState([]);
    const [mainMember, setMainMember] = useState({});
    const [events, setEvents] = useState([]);
    const [exportLogs, setExportLogs] = useState([]);

    useEffect(() => {
        getPrjctEvents(prjctId);
    }, [])

    useEffect(() => {
        if(memberModifyModal === false){
            getManager().then(re => {});
        }
    }, [memberModifyModal])

    useEffect(()=>{
        getPrjctExportLogs(prjctId).then(re => {});
    }, [exportModalFlag])

    const getPrjctExportLogs = async (prjctId) => {
        const res = await ManagerProjectService.getPrjctExportLogs({
            "prjctId" : prjctId
        });
        if(res.data.data){
            setExportLogs(res.data.data);
        }
    }

    const getPrjctEvents = async (prjctId) => {
        const res = await ManagerProjectService.getPrjctEvents({
            "prjctId": prjctId
        })
        if(res.data.data){
            setEvents(res.data.data);
        }
    }

    const getManager = async () => {
        const result = await ManagerProjectService.getManagers({"prjctId": prjctId});
        if(result.data.data){
            const filteredData = result.data.data
                ?.filter(item => item.mainYn !== null)
                .sort((a, b) => {
                    if (a.mainYn === 'Y' && b.mainYn !== 'Y') {
                        return -1;
                    }
                    if (a.mainYn !== 'Y' && b.mainYn === 'Y') {
                        return 1;
                    }
                    if ((a.userTy === '10' || a.userTy === '11') && !(b.userTy === '10' || b.userTy === '11')) {
                        return -1;
                    }
                    if (!(a.userTy === '10' || a.userTy === '11') && (b.userTy === '10' || b.userTy === '11')) {
                        return 1;
                    }
                    return 0;
                });
            const mainMember = result.data.data?.find(item => item.mainYn === 'Y');
            setMainMember(mainMember);
            setMembers(filteredData);
        }
    }

    const convertSttusCdStr = (sttusCd, awsSttusCd) => {
        switch (sttusCd){
            case "00":
                return (
                    <span className="badge badge-coco badge-coco-soft-yellow">{GetMessageIntl(intl, "projectSetting.wait")}</span>
                )
            case "01":
                return (
                    <span className="badge badge-coco badge-coco-soft-green">{GetMessageIntl(intl, "projectSetting.process")}</span>
                )
            case "02":
                if(awsSttusCd === "PRECREATED"){
                    return (
                        <span className="badge badge-coco badge-coco-soft-green">{GetMessageIntl(intl, "projectSetting.process")}</span>
                    )
                }
                if(awsSttusCd === "COMPLETE"){
                    return (
                        <span className="badge badge-coco badge-coco-soft-skyblue">{GetMessageIntl(intl, "projectSetting.complete")}</span>
                    )
                }
            case "03":
                return (
                    <CustomTooltip text={GetMessageIntl(intl, "projectSetting.exportFail")} width="156px">
                        <span className="badge badge-coco badge-coco-soft-red">{GetMessageIntl(intl, "projectSetting.fail")}</span>
                    </CustomTooltip>
                )
            case "04":
                return (
                    <CustomTooltip text={GetMessageIntl(intl, "projectSetting.exportFailByZero")} width="230px" >
                        <span className="badge badge-coco badge-coco-soft-red">{GetMessageIntl(intl, "projectSetting.fail")}</span>
                    </CustomTooltip>
                )
        }
    }


    const calculateElapsedTime = (regDtmText, modDtmText) => {
        let regDtm = new Date(regDtmText + ":00");  // Add seconds
        let modDtm = new Date(modDtmText + ":00");  // Add seconds

        let difference = modDtm.getTime() - regDtm.getTime();

        let differenceInMinutes = difference / 1000 / 60;

        let hours = Math.floor(differenceInMinutes / 60);
        let minutes = Math.floor(differenceInMinutes % 60);

        return hours + ":" + (minutes < 10 ? '0' + minutes : minutes);  // Difference in HH:MM format
    }

    function downloadFile(filePath, realFileName) {
        filePath = filePath.replaceAll('#','%23') // #을 url인코딩으로 %23으로 치환
        fetch(filePath, {method: 'GET'})
            .then((res) => {
                return res.blob() // raw 데이터를 받아온다
            })
            .then((blob) => {
                const url = window.URL.createObjectURL(blob) // 받아온 날 상태의 data를 현재 window에서만 사용하는 url로 바꾼다
                const a = document.createElement('a')
                a.href = url
                a.download = realFileName // 원하는 이름으로 파일명 지정
                document.body.appendChild(a)
                a.click() // 자동으로 눌러버리기
                setTimeout((_) => {
                    window.URL.revokeObjectURL(url) // 해당 url을 더 사용 못하게 날려버린다
                }, 60000)
                a.remove() // a를 다 사용했으니 지워준다
                return false;
            })
            .catch((err) => {
                console.error('err: ', err)
            })
    }

    const convertResultFilePathToCn = (resultFilePath) => {
        let parts = resultFilePath.split('/');
        let fileNameWithExtension = parts[parts.length - 1];
        let extensionIndex = fileNameWithExtension.lastIndexOf('.');
        let fileNameWithoutExtension = fileNameWithExtension.substring(0, extensionIndex);

        //년월일시분초 삭제
        let lastIndex = fileNameWithoutExtension.lastIndexOf("_");
        fileNameWithoutExtension = fileNameWithoutExtension.substring(0, lastIndex);
        return fileNameWithoutExtension;
    }

    const handleSwitchModalFlag = () => {
        setMemberModifyModal(!memberModifyModal);
    }

    const handleSwitchExportModalFlag = () => {
        setExportModalFlag(!exportModalFlag)
    }

    return(
        <>
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <BreadCrumb title={GetMessageIntl(intl,"menu.MPJT0005")} pageTitle="Laflow"/>
                    </Row>
                    <Row style={{height: "calc(100vh - 155px)"}}>
                        <Col xxl={8}>
                            <Card className="shadow-none mb-0 h-100" style={{borderRadius: "7px", border: "1px solid #CFD8DC"}}>
                                <CardHeader className={"pd-x-22 pd-y-16 d-flex align-items-center justify-content-between"} style={{borderRadius: "7px 7px 0"}}>
                                    <div className={"d-flex align-items-center"}>
                                        <i className="ri-file-transfer-line mr-8" style={{color: "#0BAD8F"}}></i><span className="text-coco-gray-600 fw-semibold tx-16">내보내기 이력</span>
                                    </div>
                                    <button className="btn btn-coco-xs-1" onClick={handleSwitchExportModalFlag}>{GetMessageIntl(intl, "projectSetting.export")}</button>
                                </CardHeader>
                                <CardBody className={"p-0"}>
                                    <Row className="mx-0" style={{height: "44px"}}>
                                        <Col xxl={12} style={{backgroundColor: "#F3F6F9"}}>
                                            <Row className="tx-16 fw-semibold" style={{height: "inherit"}}>
                                                <Col className="mt-10 h-100 pd-l-22" style={{backgroundColor: "#F3F6F9"}} xxl={1}>{GetMessageIntl(intl, "projectSetting.activer")}</Col>
                                                <Col className="mt-10 h-100" style={{backgroundColor: "#F3F6F9"}} xxl={4}>{GetMessageIntl(intl, "projectSetting.history")}</Col>
                                                <Col className="mt-10 h-100" style={{backgroundColor: "#F3F6F9"}} xxl={1}>{GetMessageIntl(intl, "projectSetting.sttus")}</Col>
                                                <Col className="mt-10 h-100" style={{backgroundColor: "#F3F6F9"}} xxl={2}>{GetMessageIntl(intl, "projectSetting.startDtm")}</Col>
                                                <Col className="mt-10 h-100" style={{backgroundColor: "#F3F6F9"}} xxl={2}>{GetMessageIntl(intl, "projectSetting.endDtm")}</Col>
                                                <Col className="mt-10 h-100" style={{backgroundColor: "#F3F6F9"}} xxl={1}>{GetMessageIntl(intl, "projectSetting.totalTime")}</Col>
                                                <Col className="mt-10 h-100 pd-r-22 text-center" style={{backgroundColor: "#F3F6F9"}} xxl={1}>{GetMessageIntl(intl, "projectSetting.action")}</Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                    <SimpleBar style={{height:"91%",maxHeight: "calc(100vh - 266px)", overflowX: "hidden"}} className="simplebar-track-coco">
                                        <div className="h-100">
                                            {
                                                exportLogs?.map((exportLog, idx) => {
                                                    const taskConfig = JSON.parse(exportLog.taskConfig);
                                                    const regDtmText = exportLog.regDtmText;
                                                    const modDtmText = exportLog.modDtmText;
                                                    const downloadBtnFlag = exportLog.awsSttusCd === "COMPLETE" && exportLog.sttusCd === "02"

                                                    // 파일 export 내역 Cn
                                                    let cn = "";
                                                    let tooltipText = "";
                                                    if(exportLog.resultFilePath){
                                                        cn = convertResultFilePathToCn(exportLog.resultFilePath);
                                                        tooltipText = cn;
                                                        // if(cn.length > 27){
                                                        //     tooltipText = cn;
                                                        //     cn = cn.slice(0, 25);
                                                        //     cn += "...";
                                                        // }
                                                    }

                                                    // 파일 다운로드 관련
                                                    let realFilename = '';
                                                    if(downloadBtnFlag){
                                                        let tmp = exportLog.resultFilePath.split("/");
                                                        realFilename = tmp[tmp.length - 1];
                                                    }
                                                    return (
                                                        <Row key={idx} className="pd-y-7 ht-40 mx-0">
                                                            <Col xxl={1} className={"pd-l-22"}><span className="text-coco-gray-700 tx-16">{exportLog.userNm}</span></Col>
                                                            <Col xxl={4}>
                                                                {
                                                                    tooltipText.length === 0 ?
                                                                        <span className="text-coco-gray-700 tx-16 text-overflow">{cn}</span>
                                                                        :
                                                                        <CustomTooltip text={tooltipText} width="320px">
                                                                            <div className={"text-overflow"}>
                                                                            <span className="text-coco-gray-700 tx-16 text-overflow">{cn}</span>
                                                                            </div>
                                                                        </CustomTooltip>
                                                                }
                                                            </Col>
                                                            <Col xxl={1}><span className="text-coco-gray-700 tx-16">{convertSttusCdStr(exportLog.sttusCd, exportLog.awsSttusCd)}</span></Col>
                                                            <Col xxl={2}><span className="text-coco-gray-700 tx-16">{regDtmText}</span></Col>
                                                            <Col xxl={2}><span className="text-coco-gray-700 tx-16">{modDtmText}</span></Col>
                                                            <Col xxl={1}><span className="text-coco-gray-700 tx-16">{calculateElapsedTime(regDtmText, modDtmText)}</span></Col>
                                                            <Col xxl={1} className={"pd-r-22 text-center"}>
                                                                {
                                                                    downloadBtnFlag &&
                                                                        <span className="text-coco-gray-700 tx-16">
                                                                            <button className="btn btn-coco-xs-2 fs-12"
                                                                                onClick={(e) => {
                                                                                    downloadFile(exportLog.downFilePath, realFilename)
                                                                                }}>다운로드</button>
                                                                        </span>
                                                                }
                                                            </Col>
                                                        </Row>
                                                    )
                                                })
                                            }
                                        </div>
                                    </SimpleBar>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col xxl={4}>
                            <Row style={{height:"36%", marginBottom: "4.4%"}}>
                                <Card className="shadow-none h-100 mb-0 pd-r-8 pd-l-8" style={{borderRadius: "7px", border: "1px solid #CFD8DC"}}>
                                    <CardBody className="pd-r-0">
                                        <Row className="justify-content-between align-items-center pb-2 mb-6" style={{marginRight: "5px", borderBottom: "1px solid #CFD8DC"}}>
                                            <Col xxl={10}>
                                                <i className="ri-user-star-line" style={{color: "#0BAD8F"}}></i>&nbsp;&nbsp;&nbsp;<span className="text-coco-gray-600 fw-semibold tx-16">{GetMessageIntl(intl, "projectSetting.member")}</span>
                                            </Col>
                                            <Col xxl={2}>
                                                <button className="btn btn-coco-xs-2" onClick={handleSwitchModalFlag}>{GetMessageIntl(intl, "projectSetting.modifyMember")}</button>
                                            </Col>
                                        </Row>
                                        <SimpleBar style={{maxHeight:"217px", overflowX:"hidden"}} className="simplebar-track-coco">
                                            {
                                                // 멤버 데이터 Map (같은 조직 내부 어드민, 매니저)
                                                memebers?.map((member, idx) => {
                                                    return (
                                                        <div key={member.userSeq} className="d-flex align-items-center" style={{paddingRight:'23px', paddingBottom:'10px', paddingTop:'10px'}}>
                                                            <div className="avatar-xs flex-shrink-0">
                                                                <NameIcon classname="fs-14 fw-semibold" userTy={member.userTy} mainYn={member.mainYn}>{member.userNm?.split('')[0]}</NameIcon>
                                                            </div>
                                                            <div className="flex-grow-1 ms-3">
                                                                <h6 className="fs-15 mb-1">{member.userNm}</h6>
                                                                <p className="text-coco-gray-700 fw-400 fs-13 mb-0">
                                                                    {UserUtil.strFromUserTy(intl, member.userTy)}
                                                                    {member.mainYn === 'Y' && GetMessageIntl(intl, "projectSetting.firstMember")}
                                                                </p>
                                                            </div>
                                                            <div className="flex-shrink-0 text-end">
                                                                <h6 className="fs-15 mb-1">&nbsp;</h6>
                                                                <p className="text-muted fs-13 mb-0">{member.lastActivity}&nbsp;{GetMessageIntl(intl, "projectSetting.lastActivity")}</p>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </SimpleBar>
                                    </CardBody>
                                </Card>
                            </Row>
                            <Row style={{height:"61%"}}>
                                <Card className="shadow-none h-100 mb-0 pd-r-8 pd-l-8" style={{borderRadius: "7px", border: "1px solid #CFD8DC"}}>
                                    <CardBody className="pd-r-0 pd-b-0">
                                        <Row className="justify-content-between align-items-center pb-2 mb-3" style={{marginRight: "5px", borderBottom: "1px solid #CFD8DC"}}>
                                            <Col xxl={12}>
                                                <i className="ri-history-line" style={{color: "#0BAD8F"}}></i>&nbsp;&nbsp;&nbsp;<span className="text-coco-gray-600 fw-semibold tx-16">{GetMessageIntl(intl, "projectSetting.projectHistory")}</span>
                                            </Col>
                                        </Row>
                                        <SimpleBar style={{maxHeight:'391px'}}  className="simplebar-track-coco">
                                            <div className="acitivity-timeline acitivity-main acitivity-manager-setting" style={{paddingRight:'23px'}}>
                                                {
                                                    // 프로젝트 이력
                                                    events?.map((event, idx) => {
                                                        const isMainMember = mainMember.userSeq === event.userSeq ? 'Y' : 'N';
                                                        return(
                                                            <div key={idx} className={"acitivity-item fs-14 d-flex pb-3"}>
                                                                <div className="flex-shrink-0 fw-semibold text-coco-gray-700">
                                                                    {
                                                                        <EventIcon userTy={event.userTy} mainYn={isMainMember}>{event.userNm?.split('')[0]}</EventIcon>
                                                                    }
                                                                </div>
                                                                <div className="flex-grow-1 ms-3">
                                                                    <h6 className="mb-1 lh-base">{event.userNm}</h6>
                                                                    <p className="text-muted mb-2 text-coco-gray-700">
                                                                        {event.eventCn}
                                                                    </p>
                                                                    <small className="mb-0 text-muted text-coco-gray-400">{event.regDtmText}</small>
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </SimpleBar>
                                    </CardBody>
                                </Card>
                            </Row>
                        </Col>
                    </Row>
                </Container>
                {
                    memberModifyModal && <MemberModifyModal modalFlag={memberModifyModal} switchModalFlag={handleSwitchModalFlag} prjctId={prjctId}/>
                }
                {
                    exportLogs && <ExportModal modalFlag={exportModalFlag} switchModalFlag={handleSwitchExportModalFlag} prjctId={prjctId}/>
                }
            </div>
        </>
    )
}

export default ProjectSetting;
