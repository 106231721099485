import {ActiveControl} from "../index";
import {RESET_CANVAS} from "./actionType";
import {GROUP_OBJECTS, MERGE_OBJECTS, SPLIT_TRACK} from "./actionType";

const INIT_STATE = {
    canvas: {
        /**
         * 화면에 표시되는 state
         */
        activeControl: ActiveControl.CURSOR,
    },
    annotation: {
    }
}

const Annotation = (state=INIT_STATE, action) => {
    switch (action.type){
        /* CANVAS */
        case RESET_CANVAS: {
            return {
                ...state,
                canvas: {
                    ...state.canvas,
                    activeControl: ActiveControl.CURSOR,
                },
            }
        }
        /* CANVAS END */
        /* SHAPE */
        // case MERGE_OBJECTS: {
        //     const { enabled } = action.payload;
        //     const activeControl = enabled ? ActiveControl.MERGE : ActiveControl.CURSOR;
        //
        //     return {
        //         ...state,
        //         annotations: {
        //             ...state.annotations,
        //             activatedStateID: null,
        //         },
        //         canvas: {
        //             ...state.canvas,
        //             activeControl,
        //         },
        //     };
        // }
        // case GROUP_OBJECTS: {
        //     const { enabled } = action.payload;
        //     const activeControl = enabled ? ActiveControl.GROUP : ActiveControl.CURSOR;
        //
        //     return {
        //         ...state,
        //         annotations: {
        //             ...state.annotations,
        //             activatedStateID: null,
        //         },
        //         canvas: {
        //             ...state.canvas,
        //             activeControl,
        //         },
        //     };
        // }
        // case SPLIT_TRACK: {
        //     const { enabled } = action.payload;
        //     const activeControl = enabled ? ActiveControl.SPLIT : ActiveControl.CURSOR;
        //
        //     return {
        //         ...state,
        //         annotations: {
        //             ...state.annotations,
        //             activatedStateID: null,
        //         },
        //         canvas: {
        //             ...state.canvas,
        //             activeControl,
        //         },
        //     };
        // }
        /* SHAPE END*/
        default:
            return state;
    }
}
export default Annotation;
