import React, {useRef, useState} from 'react'
import JsFileDownloader from 'js-file-downloader';
import {DatasetService} from "../../apis/api/DatasetService";
import {Col, Row} from "reactstrap";


const DatasetFileListTest = () => {
    const getFile = async () => {
        const coconutUserInfo = JSON.parse(sessionStorage.getItem("coconutUserInfo"));
        let accessToken = coconutUserInfo.accessToken;


        //DatasetService.getDatasetListForMetadata('파일명.csv', {"datasetId": "DT4515e26e-6e62-4573-aa36-19135a6b5655"})
        // 다운로드 받을 데이터가 있는지 확인
        // 이때 다운받을 파일명 데이터 수신
        new JsFileDownloader({
            url: '/service/dataset/fileListDownload',
            method: 'POST',
            contentType: 'application/json',
            filename: '데이터셋명.csv',
            headers: [
                {name: 'Authorization', value: 'Bearer '+accessToken}
            ],
            body: '{"datasetId": "DT4515e26e-6e62-4573-aa36-19135a6b5655"}'
        })
        .then(function () {
            // Called when download ended
        })
        .catch(function (error) {
            // Called when an error occurred
        });
        console.log('----end----')
    }

    const [file1, setFile1] = useState();
    const [file2, setFile2] = useState();
    const putFile1 = async (e) => {
        const data = new FormData();
        data.append("file", file1.target.files[0]);
        data.append("datasetId", 'DT4515e26e-6e62-4573-aa36-19135a6b5655');
        data.append("metaInfoMapngTy", "01");
        const res = await DatasetService.setDatasetMetadata(data, {contentType : 'multipart/form-data'});
        console.log(res)
    }

    const putFile2 = (e) => {
        const data = new FormData();
        data.append("file", file2.target.files[0]);
        data.append("datasetId", 'DT4515e26e-6e62-4573-aa36-19135a6b5655');
        data.append("metaInfoMapngTy", "02");
        DatasetService.setDatasetMetadata(data, {contentType : 'multipart/form-data'});
    }


    return (
        <>
            <Row>
                <Col  xl={2} xxl={1}>
                    <button onClick={getFile}>다운로드</button>
                </Col>
            </Row>
            <Row>
                <Col  xl={2} xxl={1}>
                    <input type="file" multiple={false} id="file" onChange={setFile1} />
                    <button onClick={putFile1}>업로드1</button>
                </Col>
            </Row>
            <Row>
                <Col  xl={2} xxl={1}>
                <input type="file" multiple={false} id="file" onChange={setFile2} />
                <button onClick={putFile2}>업로드2</button>
                </Col>
            </Row>
        </>
    )
}


export default DatasetFileListTest;