import {createStore, applyMiddleware, Store, Reducer} from "redux";
import createSagaMiddleware from "redux-saga";
import rootReducer from "./reducers";
import rootSaga from "./sagas";
import thunk from 'redux-thunk';
import {isDev} from "pages/user/label/annotation/image/utils/environment";
import {createLogger} from "redux-logger";
// import createRootReducer from "../cvat/reducers/root-reducer";


const sagaMiddleware = createSagaMiddleware();
const logger = createLogger({
	predicate: isDev,
	collapsed: true,
});

const middlewares = [sagaMiddleware, thunk, logger,];

let store = null;

export function configureStore(initialState) {
	let appliedMiddlewares = applyMiddleware(...middlewares);
    if (isDev()) {
        const { composeWithDevTools } = require('redux-devtools-extension');

        appliedMiddlewares = composeWithDevTools(appliedMiddlewares);
    }

	store = createStore(
		// createRootReducer(),
		rootReducer(),
		initialState,
        appliedMiddlewares
	);
	sagaMiddleware.run(rootSaga);
	return store;
}

export function getRootStore() {
	if (store) {
		return store;
	}

	throw new Error('First create a Coconut store');
}

