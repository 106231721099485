// @flow
import {all, call, fork, put, take, takeEvery, takeLatest} from "redux-saga/effects";

import {
    CHANGE_MANAGE_PROJECT,
    CHANGE_MANAGE_WORKFLOW, GO_TO_DETAILS_BY_WORKER,
    SET_DATASET_NM,
} from './actionType';
import {getDataList, updateSearchParam, updateSearchParamVisible} from "../dataset/details/action";
import {SearchFilter} from "../dataset/details";
import {getRootStore} from "../index";
import {alertError, alertInfo} from "../../util/AlertUtil";
import {SET_DATA_LIST} from "../dataset/details/actionType";
import {getModule} from "../label/action";
import {useSelector} from "react-redux";

/**
 * Changes the body attribute
 */
function changeManageDepthLayoutTitle(attribute, value) {
    if (document.documentElement) document.documentElement.setAttribute(attribute, value);
    return true;
}

/**
 * Changes the Manage Project
 * @param {*} param0
 */
function* changeManageProject({ payload: {projectId, projectNm} }) {
    try {
        yield call(changeManageDepthLayoutTitle, "data-layout-title", projectNm);
    } catch (error) {
        // console.log(error);
    }
}

/**
    * Changes the Manage Workflow
    * @param {*} param0
*/
function* changeManageWorkflow({ payload: {workflowId, workflowNm} }) {
    try {
        yield call(changeManageDepthLayoutTitle, "data-layout-title", workflowNm);
    } catch (error) {
        // console.log(error);
    }
}

function* gotoDetailsByWorker(action){
    try {
        const {userSeq, workerViewList} = action.payload;
        const {intl, history} = action.hooks;
        yield put(updateSearchParamVisible([SearchFilter.WORKER]))
        yield put(updateSearchParam(SearchFilter.WORKER, userSeq, workerViewList))
        yield put(getDataList());
        yield take(SET_DATA_LIST);

        const dataList = getRootStore().getState().Details.dataList;
        const canvasDataTy = getRootStore().getState().ManageWorker.canvasDataTy;
        if (dataList.length > 0){
            const data = dataList[0];
            const module = {
                moduleId: data.labelModuleId,
                name: data.labelModuleNm,
                type: "01",
                canvasDataTy : canvasDataTy
            };
            const frame = {
                datasetId: data.datasetId,
                fileSeq: data.fileSeq,
                moduleId: data.labelModuleId
            }
            yield put(getModule({module, frame}));
            history.push({
                pathname: '/manager/project/dataset/details',
            });
        } else {
            alertError('projectWorker.noWorkHistory'
                , 'projectWorker.noWorkHistorySub'
                , null
                , null
                , null
                , intl
            )
        }
    }catch (error){
        console.log(error)
    }
}


/**
 * Watchers
 */
export function* watchChangeManageProject() {
    yield takeEvery(CHANGE_MANAGE_PROJECT, changeManageProject);
}
export function* watchChangeManageWorkflow() {
    yield takeEvery(CHANGE_MANAGE_WORKFLOW, changeManageWorkflow);
}
export function* watchGoToDetailsByWorker() {
    yield takeLatest(GO_TO_DETAILS_BY_WORKER, gotoDetailsByWorker);
}

function* ManageSaga() {
    yield all([
        fork(watchChangeManageProject),
        fork(watchChangeManageWorkflow),
        watchGoToDetailsByWorker(),
    ]);
}

export default ManageSaga;
