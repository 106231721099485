import {callApi} from '../utils/AxiosUtil';
export const ManagerService = {
    /**
     * 매니저 목록
     * @param param
     * @returns {Promise<axios.AxiosResponse<any>>}
     */
    getManagerList: (param) => callApi('/service/user/manager/list', param),

    /**
     * 회원 목록
     * @param param
     * @returns {Promise<axios.AxiosResponse<any>>}
     */
    getUserList: (param) => callApi('/service/user/list', param),

    /**
     * 태스크 목록
     * @param param
     * @returns {Promise<axios.AxiosResponse<any>>}
     */
    getTaskList: () => callApi('/service/module/listAll'),

    /**
     * 조직 목록
     * @returns {Promise<axios.AxiosResponse<any>>}
     */
    getOrgnztList: () => callApi('/service/orgnzt/list'),



}