import React, {useCallback, useRef, useState} from 'react'
import {Button, Col, Row} from "reactstrap";
import {FilePond, registerPlugin} from 'react-filepond'
import 'filepond/dist/filepond.min.css'
import {useDropzone} from "react-dropzone";
import SimpleBar from "simplebar-react";
import CommonUtil from "../../util/CommonUtil";
import {ModuleService} from "../../apis/api/ModuleService";

const ModuleNormalConfigUpdateTest = () => {
    const go = () => {
        let param = {};
        param.moduleId = "MD5c3bc558-3747-4ca9-9154-0d49600dca27";
        param.moduleTy = "01";
        param.moduleConfig = JSON.stringify({
            workerConfig : {
                test: 1
            }
        });

        ModuleService
            .updateModuleItem(param)
            .then(result => {
                console.log(result)
            }).catch((error) => {
            }
        );
    }


    return (
        <>

            <Row>
                <Col>
                    <Button onClick={go}>전송</Button>
                </Col>
            </Row>
        </>
    )
}

export default ModuleNormalConfigUpdateTest;