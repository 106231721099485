//@ts-nocheck
import React from "react";
import GlobalHotKeys from "../../../utils/mousetrap-react";

interface Props {
    children: JSX.Element;
    subKeyMap: any | null;
    handlers: any | null;
}
export const RightGroup = ({children, subKeyMap, handlers}: Props) => {
    return (
        <>
            {(subKeyMap && handlers) && <GlobalHotKeys keyMap={subKeyMap} handlers={handlers}/>}
            {children}
        </>
    );
}
