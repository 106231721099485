import SimpleBar from "simplebar-react";
import {Card, CardBody, CardHeader, Col, Input, Label, ListGroup, ListGroupItem, OffcanvasBody, Row} from "reactstrap";
import {GetMessageIntl} from "../../../../../../../../util/message";
import {Controller, useFormContext} from "react-hook-form";
import Select from "react-select";
import {defaultSelectStyle, formatGroupLabelNoCount} from "../../../../../../../../components/constants/select";
import React, {useEffect, useState} from "react";
import {useIntl} from "react-intl";
import {useDispatch, useSelector} from "react-redux";
import {ManagerWorkflowService} from "../../../../../../../../apis/api/ManagerWorkflowService";
import {
    addManageWorkflowLabelClassItem,
    deleteManageWorkflowLabelClassItem, setManageWorkflowLabelClassItems
} from "../../../../../../../../store/manage/project/workflow/label/action";
import {addManageLabelClassItem} from "../../../../../../../../store/manage/label/action";

function PointConfigDiv() {
    const intl = useIntl();
    const dispatch = useDispatch();
    const prjctId = sessionStorage.getItem("prjctId");
    const { control:controlPoint, register:registerPoint, unregister:unregisterPoint, setValue:setValuePoint, getValues:getValuesPoint, reset:resetPoint, resetField:resetFieldPoint
        , watch:watchPoint, formState: { errors:errorsPoint },} = useFormContext();
    const watchAllPoint = watchPoint();
    const labelShapesTypeInfo = {
        'rectangle': {'label': GetMessageIntl(intl,'workflow.shapeConfig.rectangle'), 'value': 'rectangle', 'icon': 'coco-20-bbox', 'id': 0}
        , 'polygon': {'label': GetMessageIntl(intl,'workflow.shapeConfig.polygon'), 'value': 'polygon', 'icon': 'coco-20-polygon', 'id': 1}
        , 'line': {'label': GetMessageIntl(intl,'workflow.shapeConfig.line'), 'value': 'line', 'icon': 'coco-20-line', 'id': 2}
        , 'polyline': {'label': GetMessageIntl(intl,'workflow.shapeConfig.polyline'), 'value': 'polyline', 'icon': 'coco-20-polyline', 'id': 3}
        , 'circle': {'label': GetMessageIntl(intl,'workflow.shapeConfig.circle'), 'value': 'circle', 'icon': 'coco-20-circle', 'id': 4}
        , 'ellipse': {'label': GetMessageIntl(intl,'workflow.shapeConfig.ellipse'), 'value': 'ellipse', 'icon': 'coco-20-ellipse', 'id': 5}
        , 'polyEllipse': {'label': GetMessageIntl(intl,'workflow.shapeConfig.polyEllipse'), 'value': 'polyEllipse', 'icon': 'coco-20-poly-ellipse', 'id': 6}
        , 'points': {'label': GetMessageIntl(intl,'workflow.shapeConfig.points'), 'value': 'points', 'icon': 'coco-20-point', 'id': 7}
        , 'cuboid': {'label': GetMessageIntl(intl,'workflow.shapeConfig.cuboid'), 'value': 'cuboid', 'icon': 'coco-20-cuboid2d', 'id': 8}
        , 'brush': {'label': GetMessageIntl(intl,'workflow.shapeConfig.brush'), 'value': 'brush', 'icon': 'coco-20-brush', 'id': 9}
    };

    const {
        initFlag,
        shapeConfig,
        pointConfig,
        bindConfig} = useSelector((state) => ({
        initFlag: state.ManageWorkflowLabel.initFlag,
        shapeConfig: state.ManageWorkflowLabel.shapeConfig,
        pointConfig: state.ManageWorkflowLabel.pointConfig,
        bindConfig: state.ManageWorkflowLabel.bindConfig
    }));
    const [pointShapeList, setPointShapeList] = useState([]);
    const [disabledFolder, setDisabledFolder] = useState(false);

    useEffect(()=>{
        if(initFlag){
            resetPoint();
            setDisabledFolder(false);
            setValuePoint('pointPayTy', '00');

            let shapeArr = [];
            if(shapeConfig){
                console.log('shapeConfig', shapeConfig);
                for(const [key, value] of Object.entries(shapeConfig)){
                    if(key==='labels'){
                        let labels = value;
                        labels.forEach(label=>{
                            shapeArr.push(label.type);
                        });
                        shapeArr = shapeArr.filter((shape,index) => shapeArr.indexOf(shape) === index);
                        setPointShapeList(shapeArr.map(shape=> {return ({type: shape, id: labelShapesTypeInfo[shape].id})}));
                    }
                }
            }

            if(pointConfig){
                console.log('pointConfig', pointConfig);
                for(const [key, value] of Object.entries(pointConfig)){
                    if(key === 'instance'){
                        value.forEach((val)=>{
                            if(shapeArr.includes(val.type)){
                                setValuePoint(`${key}.${val.id}.id`, val.id);
                                setValuePoint(`${key}.${val.id}.type`, val.type);
                                //setValuePoint(`${key}.${val.id}.value`, val.value);
                                numberFormatter(val.value.toString(), `${key}.${val.id}.value`);
                            }
                        })
                    }else{
                        setValuePoint(key, (value ? value.toLocaleString() : ""));
                    }
                }
            }

            if(bindConfig){
                if(bindConfig.dataBindType == '00'){
                    // 데이터(파일) 할당
                    // 폴더 포인트 설정으로 못하게
                    setDisabledFolder(true);
                    if(pointConfig&& pointConfig.pointPayTy == '01'){
                        setValuePoint('pointPayTy', '00');
                    }

                    if(pointConfig && pointConfig.pointPayTy && pointConfig.pointPayTy != '00'){
                        setValuePoint('payPoint', '');
                    }

                }/*else if(bindConfig.dataBindType == '01'){
                // 데이터(파일) 할당
                setDisabledFolder(false);
            }*/

            }
        }

    }, [initFlag, pointConfig, shapeConfig, bindConfig]);

    const handleClickPointPayTy = (e) =>{
        let pointPayTy = e.target.value;

        if(pointPayTy == '00' || pointPayTy =='01') {
            if (e.target.checked) {
                // 조건 충족을 체크 했을 경우에는 group을 추가 해줘야함.
                unregisterPoint('instance',);
            }
        }else if(pointPayTy == '02'){
            if (e.target.checked) {
                unregisterPoint('payPoint',);
            }
        }

    }

    const numberFormatter = (value, key) => {
        const numberValue = value.replace(/,/g, "");
        if (!isNaN(Number(numberValue)) && Number.isFinite(+numberValue)) {
            const formattedValue = Number(numberValue).toLocaleString("ko-Kr");
            setValuePoint(key, formattedValue
                // , {shouldDirty: true, shouldValidate: true}
            );
        }
    }

    return(
        <>
            <SimpleBar style={{ maxHeight: "calc(100vh - 229px)" }} autoHide={false} className="simplebar-track-coco">
                <OffcanvasBody style={{ minHeight: "300px" }} >
                    <h5 className="module-title">
                        <i className="ri-money-dollar-circle-line"></i>{GetMessageIntl(intl,"workflow.pointPaySetting")}
                    </h5>
                    <Row>
                        <Col md={12}>
                            <Label htmlFor="standardTy1" className="form-label">{GetMessageIntl(intl,"workflow.pointPayStandard")}<span className="text-coco-danger-400">*</span></Label>
                            <div className="ht-38 d-flex align-items-center">
                                <div className="form-check form-radio-coco form-check-inline">
                                    <input className="form-check-input" type="radio" id="pointPayTy1" value="00" onClick={handleClickPointPayTy}{...registerPoint("pointPayTy")}/>
                                    <Label className="form-check-label mb-0" htmlFor="pointPayTy1">
                                        {GetMessageIntl(intl,"workflow.pointFile")}
                                    </Label>
                                </div>
                                <div className="form-check form-radio-coco form-check-inline">
                                    <input className="form-check-input" type="radio" id="pointPayTy2" onClick={handleClickPointPayTy} value="01" {...registerPoint("pointPayTy")} disabled={disabledFolder}/>
                                    <Label className="form-check-label mb-0" htmlFor="pointPayTy2">
                                        {GetMessageIntl(intl,"workflow.dataFolder")}
                                    </Label>
                                </div>
                                <div className="form-check form-radio-coco form-check-inline">
                                    <input className="form-check-input" type="radio" id="pointPayTy3" onClick={handleClickPointPayTy} value="02" {...registerPoint("pointPayTy")}/>
                                    <Label className="form-check-label mb-0" htmlFor="pointPayTy3">
                                        {GetMessageIntl(intl,"workflow.pointInstanceTool")}
                                    </Label>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <hr className="coco-hr"/>
                    <div>
                        <h5 className="module-title">
                            <i className="ri-coin-line"></i>{GetMessageIntl(intl,"workflow.pointPayInput")}
                        </h5>
                        <>
                            {watchAllPoint.pointPayTy === '00' || watchAllPoint.pointPayTy === '01'?
                                <Row>
                                    <Col md={12}>
                                        <Label htmlFor="payPoint" className="form-label">{GetMessageIntl(intl,"workflow.pointPayValue")}<span className="text-coco-danger-400">*</span></Label>
                                        <div className={errorsPoint.payPoint?"validation-error":""}>
                                            <input type={"text"} min="0" maxLength={"7"} className="form-control"
                                                   {...registerPoint(`payPoint`,{
                                                        required: GetMessageIntl(intl,"validation.required"),
                                                        pattern: {
                                                            value: /^[0-9]+$/,
                                                            message:GetMessageIntl(intl,"validation.onlyNumber")
                                                        },
                                                        validate: (value, values) => {
                                                            if(parseInt(value) <= 0){
                                                                return GetMessageIntl(intl,"validation.greaterThanZero");
                                                            }
                                                        },
                                                        setValueAs: (value) => value.toString().replaceAll(",", "")
                                                    })}
                                                   onChange={(e) => {
                                                           const { value } = e.target;
                                                           if(value==''){
                                                               setValuePoint('payPoint',value);
                                                           }else{
                                                               numberFormatter(value, 'payPoint');
                                                           }
                                                       }}
                                                   placeholder={GetMessageIntl(intl,"workflow.pointPayInputPlaceholder")}
                                            />
                                        </div>
                                        {errorsPoint.payPoint && <p className="validation-error-message">{errorsPoint.payPoint.message}</p>}
                                    </Col>
                                </Row>
                                :
                                shapeConfig&&shapeConfig.labels && shapeConfig.labels.length>0?
                                    <>
                                        <ListGroup className="text-coco-gray-700 mb-0">
                                            {pointShapeList.map((shape, index)=>{
                                                return (
                                                    <ListGroupItem key={`shape_${shape.id}`} className={"d-flex align-items-start border-0 pd-0 tx-16 mb-16 fw-normal"} id={`shapeTyValue_${shape.id}`}>
                                                        <div className="projectClassNo wd-20px text-center mr-12 ht-38 d-flex align-items-center" >
                                                            {/*{...registerLabel(`classItem.${id}.id`, { required: true })}*/}
                                                            {index+1}
                                                            <input type="hidden" name="id" value={shape.id} {...registerPoint(`instance.${shape.id}.id`)} />
                                                            <input type="hidden" name="type" value={shape.type} {...registerPoint(`instance.${shape.id}.type`)} />
                                                        </div>
                                                        <div className="mr-12 ht-38 d-flex align-items-center">
                                                            <i className={"d-inline-flex i-coco-20 bg-coco-gray-700 ".concat(labelShapesTypeInfo[shape.type].icon)}/>
                                                        </div>
                                                        <div className={"tx-16 mn-wd-86 mr-24 ht-38 d-flex align-items-center"}>
                                                            {labelShapesTypeInfo[shape.type].label}
                                                        </div>
                                                        <div className="wd-320px">
                                                            <div className={errorsPoint.instance&&errorsPoint.instance[shape.id]&&errorsPoint.instance[shape.id].value?"validation-error":""}>
                                                                <input type={"text"} className="form-control" {...registerPoint(`instance.${shape.id}.value`,{
                                                                    required: GetMessageIntl(intl,"validation.required"),
                                                                    pattern: {
                                                                        value: /^[0-9]+$/,
                                                                        message:GetMessageIntl(intl,"validation.onlyNumber")
                                                                    },
                                                                    validate: (value, values) => {
                                                                        if(parseInt(value) <= 0){
                                                                            return GetMessageIntl(intl,"validation.greaterThanZero");
                                                                        }
                                                                    },
                                                                    setValueAs: (value) => value.toString().replaceAll(",", "")
                                                                })}
                                                                maxLength={"7"}
                                                                placeholder={GetMessageIntl(intl,"workflow.pointConfig.pointValuePlaceholder")}
                                                                onChange={(e) => {
                                                                   const { value } = e.target;
                                                                   if(value==''){
                                                                       setValuePoint(`instance.${shape.id}.value`,value);
                                                                   }else{
                                                                       numberFormatter(value, `instance.${shape.id}.value`);
                                                                   }
                                                                }
                                                               }
                                                                />
                                                            </div>
                                                            {errorsPoint.instance&&errorsPoint.instance[shape.id]&&errorsPoint.instance[shape.id].value&& <p className={"mb-0 validation-error-message"}>{errorsPoint.instance[shape.id].value.message}</p>}
                                                        </div>
                                                    </ListGroupItem>
                                                    )
                                                })
                                            }
                                        </ListGroup>
                                        <div className={"mt-12"}>
                                            <div className={"text-coco-gray-400 tx-16 fw-normal mb-8"}>{GetMessageIntl(intl, "workflow.pointConfig.pointAddOption")}</div>
                                            <Card style={{borderRadius:"7px", border: "1px dashed #CFD8DC"}}>
                                                <CardBody className={"pd-16 bg-coco-gray-50"}>
                                                    <Label htmlFor="instance.limitPoint">{GetMessageIntl(intl, "workflow.pointConfig.fileLimitPoint")}</Label>
                                                    <input type={"text"} className="form-control" {...registerPoint(`limitPoint`,{
                                                        pattern: {
                                                            value: /^[0-9]+$/,
                                                            message:GetMessageIntl(intl,"validation.onlyNumber")
                                                        },
                                                        validate: (value, values) => {
                                                            let max = getValuesPoint('instance').reduce((previous, current) => {
                                                                return current.value > previous.value ? current : previous;
                                                            });

                                                            if(parseInt(value) <= 0){
                                                                return GetMessageIntl(intl,"validation.greaterThanZero");
                                                            }

                                                            if(max.value != '' && parseInt(max.value) > parseInt(value)){
                                                                return GetMessageIntl(intl, "workflow.pointConfig.instanceLimitValid");
                                                            }
                                                        }
                                                    })} placeholder={GetMessageIntl(intl,"workflow.pointConfig.pointValuePlaceholder")}/>
                                                    {errorsPoint.limitPoint&& <p className={"mb-0 validation-error-message"}>{errorsPoint.limitPoint.message}</p>}
                                                </CardBody>
                                            </Card>
                                        </div>
                                    </>
                                    :
                                    <>
                                        <div className={"d-flex align-items-center justify-content-center default-attribute-div"}
                                             style={{height: "calc(100vh - 470px)"}}>
                                            <span className="text-center text-coco-gray-200 tx-16 fw-500 lh-18" dangerouslySetInnerHTML={{ __html: GetMessageIntl(intl,"workflow.pointConfig.emptyShapeConfig")}}></span>
                                        </div>
                                    </>
                            }
                        </>
                    </div>
                </OffcanvasBody>
            </SimpleBar>
        </>
    );
}


export default PointConfigDiv;

