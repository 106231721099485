import {
  INIT_MANAGE_WORKFLOW_LABEL_MODULE_CONFIG,
  SET_MANAGE_WORKFLOW_LABEL_BASE_CONFIG,
  SET_MANAGE_WORKFLOW_LABEL_SHAPE_CONFIG,
  SET_MANAGE_WORKFLOW_LABEL_WORKER_CONFIG,
  SET_MANAGE_WORKFLOW_LABEL_BIND_CONFIG,
  SET_MANAGE_WORKFLOW_LABEL_ORDER_CONFIG,
  SET_MANAGE_WORKFLOW_LABEL_POINT_CONFIG,
  SET_MANAGE_WORKFLOW_LABEL_DATA_CONFIG,
  SET_MANAGE_WORKFLOW_LABEL_TEST_CONFIG,
  SET_MANAGE_WORKFLOW_LABEL_CLASS_ITEMS,
  ADD_MANAGE_WORKFLOW_LABEL_CLASS_ITEM,
  UPDATE_MANAGE_WORKFLOW_LABEL_CLASS_ITEM,
  DELETE_MANAGE_WORKFLOW_LABEL_CLASS_ITEM,
  SET_MANAGE_WORKFLOW_LABEL_ATTR_ITEMS,
  ADD_MANAGE_WORKFLOW_LABEL_ATTR_ITEM,
  UPDATE_MANAGE_WORKFLOW_LABEL_ATTR_ITEM,
  DELETE_MANAGE_WORKFLOW_LABEL_ATTR_ITEM,
  SET_SELECTED_MANAGE_WORKFLOW_LABEL_CLASS_ID,
  SET_MANAGE_WORKFLOW_LABEL_ACTIVE_DATA,
  SET_MANAGE_WORKFLOW_LABEL_INACTIVE_DATA,
  SET_MANAGE_WORKFLOW_LABEL_VIEW_TYPE,
  SET_MANAGE_WORKFLOW_LABEL_SELECTED_ROW_ID,
  UPDATE_MANAGE_WORKFLOW_LABEL_EDITOR_CN_BY_ID,
  SET_MANAGE_WORKFLOW_LABEL_DATA_ERROR,
  SET_MANAGE_WORKFLOW_LABEL_FILES,
  SWITCH_MANAGE_WORKFLOW_LABEL_REGIST_DATA_REFRESH_FLAG,
  SET_MANAGE_WORKFLOW_LABEL_FILES_RESET,
  RESET_MANAGE_WORKFLOW_LABEL_MODULE_CONFIG,
  SET_MANAGE_WORKFLOW_LABEL_SETTING_VIEW_TYPE,
  SET_MANAGE_WORKFLOW_LABEL_SETTING_ROW_ID
} from './actionType';

const INIT_STATE = {
  baseConfig: null,
  shapeConfig: {
    labels: [],
    objectMin: null,
    objectMax: null
  },
  workerConfig: null,
  orderConfig: null,
  bindConfig: null,
  pointConfig: null,
  dataConfig: null,
  testConfig: null,
  /**
   * LabelSetting
   */
  selectedLabelClassId: -1,
  selectedLabelAttrId: -1,
  selectedLabelAttrItemId: -1,
  labelShapeConfig: [],
  labelClassItems: [],
  labelAttrItems: [],
  clsfcSmrySelectedId: "",
  clsfcSmryViewType: "",
  /**
   * WorkerSetting
   */
  labelDataset: [],
  labelGuide: null,
  error: {},
  /*
  * registerData
  * */
  activeData : [],
  inactiveData : [],
  viewType : "",
  selectedId : "",
  errorData: [],
  files: [],
  refreshFlag: false,
  initFlag: false,
};

const ManageWorkflowLabel = (state = INIT_STATE, action) => {
  switch (action.type) {
    case INIT_MANAGE_WORKFLOW_LABEL_MODULE_CONFIG:
      return {
        ...state
        ,baseConfig: action.payload.baseConfig? action.payload.baseConfig: null
        ,shapeConfig: action.payload.shapeConfig? action.payload.shapeConfig: []
        ,workerConfig: action.payload.workerConfig? action.payload.workerConfig: null
        ,bindConfig: action.payload.bindConfig? action.payload.bindConfig: null
        ,orderConfig: action.payload.orderConfig?action.payload.orderConfig:null
        ,pointConfig: action.payload.pointConfig? action.payload.pointConfig: null
        ,dataConfig: action.payload.dataConfig? action.payload.dataConfig: []
        ,testConfig: action.payload.testConfig? action.payload.testConfig: null
        ,tabActive: action.payload.tabActive? action.payload.tabActive: null
        ,labelClassItems:[]
        ,labelAttrItems:[]
        ,initFlag: true
      };
    case RESET_MANAGE_WORKFLOW_LABEL_MODULE_CONFIG:
      return {
        ...state
        ,baseConfig: null
        ,shapeConfig: null
        ,workerConfig: null
        ,bindConfig: null
        ,orderConfig: null
        ,pointConfig: null
        ,dataConfig: []
        ,testConfig: null
        ,tabActive: null
        ,labelClassItems:[]
        ,labelAttrItems:[]
        ,initFlag: false
      };
    case SET_MANAGE_WORKFLOW_LABEL_BASE_CONFIG:
      return {
        ...state,
        baseConfig:action.payload,
      };
    case SET_MANAGE_WORKFLOW_LABEL_SHAPE_CONFIG:
      return {
        ...state,
        shapeConfig:action.payload,
      };
    case SET_MANAGE_WORKFLOW_LABEL_WORKER_CONFIG:
      return {
        ...state,
        workerConfig:action.payload,
      };
    case SET_MANAGE_WORKFLOW_LABEL_BIND_CONFIG:
      return{
        ...state,
        bindConfig: action.payload,
      }
    case SET_MANAGE_WORKFLOW_LABEL_ORDER_CONFIG:
      return {
        ...state,
        orderConfig:action.payload,
      };
    case SET_MANAGE_WORKFLOW_LABEL_POINT_CONFIG:
      return {
        ...state,
        pointConfig:action.payload,
      };
    case SET_MANAGE_WORKFLOW_LABEL_DATA_CONFIG:
      return {
        ...state,
        dataConfig:action.payload,
      };
    case SET_MANAGE_WORKFLOW_LABEL_TEST_CONFIG:
      return {
        ...state,
        testConfig:action.payload,
      };

    case SET_MANAGE_WORKFLOW_LABEL_CLASS_ITEMS:
      return {
        ...state,
        labelClassItems:action.payload,
      };

    case ADD_MANAGE_WORKFLOW_LABEL_CLASS_ITEM:
      return {
        ...state,
        labelClassItems:[...state.labelClassItems, action.payload]
      };

    case UPDATE_MANAGE_WORKFLOW_LABEL_CLASS_ITEM:
      return {
        ...state,
        labelClassItems: state.labelClassItems.map(item =>
            item.id === action.payload.classId?{...item, ...action.payload.item}
                :item)

      };

    case DELETE_MANAGE_WORKFLOW_LABEL_CLASS_ITEM:
      return{
        ...state,
        labelClassItems: state.labelClassItems.filter(
            labelClassItem => labelClassItem.id !== action.payload
        )
      };

    case SET_MANAGE_WORKFLOW_LABEL_ATTR_ITEMS:
      return{
        ...state,
        labelClassItems: state.labelClassItems.map(labelClassItem =>
            labelClassItem.id === action.payload.classId?{...labelClassItem, attributes:action.payload.items}
                :labelClassItem
        ),
        labelAttrItems: action.payload.items
      };

    case ADD_MANAGE_WORKFLOW_LABEL_ATTR_ITEM:
      return{
        ...state,
        labelClassItems: state.labelClassItems.map(labelClassItem =>
            labelClassItem.id === action.payload.classId?{...labelClassItem, attributes: [...labelClassItem.attributes, action.payload.item]}
                :labelClassItem
        ),
        labelAttrItems: [...state.labelAttrItems, action.payload.item]
      };

    case UPDATE_MANAGE_WORKFLOW_LABEL_ATTR_ITEM:
      return {
        ...state,
        labelClassItems: state.labelClassItems.map(item =>
            item.id === action.payload.classId?{...item, attributes: item.attributes.map(
                      attr => attr.id === action.payload.attrId?  action.payload.item : attr
                  )}
                :item
        ),
        labelAttrItems: state.labelAttrItems.map(item =>
            item.id === action.payload.attrId? action.payload.item
                :item
        ),
      };

    case DELETE_MANAGE_WORKFLOW_LABEL_ATTR_ITEM:
      console.log(action.payload);
      return{
        ...state,
        labelClassItems: state.labelClassItems.map(item =>
            item.id === action.payload.classId?{...item, attributes: item.attributes.filter(
                  attr => attr.id != action.payload.deleteAttrId)}:item
        ),
        labelAttrItems: state.labelAttrItems.filter(item =>
            item.id !== action.payload.deleteAttrId
        )
      }
    case SET_SELECTED_MANAGE_WORKFLOW_LABEL_CLASS_ID:
      return {
        ...state,
        selectedLabelClassId: action.payload,
        labelAttrItems: action.payload === -1 ? []: state.labelClassItems.find(labelClassItem =>
            labelClassItem.id === action.payload).attributes
      };
    case SET_MANAGE_WORKFLOW_LABEL_ACTIVE_DATA:
      return{
        ...state,
        activeData: [...action.payload]
      }
    case SET_MANAGE_WORKFLOW_LABEL_INACTIVE_DATA:
      return{
        ...state,
        inactiveData: [...action.payload]
      }
    case SET_MANAGE_WORKFLOW_LABEL_VIEW_TYPE:
      return{
        ...state,
        viewType: action.payload
      }
    case SET_MANAGE_WORKFLOW_LABEL_SELECTED_ROW_ID:
      return{
        ...state,
        selectedId: action.payload
      }
    case SET_MANAGE_WORKFLOW_LABEL_DATA_ERROR:
      return{
        ...state,
        errorData: action.payload
      }
    case UPDATE_MANAGE_WORKFLOW_LABEL_EDITOR_CN_BY_ID:
      return{
        ...state,

      }
    case SET_MANAGE_WORKFLOW_LABEL_FILES:
      return {
        ...state,
        files: {
          ...state.files,
          [action.payload.id]: action.payload.files,
        },
      };
    case SET_MANAGE_WORKFLOW_LABEL_FILES_RESET:
      return {
        ...state,
        files: []
      }
    case SWITCH_MANAGE_WORKFLOW_LABEL_REGIST_DATA_REFRESH_FLAG:
      return{
        ...state,
        refreshFlag: !state.refreshFlag
      }
    case SET_MANAGE_WORKFLOW_LABEL_SETTING_ROW_ID:
      return {
        ...state,
        clsfcSmrySelectedId: action.payload
      }
    case SET_MANAGE_WORKFLOW_LABEL_SETTING_VIEW_TYPE:
      return {
        ...state,
        clsfcSmryViewType: action.payload
      }
    default:
      return state;
  }
};

export default ManageWorkflowLabel;
