import {callApi, callApiWithoutAuth} from '../../apis/utils/AxiosUtil';

export const DashboardService = {
    /**
     *
     * @param param{{prjctId:string}}
     * @returns {Promise<axios.AxiosResponse<any>>}
     */
    getProjectWorkflowList: (param) => callApi("/service/dashboard/projectWorkflowList", param),

    /**
     *
     * @param param{{prjctId:string}}
     * @returns {Promise<axios.AxiosResponse<any>>}
     */
    lastBatchDtmText: (param) => callApi("/service/dashboard/lastBatchDtmText", param),

    askBoard30DaysList: (param) => callApi("/service/dashboard/askBoard30DaysList", param),

    /**
     * 워크플로우 대시보드
     * @param param
     * @returns {Promise<axios.AxiosResponse<any>>}
     */
    workflowDashboardColumnAll: (param) => callApi("/service/dashboard/workflowDashboardColumnAll", param),

    /**
     * 프로젝트 대시보드
     * @param param
     * @returns {Promise<axios.AxiosResponse<any>>}
     */
    projectDashboardColumnAll: (param) => callApi("/service/dashboard/projectDashboardColumnAll", param),

    /**
     * 지급 포인트 누적 추이
     * @param param
     * @returns {Promise<axios.AxiosResponse<any>>}
     */
    projectDashboardColumn02: (param) => callApi("/service/dashboard/projectDashboardColumn02", param),


    /**
     * 프로젝트 대시보드 top 10 작업자
     * @param param
     * @returns {Promise<axios.AxiosResponse<any>>}
     */
    projectDashboardColumn03: (param) => callApi("/service/dashboard/projectDashboardColumn03", param),


    /**
     * 워크플로우 대시보드 제출/완료 추이
     * @param param
     * @returns {Promise<axios.AxiosResponse<any>>}
     */
    workflowWorkTrendLineChartData: (param) => callApi("/service/dashboard/workflowWorkTrendLineChartData", param),


}