// @ts-nocheck
import {Line} from "./file-info";
import {GetMessageIntl} from "../../../../../util/message";
import React, {useEffect} from "react";
import {Input, Label} from "reactstrap";
import {useIntl} from "react-intl";
import {useDispatch, useSelector} from "react-redux";
import {SttusDisplay} from "../../../../../store/dataset/details";
import {SkipController} from "./skip-controller";
import {CommonService} from "../../../../../apis/api/CmmnService";
import {updateCanvasSkipPopover} from "../../../../../store/label/action";
import {getRejectRsn, getSkipRsn} from "../../../../../store/dataset/details/action";
import {getAnnotationsReasons} from "../../../../../store/review/action";
import {cloneDeep} from "lodash";
import {CANVAS_DATA_TY} from "../../../../user/label/annotation/image/const";
import {ReviewRejectView} from "../../../../user/label/clsfcSmry/components/taskSideBar/summary/ReviewRejectView";

interface Props {
    displayMode: SttusDisplay | null;
}

export function JobInfoContent(props: Props): JSX.Element {
    const intl = useIntl();
    const dispatch = useDispatch();
    const {displayMode} = props;
    const {
        Details: {
            fileInfo: {
                lastRejectRsn,
                rejectCount,
                labelUserNm,
                reviewUserNm,
                reviewModuleId,
                labelModuleId
            }
        },
        Label: {
            Cmmn: {
                canvas: {
                    skipPopover: {reasons: skipRsn, choices}
                },
                statistics: {
                    data: statisticsData
                },
                frames: {
                    frame: {
                        datasetId,
                        fileSeq
                    }
                },
                module : {
                    canvasDataTy
                }
            }
        },
        Review: {
            Cmmn: {
                annotations: {
                    reasons: rejectRsn
                }
            }
        }
    } = useSelector(state => state);

    useEffect(() => {
        if (labelModuleId && datasetId && fileSeq) {
            dispatch(getRejectRsn({labelModuleId, datasetId, fileSeq}));
        }
    }, [reviewModuleId, datasetId, fileSeq])

    useEffect(() => {
        if (labelModuleId && datasetId && fileSeq){
            dispatch(getSkipRsn(labelModuleId, datasetId, fileSeq));
        }
    }, [labelModuleId, datasetId, fileSeq])

    const instCnt = statisticsData ? statisticsData.totalData.total : 0;
    return (
        <>
            <Line name={GetMessageIntl(intl, 'details.lastLabeler')} value={labelUserNm} />
            {
                displayMode === SttusDisplay.SUBMIT
                && <Line name={GetMessageIntl(intl, 'details.lastReviewer')} value={reviewUserNm}/>
            }
            <Line name={GetMessageIntl(intl, 'details.rejectCount')} value={rejectCount} />
            {
               canvasDataTy === CANVAS_DATA_TY.IMAGE_ANNOTATION
               && <Line name={GetMessageIntl(intl, 'details.instanceCount')} value={instCnt} />
            }
            {
                displayMode === SttusDisplay.SUBMIT
                && canvasDataTy === CANVAS_DATA_TY.IMAGE_ANNOTATION
                && <Line name={GetMessageIntl(intl, 'details.lastRejectRsn')}
                         value={<LastRejectRsn rejectRsn={rejectRsn} choiceCdList={lastRejectRsn} />}/>
            }

            {
                displayMode === SttusDisplay.SUBMIT
                && canvasDataTy && canvasDataTy !== CANVAS_DATA_TY.IMAGE_ANNOTATION
                && rejectCount>0
                &&
                <>
                    <Line name={GetMessageIntl(intl, 'details.lastRejectRsn')}
                         value={null} />
                    <ClsfcSmryLastRejectRsn lastRejectList={lastRejectRsn}/>
                </>
            }
            {
                canvasDataTy === CANVAS_DATA_TY.IMAGE_ANNOTATION
                && <Line name={GetMessageIntl(intl, 'details.skipRsn')}
                         value={<SkipRsn skipRsn={skipRsn} choices={choices} />} />
            }

            {
                canvasDataTy != CANVAS_DATA_TY.IMAGE_ANNOTATION
                && choices.rsnCd.length>0
                &&
                <>
                    <Line name={GetMessageIntl(intl, 'details.skipRsn')}
                         value={null} />
                    <ClsfcSmrySkipRsn skipRsn={skipRsn} choices={choices} />
                </>
            }

        </>
    )
}

interface LastRejectRsnProps{
    rejectRsn: any[],
    choiceCdList: string[]
}
function LastRejectRsn({rejectRsn, choiceCdList}: LastRejectRsnProps): JSX.Element {
    const intl = useIntl();
    const reject = cloneDeep(rejectRsn);
    reject.push({cmmnDetailCd: "11", cmmnDetailCdNm: GetMessageIntl(intl, 'details.classReject')})
    return (
        <>
            {reject.filter(rsn => rsn.cmmnDetailCd !== '01').map((rsn, index) => {
                const inputID = `info-${index}`;
                const {cmmnDetailCd: rsnCd} = rsn;
                const checked = choiceCdList.includes(rsnCd);
                return (
                    <div className="form-check form-check-coco" key={inputID}>
                        <Input className="form-check-input" type="checkbox"
                               id={inputID}
                               value={rsnCd}
                               checked={checked}
                               disabled={true}
                        />
                        <Label className="form-check-label" htmlFor={inputID}>
                            {rsn.cmmnDetailCdNm}
                        </Label>
                    </div>
                )})
            }
        </>
    )
}

interface SkipRsnProps {
    skipRsn: any;
    choices: any;
}
function SkipRsn({skipRsn, choices}: SkipRsnProps): JSX.Element {
    const { rsnCd: choiceCdList, rsnCn: choiceCn } = choices;

    return (
        <>
            {skipRsn.map((rsn: any): JSX.Element => {
                    const { cmmnDetailCd: rsnCd} = rsn;
                    return (
                        <div className="form-check form-check-coco" key={rsnCd}>
                            <Input className="form-check-input" type="checkbox"
                                   id={rsnCd}
                                   checked={choiceCdList.includes(rsnCd)}
                                   value={rsnCd}
                                   disabled={true}
                            />
                            <Label className="form-check-label" htmlFor={rsnCd}>
                                {rsn.cmmnDetailCdNm}

                            </Label>
                            {rsnCd==="01" && choiceCdList.includes(rsnCd) &&
								<div className={"d-flex justify-content-between text-coco-gray-200"}>
									<div>({choiceCn})</div>
								</div>}
                        </div>
                    )
                }
            )}
        </>
    )
}


function ClsfcSmryLastRejectRsn({lastRejectList}): JSX.Element {
    const intl = useIntl();
    return (
        <>
            {lastRejectList.map((reject) => {
                const {question, type, classes} = reject;
                return (
                    <div key={`lastRejectRsn_${reject.classes.id}`} className={"pd-x-8 pd-y-10 bg-coco-gray-50 mt-10"} style={{borderRadius:'4px'}}>
                        <div className={"d-flex align-items-center tx-14"}>
                            {
                                type=='clsfc' &&
                                <div className="avatar-title rounded-circle pd-4"
                                     style={{backgroundColor: "#EDE0FF", color: "#9E53FF", height: "20px", width: "20px"}}>
                                    <i className="ri-node-tree tx-14"></i>
                                </div>
                            }
                            { type=='smry' &&
                                <div className="avatar-title rounded-circle pd-4"
                                     style={{backgroundColor: "#FFF4D7", color: "#FFB900", height: "20px", width: "20px"}}>
                                    <i className="mdi mdi-text-shadow tx-14"></i>
                                </div>
                            }
                            <span className={"text-coco-gray-700 fw-500 pd-l-8"}>
                                {question}
                            </span>
                        </div>
                        <ul className={"m-0  pd-r-8 pd-l-24 tx-14 mt-10 fw-normal"}>
                            {
                                type=='clsfc' &&
                                <li>{GetMessageIntl(intl, "details.classificationError")}: {classes.q1.content[0].details}</li>
                            }
                            {
                                type=='smry'
                                && classes.q1.reject
                                && <li>{GetMessageIntl(intl, "review.missingKeyword")}: {classes.q1.content[0].details}</li>
                            }
                            {
                                type=='smry'
                                && classes.q2.reject
                                && classes.q2.content.map((q)=>{
                                    return <li key={`q2_${q.id}`}>{q.sentence}:{q.details}</li>
                                })
                            }
                            {
                                type=='smry'
                                && classes.q3.reject
                                && classes.q3.content.map((q)=>{
                                    return <li key={`q3_${q.id}`}>{q.sentence}:{q.details}</li>
                                })
                            }
                        </ul>
                    </div>
                )})
            }
        </>
    )
}

function ClsfcSmrySkipRsn({skipRsn, choices}: SkipRsnProps): JSX.Element {
    const { rsnCd: choiceCdList, rsnCn: choiceCn } = choices;

    return (
        <div className={"pd-x-8 pd-y-10 bg-coco-gray-50 mt-10"} style={{borderRadius:'4px'}}>
            <ul className={"m-0  pd-r-8 pd-l-24 tx-14"}>
            {choiceCdList.map((rsnCd: any): JSX.Element => {
                const choiceRsn = skipRsn.find((skip)=> skip.cmmnDetailCd == rsnCd);
                return (
                    <li key={`choiceLi_${choiceRsn.cmmnDetailCdNm}`}>
                        {choiceRsn.cmmnDetailCdNm}
                        {rsnCd==="01" &&
                            <span>:{choiceCn}</span>
                        }
                    </li>
                )
                }
            )}
            </ul>
        </div>
    )
}
