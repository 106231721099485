// @ts-nocheck
import {useIntl} from "react-intl";
import {useDispatch, useSelector} from "react-redux";
import {DropdownItem, Input} from "reactstrap";
import {GetMessageIntl} from "../../../../../util/message";
import React from "react";
import {getDataList, updateSearchParam} from "../../../../../store/dataset/details/action";
import {SearchFilter} from "../../../../../store/dataset/details";
import {ApplySearchButton} from "./search-header";

export function FolderFilter(): JSX.Element{
    const intl = useIntl();
    const dispatch = useDispatch();
    const {
        Details: {
            searchParam:{
                view,
                data
            }
        }
    } = useSelector(state => state);

    const choice = data.folder;
    const choiceLabelList = view.folder;

    const onChange = (target) => {
        const value = target.value;

        dispatch(updateSearchParam(SearchFilter.FOLDER, (value=='' ? null : value), [{label: value}]))
    }

    const onKeyPress = (e) => {
        if (e.key === "Enter"){
            dispatch(getDataList());
        }
    }

    return(
        <>
            <div className={"filter-container text-container"}>
                <div className={"d-flex align-items-center justify-content-between"}>
                    <DropdownItem header>{GetMessageIntl(intl, 'details.folder')}</DropdownItem>
                    {/*<ApplySearchButton/>*/}
                </div>
                <DropdownItem divider/>
                <div className={"d-flex flex-column align-items-center gap-2"}>
                    <div className="position-relative form-search">
                        <Input type="text" className="form-control" id="search-input"
                               placeholder={GetMessageIntl(intl, 'details.searchFolderPlaceholder')}
                               defaultValue={choice}
                               onChange={(event)=>onChange(event.target)}
                               onKeyDown={(event) => onKeyPress(event)}
                        />
                        <span className="ri-search-line search-widget-icon"></span>
                        <span className="ri-close-line search-widget-icon search-widget-icon-close d-none"
                              id="search-close-icon"></span>
                    </div>
                </div>
            </div>
        </>
    )
}
