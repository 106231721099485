// Copyright (C) 2020-2022 Intel Corporation
//
// SPDX-License-Identifier: MIT
// @ts-nocheck
import { connect } from 'react-redux';

import {Canvas, CuboidDrawingMethod, RectDrawingMethod} from 'pages/user/label/annotation/image/cvat-canvas-wrapper';
import {
    mergeObjects,
    groupObjects,
    splitTrack,
    pasteShapeAction,
    // redrawShape,
    repeatDrawShapeAction,
    rotateCurrentFrame, redrawShape, rememberObject,
} from 'store/label/action';
import ControlsSideBarComponent from 'pages/user/label/annotation/image/components/standard-workspace/controls-side-bar/controls-side-bar';
import {ActiveControl, CombinedState, ObjectType, Rotation, ShapeType} from 'store/label';
import { KeyMap } from 'pages/user/label/annotation/image/utils/mousetrap-react';
import {switchGrid} from "../../../../../../../../store/settings/action";

interface StateToProps {
    canvasInstance: Canvas;
    rotateAll: boolean;
    activeControl: ActiveControl;
    keyMap: KeyMap;
    normalizedKeyMap: Record<string, string>;
    labels: any[];
    frameData: any;
}

interface DispatchToProps {
    mergeObjects(enabled: boolean): void;
    groupObjects(enabled: boolean): void;
    splitTrack(enabled: boolean): void;
    rotateFrame(angle: Rotation): void;
    resetGroup(): void;
    repeatDrawShape(): void;
    pasteShape(): void;
    // redrawShape(): void;
    onDrawStart(
        shapeType: ShapeType,
        labelID: number,
        objectType: ObjectType,
        points?: number,
        rectDrawingMethod?: RectDrawingMethod,
        cuboidDrawingMethod?: CuboidDrawingMethod,
    ): void;
}

function mapStateToProps(state: CombinedState): StateToProps {
    const {
        Label: {
            Cmmn: {
                canvas: {instance: canvasInstance, activeControl},
                module: {
                    labels,
                    countLimit: {objectMax}
                },
                player: {
                    frame: {data: frameData},
                },
                statistics: {
                    data: statisticsData
                },
                drawing: {
                    activeNumOfPoints,
                    activeRectDrawingMethod,
                    activeCuboidDrawingMethod,
                }
            }
        },
        settings: {
            player: {
                rotateAll,
                grid
            },
        },
        shortcuts: { keyMap, normalizedKeyMap },
    } = state;

    const shapeCount = statisticsData ? statisticsData.totalData.total : 0;

    return {
        rotateAll,
        grid,
        canvasInstance: canvasInstance as Canvas,
        activeControl,
        labels,
        normalizedKeyMap,
        keyMap,
        frameData,
        objectMax,
        shapeCount,
        activeNumOfPoints,
        activeRectDrawingMethod,
        activeCuboidDrawingMethod,
    };
}

function dispatchToProps(dispatch: any): DispatchToProps {
    return {
        mergeObjects(enabled: boolean): void {
            dispatch(mergeObjects(enabled));
        },
        groupObjects(enabled: boolean): void {
            dispatch(groupObjects(enabled));
        },
        splitTrack(enabled: boolean): void {
            dispatch(splitTrack(enabled));
        },
        rotateFrame(rotation: Rotation): void {
            dispatch(rotateCurrentFrame(rotation));
        },
        repeatDrawShape(): void {
            dispatch(repeatDrawShapeAction());
        },
        pasteShape(): void {
            dispatch(pasteShapeAction());
        },
        // resetGroup(): void {
        //     dispatch(resetAnnotationsGroup());
        // },
        redrawShape(): void {
            dispatch(redrawShape());
        },
        switchGrid(enabled: boolean): void {
            dispatch(switchGrid(enabled));
        },
        onDrawStart(
            shapeType: ShapeType,
            labelID: number,
            objectType: ObjectType,
            points?: number,
            rectDrawingMethod?: RectDrawingMethod,
            cuboidDrawingMethod?: CuboidDrawingMethod,
        ): void {
            dispatch(
                rememberObject({
                    activeObjectType: objectType,
                    activeShapeType: shapeType,
                    activeLabelID: labelID,
                    activeNumOfPoints: points,
                    activeRectDrawingMethod: rectDrawingMethod,
                    activeCuboidDrawingMethod: cuboidDrawingMethod,
                }),
            );
        },
    };
}

export default connect(mapStateToProps, dispatchToProps)(ControlsSideBarComponent);
