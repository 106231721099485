// @ts-nocheck
import React from "react";
import {MD_TY_CD} from "../const";

interface Props{
    type: MD_TY_CD;
    statesHidden: boolean;
    showStates():void;
    hideStates():void;
}

function SwitchHidden(props: Props): JSX.Element {
    const { statesHidden, showStates, hideStates } = props;
    return (
        <>
            { statesHidden ? (
                    <div className="cursor-pointer" type="button"
                         onClick={showStates}
                    >
                        <i className="ri-eye-close-line"></i>
                    </div>
                ) : (
                    <div className="cursor-pointer" type="button"
                         onClick={hideStates}
                    >
                        <i className="ri-eye-line"></i>
                    </div>
                )
            }
        </>
    )
}
function ClassesItemButtons(props: Props): JSX.Element {
    const {type} = props;
    if (type === MD_TY_CD.REVIEW) {
        return <div />;
    }
    return (
        <div className="fs-20 text-center">
            <SwitchHidden {...props} />
        </div>
    )
}

export default React.memo(ClassesItemButtons);
