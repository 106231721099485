import {HistoryRejectDetail} from "./HistoryRejectDetail";

interface Props {
    history: any;
    index: number;
    shapeConfig ?: any;
}
export function History(props: Props): JSX.Element {
    const {history, index, shapeConfig} = props;
    const notFirstItem = 'py-3';
    const { style } = history;
    let parsedData = null;

    if(shapeConfig){
        let classes = JSON.parse(history.issueCn).classes;
        let labels = shapeConfig.labels;

        parsedData = classes.map((item, idx) => {
            return {
                ...item,
                question: labels[idx].question
            }
        })
    }

    return(
        <div className={"history-item pd-b-32 acitivity-item fs-14 d-flex ".concat(index >= 1 ? notFirstItem : "")}>
            <div className="flex-shrink-0 fw-semibold text-coco-gray-700">
                <Icon className={""} iconClass={style.icon} backgroundColor={style.backgroundColor} iconColor={style.iconColor}/>
            </div>

            <div className="flex-grow-1 ms-3 lh-18">
                <div className={"subtitle mb-1"}>{style.subTitleText}</div>
                <div className="title mb-1">
                    {style.titleText}
                    {history.userNm &&
						<div className={"d-flex"}>(<div style={{color: style.textPointColor}}>{history.userNm}</div>)</div>
                    }
                </div>
                <div className="messasge mb-2 text-coco-gray-700">
                    {
                        // 반려 이력 상세 컴포넌트
                        shapeConfig && history.taskSttusCd === "04" &&
                            <HistoryRejectDetail data={parsedData}/>
                    }
                    {
                        // style.subText && <Message messages={style.subText}/>
                    }
                </div>
                <small className="date mb-0 text-muted text-coco-gray-400">{history.regDtmText}</small>
            </div>
        </div>
    )
}

interface IconProps {
    className: string;
    iconClass: string;
    backgroundColor: string;
    iconColor: string;
}

function Icon(props: IconProps): JSX.Element{
    const {className, iconClass, backgroundColor, iconColor} = props;
    return (
        <div className={'event-icon '+className} style={{backgroundColor: backgroundColor, color: iconColor}}>
            <div className={'icon '+iconClass} />
        </div>
    )
}

interface MeessageProps{
    messages: string[];
}
function Message(props: MeessageProps): JSX.Element{
    const {messages} = props;
    return (
        <>
            {messages.map((msg, index) => {
                return (
                    <div key={`msg-${index}`}>
                        · {msg}
                    </div>
                )
            })
            }
        </>
    )
}
