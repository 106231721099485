import {callApi, callApiWithoutAuth} from '../../apis/utils/AxiosUtil';

export const CommonService={
    getTimezoneList : () => callApiWithoutAuth("/util/timezoneList", null),

    /**
     * 클라우드 스토리지 공급자 목록
     * @returns {Promise<axios.AxiosResponse<any>>}
     */
    getStorageVendorList : () => callApiWithoutAuth("/util/cmmnCodeList", {cmmnCd:'STORAGE_VENDOR_CD'}),
    /**
     * 게시판 : FAQ 내용 타입 목록
     * @param param
     * @returns {Promise<axios.AxiosResponse<any>>}
     */
    getFaqCnTyList : () => callApiWithoutAuth("/util/cmmnCodeList", {cmmnCd:'BOARD_FAQ_CN_TY_CD'}),
    /**
     * 게시판 : 1:1 문의 내용 타입 목록
     * @param param
     * @returns {Promise<axios.AxiosResponse<any>>}
     */
    getAskCnTyList : () => callApiWithoutAuth("/util/cmmnCodeList", {cmmnCd:'BOARD_ASK_CN_TY_CD'}),
    /**
     * 데이터 도메인 코드 목록
     * @param param
     * @returns {Promise<axios.AxiosResponse<any>>}
     */
    getDataDomainCdList : () => callApiWithoutAuth("/util/cmmnCodeList", {cmmnCd:'DATA_DOMAIN_CD'}),
    /**
     * 회원가입: 직업 구분 코드 목록
     * @param param
     * @returns {Promise<axios.AxiosResponse<any>>}
     */
    getJobCodeList : () => callApiWithoutAuth("/util/cmmnCodeList", {cmmnCd:'JOB_CD'}),
    /**
     * 검수 : 검수 반려 사유 목록
     * @param param
     * @returns {Promise<axios.AxiosResponse<any>>}
     */
    getReviewRejectReasonList : () => callApiWithoutAuth("/util/cmmnCodeList", {cmmnCd:'REVIEW_RJCT_RSN_CD'}),

    /**
     * 스킵 사유 코드(이미지 라벨링 11 )
     * @returns {Promise<axios.AxiosResponse<any>>}
     */
    getSkipRsnCd: () => callApiWithoutAuth("/util/cmmnCodeList", {cmmnCd: 'SKIP_RSN_CD_11'}),

    /**
     * 스킵 사유 코드(이미지 분류/요약  라벨링 12 )
     * @returns {Promise<axios.AxiosResponse<any>>}
     */
    getSkipRsnCdForImageClsfcSmry: () => callApiWithoutAuth("/util/cmmnCodeList", {cmmnCd: 'SKIP_RSN_CD_12'}),
    /**
     * 스킵 사유 코드(오디오 분류/요약  라벨링 22)
     * @returns {Promise<axios.AxiosResponse<any>>}
     */
    getSkipRsnCdForAudioClsfcSmry: () => callApiWithoutAuth("/util/cmmnCodeList", {cmmnCd: 'SKIP_RSN_CD_22'}),
    /**
     * 스킵 사유 코드(비디오 분류/요약  라벨링 32)
     * @returns {Promise<axios.AxiosResponse<any>>}
     */
    getSkipRsnCdForVideoClsfcSmry: () => callApiWithoutAuth("/util/cmmnCodeList", {cmmnCd: 'SKIP_RSN_CD_32'}),
    /**
     * 스킵 사유 코드(텍스트 분류/요약 라벨링 42)
     * @returns {Promise<axios.AxiosResponse<any>>}
     */
    getSkipRsnCdForTextClsfcSmry: () => callApiWithoutAuth("/util/cmmnCodeList", {cmmnCd: 'SKIP_RSN_CD_42'}),

    /**
     * 작업자 참여 제한 타입 코드
     * @returns {Promise<axios.AxiosResponse<any>>}
     */
    getWorkerJoinCtrlTyCd : () => callApiWithoutAuth("/util/cmmnCodeList", {cmmnCd:'WORKER_JOIN_CTRL_TY_CD'}),

    /**
     * 작업자참여조건 코드
     * @returns {Promise<axios.AxiosResponse<any>>}
     */
    getWorkerJoinCondition : () => callApiWithoutAuth("/util/cmmnCodeList", {cmmnCd:'WORKER_JOIN_CONDITION'}),

    /**
     * 작업자참여조건-프로필
     * @returns {Promise<axios.AxiosResponse<any>>}
     */
    getWorkerJoinConditionProfile : () => callApiWithoutAuth("/util/cmmnCodeList", {cmmnCd:'WORKER_JOIN_CONDITION_PROFILE'}),

    /**
     * 성별
     * @returns {Promise<axios.AxiosResponse<any>>}
     */
    getGenderCd : () => callApiWithoutAuth("/util/cmmnCodeList", {cmmnCd:'GENDER_CD'}),

    /**
     * 학력
     * @returns {Promise<axios.AxiosResponse<any>>}
     */
    getEducationCd : () => callApiWithoutAuth("/util/cmmnCodeList", {cmmnCd:'EDUCATION_CD'}),

    /**
     * 직업 코드
     * @returns {Promise<axios.AxiosResponse<any>>}
     */
    getWorkerJobCd : () => callApiWithoutAuth("/util/cmmnCodeList", {cmmnCd:'WORKER_JOB_CD'}),

}
