// @ts-nocheck
import {useDispatch, useSelector} from "react-redux";
import React, {useEffect, useState} from "react";
import {ActiveControl} from "../../../../../../../../store/label";
import {
    getAutoLabelAnnotation, loadPrevFileObjects,
    redoAction as redoActionAsync,
    undoAction as undoActionAsync
} from "../../../../../../../../store/label/action";
import {RightGroup} from "../../../components/header/right-group/RightGroup";
import {ExitBtn, FAQBtn, GuideBookBtn} from "../../../../../cmmn/components/header/rightGroup/RightGroupButtons";
import ShortcutsButton from "./shortcuts-button";

export const RightGroupContainer = (): JSX.Element => {
    const dispatch = useDispatch();
    const {
        Label: {
            Cmmn: {
                canvas: {
                    instance: canvasInstance,
                    activeControl,
                },
                module: {
                    instance: module, moduleId
                },
                frames: {
                    frame: frameData
                },
                annotations: {history},
                player: {
                    frame: {number: frame}
                },
            }
        },
        shortcuts: { keyMap, normalizedKeyMap }
    } = useSelector(state => state);


    const [undoAction, setundoAction] = useState(undefined);
    const [redoAction, setredoAction] = useState(undefined);

    useEffect(() => {
        setundoAction(history.undo.length ? history.undo[history.undo.length - 1][0] : undefined)
        setredoAction(history.redo.length ? history.redo[history.redo.length - 1][0] : undefined)
    }, [history])


    const undo = () => {
        if (activeControl === ActiveControl.CURSOR) {
            dispatch(undoActionAsync(module, frame));
        }
    }
    const redo = () => {
        if (activeControl === ActiveControl.CURSOR) {
            dispatch(redoActionAsync(module, frame));
        }
    }

    const getAutoLabel = () => {
        dispatch(getAutoLabelAnnotation("dog", frameData, module))
    }

    const AutoBtn = () => {
        return (
            <div className="ms-1 header-item d-none d-sm-flex">
                <button type="button" id="auto" className="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle text-white"
                        onClick={getAutoLabel}
                >
                    자동
                    {/*<%--								<i className="mdi mdi-arrow-left-top fs-24 text-white"></i>--%>*/}
                </button>
            </div>
        )
    }

    const UndoBtn = () => {
        return (
            <div className="ms-1 header-item d-flex">
                <button type="button" id="undo" className="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle"
                        onClick={undo}
                >
                    <i className="mdi mdi-arrow-left-top fs-24 text-white"></i>
                </button>
            </div>
        )
    };

    const RedoBtn = () => {
        return (
            <div className="ms-1 header-item d-flex">
                <button type="button" id="redo" className="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle"
                        onClick={redo}
                >
                    <i className="mdi mdi-arrow-right-top fs-24 text-white"></i>
                </button>
            </div>
        )
    };

    const preventDefault = (event: KeyboardEvent | undefined): void => {
        if (event) {
            event.preventDefault();
        }
    };

    const subKeyMap: any = {
        LOAD_PREV_FILE_OBJECTS: keyMap.LOAD_PREV_FILE_OBJECTS,
        UNDO: keyMap.UNDO,
        REDO: keyMap.REDO,
    };

    const handlers: any = {
        LOAD_PREV_FILE_OBJECTS: (event: KeyboardEvent | undefined) => {
            preventDefault(event);
            if (activeControl !== ActiveControl.CURSOR) {
                canvasInstance.cancel();
            }
            dispatch(loadPrevFileObjects(module, frameData))
        },
        UNDO: (event: KeyboardEvent | undefined) => {
            preventDefault(event);
            if (activeControl !== ActiveControl.CURSOR) {
                canvasInstance.cancel();
            }
            undo();
        },
        REDO: (event: KeyboardEvent | undefined) => {
            preventDefault(event);
            if (activeControl !== ActiveControl.CURSOR) {
                canvasInstance.cancel();
            }
            redo();
        }
    }

    return (
        <RightGroup subKeyMap={subKeyMap} handlers={handlers}>
            <>
                <AutoBtn/>
                <UndoBtn/>
                <RedoBtn/>
                <FAQBtn moduleId={moduleId}/>
                <GuideBookBtn moduleId={moduleId}/>
                <ShortcutsButton/>
                <ExitBtn/>
            </>
        </RightGroup>
    )
}
