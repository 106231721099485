import React, {useCallback, useEffect, useRef, useState} from 'react'
import 'filepond/dist/filepond.min.css'
import {GetMessage} from "../../util/message";
import {Input, Label} from "reactstrap";
import {Controller, useForm} from "react-hook-form";
import Select from "react-select";
import {multiSingleSelectStyle} from "../../components/constants/select";

const ModuleTestConfigClassTest = () => {
    return (
        <>


        </>
    )
}


export function ChipLabelSelector(props) {
    const {
        labels, value, onChange, onEnterPress, ...rest
    } = props;

    const inputChange = (newValue) => {
        const [label] = labels.filter((_label) => _label.id === +newValue);
        if (label) {
            onChange(label);
        } else {
            throw new Error(`Label with id ${newValue} was not found within the list`);
        }
    }
    return (
        <div className={"d-flex"}>
            {
                labels.map(element => (
                    <div className="form-check form-radio-coco" key={element.id}>
                        <Input type="radio" className="form-check-input btn-check"
                               id={`coco-class-${element.id}`}
                               name="object-popover-class" required
                               value={element.id}
                               onChange={(e) => inputChange(parseInt(e.target.value))}
                               checked={element.id === value}
                        />
                        <Label className="form-check-label btn btn-coco-outline-1 pd-x-12 pd-y-3"
                               htmlFor={`coco-class-${element.id}`}
                        >
                            {element.name}
                        </Label>
                    </div>
                ))
            }
        </div>
    )
}

export function ItemAttributeTitle(props){
    const {attrTitleClassName, attrName, attrRequired, attrInputType} = props;
    return (
        <div className={attrTitleClassName}>
            {attrName}
            {!!attrRequired && <span className={"text-danger"}>*</span>}
            {
                attrInputType === 'checkbox'?
                    <span className={"px-1 text-coco-gray-400"}>({GetMessage('ui.U00047')})</span>
                    : attrInputType === 'select'?
                        <span className={"px-1 text-coco-gray-400"}>({GetMessage('ui.U00046')})</span>
                        :<></>
            }

        </div>
    )
}
export function ItemAttribute(props){
    const {readonly, attrInputType, attrValues, attrValue, attrName, attrID,
        attrRequired, attrRegexp, attrRegexpSample, changeAttribute,
        control, register, errors
    } = props;
    console.log(props);
    const attrClassName = "coco-object-popover-attribute";
    const attrTitleClassName = "coco-object-popover-attribute-title";
    const attrInputPrefix = "coco-object-popover-attribute-input-"
    const attrInputName = attrInputPrefix+attrID

    let rules = {
        required: {
            value: attrRequired,
            message: GetMessage("validation.required")
        }
    };
    if (attrRegexp) {
        rules.pattern = {
            value: new RegExp(attrRegexp),
            message: GetMessage("validation.contentNotVaild")
        }
    }

    if (attrInputType === "text") {
        return (
            <div className={attrClassName}>
                <ItemAttributeTitle
                    attrInputType={attrInputType}
                    attrTitleClassName={attrTitleClassName}
                    attrName={attrName}
                    attrRequired={attrRequired}
                />
                <div className={Object.keys(errors).includes(attrInputName)?"validation-error":""}>
                    <Controller
                        control={control}
                        name={attrInputName}
                        render={({ field }) => {
                            const { onChange } = field;
                            return (
                                <Input {...field}
                                       defaultValue={attrValue??''}
                                       onChange={(event) => {
                                           const newValue = event.target.value;
                                           onChange(newValue);
                                           changeAttribute(attrID, newValue);
                                       }}
                                       autoComplete={"off"}
                                       placeholder={attrRegexpSample}
                                />
                            )
                        }}
                        rules={rules}
                    />
                </div>
                {Object.keys(errors).includes(attrInputName) && <p className="validation-error-message">{errors[attrInputName].message}</p>}
            </div>
        )
    }

    let selectOption = [];
    attrValues.map(attr => {
        selectOption.push({label: attr, value: attr})
    });

    if (attrInputType === "select") {
        const [singleAttr, setSingleAttr] = useState(selectOption.find(option => option.value === attrValue));
        return (
            <div className={attrClassName}>
                <ItemAttributeTitle
                    attrInputType={attrInputType}
                    attrTitleClassName={attrTitleClassName}
                    attrName={attrName}
                    attrRequired={attrRequired}
                />
                <div className={Object.keys(errors).includes(attrInputName)?"validation-select-error":""}>
                    <Controller
                        control={control}
                        name={attrInputName}
                        render={({field}) =>
                            <Select
                                {...field}
                                options={selectOption}
                                value={singleAttr}
                                styles={canvasMultiSingleSelectStyle}
                                className="mb-0"
                                onChange={(newValue) => {
                                    const newAttr = newValue[newValue.length-1];
                                    setSingleAttr(newAttr);
                                    if (newAttr){
                                        changeAttribute(attrID, newAttr.value);
                                    } else {
                                        changeAttribute(attrID, '');
                                    }
                                }}
                                isMulti
                            />
                        }
                        rules={rules}
                    />
                </div>
                {Object.keys(errors).includes(attrInputName) && <p className="validation-error-message">{errors[attrInputName].message}</p>}
            </div>
        )
    }

    if (attrInputType === "checkbox") {
        const [multiAttr, setMultiAttr] = useState(attrValue ? selectOption.filter(option => attrValue.includes(option.value)) : []);
        return (
            <div className={attrClassName}>
                <ItemAttributeTitle
                    attrInputType={attrInputType}
                    attrTitleClassName={attrTitleClassName}
                    attrName={attrName}
                    attrRequired={attrRequired}
                />
                <div className={Object.keys(errors).includes(attrInputName) ? "validation-select-error" : ""}>
                    <Controller
                        control={control}
                        name={attrInputName}
                        render={({field}) => {
                            const {onChange} = field;
                            return (
                                <Select
                                    {...field}
                                    options={selectOption}
                                    defaultValue={multiAttr}
                                    // value={multiAttr}
                                    styles={canvasMultiSingleSelectStyle}
                                    className={"mb-0"}
                                    onChange={(values) => {
                                        onChange(values);
                                        let newValues = [];
                                        for (const label of values) {
                                            newValues.push(label.value)
                                        }
                                        setMultiAttr(values)
                                        changeAttribute(attrID, newValues);
                                    }}
                                    isMulti
                                />
                            )
                        }

                        }
                        rules={rules}
                    />
                </div>
                {Object.keys(errors).includes(attrInputName) &&
                    <p className="validation-error-message">{errors[attrInputName].message}</p>}
            </div>
        );
    }
    return (
        <>

        </>
    );
}

const canvasMultiSingleSelectStyle = {
    ...multiSingleSelectStyle,
    multiValue: (styles, state) => {
        return {
            ...styles,
            backgroundColor: state.isDisabled? '#b0bec5':'#0bad8f',
            padding:'0px 6px 0px 0px',
            borderRadius:'4px'
        };
    },
    multiValueLabel: (styles, state) => ({
        ...styles,
        color: 'white',
        borderRight:state.isDisabled? '1px solid white': '1px solid #7ed0bd',
        borderRadius: '0px',
        paddingRight:'6px',
        fontSize: '12px'
    })
}
export default ModuleTestConfigClassTest;