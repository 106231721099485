import {callApi} from '../utils/AxiosUtil';

export const BoardService={
   getNoticeList: (param) => callApi('/service/board/noticeList', param),
   getFaqList: (param) => callApi('/service/board/faqList', param),
   getAskList: (param) => callApi('/service/board/askList', param),
   getTmpSaveData: (param) => callApi('/service/board/tmpSave', param),
   uploadContentImage: (param, option) => callApi('/service/board/upload/contentfile', param, option),
   setContent: (param, option) => callApi('/service/board/boardCn/update', param, option),
   getContent: (param) => callApi('/service/board/item', param),

   // 게시글 첨부파일 정보 가져오기
   getContentAttachFile: (param) => callApi('/service/board/item/files', param),
   // 1:1 문의 답변 리스트
   getReply: (param) => callApi('/service/board/oto/item', param),
   getCnTargetTask: (param) => callApi('/service/board/oto/item/taskNm', param),
}