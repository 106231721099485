import React, { useState, useRef, useEffect, useCallback } from "react";
import {useDispatch, useSelector} from "react-redux";
import {Card, CardBody, CardHeader} from "reactstrap";
import {ReactSortable} from "react-sortablejs";
import {GetMessageIntl} from "../../../../../../../util/message";

import {useIntl} from "react-intl";
import {Controller, useFieldArray, useFormContext} from "react-hook-form";

function LabelOrderRows({index, datasetId, datasetNm, moduleId, useOrd, regDtmText,asgnRate}) {
    const intl = useIntl();
    const dispatch = useDispatch();
    const { control:controlOrder, register:registerOrder, unregister: unregisterOrder, setValue:setValueOrder, getValues:getValuesOrder, reset:resetOrder, resetField:resetFieldOrder
        , watch:watchOrder, formState: { errors:errorsOrder },} = useFormContext();

    return(
        <tr style={{fontSize:"16px"}}>
            <td scope="col" className="pd-t-16 pd-b-16 wd-3p">
                {asgnRate != 100? <i className="bx bx-grid-vertical tx-22 vertical-middle handle cursor-pointer"></i>:null}
                <input type="hidden" name="moduleId" value={moduleId} {...registerOrder(`orderConfigList.${index}.moduleId`)} />
            </td>
            <td scope="col" className="pd-t-16 pd-b-16 wd-4p vertical-middle">
                {index+1} <input type="hidden" name="useOrd" value={index+1} {...registerOrder(`orderConfigList.${index}.useOrd`)} />
            </td>
            <td scope="col" className="pd-t-16 pd-b-16 wd-50p vertical-middle">
                {datasetNm}<input type="hidden" name="datasetId" value={datasetId} {...registerOrder(`orderConfigList.${index}.datasetId`)} />
            </td>
            <td scope="col" className="pd-t-16 pd-b-16 wd-20p text-center vertical-middle">
                {regDtmText}
            </td>
            <td scope="col" className="pd-t-16 pd-b-16 wd-12p text-center vertical-middle">
                <span className={"text-coco-primary-400"}>{asgnRate}</span>%
            </td>
            {/*<td scope="col" className="py-2 text-center vertical-middle">
                {GetMessageIntl(intl, "workflow.completeRate")}
            </td>*/}
        </tr>
    )
}
export default LabelOrderRows;