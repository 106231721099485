import React, {useEffect, useState} from 'react';
import SimpleBar from "simplebar-react";
import {Link, useHistory} from 'react-router-dom';
import {
    Col,
    ButtonGroup,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Row,
    UncontrolledDropdown,
    Card
} from 'reactstrap';
import {ManagerProjectService} from "../../apis/api/ManagerProjectService";
import {manage_project_setList} from "../../store/manage/project/action";
import {useUserInfo} from "../../hooks/UserHooks";
import {GetMessage} from "../../util/message";
import {ContextMenuTrigger} from "react-contextmenu";
import thumbnail from "../../assets/images/thumbnail.png";
import {changeManageProjectId} from "../../store/manage/action";
import {useDispatch} from "react-redux";
import {setMountCnt} from "../../store/manage/worker/action";
import {dataset_setMountCnt} from "../../store/dataset/action";

const HeaderBreadCrumb = (props) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const targetPrjctId = sessionStorage.getItem("prjctId");
    const [projectList,setProjectList] = useState([]);
    const [projectItem,setProjectItem] = useState(null);
    const { coconutUserInfo, loading } = useUserInfo();
    const dupMsg = GetMessage("validation.duplication");

    useEffect(()=>{
        getProjects();
    },[]);

    useEffect(() => {
        if(projectList.length>0){
            setProjectItem(projectList.find((item) => item.prjctId === targetPrjctId));
        }
    }, [projectList])

    const getProjects = async () => {
        const res = await ManagerProjectService.getProjectList({
            'orgnztId' : coconutUserInfo.orgnztId
        });
        if(res.data.data){
            setProjectList(res.data.data);
        }
    };

    const hadleClickDropDownItem = (prjctId)=>{
        dispatch(changeManageProjectId(prjctId));
        sessionStorage.setItem("prjctId",prjctId);
        // 데이터, 작업자 목록 필터 관련 초기화 변수
        dispatch(setMountCnt(1));
        dispatch(dataset_setMountCnt(1));

        ManagerProjectService
            .getProjectItem({"prjctId":prjctId})
            .then(()=>{
                setProjectItem(projectList.find((item) => item.prjctId === prjctId));
                history.push({
                    pathname:"/manager/project/dashboard"
                });
            });
    }

    const hadleClickPrjctNm = (event) => {
        setProjectItem({...projectItem, 'editMode':true, 'nmLength': projectItem.prjctNm.length});
    }

    const isProjectNmDuplication = async(event, prjctId, prjctNm)=>{
        if(prjctNm === ''){
            setProjectList(
                projectList.map((prjctItem) => prjctItem.prjctId === prjctId? {...prjctItem, 'editMode':false}: prjctItem)
            );
            return;
        }
        await ManagerProjectService.isProjectNmDuplication({
            'prjctId': prjctId,
            'prjctNm': prjctNm,
        }).then((result)=>{
            if(!result.data.data){
                handleUpdateEvent( prjctId, prjctNm);
            }else{
                event.target.classList.add('is-invalid');
                event.target.parentElement.children[1].classList.add('invalid-tooltip');
                event.target.parentElement.children[1].innerText= dupMsg;
            }
        });

    }

    const handleKeyPress = (event, prjctId) =>{
        if (event.key === 'Enter') {
            isProjectNmDuplication(event, prjctId, event.target.value.trim());
        }
        else{
            event.target.classList.remove('is-invalid');
            event.target.parentElement.children[1].classList.remove('invalid-tooltip');
            event.target.parentElement.children[1].innerText= '';
        }
    }

    const handleUpdateEvent = async(prjctId, prjctNm)=>{
        await ManagerProjectService.updateProjectItem({
            'prjctId': prjctId,
            'prjctNm': prjctNm,
        }).then(res=>{
            if(res.data.data){
                getProjects();
            }else{
                setProjectList(
                    projectList.map((prjctItem) => prjctItem.prjctId === prjctId? {...prjctItem, 'editMode':false}: prjctItem)
                );
            }
        })
    }

    return (
        <React.Fragment>
            <Row>
                <Col xs={12}>
                    <div className="page-title-right d-flex align-items-center">
                        <ButtonGroup>
                            <UncontrolledDropdown>
                                <DropdownToggle tag="button" className="btn btn-light pd-x-12 pd-y-5 mr-12">
                                    <i className="bx bx-dots-horizontal-rounded text-coco-gray-200"></i>
                                </DropdownToggle>
                                <DropdownMenu className={"default-scroll-bar"} style={{ maxHeight: "500px", overflowY:"scroll"}}>
                                    {projectList.length>0?
                                        projectList.map((data, index)=> {
                                            const {prjctId, prjctNm}= data;
                                            return(
                                                <DropdownItem key={"menu_project_".concat(prjctId)} onClick={()=>hadleClickDropDownItem(prjctId)}>{prjctNm}</DropdownItem>
                                            )
                                        })
                                        :GetMessage("common.noData")
                                    }
                                </DropdownMenu>
                            </UncontrolledDropdown>
                        </ButtonGroup>
                        <ol className="breadcrumb m-0">
                            <li className="breadcrumb-item text-coco-gray-700 tx-16 fw-semibold">
                                <span onDoubleClick={hadleClickPrjctNm}>
                                    {projectItem?
                                        projectItem.editMode?
                                            <>
                                                <input type="text" defaultValue={projectItem.prjctNm} className="form-control editable tx-16 pd-x-0-f pd-y-3 fw-semibold lh-19"
                                                       onBlur={(e)=>isProjectNmDuplication(e, projectItem.prjctId, e.target.value.trim())}
                                                       maxLength={50}
                                                       onKeyUp={(e)=> handleKeyPress(e, projectItem.prjctId)}
                                                       style={{minWidth: 250, width: 12 * projectItem.nmLength}}
                                                       autoFocus />
                                                <p className="mb-0 validation-error-message"></p>
                                            </>
                                            : projectItem.prjctNm
                                        : null
                                    }
                                </span>
                            </li>
                        </ol>
                    </div>
                </Col>
            </Row>
        </React.Fragment>
    );
};

export default HeaderBreadCrumb;