import React, {useEffect, useRef, useState} from 'react';
import BreadCrumb from "../../../components/common/BreadCrumb";
import {Button, Card, CardBody, CardHeader, Col, Container, Input, Row} from "reactstrap";
import {useDispatch, useSelector} from "react-redux";
import {
    dataset_setMountCnt,
    setCanvasDataTy,
    setCurrentPage,
    setDatasetId,
    setDatasetOptions,
    setFileOrFolderNm,
    setFolderCurrentPage,
    setSearchParam,
    setWrkflow,
    setWrkflowOptions,
    setWrkflowWithModules,
    switchFolderViewFlag,
    switchModalFlag
} from "../../../store/dataset/action";
import ExcelDownloadModal from "./ExceldownloadModal";
import ReactPaginate from "react-paginate";
import {DatasetService} from "../../../apis/api/DatasetService";
import PageLeftInfomation from "../../../components/common/PageLeftInfomation";
import {GetMessageIntl} from "../../../util/message";
import DataListByFolder from "./DataListByFolder";
import Select from "react-select";
import {defaultSelectStyle} from "../../../components/constants/select";
import {AgGridReact} from "ag-grid-react";
import button from "bootstrap/js/src/button";
import {AgRowStyle} from "../../../components/common/AgGridCustomStyle";
import {useIntl} from "react-intl";
import {useHistory} from "react-router";
import {getWorkflow} from "../../../store/dataset/details/action";
import {getModule} from "../../../store/label/action";
import DatasetGridTooltip from "./DatasetGridTooltip";
import {EmptyListContentView} from "../../../components/features/EmptyListContentView";
import {LabelSttus, ReviewSttus} from "../../../store/dataset/details";

function DatasetListByFile() {
    const intl = useIntl();
    const dis = useDispatch();

    const [regDtm, setRegDtm] = useState("");
    const [highlightWords, setHighlightWords] = useState([]);

    const [selectLazyload, setSelectLazyload] = useState(false);

    const refComp = useRef();
    const wrkflowRef = useRef();
    const moduleRef = useRef();

    const {
        modalFlag,
        searchParam,
        folderViewFlag,
        datasetId, wrkflowId, wrkflowNm,
        wrkflowOptions,
        wrkflowWithModulesArr,
        datasetOptions,
        fileOrFolderNm,
        mount
    } = useSelector((state) => ({
        modalFlag: state.Dataset.modalFlag,
        searchParam: state.Dataset.searchParam,
        folderViewFlag: state.Dataset.folderViewFlag,
        datasetId: state.Dataset.datasetId,
        wrkflowId: state.Dataset.wrkflowId,
        wrkflowNm: state.Dataset.wrkflowNm,
        wrkflowOptions: state.Dataset.wrkflowOptions,
        wrkflowWithModulesArr: state.Dataset.wrkflowWithModulesArr,
        datasetOptions: state.Dataset.datasetOptions,
        fileOrFolderNm: state.Dataset.fileOrFolderNm,
        mount: state.Dataset.mount
    }));

    let prjctId = sessionStorage.getItem("prjctId");

    const getWrkflowNmListByPrjctId = async (prjctId) => {
        await DatasetService.getWrkflowNmList({
            prjctId: prjctId
        }).then((res) => {
            if(res.data && res.data.data){
                let re = res.data.data;

                const filteredRe = re.filter(item => {
                    return item.moduleList.some(moduleItem => moduleItem.dataset_id != null);
                });

                dis(setWrkflowWithModules(filteredRe));
                const options = filteredRe.map((wrkflow) => {
                    return { value: wrkflow.wrkflowId, label: wrkflow.wrkflowNm , canvasDataTy: wrkflow.canvasDataTy }
                });

                console.log("options :", options)
                if(options && options.length !== 0){
                    if(mount < 2){
                        dis(setWrkflowOptions(options))
                        dis(setWrkflow(options[0].value, options[0].label));
                        dis(setSearchParam({ WRKFLOW_ID : options[0].value}));
                        dis(setCanvasDataTy(options[0].canvasDataTy));
                    }
                }
            }
            dis(dataset_setMountCnt(mount + 1))
            setSelectLazyload(true);
        })
    }

    useEffect(() => {
        if(mount < 2){
            getWrkflowNmListByPrjctId(prjctId);
        }else{
            setSelectLazyload(true)
        }
    }, []);

    useEffect(() => {
        const findWrkflow = wrkflowWithModulesArr.find(wrkflow => wrkflow.wrkflowId === wrkflowId);
        if(findWrkflow && findWrkflow.moduleList){
            // 모듈명 셀렉트
            const filteredModule = findWrkflow.moduleList.filter(module => module.dataset_id !== null)
                .map(module => {
                    return {
                        label : module.module_nm,
                        value : module.dataset_id
                    }
                });
            const defaultArr = [{label: "데이터셋 모듈 전체", value: ""}];
            let data = [...defaultArr, ...filteredModule];
            dis(setDatasetOptions(data));
        }
    }, [wrkflowId])

    function handleViewSwitch() {
        dis(switchFolderViewFlag());
        handleSearch();
    }

    /*
        TITLE 오른쪽 메인 버튼 컴포넌트
        페이지마다 유동
     */
    const ButtonComponent = () => {
        return (
            <span className={"d-flex align-items-center"}>
                <span className="d-flex" style={{fontSize: "22px", marginRight: "5px"}} onClick={handleViewSwitch}>
                    {folderViewFlag === true ? <i className="mdi mdi-folder-text-outline"></i> : <i className="ri-list-check-2"></i>}
                </span>
                <span>
                    <Button type="button" className="btn btn-coco-1" onClick={showExportModal}>
                        {GetMessageIntl(intl, "dataset.excelExportDatasetList")}
                    </Button>
                </span>
            </span>
        )
    }

    const showExportModal = () => {
        dis(switchModalFlag())
    }

    // 기존 State 초기화 -> Param 재저장
    const handleSearch = () => {
        dis(dataset_setMountCnt(mount + 1))
        dis(setCurrentPage(1));
        let obj = {};
        if (fileOrFolderNm !== "") {
            obj.FILE_NM = fileOrFolderNm;
        }
        if (wrkflowId !== "") {
            obj.WRKFLOW_ID = wrkflowId;
        }
        if (datasetId !== "" && datasetId !== null) {
            obj.DATASET_ID = datasetId;
        }

        dis(setCanvasDataTy(wrkflowRef.current.getValue()[0].canvasDataTy));
        dis(setSearchParam(obj));
    }

    const handleWrkflowChange = (e) => {
        dis(setWrkflow(e.value, e.label));
    }

    // Input, Select 등 State로 조정
    const handleReset = () => {
        dis(dataset_setMountCnt(mount + 1));
        dis(setCurrentPage(1));
        dis(setFolderCurrentPage(1));
        dis(setDatasetId(null));
        dis(setFileOrFolderNm(""));
        let obj = {};
        if (wrkflowId !== "") {
            obj.WRKFLOW_ID = wrkflowOptions[0].value;
            //obj.canvasDataTy = wrkflowOptions[0].canvasDataTy;
            dis(setWrkflow(wrkflowOptions[0].value, wrkflowOptions[0].label));
        }
        dis(setSearchParam(obj));
    }

    return (
        <>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title={GetMessageIntl(intl, "menu.MPJT0003")} pageTitle="Laflow" ButtonComponent={ButtonComponent}/>
                    <Row>
                        <Card className="shadow-none mg-b-16">
                            <CardHeader className="border-0 rounded p-0">
                                <Row className="g-3">
                                    <Col xxl={2} lg={2}>
                                        <div className="position-relative form-search">
                                            <Input
                                                type="text"
                                                className="form-control"
                                                placeholder={folderViewFlag === true ? GetMessageIntl(intl, "dataset.searchFile"): GetMessageIntl(intl, "dataset.searchFolder")}
                                                value={fileOrFolderNm || ""}
                                                onChange={(e) => {
                                                    dis(setFileOrFolderNm(e.target.value))
                                                }}
                                            />{" "}
                                            <span className="ri-search-line search-widget-icon"></span>
                                        </div>
                                    </Col>
                                    {
                                        selectLazyload && wrkflowOptions &&
                                            <>
                                                <Col lg={2} className="lh-19">
                                                    <Select
                                                        ref={wrkflowRef}
                                                        placeholder={GetMessageIntl(intl,"dataset.allWorkflow")}
                                                        options={wrkflowOptions}
                                                        defaultValue={(wrkflowOptions || []).find(item => item.value === wrkflowId) || []}
                                                        value={(wrkflowOptions || []).find(item => item.value === wrkflowId) || ""}
                                                        styles={defaultSelectStyle}
                                                        onChange={handleWrkflowChange}
                                                    />
                                                </Col>
                                                {
                                                    datasetOptions.length > 0 &&
                                                        <Col xxl={2} lg={2}>
                                                            <Select
                                                                ref={moduleRef}
                                                                placeholder= {"데이터셋 모듈 전체"}
                                                                options={datasetOptions}
                                                                defaultValue={(datasetOptions || []).find(item => item.value === datasetId) || []}
                                                                value={(datasetOptions || []).find(item => item.value === datasetId) || ""}
                                                                styles={defaultSelectStyle}
                                                                onChange={(e) => {
                                                                    dis(setDatasetId(e.value));
                                                                }}
                                                            />
                                                        </Col>
                                                }
                                            </>
                                    }
                                    {/*<Col lg={2}>*/}
                                    {/*    <Flatpickr*/}
                                    {/*        ref={refComp}*/}
                                    {/*        className="form-control"*/}
                                    {/*        options={{*/}
                                    {/*            mode: "range",*/}
                                    {/*            maxDate: 'today',*/}
                                    {/*            dateFormat: "Y-m-d",*/}
                                    {/*        }}*/}
                                    {/*        placeholder={GetMessage("common.createDate")}*/}
                                    {/*        onValueUpdate={(selectedDates, dateStr, instance) => {*/}
                                    {/*            const tmp = dateStr.replace('to', '~');*/}
                                    {/*            setRegDtm(tmp)*/}
                                    {/*            instance.element.value = tmp;*/}
                                    {/*        }}*/}
                                    {/*    />*/}
                                    {/*</Col>*/}
                                    <Col xl={1} className={"align-self-center"} style={{maxWidth:"75px"}}>
                                        <div className={"d-flex"}>
                                            <button className="btn btn-coco-outline-1"
                                                    onClick={(e) => {
                                                        handleSearch()
                                                    }}>
                                                {GetMessageIntl(intl, "common.apply")}
                                            </button>
                                        </div>
                                    </Col>
                                    <Col className={"pd-0 align-self-center"}>
                                        <div className={"d-flex"}>
                                            <i  className="ri-refresh-line text-coco-gray-200 fs-24 align-self-auto"
                                                onClick={()=>{handleReset()}
                                                }></i>
                                        </div>
                                    </Col>
                                </Row>
                            </CardHeader>
                        </Card>
                        {folderViewFlag === true ?
                            <DatasetList wrkflowId={wrkflowId} wrkflowNm={wrkflowNm}
                                                prjctId={prjctId} highlightWords={highlightWords} searchParam={searchParam} />
                            : <DataListByFolder wrkflowId={wrkflowId} wrkflowNm={wrkflowNm}
                                                prjctId={prjctId} highlightWords={highlightWords} searchParam={searchParam} />
                        }
                    </Row>
                    {
                        modalFlag === true ? <ExcelDownloadModal prjctId={prjctId} wrkflowId={wrkflowId}/> : null
                    }
                </Container>
            </div>
        </>
    );
}


function DatasetList(props) {
    const intl = useIntl();
    const history = useHistory();
    const dis = useDispatch();
    const {
        searchParam, currentPage, wrkflowId, datasetId
    } = useSelector((state) => ({
        searchParam: state.Dataset.searchParam,
        currentPage: state.Dataset.currentPage,
        wrkflowId: state.Dataset.wrkflowId,
        datasetId: state.Dataset.datasetId
    }));

    const [datasets, setDatasets] = useState([]);
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);

    function calStartIdx(page) {
        return (page - 1) * perPage;
    }

    const fetchDatasets = async (page, size = perPage) => {
        setLoading(true)

        const res = await DatasetService.getDatasetListByFile(
            {
                'prjctId': props.prjctId,
                'wrkflowId' : wrkflowId,
                'start': calStartIdx(page),
                'length': size,
                'orderParam': [],
                'searchParam': [searchParam]
            }
        );

        if (res.data.data) {
            setDatasets(res.data.data);
            setTotalRows(res.data.recordsTotal);
        }
        setLoading(false);
    };

    useEffect(() => {
        let page = 1;
        if(currentPage > 1){
            page = currentPage
        }
        dis(setCurrentPage(page));
        fetchDatasets(page);
    }, [searchParam]);

    const handlePageChange = page => {
        let pageIdx = page.selected + 1;
        fetchDatasets(pageIdx);
        dis(setCurrentPage(pageIdx));
    };

    const wrkflow = useRef(null);
    wrkflow.current = {
        id: props.wrkflowId,
        name: props.wrkflowNm
    };

    const gotoDetails = (wrkflow, module, frame) => {
        dis(getWorkflow(wrkflow));
        dis(getModule({module, frame}));
        history.push({
            pathname: '/manager/project/dataset/details',
        });
    }

    const SttusBadge = ({labelSttus, reviewSttus, managerConfirmCd, rejctCount, reviewModuleNm, nextTaskNm, lstTaskSttusCd, returnCd}) => {
        let color = "";
        let text = "";

        if(reviewSttus === null){
            if(labelSttus === null){
                text = GetMessageIntl(intl, "dataset.beforeWork")
                color = "yellow"
            }else if(labelSttus === LabelSttus.WORKING){
                text = GetMessageIntl(intl, "dataset.working")
                color = "green"
            }else if(labelSttus === LabelSttus.SUBMIT){
                text = GetMessageIntl(intl, "dataset.beforeWork")
                color = "yellow"
            }else if (labelSttus === LabelSttus.SKIP) {
                text = GetMessageIntl(intl, "dataset.necessaryReview")
                color = "navy"
            }
        }else if(reviewSttus === ReviewSttus.WORKING) {
            if (parseInt(rejctCount) > 0) {
                text = GetMessageIntl(intl, "dataset.reReview")
                color = "purple"
            } else {
                if(labelSttus === LabelSttus.WORKING) {
                    text = GetMessageIntl(intl, "dataset.working")
                    color = "green"
                }else if(labelSttus === LabelSttus.SUBMIT){
                    if(reviewModuleNm){
                        text = GetMessageIntl(intl, "dataset.working");
                        color = "green"
                    }else{
                        text = GetMessageIntl(intl, "dataset.beforeWork")
                        color = "yellow"
                    }
                }
            }
        } else if(reviewSttus === ReviewSttus.PASS){
            if(labelSttus === LabelSttus.SUBMIT){
                if(nextTaskNm){ // 2차 검수
                    text = GetMessageIntl(intl, "dataset.beforeWork")
                    color = "yellow"
                }else{ // 1차에서 끝
                    text = GetMessageIntl(intl, "dataset.completeWork")
                    color = "skyblue"
                }
            }
        } else if (reviewSttus === ReviewSttus.REJECT) {
            // lstTaskSttusCd 00 -> 재검  else 수정중
            if(lstTaskSttusCd === "00"){
                text = GetMessageIntl(intl, "dataset.reReview")
                color = "purple"
            }else{
                text = GetMessageIntl(intl, "dataset.modifyingWork")
                color = "purple"
            }
        }

        if(labelSttus === LabelSttus.SKIP){
            text = GetMessageIntl(intl, "dataset.necessaryReview")
            color = "navy"
        }

        if(managerConfirmCd === "04"){
            text = GetMessageIntl(intl, "dataset.unableWork")
            color = "red"
        }

        if(returnCd === "05"){
            text = GetMessageIntl(intl, "dataset.beforeWork");
            color = "yellow"
        }

        return (
            <div className={"badge badge-coco ".concat(`badge-coco-soft-${color}`)}>{text}</div>
        )
    }

    const WorkBadge = ({labelSttus, reviewSttus, managerConfirmCd, rejctCount, reviewModuleNm, labelModuleNm, moduleNm, nextTaskNm, lstTaskSttusCd, returnCd}) => {
        let color = "";
        let text = "";

        if(reviewSttus === null){
            if(labelSttus === null){
                text = nextTaskNm
            }else if(labelSttus === LabelSttus.WORKING){
                text = labelModuleNm
            }else if(labelSttus === LabelSttus.SUBMIT){
                text = reviewModuleNm
            }else if (labelSttus === LabelSttus.SKIP) {
                text = GetMessageIntl(intl, "dataset.hold")
            }
        }else if(reviewSttus === ReviewSttus.WORKING) {
            if (parseInt(rejctCount) > 0) {
                if(nextTaskNm){
                    text = nextTaskNm
                }else{
                    text = reviewModuleNm
                }
            } else {
                if(labelSttus === LabelSttus.WORKING) {
                    text = labelModuleNm
                }else if(labelSttus === LabelSttus.SUBMIT){
                    if(reviewModuleNm){
                        text = reviewModuleNm;
                    }else{
                        if (nextTaskNm) {
                            text = nextTaskNm;
                        } else {
                            text = GetMessageIntl(intl, "dataset.undefineReview");
                        }
                    }
                }
            }
        } else if(reviewSttus === ReviewSttus.PASS){
            if(labelSttus === LabelSttus.SUBMIT){
                if(nextTaskNm){ // 2차 검수
                    text = nextTaskNm
                }else{ // 1차에서 끝
                    text = GetMessageIntl(intl, "dataset.done")
                }
            }
        } else if (reviewSttus === ReviewSttus.REJECT) {
            if(lstTaskSttusCd === "00"){
                text = reviewModuleNm
            }else{
                text = labelModuleNm
            }
        }

        if(labelSttus === LabelSttus.SKIP){
            text = GetMessageIntl(intl, "dataset.managerReview")
        }

        if(managerConfirmCd === "04"){
            text = GetMessageIntl(intl, "dataset.done")
        }

        if(returnCd === '05'){
            text = labelModuleNm;
        }

        return (
            <div>{text}</div>
        )
    }

    const [columnDefs] = useState([
        {
            field: 'fileNm',
            headerName: GetMessageIntl(intl,"dataset.fileNm"),
            cellClass: "firstCell btnCell",
            flex: '38%',
            tooltipField: 'fileNm',
            cellRendererParams: {
                getWrkflow: () => wrkflow.current,
            },
            cellRenderer: params => {
                const {
                    canvasDataTy
                } = useSelector((state) => ({
                    canvasDataTy: state.Dataset.canvasDataTy
                }));

                return (
                    <>
                        <div className={"d-flex justify-content-between"}>
                            <span>
                                {
                                    params.value.length > 51 ?
                                        params.value.substr(0, 51) + "..."
                                        :
                                        params.value
                                }
                            </span>
                            <button type="button"
                                    style={{
                                        display: "none",
                                        height: "50%"
                                    }}
                                    onClick={() => {
                                        const data = params.data;
                                        const wrkflow = params.getWrkflow();
                                        const module = {
                                            moduleId: data.moduleId,
                                            name: data.moduleNm,
                                            type: data.moduleTy,
                                            canvasDataTy : canvasDataTy
                                        }
                                        const frame = {
                                            datasetId: data.datasetId,
                                            fileSeq: data.fileSeq,
                                            moduleId: data.moduleId,
                                            labelSttus: data.labelSttus,
                                            reviewSttus: data.reviewSttus,
                                            rejectCount: data.rejctCount,
                                        }
                                        if(!wrkflow.id) {
                                            return;
                                        }
                                        gotoDetails(wrkflow, module, frame);
                                    }}
                                    className="joinBtn btn btn-coco-xs-1 align-self-center">
                                {GetMessageIntl(intl,"dataset.detailView")}
                            </button>
                        </div>
                    </>
                )
            }
        },
        {
            field: 'datasetNm',
            headerName: GetMessageIntl(intl,"dataset.datasetNm"),
            flex: '24%',
        },
        {
            field: 'moduleTy',
            headerName: GetMessageIntl(intl,"dataset.taskType"),
            flex: '18%',
            cellRenderer: params => {
                const labelSttus = params.data.labelSttus;
                const reviewSttus = params.data.reviewSttus;
                const managerConfirmCd = params.data.managerConfirmCd;
                const rejctCount = params.data.rejctCount;
                const reviewModuleNm = params.data.reviewModuleNm;
                const labelModuleNm = params.data.labelModuleNm;
                const taskNm =params.data.taskNm;
                const nextTaskNm = params.data.nextTaskNm;
                const lstTaskSttusCd = params.data.lstTaskSttusCd;
                const returnCd = params.data.returnCd;
                return (
                    <>
                        <WorkBadge
                            labelSttus={labelSttus} reviewSttus={reviewSttus} managerConfirmCd={managerConfirmCd}
                            rejctCount={rejctCount} reviewModuleNm={reviewModuleNm} labelModuleNm={labelModuleNm} moduleNm={taskNm}
                            nextTaskNm={nextTaskNm} lstTaskSttusCd={lstTaskSttusCd} returnCd={returnCd}
                        />
                    </>
                )
            }
        },
        {
            field: 'labelSttus',
            headerName: GetMessageIntl(intl,"dataset.sttus"),
            flex: '8%',
            cellRenderer: params => {
                const labelSttus = params.data.labelSttus;
                const reviewSttus = params.data.reviewSttus;
                const managerConfirmCd = params.data.managerConfirmCd;
                const rejctCount = params.data.rejctCount;
                const reviewModuleNm = params.data.reviewModuleNm;
                const nextTaskNm = params.data.nextTaskNm;
                const labelModuleNm = params.data.labelModuleNm;
                const lstTaskSttusCd = params.data.lstTaskSttusCd;
                const returnCd = params.data.returnCd;
                return (
                    <>
                        <SttusBadge labelSttus={labelSttus} reviewSttus={reviewSttus} managerConfirmCd={managerConfirmCd}
                                    rejctCount={rejctCount} reviewModuleNm={reviewModuleNm} labelModuleNm={labelModuleNm}
                                    nextTaskNm={nextTaskNm} lstTaskSttusCd={lstTaskSttusCd} returnCd={returnCd} />
                    </>
                )
            }
        },
        // {field: 'instanceCount', headerName: GetMessageIntl(intl,"dataset.rejectCnt"), flex: '8%',},
        {field: 'regDtmText', headerName: GetMessageIntl(intl,"dataset.regDtm"), flex: '12%',},
    ]);

    const [defaultColDef] = useState({
        autoHeight: true,
        resizable: true,
        cellClass: 'cell-wrap-text',
        tooltipComponent: DatasetGridTooltip,
    })

    let count = Math.ceil(Number((totalRows / perPage)))
    if(count === 0){
        count = 1;
    }

    return (
        <>
            <Card className="shadow-none">
                <CardBody className="px-0 pb-0">
                    <div className="ag-theme-alpine" style={{width: '100%'}}>
                        <AgGridReact
                            rowData={datasets}
                            rowStyle={AgRowStyle}
                            defaultColDef={defaultColDef}
                            columnDefs={columnDefs}
                            tooltipShowDelay={0}
                            TOOLTIP_HIDE_DELAY={2000}
                            pagination={true}
                            paginationPageSize={perPage}
                            suppressPaginationPanel={true}
                            suppressRowTransform={true}
                            detailRowAutoHeight={true}
                            gridOptions={{
                                suppressCellFocus : true
                            }}
                            domLayout={'autoHeight'}
                        >
                        </AgGridReact>
                        {
                            datasets && datasets.length > 0 ?
                                <>
                                    <Row className='mx-0'>
                                        <Col className='d-flex justify-content-start align-items-center fs-16 text-coco-gray-700 fw-500' sm='6'>
                                            <PageLeftInfomation
                                                totalRows={totalRows}
                                                perPage={perPage}
                                                currentPage={currentPage}
                                            />
                                        </Col>
                                        <Col className='d-flex justify-content-end align-items-center' sm='6'>
                                            <ReactPaginate
                                                previousLabel={'←'}
                                                nextLabel={'→'}
                                                onPageChange={handlePageChange}
                                                pageCount={count}
                                                forcePage={currentPage - 1}
                                                activeClassName={'active'}
                                                pageClassName={'page-item'}
                                                nextLinkClassName={'page-link'}
                                                nextClassName={'page-item next'}
                                                previousClassName={'page-item prev'}
                                                previousLinkClassName={'page-link'}
                                                pageLinkClassName={'page-link'}
                                                breakClassName='page-item'
                                                breakLinkClassName='page-link'
                                                containerClassName={'pagination react-paginate pagination-custom pagination-sm justify-content-end align-items-center'}
                                            />
                                        </Col>
                                    </Row>
                                </>
                                :
                                <EmptyListContentView msgCode={`dataset.emptyDatasetList`}/>
                        }
                    </div>
                </CardBody>
            </Card>
        </>
    );
}

export default DatasetListByFile;
