import React, {useEffect, useMemo, useRef, useState} from 'react';
import BreadCrumb from "../../components/common/BreadCrumb";
import {ButtonGroup, Card, CardBody, CardHeader, Col, Container, DropdownItem, DropdownMenu, DropdownToggle, Input, Row, UncontrolledDropdown} from "reactstrap";
import {useDispatch, useSelector} from "react-redux";
import Flatpickr from "react-flatpickr";
import button from "bootstrap/js/src/button";
import ReactPaginate from "react-paginate";
import PageLeftInfomation from "../../components/common/PageLeftInfomation";
import {AgRowStyle} from "../../components/common/AgGridCustomStyle";
import {AgGridReact} from "ag-grid-react";
import {GetMessage, GetMessageIntl} from "../../util/message";
import {
    board_setModalMode,
    board_setRowData,
    board_switchModalFlag,
    board_switchRefreshFlag,
    board_switchViewModalFlag, noticeCnTitle, noticeCurrentPage, noticeRegDtm, noticeSetSearchParam
} from "../../store/board/action";
import {BoardService} from "../../apis/api/BoardService";
import NoticeView from "./NoticeView";
import NoticeAddModal from "./NoticeAddModal";
import {alertSuccess, confirm} from "../../util/AlertUtil";
import {useIntl} from "react-intl";
import {useHistory} from "react-router-dom";
import {setWorkDtm} from "../../store/manage/worker/action";

function BoardNoticeList() {
    const dis = useDispatch();
    const intl = useIntl();
    const coconutUserInfo = JSON.parse(sessionStorage.getItem("coconutUserInfo"));
    const userRole = coconutUserInfo.userRole;
    const refComp = useRef();
    const {
        searchParam,
        cnTitle,
        regDtm,
        viewModalFlag,
        modalFlag
    } = useSelector((state) => ({
        searchParam : state.Board.noticeSearchParam,
        cnTitle : state.Board.noticeCnTitle,
        regDtm : state.Board.noticeRegDtm,
        viewModalFlag : state.Board.viewModalFlag,
        modalFlag : state.Board.modalFlag
    }));
    const [tmpSavedCn, setTmpSavedCn] = useState([]);
    const history = useHistory();

    const ButtonComponent = () => {
        return (
            <button type="button" className="btn btn-coco-1"
                    onClick={() => {
                        dis((board_switchModalFlag()));
                    }}
            >
                {GetMessageIntl(intl, "board.addNotice")}
            </button>
        )
    };

    useEffect(()=>{
        getTmpSaveData().then(r => {});
    }, [modalFlag])

    useEffect(()=>{
        if(viewModalFlag === true){
            dis(board_switchViewModalFlag())
        }
        if(modalFlag === true){
            dis(board_switchModalFlag())
        }
    }, [])

    const getTmpSaveData = async () => {
        BoardService.getTmpSaveData({
            'boardSeq': 1,
        }).then(re => {
            console.log(re)
            setTmpSavedCn(re.data.data);
        })
    }

    // 기존 State 초기화 -> Param 재저장
    const handleSearch = () => {
        dis(noticeCurrentPage(1));
        let obj = {};
        if (cnTitle !== "") {
            obj.CN_TITLE = cnTitle;
        }
        if (regDtm !== "") {
            obj.REG_DTM = regDtm;
        }
        dis(noticeSetSearchParam(obj));
    }

    // Input, Select 등 State로 조정
    const handleReset = () => {
        dis(noticeSetSearchParam({}));
        dis(noticeCnTitle(""));
        dis(noticeRegDtm(""));
        dis(noticeCurrentPage(1))
        refComp.current.flatpickr.clear();
    }

    const clickedDropdown = (e) => {
        const cnSeq = e.target.getAttribute('cnseq');
        const tmpCn = tmpSavedCn.filter((cn) => cn.cnSeq === parseInt(cnSeq)).map(cn => {
            return cn;
        })
        dis(board_setRowData(tmpCn[0]));
        dis(board_setModalMode("T"));
        dis(board_switchModalFlag());
    }

    return (
        <>
            <div className="page-content">
                <Container fluid>
                    {viewModalFlag === false ?
                        <>
                            <BreadCrumb title={GetMessage("menu.MBRD0001")} pageTitle="Laflow" ButtonComponent={userRole === '01' ? ButtonComponent : undefined}/>
                            <Row>
                                <Card className="shadow-none mg-b-16">
                                    <CardHeader className="border-0 rounded p-0">
                                        <Row className="g-3">
                                            <Col lg={3}>
                                                <div className="position-relative form-search">
                                                    <Input type="text"
                                                           className="form-control"
                                                           value={cnTitle}
                                                           onChange={(e) => {
                                                               dis(noticeCnTitle(e.target.value));
                                                           }}
                                                           id="search-input"
                                                           placeholder={GetMessage("board.searchPlaceHolder")}
                                                    />{" "}
                                                    <span className="ri-search-line search-widget-icon"></span>
                                                    <span className="ri-close-line search-widget-icon search-widget-icon-close d-none" id="search-close-icon"></span>
                                                </div>
                                            </Col>
                                            <Col lg={2}>
                                                <Flatpickr
                                                    ref={refComp}
                                                    className="form-control"
                                                    options={{
                                                        mode: "range",
                                                        maxDate: 'today',
                                                        dateFormat: "Y-m-d"
                                                    }}
                                                    value={regDtm.replace('~', 'to')}
                                                    placeholder={GetMessage("common.regDate")}
                                                    onChange={(selectedDates, dateStr, instance) => {
                                                        const tmp = dateStr.replace('to', '~');
                                                        if (tmp !== regDtm) {
                                                            dis(noticeRegDtm(tmp));
                                                        }
                                                        instance.input.value = tmp;
                                                    }}
                                                />
                                            </Col>
                                            <Col xl={1} className={"align-self-center"} style={{maxWidth:"75px"}}>
                                                <div className={"d-flex"}>
                                                    <button className="btn btn-coco-outline-1"
                                                            onClick={(e) => {handleSearch()}}>
                                                        {GetMessage("common.apply")}
                                                    </button>
                                                </div>
                                            </Col>
                                            <Col className={"pd-0 align-self-center"}>
                                                <div className={"d-flex"}>
                                                    <i  className="ri-refresh-line text-coco-gray-200 fs-24 align-self-auto"
                                                        onClick={()=>{handleReset()}
                                                        }></i>
                                                </div>
                                            </Col>
                                            <Col className="pd-0 w-100 right" style={{textAlign:"right"}}>
                                                    {
                                                        userRole === '01' ?
                                                            <ButtonGroup>
                                                                <UncontrolledDropdown>
                                                                    <DropdownToggle tag="button" className="btn">
                                                                        {GetMessage("board.tempSave")} (<span className="text-coco-danger-900">{tmpSavedCn !== undefined ? tmpSavedCn.length : 0}</span>)
                                                                    </DropdownToggle>
                                                                    <DropdownMenu container={'body'}>
                                                                        <DropdownItem>{GetMessage("board.tempSave")}</DropdownItem>
                                                                        <DropdownItem divider />
                                                                        {
                                                                            tmpSavedCn !== undefined ?
                                                                            tmpSavedCn.map((cn, idx) => {
                                                                                return(
                                                                                    <DropdownItem key={idx} cnseq={cn.cnSeq} onClick={clickedDropdown}>{cn.regDtmText} {cn.cnTitle}</DropdownItem>
                                                                                )
                                                                            }) : null
                                                                        }
                                                                    </DropdownMenu>
                                                                </UncontrolledDropdown>
                                                            </ButtonGroup>
                                                            : undefined
                                                    }
                                            </Col>
                                        </Row>
                                    </CardHeader>
                                </Card>
                                <BoardList searchParam={searchParam}/>
                            </Row>
                        </>
                        : <NoticeView/>
                    }
                    </Container>
                    <NoticeAddModal modalFlag={modalFlag} />
                </div>
        </>
    );
}

export default BoardNoticeList;

function BoardList(props) {
    const dis = useDispatch();
    const history = useHistory();

    const [datasets, setDatasets] = useState();
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const coconutUserInfo = JSON.parse(sessionStorage.getItem("coconutUserInfo"));
    const userRole = coconutUserInfo.userRole;
    const [actionCellClickFlag, setActionCellClickFlag] = useState(false);
    const gridRef = useRef();
    const {
        modalFlag,
        refreshFlag,
        searchParam,
        currentPage
    } = useSelector((state) => ({
        searchParam : state.Board.noticeSearchParam,
        modalFlag : state.Board.modalFlag,
        refreshFlag : state.Board.refreshFlag,
        currentPage : state.Board.noticeCurrentPage
    }));
    const intl = useIntl();

    function calStartIdx(page) {
        return (page - 1) * perPage;
    }

    const fetchData = async (page, size = perPage) => {
        setLoading(true);

        const res = await BoardService.getNoticeList({
            'start': calStartIdx(page),
            'length': size,
            'orderParam': [],
            'searchParam': searchParam
        })
        if (res.data.data) {
            let content = [];
            if(res.data.data.start === 1){
                content = [
                    ...res.data.data.notice
                    ,...res.data.data.data
                ]
            }else{
                content = [
                    ...res.data.data.data
                ]
            }
            setDatasets(content);
            setTotalRows(res.data.data.recordsTotal);
        }
        setLoading(false);
    };

    useEffect(() => {
        let page = 1;
        if(currentPage > 1){
            page = currentPage
        }
        dis(noticeCurrentPage(page));
        fetchData(page);
    }, [searchParam, modalFlag, refreshFlag]);

    const handlePageChange = page => {
        let pageIdx = page.selected + 1;
        fetchData(pageIdx);
        dis(noticeCurrentPage(pageIdx));
    };

    const handleRowClick = (params) => {
        // View Component 보여주는 Flag State 변경
        if(!actionCellClickFlag){
            if(userRole === '01'){
                history.push({
                    pathname: '/platform/board/notice',
                });
            }else if(userRole === '20'){
                history.push({
                    pathname: '/user/board/notice',
                });
            }else{
                history.push({
                    pathname: '/manager/board/notice',
                });
            }
            dis(board_switchViewModalFlag());
        }
    }

    const handleCellClick = (params) => {
        dis(board_setRowData(params.data));
        if(!(params.colDef.field === "action")){
            handleRowClick(params);
        }
    }

    const deleteBoard = async (rData) => {
        const data = new FormData();
        data.append("cnSeq", rData.cnSeq);
        data.append("mode", "D");
        data.append("boardSeq", 1);

        BoardService.setContent(data, {contentType : 'multipart/form-data'})
            .then((res) => {
                console.log(res.data)
            })
            .catch((err)=>{
                const data = new FormData();
            })
            .finally(()=> {
                dis(board_setModalMode("I"));
            });
    }

    const handleDetailEvent = (params) => {
        handleRowClick(params)
    }
    const handleModifyEvent = (params) => {
        dis(board_setModalMode("U"));
        dis(board_switchModalFlag());
    }
    const handleDeleteEvent = (params) => {
        confirm("board.askDeleteYn"
            , "board.warningDelete"
            ,()=>{
                deleteBoard(params.data).then(re => {
                    alertSuccess("board.successDelete", "board.successDeleteMessage", ()=>{
                        dis(board_switchRefreshFlag())
                    }, null, intl)
                })
            }
            ,null
            , null
            , intl);
    }

    const [adminColumnDefs] = useState([
        {
            field: 'idx',
            headerName: 'No',
            cellClass: "firstCell",
            cellRenderer: params => {
                let title = '';
                if(params.data.noticeYn === 'Y'){
                    title = GetMessage('board.important');
                }else{
                    title = params.data.idx
                }
                return title;
            },
            flex : '7%'
        },
        {
            field: 'cnTitle',
            headerName: GetMessage("board.title"),
            flex : "40%"
        },
        {field: 'userNm', headerName: GetMessageIntl(intl, "board.writer"), flex : '15%'},
        {field: 'regDtmText', headerName: GetMessageIntl(intl, "common.regDate"), flex : "18%"},
        {field: 'readCnt', headerName: GetMessageIntl(intl, "board.readCnt"), flex : "10%"},
        {
            field: 'action',
            headerName: GetMessageIntl(intl, "common.action"),
            cellClass: "lastCell",
            flex : "10%",
            cellRenderer: params => {
                return(
                    <div className={"actionCell"} onClick={(e)=>{e.preventDefault()}}>
                        <UncontrolledDropdown>
                            <DropdownToggle
                                href="#"
                                className="btn-soft-secondary btn-sm dropdown"
                                tag="button"
                                style={{lineHeight: "17px"}}
                            >
                                <i className="ri-more-fill align-middle"></i>
                            </DropdownToggle>
                            <DropdownMenu className="dropdown-menu-end" style={{lineHeight: "17px"}} container={'body'}>
                                <DropdownItem className="dropdown-item"
                                              href="#"
                                              onClick={handleDetailEvent}
                                >
                                    {GetMessageIntl(intl, "board.detail")}
                                </DropdownItem>
                                <DropdownItem
                                    className="dropdown-item edit-item-btn"
                                    href="#"
                                    onClick={handleModifyEvent}
                                >
                                    {GetMessageIntl(intl, "board.modify")}
                                </DropdownItem>
                                <DropdownItem
                                    className="dropdown-item remove-item-btn"
                                    href="#"
                                    onClick={()=>{handleDeleteEvent(params)}}
                                >
                                    {GetMessageIntl(intl, "board.delete")}
                                </DropdownItem>
                            </DropdownMenu>
                        </UncontrolledDropdown>
                    </div>
                )
            }
        },
    ]);

    const [columnDefs] = useState([
        {
            field: 'idx',
            headerName: 'No',
            cellClass: "firstCell",
            cellRenderer: params => {
                let title = '';
                if(params.data.noticeYn === 'Y'){
                    title = GetMessage('board.important');
                }else{
                    title =  params.data.idx
                }
                return title;
            },
            flex : '7%'
        },
        {
            field: 'cnTitle',
            headerName: GetMessage("board.title"),
            flex : "48%"
        },
        {field: 'userNm', headerName: GetMessageIntl(intl, "board.writer"), flex : '10%'},
        {field: 'regDtmText', headerName:  GetMessageIntl(intl, "common.regDate"), flex : "20%"},
        {field: 'readCnt', headerName:  GetMessageIntl(intl, "board.readCnt"), flex : "7%"},
    ])

    const [defaultColDef] = useState({
        autoHeight: true,
        resizable: true,
        cellClass: 'cell-wrap-text',
    })

    const gridOption = {
        suppressCellFocus : true,
    }

    const onFirstDataRendered = () => {
        if(gridRef.current){
            gridRef.current.classList.remove('ag-grid-hidden')
        }
    };

    let count = Math.ceil(Number((totalRows / perPage)));
    if(count === 0){
        count = 1;
    }

    return (
        <>
            <Card className="shadow-none">
                <CardBody className="px-0 pb-0">
                    <div ref={gridRef} className="ag-theme-alpine" style={{width: "100%"}}>
                        <AgGridReact
                            rowData={datasets}
                            rowStyle={AgRowStyle}
                            columnDefs={(userRole === '01') ? adminColumnDefs : columnDefs}
                            pagination={true}
                            paginationPageSize={perPage}
                            suppressPaginationPanel={true}
                            detailRowAutoHeight={true}
                            suppressRowTransform={true}
                            domLayout={'autoHeight'}
                            onCellClicked={handleCellClick}
                            gridOptions={gridOption}
                            // onFirstDataRendered={onFirstDataRendered}
                        >
                        </AgGridReact>
                        <Row className='mx-0'>
                            <Col className='d-flex justify-content-start align-items-center fs-16 text-coco-gray-700 fw-500' sm='6'>
                                <PageLeftInfomation
                                    totalRows={totalRows}
                                    perPage ={perPage}
                                    currentPage={currentPage}
                                />
                            </Col>
                            <Col className='d-flex justify-content-end align-items-center' sm='6'>
                                <ReactPaginate
                                    previousLabel={'←'}
                                    nextLabel={'→'}
                                    onPageChange={handlePageChange}
                                    pageCount={count}
                                    forcePage={currentPage - 1}
                                    activeClassName={'active'}
                                    pageClassName={'page-item'}
                                    nextLinkClassName={'page-link'}
                                    nextClassName={'page-item next'}
                                    previousClassName={'page-item prev'}
                                    previousLinkClassName={'page-link'}
                                    pageLinkClassName={'page-link'}
                                    breakClassName='page-item'
                                    breakLinkClassName='page-link'
                                    containerClassName={'pagination react-paginate pagination-custom pagination-sm justify-content-end align-items-center'}
                                />
                            </Col>
                        </Row>
                    </div>
                </CardBody>
            </Card>
        </>
    );
}