import {
    ACTIVATE_LABEL,
    ACTIVATE_OBJECT,
    ADD_Z_LAYER,
    CHANGE_ANNOTATIONS_FILTERS,
    CHANGE_FRAME,
    CHANGE_FRAME_FAILED,
    CHANGE_FRAME_SUCCESS,
    CHANGE_WORKSPACE,
    CHECK_VALIDATION_SUCCESS,
    CLOSE_MODULE_SUCCESS,
    COLLAPSE_APPEARANCE,
    COLLAPSE_OBJECT_ITEMS,
    COLLAPSE_SIDEBAR,
    COLLECT_STATISTICS,
    COLLECT_STATISTICS_FAILED,
    COLLECT_STATISTICS_SUCCESS,
    CONFIRM_CANVAS_READY,
    COPY_SHAPE,
    CREATE_ANNOTATIONS_SUCCESS,
    DELETE_FRAME,
    DELETE_FRAME_FAILED,
    DELETE_FRAME_SUCCESS,
    DRAG_CANVAS,
    EDIT_SHAPE,
    FETCH_ANNOTATIONS_SUCCESS, GET_AUTO_LABEL_ANNOTATION_SUCCESS,
    GET_DATA_FAILED,
    GET_MODULE,
    GET_MODULE_FAILED,
    GET_MODULE_SUCCESS,
    GET_PREDICTIONS,
    GET_PREDICTIONS_FAILED,
    GET_PREDICTIONS_SUCCESS,
    GROUP_ANNOTATIONS,
    GROUP_ANNOTATIONS_SUCCESS,
    GROUP_OBJECTS,
    INTERACT_WITH_CANVAS,
    LOAD_PREV_FILE_OBJECTS_SUCCESS,
    MERGE_ANNOTATIONS_SUCCESS,
    MERGE_OBJECTS,
    PASTE_SHAPE,
    PROPAGATE_OBJECT_SUCCESS,
    READY_OBJECT,
    REDO_ACTION_SUCCESS,
    RELAX_OBJECT,
    REMEMBER_OBJECT, REMOVE_ANNOTATIONS_SUCCESS,
    REMOVE_MODULE_ANNOTATIONS_SUCCESS,
    REMOVE_OBJECT,
    REMOVE_OBJECT_FAILED,
    REMOVE_OBJECT_SUCCESS,
    REPEAT_DRAW_SHAPE,
    RESET_ANNOTATIONS_GROUP,
    RESET_CANVAS,
    RESTORE_FRAME,
    RESTORE_FRAME_FAILED,
    RESTORE_FRAME_SUCCESS,
    ROTATE_FRAME,
    SAVE_ANNOTATIONS,
    SAVE_ANNOTATIONS_FAILED,
    SAVE_ANNOTATIONS_SUCCESS,
    SELECT_ISSUE_POSITION,
    SET_FORCE_EXIT_ANNOTATION_PAGE_FLAG,
    SHAPE_DRAWN,
    SKIP_DATA,
    SKIP_DATA_FAILED,
    SKIP_DATA_SUCCESS,
    SPLIT_ANNOTATIONS_SUCCESS,
    SPLIT_TRACK,
    SUBMIT_ANNOTATIONS,
    SUBMIT_ANNOTATIONS_FAILED,
    SUBMIT_ANNOTATIONS_SUCCESS,
    SWITCH_NAVIGATION_BLOCKED,
    SWITCH_PLAY,
    SWITCH_PROPAGATE_VISIBILITY,
    SWITCH_SHORTCUTS,
    SWITCH_SHOWING_FILTERS,
    SWITCH_SHOWING_STATISTICS,
    SWITCH_Z_LAYER,
    UNDO_ACTION_SUCCESS,
    UPDATE_ANNOTATIONS_FAILED,
    UPDATE_ANNOTATIONS_SUCCESS,
    UPDATE_BRUSH_TOOLS_CONFIG,
    UPDATE_CANVAS_CONTEXT_MENU, UPDATE_CANVAS_CONTEXT_MENU_POSITION,
    UPDATE_CANVAS_EDIT_IMAGE_POPOVER,
    UPDATE_CANVAS_SKIP_POPOVER,
    UPDATE_CANVAS_SKIP_RSN,
    UPDATE_PREDICTOR_STATE,
    UPDATE_SIDEBAR_TAB,
    UPLOAD_MODULE_ANNOTATIONS,
    UPLOAD_MODULE_ANNOTATIONS_FAILED,
    UPLOAD_MODULE_ANNOTATIONS_SUCCESS,
    ZOOM_CANVAS,
    SET_CANVAS_DATA_TY, UPDATE_DURATION, UPDATE_INTERNAL_PLAYER,
} from "./actionType";
import {
    RESET_REVIEW_STATE_SUCCESS,
    SUBMIT_ANNOTATIONS_SUCCESS as REVIEW_SUBMIT_ANNOTATIONS_SUCCESS
} from "../review/actionType";
import {ActiveControl, ContextMenuType, DimensionType, ObjectType, ShapeType, Workspace} from ".";
// import {BoundariesActionTypes} from "../../cvat/actions/boundaries-actions";
import {Canvas, CanvasMode} from "pages/user/label/annotation/image/work/canvas/typescript/canvas";
import {Canvas3d} from "pages/user/label/annotation/image/work/canvas3d/typescript/canvas3d";
import {clamp} from "pages/user/label/annotation/image/utils/math";
import {translateFromSVG,} from "../../pages/user/label/annotation/image/work/canvas/typescript/shared";
import {CLOSE_WORKFLOW, GET_SKIP_RSN_SUCCESS, GET_WORKFLOW_SUCCESS} from "../dataset/details/actionType";
import {
    CANVAS_DATA_TY,
    DATA_TYPE,
    LABEL_TYPE,
    MD_STTUS_CD,
    MD_TY_CD
} from "../../pages/user/label/annotation/image/const";

// import {AuthActionTypes} from "../../cvat/actions/auth-actions";

function updateActivatedStateID(newStates: any[], prevActivatedStateID: number | null): number | null {
    return prevActivatedStateID === null || newStates.some((_state: any) => _state.clientID === prevActivatedStateID) ?
        prevActivatedStateID :
        null;
}

const INIT_STATE = {
    activities: {
        loads: {},
    },
    canvas: {
        sideBarTab: null,
        contextMenu: {
            visible: false,
            left: 0,
            top: 0,
            deltaPosition: {
                x: 0,
                y: 0
            },
            type: ContextMenuType.CANVAS_SHAPE,
            pointID: null,
            clientID: null,
            parentID: null,
        },
        badges: {
            visible: false,
            instanceNotAttrValidCnt: 0,
            instanceNotEditedCnt: 0,
            classesNotEditedCnt: 0,
        },
        skipPopover: {
            visible: false,
            reasons: [],
            choices: {
                rsnCd: [],
                rsnCn: '',
            }
        },
        shortcuts: {
            visible: false,
        },
        editImagePopover: {
            visible: false,
        },
        brushTools: {
            visible: false,
            top: 0,
            left: 0,
        },
        instance: null,
        ready: false,
        activeControl: ActiveControl.CURSOR,
    },
    module: {
        openTime: null,
        moduleId: null,
        name: null,
        type: null, // 라벨링 | 검수
        status: null,   // 최초 캔버스 | 재 캔버스
        // canvasDataTy: CANVAS_DATA_TY.TEXT_CLSR_SMRY,
        canvasDataTy : null,
        labelType: null,    // 라벨링 유형
        dataType: null, // 데이터 유형
        labels: [],
        requestedId: null,
        instance: null,
        attributes: {},
        fetching: false,
        saving: false,
        frame: {
            datasetId: null,
            fileSeq: null
        },
        countLimit:{
            objectMin: null,
            objectMax: null
        },
    },
    frames: {
        frame: {
            datasetId: null,
            fileSeq: null,
            name: null,
            url: null,
            moduleId: null,
            width: null,
            height: null,
            data: null,
            instance: null,
        },
        list: []
    },
    player: {
        frame: {
            number: 0,
            datasetId: null,
            fileSeq: null,
            filename: '',
            data: null,
            relatedFiles: 0,
            fetching: false,
            delay: 0,
            changeTime: null,
        },
        duration: null,
        playing: false,
        frameAngles: [],
        navigationBlocked: false,
    },
    drawing: {
        activeShapeType: null,//ShapeType.RECTANGLE,
        activeLabelID: null,
        activeObjectType: ObjectType.SHAPE,
    },
    annotations: {
        activatedLabelID:null,
        readyStateID: null,
        readyElementID: null,
        readyAttributeID: null,
        activatedStateID: null,
        activatedElementID: null,
        activatedAttributeID: null,
        saving: {
            forceExit: false,
            uploading: false,
        },
        submit: {
            forceExit: false,
            uploading: false,
        },
        skip: {
            forceExit: false,
            uploading: false,
        },
        collapsed: {},
        collapsedAll: true,
        states: [],
        filters: [],
        resetGroupFlag: false,
        history: {
            undo: [],
            redo: [],
        },
        zLayer: {
            min: 0,
            max: 0,
            cur: 0,
        },
        validation: {
            rejectFlag: {
                objects: false,
                attributes: false
            },
        }
    },
    remove: {
        objectState: null,
        force: false,
    },
    statistics: {
        visible: false,
        collecting: false,
        data: null,
    },
    propagate: {
        visible: false,
    },
    colors: [],
    sidebarCollapsed: false,
    appearanceCollapsed: false,
    filtersPanelVisible: false,
    predictor: {
        enabled: false,
        error: null,
        message: '',
        projectScore: 0,
        fetching: false,
        annotatedFrames: [],
        timeRemaining: 0,
        progress: 0,
        annotationAmount: 0,
        mediaAmount: 0,
    },
    workspace: Workspace.STANDARD,
    canvasDataTy : null
}

const Cmmn = (state = INIT_STATE, action) => {
    switch (action.type){
        case GET_MODULE: {
            // if (state.canvas.instance) {
            //     state.canvas.instance.destroy();
            // }
            const {moduleId, name, type, canvasDataTy} = action.payload.module;
            return {
                ...state,
                module: {
                    ...state.module,
                    moduleId,
                    name,
                    type,
                    canvasDataTy,
                    instance: null,
                    requestedId: action.payload,
                    fetching: true,
                },
                canvas: {
                    ...state.canvas,
                    instance: new Canvas(),//module.dimension === DimensionType.DIM_2D ? new Canvas() : new Canvas3d(),
                },
                workspace: type,
            };
        }
        // case BoundariesActionTypes.RESET_AFTER_ERROR:
        case GET_MODULE_SUCCESS: {
            const {
                module,
                states=[],
                dataType,
                labelType,
                labels,
                // openTime,
                frameNumber: number = 0,   // 프레임이 여러개일 경우에는 추가 개념 필요
                datasetId,
                fileSeq,
                // relatedFiles,
                // colors,  // outlined borders 에 사용되는 컬러 enum
                // frame: frameInstance,
                countLimit,
                filters,
                minZ,
                maxZ,
            } = action.payload;
            const isReview = module.type === Workspace.REVIEW_WORKSPACE //LabelStage.REVIEW;
            let workspaceSelected = Workspace.STANDARD;

            const defaultLabel = module.labels.length ? module.labels[0] : null;
            let activeShapeType = defaultLabel && defaultLabel.type !== 'any' ?
                defaultLabel.type : INIT_STATE.drawing.activeShapeType; //ShapeType.RECTANGLE;

            if (module.dimension === DimensionType.DIM_3D) {
                workspaceSelected = Workspace.STANDARD3D;
                activeShapeType = ShapeType.CUBOID;
            }

            return {
                ...state,
                module: {
                    ...state.module,
                    // openTime,
                    dataType,
                    labelType,
                    fetching: false,
                    instance: module,
                    labels: labelType === LABEL_TYPE ? module.labels : labels,
                    attributes: module.labels
                        .reduce((acc: Record<number, any[]>, label: any): Record<number, any[]> => {
                            acc[label.id] = label.attributes;
                            return acc;
                        }, {}),
                    countLimit: countLimit,
                },
                // frames: {
                //     ...state.frames,
                //     frame:{
                //         ...state.frames.frame,
                //         instance: frameInstance
                //     }
                // },
                annotations: {
                    ...state.annotations,
                    states,
                    filters,
                    zLayer: {
                        min: minZ,
                        max: maxZ,
                        cur: maxZ,
                    },
                },
                player: {
                    ...state.player,
                    frame: {
                        ...state.player.frame,
                        datasetId,
                        fileSeq,
                        // filename,
                        // relatedFiles,
                        number,
                        // data: frameInstance,
                    },
                    frameAngles: Array(module.stopFrame - module.startFrame + 1).fill(0),
                },
                drawing: {
                    ...state.drawing,
                    activeLabelID: defaultLabel ? defaultLabel.id : null,
                    activeObjectType: module.mode === 'interpolation' ? ObjectType.TRACK : ObjectType.SHAPE,
                    activeShapeType,
                },
                canvas: {
                    ...state.canvas,
                },
                // workspace: isReview ? Workspace.REVIEW_WORKSPACE : workspaceSelected,
            };
        }
        case GET_MODULE_FAILED: {
            return {
                ...state,
                module: {
                    ...state.module,
                    instance: undefined,
                    fetching: false,
                },
            };
        }
        case GET_WORKFLOW_SUCCESS: {
            return {
                ...state,
                workspace: Workspace.MANAGER_WORKSPACE,
            }
        }
        case GET_DATA_FAILED: {
            return {
                ...state,
                player: {
                    ...state.player,
                    frame: {
                        ...state.player.frame,
                        fetching: false,
                    },
                },
            };
        }
        case CHANGE_FRAME: {
            const {
                datasetId,
                fileSeq
            } = action.payload

            return {
                ...state,
                module:{
                    ...state.module,
                    frame:{
                        ...state.frames.frame,
                        datasetId: datasetId,
                        fileSeq: fileSeq,
                    }
                },
                frames:{
                    ...state.frames,
                    frame:{
                        ...state.frames.frame,
                        datasetId: datasetId,
                        fileSeq: fileSeq,
                    }
                },
                annotations: {
                    ...state.annotations,
                    states: INIT_STATE.annotations.states
                },
                player: {
                    ...state.player,
                    frame: {
                        ...state.player.frame,
                        fetching: true,
                    },
                    frameAngles:[0]    // Todo 다중 프레임을 가지고 있을 경우에는 수정해주기
                },
                canvas: {
                    ...state.canvas,
                    // instance: new Canvas(),
                    ready: false,
                },
            };
        }
        case RESET_REVIEW_STATE_SUCCESS: {
            const {moduleStatus} = action.payload;
            return {
                ...state,
                module: {
                    ...state.module,
                    status: moduleStatus
                }
            }
        }
        case CHANGE_FRAME_SUCCESS: {
            const { activatedStateID } = state.annotations;
            const {
                frame: frameInstance,
                module,
                number=0,
                relatedFiles,

                states=[], // annotation 이전 결과

                minZ=0,
                maxZ=0,
                curZ=0,
                delay,
                changeTime,
            } = action.payload;
            const { datasetId, fileSeq, fileSize, name, path, url, _data:data } = frameInstance;

            let moduleId = frameInstance.moduleId;
            if(moduleId == null && state.module.instance){
                moduleId = state.module.instance.id;
            }

            return {
                ...state,
                canvas: {
                    ...state.canvas,
                    skipPopover: {
                        ...state.canvas.skipPopover,
                        choices:{
                            ...INIT_STATE.canvas.skipPopover.choices
                        }
                    }
                },
                module: {
                    ...state.module,
                    instance: module
                },
                frames: {
                    ...state.frames,
                    frame: {
                        ...state.frames.frame,
                        datasetId,
                        fileSeq,
                        fileSize,
                        name,
                        path,
                        url,
                        moduleId,
                        width: data.renderWidth ?? INIT_STATE.frames.frame.width,
                        height: data.renderHeight ?? INIT_STATE.frames.frame.height,
                        data: data,
                        //     {
                        //     imageData: data.imageData,
                        //     renderWidth:  data.renderWidth,
                        //     renderHeight:  data.renderHeight,
                        // },
                        instance: frameInstance,
                    }
                },
                player: {
                    ...state.player,
                    playing: false,
                    frame: {
                        ...state.player.frame,
                        data,
                        datasetId,
                        fileSeq,
                        filename:name,
                        url,
                        relatedFiles: relatedFiles ?? 0,
                        number,
                        fetching: false,
                        changeTime,
                        delay,
                    },
                },
                annotations: {
                    ...state.annotations,
                    activatedStateID: updateActivatedStateID(states, activatedStateID),
                    states,
                    zLayer: {
                        min: minZ,
                        max: maxZ,
                        cur: curZ,
                    },
                },
            };
        }
        case CHANGE_FRAME_FAILED: {
            return {
                ...state,
                player: {
                    ...state.player,
                    frame: {
                        ...state.player.frame,
                        fetching: false,
                    },
                },
            };
        }
        case ROTATE_FRAME: {
            const { offset, angle, rotateAll } = action.payload;
            return {
                ...state,
                player: {
                    ...state.player,
                    frameAngles: state.player.frameAngles.map((_angle, idx) => {
                        if (rotateAll || offset === idx) {
                            return angle;
                        }
                        return _angle;
                    }),
                },
            };
        }
        case SAVE_ANNOTATIONS: {
            return {
                ...state,
                annotations: {
                    ...state.annotations,
                    saving: {
                        ...state.annotations.saving,
                        uploading: true,
                    },
                },
            };
        }
        case SAVE_ANNOTATIONS_SUCCESS: {
            const { states } = action.payload;
            const { activatedStateID } = state.annotations;

            return {
                ...state,
                annotations: {
                    ...state.annotations,
                    states,
                    activatedStateID: updateActivatedStateID(states, activatedStateID),
                    saving: {
                        ...state.annotations.saving,
                        uploading: false,
                    },
                },
            };
        }
        case SAVE_ANNOTATIONS_FAILED: {
            return {
                ...state,
                annotations: {
                    ...state.annotations,
                    saving: {
                        ...state.annotations.saving,
                        uploading: false,
                    },
                },
            };
        }
        case SUBMIT_ANNOTATIONS: {
            return {
                ...state,
                annotations: {
                    ...state.annotations,
                    submit: {
                        ...state.annotations.submit,
                        uploading: true,
                    },
                },
            };
        }
        case REVIEW_SUBMIT_ANNOTATIONS_SUCCESS:{
            return {
                ...state,
                canvas: {
                    ...state.canvas,
                    sideBarTab: null,
                    contextMenu: {
                        ...state.canvas.contextMenu,
                        // visible: false
                    }
                }
            }
        }
        case SUBMIT_ANNOTATIONS_SUCCESS: {
            const { states } = action.payload;
            const { activatedStateID } = state.annotations;

            return {
                ...state,
                canvas: {
                    ...state.canvas,
                    sideBarTab: null,
                },
                annotations: {
                    ...state.annotations,
                    states,
                    activatedStateID: updateActivatedStateID(states, activatedStateID),
                    submit: {
                        ...state.annotations.submit,
                        uploading: false,
                    },
                },
            };
        }
        case SUBMIT_ANNOTATIONS_FAILED: {
            return {
                ...state,
                annotations: {
                    ...state.annotations,
                    submit: {
                        ...state.annotations.submit,
                        uploading: false,
                    },
                },
            };
        }
        case SKIP_DATA: {
            return {
                ...state,
                annotations: {
                    ...state.annotations,
                    skip: {
                        ...state.annotations.skip,
                        uploading: true,
                    },
                },
            };
        }
        case SKIP_DATA_SUCCESS: {
            return {
                ...state,
                canvas: {
                    ...state.canvas,
                    skipPopover: {
                        ...state.canvas.skipPopover,
                        visible: false,
                        choices: {
                            ...INIT_STATE.canvas.skipPopover.choices
                        }
                    }
                },
                annotations: {
                    ...state.annotations,
                    skip: {
                        ...state.annotations.skip,
                        uploading: false
                    },
                },
            };
        }
        case SKIP_DATA_FAILED: {
            return {
                ...state,
                annotations: {
                    ...state.annotations,
                    skip: {
                        ...state.annotations.skip,
                        uploading: false,
                    },
                },
            };
        }
        case GET_SKIP_RSN_SUCCESS: {
            const {rsnCd, rsnCn} = action.payload;
            return {
                ...state,
                canvas: {
                    ...state.canvas,
                    skipPopover: {
                        ...state.canvas.skipPopover,
                        choices: {
                            rsnCd: rsnCd ?? INIT_STATE.canvas.skipPopover.choices.rsnCd,
                            rsnCn: rsnCn ?? INIT_STATE.canvas.skipPopover.choices.rsnCn
                        }
                    }
                }
            }
        }
        case SWITCH_PLAY: {
            const { playing } = action.payload;

            return {
                ...state,
                player: {
                    ...state.player,
                    playing,
                },
            };
        }
        case UPDATE_DURATION: {
            const { duration } = action.payload;

            return {
                ...state,
                player: {
                    ...state.player,
                    duration,
                },
            };
        }
        case UPDATE_INTERNAL_PLAYER:{
            const {width, height} = action.payload;
            return {
                ...state,
                frames: {
                    ...state.frames,
                    frame: {
                        ...state.frames.frame,
                        width: width,
                        height: height,
                    }
                }
            };
        }
        case COLLAPSE_SIDEBAR: {
            return {
                ...state,
                sidebarCollapsed: !state.sidebarCollapsed,
            };
        }
        case COLLAPSE_APPEARANCE: {
            return {
                ...state,
                appearanceCollapsed: !state.appearanceCollapsed,
            };
        }
        case COLLAPSE_OBJECT_ITEMS: {
            const { states, collapsed } = action.payload;

            const updatedCollapsedStates = { ...state.annotations.collapsed };
            const totalStatesCount = state.annotations.states.length;
            for (const objectState of states) {
                updatedCollapsedStates[objectState.clientID] = collapsed;
                for (const element of objectState.elements) {
                    updatedCollapsedStates[element.clientID] = collapsed;
                }
            }

            return {
                ...state,
                annotations: {
                    ...state.annotations,
                    collapsed: updatedCollapsedStates,
                    collapsedAll: states.length === totalStatesCount ? collapsed : state.annotations.collapsedAll,
                },
            };
        }
        case CONFIRM_CANVAS_READY: {
            return {
                ...state,
                canvas: {
                    ...state.canvas,
                    ready: true,
                },
            };
        }
        case DRAG_CANVAS: {
            const { enabled } = action.payload;
            const activeControl = enabled ? ActiveControl.DRAG_CANVAS : ActiveControl.CURSOR;

            return {
                ...state,
                annotations: {
                    ...state.annotations,
                    activatedStateID: null,
                },
                canvas: {
                    ...state.canvas,
                    activeControl,
                },
            };
        }
        case ZOOM_CANVAS: {
            const { enabled } = action.payload;
            const activeControl = enabled ? ActiveControl.ZOOM_CANVAS : ActiveControl.CURSOR;

            return {
                ...state,
                annotations: {
                    ...state.annotations,
                    activatedStateID: null,
                },
                canvas: {
                    ...state.canvas,
                    activeControl,
                },
            };
        }
        case REMEMBER_OBJECT: {
            const { payload } = action;

            let { activeControl } = state.canvas;
            if ('activeObjectType' in payload && payload.activeObjectType === ObjectType.TAG) {
                activeControl = ActiveControl.CURSOR;
            } else if ('activeShapeType' in payload) {
                const controlMapping = {
                    [ShapeType.RECTANGLE]: ActiveControl.DRAW_RECTANGLE,
                    [ShapeType.POLYGON]: ActiveControl.DRAW_POLYGON,
                    [ShapeType.POLYLINE]: ActiveControl.DRAW_POLYLINE,
                    [ShapeType.POINTS]: ActiveControl.DRAW_POINTS,
                    [ShapeType.ELLIPSE]: ActiveControl.DRAW_ELLIPSE,
                    [ShapeType.CUBOID]: ActiveControl.DRAW_CUBOID,
                    [ShapeType.SKELETON]: ActiveControl.DRAW_SKELETON,
                    [ShapeType.MASK]: ActiveControl.DRAW_MASK,
                };

                activeControl = controlMapping[payload.activeShapeType as ShapeType];
            }

            return {
                ...state,
                annotations: {
                    ...state.annotations,
                    activatedStateID: null,
                },
                canvas: {
                    ...state.canvas,
                    activeControl,
                },
                drawing: {
                    ...state.drawing,
                    ...payload,
                    activeInteractor: undefined,
                },
            };
        }
        case LOAD_PREV_FILE_OBJECTS_SUCCESS: {
            const {states, history} = action.payload;

            return {
                ...state,
                annotations: {
                    ...state.annotations,
                    states,
                    history,
                }
            }
        }
        case GET_AUTO_LABEL_ANNOTATION_SUCCESS: {
            const {states, history} = action.payload;
            return {
                ...state,
                annotations: {
                    ...state.annotations,
                    states,
                    history,
                }
            }
        }
        case REPEAT_DRAW_SHAPE: {
            const { activeControl } = action.payload;

            return {
                ...state,
                annotations: {
                    ...state.annotations,
                    activatedStateID: null,
                },
                canvas: {
                    ...state.canvas,
                    activeControl,
                },
            };
        }
        case SELECT_ISSUE_POSITION: {
            const { enabled } = action.payload;
            const activeControl = enabled ? ActiveControl.OPEN_ISSUE : ActiveControl.CURSOR;

            return {
                ...state,
                annotations: {
                    ...state.annotations,
                    activatedStateID: null,
                },
                canvas: {
                    ...state.canvas,
                    activeControl,
                },
            };
        }
        case MERGE_OBJECTS: {
            const { enabled } = action.payload;
            const activeControl = enabled ? ActiveControl.MERGE : ActiveControl.CURSOR;

            return {
                ...state,
                annotations: {
                    ...state.annotations,
                    activatedStateID: null,
                },
                canvas: {
                    ...state.canvas,
                    activeControl,
                },
            };
        }
        case GROUP_OBJECTS: {
            const { enabled } = action.payload;
            const activeControl = enabled ? ActiveControl.GROUP : ActiveControl.CURSOR;

            return {
                ...state,
                annotations: {
                    ...state.annotations,
                    activatedStateID: null,
                },
                canvas: {
                    ...state.canvas,
                    activeControl,
                },
            };
        }
        case SPLIT_TRACK: {
            const { enabled } = action.payload;
            const activeControl = enabled ? ActiveControl.SPLIT : ActiveControl.CURSOR;

            return {
                ...state,
                annotations: {
                    ...state.annotations,
                    activatedStateID: null,
                },
                canvas: {
                    ...state.canvas,
                    activeControl,
                },
            };
        }
        case SHAPE_DRAWN: {
            return {
                ...state,
                canvas: {
                    ...state.canvas,
                    activeControl: ActiveControl.CURSOR,
                },
            };
        }
        case UPDATE_ANNOTATIONS_SUCCESS: {
            const {
                history, states: updatedStates, minZ, maxZ,
            } = action.payload;
            const { states: prevStates } = state.annotations;
            const nextStates = [...prevStates];

            const clientIDs = prevStates.map((prevState: any): number => prevState.clientID);
            for (const updatedState of updatedStates) {
                const index = clientIDs.indexOf(updatedState.clientID);
                if (index !== -1) {
                    nextStates[index] = updatedState;
                }
            }

            const maxZLayer = Math.max(state.annotations.zLayer.max, maxZ);
            const minZLayer = Math.min(state.annotations.zLayer.min, minZ);

            return {
                ...state,
                annotations: {
                    ...state.annotations,
                    zLayer: {
                        min: minZLayer,
                        max: maxZLayer,
                        cur: clamp(state.annotations.zLayer.cur, minZLayer, maxZLayer),
                    },
                    states: nextStates,
                    history,
                },
            };
        }
        case CREATE_ANNOTATIONS_SUCCESS: {
            const { states, history } = action.payload;

            return {
                ...state,
                annotations: {
                    ...state.annotations,
                    states,
                    history,
                },
            };
        }
        case MERGE_ANNOTATIONS_SUCCESS: {
            const { states, history } = action.payload;

            return {
                ...state,
                annotations: {
                    ...state.annotations,
                    states,
                    history,
                },
            };
        }
        case RESET_ANNOTATIONS_GROUP: {
            return {
                ...state,
                annotations: {
                    ...state.annotations,
                    resetGroupFlag: true,
                },
            };
        }
        case GROUP_ANNOTATIONS: {
            return {
                ...state,
                annotations: {
                    ...state.annotations,
                    resetGroupFlag: false,
                },
            };
        }
        case GROUP_ANNOTATIONS_SUCCESS: {
            const { states, history } = action.payload;

            return {
                ...state,
                annotations: {
                    ...state.annotations,
                    states,
                    history,
                },
            };
        }
        case SPLIT_ANNOTATIONS_SUCCESS: {
            const { states, history } = action.payload;

            return {
                ...state,
                annotations: {
                    ...state.annotations,
                    states,
                    history,
                },
            };
        }
        case READY_OBJECT: {
            const { readyStateID, readyElementID, readyAttributeID } = action.payload;

            const {
                canvas: { activeControl, instance },
            } = state;

            if (activeControl !== ActiveControl.CURSOR || (instance as Canvas | Canvas3d).mode() !== CanvasMode.IDLE) {
                return state;
            }

            return {
                ...state,
                annotations: {
                    ...state.annotations,
                    readyStateID,
                    readyElementID,
                    readyAttributeID,
                },
            };
        }
        case RELAX_OBJECT: {
            return {
                ...state,
                annotations: {
                    ...state.annotations,
                    readyStateID: null,
                    readyElementID: null,
                    readyAttributeID: null
                }
            }
        }
        case ACTIVATE_OBJECT: {
            const { activatedStateID, activatedElementID, activatedAttributeID } = action.payload;

            const {
                canvas: { activeControl, instance },
            } = state;

            if (activeControl !== ActiveControl.CURSOR || (instance as Canvas | Canvas3d).mode() !== CanvasMode.IDLE) {
                return state;
            }

            return {
                ...state,
                annotations: {
                    ...state.annotations,
                    activatedLabelID: null,
                    readyStateID: null,
                    readyElementID: null,
                    readyAttributeID: null,
                    activatedStateID,
                    activatedElementID,
                    activatedAttributeID,
                },
            };
        }
        case ACTIVATE_LABEL: {
            const { activatedLabelID } = action.payload;

            const {
                canvas: { activeControl, instance },
            } = state;

            if (activeControl !== ActiveControl.CURSOR || (instance as Canvas | Canvas3d).mode() !== CanvasMode.IDLE) {
                return state;
            }

            return {
                ...state,
                annotations: {
                    ...state.annotations,
                    activatedLabelID,
                    activatedStateID: null
                }
            }
        }
        case REMOVE_OBJECT: {
            const { objectState, force } = action.payload;
            return {
                ...state,
                remove: {
                    ...state.remove,
                    objectState,
                    force,
                },
            };
        }
        case REMOVE_OBJECT_SUCCESS: {
            const { objectState, history, updateStates } = action.payload;
            const contextMenuClientID = state.canvas.contextMenu.clientID;
            const contextMenuVisible = state.canvas.contextMenu.visible;

            return {
                ...state,
                annotations: {
                    ...state.annotations,
                    history,
                    activatedStateID: null,
                    states: updateStates.filter(
                        (_objectState: any) => _objectState.clientID !== objectState.clientID,
                    ),
                },
                canvas: {
                    ...state.canvas,
                    contextMenu: {
                        ...state.canvas.contextMenu,
                        clientID: objectState.clientID === contextMenuClientID ? null : contextMenuClientID,
                        visible: objectState.clientID === contextMenuClientID ? false : contextMenuVisible,
                    },
                },
                remove: {
                    objectState: null,
                    force: false,
                },
            };
        }
        case REMOVE_OBJECT_FAILED: {
            return {
                ...state,
                remove: {
                    objectState: null,
                    force: false,
                },
            };
        }
        case REMOVE_ANNOTATIONS_SUCCESS: {
            const {history, states} = action.payload;
            return {
                ...state,
                annotations: {
                    ...state.annotations,
                    history,
                    states,
                    activatedStateID: null,
                    collapsed: {},
                },
                canvas: {
                    ...state.canvas,
                    contextMenu: {
                        ...state.canvas.contextMenu,
                        clientID: null,
                        visible: false
                    },
                },
                remove: {
                    objectState: null,
                    force: false,
                },
            };
        }
        case PASTE_SHAPE: {
            const { activeControl } = action.payload;

            return {
                ...state,
                canvas: {
                    ...state.canvas,
                    activeControl,
                    contextMenu: {
                        ...INIT_STATE.canvas.contextMenu,
                    },
                },
                annotations: {
                    ...state.annotations,
                    activatedStateID: null,
                },
            };
        }
        case COPY_SHAPE: {
            const { objectState } = action.payload;

            return {
                ...state,
                drawing: {
                    ...state.drawing,
                    activeInitialState: objectState,
                },
            };
        }
        case EDIT_SHAPE: {
            const { enabled } = action.payload;
            const activeControl = enabled ? ActiveControl.EDIT : ActiveControl.CURSOR;

            return {
                ...state,
                canvas: {
                    ...state.canvas,
                    activeControl,
                },
            };
        }
        case PROPAGATE_OBJECT_SUCCESS: {
            const { history } = action.payload;
            return {
                ...state,
                annotations: {
                    ...state.annotations,
                    history,
                },
            };
        }
        case SWITCH_PROPAGATE_VISIBILITY: {
            const { visible } = action.payload;
            return {
                ...state,
                propagate: {
                    visible,
                },
            };
        }
        case SWITCH_SHOWING_STATISTICS: {
            const { visible } = action.payload;

            return {
                ...state,
                statistics: {
                    ...state.statistics,
                    visible,
                },
            };
        }
        case SWITCH_SHOWING_FILTERS: {
            const { visible } = action.payload;

            return {
                ...state,
                filtersPanelVisible: visible,
            };
        }
        case COLLECT_STATISTICS: {
            return {
                ...state,
                statistics: {
                    ...state.statistics,
                    collecting: true,
                },
            };
        }
        case COLLECT_STATISTICS_SUCCESS: {
            const { data } = action.payload;
            const instanceNotAttrValidCnt = data.instanceNotAttrValidCnt;
            const instanceNotEditedCnt = data.instanceNotEditedCnt;
            const classesNotEditedCnt = data.classesNotEditedCnt;

            return {
                ...state,
                canvas: {
                    ...state.canvas,
                    badges: {
                        ...state.canvas.badges,
                        instanceNotAttrValidCnt,
                        instanceNotEditedCnt,
                        classesNotEditedCnt
                    }
                },
                statistics: {
                    ...state.statistics,
                    collecting: false,
                    data,
                },
            };
        }
        case COLLECT_STATISTICS_FAILED: {
            return {
                ...state,
                statistics: {
                    ...state.statistics,
                    collecting: false,
                    data: null,
                },
            };
        }
        // case REVIEW_CHECK_VALIDATION_SUCCESS: {
        //     const { errorVisible, instanceNotEditedCnt, classesNotEditedCnt } = action.payload;
        //     return {
        //         ...state,
        //         canvas: {
        //             ...state.canvas,
        //             badges: {
        //                 ...state.canvas.badges,
        //                 visible: errorVisible ?? state.canvas.badges.visible,
        //                 instanceNotEditedCnt,
        //                 classesNotEditedCnt,
        //             }
        //         }
        //     }
        // }
        case CHECK_VALIDATION_SUCCESS: {
            const { errorVisible, instanceNotAttrValidCnt, instanceNotEditedCnt, classesNotEditedCnt } = action.payload;
            return {
                ...state,
                canvas: {
                    ...state.canvas,
                    badges: {
                        ...state.canvas.badges,
                        visible: errorVisible,
                        instanceNotAttrValidCnt,
                        instanceNotEditedCnt,
                        classesNotEditedCnt
                    }
                }
            }
        }
        case UPLOAD_MODULE_ANNOTATIONS: {
            const { module, loader } = action.payload;
            const { loads } = state.activities;
            loads[module.id] = module.id in loads ? loads[module.id] : loader.name;

            return {
                ...state,
                activities: {
                    ...state.activities,
                    loads: {
                        ...loads,
                    },
                },
            };
        }
        case UPLOAD_MODULE_ANNOTATIONS_FAILED: {
            const { module } = action.payload;
            const { loads } = state.activities;

            delete loads[module.id];

            return {
                ...state,
                activities: {
                    ...state.activities,
                    loads: {
                        ...loads,
                    },
                },
            };
        }
        case UPLOAD_MODULE_ANNOTATIONS_SUCCESS: {
            const { states, history } = action.payload;

            return {
                ...state,
                annotations: {
                    ...state.annotations,
                    history,
                    states,
                    activatedStateID: null,
                    collapsed: {},
                },
            };
        }
        // Added Remove Annotations
        case REMOVE_MODULE_ANNOTATIONS_SUCCESS: {
            const { history } = action.payload;
            const { states } = action.payload;
            return {
                ...state,
                annotations: {
                    ...state.annotations,
                    history,
                    states,
                    activatedStateID: null,
                    collapsed: {},
                },
            };
        }
        case UPDATE_CANVAS_CONTEXT_MENU: {
            const {
                visible, left, top, type, pointID,
            } = action.payload;
            const { activatedElementID, activatedStateID } = state.annotations;
            let defaultTop, defaultLeft;
            if (visible && !!!top && !!!left && state.canvas.instance) {
                const activatedSvgShape = state.canvas.instance.view.svgShapes[activatedStateID];
                // TODO activatedSvgShape이 없을때 검수 캔버스 진입이 안되는 상황을 피하기 위해 기본값 0으로 설정
                const shapeBBox = activatedSvgShape?.node.getBBox() ?? {x:0, y:0, width : 0, height:0};
                const [leftValue, topValue] = translateFromSVG(state.canvas.instance.view.content, [shapeBBox.x, shapeBBox.y]);
                const [rightValue, bottomValue ] = translateFromSVG(state.canvas.instance.view.content, [shapeBBox.x + shapeBBox.width, shapeBBox.y+ shapeBBox.height]);
                defaultTop = (bottomValue - topValue) === 0 ? topValue + 50 : topValue + (bottomValue - topValue) * 1/3;
                defaultLeft = (rightValue - leftValue) === 0 ? leftValue + 50 : leftValue + (rightValue - leftValue) * 2/3;
                // console.log('[UPDATE_CANVAS_CONTEXT_MENU]', defaultTop, defaultLeft, bottomValue, topValue)
            }

            return {
                ...state,
                canvas: {
                    ...state.canvas,
                    contextMenu: {
                        ...state.canvas.contextMenu,
                        visible,
                        left: left ?? defaultLeft,
                        top: top ?? defaultTop,
                        deltaPosition: {
                            x: 0,
                            y: 0
                        },
                        type,
                        pointID,
                        clientID: Number.isInteger(activatedElementID) ? activatedElementID : activatedStateID,
                        parentID: Number.isInteger(activatedElementID) ? activatedStateID : null,
                    },
                },
            };
        }
        case UPDATE_CANVAS_CONTEXT_MENU_POSITION: {
            const {x, y} = action.payload;

            const { activatedElementID, activatedStateID } = state.annotations;
            let defaultTop, defaultLeft;
            if ( !!!x && !!!y ) {
                const activatedSvgShape = state.canvas.instance.view.svgShapes[activatedStateID];
                // TODO activatedSvgShape이 없을때 검수 캔버스 진입이 안되는 상황을 피하기 위해 기본값 0으로 설정
                const shapeBBox = activatedSvgShape?.node.getBBox() ?? {x:0, y:0, width : 0, height:0};
                const [leftValue, topValue] = translateFromSVG(state.canvas.instance.view.content, [shapeBBox.x, shapeBBox.y]);
                const [rightValue, bottomValue ] = translateFromSVG(state.canvas.instance.view.content, [shapeBBox.x + shapeBBox.width, shapeBBox.y+ shapeBBox.height]);
                defaultTop = topValue + (bottomValue - topValue) * 1/3;
                defaultLeft = leftValue + (rightValue - leftValue) * 2/3;
            }
            console.log('[updateCanvasContext Position] defaultTop, defaultLeft', defaultTop, defaultLeft)
            return {
                ...state,
                canvas: {
                    ...state.canvas,
                    contextMenu: {
                        ...state.canvas.contextMenu,
                        deltaPosition: {
                            x: x ?? defaultTop,
                            y: y ?? defaultLeft }
                    },

                }
            }
        }
        case UPDATE_CANVAS_SKIP_POPOVER: {
            const { visible, reasons } = action.payload

            return {
                ...state,
                canvas: {
                    ...state.canvas,
                    skipPopover: {
                        ...state.canvas.skipPopover,
                        visible: visible ?? state.canvas.skipPopover.visible,
                        reasons: reasons ?? state.canvas.skipPopover.reasons,
                    },
                },
            };
        }
        case UPDATE_CANVAS_SKIP_RSN: {
            const { rsnCd, rsnCn} = action.payload;
            return {
                ...state,
                canvas: {
                    ...state.canvas,
                    skipPopover: {
                        ...state.canvas.skipPopover,
                        choices: {
                            rsnCd: rsnCd ?? state.canvas.skipPopover.choices.rsnCd,
                            rsnCn: rsnCn ?? state.canvas.skipPopover.choices.rsnCn,
                        }
                    },
                },
            }
        }
        case UPDATE_CANVAS_EDIT_IMAGE_POPOVER: {
            const {visible} = action.payload;
            return {
                ...state,
                canvas: {
                    ...state.canvas,
                    editImagePopover: {
                        visible,
                    }
                }
            }
        }
        case UPDATE_BRUSH_TOOLS_CONFIG: {
            return {
                ...state,
                canvas: {
                    ...state.canvas,
                    brushTools: {
                        ...state.canvas.brushTools,
                        ...action.payload,
                    },
                },
            };
        }
        case REDO_ACTION_SUCCESS:
        case UNDO_ACTION_SUCCESS: {
            const { activatedStateID } = state.annotations;
            const {
                history, states, minZ, maxZ,
            } = action.payload;

            return {
                ...state,
                annotations: {
                    ...state.annotations,
                    activatedStateID: updateActivatedStateID(states, activatedStateID),
                    states,
                    history,
                    zLayer: {
                        min: minZ,
                        max: maxZ,
                        cur: clamp(state.annotations.zLayer.cur, minZ, maxZ),
                    },
                },
            };
        }
        case FETCH_ANNOTATIONS_SUCCESS: {
            const { activatedStateID } = state.annotations;
            const {
                states, history, minZ, maxZ,
            } = action.payload;

            return {
                ...state,
                annotations: {
                    ...state.annotations,
                    activatedStateID: updateActivatedStateID(states, activatedStateID),
                    states,
                    history,
                    zLayer: {
                        min: minZ,
                        max: maxZ,
                        cur: clamp(state.annotations.zLayer.cur, minZ, maxZ),
                    },
                },
            };
        }
        case CHANGE_ANNOTATIONS_FILTERS: {
            const { filters } = action.payload;
            return {
                ...state,
                annotations: {
                    ...state.annotations,
                    filters,
                },
            };
        }
        case UPDATE_SIDEBAR_TAB: {
            const { tab } = action.payload;
            return {
                ...state,
                canvas: {
                    ...state.canvas,
                    sideBarTab: tab,
                    contextMenu: {
                        ...state.canvas.contextMenu,
                        visible: tab === '2' ? false : state.canvas.contextMenu.visible
                    }
                }
            }
        }
        case SWITCH_Z_LAYER: {
            const { cur } = action.payload;
            const { max, min } = state.annotations.zLayer;

            let { activatedStateID } = state.annotations;
            if (activatedStateID !== null) {
                const idx = state.annotations.states.map((_state: any) => _state.clientID).indexOf(activatedStateID);
                if (idx !== -1) {
                    if (state.annotations.states[idx].zOrder > cur) {
                        activatedStateID = null;
                    }
                } else {
                    activatedStateID = null;
                }
            }

            return {
                ...state,
                annotations: {
                    ...state.annotations,
                    activatedStateID,
                    zLayer: {
                        ...state.annotations.zLayer,
                        cur: clamp(cur, min, max),
                    },
                },
            };
        }
        case ADD_Z_LAYER: {
            const { max } = state.annotations.zLayer;
            return {
                ...state,
                annotations: {
                    ...state.annotations,
                    zLayer: {
                        ...state.annotations.zLayer,
                        max: max + 1,
                        cur: max + 1,
                    },
                },
            };
        }
        case INTERACT_WITH_CANVAS: {
            const { activeInteractor, activeLabelID } = action.payload;
            return {
                ...state,
                annotations: {
                    ...state.annotations,
                    activatedStateID: null,
                },
                drawing: {
                    ...state.drawing,
                    activeInteractor,
                    activeLabelID,
                },
                canvas: {
                    ...state.canvas,
                    activeControl: activeInteractor.kind.startsWith('opencv') ?
                        ActiveControl.OPENCV_TOOLS :
                        ActiveControl.AI_TOOLS,
                },
            };
        }
        case SET_FORCE_EXIT_ANNOTATION_PAGE_FLAG: {
            const { forceExit } = action.payload;
            return {
                ...state,
                annotations: {
                    ...state.annotations,
                    saving: {
                        ...state.annotations.saving,
                        forceExit,
                    },
                },
            };
        }
        case CHANGE_WORKSPACE: {
            const { workspace } = action.payload;
            if (state.canvas.activeControl !== ActiveControl.CURSOR) {
                return state;
            }

            return {
                ...state,
                workspace,
                annotations: {
                    ...state.annotations,
                    activatedStateID: null,
                    activatedAttributeID: null,
                },
            };
        }
        case UPDATE_PREDICTOR_STATE: {
            const { payload } = action;
            return {
                ...state,
                predictor: {
                    ...state.predictor,
                    ...payload,
                },
            };
        }
        case GET_PREDICTIONS: {
            return {
                ...state,
                predictor: {
                    ...state.predictor,
                    fetching: true,
                },
            };
        }
        case GET_PREDICTIONS_SUCCESS: {
            const { frame } = action.payload;
            const annotatedFrames = [...state.predictor.annotatedFrames, frame];

            return {
                ...state,
                predictor: {
                    ...state.predictor,
                    fetching: false,
                    annotatedFrames,
                },
            };
        }
        case GET_PREDICTIONS_FAILED: {
            return {
                ...state,
                predictor: {
                    ...state.predictor,
                    fetching: false,
                },
            };
        }
        case RESET_CANVAS: {
            return {
                ...state,
                canvas: {
                    ...state.canvas,
                    activeControl: ActiveControl.CURSOR,
                },
            };
        }
        case SWITCH_NAVIGATION_BLOCKED: {
            return {
                ...state,
                player: {
                    ...state.player,
                    navigationBlocked: action.payload.navigationBlocked,
                },
            };
        }
        case SWITCH_SHORTCUTS: {
            return {
                ...state,
                canvas: {
                    ...state.canvas,
                    shortcuts: {
                        visible: action.payload.visible
                    }
                }
            }
        }
        case DELETE_FRAME:
        case RESTORE_FRAME: {
            return {
                ...state,
                player: {
                    ...state.player,
                    frame: {
                        ...state.player.frame,
                        fetching: true,
                    },
                },
                canvas: {
                    ...state.canvas,
                    ready: false,
                },
            };
        }
        case DELETE_FRAME_FAILED:
        case RESTORE_FRAME_FAILED: {
            return {
                ...state,
                player: {
                    ...state.player,
                    frame: {
                        ...state.player.frame,
                        fetching: false,
                    },
                },
                canvas: {
                    ...state.canvas,
                    ready: true,
                },
            };
        }
        case DELETE_FRAME_SUCCESS:
        case RESTORE_FRAME_SUCCESS: {
            return {
                ...state,
                player: {
                    ...state.player,
                    frame: {
                        ...state.player.frame,
                        data: action.payload.data,
                        fetching: false,
                    },
                },
                annotations: {
                    ...state.annotations,
                    history: action.payload.history,
                    states: action.payload.states,
                },
                canvas: {
                    ...state.canvas,
                    ready: true,
                },
            };
        }
        // case AuthActionTypes.LOGOUT_SUCCESS:
        case CLOSE_MODULE_SUCCESS:{
            if (state.canvas.instance) {
                state.canvas.instance.destroy();
            }
            return { ...INIT_STATE };
        }


        case SET_CANVAS_DATA_TY :
            return {
                ...state,
                canvasDataTy: action.payload
            }

        default:
            return state;
    }
}

export default Cmmn;
