// @ts-nocheck
import {Input, Label, Popover} from "reactstrap"
import {GetMessage, GetMessageIntl} from "util/message";
import React, {useEffect, useState} from "react";
import {CommonService} from "../../../../../../apis/api/CmmnService";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router";
import {useIntl} from "react-intl";
import {skipData, updateCanvasSkipPopover, updateCanvasSkipRsn} from "../../../../../../store/label/action";
import {Controller, useForm} from "react-hook-form";
import {confirmWarning} from "../../../../../../util/AlertUtil";
import {SKIP_POPOVER_INPUT_TEXT, SKIP_POPOVER_INPUT_TEXT_CONTAINER} from "../../../annotation/image/components/classNameConsts";
import {CANVAS_DATA_TY} from "../../../annotation/image/const";
interface Props {
    target: HTMLDivElement;
    toggle: any;
    isOpen: boolean;
}
export const SkipPopover = (props: Props) => {
    const {target, toggle, isOpen} = props;

    return (
        <Popover
            className={"skipPopover"}
            target={target}
            placement="top"
            isOpen={isOpen}
        >
            <SkipPopoverContent toggle={toggle}/>
        </Popover>
    )
}

const SkipPopoverContent = (props) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const intl = useIntl();

    const {toggle} = props;
    const {
        Label: {
            Cmmn: {
                canvas: {
                    skipPopover: {
                        reasons: skipRsn,
                        choices: choice
                    }
                },
                module: {
                    instance: moduleInstance,
                    canvasDataTy
                }
            }
        }
    } = useSelector(state => state);

    const { control, register, trigger, formState: { errors }, handleSubmit } = useForm({criteriaMode: "all"});
    const { rsnCd: choiceCdList, rsnCn: choiceCn } = choice;
    useEffect(() => {
        const getSkipRsnCd = async () => {
            let result;
            if (canvasDataTy === CANVAS_DATA_TY.IMAGE_ANNOTATION) {
                result = await CommonService.getSkipRsnCd();
            } else if (canvasDataTy === CANVAS_DATA_TY.IMAGE_CLSR_SMRY) {
                result = await CommonService.getSkipRsnCdForImageClsfcSmry();
            } else if (canvasDataTy === CANVAS_DATA_TY.AUDIO_CLSR_SMRY) {
                result = await CommonService.getSkipRsnCdForAudioClsfcSmry();
            } else if (canvasDataTy === CANVAS_DATA_TY.VIDEO_CLSR_SMRY) {
                result = await CommonService.getSkipRsnCdForVideoClsfcSmry();
            } else if (canvasDataTy === CANVAS_DATA_TY.TEXT_CLSR_SMRY) {
                result = await CommonService.getSkipRsnCdForTextClsfcSmry();
            }
            const data = result ? result.data :{data: []};
            dispatch(updateCanvasSkipPopover(null, data.data))
        }
        if (skipRsn.length === 0) {
            getSkipRsnCd();
        }
    }, []);

    const onSkipAnnotation = (cd, cn) => {
        dispatch(updateCanvasSkipRsn(cd, cn))
    }

    const onSkipSubmit = () => {
        trigger(SKIP_POPOVER_INPUT_TEXT);

        if (choiceCdList.includes("01") && choiceCn==="") return;

        confirmWarning("label.skipConfirm","label.skipConfirmText"
            , () => {
                dispatch(skipData({moduleInstance, skipCn: choice, intl, history, dispatch}));
            }
            ,"","",intl)
    }

    const onCdChange = (target) => {
        const checked = target.checked;
        const value = target.value;
        let newValues = [...choiceCdList];
        if (checked) {
            newValues.push(value);
        } else {
            const index = newValues.indexOf(value)
            newValues.splice(index, 1);
        }

        if (value === '01' && !checked) {
            onSkipAnnotation(newValues, '');
        } else {
            onSkipAnnotation(newValues, null);
        }
    }
    const onSubmit = (data) => {
        // console.log('[onSubmit]', data)
    }

    const textInputName = SKIP_POPOVER_INPUT_TEXT;
    return (
        <>
            <div className="skipPopoverContent">
                <div className={"d-flex align-items-center title"}>
                    <i className={"ri-error-warning-fill"}></i>
                    <div className={"popover-title"}>{GetMessage("label.skipTitle")}</div>
                    <div className={"popover-subtitle"}>{GetMessage("label.skipSubTitle")}</div>
                </div>
                <div className={"vstack content"}>
                    <form onSubmit={handleSubmit(onSubmit)}>
                    {skipRsn.map(
                            (rsn: any): JSX.Element => {
                                const { cmmnDetailCd: rsnCd} = rsn;
                                return (
                                    <div className="form-check form-check-coco" key={rsnCd}>
                                        <Input className="form-check-input" type="checkbox"
                                               id={rsnCd} defaultChecked={choiceCdList.includes(rsnCd)}
                                               onChange={(event) => {
                                                   onCdChange(event.target)
                                               }}
                                               value={rsnCd}
                                        />
                                        <Label className="form-check-label" htmlFor={rsnCd}>
                                            {rsn.cmmnDetailCdNm}
                                        </Label>
                                    </div>
                                )
                            }
                    )}
                    {
                        (choiceCdList.includes('01')) &&
						<>
							<div className={[Object.keys(errors).includes(SKIP_POPOVER_INPUT_TEXT)?"validation-error-bottom":"", SKIP_POPOVER_INPUT_TEXT_CONTAINER].join(' ')}>
								<Controller
									control={control}
									name={SKIP_POPOVER_INPUT_TEXT}
									render={({ field }) => {
                                        const { onChange } = field;
                                        return (
                                            <Input {...field}
                                                   className={"editable"}
                                                   value={choiceCn}
                                                   onChange={(event) => {
                                                       const newValue = event.target.value;
                                                       onChange(newValue);
                                                       onSkipAnnotation(null, newValue);
                                                   }}
                                                   autoComplete={"off"}
                                                   placeholder={GetMessageIntl(intl, "label.inputPlaceholder")}
                                            />
                                        )
                                    }}
									rules={{
                                        required: true,
                                    }}
								/>
                                {Object.keys(errors).includes(SKIP_POPOVER_INPUT_TEXT) && <p className="validation-error-message">{GetMessageIntl(intl, "validation.required")}</p>}
							</div>
						</>
                    }
                    <div className={"skip-popover-footer"}>
                        <button type="button"
                                onClick={toggle}
                                className="btn btn-coco-2 waves-effect waves-light cancel"
                        >
                            {GetMessage('ui.U00002')}
                        </button>
                        <button type="button"
                                onClick={onSkipSubmit}
                                className="btn btn-coco-1 waves-effect waves-light skip" disabled={choiceCdList.length === 0}
                        >
                            {GetMessage('label.skipPopoverButton')}
                        </button>
                    </div>
                    </form>
                </div>
            </div>
        </>
    )
}
