// @ts-nocheck
import {connect} from "react-redux";
import {ObjectReviewFooter} from "../../../components/review-workspace/objects-side-bar/object-review-footer";
import React from "react";
import {
    activateLabel,
    activateObject as activateObjectAction, updateSideBarTab,
} from "../../../../../../../../store/label/action";
import {ActiveControl} from "../../../../../../../../store/label";
import {updateCompletedStates, updateReviewStates} from "../../../../../../../../store/review/action";
import {MD_STTUS_CD, MD_TY_CD} from "../../../const";
import ReviewInstance from "../../../work/core/review-instance";

interface OwnProps {
    instData: ReviewInstance[];
    disabled: boolean;
    clientID: number;
    sortedStatesID: number[];
    onOpenCanvasContextMenu(e): void;
}
interface StateToProps {
    labels: any[];
    objectStates: any[];
    reviewInstData: any[];
    reviewClsData: any[];
    ready: boolean;
    activeControl: ActiveControl;
    moduleType: MD_TY_CD;
    moduleStatus: MD_STTUS_CD;
}

interface DispatchToProps {
    onUpdateSideBarTab(tab: string): void;
    onActivateLabel(activatedLabelID: number | null): void;
    activateObject(activatedStateID: number | null): void;
    updateReviewState(instData): void;
    onUpdateCompletedStates(reviewData: ReviewInstance, value: boolean): void;
}

function mapStateToProps(state, own: OwnProps) :StateToProps {
    const { clientID } = own;
    const {
        Label: {
            Cmmn: {
                annotations: {states},
                canvas: {
                    ready, activeControl
                },
                module: {
                    type: moduleType,
                    status: moduleStatus,
                    labels
                }
            }
        },
        Review: {
            Cmmn: {
                annotations: {
                    data: reviewTotalData
                }
            }
        }
    } = state;
    const reviewInstData = reviewTotalData.instance;
    const reviewClsData = reviewTotalData.classes;
    const editable = reviewInstData[clientID]?.editable;
    return {
        labels,
        objectStates: states,
        reviewInstData,
        reviewClsData,
        editable,
        ready,
        activeControl,
        moduleType,
        moduleStatus
    }
}

function dispatchToProps(dispatch: any): DispatchToProps {
    return {
        onUpdateSideBarTab(tab: string): void {
            dispatch(updateSideBarTab(tab));
        },
        onActivateLabel(activatedLabelID: number | null): void {
            dispatch(activateLabel(activatedLabelID));
        },
        activateObject(activatedStateID: number | null): void {
            dispatch(activateObjectAction(activatedStateID, null, null));
        },
        updateReviewState(instData): void {
            dispatch(updateReviewStates(instData, null));
        },
        onUpdateCompletedStates(reviewData: ReviewInstance, value: boolean): void {
            dispatch(updateCompletedStates(reviewData, value))
        }
    };
}

type Props = StateToProps & DispatchToProps & OwnProps;
class ObjectReviewFooterContainer extends React.PureComponent<Props> {

    public constructor(props: Props) {
        super(props);
    }

    private nextActivate = (activeStateID: number): void => {
        const { objectStates, ready, activeControl, activateObject } = this.props;

        if (ready && activeControl === ActiveControl.CURSOR) {
            activateObject(activeStateID);
        }
    }

    private nextObjectActivate = (e): void => {
        const { editable, clientID, sortedStatesID, objectStates, reviewInstData, onOpenCanvasContextMenu } = this.props;
        const index = objectStates.findIndex((state) => state.clientID === clientID);
        if (!editable) {
            return;
        }
        if (index === objectStates.length - 1) {
            this.activateClassTab();
            return;
        }

        let nextObject;
        for (let i = index + 1; i < objectStates.length; i++) {
            const nClientID = objectStates[i].clientID;
            if (reviewInstData[nClientID].editable === true) {
                nextObject = objectStates[i];
                break;
            }
        }
        if (nextObject) {
            this.nextActivate(nextObject.clientID);
        } else {
            this.activateClassTab();
        }
        onOpenCanvasContextMenu(e)
    }

    private activateClassTab = (): void => {
        const { labels, reviewClsData, onActivateLabel, onUpdateSideBarTab } = this.props;
        let nextLabelID = labels[0].id;
        for (let i = 0; i < labels.length; i++) {
            const labelID = labels[i].id;
            if (reviewClsData[labelID].reject === true) {
                nextLabelID = labelID;
                break;
            }
        }
        onActivateLabel(nextLabelID);
        onUpdateSideBarTab("2");
    }

    private updateCompletedError = (): void => {
        const {instData, onUpdateCompletedStates} = this.props;
        onUpdateCompletedStates(instData, !instData.completedError)
    }

    public render(): JSX.Element {
        const {
            disabled,
            moduleType,
            moduleStatus,
            editable,
            clientID
        } = this.props;

        return (
            <ObjectReviewFooter disabled={disabled}
                                onClick={(e) => {
                                    this.nextObjectActivate(e);
                                    this.updateCompletedError();
                                }}
                                moduleType={moduleType}
                                moduleStatus={moduleStatus}
                                editable={editable}
            />
        );
    }
}
export default connect(mapStateToProps, dispatchToProps)(ObjectReviewFooterContainer);
