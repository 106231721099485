import React from 'react';
import { Row } from 'reactstrap';
import {useIntl} from "react-intl";
import {GetMessageIntl} from "../../util/message";

interface Props {
    msgCode : string
}
export const EmptyListContentView = (props: Props) => {
    const intl = useIntl();
    const { msgCode } = props;

    return (
        <Row className='d-flex align-items-center justify-content-center fs-16 fw-500 mx-0 w-100 text-coco-gray-400' style={{height: "54vh"}}>
            {GetMessageIntl(intl, msgCode)}
        </Row>
    );
}
