import {
  SET_MANAGE_LABEL_ID
  , SET_MANAGE_LABEL_SHAPE_CONFIG
  , GET_MANAGE_LABEL_SHAPE_CONFIG
  , SET_MANAGE_LABEL_DATASET
  , GET_MANAGE_LABEL_DATASET
  , UPDATE_MANAGE_LABEL_DATASET
  , SET_MANAGE_LABEL_GUIDE
  , GET_MANAGE_LABEL_GUIDE
  , SET_MANAGE_LABEL_CLASS_ITEMS
  , GET_MANAGE_LABEL_CLASS_ITEMS
  , ADD_MANAGE_LABEL_CLASS_ITEM
  , UPDATE_MANAGE_LABEL_CLASS_ITEM
  , DELETE_MANAGE_LABEL_CLASS_ITEM
  , SET_MANAGE_LABEL_ATTR_LIST
  , GET_MANAGE_LABEL_ATTR_LIST
  , SET_MANAGE_LABEL_ATTR_ITEMS
  , ADD_MANAGE_LABEL_ATTR_ITEM
  , UPDATE_MANAGE_LABEL_ATTR_ITEM
  , DELETE_MANAGE_LABEL_ATTR_ITEM
  , SET_SELECTED_MANAGE_LABEL_CLASS_ID
  , SET_SELECTED_MANAGE_LABEL_ATTR_ID
  , SET_SELECTED_MANAGE_LABEL_ATTR_ITEM_ID
} from './actionType';

export const setManageLabelShapeConfig =(labelShapeConfig)=>({
  type:SET_MANAGE_LABEL_SHAPE_CONFIG,
  payload: labelShapeConfig,
})

export const setManageLabelClassItems = labelClassItems =>({
  type: SET_MANAGE_LABEL_CLASS_ITEMS,
  payload: labelClassItems,
})

export const addManageLabelClassItem = labelClassItem =>({
  type: ADD_MANAGE_LABEL_CLASS_ITEM,
  payload: labelClassItem,
})


export const updateManageLabelClassItem = labelClassItem =>({
  type: UPDATE_MANAGE_LABEL_CLASS_ITEM,
  payload: labelClassItem,
})

export const deleteManageLabelClassItem = deleteClassItemId =>({
  type: DELETE_MANAGE_LABEL_CLASS_ITEM,
  payload: deleteClassItemId,
})

export const setManageLabelAttrItems = labelAttrItems =>({
  type: SET_MANAGE_LABEL_ATTR_ITEMS,
  payload: labelAttrItems,
})

export const addManageLabelAttrItem = labelAttrItem =>({
  type: ADD_MANAGE_LABEL_ATTR_ITEM,
  payload: labelAttrItem,
})

export const deleteManageLabelAttrItem = deleteLabelAttrId =>({
  type: DELETE_MANAGE_LABEL_ATTR_ITEM,
  payload: deleteLabelAttrId,
})

export const setSelectedManageLabelClassId  = id =>({
  type: SET_SELECTED_MANAGE_LABEL_CLASS_ID,
  payload: id,
})

export const setSelectedManageLabelAttrId  = id =>({
  type: SET_SELECTED_MANAGE_LABEL_ATTR_ID,
  payload: id,
})

export const setSelectedManageLabelAttrItemId  = id =>({
  type: SET_SELECTED_MANAGE_LABEL_ATTR_ITEM_ID,
  payload: id,
})
