// @ts-nocheck
import {ActiveControl, CombinedState} from "../../../../../../../../store/label";
import React from "react";

import {connect} from "react-redux";
import {updateCompletedStates, updateReviewStates} from "../../../../../../../../store/review/action";
import {activateLabel} from "../../../../../../../../store/label/action";
import {ObjectReviewFooter} from "../../../components/review-workspace/objects-side-bar/object-review-footer";
import {MD_STTUS_CD, MD_TY_CD} from "../../../const";
import {cloneDeep} from "lodash";
import ReviewInstance from "../../../work/core/review-instance";
import ReviewClasses from "../../../work/core/review-classes";

interface OwnProps{
    labelID: number;
}

interface StateToProps {
    labels: any[];
    reviewClsData: any[];
    reviewData: ReviewClasses;
    moduleType: MD_TY_CD;
    moduleStatus: MD_STTUS_CD;
    reject: boolean | null;
    completedError: boolean;
    touch: boolean;
}
interface DispatchToProps {
    onActivateLabel(activatedLabelID: number | null): void;
    onUpdateReviewLabel(RawClassesData): void;
    onUpdateCompletedStates(reviewData: ReviewClasses, value: boolean): void;
}

function mapStateToProps(state: CombinedState, own: OwnProps): StateToProps {
    const {
        Label: {
            Cmmn: {
                module: {labels, type: moduleType, status: moduleStatus}
            }
        },
        Review: {
            Cmmn: {
                annotations: {
                    data: reviewTotalData
                }
            }
        }
    } = state;

    const reviewData = reviewTotalData.classes ? reviewTotalData.classes[own.labelID] : null;
    return {
        labels,
        moduleType,
        moduleStatus,
        reviewData,
        reviewClsData: reviewTotalData.classes,
        reject: reviewData.reject,
        completedError: reviewData.completedError,
        touch: reviewData.touch
    }
}
function dispatchToProps(dispatch: any): DispatchToProps {
    return {
        onActivateLabel(activatedLabelID: number | null): void {
            dispatch(activateLabel(activatedLabelID));
        },
        onUpdateReviewLabel(classData): void {
            dispatch(updateReviewStates(null, classData));
        },
        onUpdateCompletedStates(reviewData: ReviewClasses, value: boolean): void {
            dispatch(updateCompletedStates(reviewData, value))
        }
    }
}

type Props = StateToProps & DispatchToProps & OwnProps;
class LabelReviewFooterContainer extends React.PureComponent<Props> {

    public constructor(props: Props) {
        super(props);
    }

    private updateCompletedError(){
        const { labelID, reviewData, onUpdateCompletedStates } = this.props;
        onUpdateCompletedStates(reviewData, !reviewData.completedError)
    }

    private nextActivate = (): void => {
        const { labelID, labels, reviewClsData, onActivateLabel } = this.props;
        const index = labels.findIndex((label) => label.id === labelID);
        if (index === labels.length - 1) {
            onActivateLabel(null);
            return;
        }

        let nextLabelID = null;
        for (let i = index + 1; i < labels.length; i++) {
            const labelID = labels[i].id;
            if (reviewClsData[labelID].reject === true) {
                nextLabelID = labelID;
                break;
            }
        }
        onActivateLabel(nextLabelID);

    }

    public render(): JSX.Element {
        const { labelID, moduleType, moduleStatus, reject, completedError, touch } = this.props;
        return (
            <ObjectReviewFooter disabled={false}
                                onClick={(e) => {
                                    this.updateCompletedError();
                                    this.nextActivate();
                                    // this.updateCompletedError();
                                }}
                                moduleType={moduleType}
                                moduleStatus={moduleStatus}
            />

        );
    }
}

export default connect(mapStateToProps, dispatchToProps)(LabelReviewFooterContainer);
