import {
    SET_SEARCH_PARAM,
    SET_USER_TY,
    SET_PRJCT_ID,
    SET_USER_NM,
    SET_PRJCT_OPTIONS,
    SET_CURRENT_PAGE
} from './actionType';

const INIT_STATE = {
  searchParam: [],
  currentPage: 1,
  userNm: "",
  userTy: "",
  prjctId: "",
  userTyOptions: [],
  prjctOptions: [],
};

const ManageManager = (state = INIT_STATE, action) => {
  switch (action.type) {
     case SET_SEARCH_PARAM :
       return {
         ...state,
         searchParam: [action.payload]
       }
    case SET_USER_NM:
      return {
        ...state,
        userNm: action.payload
      }
    case SET_USER_TY:
      return {
        ...state,
        userTy: action.payload
      }
    case SET_PRJCT_ID:
      return {
        ...state,
        prjctId: action.payload
      }
    case SET_PRJCT_OPTIONS:
      return {
        ...state,
        prjctOptions: action.payload
      }
    case SET_CURRENT_PAGE:
      return {
        ...state,
        currentPage: action.payload
      }
    default:
      return state;
  }
};

export default ManageManager;
