import React from 'react'
import JsFileDownloader from 'js-file-downloader';
import {DatasetService} from "../../apis/api/DatasetService";


const FileExportTest = () => {
    const getFile = async () => {

        // 다운로드 받을 데이터가 있는지 확인
        // 이때 다운받을 파일명 데이터 수신
        const param = {
            'moduleId': 'MD046740c5-0fb2-4096-83ca-6911fd5a4f99'
        }
        const response = await DatasetService.checkExportData(param);
        const result = response.data.data;
        if (result.fileCount > 0) {
            console.log(result.fileName)
            new JsFileDownloader({
                url: '/service/module/export',
                method: 'POST',
                contentType: 'application/json',
                filename: result.fileName,
                headers: [
                    {name: 'Authorization', value: 'Bearer ABC123...'}
                ],
                body: '{"moduleId": "MD046740c5-0fb2-4096-83ca-6911fd5a4f99", "zipType" : "02"}'
            })
                .then(function () {
                    // Called when download ended
                })
                .catch(function (error) {
                    // Called when an error occurred
                });
        } else {
            alert('다운받을 데이터가 없습니다.')
        }

        console.log('----end----')
    }

    return (
        <>
            <button onClick={getFile}>다운로드</button>
        </>
    )
}


export default FileExportTest;