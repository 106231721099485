//@ts-nocheck

export const isClsfcSmryType = (value): boolean => {
    return (value === "12" || value === "22" || value === "32" || value === "42");
}

export const isClsfc = (type): boolean => {
    return type === "clsfc";
}

export const parseLabels = (original) => {
    return {
        labels: original.labels.map((label, index) => {
            let attributesItem = {};

            label.order =  label.id;

            attributesItem.id = label.id;
            attributesItem.required = label.attributes[0].required;

            if(isClsfc(label.type)){
                attributesItem.inputType = label.attributes[0].inputType;
                attributesItem.values = label?.attributes[0]?.values?.map(item => {
                    return item.text;
                });
            }else{
                attributesItem.inputType = "text";
                if(label.attributes[0].max){
                    if(label.attributes[0].max === "MAX"){
                        attributesItem.max = 1000;
                    }else{
                        attributesItem.max = parseInt(label.attributes[0].max, 10);
                    }
                }
                attributesItem.min = label.attributes[0].min ? parseInt(label.attributes[0].min, 10) : 0
            }

            return {
                ...label,
                attributes: [attributesItem]
            };
        })

    };
}