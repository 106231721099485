import React from 'react';
import { Link } from 'react-router-dom';
import { Col, Row } from 'reactstrap';

const BreadCrumb = (props) => {
    return (
        <React.Fragment>
            <Row>
                <Col xs={12}>
                    <div className="d-sm-flex align-items-center justify-content-between list-mg-b">
                        <h3 className="mb-sm-0 tx-20 text-coco-gray-700 fw-semibold">{props.title}</h3>

                        {/*<div className="page-title-right">
                            <ol className="breadcrumb m-0">
                                <li className="breadcrumb-item"><Link to="#">{pageTitle}</Link></li>
                                <li className="breadcrumb-item active">{title}</li>
                            </ol>
                        </div>*/}


                        {
                            // 조건부 렌더링 버튼
                            props.ButtonComponent !== undefined ? props.ButtonComponent() : null
                        }


                    </div>
                </Col>
            </Row>
        </React.Fragment>
    );
};

export default BreadCrumb;