import {Input, Label} from "reactstrap";
import React from "react";

interface Props {
    clientID: number;
    index: number;
    indexPrefix: string[];
    rsn: any;
}
export function ObjectReviewContent(props: Props) {
    const {clientID, index, indexPrefix, rsn} = props;
    const {cmmnDetailCd: rsnCd} = rsn;
    const inputID = `content-${clientID}-${index}`;
    return (
        <div key={rsnCd}>
            {indexPrefix + ' ' + rsn.cmmnDetailCdNm}
        </div>
    )
}
