import {GetMessage} from "util/message";
import React from "react";

{/*instanceTitle*/}
type InstanceTitleProps = {
    endTitle: string;
}
const InstanceHeader: React.FC<InstanceTitleProps> = ({endTitle}) => {

    return (
        <React.Fragment>
            <div className="d-flex align-items-center text-coco-gray-700 tab-header">
                <div className="wd-8p regionId">
                    #
                </div>
                <div className="wd-16p regionType text-center">
                    {GetMessage("ui.U00115")}
                </div>
                <div className="wd-56p text-left ps-2">
                    {GetMessage("ui.U00114")}
                </div>
                <div className="wd-20p end text-center mx-2">
                    {endTitle}
                </div>
            </div>
        </React.Fragment>
    )
}
export default InstanceHeader;

const TitlePanel = (props:any) => {
    return (
        <React.Fragment>
            <div className="d-flex align-items-center text-coco-gray-700 tx-14 px-4">
                {props.children}
            </div>
        </React.Fragment>
    )
}
