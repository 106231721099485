/* MODULE */
export const GET_MODULE = 'label/GET_MODULE';
export const GET_MODULE_SUCCESS = 'label/GET_MODULE_SUCCESS';
export const GET_MODULE_FAILED = 'label/GET_MODULE_FAILED';
export const CLOSE_MODULE = 'label/CLOSE_MODULE';
export const CLOSE_MODULE_SUCCESS = 'label/CLOSE_MODULE_SUCCESS';
export const CLOSE_MODULE_FAILED = 'label/CLOSE_MODULE_FAILED';
export const CHANGE_FRAME = 'label/CHANGE_FRAME';
export const CHANGE_FRAME_SUCCESS = 'label/CHANGE_FRAME_SUCCESS';
export const CHANGE_FRAME_FAILED = 'label/CHANGE_FRAME_FAILED';
export const SAVE_ANNOTATIONS = 'label/SAVE_ANNOTATIONS';
export const SAVE_ANNOTATIONS_SUCCESS = 'label/SAVE_ANNOTATIONS_SUCCESS';
export const SAVE_ANNOTATIONS_FAILED = 'label/SAVE_ANNOTATIONS_FAILED';
export const SUBMIT_ANNOTATIONS = 'label/SUBMIT_ANNOTATIONS';
export const SUBMIT_ANNOTATIONS_SUCCESS = 'label/SUBMIT_ANNOTATIONS_SUCCESS';
export const SUBMIT_ANNOTATIONS_FAILED = 'label/SUBMIT_ANNOTATIONS_FAILED';
export const SKIP_DATA = 'label/SKIP_DATA';
export const SKIP_DATA_SUCCESS = 'label/SKIP_DATA_SUCCESS';
export const SKIP_DATA_FAILED = 'label/SKIP_DATA_FAILED';
export const CHECK_VALIDATION = 'label/CHECK_VALIDATION';
export const CHECK_VALIDATION_SUCCESS= 'label/CHECK_VALIDATION_SUCCESS';
export const CONFIRM_CANVAS_READY = 'label/CONFIRM_CANVAS_READY';
export const DRAG_CANVAS = 'label/DRAG_CANVAS';
export const ZOOM_CANVAS = 'label/ZOOM_CANVAS';
export const SELECT_ISSUE_POSITION = 'label/SELECT_ISSUE_POSITION';
/* MODULE END */
/* SHAPE */
export const MERGE_OBJECTS = 'label/MERGE_OBJECTS';
export const GROUP_OBJECTS = 'label/GROUP_OBJECTS';
export const SPLIT_TRACK = 'label/SPLIT_TRACK';
export const COPY_SHAPE = 'label/COPY_SHAPE';
export const PASTE_SHAPE_ACTION = 'label/PASTE_SHAPE_ACTION';
export const PASTE_SHAPE = 'label/PASTE_SHAPE';
export const EDIT_SHAPE = 'label/EDIT_SHAPE';
export const REDRAW_SHAPE = 'label/REDRAW_SHAPE';
export const REPEAT_DRAW_SHAPE_ACTION = 'label/REPEAT_DRAW_SHAPE_ACTION';
export const REPEAT_DRAW_SHAPE = 'label/REPEAT_DRAW_SHAPE';
export const SHAPE_DRAWN = 'label/SHAPE_DRAWN';
export const RESET_CANVAS = 'label/RESET_CANVAS';
export const REMEMBER_OBJECT = 'label/REMEMBER_OBJECT';
export const LOAD_PREV_FILE_OBJECTS = 'label/LOAD_PREV_FILE_OBJECTS';
export const LOAD_PREV_FILE_OBJECTS_SUCCESS = 'label/LOAD_PREV_FILE_OBJECTS_SUCCESS';
export const LOAD_PREV_FILE_OBJECTS_FAILED = 'label/LOAD_PREV_FILE_OBJECTS_FAILED';
/* SHAPE END*/
/* ANNOTATIONS */
export const UPDATE_ANNOTATIONS = 'label/UPDATE_ANNOTATIONS';
export const UPDATE_ANNOTATIONS_SUCCESS = 'label/UPDATE_ANNOTATIONS_SUCCESS';
export const UPDATE_ANNOTATIONS_FAILED = 'label/UPDATE_ANNOTATIONS_FAILED';
export const CREATE_ANNOTATIONS = 'label/CREATE_ANNOTATIONS';
export const CREATE_ANNOTATIONS_SUCCESS = 'label/CREATE_ANNOTATIONS_SUCCESS';
export const CREATE_ANNOTATIONS_FAILED = 'label/CREATE_ANNOTATIONS_FAILED';
export const REMOVE_ANNOTATIONS = 'label/REMOVE_ANNOTATIONS';
export const REMOVE_ANNOTATIONS_SUCCESS = 'label/REMOVE_ANNOTATIONS_SUCCESS';
export const REMOVE_ANNOTATIONS_FAILED = 'label/REMOVE_ANNOTATIONS_FAILED';
export const MERGE_ANNOTATIONS = 'label/MERGE_ANNOTATIONS';
export const MERGE_ANNOTATIONS_SUCCESS = 'label/MERGE_ANNOTATIONS_SUCCESS';
export const MERGE_ANNOTATIONS_FAILED = 'label/MERGE_ANNOTATIONS_FAILED';
export const RESET_ANNOTATIONS_GROUP = 'label/RESET_ANNOTATIONS_GROUP';
export const GROUP_ANNOTATIONS = 'label/GROUP_ANNOTATIONS';
export const GROUP_ANNOTATIONS_SUCCESS = 'label/GROUP_ANNOTATIONS_SUCCESS';
export const GROUP_ANNOTATIONS_FAILED = 'label/GROUP_ANNOTATIONS_FAILED';
export const SPLIT_ANNOTATIONS = 'label/SPLIT_ANNOTATIONS';
export const SPLIT_ANNOTATIONS_SUCCESS = 'label/SPLIT_ANNOTATIONS_SUCCESS';
export const SPLIT_ANNOTATIONS_FAILED = 'label/SPLIT_ANNOTATIONS_FAILED';
export const GET_AUTO_LABEL_ANNOTATION = 'label/GET_AUTO_LABEL_ANNOTATION'
export const GET_AUTO_LABEL_ANNOTATION_SUCCESS = 'label/GET_AUTO_LABEL_ANNOTATION_SUCCESS'
export const GET_AUTO_LABEL_ANNOTATION_FAILED = 'label/GET_AUTO_LABEL_ANNOTATION_FAILED'
/* ANNOTATIONS END */
/* VIEW */
export const COLLAPSE_SIDEBAR = 'label/COLLAPSE_SIDEBAR';
export const COLLAPSE_APPEARANCE = 'label/COLLAPSE_APPEARANCE';
export const COLLAPSE_OBJECT_ITEMS = 'label/COLLAPSE_OBJECT_ITEMS';
export const READY_OBJECT = 'label/READY_OBJECT';
export const RELAX_OBJECT = 'label/RELAX_OBJECT';
export const ACTIVATE_OBJECT = 'label/ACTIVATE_OBJECT';
export const ACTIVATE_LABEL = 'label/ACTIVATE_LABEL';
export const REMOVE_OBJECT = 'label/REMOVE_OBJECT';
export const REMOVE_OBJECT_SUCCESS = 'label/REMOVE_OBJECT_SUCCESS';
export const REMOVE_OBJECT_FAILED = 'label/REMOVE_OBJECT_FAILED';
export const PROPAGATE_OBJECT_SUCCESS = 'label/PROPAGATE_OBJECT_SUCCESS';
export const PROPAGATE_OBJECT_FAILED = 'label/PROPAGATE_OBJECT_FAILED';
export const SWITCH_PROPAGATE_VISIBILITY = 'label/SWITCH_PROPAGATE_VISIBILITY';
export const SWITCH_SHOWING_STATISTICS = 'label/SWITCH_SHOWING_STATISTICS';
export const SWITCH_SHOWING_FILTERS = 'label/SWITCH_SHOWING_FILTERS';
export const COLLECT_STATISTICS = 'label/COLLECT_STATISTICS';
export const COLLECT_STATISTICS_SUCCESS = 'label/COLLECT_STATISTICS_SUCCESS';
export const COLLECT_STATISTICS_FAILED = 'label/COLLECT_STATISTICS_FAILED';
export const UPLOAD_MODULE_ANNOTATIONS = 'label/UPLOAD_MODULE_ANNOTATIONS';
export const UPLOAD_MODULE_ANNOTATIONS_SUCCESS = 'label/UPLOAD_MODULE_ANNOTATIONS_SUCCESS';
export const UPLOAD_MODULE_ANNOTATIONS_FAILED = 'label/UPLOAD_MODULE_ANNOTATIONS_FAILED';
export const REMOVE_MODULE_ANNOTATIONS_SUCCESS = 'label/REMOVE_MODULE_ANNOTATIONS_SUCCESS';
export const REMOVE_MODULE_ANNOTATIONS_FAILED = 'label/REMOVE_MODULE_ANNOTATIONS_FAILED';
export const UPDATE_CANVAS_CONTEXT_MENU = 'label/UPDATE_CANVAS_CONTEXT_MENU';
export const UPDATE_CANVAS_CONTEXT_MENU_POSITION = 'label/UPDATE_CANVAS_CONTEXT_MENU_POSITION';
export const UPDATE_CANVAS_SKIP_POPOVER = 'label/UPDATE_CANVAS_SKIP_POPOVER';
export const UPDATE_CANVAS_SKIP_RSN = 'label/UPDATE_CANVAS_SKIP_RSN';
export const UPDATE_CANVAS_EDIT_IMAGE_POPOVER = 'label/UPDATE_CANVAS_EDIT_IMAGE_POPOVER';
export const UNDO_ACTION = 'label/UNDO_ACTION';
export const UNDO_ACTION_SUCCESS = 'label/UNDO_ACTION_SUCCESS';
export const UNDO_ACTION_FAILED = 'label/UNDO_ACTION_FAILED';
export const REDO_ACTION = 'label/REDO_ACTION';
export const REDO_ACTION_SUCCESS = 'label/REDO_ACTION_SUCCESS';
export const REDO_ACTION_FAILED = 'label/REDO_ACTION_FAILED';
export const CHANGE_ANNOTATIONS_FILTERS = 'label/CHANGE_ANNOTATIONS_FILTERS';
export const FETCH_ANNOTATIONS = 'label/FETCH_ANNOTATIONS';
export const FETCH_ANNOTATIONS_SUCCESS = 'label/FETCH_ANNOTATIONS_SUCCESS';
export const FETCH_ANNOTATIONS_FAILED = 'label/FETCH_ANNOTATIONS_FAILED';
export const ROTATE_FRAME = 'label/ROTATE_FRAME';
export const SWITCH_Z_LAYER = 'label/SWITCH_Z_LAYER';
export const ADD_Z_LAYER = 'label/ADD_Z_LAYER';
export const CHANGE_GROUP_COLOR = 'label/CHANGE_GROUP_COLOR';
export const SEARCH_ANNOTATIONS_FAILED = 'label/SEARCH_ANNOTATIONS_FAILED';
export const SEARCH_EMPTY_FRAME_FAILED = 'label/SEARCH_EMPTY_FRAME_FAILED';
export const CHANGE_WORKSPACE = 'label/CHANGE_WORKSPACE';
export const SAVE_LOGS = 'label/SAVE_LOGS';
export const SAVE_LOGS_SUCCESS = 'label/SAVE_LOGS_SUCCESS';
export const SAVE_LOGS_FAILED = 'label/SAVE_LOGS_FAILED';
export const INTERACT_WITH_CANVAS = 'label/INTERACT_WITH_CANVAS';
export const GET_DATA_FAILED = 'label/GET_DATA_FAILED';
export const SET_CANVAS_DATA_TY = 'label/SET_CANVAS_DATA_TY';
export const SET_FORCE_EXIT_ANNOTATION_PAGE_FLAG = 'label/SET_FORCE_EXIT_ANNOTATION_PAGE_FLAG';
export const UPDATE_PREDICTOR_STATE = 'label/UPDATE_PREDICTOR_STATE';
export const GET_PREDICTIONS = 'label/GET_PREDICTIONS';
export const GET_PREDICTIONS_FAILED = 'label/GET_PREDICTIONS_FAILED';
export const GET_PREDICTIONS_SUCCESS = 'label/GET_PREDICTIONS_SUCCESS';
export const SWITCH_NAVIGATION_BLOCKED = 'label/SWITCH_NAVIGATION_BLOCKED';
export const DELETE_FRAME = 'label/DELETE_FRAME';
export const DELETE_FRAME_SUCCESS = 'label/DELETE_FRAME_SUCCESS';
export const DELETE_FRAME_FAILED = 'label/DELETE_FRAME_FAILED';
export const RESTORE_FRAME = 'label/RESTORE_FRAME';
export const RESTORE_FRAME_SUCCESS = 'label/RESTORE_FRAME_SUCCESS';
export const RESTORE_FRAME_FAILED = 'label/RESTORE_FRAME_FAILED';
export const UPDATE_BRUSH_TOOLS_CONFIG = 'label/UPDATE_BRUSH_TOOLS_CONFIG';
export const UPDATE_SIDEBAR_TAB = 'label/UPDATE_SIDEBAR_TAB';
export const SWITCH_SHORTCUTS = 'label/SWITCH_SHORTCUTS';
/* VIEW END */
/* PLAYER */
export const SWITCH_PLAY = 'label/SWITCH_PLAY';
export const UPDATE_DURATION = 'label/UPDATE_DURATION';

export const UPDATE_INTERNAL_PLAYER = 'label/UPDATE_INTERNAL_PLAYER';

/* PLAYER END*/
