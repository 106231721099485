// @ts-nocheck
import React, {useEffect, useRef, useState} from "react";
import { useSelector } from "react-redux";
import SimpleBar from "simplebar-react";


export const TextCanvas = () => {
    const canvasRef = useRef();
    const text = useSelector(state => state.Label.Cmmn.frames.frame.data);
    return (
        <>
            <div className={"text-center"}>
                <SimpleBar style={{maxHeight: "calc(100vh - 70px)"}} autoHide={false} className="table-responsive simplebar-track-coco">
                    <div className={"bg-coco-default-white wd-60p pd-x-96 pd-y-113 mt-28"} style={{ minHeight: "300px", margin: "0 auto"}}>
                        <pre style={{textAlign:'left', whiteSpace:'pre-wrap', overflow:'auto', fontFamily:'Pretendard', fontSize:'16px'}}>{text}</pre>
                    </div>
                </SimpleBar>
            </div>
        </>
    )
}

