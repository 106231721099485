import {all, put, take, takeEvery, takeLatest} from "redux-saga/effects";
import {getSavedReviewResult} from "../../../pages/user/label/annotation/image/work/service";
import {resetReviewStatesSuccess} from "../action";
import {LABEL_TYPE, MD_STTUS_CD} from "../../../pages/user/label/annotation/image/const";
import {CHANGE_FRAME_SUCCESS} from "../../label/actionType";

/**
 * 이전 검수 결과를 가져온다.
 * @param action
 * @returns {Generator<Promise<any>|*, void, *>}
 */
function* getReviewStateAsync(action){
	const {module, frame} = action.payload;
	if (module.labelType !== LABEL_TYPE.CLASSIFICATION_SUMMARY) return;
	const moduleId = module.id;
	const result = yield getSavedReviewResult({moduleId: moduleId, datasetId:frame.datasetId, fileSeq:frame.fileSeq});

	if (result.errorMessage) {
		// todo 에러 처리해주기
	} else {
		const newReviewData = result.data ? result.data.issueCnObj : null; // 이전 검수 결과
		const moduleStatus = newReviewData ? MD_STTUS_CD.RE : MD_STTUS_CD.FIRST;
		yield put(resetReviewStatesSuccess(newReviewData, moduleStatus));
	}
}

function* watchChangeFrameSuccess() {
	yield takeLatest(CHANGE_FRAME_SUCCESS, getReviewStateAsync)
}

function* ReviewClsfcSmrySaga() {
	yield all([
		watchChangeFrameSuccess()
	])
}

export default ReviewClsfcSmrySaga;
