import {
  INIT_MANAGE_WORKFLOW_REVIEW_MODULE_CONFIG,
  SET_MANAGE_WORKFLOW_REVIEW_BASE_CONFIG,
  SET_MANAGE_WORKFLOW_REVIEW_REVIEW_CONFIG,
  SET_MANAGE_WORKFLOW_REVIEW_WORKER_CONFIG,
  SET_MANAGE_WORKFLOW_REVIEW_POINT_CONFIG,
  SET_MANAGE_WORKFLOW_REVIEW_DATA_CONFIG,
  SET_MANAGE_WORKFLOW_REVIEW_TEST_CONFIG,
  SWITCH_MANAGE_WORKFLOW_REVIEW_REGIST_DATA_REFRESH_FLAG,
  SET_MANAGE_WORKFLOW_REVIEW_VIEW_TYPE,
  SET_MANAGE_WORKFLOW_REVIEW_INACTIVE_DATA,
  SET_MANAGE_WORKFLOW_REVIEW_FILES_RESET,
  SET_MANAGE_WORKFLOW_REVIEW_FILES,
  SET_MANAGE_WORKFLOW_REVIEW_DATA_ERROR,
  UPDATE_MANAGE_WORKFLOW_REVIEW_EDITOR_CN_BY_ID,
  SET_MANAGE_WORKFLOW_REVIEW_SELECTED_ROW_ID,
  SET_MANAGE_WORKFLOW_REVIEW_ACTIVE_DATA,
  SET_MANAGE_WORKFLOW_REVIEW_TARGET_LABEL_BIND_CONFIG,
  RESET_MANAGE_WORKFLOW_REVIEW_MODULE_CONFIG, SET_SHAPE_CONFIG
} from './actionType';

const INIT_STATE = {
  baseConfig: null,
  reviewConfig: null,
  workerConfig: null,
  orderConfig: null,
  pointConfig: null,
  dataConfig: null,
  testConfig: null,
  bindConfig: null,
  shapeConfig: null,
  /**
   * ReviewSetting
   */
  reviewWorkerConfig: [],
  workerGroupItems:[],
  workerConditionItems:[],
  /**
   * WorkerSetting
   */
  reviewDataset:[],
  reviewGuide:null,
  error: {},
  /*
  * registerData
  * */
  activeData : [],
  inactiveData : [],
  viewType : "",
  selectedId : "",
  errorData: [],
  files: [],
  refreshFlag: false,
  initFlag: false,
};

const ManageWorkflowReview = (state = INIT_STATE, action) => {
  switch (action.type) {
    case INIT_MANAGE_WORKFLOW_REVIEW_MODULE_CONFIG:
      return {
        ...state
        ,baseConfig: action.payload.baseConfig? action.payload.baseConfig: null
        ,reviewConfig: action.payload.reviewConfig? action.payload.reviewConfig: null
        ,workerConfig: action.payload.workerConfig? action.payload.workerConfig: null
        ,pointConfig: action.payload.pointConfig? action.payload.pointConfig: null
        ,dataConfig: action.payload.dataConfig? action.payload.dataConfig: []
        ,testConfig: action.payload.testConfig? action.payload.testConfig: null
        ,tabActive: action.payload.tabActive? action.payload.tabActive: null
        ,initFlag: true
      };
    case RESET_MANAGE_WORKFLOW_REVIEW_MODULE_CONFIG:
      return {
        ...state
        ,baseConfig: null
        ,reviewConfig: null
        ,workerConfig: null
        ,pointConfig: null
        ,dataConfig: []
        ,testConfig: null
        ,tabActive: null
        ,initFlag: false
      }
    case SET_MANAGE_WORKFLOW_REVIEW_BASE_CONFIG:
      return {
        ...state,
        baseConfig:action.payload,
      };
    case SET_MANAGE_WORKFLOW_REVIEW_REVIEW_CONFIG:
      return {
        ...state,
        reviewConfig:action.payload,
      };
    case SET_MANAGE_WORKFLOW_REVIEW_WORKER_CONFIG:
      return {
        ...state,
        workerConfig:action.payload,
      };
    case SET_MANAGE_WORKFLOW_REVIEW_POINT_CONFIG:
      return {
        ...state,
        pointConfig:action.payload,
      };
    case SET_MANAGE_WORKFLOW_REVIEW_DATA_CONFIG:
      return {
        ...state,
        dataConfig:action.payload,
      };
    case SET_MANAGE_WORKFLOW_REVIEW_TEST_CONFIG:
      return {
        ...state,
        testConfig:action.payload,
      };
    case SET_MANAGE_WORKFLOW_REVIEW_ACTIVE_DATA:
      return{
        ...state,
        activeData: [...action.payload]
      }
    case SET_MANAGE_WORKFLOW_REVIEW_INACTIVE_DATA:
      return{
        ...state,
        inactiveData: [...action.payload]
      }
    case SET_MANAGE_WORKFLOW_REVIEW_VIEW_TYPE:
      return{
        ...state,
        viewType: action.payload
      }
    case SET_MANAGE_WORKFLOW_REVIEW_SELECTED_ROW_ID:
      return{
        ...state,
        selectedId: action.payload
      }
    case SET_MANAGE_WORKFLOW_REVIEW_DATA_ERROR:
      return{
        ...state,
        errorData: action.payload
      }
    case UPDATE_MANAGE_WORKFLOW_REVIEW_EDITOR_CN_BY_ID:
      return{
        ...state,

      }
    case SET_MANAGE_WORKFLOW_REVIEW_FILES:
      return {
        ...state,
        files: {
          ...state.files,
          [action.payload.id]: action.payload.files,
        },
      };
    case SET_MANAGE_WORKFLOW_REVIEW_FILES_RESET:
      return {
        ...state,
        files: []
      }
    case SWITCH_MANAGE_WORKFLOW_REVIEW_REGIST_DATA_REFRESH_FLAG:
      return{
        ...state,
        refreshFlag: !state.refreshFlag
      };
    case SET_MANAGE_WORKFLOW_REVIEW_TARGET_LABEL_BIND_CONFIG:
      return{
        ...state,
        bindConfig: action.payload.config
      }
    case SET_SHAPE_CONFIG :
      return {
        ...state,
        shapeConfig: action.payload
      };
    /*case SET_MANAGE_WORKFLOW_REVIEW_WORKER_CONDITION_EMAILS:
      return {
        ...state,
        workerConfig:{
          ...state.workerConfig,
          conditionEmails: action.payload
        }
      };

    case SET_MANAGE_WORKFLOW_REVIEW_WORKER_GROUP_ITEMS:
      return {
        ...state,
        workerConfig:{
          ...state.workerConfig,
          groupConditions: action.payload
        },
        workerGroupItems:action.payload,
      };

    case ADD_MANAGE_WORKFLOW_REVIEW_WORKER_GROUP_ITEM:
      return {
        ...state,
        workerConfig:{
          ...state.workerConfig,
          groupConditions: [
            ...state.workerConfig.groupConditions,
            action.payload
          ]
        },
        workerGroupItems:[...state.workerGroupItems, action.payload]
      };

    case UPDATE_MANAGE_WORKFLOW_REVIEW_WORKER_GROUP_ITEM:
      return {
        ...state,
        workerConfig:{
          ...state.workerConfig,
          groupConditions: state.workerConfig.groupConditions.map(item =>
              item.id === action.payload.groupId?{...item, ...action.payload.item}
                  :item
          )
        },
        workerGroupItems: state.workerGroupItems.map(item =>
            item.id === action.payload.groupId?{...item, ...action.payload.item}
                :item)

      };

    case DELETE_MANAGE_WORKFLOW_REVIEW_WORKER_GROUP_ITEM:
      return{
        ...state,
        workerConfig:{
          ...state.workerConfig,
          groupConditions: state.workerConfig.groupConditions.filter(item =>
              item.id !== action.payload
          )
        },
        workerGroupItems: state.workerGroupItems.filter(
            item => item.id !== action.payload
        )
      };

    case RESET_MANAGE_WORKFLOW_REVIEW_WORKER_GROUP :
      return {
        ...state,
        workerConfig:{
          ...state.workerConfig,
          groupConditions: []
        },
        workerGroupItems: []
      }

    case SET_MANAGE_WORKFLOW_REVIEW_WORKER_CONDITION_ITEMS:
      return{
        ...state,
        workerConfig:{
          ...state.workerConfig,
          groupConditions: state.workerConfig.groupConditions.map(item =>
              item.id === action.payload.groupId?{...item, conditions:action.payload.items}
                  :item
          )
        },
        workerGroupItems: state.workerGroupItems.map(item =>
            item.id === action.payload.groupId?{...item, conditions:action.payload.items}
                :item
        )
      };

    case ADD_MANAGE_WORKFLOW_REVIEW_WORKER_CONDITION_ITEM:
      return{
        ...state,
        workerConfig:{
          ...state.workerConfig,
          groupConditions: state.workerConfig.groupConditions.map(item =>
              item.id === action.payload.groupId?{...item, conditions: [...item.conditions, action.payload.item]}
                  :item
          )
        },
        workerGroupItems: state.workerGroupItems.map(item =>
            item.id === action.payload.groupId?{...item, conditions: [...item.conditions, action.payload.item]}
                :item
        )
      };

    case UPDATE_MANAGE_WORKFLOW_REVIEW_WORKER_CONDITION_ITEM:
      return {
        ...state,
        workerConfig:{
          ...state.workerConfig,
          groupConditions: state.workerConfig.groupConditions.map(item =>
              item.id === action.payload.groupId?{...item, conditions: item.conditions.map(
                    condition => condition.id === action.payload.conditionId?  action.payload.item : condition
                )}:item
          )
        },
        workerGroupItems: state.workerGroupItems.map(item =>
            item.id === action.payload.groupId?{...item, conditions: item.conditions.map(
                      condition => condition.id === action.payload.conditionId?  action.payload.item : condition
                  )}
                :item
        )
      };

    case DELETE_MANAGE_WORKFLOW_REVIEW_WORKER_CONDITION_ITEM:
      console.log(action.payload);
      return{
        ...state,
        workerConfig:{
          ...state.workerConfig,
          groupConditions: state.workerConfig.groupConditions.map(item =>
              item.id === action.payload.groupId?{...item, conditions: item.conditions.filter(
                    attr => attr.id != action.payload.deleteConditionId)}:item
          )
        },
        workerGroupItems: state.workerGroupItems.map(item =>
            item.id === action.payload.groupId?{...item, conditions: item.conditions.filter(
                  attr => attr.id != action.payload.deleteConditionId)}:item
        )
      }*/

    default:
      return state;
  }
};

export default ManageWorkflowReview;
