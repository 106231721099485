import React from 'react';
import {CustomHandleView} from "./CustomHandleView";

export const CustomHandle = (props) => {
    const { direction, position, onMouseDown, onTouchEnd, onMouseUp, innerRef } = props;

    return (
        <div
            ref={innerRef}
            onMouseUp={onMouseUp}
            onMouseDown={onMouseDown}
            onTouchEnd={onTouchEnd}
        >
            <CustomHandleView position={position} direction={direction}/>
        </div>
    )
}
