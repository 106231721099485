// label Type
export const SET_MANAGE_LABEL_ID                       = "SET_MANAGE_LABEL_ID";
export const SET_MANAGE_LABEL_SHAPE_CONFIG                       = "SET_MANAGE_LABEL_SHAPE_CONFIG";
export const GET_MANAGE_LABEL_SHAPE_CONFIG                       = "GET_MANAGE_LABEL_SHAPE_CONFIG";
export const SET_MANAGE_LABEL_DATASET                  = "SET_MANAGE_LABEL_DATASET";
export const GET_MANAGE_LABEL_DATASET                  = "GET_MANAGE_LABEL_DATASET";
export const UPDATE_MANAGE_LABEL_DATASET               = "UPDATE_MANAGE_LABEL_DATASET";
export const SET_MANAGE_LABEL_GUIDE                    = "SET_MANAGE_LABEL_GUIDE";
export const GET_MANAGE_LABEL_GUIDE                    = "GET_MANAGE_LABEL_GUIDE";
export const SET_MANAGE_LABEL_CLASS_ITEMS              = "SET_MANAGE_LABEL_CLASS_ITEMS";
export const GET_MANAGE_LABEL_CLASS_ITEMS              = "GET_MANAGE_LABEL_CLASS_ITEMS";
export const ADD_MANAGE_LABEL_CLASS_ITEM            = "ADD_MANAGE_LABEL_CLASS_ITEM";
export const UPDATE_MANAGE_LABEL_CLASS_ITEM            = "UPDATE_MANAGE_LABEL_CLASS_ITEM";
export const DELETE_MANAGE_LABEL_CLASS_ITEM            = "DELETE_MANAGE_LABEL_CLASS_ITEM";
export const SET_MANAGE_LABEL_ATTR_LIST                = "SET_MANAGE_LABEL_ATTR_LIST";
export const GET_MANAGE_LABEL_ATTR_LIST                = "GET_MANAGE_LABEL_ATTR_LIST";
export const SET_MANAGE_LABEL_ATTR_ITEMS               = "SET_MANAGE_LABEL_ATTR_ITEMS";
export const ADD_MANAGE_LABEL_ATTR_ITEM                = "ADD_MANAGE_LABEL_ATTR_ITEM";
export const UPDATE_MANAGE_LABEL_ATTR_ITEM             = "UPDATE_MANAGE_LABEL_ATTR_ITEM";
export const DELETE_MANAGE_LABEL_ATTR_ITEM             = "DELETE_MANAGE_LABEL_ATTR_ITEM";
export const SET_SELECTED_MANAGE_LABEL_CLASS_ID        = "SET_SELECTED_MANAGE_LABEL_CLASS_ID";
export const SET_SELECTED_MANAGE_LABEL_ATTR_ID         = "SET_SELECTED_MANAGE_LABEL_ATTR_ID";
export const SET_SELECTED_MANAGE_LABEL_ATTR_ITEM_ID    = "SET_SELECTED_MANAGE_LABEL_ATTR_ITEM_ID";
