import {Card, CardBody, CardHeader, Col, Row} from "reactstrap";
import {GetMessageIntl} from "../../../../util/message";
import ReactECharts from 'echarts-for-react';
import Select from "react-select";
import {noBorderSelectStyle} from "../../../../components/constants/select";
import React, {useCallback, useEffect, useRef, useState} from "react";
import SimpleBar from "simplebar-react";
import moduleReviewImg from "../../../../assets/images/task/module_review.png";
import ReactFlow, {addEdge, Controls, MarkerType, ReactFlowProvider, useEdgesState, useNodesState, useReactFlow} from 'reactflow';
import CustomEdge from "../../../test/CustomEdge";
import {useIntl} from "react-intl";
import {useDispatch, useSelector} from "react-redux";
import {DashboardService} from "../../../../apis/api/DashboardService";
import {ManagerWorkflowService} from "../../../../apis/api/ManagerWorkflowService";
import DatasetNode from "./nodes/DatasetNode";
import ReviewNode from "./nodes/ReviewNode";
import FinishNode from "./nodes/FinishNode";
import LabelNode from "./nodes/LabelNode";
import FloatingEdge from "../workflow/components/edges/FloatingEdge";
import workflowNode from "./nodes/WorkFlowNode";
import SDQNode from "../workflow/components/nodes/SDQNode";
import AutoLabelNode from "./nodes/AutoLabelNode";
import CrossReviewNode from "./nodes/CrossReviewNode";
import ManagerReviewNode from "./nodes/ManagerReviewNode";
import AcceptNode from "./nodes/sub/AcceptNode";
import UnabledNode from "./nodes/UnabledNode";
import SubUnabledNode from "./nodes/sub/SubUnabledNode";
import RestoreNode from "./nodes/sub/RestoreNode";
import SubmitNode from "./nodes/sub/SubmitNode";
import SkipNode from "./nodes/sub/SkipNode";
import LabeledNode from "./nodes/sub/LabeledNode";
import ApprovalNode from "./nodes/sub/ApprovalNode";
import RejectNode from "./nodes/sub/RejectNode";
import AmbiguityNode from "./nodes/sub/AmbiguityNode";
import StateChangeNode from "./nodes/sub/StateChangeNode";
import UpdateSubmitNode from "./nodes/sub/UpdateSubmitNode";
import {setDashboardWorkflowColumn04} from "../../../../store/dashboard/action";
import {ModuleService} from "../../../../apis/api/ModuleService";


function WorkflowDashboard(prop) {
    const intl = useIntl();
    const dispatch = useDispatch();
    const {iconStyle, wrkflowId, canvasDataTy} = prop;
    const {
        prjctId,
    } = useSelector((state) => ({
        prjctId: state.Manage.prjctId,
    }));

    const column05Ref = useRef();
    const column06Ref = useRef();
    const column07Ref = useRef();
    const column08Ref = useRef();

    const [clicked, setClicked] = useState(false);
    const [clickedItem, setClickedItem] = useState(null);

    const [workflowFirstRow, setWorkflowFirstRow] = useState({
        count01: 0
        , count02: 0
        , count03: 0
        , count04: 0
        , count04Distinct: 0
    });

    const [workersByTaskData, setWorkersByTaskData] = useState([]);
    const [workflowProgressList, setWorkflowProgressList] = useState([]);

    const [classInstanceData, setClassInstanceData] = useState({});
    const [workSkipReasonData, setWorkSkipReasonData] = useState({});
    const [rejectReasonData, setRejectReasonData] = useState({});
    const [rejectReasonByClassData, setRejectReasonByClassData] = useState({});
    const [workflowTaskStatusData, setWorkflowTaskStatusData] = useState({});

    const [pieChartData, setPieChartData] = useState({});
    const [classInstanceOption, setClassInstanceOption] = useState({});
    const [workSkipReasonOption, setWorkSkipReasonOption] = useState({});
    const [pieChartOption, setPieChartOption] = useState({});
    const [column07ChartOption, setColumn07ChartOption] = useState({});
    const [column08ChartOption, setColumn08ChartOption] = useState({});
    const [column09ChartOption, setColumn09ChartOption] = useState({});
    const [workProgressStackOptions, setWorkProgressStackOptions] = useState({});

    const [complateCount, setComplateCount] = useState(0);
    const [finishCount, setFinishCount] = useState(0);
    const [finishPercent, setFinishPercent] = useState(0);
    const [complatePercent, setComplatePercent] = useState(0);


    const [column05LabelTaskOption, setColumn05LabelTaskOption] = useState([]);
    const [column06LabelTaskOption, setColumn06LabelTaskOption] = useState([{label: "No Data", value: "-"}]);
    const [column07LabelTaskOption, setColumn07LabelTaskOption] = useState([{label: "No Data", value: "-"}]);
    const [column08LabelTaskOption, setColumn08LabelTaskOption] = useState([{label: "No Data", value: "-"}]);

    useEffect(() => {
        setColumn05LabelTaskOption([]);
        setColumn06LabelTaskOption([]);
        setColumn07LabelTaskOption([]);
        setColumn08LabelTaskOption([]);
        setClicked(false);
        setColumn09ChartOption(getColumn09ChartOption([]))
        DashboardService.workflowDashboardColumnAll({wrkflowId: wrkflowId})
            .then(res => {
                if (res.data.data != null) {
                    setWorkflowFirstRow(JSON.parse(res.data.data.column01));
                    setWorkflowProgressList(JSON.parse(res.data.data.column02));
                    setWorkersByTaskData(JSON.parse(res.data.data.column03));
                    setClassInstanceData(JSON.parse(res.data.data.column05));
                    setWorkSkipReasonData(JSON.parse(res.data.data.column06));
                    setRejectReasonData(JSON.parse(res.data.data.column07));
                    setRejectReasonByClassData(JSON.parse(res.data.data.column08));
                    setWorkflowTaskStatusData(JSON.parse(res.data.data.column04));
                    const col10 = JSON.parse(res.data.data.column10);
                    setPieChartData(col10.chartData);

                    let summaryData = col10.summaryData;
                    setComplateCount(summaryData.complete);
                    setFinishCount(summaryData.complete + summaryData.confirm);
                    setComplatePercent(summaryData.total == 0 ? 0 : (summaryData.complete / summaryData.total * 100).toFixed(1));
                    setFinishPercent(summaryData.total == 0 ? 0 : ((summaryData.complete + summaryData.confirm) / summaryData.total * 100).toFixed(1));
                }
            });

        if (wrkflowId) {
            DashboardService.workflowWorkTrendLineChartData({wrkflowId: wrkflowId})
                .then(res => {
                    if (res.data.data != null) {
                        setWorkProgressStackOptions(getWorkProgressStackOptions(res.data.data));
                    }
                });
        }
    }, [wrkflowId]);

    useEffect(() => {
        dispatch(setDashboardWorkflowColumn04(workflowTaskStatusData));
    }, [workflowTaskStatusData]);

    useEffect(() => {
        if (classInstanceData && classInstanceData.hasOwnProperty('labelTaskList')) {
            if (column05Ref.current.getValue().length > 0) {
                const moduleId = column05Ref.current.getValue()[0].value;
                const data = classInstanceData[moduleId];
                if (data) {
                    setClassInstanceOption(getClassInstanceOptions(data.axis, data.data, data.rate));
                }
            }
        }
    }, [column05LabelTaskOption]);

    //   작업 스킵 사유 분포
    useEffect(() => {
        if (workSkipReasonData && workSkipReasonData.hasOwnProperty('labelTaskList')) {
            if (column06Ref.current.getValue().length > 0) {
                const moduleId = column06Ref.current.getValue()[0].value;
                const data = workSkipReasonData[moduleId];
                if (data) {
                    setWorkSkipReasonOption(getSkipReasonOption(data));
                }
            }
        }
    }, [column06LabelTaskOption]);


    //반려 사유 분포
    useEffect(() => {
        if (rejectReasonData && rejectReasonData.hasOwnProperty('labelTaskList') && rejectReasonData.hasOwnProperty('chartData')) {
            if (column07Ref.current.getValue().length > 0) {
                const moduleId = column07Ref.current.getValue()[0].value;
                const data = rejectReasonData.chartData[moduleId];
                if (data) {
                    setColumn07ChartOption(getColumn07ChartOption(data));
                }
            }
        }
    }, [column07LabelTaskOption]);

    // 클래스별 인스턴스 반려 개수 및 반려사유
    useEffect(() => {
        // 이미지 어노테이션
        if (canvasDataTy === '11') {
            if (rejectReasonByClassData && rejectReasonByClassData.hasOwnProperty('labelTaskList') && rejectReasonData.hasOwnProperty('chartData')) {
                if (column08Ref.current.getValue().length > 0) {
                    const moduleId = column08Ref.current.getValue()[0].value;
                    const data = rejectReasonByClassData.chartData[moduleId];
                    if (data) {
                        setColumn08ChartOption(getColumn08ChartOption(data))
                    }
                }
            }
        }else{
            // 요약, 분류
        }
    }, [column08LabelTaskOption]);

    useEffect(() => {
        if (classInstanceData && classInstanceData.hasOwnProperty('labelTaskList')) {
            setColumn05LabelTaskOption(classInstanceData['labelTaskList'].map(item => {
                return {label: item.taskNm, value: item.moduleId}
            }));
        }
    }, [classInstanceData])

    useEffect(() => {
        if (workSkipReasonData && workSkipReasonData.hasOwnProperty('labelTaskList')) {
            setColumn06LabelTaskOption(workSkipReasonData['labelTaskList'].map(item => {
                return {label: item.taskNm, value: item.moduleId}
            }));
        }
    }, [workSkipReasonData])

    useEffect(() => {
        if (rejectReasonData && rejectReasonData.hasOwnProperty('labelTaskList')) {
            setColumn07LabelTaskOption(rejectReasonData['labelTaskList'].map(item => {
                return {label: item.taskNm, value: item.moduleId}
            }));
        }
    }, [rejectReasonData])

    useEffect(() => {
        if (rejectReasonByClassData && rejectReasonByClassData.hasOwnProperty('labelTaskList')) {
            setColumn08LabelTaskOption(rejectReasonByClassData['labelTaskList'].map(item => {
                return {label: item.taskNm, value: item.moduleId}
            }));
        }
    }, [rejectReasonByClassData])

    useEffect(() => {
        const data = getPieChartOption(pieChartData);
        setPieChartOption(data);
    }, [pieChartData])

    useEffect(() => {
    }, [clickedItem])


    const selectChangeHandler05 = (e) => {
        const data = classInstanceData[e.value];
        if (data) {
            setClassInstanceOption(getClassInstanceOptions(data.axis, data.data, data.rate));
        }
    }
    const selectChangeHandler06 = (e) => {
        const data = workSkipReasonData[e.value];
        if (data) {
            setWorkSkipReasonOption(getSkipReasonOption(data));
        }
    }

    const selectChangeHandler07 = (e) => {
        console.log(e)
    }

    const selectChangeHandler08 = (e) => {
        console.log(e)
    }

    const onChartClick = (params, chart) => {
        setClicked(true);
        setClickedItem(params.name)
        // 클래스별 인스턴스 반려 개수 chart bar 클릭했을때 수행

        const moduleId = column08Ref.current.getValue()[0].value;
        const data = rejectReasonByClassData.chartData[moduleId];
        const tClkItem = data.filter(item => (item.name == params.name));
        console.log(tClkItem);
        if (tClkItem.length > 0) {
            setColumn09ChartOption(getColumn09ChartOption(tClkItem[0].subChartData));
        }
    }

    let base = +new Date(2023, 1, 1);
    let oneDay = 24 * 3600 * 1000;
    let data = [[base, Math.random() * 100]];
    let data2 = [[base, Math.random() * 100]];
    for (let i = 1; i < 100; i++) {
        let now = new Date((base += oneDay));
        data.push([+now, Math.round((Math.random() + 0.5) * 20 + data[i - 1][1])]);
        data2.push([+now, Math.round((Math.random() + 0.5) * 40 + data2[i - 1][1])]);
    }


    const pointSum1 = workflowProgressList.reduce((a, b) => {
        return eval(a) + eval(b.col01)
    }, 0);
    const pointSum2 = workflowProgressList.reduce((a, b) => {
        return eval(a) + eval(b.col02)
    }, 0);
    const pointSum3 = workflowProgressList.reduce((a, b) => {
        return eval(a) + eval(b.col03)
    }, 0);
    const pointSum4 = workflowProgressList.reduce((a, b) => {
        return eval(a) + eval(b.col04)
    }, 0);


    return (
        <>
            <Row className="row-cols-xxl-5 row-cols-lg-3 row-cols-1" style={{minWidth: "1580px"}}>
                <Col xxl={8}>
                    <Row className="ht-150">
                        <Col xxl={4}><TopCardComponent title={GetMessageIntl(intl, "dashboard.datasetCount")} count={workflowFirstRow.count01} icon={"ri-database-2-line"} iconStyle={iconStyle}/></Col>
                        <Col xxl={4}><TopCardComponent title={GetMessageIntl(intl, "dashboard.folderCount")} count={workflowFirstRow.count02} icon={"ri-folder-line"} iconStyle={iconStyle}/></Col>
                        <Col xxl={4}><TopCardComponent title={GetMessageIntl(intl, "dashboard.fileCount")} count={workflowFirstRow.count03} icon={"ri-file-4-line"} iconStyle={iconStyle}/></Col>
                    </Row>
                    <Row className="ht-400 mt-24">
                        <Col xxl={12} style={{borderRadius: "7px"}}>
                            <Card className="shadow-none h-100 mb-0" style={{borderRadius: "7px", border: "1px solid #CFD8DC"}}>
                                <CardBody>
                                    <Row>
                                        <Col xxl={12} className="pd-t-5">
                                            <i className="ri-sword-line" style={iconStyle}></i>&nbsp;&nbsp;&nbsp;<span className="text-coco-gray-600 fw-semibold tx-16">{GetMessageIntl(intl, "dashboard.workflowTaskBoxTitle")}</span>
                                        </Col>
                                    </Row>
                                    <Row className="ht-280">
                                        {/*태스크별 상태 및 포인트 지급 현황*/}
                                        <Col xxl={12} style={{paddingRight: "0px"}}>
                                            <Row className="mt-10" style={{height: "44px"}}>
                                                <Col xxl={12} style={{maxWidth: "99%", backgroundColor: "#F3F6F9"}}>
                                                    <Row style={{height: "inherit"}}>
                                                        <Col className="mt-10 h-100 pd-l-8" style={{backgroundColor: "#F3F6F9", maxWidth: "50px"}} xxl={1}>#</Col>
                                                        <Col className="mt-10 h-100 pd-l-8" style={{backgroundColor: "#F3F6F9", minWidth: "200px"}} xxl={3}><Header title={GetMessageIntl(intl, "dashboard.taskNm")}/></Col>
                                                        <Col className="mt-10 h-100 pd-l-8" style={{backgroundColor: "#F3F6F9", maxWidth: "80px"}} xxl={1}><Header title={GetMessageIntl(intl, "dashboard.status")}/></Col>
                                                        <Col className="mt-10 h-100 pd-l-8" style={{backgroundColor: "#F3F6F9", maxWidth: "90px"}} xxl={2}><Header title={GetMessageIntl(intl, "dashboard.openDate")}/></Col>
                                                        <Col className="mt-10 h-100 pd-l-8" style={{backgroundColor: "#F3F6F9", maxWidth: "135px"}} xxl={2}><Header title={GetMessageIntl(intl, "dashboard.thisMonthPoint")}/></Col>
                                                        <Col className="mt-10 h-100 pd-l-8" style={{backgroundColor: "#F3F6F9", maxWidth: "135px"}} xxl={2}><Header title={GetMessageIntl(intl, "dashboard.readyToPayPoint")}/></Col>
                                                        <Col className="mt-10 h-100 pd-l-8" style={{backgroundColor: "#F3F6F9", maxWidth: "135px"}} xxl={2}><Header title={GetMessageIntl(intl, "dashboard.pointsPaid")}/></Col>
                                                        <Col className="mt-10 h-100 pd-l-8" style={{backgroundColor: "#F3F6F9", maxWidth: "135px"}} xxl={2}><Header title={GetMessageIntl(intl, "dashboard.totalPoint")}/></Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                            <SimpleBar style={{maxHeight: "230px", minHeight: "230px", overflowX: "hidden"}} autoHide={false} className="simplebar-track-coco">
                                                <Row>
                                                    {
                                                        workflowProgressList.map((item, idx) => {
                                                            return (
                                                                <Row key={"workflowProgressList_".concat(idx)} className="pd-y-7 ht-40 pd-l-24 pd-r-0">
                                                                    <Col className="mt-10 h-100 pd-l-2" style={{maxWidth: "50px"}} xxl={1}>{idx + 1}</Col>
                                                                    <Col className="mt-10 h-100 pd-l-2" xxl={3}><span className="text-coco-gray-700 tx-16">{item.taskNm}</span></Col>
                                                                    <Col className="mt-10 h-100 pd-l-2" style={{maxWidth: "70px"}} xxl={1}>
                                                                        {item.sttusCd === '00' ? <div className="badge badge-coco badge-coco-soft-yellow" style={{width: "43px"}}>{GetMessageIntl(intl, "dashboard.status01")}</div>
                                                                            : item.sttusCd === '01' ? <div className="badge badge-coco badge-coco-soft-yellow" style={{width: "43px"}}>{GetMessageIntl(intl, "dashboard.status01")}</div> /*이런 경우가 있을까*/
                                                                                : item.sttusCd === '02' ? <div className="badge badge-coco badge-coco-soft-green" style={{width: "43px"}}>{GetMessageIntl(intl, "dashboard.status02")}</div>
                                                                                    : item.sttusCd === '03' ? <div className="badge badge-coco badge-coco-soft-red" style={{width: "43px"}}>{GetMessageIntl(intl, "dashboard.status03")}</div>
                                                                                        : item.sttusCd === '04' ? <div className="badge badge-coco badge-coco-soft-skyblue" style={{width: "43px"}}>{GetMessageIntl(intl, "dashboard.status04")}</div>
                                                                                            : null
                                                                        }
                                                                    </Col>
                                                                    <Col className="mt-10 h-100 pd-l-2" style={{maxWidth: "95px"}} xxl={2}><span className="text-coco-gray-400 tx-14">{item.openDate}</span></Col>
                                                                    <Col className="mt-10 h-100 pd-l-2" style={{maxWidth: "137px"}} xxl={2}><span className="text-coco-danger-400 tx-16">{item.col01.toLocaleString()}</span></Col>
                                                                    <Col className="mt-10 h-100 pd-l-2" style={{maxWidth: "137px"}} xxl={2}><span className="text-coco-gray-400 tx-16">{item.col02.toLocaleString()}</span></Col>
                                                                    <Col className="mt-10 h-100 pd-l-2" style={{maxWidth: "137px"}} xxl={2}><span className="text-coco-gray-400 tx-16">{item.col03.toLocaleString()}</span></Col>
                                                                    <Col className="mt-10 h-100 pd-l-2" style={{maxWidth: "137px"}} xxl={2}><span className="text-coco-gray-400 tx-16">{item.col04.toLocaleString()}</span></Col>
                                                                </Row>
                                                            )
                                                        })
                                                    }
                                                </Row>
                                            </SimpleBar>
                                            <hr/>
                                            <Row className="mt-10" style={{height: "44px"}}>
                                                <Col xxl={2}><span className="text-coco-gray-700 tx-16" style={{color: "#465A64"}}>{GetMessageIntl(intl, "dashboard.sum")}</span></Col>
                                                <Col className="h-100 pd-l-2" style={{maxWidth: "310px"}}></Col>
                                                <Col className="h-100 pd-l-2" style={{maxWidth: "140px"}}><span className="text-coco-danger-400 tx-16">{pointSum1.toLocaleString()}</span></Col>
                                                <Col className="h-100 pd-l-2" style={{maxWidth: "140px"}}><span className="text-coco-gray-400 tx-16">{pointSum2.toLocaleString()}</span></Col>
                                                <Col className="h-100 pd-l-2" style={{maxWidth: "138px"}}><span className="text-coco-gray-400 tx-16">{pointSum3.toLocaleString()}</span></Col>
                                                <Col className="h-100 pd-l-2" style={{maxWidth: "140px"}}><span className="text-coco-gray-400 tx-16">{pointSum4.toLocaleString()}</span></Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Col>
                <Col xxl={4}>
                    <Card className="shadow-none h-100 mb-0" style={{borderRadius: "7px", border: "1px solid #CFD8DC"}}>
                        <CardBody>
                            <Row className="ht-40">
                                <Col xxl={12}>
                                    <i className="ri-user-4-line" style={iconStyle}></i>&nbsp;&nbsp;&nbsp;<span className="text-coco-gray-600 fw-semibold tx-16">{GetMessageIntl(intl, "dashboard.workerSttus")}</span>
                                </Col>
                            </Row>
                            <Row style={{height: "90px"}}>
                                <Col xxl={6}>
                                    <Card className="shadow-none h-100 mb-0">
                                        <CardHeader className="pd-0" style={{borderBottom: "0px"}}><span className="text-coco-gray-600 fw-semibold tx-16">중복 포함</span></CardHeader>
                                        <CardBody><span className="text-coco-gray-800 fw-semibold tx-28">{workflowFirstRow.count04.toLocaleString()}</span></CardBody>
                                    </Card>
                                </Col>
                                <Col xxl={6}>
                                    <Card className="shadow-none h-100 mb-0">
                                        <CardHeader className="pd-0" style={{borderBottom: "0px"}}><span className="text-coco-gray-600 fw-semibold tx-16">중복 제외</span></CardHeader>
                                        <CardBody><span className="text-coco-gray-800 fw-semibold tx-28">{workflowFirstRow.count04Distinct.toLocaleString()}</span></CardBody>
                                    </Card>
                                </Col>
                            </Row>
                            <hr/>
                            <Row>
                                <Col xxl={12}>
                                    <i className="ri-user-smile-line" style={{...iconStyle, color: "#465A64"}}></i>&nbsp;&nbsp;&nbsp;<span className="text-coco-gray-600 fw-semibold tx-16">{GetMessageIntl(intl, "dashboard.workflowWorkersByTask")}</span>
                                </Col>
                            </Row>
                            <Row className="h-400">
                                {/*태스크별 작업자*/}
                                <Col xxl={12} className="pd-r-0">
                                    <SimpleBar style={{maxHeight: "340px", overflowX: "hidden"}} autoHide={false} className="simplebar-track-coco mt-10 ">
                                        {
                                            workersByTaskData.map((item, idx) => {
                                                return (
                                                    <Row className="mt-10 mb-10 pd-5 ht-50" key={item.wrkflowId.concat(idx)}>
                                                        <Col xxl={2}>{item.moduleTy == '01' ?
                                                            <div className="avatar-title fs-20 rounded-circle" style={{backgroundColor: '#DFF3EF', color: '#0BAD8F', width: "42px", height: "42px"}}>
                                                                <i className="ri-pen-nib-line lh-19"></i>
                                                            </div>
                                                            :
                                                            <img src={moduleReviewImg} style={{WebkitUserDrag: 'none'}}/>}</Col>
                                                        <Col xxl={5}>
                                                            <span className="text-coco-gray-500 fw-semibold tx-16">{item.moduleNm}</span>
                                                        </Col>
                                                        <Col xxl={4}>
                                                            <Row className="h-50">
                                                                <Col xxl={12} className="text-right"><span className="text-coco-secondary-700 fw-semibold tx-16">{item.active} {GetMessageIntl(intl, "dashboard.person")}</span></Col>
                                                            </Row>
                                                            <Row className="h-50">
                                                                <Col xxl={12} className="text-right"><span
                                                                    className="text-coco-gray-400 tx-16">{GetMessageIntl(intl, "dashboard.inactive")} {item.inactive} {GetMessageIntl(intl, "dashboard.person")}</span></Col>
                                                            </Row>
                                                        </Col>
                                                        <Col xxl={1}></Col>
                                                    </Row>
                                                )
                                            })
                                        }
                                    </SimpleBar>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            <Row className="mt-24" style={{height: "566px"}}>
                <Col xxl={8}>
                    <Card className="shadow-none h-100 mb-0" style={{borderRadius: "7px", border: "1px solid #CFD8DC", backgroundColor: "#F3F6F9"}}>
                        <CardBody>
                            <Row>
                                <Col xxl={12} className="pd-t-5">
                                    <i className="ri-route-fill" style={iconStyle}></i>&nbsp;&nbsp;&nbsp;<span className="text-coco-gray-600 fw-semibold tx-16">{GetMessageIntl(intl, "dashboard.taskStatus")}</span>
                                </Col>
                            </Row>
                            <Row style={{height: "500px"}}>
                                <Col xxl={12}>
                                    {/*태스크 현황*/}
                                    <ReactFlowProvider>
                                        <ReactflowComponent wrkflowId={wrkflowId} workflowTaskStatusData={workflowTaskStatusData}/>
                                    </ReactFlowProvider>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
                <Col xxl={4}>
                    <Card className="shadow-none h-100 mb-0">
                        <CardBody className="pd-t-0">
                            <Row style={{height: "120px"}}>
                                <Col xxl={12} style={{borderRadius: "7px", backgroundColor: "#0BAD8F", color: "white"}}>
                                    <Row className="pd-10">
                                        <Col xxl={6} className="ht-100">
                                            <Row className="ht-50">
                                                <Col xxl={12} className="pd-t-20">
                                                    <i className="ri-checkbox-circle-line" style={{...iconStyle, backgroundColor: "#FFFFFF"}}></i>&nbsp;&nbsp;&nbsp;<span
                                                    className="text-coco-default-white fw-semibold tx-16">{GetMessageIntl(intl, "dashboard.numberOfCompletions")}</span>
                                                </Col>
                                            </Row>
                                            <Row className="ht-50">
                                                <Col xxl={12} className="pd-t-10">
                                                    <span className="text-coco-default-white fw-semibold tx-24" style={{fontWeight: 800}}>{complateCount} ({complatePercent}%)</span>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col xxl={6} className="ht-100" style={{borderLeft: "2px dashed #FFFFFF"}}>
                                            <Row className="ht-50">
                                                <Col xxl={12} className="pd-t-20">
                                                    <i className="ri-flag-line" style={{...iconStyle, backgroundColor: "#FFFFFF"}}></i>&nbsp;&nbsp;&nbsp;<span
                                                    className="text-coco-default-white fw-semibold tx-16">{GetMessageIntl(intl, "dashboard.numberOfCasesClosed")}</span>
                                                </Col>
                                            </Row>
                                            <Row className="ht-50">
                                                <Col xxl={12} className="pd-t-10">
                                                    <span className="text-coco-default-white fw-semibold tx-24" style={{fontWeight: 800}}>{finishCount} ({finishPercent}%)</span>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row>
                                <Col xxl={12} className="mt-28">
                                    <ReactECharts option={pieChartOption} style={{width: "500px", height: "400px"}}/>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </Row>

            <Row className="mt-24" style={{height: "400px"}}>
                <Col xxl={7}>
                    <Card className="shadow-none h-100 mb-0" style={{borderRadius: "7px", border: "1px solid #CFD8DC"}}>
                        <CardBody>
                            <Row>
                                <Col xxl={8} className="pd-t-5">
                                    <i className="ri-price-tag-3-line" style={iconStyle}></i>&nbsp;&nbsp;&nbsp;<span className="text-coco-gray-600 fw-semibold tx-16">{GetMessageIntl(intl, "dashboard.workflowClassBoxTitle")}</span>
                                </Col>
                                <Col xxl={4}>
                                    <Select
                                        ref={column05Ref}
                                        className="mb-0"
                                        style={{zIndex: 9999}}
                                        styles={noBorderSelectStyle}
                                        defaultValue={column05LabelTaskOption[0]}
                                        key={column05LabelTaskOption[0]}
                                        options={column05LabelTaskOption}
                                        onChange={selectChangeHandler05}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col xxl={12}>
                                    <ReactECharts option={classInstanceOption} style={{width: "900px", height: "330px"}}/>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
                <Col xxl={5}>
                    <Card className="shadow-none h-100 mb-0 border-1">
                        <CardBody>
                            <Row>
                                <Col xxl={8} className="pd-t-5">
                                    <i className="ri-forbid-line" style={iconStyle}></i>&nbsp;&nbsp;&nbsp;<span className="text-coco-gray-600 fw-semibold tx-16">{GetMessageIntl(intl, "dashboard.workflowSkipBoxTitle")}</span>
                                </Col>
                                <Col xxl={4}>
                                    <Select
                                        ref={column06Ref}
                                        className="mb-0"
                                        style={{zIndex: 9999}}
                                        styles={noBorderSelectStyle}
                                        defaultValue={column06LabelTaskOption[0]}
                                        key={column06LabelTaskOption[0]}
                                        options={column06LabelTaskOption}
                                        onChange={selectChangeHandler06}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col xxl={12}>
                                    <ReactECharts option={workSkipReasonOption} style={{width: "600px", height: "330px"}}/>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            <Row className="mt-24" style={{height: "400px"}}>
                <Col xxl={6}>
                    <Card className="shadow-none h-100 mb-0" style={{borderRadius: "7px", border: "1px solid #CFD8DC"}}>
                        <CardBody>
                            <Row>
                                <Col xxl={12} className="pd-t-5">
                                    <i className="ri-hand-coin-line" style={iconStyle}></i>&nbsp;&nbsp;&nbsp;<span className="text-coco-gray-600 fw-semibold tx-16">{GetMessageIntl(intl, "dashboard.workflowWorkProgressStackTitle")}</span>
                                </Col>
                            </Row>
                            <Row>
                                <Col xxl={12}>
                                    <ReactECharts option={workProgressStackOptions} style={{width: "750px", height: "330px"}}/>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
                <Col xxl={6}>
                    <Card className="shadow-none h-100 mb-0 border-1">
                        {/*반려 사유 분포*/}
                        <CardBody>
                            <Row>
                                <Col xxl={8} className="pd-t-5">
                                    <i className="ri-route-fill" style={iconStyle}></i>&nbsp;&nbsp;&nbsp;<span className="text-coco-gray-600 fw-semibold tx-16">{GetMessageIntl(intl, "dashboard.workflowRejectReasonTitle")}</span>
                                </Col>
                                <Col xxl={4}>
                                    <Select
                                        ref={column07Ref}
                                        className="mb-0"
                                        style={{zIndex: 9999}}
                                        styles={noBorderSelectStyle}
                                        key={column07LabelTaskOption[0]}
                                        defaultValue={column07LabelTaskOption[0]}
                                        options={column07LabelTaskOption}
                                        onChange={selectChangeHandler07}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col xxl={12}>
                                    <ReactECharts option={column07ChartOption} style={{width: "750px", height: "330px"}}/>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            {canvasDataTy === '11' ?
                <Row className="mt-24" style={{height: "400px"}}>
                    <Card className="shadow-none h-100 mb-0">
                        < CardBody style={{borderRadius: "7px", border: "1px solid #CFD8DC"}}>
                            <Row>
                                <Col xxl={6}>
                                    <Row>
                                        <Col xxl={8} className="pd-t-5">
                                            <i className="ri-hand-coin-line" style={iconStyle}></i>&nbsp;&nbsp;&nbsp;<span className="text-coco-gray-600 fw-semibold tx-16">{GetMessageIntl(intl, "dashboard.workflowClassInstanceRejcetBoxTitle")}</span>
                                        </Col>
                                        <Col xxl={4}>
                                            <Select
                                                ref={column08Ref}
                                                className="mb-0"
                                                style={{zIndex: 9999}}
                                                styles={noBorderSelectStyle}
                                                key={column08LabelTaskOption[0]}
                                                defaultValue={column08LabelTaskOption[0]}
                                                options={column08LabelTaskOption}
                                                onChange={selectChangeHandler08}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xxl={12}>
                                            {/*클래스별 인스턴스 반려 개수 및 반려 사유 분포*/}
                                            <ReactECharts option={column08ChartOption} style={{width: "750px", height: "330px"}} onEvents={{'click': onChartClick}}/>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col xxl={6}>
                                    {
                                        clicked ?
                                            (<>
                                                    <Row>
                                                        <Col xxl={9} className="pd-t-5">
                                                            <span className="text-coco-gray-600 fw-semibold tx-16">{GetMessageIntl(intl, "dashboard.workflowClassDetailBoxTitle", {'className': clickedItem})}</span>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col xxl={12}>
                                                            <ReactECharts option={column09ChartOption} style={{width: "750px", height: "330px"}}/>
                                                        </Col>
                                                    </Row>
                                                </>
                                            ) : null
                                    }
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Row>
                : ""}
            <Row className="ht-20"></Row>
        </>
    )
}


function TopCardComponent(prop) {
    const {title, count, icon, iconStyle} = prop;
    return (
        <>
            <Card className="shadow-none h-100 mb-0" style={{background: "#F3F6F9", borderRadius: "7px"}}>
                <CardBody>
                    <Row className="ht-40">
                        <Col xxl={12}>
                            <i className={icon} style={iconStyle}></i>&nbsp;&nbsp;&nbsp;<span className="text-coco-gray-600 fw-semibold tx-16">{title}</span>
                        </Col>
                    </Row>
                    <Row className="ht-40">
                        <Col xxl={12}>
                            <Row className="ht-40">
                                <Col xxl={12}><span className="text-coco-gray-800 fw-semibold tx-28">{count.toLocaleString()}</span></Col>
                            </Row>
                        </Col>
                    </Row>
                </CardBody>
            </Card>
        </>
    )
}

const Header = (props) => {
    return <span className="text-coco-gray-700 fw-semibold tx-16">{props.title}</span>;
}


const nodeTypes = {
    dataset: DatasetNode,
    workflow: workflowNode,
    label: LabelNode,
    autoLabel: AutoLabelNode,
    review: ReviewNode,
    crossReview: CrossReviewNode,
    managerReview: ManagerReviewNode,
    sdq4ai: SDQNode,
    accept: AcceptNode,
    finish: FinishNode,
    unabled: UnabledNode,
    subUnabled: SubUnabledNode,
    restore: RestoreNode,
    submit: SubmitNode,
    skip: SkipNode,
    labeled: LabeledNode,
    approval: ApprovalNode,
    reject: RejectNode,
    ambiguity: AmbiguityNode,
    stateChange: StateChangeNode,
    updateSubmit: UpdateSubmitNode
}

const edgeTypes = {
    floating: FloatingEdge,
    custom: CustomEdge,
};


const getColumn07ChartOption = (data) => {
    return {
        xAxis: {
            type: 'category',
            data: ['객체 누락', '불필요 객체', '영역 오류', '분류 오류', '속성값 변경', '속성값 누락', '속성값 삭제']
        },
        yAxis: {
            type: 'value',
            splitLine: {
                show: false
            }
        },
        grid: {
            top: "3%",
            left: "5%",
            right: "3%",
            bottom: "7%",
            borderWidth: 0.5,
            show: true
        },
        series: [
            {
                data: data,
                type: 'bar'
            }
        ]
    };
}


const getColumn09ChartOption = (data) => {
    return {
        xAxis: {
            type: 'category',
            data: ['객체 누락', '불필요 객체', '영역 오류', '분류 오류', '속성값 변경', '속성값 누락', '속성값 삭제']
        },
        yAxis: {
            type: 'value',
            splitLine: {
                show: false
            }
        },
        grid: {
            top: "3%",
            left: "5%",
            right: "3%",
            bottom: "7%",
            borderWidth: 0.5,
            show: true
        },
        series: [
            {
                data: data,
                type: 'bar'
            }
        ]
    };
}

const getColumn08ChartOption = (data) => {
    const axisList = data.map((item) => {
        return item.name
    });

    const dataList = data.map((item) => {
        return item.cnt
    });

    return {
        xAxis: {
            type: 'category',
            data: axisList
        },
        grid: {
            top: "3%",
            left: "5%",
            right: "3%",
            bottom: "7%",
            borderWidth: 0.5,
            show: false
        },
        yAxis: {
            type: 'value',
            splitLine: {
                show: false
            }
        },
        series: [
            {
                data: dataList,
                type: 'bar'
            }
        ]
    };
}

const getPieChartOption = (data) => {
    return {
        color: ['#338AFF', '#338AFF', '#F5980C', '#FFEB38', '#F28067', '#73c0de', '#0BAD8F'],
        tooltip: {
            show: true,
            // alwaysShowContent : true
        },
        legend: {
            bottom: '5',
            right: '5',
            orient: 'vertical',
            selectedMode: false
        },
        series: {
            nodeClick: false,
            type: 'sunburst',
            emphasis: {
                focus: 'series',
                blurScope: 'coordinateSystem'
            },
            data: data,
            radius: ['40%', '100%'],
            label: {
                align: 'center'
                , rotate: 0
                // ,formatter : '{c}'
            }
        }
    };
}


const getWorkProgressStackOptions = (data) => {

    const commitList = data.map(item => {
        return [item.dateLong, item.commitCnt];
    });

    const completeList = data.map(item => {
        return [item.dateLong, item.completeCnt];
    });

    return {
        color: ['#0BAD8F', '#FFA98E'],
        legend: {
            data: ['제출', '완료']
        },
        grid: {
            left: "7%",
            right: "3%",
            bottom: "5%",
            borderWidth: 0.5,
            show: true
        },
        // toolbox: {
        //     feature: {
        //         restore: {},
        //         saveAsImage: {}
        //     }
        // },
        xAxis: {
            type: 'time',
            boundaryGap: false
        },
        yAxis: {
            type: 'value',
            boundaryGap: [0, '20%'],
            splitLine: {
                show: false
            }
        },
        dataZoom: [
            {
                type: 'inside',
                start: 0,
                end: 100
            }
        ],
        tooltip: {
            show: true
        },
        series: [
            {
                name: '제출',
                type: 'line',
                smooth: true,
                symbol: 'circle',
                showSymbol: true,
                areaStyle: {},
                data: commitList,
                silent: true
            },
            {
                name: '완료',
                type: 'line',
                smooth: true,
                symbol: 'roundRect',
                showSymbol: true,
                areaStyle: {},
                data: completeList,
                silent: true
            }
        ]
    };
}


const defaultEdgeOptions = {
    style: {strokeWidth: 2, stroke: "#0bad8f"},
    type: 'smoothstep',
    markerEnd: {
        type: MarkerType.ArrowClosed,
        color: "#0bad8f",
    },
    /*markerStart:'edge-marker-start',*/
    zIndex: 9
};

const ReactflowComponent = (prop) => {

    const {wrkflowId} = prop;
    const [nodes, setNodes, onNodesChange] = useNodesState([]);
    const [edges, setEdges, onEdgesChange] = useEdgesState([]);
    const reactFlowInstance = useReactFlow();

    // const {workflowTaskStatusData} = useSelector((state) => ({
    //     workflowTaskStatusData: state.Dashboard.workflowTaskStatusData
    // }));

    const emptyData = {
        taskNm: "", col01: 0, col02: 0, col03: 0, col04: 0, col05: 0
    }
    useEffect(() => {
        ManagerWorkflowService.getWorkflowItem({wrkflowId: wrkflowId}).then((result) => {
            if (result.data.data) {
                let resultData = result.data.data;
                if (resultData.nodeInfo) {
                    let nodeInfo = JSON.parse(resultData.nodeInfo);

                    let datasetList = null;
                    ModuleService.getManageDatasetModuleList({wrkflowId: wrkflowId})
                        .then(res => {
                            if (res.data.data) {
                                datasetList = res.data.data;
                            }

                            DashboardService.workflowDashboardColumnAll({wrkflowId: wrkflowId})
                                .then(res => {
                                    if (res.data.data != null) {
                                        const column04Data = JSON.parse(res.data.data.column04);
                                        const newNodes = nodeInfo.nodes.map(nodeItem => {
                                            if (res.data.data.column04 == '{}') {
                                                return {...nodeItem, data: emptyData}
                                            } else {
                                                if (nodeItem.hasOwnProperty("parentNode")) {
                                                    return {...nodeItem, data: column04Data[nodeItem.parentNode]}
                                                } else {
                                                    if (nodeItem.type == 'dataset' && datasetList) {
                                                        return {...nodeItem, data: datasetList.find(item => item.moduleId == nodeItem.id)}
                                                    } else {
                                                        return {...nodeItem, data: column04Data[nodeItem.id]}
                                                    }
                                                }
                                            }
                                        });
                                        setNodes(newNodes);
                                        setEdges(nodeInfo.edges);
                                    }
                                });


                        });
                }
            }
        })
    }, [wrkflowId]);

    const onConnect = useCallback((params) => setEdges((eds) => addEdge(params, eds)), []);

    return (
        <>
            <ReactFlow
                nodes={nodes}
                edges={edges}
                onNodesChange={onNodesChange}
                onEdgesChange={onEdgesChange}
                onConnect={onConnect}
                onLoad={() => {
                    reactFlowInstance.fitView();

                }}
                snapToGrid={true}
                nodeTypes={nodeTypes}
                edgeTypes={edgeTypes}
                nodesDraggable={false}
                defaultEdgeOptions={defaultEdgeOptions}
                className={"workflow_dashboard_flow"}
                fitView
            >
                <Controls showInteractive={false} className={'m-0'}/>
            </ReactFlow>
        </>
    );
};


function getClassInstanceOptions(axis, data, rate) {
    return {
        color: ['#46BCA2', '#F06448'],
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'cross',
                crossStyle: {
                    color: '#999'
                }
            }
        },
        grid: {
            left: "7%",
            right: "7%",
            bottom: "7%",
            borderWidth: 0.5,
            show: true
        },
        toolbox: {
            feature: {
                dataView: {show: false, readOnly: false},
                magicType: {show: false, type: ['line', 'bar']},
                restore: {show: false},
                saveAsImage: {show: false}
            }
        },
        legend: {
            data: ['인스턴스개수', '비중'],
            selectedMode: false
        },
        xAxis: [
            {
                type: 'category',
                data: axis,
                axisPointer: {
                    type: 'shadow'
                }
            }
        ],
        yAxis: [
            {
                type: 'value',
                name: '(단위:개)',
                min: 0,
                interval: 100,
                axisLabel: {
                    formatter: '{value}'
                },
                splitLine: {
                    show: false
                }
            },
            {
                type: 'value',
                name: '(단위:%)',
                min: 0,
                interval: 10,
                axisLabel: {
                    formatter: '{value}'
                },
                splitLine: {
                    show: false
                }
            }
        ],
        series: [
            {
                name: '인스턴스개수',
                type: 'bar',
                tooltip: {
                    valueFormatter: function (value) {
                        return value + ' 개';
                    }
                },
                data: data
            },
            {
                name: '비중',
                type: 'line',
                yAxisIndex: 1,
                tooltip: {
                    valueFormatter: function (value) {
                        return value + ' %';
                    }
                },
                data: rate,
                // markPoint : {
                //     data: [{
                //         type: "max",
                //         symbol: "rect",
                //         symbolSize : 25,
                //         label: {
                //             show: true
                //         }
                //     },{
                //         type: "min",
                //         symbol: "rect",
                //         symbolSize : 25,
                //         label: {
                //             show: true
                //         }
                //     }],
                //     silent: true
                // }
            }
        ]
    };
}


function getSkipReasonOption(data) {
    const sum = data.reduce((prev, current) => prev + current.value, 0);
    const centerText = "전체\n" + sum.toLocaleString();

    return {
        tooltip: {
            trigger: 'item'
        },
        legend: {
            bottom: '5',
            right: '5',
            orient: 'vertical',
            selectedMode: false
        },
        grid: {
            top: "10%"
        },
        series: [
            {
                name: '',
                type: 'pie',
                radius: ['60%', '95%'],
                avoidLabelOverlap: false,
                label: {
                    fontSize: 28,
                    fontWeight: 600,
                    show: true,
                    position: 'center',
                    formatter: function (param) {
                        return centerText;
                    }
                },
                center: ["35%", "50%"],
                emphasis: {
                    disable: true
                },
                labelLine: {
                    show: false
                },
                data: data
            }
        ]
    };
}

export default WorkflowDashboard;